import React, { useEffect, VFC } from 'react';
import { IconButton } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Menu } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { useClickawayPresence } from '../../../contexts/ClickawayPresence';

interface ActionMenuProps {
  actions: ([string, () => void] | false | null | undefined)[];
}

const ActionMenu: VFC<ActionMenuProps> = ({ actions }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  const clickawayPresence = useClickawayPresence();

  useEffect(() => {
    if (open) {
      return clickawayPresence.register();
    }
  }, [open, clickawayPresence]);

  const handleAction = (action: () => void) => () => {
    handleClose();
    action();
  };

  const handleClick = (event: React.UIEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
        <ArrowDropDownIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: '6em',
            width: '20ch',
          },
        }}
      >
        {actions.map((action, i) =>
          action ? (
            <MenuItem key={i} onClick={handleAction(action[1])}>
              {action[0]}
            </MenuItem>
          ) : null
        )}
      </Menu>
    </div>
  );
};

export default ActionMenu;
