import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import { useApolloClient, useMutation, useSubscription } from '@apollo/client';
import { generatePath, Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import StackView from '../../../../se/components/StackView';
import { InlineOmniSearchWithRouter } from '../../../OmniSearch';
import proceduresSearchSource from '../../../../omnisearch/sources/surgeon/procedures';
import { a11yProps } from '../../../core/TabPanel';
import procedures from '../../../../graph/surgeon/procedures';
import schema, { itemSubscription } from '../../../../graph/surgeon/procedures';
import CollectionListOnly from '../../../../se/components/collection/CollectionListOnly';
import { consultationColumns, State, viewColumns } from '../columns';
import {
  last30DaysRange,
  last90DaysRange,
  lastMonthRange,
  lastSevenDaysExcludingTodayRange,
  lastYearRange,
  NamedRange,
  next30DaysExcludingTodayRange,
  next90DaysExcludingTodayRange,
  nextMonthRange,
  nextSevenDaysExcludingTodayRange,
  thisYearRange,
} from '../../../core/DatePicker';
import RequiresAttention from './RequiresAttention';
import { Box, Button, Chip, CircularProgress, Hidden, Snackbar, Typography } from '@material-ui/core';
import Form from '../../../../se/components/Form';
import { RouterlessModal } from '../../../../se/components/Modal';
import InvitePatient from '../../surgeonProcedures/InvitePatient';
import { compose } from 'recompose';
import { withSession } from '../../../../state/Session';
import { unpackSessionObject } from '../../../pages/unpackSessionObject';
import { withScope } from '../../../../contexts/ScopeContext';
import withExchangeOnly from '../../../../util/withExchangeOnly';
import PatientProfile from '../PatientProfile';
import TitleAction from '../../../../se/components/TitleAction';
import get from 'lodash/get';
import { ConsultationStatus } from './enums';
import PatientFileGenerator from '../../procedures/PatientFileGenerator';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import EditProcedureInput from '../../surgeonProcedures/EditProcedureInput';
import pick from 'lodash/fp/pick';
import EntityEditOriginal from '../../../../se/components/entity/EntityEdit';
import Filters from '../../../pages/analytics/Filters';
import ConsultationsWithUnresolvedState from './ConsultationsWithUnresolvedState';
import { consultationsSubscription } from '../../../../graph/surgeon/consultations';
import { Alert } from '@material-ui/lab';
import compact from 'lodash/compact';
import Fab from '@material-ui/core/Fab';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SurgeonProcedureMobileListItem from '../../surgeonProcedures/SurgeonProcedureMobileListItem';
import ChangeStatusButton from '../ChangeStatusButton';
import { ButtonBack } from '../../../ButtonBack';
import EntityState from '../../../../se/components/EntityState';
import { LocalDate } from '@js-joda/core';
import { breakpoint } from '../../../../se/utilities/responsive';
import { Pill, Pills } from '../../../core/TabRoutes';

export const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  status: {
    minWidth: 200,
    marginRight: theme.spacing(3),
  },
  titleSearch: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    minWidth: 0,

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  titleActions: {
    display: 'flex',
    flex: 1,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  titleButton: {
    marginLeft: 'auto',
  },
  title: {
    marginRight: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  search: {
    width: 400,
  },
  anchorTopRight: {
    transform: 'translate(100%, -50%)',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(10),
    zIndex: 1,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  warning: {
    backgroundColor: theme.palette.warning.dark,
    color: theme.palette.getContrastText(theme.palette.warning.dark),
  },
}));

const EntityEdit = EntityEditOriginal as any;

const PrevPageContext = React.createContext<{
  prevTab: TabParameter | null;
  prevDateRange: any;
  set: (tab: TabParameter | null, dateRange: any) => void;
}>({
  prevTab: null,
  prevDateRange: null,
  set: (tab: TabParameter | null, dateRange: any) => {},
});

enum TabParameter {
  Today = 'today',
  Future = 'future',
  Past = 'past',
}

type Params = {
  tab: TabParameter;
};

interface ConsultationsProps extends RouteComponentProps<Params> {
  children: React.ReactNode;
}

const EmptyState = (props: any) => (
  <EntityState title="No Consultations Scheduled" hint="Currently there are no consultations." {...props} />
);

const Consultations: FC<ConsultationsProps> = ({ location, history, match, children }) => {
  const classes = useStyles();
  const apolloClient = useApolloClient();
  const [open, setOpen] = useState<boolean>(false);
  const [severity, setSeverity] = useState<any>('info');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [patientId, setPatientId] = useState<number | undefined>(undefined);
  const [busy, setBusy] = useState<boolean>(false);
  const [createConsultation] = useMutation(schema.create);

  const handleChange = (_: any, tab: string) => {
    history.replace(generatePath(match.path, { ...match.params, tab }));
  };

  const onSubmit = async (value: any) => {
    setBusy(true);
    const { consultationDate, consultationTime, patient, physician, locationId } = value || {};
    try {
      const newConsultation = await createConsultation({
        variables: {
          consultationDate,
          consultationTime,
          patient,
          physician,
          locationId,
        },
      });
      setPatientId(newConsultation?.data?.createProcedure?.id);
    } catch (e) {
      console.log(e);
      setSeverity('error');
      setSnackbarMessage(
        `Patient could not be invited. Please try again or contact system administrator if the problem persists.`
      );
      setSnackbarOpen(true);
    } finally {
      setOpen(false);
      setBusy(false);
      setSeverity('success');
      setSnackbarMessage(`Success! Patient ${patient.name} has been invited.`);
      setSnackbarOpen(true);
      // handleChange(undefined, 'future');
    }
  };

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const query = useSubscription(consultationsSubscription, {
    variables: {
      filter: {
        dateRange: NamedRange.last30Days(),
      },
    },
  });

  const statuses = [ConsultationStatus.Consultation, ConsultationStatus.ConsultationConfirmed];

  const pastConsultationsWithUnresolvedState = query.data?.consultations?.filter(
    (row: { status: string }) => statuses.find(s => s === row.status) !== undefined
  );

  const issues = compact([
    // {
    //   severity: 'warning',
    //   title: 'Approaching consultation but no questionnaire fulfilled',
    //   actionTitle: 'Send reminder',
    //   onClick: () => history.push(location.pathname.replace(`/${match.params.tab}`, `/requires-attention/no-questionnaire-fulfilled`)),
    // },
    pastConsultationsWithUnresolvedState?.length
      ? {
          severity: 'info',
          title: `${pastConsultationsWithUnresolvedState?.length} ${
            pastConsultationsWithUnresolvedState?.length === 1 ? 'consultation is' : 'consultations are'
          } in unresolved state`,
          actionTitle: 'Review',
          onClick: () =>
            history.push(location.pathname.replace(`/${match.params.tab}`, `/requires-attention/unresolved-state`)),
        }
      : undefined,
  ]);

  return (
    <StackView>
      <Hidden smUp>
        <Box className={classes.fab}>
          <Fab variant="extended" onClick={() => setOpen(true)} color="primary" aria-label="add">
            <PersonAddIcon className={classes.extendedIcon} /> Invite Patient
          </Fab>
        </Box>
      </Hidden>
      <Box display="flex" justifyContent="space-between" mt={1} mb={1}>
        <div className={classes.titleSearch}>
          <Typography variant="h2" className={classes.title}>
            Consultations
          </Typography>
          <div className={classes.titleActions}>
            <InlineOmniSearchWithRouter source={[apolloClient, proceduresSearchSource]} />
            <Hidden smDown>
              <Button onClick={() => setOpen(true)} color="primary" variant="contained" className={classes.titleButton}>
                Invite Patient
              </Button>
            </Hidden>
          </div>
        </div>
      </Box>
      <Box my={2}>
        <Pills
          indicatorColor={'primary'}
          value={match.params.tab}
          variant="scrollable"
          onChange={handleChange}
          aria-label="consultations tabs"
        >
          <Pill label={'Past Consultations'} value="past" {...a11yProps(0)} />
          <Pill label={'Today’s Consultations'} value="today" {...a11yProps(1)} />
          <Pill label={'Future Consultations'} value="future" {...a11yProps(2)} />
        </Pills>
      </Box>
      {children}
      {!!issues?.length && <RequiresAttention issues={issues} />}
      {open && (
        <RouterlessModal en title={'Invite Patient'} onClose={() => setOpen(false)}>
          <Form autoFocus label={'Invite Patient'} input={InvitePatient} onSubmit={onSubmit} busy={busy} />
        </RouterlessModal>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={7000}
        onClose={handleClose}
      >
        <Alert
          severity={severity}
          variant="filled"
          action={
            severity === 'success' && (
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                onClick={() => history.push(location.pathname.replace(`/${match.params.tab}`, `/${patientId}`))}
              >
                JUMP TO PATIENT PROFILE
              </Button>
            )
          }
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </StackView>
  );
};

const getDateRange = (tab: TabParameter): any => {
  switch (tab) {
    case TabParameter.Today:
      return NamedRange.today();
    case TabParameter.Future:
      return NamedRange.next30DaysExcludingToday();
    case TabParameter.Past:
      return NamedRange.last30Days();
    default:
      return NamedRange.today();
  }
};

const FilteredConsultations: FC<RouteComponentProps<Params>> = props => {
  const tab = props.match.params.tab;
  const isPastOrFuture = tab === TabParameter.Past || tab === TabParameter.Future;
  const { prevTab, prevDateRange, set } = useContext(PrevPageContext);
  const isFuture = tab === TabParameter.Future;

  const [filter, setFilter] = useState<{
    physician: number | undefined;
    procedureStatus: string | undefined;
    dateRange: any;
  }>({
    physician: undefined,
    procedureStatus: undefined,
    dateRange: prevTab !== tab ? getDateRange(tab) : prevDateRange,
  });

  useEffect(() => {
    if (prevTab !== tab) {
      set(tab, getDateRange(tab));
      setFilter(prev => ({ ...prev, dateRange: getDateRange(tab) }));
    }
  }, [tab, prevTab, set]);

  useEffect(() => {
    if (!prevDateRange?.equals(filter.dateRange)) {
      set(tab, filter.dateRange);
    }
  }, [tab, prevDateRange, filter.dateRange, set]);

  const query = useSubscription(consultationsSubscription, {
    variables: {
      filter: {
        physician: filter.physician,
        procedureStatus: filter.procedureStatus,
        dateRange: isPastOrFuture ? filter.dateRange : getDateRange(tab),
      },
    },
  });

  // @ts-ignore
  return (
    <Consultations {...props}>
      <Filters
        value={filter}
        onChange={setFilter}
        reload={false}
        hideConsultationStatusSelectInput={false}
        hidePhysicianSelectInput={false}
        hideDateFilter={!isPastOrFuture}
        hideProcedureTypeSelectInput={true}
        dateTimePickerTitle="Filter by Consultation Date"
        defaultValue={{
          dateRange: getDateRange(tab),
        }}
        datePickerRanges={(today = LocalDate.now()) =>
          isFuture
            ? [
                nextSevenDaysExcludingTodayRange(today),
                next30DaysExcludingTodayRange(today),
                next90DaysExcludingTodayRange(today),
                nextMonthRange(today),
                thisYearRange(today),
              ]
            : [
                lastSevenDaysExcludingTodayRange(today),
                lastMonthRange(today),
                last30DaysRange(today),
                last90DaysRange(today),
                lastYearRange(today),
              ]
        }
      />
      {/* @ts-ignore */}
      <CollectionListOnly
        match={props.match}
        location={props.location}
        history={props.history}
        data={query}
        getList={(query: { data: { consultations?: any[] } }) => query.data?.consultations}
        idProvider={(row: any) => row.id}
        showUrlProvider={(id: any) => generatePath(props.match.path, { ...props.match.params, tab: id })}
        columns={consultationColumns}
        hideColumns={false}
        useColumnSelection={true}
        defaultDisplayColumns={[
          '#',
          // 'Visit',
          'Name',
          'Consultation Date',
          'Physician',
          // 'Procedure Type',
          // 'DOB',
          'Age',
          'Sex',
          // 'Patient Email Address',
          // 'Patient Cell Phone Number',
          // 'Patient Home Phone Number',
          'Form %',
          // 'Last Invite',
          'Status',
          'Actions',
        ]}
        tableKey={props.location.pathname}
        // View={View}
        // onViewClick={onViewClick}
        // showUrlProvider={showUrlProvider}
        // Loading={Loading}
        // Error={Error}
        Empty={EmptyState}
        // FilterComponent={FilterComponent}
        // FooterComponent={FooterComponent}
        // filter={filter}
        // setFilter={setFilter}
        // highlightedProvider={highlightedProvider}
        // highlightedRowStyles={highlightedRowStyles}
        // highlightedRowStyleProvider={highlightedRowStyleProvider}
        // AdditionalBlock={AdditionalBlock}
        // containsSeparator={containsSeparator}
        // useCSVExport={useCSVExport}
        MobileItemComponent={SurgeonProcedureMobileListItem}
        // simpleFilter={simpleFilter}
        // defaultSort={defaultSort}
        // searchSource={searchSource}
      />
    </Consultations>
  );
};

const Header: any = (props: any) => {
  const classes = useStyles();
  const id = get(props, 'data.id');
  const consultationDate = get(props, 'data.consultationDate');
  const consultationTime = get(props, 'data.consultationTime');
  const patientName = get(props, 'data.patient.name');
  const patient = get(props, 'data.patient');
  const physician = get(props, 'data.patient.physician');
  const transferId = get(props, 'scheduleData.id');
  const status = get(props, 'data.status');
  const state = get(props, 'scheduleData.state') ? JSON.parse(get(props, 'scheduleData.state')) : null;
  const statusLabel = <State status={status} state={state} />;
  const isMobile = window.innerWidth < breakpoint.md;
  return (
    <Box mb={3}>
      <TitleAction
        Title={() => (
          <Box display="flex" flexDirection="row" alignItems="center">
            {patientName && <Typography variant="h3">{patientName}</Typography>}
            <FormControl className={classes.formControl}>
              <PatientFileGenerator procedureId={id} physician={patient && physician} />
            </FormControl>
          </Box>
        )}
        Back={() => <ButtonBack onClick={props.history.goBack}>Consultations</ButtonBack>}
      >
        {isMobile ? (
          <Box alignItems={'center'}>
            <Chip style={{ marginBottom: '10px' }} label={<span>{statusLabel}</span>} />
            <ChangeStatusButton
              data={{
                id,
                transferId,
                status,
                state,
                consultation: {
                  consultationDate,
                  consultationTime,
                },
                variant: 'contained',
              }}
            />
          </Box>
        ) : (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box mr={2} pr={2} borderRight={1} borderColor="divider">
              <Chip label={<span>{statusLabel}</span>} />
            </Box>

            <ChangeStatusButton
              data={{
                id,
                transferId,
                status,
                state,
                consultation: {
                  consultationDate,
                  consultationTime,
                },
                variant: 'contained',
              }}
            />
          </Box>
        )}
      </TitleAction>
    </Box>
  );
};

// @ts-ignore
const Show: any = compose(withRouter, withSession(unpackSessionObject), withScope, withExchangeOnly)(PatientProfile);

const View: any = (props: any) => {
  const query = useSubscription(itemSubscription, {
    variables: {
      id: props.data.id,
    },
  });

  const apolloClient = useApolloClient();

  return query.data?.patient ? (
    <Fragment>
      <Show {...props} data={query.data.patient} Before={Header} />
      <Route
        exact
        path={props.match.path + '/edit'}
        render={props => (
          <EntityEdit
            {...props}
            Input={EditProcedureInput}
            data={query}
            getValue={(query: any) => ({
              ...query?.data?.patient,
              patient: {
                ...query?.data?.patient?.patient,
                dateOfBirth: query?.data?.patient?.patient?.dateOfBirthISO,
                physician: query?.data?.patient?.patient?.physician?.id,
              },
              locationId: query?.data?.patient?.organizationLocation?.id,
            })}
            handleUpdate={({ patient, ...rest }: any) => {
              apolloClient.mutate({
                mutation: procedures.update,
                variables: {
                  ...rest,
                  patient: pick(['name', 'dateOfBirth', 'age', 'sex', 'homePhone', 'cellPhone', 'emailAddress'])(
                    patient
                  ),
                  physician: patient?.physician,
                  locationId: rest?.locationId,
                },
              });
            }}
            backUrlProvider={(id: any) => generatePath(props.match.path, { ...props.match.params, id }) + '/..'}
            replace={true}
            idProvider={() => props.match.params.id}
          />
        )}
      />
    </Fragment>
  ) : (
    <Box display="flex" justifyContent="center" alignItems="center">
      <CircularProgress size="3rem" aria-label="Loading" />
    </Box>
  );
};

const Routes: FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [context, setContext] = useState<{ prevTab: TabParameter | null; prevDateRange: any }>({
    prevTab: null,
    prevDateRange: null,
  });

  return (
    <PrevPageContext.Provider
      value={{
        ...context,
        set: (prevTab, prevDateRange) => setContext({ prevTab, prevDateRange }),
      }}
    >
      <Switch>
        <Route
          path={`${match.path}/:id(\\d+)`}
          render={props => (
            <View
              {...props}
              data={{ id: parseInt(props.match.params.id, 10) }}
              columns={viewColumns}
              idProvider={({ match }: any) => parseInt(props.match.params.id, 10)}
            />
          )}
        />
        <Route
          exact
          path={`${match.path}/requires-attention/:tab`}
          render={props => {
            switch (props.match.params.tab) {
              case 'no-questionnaire-fulfilled':
              case 'unresolved-state':
              default:
                return <ConsultationsWithUnresolvedState {...props} />;
            }
          }}
        />
        <Route
          exact
          path={`${match.path}/:tab`}
          render={props => {
            switch (props.match.params.tab) {
              case 'today':
              case 'future':
              case 'past':
                return <FilteredConsultations {...props} />;
              default:
                return <Redirect to={`${match.url}/today`} />;
            }
          }}
        />
        <Route render={() => <Redirect to={`${match.url}/today`} />} />
      </Switch>
    </PrevPageContext.Provider>
  );
};

export default withRouter(Routes);
