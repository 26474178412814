import React, { useState } from 'react';
import {
  Box,
  Button,
  Chip,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import {
  bookPositionProcedure,
  bookPositionRoom,
  getOpenPositions,
  hiringManagerApproveProcedure,
  hiringManagerApproveRoom,
  hiringManagerRejectProcedure,
  hiringManagerRejectRoom,
  list,
  sendOpenPositionProcedureNotification,
  sendOpenPositionRoomNotification,
} from '../../../../graph/staff';
import StaffMember, {
  OtherSpecialtyLabel,
  PrimarySpecialtyColors,
  PrimarySpecialtyLabel,
} from '../../../../types/StaffMember';
import ButtonWithOptions from '../../../../se/components/buttons/ButtonWithOptions';
import { OpenPosition, StaffShiftLog, StaffShiftLogType, StaffShiftState } from '../../../../types/StaffShift';
import { withScope } from '../../../../contexts/ScopeContext';
import { format, isBefore, subDays } from 'date-fns';
import { ZonedDateTime } from '@js-joda/core';
import { getFirstShiftLog, getLastShiftLog } from '../../../../util/staffShiftLogs';
import { Count, DeliveryStatus } from '../../../entities/patient/columns';
import LinkButton from '../../../../se/components/LinkButton';
import Tooltip from '../../../Tooltip';
import { compose, withProps } from 'recompose';
import { withLabel } from '../../../../se/components/Label';
import PhoneInput from '../../../../se/components/inputs/PhoneInput';
import sortBy from 'lodash/sortBy';
import findLast from 'lodash/findLast';
import { useSession } from '../../../../state/Session';
import { DeleteOutlineTwoTone, DoneAll, HourglassEmpty, HowToRegTwoTone, PersonAdd } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';

export type ApprovedBy = 'Hiring Manager' | 'Nurse';

interface StaffShiftProps {
  openPosition: OpenPosition;
  scope: any;
  sendSms?: boolean;
}

const PhoneField: any = compose(
  withLabel('Phone Number'),
  withProps({
    placeholder: 'Enter mobile phone number',
    autoComplete: 'off',
  })
)(PhoneInput);

const StaffShiftAction = withScope(({ openPosition, scope }: StaffShiftProps) => {
  const classes = useStyles();
  const session: any = useSession();
  const userId = session?.session?.user?.id;
  const event: StaffShiftLog | undefined = getFirstShiftLog(openPosition?.logs, StaffShiftState.CreatedOpenPosition);

  const { data } = useQuery(list);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [bookProcedure] = useMutation(bookPositionProcedure, {
    refetchQueries: ['openPositions', 'openPosition'],
  });
  const [bookRoom] = useMutation(bookPositionRoom, {
    refetchQueries: ['openPositions', 'openPosition'],
  });
  const hospitalId = scope?.hospital?.id;

  const assign = async (staffId?: number) => {
    if (openPosition?.description?.isProcedure) {
      await bookProcedure({
        variables: {
          id: openPosition?.staffShiftId,
          shiftHospitalId: openPosition?.staffShiftHospitalId,
          staffHospitalId: hospitalId,
          staffId,
        },
      });
    }
    if (openPosition?.description?.isRoom) {
      await bookRoom({
        variables: {
          id: openPosition?.staffShiftId,
          shiftHospitalId: openPosition?.staffShiftHospitalId,
          staffHospitalId: hospitalId,
          staffId,
        },
      });
    }
  };

  const chooseStaffMember = async (event: React.MouseEvent<HTMLLIElement, MouseEvent>, staffMember: StaffMember) => {
    event.stopPropagation();
    await assign(staffMember?.staffId);
  };

  const [approveProcedure, { loading: loadingP }] = useMutation(hiringManagerApproveProcedure, {
    refetchQueries: ['openPositions', 'openPosition'],
  });
  const [approveRoom, { loading: loadingR }] = useMutation(hiringManagerApproveRoom, {
    refetchQueries: ['openPositions', 'openPosition'],
  });

  const approve = async (phoneNumber?: string) => {
    if (openPosition?.description?.isProcedure) {
      await approveProcedure({
        variables: {
          id: openPosition?.staffShiftId,
          shiftHospitalId: openPosition?.staffShiftHospitalId,
          staffHospitalId: openPosition?.staffHospitalId,
          staffId: openPosition?.staff?.staffId,
          phoneNumber,
        },
      });
    }
    if (openPosition?.description?.isRoom) {
      await approveRoom({
        variables: {
          id: openPosition?.staffShiftId,
          shiftHospitalId: openPosition?.staffShiftHospitalId,
          staffHospitalId: openPosition?.staffHospitalId,
          staffId: openPosition?.staff?.staffId,
          phoneNumber,
        },
      });
    }
  };

  const [rejectProcedure] = useMutation(hiringManagerRejectProcedure, {
    refetchQueries: ['openPositions', 'openPosition'],
  });
  const [rejectRoom] = useMutation(hiringManagerRejectRoom, {
    refetchQueries: ['openPositions', 'openPosition'],
  });

  const reject = async (staffId?: number) => {
    if (openPosition?.description?.isProcedure) {
      await rejectProcedure({
        variables: {
          id: openPosition?.staffShiftId,
          shiftHospitalId: openPosition?.staffShiftHospitalId,
          staffHospitalId: hospitalId,
          staffId,
        },
      });
    }
    if (openPosition?.description?.isRoom) {
      await rejectRoom({
        variables: {
          id: openPosition?.staffShiftId,
          shiftHospitalId: openPosition?.staffShiftHospitalId,
          staffHospitalId: hospitalId,
          staffId,
        },
      });
    }
  };

  const showBookPositionButton =
    openPosition?.state === StaffShiftState.CreatedOpenPosition ||
    openPosition?.state === StaffShiftState.RejectedByHiringManager ||
    openPosition?.state === StaffShiftState.Opened;

  const [openApproveDialog, setOpenApproveDialog] = useState<boolean>(false);

  const handleCloseApproveDialog = () => setOpenApproveDialog(false);

  const clickApprove = async () => {
    setOpenApproveDialog(true);
    handleClose();
  };

  const clickReject = async () => {
    await reject(openPosition?.staff?.staffId);
    handleClose();
  };

  const options =
    hospitalId === openPosition?.staffShiftHospitalId && userId === event?.userId
      ? [
          {
            label: 'Approve',
            primary: true,
            onClick: clickApprove,
            loading: false,
          },
          {
            label: 'Reject',
            primary: false,
            onClick: clickReject,
            loading: false,
          },
        ]
      : [];

  const showApproveButton = options.length > 0 && openPosition?.state === StaffShiftState.BookOpenPosition;

  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [noPhoneNumberError, setNoPhoneNumberError] = useState<string | null>(null);

  const handleApprove = async () => {
    if (phoneNumber || openPosition?.staff?.phoneNumber) {
      setNoPhoneNumberError(null);
      await approve((phoneNumber || openPosition?.staff?.phoneNumber) as string);
      handleCloseApproveDialog();
    } else {
      setNoPhoneNumberError('Please enter phone number');
    }
  };

  return (
    <>
      {showBookPositionButton && (
        <>
          <Button aria-describedby={id} variant="contained" onClick={handleClick}>
            Request
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <MenuList className={classes.staffList}>
              {(data?.staffMembers || []).map((e: StaffMember) => (
                <MenuItem key={e.id} className={classes.staffListItem} onClick={event => chooseStaffMember(event, e)}>
                  <Typography>{e.name}</Typography>
                  {e.title && (
                    <Typography variant={'body1'} color={'textSecondary'}>
                      {e.title}
                    </Typography>
                  )}
                  {e.primarySpecialty && (
                    <Typography variant={'body2'} color={'textSecondary'}>
                      <span style={{ color: PrimarySpecialtyColors[e.primarySpecialty] }}>●&nbsp;</span>
                      {PrimarySpecialtyLabel[e.primarySpecialty]}
                    </Typography>
                  )}
                  {!!e.otherSpecialties?.length && (
                    <Typography variant={'body2'} color={'textSecondary'}>
                      {(e.otherSpecialties || []).map(e => OtherSpecialtyLabel[e]).join(', ')}
                    </Typography>
                  )}
                </MenuItem>
              ))}
            </MenuList>
          </Popover>
        </>
      )}
      {showApproveButton && (
        <Box mt={2}>
          <ButtonWithOptions
            options={options}
            primaryOptions={options.filter(o => o?.primary)}
            secondaryOptions={options.filter(o => !o.primary)}
            loading={false}
            variant={'outlined'}
            popperStyles={{ width: 150, textAlign: 'right' }}
          />
          {openApproveDialog && (
            <Dialog
              open={openApproveDialog}
              onClick={e => e.stopPropagation()}
              onClose={() => setOpenApproveDialog(false)}
            >
              <DialogTitle>
                Are you sure you want to approve and send notification about open position to{' '}
                {openPosition?.staff?.name}?
              </DialogTitle>
              <DialogContent>
                {openPosition?.staff?.phoneNumber && (
                  <DialogContentText>
                    This action will send a message to this phone number: {openPosition?.staff?.phoneNumber}.
                  </DialogContentText>
                )}
                {!openPosition?.staff?.phoneNumber && (
                  <Box>
                    <DialogContentText>
                      {openPosition?.staff?.name} has no phone number. Enter the phone number to which the message will
                      be sent:
                    </DialogContentText>
                    <PhoneField onChange={(tel: string) => setPhoneNumber(tel)} />
                    {noPhoneNumberError && <Typography color="error">{noPhoneNumberError}</Typography>}
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseApproveDialog}>Cancel</Button>
                <Button onClick={handleApprove} autoFocus disabled={loadingP || loadingR}>
                  Approve and Send
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Box>
      )}
    </>
  );
});

export const getLastLog = (logs: Array<StaffShiftLog>, logType: StaffShiftLogType): StaffShiftLog | undefined => {
  const sorted = sortBy(logs, 'at');
  return findLast(sorted, ({ type }: StaffShiftLog) => type === logType);
};

export const AssignedStaffMember = withScope(({ openPosition, scope, sendSms = false }: StaffShiftProps) => {
  const classes = useStyles();
  const staffMember = openPosition?.staff;

  const hospitalId = scope?.hospital?.id;

  const [sendOpenPositionProcedure, { loading: loadingP, error: errorP }] = useMutation(
    sendOpenPositionProcedureNotification,
    {
      refetchQueries: [
        'openPositions',
        {
          query: getOpenPositions,
          variables: {
            id: openPosition?.id,
          },
        },
      ],
    }
  );
  const [sendOpenPositionRoom, { loading: loadingR, error: errorR }] = useMutation(sendOpenPositionRoomNotification, {
    refetchQueries: [
      'openPositions',
      {
        query: getOpenPositions,
        variables: {
          id: openPosition?.id,
        },
      },
    ],
  });

  const send = async () => {
    setDisabledSmsButton(true);
    if (openPosition?.description?.isProcedure) {
      await sendOpenPositionProcedure({
        variables: {
          id: openPosition?.staffShiftId,
          shiftHospitalId: openPosition?.staffShiftHospitalId,
          staffHospitalId: hospitalId,
          staffId: openPosition?.staff?.staffId,
        },
      });
    }
    if (openPosition?.description?.isRoom) {
      await sendOpenPositionRoom({
        variables: {
          id: openPosition?.staffShiftId,
          shiftHospitalId: openPosition?.staffShiftHospitalId,
          staffHospitalId: hospitalId,
          staffId: openPosition?.staff?.staffId,
        },
      });
    }
    setDisabledSmsButton(false);
  };

  const [disabledSmsButton, setDisabledSmsButton] = useState(false);
  const smsStatus = getLastLog(
    openPosition?.logs || [],
    StaffShiftLogType.MessageDeliveryOpenPositionInvite
  )?.messageStatus;
  const smsCount = openPosition?.openPositionSmsCount || 0;
  const tooltip = `This will ${
    (smsCount ?? 0) > 0 ? 'resend' : 'send'
  } the SMS containing a link to the open position.`;
  const error = errorP || errorR ? 'Something went wrong.' : null;

  return (
    <>
      {!!staffMember && (
        <Box className={classes.assignedStaffBox}>
          <Box>
            {!!staffMember.otherSpecialties?.length && (
              <Typography variant={'body2'} color={'textSecondary'}>
                {(staffMember.otherSpecialties || []).map(e => OtherSpecialtyLabel[e]).join(', ')}
              </Typography>
            )}
            {staffMember.title && <Typography variant={'body1'}>{staffMember.title}</Typography>}
            {staffMember.phoneNumber && (
              <Typography variant={'body1'} color={'textSecondary'}>
                {staffMember?.phoneNumber}
              </Typography>
            )}
            {sendSms && openPosition?.state === StaffShiftState.ApprovedByHiringManager && (
              <Tooltip content={error ? error : tooltip} delay={200}>
                <Box onClick={e => e.stopPropagation()}>
                  <DeliveryStatus status={disabledSmsButton ? null : smsStatus} working={disabledSmsButton} />
                  <LinkButton onClick={send} disabled={loadingP || loadingR || disabledSmsButton}>
                    Send SMS Invite
                    <Count>{`[ ${smsCount || 0} ]`}</Count>
                  </LinkButton>
                </Box>
              </Tooltip>
            )}
          </Box>
        </Box>
      )}
    </>
  );
});

export const getStatusState = (state: StaffShiftState, date: string) => {
  const isBeforeDate = isBefore(date, subDays(new Date(), 1));

  switch (state) {
    case StaffShiftState.CreatedOpenPosition:
      return isBeforeDate ? 'EXPIRED' : 'OPEN';
    case StaffShiftState.Opened:
      return isBeforeDate ? 'EXPIRED' : 'OPEN';
    case StaffShiftState.RejectedByHiringManager:
      return isBeforeDate ? 'EXPIRED' : 'OPEN';
    case StaffShiftState.BookOpenPosition:
      return isBeforeDate ? 'EXPIRED' : 'REQUESTED';
    case StaffShiftState.ApprovedByHiringManager:
      return isBeforeDate ? 'EXPIRED' : 'APPROVED';
    case StaffShiftState.ApprovedByStaffMember:
      return 'ACCEPTED';
    case StaffShiftState.Deleted:
      return 'DELETED';
    default:
      return 'UNKNOWN';
  }
};

const OpenStatus = () => (
  <Chip
    label="OPEN POSITION"
    color="primary"
    variant="outlined"
    icon={
      <PersonAdd
        fontSize="small"
        style={{ color: 'rgb(0, 167, 247)', filter: `drop-shadow(0 0 8px rgb(0, 167, 247))` }}
      />
    }
    style={{ border: 'none', textShadow: '0 0 8px rgb(0, 167, 247)' }}
  />
);

export const getStatusStateChip = (state: StaffShiftState, date: string) => {
  const isBeforeDate = isBefore(date, subDays(new Date(), 1));

  switch (state) {
    case StaffShiftState.CreatedOpenPosition:
      return isBeforeDate ? (
        <Chip label="EXPIRED" icon={<HourglassEmpty />} style={{ background: 'transparent' }} disabled />
      ) : (
        <OpenStatus />
      );
    case StaffShiftState.Opened:
      return isBeforeDate ? (
        <Chip label="EXPIRED" icon={<HourglassEmpty />} style={{ background: 'transparent' }} disabled />
      ) : (
        <OpenStatus />
      );
    case StaffShiftState.RejectedByHiringManager:
      return isBeforeDate ? (
        <Chip label="EXPIRED" icon={<HourglassEmpty />} style={{ background: 'transparent' }} disabled />
      ) : (
        <OpenStatus />
      );
    case StaffShiftState.BookOpenPosition:
      return isBeforeDate ? (
        <Chip label="EXPIRED" icon={<HourglassEmpty />} style={{ background: 'transparent' }} disabled />
      ) : (
        <Chip
          label="REQUESTED"
          variant="outlined"
          icon={<HowToRegTwoTone fontSize="small" style={{ color: '#fff' }} />}
          style={{ border: 'none' }}
        />
      );
    case StaffShiftState.ApprovedByHiringManager:
      return isBeforeDate ? (
        <Chip label="EXPIRED" icon={<HourglassEmpty />} style={{ background: 'transparent' }} disabled />
      ) : (
        <Chip
          style={{ background: 'transparent', color: '#fff' }}
          icon={<CheckIcon style={{ color: '#fff' }} fontSize="small" />}
          label="APPROVED"
        />
      );
    case StaffShiftState.ApprovedByStaffMember:
      return (
        <Chip
          label="ACCEPTED"
          style={{ background: 'transparent', color: '#18aa4a' }}
          icon={<DoneAll fontSize="small" style={{ color: '#18aa4a' }} />}
        />
      );
    case StaffShiftState.Deleted:
      return <Chip icon={<DeleteOutlineTwoTone />} style={{ background: 'transparent' }} label="DELETED" disabled />;
    default:
      return <Chip label="UNKNOWN" />;
  }
};

export const getStateContent = (
  logs: StaffShiftLog[],
  staffHospitalName: string | undefined,
  state: StaffShiftState,
  date: string
) => {
  const isBeforeDate = isBefore(date, subDays(new Date(), 1));
  const lastEvent: StaffShiftLog | undefined = getLastShiftLog(logs, state);

  switch (state) {
    case StaffShiftState.CreatedOpenPosition || StaffShiftState.Opened:
      return isBeforeDate ? undefined : (
        <>
          {lastEvent?.at && (
            <>
              <Typography variant={'body1'} color={'textSecondary'}>
                Posted on {format(ZonedDateTime.parse(lastEvent?.at).toLocalDateTime().toString(), 'MMM D, YYYY')}
              </Typography>
              <Typography variant={'body1'} color={'textSecondary'}>
                At {format(ZonedDateTime.parse(lastEvent?.at).toLocalDateTime().toString(), 'HH:mm')}
              </Typography>
            </>
          )}
        </>
      );
    case StaffShiftState.BookOpenPosition:
      return isBeforeDate ? undefined : (
        <>
          {staffHospitalName && <Typography variant={'body1'}>{staffHospitalName}</Typography>}
          {lastEvent?.userName && <Typography variant={'body1'}>{lastEvent?.userName}</Typography>}
          {!!lastEvent?.userPhoneNumber && (
            <Typography variant={'body1'} color={'textSecondary'}>
              {lastEvent?.userPhoneNumber}
            </Typography>
          )}
          {lastEvent?.at && (
            <Typography variant={'body1'} color={'textSecondary'}>
              {format(ZonedDateTime.parse(lastEvent?.at).toLocalDateTime().toString(), 'MMM D, YYYY')} at{' '}
              {format(ZonedDateTime.parse(lastEvent?.at).toLocalDateTime().toString(), 'HH:mm')}
            </Typography>
          )}
        </>
      );
    case StaffShiftState.ApprovedByHiringManager:
      return isBeforeDate ? undefined : (
        <>
          <Typography variant={'body1'}>By {lastEvent?.userName}</Typography>
          {lastEvent?.at && (
            <Typography variant={'body1'} color={'textSecondary'}>
              {format(ZonedDateTime.parse(lastEvent?.at).toLocalDateTime().toString(), 'MMM D, YYYY')} at{' '}
              {format(ZonedDateTime.parse(lastEvent?.at).toLocalDateTime().toString(), 'HH:mm')}
            </Typography>
          )}
        </>
      );
    case StaffShiftState.ApprovedByStaffMember:
      return (
        <>
          <Typography variant={'body1'}>By {lastEvent?.userName}</Typography>
          {lastEvent?.at && (
            <Typography variant={'body1'} color={'textSecondary'}>
              {format(ZonedDateTime.parse(lastEvent?.at).toLocalDateTime().toString(), 'MMM D, YYYY')} at{' '}
              {format(ZonedDateTime.parse(lastEvent?.at).toLocalDateTime().toString(), 'HH:mm')}
            </Typography>
          )}
        </>
      );
    case StaffShiftState.Deleted:
      return (
        <>
          <Typography variant={'body1'}>By {lastEvent?.userName}</Typography>
          {lastEvent?.at && (
            <Typography variant={'body1'} color={'textSecondary'}>
              {format(ZonedDateTime.parse(lastEvent?.at).toLocalDateTime().toString(), 'MMM D, YYYY')} at{' '}
              {format(ZonedDateTime.parse(lastEvent?.at).toLocalDateTime().toString(), 'HH:mm')}
            </Typography>
          )}
        </>
      );
    default:
      return undefined;
  }
};

const useStyles = makeStyles(theme =>
  createStyles({
    staffList: {
      height: 400,
      minWidth: 300,
    },
    staffListItem: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    assignedStaffBox: {
      display: 'flex',
      flexDirection: 'row',
    },
    approve: {
      marginLeft: theme.spacing(2),
      color: theme.palette.success.main,
    },
    reject: {
      marginLeft: theme.spacing(1),
      color: theme.palette.warning.main,
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
    transparentChip: {
      backgroundColor: 'transparent',
    },
    statuses: {
      booked: {
        backgroundColor: theme.palette.success.main,
      },
    },
  })
);

export default StaffShiftAction;
