import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Calendar } from 'react-date-range';
import Dropdown from '../../../core/Dropdown';
import responsive from '../../../../se/utilities/responsive';

import { list } from '../../../../graph/physicians';
import graphqlSelectInput from '../../../inputs/graphqlSelectInput';
import Button from '../../../../se/components/Button';

const Filters = styled.div`
  justify-content: flex-end;
  align-items: stretch !important;
  flex: 1;

  > div {
    height: 100%;
  }

  ${responsive.md.andSmaller`
    align-items: center !important;
    margin-top: ${props => (props.noSpacingStyles ? '0' : '.75em')};

    > div {
      width: 100%;

      > button {
        width: 100%;
      }
    }
  `};

  #select {
    width: 12em;
    ${responsive.md.andSmaller`
      > div {
        border: none;
      }
    `}
  }

  > :nth-child(odd) {
    margin-right: ${props => (props.noSpacingStyles ? '0' : '1em')};
  }
`;

export const PhysicianDropdown = graphqlSelectInput({
  entityName: 'Physician',
  placeholder: 'Physician',
  graphqlList: list,
  sortOptions: true,
  getOptionValue: option => (option ? parseInt(option.value, 10) : undefined),
});

export default ({
  date,
  physician,
  withoutPhysician,
  onChange,
  noSpacingStyles,
  buttonFormat,
  style,
  color,
  textColor,
}) => {
  const dropdownContent = ({ close }) => {
    const handleChange = date => {
      close();
      onChange({ date: format(date, 'YYYY-MM-DD') });
    };

    return () => <Calendar date={date} onChange={handleChange} />;
  };

  const onPhysicianChange = value => onChange({ physician: value });

  return (
    <Filters noSpacingStyles={noSpacingStyles} style={style}>
      {!withoutPhysician && <PhysicianDropdown onChange={onPhysicianChange} value={physician} menuWidthFollowContent />}
      <Dropdown content={dropdownContent}>
        <Button style={{ flex: 1, color: textColor, backgroundColor: color }}>
          {format(date, buttonFormat || 'MMMM D, YYYY')}
        </Button>
      </Dropdown>
    </Filters>
  );
};
