const groupBy = (items: any[], key: (item: any) => any) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [key(item)]: [...(result[key(item)] || []), item],
    }),
    {}
  );

export default groupBy;
