import { createContext, useContext } from 'react';
import { QuestionnaireAndAnswers } from '../../../../types/Questionnaire';

export const PreOpChartsContext = createContext<QuestionnaireAndAnswers[] | null>(null);

export const usePreOpCharts = () => {
  const value = useContext(PreOpChartsContext);

  if (value === null) {
    throw new Error('usePreOpCharts must be used within a PreOpChartsContext');
  }

  return value;
};
