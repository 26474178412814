import differenceInHours from 'date-fns/difference_in_hours';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import differenceInSeconds from 'date-fns/difference_in_seconds';

export const formatDuration = (from, to, noSeconds = false) => {
  const hoursTotal = differenceInHours(to, from);
  const minutesTotal = differenceInMinutes(to, from);
  const secondsTotal = differenceInSeconds(to, from);

  const hours = hoursTotal;
  const minutes = minutesTotal - hoursTotal * 60;
  const seconds = secondsTotal - minutesTotal * 60;

  if (noSeconds) {
    if (hours > 0) {
      return `${hours}h ${minutes}m`;
    } else {
      return `${hours}h ${minutes}m`;
    }
  } else {
    if (hours > 0) {
      return `${hours}h ${minutes}m ${seconds}s`;
    } else {
      return `${hours}h ${minutes}m ${seconds}s`;
    }
  }
};
