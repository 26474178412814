import React from 'react';
import styled, { withTheme } from 'styled-components';
import { branch, compose, renderComponent, withProps, withState } from 'recompose';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import withSize from '../../../hocs/withSize';
import Color from 'color';
import get from 'lodash/get';
import { defaultTo } from 'lodash';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1em;
  color: black;
`;

const CustomTooltipContainer = styled.div`
  background-color: white;
  color: black;
  padding: 1em;
`;

const Dot = styled.div`
  width: 0.85em;
  height: 0.85em;
  border-radius: 50%;
  margin-right: 0.5em;
  background: ${props => props.color};
`;

export const GraphContainer = styled.div`
  align-self: stretch;
`;

const CustomTooltip = ({ payload, label, labelStyle }) => (
  <CustomTooltipContainer>
    <label style={labelStyle}>{label}</label>
    {defaultTo(payload, []).map(({ dataKey, name, unit, value, fill }) => (
      <Row key={dataKey}>
        <Dot color={fill} />
        <p>{`${name} : ${value} ${unit}`}</p>
      </Row>
    ))}
  </CustomTooltipContainer>
);

const Graph = ({ value, width, height, labels, colors, setMouse, mouse, ratio, bars }) => (
  <GraphContainer onMouseEnter={_ => setMouse({ x: _.clientX, y: _.clientY })}>
    <BarChart width={width} height={height || Math.floor(width * (ratio || 0.75))} data={value} margin={{ left: -30 }}>
      <YAxis
        tick={{
          stroke: colors.stroke.string(),
          fill: colors.stroke.string(),
          fontSize: '0.7em',
          strokeWidth: '0.1',
        }}
        axisLine={false}
        tickLine={false}
      />
      <CartesianGrid stroke={colors.stroke.alpha(0.2).string()} strokeArray="1 1" />
      <XAxis
        dataKey="date"
        tickFormatter={p => get(labels, `[${p}][0].name`, '')}
        tick={{
          stroke: colors.stroke.string(),
          fill: colors.stroke.string(),
          fontSize: '0.8em',
          strokeWidth: '0.1',
        }}
        tickLine={false}
        axisLine={false}
      />
      {window.innerWidth > 500 && (
        <Tooltip
          content={<CustomTooltip />}
          cursor={{ fill: colors.bar.darken(0.6).string() }}
          wrapperStyle={{ zIndex: 1000, position: 'fixed', x: `${mouse.x}px`, top: `${mouse.y / 1.5}px` }}
        />
      )}
      {bars.map(({ id, color, title, unit }) => (
        <Bar
          key={id}
          name={title}
          unit={unit}
          dataKey={id}
          barSize={width / value.length / 6}
          fill={color}
          stackId={'a'}
        />
      ))}
    </BarChart>
  </GraphContainer>
);

const colors = ({ theme }) => ({
  colors: {
    stroke: theme.border.color.default.lighten(0.4),
    bar: Color('#00a4fa'),
  },
});

export default compose(
  withTheme,
  withSize,
  withProps(colors),
  withState('mouse', 'setMouse', { x: 0, y: 0 }),
  branch(props => !Number.isFinite(props.width), renderComponent(GraphContainer))
)(Graph);
