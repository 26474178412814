import ExpandableText from '../../core/ExpandableText';
import React from 'react';

export const nameColumn = {
  title: 'Name',
  key: 'name',
  lens: item => JSON.parse(item.values || '{}')?.name,
  Component: props => <ExpandableText text={props.data} />,
};

export const typeColumn = {
  title: 'Field Type',
  key: 'type',
  lens: item => JSON.parse(item.values || '{}')?.type,
  Component: props => <ExpandableText text={props.data} />,
};

export const pathColumn = {
  title: 'Path',
  key: 'path',
  lens: item => JSON.parse(item.values || '{}')?.path,
  Component: props => <ExpandableText text={props.data} />,
};

export const unitColumn = {
  title: 'Unit',
  key: 'unit',
  lens: item => JSON.parse(item.values || '{}')?.unit,
  Component: props => <ExpandableText text={props.data} />,
};

export const minColumn = {
  title: 'Min',
  key: 'min',
  lens: item => JSON.parse(item.values || '{}')?.min,
  Component: props => <ExpandableText text={props.data} />,
};

export const maxColumn = {
  title: 'Max',
  key: 'max',
  lens: item => JSON.parse(item.values || '{}')?.max,
  style: { maxWidth: '50em' },
  Component: props => <ExpandableText text={props.data} />,
};

export const listColumns = [nameColumn, typeColumn, pathColumn, unitColumn, minColumn, maxColumn];

export const viewColumns = [nameColumn, typeColumn, pathColumn, unitColumn, minColumn, maxColumn];
