import React, { ComponentClass, createElement, forwardRef, FunctionComponent } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Value } from '../predefinedForms/ValueContext';
import { RootFrame } from '../DatabaseHtmlForm';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import NoPermissionText from '../../../NoPermissionText';
import useHasAccessRight from '../../../../hooks/useHasAccessRight';
import makeStyles from '@material-ui/core/styles/makeStyles';

const ErrorPage = () => (
  <div
    className="page"
    style={{
      display: 'grid',
      alignContent: 'center',
      justifyItems: 'center',
    }}
  >
    <h2>Unable to render this page.</h2>
  </div>
);

const useStyles = makeStyles(theme => ({
  content: {
    position: 'relative',
  },
  blurred: {
    backgroundColor: 'white',
    color: theme.palette.background.default,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 100,
  },
}));

const combinedForm = <P extends {}>(forms: Array<FunctionComponent<P> | ComponentClass<P> | string>) => {
  const Component = forwardRef<HTMLElement, P>((props, ref) => {
    const {
      hospitalId,
      formName,
      hospitalName,
      defaultValue,
      value,
      setValue,
      signature,
      questionnaire,
      questionnaireAnswers,
      lastPreOpCompletedEvent,
      procedureId,
      showQRCode,
      isTablet,
    } = props as any;

    const classes = useStyles();
    const hasAccessRight = useHasAccessRight();
    const isAllowedToView = hasAccessRight('patient.view');

    return (
      <Box className={clsx({ [classes.content]: !isAllowedToView })}>
        {!isAllowedToView && (
          <Box className={clsx({ [classes.blurred]: !isAllowedToView })}>
            <NoPermissionText withText={true} style={{ top: '50%', left: '25%' }} />
          </Box>
        )}
        <Value.Provider value={{ defaultValue, value, setValue, questionnaire, questionnaireAnswers }}>
          {forms.map((form, i) => (
            <Value.Provider key={i} value={{ defaultValue, value, setValue, questionnaire, questionnaireAnswers }}>
              <ErrorBoundary FallbackComponent={ErrorPage}>
                {createElement(form, {
                  ...props,
                  formName: formName,
                  pageNumber: i + 1,
                } as any)}
              </ErrorBoundary>
            </Value.Provider>
          ))}
        </Value.Provider>
      </Box>
    );
  });

  Component.displayName = 'CombinedForm';

  return Component as any as FunctionComponent<P> | ComponentClass<P> | string;
};

export default combinedForm;
