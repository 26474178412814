import { Theme } from '@material-ui/core';

export default function FormHelperText(theme: Theme) {
  return {
    MuiFormHelperText: {
      root: {
        fontSize: '1rem',
        color: theme.palette.text.secondary,
      },
    },
  };
}
