export const addItem = key => value => state => {
  const list = [...state[key]];
  list.push(value);
  return { ...state, [key]: list };
};

export const removeItem = key => index => state => {
  const list = [...state[key]];
  list.splice(index, 1);
  return { ...state, [key]: list };
};
