import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import LinkButton from '../../../se/components/LinkButton';
import { Contacts } from '@material-ui/icons';

const Container = styled.div`
  margin-bottom: 2em;
`;

const BraceletContainer = styled.div`
  padding: 1em 0em;
  display: flex;
  flex-wrap: wrap;
  i {
    font-size: 0.8em;
  }
`;

const BraceletTag = styled.span`
  padding: 0.25em 0.5em;
  margin-right: 0.25em;
  margin-top: 0.5em;
  border: 1px solid ${p => p.theme.button.primary.backgroundColor.string()};
`;

const exists = x => !!x;
class BraceletOverview extends Component {
  render() {
    return (
      <Container>
        <BraceletContainer>
          {this.props.bracelets.length === 0 ? (
            <p style={{ fontWeight: 700 }}>
              <Contacts fontSize={'small'} />
              Add bracelets to get started.
            </p>
          ) : (
            <Fragment>
              {this.props.bracelets.filter(exists).map((bracelet, i) => (
                <BraceletTag key={i}>{bracelet}</BraceletTag>
              ))}
            </Fragment>
          )}
        </BraceletContainer>
        <LinkButton onClick={this.props.openModal} disabled={!this.props.canEdit}>
          Manage bracelets
        </LinkButton>
      </Container>
    );
  }
}

export default BraceletOverview;
