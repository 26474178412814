import { QuestionnaireType } from '../../../../types/Questionnaire';
import { Patient } from '../../../../types/Patient';

export const getUrlPart = (questionnaireType: QuestionnaireType) => {
  switch (questionnaireType) {
    case QuestionnaireType.PreOpChart:
      return 'preop';
    case QuestionnaireType.OrChart:
      return 'or';
    case QuestionnaireType.PacuChart:
      return 'pacu';
    case QuestionnaireType.PhysicianChart:
      return 'physician';
    case QuestionnaireType.AnesthesiologistPreOpChart:
      return 'anesthesiologist-preop';
    case QuestionnaireType.AnesthesiologistOrChart:
      return 'anesthesiologist-or';
    case QuestionnaireType.AnesthesiologistPacuChart:
      return 'anesthesiologist-pacu';
    default:
      return 'preop';
  }
};

export const getChartingName = (questionnaireType: QuestionnaireType) => {
  switch (questionnaireType) {
    case QuestionnaireType.PreOpChart:
      return 'PreOp';
    case QuestionnaireType.OrChart:
      return 'OR';
    case QuestionnaireType.PacuChart:
      return 'PACU';
    case QuestionnaireType.PhysicianChart:
      return 'Physician';
    case QuestionnaireType.AnesthesiologistPreOpChart:
      return 'Anesthesiologist';
    case QuestionnaireType.AnesthesiologistOrChart:
      return 'Anesthesiologist';
    case QuestionnaireType.AnesthesiologistPacuChart:
      return 'Anesthesiologist';
    default:
      return 'PreOp';
  }
};

export const getAllChartingData = (patient?: Patient) => {
  return [
    ...(patient?.procedure?.preOpCharts || []),
    ...(patient?.procedure?.orCharts || []),
    ...(patient?.procedure?.pacuCharts || []),
    ...(patient?.procedure?.physicianCharts || []),
    ...(patient?.procedure?.anesthesiologistPreOpCharts || []),
    ...(patient?.procedure?.anesthesiologistOrCharts || []),
    ...(patient?.procedure?.anesthesiologistPacuCharts || []),
  ];
};

export const getChartingData = (questionnaireType: QuestionnaireType, patient?: Patient) => {
  switch (questionnaireType) {
    case QuestionnaireType.PreOpChart:
      return patient?.procedure?.preOpCharts || [];
    case QuestionnaireType.OrChart:
      return patient?.procedure?.orCharts || [];
    case QuestionnaireType.PacuChart:
      return patient?.procedure?.pacuCharts || [];
    case QuestionnaireType.PhysicianChart:
      return patient?.procedure?.physicianCharts || [];
    case QuestionnaireType.AnesthesiologistPreOpChart:
      return patient?.procedure?.anesthesiologistPreOpCharts || [];
    case QuestionnaireType.AnesthesiologistOrChart:
      return patient?.procedure?.anesthesiologistOrCharts || [];
    case QuestionnaireType.AnesthesiologistPacuChart:
      return patient?.procedure?.anesthesiologistPacuCharts || [];
    default:
      return [];
  }
};

export const getChartingDataFrom = (questionnaireType: QuestionnaireType, data?: any) => {
  switch (questionnaireType) {
    case QuestionnaireType.PreOpChart:
      return data?.preOpCharts || [];
    case QuestionnaireType.OrChart:
      return data?.orCharts || [];
    case QuestionnaireType.PacuChart:
      return data?.pacuCharts || [];
    case QuestionnaireType.PhysicianChart:
      return data?.physicianCharts || [];
    case QuestionnaireType.AnesthesiologistPreOpChart:
      return data?.anesthesiologistPreOpCharts || [];
    case QuestionnaireType.AnesthesiologistOrChart:
      return data?.anesthesiologistOrCharts || [];
    case QuestionnaireType.AnesthesiologistPacuChart:
      return data?.anesthesiologistPacuCharts || [];
    default:
      return [];
  }
};

export const getChartingDatQueryName = (questionnaireType: QuestionnaireType) => {
  switch (questionnaireType) {
    case QuestionnaireType.PreOpChart:
      return 'preOpCharts';
    case QuestionnaireType.OrChart:
      return 'orCharts';
    case QuestionnaireType.PacuChart:
      return 'pacuCharts';
    case QuestionnaireType.PhysicianChart:
      return 'physicianCharts';
    case QuestionnaireType.AnesthesiologistPreOpChart:
      return 'anesthesiologistPreOpCharts';
    case QuestionnaireType.AnesthesiologistOrChart:
      return 'anesthesiologistOrCharts';
    case QuestionnaireType.AnesthesiologistPacuChart:
      return 'anesthesiologistPacuCharts';
    default:
      return 'preOpCharts';
  }
};
