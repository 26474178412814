import React from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import Modal from '../../se/components/Modal';
import withRoomList from './components/withRoomList';
import JourneyRecorder from './components/JourneyRecorder';
import BraceletOverview from './components/BraceletOverview';
import BraceletEntry from './components/BraceletEntry';
import {
  withEnterRoomInPositioningSessionQuery,
  withExitRoomInPositioningSessionQuery,
  withStartPositionSessionQuery,
  withStopPositionSessionQuery,
} from './graphql/withAllTrackerMutations';

import { insertNewBracelet, removeBracelet, updateBracelet } from './helpers/braceletListOperations';
import LoadingIndicator from '../core/LoadingIndicator';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

class Tracker extends React.Component {
  state = {
    isModalOpen: false,
    bracelets: [],
    selectedRoom: undefined,
    isRecording: undefined,
    sessionId: undefined,
  };

  closeModal = () => this.setState({ isModalOpen: false });

  openModal = () => {
    this.setState({ isModalOpen: true });
    if (this.state.bracelets.length === 0) {
      this.setState({ bracelets: [''] });
    }
  };

  startRecording = async () => {
    this.setState({ isRecording: true });
    const { data = {} } = await this.props.startPositionSession({
      variables: {
        bracelets: this.state.bracelets,
      },
    });
    const sessionId = data.startPositioningSession;
    this.setState({ sessionId });
  };

  stopRecording = async () => {
    this.setState({
      isRecording: false,
      selectedRoom: undefined,
    });
    await this.props.stopPositionSession({
      variables: {
        bracelets: this.state.bracelets,
        sessionId: this.state.sessionId,
      },
    });
  };

  selectRoom = async selectedRoom => {
    if (selectedRoom === this.state.selectedRoom) {
      this.setState({ selectedRoom: undefined });
      await this.props.exitRoomInPositioningSession({
        variables: {
          bracelets: this.state.bracelets,
          sessionId: this.state.sessionId,
          roomId: selectedRoom,
        },
      });
    } else {
      this.setState({ selectedRoom });
      await this.props.enterRoomInPositioningSession({
        variables: {
          bracelets: this.state.bracelets,
          sessionId: this.state.sessionId,
          roomId: selectedRoom,
        },
      });
    }
  };
  exitRoom = async roomId => {
    await this.props.exitRoomInPositioningSession({
      variables: {
        bracelets: this.state.bracelets,
        sessionId: this.state.sessionId,
        roomId,
      },
    });
  };

  enterRoom = async roomId => {
    await this.props.enterRoomInPositioningSession({
      variables: {
        bracelets: this.state.bracelets,
        sessionId: this.state.sessionId,
        roomId,
      },
    });
  };

  selectRoom = async selectedRoom => {
    const previousRoom = this.state.selectedRoom;
    if (selectedRoom === previousRoom) {
      this.setState({ selectedRoom: undefined });
      await this.exitRoom(selectedRoom);
    } else {
      this.setState({ selectedRoom });
      if (previousRoom) {
        await this.exitRoom(previousRoom);
      }
      await this.enterRoom(selectedRoom);
    }
  };

  addBracelet = () => this.setState(insertNewBracelet);

  removeBracelet = index => this.setState(removeBracelet(index));

  updateBracelet = ({ value, index }) => this.setState(updateBracelet(value, index));

  render() {
    if (this.props.rooms.loading) {
      return <LoadingIndicator />;
    }

    if (this.props.rooms.error) {
      return <p>{JSON.stringify(this.props.rooms.error)}</p>;
    }

    return (
      <Container>
        {this.state.isModalOpen && (
          <Modal title="Bracelets" onClose={this.closeModal} withRouter={false}>
            <BraceletEntry
              bracelets={this.state.bracelets}
              onAdd={this.addBracelet}
              onRemove={this.removeBracelet}
              onChange={this.updateBracelet}
              closeModal={this.closeModal}
            />
          </Modal>
        )}
        <BraceletOverview
          openModal={this.openModal}
          bracelets={this.state.bracelets}
          canEdit={!this.state.isRecording}
        />
        <JourneyRecorder
          rooms={this.props.rooms.data.rooms}
          selectRoom={this.selectRoom}
          selectedRoom={this.state.selectedRoom}
          isRecording={this.state.isRecording}
          startRecording={this.startRecording}
          stopRecording={this.stopRecording}
          canRecord={this.state.bracelets.length > 0}
        />
      </Container>
    );
  }
}

export default compose(
  withRoomList,
  withStartPositionSessionQuery,
  withStopPositionSessionQuery,
  withEnterRoomInPositioningSessionQuery,
  withExitRoomInPositioningSessionQuery
)(Tracker);
