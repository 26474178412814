import { gql } from '@apollo/client';

export const hasConsent = gql`
  query hasConsent($id: Long!) {
    hasConsent(id: $id)
  }
`;

export const hasSignature = gql`
  query hasSignature($id: Long!) {
    hasSignature(id: $id)
  }
`;

export const addSignature = gql`
  mutation addSignature($id: Long!, $signature: String!) {
    addSignature(id: $id, signature: $signature)
  }
`;

export const updateSignatureConsent = gql`
  mutation updateSignatureConsent($id: Long!, $mdSignature: Boolean!) {
    updateSignatureConsent(id: $id, mdSignature: $mdSignature)
  }
`;

export const resendPhysicianSignatureInvite = gql`
  mutation resendPhysicianSignatureInvite($physician: Long!) {
    resendPhysicianSignatureInvite(physician: $physician)
  }
`;

export default {
  hasConsent,
  hasSignature,
  addSignature,
  updateSignatureConsent,
};
