import React, { Fragment } from 'react';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import HtmlInput from '../html/Input';
import InputError from '../errors/InputError';

const isModifierKey = event => {
  const key = event.keyCode;
  return (
    event.shiftKey === true ||
    key === 35 ||
    key === 36 || // Allow Shift, Home, End
    key === 9 ||
    key === 13 ||
    key === 46 ||
    key === 188 || // Allow Backspace (key=8), Tab, Enter, Delete, ','
    (key > 36 && key < 41) || // Allow left, up, right, down
    // Allow Ctrl/Command + A,C,V,X,Z
    ((event.ctrlKey === true || event.metaKey === true) &&
      (key === 65 || key === 67 || key === 86 || key === 88 || key === 90 || key === 91))
  );
};

export const processPhoneNumbersString = value => {
  const inputPhoneNumberArray = value.split(',').map(number => {
    try {
      return parsePhoneNumber(number, 'US');
    } catch (e) {
      return {
        number,
        nationalNumber: number,
        countryCallingCode: number === '+' ? '' : '1',
      };
    }
  });

  const plainNumbers = inputPhoneNumberArray
    .map(_ => `${_.countryCallingCode === '' ? '' : '+'}${_.countryCallingCode} ${_.nationalNumber}`)
    .join(',');
  const nationalNumbers = inputPhoneNumberArray
    .map(_ => (_.countryCallingCode === '1' ? _.nationalNumber : _.number))
    .join(',');
  return { plainNumbers, nationalNumbers };
};

export const formatPhoneNumber = data => {
  let generatedOutput = '';
  if (data && data.length > 0) {
    const inputPhoneNumberArray = data.split(',');

    for (let i = 0; i < inputPhoneNumberArray.length; i++) {
      generatedOutput = generatedOutput.concat(new AsYouType('US').input(inputPhoneNumberArray[i]));
      if (i < inputPhoneNumberArray.length - 1) {
        generatedOutput = generatedOutput.concat(', ');
      }
    }
  }
  return generatedOutput;
};
export const phoneNumberFormatPipeline = value => formatPhoneNumber(processPhoneNumbersString(value).nationalNumbers);

export default class PhoneInput extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      value: '',
      displayValue: props.value
        ? // format initial numbers as if they were just copy/pasted into the field
          phoneNumberFormatPipeline(props.value)
        : undefined,
    };
  }

  processEvent = e => processPhoneNumbersString(e.target.value);

  handleChange = e => {
    const { nationalNumbers, plainNumbers } = this.processEvent(e);
    this.setState({ displayValue: formatPhoneNumber(nationalNumbers) });

    this.props.onChange(plainNumbers);
  };

  handleKeyDown = e => {
    if (
      e.keyCode === 8 &&
      e.target.selectionStart !== e.target.value.length &&
      e.target.selectionStart === e.target.selectionEnd
    ) {
      e.preventDefault();
    }
  };

  handleOnKeyUp = e => {
    if (e.target.selectionStart !== e.target.selectionEnd || isModifierKey(e)) {
      return;
    } else if (e.keyCode === 8 && e.target.selectionStart !== e.target.value.length) {
      e.target.setSelectionRange(e.target.selectionStart - 1, e.target.selectionStart);
      return;
    }

    const { nationalNumbers } = this.processEvent(e);
    const generatedOutput = formatPhoneNumber(nationalNumbers);
    const currentCursorPosition = e.target.selectionStart;
    let finalCursorPosition = currentCursorPosition;

    if (currentCursorPosition === nationalNumbers.length) {
      finalCursorPosition = generatedOutput.length;
    } else if (currentCursorPosition === nationalNumbers.lastIndexOf(',') + 2) {
      finalCursorPosition = finalCursorPosition + 1;
    }

    e.target.selectionStart = finalCursorPosition;
    e.target.selectionEnd = finalCursorPosition;
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        displayValue: this.props.value ? phoneNumberFormatPipeline(this.props.value) : '',
      });
    }
  }

  render() {
    const {
      Input = HtmlInput,
      Error = InputError,
      placeholder = '',
      isHidden,
      autoFocus,
      value,
      isDisabled,
      name = 'text',
      error,
      hasError,
      onKeyDown, // do not delete because of ...rest
      onChange, // do not delete because of ...rest
      ...rest
    } = this.props;
    return (
      <Fragment>
        <Input
          ref={this.myRef}
          id={name}
          type={isHidden ? 'hidden' : 'text'}
          name={name}
          autoComplete="on"
          autoCapitalize="off"
          autoCorrect="off"
          placeholder={placeholder}
          autoFocus={autoFocus || false}
          value={this.state.displayValue || ''}
          onChange={this.handleChange}
          onKeyUp={this.handleOnKeyUp}
          onKeyDown={this.handleKeyDown}
          disabled={isDisabled}
          hasError={hasError}
          {...rest}
        />
        <Error isVisible={hasError} id={name}>
          {hasError ? error : ''}
        </Error>
      </Fragment>
    );
  }
}
