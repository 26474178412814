import React, { ReactNode, useState } from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Divider, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import useId from '../../../form/questions/util/useId';

interface Group {
  id: string;
  name: ReactNode;
  addon?: ReactNode;
  contents: ReactNode;
}

interface Action {
  id: string;
  name: ReactNode;
  icon?: ReactNode;
  onClick: () => void;
}

interface GroupsProps {
  groups: Group[];
  actions?: Action[];
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const Groups = ({ groups, actions }: GroupsProps) => {
  const classes = useStyles();

  const baseId = useId();

  const [value, setValue] = useState<number>(0);

  return (
    <Box className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={3}>
          <List>
            {groups.map(({ id, name, addon }: Group, i: number) => (
              <ListItem
                button
                key={id}
                role="tab"
                id={`${baseId}-tab-${i}`}
                aria-controls={`${baseId}-tabpanel-${i}`}
                onClick={() => setValue(i)}
                selected={value === i}
              >
                <ListItemText primary={name} secondary={addon} />
              </ListItem>
            ))}
          </List>
          {actions && actions.length > 0 && (
            <>
              <Divider />
              <List>
                {actions.map(({ id, name, icon, onClick }) => (
                  <ListItem button key={id} onClick={onClick}>
                    {icon && <ListItemIcon>{icon}</ListItemIcon>}
                    <ListItemText primary={name} />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </Grid>

        <Grid item lg={9}>
          {groups.map(({ id, contents }, i) => (
            <Box
              key={id}
              role="tabpanel"
              id={`${id}-tabpanel-${i}`}
              aria-labelledby={`${id}-tab-${i}`}
              hidden={value !== i}
            >
              {value === i && <>{contents}</>}
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Groups;
