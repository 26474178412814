import { createContext, useContext } from 'react';
import { QuestionnaireAndAnswers, QuestionnaireType } from '../types/Questionnaire';
import { getChartingDataFrom } from '../components/pages/kiosk/charting/utils';

export const useChartingAnswers = (questionnaireType: QuestionnaireType, name: string): any => {
  const data = useContext(ChartingQuestionnairesContext);

  return JSON.parse(
    (
      (getChartingDataFrom(questionnaireType, data)) || []
    )?.find(e => e.questionnaire?.name === name)?.questionnaireExchange?.answers || '{}'
  );
};

const ChartingQuestionnairesContext = createContext<{
  preOpCharts: QuestionnaireAndAnswers[];
  orCharts: QuestionnaireAndAnswers[];
  pacuCharts: QuestionnaireAndAnswers[];
  physicianCharts: QuestionnaireAndAnswers[];
  anesthesiologistPreOpCharts: QuestionnaireAndAnswers[];
  anesthesiologistOrCharts: QuestionnaireAndAnswers[];
}>({
  preOpCharts: [],
  orCharts: [],
  pacuCharts: [],
  physicianCharts: [],
  anesthesiologistPreOpCharts: [],
  anesthesiologistOrCharts: [],
});

export default ChartingQuestionnairesContext;
