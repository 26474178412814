import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Icon } from '@material-ui/core';

const EmptyStateIllustration = styled.div<{ illustration: (() => string) | string }>`
  width: 16rem;
  height: 8rem;
  background: url(${props => props.illustration}) center center / contain no-repeat}`;

interface EntityStateProps {
  illustration: (() => string) | string;
  icon?: string;
  title: string;
  hint?: string;
  children?: ReactNode;
}

const EntityState: FC<EntityStateProps> = ({ illustration, icon, title, hint = '', children }) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    {illustration ? (
      <Box mb={4}>
        <EmptyStateIllustration illustration={illustration} />
      </Box>
    ) : null}
    {icon ? (
      <Box mb={1}>
        <Icon style={{ fontSize: '4rem' }} color="disabled">
          {icon}
        </Icon>
      </Box>
    ) : null}
    <Typography align="center" variant="h4" gutterBottom>
      {title}
    </Typography>
    {hint && (
      <Typography align="center" color="textSecondary">
        {hint}
      </Typography>
    )}
    {children}
  </Box>
);

export default EntityState;
