import React, { Fragment } from 'react';
import renderNode from './renderNode';
import { InnerField } from './Containers';
import { isNull, isUndefined } from '../../../../se/utilities/check';
import TextField from './TextField';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { withTheme } from '../../../../se/theme';

export default ({ ifYes, ifNo, value, question, important }) => {
  const response = get(value, 'yes');
  const yesNo = isArray(response) ? response[0] : response;
  return (
    <div style={{ borderTop: `1px solid ${withTheme(theme => theme.border.color.default.string())}` }}>
      <TextField
        label={question}
        value={yesNo ? 'Yes' : isUndefined(yesNo) || isNull(yesNo) ? '' : 'No'}
        highlightAnswer={important}
      />
      {!!response && (
        <Fragment>
          {ifYes && <InnerField>{renderNode(ifYes, { value: response[1] })}</InnerField>}
          {ifNo && <InnerField>{renderNode(ifNo, { value: response[1] })}</InnerField>}
        </Fragment>
      )}
    </div>
  );
};
