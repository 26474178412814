import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Checkbox,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  useTheme,
} from '@material-ui/core';
import { MatrixTableAnswer } from '../../../../types/Answer';
import { Field, MatrixTableQuestion } from '../../../../types/Question';
import { alpha, withStyles } from '@material-ui/core/styles';
import { RadioButtonUncheckedTwoTone } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useSave } from './SaveProvider';
import { useSlideIn } from '../../../../form/Form';

type TableProps = MatrixTableQuestion['props'] & {
  answer?: MatrixTableAnswer;
  onChange: (value: MatrixTableAnswer) => void;
}

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    root: {
      '&:nth-of-type(odd)': {
        // backgroundColor: theme.palette.action.hover,
      },
      '&:last-of-type': {
        // backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      borderRight: `2px solid ${theme.palette.divider}`,
      '&:last-of-type': {
        borderRight: 'none',
      },
    },
    body: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderRight: `2px solid ${theme.palette.divider}`,
      borderBottom: 'none',
      '&:first-of-type': {
        // borderRight: 0,
      },
      '&:last-of-type': {
        borderRight: 'none',
      },
    },
  }),
)(TableCell);

const isCompleted = (rows: Field[], value: MatrixTableAnswer) =>
  rows.every(row => (value?.[row?.value] && value?.[row?.value] !== null && value?.[row?.value] !== undefined));

const isCompletedRow = (key: string, value: MatrixTableAnswer) => (value?.[key] && value?.[key] !== null && value?.[key] !== undefined);

const MatrixTable = ({ rows, columns, answer, onChange }: TableProps) => {
  const { validationRequest } = useSave();
  const classes = useStyles();
  const slideIn = useSlideIn();
  const theme = useTheme();

  useEffect(() => {
    if (validationRequest) {
      if (!answer || !isCompleted(rows, answer)) {
        slideIn();
      }
    }
  }, [validationRequest, answer, slideIn]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <StyledTableRow className={classes.mainRow}>
            <StyledTableCell></StyledTableCell>
            {columns.map((col, i) => (<StyledTableCell key={`${col.value}-h-${i}`}>{col.label}</StyledTableCell>))}
          </StyledTableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, i) => (
            <StyledTableRow className={classes.mainRow}>
              <StyledTableCell
                padding="none"
                rowSpan={1}
                style={{
                  color: validationRequest
                    ? isCompletedRow(row?.value, answer)
                      ? 'inherit'
                      : theme.palette.error.light
                    : 'inherit',
                }}
              >
                {row?.label}
              </StyledTableCell>

              {columns.map(column => (
                <BlueCheckbox
                  checked={answer?.[row.value]?.column.value === column.value}
                  onChange={() =>
                    onChange({
                      ...answer,
                      [row?.value]: { row, column },
                    })
                  }
                />
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles(theme => ({
  mainRow: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}));

export default MatrixTable;

const BlueCheckbox = withStyles({
  root: {
    color: 'rgba(0, 0, 0, 0.54)',
    '&$checked': {
      color: 'rgb(25, 118, 210)',
    },
  },
  checked: {},
})(props => (
  <StyledTableCell style={{ textAlign: 'center' }}>
    <Checkbox
      icon={<RadioButtonUncheckedTwoTone color="primary"/>}
      checkedIcon={<CheckCircleIcon color="primary"/>}
      {...props}
      style={{ padding: 0 }}
    />
  </StyledTableCell>
));
