import React, { useEffect, useState, useCallback } from 'react';
import tippy from 'tippy.js';

// TODO replace this component with Material UI tooltip

export default ({ children, content, delay }) => {
  const [instance, setInstance] = useState(undefined);

  const attachCallback = useCallback(
    node => {
      if (node !== null && content && !instance) {
        setInstance(
          tippy(node, {
            content: content,
            allowHTML: true,
            delay,
          })
        );
      }
    },
    [content, instance, setInstance, delay]
  );

  useEffect(() => {
    if (content && instance) {
      instance.setContent(content);
    }
  }, [content, instance]);

  return <span ref={attachCallback}>{children}</span>;
};
