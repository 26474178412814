import { Theme } from '@material-ui/core';

export default function StepConnector(theme: Theme) {
  return {
    MuiStepLabel: {
      root: {
        ...theme.typography.button,
      },
      label: {
        ...theme.typography.button,
        '&$active': {
          ...theme.typography.button,
        },
      },
      icon: {
        width: 100,
      },
      iconContainer: {},
    },
  };
}
