import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment ProcedureTypeData on ProcedureType {
      id
      name
      expectedPreOpDuration
      expectedDuration
      expectedPacuDuration
    }
  `,
  base: gql`
    fragment ProcedureTypeBase on ProcedureType {
      id
      name
    }
  `,
};

export const list = gql`
  query procedureTypes {
    procedureTypes {
      ...ProcedureTypeData
    }
  }
  ${fragments.all}
`;

export const listLastHundred = gql`
  query lastProcedureTypes {
    procedureTypes: lastProcedureTypes {
      ...ProcedureTypeBase
    }
  }
  ${fragments.base}
`;

export const item = gql`
  query procedureType($id: Long!) {
    procedureType(id: $id) {
      ...ProcedureTypeData
    }
  }
  ${fragments.all}
`;

export const create = gql`
  mutation createProcedureType(
    $name: String!
    $expectedPreOpDuration: Long
    $expectedDuration: Long
    $expectedPacuDuration: Long
  ) {
    createProcedureType(
      name: $name
      expectedPreOpDuration: $expectedPreOpDuration
      expectedDuration: $expectedDuration
      expectedPacuDuration: $expectedPacuDuration
    ) {
      ...ProcedureTypeData
    }
  }
  ${fragments.all}
`;

export const update = gql`
  mutation editProcedureType(
    $id: Long!
    $name: String!
    $expectedPreOpDuration: Long
    $expectedDuration: Long
    $expectedPacuDuration: Long
  ) {
    editProcedureType(
      id: $id
      name: $name
      expectedPreOpDuration: $expectedPreOpDuration
      expectedDuration: $expectedDuration
      expectedPacuDuration: $expectedPacuDuration
    ) {
      ...ProcedureTypeData
    }
  }
  ${fragments.all}
`;

export const remove = gql`
  mutation deleteProcedureType($id: Long!) {
    deleteProcedureType(id: $id)
  }
`;

export const getGlobalAvgTimesConfiguration = gql`
  query averageTimesConfiguration {
    averageTimesConfiguration {
      expectedWRDuration
      expectedPreOpDuration
      expectedDuration
      expectedPacuDuration
    }
  }
`;

export const setGlobalAvgTimesConfig = gql`
  mutation updateAverageTimesConfiguration(
    $expectedWRDuration: Long
    $expectedPreOpDuration: Long
    $expectedDuration: Long
    $expectedPacuDuration: Long
  ) {
    updateAverageTimesConfiguration(
      expectedWRDuration: $expectedWRDuration
      expectedPreOpDuration: $expectedPreOpDuration
      expectedDuration: $expectedDuration
      expectedPacuDuration: $expectedPacuDuration
    )
  }
`;

export default {
  list,
  item,
  create,
  update,
  remove,
};
