import React, { LegacyRef, ReactNode, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { H3 } from '../../se/components/typography';
import responsive from '../../se/utilities/responsive';
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useSystemWideActorStatuses, { SystemWideActorStatuses } from './useSystemWideActorStatuses';
import Button from '@material-ui/core/Button';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

export const Title = styled(H3)`
  margin: 0;
  ${responsive.md.andSmaller`
    font-size: 1rem;
  `}
`;

interface ActorsProps extends ReturnType<typeof useSystemWideActorStatuses>, RouteComponentProps {}

const Actors = ({ match, location, history, ...outer }: ActorsProps) => {
  const theme = useTheme();

  return (
    <Switch>
      <Route exact path={match.path} render={props => <All {...props} {...outer} />} />
      <Route
        path={match.path + '/:hospitalId'}
        render={props => <Single {...props} {...outer} hospitalId={props.match.params.hospitalId} />}
      />
      <Route render={() => <Redirect to={match.path} />} />
    </Switch>
  );
};

export default Actors;

interface AllProps extends ActorsProps {}

const All = ({ loading, data, error }: AllProps) => (
  <Box>
    <Box mb={2}>
      <Breadcrumbs>
        <Link component={RouterLink} to="/dashboard" color="inherit">
          Dashboard
        </Link>
        <Typography color="textPrimary">Actors</Typography>
      </Breadcrumbs>
    </Box>
    <Paper>
      <Box p={2}>
        <Title>Actors</Title>
        {loading ? (
          <CircularProgress />
        ) : error || !data?.hospitals ? (
          <pre>{JSON.stringify(error)}</pre>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="column">
                    Organization
                  </TableCell>
                  <TableCell component="th" scope="column" style={{ lineHeight: 1 }}>
                    Snapshot Router
                  </TableCell>
                  <TableCell component="th" scope="column" style={{ lineHeight: 1 }}>
                    Enrollment Reader
                  </TableCell>
                  <TableCell component="th" scope="column" style={{ lineHeight: 1 }}>
                    Exit Gateway
                  </TableCell>
                  <TableCell component="th" scope="column" style={{ lineHeight: 1 }}>
                    Sensor Monitor
                  </TableCell>
                  <TableCell component="th" scope="column" style={{ lineHeight: 1 }}>
                    Subscription Registry
                  </TableCell>
                  <TableCell component="th" scope="column" style={{ lineHeight: 1 }}>
                    Gateway Router
                  </TableCell>
                  {/*<TableCell component="th" scope="column" style={{ lineHeight: 1 }}>*/}
                  {/*  More Info*/}
                  {/*</TableCell>*/}
                </TableRow>
              </TableHead>
              <TableBody>
                {data!.hospitals.map(hospital => (
                  <TableRow key={hospital.id}>
                    <TableCell component="th" scope="row">
                      <Link component={RouterLink} to={`/su/${hospital.id}`}>
                        {hospital.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {hospital.actorStatuses.snapshotRouter ? (
                        hospital.actorStatuses.snapshotRouter.status === 'Unresponsive' ? (
                          <Typography color="error">UNRESPONSIVE</Typography>
                        ) : (
                          <Typography>OK</Typography>
                        )
                      ) : (
                        <Typography>—</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {hospital.actorStatuses.enrollmentReader ? (
                        hospital.actorStatuses.enrollmentReader.status === 'Unresponsive' ? (
                          <Typography color="error">UNRESPONSIVE</Typography>
                        ) : (
                          <Typography>OK</Typography>
                        )
                      ) : (
                        <Typography>—</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {hospital.actorStatuses.exitGateway ? (
                        hospital.actorStatuses.exitGateway.status === 'Unresponsive' ? (
                          <Typography color="error">UNRESPONSIVE</Typography>
                        ) : (
                          <Typography>OK</Typography>
                        )
                      ) : (
                        <Typography>—</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {hospital.actorStatuses.sensorMonitor ? (
                        hospital.actorStatuses.sensorMonitor.status === 'Unresponsive' ? (
                          <Typography color="error">UNRESPONSIVE</Typography>
                        ) : (
                          <Typography>OK</Typography>
                        )
                      ) : (
                        <Typography>—</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {hospital.actorStatuses.subscriptionRegistry ? (
                        hospital.actorStatuses.subscriptionRegistry.status === 'Unresponsive' ? (
                          <Typography color="error">UNRESPONSIVE</Typography>
                        ) : (
                          <Typography>OK</Typography>
                        )
                      ) : (
                        <Typography>—</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {hospital.actorStatuses.gatewayRouter ? (
                        hospital.actorStatuses.gatewayRouter.status === 'Unresponsive' ? (
                          <Typography color="error">UNRESPONSIVE</Typography>
                        ) : (
                          <Typography>OK</Typography>
                        )
                      ) : (
                        <Typography>—</Typography>
                      )}
                    </TableCell>
                    {/*<TableCell>*/}
                    {/*  <Button component={RouterLink} to={`/dashboard/actors/${hospital.id}`} size="small">*/}
                    {/*    →*/}
                    {/*  </Button>*/}
                    {/*</TableCell>*/}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Paper>
  </Box>
);

interface SingleProps extends ActorsProps {
  hospitalId: string;
}

const Single = ({ loading, data, error, hospitalId }: SingleProps) => (
  <Box>
    <Box mb={2}>
      <Breadcrumbs>
        <Link component={RouterLink} to="/dashboard" color="inherit">
          Dashboard
        </Link>
        <Link component={RouterLink} to="/dashboard/Actors" color="inherit">
          Actors
        </Link>
        <Typography color="textPrimary">#{hospitalId}</Typography>
      </Breadcrumbs>
    </Box>
    <Paper>
      <Box p={2}>
        <Title>Actors #{hospitalId}</Title>
        {loading ? (
          <CircularProgress />
        ) : error || !data?.hospitals ? (
          <pre>{JSON.stringify(error)}</pre>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="column">
                    Organization
                  </TableCell>
                  <TableCell component="th" scope="column" style={{ lineHeight: 1 }}>
                    Snapshot Router
                  </TableCell>
                  <TableCell component="th" scope="column" style={{ lineHeight: 1 }}>
                    Enrollment Reader
                  </TableCell>
                  <TableCell component="th" scope="column" style={{ lineHeight: 1 }}>
                    Exit Gateway
                  </TableCell>
                  <TableCell component="th" scope="column" style={{ lineHeight: 1 }}>
                    Sensor Monitor
                  </TableCell>
                  <TableCell component="th" scope="column" style={{ lineHeight: 1 }}>
                    Subscription Registry
                  </TableCell>
                  <TableCell component="th" scope="column" style={{ lineHeight: 1 }}>
                    Gateway Router
                  </TableCell>
                  <TableCell component="th" scope="column" style={{ lineHeight: 1 }}>
                    More Info
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data!.hospitals.map(hospital => (
                  <TableRow key={hospital.id}>
                    <TableCell component="th" scope="row">
                      <Link component={RouterLink} to={`/su/${hospital.id}`}>
                        {hospital.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <SnapshotRouterInfo info={hospital.actorStatuses.snapshotRouter} />
                    </TableCell>
                    <TableCell>
                      {hospital.actorStatuses.enrollmentReader ? (
                        hospital.actorStatuses.enrollmentReader.status === 'Unresponsive' ? (
                          <Typography color="error">UNRESPONSIVE</Typography>
                        ) : (
                          <Typography>OK</Typography>
                        )
                      ) : (
                        <Typography>—</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {hospital.actorStatuses.exitGateway ? (
                        hospital.actorStatuses.exitGateway.status === 'Unresponsive' ? (
                          <Typography color="error">UNRESPONSIVE</Typography>
                        ) : (
                          <Typography>OK</Typography>
                        )
                      ) : (
                        <Typography>—</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {hospital.actorStatuses.sensorMonitor ? (
                        hospital.actorStatuses.sensorMonitor.status === 'Unresponsive' ? (
                          <Typography color="error">UNRESPONSIVE</Typography>
                        ) : (
                          <Typography>OK</Typography>
                        )
                      ) : (
                        <Typography>—</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {hospital.actorStatuses.subscriptionRegistry ? (
                        hospital.actorStatuses.subscriptionRegistry.status === 'Unresponsive' ? (
                          <Typography color="error">UNRESPONSIVE</Typography>
                        ) : (
                          <Typography>OK</Typography>
                        )
                      ) : (
                        <Typography>—</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {hospital.actorStatuses.gatewayRouter ? (
                        hospital.actorStatuses.gatewayRouter.status === 'Unresponsive' ? (
                          <Typography color="error">UNRESPONSIVE</Typography>
                        ) : (
                          <Typography>OK</Typography>
                        )
                      ) : (
                        <Typography>—</Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button component={RouterLink} to={`/dashboard/actors/${hospital.id}`} size="small">
                        →
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Paper>
  </Box>
);

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

interface SnapshotRouterInfoProps {
  info: ArrayElement<SystemWideActorStatuses['hospitals']>['actorStatuses']['snapshotRouter'];
}

const SnapshotRouterInfo = ({ info }: SnapshotRouterInfoProps) => {
  const [ok, unresponsive] = useMemo<[string[], string[]]>(() => {
    const ok: string[] = [];
    const unresponsive: string[] = [];

    if (info) {
      if (info.status === 'Unresponsive') {
        unresponsive.push('Snapshot Router');
      } else if (info.status === 'OK') {
        ok.push('Snapshot Router');
      }

      if (info.signalProcessorStatuses) {
        for (let { beaconId, signalProcessorStatus } of info.signalProcessorStatuses) {
          if (signalProcessorStatus.status === 'Unresponsive') {
            unresponsive.push(beaconId + ' Signal Processor');
          } else if (signalProcessorStatus.status === 'OK') {
            ok.push(beaconId + ' Signal Processor');
          }

          if (signalProcessorStatus.downstream) {
            if (signalProcessorStatus.downstream === 'Unresponsive') {
              unresponsive.push(beaconId + ' Signal Processor Downstream ');
            } else if (signalProcessorStatus.downstream == 'OK') {
              ok.push(beaconId + ' Signal Processor Downstream');
            }
          }

          if (signalProcessorStatus.downstreamFilter) {
            if (signalProcessorStatus.downstreamFilter.status === 'Unresponsive') {
              unresponsive.push(beaconId + ' Signal Processor Downstream Filter');
            } else if (signalProcessorStatus.downstreamFilter.status === 'OK') {
              ok.push(beaconId + ' Signal Processor Downstream Filter');
            }
          }

          if (signalProcessorStatus.patientActionDeferrer) {
            if (signalProcessorStatus.patientActionDeferrer === 'Unresponsive') {
              unresponsive.push(beaconId + ' Signal Processor Patient Action Deferrer');
            } else if (signalProcessorStatus.patientActionDeferrer === 'OK') {
              ok.push(beaconId + ' Signal Processor Patient Action Deferrer');
            }
          }
        }
      }
    }

    return [ok, unresponsive];
  }, [info]);

  return (
    <>
      {info ? (
        unresponsive.length > 0 ? (
          <Typography color="error">
            {unresponsive.length}/{ok.length + unresponsive.length} UNRESPONSIVE
          </Typography>
        ) : (
          <Typography>OK</Typography>
        )
      ) : (
        <Typography>—</Typography>
      )}
    </>
  );
};
