import React from 'react';
import { useMutation } from '@apollo/client';
import Notes from '../procedures/Notes';
import { deleteAnesthesiaNote, deletePreopNote, postAnesthesiaNote, postPreopNote } from '../../../graph/procedures';
import { ProcedureEvent } from '../../../types/ProcedureEvent';

interface AnesthesiaNotesProps {
  procedureId: number;
  procedureNotes: Array<ProcedureEvent>;
}

const AnesthesiaNotes = ({ procedureId, procedureNotes }: AnesthesiaNotesProps) => {
  const [create] = useMutation(postAnesthesiaNote);
  const [remove] = useMutation(deleteAnesthesiaNote);

  const createNote = async (note: string) => {
    await create({
      variables: {
        procedureId,
        note,
      },
    });
  };

  const deleteNote = async (noteId: number) => {
    await remove({
      variables: {
        noteId,
      },
    });
  };

  return <Notes notes={procedureNotes} createNote={createNote} deleteNote={deleteNote} />;
};

export default AnesthesiaNotes;
