import React, { createContext, Ref, RefObject, useContext, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { ReactNode } from 'react';
import classes from './NotesContextProvider.module.css';

interface NotesContext {
  rootElement: HTMLDivElement;
}

const notesContext = createContext<NotesContext | null>(null);

export const useNotesContext = (): NotesContext => {
  const notes = useContext(notesContext);

  if (notes === null) {
    throw new Error('NotesContextProvider not found');
  }

  return notes;
};

interface NotesContextProviderProps {
  children: ReactNode;
}

const NotesContextProvider = ({ children }: NotesContextProviderProps) => {
  const rootElement = useMemo(() => document.createElement('div'), []);

  const rootRef = useRef<HTMLDivElement>();

  useLayoutEffect(() => {
    rootRef.current?.appendChild(rootElement);
  }, []);

  return (
    <notesContext.Provider value={{ rootElement }}>
      {children}
      <div ref={rootRef as Ref<HTMLDivElement>} className={classes.root} />
    </notesContext.Provider>
  );
};

export default NotesContextProvider;
