import ReactDOM from 'react-dom';
import React from 'react';

export default Component =>
  class extends React.Component {
    state = {
      width: null,
    };

    refreshWidth = () => {
      const node = ReactDOM.findDOMNode(this);
      this.setState({
        width: node && node.clientWidth,
        height: node && node.clientHeight,
        scrollHeight: node && node.scrollHeight,
        scrollWidth: node && node.scrollWidth,
        horizontalOverflow: node && node.scrollWidth > node.clientWidth,
        verticalOverflow: node && node.scrollHeight > node.clientHeight,
      });
    };

    handleResize = () => {
      this.refreshWidth();
    };

    componentDidMount() {
      this.refreshWidth();
      window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    render() {
      return (
        <Component
          {...this.props}
          width={this.state.width}
          height={this.state.height}
          scrollWidth={this.state.scrollWidth}
          scrollHeight={this.state.scrollHeight}
          horizontalOverflow={this.state.horizontalOverflow}
          verticalOverflow={this.state.verticalOverflow}
        />
      );
    }
  };
