import React from 'react';
import { patientChartForm } from '../../../graph/surgeon/forms';
import { CenteredSpinner } from '../../../se/components/Spinner';
import FormContent from './FormContent';
import { useSubscription } from '@apollo/client';
import { QuestionnaireType } from '../../../types/Questionnaire';
import { useTheme } from '@material-ui/core';

const PatientChartForm = ({
  id,
  hospitalId,
  patientId,
  procedureId,
  questionnaireType,
  isolated,
}: {
  id: number;
  hospitalId: number;
  patientId: number;
  procedureId: number;
  questionnaireType: QuestionnaireType;
  isolated?: boolean;
}) => {
  const { data, loading } = useSubscription(patientChartForm, {
    variables: { id, procedureId, questionnaireType },
  });

  const formData = data?.patientChartForm || {};
  const theme = useTheme();

  return !loading ? (
    <FormContent
      {...formData}
      hospitalId={hospitalId}
      patientId={patientId}
      procedureId={procedureId}
      lastPreOpCompletedEvent={null}
      withActions={false}
      isolated={isolated}
      headerBackground={theme.palette.type === 'light' ? theme.palette.common.white : theme.palette.background.paper}
    />
  ) : (
    <CenteredSpinner size={undefined} strokeAccessor={undefined} />
  );
};

export default PatientChartForm;
