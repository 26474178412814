import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PickerHeader from './PickerHeader';
import BedInlineInput from '../../../entities/patient/BedInlineInput';

const Content = styled.div`
  padding: 0 1em 1em;
  display: flex;
  flex-direction: column;
`;

const BedSlots = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  flex: 1;
`;

const BedSlotWrapper = styled.button`
  border: none;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  font-size: 4rem;
  border-radius: 0.25rem;

  &:active {
    background: #00b641;
  }

  &:disabled {
    opacity: 0.25;
  }
`;

const BedSlot = ({ bedNumber, disabled, onClick }) => (
  <BedSlotWrapper disabled={disabled} onClick={disabled ? () => {} : () => onClick(bedNumber)}>
    <span>{bedNumber}</span>
  </BedSlotWrapper>
);

export const alphabet = 'abcdefghijklmnopqrstuvwxyz';

class PatientBedPicker extends React.Component {
  handleAssignBed = async bedNumber => {
    await this.props.onAssignBed(bedNumber);
    this.props.goToStartPage();
  };

  render() {
    const isAlphabeticBedOrder = this.props.room.isAlphabeticBedOrder;
    return (
      <Content>
        <PickerHeader
          bedInput={<BedInlineInput onClick={this.handleAssignBed} />}
          onCancel={this.props.onCancel}
          title={'Assign Bed'}
        />
        <BedSlots>
          {this.props.beds.map((bed, i) => (
            <BedSlot
              key={i}
              bedNumber={isAlphabeticBedOrder ? alphabet.charAt(i % 26).toUpperCase() : i + 1}
              disabled={bed.occupied}
              onClick={this.handleAssignBed}
            />
          ))}
        </BedSlots>
      </Content>
    );
  }
}

PatientBedPicker.propTypes = {
  beds: PropTypes.arrayOf(
    PropTypes.shape({
      occupied: PropTypes.bool,
    })
  ).isRequired,
  onAssignBed: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  goToStartPage: PropTypes.func.isRequired,
};

export default PatientBedPicker;
