import React from 'react';
import PropTypes from 'prop-types';
import { RouterlessModal } from '../../../se/components/Modal';
import TimeEditForm from './TimeEditForm';
import { PROCEDURE_STATUSES } from '../../entities/patient/enums';
import { isOrTimeActive } from '../../pages/kiosk/tablet/utils';

export const OR_TIMES_S = {
  ENTERED_AT: 'ENTERED_AT',
  TIMEOUT_START: 'TIMEOUT_START',
  PROCEDURE_START: 'PROCEDURE_START',
  CLOSING_START: 'CLOSING_START',
};

export const OR_TIMES = {
  ENTERED_AT: 'enteredAt',
  TIMEOUT_START: 'timeoutStart',
  PROCEDURE_START: 'procedureStart',
  CALLED_NEXT_PATIENT: 'calledNextPatient',
  CLOSING_START: 'closingStart',
  SURGEON_LEFT: 'surgeonLeft',
};

export const OR_TIMES_STATUS_FROM = {
  ENTERED_AT: PROCEDURE_STATUSES.IN_OR,
  TIMEOUT_START: PROCEDURE_STATUSES.TIME_OUT,
  PROCEDURE_START: PROCEDURE_STATUSES.ONGOING,
  CALLED_NEXT_PATIENT: PROCEDURE_STATUSES.CALL_NEXT_PATIENT,
  CLOSING_START: PROCEDURE_STATUSES.CLOSING,
  SURGEON_LEFT: PROCEDURE_STATUSES.SURGEON_LEFT_OR,
};

export const isTimeFieldDisabled = (status, field) => !isOrTimeActive(status, field);

const getDisabledFields = status =>
  Object.keys(OR_TIMES).reduce(
    (acc, cur) => ({
      ...acc,
      [OR_TIMES[cur]]: isTimeFieldDisabled(status, cur),
    }),
    {}
  );

const TimeEditModal = ({ onClose, opened, status, ...rest }) =>
  opened && (
    <RouterlessModal onClose={onClose} title="Edit Time Entries">
      <TimeEditForm {...rest} onClose={onClose} disabledFields={getDisabledFields(status)} />
    </RouterlessModal>
  );

TimeEditModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  focused: PropTypes.oneOf(Object.values(OR_TIMES)),
  patientId: PropTypes.number.isRequired,
  enteredAt: PropTypes.instanceOf(Date),
  timeoutStart: PropTypes.instanceOf(Date),
  procedureStart: PropTypes.instanceOf(Date),
  closingStart: PropTypes.instanceOf(Date),
};

export default TimeEditModal;
