import { sortString } from '../../../util/sort';

export const nextType = type => {
  switch (type) {
    case 'ASC':
      return 'DESC';
    case 'DESC':
      return 'ASC';
    case undefined:
    default:
      return 'ASC';
  }
};

export const chooseSortFunction = column => {
  if (column.sortFunction) {
    return column.sortFunction;
  }
  return (l, r, context, lens) => sortString(lens(l, context), lens(r, context));
};

export const listSort = (sort, sortColumn, data) => (l, r) => {
  const sortFn = chooseSortFunction(sortColumn);
  return sort.type === 'ASC'
    ? sortFn(l, r, { data }, sortColumn.lens, sort)
    : sortFn(r, l, { data }, sortColumn.lens, sort);
};

export const splitSeparatedList = list => {
  const left = [];
  const right = [];
  let separator = null;
  list.forEach(item => {
    if (item.separator) {
      separator = { ...item };
    } else if (separator) {
      right.push(item);
    } else {
      left.push(item);
    }
  });
  return { left, separator, right };
};

export const getSortedList = (list, containsSeparator, sort, sortColumn, data) => {
  const sortFn = listSort(sort, sortColumn, data);
  if (containsSeparator) {
    const { left, separator, right } = splitSeparatedList(list);
    return [...left.sort(sortFn), separator, ...right.sort(sortFn)];
  }
  return [...list].sort(sortFn);
};
