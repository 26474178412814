import React, { useEffect } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { listAssignedVendors, sendNewScheduleNotification } from '../../../../../graph/vendors';
import { format } from 'date-fns';
import { Destination } from '../../../../../types/NotificationTemplate';
import SendNotificationDialog, { Contact } from './SendNotificationDialog';
import { TooltipWrapper } from '../SendNotificationDropdown';
import { MenuItem } from '@material-ui/core';

const SendNotification = ({
  openDialog,
  handleOpenDialog,
  handleCloseDialog,
  date,
  notifyVendors,
}: {
  openDialog: boolean;
  handleOpenDialog: () => void;
  handleCloseDialog: () => void;
  date: Date;
  notifyVendors: boolean;
}) => {
  const { data } = useQuery(listAssignedVendors, {
    variables: { date: format(date, 'YYYY-MM-DD') },
    skip: !openDialog,
  });

  const client = useApolloClient();
  useEffect(() => {
    openDialog &&
      client.refetchQueries({
        // @ts-ignore
        include: [{ query: listAssignedVendors, variables: { date: format(date, 'YYYY-MM-DD') } }],
      });
  }, [openDialog, date]);

  const [doSend] = useMutation(sendNewScheduleNotification);

  const sendNotifications = async (recipients: Contact[], message: String) => {
    await doSend({
      variables: {
        contacts: recipients,
        message,
        date: format(date, 'YYYY-MM-DD'),
      },
    });
  };

  return (
    <>
      <TooltipWrapper title={'No vendor to notify'} isDisabled={!notifyVendors}>
        <MenuItem disabled={!notifyVendors} onClick={handleOpenDialog}>
          Vendors
        </MenuItem>
      </TooltipWrapper>
      {openDialog && (
        <SendNotificationDialog
          open={openDialog}
          date={date}
          recipients={data?.listAssignedVendors || []}
          sendNotifications={sendNotifications}
          destination={Destination.Vendor}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
};

export default SendNotification;
