import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import { z } from 'zod';
import { tryParseJson2 } from '../util/parseJson';
import Allergies from './Allergies';

const parseAllergies = (allergies: unknown) => {
  if (typeof allergies === 'string') {
    const parsed = tryParseJson2(allergies, undefined);
    return z
      .string()
      .or(Allergies)
      .safeParse(parsed === undefined ? allergies : parsed);
  } else {
    return z.string().or(Allergies).safeParse(allergies);
  }
};

export const useParsedAllergies = (allergies: unknown) => useMemo(() => parseAllergies(allergies), [allergies]);

export const unparsedAllergiesSummary = (unparsedAllergies: unknown): string | null => {
  const { success, data } = parseAllergies(unparsedAllergies);

  if (!success) {
    return null;
  }

  if (!data) {
    return null;
  }

  if (typeof data === 'string') {
    return data;
  }

  const allergies: Array<{ name?: string | null; reaction?: string | null }> = [
    ...(data.adhesive ? [{ name: 'Adhesive', ...data.adhesive }] : []),
    ...(data.latex ? [{ name: 'Latex', ...data.latex }] : []),
    ...(data.medications || []),
    ...(data.unknown || []),
  ];

  return allergiesSummary(allergies);
};

const allergiesSummary = (allergies: Array<{ name?: string | null }>) =>
  allergies.flatMap(a => (a.name ? [a.name] : [])).join(', ');

interface AllergiesViewProps {
  short?: boolean;
  allergies: unknown;
}

const AllergiesView = ({ short, allergies }: AllergiesViewProps) => {
  const parsed = useParsedAllergies(allergies);

  if (parsed.success) {
    const data = parsed.data;

    if (typeof data === 'string') {
      return (
        <Typography component="span" color="error">
          {data}
        </Typography>
      );
    } else if (data === null) {
      return (
        <Typography component="span" color="initial">
          None reported
        </Typography>
      );
    } else {
      const allergies: Array<{ name?: string | null; reaction?: string | null }> = [
        ...(data.adhesive ? [{ name: 'Adhesive', ...data.adhesive }] : []),
        ...(data.latex ? [{ name: 'Latex', ...data.latex }] : []),
        ...(data.medications || []),
        ...(data.unknown || []),
      ];

      if (allergies.length === 0) {
        return (
          <Typography component="span" color="initial">
            None reported
          </Typography>
        );
      } else if (short) {
        return (
          <Typography component="span" color="error">
            {allergiesSummary(allergies)}
          </Typography>
        );
      }

      return (
        <Box mb={-1}>
          {allergies.map((allergy, i) => {
            return (
              <Box mb={1} key={i}>
                <Typography variant="h4" style={{ fontWeight: 'bold' }} gutterBottom>
                  {allergy.name}
                </Typography>
                {allergy.reaction && <Typography variant="body1">{allergy.reaction}</Typography>}
              </Box>
            );
          })}
        </Box>
      );
    }
  }

  return null;
};

export default AllergiesView;
