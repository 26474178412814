import React, { FormEvent, useState } from 'react';
import { Button, FormControl, TextField, Typography, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { gql, useMutation } from '@apollo/client';
import { compose, withProps } from 'recompose';
// @ts-ignore
import PhoneInput from '../../../../../se/components/inputs/PhoneInput';
import { withLabel } from '../../../../../se/components/Label';

interface RideHomeFormProps {
  procedureId: number;
  onDone: () => void;
}

const RideHomeForm = ({ procedureId, onDone }: RideHomeFormProps) => {
  const [phoneNumber, setPhoneNumber] = useState('');

  const [setRideHome, { loading: busy }] = useMutation(
    gql`
      mutation setRideHome($procedureId: Long!, $phoneNumber: String!) {
        setRideHome(procedureId: $procedureId, phoneNumber: $phoneNumber)
      }
    `
  );

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await setRideHome({
        variables: {
          procedureId,
          phoneNumber,
        },
      });
      onDone();
    } catch (e) {
      console.error(e);
    }
  };

  const handleReset = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onDone();
  };

  return (
    <Box textAlign="center" display="flex" flexDirection="column" justifyContent="middle" maxWidth="62ch">
      <Typography variant="h5" gutterBottom>
        Ride Home Info
      </Typography>
      <Typography gutterBottom>Please enter the phone number of a person who will be picking you up.</Typography>
      <Box
        flexDirection="column"
        component="form"
        onSubmit={handleSubmit}
        onReset={handleReset}
        display="flex"
        justifyContent="center"
        textAlign="left"
        mt={2}
      >
        <FormControl variant="outlined" style={{ flex: 3 }}>
          <PhoneNumberInput value={phoneNumber} onChange={value => setPhoneNumber(value.trim())} disabled={busy} />
        </FormControl>
        <Box display="flex" flexDirection="column">
          <Button size="large" type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Box>
        <Box display="flex" flexDirection="column">
          <Button size="large" type="reset" variant="text" color="secondary">
            Skip
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default RideHomeForm;

const PhoneNumberInput = compose(
  withLabel('Ride Home Phone Number'),
  withProps({ placeholder: 'e.g., (619) 412-9512' })
)(PhoneInput);
