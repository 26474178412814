import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Route, Switch, withRouter } from 'react-router-dom';

import { getNestedValue } from '../../utilities/data/object';
import ErrorBoundary from '../ErrorBoundary';
import NotFound from '../NotFound';

const createRoutes = basePath => [
  {
    path: basePath === '' ? '/' : basePath,
    key: 1,
  },
  {
    path: `${basePath}/create`,
    key: 1,
  },
  {
    path: `${basePath}/:id`,
    matchPath: `${basePath}/[^/]+`,
    key: 2,
  },
  {
    path: `${basePath}/:id/edit`,
    matchPath: `${basePath}/[^/]+/edit`,
    key: 2,
  },
];

const CollectionRouter = ({
  idType = 'number',
  List,
  Create,
  Show,
  Edit,
  NotFound,
  match,
  location,
  baseRouteUrlAffix,
  additionalRoutes,
  ...rest
}) => {
  const basePathCandidate = (match && match.path) || '';
  const basePathMatch = basePathCandidate.match(/\/*$/);
  const basePath = basePathCandidate.slice(0, basePathCandidate.length - basePathMatch[0].length);
  const baseUrl = match.url;
  const baseAffix = baseRouteUrlAffix ? `${baseRouteUrlAffix}/` : '';

  const routes = createRoutes(basePath);
  const route = routes.find(route => location.pathname.match(`^${route.matchPath || route.path}$`));

  /**
   * 1. Match additional routes if there are any.
   * 2. Matching goes a little bit weired but it makes sense if you look at it
   * 3. Main reason we don‘t want exact prop...
   */
  return (
    <ErrorBoundary>
      <Fragment key={(route && route.key) || 1}>
        <Switch location={location}>
          {Show && Edit ? (
            <Route
              key={'edit'}
              path={idType === 'number' ? `${basePath}/${baseAffix}:id(\\d+)/edit` : `${basePath}/${baseAffix}:id/edit`}
              render={props => (
                <Show
                  {...props}
                  {...rest}
                  Edit={Edit}
                  basePathProvider={() => `${basePath}/${baseAffix}:id`}
                  baseUrlProvider={id => `${baseUrl}/${baseAffix}${id}`}
                  viewBackUrlProvider={() => baseUrl}
                  onRemoveUrlProvider={() => baseUrl}
                  editBackUrlProvider={id => `${baseUrl}/${baseAffix}${id}`}
                  idProvider={({ match }) => getNestedValue('params.id', match)}
                />
              )}
            />
          ) : null}

          {Create ? (
            <Route
              key={'create'}
              path={`${basePath}/${baseAffix}create`}
              render={props => (
                <List
                  {...props}
                  {...rest}
                  Create={Create}
                  createBackUrlProvider={() => baseUrl}
                  createUrlProvider={() => `${baseUrl}/${baseAffix}create`}
                  createPathProvider={() => `${basePath}/${baseAffix}create`}
                />
              )}
            />
          ) : null}

          {Show ? (
            <Route
              key={'show'}
              path={idType === 'number' ? `${basePath}/${baseAffix}:id(\\d+)` : `${basePath}/${baseAffix}:id`}
              render={props => (
                <Show
                  {...props}
                  {...rest}
                  Edit={Edit}
                  basePathProvider={() => `${basePath}/${baseAffix}:id`}
                  baseUrlProvider={id => `${baseUrl}/${baseAffix}${id}`}
                  viewBackUrlProvider={() => baseUrl}
                  idProvider={({ match }) => getNestedValue('params.id', match)}
                />
              )}
            />
          ) : null}

          <Route
            key={'list'}
            path={basePath === '' ? '/' : basePath}
            render={props => (
              <List
                {...props}
                {...rest}
                Create={Create}
                createUrlProvider={() => `${baseUrl}/${baseAffix}create`}
                createPathProvider={() => `${basePath}/${baseAffix}create`}
                showUrlProvider={Show ? id => `${baseUrl}/${baseAffix}${id}` : undefined}
              />
            )}
          />
        </Switch>
      </Fragment>
    </ErrorBoundary>
  );
};

CollectionRouter.defaultProps = {
  NotFound: NotFound,
};

CollectionRouter.propTypes = {
  baseRouteUrl: PropTypes.string,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  List: PropTypes.func.isRequired,
  Create: PropTypes.func,
  Show: PropTypes.func,
  Edit: PropTypes.func,
};

export default compose(withRouter)(CollectionRouter);
