import { Mutation } from '@apollo/client/react/components';
import React, { Fragment, useEffect, useMemo, useState } from 'react';

import styled, { ThemeProvider } from 'styled-components';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { useQuery } from '@apollo/client';
import { getSurgeryInstructionsTemplates, updateProcedureSurgeryInstructions } from '../../../../graph/procedures';
import LinkButton from '../../../../se/components/LinkButton';
import SelectInput from '../../../../se/components/inputs/SelectInput';
import Button from '../../../../se/components/Button';
import { TruncatedText } from '../../../../se/components/typography';
import { format } from 'date-fns';
import RichTextPage from './RichTextPage';
import { InlineColumn, ResendActionsInstructions } from '../columns';
import InstructionsAccepted from './InstructionsAccepted';
import { light as lightTheme } from '../../../../theme';
import { TemplateContext, TemplateStateProvider } from './TemplateContext';
import {
  instructionTemplateLabels,
  instructionTemplateLabelsView2Hospital15,
  instructionTemplateLabelsViewHospital2,
  instructionTemplateLabelsView2Hospital22,
  instructionTemplateLabelsViewHospital55,
  InstructionTemplates,
  InstructionTemplates as instructionTemplates,
} from '../enums';
import defaultTo from 'lodash/defaultTo';
import { generateInstructionsPdf } from '../pdf/InstructionPdfGenerator';
import { ZonedDateTime } from '@js-joda/core';
import { getNotificationTemplate } from '../../../../graph/notificationTemplates';
import { eventTypes } from '../ProcedureEventLog';
import { filter } from 'lodash';
import isNil from 'lodash/fp/isNil';
import { Print } from '@material-ui/icons';
import { withScope } from '../../../../contexts/ScopeContext';
import { useScope } from '../../../../hooks/useScope';
import { SendingSetting } from '../../notificationTemplate/NotificationTemplates';

export const InstructionBody = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;

  > * + * {
    margin-left: 3em;
  }
`;

export const TemplateEditor = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 1 44.2em; // Do Beograda
  max-width: 100%;
`;

export const EditorActions = styled.div<{ flexEnd?: boolean }>`
  display: flex;
  justify-content: ${props => (props.flexEnd ? 'flex-end' : 'space-between')};
  padding-right: 10%;
  gap: 10px;
  margin-bottom: 1em;
`;

const EditorPreview = styled.div`
  background: ${props => props.theme.backgroundColor.string()};
  color: ${props => props.theme.textColor.string()};
  box-shadow: 2px 2px 7px 4px rgba(0, 0, 0, 0.15);
  padding: 2rem;
  font-size: 0.8em;
  min-height: 54rem;
`;

const TemplatePicker = ({ selected, onSelected, templates }: any) => {
  const scope = useScope();
  const hospitalId = scope?.hospital?.id;

  const caseViewHospital2 =
    (window.location.hostname === 'localhost' && hospitalId === 67) ||
    (window.location.hostname === 'testing.ospitek.com' && hospitalId === 67) ||
    (window.location.hostname === 'view.ospitek.com' && hospitalId === 2);

  const caseView2Hospital8 =
    (window.location.hostname === 'localhost' && hospitalId === 83) ||
    (window.location.hostname === 'testing.ospitek.com' && hospitalId === 83) ||
    (window.location.hostname === 'view2.ospitek.com' && hospitalId === 8);

  const caseView2Hospital15 =
    (window.location.hostname === 'localhost' && hospitalId === 59) ||
    (window.location.hostname === 'testing.ospitek.com' && hospitalId === 59) ||
    (window.location.hostname === 'view2.ospitek.com' && hospitalId === 15);

  const caseView2Hospital28 =
    (window.location.hostname === 'view2.ospitek.com' && hospitalId === 28) ||
    (window.location.hostname === 'testing.ospitek.com' && hospitalId === 96);

  const caseView2Hospital22 =
    (window.location.hostname === 'localhost' && hospitalId === 84) ||
    (window.location.hostname === 'testing.ospitek.com' && hospitalId === 84) ||
    (window.location.hostname === 'view2.ospitek.com' && hospitalId === 22);

  const caseViewHospital55 =
    (window.location.hostname === 'localhost' && hospitalId === 98) ||
    (window.location.hostname === 'testing.ospitek.com' && hospitalId === 98) ||
    (window.location.hostname === 'view.ospitek.com' && hospitalId === 55);

  const caseView4Hospital2 =
    (window.location.hostname === 'localhost' && hospitalId === 1) ||
    (window.location.hostname === 'view4.ospitek.com' && hospitalId === 2);

  const caseView4Hospital3 = window.location.hostname === 'view4.ospitek.com' && hospitalId === 3;

  const instructionTemplateLabelsCorrections = caseViewHospital2
    ? {
        [InstructionTemplates.GynecologySurgeryInstructions]: 'Eye Laser Pre-Op Instructions',
      }
    : caseView2Hospital8
    ? {
        [InstructionTemplates.CovidTestSurgeryInstructions]: 'Pre-Operative Instructions',
      }
    : caseView2Hospital28
    ? {
        [InstructionTemplates.CovidTestSurgeryInstructions]: 'Locals only',
      }
    : caseView4Hospital2
    ? {
        DayOfSurgeryInstructions: 'Day of Surgery Instructions',
      }
    : caseView4Hospital3
    ? {
        DayOfSurgeryInstructions: 'Day of Surgery Instructions',
      }
    : {};

  const originalCorrectedInstructionTemplateLabels = caseView2Hospital15
    ? instructionTemplateLabelsView2Hospital15
    : caseViewHospital2
    ? instructionTemplateLabelsViewHospital2
    : caseView2Hospital22
    ? instructionTemplateLabelsView2Hospital22
    : caseViewHospital55
    ? instructionTemplateLabelsViewHospital55
    : {
        ...instructionTemplateLabels,
        ...instructionTemplateLabelsCorrections,
      };

  const correctedInstructionTemplateLabels = caseView2Hospital8
    ? templates.reduce(
        (acc: any, t: any) => ({
          ...acc,
          [t.type]: t.label ?? t.title ?? t.name ?? originalCorrectedInstructionTemplateLabels[t.type] ?? t.type,
        }),
        {}
      )
    : originalCorrectedInstructionTemplateLabels;

  useEffect(() => {
    const options = Object.keys(correctedInstructionTemplateLabels);
    if (options.length > 0 && selected && options.indexOf(selected) < 0) {
      onSelected(options[0]);
    }
  }, [correctedInstructionTemplateLabels, onSelected, selected]);

  return (
    <SelectInput
      name="status"
      placeholder="Select"
      options={correctedInstructionTemplateLabels}
      value={selected}
      onChange={(value: any) => onSelected(value)}
      menuWidthFollowContent
      isSearchable={false}
      style={{
        width: 350,
        marginRight: '2rem',
      }}
    />
  );
};

const Actions = styled.div`
  display: flex;
  margin-bottom: 1em;
  > * + * {
    margin-left: 1em;
  }
`;

export const getInstructionLastSent = (data: any) => {
  const emailInstructionInvite = get(data, 'instructionsInviteSentAt');
  const smmInstructionInvites = filter(
    get(data, 'events'),
    ({ type }) => type === eventTypes.MessageDeliveryInstructions
  ).map(invite => invite?.timestamp);
  const lastTwoSentAt = [emailInstructionInvite, smmInstructionInvites?.[0]];
  // @ts-ignore
  return lastTwoSentAt.sort((a: any, b: any) => new Date(b) - new Date(a)).filter(lastSent => !isNil(lastSent));
};

export const SurgeryInstructions = withScope(({ data, scope }: any) => {
  const hospitalId = scope?.hospital?.id;

  const caseViewHospital55 =
    (window.location.hostname === 'localhost' && hospitalId === 98) ||
    (window.location.hostname === 'testing.ospitek.com' && hospitalId === 98) ||
    (window.location.hostname === 'view.ospitek.com' && hospitalId === 55);

  const sortedLastTwoSentAt = getInstructionLastSent(data);
  const invite = sortedLastTwoSentAt?.[0];
  const instructionData = get(data, 'procedureInstructions', '{}');
  const defaults = {
    arrivalDate: format(get(data, 'startTime'), 'YYYY-MM-DD'),
  };
  const { formType, variables: variablesNoDef } = JSON.parse(instructionData) || {};
  const variables = { ...(variablesNoDef || {}), ...defaults };

  const { data: surgeryTemplatesData } = useQuery(getSurgeryInstructionsTemplates);
  const templates = get(surgeryTemplatesData, 'surgeryInstructionsTemplates', []);
  const [templateType, setTemplateType] = useState(
    caseViewHospital55
      ? defaultTo(formType, instructionTemplates.CovidTestSurgeryInstructions)
      : defaultTo(formType, instructionTemplates.GeneralSurgeryInstructions)
  );
  const template = useMemo(() => templates.find((_: any) => _.type === templateType), [templateType, templates]);
  const templateContent = get(JSON.parse(get(template, 'content') || '{}') || {}, 'content');

  const updateInstructions = (mutate: any, state: any) => () => {
    mutate({
      variables: {
        procedureId: data.id,
        content: JSON.stringify({ content: templateContent, variables: state, formType: get(template, 'type') }),
      },
    });
  };
  const { data: smsPatientReadyNotification } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'SurgeryInstructions', channel: 'SMS' },
  });
  const smsNotificationData = smsPatientReadyNotification?.getNotificationTemplate[0] || null;
  const { data: emailPatientReadyNotification } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'SurgeryInstructions', channel: 'Email' },
  });
  const emailNotificationData = emailPatientReadyNotification?.getNotificationTemplate[0] || null;

  const onPrint =
    ({ variables }: any) =>
    () => {
      generateInstructionsPdf({
        instructions: {
          content: templateContent,
          variables: variables,
        },
        procedure: data,
        hospitalName: get(scope, 'hospital.name'),
        scheduleProvider: get(scope, 'hospital.scheduleProvider'),
      });
    };

  const preSend =
    ({ action, isDirty }: any) =>
    async () => {
      if (isDirty) {
        if (window.confirm('You have unsaved changes. Do you want to save them and send?')) {
          await action();
          return true;
        }
        return false;
      }
      return true;
    };

  // @ts-ignore
  return (
    <TemplateStateProvider initialState={variables}>
      <TemplateContext.Consumer>
        {({ variables: state, setVariables }) => (
          <Mutation mutation={updateProcedureSurgeryInstructions}>
            {/* @ts-ignore */}
            {(mutate, { loading }) => (
              <InstructionBody>
                <TemplateEditor>
                  <EditorActions>
                    <TemplatePicker selected={templateType} onSelected={setTemplateType} templates={templates} />
                    {/* @ts-ignore */}
                    <Button onClick={onPrint({ variables })}>
                      <Print fontSize={'small'} style={{ paddingRight: '0.5rem' }} />
                      Print Instructions
                    </Button>
                  </EditorActions>
                  <ThemeProvider theme={lightTheme}>
                    <Fragment>
                      <Actions>
                        {/* @ts-ignore */}
                        <Button
                          disabled={(isEqual(variables, state) && get(template, 'type') === formType) || loading}
                          onClick={updateInstructions(mutate, state)}
                        >
                          Save Changes
                        </Button>
                        {!isEqual(variables, state) && (
                          <LinkButton onClick={() => setVariables(variables)} style={{ border: 'none' }}>
                            Cancel
                          </LinkButton>
                        )}
                      </Actions>
                      <EditorPreview>
                        <RichTextPage value={templateContent} />
                      </EditorPreview>
                    </Fragment>
                  </ThemeProvider>
                </TemplateEditor>
                <InlineColumn>
                  <InstructionsAccepted data={data} style={{ fontSize: '1.3em' }} />
                  <TruncatedText style={{ opacity: 0.6 }} textStyle={undefined}>
                    {invite
                      ? `${
                          sortedLastTwoSentAt.filter(lastSent => lastSent !== undefined).length > 1
                            ? 'Last sent at'
                            : 'Sent at'
                        } ${format(ZonedDateTime.parse(invite).toLocalDateTime().toString(), 'MM/DD/YYYY HH:mm:ss')}`
                      : 'Not Sent'}
                  </TruncatedText>
                  <ResendActionsInstructions
                    data={{ ...data, templateType: templateType }}
                    preSubmit={preSend({
                      action: updateInstructions(mutate, state),
                      isDirty: !(isEqual(variables, state) && get(template, 'type') === formType),
                    })}
                    smsEnabled={smsNotificationData?.sendingSetting !== SendingSetting.Off}
                    emailEnabled={emailNotificationData?.sendingSetting !== SendingSetting.Off}
                  />
                </InlineColumn>
              </InstructionBody>
            )}
          </Mutation>
        )}
      </TemplateContext.Consumer>
    </TemplateStateProvider>
  );
});
