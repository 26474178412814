import React from 'react';
import { useMutation } from '@apollo/client';
import Notes from '../procedures/Notes';
import { deleteSourceProcedureNote, postSourceProcedureNote } from '../../../graph/scheduleRequests';

interface ProcedureNotesProps {
  requestId: number;
  procedureNotes: Array<any>;
}

const ProcedureNotes = ({ requestId, procedureNotes }: ProcedureNotesProps) => {
  const [create] = useMutation(postSourceProcedureNote);
  const [remove] = useMutation(deleteSourceProcedureNote);

  const createNote = async (note: string) => {
    await create({
      variables: {
        id: requestId,
        note,
      },
    });
  };

  const deleteNote = async (noteId: number) => {
    await remove({
      variables: {
        id: requestId,
        noteId,
      },
    });
  };

  return <Notes notes={procedureNotes} createNote={createNote} deleteNote={deleteNote} />;
};

export default ProcedureNotes;
