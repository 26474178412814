import get from 'lodash/get';
import LinkButton from '../../../../se/components/LinkButton';
import React from 'react';
import { first } from 'lodash';
import { sortDate } from '../../../../util/sort';
import config from '../../../../config';
import { eventTypes } from '../ProcedureEventLog';
import { getLogEvents } from '../events';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const chronologicallyLast = items => first(items.sort((l, r) => sortDate(r.timestamp, l.timestamp)));
export const getInstructionAcceptances = getLogEvents(eventTypes.InstructionsAccepted);

const useStyles = makeStyles(theme => ({
  success: {
    color: theme.palette.success.main,
  },
}));

const openPastInstructions = exchange => {
  if (exchange) {
    window.open(
      `${config.careURL}/manage/instructions/${exchange.exchangeId}/${exchange.id}`,
      'questionnaire',
      `width=1024,height=750`
    );
  }
};

const FormAcceptanceStatus = {
  Accepted: 'Accepted',
  PreviousVersion: 'PreviousVersion',
  NotAccepted: 'NotAccepted',
  Declined: 'Declined',
};

const InstructionsAccepted = ({ data, style }) => {
  const classes = useStyles();
  const status = get(data, 'instructionsAccepted');
  return status === FormAcceptanceStatus.Accepted ? (
    <Typography className={classes.success} style={{ ...style }}>
      Accepted
    </Typography>
  ) : status === FormAcceptanceStatus.PreviousVersion ? (
    <LinkButton
      warning
      onClick={() => openPastInstructions(chronologicallyLast(getInstructionAcceptances(data)))}
      style={style}
    >
      Accepted Previous Version
    </LinkButton>
  ) : null;
};

export default InstructionsAccepted;
