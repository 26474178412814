export const getGridParentStyles = (columnCount, border) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
  gridGap: '0.1em',
  padding: border ? '0.1em' : 0,
  background: border ? 'grey' : 'white',
});

export const getGridChildStyles = (colSpan, rowSpan) => ({
  gridColumn: `span ${colSpan ?? 1}`,
  gridRow: `span ${rowSpan ?? 1}`,
  textAlign: 'left',
  background: 'white',
});

export const getGridChildStyles2 = (colSpan, rowSpan) => ({
  gridColumn: `span ${colSpan ?? 1}`,
  gridRow: `span ${rowSpan ?? 1}`,
  textAlign: 'left',
  background: 'white',
});

export const getGridChildStyles3 = (colSpan, rowSpan) => ({
  gridColumn: `span ${colSpan ?? 1}`,
  gridRow: `span ${rowSpan ?? 1}`,
  textAlign: 'left',
  background: 'white',
  fontSize: '.8rem',
  padding: '.5rem',
});

export const getGridHeaderChildStyles = (colSpan, rowSpan) => ({
  gridColumn: `span ${colSpan ?? 1}`,
  gridRow: `span ${rowSpan ?? 1}`,
  textAlign: 'center',
  background: '#E8E8E8',
});

const styles = {
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '1em',
    textAlign: 'center',
  },
  rowSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '0.5em',
    textAlign: 'center',
  },
};

export default styles;
