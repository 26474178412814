import graphqlSelectInput from '../graphqlSelectInput';
import { notUtilityRooms } from '../../../graph/rooms';

export default graphqlSelectInput({
  entityName: 'Room',
  label: 'Room',
  placeholder: '',
  graphqlList: notUtilityRooms,
  getOptionValue: option => (option ? parseInt(option.value, 10) : undefined),
});
