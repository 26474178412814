import { usePatientIdContext } from '../../components/pages/kiosk/charting/PatientIdContextProvider';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { item as patientWithCharts } from '../../graph/patientChart';
import { Patient, PatientStatus } from '../../types/Patient';
import {
  completeCleaning as completeCleaningMutation,
  opreationRoomSubscription,
  setPatientStatus,
} from '../../graph/rooms';
import { Room } from '../../types/Room';
import React, { MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import update from 'lodash/update';
import merge from 'lodash/merge';
import { format } from 'date-fns';
import { getPatientProcedureDuration, transformORPatients } from '../../components/entities/patient/transducers';
import { mapTabletOperationRoom } from '../../components/entities/room/transducers';
import { orTabletProcedureSteps, setProcedureStepTimestamp } from '../../graph/procedureSteps';
import { exitProcedure as exitProcedureMutation } from '../../graph/patients';
import { Modal, ModalAction, Subtitle, Title } from '../../components/pages/kiosk/tablet/Modal';
import { STATUSES as ROOM_STATUSES } from '../../components/entities/room/enums';
import Box from '@material-ui/core/Box';
import { ButtonLabel, MainButton, TimeOutButton } from '../../components/pages/kiosk/tablet/common';
import responsive from '../../se/utilities/responsive';

import { WithWorking } from '../../components/pages/kiosk/tablet/v2/OperationRoomTabletV2';
import styled, { css } from 'styled-components';
import { Alert } from '@material-ui/lab';
import Button from '../../se/components/Button';
import prevSound from '../../assets/sound/discharge.mp3';
import nextSound from '../../assets/sound/orReady.mp3';
import {
  getProcedureState,
  ProcedureState,
  setTimeToDate,
  toPatientStatus,
} from '../../components/pages/kiosk/tablet/util/procedureSteps';
import { ProcedureStep } from '../../types/ProcedureStep';
import Mousetrap from 'mousetrap';
import { throttle } from 'lodash';
import { withAlertSound } from '../../components/pages/kiosk/tablet/v2/CurrentProcedure';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const MessageToSend = styled.div`
  position: relative;
  padding: 1em 1.5em;
  border-radius: .25em;
  margin-bottom: 4rem;
  font-size: 1.75rem;
  line-height: 1.5;
  background-color: ${props => props.theme.textColor.alpha(0.2).string()};
  max-width: 30em;
  text-align: left;
  white-space: pre-wrap;

  :before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid ${props => props.theme.textColor.alpha(0.2).string()};
    border-top: 10px solid ${props => props.theme.textColor.alpha(0.2).string()};
    border-bottom: 10px solid transparent;
    right: 19px;
    bottom: -20px;
  }
}
`;

const Root = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  background: ${props => props.theme.backgroundColor.string()};
  color: ${props => props.theme.textColor.string()};
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
`;

export const CustomModal =
  (message = '', yesPrimary = false, yesLabel = '', yesSecondaryLabel = '', noLabel = '') =>
  ({
    handleConfirm,
    handleSecondaryConfirm,
    handleCancel,
    working,
    sendTo,
    messageToSend,
    errorMessage,
    children,
  }: any) =>
    (
      <Modal
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{ overflowY: 'unset', fontWeight: 400 }}
      >
        <Title>Are you sure?</Title>
        <Subtitle>
          {message}{' '}
          {!!sendTo && (
            <span>
              <br />
              {sendTo}
            </span>
          )}
        </Subtitle>
        {!!messageToSend && <MessageToSend>{messageToSend}</MessageToSend>}
        {children}
        <ModalAction>
          <Button xl primary={yesPrimary} onClick={handleConfirm} disabled={working}>
            <WithWorking working={working}>{yesLabel || 'Yes'}</WithWorking>
          </Button>
          {handleSecondaryConfirm && (
            <Button xl primary={yesPrimary} onClick={handleSecondaryConfirm} disabled={working}>
              <WithWorking working={working}>{yesSecondaryLabel || 'Yes'}</WithWorking>
            </Button>
          )}
          <Button xl primary={!yesPrimary} onClick={handleCancel} disabled={working}>
            {noLabel || 'No'}
          </Button>
        </ModalAction>
        {!!errorMessage && (
          <Alert severity="error" style={{ marginTop: 40 }}>
            {errorMessage}
          </Alert>
        )}
      </Modal>
    );

const ExitModal = CustomModal('Current procedure progress will be lost. This action cannot be reversed.');
const ClosingModal = CustomModal(
  'This action will start procedure closing and send SMS to the patient’s caretaker.',
  true
);

const AnesthesiaChartHeader = () => {
  const patientId = usePatientIdContext();

  const { data: patientData } = useSubscription(patientWithCharts, {
    variables: { id: patientId },
    skip: !patientId,
  });

  const roomId = patientData?.patient?.room?.id;

  const [modal, setModal] = useState<boolean>(false);
  const [closingModal, setClosingModal] = useState<boolean>(false);

  const [exitProcedure] = useMutation(exitProcedureMutation);

  const roomData = useSubscription(opreationRoomSubscription, {
    variables: {
      roomId: roomId,
    },
    skip: !roomId,
  });

  const room: Room & { status: string; patient: Patient & { duration?: string } } = useMemo(() => {
    const transformedRoom = transformORPatients(roomData?.data?.tabletApp || {});
    const update1 = update(transformedRoom, 'patient', patient =>
      merge(patient, getPatientProcedureDuration(patient, transformedRoom))
    );
    const update2 = mapTabletOperationRoom(update1);
    return {
      ...update2,
      status: !update2.patient ? null : update2.status,
    };
  }, [roomData]);

  const [working, setWorking] = useState<boolean>(false);

  const withWorking = (fn: any) => async (args: any) => {
    if (fn) {
      setWorking(true);
      await fn(args);
      setWorking(false);
    }
  };

  const { patient, status } = room || {};

  const [setStatus] = useMutation(setPatientStatus);

  const changeStatus = async (status: PatientStatus) => {
    status &&
      (await setStatus({
        variables: {
          id: patient?.id,
          status,
        },
        refetchQueries: [{ query: orTabletProcedureSteps, variables: { patientId: patient?.id } }],
      }));
  };

  const manuallyExitProcedure = (id?: number) => async () => {
    try {
      id && (await exitProcedure({ variables: { id } }));
    } catch (error) {
      console.error(error);
    } finally {
      setModal(false);
    }
  };

  const startProcedureClosing = async () => {
    try {
      await changeStatus(PatientStatus.Closing);
    } catch (error) {
      console.error(error);
    } finally {
      setClosingModal(false);
    }
  };

  const currentProcedureExists = [
    PatientStatus.InOr,
    PatientStatus.AnestheticStart,
    PatientStatus.ReadyForSurgery,
    PatientStatus.Ready,
    PatientStatus.TimeOut,
    PatientStatus.TimeOut2,
    PatientStatus.Ongoing,
    PatientStatus.CallNextPatient,
    PatientStatus.Closing,
    PatientStatus.SurgeonLeftOR,
    PatientStatus.ProcedureEnd,
    PatientStatus.AnestheticEnd,
    PatientStatus.Discharged,
    ROOM_STATUSES.CLEANING,
  ].includes(status);

  if (!room?.patient?.id || room.patient.id !== patientData?.patient?.id) {
    switch (patientData?.patient?.status) {
      case PatientStatus.Admitted:
        return (
          <Container>
            <TimeOutButton2 disabled style={{ filter: 'opacity(0.5)' }}>
              <WithWorking working={false} size={'1rem'}>
                <Box fontSize={'3.5em'} style={{ transform: `translateY(0.125em)` }}>
                  <ChevronLeftIcon fontSize={'inherit'} />
                </Box>
              </WithWorking>
              <ButtonLabel2>{'Undo'}</ButtonLabel2>
            </TimeOutButton2>
            <Steps>
              <p>Patient is admitted and procedure has yet to start.</p>
            </Steps>
            <MainButton2 disabled style={{ filter: 'opacity(0.5)' }}>
              <WithWorking working={false} size={'1rem'}>
                <Box fontSize={'3.5em'} style={{ transform: `translateY(0.125em)` }}>
                  <ChevronRightIcon fontSize={'inherit'} />
                </Box>
              </WithWorking>
              <ButtonLabel2>Next</ButtonLabel2>
            </MainButton2>
          </Container>
        );
      case PatientStatus.InOr:
      case PatientStatus.AnestheticStart:
      case PatientStatus.ReadyForSurgery:
      case PatientStatus.Ready:
      case PatientStatus.TimeOut:
      case PatientStatus.TimeOut2:
      case PatientStatus.Ongoing:
      case PatientStatus.CallNextPatient:
      case PatientStatus.Closing:
      case PatientStatus.SurgeonLeftOR:
      case PatientStatus.ProcedureEnd:
      case PatientStatus.AnestheticEnd:
      case PatientStatus.Done:
      case PatientStatus.Discharged:
        return (
          <Container>
            <TimeOutButton2 disabled>
              <WithWorking working={false} size={'1rem'}>
                <Box fontSize={'3.5em'} style={{ transform: `translateY(0.125em)` }}>
                  <ChevronLeftIcon fontSize={'inherit'} />
                </Box>
              </WithWorking>
              <ButtonLabel2>{'Undo'}</ButtonLabel2>
            </TimeOutButton2>
            <Steps>
              <ProcedureSteps patient={patientData.patient!} />
            </Steps>
            <MainButton2 disabled>
              <WithWorking working={false} size={'1rem'}>
                <Box fontSize={'3.5em'} style={{ transform: `translateY(0.125em)` }}>
                  <ChevronRightIcon fontSize={'inherit'} />
                </Box>
              </WithWorking>
              <ButtonLabel2>Next</ButtonLabel2>
            </MainButton2>
          </Container>
        );
      case PatientStatus.Canceled:
        return (
          <Container>
            <TimeOutButton2 disabled style={{ filter: 'opacity(0.5)' }}>
              <WithWorking working={false} size={'1rem'}>
                <Box fontSize={'3.5em'} style={{ transform: `translateY(0.125em)` }}>
                  <ChevronLeftIcon fontSize={'inherit'} />
                </Box>
              </WithWorking>
              <ButtonLabel2>{'Undo'}</ButtonLabel2>
            </TimeOutButton2>
            <Steps>
              <p>Procedure is canceled.</p>
            </Steps>
            <MainButton2 disabled style={{ filter: 'opacity(0.5)' }}>
              <WithWorking working={false} size={'1rem'}>
                <Box fontSize={'3.5em'} style={{ transform: `translateY(0.125em)` }}>
                  <ChevronRightIcon fontSize={'inherit'} />
                </Box>
              </WithWorking>
              <ButtonLabel2>Next</ButtonLabel2>
            </MainButton2>
          </Container>
        );
      default:
        return null;
    }
  }

  return modal ? (
    <Root>
      <ExitModal
        handleConfirm={withWorking(manuallyExitProcedure(patient?.id))}
        handleCancel={() => setModal(false)}
        working={working}
      />
    </Root>
  ) : closingModal ? (
    <Root>
      <ClosingModal
        handleConfirm={startProcedureClosing}
        handleCancel={() => setClosingModal(false)}
        working={working}
      />
    </Root>
  ) : currentProcedureExists ? (
    <CurrentProcedure roomId={roomId} room={room} />
  ) : null;
};

const ProcedureSteps = ({ patient }: { patient: Patient }) => {
  const { data } = useQuery(orTabletProcedureSteps, {
    variables: {
      patientId: patient.id,
    },
  });

  const status = patient.status as PatientStatus;

  const procedureState: Pick<ProcedureState, 'steps'> | null = useMemo(() => {
    const steps = data?.orTabletProcedureSteps || [];
    const state = getProcedureState(steps, status);
    return {
      steps: steps.map((e: ProcedureStep, i: number) => ({
        ...e,
        highlight: i === (state?.index || 0),
        show: true,
      })),
    };
  }, [data, status]);

  return (
    <>
      {(procedureState?.steps || []).map(
        (procedureStep: ProcedureStep & { highlight: boolean; show: boolean }, index: number) => (
          <ProcedureStepTime key={procedureStep?.id} index={index} procedureStep={procedureStep} patient={patient} />
        )
      )}
    </>
  );
};

const CurrentProcedure = ({
  roomId,
  room,
}: {
  roomId: number;
  room: Room & { status: string; patient: Patient & { duration?: string } };
}) => {
  const { patient, status } = room;

  const withAlertSoundPrev = withAlertSound(prevSound);
  const withAlertSoundNext = withAlertSound(nextSound);

  const [completeCleaning] = useMutation(completeCleaningMutation);

  const { data } = useQuery(orTabletProcedureSteps, {
    variables: {
      patientId: patient?.id,
    },
  });

  const procedureState: ProcedureState | null = useMemo(() => {
    if (status === ROOM_STATUSES.CLEANING) {
      return {
        steps: null,
        index: null,
        prev: null,
        next: () => updateRoomStatus(roomId),
        nextLabel: 'Cleaning Completed',
      };
    }

    const steps = data?.orTabletProcedureSteps || [];
    const state = getProcedureState(steps, status as PatientStatus);
    return {
      steps: steps.map((e: ProcedureStep, i: number) => ({
        ...e,
        highlight: i === (state?.index || 0),
        show: i <= (state?.index || 0),
      })),
      index: state?.index || null,
      prev: state?.prev?.type ? withAlertSoundPrev(() => changeStatus(toPatientStatus(state?.prev?.type))) : null,
      next: state?.next?.type ? withAlertSoundNext(() => changeStatus(toPatientStatus(state?.next?.type))) : null,
      nextLabel: state?.next?.name || null,
    };
  }, [data, status, roomId]);

  const [setStatus] = useMutation(setPatientStatus);

  const changeStatus = async (status: PatientStatus | null) => {
    status &&
      (await setStatus({
        variables: {
          id: patient?.id,
          status,
        },
        refetchQueries: [{ query: orTabletProcedureSteps, variables: { patientId: patient?.id } }],
      }));
  };

  const updateRoomStatus = async (id?: number) => {
    id && (await completeCleaning({ variables: { id } }));
  };

  // forward button handler
  // @ts-ignore
  useEffect(() => {
    Mousetrap.bind(
      'l',
      throttle(() => {
        procedureState?.next?.();
      }, 1000)
    );
    return () => Mousetrap.unbind('l');
  });

  // back button handler
  // @ts-ignore
  useEffect(() => {
    Mousetrap.bind(
      'k',
      throttle(() => {
        procedureState?.prev?.();
      }, 1000)
    );
    return () => Mousetrap.unbind('k');
  });

  const nextStepExists = !!procedureState?.next;

  return (
    <Container>
      {nextStepExists ? (
        <TimeOutButton2
          onClick={procedureState?.prev as MouseEventHandler<HTMLButtonElement> | undefined}
          disabled={!procedureState?.prev}
        >
          <WithWorking working={false} size={'1rem'}>
            <Box fontSize={'3.5em'} style={{ transform: `translateY(0.125em)` }}>
              <ChevronLeftIcon fontSize={'inherit'} />
            </Box>
          </WithWorking>
          <ButtonLabel2>{'Undo'}</ButtonLabel2>
        </TimeOutButton2>
      ) : (
        <TimeOutButton2
          onClick={procedureState?.prev as MouseEventHandler<HTMLButtonElement> | undefined}
          disabled={false}
        >
          <WithWorking working={false}>Undo</WithWorking>
        </TimeOutButton2>
      )}

      <Steps>
        {(procedureState?.steps || []).map(
          (procedureStep: ProcedureStep & { highlight: boolean; show: boolean }, index: number) => (
            <ProcedureStepTime key={procedureStep?.id} index={index} procedureStep={procedureStep} patient={patient} />
          )
        )}
      </Steps>

      {nextStepExists ? (
        <MainButton2
          onClick={procedureState?.next as MouseEventHandler<HTMLButtonElement> | undefined}
          disabled={!procedureState?.next}
        >
          <WithWorking working={false} size={'1rem'}>
            <Box fontSize={'3.5em'} style={{ transform: `translateY(0.125em)` }}>
              <ChevronRightIcon fontSize={'inherit'} />
            </Box>
          </WithWorking>
          <ButtonLabel2>{procedureState?.nextLabel}</ButtonLabel2>
        </MainButton2>
      ) : (
        <MainButton2 disabled style={{ filter: 'opacity(0.5)' }}>
          <WithWorking working={false} size={'1rem'}>
            <Box fontSize={'3.5em'} style={{ transform: `translateY(0.125em)` }}>
              <ChevronRightIcon fontSize={'inherit'} />
            </Box>
          </WithWorking>
          <ButtonLabel2>Next</ButtonLabel2>
        </MainButton2>
      )}
    </Container>
  );
};

export const ButtonLabel2 = styled(ButtonLabel)`
  margin: -0.25em;
  font-size: 0.7rem;
  line-height: 1;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 6rem auto 6rem;
  grid-auto-flow: column;
  align-items: center;
  justify-content: stretch;
`;

const Steps = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
`;

const commonButtonStyles = css`
  margin: 0;
  width: 6rem;
  height: 6rem;
  display: grid;
  grid-auto-rows: minmax(0, 1fr);
  grid-auto-flow: row;
  align-items: center;
  text-transform: initial;
  padding: 0.25rem;
`;

export const MainButton2 = styled(MainButton)`
  background-color: ${props => props.theme.textColor.string()};
  border: 0.25rem solid transparent;
  border-radius: 0.65rem;
  color: ${props => props.theme.button.primary.color.string()};
  flex: 1 0 calc(50% - 1.5rem);
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-family: inherit;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin: 0 0.75rem;
  outline: none;
  flex-shrink: 0;
  @media (min-width: 50rem) {
    padding: 1rem 0;
  }

  transition: all 0.3s ease;

  &:active {
    opacity: 0.5;
  }

  &:disabled {
    opacity: 0.75;
  }

  &:active:disabled {
    opacity: 0.75;
  }

  ${commonButtonStyles}
`;

export const TimeOutButton2 = styled(TimeOutButton)`
  background-color: ${props => props.theme.textColor.string()};
  border: 0.25rem solid transparent;
  border-radius: 0.65rem;
  color: ${props => props.theme.button.primary.color.string()};
  text-transform: uppercase;

  > * {
    transition: color 0.3s ease;
  }

  &:disabled {
    opacity: 0.5;

    > * {
      color: rgba(255, 255, 255, 0.25);
    }
  }

  ${responsive.sm.andSmaller`
    margin-top: 0;
  `};

  ${commonButtonStyles}
`;

const Timestamp = styled.div<{ first: boolean }>`
  margin: 0;
  padding: 0;
  > * {
    margin: 0;
    padding: 0;
  }
  display: grid;
  grid-auto-rows: minmax(0, 1fr);
  align-items: center;
`;

const TimestampName = styled.div<{ highlight?: boolean }>`
  font-style: normal;
  line-height: 1;
  color: ${props =>
    props.highlight ? props.theme.textColor.alpha(0.8).string() : props.theme.textColor.alpha(0.6).string()};
`;

const TimestampValue = styled.div<{ highlight?: boolean }>`
  font-style: normal;
  font-weight: 700;

  color: ${props => (props.highlight ? props.theme.textColor.string() : props.theme.textColor.alpha(0.8).string())};
`;

const TimestampValueInput = styled.input<{ highlight?: boolean }>`
  font-style: inherit;
  font-weight: 700;
  color: ${props => (props.highlight ? props.theme.textColor.string() : props.theme.textColor.alpha(0.8).string())};
  z-index: 300;
  position: relative;
  outline: none;
  border: 2px solid #00a7f7;
  border-radius: 0.6rem;
  //background-color: #1f2c61;
  padding: 0;

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
`;

const ProcedureStepTime = ({
  index,
  procedureStep,
  patient,
}: {
  index: number;
  procedureStep: ProcedureStep & { highlight: boolean; show: boolean };
  patient?: Patient & { duration?: string | undefined };
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [save] = useMutation(setProcedureStepTimestamp);

  const initialTime =
    procedureStep?.show && procedureStep?.timestamp ? format(procedureStep?.timestamp, 'HH:mm') : undefined;
  const [edit, setEdit] = useState(false);
  const [time, setTime] = useState(initialTime);

  const canEdit = !!initialTime;
  const trySetEdit = useCallback(() => {
    canEdit && !edit && setEdit(value => !value);
    inputRef?.current?.focus();
    inputRef?.current?.showPicker();
  }, [canEdit, edit]);

  const saveAndClose = useCallback(async () => {
    patient?.id &&
      (await save({
        variables: {
          patientId: patient?.id,
          procedureStepId: procedureStep?.id,
          procedureStepType: procedureStep?.type,
          timestamp: setTimeToDate(new Date(), time),
        },
        refetchQueries: [{ query: orTabletProcedureSteps, variables: { patientId: patient?.id } }],
      }));
    setEdit(false);
  }, [patient, procedureStep, time, save]);

  return (
    <>
      {edit && <Overlay onClick={saveAndClose} />}
      <Timestamp first={index === 0} onClick={trySetEdit}>
        <TimestampName highlight={procedureStep?.highlight}>{procedureStep?.name}</TimestampName>
        {!edit && <TimestampValue highlight={procedureStep?.highlight}>{initialTime || '-'}</TimestampValue>}
        {edit && (
          <TimestampValueInput
            ref={inputRef}
            type="time"
            value={time}
            onChange={e => {
              setTime(e?.target?.value);
            }}
          />
        )}
      </Timestamp>
    </>
  );
};

export default AnesthesiaChartHeader;
