import React from 'react';
import ExpandableText from '../../core/ExpandableText';

export const nameColumn = {
  title: 'Name',
  key: 'name',
  lens: item => item.name,
  style: { maxWidth: '50em' },
  Component: props => <ExpandableText text={props.data} />,
};

export const listColumns = [nameColumn];

export const viewColumns = [nameColumn];
