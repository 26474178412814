import { ScheduleUserType } from '../../../../types/ScheduleUser';
import { useQuery } from '@apollo/client';
import { hasScheduleAccessAllRight } from '../../../../graph/procedures';

const useHasScheduleAccessAllRight = (scheduleUserId?: number, scheduleUserType?: ScheduleUserType) => {
  const { data } = useQuery(hasScheduleAccessAllRight, {
    variables: {
      scheduleUserId,
      scheduleUserType,
    },
    skip: !scheduleUserId,
  });

  return !!data?.hasScheduleAccessAllRight;
};

export default useHasScheduleAccessAllRight;
