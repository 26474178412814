import React, { FC } from 'react';
import { Procedure as ProcedureT } from '../../../../../../types/Procedure';
import { Anesthesiologist, Physician } from '../../anesthesiologist/Personnel';
import { Bed } from '../../Patient';
import { PatientStat } from '../../shared/PatientStat';
import { getColor } from '../Procedure';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const Label = ({ text, backgroundColor }: { text: string; backgroundColor: string }) => (
  <Box borderRadius={4} bgcolor={backgroundColor} style={{ fontWeight: 'bold', color: 'white' }} px={0.5}>
    {text}
  </Box>
);

const CardCompactHeader: FC<{
  procedure: ProcedureT;
  physicianName?: string;
  anesthesiologistName?: string;
  anesthesiaType?: string;
  isCanceled?: any;
  noShow: boolean;
  color: string;
  status: string;
  showBedNumber: boolean;
  stretch?: boolean;
  hideStartTime?: boolean;
  inOrStatus: string;
  hospitalId?: number;
}> = ({
  procedure,
  physicianName,
  anesthesiologistName,
  anesthesiaType,
  isCanceled,
  noShow,
  color,
  status,
  stretch,
  hideStartTime,
  showBedNumber,
  inOrStatus,
  hospitalId,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Physician physicianName={physicianName} color={color} inline />
        <Box display="flex" alignItems="center" style={{ gap: 8 }}>
          <Anesthesiologist
            procedureId={procedure.id}
            anesthesiologistName={anesthesiologistName}
            anesthesiaType={anesthesiaType}
            inline
            style={{ fontWeight: 400 }}
          />

          <PatientStat inline right>
            {/* {isCanceled ? 'Canceled' : noShow ? 'NoShow' : status} */}
            <Label backgroundColor={getColor(status, hospitalId)} text={inOrStatus ? inOrStatus : status} />
            {showBedNumber && !!procedure?.patient?.bed && <Bed bed={procedure?.patient?.bed} />}
          </PatientStat>
        </Box>
      </Box>
      <Box className={classes.procedure}>{procedure.procedureType.replaceAll('\n', ' ')}</Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    lineHeight: 'normal',
    paddingLeft: '0.375em',
    height: '100%',
    gap: '0.25em',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  procedure: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block',
  },
}));

export default CardCompactHeader;
