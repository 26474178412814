export const PhysicianCategory = {
  Surgical: 'Surgical',
  Eye: 'Eye',
  Pain: 'Pain',
  Endoscopy: 'Endoscopy',
};

export const PhysicianCategoryLabels = {
  [PhysicianCategory.Surgical]: 'Surgical',
  [PhysicianCategory.Eye]: 'Eye',
  [PhysicianCategory.Pain]: 'Pain',
  [PhysicianCategory.Endoscopy]: 'Endoscopy',
};

export const PhysicianSpecialityColors = {
  ORT: { color: '#50E3C2' },
  ORTHO: { color: '#B057FF' },
  PLS: { color: '#FF5ABB' },
  'PLS/HAND': { color: '#F55323' },
  URO: { color: '#B29377' },
  NEURO: { color: '#FFAC00' },
  GI: { color: '#9B9B9B' },
  GEN: { color: '#4BE3B1' },
  ENT: { color: '#54BFE3' },
  CV: { color: '#AAF541' },
  default: { color: '#c0c0c0' },
};
