import React, { FC, useState } from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { useScope } from '../../../../../hooks/useScope';
import { StaffSchedulePdfGenerator, RoomWithProcedureFlag } from './versions/v1/StaffSchedulePdfGenerator';
import Scope from '../../../../../types/Scope';
import { generateSchedulePdf } from './versions/schedulePDFGenerator';
import { Room } from '../../../../../types/Room';
import { schedulePdfsOptions } from '../../../../inputs/SchedulePdfsMultipleSelectInput';

const PDFScheduleButton: FC<{ date: Date; staffPerRoom: RoomWithProcedureFlag[]; operationRooms?: Room[]; }> = ({ date, staffPerRoom, operationRooms }) => {
  const scope: Scope = useScope();
  const schedulePDFs = scope?.hospital?.modules?.schedulePDFs;
  const hospitalName = scope?.hospital?.name || '';

  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | (EventTarget & HTMLAnchorElement) | null
  >(null);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!schedulePDFs || (schedulePDFs || []).length === 0) {
    return (
      <Tooltip title={'Download Staff Schedule'}>
        <IconButton onClick={() => StaffSchedulePdfGenerator(hospitalName, date, staffPerRoom)} color="primary">
          <PictureAsPdfIcon/>
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <>
      <Tooltip title={'Pick Staff Schedule to Download'}>
        <IconButton onClick={handleClick} color="primary">
          <PictureAsPdfIcon/>
        </IconButton>
      </Tooltip>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {(schedulePdfsOptions.filter(e => !!(schedulePDFs || [])?.find(el => e?.value === el?.value))).map(e => (<MenuItem onClick={() => generateSchedulePdf(e.value)?.(hospitalName, date, staffPerRoom, operationRooms) }>
          {e.label}
        </MenuItem>))}
      </Menu>
    </>
  );
};

export default PDFScheduleButton;
