import Box from '@material-ui/core/Box';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { createStyles, fade, makeStyles } from '@material-ui/core';

const Procedures = ({ from, to, procedures, onClick, ...props }) => {
  const classes = useStyles();

  return (
    <Box
      flex={1}
      component="button"
      onClick={() => onClick({ from, to, procedures, ...props })}
      className={classes.root}
    >
      <Typography variant="subtitle2">!</Typography>
    </Box>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.primary,
      backgroundColor: fade(theme.palette.warning.dark, 1),
      paddingLeft: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      border: `2px solid ${theme.palette.warning.main}`,
      borderLeftWidth: `.5rem`,

      transition: 'background .05s ease-in-out',
      cursor: 'pointer',
      outline: 'none',

      '&:hover': {
        backgroundColor: fade(theme.palette.warning.main, 0.5),
      },
    },
  })
);

export default Procedures;
