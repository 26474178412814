import React from 'react';
import styled from 'styled-components';
import TextField from './TextField';
import get from 'lodash/get';
import defaultTo from 'lodash/defaultTo';
import { withTheme } from '../../../../se/theme';

const Wrapper = styled.div`
  border-top: 1px solid ${withTheme(theme => theme.border.color.default.string())};
  border-bottom: 1px solid ${withTheme(theme => theme.border.color.default.string())};
  margin-bottom: 0.5em;
`;

export default ({ value }) => {
  const { month = '-', day = '-', year = '-' } = defaultTo(get(value, 'dateOfBirth', {}), {});
  return (
    <Wrapper>
      <TextField label={'Date of Birth'} value={value ? `${month}/${day}/${year}` : ''} />
    </Wrapper>
  );
};
