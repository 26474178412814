import React from 'react';

interface FormContext<T> {
  procedureId: number;
  name: string;
  value: T;
  onChange: (value: T) => void;
}

export default React.createContext<FormContext<unknown>>(null as any);
