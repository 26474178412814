import { differenceInYears } from 'date-fns';
import emptyReducer from './emptyReducer';

export const age65AndOlderYes = emptyReducer<boolean>(false).with({
  DateOfBirth: date => (date ? differenceInYears(new Date(), date) >= 65 : false),
});

export const age65AndOlderNo = emptyReducer<boolean>(false).with({
  DateOfBirth: date => (date ? differenceInYears(new Date(), date) < 65 : false),
});

export const age50AndOlderYes = emptyReducer<boolean>(false).with({
  DateOfBirth: date => (date ? differenceInYears(new Date(), date) >= 50 : false),
});

export const age40AndOlderYes = emptyReducer<boolean>(false).with({
  DateOfBirth: date => (date ? differenceInYears(new Date(), date) >= 40 : false),
});

export const age50AndOlderNo = emptyReducer<boolean>(false).with({
  DateOfBirth: date => (date ? differenceInYears(new Date(), date) < 50 : false),
});

export const ageOver50Yes = emptyReducer<boolean>(false).with({
  DateOfBirth: date => (date ? differenceInYears(new Date(), date) > 50 : false),
});

export const ageOver50No = emptyReducer<boolean>(false).with({
  DateOfBirth: date => (date ? differenceInYears(new Date(), date) <= 50 : false),
});

export const ageLess3More65 = emptyReducer<boolean>(false).with({
  DateOfBirth: date =>
    date ? differenceInYears(new Date(), date) < 3 && differenceInYears(new Date(), date) > 65 : false,
});

export const ageFrom60to69YearsOld = emptyReducer<boolean>(false).with({
  DateOfBirth: date =>
    date ? differenceInYears(new Date(), date) >= 60 && differenceInYears(new Date(), date) <= 69 : false,
});

export const ageFrom70to79YearsOld = emptyReducer<boolean>(false).with({
  DateOfBirth: date =>
    date ? differenceInYears(new Date(), date) >= 70 && differenceInYears(new Date(), date) <= 79 : false,
});

export const ageFrom41to60YearsOld = emptyReducer<boolean>(false).with({
  DateOfBirth: date =>
    date ? differenceInYears(new Date(), date) >= 41 && differenceInYears(new Date(), date) <= 60 : false,
});

export const ageFrom61to74YearsOld = emptyReducer<boolean>(false).with({
  DateOfBirth: date =>
    date ? differenceInYears(new Date(), date) >= 61 && differenceInYears(new Date(), date) <= 74 : false,
});

export const ageFrom75YearsOld = emptyReducer<boolean>(false).with({
  DateOfBirth: date => (date ? differenceInYears(new Date(), date) >= 75 : false),
});

export const ageFrom80YearsOld = emptyReducer<boolean>(false).with({
  DateOfBirth: date => (date ? differenceInYears(new Date(), date) >= 80 : false),
});

const calculateBMI = (weightLb: number, heightFt: number, heightIn: number) => {
  const heightInInches = 12 * heightFt + heightIn;
  const bodyMassIndex = (703 * weightLb) / heightInInches / heightInInches;
  return isFinite(bodyMassIndex) ? bodyMassIndex.toFixed(0) : 0;
};

export const bmiGreaterThan30 = emptyReducer<boolean>(false).with({
  BodyMassIndex: (heightFt, heightIn, weightLb) =>
    heightFt && weightLb ? calculateBMI(weightLb, heightFt, heightIn ? heightIn : 0) > 30 : false,
});

export const bmiGreaterThan35 = emptyReducer<boolean>(false).with({
  BodyMassIndex: (heightFt, heightIn, weightLb) =>
    heightFt && weightLb ? calculateBMI(weightLb, heightFt, heightIn ? heightIn : 0) > 35 : false,
});

export const bmiGreaterorEqual35 = emptyReducer<boolean>(false).with({
  BodyMassIndex: (heightFt, heightIn, weightLb) =>
    heightFt && weightLb ? calculateBMI(weightLb, heightFt, heightIn ? heightIn : 0) >= 35 : false,
});

export const bmiLessThanOrEqual35 = emptyReducer<boolean>(false).with({
  BodyMassIndex: (heightFt, heightIn, weightLb) =>
    heightFt && weightLb ? calculateBMI(weightLb, heightFt, heightIn ? heightIn : 0) <= 35 : false,
});

export const toBooleanNegative = emptyReducer<boolean>(false).with({
  YesNo: (question, positive) => positive === false,
});

export const toBooleanPositive = emptyReducer<boolean>(false).with({
  YesNo: (question, positive) => positive === true,
});

export const toMale = emptyReducer<boolean>(false).with({
  OneOf: (value, options, option) => (option ? option.label === 'Male' : false),
});

export const toFemale = emptyReducer<boolean>(false).with({
  OneOf: (value, options, option) => (option ? option?.label === 'Female' : false),
});

export const smoker = emptyReducer<boolean>(false).with({
  OneOf: (value, options, option) => (option ? option?.label === 'Current Smoker' : false),
});

export const neckSizeYes = emptyReducer<boolean>(false).with({
  OneOf: (value, options, option) => (option ? option?.label === 'Yes' : false),
});

export const neckSizeNo = emptyReducer<boolean>(false).with({
  OneOf: (value, options, option) => (option ? option?.label === 'No' : false),
});

export const neckSizeNotSure = emptyReducer<boolean>(false).with({
  OneOf: (value, options, option) => (option ? option?.label === 'Not Sure' : false),
});

export const testReducer = (checkBoxLabel: string) =>
  emptyReducer<boolean>(false).with({
    OneOf: (label, options, answer) =>
      options.filter(option => option.label === answer?.label)?.[0]?.selected &&
      answer?.label.toLowerCase() === checkBoxLabel,
  });
