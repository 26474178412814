import React, { useEffect, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import { formSaveTypeLabels } from '../entities/procedures/enums';
import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import { list } from '../../graph/surgeon/forms';
import groupBy from 'lodash/groupBy';

const FormSectionsInput = ({ onChange, value = [], disabled = false }) => {
  const state = value.reduce((acc, curr) => ({ ...acc, [curr]: true }), {});

  const setState = values => {
    const formIds = Object.entries(values)
      .filter(([_, value]) => !!value)
      .map(([key, _]) => parseInt(key, 10))
      .sort((a, b) => reportForms.find(x => x.id === a).order - reportForms.find(x => x.id === b).order);

    onChange(formIds);
  };

  const { data: formQuery } = useQuery(list);
  const reportForms = useMemo(() => get(formQuery, 'forms', []), [formQuery]);

  const sections = useMemo(() => groupBy(reportForms, _ => _.saveType), [reportForms]);

  const handleCheckboxChange = (id, checked) => {
    setState({ ...state, [id]: checked });
  };

  const selectAll = () => setState(reportForms.reduce((acc, curr) => ({ ...acc, [curr.id]: true }), {}));

  useEffect(() => {
    setState(reportForms.reduce((acc, curr) => ({ ...acc, [curr.id]: value.includes(curr.id) }), {}));
    // eslint-disable-next-line
  }, [reportForms]);

  const deselectAll = () => {
    setState(Object.keys(state).reduce((acc, curr) => ({ ...acc, [curr]: false }), {}));
  };

  return (
    <Box display="flex" flexDirection="row" width="100%">
      <FormGroup style={{ width: '100%' }}>
        <Grid container>
          <Grid item xs={12}>
            <Box mb={2}>
              <Button color="primary" size="small" onClick={selectAll} disabled={disabled}>
                Select all
              </Button>
              <Button color="primary" size="small" onClick={deselectAll} disabled={disabled}>
                Deselect all
              </Button>
            </Box>
          </Grid>
          {Object.entries(sections)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([type, items], index) => (
              <Grid item md={6} key={`section-patient-file-generator-${index}`}>
                <Typography variant="subtitle1" gutterBottom>
                  {formSaveTypeLabels[type]}
                </Typography>
                <Box display="flex" flexDirection="column">
                  {items.map(item => (
                    <FormControlLabel
                      key={`${type}-${item.id}`}
                      control={
                        <Checkbox
                          checked={state[item.id] || false}
                          onChange={e => handleCheckboxChange(item.id, e.target.checked)}
                          name={item.name}
                          disabled={disabled}
                        />
                      }
                      label={item.name}
                    />
                  ))}
                </Box>
              </Grid>
            ))}
        </Grid>
      </FormGroup>
    </Box>
  );
};

export default FormSectionsInput;
