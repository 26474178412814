import React from 'react';
import { Checkbox } from '@material-ui/core';
import { CheckboxAnswer } from '../../types/Answer';
import { CheckboxQuestion } from '../../types/Question';

type CheckboxInputProps = CheckboxQuestion['props'] & {
  name?: string;
  answer?: CheckboxAnswer;
  onChange?: (newAnswer: CheckboxAnswer) => void;
};

const CheckboxInput = ({ answer, onChange }: CheckboxInputProps) => (
  <Checkbox color="primary" checked={answer} onChange={e => onChange?.(e.target.checked)} />
);

export default CheckboxInput;
