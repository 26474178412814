import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { ListQuestion } from '../../types/Question';
import { Answer, ListAnswer } from '../../types/Answer';
import { Box } from '@material-ui/core';
import ListInput from '../questions/ListInput';
import { ChartsContext, ConditionContext, MedicationsContext, Tags } from '../Form';
import jp from 'jsonpath';
import { tryParseJson } from '../../util/parseJson';

interface ListFormProps {
  children: ReactNode[] | undefined;
  tags: Tags;
  question: ListQuestion['props'];
  answer?: Answer<ListQuestion>;
  onChange?: (newAnswer: Answer<ListQuestion>) => void;
}

interface SetAnswerProps<TValue> {
  setter: (value: TValue) => void;
  value: TValue;
}

const SetAnswer = <TValue,>({ setter, value }: SetAnswerProps<TValue>) => {
  useEffect(() => {
    setter(value);
  }, []);

  return null;
};

const ListForm = ({ children, tags, question, answer, onChange }: ListFormProps) => {
  const medications = useContext(MedicationsContext);
  const charts = useContext(ChartsContext);

  useEffect(() => {
    if (!onChange || !question.preFill || (answer && answer.length > 0)) {
      return;
    }

    switch (question.preFill.type) {
      case 'Medication':
        if (!Array.isArray(medications)) {
          return;
        }

        const fieldsMap = question.preFill.map;

        onChange(
          medications.map(medication =>
            Object.fromEntries(Object.entries(fieldsMap).map(([key, path]) => [key, jp.query(medication, path)[0]]))
          )
        );
        break;
      case 'Questionnaire':
        const { questionnaireId, path, map } = question.preFill;
        const chart = charts?.find(chart => chart.questionnaire.id === questionnaireId);

        if (!chart) {
          return;
        }

        const answers = tryParseJson(chart.questionnaireExchange?.answers);

        if (!answers) {
          return;
        }

        const list = jp.query(answers, path)[0];

        if (!Array.isArray(list)) {
          return;
        }

        onChange(
          list.map(item => Object.fromEntries(Object.entries(map).map(([key, path]) => [key, jp.query(item, path)[0]])))
        );
        break;
      default:
        return;
    }
  }, [question, answer, charts, medications, onChange]);

  return (
    <Box>
      <ConditionContext.Consumer>
        {condition => (
          <>
            {condition && (!answer || answer.length === 0) && (
              <SetAnswer setter={onChange as any} value={[null] as any} />
            )}
            <ListInput
              {...question}
              answer={answer}
              onChange={onChange as (newAnswer: ListAnswer) => void}
              children={children}
            />
          </>
        )}
      </ConditionContext.Consumer>
    </Box>
  );
};

export default ListForm;
