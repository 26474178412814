import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { setUndoBlockNerve } from '../../../../graph/patients';

const withUndoBlockNerve = Component => props =>
  (
    <Mutation mutation={setUndoBlockNerve}>
      {setUndoBlockNerve => <Component {...props} setUndoBlockNerve={setUndoBlockNerve} />}
    </Mutation>
  );

export default withUndoBlockNerve;
