import { gql } from '@apollo/client';
import { fragments as proceduresFragments } from './procedures';
import { QuestionnaireType } from '../types/Questionnaire';
import { getChartingDatQueryName } from '../components/pages/kiosk/charting/utils';

export const procedureBaseFields = `
  id
  startTime
  startTimeText
  endTimeText
  duration
  orId
  or
  isCanceled
  patientId
  patientHstId
  patientName
  patientInitials
  patientDateOfBirth
  patientDateOfBirthISO
  patientAge
  patientSex
  patientHomePhone
  patientCellPhone
  caretakerCellPhone
  familyCellPhone
  patientEmailAddress
  procedureTypeId
  procedureType
  procedureMod
  diagnosis
  equipment
  cptCodes
  bilaterally
  visitType
  room
  insuranceName
  policyName
  anesthesiaType
  anesthesiaReviewed
  allergies
  notes
`;

const ChartingData = `
  id
  questionnaire {
    id
    name
    icon
    type
    content
    order
  }
  questionnaireExchange {
    id
    procedureId
    answers
    submittedAt
  }
  completed
  defaultAnswers
`;

const ChartingCompleted = `
  id
  completed
`;

export const AllChartingData = `
  preOpCharts {
    ${ChartingData}
  }
  orCharts {
    ${ChartingData}
  }
  pacuCharts {
    ${ChartingData}
  }
  physicianCharts {
    ${ChartingData}
  }
  anesthesiologistPreOpCharts {
    ${ChartingData}
  }
  anesthesiologistOrCharts {
    ${ChartingData}
  }
  anesthesiologistPacuCharts {
    ${ChartingData}
  }
`;

export const fragments = {
  item: gql`
    fragment PatientWithProcedureData on Patient {
      id
      hstId
      name
      initials
      braceletId
      type
      notificationNumbers
      procedureType {
        id
        name
      }
      physician {
        id
        hstId
        name
      }
      room {
        id
        name
        type
      }
      status
      phoneNumber
      bed
      priority
      icon
      color
      createdAt
      changedRoomAt
      dischargedAt
      voidedAt
      note
      caretakerConsent
      caretakerMessages {
        phoneNumber
        content
      }
      caretakerThread {
        id
        hasUnreadMessages
      }
      patientThread {
        id
        hasUnreadMessages
      }
      procedure {
        ${procedureBaseFields}
        ${AllChartingData}
        anesthesiologist {
          id
          name
        }
        entryQuestionnaire {
          ...Questionnaire
        }
        events {
          id
          type
          timestamp
          patientPin
          channel
          content
          user {
            id
            name
          }
          text
          status
          anesthesiaReviewed
          exchangeId
        }
        fallRiskAssessment
        medications
      }
    }
    ${proceduresFragments.questionnaire}
  `,
  listItem: (questionnaireType: QuestionnaireType) => gql`
    fragment PatientChartingList on Patient {
      id
      hstId
      name
      procedureType {
        id
        name
      }
      physician {
        id
        hstId
        name
      }
      room {
        id
        name
        type
      }
      status
      createdAt
      changedRoomAt
      dischargedAt
      voidedAt
      procedure {
        id
        anesthesiologist {
          id
          name
        }
        anesthesiaType
        ${getChartingDatQueryName(questionnaireType)} {
          ${ChartingCompleted}
        }
      }
    }
  `,
};

export const item = gql`
  subscription patient($id: Long!) {
    patient(id: $id) {
      ...PatientWithProcedureData
      log {
        id
        entries {
          type
          room {
            id
            name
            type
          }
          manualEntryBy {
            id
            name
          }
          status
        }
        timestamp
        createdBy {
          id
          name
          superRole
        }
        createdAt
      }
    }
  }
  ${fragments.item}
`;

export const itemByProcedureId = gql`
  subscription patientByProcedureId($procedureId: Long!) {
    patient: patientByProcedureId(procedureId: $procedureId) {
      ...PatientWithProcedureData
      log {
        id
        entries {
          type
          room {
            id
            name
            type
          }
          manualEntryBy {
            id
            name
          }
          status
        }
        timestamp
        createdBy {
          id
          name
          superRole
        }
        createdAt
      }
    }
  }
  ${fragments.item}
`;

export const list = (questionnaireType: QuestionnaireType) => gql`
  subscription patients($filter: Filter) {
    patients: activeAndPastPatients(filter: $filter) {
      ...PatientChartingList
    }
  }
  ${fragments.listItem(questionnaireType)}
`;

export const listPhysicianPatients = (physicianId: number, questionnaireType: QuestionnaireType) => gql`
  subscription patients($filter: Filter) {
    patients: physicianActiveAndPastPatients(physician: ${physicianId}, filter: $filter) {
      ...PatientChartingList
    }
  }
  ${fragments.listItem(questionnaireType)}
`;

export default {
  list,
  item,
};
