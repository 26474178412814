import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import { listAllGateways } from '../../../graph/gateways';
import React from 'react';
import { getColumns } from '../../pages/gateway/Gateways';
import { withProps } from 'recompose';
import Filters from '../../pages/analytics/Filters';
import { NamedRange } from '../../core/DatePicker';
import { TruncatedText } from '../../../se/components/typography';
import TitleAction from '../../../se/components/TitleAction';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { HospitalGateway } from '../../../types/Gateway';

const Text = TruncatedText as any;

const GatewayTitle = () => (
  <Box display="flex" alignItems="center" style={{ gap: '1rem' }}>
    <Typography variant="h2">Gateways</Typography>
  </Box>
);

export default GraphQLCRUD({
  entityName: 'HospitalGateway',
  List: {
    TitleAndActions: () => <TitleAction Title={GatewayTitle} />,
    Title: () => <div />,
    tableKey: 'HospitalGateway',
    columns: [
      {
        title: 'Hospital',
        lens: (data: HospitalGateway) => data.hospital?.name,
        Component: (props: { data: string }) => <Text>{props.data}</Text>,
      },
      ...getColumns(true),
    ],
    FilterComponent: withProps({
      hidePhysicianSelectInput: true,
      hideProcedureTypeSelectInput: true,
      hideHospitalSelectInput: false,
      hideDateFilter: true,
    })(Filters),
    defaultFilterValues: {
      dateRange: NamedRange.lastSevenDays(),
    },
  },
  scheme: {
    list: listAllGateways,
  },
});
