import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import type { ZodType, ZodTypeDef } from 'zod';

export default function useValidatedQuery<TData, TVariables>(
  type: ZodType<TData, ZodTypeDef, TData>,
  ...args: Parameters<typeof useQuery<TData, TVariables>>
): ReturnType<typeof useQuery<TData, TVariables>> {
  const query = useQuery<TData, TVariables>(...args);

  useEffect(() => {
    if (!query.data) {
      return;
    }

    const result = type.safeParse(query.data);

    if (!result.success) {
      console.warn('Unexpected data received', result.error);
    }
  }, [query.data]);

  return query;
}
