import React, { FC } from 'react';
import { useSession } from '../../../state/Session';
import { unpackSessionObject } from '../unpackSessionObject';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Theme } from '@material-ui/core';
import RouterLink from '../../../se/components/RouterLink';
import PTOCalendar from './PTOCalendar';
import { a11yProps } from '../../core/TabPanel';
import HolidayCalendar from './HolidayCalendar';
import { Header } from '../sensor/SensorPage';
import { generatePath, Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import StaffMemberCalendar, { StaffMemberPicker } from './StaffMemberCalendar';
import EntityState from '../../../se/components/EntityState';
import users from '../../../assets/images/illustrations/users.svg';
import { ArrowBack } from '@material-ui/icons';
import { Pill, Pills } from '../../core/TabRoutes';
import { useScope } from '../../../hooks/useScope';

export enum CalendarRouteParam {
  Calendar = 'staff',
  PTOCalendar = 'pto',
  HolidayCalendar = 'holiday',
}

export type Params = {
  organizationId: string;
  tab: CalendarRouteParam;
  staffId: string;
};

const EmptyStaffCalendarPage: FC<RouteComponentProps<Params>> = props => {
  const classes = useStyles();
  const { match, history } = props;
  return (
    <CalendarTabs {...props}>
      <Box className={classes.emptyState}>
        <EntityState
          title="Please select staff member to see their calendar"
          hint="The calendar will be displayed after a staff member is selected"
          illustration={users}
        >
          <Box my={4}>
            <StaffMemberPicker history={history} baseUrl={`/kiosk/${match.params.organizationId}/calendar/staff`} />
          </Box>
        </EntityState>
      </Box>
    </CalendarTabs>
  );
};

export const CalendarTabs: FC<RouteComponentProps<Params>> = ({ match, history, children }) => {
  const classes = useStyles();
  const scope = useScope();
  const session = useSession();
  const { isAdmin, isSuperAdmin, isGroupAdmin } = unpackSessionObject(session);

  const organizationId = scope?.hospital?.id;
  const isPowerUser = isSuperAdmin || isGroupAdmin || isAdmin;

  const handleChange = (_: any, tab: CalendarRouteParam) => {
    history.replace(generatePath(match.path.replace('/:staffId', ''), { ...match.params, tab }));
  };

  return (
    <div className={classes.page}>
      <Header className={classes.narrowHeader}>
        <Box className={classes.titleStack}>
          <Box className={classes.nav}>
            <RouterLink to={isAdmin ? '/' : `/su/${organizationId}`}>
              <ArrowBack fontSize={'small'} />
              {` Back${isPowerUser ? ' to admin' : ''}`}
            </RouterLink>
            <Box ml={2}>
              <Pills
                indicatorColor={'primary'}
                value={match.params.tab}
                variant="scrollable"
                onChange={handleChange}
                aria-label="surgery tabs"
              >
                <Pill label="Calendar" value={CalendarRouteParam.Calendar} {...a11yProps(0)} />
                <Pill label="PTO Calendar" value={CalendarRouteParam.PTOCalendar} {...a11yProps(1)} />
                <Pill label="Holiday Calendar" value={CalendarRouteParam.HolidayCalendar} {...a11yProps(2)} />
              </Pills>
            </Box>
          </Box>
          <Box mt={2}>
            {match.params.staffId && (
              <StaffMemberPicker
                history={history}
                baseUrl={`/kiosk/${match.params.organizationId}/calendar/staff`}
                staffId={match.params.staffId}
              />
            )}
          </Box>
        </Box>
      </Header>
      {children}
    </div>
  );
};

const MultipleCalendarPage: FC<RouteComponentProps<Params>> = ({ match, history, location }) => (
  <Switch>
    <Route
      path={`${match.path}/:tab/:staffId`}
      render={props => {
        switch (props.match.params.tab) {
          case 'staff':
            return <StaffMemberCalendar {...props} />;
          default:
            return <Redirect to={`${match.url}/staff`} />;
        }
      }}
    />
    <Route
      exact
      path={`${match.path}/:tab`}
      render={props => {
        switch (props.match.params.tab) {
          case 'staff':
            return <EmptyStaffCalendarPage {...props} />;
          case 'pto':
            return <PTOCalendar {...props} />;
          case 'holiday':
            return <HolidayCalendar {...props} />;
          default:
            return <Redirect to={`${match.url}/staff`} />;
        }
      }}
    />
    <Route render={() => <Redirect to={`${match.url}/staff`} />} />
  </Switch>
);

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.text.primary,
    padding: '2em 3em',
  },
  nav: {
    display: 'flex',
    flexDirection: 'row',
  },
  titleStack: {
    display: 'flex',
    flexDirection: 'column',
  },
  narrowHeader: {
    marginBottom: '1em',
    position: 'sticky',
    top: 0,
    padding: '20px 0',
    zIndex: 50,
    background: '#000c3f',
  },
  emptyState: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.text.primary,
    padding: '2em 3em',
  },
}));

export default withRouter(MultipleCalendarPage);
