import React from 'react';
import { alpha, Box } from '@material-ui/core';
import ChartingPatients from './ChartingPatients';
import { ThemeProvider } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import FooterSession from '../tablet/components/FooterSession';
import { useChartingSession } from './modules/hooks';
import { withPin } from './modules/withPin';

const ChartingPage = withPin()(() => {
  const classes = useStyles();
  const chartingSession = useChartingSession();
  return (
    <Box
      position="fixed"
      top={0}
      bottom={0}
      left={0}
      right={0}
      padding={4}
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      justifyContent="start"
      overflow="auto"
    >
      <ThemeProvider theme={{ gridListColumnsTemplate: 'auto auto auto minmax(10vw,2fr) auto' }}>
        <ChartingPatients chartingSession={chartingSession} />
      </ThemeProvider>
      <Box className={classes.footer}>
        <FooterSession />
      </Box>
    </Box>
  );
});

export default ChartingPage;

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: alpha(theme.palette.common.white, 0.8),
    backdropFilter: 'blur(10px)',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    position: 'fixed',
    bottom: 0,
    left: 0,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    borderTop: `1px solid ${theme.palette.divider}`,
    width: '100%',
  },
}));
