export const SurgeryStatus = {
  SurgeryPlanned: 'SurgeryPlanned',
  SurgeryScheduled: '{"__typename":"Requested","seen":false}',
  SurgeryRequestReceived: '{"__typename":"Requested","seen":true}',
  SurgeryCanceled: '{"__typename":"Cancelled"}',
  SurgeryRescheduled: '{"__typename":"Rescheduled"}',
  SurgeryConfirmed: '{"__typename":"Confirmed"}',
};

export const SurgeryStatusesHospital = {
  [SurgeryStatus.SurgeryScheduled]: 'To be Scheduled (New)',
  [SurgeryStatus.SurgeryRequestReceived]: 'To be Scheduled',
  [SurgeryStatus.SurgeryConfirmed]: 'Case Scheduled',
  [SurgeryStatus.SurgeryRescheduled]: 'Surgery Rescheduled',
  [SurgeryStatus.SurgeryCanceled]: 'Surgery Canceled',
};

export const SurgeryStatusesSurgeonOffice = {
  [SurgeryStatus.SurgeryScheduled]: 'Surgery Scheduled (New)',
  [SurgeryStatus.SurgeryRequestReceived]: 'Surgery Scheduled',
  [SurgeryStatus.SurgeryConfirmed]: 'Surgery Confirmed',
  [SurgeryStatus.SurgeryRescheduled]: 'Surgery Rescheduled',
  [SurgeryStatus.SurgeryCanceled]: 'Surgery Canceled',
};
