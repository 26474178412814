import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { H2 } from '../../../se/components/typography';
import responsive from '../../../se/utilities/responsive';
import { Box, Grid, Paper } from '@material-ui/core';
import { compose, withProps } from 'recompose';
import { withLabel } from '../../../se/components/Label';
import TextInput from '../../../se/components/inputs/TextInput';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import ToggleInput from '../../../se/components/inputs/ToogleInput';
import { withDescription } from '../../../se/components/Description';
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import Form from '../../../se/components/Form';
import Spinner from '../../../se/components/Spinner';
import EntityErrorState from '../../../se/components/entity-states/EntityErrorState';

export const Title = styled(H2)`
  margin: 0.5em 0;
  ${responsive.md.andSmaller`
    font-size: 1.25rem;
  `}
`;

const q = gql`
  query ipFiltering {
    ipFiltering {
      ipAddressWhitelistEnabled
      ipAddressWhitelist
    }
  }
`;

const IPFiltering = () => {
  const data = useQuery(q);

  const [mutate, mutation] = useMutation(
    gql`
      mutation setIPFiltering($ipAddressWhitelistEnabled: Boolean!, $ipAddressWhitelist: [String!]!) {
        setIPFiltering(ipAddressWhitelistEnabled: $ipAddressWhitelistEnabled, ipAddressWhitelist: $ipAddressWhitelist) {
          ipAddressWhitelistEnabled
          ipAddressWhitelist
        }
      }
    `,
    {
      refetchQueries: [{ query: q }],
    }
  );

  const handleSubmit = value =>
    mutate({
      variables: {
        ipAddressWhitelistEnabled: value.ipAddressWhitelistEnabled,
        ipAddressWhitelist: value.ipAddressWhitelist.split(',').map(ip => ip.trim()),
      },
    }).then(() => location.reload());

  const initialValue =
    data.data && data.data.ipFiltering
      ? {
          ipAddressWhitelistEnabled: data.data.ipFiltering.ipAddressWhitelistEnabled,
          ipAddressWhitelist: data.data.ipFiltering.ipAddressWhitelist.join(','),
        }
      : {
          ipAddressWhitelistEnabled: false,
          ipAddressWhitelist: '',
        };

  const [formKey, setFormKey] = useState(0);

  useEffect(() => {
    if (mutation.called && mutation.loading === false) {
      setFormKey(prev => prev + 1);
    }
  }, [mutation.called, mutation.loading]);

  return (
    <Box p={2}>
      <Box mb={4}>
        <Title>IP Filtering</Title>
      </Box>
      <Grid container spacing={1} alignItems="center">
        {/* @ts-ignore */}
        <Grid item xs={6}>
          <Paper>
            <Box p={4}>
              {data.loading ? (
                <Spinner />
              ) : data.error ? (
                <EntityErrorState error={data.error} />
              ) : (
                <Form
                  key={formKey}
                  autoFocus
                  initialValue={initialValue}
                  input={Input}
                  label={'Update'}
                  onSubmit={handleSubmit}
                  context={{
                    isCreate: false,
                    isEdit: true,
                  }}
                />
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IPFiltering;

const Input = withProps({
  schema: {
    ipAddressWhitelistEnabled: withProps({ label: 'IP Address Whitelist' })(ToggleInput),
    ipAddressWhitelist: compose(
      withLabel('IP Addresses Whitelist'),
      withDescription('To add multiple IP addresses, separate them with a comma.')
    )(TextInput),
  },
})(ObjectInput);
