import React from 'react';
import styled from 'styled-components';
import { Room } from '../../../../../types/Room';

import AnesthesiologistUtilityRoom from './AnesthesiologistUtilityRoom';

const Anes = styled.div<{ inline?: boolean; condensView?: boolean }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex: ${props => (props.inline ? '0 1 auto' : '1 0 auto')};
  justify-content: ${props => (props.condensView ? 'space-between' : 'flex-end')};
  background-color: ${props => (props.condensView ? 'rgba(255,255,255,0.1)' : undefined)};
  padding: ${props => (props.condensView ? '0px 5px 0px 3px;' : undefined)};
  margin-bottom: ${props => (props.condensView ? '0' : undefined)};
  font-size: ${props => (props.condensView ? '1.33em' : 'inherit')};
  line-height: inherit;
`;

export const Anesthesiologist = ({
  date,
  roomId,
  inline,
  style,
  roomType,
  room,
  isKiosk,
}: {
  date?: Date;
  roomId: number;
  inline?: boolean;
  style?: React.CSSProperties;
  roomType?: string;
  room?: Room;
  isKiosk?: boolean;
}) => (
  <Anes condensView={false} inline={inline} style={style} onClick={e => e.stopPropagation()}>
    <AnesthesiologistUtilityRoom date={date} roomId={roomId} roomType={roomType} room={room} isKiosk={isKiosk} />
  </Anes>
);
