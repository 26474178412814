import toString from './toString';
import { capitalize } from 'lodash';

const toHighlightsString3 = toString.with({
  YesNo: (question, positive, child) => {
    if (child) {
      return child;
    } else if (positive) {
      return capitalize(
        question
          .replace(/\?/g, '')
          .replace(/^Do you have\s/g, '')
          .replace(/^Are you\s/g, '')
          .replace(/^Do you\s/g, '')
          .replace(/^Were you\s/g, '')
      );
    } else {
      return 'No';
    }
  },
  OneOf: (label, options, answer) => (answer ? `${answer.label}${answer.child ? ` (${answer.child})` : ''}` : null),
});

export default toHighlightsString3;
