import React from 'react';
import Button from '@material-ui/core/Button';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import { useMutation } from '@apollo/client';
import superUsers from '../../../../graph/superUsers';
import users from '../../../../graph/users';

interface NotificationsButtonProps {
  subscribedToNotifications: boolean;
  toggleSubscribedToNotifications: (value: boolean) => Promise<void>;
  labelYes?: string;
  labelNo?: string;
}

const NotificationsButton = ({
  toggleSubscribedToNotifications,
  subscribedToNotifications,
  labelYes,
  labelNo,
}: NotificationsButtonProps) => {
  const toggle = async (e: any) => {
    e.stopPropagation();
    await toggleSubscribedToNotifications(subscribedToNotifications);
  };

  return subscribedToNotifications ? (
    <Button color="primary" onClick={toggle} startIcon={<NotificationsIcon />}>
      {labelYes || 'Subscribed to Notifications'}
    </Button>
  ) : (
    <Button color="primary" onClick={toggle} startIcon={<NotificationsOffIcon />}>
      {labelNo || 'Unsubscribed from Notifications'}
    </Button>
  );
};

export const AdminNotificationsButton = (props: any) => {
  const [subscribeToNotifications] = useMutation(users.update);

  const toggleSubscribedToNotifications = async (val: boolean) => {
    await subscribeToNotifications({
      variables: { id: props.data?.id, subscribedToOpenPositionNotifications: !val },
    });
  };

  return (
    <NotificationsButton
      subscribedToNotifications={props.data?.subscribedToOpenPositionNotifications}
      toggleSubscribedToNotifications={toggleSubscribedToNotifications}
      labelYes={'Yes'}
      labelNo={'No'}
    />
  );
};

export const SensorEmailNotificationsButton = (props: any) => {
  const [subscribeToNotifications] = useMutation(users.update);

  const toggleSubscribedToNotifications = async (val: boolean) => {
    await subscribeToNotifications({
      variables: { id: props.data?.id, sensorEmailNotifications: !val },
    });
  };

  return (
    <NotificationsButton
      subscribedToNotifications={props.data?.sensorEmailNotifications}
      toggleSubscribedToNotifications={toggleSubscribedToNotifications}
      labelYes={'Yes'}
      labelNo={'No'}
    />
  );
};

export const SensorPhoneNotificationsButton = (props: any) => {
  const [subscribeToNotifications] = useMutation(users.update);

  const toggleSubscribedToNotifications = async (val: boolean) => {
    await subscribeToNotifications({
      variables: { id: props.data?.id, sensorPhoneNotifications: !val },
    });
  };

  return (
    <NotificationsButton
      subscribedToNotifications={props.data?.sensorPhoneNotifications}
      toggleSubscribedToNotifications={toggleSubscribedToNotifications}
      labelYes={'Yes'}
      labelNo={'No'}
    />
  );
};

export const SuperAdminNotificationButton = (props: any) => {
  const [subscribeToNotifications] = useMutation(superUsers.update);

  const toggleSubscribedToNotifications = async (val: boolean) => {
    await subscribeToNotifications({
      variables: { id: props.data?.id, subscribeToNotifications: !val },
    });
  };

  return (
    <NotificationsButton
      subscribedToNotifications={props.data?.subscribedToNotifications}
      toggleSubscribedToNotifications={toggleSubscribedToNotifications}
      labelYes={'Yes'}
      labelNo={'No'}
    />
  );
};

export default NotificationsButton;
