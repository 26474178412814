import React from 'react';
import { PatientType } from '../../types/Patient';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import styled from 'styled-components';
import get from 'lodash/get';
import Label from '../../se/components/Label';
import { useScope } from '../../hooks/useScope';

interface ToggleButtonSelectInputProps {
  defaultValue: PatientType;
  value: PatientType;
  onChange: (value: PatientType) => void;
  disabled?: boolean;
}

const Icon = styled.div`
  color: #000c3f;
  padding: 3px 2px;
  border-radius: 2px;
  font-size: 11px;
  line-height: 1;
  margin-left: 8px;
  font-weight: 600;
  width: 22px;
`;

const IN_COLOR = '#3F76F0';
const ER_COLOR = '#ff4848';
const ICU_COLOR = '#E9AF00';

export const PatientTypeIcon = ({ text, backgroundColor }: { text: string; backgroundColor: any }) => {
  const scope = useScope();
  const showIcon = get(scope, 'hospital.modules.hasPatientTypes', true);
  if (!showIcon) return null;
  return (
    <div style={{ paddingRight: 5 }}>
      <Icon style={{ backgroundColor: backgroundColor }}>{text}</Icon>
    </div>
  );
};

export const PatientTypeIconSwitch = ({ patientType }: { patientType: PatientType }) => {
  switch (patientType) {
    case 'Out':
      return null;
    case 'In':
      return <PatientTypeIcon backgroundColor={IN_COLOR} text={'IN'} />;
    case 'Emergency':
      return <PatientTypeIcon backgroundColor={ER_COLOR} text={'ER'} />;
    case 'ICU':
      return <PatientTypeIcon backgroundColor={ICU_COLOR} text={'ICU'} />;
    default:
      return null;
  }
};

const PatientTypeButtonGroup = ({
  defaultValue = PatientType.Out,
  value,
  onChange,
  disabled = false,
}: ToggleButtonSelectInputProps) => {
  const scope = useScope();

  const chosen = value || defaultValue;

  const showInput = get(scope, 'hospital.modules.hasPatientTypes', true);

  if (!showInput) return null;

  return (
    <Label section={''} text="Select Patient Type">
      <ToggleButtonGroup aria-label="outlined button group" value={value} defaultValue={value}>
        <ToggleButton
          value={PatientType.Out}
          color={chosen === PatientType.Out ? 'primary' : 'default'}
          disabled={disabled}
          style={{ textTransform: 'uppercase' }}
          onClick={() => onChange(PatientType.Out)}
        >
          Outpatient
          <PatientTypeIconSwitch patientType={PatientType.Out} />
        </ToggleButton>
        <ToggleButton
          value={PatientType.In}
          color={chosen === PatientType.In ? 'primary' : 'default'}
          disabled={disabled}
          style={{ textTransform: 'uppercase' }}
          onClick={() => onChange(PatientType.In)}
        >
          <PatientTypeIconSwitch patientType={PatientType.In} />
          Inpatient
        </ToggleButton>
        <ToggleButton
          value={PatientType.Emergency}
          color={chosen === PatientType.Emergency ? 'primary' : 'default'}
          disabled={disabled}
          style={{ textTransform: 'uppercase', color: ER_COLOR }}
          onClick={() => onChange(PatientType.Emergency)}
        >
          <PatientTypeIconSwitch patientType={PatientType.Emergency} />
          Emergency
        </ToggleButton>
        <ToggleButton
          value={PatientType.ICU}
          color={chosen === PatientType.ICU ? 'primary' : 'default'}
          disabled={disabled}
          style={{ textTransform: 'uppercase', color: ICU_COLOR }}
          onClick={() => onChange(PatientType.ICU)}
        >
          <PatientTypeIconSwitch patientType={PatientType.ICU} />
          ICU
        </ToggleButton>
      </ToggleButtonGroup>
    </Label>
  );
};

export default PatientTypeButtonGroup;
