import { useState, useEffect } from 'react';
import get from 'lodash/get';
import { findLastIndex, findIndex } from 'lodash/array';

const OFFSET = 12;

function getOverflownElements(el) {
  if (el) {
    const children = Array(...el.children);
    const underflowIdx = findLastIndex(children, c => c.getBoundingClientRect().top - OFFSET <= el.clientTop);
    const overflowIdx = findIndex(children, c => c.getBoundingClientRect().top - OFFSET >= el.clientHeight);

    return {
      underflow: children.slice(0, underflowIdx + 1),
      overflow: overflowIdx > -1 ? children.slice(overflowIdx, children.length) : [],
    };
  } else {
    return { underflow: [], overflow: [] };
  }
}

export const useOverflownChildren = (ref, tick) => {
  const [overflown, setOverflown] = useState(getOverflownElements(get(ref, 'current')));

  useEffect(() => {
    let requestRunning = null;

    const element = get(ref, 'current');

    function handleScroll() {
      if (requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          setOverflown(getOverflownElements(element));
          requestRunning = null;
        });
      }
    }

    element.addEventListener('scroll', handleScroll);
    return () => element.removeEventListener('scroll', handleScroll);
  }, [ref, setOverflown]);

  useEffect(() => {
    const element = get(ref, 'current');
    setOverflown(getOverflownElements(element));
  }, [ref, tick, setOverflown]);

  return overflown;
};
