import { z } from 'zod';
import useValidatedQuery from './useValidatedQuery';
import { gql, useQuery } from '@apollo/client';

const ActorStatus = z.enum(['OK', 'Unresponsive', 'Unknown']);

const SystemWideActorStatuses = z.object({
  hospitals: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      type: z.string(),
      actorStatuses: z.object({
        gatewayRouter: z
          .object({
            status: ActorStatus,
            routingTable: z.array(
              z.object({
                gatewayId: z.string(),
                organizationId: z.number(),
              })
            ),
            staging: z.array(
              z.object({
                id: z.string(),
                timestamp: z.string().nullable().optional(),
                associatedAt: z.string().nullable().optional(),
                organization: z
                  .object({
                    id: z.number(),
                    name: z.string(),
                  })
                  .nullable()
                  .optional(),
              })
            ),
          })
          .nullable()
          .optional(),
        subscriptionRegistry: z
          .object({
            status: ActorStatus,
            connections: z.number().optional(),
          })
          .nullable()
          .optional(),
        snapshotRouter: z
          .object({
            status: ActorStatus,
            signalProcessorStatuses: z
              .array(
                z.object({
                  beaconId: z.string(),
                  signalProcessorStatus: z.object({
                    status: ActorStatus,
                    patientActionDeferrer: z.object({
                      status: ActorStatus,
                    }),
                    downstream: ActorStatus,
                    downstreamFilter: ActorStatus,
                  }),
                })
              )
              .nullable()
              .optional(),
          })
          .nullable()
          .optional(),
        enrollmentReader: z
          .object({
            status: ActorStatus,
            enrollmentReaders: z
              .array(
                z.object({
                  id: z.string(),
                  type: z.string(),
                })
              )
              .nullable()
              .optional(),
            lastKnownBeaconId: z.string().nullable().optional(),
          })
          .optional(),
        exitGateway: z
          .object({
            status: ActorStatus,
            exitGateways: z
              .array(
                z.object({
                  id: z.string(),
                  type: z.string(),
                })
              )
              .nullable()
              .optional(),
            lastKnownBeaconId: z.string().nullable().optional(),
          })
          .nullable()
          .optional(),
        sensorMonitor: z
          .object({
            status: ActorStatus,
          })
          .nullable()
          .optional(),
      }),
    })
  ),
});

export type SystemWideActorStatuses = z.infer<typeof SystemWideActorStatuses>;

interface APIOperation<T> {
  loading: boolean;
  error?: unknown;
  data?: T;
}

export default function useSystemWideActorStatuses(): ReturnType<typeof useQuery<SystemWideActorStatuses>> {
  return useValidatedQuery(
    SystemWideActorStatuses,
    gql`
      query systemWideActorStatuses {
        hospitals {
          id
          name
          type
          actorStatuses {
            gatewayRouter {
              status
              routingTable {
                gatewayId
                organizationId
              }
              staging {
                id
                timestamp
                associatedAt
                organization {
                  id
                  name
                }
              }
            }
            subscriptionRegistry {
              status
              connections
            }
            snapshotRouter {
              status
              signalProcessorStatuses {
                beaconId
                signalProcessorStatus {
                  status
                  patientActionDeferrer {
                    status
                  }
                  downstream
                  downstreamFilter
                }
              }
            }
            enrollmentReader {
              status
              enrollmentReaders {
                id
                type
              }
              lastKnownBeaconId
            }
            exitGateway {
              status
              exitGateways {
                id
                type
              }
              lastKnownBeaconId
            }
            sensorMonitor {
              status
            }
          }
        }
      }
    `
  );
}
