import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import Distribution from './Distribution';
import AnalyticsPanelContainer from '../../Panel/AnalyticsPanelContainer';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

const ToggleButton = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  > i {
    font-size: 1.5rem;
    cursor: pointer;
  }
`;

export const HideControl = ({ hidden, setHidden }) => (
  <Fragment>
    {hidden && window.innerWidth < 500 ? (
      <ToggleButton onClick={() => setHidden(false)}>
        <KeyboardArrowDown fontSize={'small'} />
      </ToggleButton>
    ) : null}
    {!hidden && window.innerWidth < 500 ? (
      <ToggleButton onClick={() => setHidden(true)}>
        <KeyboardArrowUp fontSize={'small'} />
      </ToggleButton>
    ) : null}
  </Fragment>
);

export default ({
  title,
  value,
  distribution,
  unit,
  ratio,
  hideValue,
  tooltipLabel,
  isDecimal,
  style,
  children,
  noPadding,
  noValue,
  color = undefined,
  greenLineY,
  prevStat,
  data,
}) => {
  const [hidden, setHidden] = useState(window.innerWidth < 500);
  const computedValue =
    noValue || hideValue ? <span>&nbsp;</span> : [isDecimal ? value.toFixed(1) : value.toFixed(0), unit].join(' ');
  const difference = data?.value - prevStat?.value;
  const formattedDifference = Number.isInteger(difference) ? difference.toString() : difference.toFixed(1);
  const displayDifference = difference >= 0 ? `+${formattedDifference}` : formattedDifference;
  const percentDifference =
    prevStat?.value !== 0 && data?.value !== 0
      ? ((data?.value - prevStat?.value) / prevStat?.value) * 100
      : prevStat?.value === 0 && data?.value === 0
      ? 0
      : prevStat?.value === 0
      ? 100
      : -100;

  const roundedPercentDifference = percentDifference.toFixed(1);
  const absolutePercentDifference = Math.abs(roundedPercentDifference) + '%';
  let textColor = 'white';
  let arrowIcon = '';

  if (percentDifference > 0) {
    textColor = '#41d261';
    if (
      data?.id === 'averageWaitingRoomTime' ||
      data?.id === 'averagePreOPTime' ||
      data?.id === 'averageORTime' ||
      data?.id === 'averagePACUTime' ||
      data?.id === 'averageCleaningTime' ||
      data?.id === 'averagePopiTime' ||
      data?.id === 'averagePostOpTime'
    ) {
      textColor = '#ff5252';
    }
    arrowIcon = '▲';
  } else if (percentDifference < 0) {
    textColor = '#ff5252';
    arrowIcon = '▼';
    if (
      data?.id === 'averageWaitingRoomTime' ||
      data?.id === 'averagePreOPTime' ||
      data?.id === 'averageORTime' ||
      data?.id === 'averagePACUTime' ||
      data?.id === 'averageCleaningTime' ||
      data?.id === 'averagePopiTime' ||
      data?.id === 'averagePostOpTime'
    ) {
      textColor = '#41d261';
    }
  }

  return (
    <AnalyticsPanelContainer
      title={title}
      compact
      style={style}
      noPadding={noPadding}
      titleColor={color}
      prevStat={prevStat}
      data={data}
    >
      <div style={{ display: 'flex' }}>
        {!noValue && (
          <Box display="flex" alignItems="baseline" flexWrap="wrap" style={{ gap: 8 }}>
            <Typography variant="h2" style={{ color }}>
              {computedValue}
            </Typography>
            <Typography style={{ color: textColor }}>
              {displayDifference} {` (${absolutePercentDifference}) `} {arrowIcon}
            </Typography>
          </Box>
        )}{' '}
        <HideControl hidden={hidden} setHidden={setHidden} />
      </div>
      {!hidden &&
        (children ? (
          children
        ) : (
          <Distribution
            color={color}
            distribution={distribution}
            unit={unit}
            label={title}
            ratio={ratio}
            tooltipLabel={tooltipLabel}
            greenLineY={greenLineY}
          />
        ))}
    </AnalyticsPanelContainer>
  );
};
