import React, { Fragment } from 'react';
import { ArrowIcon, ListItemLink } from '../../../se/components/collection/mobile/ListItem';
import { Column, Label, Row, Value, TwoItemSection } from '../../../se/components/collection/mobile/shared';
import RouterLink from '../../../se/components/RouterLink';

const UserMobileListItem = ({
  history,
  location,
  columns,
  item,
  context,
  index: i,
  showUrlProvider,
  idProvider,
  onClick,
}) => {
  if (item.separator) {
    return null;
  }
  const nameColumn = columns.find(_ => _.key === 'name');
  const emailColumn = columns.find(_ => _.key === 'email');
  const roleColumn = columns.find(_ => _.key === 'role');

  return (
    <Fragment>
      <ListItemLink
        as={showUrlProvider ? RouterLink : undefined}
        to={
          showUrlProvider
            ? {
                pathname: showUrlProvider(idProvider(item)),
                search: location.search,
              }
            : undefined
        }
        onClick={onClick}
      >
        <ArrowIcon />
        <TwoItemSection style={{ marginBottom: '0.75rem' }}>
          <Column>
            <Label>{nameColumn.title}</Label>
            <Value trimText>{nameColumn.lens(item, context)}</Value>
          </Column>
          <Column>
            <Label>{roleColumn.title}</Label>
            <Value trimText>{roleColumn.lens(item, context)}</Value>
          </Column>
        </TwoItemSection>

        <Row>
          <Column>
            <Label>{emailColumn.title}</Label>
            <Value trimText>{emailColumn.lens(item, context)}</Value>
          </Column>
        </Row>
      </ListItemLink>
    </Fragment>
  );
};

export default UserMobileListItem;
