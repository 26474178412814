import React, { useState, useMemo, Fragment } from 'react';
import styled from 'styled-components';
import { listProceduresFromToSubscription } from '../../../../../graph/procedures';
import get from 'lodash/get';
import first from 'lodash/first';
import last from 'lodash/last';
import groupBy from 'lodash/groupBy';
import rooms from '../../../../../graph/rooms';
import { ROOM_TYPES } from '../../../../entities/room/enums';
import { format, addDays, subDays, getDay, startOfWeek, endOfWeek, isWeekend } from 'date-fns';
import Week from './Week';
import { Subscription } from '@apollo/client/react/components';
import { withQuery } from '@apollo/client/react/hoc';

const Schedule = styled.div`
  font-size: 0.75em;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const padWeek = (date, showWeekends) => {
  const start = startOfWeek(date, { weekStartsOn: 0 });
  const end = endOfWeek(date, { weekStartsOn: 0 });

  return [...new Array(7)].map((_, i) => addDays(start, i)).filter(_ => showWeekends || !isWeekend(_));
};

export const WeeklyScheduleCalendar = ({
  data,
  modal,
  setModal,
  week,
  rooms,
  date,
  setEditProcedureMonthly,
  openProcedureForm,
  canUseScheduleViewProcedure,
  hideRoomName,
}) => {
  const weeklyProcedures = groupBy(get(data, 'proceduresFromTo', []), ({ orId, date }) => `${orId}_${date}`);

  return (
    <Schedule>
      <Week
        key={0}
        rooms={rooms}
        dates={week}
        procedures={weeklyProcedures}
        dateSelection={date}
        onCellClick={val => setModal(val)}
        showDayNames={true}
        firstWeek={true}
        setEditProcedureMonthly={setEditProcedureMonthly}
        openProcedureForm={openProcedureForm}
        canUseScheduleViewProcedure={canUseScheduleViewProcedure}
        hideRoomName={hideRoomName}
      />
    </Schedule>
  );
};

const WeeklySchedule = ({
  data,
  date,
  physician,
  setEditProcedureMonthly,
  openProcedureForm,
  canUseScheduleViewProcedure,
  showWeekends,
}) => {
  const [modalData, setModalData] = useState(null);

  const rooms = get(data, 'rooms', [])
    .filter(_ => _.type === ROOM_TYPES.OR)
    .sort((a, b) => a.name.localeCompare(b.name));

  const week = useMemo(() => padWeek(date, showWeekends), [date, showWeekends]);

  return (
    <Fragment>
      <Subscription
        subscription={listProceduresFromToSubscription}
        variables={{
          from: format(first(week), 'YYYY-MM-DD'),
          to: format(last(week), 'YYYY-MM-DD'),
          physician: physician ? parseInt(physician, 10) : undefined,
          isCanceled: false,
        }}
      >
        {({ data }) => (
          <Fragment>
            <WeeklyScheduleCalendar
              week={week}
              rooms={rooms}
              data={data}
              modal={modalData}
              setModal={setModalData}
              date={date}
              setEditProcedureMonthly={setEditProcedureMonthly}
              openProcedureForm={openProcedureForm}
              canUseScheduleViewProcedure={canUseScheduleViewProcedure}
            />
          </Fragment>
        )}
      </Subscription>
    </Fragment>
  );
};

export default withQuery(rooms.list)(WeeklySchedule);
