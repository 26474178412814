import React from 'react';
import styled from 'styled-components';

import { withTheme } from '../theme';
import responsive from '../utilities/responsive';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const H2 = ({ children, ...rest }) => (
  <Typography variant="h2" {...rest}>
    {children}
  </Typography>
);

const H3 = styled.h3`
  font-size: 1.75rem;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const H4 = styled.h4`
  font-size: 1.5rem;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const H6 = styled.h6`
  font-size: 1rem;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const Text = styled.span`
  font-size: ${withTheme((theme, props) => theme.textSize[props.size || 'm'] || theme.textSize.m)};
  white-space: ${props => (props.wrap ? 'unset' : 'nowrap')};
  line-height: 1.4;

  ${responsive.md.andSmaller`
    white-space: initial;
  `};
`;

const TruncatedText = ({ children, style, textStyle }) => (
  <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" style={style}>
    <Typography component="span" style={textStyle}>
      {children}
    </Typography>
  </Box>
);

const Paragraph = styled.p`
  font-size: ${withTheme((theme, props) => theme.textSize[props.size || 'm'] || theme.textSize.m)};
  line-height: ${withTheme((theme, props) => theme.lineHeight[props.size || 'm'] || theme.lineHeight.m)};
  margin: ${withTheme((theme, props) => theme.lineHeight[props.size || 'm'] || theme.lineHeight.m)} auto;
`;

const Code = styled.code`
  background-color: ${withTheme(theme => theme.border.color.default.alpha(1).string())};
  border-radius: ${withTheme(theme => theme.border.radius.default)};
  color: ${withTheme(theme => theme.textColor.string())};
  padding: 2px 4px;
`;

export { H2, H3, H4, H6, Text, TruncatedText, Paragraph, Code };
