import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import { listPacuPrepPostopPatients } from '../../../graph/patients';
import { groupByRoom } from './transducers';
import { bedColumn, nameColumn, PatientNameColumn, physicianColumn, procedureTypeColumn } from './columns';
import PatientListView from './views/PatientListView';
import { PACU, POST_OP, PRE_OP } from '../room/enums';
import React, { Fragment } from 'react';
import { ListItemLink } from '../../../se/components/collection/mobile/ListItem';
import { Column, Label, TwoItemSection, Value } from '../../../se/components/collection/mobile/shared';
import {
  PatientTableSeparator,
  PatientTableSeparatorLine,
  PatientTableSeparatorText,
} from './views/PatientMobileListItem';
import BedInlineInput from './BedInlineInput';

const Empty = { illustration: theme => theme.illustrations.patients };

const rooms = [PRE_OP, PACU];
const roomsWithPostOp = [PRE_OP, PACU, POST_OP];

const PatientMobileItemComponent = ({ columns, item, context }) => {
  if (item.separator) {
    return (
      <PatientTableSeparator>
        <PatientTableSeparatorText>{item.label}</PatientTableSeparatorText>
        <PatientTableSeparatorLine />
      </PatientTableSeparator>
    );
  }

  const patientNameColumn = columns.find(_ => _.key === 'name');
  const physicianNameColumn = columns.find(_ => _.key === 'physician');
  const procedureTypeColumn = columns.find(_ => _.key === 'procedureType');
  const bedColumn = columns.find(_ => _.title === 'Bed');
  return (
    <Fragment>
      <ListItemLink>
        <TwoItemSection style={{ marginBottom: '0.5rem' }}>
          <Column>
            <PatientNameColumn data={patientNameColumn.lens(item, context)} />
          </Column>
        </TwoItemSection>
        <TwoItemSection style={{ marginBottom: '0.5rem' }}>
          <Column>
            <Label style={{ fontWeight: 'bold' }}>{physicianNameColumn.title}</Label>
            <Value>{physicianNameColumn.lens(item, context)}</Value>
          </Column>
        </TwoItemSection>
        <TwoItemSection style={{ marginBottom: '0.5rem' }}>
          <Column>
            <Label style={{ fontWeight: 'bold' }}>{procedureTypeColumn.title}</Label>
            <Value>{procedureTypeColumn.lens(item, context)}</Value>
          </Column>
        </TwoItemSection>
        <TwoItemSection style={{ marginBottom: '0.5rem' }}>
          <Column>
            <Label style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>{bedColumn.title}</Label>
            <BedInlineInput patient={item} />
          </Column>
        </TwoItemSection>
      </ListItemLink>
    </Fragment>
  );
};

export default hasPostOp =>
  GraphQLCRUD({
    entityName: 'Patient',
    scheme: {
      list: listPacuPrepPostopPatients,
    },
    List: {
      tableKey: 'AssignmentPatients',
      columns: [nameColumn('name'), physicianColumn, procedureTypeColumn, bedColumn],
      View: PatientListView,
      MobileItemComponent: PatientMobileItemComponent,
      Empty,
      mapListProps: groupByRoom(hasPostOp ? roomsWithPostOp : rooms),
    },
  });
