import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { getNotificationTemplate } from '../../../../../graph/notificationTemplates';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';
import { Destination } from '../../../../../types/NotificationTemplate';
import ScheduleUser from '../../../../../types/ScheduleUser';
import Entity from '../../../../../types/Entity';
import ScheduleAccess from '../ScheduleAccess';

export type Contact = {
  id: number;
  phoneNumber: string;
};

export type Recipient = Entity & ScheduleUser & Contact;

type RecipientCheckboxes = { [recipientId: string]: boolean };

const SendNotificationDialog: FC<{
  date: Date;
  open: boolean;
  handleClose: () => void;
  recipients: Recipient[];
  sendNotifications: (recipients: Contact[], message: String) => Promise<void>;
  destination: Destination;
}> = ({ date, open, handleClose, recipients, sendNotifications, destination }) => {
  const { data: notification } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'ScheduleCreated', destination: destination },
  });

  const notificationText = notification?.getNotificationTemplate[0]?.content || '';
  const [message, setMessage] = useState<string>('');

  const [sending, setSending] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);

  useEffect(() => {
    setMessage(notificationText);
  }, [notificationText]);

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setMessage(e.target.value);
  };
  const classes = useStyles();

  useEffect(() => {
    setSent(false);
  }, [open]);

  const [checkboxes, setCheckboxes] = useState<RecipientCheckboxes>({});

  const selectAllCheckboxes = useCallback(() => {
    setCheckboxes(
      recipients.reduce(
        (acc: any, curr: Recipient) => ({
          ...acc,
          [curr.id]: curr.hasScheduleAccess && !!curr.phoneNumber,
        }),
        {}
      )
    );
  }, [setCheckboxes, recipients]);

  useEffect(() => {
    selectAllCheckboxes();
  }, [recipients]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
  };

  const doSend = async () => {
    setSending(true);
    setSent(false);

    await sendNotifications(
      recipients
        .filter(e => checkboxes[e.id] && e.phoneNumber)
        .map(e => ({
          id: e.id,
          phoneNumber: e.phoneNumber,
        })),
      message
    );

    setSending(false);
    setSent(true);
  };

  const selectAll = () => selectAllCheckboxes();

  const deselectAll = () => setCheckboxes({});

  return (
    <Dialog onClose={handleClose} open={open} classes={{ paper: classes.paper }}>
      <DialogTitle>
        <Typography variant="h6">
          Send notification of the new schedule to the{' '}
          {`${destination
            .split(/(?=[A-Z])/)
            .map(word => word.toLowerCase())
            .join(' ')}${recipients.length > 1 ? 's' : ''}`}
        </Typography>
        {handleClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'grid', gridTemplateColumns: '.9fr .1fr 1fr', color: 'silver' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              textAlign: 'center',
              borderBottom: '1px solid silver',
            }}
          >
            <div>Select Staff Member</div>
            <div>Current Access</div>
            <div>Change Access</div>
          </div>
          <div></div>
          <div>
            Make sure to enter Staff Members with their phone numbers, in order for them to receive messages &#40;add in
            staff member profile&#41;
          </div>
        </div>
        <Box className={classes.dialog}>
          <Box className={classes.content}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem' }}
              >
                <Button color="primary" variant="text" onClick={selectAll} style={{ width: 'fit-content' }}>
                  Select all
                </Button>
                <Button color="primary" variant="text" onClick={deselectAll} style={{ width: 'fit-content' }}>
                  Deselect all
                </Button>
              </FormLabel>
              <FormGroup>
                {recipients.map((e: Recipient) => (
                  <div key={e.id}>
                    <div style={{ display: 'grid', gridTemplateColumns: '.33fr .67fr', alignItems: 'center' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={(checkboxes as any)[e.id] || false}
                            onChange={handleChange}
                            name={`${e.id}`}
                          />
                        }
                        label={
                          e.phoneNumber ? (
                            <>
                              <div>{e.name}</div>
                              <div>{e.phoneNumber}</div>
                            </>
                          ) : (
                            `${e.name} (No Phone Number)`
                          )
                        }
                        disabled={!(e.hasScheduleAccess && e.phoneNumber)}
                      />
                      <ScheduleAccess date={date} scheduleUser={e} destination={destination} />
                    </div>
                    <div
                      style={{
                        borderTop: '1px solid gray',
                        marginRight: '20px',
                        marginTop: '15px',
                        marginBottom: '15px',
                      }}
                    ></div>
                  </div>
                ))}
              </FormGroup>
            </FormControl>
            <TextField
              label="Send message with schedule link"
              multiline
              minRows={10}
              variant="filled"
              fullWidth={true}
              value={message}
              onChange={handleMessageChange}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.action}>
        {sent && (
          <Box className={classes.success}>
            <DoneAllIcon style={{ color: 'green' }} />
            <Typography style={{ color: 'green' }}>Notifications Sent</Typography>
          </Box>
        )}
        <Button variant="contained" color="primary" onClick={doSend} fullWidth={false} disabled={sending}>
          Send Notifications
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    minWidth: '75rem',
    minHeight: '40rem',
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '6fr 6fr',
    marginBottom: theme.spacing(1),
  },
  action: {
    margin: theme.spacing(3),
  },
  success: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
    display: 'flex',
    flexDirection: 'row',
  },
}));

export default SendNotificationDialog;
