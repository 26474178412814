import React, { ElementType, FC, ReactNode, VFC } from 'react';
import classNames from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Typography } from '@material-ui/core';
import Dates from './types/Dates';
import withStyles from '@material-ui/core/styles/withStyles';
import { DateString } from './util/groupStaffAvailability';
import { format } from 'date-fns';

export const useStyles = makeStyles(() => ({
  layout: {
    width: '50px',
    height: '50px',
    border: '1px solid #000c3f',
    display: 'grid',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    '& > *': {
      gridRow: '1 / span 1',
      gridColumn: '1 / span 1',
    },
  },
  root: {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
  },
  selected: {
    border: '2px solid white',
  },
  highlighted: {
    border: '4px solid white',
  },
  extra: {
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'stretch',
  },
  interactivity: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
    },
  },
  date: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  },
}));

interface CalendarDateProps {
  date: Date;
  renderDate?: (date: Date) => ReactNode;
  renderDateTooltip?: (date: Date) => ReactNode;
  selectedDates: Dates;
  setSelectedDates: (datesOrFn: Dates | ((prev: Dates) => Dates)) => void;
  highlightedDates: Dates;
  component?: ElementType;
  classes?: {
    root?: string;
    selected?: string;
  };
}

/* @ts-ignore */
const CalendarDate: VFC<CalendarDateProps> = ({
  date,
  renderDate,
  renderDateTooltip,
  selectedDates,
  setSelectedDates,
  highlightedDates,
  component,
  classes,
}) => {
  const styles = useStyles();

  const Component = component ?? 'div';

  const selectDate = (date: DateString) => {
    setSelectedDates(prev => prev.add(date));
  };

  const unselectDate = (date: DateString) => {
    setSelectedDates(prev => prev.delete(date));
  };

  const deselectAll = () => {
    setSelectedDates(Dates.empty);
  };

  const onSelectionStart = (event: React.MouseEvent<HTMLElement>, date: Date) => {
    let selectionInProgress = !selectedDates.has(format(date, 'YYYYMMDD')) || event.shiftKey;
    let deselectionInProgress = !selectionInProgress;

    if (selectionInProgress && !event.shiftKey) {
      deselectAll();
    }

    if (deselectionInProgress) {
      unselectDate(format(date, 'YYYYMMDD'));
    } else {
      selectDate(format(date, 'YYYYMMDD'));
    }

    let lastDate = date;

    function handleTarget(initialTarget: HTMLElement) {
      const date = fallthroughUntil(initialTarget, element => element.dataset.date && new Date(element.dataset.date));

      if (date && date.getTime() !== lastDate.getTime()) {
        lastDate = date;

        if (selectionInProgress) {
          selectDate(format(date, 'YYYYMMDD'));
        } else if (deselectionInProgress) {
          unselectDate(format(date, 'YYYYMMDD'));
        }
      }
    }

    const mouseMove = createInterpolatingMouseMoveHandler(50, event.pageX, event.pageY, handleTarget);

    document.body.addEventListener('mousemove', mouseMove);

    function mouseUp() {
      document.body.removeEventListener('mousemove', mouseMove);
      document.body.removeEventListener('mouseup', mouseUp);
    }

    document.body.addEventListener('mouseup', mouseUp);
  };

  const selected = selectedDates.has(format(date, 'YYYYMMDD'));
  const highlighted = highlightedDates.has(format(date, 'YYYYMMDD'));

  const dateContent = (
    <Component
      data-date={date.toISOString()}
      onMouseDown={(e: React.MouseEvent<HTMLElement>) => onSelectionStart(e, date)}
      className={classNames(styles.root, classes?.root, styles.layout)}
    >
      <div
        className={classNames(
          { [styles.selected]: selected },
          { [styles.highlighted]: highlighted },
          classes?.selected && { [classes.selected]: selected },
          styles.extra
        )}
      >
        {renderDate ? renderDate(date) : null}
      </div>
      <div className={classNames(styles.interactivity, styles.date)}>
        <Typography>{date.getDate()}</Typography>
      </div>
    </Component>
  );

  const dateTooltip = renderDateTooltip ? renderDateTooltip(date) : null;

  return dateTooltip ? (
    <StyledTooltip title={dateTooltip} enterDelay={700} leaveDelay={0}>
      {dateContent}
    </StyledTooltip>
  ) : (
    dateContent
  );
};

export const StyledTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid white',
  },
}))(Tooltip);

export const EmptyCalendarDate: FC = ({ children }) => {
  const styles = useStyles();

  return <div className={styles.layout}>{children && <div className={styles.date}>{children}</div>}</div>;
};

function createInterpolatingMouseMoveHandler(
  granularity: number,
  initialX: number,
  initialY: number,
  handler: (target: HTMLElement) => void
) {
  let lastX = initialX;
  let lastY = initialY;

  return function (this: HTMLElement, ev: HTMLElementEventMap['mousemove']): void {
    const diffX = ev.pageX - lastX;
    const diffY = ev.pageY - lastY;

    const resolution = Math.max(1, Math.ceil(Math.max(Math.abs(diffX), Math.abs(diffY)) / granularity));

    const incrementX = (ev.pageX - lastX) / resolution;
    const incrementY = (ev.pageY - lastY) / resolution;

    for (let i = 1; i < resolution; i++) {
      const element = document.elementFromPoint(lastX + i * incrementX, lastY + i * incrementY);

      if (element) {
        handler(element as HTMLElement);
      }
    }

    handler(ev.target as HTMLElement);

    lastX = ev.pageX;
    lastY = ev.pageY;
  };
}

export function fallthroughUntil<C>(initialTarget: HTMLElement, criteria: (element: HTMLElement) => C): C | undefined {
  let target = initialTarget;

  while (target) {
    const potentialCriteria = criteria(target);

    if (potentialCriteria) {
      return potentialCriteria;
    }

    target = target.parentElement as HTMLElement;
  }
}

export default CalendarDate;
