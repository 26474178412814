import React, { useEffect, useState } from 'react';
import { Box, FormControl, FormControlLabel, FormGroup, FormLabel, Radio } from '@material-ui/core';
import { SelectAnswer } from '../../types/Answer';
import { SelectQuestion } from '../../types/Question';
import { useSave } from '../../components/pages/kiosk/charting/SaveProvider';
import { ConditionContext, useSlideIn } from '../Form';
import omit from 'lodash/omit';
import SubFormCard from '../components/SubFormCard';
import ReactHtmlParser from 'react-html-parser';

type RadioGroupInputProps = SelectQuestion['props'] & {
  name?: string;
  answer?: SelectAnswer;
  onChange?: (newAnswer: SelectAnswer) => void;
};

const RadioGroupInput = ({ optional, label, options, answer, onChange, horizontal }: RadioGroupInputProps) => {
  const [invalid, setInvalid] = useState(false);
  const hasChildren = options.some(option => !!option?.child);

  const { validationRequest } = useSave();
  const slideIn = useSlideIn();

  useEffect(() => {
    if (optional) {
      return;
    }

    if (validationRequest) {
      if (!answer) {
        setInvalid(true);
        slideIn();
      }
    }
  }, [validationRequest, answer, optional, slideIn]);

  return (
    <FormControl component="fieldset" error={invalid}>
      {label && <FormLabel component="legend">{ReactHtmlParser(label)}</FormLabel>}
      <FormGroup row={horizontal && !hasChildren}>
        {options.map(option => {
          const { name, label } = option;
          return option?.child ? (
            <Box ml={-2}>
              <SubFormCard
                mainItem={
                  <FormControlLabel
                    key={name}
                    control={
                      <Radio
                        color="primary"
                        checked={answer?.name === option.name}
                        onChange={() => {
                          onChange?.(omit(option, ['ifYes', 'child']));
                          setInvalid(false);
                        }}
                        name={name}
                      />
                    }
                    label={ReactHtmlParser(label)}
                  />
                }
                showChildren={answer?.name === option.name}
                children={
                  option?.child ? (
                    <ConditionContext.Provider value={true}>{option?.child}</ConditionContext.Provider>
                  ) : undefined
                }
              />
            </Box>
          ) : (
            <FormControlLabel
              key={name}
              control={
                <Radio
                  color="primary"
                  checked={answer?.name === option.name}
                  onChange={() => {
                    onChange?.(option);
                    setInvalid(false);
                  }}
                  name={name}
                />
              }
              label={ReactHtmlParser(label)}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

export default RadioGroupInput;
