import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { list, sendToScanChart, sentToScanChart } from '../../../../graph/surgeon/forms';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import { formTypeLabels } from '../../procedures/enums';
import DialogActions from '@material-ui/core/DialogActions';
import Spinner from '../../../../se/components/Spinner';
import { FormType } from '../../../../types/Form';
import { useToaster } from '../../../core/Toaster';
import { useTheme } from '@material-ui/core';
import formatRelative from 'date-fns-3/formatRelative';
import { useScope } from '../../../../hooks/useScope';

const ScanChartFileGenerator = ({ procedureId }) => {
  const [busy, setBusy] = useState(false);
  const [open, setOpen] = useState(false);

  const [state, setState] = useState({});
  const { data: formQuery } = useQuery(list);
  const { data: sentToScanChartQuery, refetch: refetchSentToScanChartQuery } = useQuery(sentToScanChart, {
    variables: {
      procedureId,
    },
  });
  const reportForms = useMemo(
    () =>
      (formQuery?.forms || []).filter(
        e =>
          e.type === FormType.PreOpForm ||
          e.type === FormType.InTakeForm ||
          e.type === FormType.PreOpChartForm ||
          e.type === FormType.OrChartForm ||
          e.type === FormType.PacuChartForm
      ),
    [formQuery]
  );

  const sections = useMemo(() => groupBy(reportForms, _ => _.type), [reportForms]);

  useEffect(() => {
    setState(reportForms.reduce((acc, curr) => ({ ...acc, [curr.id]: true }), {}));
  }, [reportForms]);

  const anyChecked = useMemo(() => Object.values(state).includes(true), [state]);

  const client = useApolloClient();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const toaster = useToaster();

  const handleGenerate = async () => {
    if (anyChecked) {
      setBusy(true);

      const formIds = Object.entries(state)
        .filter(([_, value]) => !!value)
        .map(([key, _]) => parseInt(key, 10))
        .sort((a, b) => reportForms.find(x => x.id === a).order - reportForms.find(x => x.id === b).order);

      try {
        const buildResult = await client.mutate({
          mutation: sendToScanChart,
          variables: { procedureId, ids: formIds },
          fetchPolicy: 'network-only',
        });

        if (buildResult.data) {
          toaster.success(`Form${formIds === 1 ? '' : 's'} sent to ScanChart.`);

          refetchSentToScanChartQuery();
        } else {
          toaster.error(`Unable to send form${formIds === 1 ? '' : 's'} to ScanChart.`);
        }
      } catch (e) {
        console.error(e);
        toaster.error(`Unable to send form${formIds === 1 ? '' : 's'} to ScanChart.`);
      } finally {
        setBusy(false);
      }
      setOpen(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleCheckboxChange = (id, checked) => {
    setState({ ...state, [id]: checked });
  };

  const selectAll = () => {
    setState(Object.keys(state).reduce((acc, curr) => ({ ...acc, [curr]: true }), {}));
  };

  const deselectAll = () => {
    setState(Object.keys(state).reduce((acc, curr) => ({ ...acc, [curr]: false }), {}));
  };

  const scope = useScope();

  const sent = [...(sentToScanChartQuery?.sentToScanChart ?? [])].sort((a, b) => -a.sentAt.localeCompare(b.sentAt));

  const theme = useTheme();

  const renderItem = item => {
    const s = sent.find(s => parseInt(s.formId, 10) === parseInt(item.id, 10));

    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={state[item.id]}
            onChange={e => handleCheckboxChange(item.id, e.target.checked)}
            name={item.name}
          />
        }
        label={
          <>
            {item.name}
            {s ? (
              <div style={{ fontSize: '0.6em ', color: theme.palette.success.dark }}>
                Last sent {formatRelative(new Date(s.sentAt), new Date())}{' '}
              </div>
            ) : (
              <div style={{ fontSize: '0.6em ', opacity: 0.6 }}>Never sent</div>
            )}
          </>
        }
      />
    );
  };

  return get(scope, 'hospital.scanChart') ? (
    <Fragment>
      {reportForms.length > 0 && (
        <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={2}>
          <Hidden smDown>
            <Button width={200} color="primary" onClick={handleClickOpen}>
              Send Documents to ScanChart
            </Button>
          </Hidden>
        </Box>
      )}
      <Dialog maxWidth="lg" open={open} onClose={handleCancel} aria-labelledby="form-dialog-title">
        <DialogTitle>Send Documents to Scan Chart</DialogTitle>
        <DialogContent>
          <DialogContentText>Please select sections to send to ScanChart.</DialogContentText>
          <Box mt={2} display="flex" flexDirection="row" width="100%">
            <FormGroup style={{ width: '100%' }}>
              <Grid container>
                <Grid item xs={12}>
                  <Box mb={2}>
                    <Button color="primary" size="small" onClick={selectAll}>
                      Select all
                    </Button>
                    <Button color="primary" size="small" onClick={deselectAll}>
                      Deselect all
                    </Button>
                  </Box>
                </Grid>
                <Grid item md={4}>
                  <Typography variant="subtitle1" gutterBottom>
                    {formTypeLabels[FormType.PreOpForm]}
                  </Typography>
                  <Box display="flex" flexDirection="column">
                    {(sections[FormType.PreOpForm] ?? []).map(renderItem)}
                  </Box>
                </Grid>
                <Grid item md={4}>
                  <Typography variant="subtitle1" gutterBottom>
                    {formTypeLabels[FormType.InTakeForm]}
                  </Typography>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="column">
                      {(sections[FormType.InTakeForm] ?? []).map(renderItem)}
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={4}>
                  <Typography variant="subtitle1" gutterBottom>
                    {formTypeLabels[FormType.PreOpChartForm]}
                  </Typography>
                  <Box display="flex" flexDirection="column" mb={2}>
                    {(sections[FormType.PreOpChartForm] ?? []).map(renderItem)}
                  </Box>
                  <Typography variant="subtitle1" gutterBottom>
                    {formTypeLabels[FormType.OrChartForm]}
                  </Typography>
                  <Box display="flex" flexDirection="column" mb={2}>
                    {(sections[FormType.OrChartForm] ?? []).map(renderItem)}
                  </Box>
                  <Typography variant="subtitle1" gutterBottom>
                    {formTypeLabels[FormType.PacuChartForm]}
                  </Typography>
                  <Box display="flex" flexDirection="column">
                    {(sections[FormType.PacuChartForm] ?? []).map(renderItem)}
                  </Box>
                </Grid>
              </Grid>
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button disabled={busy || !anyChecked} onClick={handleGenerate} color="primary">
            Generate & Send
          </Button>

          {busy && (
            <Spinner
              style={{
                verticalAlign: 'middle',
                marginLeft: '0.5rem',
              }}
            />
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  ) : null;
};

export default ScanChartFileGenerator;

const Renderer = ({ forms, onComplete, onError }) => (
  <div style={{ width: 0, height: 0, position: 'fixed', top: -1, left: -1, overflow: 'hidden' }}>
    {forms.map(form => (
      <RenderOne
        form={form}
        // lastPreOpCompletedEvent={lastPreOpCompletedEvent}
      />
    ))}
  </div>
);

const RenderOne = ({ form, onComplete, onError }) => {
  const rootRef = useRef();

  useEffect(() => {
    const root = rootRef.current;

    if (!root) {
      return;
    }

    setTimeout(() => {}, 2000);
  });

  return (
    <div ref={rootRef}>
      <SurgeonFormPage
        id={form.formId}
        procedureId={form.procedureId}
        // lastPreOpCompletedEvent={lastPreOpCompletedEvent}
      />
    </div>
  );
};

function renderAndUploadForm(pendingForm) {
  const root = document.createElement('div');
  ReactDOM.render(
    <SurgeonFormPage
      id={pendingForm.formId}
      procedureId={pendingForm.procedureId}
      // lastPreOpCompletedEvent={lastPreOpCompletedEvent}
    />,
    root
  );
  document.body.append(root);
}
