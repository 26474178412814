import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { useStyles } from '../ScheduleProcedureForm';
import { FormParams, StaffDetails } from '../types/types';
import PhysicianAutocomplete from '../components/PhysicianAutocomplete';
import AnesthesiologistAutocomplete from '../components/AnesthesiologistAutocomplete';

const StaffForm: FC<FormParams<StaffDetails>> = ({ value, setValue }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h3" gutterBottom>
        Surgeon and Anesthesiologist
      </Typography>

      <form className={classes.form} noValidate autoComplete="off">
        <Grid container spacing={4}>
          <Grid item md={6}>
            <PhysicianAutocomplete value={value} setValue={setValue} />
          </Grid>
          <Grid item md={6}>
            <AnesthesiologistAutocomplete value={value} setValue={setValue} />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default StaffForm;
