import React from 'react';
import { format } from 'date-fns';
import { TruncatedText } from '../../../se/components/typography';
import FormStatus from '../../../form/answers/FormStatus';
import { tryParseJson } from '../../../util/parseJson';
import { Category, CovidScreeningStatus, OrganizationType } from '../../../types/CovidScreening';
import { isCompleted, isPass } from '../../../form/Form';
import { sortString } from '../../../util/sort';
import secretValue from '../../../util/secretValue';

const Text = TruncatedText as any;

const getCovidScreeningStatus = (data: any, category: Category): CovidScreeningStatus => {
  if (!data) return CovidScreeningStatus.NotFilled;

  if (category === Category.Patient) {
    const question = tryParseJson(data?.submission?.question);
    const answer = tryParseJson(data?.submission?.answer);
    return isCompleted({ category }, question, answer)
      ? isPass({ category }, question, answer)
        ? CovidScreeningStatus.Pass
        : CovidScreeningStatus.CovidRisk
      : CovidScreeningStatus.NotFilled;
  } else {
    const question = tryParseJson(data?.question);
    const answer = tryParseJson(data?.answer);
    return isCompleted({ category }, question, answer)
      ? isPass({ category }, question, answer)
        ? CovidScreeningStatus.Pass
        : CovidScreeningStatus.CovidRisk
      : CovidScreeningStatus.NotFilled;
  }
};

const getFullName = (firstName?: string, lastName?: string) =>
  firstName && lastName ? `${firstName} ${lastName}` : firstName ? firstName : lastName ? lastName : null;

const getFullNameFromAnswer = (answer: string) => {
  const [firstName, lastName] = [tryParseJson(answer)?.name, tryParseJson(answer)?.lastName];
  return firstName === secretValue || lastName === secretValue ? null : getFullName(firstName, lastName);
};

const getColumns = (category: Category, organizationType: OrganizationType) =>
  category === Category.Patient
    ? [
        {
          title: 'Name',
          key: 'name',
          lens: (data: any) =>
            data?.procedure?.patientName ||
            getFullNameFromAnswer(data?.submission?.answer) ||
            data?.submission?.id.toUpperCase(),
          Component: (props: any) => <Text>{props.data}</Text>,
        },
        organizationType === OrganizationType.SurgeryCenter
          ? {
              title: 'Surgery Date',
              key: 'scheduledAt',
              lens: (data: any) =>
                data?.procedure?.scheduledAt ? format(data?.procedure?.scheduledAt, 'MM/DD/YYYY HH:mm') : '-',
              Component: (props: any) => <Text>{props.data}</Text>,
            }
          : {
              title: 'Consultation Date',
              key: 'scheduledAt',
              lens: (data: any) =>
                data?.procedure?.scheduledAt ? format(data?.procedure?.scheduledAt, 'MM/DD/YYYY HH:mm') : '-',
              Component: (props: any) => <Text>{props.data}</Text>,
            },
        {
          title: 'Submission Date',
          key: 'dateSubmitted',
          lens: (data: any) =>
            data?.submission?.dateSubmitted ? format(data?.submission?.dateSubmitted, 'MM/DD/YYYY HH:mm') : '-',
          Component: (props: any) => <Text>{props.data}</Text>,
        },
        {
          title: 'Status',
          key: 'status',
          lens: (data: any) => ({
            procedureId: data?.procedure?.id,
            submissionId: data?.submission?.id || data?.procedure?.submissionId,
            startTime: data?.procedure?.scheduledAt,
            patientName: tryParseJson(data?.submission?.answer)?.name || data?.procedure?.patientName,
            patientPhoneNumber: data?.procedure?.patientPhoneNumber,
            status: getCovidScreeningStatus(data, category),
            covidScreeningInviteSentAt: data?.procedure?.covidScreeningInviteSentAt,
            covidScreeningInviteSMSCount: data?.procedure?.covidScreeningInviteSMSCount,
            lastCovidScreeningInviteSMS: data?.procedure?.lastCovidScreeningInviteSMS,
            category,
          }),
          Component: FormStatus,
          sortFunction: (l: any, r: any) =>
            sortString(getCovidScreeningStatus(l, category), getCovidScreeningStatus(r, category)),
        },
      ]
    : [
        {
          title: 'Name',
          key: 'name',
          lens: (data: any) => getFullNameFromAnswer(data?.answer) || data?.id.toUpperCase(),
          Component: (props: any) => <Text>{props.data}</Text>,
        },
        {
          title: 'Submission Date',
          key: 'dateSubmitted',
          lens: (data: any) => (data.dateSubmitted ? format(data.dateSubmitted, 'MM/DD/YYYY HH:mm') : '-'),
          Component: (props: any) => <Text>{props.data}</Text>,
        },
        {
          title: 'Status',
          key: 'status',
          lens: (data: any) => ({
            procedureId: data?.procedure?.id,
            submissionId: data?.id,
            startTime: data?.procedure?.scheduledAt,
            patientName: tryParseJson(data?.answer)?.name || data?.procedure?.patientName,
            patientPhoneNumber: data?.procedure?.patientPhoneNumber,
            status: getCovidScreeningStatus(data, category),
            covidScreeningInviteSentAt: data?.procedure?.covidScreeningInviteSentAt,
            covidScreeningInviteSMSCount: data?.procedure?.covidScreeningInviteSMSCount,
            lastCovidScreeningInviteSMS: data?.procedure?.lastCovidScreeningInviteSMS,
            category,
          }),
          Component: FormStatus,
          sortFunction: (l: any, r: any) =>
            sortString(getCovidScreeningStatus(l, category), getCovidScreeningStatus(r, category)),
        },
      ];

export default getColumns;
