import React from 'react';
import Form from '../../../types/Form';
import PatientChartForm from './PatientChartForm';
import { getQuestionnaireType } from '../../pages/kiosk/charting/ChartingForms';
import Groups from '../procedures/Groups';

const PatientChartFormGroup = ({
  hospitalId,
  patientId,
  procedureId,
  forms,
}: {
  hospitalId: number;
  patientId: number;
  procedureId: number;
  forms: Form[];
}) =>
  forms.length === 1 ? (
    <PatientChartForm
      id={forms[0].id}
      hospitalId={hospitalId}
      patientId={patientId}
      procedureId={procedureId}
      questionnaireType={getQuestionnaireType(forms[0].type)}
    />
  ) : (
    <Groups
      groups={forms.map(form => ({
        id: String(form.id),
        name: form.name,
        contents: (
          <PatientChartForm
            id={form?.id}
            hospitalId={hospitalId}
            patientId={patientId}
            procedureId={procedureId}
            questionnaireType={getQuestionnaireType(form?.type)}
          />
        ),
      }))}
    />
  );

export default PatientChartFormGroup;
