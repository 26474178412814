import { compose } from 'recompose';
import SelectInput from '../../../se/components/inputs/SelectInput';
import { withLabel } from '../../../se/components/Label';
import { list } from '../../../graph/config';
import { ConfigType } from '../../../types/HospitalConfig';
import React from 'react';
import { useQuery } from '@apollo/client';

const LabeledSelectInput = compose(withLabel('Title'))(SelectInput);

const TitleSelectInput = ({ value, onChange }) => {
  const selectedValue = value && JSON.parse(value)?.abbr;

  const { data } = useQuery(list, {
    variables: {
      type: ConfigType.StaffTitle,
    },
  });

  const options = data?.configs?.reduce((acc, curr) => {
    const parsed = curr?.values && JSON.parse(curr?.values);
    return parsed ? { ...acc, [parsed?.abbr]: parsed?.title } : acc;
  }, {});

  return (
    <LabeledSelectInput
      /* @ts-ignore */
      placeholder={'e.g., Registered Nurse'}
      required={false}
      value={selectedValue}
      onChange={val => {
        onChange(JSON.stringify({ title: options[val], abbr: val }));
      }}
      options={options}
    />
  );
};

export default TitleSelectInput;
