import React from 'react';
import { TruncatedText } from '../../../se/components/typography';

import { OR, PACU, POST_OP, PRE_OP, ROOM_TYPE_LABELS } from './enums';
import { sortNumber } from '../../../util/sort';

export const nameColumn = {
  title: 'Name',
  lens: data => data.name,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const baseColumns = [
  {
    title: 'Type',
    lens: data => ROOM_TYPE_LABELS[data.type],
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  {
    title: 'Capacity',
    lens: data => ([PRE_OP, PACU, POST_OP].includes(data.type) ? data.capacity : undefined),
    Component: ({ data }) => <TruncatedText>{data}</TruncatedText>,
    sortFunction: (l, r) => sortNumber(l.capacity, r.capacity),
  },
  {
    title: 'Daily capacity in hours',
    lens: data => (data.type === OR ? `${(data.availability ? data.availability + 'h' : false) || '–'}` : undefined),
    Component: ({ data }) => <TruncatedText>{data}</TruncatedText>,
    sortFunction: (l, r) => sortNumber(l.availability, r.availability),
  },
  {
    title: 'Air Filtration Duration',
    lens: data => (data.airFiltrationDurationMin ? data.airFiltrationDurationMin + ' min' : undefined),
    Component: ({ data }) => <TruncatedText>{data}</TruncatedText>,
    sortFunction: (l, r) => sortNumber(l.airFiltrationDurationMin, r.airFiltrationDurationMin),
  },
  {
    title: 'OR Order',
    lens: data => (data.type === OR ? `${(data.order ? data.order : false) || '–'}` : undefined),
    Component: ({ data }) => <TruncatedText>{data}</TruncatedText>,
    sortFunction: (l, r) => sortNumber(l.order, r.order),
  },
];
