import React, { Fragment } from 'react';
import { Link, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import GridFader from '../core/GridFader';
import AdminHomepage from './user/admin/Homepage';
import SOAdminHomepage from './user/surgeon/admin/Homepage';
import { unpackSessionObject } from './unpackSessionObject';
import { withSession } from '../../state/Session';
import ExpireableConnectionProvider from '../../components/ExpireableConnectionProvider';
import { OrganizationType } from '../entities/hospitals/enums';

import get from 'lodash/get';
import Initialization from './user/Initialization';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { AppBar, MuiThemeProvider } from '@material-ui/core';
import { muiThemeDark } from '../../muiTheme';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import { ArrowBack } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { Scope, ScopeProvider } from '../../contexts/ScopeContext';

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: 1201,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

const AdminRibbon = ({ organizationId, label }) => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={muiThemeDark}>
      <Hidden smDown>
        <AppBar position="sticky" className={classes.appBar}>
          <Toolbar variant="dense">
            <Button
              component={Link}
              to={`/hospitals/${organizationId}`}
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              startIcon={<ArrowBack />}
            >
              {`Back to ${label}`}
            </Button>
            <Typography color="inherit">{`You are viewing this page as a ${label}.`}</Typography>
          </Toolbar>
        </AppBar>
      </Hidden>
    </MuiThemeProvider>
  );
};

const RibbonAndAdminHomepage = ({ label, organizationId }) => (
  <Scope.Consumer>
    {scope => (
      <Fragment>
        <AdminRibbon label={label} organizationId={organizationId} />
        {scope ? (
          get(scope, 'hospital.type') === OrganizationType.SurgeonOffice ? (
            <SOAdminHomepage />
          ) : (
            <AdminHomepage />
          )
        ) : (
          <Initialization />
        )}
      </Fragment>
    )}
  </Scope.Consumer>
);

const userRoleLabel = ({ isSuperAdmin, isGroupAdmin, isMonitoringEngineer }) => {
  if (isSuperAdmin) return 'super admin';
  else if (isGroupAdmin) return 'group admin';
  else return 'monitoring engineer';
};

const User = ({ match, session, accessToken, isSuperAdmin, isGroupAdmin, isMonitoringEngineer, ...rest }) => (
  <Switch component={GridFader}>
    <Route
      render={() =>
        session && session.valid && (isSuperAdmin || isGroupAdmin || isMonitoringEngineer) ? (
          <ExpireableConnectionProvider session={session} organizationId={match.params.organizationId}>
            <ScopeProvider>
              <RibbonAndAdminHomepage
                organizationId={match?.params?.organizationId}
                label={userRoleLabel({ isSuperAdmin, isGroupAdmin, isMonitoringEngineer })}
                isGroupAdmin={isGroupAdmin}
              />
            </ScopeProvider>
          </ExpireableConnectionProvider>
        ) : (
          <Redirect to={`/`} />
        )
      }
    />
  </Switch>
);

export default compose(withRouter, withSession(unpackSessionObject))(User);
