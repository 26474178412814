import React, { Fragment } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { compose, mapProps, withProps } from 'recompose';
import get from 'lodash/fp/get';
import Page from '../../../../../se/components/Page';
import Sidebar from '../../../../../se/components/Sidebar';
import ChangePassword from '../../ChangePassword';
import Header from '../../admin/Header';
import Footer from '../../admin/Footer';
import { dark as darkTheme } from '../../../../../theme';
import { Context as PatientNameFormatContext } from '../../../../../contexts/PatientNameFormat';
import { withSession } from '../../../../../state/Session';
import { unpackSessionObject } from '../../../unpackSessionObject';
import { isDefinedAndNotNull } from '../../../../../se/utilities/check';
import Configuration from '../../../Configuration';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import { muiThemeDark } from '../../../../../muiTheme';
import ThemeBasedCSS from '../../../../ThemeBasedCSS';
import Users from '../../../../entities/user/Users';

import Physicians from '../../../../entities/surgeonPhysician/Physicians';
import Feedback from '../../../../entities/surgeonFeedback/Feedback';
import Schedule from '../../../../entities/schedule/Schedule';
import { CssBaseline } from '@material-ui/core';
import { MainContainer } from '../../../MainContainer';
import { OmniSearchProvider, OmniSearchSources } from '../../../../OmniSearch';
import proceduresSource from '../../../../../omnisearch/sources/surgeon/procedures';
import Surgeries from '../../../../entities/surgeonOffice/surgeries/Surgeries';
import Consultations from '../../../../entities/surgeonOffice/consultations/Consultations';
import { CovidScreeningSO } from '../../../../entities/covidScreening/CovidScreeningSubmissions';
import {
  AirlineSeatFlat,
  Contacts,
  Event,
  LocalHospital,
  MoreHoriz,
  People,
  Settings,
  ThumbUp,
} from '@material-ui/icons';
import { withScope } from '../../../../../contexts/ScopeContext';

const getAdminRoutes = (basePath, baseUrl, modules) =>
  [
    modules.exchangeOnly
      ? undefined
      : {
          path: `${basePath}/consultations`,
          to: `${baseUrl}/consultations`,
          label: 'Consultations',
          icon: <Contacts />,
          component: Consultations,
        },
    {
      path: `${basePath}/surgeries`,
      to: `${baseUrl}/surgeries`,
      label: 'Surgeries',
      icon: <AirlineSeatFlat />,
      component: Surgeries,
    },
    {
      path: `${basePath}/schedule-procedure`,
      to: `${baseUrl}/schedule-procedure`,
      label: 'Surgery Schedule',
      icon: <Event />,
      component: Schedule,
    },
    {
      separator: true,
    },
    {
      path: `${basePath}/feedback`,
      to: `${baseUrl}/feedback`,
      label: 'Feedback',
      icon: <ThumbUp />,
      component: Feedback,
    },
    modules.covidScreening
      ? {
          path: `${basePath}/covid-screening`,
          to: `${baseUrl}/covid-screening`,
          label: 'Covid Screening',
          icon: 'coronavirus',
          component: CovidScreeningSO,
        }
      : undefined,
    {
      separator: true,
    },
    {
      label: 'Configuration',
      icon: <Settings />,
      subPages: [
        {
          path: `${basePath}/users`,
          to: `${baseUrl}/users`,
          label: 'Users',
          icon: <People />,
          component: Users,
        },
        {
          path: `${basePath}/physicians`,
          to: `${baseUrl}/physicians`,
          label: 'Physicians',
          icon: <LocalHospital />,
          component: Physicians,
        },
        {
          path: `${basePath}/configuration`,
          to: `${baseUrl}/configuration`,
          label: 'Messaging',
          icon: <MoreHoriz />,
          component: Configuration,
        },
      ],
    },
  ].filter(_ => !!_);

const getSwitchRoutes = (basePath, baseUrl, modules) => getAdminRoutes(basePath, baseUrl, modules);

export const getBasePathAndUrl = match => {
  if (!isDefinedAndNotNull(match) || !isDefinedAndNotNull(!match.path) || !isDefinedAndNotNull(!match.url)) {
    return ['', ''];
  }
  return [match.path === '/' ? '' : match.path, match.url === '/' ? '' : match.url];
};

const AdminSidebar = compose(
  withSession(unpackSessionObject),
  withRouter,
  withScope,
  withProps({
    Header,
    links: [],
    Footer,
  }),
  mapProps(props => {
    const { match, isSuperAdmin } = props;
    const [basePath, baseUrl] = getBasePathAndUrl(match);
    const modules = {
      exchangeOnly: props.scope.hospital.exchangeOnly,
      covidScreening: !!props.scope?.hospital?.covidScreening,
    };

    return {
      isSuperAdmin,
      ...props,
      links: getSwitchRoutes(basePath, baseUrl, modules),
    };
  })
)(Sidebar);

const Homepage = props => {
  const [basePath, baseUrl] = getBasePathAndUrl(props.match);
  const modules = {
    exchangeOnly: props.scope.hospital.exchangeOnly,
    covidScreening: !!props.scope?.hospital?.covidScreening,
  };
  const switchRoutes = getSwitchRoutes(basePath, baseUrl, modules).filter(_ => !_.separator);
  return (
    <ThemeProvider theme={darkTheme}>
      <MUIThemeProvider theme={muiThemeDark}>
        <style>
          {`body {
            background: ${muiThemeDark.palette.background.default};
          }`}
        </style>
        <CssBaseline />
        <PatientNameFormatContext.Provider
          value={props.isSuperAdmin ? 'PatientId' : props.patientNameFormat || 'FullName'}
        >
          <OmniSearchProvider>
            <OmniSearchSources sources={[proceduresSource]} />
            <Fragment>
              <Page Sidebar={AdminSidebar}>
                <MainContainer switchControl>
                  <Switch>
                    <Route
                      exact
                      path={`${basePath}/`}
                      render={() => (
                        <Redirect to={`${baseUrl}/${modules.exchangeOnly ? 'surgeries' : 'consultations'}`} />
                      )}
                    />
                    {switchRoutes.map(({ path, component, subPages }, i) => [
                      path ? <Route key={i} path={path} component={component} /> : null,
                      ...(subPages
                        ? subPages.map(subPage => (
                            <Route
                              key={path ? `${path}${subPage.path}` : subPage.path}
                              path={path ? `${path}${subPage.path}` : subPage.path}
                              component={subPage.component}
                            />
                          ))
                        : []),
                    ])}
                    <Route path="/change-password" component={ChangePassword} />
                    <Route
                      render={() => (
                        <Redirect to={`${baseUrl}/${modules.exchangeOnly ? 'surgeries' : 'consultations'}`} />
                      )}
                    />
                  </Switch>
                </MainContainer>
              </Page>
              <ThemeBasedCSS />
            </Fragment>
          </OmniSearchProvider>
        </PatientNameFormatContext.Provider>
      </MUIThemeProvider>
    </ThemeProvider>
  );
};

export default compose(
  withSession(unpackSessionObject),
  withRouter,
  withSession(get('session.account.metadata')),
  withScope
)(Homepage);
