import React, { FC } from 'react';
import { create as createProcedureType, list as procedureTypes } from '../../../../../../graph/procedureTypes';
import { procedureTypesPicked, setValueAndResetError } from '../utils/values';
import set from 'lodash/fp/set';
import { transformValue } from '../utils/functions';
import { AutocompleteInputSetter } from './AutocompleteInputSetter';
import { FormParams, ProcedureDetails } from '../types/types';
import { useMutation } from '@apollo/client';

const ProcedureTypeAutocomplete: FC<FormParams<ProcedureDetails>> = ({ value, setValue }) => {
  const [create] = useMutation(createProcedureType, {
    refetchQueries: [{ query: procedureTypes }],
  });

  const createProcedureTypeOption = async (text: string) => {
    const newProcedureType = await create({
      variables: {
        name: text,
      },
    });
    return newProcedureType?.data?.createProcedureType;
  };

  return (
    <AutocompleteInputSetter
      list={procedureTypes}
      label="Procedure Type"
      variant="filled"
      margin="dense"
      fullWidth
      required
      error={!!value?.procedureType?.name?.error}
      helperText={value?.procedureType?.name?.error}
      onChange={event => {
        if (event?.constructor?.name === 'SyntheticEvent') {
          setValue(setValueAndResetError('procedureType.name', event.target.value, value));
        } else {
          setValue(set('procedureType', transformValue(event || {}, procedureTypesPicked))(value));
        }
      }}
      onBlurCreateOption={createProcedureTypeOption}
      value={value?.procedureType?.name?.value}
      multiline
      minRows={4}
    />
  );
};

export default ProcedureTypeAutocomplete;
