import React, { useState } from 'react';

export const TemplateContext = React.createContext();

export const TemplateStateProvider = ({ children, initialState }) => {
  const [variables, setVariables] = useState(initialState);

  return <TemplateContext.Provider value={{ variables, setVariables }}>{children}</TemplateContext.Provider>;
};

export const withTemplateCtx = Component => props =>
  <TemplateContext.Consumer>{ctx => <Component {...props} {...ctx} />}</TemplateContext.Consumer>;
