import { useMemo, useEffect, useRef } from 'react';
import { differenceInSeconds, subSeconds } from 'date-fns';
import { withDate } from '../../../HospitalInfo';
import { soundAlert } from '../Monitor';
import warningOnSound from '../../../../assets/sound/start_warning.mp3';
import warningOffSound from '../../../../assets/sound/stop_warning.mp3';
import { isDefinedAndNotNull } from '../../../../se/utilities/check';

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useSoundAlert = airFiltrationWarningOn => {
  const prevAirFiltrationWarningOn = usePrevious(airFiltrationWarningOn);
  useEffect(() => {
    if (isDefinedAndNotNull(prevAirFiltrationWarningOn) && airFiltrationWarningOn !== prevAirFiltrationWarningOn) {
      soundAlert(warningOnSound, airFiltrationWarningOn);
      soundAlert(warningOffSound, !airFiltrationWarningOn);
    }
  }, [airFiltrationWarningOn, prevAirFiltrationWarningOn]);
};

export const useFiltrationProgress = ({ warningEndAt, warningDuration, now }) =>
  useMemo(() => {
    const diff = differenceInSeconds(now, subSeconds(warningEndAt, warningDuration));
    return (1 - Math.min(Math.max(diff / warningDuration, 0), 1)) * 100;
  }, [warningEndAt, warningDuration, now]);

export const withTick = withDate(1000);

export const leftPad = val => (String(val).length < 2 ? `0${val}` : val);
export const formatDuration = seconds => {
  const mnt = Math.floor(seconds / 60);
  const sec = seconds % 60;
  return [leftPad(mnt), leftPad(sec)].join(':');
};

export const formatTime = (start, end) => {
  if (start && end) {
    return formatDuration(Math.max(differenceInSeconds(end, start), 0));
  }
  return '--:--';
};
