import store from 'store2';
import isObject from 'lodash/isObject';
import config from './config';

export const getStoredSession = (): unknown => {
  return get(config.apiURL) || get();
};

export const storeSession = (session: unknown): void => {
  set(session, config.apiURL);
};

export const removeStoredSession = (): void => {
  unset(config.apiURL);
  unset();
};

const get = (realm?: string): unknown => {
  const session = store(key(realm));

  if (isObject(session) && session.hasOwnProperty('token')) {
    return session;
  }
};

const set = (session: unknown, realm?: string): void => {
  store(key(realm), session);
};

const unset = (realm?: string): void => {
  store.remove(key(realm));
};

const key = (realm?: string): string => (realm ? `session@${realm}` : 'session');
