type CalendarMonth = {
  month: number;
  year: number;
  date: Date;
  dateString: string;
};

export const generateMonthsBetweenTwoDates = (startDate: string, endDate: string): CalendarMonth[] => {
  const start = startDate.split('-');
  const end = endDate.split('-');
  const startYear = parseInt(start[0]);
  const endYear = parseInt(end[0]);
  const dates: CalendarMonth[] = [];

  for (let i = startYear; i <= endYear; i++) {
    const endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
    const startMonth = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (let j = startMonth; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      const month = j + 1;
      const displayMonth = month < 10 ? '0' + month : month;
      dates.push({
        dateString: [i, displayMonth, '01'].join('-'),
        month: month - 1,
        year: i,
        date: new Date(i, month - 1, 1),
      });
    }
  }
  return dates;
};

export const convertDateToString = (date: Date) => {
  let d = new Date(date);

  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const convertStringDateToDate = (date: string) => {
  const year = date.slice(0, 4) as unknown as number;
  const month = date.slice(4, 6) as unknown as number;
  const day = date.slice(6, 8) as unknown as number;

  return new Date(year, month - 1, day);
};
