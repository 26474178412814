import isNull from 'lodash/isNull';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import mapValues from 'lodash/mapValues';

export function sanitize(object) {
  if (isString(object)) return _sanitizeString(object);
  if (isArray(object)) return _sanitizeArray(object);
  if (isPlainObject(object)) return _sanitizeObject(object);
  return object;
}

function _sanitizeString(string) {
  return isEmpty(string) ? null : string;
}

function _sanitizeArray(array) {
  return filter(map(array, sanitize), _isProvided);
}

function _sanitizeObject(object) {
  return pickBy(mapValues(object, sanitize), _isProvided);
}

function _isProvided(value) {
  const typeIsNotSupported = !isNull(value) && !isString(value) && !isArray(value) && !isPlainObject(value);
  return typeIsNotSupported || !isEmpty(value);
}
