import { compose } from 'recompose';
import SelectInput from '../../../../../se/components/inputs/SelectInput';
import { withLabel } from '../../../../../se/components/Label';
import { list } from '../../../../../graph/config';
import { ConfigType } from '../../../../../types/HospitalConfig';
import React from 'react';
import { useQuery } from '@apollo/client';

const LabeledSelectInput = compose(withLabel('Cancellation Reason'))(SelectInput);

const CancellationSelectInput = ({ value, onChange }) => {
  const { data } = useQuery(list, {
    variables: {
      type: ConfigType.ProcedureCancellationReason,
    },
  });

  const options = data?.configs?.reduce((acc, curr) => {
    const parsed = curr?.values && JSON.parse(curr?.values);
    return parsed ? { ...acc, [parsed?.name]: parsed?.name } : acc;
  }, {});

  return (
    <LabeledSelectInput
      /* @ts-ignore */
      placeholder={'e.g., Schedule change'}
      required={false}
      value={value}
      onChange={onChange}
      options={options}
    />
  );
};

export default CancellationSelectInput;
