import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { gzip, ungzip } from 'pako';

import scheduledRequestMock from '../mocks/scheduleRequest/graphql';

import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

import config from '../config';
import DurableMutationsLink from './DurableMutationsLink';
import isMutation from './isMutation';
import { LocalStorageOperationsRepository } from './OperationsRepository';

const RESPONSE_UNAUTHORIZED = 'Unauthorized';

const NativeWebSocket = window.WebSocket || window.MozWebSocket;

const defaultConnectionType = !import.meta.env.MODE || import.meta.env.MODE === 'development' ? 'text' : 'binary';

class MessageQueue {
  nextReceivedMessageId = 1;
  lastProcessedMessageId = 0;
  indexedMessageQueue = {};

  getNextId() {
    return this.nextReceivedMessageId++;
  }

  process(messageId, fn) {
    this.indexedMessageQueue[messageId] = fn;

    while (true) {
      const next = this.indexedMessageQueue[this.lastProcessedMessageId + 1];

      if (!next) {
        break;
      }

      next();

      this.indexedMessageQueue[this.lastProcessedMessageId + 1] = undefined;

      this.lastProcessedMessageId += 1;
    }
  }
}

class MyWebSocket extends NativeWebSocket {
  messageQueue = new MessageQueue();

  send(data) {
    super.send(gzip(data));
  }
  set onmessage(handler) {
    const myWebSocket = this;

    function proxyHandler(ev) {
      const messageId = myWebSocket.messageQueue.getNextId();

      if (ev.data) {
        if (ev.data instanceof Blob) {
          const reader = new FileReader();

          reader.onload = () => {
            const data = ungzip(new Uint8Array(reader.result), { to: 'string' });
            myWebSocket.messageQueue.process(messageId, () => handler.call(this, { ...ev, data }));
          };

          reader.readAsArrayBuffer(ev.data);
        } else if (ev.data instanceof Object && ev.data.arrayBuffer instanceof Function) {
          ev.data.arrayBuffer().then(buffer => {
            const data = ungzip(new Uint8Array(buffer), { to: 'string' });
            myWebSocket.messageQueue.process(messageId, () => handler.call(this, { ...ev, data }));
          });
        } else if (ev.data instanceof ArrayBuffer) {
          const data = ungzip(new Uint8Array(ev.data), { to: 'string' });
          myWebSocket.messageQueue.process(messageId, () => handler.call(this, { ...ev, data }));
        } else {
          try {
            myWebSocket.messageQueue.process(messageId, () => handler.call(this, ev));
          } finally {
            console.warn('Unexpected type of WS data: ', typeof ev.data);
          }
        }
      } else {
        myWebSocket.messageQueue.process(messageId, () => handler.call(this, ev));
      }
    }
    super.onmessage = proxyHandler;
  }
}

export const createApolloClient = (accessToken, organizationId, onUnauthorized, onConnected, onDisconnected) => {
  const [protocol, host] = config.apiURL.split('://');
  const connectionType = localStorage.getItem('ws-connection-type') || defaultConnectionType;
  if (connectionType !== 'binary' && connectionType !== 'text')
    console.warn(`Unknown WS connection type ${connectionType}, defaulting to ${connectionType}.`);
  const endpoint = `${protocol === 'https' ? 'wss' : 'ws'}://${host}/graphql${
    connectionType === 'binary' ? '?binary' : ''
  }`;
  const wsImpl = connectionType === 'binary' ? MyWebSocket : undefined;

  const subscriptionClient = new SubscriptionClient(
    endpoint,
    {
      reconnect: true,
      reconnectionAttempts: Number.MAX_SAFE_INTEGER,
      connectionParams: () =>
        pickBy(
          {
            accessToken,
            organizationId: isFinite(organizationId) && organizationId,
            clientVersion: config.appVersion,
          },
          identity
        ),
      connectionCallback: (error, result) => {
        if (error && error.toString().startsWith(RESPONSE_UNAUTHORIZED)) {
          subscriptionClient.close();
          if (onUnauthorized) {
            onUnauthorized(error, result);
          }
        }
      },
    },
    wsImpl
  );

  if (onConnected) {
    subscriptionClient.onConnected(onConnected);
    subscriptionClient.onReconnected(onConnected);
  }

  if (onDisconnected) {
    subscriptionClient.onDisconnected(onDisconnected);
  }

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([
      new DurableMutationsLink(new LocalStorageOperationsRepository(organizationId)),
      new RetryLink({
        delay: {
          initial: 300,
          max: 10000,
          jitter: true,
        },
        attempts: {
          max: 10,
          retryIf: (_, operation) => isMutation(operation),
        },
      }),
      new WebSocketLink(subscriptionClient),
    ]),
    cache: new InMemoryCache(),
    typeDefs: scheduledRequestMock.typeDefs,
    resolvers: scheduledRequestMock.resolvers,
  });

  return [apolloClient, subscriptionClient];
};
