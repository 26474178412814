import React, { useEffect, useState } from 'react';
import { NotesAnswer } from '../../types/Answer';
import { NotesQuestion } from '../../types/Question';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Box, Button, Slide, SvgIcon, TextField, useTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import { useNotesContext } from '../../components/pages/kiosk/charting/NotesContextProvider';
import { createPortal } from 'react-dom';
import randomId from './util/randomId';
import Fab from '@material-ui/core/Fab';
import ReactHtmlParser from 'react-html-parser';

type NotesInputProps = NotesQuestion['props'] & {
  name?: string;
  answer?: NotesAnswer;
  onChange?: (newAnswer: NotesAnswer) => void;
};

export const useStyles = makeStyles(theme => ({
  root: {},
  open: {
    transform: 'translateY(400px)',
  },
  notesButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.common.white,
    padding: theme.spacing(1.5, 2, 2),
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
    width: 400,
    height: 110,
    overflow: 'hidden',
    marginBottom: theme.spacing(-1.25),
    transition: `transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
    zIndex: 1,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(5),
    left: theme.spacing(5),
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function NotesIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M17,4V10L15,8L13,10V4H9V20H19V4H17M3,7V5H5V4C5,2.89 5.9,2 7,2H19C20.05,2 21,2.95 21,4V20C21,21.05 20.05,22 19,22H7C5.95,22 5,21.05 5,20V19H3V17H5V13H3V11H5V7H3M5,5V7H7V5H5M5,19H7V17H5V19M5,13H7V11H5V13Z" />
    </SvgIcon>
  );
}

const NotesInput = ({ name, label, answer, onChange }: NotesInputProps) => {
  const { rootElement } = useNotesContext();
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const [inputKey, setInputKey] = useState<string>();

  useEffect(() => {
    if (!open) {
      return;
    }

    const timeout = setTimeout(() => {
      setInputKey(randomId(6));
    }, 350);

    return () => clearTimeout(timeout);
  }, [open]);

  const theme = useTheme();

  return (
    <>
      <Dialog TransitionComponent={Transition} open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogContent>
          <DialogTitle>{ReactHtmlParser(label)}</DialogTitle>
          <TextField
            key={inputKey}
            multiline
            type="text"
            autoComplete="off"
            autoFocus
            label={ReactHtmlParser(label)}
            variant="filled"
            fullWidth={true}
            aria-label={label}
            name={name}
            value={answer ?? ''}
            onChange={e => onChange?.(e.target.value)}
            minRows={3}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {createPortal(
        <Fab variant="extended" className={classes.fab} aria-label="edit" onClick={() => setOpen(true)}>
          <Box mr={1} display="flex" alignItems="center">
            <NotesIcon color="secondary" />
          </Box>
          Notes
        </Fab>,
        rootElement
      )}
    </>
  );
};

export default NotesInput;
