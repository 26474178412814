import React from 'react';
import { useMutation } from '@apollo/client';
import { clearLockdown } from '../../../graph/users';
import Button from '@material-ui/core/Button';
import NotificationsIcon from '@material-ui/icons/Notifications';

export default ({ data: { id, lockdown }, refetch }) => {
  const [mutate] = useMutation(clearLockdown);
  const clear = () => mutate({ variables: { id }, refetchQueries: [{ query: refetch, variables: { id } }] });

  return lockdown ? (
    <Button color="secondary" onClick={clear} startIcon={<NotificationsIcon />}>
      Clear Lockdown
    </Button>
  ) : null;
};
