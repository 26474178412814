import { Theme, alpha } from '@material-ui/core';

export default function Button(theme: Theme) {
  return {
    MuiToggleButton: {
      root: {
        ...theme.typography.button,
        border: `1px solid ${alpha(theme.palette.action.active, 0.12)}`,
        color: alpha(theme.palette.action.active, 0.38),
      },
    },
  };
}
