import React, { Fragment, useContext, useEffect, useState } from 'react';

import ToastBar from './ToastBar';

const Context = React.createContext();

export const useToaster = () => useContext(Context);

export default ({ children }) => {
  const [{ nextId, toast }, setState] = useState({ nextId: 0 });

  useEffect(() => {
    if (toast) {
      const timeout = setTimeout(() => {
        setState({ nextId });
      }, 5000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [toast, nextId]);

  const toaster = {
    info: children => setState({ nextId: nextId + 1, toast: { id: nextId, variant: 'info', children } }),
    success: children => setState({ nextId: nextId + 1, toast: { id: nextId, variant: 'success', children } }),
    warning: children => setState({ nextId: nextId + 1, toast: { id: nextId, variant: 'warning', children } }),
    error: children => setState({ nextId: nextId + 1, toast: { id: nextId, variant: 'error', children } }),
  };

  return (
    <Context.Provider value={toaster}>
      <Fragment>
        {toast && <ToastBar {...toast} />}
        {children}
      </Fragment>
    </Context.Provider>
  );
};
