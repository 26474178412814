import React, { FC } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { useQuery } from '@apollo/client';
import { totalCancellationsQuery } from '../../../../graph/dashboard';
import { Paper, Typography, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { renderLegend, renderActiveShape } from './charts/ORPerformancePieChart';

const COLORS = ['#00A7F7', '#FFBB28', '#485796', '#2B3C87', '#44AABF', '#FFD166', '#FF6F61', '#3D9970', '#F38181'];

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper>
        <Box p={2}>
          <Typography gutterBottom>{payload[0].name}</Typography>
          {payload.map((p, i) => (
            <>
              <Box key={i} display="flex" alignItems="center" style={{ width: '16ch' }}>
                <Typography variant="h4">{`${Math.round(p.value)} case${
                  Math.round(p.value) % 10 == 1 ? '' : 's'
                }`}</Typography>
              </Box>
            </>
          ))}
        </Box>
      </Paper>
    );
  }

  return null;
};

const ProcedureCancellationChart: FC<{ filter: any }> = ({ filter }) => {
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const { data: fetchedData, loading } = useQuery(totalCancellationsQuery, {
    variables: { filter },
    fetchPolicy: 'network-only',
  });

  const data = fetchedData?.totalCancellations?.data?.map(e => ({ name: e.name, value: e.value })) || [];

  const total = Math.round(data.reduce((acc, cur) => acc + cur.value, 0));

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          startAngle={90}
          endAngle={-270}
          dataKey="value"
          data={data}
          innerRadius="50%"
          outerRadius="70%"
          activeShape={props => renderActiveShape(props, `${total}`, 'cancellations')}
          activeIndex={activeIndex}
        >
          {data.map((entry, index) => (
            <Cell
              stroke="#1A2553"
              strokeWidth={3}
              style={{ outline: 'none' }}
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
        </Pie>
        <Tooltip
          content={({ active, payload, label }) => <CustomTooltip active={active} payload={payload} label={label} />}
          cursor={{ fill: 'rgba(255,255,255,0.15)' }}
          contentStyle={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: 4,
            border: 0,
          }}
        />
        <Legend content={renderLegend} align="left" verticalAlign="bottom" iconType="circle" iconSize={8} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default ProcedureCancellationChart;
