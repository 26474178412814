import React, { useEffect } from 'react';
import styled from 'styled-components';
import { getNestedValue } from '../../../se/utilities/data/object';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';

import scheme from '../../../graph/superUsers';
import { lastLogin, lastLoginNoAction, nameColumn } from './columns';
import { TruncatedText } from '../../../se/components/typography';
import format from 'date-fns/format';
import { superUserRoleLabels } from '../role/labels';
import UserInput from '../../inputs/user/UserInput';
import { SuperAdminNotificationButton } from '../../pages/user/admin/NotificationsButton';
import UserMobileListItem from './UserMobileListItem';
import { monitoringUsers } from '../../../graph/users';
import ClearLockdownButton from '../../pages/user/ClearLockdownButton';
import Box from '@material-ui/core/Box';

const Empty = { illustration: theme => theme.illustrations.users };

const baseColumns = [
  {
    title: 'Email',
    key: 'email',
    lens: item => item.email,
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  {
    title: 'Role',
    key: 'role',
    lens: item => item.superRole && item.superRole,
    Component: props => <TruncatedText>{getNestedValue(props.data, superUserRoleLabels)}</TruncatedText>,
  },
  {
    title: 'Created At',
    lens: item => item.dateCreated,
    Component: props => <TruncatedText>{format(props.data, 'MM/DD/YYYY HH:mm:ss')}</TruncatedText>,
  },
];

const notificationColumn = {
  title: 'Receiving Notifications',
  lens: item => item,
  Component: props => (
    <Box my={-1}>
      <SuperAdminNotificationButton {...props} />
    </Box>
  ),
};

export const List = {
  tableKey: 'SuperAdminUsers',
  MobileItemComponent: UserMobileListItem,
  columns: [nameColumn, ...baseColumns, lastLoginNoAction, notificationColumn],
  Empty,
};

export const Create = {
  actionTitleButton: 'Create & Send Invitation',
  Input: UserInput(superUserRoleLabels, true, true),
  Empty,
};

const Row = styled.div`
  display: flex;
  :nth-child(even) {
    margin-left: 0.5em;
  }
`;

const Actions = props => (
  <Row>
    <SuperAdminNotificationButton {...props} />
    <ClearLockdownButton {...props} refetch={scheme.item} />
  </Row>
);

export const Show = {
  columns: [...baseColumns, lastLogin],
  Empty,
  Actions: Actions,
};

export const Edit = {
  Input: UserInput(superUserRoleLabels, false, true),
  removeRefetchQueries: [{ query: monitoringUsers }],
  mapEditItemProps: ({ data }) => ({
    data: data && {
      ...data,
      user: {
        ...data.user,
        role: getNestedValue('user.superRole', data),
      },
    },
  }),
  Empty,
};

export default GraphQLCRUD({
  entityName: 'User',
  scheme,
  List,
  Create,
  Show,
  Edit,
});
