import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { assignBed } from '../../../graph/patients';
import TextInputComponent from '../../../se/components/inputs/TextInput';
import LinkButton from '../../../se/components/LinkButton';
import { CenteredSpinner as CenteredSpinnerComponent } from '../../../se/components/Spinner';
import { useMutation } from '@apollo/client';
import { Bed, Patient } from '../../../types/Patient';
import { Close } from '@material-ui/icons';

const Row = styled.div`
  display: flex;
  align-items: center;
  > div > input {
    max-width: 10em;
    padding: 0.4em;
    border-color: #a9d4f3;
  }
`;

const Action = styled.span`
  width: 3em;
  padding-left: 1em;
`;

const ClearableInput = styled.div`
  position: relative;
  > button {
    border-bottom: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: auto;
    right: 0.8em;
    margin: auto;
  }
`;

const TextInput = TextInputComponent as any;
const CenteredSpinner = CenteredSpinnerComponent as any;

const BedInlineInput: FC<{
  patient?: Patient;
  onClick?: (bed: Bed) => void;
}> = ({ patient, onClick }) => {
  const [bed, setBed] = useState<Bed>(patient?.bed);

  const isSaved = patient?.bed === bed;

  const [assign, { loading }] = useMutation(assignBed);

  const onSave = async () => {
    if (onClick) {
      await onClick(bed);
    } else {
      await assign({ variables: { id: patient?.id, bed } });
    }
  };

  return (
    <Row>
      <ClearableInput>
        <TextInput onChange={setBed} value={bed} placeholder="Bed Number" maxlength={4} autocomplete={false} />
        <LinkButton onClick={() => setBed(null)}>
          <Close fontSize={'small'} />
        </LinkButton>
      </ClearableInput>
      <Action>
        {!loading ? (
          <LinkButton onClick={onSave} disabled={loading || isSaved}>
            {isSaved ? 'Saved' : 'Update'}
          </LinkButton>
        ) : (
          <CenteredSpinner />
        )}
      </Action>
    </Row>
  );
};

export default BedInlineInput;
