import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment BookingData on Booking {
      id
      roomId
      roomName
      physicianId
      physicianName
      organizationId
      organizationName
      start
      end
      repeat
      endsAfterWeeks
      date
      notes
      masterBookingId
      createdAt
      forOrganizationId
      procedures {
        id
        organizationId
        patientName
        patient {
          id
          name
          age
          sex
          physician {
            id
            hstId
            name
            email
          }
        }
        procedureType
        serviceDateTime
        serviceTime
        serviceDate
        duration
        scheduleRequest {
          id
        }
      }
    }
  `,
};

export const list = gql`
  query bookingsSO($from: Date!, $to: Date!) {
    bookingsSO(from: $from, to: $to) {
      ...BookingData
    }
  }
  ${fragments.all}
`;
