import React from 'react';
import { withRouter } from 'react-router';
import { useSubscription } from '@apollo/client';
import { Patient } from '../../../types/Patient';
import { QuestionnaireAndAnswers } from '../../../types/Questionnaire';
import { sortBy } from 'lodash';
import { item as patientWithCharts } from '../../../graph/patientChart';
import { ThemeProvider } from '@material-ui/core/styles';
import { tabletThemeLight } from '../../../themes/tabletTheme';

const VitalsSignature = withRouter(({ patientId }: { patientId: string }) => {
  const { data: patientData } = useSubscription(patientWithCharts, {
    variables: { id: patientId },
    skip: !patientId,
  });
  const patient: Patient | undefined = patientData?.patient;

  const charts: QuestionnaireAndAnswers[] | undefined = sortBy(
    patient?.procedure?.pacuCharts || [],
    'questionnaire.order'
  );

  const mergedAnswers = charts?.reduce(
    (acc, e) => ({
      ...acc,
      ...JSON.parse(e?.questionnaireExchange?.answers || '{}'),
    }),
    {}
  );

  const signature = Array.isArray(mergedAnswers) ? undefined : mergedAnswers?.signature;

  return (
    <ThemeProvider theme={tabletThemeLight}>
      {signature && (
        <div style={{ height: '100px' }}>
          <img src={signature.signature} alt={signature.signedBy} height="72" />
          {signature.signedBy && <div>{signature.signedBy}</div>}
        </div>
      )}
    </ThemeProvider>
  );
});

export default VitalsSignature;
