import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import format from 'date-fns/format';
import { TruncatedText } from '../../../se/components/typography';
import { getIssueColor, getIssueIcon, getIssueText } from './helpers';
import scheme from '../../../graph/issues';
import { Link as RouterLink } from 'react-router-dom';
import { withSession } from '../../../state/Session';
import { unpackSessionObject } from '../../pages/unpackSessionObject';
import { sortDate, sortString } from '../../../util/sort';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import { Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  issueColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: props => getIssueColor(props.data, theme),
  },
}));

const IssueColumn = ({ children, data }) => {
  const classes = useStyles({ data });

  return <Box className={classes.issueColumn}>{children}</Box>;
};

class ActionColumn extends React.Component {
  handleAction = mutate => () => {
    mutate({
      variables: {
        issueId: this.props.data.localId,
        organizationId: this.props.data.organizationId,
      },
    });
  };

  render() {
    return (
      <Mutation
        mutation={scheme.resolveIssue}
        refetchQueries={[
          {
            query: scheme.get,
            variables: {
              issueId: this.props.data.localId,
              organizationId: this.props.data.organizationId,
            },
          },
        ]}
      >
        {(mutate, { loading }) => (
          <IssueColumn data={this.props.data}>
            <Box my={-1}>
              {this.props.data.isResolved ? (
                <Button disabled>Resolved</Button>
              ) : (
                <Button color="primary" onClick={this.handleAction(mutate)} disabled={loading}>
                  Resolve
                </Button>
              )}
            </Box>
          </IssueColumn>
        )}
      </Mutation>
    );
  }
}

export const markAsResolvedColumn = {
  title: 'Action',
  lens: item => item,
  Component: ActionColumn,
  sortFunction: (l, r) => l.isResolved - r.isResolved,
};

const OptionalLink = ({ to, condition, children }) =>
  condition ? (
    <Link component={RouterLink} to={to} color="inherit">
      {children}
    </Link>
  ) : (
    children
  );

const IssueType = withSession(unpackSessionObject)(({ data, isSuperAdmin }) => (
  <IssueColumn data={data}>
    <Box mr={1} display="flex">
      <Icon fontSize="small">{getIssueIcon(data)}</Icon>
    </Box>
    {data.type === 'GATEWAY_UNRESPONSIVE' ? (
      <OptionalLink to={`/su/${data.organizationId}/gateways`} condition={isSuperAdmin}>
        <TruncatedText>{getIssueText(data)}</TruncatedText>
      </OptionalLink>
    ) : data.type === 'SCREEN_OFFLINE' ? (
      <OptionalLink to={`/su/${data.organizationId}/screens`} condition={isSuperAdmin}>
        <TruncatedText>{getIssueText(data)}</TruncatedText>
      </OptionalLink>
    ) : (
      <TruncatedText>{getIssueText(data)}</TruncatedText>
    )}
    {data.type === 'LOW_BATTERY' && (
      <Box ml={1}>
        <Link variant="body1" component={RouterLink} to={`/${data.organizationId}/bracelets`}>
          See all
        </Link>
      </Box>
    )}
  </IssueColumn>
));

export const baseColumns = [
  {
    title: 'Time',
    lens: item => item,
    Component: ({ data }) => (
      <IssueColumn data={data}>
        <Typography>{data.createdAt ? format(data.createdAt, 'MM/DD/YYYY HH:mm') : 'N/A'}</Typography>
      </IssueColumn>
    ),
    sortFunction: (l, r) => sortDate(l.createdAt, r.createdAt),
  },
  {
    title: 'Issue',
    lens: item => item,
    Component: IssueType,
    sortFunction: (l, r) => sortString(getIssueText(l), getIssueText(r)),
  },
  {
    title: 'Location',
    lens: item => item,
    Component: ({ data }) => (
      <IssueColumn data={data}>
        <Link variant="body1" component={RouterLink} to={`/hospitals/${data.organizationId}`} color="inherit">
          {data.organizationName}
        </Link>
      </IssueColumn>
    ),
    sortFunction: (l, r) => sortString(l.organizationName, r.organizationName),
  },
];
