import { Subscription } from '@apollo/client/react/components';
import React, { useState } from 'react';
import styled from 'styled-components';
import { restartAirFiltration, roomSubscription } from '../../../../graph/rooms';
import get from 'lodash/get';
import colors from '../../../../colors';
import { useMutation } from '@apollo/client';
import { withProps } from 'recompose';
import defaultTo from 'lodash/defaultTo';
import ProgressRing from './ProgressRing';
import { formatTime, useFiltrationProgress, withTick } from './utils';
import { Counter, TimerOuterRim } from './Common';
import { AirFilterTimeModuleOptional } from '../../../ModuleOptional';

const StartButton = withProps({ children: 'Start' })(styled.button`
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  border: 4px solid ${colors.dusk.alpha(0.8).string()};
  color: ${colors.sky.string()};
  background: transparent;
  font-size: 1rem;

  :hover {
    background: ${colors.sky.alpha(0.1).string()};
    border: 4px solid ${colors.sky.alpha(0.8).string()};
    opacity: 0.9;
  }

  :active {
    border: 4px solid ${colors.sky.string()};
    background: ${colors.dusk.alpha(0.5).string()};
    opacity: 0.9;
  }

  transition: 0.1s all ease;
`);

const RestartButton = withProps({ children: 'refresh', className: 'material-icons' })(styled.i`
  color: ${colors.orange.string()};
  font-size: 1.2rem;
  margin: 0.2rem;
  cursor: pointer;
  :active {
    color: ${colors.orange.alpha(0.6).string()};
  }
  z-index: 1;
`);

const Container = styled.div`
  display: flex
  padding: 1em;
`;

const Indicator = styled.div`
  display: flex
  flex-direction: column;
  justify-content: center;
  color: ${props => props.theme.textColor.alpha(props.active ? 1 : 0.7).string()};
  label {
    font-size: 1.2rem;
    line-height: 1.6rem;    
  }
  b {
    font-weight: 600;
  }
`;

const Timer = withTick(({ onRestart, disabled, warningEndAt, warningDuration }) => {
  const now = new Date();
  const progress = useFiltrationProgress({ now, warningEndAt, warningDuration });

  return (
    <TimerOuterRim>
      <ProgressRing
        style={{ position: 'absolute' }}
        color={colors.orange.alpha(0.9).string()}
        radius={46}
        stroke={4}
        progress={progress}
      />
      <Counter>{formatTime(now, warningEndAt)}</Counter>
      <RestartButton onClick={onRestart} disabled={disabled} />
    </TimerOuterRim>
  );
});

const AirFiltrationControl = ({ roomId, airFiltrationEndsAt, airFiltrationWarningOn, airFiltrationDurationMin }) => {
  const [restartMutation] = useMutation(restartAirFiltration);
  const [working, setWorking] = useState(false);
  const onRestart = async () => {
    try {
      setWorking(true);
      await restartMutation({ variables: { id: roomId } });
    } catch (e) {
      console.log(e);
    } finally {
      setWorking(false);
    }
  };

  return (
    <Container>
      <Container>
        {!airFiltrationWarningOn ? (
          <StartButton onClick={onRestart} disabled={working} />
        ) : (
          <Timer
            onRestart={onRestart}
            disabled={working}
            warningEndAt={airFiltrationEndsAt}
            warningDuration={defaultTo(airFiltrationDurationMin, 20) * 60}
          />
        )}
      </Container>
      <Indicator active={airFiltrationWarningOn}>
        <label>Warning Indicator</label>
        <label>
          <b>{airFiltrationWarningOn ? 'ON' : 'OFF'}</b>
        </label>
      </Indicator>
    </Container>
  );
};

const AirFiltrationData = ({ roomId }) => (
  <Subscription subscription={roomSubscription} variables={{ roomId: roomId }} skip={!roomId}>
    {({ data }) => <AirFiltrationControl roomId={roomId} {...get(data, 'room', {})} />}
  </Subscription>
);

export default AirFilterTimeModuleOptional(AirFiltrationData);
