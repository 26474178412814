import { useContext } from 'react';
import { SessionContext } from '../state/Session';
import UserRoles from '../types/UserRoles';

interface AccessRights {
  [key: string]: boolean;
}

const superAdminAccessRights: AccessRights = {
  'hospital.*': true,
  'surgeonoffice.*': true,
  'room.*': true,
  'physician.*': true,
  'staffmember.*': true,
  'anesthesiologist.*': true,
  'proceduretype.*': true,
  'config.*': true,
  'vendor.*': true,
  'user.*': true,
  'screen.*': true,
  '*.list': true,
  '*.show': true,
  '*': false,
};

const groupAdminAccessRights: AccessRights = {
  'hospital.*': true,
  'surgeonoffice.*': false,
  'room.*': true,
  'physician.*': true,
  'staffmember.*': true,
  'anesthesiologist.*': true,
  'proceduretype.*': true,
  'config.*': true,
  'user.*': true,
  'screen.*': true,
  '*.list': true,
  '*.show': true,
  '*': true,
};

const monitoringEngineerAccessRights: AccessRights = {
  'hospital.*': true,
  'room.*': true,
  'physician.*': true,
  'staffmember.*': true,
  'anesthesiologist.*': true,
  'config.*': true,
  'user.*': true,
  'screen.*': true,
  '*.list': true,
  '*.show': true,
  '*': false,
};

const nonSuperAdminAccessRights: AccessRights = {
  'hospital.*': false,
  'surgeonoffice.*': false,
  '*': true,
};

function matchAccessRight(accessRight: string, accessRights: AccessRights): boolean {
  const chunks = accessRight.split('.');

  const entries = Object.entries(accessRights);

  for (let i = 0, l = entries.length; i < l; i++) {
    const [entryAccessRight, result] = entries[i];

    const entryChunks = entryAccessRight.split('.');
    const entryChunksLength = entryChunks.length;

    if (entryChunksLength === 1 && entryChunks[0] === '*') {
      return result;
    } else if (
      entryChunksLength === chunks.length &&
      entryChunks.every((entryChunk, j) => entryChunk === '*' || entryChunk === chunks[j])
    ) {
      return result;
    }
  }

  return false;
}

export default function useHasAccessRight() {
  const sessionContext = useContext(SessionContext) as any;
  const roles = (sessionContext.session?.user?.metadata ?? []) as string[];
  const isSuperAdmin = roles.indexOf(UserRoles.SuperAdmin) >= 0;
  const isGroupAdmin = roles.indexOf(UserRoles.GroupAdmin) >= 0;
  const isMonitoringEngineer = roles.indexOf(UserRoles.MonitoringEngineer) >= 0;

  const accessRights = isSuperAdmin
    ? superAdminAccessRights
    : isGroupAdmin
    ? groupAdminAccessRights
    : isMonitoringEngineer
    ? monitoringEngineerAccessRights
    : nonSuperAdminAccessRights;

  return (accessRight: string) => matchAccessRight(accessRight, accessRights);
}
