const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
const charactersLength = characters.length;

const charactersAndDigits = characters + '0123456789';
const charactersAndDigitsLength = charactersAndDigits.length;

const randomCharacter = () => characters.charAt(Math.floor(Math.random() * charactersLength));
const randomCharacterOrDigit = () => charactersAndDigits.charAt(Math.floor(Math.random() * charactersAndDigitsLength));

export default function randomId(length: number) {
  const actualLength = Math.max(1, Math.floor(length));

  let result = randomCharacter();

  for (let i = 1; i < actualLength; i++) {
    result += randomCharacterOrDigit();
  }

  return result;
}
