import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';

/**
 * React Router’s `<Link>` styled like a Button.
 */
export default ({ children, ...rest }) => (
  <Button variant="contained" component={Link} {...rest}>
    {children}
  </Button>
);
