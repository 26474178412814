import styled from 'styled-components';
import colors from '../../../../colors';

const Circle = styled.div`
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  border: 4px solid ${colors.orange.alpha(0.5).string()};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TimerOuterRim = styled(Circle)`
  width: 6rem;
  height: 6rem;
  border-color: ${colors.orange.alpha(0.5).string()};
  flex-direction: column;
  position: relative;
`;

export const Counter = styled.label`
  font-family: monospace;
  color: ${colors.orange.string()};
  font-size: 1.2rem;
  padding-top: 1rem;
  font-weight: 600;
`;
