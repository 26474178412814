import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo, useRef } from 'react';

interface ClickAwayPresence {
  register: () => () => void;
  present: () => boolean;
}

const ClickawayPresenceContext = createContext<ClickAwayPresence>({
  register: () => () => {},
  present: () => false,
});

export function useClickawayPresence(): ClickAwayPresence {
  return useContext(ClickawayPresenceContext);
}

export function ClickAwayPresenceProvider({ children }: PropsWithChildren<unknown>) {
  const counterRef = useRef<number>(0);

  const register = useCallback(() => {
    counterRef.current += 1;
    return () => (counterRef.current -= 1);
  }, []);

  const present = useCallback(() => counterRef.current > 0, []);

  const value = useMemo(() => ({ register, present }), [present, register]);

  return <ClickawayPresenceContext.Provider value={value}>{children}</ClickawayPresenceContext.Provider>;
}
