import React from 'react';
import { Link } from '@material-ui/core';

const getGMapsLink = address => `https://www.google.com/maps/place/${(address || '').split(' ').join('+')}`;

export default ({ children }) => (
  <Link
    onClick={e => {
      e.stopPropagation();
    }}
    href={getGMapsLink(children)}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </Link>
);
