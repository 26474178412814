import styled from 'styled-components';
import LinkButton from '../se/components/LinkButton';

export default styled(LinkButton)`
  opacity: 0.5;
  margin: 0 0.5rem;
  :hover {
    opacity: 1;
  }
`;
