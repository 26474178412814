import { PatientChartButtonsConfig } from '../../../../../types/HospitalConfig';
import { ChartingSession } from './chartingStore';
import { Tab } from '../../../../core/TabRoutes';
import { getUrlPart } from '../utils';
import { QuestionnaireType } from '../../../../../types/Questionnaire';
import { Charting } from '../ChartingPatients';

const getPatientChartTabs = (
  config: PatientChartButtonsConfig | null,
  chartingSession?: ChartingSession | null
): Tab[] | null => {
  const tabsConfig = (config?.buttons || []).find(e => e?.role?.toLowerCase() === chartingSession?.role?.toLowerCase());

  const configuredTabs = (tabsConfig?.buttons || [])?.map(e => ({
    chartType: e.chartType,
    title: e.buttonLabel || '',
    group: e.groupLabel || '',
    path: `/${getUrlPart(e.chartType || QuestionnaireType.PreOpChart)}`,
    component: Charting(config, e.chartType || QuestionnaireType.PreOpChart, chartingSession),
  }));

  return configuredTabs ? configuredTabs : null;
};

export default getPatientChartTabs;
