import { withStyles } from "@material-ui/core"
import Box from "@material-ui/core/Box"

const InputWrapper = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),

    '& label + label': {
      marginBottom: 0,
    }
  },
}))(Box);

export default InputWrapper;
