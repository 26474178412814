import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { setUndoHoldProcedure } from '../../../../graph/patients';

const withUndoHoldProcedure = Component => props =>
  (
    <Mutation mutation={setUndoHoldProcedure}>
      {setUndoHoldProcedure => <Component {...props} setUndoHoldProcedure={setUndoHoldProcedure} />}
    </Mutation>
  );

export default withUndoHoldProcedure;
