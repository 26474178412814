import React, { useMemo, useRef } from 'react';
import CreatableSelect from 'react-select/creatable';
import { withTheme } from 'styled-components';
import { withTheme as withSpicyTheme } from '../../theme';
import InputError from '../errors/InputError';
import selectStyles from './selectStyles';
import { sortString } from '../../../util/sort';
import TextInput from './TextInput';
import get from 'lodash/get';
import find from 'lodash/find';
import { useClickawayPresence } from '../../../contexts/ClickawayPresence';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
  </div>
);

const createOptionObject = object =>
  Object.entries(object).reduce((acc, [value, label]) => [...acc, { value, label }], []);

const deepSelectOptions = object => {
  if (typeof object !== 'object') {
    return undefined;
  } else {
    if (typeof Object.values(object).pop() === 'object') {
      return Object.entries(object).reduce(
        (acc, [label, valueObj]) => [...acc, { options: createOptionObject(valueObj), label }],
        []
      );
    } else {
      return createOptionObject(object);
    }
  }
};

const AnesthesiologistNoteInput = ({
  Error = InputError,
  options = {},
  autoFocus,
  value,
  defaultValue = '',
  onChange,
  name = 'select',
  placeholder = '',
  error,
  hasError,
  getOptionValue,
  menuWidthFollowContent,
  required,
  isClearable,
  isSearchable,
  style,
  sortOptions,
  disabled,
  maxMenuHeight,
  ...rest
}) => {
  const theme = withSpicyTheme(theme => theme)({ ...rest });
  const customStyles = selectStyles({ theme, menuWidthFollowContent, hasError });
  const selectOptions = useMemo(() => deepSelectOptions(options), [options]);

  const clickawayPresenceDeregisterRef = useRef(() => {});
  const clickawayPresence = useClickawayPresence();

  const sortedOptions = sortOptions ? selectOptions.sort((l, r) => sortString(l.label, r.label)) : selectOptions;

  const valueField = useMemo(() => {
    if (!selectOptions) return '';
    const labelToFind = value === null || value === undefined ? defaultValue : value;

    let selectedOption = selectOptions.find(option => option.label === labelToFind);

    if (!selectedOption && selectOptions[0] && selectOptions[0].options) {
      for (let group of selectOptions) {
        selectedOption = group.options.find(option => option.label === labelToFind);
        if (selectedOption) break;
      }
    }

    return selectedOption || { label: labelToFind, value: labelToFind };
  }, [selectOptions, value, defaultValue]);

  if (disabled) {
    return (
      <TextInput
        disabled={true}
        value={get(
          find(sortedOptions, _ => _.value === String(value)),
          'label'
        )}
      />
    );
  }

  return (
    <div style={style}>
      <CreatableSelect
        id={name}
        inputId={name}
        name={name}
        autoComplete="on"
        autoCapitalize="off"
        autoCorrect="off"
        isClearable={isClearable !== false}
        isSearchable={isSearchable !== false}
        backspaceRemovesValue={false}
        autoFocus={autoFocus || false}
        options={sortedOptions}
        getOptionLabel={option => option.label}
        value={valueField}
        onChange={option => {
          const selectedValue = option ? option.label : '';
          onChange(selectedValue);
        }}
        styles={customStyles}
        placeholder={placeholder}
        maxMenuHeight={maxMenuHeight || 250}
        menuPlacement={'auto'}
        menuPortalTarget={document.getElementById('select-menu')}
        disabled={disabled}
        formatGroupLabel={formatGroupLabel}
        onMenuOpen={() => {
          clickawayPresenceDeregisterRef.current = clickawayPresence.register();
        }}
        onMenuClose={() => {
          clickawayPresenceDeregisterRef.current();
          clickawayPresenceDeregisterRef.current = () => {};
        }}
        {...rest}
      />

      <input
        tabIndex={-1}
        value={value || defaultValue}
        onChange={() => {}}
        required={required || (value || defaultValue) === null || (value || defaultValue) === undefined}
        style={{
          opacity: 0,
          width: 0,
          height: 0,
          position: 'absolute',
        }}
      />
      <Error isVisible={hasError} id={name}>
        {hasError ? error : ''}
      </Error>
    </div>
  );
};

AnesthesiologistNoteInput.defaultProps = {
  getOptionValue: option => (option ? option.label : undefined),
  menuWidthFollowContent: false,
};

export default withTheme(AnesthesiologistNoteInput);
