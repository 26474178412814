import React, { useContext } from 'react';
import { format } from 'date-fns';
import { TruncatedText } from '../../../se/components/typography';
import ExpandableText from '../../core/ExpandableText';
import Icon from '../../../se/components/Icon';
import styled from 'styled-components';
import get from 'lodash/get';
import { withProps } from 'recompose';
import { PatientIdFormatter } from '../../../util/PatientNameFormatter';
import { sortDate, sortNumber } from '../../../util/sort';
import { withRouter } from 'react-router';
import LinkButton from '../../../se/components/LinkButton';
import { Scope } from '../../../contexts/ScopeContext';

const Inline = styled.div`
  display: inline-flex;
  padding: 0;
  position: relative;
`;

const Star = withProps(({ full }) => ({
  xl: true,
  children: full ? 'star' : 'star_border',
}))(styled(Icon)`
  user-select: none;
  cursor: default;
  color: ${props => (props.full ? 'orange' : 'white')};
`);

const StarProgress = styled.div`
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  width: ${props => props.percent}%;
`;

export const StarRating = ({ rating }) => (
  <Inline>
    <div style={{ opacity: 0.5 }}>
      {[...Array(5)].map((_, i) => (
        <Star key={i} full={false} />
      ))}
    </div>
    <StarProgress percent={(rating / 5) * 100}>
      {[...Array(5)].map((_, i) => (
        <Star key={i} full={true} />
      ))}
    </StarProgress>
  </Inline>
);

export default [
  {
    title: 'Patient ID',
    key: 'patientId',
    lens: data => data.patientId,
    Component: withRouter(props => {
      const { hospital } = useContext(Scope);

      const onClick = e => {
        e.stopPropagation();
        props?.history?.push(props?.location.pathname.replace('/feedback', `/consultations/${props.data}`));
      };

      return (
        <LinkButton onClick={onClick}>
          {PatientIdFormatter(hospital.id, hospital.groupId)({ id: props.data })}
        </LinkButton>
      );
    }),
  },
  {
    title: 'Physician Name',
    key: 'physicianName',
    lens: item => get(item, 'physician.name'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  {
    title: 'Rating',
    key: 'rating',
    lens: data => data.rating,
    Component: props => <StarRating rating={props.data} />,
    sortFunction: (l, r) => sortNumber(l.rating, r.rating),
  },
  {
    title: 'Date Submitted',
    key: 'submittedAt',
    lens: data => data.submittedAt,
    sortFunction: (l, r) => sortDate(l.submittedAt, r.submittedAt),
    Component: props => <TruncatedText>{format(props.data, 'MM/DD/YYYY')}</TruncatedText>,
  },
  {
    title: 'Suggestion',
    key: 'suggestion',
    lens: data => data.suggestion,
    style: { overflow: 'hidden', maxWidth: '30em' },
    Component: props => <ExpandableText text={props.data} />,
  },
];
