import React from 'react';
import * as XLSX from 'xlsx';
import Button from '@material-ui/core/Button';
import { SaveRounded } from '@material-ui/icons';

function ExcelGenerator({ excelFileName, data }: { excelFileName: string; data: any[] }) {
  const generateExcel = () => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Add a worksheet
    const ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Set column width to fit content
    const colWidths = data[0].map(col => ({
      wch: col.length + 2,
    }));
    ws['!cols'] = colWidths;

    // Convert the workbook to a binary string
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    // Create a Blob from the binary string
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

    // Create a download link and trigger the download
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = `${excelFileName}.xlsx`;
    document.body.appendChild(a);
    a.click();

    // Clean up
    document.body.removeChild(a);
  };

  // Utility function to convert s to array buffer
  const s2ab = s => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  return (
    <Button color="primary" startIcon={<SaveRounded />} onClick={generateExcel}>
      Export as Excel file
    </Button>
  );
}

export default ExcelGenerator;
