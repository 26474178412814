import findLast from 'lodash/findLast';
import sortBy from 'lodash/sortBy';
import { StaffShiftLog, StaffShiftState } from '../types/StaffShift';
import find from 'lodash/find';

export const getLastShiftLog = (events: Array<StaffShiftLog>, state: StaffShiftState): StaffShiftLog | undefined => {
  const sorted = sortBy(events, 'at');
  return findLast(sorted, (l: StaffShiftLog) => l.state === state);
};

export const getFirstShiftLog = (events: Array<StaffShiftLog>, state: StaffShiftState): StaffShiftLog | undefined => {
  const sorted = sortBy(events, 'at');
  return find(sorted, (l: StaffShiftLog) => l.state === state);
};

export default {
  getLastShiftLog,
};
