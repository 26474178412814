import React, { ReactComponentElement } from 'react';
import { Box, ButtonBase, Typography, useTheme, withStyles } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import Chip from '@material-ui/core/Chip';
import { QuestionnaireStatus } from '../../../../../types/Questionnaire';

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.text.primary,
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(6),
    maxHeight: 340,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 16,
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    '&:hover': {
      // backgroundColor: purple[700],
    },
  },
}))(ButtonBase);

const SectionButton = ({
  text,
  Icon,
  status,
  onClick,
  ...rest
}: {
  text: string;
  Icon?: ReactComponentElement<any>;
  status?: QuestionnaireStatus;
  onClick: () => void;
}) => {
  const theme = useTheme();
  const isCompleted = status === QuestionnaireStatus.Completed;
  return (
    <ColorButton
      onClick={onClick}
      {...rest}
      style={{
        backgroundColor: isCompleted ? theme.palette.secondary.light : theme.palette.primary.main,
        color: isCompleted ? theme.palette.text.primary : theme.palette.secondary.light,
      }}
    >
      <Box position="relative" display="flex" style={{ opacity: isCompleted ? 0.5 : undefined }}>
        <div>{Icon}</div>
      </Box>
      <Typography variant="h4" align="left">
        {text}
      </Typography>
      {(isCompleted || status === QuestionnaireStatus.PartiallyCompleted) && (
        <Chip
          label={isCompleted ? 'Completed' : 'Partially Completed'}
          icon={
            isCompleted ? (
              <Box display="flex" style={{ color: theme.palette.secondary.light }}>
                <Check fontSize="small" color="inherit" />
              </Box>
            ) : undefined
          }
          style={{
            backgroundColor: isCompleted ? theme.palette.primary.main : theme.palette.secondary.light,
            color: isCompleted ? theme.palette.secondary.light : theme.palette.text.primary,
          }}
        />
      )}
    </ColorButton>
  );
};

export default SectionButton;
