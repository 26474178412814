import { Box, Chip, createStyles } from '@material-ui/core';
import React from 'react';
import { FiberManualRecord } from '@material-ui/icons';
import { OpenPositionDescription } from '../../../../../types/StaffShift';
import { PrimarySpecialtyColors } from '../../../../../types/StaffMember';
import StaffShiftTime from './StaffShiftTime';
import classNames from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { stubStaffShiftStore, StaffShiftStoreContext } from './StaffShift';

interface AddStaffSlotProps {
  id?: number;
  from?: string;
  to?: string;
  openPosition?: OpenPositionDescription;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onDelete?: (event: React.MouseEvent<HTMLElement>) => Promise<void>;
  interactive?: boolean;
}

const AddStaffSlot: React.FC<AddStaffSlotProps> = ({ id, onClick, from, to, openPosition, onDelete, interactive }) => {
  const classes = useStyles();

  return (
    <Chip
      variant="outlined"
      size="small"
      color="primary"
      onClick={onClick}
      onDelete={onDelete}
      icon={
        <FiberManualRecord
          style={{
            color: openPosition?.primarySpecialty
              ? PrimarySpecialtyColors[openPosition?.primarySpecialty]
              : 'rgba(127, 127, 127, 0.2)',
          }}
        />
      }
      label={
        <Box>
          <span>Open</span>
          <StaffShiftStoreContext.Provider value={stubStaffShiftStore}>
            <StaffShiftTime from={from} to={to} assignedShiftId={id} hideClock={true} editableStaff={false} />
          </StaffShiftStoreContext.Provider>
        </Box>
      }
      className={classNames(classes.root, {
        [classes.interactive]: interactive !== undefined ? interactive : Boolean(onClick),
      })}
    />
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      order: 100,
      cursor: 'inherit',
    },
    interactive: {
      cursor: 'pointer',
    },
  })
);

export default AddStaffSlot;
