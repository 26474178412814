import { graphql } from '@apollo/client/react/hoc';
import React, { Fragment } from 'react';
import { compose, mapProps } from 'recompose';
import MultiSelectInput from '../../../se/components/inputs/MultiSelectInput';
import { groupAdminUsers } from '../../../graph/users';

const toSelectOptions = data => (data ? data.map(({ id, name }) => ({ value: id, label: name })) : data);
const fromSelectOptions = data => (data ? data.map(({ value, label }) => ({ id: value, name: label })) : data);

const groupAdminOptions = ({ data: { loading, groupAdmin }, ...rest }) => ({
  options: toSelectOptions(groupAdmin),
  loading,
  ...rest,
});

const GroupAdminAssignment = ({ value, options, onChange, loading, disabled, hasError, ...rest }) => {
  const handleChange = value => onChange(fromSelectOptions(value));

  return (
    <Fragment>
      <MultiSelectInput
        name={`monitors`}
        options={options}
        onChange={handleChange}
        value={toSelectOptions(value)}
        loading={loading}
        disabled={disabled}
      />
    </Fragment>
  );
};

export default compose(graphql(groupAdminUsers), mapProps(groupAdminOptions))(GroupAdminAssignment);
