import React, { Fragment } from 'react';
import { HospitalStats } from '../feedback/HospitalStats';
import { get } from 'lodash';
import GraphLayout from '../../pages/analytics/GraphLayoutNoDiff';
import styled from 'styled-components';
import responsive from '../../../se/utilities/responsive';
import { useSession } from '../../../state/Session';
import { unpackSessionObject } from '../../pages/unpackSessionObject';
import { nurseColor } from '../../pages/analytics/Distribution';

const Stats = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr;
  grid-gap: 0.5rem;
  margin-bottom: 2em;
  ${responsive.md.andSmaller`
      grid-template-columns: 1fr;
  `};
`;

export const Analytics = ({ data, pathRatingStats, pathFeedbackStats }) => {
  const session = useSession();
  const { isNurse } = unpackSessionObject(session);
  return (
    <Stats>
      <Fragment>
        <HospitalStats {...get(data, pathRatingStats, {})} color={isNurse && nurseColor} />
        {get(data, pathFeedbackStats, []).map((stat, i) => (
          <GraphLayout
            color={isNurse && nurseColor}
            key={`${stat.id}-${i}`}
            {...stat}
            ratio={0.4}
            hideValue={stat.id === 'ratingOverTime'}
            tooltipLabel={stat.id === 'ratingGrouped' ? 'Number of Patients' : undefined}
            isDecimal={true}
          />
        ))}
      </Fragment>
    </Stats>
  );
};
