import { useEffect, useState } from 'react';
import Dates from '../types/Dates';
import { useClickawayPresence } from '../../../../contexts/ClickawayPresence';
import { fallthroughUntil } from '../CalendarDate';

export const useDeselect = () => {
  const [selectedDates, setSelectedDates] = useState<Dates>(Dates.empty);
  const [highlightedDates, setHighlightedDates] = useState<Dates>(Dates.empty);

  const clickawayPresence = useClickawayPresence();

  useEffect(() => {
    const deselectDate = () => {
      setSelectedDates(Dates.empty);
      setHighlightedDates(Dates.empty);
    };

    const handlePointerDown = (e: PointerEvent) => {
      if (clickawayPresence.present()) {
        return;
      }

      const isOverInteractive = fallthroughUntil(e.target as HTMLElement, element => {
        const isObviouslyInteractive = ['BUTTON', 'INPUT', 'SELECT'].indexOf(element.tagName) >= 0;
        const isDate = element.dataset.date;
        const hasSelectId = element.id === 'select';
        const hasSuppressionClass = element.classList.contains('suppress-deselection');

        return isObviouslyInteractive || isDate || hasSelectId || hasSuppressionClass;
      });

      if (isOverInteractive) {
        return;
      }

      let prevX = e.clientX;
      let prevY = e.clientY;

      let distanceTravelled = 0;

      const handlePointerMove = (e: PointerEvent) => {
        if (clickawayPresence.present()) {
          document.body.removeEventListener('pointermove', handlePointerMove);
          document.body.removeEventListener('pointerup', handlePointerUp);
          return;
        }

        distanceTravelled += Math.sqrt(Math.pow(e.clientX - prevX, 2) + Math.pow(e.clientY - prevY, 2));

        if (distanceTravelled > 2) {
          document.body.removeEventListener('pointermove', handlePointerMove);
          document.body.removeEventListener('pointerup', handlePointerUp);
          return;
        }

        prevX = e.clientX;
        prevY = e.clientY;
      };

      const handlePointerUp = () => {
        if (!clickawayPresence.present()) {
          deselectDate();
        }

        document.body.removeEventListener('pointermove', handlePointerMove);
        document.body.removeEventListener('pointerup', handlePointerUp);
      };

      document.body.addEventListener('pointermove', handlePointerMove);
      document.body.addEventListener('pointerup', handlePointerUp);
    };

    document.body.addEventListener('pointerdown', handlePointerDown);

    return () => document.body.removeEventListener('pointerdown', handlePointerDown);
  }, [clickawayPresence]);

  return {
    selectedDates,
    setSelectedDates,
    highlightedDates,
    setHighlightedDates,
  };
};
