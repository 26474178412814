import React from 'react';

import type IllustrationProps from '../IllustrationProps';

export default ({ width, height, variant }: IllustrationProps) => {
  const color = variant === 'light' ? '#1976d2' : '#00a7f7';
  return (
    <svg viewBox="0 0 60 60" width={width} height={height}>
      <polygon
        fill={color}
        points="35.88 34.88 31.47 34.88 31.47 30.45 29.92 30.45 29.92 34.88 25.52 34.88 25.52 36.43 29.92 36.43 29.92 40.86 31.47 40.86 31.47 36.43 35.88 36.43 35.88 34.88"
      />
      <path fill={color} d="M44.46,44.45H17V27H44.46Zm-26.18-1.3H43.17V28.27H18.28Z" />
      <path
        fill={color}
        d="M53.37,54.6a5.24,5.24,0,0,0-4.63-5.21V27a15.63,15.63,0,0,0-5.51-11.93l-1.74-1.49h.9a4,4,0,0,0,2.86-1.17,4,4,0,0,0,1.21-2.85l0-5.28A4.23,4.23,0,0,0,42.33,0H19.18a4.85,4.85,0,0,0-4.83,4.87h0v4.4a4,4,0,0,0,4,4h1.5l-3.55,3a11.67,11.67,0,0,0-4.2,9v24.6H7.87a4.8,4.8,0,0,0-4.78,4.81h0v.5A4.8,4.8,0,0,0,7.87,60h10a4.69,4.69,0,0,0,.84-.08h30v-.06A5.26,5.26,0,0,0,53.37,54.6Zm-9.17,0a4,4,0,0,1,3.25-3.89v7.78A4,4,0,0,1,44.2,54.6Zm-39.82.55v-.5a3.5,3.5,0,0,1,3.48-3.51h4.26v7.52H7.87A3.51,3.51,0,0,1,4.38,55.15ZM18.6,58.58H13.4V51.14h4.47a3.5,3.5,0,0,1,3.48,3.51v.5A3.5,3.5,0,0,1,18.6,58.58Zm4-3.43v-.5a4.8,4.8,0,0,0-4.77-4.82H13.4V25.23a10.43,10.43,0,0,1,3.74-8L23.44,12H18.37a2.72,2.72,0,0,1-2.71-2.73V4.87A3.54,3.54,0,0,1,19.18,1.3H42.26a2.93,2.93,0,0,1,2.93,2.93v0l0,5.28a2.81,2.81,0,0,1-.82,2,2.73,2.73,0,0,1-1.93.79H37.94l4.46,3.8A14.32,14.32,0,0,1,47.45,27V49.43a5.25,5.25,0,0,0-2.71,9.18H21.22A4.78,4.78,0,0,0,22.65,55.15ZM48.74,58.5V50.7a3.95,3.95,0,0,1,0,7.8Z"
      />
      <path
        opacity={0.28}
        fill={color}
        d="M30.25,11.83,30.18,13s-5.53,6.89-6.78,10.72-.75,6.19-.83,11,.1,20,.1,20l.26,4.59v.34l-9.88,0,0-36.15a13.19,13.19,0,0,1,4.2-6.64c3.62-3.32,5.64-4.85,5.64-4.85l-7.32.25"
      />
    </svg>
  );
};
