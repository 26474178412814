import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { list, patientFile } from '../../../../graph/surgeon/forms';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import { formSaveTypeLabels, formTypeLabels } from '../../procedures/enums';
import DialogActions from '@material-ui/core/DialogActions';
import Spinner from '../../../../se/components/Spinner';
import { FormType } from '../../../../types/Form';
import printJS from 'print-js';
import { useSession } from '../../../../state/Session';
import { unpackSessionObject } from '../../../pages/unpackSessionObject';
import Tooltip from '../../../Tooltip';

const PatientFileGenerator = ({ procedureId, formTypes, chartingTablet }) => {
  const [busy, setBusy] = useState(false);
  const [open, setOpen] = useState(false);

  const [state, setState] = useState({});
  const { data: formQuery } = useQuery(list);
  const reportForms = useMemo(
    () => (formQuery?.forms || []).filter(e => formTypes.includes(e.type)),
    [formQuery, formTypes]
  ); // Filter based on formTypes

  const sections = useMemo(() => groupBy(reportForms, _ => _.type), [reportForms]);

  useEffect(() => {
    setState(reportForms.reduce((acc, curr) => ({ ...acc, [curr.id]: true }), {}));
  }, [reportForms]);

  const anyChecked = useMemo(() => Object.values(state).includes(true), [state]);

  const client = useApolloClient();

  const session = useSession();
  const { isSuperAdmin } = unpackSessionObject(session);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const printPdf = pdfUrl => {
    setTimeout(function () {
      printJS(pdfUrl);
    }, 1000);
  };

  const handleGenerate = async () => {
    if (anyChecked) {
      setBusy(true);
      try {
        const formIds = Object.entries(state)
          .filter(([_, value]) => !!value)
          .map(([key, _]) => parseInt(key, 10))
          .sort((a, b) => reportForms.find(x => x.id === a).order - reportForms.find(x => x.id === b).order);

        const buildResult = await client.query({
          query: patientFile,
          variables: { procedureId, ids: formIds, mdSignature: false },
          fetchPolicy: 'network-only',
        });

        if (buildResult.data) {
          // const pdfData = await fetch(buildResult.data.patientFile);
          // const pdfBlob = await pdfData.blob();
          // const pdfUrl = URL.createObjectURL(pdfBlob);

          // printPdf(pdfUrl);

          const link = document.createElement('a');
          link.download = 'PatientFile' + procedureId + Date.now() + '.pdf';
          link.target = '_blank';
          link.href = buildResult.data.patientFile;
          link.text = 'PDF download';

          link.dispatchEvent(new MouseEvent('click'));
          URL.revokeObjectURL(link.href);
        }
      } finally {
        setBusy(false);
      }
      setOpen(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleCheckboxChange = (id, checked) => {
    setState({ ...state, [id]: checked });
  };

  const selectAll = () => {
    setState(Object.keys(state).reduce((acc, curr) => ({ ...acc, [curr]: true }), {}));
  };

  const deselectAll = () => {
    setState(Object.keys(state).reduce((acc, curr) => ({ ...acc, [curr]: false }), {}));
  };

  const renderItem = item => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={state[item.id]}
            onChange={e => handleCheckboxChange(item.id, e.target.checked)}
            name={item.name}
          />
        }
        label={<>{item.name}</>}
      />
    );
  };

  return (
    <Fragment>
      {reportForms.length > 0 && (
        <Box display="flex" flexDirection="row" justifyContent="flex-end" mt={2}>
          <Hidden smDown>
            <Tooltip content={isSuperAdmin ? 'You don’t have sufficient permissions to generate this document.' : null}>
              <Button width={200} color="primary" onClick={handleClickOpen} disabled={isSuperAdmin}>
                {chartingTablet ? 'Print Documents' : 'Generate Medical File'}
              </Button>
            </Tooltip>
          </Hidden>
        </Box>
      )}
      <Dialog fullWidth={true} open={open} onClose={handleCancel} aria-labelledby="form-dialog-title">
        <DialogTitle> {chartingTablet ? 'Print Documents' : 'Generate Medical File'} </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {chartingTablet ? 'Please select sections to print.' : 'Please select sections for patient file.'}
          </DialogContentText>
          <Box mt={2} display="flex" flexDirection="row" width="100%">
            <FormGroup style={{ width: '100%' }}>
              <Grid container>
                <Grid item xs={12}>
                  <Box mb={2} display="flex" flexDirection="row">
                    <Button color="primary" size="small" onClick={selectAll}>
                      Select all
                    </Button>
                    <Button color="primary" size="small" onClick={deselectAll} style={{ marginLeft: '1rem' }}>
                      Deselect all
                    </Button>
                  </Box>
                </Grid>
                {Object.entries(formTypeLabels).map(
                  ([formType, label]) =>
                    sections[formType] &&
                    sections[formType].length > 0 && (
                      <Grid item md={4} key={formType}>
                        <Typography variant="subtitle1" gutterBottom>
                          {label}
                        </Typography>
                        <Box display="flex" flexDirection="column" mb={2}>
                          {(sections[formType] ?? []).map(renderItem)}
                        </Box>
                      </Grid>
                    )
                )}
              </Grid>
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button disabled={busy || !anyChecked} onClick={handleGenerate} color="primary">
            {chartingTablet ? 'Print' : 'Generate & Download'}
          </Button>

          {busy && (
            <Spinner
              style={{
                verticalAlign: 'middle',
                marginLeft: '0.5rem',
              }}
            />
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default PatientFileGenerator;
