import React, { FC, useRef, useState } from 'react';
import { Box, Chip, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StaffAvailability } from '../../../types/StaffAvailability';
import ImmutableSet from './types/ImmutableSet';
import TimeRangeForm from './TimeRangeForm';
import formatTimeRange from './util/formatTimeRange';
import ActionManipulation from './types/ActionManipulation';
import { StaffAvailabilityConcreteGroup } from './util/groupStaffAvailability';
import ActionButton from './ActionButton';

export const useStyles = makeStyles((theme: Theme) => ({
  timeRanges: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  timeRange: {
    display: 'flex',
    whiteSpace: 'nowrap',
    height: '30.75px',
    alignItems: 'center',
  },
  removeButton: {
    marginLeft: '-0.25rem',
    marginRight: '-0.75rem',
    marginTop: '-2rem',
    marginBottom: '-2rem',
  },
}));

interface ConcreteStaffAvailabilityEditorProps extends StaffAvailabilityConcreteGroup {
  title: string;
  createStaffAvailabilities: (staffAvailability: Pick<StaffAvailability, 'from' | 'to'>) => Promise<void>;
  deleteStaffAvailabilities: (ids: ImmutableSet<StaffAvailability['id']>) => Promise<void>;
  staffId?: number;
  available: boolean;
  highlightColor?: string;
}

const StaffAvailabilityConcreteGroupEditor: FC<ConcreteStaffAvailabilityEditorProps> = ({
  timeRanges,
  ids,
  title,
  createStaffAvailabilities,
  deleteStaffAvailabilities,
  staffId,
  available,
  highlightColor,
}) => {
  const classes = useStyles();

  const isEmpty = timeRanges.length === 0;
  const isAllDay = timeRanges.length === 1 && !timeRanges[0].from && !timeRanges[0].to;
  const isLimited = !isEmpty && !isAllDay;

  const [isLimitedBeingEdited, setIsLimitedBeingEdited] = useState(false);

  const actionManipulation = useRef(new ActionManipulation());

  return (
    <>
      <Typography variant="body2" gutterBottom>
        {title}
      </Typography>
      <Box display="flex" flexDirection="column">
        <Box display="flex" mb={1}>
          <ActionButton
            size="small"
            variant="contained"
            style={{
              backgroundColor: isAllDay ? highlightColor : 'transparent',
            }}
            onClick={async () => {
              if (isAllDay) {
                await deleteStaffAvailabilities(ids);
              } else {
                setIsLimitedBeingEdited(false);

                if (isLimited) {
                  await deleteStaffAvailabilities(ids);
                }
                await createStaffAvailabilities({});
              }
            }}
            loadingSize={15}
          >
            All-day
          </ActionButton>
          <ActionButton
            size="small"
            variant="contained"
            style={{
              backgroundColor: isLimited || isLimitedBeingEdited ? highlightColor : 'transparent',
            }}
            onClick={async () => {
              if (isLimited) {
                setIsLimitedBeingEdited(false);
                await deleteStaffAvailabilities(ids);
              } else {
                if (isAllDay) {
                  await deleteStaffAvailabilities(ids);
                }
                setIsLimitedBeingEdited(prev => !prev);
              }
            }}
            onMouseDown={actionManipulation.current.preventOnClick()}
            loadingSize={15}
          >
            Limited
          </ActionButton>
        </Box>
        {isLimited && (
          <div className={classes.timeRanges}>
            {timeRanges.map(({ from, to, ids }, i) => (
              <div key={i} className={classes.timeRange}>
                <Chip
                  label={formatTimeRange(from, to)}
                  style={{ backgroundColor: highlightColor }}
                  size={'small'}
                  onDelete={async () => {
                    await deleteStaffAvailabilities(ids);
                  }}
                />
              </div>
            ))}
          </div>
        )}
        {(isLimited || isLimitedBeingEdited) && (
          <TimeRangeForm
            staffId={staffId}
            available={available}
            onCancel={() => setIsLimitedBeingEdited(false)}
            onSubmit={async timeRange => {
              if (timeRanges.some(tr => tr.from === timeRange.from && tr.to === timeRange.to)) {
                return;
              }
              try {
                await createStaffAvailabilities(timeRange);
              } finally {
                setIsLimitedBeingEdited(false);
              }
            }}
            actionManipulation={actionManipulation}
          />
        )}
      </Box>
    </>
  );
};

export default StaffAvailabilityConcreteGroupEditor;
