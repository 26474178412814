import { gql } from '@apollo/client';

export const fragments = {
  holiday: gql`
    fragment HolidayData on HolidayCalendar {
      id
      date
      description
    }
  `,
};

export const listHoliday = gql`
  query listHolidayCalendar($from: Date!, $to: Date!) {
    listHolidayCalendar(from: $from, to: $to) {
      ...HolidayData
    }
  }
  ${fragments.holiday}
`;

export const createHoliday = gql`
  mutation createHoliday($dates: [Date!]!, $description: String!) {
    createHoliday(dates: $dates, description: $description) {
      ...HolidayData
    }
  }
  ${fragments.holiday}
`;

export const updateHoliday = gql`
  mutation updateHoliday($eventIds: [Long!]!, $description: String!) {
    updateHoliday(eventIds: $eventIds, description: $description)
  }
`;

export const deleteHoliday = gql`
  mutation deleteHoliday($eventIds: [Long!]!) {
    deleteHoliday(eventIds: $eventIds)
  }
`;
