import React, { FC, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { useStyles } from '../ScheduleProcedureForm';
import Chip from '@material-ui/core/Chip';
import { EquipmentDetails, FormParams } from '../types/types';
import { sort } from '../utils/functions';
import { list as equipmentListQuery, create as createEquipment } from '../../../../../../graph/equipment';
import { AutocompleteInputSetter } from '../components/AutocompleteInputSetter';
import { useMutation } from '@apollo/client';

//only placeholder for now
const equipmentListArray = [
  'Scissors',
  'Forceps',
  'Organ and Tissue Grasping Forceps',
  'Haemostatic Forceps',
  'Bulldog Clamps',
  'Vessel Clips',
  'Approximators',
  'Dissecting- and Ligature Forceps',
  'Surgical Needles',
  'Suture Instruments',
  'Ligature Needles',
  'Wound Retractors',
  'Self-Retaining Retractors',
  'Abdominal Retractors',
  'Abdominal Surgery Intestinal',
  'Gall Bladder',
];

const EquipmentForm: FC<FormParams<EquipmentDetails>> = ({ value, setValue }) => {
  const classes = useStyles();
  const [equipmentList, setEquipmentList] = useState<string[]>(sort(equipmentListArray));

  const [create] = useMutation(createEquipment, {
    refetchQueries: [{ query: equipmentListQuery }],
  });

  const createEquipmentOption = async (text: string) => {
    const newEquipment = await create({
      variables: {
        name: text,
      },
    });

    return newEquipment?.data?.creatEquipment;
  };

  const handleDelete = (val: string) => {
    setValue(value?.filter(equip => equip !== val));
    setEquipmentList(sort([...equipmentList, val]));
  };

  const addItem = (item: string) => {
    if (!(value || []).find(e => e === item)) {
      setValue([...(value || []), item]);
    }
  };

  return (
    <>
      <Typography variant="h3" gutterBottom>
        Equipment
      </Typography>

      <form className={classes.form} noValidate autoComplete="off" onSubmit={e => e.preventDefault()}>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <AutocompleteInputSetter
              list={equipmentListQuery}
              onEnterCreateOption={createEquipmentOption}
              label={'Choose Equipment'}
              variant="filled"
              margin="dense"
              fullWidth
              clearAfterSelect={true}
              onChange={event => {
                // @ts-ignore
                const value = event?.name;
                if (!value) return;
                addItem(value);
                setEquipmentList([...equipmentList.filter(equip => equip !== value)]);
              }}
            />
          </Grid>
          <Grid item md={6}>
            {value?.map(equip => (
              <Chip
                label={equip}
                key={equip}
                onDelete={() => handleDelete(equip)}
                style={{ margin: 2 }}
                color="primary"
                variant="outlined"
              />
            ))}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default EquipmentForm;
