import React, { Dispatch, FC, SetStateAction } from 'react';
import styled, { css } from 'styled-components';
import { getDisposition } from './Procedure';
import { Procedure as ProcedureT, Procedure } from '../../../../../types/Procedure';
import { differenceInHours, format, getHours, setHours, setMinutes, setSeconds } from 'date-fns';
import { currentDateInZone } from '../../../../../util/dateTime';
import { useScope } from '../../../../../hooks/useScope';
import { alpha, Box } from '@material-ui/core';
import StaffShifts from '../staff/StaffShifts';
import StaffShiftMenu from '../staff/StaffShiftMenu';
import { StaffShiftRoomContext } from '../staff/StaffShiftContext';
import { StaffSlotRoomContext } from '../staff/StaffSlotContext';
import responsiveStyles from '../../../../../se/utilities/responsive';
import { Room } from '../../../../../types/Room';
import OperatingRoomProcedures from './OperatingRoomProcedures';
import { Time } from '../../../../entities/schedule/util/time';
import { Anesthesiologist } from '../anesthesiologist/PersonnelRoom';
import { fullNameToLastNameRest } from '../shared/procedureUtils';
import isEmpty from 'lodash/fp/isEmpty';
import clsx from 'clsx';
import { useStylesOverview } from '../overview/Overview';
import { makeStyles } from '@material-ui/core/styles';

const BORDER_COLOR = '#0e274c';
const LEFT_OFFSET = '4em';
const LEFT_OFFSET_MOBILE = '2.5em';
const TOP_OFFSET = '0.4rem';
const CURSOR_SIZE = 0.5;

const responsive = responsiveStyles as any;

export const Root = styled.div<{ isKiosk?: boolean; hasMoreThan6ORs: boolean; hasMoreThan6ORsView: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-x: ${props => (props.hasMoreThan6ORs ? 'auto' : 'hidden')};
  overflow-y: auto;
  padding-right: 1em;
  font-family: 'Roboto Condensed', sans-serif;

  ${props =>
    props.hasMoreThan6ORsView
      ? css`
          overflow: auto;
          ${props.isKiosk
            ? css`
                overflow: hidden;
                flex: 1 0 0;
              `
            : css`
                flex: 0 1 auto;
                height: auto;
              `}
        `
      : css`
          ${props.isKiosk
            ? css`
                flex: 1 0 0;
              `
            : css`
                flex: 0 1 auto;
                height: auto;
              `}
        `}
`;

export const Grid = styled.div<{ isKiosk?: boolean; hasMoreThan6ORs: boolean; hasMoreThan6ORsView: boolean }>`
  display: flex;
  flex: 1 0 auto;
  position: relative;
  overflow: hidden;

  ${props =>
    props.hasMoreThan6ORsView
      ? css`
          height: ${props.isKiosk ? 'auto' : '100vh'};
          min-height: ${props.isKiosk ? 0 : '70rem'};
        `
      : css`
          width: ${props.hasMoreThan6ORs ? 'calc(100% + 1.625 * 100%)' : '100%'};
          height: ${props.isKiosk ? 'auto' : '100vh'};
          min-height: ${props.isKiosk ? 0 : '70rem'};
        `}
`;

export const XAxis = styled.div<{}>`
  display: flex;
  flex: 1 0 0;
  margin-left: ${LEFT_OFFSET};
  margin-bottom: 0.5em;
  z-index: 7;
  > :last-child {
    // border-right: 1px solid ${BORDER_COLOR};
  }

  ${responsive.md.andSmaller`
    margin-left: ${LEFT_OFFSET_MOBILE};
  `}
`;

export const YAxis = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 0.8125em;
  pointer-events: none;
`;

export const Tick = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  text-align: start;
  padding-left: 1em;
  color: #abe3ff;
  opacity: 0.5;
  margin: 0 0.25em;

  :after {
    border-top: 1px solid ${BORDER_COLOR};
    opacity: 0.9;
    right: 0;
    content: '';
    left: ${LEFT_OFFSET};
    position: absolute;
    top: ${TOP_OFFSET};

    ${responsive.md.andSmaller`
      left: ${LEFT_OFFSET_MOBILE};
    `}
  }

  ${responsive.md.andSmaller`
    padding-left: 0.5em;
  `}
`;

export const Rooms = styled.div<{ columns?: any; hasMoreThan6ORs: boolean; hasMoreThan6ORsView: boolean }>`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  margin-left: ${LEFT_OFFSET};

  ${responsive.md.andSmaller`
    margin-left: ${LEFT_OFFSET_MOBILE};
  `}

  ${props =>
    !props.hasMoreThan6ORsView &&
    css`
      width: ${props.hasMoreThan6ORs ? 'calc(100% + 1.6 * 100%)' : '100%'};
    `}
`;

export const Cursor = styled.div<{ y?: any }>`
  position: absolute;
  top: calc(${props => props.y}%);
  left: ${LEFT_OFFSET};
  right: 0;
  border-top: 1px solid rgba(232, 29, 29, 0.5);
  z-index: 8;

  ${responsive.md.andSmaller`
    left: ${LEFT_OFFSET_MOBILE};
  `}
`;

export const Dot = styled.div`
  border-radius: 50%;
  width: ${CURSOR_SIZE}em;
  height: ${CURSOR_SIZE}em;
  margin-top: -${CURSOR_SIZE / 2}em;
  margin-left: calc(-${CURSOR_SIZE / 2}em + 1px);
  background: rgba(232, 29, 29, 0.8);
`;

const Timeline: FC<{
  currentOR?: string;
  isKiosk?: boolean;
  isFrontDesk?: boolean;
  editableStaff?: boolean;
  operationRooms: Room[];
  canUseScheduleViewProcedure?: boolean;
  isPowerUser?: boolean;
  date: Date;
  setEditProcedure?: Dispatch<SetStateAction<ProcedureT | null>>;
  showBedNumber?: boolean;
  scheduleStaffList?: any;
  showOverlay: boolean;
  editMode: boolean;
  openProcedureForm?: () => void;
  startTime: Time;
  duration: number | null | undefined;
  setStartTime?: (val: Time) => void;
  setDuration?: (val: number | null | undefined) => void;
  condensView?: boolean;
  unassignedProcedures: Procedure[];
  hasMoreThan6ORsView: boolean;
  showGraphs?: boolean;
}> = ({
  currentOR,
  isFrontDesk,
  isKiosk,
  editableStaff,
  operationRooms,
  canUseScheduleViewProcedure,
  isPowerUser,
  date,
  setEditProcedure,
  showBedNumber,
  scheduleStaffList,
  showOverlay = false,
  editMode,
  openProcedureForm,
  startTime,
  duration,
  setStartTime,
  setDuration,
  condensView,
  unassignedProcedures,
  hasMoreThan6ORsView,
  showGraphs,
}) => {
  const canAddStaff = !isKiosk && editableStaff;
  const dayStart = setSeconds(setMinutes(setHours(date, 6), 0), 0);
  const dayEnd = setSeconds(setMinutes(setHours(date, 19), 0), 0);
  const HOUR_COUNT = differenceInHours(dayEnd, dayStart);
  const START_HOUR = getHours(dayStart);
  const hours = [...new Array(HOUR_COUNT + 1)].map((_, i) => `${i + START_HOUR}:00`);

  const scope = useScope();
  const tz = scope?.hospital.timezone.id;
  const hospitalId = scope?.hospital.id;
  const editableAnesthesiologist = !isKiosk && !isFrontDesk && editableStaff;

  const muiClasses = timelineUseStyles();

  const hasMoreThan6ORs = isKiosk ? false : operationRooms.length + (!isEmpty(unassignedProcedures) ? 1 : 0) > 6;

  const classes = useStylesOverview();

  const timelineContent = (
    <Root isKiosk={isKiosk} hasMoreThan6ORs={hasMoreThan6ORs} hasMoreThan6ORsView={hasMoreThan6ORsView}>
      <Rooms
        columns={operationRooms.length}
        hasMoreThan6ORs={hasMoreThan6ORs}
        hasMoreThan6ORsView={hasMoreThan6ORsView}
      >
        {/*{showGraphs*/}
        {/*  ? [...Array(3).keys()].map((item, i) => (*/}
        {/*      <Box*/}
        {/*        className={muiClasses.columnSpecial}*/}
        {/*        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}*/}
        {/*      >*/}
        {/*        <div>{i === 0 ? 'WR' : i === 1 ? 'PreOp' : 'PACU'}</div>*/}
        {/*      </Box>*/}
        {/*    ))*/}
        {/*  : null}*/}
        {operationRooms.map((room, i) => {
          const { id, name, procedures, staffShifts } = room;
          const anesthesiologistName = fullNameToLastNameRest(
            room?.anesthesiologistShifts?.[0]?.anesthesiologist?.name
          );
          const anesthesiologistShiftRoomId = room?.anesthesiologistShifts?.[0]?.id;

          const proceduresInRoom = procedures?.filter(procedure => procedure?.orId === room?.id);

          return (
            <StaffShiftRoomContext
              key={id || name}
              hospitalId={hospitalId}
              roomId={id}
              date={format(date, 'YYYY-MM-DD')}
              staffShifts={staffShifts || []}
            >
              <StaffSlotRoomContext hospitalId={hospitalId} roomId={id} date={format(date, 'YYYY-MM-DD')}>
                <Box className={classes.staffWrapper}>
                  {/* @ts-ignore */}
                  <Box className={clsx(classes.roomHeader)} onlyRoomName={!(canAddStaff && id !== undefined)}>
                    <StaffSlotRoomContext hospitalId={hospitalId} roomId={id} date={format(date, 'YYYY-MM-DD')}>
                      {canAddStaff && <Box mr={1}>{!!id && <StaffShiftMenu />}</Box>}
                    </StaffSlotRoomContext>
                    {/*Only a placeholder so the layout would be proper*/}
                    <span style={{ flex: 1, textAlign: 'center' }}>{name}</span>
                    <Box mr={1}>
                      {!!id && (
                        <Anesthesiologist
                          date={date}
                          roomId={id}
                          condensView={condensView}
                          editableAnesthesiologist={editableAnesthesiologist}
                          anesthesiologistName={anesthesiologistName}
                          anesthesiologistShiftRoomId={anesthesiologistShiftRoomId}
                          procedureQuery={proceduresInRoom}
                          roomType={room?.type}
                        />
                      )}
                    </Box>
                  </Box>
                  {(staffShifts || []).length !== 0 && (
                    <div style={{ marginRight: 20 }}>
                      <StaffShifts staffShifts={staffShifts || []} editableStaff={editableStaff} isKiosk={isKiosk} />
                    </div>
                  )}
                </Box>
              </StaffSlotRoomContext>
            </StaffShiftRoomContext>
          );
        })}
      </Rooms>
      <Grid isKiosk={isKiosk} hasMoreThan6ORs={hasMoreThan6ORs} hasMoreThan6ORsView={hasMoreThan6ORsView}>
        <YAxis>
          {hours.map(tick => (
            <Box className={muiClasses.tick} key={tick}>
              {tick}
            </Box>
          ))}
        </YAxis>
        <Cursor y={getDisposition(dayStart, currentDateInZone(tz), dayEnd)}>
          <Dot />
        </Cursor>
        <XAxis>
          {/*{showGraphs &&*/}
          {/*  [...Array(3).keys()].map((item, i) => (*/}
          {/*    <Box className={muiClasses.columnSpecial}>*/}
          {/*      {hours.map(tick =>*/}
          {/*        [...Array(4).keys()].map(() => (*/}
          {/*          <Box*/}
          {/*            className={clsx(muiClasses.bar, {*/}
          {/*              [muiClasses.barWr]: i === 0,*/}
          {/*              [muiClasses.barPreOp]: i === 1,*/}
          {/*              [muiClasses.barPacu]: i === 2,*/}
          {/*            })}*/}
          {/*            style={{ width: Math.floor(Math.random() * 100) + 1 + '%' }}*/}
          {/*          ></Box>*/}
          {/*        ))*/}
          {/*      )}*/}
          {/*    </Box>*/}
          {/*  ))}*/}

          {operationRooms.map((room, i) => (
            <OperatingRoomProcedures
              operationRooms={operationRooms}
              key={room?.id || room?.name}
              index={i}
              date={date}
              room={room}
              currentOR={currentOR}
              isKiosk={isKiosk}
              canUseScheduleViewProcedure={canUseScheduleViewProcedure}
              isFrontDesk={isFrontDesk}
              isPowerUser={isPowerUser}
              showOverlay={showOverlay}
              editMode={editMode}
              showBedNumber={showBedNumber}
              setEditProcedure={setEditProcedure}
              dayStart={dayStart}
              dayEnd={dayEnd}
              openProcedureForm={openProcedureForm}
              startTime={startTime}
              duration={duration}
              setStartTime={setStartTime}
              setDuration={setDuration}
            />
          ))}
        </XAxis>
      </Grid>
    </Root>
  );

  if (isKiosk && scheduleStaffList) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: !!scheduleStaffList ? 'row' : 'column',
          width: '100%',
          height: '100%',
        }}
      >
        {timelineContent}
        {scheduleStaffList}
      </div>
    );
  }
  return timelineContent;
};

export const timelineUseStyles = makeStyles(theme => ({
  tick: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'start',
    textAlign: 'start',
    paddingLeft: '1em',
    color: theme.palette.text.primary,
    opacity: 0.5,
    margin: '0 0.25em',

    '&:after': {
      borderTop: `1px solid ${alpha(theme.palette.text.primary, 0.5)}`,
      opacity: 0.9,
      right: 0,
      content: '""',
      left: '4em',
      position: 'absolute',
      top: '0.4rem',
      flex: 1,

      [theme.breakpoints.down('md')]: {
        left: '2.5em',
      },
    },

    [theme.breakpoints.down('md')]: {
      paddingLeft: '0.5em',
    },
  },

  columnSpecial: {
    width: '4em',
    height: '100%',
    backgroundColor: alpha(theme.palette.text.primary, 0.07),
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
    margin: '0 0.1875em',
  },
  bar: {
    backgroundColor: '#d34ec8',
    width: '100%',
    height: '100%',
    borderTopRightRadius: '0.15em',
    borderBottomRightRadius: '0.15em',
  },
  barWr: {
    backgroundColor: '#524ed3',
  },
  barPreOp: {
    backgroundColor: '#4e97d3',
  },
  barPacu: {
    backgroundColor: '#41bcd2',
  },
}));

export default Timeline;
