import { ApolloLink } from '@apollo/client';
import { NextLink, Operation } from '@apollo/client/link/core/types';
import isMutation from './isMutation';
import { OperationsRepository } from './OperationsRepository';
import { Observable } from '@apollo/client/utilities';

export default class DurableMutationsLink extends ApolloLink {
  constructor(repository: OperationsRepository) {
    let started = false;
    super((operation: Operation, forward: NextLink) => {
      if (isMutation(operation)) {
        if (!started) {
          started = true;
          repository.list().then(operations => {
            operations.forEach(o => {
              void forward(o).forEach(() => {
                void repository.remove(o.key);
              });
            });
          });
        }

        const key = repository.add(operation);
        const result = forward(operation);

        key.then(k => {
          void Observable.from(result).forEach(() => {
            repository.remove(k);
          });
        });

        return result;
      }

      return forward(operation);
    });
  }
}
