export type Messages = {
  [key: string]: string;
};

export class LocaleId {
  language: string;
  country?: string;

  constructor(language: string, country?: string) {
    this.language = language.toLowerCase();
    this.country = country && country.toUpperCase();
  }

  toString() {
    return this.language + (this.country ? `-${this.country}` : '');
  }

  equals(other: LocaleId) {
    return this.language === other.language && this.country === other.country;
  }

  static fromString(id: string) {
    const [language, country] = id.split(/[_-]/, 2);
    return new LocaleId(language, country);
  }
}

export interface Locale {
  id: LocaleId;
  name: string;
  nativeName: string;
  messages?: { [key: string]: string };
}

export interface Context {
  availableLocales: Locale[];
  selectedLocale: Locale;
  selectLocale: (id: LocaleId) => void;
  setSpecificLocales: (locales?: Array<Pick<Locale, 'id' | 'messages'>>) => void;
}
