import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import { withTheme as withSpicyTheme } from '../theme';
import { isFunction } from '../utilities/check';
import { getNestedValue } from '../utilities/data/object';

import './spin.css';

const Spinner = ({ style, size, strokeAccessor, ...rest }) => {
  const theme = withSpicyTheme(theme => theme)({ ...rest });
  const strokeValue = strokeAccessor ? getNestedValue(strokeAccessor, theme) || {} : {};
  const stroke = isFunction(strokeValue.string) ? strokeValue.string() : theme.textColor.string();

  return (
    <svg
      style={{
        ...style,
        width: size || theme.textSize.xl,
        height: size || theme.textSize.xl,
        background: 'none',
        animation: '1s linear 0s normal none infinite running spin',
      }}
      viewBox="0 0 100 100"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke={stroke}
        strokeWidth="10"
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
        transform="rotate(335.785 50 50)"
      />
    </svg>
  );
};

export const AbsoluteContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CenteredContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const SpinnerWithDefaultTheme = withTheme(Spinner);

Spinner.propTypes = {
  strokeAccessor: PropTypes.string,
  size: PropTypes.string,
};

export const AbsoluteSpinner = ({ size, strokeAccessor, ...rest }) => (
  <AbsoluteContainer {...rest}>
    <SpinnerWithDefaultTheme size={size} strokeAccessor={strokeAccessor} {...rest} />
  </AbsoluteContainer>
);

export const CenteredSpinner = ({ size, strokeAccessor, ...rest }) => (
  <CenteredContainer {...rest}>
    <SpinnerWithDefaultTheme size={size} strokeAccessor={strokeAccessor} {...rest} />
  </CenteredContainer>
);

export default SpinnerWithDefaultTheme;
