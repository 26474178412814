import React, { Dispatch, FC, Ref, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Container,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { Measurements, Value } from './ChartV1';
import Box from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import MeasurementInput, { MeasurementInputControl } from './MeasurmentInput';
import useMouseDownRef from './useMouseDownRef';
import { MeasurementsV2 } from './ChartV2';

const StyledTable = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: `2px solid ${theme.palette.divider}`,
    },
  })
)(Table);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      borderRight: `2px solid ${theme.palette.divider}`,
      borderBottom: `2px solid ${theme.palette.divider}`,
      '&:last-of-type': {
        borderRight: 'none',
      },
    },
    body: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      fontSize: 14,
      borderRight: `2px solid ${theme.palette.divider}`,
      '&:last-of-type': {
        borderRight: 'none',
      },
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '2px solid transparent',
      '&:last-of-type': {
        borderBottom: `2px solid ${theme.palette.divider}`,
      },
    },
  })
)(TableRow);

const ChartListViewV2: FC<{
  value: Value;
  onChange: Dispatch<SetStateAction<Measurements>>;
  onSignatureChange: (val: Value) => void;
  selectedIndex: number;
  onChangeSelectedIndex: Dispatch<SetStateAction<number>>;
}> = ({ value: { measurments, signature }, onChange, selectedIndex, onChangeSelectedIndex }) => {
  const classes = useStyles();
  const rows = Array.from(Array(measurments?.length).keys()).map(e => e * 2);

  return (
    <Box display="flex" flex={1} flexDirection="row">
      <div className={classes.centered}>
        <Container className={classes.root}>
          <TableContainer>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StyledTableCell width="15%">
                    <Typography variant="h4">Time</Typography>
                  </StyledTableCell>
                  <StyledTableCell width="10%">
                    <Typography variant="h4">O₂ Therapy</Typography>
                  </StyledTableCell>
                  <StyledTableCell width="10%">
                    <Typography variant="h4">O₂ SAT%</Typography>
                  </StyledTableCell>
                  <StyledTableCell width="10%">
                    <Typography variant="h4">BP</Typography>
                  </StyledTableCell>
                  <StyledTableCell width="10%">
                    <Typography variant="h4">Pulse</Typography>
                  </StyledTableCell>
                  <StyledTableCell width="10%">
                    <Typography variant="h4">Resp</Typography>
                  </StyledTableCell>
                  <StyledTableCell width="5%">
                    <Typography variant="h4">Pain Level</Typography>
                  </StyledTableCell>
                  <StyledTableCell width="5%">
                    <Typography variant="h4">CSM</Typography>
                  </StyledTableCell>
                  <StyledTableCell width="10%">
                    <Typography variant="h4">Extremity Pulses</Typography>
                  </StyledTableCell>
                  <StyledTableCell width="15%">
                    <Typography variant="h4">Temp °C</Typography>
                  </StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {orderBy(measurments || [], ['timestamp']).map((measurment, i) => (
                  <Aspect
                    key={measurment.key}
                    measurment={measurment}
                    onChange={onChange}
                    selected={selectedIndex === i}
                    onSelect={() => onChangeSelectedIndex(i)}
                    onDeselect={() => onChangeSelectedIndex(prev => (prev === i ? NaN : prev))}
                  />
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Container>
      </div>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  noScroll: {
    overflowX: 'hidden',
  },
  centered: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    // overflow: 'auto',
  },
  selectedRow: {
    background: theme.palette.secondary.light,
    border: `2px solid ${theme.palette.secondary.main}`,
    '& > *': {
      borderTop: `2px solid ${theme.palette.secondary.main}`,
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      background: theme.palette.secondary.light,
    },
    '& > *:last-of-type': {
      borderRight: `2px solid ${theme.palette.secondary.main}`,
    },
  },
  value: {
    fontFamily: '"Oswald", sans-serif',
  },
}));

export default ChartListViewV2;

const Aspect: FC<{
  measurment: MeasurementsV2;
  onChange: Dispatch<SetStateAction<MeasurementsV2>>;
  selected: boolean;
  onSelect: () => void;
  onDeselect: () => void;
}> = ({ measurment, onChange, selected, onSelect, onDeselect }) => {
  const theme = useTheme();

  const classes = useStyles();
  const originalDate = useMemo(() => new Date(Number(measurment.timestamp)), []);

  const [temp, setTemp] = useState<string>(measurment?.temp ? String(measurment.temp) : '');

  const timeRef = useRef<MeasurementInputControl>();
  const o2therapyRef = useRef<MeasurementInputControl>();
  const o2SatRef = useRef<MeasurementInputControl>();
  const bpRef = useRef<MeasurementInputControl>();
  const bpmRef = useRef<MeasurementInputControl>();
  const respRef = useRef<MeasurementInputControl>();
  const painLevelRef = useRef<MeasurementInputControl>();
  const csmRef = useRef<MeasurementInputControl>();
  const extremityPulsesRef = useRef<MeasurementInputControl>();
  const tempRef = useRef<MeasurementInputControl>();

  useEffect(() => {
    if (selected) {
      if (!measurment.timestampString) timeRef.current?.focus();
      else if (!measurment.o2therapy) o2therapyRef.current?.focus();
      else if (!measurment.o2Sat) o2SatRef.current?.focus();
      else if (!measurment.dia || !measurment.sys) bpRef.current?.focus();
      else if (!measurment.bpm) bpmRef.current?.focus();
      else if (!measurment.resp) respRef.current?.focus();
      else if (!measurment.painLevel) painLevelRef.current?.focus();
      else if (!measurment.csm) csmRef.current?.focus();
      else if (!measurment.extremityPulses) extremityPulsesRef.current?.focus();
      else if (!measurment.temp) tempRef.current?.focus();
    } else {
      timeRef.current?.blur();
      o2therapyRef.current?.blur();
      o2SatRef.current?.blur();
      bpRef.current?.blur();
      bpmRef.current?.blur();
      respRef.current?.blur();
      painLevelRef.current?.blur();
      csmRef.current?.blur();
      extremityPulsesRef.current?.blur();
      tempRef.current?.blur();
    }
  }, [selected]);

  const handleFocus = () => {
    onSelect();
  };

  const mouseDownRef = useMouseDownRef();

  const handleBlur = () => {
    const deselectIfNeeded = () => {
      if (
        document.activeElement !== timeRef.current?.inputRef.current &&
        document.activeElement !== o2therapyRef.current?.inputRef.current &&
        document.activeElement !== o2SatRef.current?.inputRef.current &&
        document.activeElement !== bpRef.current?.inputRef.current &&
        document.activeElement !== bpmRef.current?.inputRef.current &&
        document.activeElement !== respRef.current?.inputRef.current &&
        document.activeElement !== painLevelRef.current?.inputRef.current &&
        document.activeElement !== csmRef.current?.inputRef.current &&
        document.activeElement !== extremityPulsesRef.current?.inputRef.current &&
        document.activeElement !== tempRef.current?.inputRef.current
      )
        onDeselect();
    };

    if (mouseDownRef.current) {
      document.addEventListener('mouseup', deselectIfNeeded, { once: true });
    } else {
      deselectIfNeeded();
    }
  };

  function epochToTime(epochMillis) {
    const date = new Date(epochMillis);

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const milliseconds = date.getMilliseconds();

    const time = hours * 3600 + minutes * 60 + seconds + milliseconds / 100;

    return time;
  }
  return (
    <StyledTableRow className={clsx({ [classes.selectedRow]: selected })}>
      <StyledTableCell className={classes.value}>
        <Typography variant="h1" className={classes.value}>
          <MeasurementInput
            ref={timeRef as Ref<MeasurementInputControl>}
            format="MilitaryTime"
            preFill
            selectOnFocus
            value={measurment.timestampString ?? ''}
            onChange={timestamp => {
              onChange(
                v =>
                  ({
                    ...v,
                    ...(timestamp.length === 5
                      ? {
                          timestamp: epochToTime(
                            new Date(originalDate.toISOString().substring(0, 10) + 'T' + timestamp).getTime()
                          ),
                        }
                      : {}),
                    timestampString: timestamp,
                  } as MeasurementsV2)
              );
            }}
            onComplete={() => o2therapyRef.current!.focus()}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Typography>
      </StyledTableCell>

      <StyledTableCell>
        <Typography variant="h1" className={classes.value}>
          <MeasurementInput
            ref={o2therapyRef as Ref<MeasurementInputControl>}
            format="O2"
            preFill
            selectOnFocus
            value={measurment.o2therapy ? String(measurment.o2therapy) : ''}
            onChange={ans => {
              onChange(
                v =>
                  ({
                    ...v,
                    o2therapy: parseInt(ans, 10),
                  } as MeasurementsV2)
              );
            }}
            onComplete={() => o2SatRef.current!.focus()}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Typography>
      </StyledTableCell>

      <StyledTableCell>
        <Typography variant="h1" className={classes.value}>
          <MeasurementInput
            ref={o2SatRef as Ref<MeasurementInputControl>}
            format="O2"
            preFill
            selectOnFocus
            value={measurment.o2Sat ? String(measurment.o2Sat) : ''}
            onChange={ans => {
              onChange(
                v =>
                  ({
                    ...v,
                    o2Sat: parseInt(ans, 10),
                  } as MeasurementsV2)
              );
            }}
            onComplete={() => bpRef.current!.focus()}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Typography>
      </StyledTableCell>

      <StyledTableCell>
        <Typography variant="h1" className={classes.value}>
          <MeasurementInput
            ref={bpRef as Ref<MeasurementInputControl>}
            format="BloodPressure"
            preFill
            selectOnFocus
            value={measurment.sys || measurment.dia ? `${measurment.sys ?? ''}/${measurment.dia ?? ''}` : ''}
            onChange={bp => {
              const [sys, dia] = bp.split('/').map(e => parseInt(e, 10));

              onChange(
                v =>
                  ({
                    ...v,
                    sys,
                    dia,
                  } as MeasurementsV2)
              );
            }}
            onComplete={() => bpmRef.current!.focus()}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Typography>
      </StyledTableCell>

      <StyledTableCell>
        <Typography variant="h1" className={classes.value}>
          <MeasurementInput
            ref={bpmRef as Ref<MeasurementInputControl>}
            format="HeartRate"
            preFill
            selectOnFocus
            value={measurment.bpm ? String(measurment.bpm) : ''}
            onChange={bpm => {
              onChange(
                v =>
                  ({
                    ...v,
                    bpm: parseInt(bpm, 10),
                  } as MeasurementsV2)
              );
            }}
            onComplete={() => respRef.current!.focus()}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Typography>
      </StyledTableCell>

      <StyledTableCell>
        <Typography variant="h1" className={classes.value}>
          <MeasurementInput
            ref={respRef as Ref<MeasurementInputControl>}
            format="RespirationRate"
            preFill
            selectOnFocus
            value={measurment.resp ? String(measurment.resp) : ''}
            onChange={bpm => {
              onChange(
                v =>
                  ({
                    ...v,
                    resp: parseInt(bpm, 10),
                  } as MeasurementsV2)
              );
            }}
            onComplete={() => painLevelRef.current!.focus()}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Typography>
      </StyledTableCell>

      <StyledTableCell>
        <Typography variant="h1" className={classes.value}>
          <MeasurementInput
            ref={painLevelRef as Ref<MeasurementInputControl>}
            format="O2"
            preFill
            selectOnFocus
            value={measurment.painLevel ? String(measurment.painLevel) : ''}
            onChange={ans => {
              onChange(
                v =>
                  ({
                    ...v,
                    painLevel: parseInt(ans, 10),
                  } as MeasurementsV2)
              );
            }}
            onComplete={() => csmRef.current!.focus()}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Typography>
      </StyledTableCell>

      <StyledTableCell>
        <Typography variant="h1" className={classes.value}>
          <MeasurementInput
            ref={csmRef as Ref<MeasurementInputControl>}
            format="O2"
            preFill
            selectOnFocus
            value={measurment.csm ? String(measurment.csm) : ''}
            onChange={ans => {
              onChange(
                v =>
                  ({
                    ...v,
                    csm: parseInt(ans, 10),
                  } as MeasurementsV2)
              );
            }}
            onComplete={() => extremityPulsesRef.current!.focus()}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Typography>
      </StyledTableCell>

      <StyledTableCell>
        <Typography variant="h1" className={classes.value}>
          <MeasurementInput
            ref={extremityPulsesRef as Ref<MeasurementInputControl>}
            format="O2"
            preFill
            selectOnFocus
            value={measurment.extremityPulses ? String(measurment.extremityPulses) : ''}
            onChange={ans => {
              onChange(
                v =>
                  ({
                    ...v,
                    extremityPulses: parseInt(ans, 10),
                  } as MeasurementsV2)
              );
            }}
            onComplete={() => tempRef.current!.focus()}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Typography>
      </StyledTableCell>

      <StyledTableCell>
        <Typography variant="h1" className={classes.value}>
          <MeasurementInput
            ref={tempRef as Ref<MeasurementInputControl>}
            format="TEMP"
            preFill
            selectOnFocus
            value={temp}
            onChange={temp => {
              setTemp(temp);
              onChange(
                v =>
                  ({
                    ...v,
                    temp: parseFloat(temp),
                  } as Measurements)
              );
            }}
            onComplete={() => tempRef.current!.blur()}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Typography>
      </StyledTableCell>
    </StyledTableRow>
  );
};
