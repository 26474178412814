import React, { Component } from 'react';
import { isString } from '../utilities/check';

const mapOfKeyCodestoChars = {
  83: 's',
  27: 'esc',
  68: 'd',
  17: 'ctrl',
};

export default (keyEventName, ...keyCodes) =>
  WrappedComponent => {
    class WithKeyEvent extends Component {
      handleKeydown = handler => event => {
        if (!this.fired && keyCodes.find(this.atLeastOneMatch(event))) {
          this.fired = true;
          return handler();
        }
      };

      isKey = event => item => isString(item) && mapOfKeyCodestoChars[event.keyCode] === item;

      // This unit will help you to find combination
      // of keys like cmd+s
      isKeyCombination = event => item => {
        if (isString(item)) {
          const [prefix, char] = item.split('+');
          if (event[`${prefix}Key`] && mapOfKeyCodestoChars[event.keyCode] === char) {
            return true;
          }
        }

        return false;
      };

      atLeastOneMatch = event => item => this.isKeyCombination(event)(item) || this.isKey(event)(item);

      handleKeyup = () => {
        this.fired = false;
      };

      keydown = {
        listen: handler => {
          window.addEventListener('keydown', this.handleKeydown(handler));
          window.addEventListener('keyup', this.handleKeyup);
        },
        unlisten: handler => {
          window.removeEventListener('keydown', this.handleKeydown(handler));
          window.removeEventListener('keyup', this.handleKeyup);
        },
      };

      render() {
        const props = {
          ...this.props,
          [keyEventName]: this.keydown,
        };

        return <WrappedComponent {...props} />;
      }
    }

    return WithKeyEvent;
  };
