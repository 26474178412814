import emptyReducer from './emptyReducer';

const toSelectedCheckBox = (checkboxValue: string) =>
  emptyReducer<boolean>(false).with({
    Object: fields => fields.every(field => field.child),
    YesNo: (question, positive, child) => !!(positive && child),
    Checkboxes: (label, checkboxes) =>
      checkboxes.some(checkbox => checkbox.label === checkboxValue && checkbox.checked),
  });

export default toSelectedCheckBox;
