import React, { useEffect, useState } from 'react';
import { gql, useSubscription } from '@apollo/client';
import produce from 'immer';

interface TrackingSessionBeaconLogProps {
  sessionId: string;
  beaconId: string;
}

const TrackingSessionBeaconLog = ({ sessionId, beaconId }: TrackingSessionBeaconLogProps) => {
  const { data } = useSubscription<{
    trackingSessionBeaconLog: {
      id: string;
      sessionId: string;
      beaconId: string;
      stream: string;
      payload: string;
      timestamp: string;
    };
  }>(
    gql`
      subscription trackingSessionBeaconLog($trackingSessionId: String!, $beaconId: String!) {
        trackingSessionBeaconLog(trackingSessionId: $trackingSessionId, beaconId: $beaconId) {
          id
          sessionId
          beaconId
          stream
          payload
          timestamp
        }
      }
    `,
    {
      variables: { trackingSessionId: sessionId, beaconId },
    }
  );

  const [entries, setEntries] = useState<NonNullable<typeof data>['trackingSessionBeaconLog'][]>([]);

  useEffect(() => {
    if (data?.trackingSessionBeaconLog) {
      const log = data.trackingSessionBeaconLog;

      setEntries(
        produce(prev => {
          const index = prev.findIndex(entry => entry.id === log.id);

          if (index !== -1) {
            prev[index] = log;
          } else {
            prev.push(log);
          }

          prev.sort((a, b) => a.timestamp.localeCompare(b.timestamp));
        })
      );
    }
  }, [data?.trackingSessionBeaconLog]);

  return (
    <table cellSpacing="24px">
      {entries.map(entry => (
        <tr key={entry.id}>
          <td>
            <pre>{entry.beaconId}</pre>
          </td>
          <td>
            <pre>{entry.stream}</pre>
          </td>
          <td>
            <pre style={{ maxWidth: '50vw', overflow: 'scroll' }}>{entry.payload}</pre>
          </td>
          <td>
            <pre>{entry.timestamp}</pre>
          </td>
        </tr>
      ))}
    </table>
  );
};

export default TrackingSessionBeaconLog;
