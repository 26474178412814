import React, { FC, useContext, useState } from 'react';
import { Box, CircularProgress, createStyles, ImageList, ImageListItem, Theme, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import SubmissionFormContext from '../../contexts/SubmissionFormContext';
import usePreSignImageRead from '../../hooks/usePreSignImageRead';
import { makeStyles } from '@material-ui/core/styles';
import Lightbox from 'lightbox-react';
import IconButton from '@material-ui/core/IconButton';
import { ArrowDownward, Print } from '@material-ui/icons';

interface ImagesPreviewProps {
  label: string;
  fileNames: Array<string>;
}

interface Image {
  imageUrl: string;
  imageNumber: number;
}

interface Preview {
  open: boolean;
  prevImage: Image | null;
  currImage: Image | null;
  nextImage: Image | null;
}

const ImagesPreview: FC<ImagesPreviewProps> = ({ label, fileNames }) => {
  const classes = useStyles();
  const { organizationId, submissionId } = useContext(SubmissionFormContext);
  const preSignedUrls = usePreSignImageRead(organizationId, submissionId, { fileName: fileNames ?? [] });

  const [preview, setPreview] = useState<Preview>({
    open: false,
    prevImage: null,
    currImage: null,
    nextImage: null,
  });

  const openImage = (image: Image) => {
    const imageNumber = image?.imageNumber || 0;
    const nextImage =
      fileNames.length < imageNumber + 1
        ? null
        : {
            imageUrl: preSignedUrls[fileNames[imageNumber + 1]],
            imageNumber: imageNumber + 1,
          };
    const prevImage =
      imageNumber - 1 >= 0
        ? {
            imageUrl: preSignedUrls[fileNames[imageNumber - 1]],
            imageNumber: imageNumber - 1,
          }
        : null;
    setPreview({
      open: true,
      prevImage: prevImage,
      currImage: image,
      nextImage: nextImage,
    });
  };

  const closePreview = () => {
    setPreview({
      open: false,
      prevImage: null,
      currImage: null,
      nextImage: null,
    });
  };

  const nextImage = () => preview?.nextImage && openImage(preview?.nextImage);

  const prevImage = () => preview?.prevImage && openImage(preview?.prevImage);

  const handleDownloadLightBox = () => {
    if (!preview?.currImage?.imageUrl) return;
    const link = document.createElement('a');
    link.download = JSON.stringify(preview?.currImage);
    link.target = '_blank';
    link.href = preview?.currImage?.imageUrl;
    link.text = 'Image download';
    link.dispatchEvent(new MouseEvent('click'));
    URL.revokeObjectURL(link.href);
  };

  const handlePrintImage = () => {
    if (!preview?.currImage?.imageUrl) return;
    const win = window.open();
    if (!win) return;
    win.document.write(
      `<img style='max-width: 100%' src='${preview?.currImage?.imageUrl}' onload="window.print()" alt="document print" />`
    );
    win.focus();
  };

  return (
    <Box>
      <Typography color="textSecondary" gutterBottom>
        {label}
      </Typography>
      <ImageList cols={3} rowHeight={166}>
        {fileNames?.map((fileName, index) => {
          const preSignedUrl = preSignedUrls[fileName];

          return (
            <ImageListItem
              key={fileName}
              onClick={() =>
                openImage({
                  imageUrl: preSignedUrls[fileName],
                  imageNumber: index,
                })
              }
              className={classes.imageListItem}
            >
              {preSignedUrl ? (
                <img src={preSignedUrl} alt={`Upload #${index + 1}`} loading="lazy" />
              ) : (
                <CircularProgress variant="indeterminate" thickness={4} />
              )}
            </ImageListItem>
          );
        })}
      </ImageList>
      {preview?.open && preview?.currImage && (
        <Lightbox
          mainSrc={preview?.currImage?.imageUrl}
          prevSrc={preview?.prevImage?.imageUrl}
          nextSrc={preview?.nextImage?.imageUrl}
          onCloseRequest={closePreview}
          onMoveNextRequest={nextImage}
          onMovePrevRequest={prevImage}
          toolbarButtons={[
            <IconButton onClick={handleDownloadLightBox} className={classes.lightboxIcon}>
              <ArrowDownward fontSize={'small'} />
            </IconButton>,
            <IconButton onClick={handlePrintImage} className={classes.lightboxIcon}>
              <Print fontSize={'small'} />
            </IconButton>,
          ]}
        />
      )}
      <Box my={1}>
        <Divider />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageList: {},
    imageListItem: {
      '&:hover': {
        opacity: 0.5,
      },
    },
    lightboxIcon: {
      color: 'white',
    },
  })
);

export default ImagesPreview;
