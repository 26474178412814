import React, { CSSProperties, LegacyRef, ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

interface RaftProps {
  anchorClassNames?: string;
  anchorStyles?: CSSProperties;
  classNames?: string;
  styles?: CSSProperties;
  children: ReactNode;
}

const Raft = ({ children }: RaftProps) => {
  const rootRef = useRef<HTMLElement>();

  const [rect, setRect] = useState<DOMRect>();

  useEffect(() => {
    let animationFrame: ReturnType<typeof requestAnimationFrame>;

    const loop = () => {
      const root = rootRef.current;

      if (root) {
        setRect(root.getBoundingClientRect());
      }

      requestAnimationFrame(loop);
    };

    loop();

    return () => {
      cancelAnimationFrame(animationFrame);
    };
  }, []);

  return (
    <div ref={rootRef as LegacyRef<HTMLElement>}>
      {rect &&
        createPortal(
          <div
            style={{
              position: 'fixed',
              top: rect.top,
              bottom: rect.bottom,
              left: rect.left,
              right: rect.right,
              zIndex: 3000,
            }}
          >
            {children}
          </div>,
          document.body
        )}
    </div>
  );
};

export default Raft;
