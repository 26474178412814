import Entity from './Entity';

export default interface ScheduleUser extends Entity {
  hasScheduleAccess: boolean;
  scheduleAccessAll: boolean;
}

export enum ScheduleUserType {
  Physician = 'Physician',
  StaffMember = 'StaffMember',
  Anesthesiologist = 'Anesthesiologist',
  Vendor = 'Vendor',
}
