import React, { FC } from 'react';
import { Procedure as ProcedureT } from '../../../../../../types/Procedure';
import { PhysicianAnesthesiologistView } from '../../anesthesiologist/Personnel';
import { Bed } from '../../Patient';
import { PatientStat } from '../../shared/PatientStat';
import { getColor } from '../Procedure';
import { alpha, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const Label = ({ text, backgroundColor = 'primary' }: { text: string; backgroundColor?: string }) => (
  <Box
    borderRadius={4}
    bgcolor={backgroundColor}
    style={{ fontWeight: 'bold', color: 'white', fontSize: '0.75em' }}
    px={0.5}
  >
    {text}
  </Box>
);

const AnesthesiologistCardCompactHeader: FC<{
  procedure: ProcedureT;
  patientInitials?: string;
  physicianName?: string;
  anesthesiologistName?: string;
  anesthesiaType?: string;
  isCanceled?: any;
  noShow: boolean;
  color: string;
  status: string;
  showBedNumber: boolean;
  stretch?: boolean;
  hideStartTime?: boolean;
  inOrStatus: string;
  hospitalId?: number;
}> = ({
  procedure,
  patientInitials,
  physicianName,
  anesthesiologistName,
  anesthesiaType,
  isCanceled,
  noShow,
  color,
  status,
  stretch,
  hideStartTime,
  showBedNumber,
  inOrStatus,
  hospitalId,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <PhysicianAnesthesiologistView physicianName={physicianName} inline />
        <Box display="flex" alignItems="center" style={{ gap: 8 }}>
          <PatientStat inline right>
            <Label backgroundColor={getColor(status, hospitalId)} text={inOrStatus ? inOrStatus : status} />
            {showBedNumber && !!procedure?.patient?.bed && <Bed bed={procedure?.patient?.bed} />}
          </PatientStat>
        </Box>
      </Box>
      <Box className={classes.procedure}>
        <span style={{ fontWeight: 'bold' }}>{anesthesiologistName}</span>
        <Box className={classes.patient}>{patientInitials?.replace(' ', '')}</Box>

        {/* PLACEHOLDER */}
        {/*<Box>*/}
        {/*  <Chip variant="outlined" size="small" label={'N'} />*/}
        {/*</Box>*/}
      </Box>
      <Box overflow="hidden" flex={1}>
        {procedure.procedureType?.replaceAll('\n', ' ')}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: theme.palette.text.primary,
    lineHeight: 'normal',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  procedure: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '.75em',
    marginBottom: '1em',
    display: 'flex',
    alignItems: 'center',
    gap: '0.25em',
  },
  patient: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    justifyContent: 'center',
    lineHeight: 'inherit',
    backgroundColor: alpha(theme.palette.text.primary, 0.1),
    borderRadius: '0.25em',
    alignSelf: 'flex-end',
  },
}));

export default AnesthesiologistCardCompactHeader;
