import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.6rem;
  margin: 1.6rem 2.8rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';

  @media (orientation: portrait) {
    justify-content: unset;
    gap: 0;
  }
`;

export const Box2 = styled.div`
  text-align: center;
`;

export const Box4 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const Text2 = styled.div`
  font-size: 1rem;
  line-height: 1.2rem;
  color: #ffffff;
  opacity: 0.5;
`;

export const Text5 = styled.p`
  text-align: center;
  line-height: 1.4;
  opacity: 0.5;
  margin-bottom: 1rem;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 3rem;

  @media (orientation: portrait) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const Footer = styled.div`
  display: grid;
  margin: 1.2rem 0;

  & > * {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
  }
`;
