import React, { FC } from 'react';
import Modal from '../../../../../se/components/Modal';
import Form from '../../../../../se/components/Form';
import { withProps } from 'recompose';
import ObjectInput from '../../../../../se/components/inputs/ObjectInput';
import CancellationSelectInput from './CancellationSelectInput';
import { useMutation } from '@apollo/client';
import { cancelProcedure } from '../../../../../graph/procedures';

const Input = withProps({
  schema: {
    cancellationReason: CancellationSelectInput,
  },
})(ObjectInput);

const ProcedureCancellationModal: FC<{
  procedureId: number;
  onDone: () => void;
}> = ({ procedureId, onDone }) => {

  const [cancelProcedureMutation] = useMutation(cancelProcedure);

  const handleSubmit = async (data) => {
    try {
      await cancelProcedureMutation({ variables: { id: procedureId, reason: data?.cancellationReason } });
      onDone();
    } catch (e: any) {

    }
  };

  return (
    <Modal title="Cancel Procedure" withRouter={false} onClose={onDone}>
      <Form
        input={Input}
        label="Cancel Procedure"
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default ProcedureCancellationModal;
