import React from 'react';
import PropTypes from 'prop-types';

import HtmlInput from '../html/Input';

const addColon = (value, deletion) => (deletion ? value.substr(0, value.length - 1) : `${value}:`);
// const addM = (value, deletion) =>
//   deletion
//     ? value.substr(0, value.length - 2)
//     : `${
//         value.indexOf(' ') < 0
//           ? `${value.substring(0, value.length - 1)} ${value.substring(value.length - 1, value.length)}`
//           : value
//       }M`.toUpperCase();

// TODO Rewrite this madness.
const ACCEPTABLE_STATES = [
  { pattern: /^$/ },
  { pattern: /^2[0-3]$/, transform: addColon },
  { pattern: /^2[0-3]:$/ },
  { pattern: /^2[0-3]:[0-5]$/ },
  { pattern: /^2[0-3]:[0-5]\d$/ },
  { pattern: /^[01]\d$/, transform: addColon },
  { pattern: /^[01]\d:$/ },
  { pattern: /^[01]\d:[0-5]$/ },
  { pattern: /^[01]\d:[0-5]\d$/ },
  { pattern: /^[01][0-2]:[0-5]\d $/ },
  // { pattern: /^[01][0-2]:[0-5]\d\s?[aA]$/, transform: addM },
  // { pattern: /^[01][0-2]:[0-5]\d\s?[pP]$/, transform: addM },
  { pattern: /^[3-9]$/, transform: addColon },
  { pattern: /^[1-2]$/ },
  { pattern: /^[1-9]:$/ },
  { pattern: /^[1-9]:[0-5]$/ },
  { pattern: /^[1-9]:[0-5]\d$/ },
  { pattern: /^[1-9]:[0-5]\d $/ },
  // { pattern: /^[1-9]:[0-5]\d\s?[aA]$/, transform: addM },
  // { pattern: /^[1-9]:[0-5]\d\s?[pP]$/, transform: addM },
  { pattern: /^\d$/ },
];

export const isAcceptableState = e => {
  const potentialNewValue = e.target.value;
  return ACCEPTABLE_STATES.find(state => {
    const match = potentialNewValue.match(state.pattern);

    return match && match.length === 1 && match[0].length === potentialNewValue.length;
  });
};

const TimeInputControl = ({ Input = HtmlInput, name, value, onChange, placeholder, ...rest }) => {
  const handleChange = e => {
    const potentialNewValue = e.target.value;
    const acceptableState = isAcceptableState(e);
    !!acceptableState &&
      onChange(
        acceptableState.transform
          ? acceptableState.transform(potentialNewValue, e.target.value.length < (value || '').length)
          : potentialNewValue
      );
  };

  return (
    <Input
      id={name}
      name={name}
      type="text"
      value={value || ''}
      onChange={handleChange}
      placeholder={placeholder}
      {...rest}
    />
  );
};

TimeInputControl.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default TimeInputControl;
