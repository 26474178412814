import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React from 'react';

const ToastBar = ({ id, children, ...props }) => (
  <Snackbar key={id} open autoHideDuration={6000}>
    <Alert variant="filled" severity={props.variant}>
      {children}
    </Alert>
  </Snackbar>
);

ToastBar.propTypes = {
  id: PropTypes.any.isRequired,
  variant: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
};

export default ToastBar;
