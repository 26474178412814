import queryString, { ParsedQuery } from 'query-string';
import { useEffect, useState } from 'react';
import useConfig from './useConfig';

function defined(value?: any): boolean {
  return value !== undefined;
}

export default function usePreSignImageRead(organizationId: string, submissionId?: string, queryParams?: ParsedQuery) {
  const { apiUrl } = useConfig();
  const search = queryString.stringify(queryParams ?? {});
  const url = [[apiUrl, 'covid-screening', organizationId, 'pre-sign-image-read'].filter(defined).join('/'), search]
    .filter(defined)
    .join('?');

  const [result, setResult] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    (async () => {
      const result = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (!result.ok) {
        console.warn('Failed to pre sign image read', result);
      }

      const { preSignedUrls } = await result.json();

      setResult(preSignedUrls);
    })();
  }, [url]);

  return result;
}
