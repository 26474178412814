import { Box, Button, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router';
import { compose } from 'recompose';
import styled from 'styled-components';

import ButtonRouterLink from '../ButtonRouterLink';
import EntityEmptyState from '../entity-states/EntityEmptyState';
import EntityLoadingState from '../entity-states/EntityLoadingState';
import EntityErrorState from '../entity-states/EntityErrorState';
import { isDefinedAndNotNull } from '../../utilities/check';
import RouterLink from '../RouterLink';
import pluralize from 'pluralize';
import TitleAction from '../TitleAction';
import titlecase from 'stringcase/lib/titlecase';
import { H2 } from '../typography';
import responsive from '../../utilities/responsive';
import Hidden from '@material-ui/core/Hidden';
import { ArrowBack } from '@material-ui/icons';

export const DefaultTitle = styled(H2)`
  display: flex;
  align-items: center;
  margin: 0.5em 0;
  ${responsive.md.andSmaller`
    font-size: 1.25rem;
  `}
`;

const EntityRouter = props => {
  const {
    entityName,
    match,
    data,
    getValue,
    Loading,
    Empty,
    Error,
    View,
    Edit,
    Title,
    Actions,
    titleProvider,
    baseUrlProvider,
    basePathProvider,
    viewBackUrlProvider,
    editBackUrlProvider,
    idProvider,
    location,
    hideTitle,
    hiddenEditMobile,
    entityShowBackButtonTitle,
    ...rest
  } = props;

  const value = getValue(data);
  const hasValue = isDefinedAndNotNull(value);
  const baseUrl = baseUrlProvider && idProvider ? baseUrlProvider(idProvider(props)) : match.url;
  const basePath = basePathProvider ? basePathProvider() : match.path;

  return (
    <>
      <Box data-name="EntityRouter" display="flex" flexDirection="column" flex={1}>
        {!hideTitle && (
          <TitleAction
            Back={
              viewBackUrlProvider
                ? () => (
                    <Button
                      component={RouterLink}
                      color="primary"
                      to={{
                        pathname: viewBackUrlProvider(),
                        search: location.search,
                      }}
                      startIcon={<ArrowBack />}
                    >
                      {entityShowBackButtonTitle || titlecase(pluralize(entityName))}
                    </Button>
                  )
                : undefined
            }
            Title={
              Title
                ? () => <Title data={value} />
                : () => (
                    <Typography component="h1" variant="h2">
                      {hasValue ? titleProvider(value) : ''}
                    </Typography>
                  )
            }
            data={hasValue ? value : undefined}
          >
            <Fragment>
              {hasValue && Actions ? <Actions data={value} /> : null}
              {hiddenEditMobile && (
                <Hidden only={['xs', 'sm']}>
                  {hasValue && Edit ? (
                    <ButtonRouterLink to={{ pathname: `${baseUrl}/edit`, search: location.search }}>
                      Edit
                    </ButtonRouterLink>
                  ) : null}
                </Hidden>
              )}
              {hasValue && !hiddenEditMobile && Edit ? (
                <ButtonRouterLink to={{ pathname: `${baseUrl}/edit`, search: location.search }}>Edit</ButtonRouterLink>
              ) : null}
            </Fragment>
          </TitleAction>
        )}

        {data.loading ? (
          <Loading />
        ) : data.error ? (
          <Error error={data.error} />
        ) : !hasValue ? (
          <Empty />
        ) : (
          <View data={value} backUrlProvider={viewBackUrlProvider} {...rest} />
        )}
      </Box>

      {Edit ? (
        <Route
          path={`${basePath}/edit`}
          render={props => (
            <Edit
              {...props}
              data={data}
              getValue={getValue}
              idProvider={idProvider}
              backUrlProvider={props => ({ search: location.search, pathname: editBackUrlProvider(props) })}
              {...rest}
            />
          )}
        />
      ) : null}
    </>
  );
};

EntityRouter.defaultProps = {
  data: {},
  Loading: EntityLoadingState,
  Error: EntityErrorState,
  Empty: EntityEmptyState,
};

EntityRouter.propTypes = {
  entityName: PropTypes.string,
  match: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  getValue: PropTypes.func.isRequired,
  Loading: PropTypes.func.isRequired,
  Error: PropTypes.func.isRequired,
  View: PropTypes.func.isRequired,
  Edit: PropTypes.func,
  baseUrlProvider: PropTypes.func,
  basePathProvider: PropTypes.func,
  backRouteProvider: PropTypes.func,
  Title: PropTypes.func,
  Actions: PropTypes.func,
  titleProvider: PropTypes.func,
  hideTitle: PropTypes.bool,
  hiddenEditMobile: PropTypes.bool,
};

export default compose(withRouter)(EntityRouter);
