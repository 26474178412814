import React, { useEffect, useRef, useState } from 'react';
import { Box, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AnesthesiaTableControls } from './AnesthesiaTableV1';
import { AnesthesiaChartAnswer, TableCreationInfo } from '../../types/Answer';
import AnesthesiaVitals, { AnesthesiaVitalsControls } from './AnesthesiaVitals';
import { defaultsDeep } from 'lodash';
import omit from 'lodash/omit';
import { getEndTime } from '../../components/entities/schedule/util/time';
import AnesthesiaChartHeader from './AnesthesiaChartHeader';
import AnesthesiaTableV2 from './AnesthesiaTableV2';
import AnesthesiaTableCreationInfoButton from './AnesthesiaTableCreationInfoButton';
import AnesthesiaNotesButton from './AnesthesiaNotesButton';
import AnesthesiaDocumentsButton from './AnesthesiaDocumentsButton';
import { useProcedureIdContext } from '../../components/pages/kiosk/charting/ProcedureIdContextProvider';

interface AnesthesiaChartProps {
  answer: AnesthesiaChartAnswer;
  onChange: (value: AnesthesiaChartAnswer) => void;
}

export const getCurrentTime = () =>
  String(new Date().getHours()).padStart(2, '0') + ':' + String(new Date().getMinutes()).padStart(2, '0');

export const getDefaultEndTime = () =>
  String(new Date().getHours() + 3).padStart(2, '0') + ':' + String(new Date().getMinutes()).padStart(2, '0');

const AnesthesiaChartV2 = ({ answer, onChange }: AnesthesiaChartProps) => {
  const classes = useStyles();

  const answerValue: AnesthesiaChartAnswer = {
    startTime: answer?.startTime === undefined || answer?.startTime === null ? getCurrentTime() : answer?.startTime,
    endTime:
      answer?.endTime === undefined || answer?.endTime === null
        ? answer?.startTime
          ? getEndTime(answer?.startTime, 60) || getDefaultEndTime()
          : getDefaultEndTime()
        : answer?.endTime,
    table: answer?.table || {},
    tableCreationInfos: answer?.tableCreationInfos,
    vitals: answer?.vitals || {},
  };

  const rootRef = useRef<HTMLElement>();

  const [size, setSize] = useState<Pick<DOMRect, 'width' | 'height'> | undefined>(undefined);

  useEffect(() => {
    const target = document.body;

    setSize({
      width: target.clientWidth,
      height: target.clientHeight,
    });

    const resizeObserver = new ResizeObserver(() => {
      setSize({
        width: target.clientWidth,
        height: target.clientHeight,
      });
    });

    resizeObserver.observe(target);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const theme = useTheme();

  const portrait = size && size.height > size.width;

  const firstColumn = (size?.width ?? 800) / 4;

  const anesthesiaTable = useRef<AnesthesiaTableControls>();
  const anesthesiaVitals = useRef<AnesthesiaVitalsControls>();

  const handleAnesthesiaTableHorizontalScroll = (offset: number) => {
    if (!portrait) {
      return;
    }

    anesthesiaVitals.current?.scrollTo(offset);
  };
  const handleAnesthesiaVitalsHorizontalScroll = (offset: number) => {
    if (!portrait) {
      return;
    }

    anesthesiaTable.current?.scrollTo(offset);
  };

  const procedureId = useProcedureIdContext();

  return (
    <div style={{ height: 0 }}>
      <div
        ref={rootRef as any}
        style={{
          height: size ? `calc(${size.height}px - 2em)` : undefined,
          margin: theme.spacing(2, -3),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {portrait ? (
          <div className={classes.portraitContainer}>
            <div style={{ flex: '0 0 min-content' }}>
              <AnesthesiaChartHeader />
            </div>
            <div className={classes.containerItem}>
              <div className={classes.containerItemPanel}>
                <AnesthesiaTableV2
                  ref={anesthesiaTable}
                  onHorizontalScroll={handleAnesthesiaTableHorizontalScroll}
                  portrait
                  firstColumn={firstColumn}
                  startTime={answerValue?.startTime || ''}
                  onStartTimeChange={t => {
                    onChange(defaultsDeep({ startTime: t }, answerValue));
                  }}
                  endTime={answerValue?.endTime || ''}
                  onEndTimeChange={t => {
                    onChange(defaultsDeep({ endTime: t }, answerValue));
                  }}
                  answer={answer?.table}
                  onChange={(value, creationInfo) =>
                    onChange(
                      defaultsDeep(
                        {
                          table: value,
                          tableCreationInfos: creationInfo
                            ? updateTableCreationInfos(answer?.tableCreationInfos, creationInfo)
                            : answer?.tableCreationInfos,
                        },
                        omit(answerValue, 'table', 'tableCreationInfos')
                      )
                    )
                  }
                />
              </div>
            </div>
            <div className={classes.containerItem}>
              <div className={classes.containerItemPanel}>
                <AnesthesiaVitals
                  ref={anesthesiaVitals}
                  onHorizontalScroll={handleAnesthesiaVitalsHorizontalScroll}
                  portrait
                  firstColumn={firstColumn}
                  startTime={answerValue?.startTime || ''}
                  onStartTimeChange={t => {
                    onChange(defaultsDeep({ startTime: t }, answerValue));
                  }}
                  endTime={answerValue?.endTime || ''}
                  onEndTimeChange={t => {
                    onChange(defaultsDeep({ endTime: t }, answerValue));
                  }}
                  answer={answerValue?.vitals || {}}
                  onChange={value => onChange(defaultsDeep({ vitals: value }, omit(answerValue, 'vitals')))}
                >
                  <Box style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1rem' }}>
                    <AnesthesiaTableCreationInfoButton tableCreationInfos={answer?.tableCreationInfos ?? []} />
                    <AnesthesiaNotesButton procedureId={procedureId!} />
                    <AnesthesiaDocumentsButton />
                  </Box>
                </AnesthesiaVitals>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.container}>
            <div className={classes.containerItem}>
              <div className={classes.containerItemPanel}>
                <AnesthesiaTableV2
                  startTime={answerValue?.startTime || ''}
                  onStartTimeChange={t => {
                    onChange(defaultsDeep({ startTime: t }, answerValue));
                  }}
                  endTime={answerValue?.endTime || ''}
                  onEndTimeChange={t => {
                    onChange(defaultsDeep({ endTime: t }, answerValue));
                  }}
                  answer={answer?.table}
                  onChange={(value, creationInfo) =>
                    onChange(
                      defaultsDeep(
                        {
                          table: value,
                          tableCreationInfos: creationInfo
                            ? updateTableCreationInfos(answer?.tableCreationInfos, creationInfo)
                            : answer?.tableCreationInfos,
                        },
                        omit(answerValue, 'table', 'tableCreationInfos')
                      )
                    )
                  }
                />
              </div>
            </div>
            <div className={classes.containerItem}>
              <div className={classes.containerItemPanel}>
                <AnesthesiaVitals
                  startTime={answerValue?.startTime || ''}
                  onStartTimeChange={t => {
                    onChange(defaultsDeep({ startTime: t }, answerValue));
                  }}
                  endTime={answerValue?.endTime || ''}
                  onEndTimeChange={t => {
                    onChange(defaultsDeep({ endTime: t }, answerValue));
                  }}
                  answer={answerValue?.vitals || {}}
                  onChange={value => onChange(defaultsDeep({ vitals: value }, omit(answerValue, 'vitals')))}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: '1em',
    paddingBottom: '1em',
    gap: '1em',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  containerItem: {
    flex: 1,
    position: 'relative',
  },
  containerItemPanel: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  portraitContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
    gap: '1em',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default AnesthesiaChartV2;

export function updateTableCreationInfos(
  existing: TableCreationInfo[] | undefined,
  creationInfo: TableCreationInfo
): TableCreationInfo[] {
  if (!existing || existing.length === 0) {
    return [creationInfo];
  }

  const last = existing[existing.length - 1];

  if (last.item === creationInfo.item && last.time === creationInfo.time) {
    const result = [...existing];

    result.splice(existing.length - 1, 1, creationInfo);

    if (
      !creationInfo.value &&
      !result.some(v => v.item === creationInfo.item && v.time === creationInfo.time && v.value)
    ) {
      result.splice(result.length - 1, 1);
    }

    return result;
  }

  if (!creationInfo.value && !existing.some(v => v.item === creationInfo.item && v.time === creationInfo.time)) {
    return existing;
  }

  return [...existing, creationInfo];
}
