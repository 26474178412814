import { DependencyList, useState } from 'react';
import useDebouncedEffect from './useDebouncedEffect';

export default function useDebouncedMemo<T>(fn: () => T, defaultValue: T, delay: number, deps?: DependencyList) {
  const [value, setValue] = useState(defaultValue);

  useDebouncedEffect(() => setValue(fn()), delay, deps);

  return value;
}
