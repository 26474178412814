import React, { ReactNode, useMemo } from 'react';
import { AnesthesiaChartAnswer } from '../../../../../types/Answer';
import { getCurrentTime } from '../../../../../form/components/AnesthesiaChartV1';
import { PredefinedFormProps } from './AnesthesiaTableV1';
import { formatTime, parseTime } from '../../../../../form/components/AnesthesiaVitals';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CloseIcon from '@material-ui/icons/Close';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { formatDate } from 'date-fns-3';

interface AnesthesiaVitalsProps<TValue> extends PredefinedFormProps<TValue> {
  children: (page: ReactNode, index: number) => ReactNode;
}

const AnesthesiaVitals = <TValue,>({ procedureId, defaultValue, children }: AnesthesiaVitalsProps<TValue>) => {
  const data: AnesthesiaChartAnswer | undefined = ((defaultValue as any)?.anesthesiaTableAndChart ||
    {}) as AnesthesiaChartAnswer;
  // const data: AnesthesiaChartAnswer | undefined = {
  //   time: '16:03',
  //   vitals: {
  //     '16:20': {
  //       BP: {
  //         SYS: 120,
  //         DIA: 80,
  //       },
  //       HR: 80,
  //       RR: 20,
  //       sO2: 97,
  //     },
  //     '16:25': {
  //       BP: {
  //         SYS: 133,
  //         DIA: 90,
  //       },
  //       HR: 70,
  //       RR: 16,
  //       sO2: 99,
  //     },
  //     '16:35': {
  //       BP: {
  //         SYS: 14,
  //         DIA: 90,
  //       },
  //       HR: 65,
  //       RR: 17,
  //       sO2: 98,
  //     },
  //     '18:15': {
  //       BP: {
  //         SYS: 133,
  //         DIA: 90,
  //       },
  //       HR: 70,
  //       RR: 16,
  //       sO2: 99,
  //     },
  //   },
  // };

  const timeInSeconds = parseTime(data?.startTime || getCurrentTime());
  const startTime = 15 * Math.floor(timeInSeconds / 60 / 15) * 60;

  const maxTime = Math.max(0, ...Object.keys(data?.vitals ?? {}).map(parseTime));

  const totalColumns = Math.max(0, maxTime - timeInSeconds) / 60 / 5;
  const quarterHoursPerPage = 8;
  const pages = 1 + Math.floor(totalColumns / quarterHoursPerPage / 3);

  interface CreationInfo {
    time: string;
    type: string;
    value: number;
    author: string;
    timestamp: Date;
  }

  const { log, showDate } = useMemo(() => {
    if (!data) {
      return { log: [], showDate: false };
    }

    const log = Object.entries(data.vitals ?? {})
      .flatMap(([time, entry]) => {
        const creationInfos = { ...(entry.creationInfos ?? {}) };

        const result: CreationInfo[] = [];

        if (creationInfos.BP?.SYS) {
          result.push({
            time,
            type: 'SYS',
            value: entry.BP?.SYS ?? NaN,
            author: creationInfos.BP.SYS.author,
            timestamp: new Date(creationInfos.BP.SYS.timestamp),
          });
        }

        if (creationInfos.BP?.DIA) {
          result.push({
            time,
            type: 'DIA',
            value: entry.BP?.DIA ?? NaN,
            author: creationInfos.BP.DIA.author,
            timestamp: new Date(creationInfos.BP.DIA.timestamp),
          });
        }

        if (creationInfos.HR) {
          result.push({
            time,
            type: 'HR',
            value: entry.HR ?? NaN,
            author: creationInfos.HR.author,
            timestamp: new Date(creationInfos.HR.timestamp),
          });
        }

        if (creationInfos.RR) {
          result.push({
            time,
            type: 'RR',
            value: entry.RR ?? NaN,
            author: creationInfos.RR.author,
            timestamp: new Date(creationInfos.RR.timestamp),
          });
        }

        return result;
      })
      .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());

    const showDate =
      log.length > 0 &&
      !log.every(
        l =>
          l.timestamp.getFullYear() === log[0].timestamp.getFullYear() &&
          l.timestamp.getMonth() === log[0].timestamp.getMonth() &&
          l.timestamp.getDate() === log[0].timestamp.getDate()
      );

    return { log, showDate };
  }, []);

  return (
    <>
      <style>
        {`.anesthesia-vitals, .anesthesia-vitals th, .anesthesia-vitals td {
            border: 1px solid black;
            border-collapse: collapse;
          }
          .anesthesia-vitals td {
            text-align: center;
          }
          .anesthesia-cell {
            width: 1.16em;
            height: 1.16em;
            position: relative;
            border: none;
          }
          .anesthesia-value {
            text-align: center;
            width: 1.16em;
            height: 1.16em;
            border: none;
          }`}
      </style>
      {[...Array(pages)].map((_, page) =>
        children(
          <div className="page">
            <table className="anesthesia-vitals" width="100%">
              <thead>
                <tr>
                  <th></th>
                  {[...Array(quarterHoursPerPage)].map((_, i) => (
                    <th key={i} colSpan={3}>
                      {formatTime(startTime + page * quarterHoursPerPage * 15 * 60 + i * 15 * 60)}
                    </th>
                  ))}
                </tr>
                <tr>
                  <th></th>
                  {[...Array(quarterHoursPerPage * 3)].map((_, i) => (
                    <th key={i}>{i * 5}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {[...Array(24)].map((_, row) => {
                  const maxValue = 240 - row * 10;
                  const minValue = maxValue - 10;
                  return (
                    <tr key={row}>
                      {row === 0 ? (
                        <th></th>
                      ) : row === 23 ? (
                        <th></th>
                      ) : row % 2 === 1 ? (
                        <th rowSpan={2}>{220 - (row - 1) * 10}</th>
                      ) : null}
                      {[...Array(quarterHoursPerPage * 3)].map((_, j) => {
                        const time = formatTime(startTime + page * quarterHoursPerPage * 15 * 60 + j * 5 * 60);
                        const entry = data?.vitals?.[time];
                        const SYSvalue = getPercentage(entry?.BP?.SYS, minValue, maxValue);
                        const DIAvalue = getPercentage(entry?.BP?.DIA, minValue, maxValue);
                        const HRvalue = getPercentage(entry?.HR, minValue, maxValue);
                        const RRvalue = getPercentage(entry?.RR, minValue, maxValue);
                        return (
                          <td key={j}>
                            <div className="anesthesia-cell">
                              {SYSvalue !== undefined && (
                                <div
                                  style={{
                                    position: 'absolute',
                                    left: '-0.4em',
                                    top: (1 - SYSvalue) * 100 + '%',
                                    width: '2em',
                                    border: 'none',
                                    transform: 'translateY(-1em)',
                                  }}
                                >
                                  <KeyboardArrowDownIcon
                                    style={{ marginBottom: '-0.33em', zIndex: 10, fill: 'magenta' }}
                                  />
                                </div>
                              )}
                              {DIAvalue !== undefined && (
                                <div
                                  style={{
                                    position: 'absolute',
                                    left: '-0.4em',
                                    top: (1 - DIAvalue) * 100 + '%',
                                    width: '2em',
                                    border: 'none',
                                    transform: 'translateY(-0.5em)',
                                  }}
                                >
                                  <KeyboardArrowUpIcon style={{ marginTop: '-0.33em', zIndex: 10, fill: 'blue' }} />
                                </div>
                              )}
                              {HRvalue !== undefined && (
                                <div
                                  style={{
                                    position: 'absolute',
                                    left: '-0.05em',
                                    top: (1 - HRvalue) * 100 + '%',
                                    width: '2em',
                                    border: 'none',
                                  }}
                                >
                                  <CloseIcon
                                    style={{ marginTop: '-0.33em', zIndex: 10, fill: 'red', transform: 'scale(0.75)' }}
                                  />
                                </div>
                              )}
                              {RRvalue !== undefined && (
                                <div
                                  style={{
                                    position: 'absolute',
                                    left: '0.2em',
                                    top: (1 - RRvalue) * 100 + '%',
                                    width: '2em',
                                    border: 'none',
                                  }}
                                >
                                  <RadioButtonUncheckedIcon
                                    style={{
                                      marginTop: '-0.33em',
                                      zIndex: 10,
                                      fill: 'green',
                                      transform: 'scale(0.75)',
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                <tr>
                  <th>SYS</th>
                  {[...Array(quarterHoursPerPage * 3)].map((_, j) => {
                    const time = formatTime(startTime + page * quarterHoursPerPage * 15 * 60 + j * 5 * 60);
                    const entry = data?.vitals?.[time];
                    const value = entry?.BP?.SYS ?? '';
                    return (
                      <td key={j}>
                        <div className="anesthesia-value">{value}</div>
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>DIA</th>
                  {[...Array(quarterHoursPerPage * 3)].map((_, j) => {
                    const time = formatTime(startTime + page * quarterHoursPerPage * 15 * 60 + j * 5 * 60);
                    const entry = data?.vitals?.[time];
                    const value = entry?.BP?.DIA ?? '';
                    return (
                      <td key={j}>
                        <div className="anesthesia-value">{value}</div>
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>HR</th>
                  {[...Array(quarterHoursPerPage * 3)].map((_, j) => {
                    const time = formatTime(startTime + page * quarterHoursPerPage * 15 * 60 + j * 5 * 60);
                    const entry = data?.vitals?.[time];
                    const value = entry?.HR;
                    return (
                      <td key={j}>
                        <div className="anesthesia-value">{value}</div>
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th>RR</th>
                  {[...Array(quarterHoursPerPage * 3)].map((_, j) => {
                    const time = formatTime(startTime + page * quarterHoursPerPage * 15 * 60 + j * 5 * 60);
                    const entry = data?.vitals?.[time];
                    const value = entry?.RR ?? '';
                    return (
                      <td key={j}>
                        <div className="anesthesia-value">{value}</div>
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>,
          page
        )
      )}
      <style>
        {`.vitals-log, .vitals-log th, .vitals-log td {
            border: 1px solid black;
            border-collapse: collapse;
          }
          .vitals-log td {
            text-align: center;
          }
          .vitals-log th, .vitals-log td {
            padding: 0.5em,
          }`}
      </style>
      {log.length > 0 &&
        children(
          <div className="page">
            <table className="vitals-log" width="100%">
              <thead>
                <tr>
                  <th>Author</th>
                  <th>Timestamp</th>
                  <th>Time</th>
                  <th>Value</th>
                  <th>Entry</th>
                </tr>
              </thead>
              <tbody>
                {log.map((l, i) => (
                  <tr key={i}>
                    <td>{l.author}</td>
                    <td>{formatDate(l.timestamp, showDate ? 'Ppp' : 'pp')}</td>
                    <td>{l.time}</td>
                    <td>{l.type}</td>
                    <td>{l.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>,
          pages
        )}
    </>
  );
};

const getPercentage = (value: number | undefined, min: number, max: number): number | undefined => {
  if (!value || value < min || value >= max) {
    return;
  }
  return (value - min) / (max - min);
};

export default AnesthesiaVitals;
