import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme as withStyledTheme } from 'styled-components';
import Select from 'react-dropdown-select';
import { withTheme } from '../../theme';
import { withProps } from 'recompose';

const Items = styled.div`
  overflow: auto;
  min-height: 10px;
  max-height: 200px;
`;

const StyledItem = styled.div`
  background: ${withTheme(theme => theme.backgroundColor.string())};
  line-height: ${withTheme(theme => theme.lineHeight.xl)};
  padding: ${withTheme(theme => theme.input.padding)};
  cursor: pointer;
  > div {
    display: flex;
    align-items: center;
  }
  input {
    margin-right: 1em;
  }
  :hover {
    background: ${withTheme(theme => theme.backgroundColor.darken(0.05).string())};
  }
  opacity: ${p => (p.disabled ? 0.85 : 1)};
`;

const customStyles = theme => ({
  padding: 'calc(1rem * 0.75 - 0.125rem)',
  border: `2px solid ${theme.border.color.default.string()}`,
  cursor: 'pointer',
});

const ContentLabel = ({ props, state, label }) => (
  <span>
    {label || 'Configure Columns'} {`(${state.values.length} of ${props.options.length} selected.)`}
  </span>
);

const OptionList = ({ props, state, methods }) => (
  <Items>
    {props.options.map(option => {
      const isSelected = methods.isSelected(option);
      const isDisabled = isSelected && state.values.length === 1;
      return (
        <StyledItem
          disabled={option.disabled || isDisabled}
          key={option[props.valueField]}
          onClick={e => (isDisabled ? {} : isSelected ? methods.removeItem(e, option, false) : methods.addItem(option))}
        >
          <input
            type="checkbox"
            onChange={e =>
              isDisabled ? {} : isSelected ? methods.removeItem(e, option, false) : methods.addItem(option)
            }
            checked={isSelected}
          />
          <span>{option[props.labelField]}</span>
        </StyledItem>
      );
    })}
  </Items>
);

const ColumnSelect = withStyledTheme(
  ({ theme, options, values, onChange, label, labelField = 'title', valueField = 'title', keepOpen }) => (
    <Select
      multi
      options={options}
      values={values}
      labelField={labelField}
      valueField={valueField}
      style={customStyles(theme)}
      contentRenderer={withProps({ label: label })(ContentLabel)}
      dropdownRenderer={OptionList}
      onChange={onChange}
      dropdownHandle={false}
      keepOpen={keepOpen}
    />
  )
);

ColumnSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ColumnSelect;
