import get from 'lodash/fp/get';

const getStaffNameFromStaffShift = staffShifts =>
  staffShifts
    .map(staffShift => ({
      staff: {
        name: get('staff.name')(staffShift),
      },
      additionalText: get('additionalText')(staffShift),
      from: get('from')(staffShift),
      to: get('to')(staffShift),
    }))
    .filter(staff => !!staff);

const getStaffOnProcedures = procedure =>
  getStaffNameFromStaffShift(get('staffShifts')(procedure))?.map(staff => ({
    ...staff,
    from: staff.from ?? get('startTimeText')(procedure),
    to: staff.to ?? get('endTimeText')(procedure),
    isProcedure: true,
  }));

function mergeBasedOnKey(list) {
  return Object.values(
    list?.reduce((a, b) => {
      a[b.name] = a[b.name] || { name: b.name, staffShifts: [] };

      a[b.name].staffShifts = [...a[b.name].staffShifts, ...b.staffShifts];

      return a;
    }, {}) || []
  );
}

export const getRoomWithStaff = orProcedures =>
  mergeBasedOnKey(
    orProcedures
      ?.filter(orProcedure => !!orProcedure.orId && !!orProcedure.staffShifts.length)
      ?.map(orProcedure => ({
        name: get('or')(orProcedure),
        staffShifts: getStaffOnProcedures(orProcedure),
      }))
  );

export const scheduleStaff = (rooms, procedures) => {
  const combinedArrays = [];

  rooms?.forEach(function (element) {
    const procedureStaffShift = procedures?.find(e => e.name === element.name)?.staffShifts;
    combinedArrays.push({
      ...element,
      staffShifts: [...element?.staffShifts, ...(procedureStaffShift ?? [])],
    });
  });

  return combinedArrays;
};
