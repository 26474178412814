import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import WarningIcon from '@material-ui/icons/Warning';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

const SeverityIcon = ({ severity }) => {
  switch (severity) {
    case 'warning':
      return <WarningIcon color="error" />;
    case 'info':
      return <InfoOutlined color="disabled" />;
    default:
      return <InfoOutlined />;
  }
};

const RequiresAttention = ({ issues }) => (
  <Box py={2}>
    <Box mb={2}>
      <Typography variant="h4">Requires Attention</Typography>
    </Box>

    <TableContainer>
      <Table aria-label="table of items that require attention">
        <TableBody>
          {issues.map((row, i) => (
            <TableRow hover key={i} onClick={row.onClick} style={{ cursor: 'pointer' }}>
              <TableCell component="th" scope="row">
                <Box display="flex" alignItems="center">
                  <Box mr={1} fontSize={0}>
                    <SeverityIcon severity={row.severity} />
                  </Box>
                  <span style={{ whiteSpace: 'nowrap' }}>{row.title}</span>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
);

export default RequiresAttention;
