import React from 'react';

import type IllustrationProps from '../IllustrationProps';

export default ({ width, height, variant }: IllustrationProps) => {
  const color = variant === 'light' ? '#1976d2' : '#00a7f7';
  return (
    <svg viewBox="0 0 60 60" width={width} height={height}>
      <path
        opacity={0.28}
        fill={color}
        d="M2.68,30.51l.25,29,13,.08-.35-1c0-.1-3.31-9.94,5.82-16.11a17.37,17.37,0,0,0,7.36-10.9l.18-.89Z"
      />
      <path
        fill={color}
        d="M49.72,13.56H42.46a7.77,7.77,0,0,0-7.9,7.63v8.52H28.78v-9a7.77,7.77,0,0,0-7.9-7.62H13.62a7.77,7.77,0,0,0-7.9,7.62v9H2.38V59.93H25.52a.75.75,0,1,0,0-1.5H3.88V31.21H34.56v4.42a.75.75,0,0,0,1.5,0V21.19a6.28,6.28,0,0,1,6.4-6.13h7.26a6.28,6.28,0,0,1,6.4,6.13V36.76a.75.75,0,0,0,1.5,0V21.19A7.77,7.77,0,0,0,49.72,13.56ZM7.22,20.75a6.27,6.27,0,0,1,6.4-6.12h7.26a6.27,6.27,0,0,1,6.4,6.12v9H7.22Z"
      />
      <path
        fill={color}
        d="M17.35,12.09a6,6,0,1,0-6-6A6,6,0,0,0,17.35,12.09Zm0-10.52a4.51,4.51,0,1,1-4.51,4.51A4.51,4.51,0,0,1,17.35,1.57Z"
      />
      <path
        fill={color}
        d="M46.09,12.09a6,6,0,1,0-6-6A6,6,0,0,0,46.09,12.09Zm0-10.52a4.51,4.51,0,1,1-4.51,4.51A4.52,4.52,0,0,1,46.09,1.57Z"
      />
      <path
        fill={color}
        d="M50.8,58.43H46.63V33.64a.75.75,0,0,0-1.5,0V58.43H38.32l2.81-3.81V19h-1.5V54.13l-4.28,5.8H52.3V19H50.8Z"
      />
      <path
        fill={color}
        d="M10.81,43.81a.76.76,0,0,0,.75-.75v-3.5h3.59a.75.75,0,0,0,0-1.5H11.56v-3.5a.75.75,0,0,0-1.5,0v3.5H6.47a.75.75,0,1,0,0,1.5h3.59v3.5A.76.76,0,0,0,10.81,43.81Z"
      />
    </svg>
  );
};
