import { Query } from '@apollo/client/react/components';

import React, { useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';
import { FilePicker } from 'react-file-picker';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import schema, { resendPhysicianSignatureInvite } from '../../../graph/surgeon/PhysicianSO';
import get from 'lodash/get';
import Chip from '@material-ui/core/Chip';
import { getNotificationTemplate } from '../../../graph/notificationTemplates';
import Tooltip from '../../Tooltip';
import { SendingSetting } from '../notificationTemplate/NotificationTemplates';

const RequestSignature = ({ data }) => {
  const [resendPhysicianSignatureInviteMutation, { loading, called }] = useMutation(resendPhysicianSignatureInvite);
  const inviteSurgeonToSign = async () => {
    await resendPhysicianSignatureInviteMutation({ variables: { physician: data.id } });
  };
  const { data: smsPatientReadyNotification } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'PhysicianSignatureUpdate', channel: 'SMS' },
  });
  const smsNotificationData = smsPatientReadyNotification?.getNotificationTemplate[0] || null;
  if (!(smsNotificationData?.sendingSetting !== SendingSetting.Off) || !data.shouldSendNotification) {
    return (
      // Had to do it like this - It had a bug with passing '' as a content to tooltip (Old value 'Patient mess....' was still displayed even condition was good.
      <Tooltip
        content={
          !data.shouldSendNotification
            ? 'Physician does not want messages to be sent to him.'
            : 'Physician messaging has been turned off. Go to message configuration to enable it.'
        }
      >
        <Button
          onClick={inviteSurgeonToSign}
          disabled={
            loading || !(smsNotificationData?.sendingSetting !== SendingSetting.Off) || !data.shouldSendNotification
          }
          variant="outlined"
          color="primary"
        >
          {loading ? 'Sending…' : called ? 'Resend' : 'Invite Physician To Sign'}
        </Button>
      </Tooltip>
    );
  } else {
    return (
      <Button onClick={inviteSurgeonToSign} disabled={loading} variant="outlined" color="primary">
        {loading ? 'Sending…' : called ? 'Resend' : 'Invite Physician To Sign'}
      </Button>
    );
  }
};

const Signature = props => {
  const [state, setState] = useState(false);
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  const { data: consentQuery } = useQuery(schema.hasConsent, { variables: { id: props.data.id } });
  const isConsent = get(consentQuery, 'hasConsent', false);

  const maxSignatureWidth = 260;
  const maxSignatureHeight = 130;

  useEffect(() => {
    setState(isConsent);
  }, [isConsent]);

  const handleChange = async () => {
    setState(!state);
    const setConsentResult = await client.mutate({
      mutation: schema.updateSignatureConsent,
      variables: { id: props.data.id, mdSignature: !state },
      refetchQueries: [{ query: schema.hasConsent, variables: { id: props.data.id } }],
      fetchPolicy: 'no-cache',
    });
    if (!Boolean(setConsentResult.data.updateSignatureConsent)) {
      alert('Error setting signature consent. Try again later');
    }
  };

  const handleFilePickerChange = file => {
    if (!file) return;

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async e => {
      const pictureData = e.target.result;
      const image = new Image();
      image.style = 'image-orientation: from-image;';
      image.src = pictureData;

      image.onload = async () => {
        // resize image here
        setLoading(true);
        try {
          // calculate aspect ratio
          const iw = image.width;
          const ih = image.height;
          let nw = iw;
          let nh = ih;

          if (iw > maxSignatureWidth) {
            nw = maxSignatureWidth;
            nh = (nw * ih) / iw;
          }
          if (nh > maxSignatureHeight) {
            nh = maxSignatureHeight;
            nw = (nh * iw) / ih;
          }

          const canvas = document.createElement('canvas');
          canvas.width = nw;
          canvas.height = nh;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0, nw, nh);

          await uploadPictureData(ctx.canvas.toDataURL('image/jpeg', 97));
        } finally {
          setLoading(false);
        }
      };
    };
  };

  const uploadPictureData = async pictureData => {
    const uploadResult = await client.mutate({
      mutation: schema.addSignature,
      variables: { id: props.data.id, signature: pictureData },
      refetchQueries: [{ query: schema.hasSignature, variables: { id: props.data.id } }],
      fetchPolicy: 'no-cache',
    });
    if (!Boolean(uploadResult.data.addSignature)) {
      alert('Error while uploading signature. Try reuploading!');
    }
  };

  const handleFilePickerError = error => {
    alert(error);
  };

  return (
    <Box mt={4}>
      <Box mb={2} display="flex" style={{ alignItems: 'center' }}>
        <Typography variant="h6">Signature</Typography>
        <Box ml={1}>
          <Query query={schema.hasSignature} variables={{ id: props.data.id }} fetchPolicy={'network-only'}>
            {result => (
              <Chip
                size="small"
                icon={
                  <Icon fontSize="inherit">{result.data && result.data.hasSignature ? 'check' : 'more_horiz'}</Icon>
                }
                label={result.data && result.data.hasSignature ? 'Signature uploaded' : 'Signature not uploaded'}
                color={result.data && result.data.hasSignature ? 'primary' : 'default'}
              />
            )}
          </Query>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Box display="flex" flexDirection="row">
            <Box mr={1}>
              <RequestSignature {...props} />
            </Box>
            <Hidden smDown>
              <FilePicker
                extensions={['jpg', 'jpeg', 'png']}
                maxSize={20}
                onChange={handleFilePickerChange}
                onError={handleFilePickerError}
              >
                <Button variant="outlined" color="primary" disabled={loading}>
                  Upload signature
                </Button>
              </FilePicker>
            </Hidden>
            <Hidden mdUp>
              <label htmlFor={'mdSignaturePhoto'}>
                <Button variant="outlined" color="primary" disabled={loading} component="span">
                  <input
                    type="file"
                    id={'mdSignaturePhoto'}
                    name={'mdSignaturePhoto'}
                    accept="image/*"
                    capture="environment"
                    style={{ display: 'none' }}
                    onChange={e => handleFilePickerChange(e.target.files ? e.target.files[0] : undefined)}
                  />
                  Take a picture
                </Button>
              </label>
            </Hidden>
          </Box>

          {loading && <CircularProgress />}
          <br />
          <Typography variant="subtitle2" color="textSecondary">
            {`*Signature will be scaled to fit in a box ${maxSignatureWidth}px wide and ${maxSignatureHeight}px tall`}
          </Typography>
        </Grid>
        <Grid item md={6}>
          <FormControlLabel
            control={<Checkbox checked={state} onChange={handleChange} name="checkedB" color="primary" />}
            label="Physician gives permission for using their eSignature on all digital forms."
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Signature;
