import clsx from 'clsx';
import { alpha, Box, Card, CardContent, Collapse, makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';

interface SubFormCardProps {
  mainItem: ReactNode;
  children?: ReactNode;
  showChildren?: boolean;
}

const SubFormCard = ({ mainItem, children, showChildren }: SubFormCardProps) => {
  const classes = useStyles();

  return (
    <Card elevation={0} className={clsx(classes.card, { [classes.cardActive]: showChildren })}>
      <CardContent className={clsx(classes.cardContent, { [classes.cardHide]: !showChildren })}>
        <Box mr={-2}>{mainItem}</Box>
        <Collapse in={showChildren}>
          <Box ml={5} mb={2}>
            {showChildren && children}
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles(theme => ({
  formGroup: {
    marginTop: theme.spacing(1),
  },
  card: {
    backgroundColor: 'transparent',
  },
  cardActive: {
    minWidth: 275,
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    marginBottom: theme.spacing(1),
  },
  cardHide: {
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardContent: {
    paddingTop: theme.spacing(0),
    '&:last-child': {
      paddingBottom: theme.spacing(0),
    },
  },
}));

export default SubFormCard;
