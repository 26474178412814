import graphqlSelectInput from '../graphqlSelectInput';
import { list, listLastHundred } from '../../../graph/procedureTypes';

export const procedureTypeSelectInputFactory = (label, query = list) =>
  graphqlSelectInput({
    entityName: 'ProcedureType',
    label: label,
    placeholder: 'e.g., Neuroimaging',
    graphqlList: query,
    getOptionValue: option => (option ? parseInt(option.value, 10) : undefined),
  });

export const LastHundredProcedureTypesSelectInput = procedureTypeSelectInputFactory('Procedure Type', listLastHundred);

export default procedureTypeSelectInputFactory('Procedure Type');
