import React, { useState, useEffect } from 'react';
import { useValue } from '../ValueContext';
import { useQuestionnaireBooleanValue } from '../hooks';
import toBoolean from '../../../../../questionnaire/toBoolean';

const FallRiskCheckbox2 = ({
  label,
  name,
  value: checkboxValue,
  path,
  reducer,
  style,
  negative,
  onCheckboxChange,
  checked,
}) => {
  const { defaultValue, setValue: setInternalValue } = useValue(name);
  const questionnaireValue = useQuestionnaireBooleanValue(reducer || toBoolean, path);

  const [isChecked, setIsChecked] = useState(
    checked !== undefined ? checked : defaultValue || questionnaireValue || false
  );

  useEffect(() => {
    setIsChecked(checked !== undefined ? checked : defaultValue || questionnaireValue || false);
  }, [checked, defaultValue, questionnaireValue]);

  const handleChange = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    setInternalValue(newValue);
    onCheckboxChange(newValue ? checkboxValue : 0, name);
  };

  return (
    <div style={{ display: 'flex', ...style }}>
      <label>
        <input type="checkbox" name={name} value={checkboxValue} checked={isChecked} onChange={handleChange} />
        {label}
      </label>
    </div>
  );
};

export default FallRiskCheckbox2;
