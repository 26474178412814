export default {
  hospitalName: 'University Ambulatory Surgery Center',
  nodeEnv: import.meta.env.MODE,
  publicURL: import.meta.env.VITE_URL || 'http://localhost:3000',
  apiURL: import.meta.env.VITE_API_URL || 'http://localhost:8080',
  appURL: import.meta.env.VITE_URL || 'http://localhost:3000',
  careURL: import.meta.env.VITE_CARE_URL || 'http://localhost:4000',
  covidScreeningURL: import.meta.env.VITE_CS_URL || 'http://localhost:5000',
  assetsURL: import.meta.env.ASSETS_URL || 'https://assets.ospitek.com',
  appVersion: import.meta.env.VITE_VERSION,
  appVersionTvInternal: import.meta.env.VITE_VERSION_TV_INTERNAL,
  appVersionTvSchedule: import.meta.env.VITE_VERSION_TV_SCHEDULE,
  appVersionTvWaitingRoom: import.meta.env.VITE_VERSION_TV_WAITING_ROOM,
};
