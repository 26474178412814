import { ParsedQuery } from 'query-string';

const keyCompactions: { [key: string]: string } = {
  category: 'c',
  parent: 'r',
};

const valueCompactions: { [key: string]: string } = {
  patient: 'p',
  caretaker: 'c',
  staff: 's',
  other: 'o',
};

function compactKey(key: string): string {
  return keyCompactions[key] ?? key;
}

function compactValue(value: string): string {
  return valueCompactions[value] ?? value;
}

export default function compactQueryParams(query: ParsedQuery): ParsedQuery {
  return Object.fromEntries(
    Object.entries(query).map(([key, value]) => [
      compactKey(key),
      value == null ? null : Array.isArray(value) ? value.map(compactValue) : compactValue(value),
    ])
  );
}
