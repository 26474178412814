export const workingHours = {
  start: 6,
  end: 18,
};

export const scheduleData = [
  {
    date: new Date(2021, 3, 11),
    centers: [
      {
        name: 'North Coast Surgery Center',
        rooms: [
          {
            name: 'OR1',
            availableSlots: [
              {
                start: 8,
                end: 11,
              },
              {
                start: 13,
                end: 15,
              },
            ],
            bookedSlots: [
              {
                start: 8,
                end: 9,
              },
              {
                start: 9.5,
                end: 11,
              },
            ],
          },
        ],
      },
      {
        name: 'North Coast Surgery Center',
        rooms: [
          {
            name: 'OR1',
            availableSlots: [
              {
                start: 6,
                end: 11,
              },
            ],
            bookedSlots: [
              {
                start: 9,
                end: 11,
              },
            ],
          },
          {
            name: 'OR2',
            availableSlots: [
              {
                start: 10,
                end: 16,
              },
            ],
          },
          {
            name: 'OR3',
            availableSlots: [
              {
                start: 9,
                end: 13,
              },
            ],
            bookedSlots: [
              {
                start: 9,
                end: 10,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    date: new Date(2021, 3, 12),
    centers: [
      {
        name: 'North Coast Surgery Center',
        rooms: [
          {
            name: 'OR1',
            availableSlots: [
              {
                start: 6,
                end: 15,
              },
            ],
            bookedSlots: [
              {
                start: 8,
                end: 9,
              },
              {
                start: 9.5,
                end: 11,
              },
            ],
          },
        ],
      },
      {
        name: 'North Coast Surgery Center',
        rooms: [
          {
            name: 'OR1',
            availableSlots: [
              {
                start: 6,
                end: 15,
              },
            ],
            bookedSlots: [
              {
                start: 6,
                end: 9,
              },
            ],
          },
          {
            name: 'OR2',
            availableSlots: [
              {
                start: 6,
                end: 15,
              },
            ],
          },
          {
            name: 'OR3',
            availableSlots: [
              {
                start: 6,
                end: 15,
              },
            ],
            bookedSlots: [
              {
                start: 9,
                end: 13,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    date: new Date(2021, 3, 13),
    centers: [
      {
        name: 'North Coast Surgery Center',
        rooms: [
          {
            name: 'OR1',
            availableSlots: [
              {
                start: 7,
                end: 16,
              },
            ],
            bookedSlots: [
              {
                start: 12,
                end: 15,
              },
            ],
          },
        ],
      },
      {
        name: 'North Coast Surgery Center',
        rooms: [
          {
            name: 'OR1',
            availableSlots: [
              {
                start: 7,
                end: 16,
              },
            ],
            bookedSlots: [
              {
                start: 10,
                end: 12,
              },
            ],
          },
          {
            name: 'OR2',
            availableSlots: [
              {
                start: 7,
                end: 16,
              },
            ],
          },
          {
            name: 'OR3',
            availableSlots: [
              {
                start: 7,
                end: 16,
              },
            ],
            bookedSlots: [
              {
                start: 9,
                end: 10,
              },
            ],
          },
        ],
      },
    ],
  },
];

export const DummyPatients = [
  {
    name: 'John Doe',
    age: 45,
    sex: 'M',
    procedure: 'Left eyebrow amputation',
  },
  {
    name: 'John Doe',
    age: 45,
    sex: 'M',
    procedure: 'Left eyebrow amputation',
  },
  {
    name: 'John Doe',
    age: 45,
    sex: 'M',
    procedure: 'Left eyebrow amputation',
  },
  {
    name: 'John Doe',
    age: 45,
    sex: 'M',
    procedure: 'Left eyebrow amputation',
  },
  {
    name: 'John Doe',
    age: 45,
    sex: 'M',
    procedure: 'Left eyebrow amputation',
  },
];
