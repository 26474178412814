import React, { CSSProperties, LegacyRef, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createTheme';
import TableCellTop from './TableCellTop';
import TableCellLeft from './TableCellLeft';
import TableCell from './TableCell';
import clsx from 'clsx';
import Raft from './Raft';

export interface TableProps {
  rootRef?: LegacyRef<HTMLElement>;
  firstColumn?: number;
  rows: number;
  columns: number;
  renderCorner?: () => ReactNode;
  renderTop?: (column: number) => ReactNode;
  renderLeft?: (row: number) => ReactNode;
  renderCell?: (row: number, column: number) => ReactNode;
  classes?: TableClasses;
  gridStyle?: CSSProperties;
  children?: ReactNode;
}

interface TableClasses {
  container?: string;
  grid?: string;
  top?: string;
  left?: string;
  cell?: string;
  topCellSpan?: number;
}

const Table = ({
  rootRef,
  firstColumn,
  rows,
  columns,
  renderCorner,
  renderTop,
  renderLeft,
  renderCell,
  gridStyle,
  children,
  ...props
}: TableProps) => {
  const classes = useStyles({
    firstColumn,
    rows,
    columns,
    renderTop,
  });

  const cells = rows * columns;
  const topOffset = renderTop ? 1 : 0;
  const leftOffset = renderTop ? 1 : 0;

  return (
    <div ref={rootRef} className={classes.root}>
      <style>{'html,body{overscroll-behavior-x:none;}'}</style>
      <div className={clsx(classes.container, props.classes?.container)}>
        <div className={clsx(classes.grid, props.classes?.grid)} style={gridStyle}>
          {renderTop && renderLeft && (
            <div className={classes.corner}>
              {renderCorner && (
                <Raft anchorClassName={classes.cornerContainer} className={classes.cornerContainerRaft}>
                  {renderCorner()}
                </Raft>
              )}
            </div>
          )}

          {renderTop &&
            Array.from({ length: columns / (props?.classes?.topCellSpan || 1) }).map((_, column) => (
              <TableCellTop
                key={column}
                span={props?.classes?.topCellSpan || 1}
                column={column}
                leftOffset={leftOffset}
                className={props.classes?.top}
              >
                {renderTop}
              </TableCellTop>
            ))}

          {renderLeft &&
            Array.from({ length: rows }).map((_, row) => (
              <TableCellLeft
                key={row}
                row={row}
                topOffset={topOffset}
                leftOffset={leftOffset - 1}
                className={props.classes?.left}
              >
                {renderLeft}
              </TableCellLeft>
            ))}

          {renderCell &&
            Array.from({ length: cells }).map((_, cell) => {
              const row = Math.floor(cell / columns);
              const column = cell % columns;

              return (
                <TableCell
                  key={cell}
                  row={row}
                  column={column}
                  topOffset={topOffset}
                  leftOffset={leftOffset}
                  className={props.classes?.cell}
                >
                  {renderCell}
                </TableCell>
              );
            })}

          <div className={classes.bottomBorder} />
          <div className={classes.rightBorder} />

          {children ?? null}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles<DefaultTheme, Pick<TableProps, 'firstColumn' | 'rows' | 'columns' | 'renderTop'>>(
  theme => ({
    root: {
      flex: 1,
      position: 'relative',
    },
    container: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      overflow: 'auto',
      display: 'flex',
      // alignItems: 'center',
      justifyContent: 'start',
    },
    grid: {
      display: 'grid',
      gridTemplateRows: ({ rows }) => `2em repeat(${rows}, 2em)`,
      gridTemplateColumns: ({ firstColumn, columns }) =>
        `${firstColumn ? `${firstColumn}px` : '5em'} repeat(${columns}, 5em)`,
      width: 'min-content',
      // margin: 'auto',
      borderRight: '1px solid transparent',
      borderBottom: '1px solid transparent',
    },
    corner: {
      position: 'sticky',
      top: 0,
      left: 0,
      backgroundColor: 'white',
      zIndex: 20,
      gridRow: '1 / span 1',
      gridColumn: '1 / span 1',
      borderBottom: `2px solid ${theme.palette.divider}`,
      borderRight: `2px solid ${theme.palette.divider}`,
    },
    cornerContainer: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    cornerContainerRaft: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'end',
    },
    bottomBorder: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      gridRow: ({ rows }) => `${rows + 1} / span 1`,
      gridColumn: ({ columns }) => `1 / span ${columns + 1}`,
      position: 'sticky',
      bottom: 1,
      zIndex: 10,
      transform: 'translateY(1px)',
      pointerEvents: 'none',
    },
    rightBorder: {
      borderRight: `1px solid ${theme.palette.divider}`,
      gridRow: ({ rows }) => `1 / span ${rows + 1}`,
      gridColumn: ({ columns }) => `${columns + 1} / span 1`,
      position: 'sticky',
      right: 1,
      zIndex: 10,
      transform: 'translateX(1px)',
      pointerEvents: 'none',
    },
  })
);

export default Table;
