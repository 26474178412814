import { merge } from 'lodash';
import { Theme } from '@material-ui/core';
import Chip from './Chip';
import Fab from './Fab';
import FormLabel from './FormLabel';
import FormHelperText from './FormHelperText';
import CssBaseline from './CssBaseline';
import Button from './Button';
import FilledInput from './FilledInput';
import ToggleButton from './ToggleButton';
import StepIcon from './StepIcon';
import StepConnector from './StepConnector';
import StepLabel from './StepLabel';

// ----------------------------------------------------------------------

export default function componentsOverrides(theme: Theme) {
  return merge(
    Button(theme),
    CssBaseline(theme),
    Chip(theme),
    Fab(theme),
    FilledInput(theme),
    FormLabel(theme),
    FormHelperText(theme),
    ToggleButton(theme),
    StepIcon(theme),
    StepConnector(theme),
    StepLabel(theme)
  );
}
