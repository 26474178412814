import React, { VFC } from 'react';
import { Box, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dates from './types/Dates';
import { StaffAvailability } from '../../../types/StaffAvailability';
import ImmutableSet from './types/ImmutableSet';
import StaffAvailabilityGroupEditor from './StaffAvailabilityGroupEditor';
import useDebouncedMemo from '../../../hooks/useDebouncedMemo';
import ActionMenu from './ActionMenu';
import groupStaffAvailability, {
  AnonymousStaffAvailability,
  StaffAvailabilityGroup,
} from './util/groupStaffAvailability';
import groupTitle from './util/groupTitle';
import classNames from 'clsx';

export const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    padding: '8px',
    position: 'fixed',
    right: '50px',
    height: '80vh',
    maxWidth: '300px',
    width: '100%',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      right: '20px',
      maxWidth: '200px',
    },
    [theme.breakpoints.down('sm')]: {
      right: 0,
      maxWidth: '150px',
    },
  },
  boxHeader: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface StaffAvailabilityEditorProps {
  selectedDates: Dates;
  setSelectedDates: (datesOrFn: Dates | ((prev: Dates) => Dates)) => void;
  highlightedDates: Dates;
  setHighlightedDates: (datesOrFn: Dates | ((prev: Dates) => Dates)) => void;
  staffAvailabilities: AnonymousStaffAvailability[];
  createStaffAvailabilities: (
    dates: Dates,
    staffAvailability: Omit<StaffAvailability<Date>, 'id' | 'staffId' | 'date'>
  ) => Promise<void>;
  deleteStaffAvailabilities: (ids: ImmutableSet<StaffAvailability['id']>) => Promise<void>;
  staffId: number;
}
export const DateAvailabilityRange = ({ dates }: { dates: Dates }) => {
  const formattedDates = groupTitle(dates);
  return (
    <Box flex={1}>
      {formattedDates.map((dateRange: string, index: number) => (
        <Typography key={index}>{dateRange}</Typography>
      ))}
    </Box>
  );
};

const StaffAvailabilityEditor: VFC<StaffAvailabilityEditorProps> = ({
  selectedDates,
  setSelectedDates,
  highlightedDates,
  setHighlightedDates,
  staffAvailabilities,
  createStaffAvailabilities,
  deleteStaffAvailabilities,
  staffId,
}) => {
  const classes = useStyles();

  const [availabilities, dateGroups]: [AnonymousStaffAvailability[], StaffAvailabilityGroup[]] = useDebouncedMemo(
    () => groupStaffAvailability(staffAvailabilities, selectedDates),
    [[], []],
    700,
    [selectedDates, staffAvailabilities]
  );

  return (
    <div className={classNames(classes.drawer)}>
      <Box className={classes.boxHeader}>
        <DateAvailabilityRange dates={selectedDates} />
        <Box>
          <ActionMenu
            actions={[
              ['Deselect All Dates', () => setSelectedDates(Dates.empty)],
              availabilities.length > 0 && [
                'Remove All Entries',
                () => {
                  deleteStaffAvailabilities(ImmutableSet.from(availabilities.map(a => a.id)));
                },
              ],
            ]}
          />
        </Box>
      </Box>
      {dateGroups.length === 1 ? (
        <StaffAvailabilityGroupEditor
          {...dateGroups[0]}
          setSelectedDates={setSelectedDates}
          setHighlightedDates={setHighlightedDates}
          createStaffAvailabilities={createStaffAvailabilities}
          deleteStaffAvailabilities={deleteStaffAvailabilities}
          staffId={staffId}
        />
      ) : (
        dateGroups.map((dateGroup, i) => (
          <StaffAvailabilityGroupEditor
            key={i}
            {...dateGroup}
            setSelectedDates={setSelectedDates}
            setHighlightedDates={setHighlightedDates}
            createStaffAvailabilities={createStaffAvailabilities}
            deleteStaffAvailabilities={deleteStaffAvailabilities}
            staffId={staffId}
          />
        ))
      )}
    </div>
  );
};

export default StaffAvailabilityEditor;
