import { ApolloClient, useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { fragments } from '../../../graph/patients';
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Source } from '../../../components/OmniSearch';
import * as H from 'history';
import { format } from 'date-fns';

interface Patient {
  id: number;
  hstId?: number;
  name?: string;
  braceletId?: string;
  notificationNumbers?: string;
  procedure: {
    id: number;
    patientDateOfBirth?: string;
    startTime?: string;
  };
  procedureType?: {
    id: number;
    name?: string;
  };
  physician?: {
    id: number;
    hstId?: string;
    name?: string;
  };
  room?: {
    id: number;
    name?: string;
    type?: string;
  };
  status?: string;
  phoneNumber?: string;
  bed?: string;
  icon?: string;
  color?: string;
  createdAt?: string;
  changedRoomAt?: string;
  dischargedAt?: string;
  voidedAt?: string;
  note?: string;
  caretakerConsent?: string;
  caretakerMessages?: {
    phoneNumber?: string;
    content?: string;
  };
}

const activeAndPastProceduresSource: Source<Patient> = {
  name: 'Patient',
  useSource: (input: string, client: ApolloClient<any>) => {
    const { data, loading, error } = useQuery<{ searchActiveAndPastProcedures?: Patient[] }, { search: string }>(
      gql`
        query searchActiveAndPastProcedures($search: String!) {
          searchActiveAndPastProcedures(search: $search) {
            ...PatientData
            procedure {
              id
              patientDateOfBirth
            }
          }
        }
        ${fragments.all}
      `,
      {
        client,
        variables: {
          search: input,
        },
      }
    );

    return {
      data: data?.searchActiveAndPastProcedures ?? [],
      loading,
      error,
    };
  },
  noOptionsText: (input: string) => (input.length > 0 ? 'No patients found.' : 'Start typing to search patients.'),
  renderOption: (patient: Patient, { selected: boolean }) => {
    const secondaryText = [
      patient.procedure?.startTime
        ? format(patient.procedure?.startTime, 'MM/DD/YYYY HH:mm')
        : patient.createdAt
        ? format(patient.createdAt, 'MM/DD/YYYY HH:mm')
        : 'No date of service',
      patient.physician?.name || 'No physician',
    ].join(' • ');

    return (
      <Box py={1} display="flex" alignItems="center">
        <Box>
          <Box display="flex" alignItems="baseline">
            <Typography>{patient.name || '-'}</Typography>
            {patient.procedure?.patientDateOfBirth && (
              <Box ml={1}>
                <Typography variant="body2" color="textSecondary">
                  DOB: {patient.procedure.patientDateOfBirth}
                </Typography>
              </Box>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant="body2" color="textSecondary">
              {secondaryText}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  },
  getOptionDisabled: (option: Patient) => false,
  getOptionLabel: (option: Patient) => option.name ?? '-',
  onSelect: (option: Patient, location: H.Location, history: H.History) => {
    const matchesCharting = location.pathname.match(/\/kiosk\/(\d+)\/charting(\/\w+)?/);

    if (matchesCharting) {
      history.push(`${matchesCharting[0]}/${option.id}`);
    } else {
      const prefix = (location.pathname.match(/\/su\/(\d+)/) ?? [''])[0];
      history.push(`${prefix}/patients/${option.id}`);
    }
  },
};

export default activeAndPastProceduresSource;
