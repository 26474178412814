import React, { FC, useContext, useState } from 'react';
import { format } from 'date-fns';
import TableWithColumnSelector, { OnClickFn, TableType } from '../../core/TableWithColumnSelector';
import { useQuery } from '@apollo/client';
import { ORUtilizationAnalyticsQuery, ORUtilizationAnalyticsTotalsQuery } from '../../../graph/dashboard';
import { getMinutes } from './util';
import { ORUtilizationTableData, ORUtilizationTableTotalsData } from '../../../types/Analytics';
import Button from '@material-ui/core/Button';
import { ArrowBack } from '@material-ui/icons';
import ORPatientsUtilizationAnalytics from './ORPatientsUtilizationAnalytics';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import PieCharts from './v2/PieCharts';
import { PieChartData } from './v2/PieChart';
import { useScope } from '../../../hooks/useScope';
import { Aggregation, AggregationContext } from './Aggregation';

const toTableData = (
  data: ORUtilizationTableData[],
  totals: ORUtilizationTableTotalsData,
  onClick?: OnClickFn,
  hasPostOp?: boolean
): TableType => {
  const aggregation = useContext(AggregationContext);
  const aggregationTitle = aggregation === 'average' ? 'Avg.' : 'Med.';
  const postOpHeader = hasPostOp ? [`POSTOP ${aggregationTitle}`] : [];
  const baseHeaders = [
    'Room',
    'Procedures',
    'Surgery Days',
    'Daily Utilization',
    'Utilization %',
    `Cleaning ${aggregationTitle}`,
    `Turnover ${aggregationTitle}`,
    `WR ${aggregationTitle}`,
    `PREOP ${aggregationTitle}`,
    `PACU ${aggregationTitle}`,
    ...postOpHeader,
    `PREOP to Discharged ${aggregationTitle}`,
  ];

  const pipoUtil = totals?.pipoUtilization?.toFixed(2) ? `${totals?.pipoUtilization?.toFixed(2)}%` : '-';
  const total = [
    totals?.room || 'Total',
    totals?.proceduresCnt?.toString() || '-',
    totals?.surgeryDays?.toString() || '-',
    getMinutes(aggregation === 'average' ? totals?.pipoAvg : totals?.pipoMed),
    pipoUtil,
    getMinutes(aggregation === 'average' ? totals?.cleaningAvg : totals?.cleaningMed),
    getMinutes(aggregation === 'average' ? totals?.popiAvg : totals?.popiMed),
    getMinutes(aggregation === 'average' ? totals?.wrAvg : totals?.wrMed),
    getMinutes(aggregation === 'average' ? totals?.preopAvg : totals?.preopMed),
    getMinutes(aggregation === 'average' ? totals?.pacuAvg : totals?.pacuMed),
    ...(hasPostOp ? [getMinutes(aggregation === 'average' ? totals?.postOpAvg : totals?.postOpMed)] : []),
    getMinutes(aggregation === 'average' ? totals?.preOpToDischargedAvg : totals?.preOpToDischargedMed),
  ];

  const totalRow = {
    id: 'total',
    columns: total,
  };

  return {
    configHeader: [...baseHeaders],
    headers: [
      {
        id: 'h1',
        columns: [
          'Room',
          'Procedures',
          'Surgery Days',
          {
            text: 'Daily Utilization',
            align: 'right',
            InfoComponent: () => (
              <Box p={2} width="30ch">
                <Typography variant="body2" color="textSecondary">
                  {aggregation === 'average' ? 'Average' : 'Median'} Operation room time defined as wheels-in to
                  wheels-out.
                </Typography>
              </Box>
            ),
          },
          {
            text: 'Utilization %',
            align: 'right',
            InfoComponent: () => (
              <Box p={2} width="30ch">
                <Typography variant="body2" color="textSecondary">
                  The Operating room utilization is calculated by dividing the Total Actual Operating time by the Total
                  Available Operating time.
                </Typography>
              </Box>
            ),
          },
          `Cleaning ${aggregationTitle}`,
          {
            text: `Turnover ${aggregationTitle}`,
            align: 'right',
            InfoComponent: () => (
              <Box p={2} width="30ch">
                <Typography variant="body2" color="textSecondary">
                  Calculated as wheels-out to wheels-in.
                </Typography>
              </Box>
            ),
          },
          `WR ${aggregationTitle}`,
          `PREOP ${aggregationTitle}`,
          `PACU ${aggregationTitle}`,
          ...postOpHeader,
          `PREOP to Discharged ${aggregationTitle}`,
        ],
      },
    ],
    rows: (data || [])
      .map(e => ({
        id: e?.id.toString(),
        columns: [
          e.room,
          e.proceduresCnt.toString(),
          e.surgeryDays.toString(),
          getMinutes(aggregation === 'average' ? e.pipoAvg : e.pipoMed),
          `${e.pipoUtilization.toFixed(2)}%`,
          getMinutes(aggregation === 'average' ? e.cleaningAvg : e.cleaningMed),
          getMinutes(aggregation === 'average' ? e.popiAvg : e.popiMed),
          getMinutes(aggregation === 'average' ? e.wrAvg : e.wrMed),
          getMinutes(aggregation === 'average' ? e.preopAvg : e.preopMed),
          getMinutes(aggregation === 'average' ? e.pacuAvg : e.pacuMed),
          ...(hasPostOp ? [getMinutes(aggregation === 'average' ? e.postOpAvg : e.postOpMed)] : []),
          getMinutes(aggregation === 'average' ? e.preOpToDischargedAvg : e.preOpToDischargedMed),
        ],
        onClick,
      }))
      .concat([totalRow]),
  };
};

export const toPieChartData = (
  data: ORUtilizationTableData[],
  totals: ORUtilizationTableTotalsData | null,
  aggregation: Aggregation
): PieChartData[] => {
  const allORs = data.map(d => ({
    title: d.room,
    subtitle: isFinite(d.proceduresCnt)
      ? d.proceduresCnt === 1
        ? '1 procedure'
        : `${d.proceduresCnt} procedures`
      : '-',
    inOr: aggregation === 'average' ? d.inOrAvg : d.inOrMed,
    inSurgery: aggregation === 'average' ? d.inSurgeryAvg : d.inSurgeryMed,
    closing: aggregation === 'average' ? d.closingAvg : d.closingMed,
    procedureComplete: aggregation === 'average' ? d.procedureCompleteAvg : d.procedureCompleteMed,
    cleaning: aggregation === 'average' ? d.cleaningAvg : d.cleaningMed,
    orReady: aggregation === 'average' ? d.orReadyAvg : d.orReadyMed,
    turnover: aggregation === 'average' ? d.popiAvg : d.popiMed,
    pipoUtilization: d.pipoUtilization,
  }));

  return totals
    ? [
        ...allORs,
        {
          title: 'Total',
          subtitle: isFinite(totals.proceduresCnt)
            ? totals.proceduresCnt === 1
              ? '1 procedure'
              : `${totals.proceduresCnt} procedures`
            : '-',
          inOr: totals.inOrAvg,
          inSurgery: totals.inSurgeryAvg,
          closing: totals.closingAvg,
          procedureComplete: totals.procedureCompleteAvg,
          cleaning: totals.cleaningAvg,
          orReady: totals.orReadyAvg,
          turnover: totals.popiAvg,
          pipoUtilization: totals.pipoUtilization,
        },
      ]
    : allORs;
};

const ORUtilizationAnalytics: FC<{ filter: any; setFilter: any }> = ({ filter, setFilter }) => {
  const aggregation = useContext(AggregationContext);
  const dateRangeJSON = filter?.dateRange?.toJSON();
  const dateRange = { from: format(dateRangeJSON.from, 'YYYY-MM-DD'), to: format(dateRangeJSON.to, 'YYYY-MM-DD') };

  const scope = useScope();
  const hasPostOp = scope?.hospital?.modules?.hasPostop;
  const analyticsV2PieCharts = scope?.hospital?.modules?.analyticsV2PieCharts || false;
  const { data, loading } = useQuery(ORUtilizationAnalyticsQuery, {
    variables: { filter: filter },

    fetchPolicy: 'cache-and-network',
  });

  const { data: totalsData, loading: totalsLoading } = useQuery(ORUtilizationAnalyticsTotalsQuery, {
    variables: { filter: filter },
    fetchPolicy: 'cache-and-network',
  });

  const [orId, setOrId] = useState<{
    idNumber: number;
    idString: string;
  } | null>(null);

  const onOrClick = (orId: string) => {
    const id = orId?.split('#')?.[0];
    setOrId({ idNumber: Number(id), idString: orId });
  };

  const table: TableType = toTableData(
    data?.ORUtilizationAnalytics || [],
    totalsData?.ORUtilizationAnalyticsTotals || [],
    onOrClick,
    hasPostOp
  );

  const onBack = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOrId(null);
  };

  if (orId) {
    return (
      <>
        <Button startIcon={<ArrowBack />} onClick={onBack}>
          Back to OR Utilization Analytics
        </Button>
        <ORPatientsUtilizationAnalytics
          orId={orId.idNumber}
          orName={(data?.ORUtilizationAnalytics || []).find(e => e?.id === orId.idString)?.room}
          dateRange={dateRange}
          filter={filter}
        />
      </>
    );
  }

  if (loading || totalsLoading) {
    return (
      <Box display="flex" alignItems="center" m={2} style={{ gap: '1em' }}>
        <CircularProgress size={30} />
        <Typography variant="body1" color="textSecondary">
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {analyticsV2PieCharts && (
        <PieCharts
          data={toPieChartData(
            data?.ORUtilizationAnalytics || [],
            totalsData?.ORUtilizationAnalyticsTotals || [],
            aggregation
          )}
        />
      )}
      <TableWithColumnSelector
        configName="or-analysis-table-config"
        tableId="OR-Analysis-table"
        tableName={`Analysis by OR`}
        excelFileName={`OR-Analysis-${format(dateRange.from, 'MM/DD/YYYY')}-${format(dateRange.to, 'MM/DD/YYYY')}`}
        {...table}
      />
    </>
  );
};

export default ORUtilizationAnalytics;
