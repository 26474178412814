import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import ModalWithActions from '../../ModalWithActions';
import Box from '@material-ui/core/Box';
import { TextField, Typography } from '@material-ui/core';
import isFinite from 'lodash/isFinite';
import { format } from 'date-fns';
import get from 'lodash/get';

interface BookDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onConfirmAction: (input: number) => () => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
  title?: string;
  confirmActionLabel?: string;
  data: {
    dateOfService?: string;
    externalProvider?: {
      id: string;
      provider: string;
    };
  };
}

const BookingDialog = ({
  open = false,
  setOpen,
  onConfirmAction,
  onCancel,
  title = 'Book Procedure',
  confirmActionLabel = 'Confirm',
  data: { dateOfService, externalProvider },
}: BookDialogProps) => {
  const patientProviderId = get(externalProvider, 'id');
  const [input, setInput] = useState<string | undefined>(patientProviderId);

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const handleConfirmAction = () => {
    input && onConfirmAction(parseInt(input, 10))();
    setOpen(false);
  };

  const handleCancel = () => {
    onCancel?.();
    setOpen(false);
  };

  return (
    <ModalWithActions
      open={open}
      handleCancel={handleCancel}
      handleConfirmAction={handleConfirmAction}
      confirmActionLabel={confirmActionLabel}
      title={title}
      disabled={input ? !isFinite(parseInt(input, 10)) : true}
    >
      <Box display="flex" flexDirection="column">
        <Typography color="textSecondary">Date and Time</Typography>
        {dateOfService && <Typography>{format(dateOfService, 'MM/DD/YYYY · HH:mm ')}</Typography>}
        <TextField label="HST ID" value={input} onChange={onChange} type="number" />
      </Box>
    </ModalWithActions>
  );
};

export default BookingDialog;
