import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import React from 'react';
import Paper from '@material-ui/core/Paper';

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      component={Paper}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      flex={1}
      p={4}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export function a11yProps(index) {
  return {
    id: `scrollable-auto-tabpanel-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
