import { compose, mapProps, withProps } from 'recompose';
import SelectInput from '../../../se/components/inputs/SelectInput';
import { ROOM_TYPE_LABELS } from '../../entities/room/enums';
import omit from 'lodash/omit';
import { withScope } from '../../../contexts/ScopeContext';

export default compose(
  withScope,
  mapProps(({ scope, ...props }) => {
    const hasInpatients = scope?.hospital?.modules?.hasPostop;

    return hasInpatients
      ? { options: ROOM_TYPE_LABELS, ...props }
      : { options: omit(ROOM_TYPE_LABELS, 'POST_OP'), ...props };
  }),
  withProps({
    placeholder: 'e.g., OR',
    required: true,
  })
)(SelectInput);
