export const WAITING_ROOM = 'WAITING_ROOM';
export const PRE_OP = 'PRE_OP';
export const OR = 'OR';
export const PACU = 'PACU';
export const UTILITY = 'UTILITY';
export const POST_OP = 'POST_OP';

export const ROOM_TYPES = {
  WAITING_ROOM,
  PRE_OP,
  OR,
  PACU,
  UTILITY,
  POST_OP,
};

export const ROOM_TYPE_LABELS = {
  WAITING_ROOM: 'Waiting Room',
  PRE_OP: 'PRE-OP',
  OR: 'OR',
  PACU: 'Recovery',
  UTILITY: 'Utility',
  POST_OP: 'POST-OP',
};

export const PATIENT_JOURNEY_LABELS = {
  WAITING_ROOM: 'Admitted',
  PRE_OP: 'PreOp',
  OR: 'Surgery',
  PACU: 'Recovery',
  POST_OP: 'PostOp',
};

export const STATUSES = {
  VACANT: 'Vacant',
  CLEANING: 'Cleaning',
};

export const STATUS_LABELS = {
  [STATUSES.CLEANING]: 'Cleaning',
  [STATUSES.VACANT]: 'Vacant',
};

export const GATEWAY_TYPES = {
  GATEWAY: 'GATEWAY',
  ENROLMENT_READER: 'ENROLMENT_READER',
  DISCHARGE_GATEWAY: 'DISCHARGE_GATEWAY',
  EXIT_GATEWAY: 'EXIT_GATEWAY',
};

export const GATEWAY_TYPE_LABELS = {
  GATEWAY: 'Gateway',
  ENROLMENT_READER: 'Enrolment Reader',
  DISCHARGE_GATEWAY: 'Discharge Gateway',
  EXIT_GATEWAY: 'Exit Gateway',
};
