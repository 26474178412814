import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { compose, withProps } from 'recompose';

import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import EntityEmptyState from '../../../se/components/entity-states/EntityEmptyState';

import get from 'lodash/get';
import { createdProcedureColumns, listColumns, uploadedProcedureColumns } from './columns';
import schema, { procedureStatistics } from '../../../graph/procedures';
import EntityView from '../../../se/components/entity/EntityView';
import QuestionnaireAnswers from './QuestionnaireAnswers';
import Filters from '../../pages/analytics/Filters';
import ProcedureEventLog, {
  filterNonIntakeEvents,
  procedureStatuseLabels,
  procedureStatuses,
} from './ProcedureEventLog';
import ProcedureInput from './ProcedureInput';
import { withTheme } from '../../../se/theme';
import ScheduledProcedureMobileListItem from './components/ScheduledProcedureMobileListItem';
import PatientVisits from './PatientVisits';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { SurgeryInstructions } from './instructions/SurgeryInstructions';
import { withSession } from '../../../state/Session';
import { unpackSessionObject } from '../../pages/unpackSessionObject';
import { RenderTitle } from './RenderTitle';
import { a11yProps, TabPanel } from './TabPanel';
import { DashboardFilter } from './DashboardFilter';
import useCareAppAccess from './utils/useCareAppAccess';
import Badge from '@material-ui/core/Badge';
import { NamedRange } from '../../core/DatePicker';
import scheduledProceduresSource from '../../../omnisearch/sources/hospital/scheduledProcedures';
import pick from 'lodash/fp/pick';
import Forms from './Forms';
import { useQuery } from '@apollo/client';
import { procedureForms } from '../../../graph/surgeon/forms';
import { DescriptionTwoTone } from '@material-ui/icons';
import ProcedureNotes from './ProcedureNotes';
import { getLastProcedureEvent } from '../../../util/procedureEvents';
import { ProcedureStatus } from '../../../types/ProcedureEvent';
import { FormType } from '../../../types/Form';
import FileUploaderPreOp from '../../FileUploaderPreOp';

import PatientFileGenerator from '../patient/views/PatientFileGenerator';
import { useScope } from '../../../hooks/useScope';
import { withScope } from '../../../contexts/ScopeContext';

const Empty = withProps({
  title: `There are no scheduled procedures at the moment.`,
  hint: '',
  illustration: withTheme(theme => get(theme, 'illustration.patients')),
})(EntityEmptyState);

function tabSwitcher(state, action) {
  if (state.preopComplete) {
    switch (action.type) {
      case 'init':
        return { ...state, switchToTab: 1 };
      case 'formsExists':
        return state.formsLoaded
          ? state
          : action.formsExists
          ? { ...state, switchToTab: 2, formsLoaded: true }
          : { ...state, formsLoaded: true };
      default:
        return state;
    }
  } else {
    return state;
  }
}

const ProcedureAndQuestionnaire = props => {
  const { scope, accessToken, data } = props;
  useCareAppAccess(get(scope, 'hospital.id'), accessToken);
  const procedureId = data?.id;
  const notes = (data?.events || []).filter(event => event.type === 'Note');
  const notesCount = notes ? notes.length : 0;
  const procedureEvents = (data?.events || []).filter(event => event.type !== 'Note');
  const lastPreOpCompletedEvent = getLastProcedureEvent(data?.events || [], ProcedureStatus.PreOpCompleted);
  const questionnaireVersion = props?.data?.entryQuestionnaire?.questionnaireVersion;

  const {
    data: queryData,
    loading,
    error,
  } = useQuery(procedureForms, { variables: { procedureId, formType: FormType.PreOpForm } });
  const forms = (queryData?.procedureForms || []).filter(form =>
    form?.content?.includes('medicalPassport')
      ? (questionnaireVersion === 1 && form?.content === 'medicalPassport') ||
        `medicalPassport${questionnaireVersion}` === form?.content
      : true
  );

  const formsExists = queryData?.procedureForms?.length > 0;

  const [tab, setTab] = useState(null);
  const tabNumber = tab || 0;

  const [{ switchToTab }, dispatch] = useReducer(tabSwitcher, {
    preopComplete: get(props, 'data.status') === procedureStatuses.preopCompleted,
  });

  useEffect(() => {
    dispatch({ type: 'init' });
  }, []);

  useEffect(() => {
    if (!loading && !error) {
      dispatch({ type: 'formsExists', formsExists });
    }
  }, [loading, error, formsExists]);

  useEffect(() => {
    if (switchToTab) {
      setTab(switchToTab);
    }
  }, [switchToTab]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Fragment>
      <EntityView {...props} />
      {procedureId && <FileUploaderPreOp procedureId={procedureId} />}
      {formsExists && (
        <Box mt={6} minWidth={0}>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr .5fr' }}>
            <Tabs indicatorColor={'primary'} value={tabNumber} onChange={handleChange} aria-label="PerOp Patient Tabs">
              <Tab label="PreOp Questionnaire" {...a11yProps(0)} />
              <Tab label="Forms" {...a11yProps(1)} />
              <Tab label="Instructions" {...a11yProps(2)} />
              <Tab label="Patient Visits" {...a11yProps(3)} />
              <Tab
                label={
                  <Badge invisible={notesCount < 1} badgeContent={notesCount} color="primary">
                    PreOp Notes&nbsp;&nbsp;
                  </Badge>
                }
                {...a11yProps(4)}
              />
              <Tab label="Procedure Events" {...a11yProps(5)} />
            </Tabs>
            {/* {procedureId && <PatientFileGenerator procedureId={procedureId} />} */}
          </div>
          <TabPanel value={tabNumber} index={0}>
            <QuestionnaireAnswers
              entryQuestionnaire={get(props, 'data.entryQuestionnaire')}
              pacuCharts={get(props, 'data.pacuCharts')}
              procedure={get(props, 'data', {})}
            />
          </TabPanel>
          <TabPanel value={tabNumber} index={1}>
            <Forms procedureId={procedureId} forms={forms} lastPreOpCompletedEvent={lastPreOpCompletedEvent} />
          </TabPanel>
          <TabPanel value={tabNumber} index={2}>
            <SurgeryInstructions data={get(props, 'data', {})} />
          </TabPanel>
          <TabPanel value={tabNumber} index={3}>
            <PatientVisits
              visits={props?.data?.visits}
              currentVisit={props?.data?.id}
              baseUrlProvider={props.baseUrlProvider}
            />
          </TabPanel>
          <TabPanel value={tabNumber} index={4}>
            <ProcedureNotes procedureId={procedureId} procedureNotes={notes} />
          </TabPanel>
          <TabPanel value={tabNumber} index={5}>
            <ProcedureEventLog events={procedureEvents} name="Procedure Events" filterEvents={filterNonIntakeEvents} />
          </TabPanel>
        </Box>
      )}
      {!formsExists && (
        <Box mt={6}>
          <Tabs indicatorColor={'primary'} value={tabNumber} onChange={handleChange} aria-label="PerOp Patient Tabs">
            <Tab label="PreOp Questionnaire" {...a11yProps(0)} />
            <Tab label="Instructions" {...a11yProps(1)} />
            <Tab label="Patient Visits" {...a11yProps(2)} />
            <Tab
              label={
                <Badge invisible={notesCount < 1} badgeContent={notesCount} color="primary">
                  PreOp Notes&nbsp;&nbsp;
                </Badge>
              }
              {...a11yProps(3)}
            />
            <Tab label="Procedure Events" {...a11yProps(4)} />
          </Tabs>
          <TabPanel value={tabNumber} index={0}>
            <QuestionnaireAnswers
              entryQuestionnaire={get(props, 'data.entryQuestionnaire')}
              pacuCharts={get(props, 'data.pacuCharts')}
              procedure={get(props, 'data', {})}
            />
          </TabPanel>
          <TabPanel value={tabNumber} index={1}>
            <SurgeryInstructions data={get(props, 'data', {})} />
          </TabPanel>
          <TabPanel value={tabNumber} index={2}>
            <PatientVisits
              visits={get(props, 'data.visits')}
              currentVisit={get(props, 'data.id')}
              baseUrlProvider={props.baseUrlProvider}
            />
          </TabPanel>
          <TabPanel value={tabNumber} index={3}>
            <ProcedureNotes procedureId={procedureId} procedureNotes={notes} />
          </TabPanel>
          <TabPanel value={tabNumber} index={4}>
            <ProcedureEventLog events={procedureEvents} name="Procedure Events" filterEvents={filterNonIntakeEvents} />
          </TabPanel>
        </Box>
      )}
    </Fragment>
  );
};

const CustomFilter = withProps({
  textSearchKey: 'name',
  hideProcedureStatusSelectInput: false,
  hideCategorySelectInput: false,
  hideSpecialitySelectInput: false,
  hideProcedureTypeSelectInput: true,
  futurePicker: true,
  defaultValue: {
    dateRange: NamedRange.next30Days(),
  },
})(Filters);

const CustomDashboardFilter = withProps({
  subscription: procedureStatistics,
  CustomFilter: CustomFilter,
  items: [
    {
      title: 'Procedures Scheduled',
      getter: data => get(data, 'procedureStatistics.scheduledProcedures', '-'),
      icon: <DescriptionTwoTone />,
    },
    {
      title: 'Patient Submitted',
      getter: data => get(data, 'procedureStatistics.totalForms', '-'),
      icon: <DescriptionTwoTone />,
    },
    {
      title: 'PreOp Complete',
      getter: data => get(data, 'procedureStatistics.preOpCompleted', '-'),
      icon: <DescriptionTwoTone />,
    },
    {
      title: 'Instructions Accepted',
      getter: data => get(data, 'procedureStatistics.instructionsAccepted', '-'),
      icon: <DescriptionTwoTone />,
    },
  ],
})(DashboardFilter);

const ScheduledProceduresCRUD = showColumns =>
  GraphQLCRUD({
    entityName: 'ScheduledProcedure',
    scheme: { ...schema, list: schema.scheduledProceduresSubscription, item: schema.scheduledProcedure },
    List: {
      tableKey: 'ScheduledProcedure',
      MobileItemComponent: ScheduledProcedureMobileListItem,
      useColumnSelection: true,
      columns: listColumns,
      Empty,
      FilterComponent: CustomDashboardFilter,
      FilterPrefix: 'sc',
      defaultFilterValues: {
        dateRange: NamedRange.next30Days(),
      },
      pickFilter: pick([
        'name',
        'physician',
        'procedureType',
        'hospital',
        'status',
        'dateRange',
        'category',
        'speciality',
        'view',
      ]),
    },
    Show: {
      columns: showColumns,
      View: compose(withSession(unpackSessionObject), withScope)(ProcedureAndQuestionnaire),
      Empty,
      Title: withProps({ procedureStatuses: procedureStatuseLabels })(RenderTitle),
    },
    Edit: {
      Input: ProcedureInput,
      skipRefetch: true,
      mapEditItemProps: ({ data }) => ({
        data: {
          ...data,
          scheduledProcedure: {
            ...data?.scheduledProcedure,
            patientName: data?.scheduledProcedure?.patientNameUnformatted,
            patientDateOfBirth: data?.scheduledProcedure?.patientDateOfBirthISO,
          },
        },
      }),
    },
    searchSource: scheduledProceduresSource,
  });

const ScheduledProcedures = props => {
  const scope = useScope();
  const createProcedureSchedule = scope?.hospital?.modules?.createProcedureSchedule;
  const CRUDComponent = ScheduledProceduresCRUD(
    createProcedureSchedule ? createdProcedureColumns : uploadedProcedureColumns
  );
  return <CRUDComponent {...props} />;
};

export default ScheduledProcedures;
