import { Subscription } from '@apollo/client/react/components';
import React, { Fragment } from 'react';
import TitleAction from '../../../se/components/TitleAction';
import { DefaultTitle } from '../../../se/components/entity/EntityRouter';
import { listUnassignedGateways } from '../../../graph/gateways';
import CollectionList from '../../../se/components/collection/CollectionList';
import { mapProps } from 'recompose';
import EntityEmptyState from '../../../se/components/entity-states/EntityEmptyState';
import { TruncatedText } from '../../../se/components/typography';
import { distanceAgo } from '../../pages/gateway/Gateways';
import HospitalAssignForm from './HospitalAssignForm';
import { withTheme } from '@material-ui/core';

const Empty = withTheme(
  mapProps(({ theme, ...props }) => ({
    title: `No gateway needs routing.`,
    hint: 'This view will refresh as soon as there are gateways without designated hospital.',
    ...props,
    illustration: theme.illustrations.waiting,
  }))(EntityEmptyState)
);

const columns = [
  {
    title: 'Mac Address',
    lens: data => data.id,
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  {
    title: 'GW Last Seen',
    lens: data => data,
    Component: ({ data }) => (
      <TruncatedText style={{ width: '9em' }}>
        {data ? `${distanceAgo(data.serverTime, data.lastSeenAt)} ago` : 'Never'}
      </TruncatedText>
    ),
  },
  {
    title: 'Hospital',
    lens: data => data,
    Component: ({ data }) => <HospitalAssignForm organizationId={data.organizationId} gatewayId={data.id} />,
  },
];

export default () => (
  <Fragment>
    <Subscription subscription={listUnassignedGateways}>
      {({ data }) => (
        <CollectionList
          idProvider={item => item.id}
          Empty={Empty}
          entityName="Gateway"
          tableKey="Gateway Routing"
          columns={columns}
          data={data}
          getList={data => data['unassociatedGateways']}
          TitleAndActions={() => <TitleAction Title={() => <DefaultTitle>Gateway Routing</DefaultTitle>} />}
          history={{}}
          location={{}}
        />
      )}
    </Subscription>
  </Fragment>
);
