export const surgeonProcedureStatuses = {
  Consultation: 'Consultation',
  ConsultationConfirmed: 'ConsultationConfirmed',
  ConsultationNeedsReschedule: 'ConsultationNeedsReschedule',
  ConsultationComplete: 'ConsultationComplete',
  ConsultationCanceled: 'ConsultationCanceled',
  ConsultationNoShow: 'ConsultationNoShow',
  SurgeryPlanned: 'SurgeryPlanned',
  ScheduledSurgery: 'ScheduledSurgery',
  SurgeryMissingDocuments: 'SurgeryMissingDocuments',
  SurgeryCanceled: 'SurgeryCanceled',
  Received: 'Received',
  SurgeryConfirmed: 'SurgeryConfirmed',
  SurgeryRescheduled: 'SurgeryRescheduled',
};

export const surgeonProcedureStatusLabels = {
  [surgeonProcedureStatuses.Consultation]: 'Consultation Scheduled',
  [surgeonProcedureStatuses.ConsultationConfirmed]: 'Consultation Confirmed',
  [surgeonProcedureStatuses.ConsultationNeedsReschedule]: 'Needs Reschedule',
  [surgeonProcedureStatuses.ConsultationComplete]: 'Consultation Completed',
  [surgeonProcedureStatuses.ConsultationCanceled]: 'Consultation Canceled',
  [surgeonProcedureStatuses.ConsultationNoShow]: 'No Show',
  [surgeonProcedureStatuses.SurgeryPlanned]: 'Surgery Planned',
  [surgeonProcedureStatuses.ScheduledSurgery]: 'Surgery Scheduled (New)',
  [surgeonProcedureStatuses.Received]: 'Surgery Scheduled',
  [surgeonProcedureStatuses.SurgeryConfirmed]: 'Surgery Confirmed',
  [surgeonProcedureStatuses.SurgeryRescheduled]: 'Surgery Rescheduled',
  [surgeonProcedureStatuses.SurgeryCanceled]: 'Surgery Canceled',
  // [surgeonProcedureStatuses.SurgeryMissingDocuments]: 'Missing Documents',
};
