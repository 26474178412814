import React from 'react';
import styles from '../../styles';
import MedicalPassportField from '../../components/MedicalPassportField';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import MedicalPassportTextArea from '../../components/MedicalPassportTextArea';
import getAgeAndBmi from './pageUtils';
import MedicalPassportRow from '../../components/MedicalPassportRow';
import MedicalPassportRow2 from '../../components/MedicalPassportRow2';
import {
  cardiovascular,
  endocrine,
  gastrointestinal,
  hepatic,
  infectious,
  musculoskeletal,
  neurological,
  oncologic,
  pain,
  psychiatric,
  pulmonary,
  renal,
  vascular,
} from '../paths';
import toAge from '../../../../../../questionnaire/toAge';
import toSex from '../../../../../../questionnaire/toSex';
import toHighlightsString from '../../../../../../questionnaire/toHighlightsString';
import toHighlightsString2 from '../../../../../../questionnaire/toHighlightsString2';
import toHeight from '../../../../../../questionnaire/toHeight';
import toWeight from '../../../../../../questionnaire/toWeight';
import toBMI from '../../../../../../questionnaire/toBMI';
import { MedicalPassportCheckboxStyledWrapper } from './Page3';
import toQuestionYesNo from '../../../../../../questionnaire/toQuestionYesNo';

const CommonSubPartOneOriginalVariant = () => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <div>Anesthetic Plan:</div>
    <div style={{ lineHeight: '1.6em' }}>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '6px' }}>
        <MedicalPassportCheckbox name="page4.anestheticPlan.ga" label="GA" />
        <MedicalPassportCheckbox name="page4.anestheticPlan.mac" label="MAC" />
        <MedicalPassportCheckbox name="page4.anestheticPlan.moderateSedation" label="Moderate Sedation" />
        <MedicalPassportCheckbox name="page4.anestheticPlan.regionalBlock" label="Regional Block" />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <MedicalPassportCheckbox name="page4.anestheticPlan.sab" label="SAB" />
        <MedicalPassportCheckbox name="page4.anestheticPlan.pnb" label="PNB" />
        <MedicalPassportCheckbox name="page4.assessment2.other" />
        <MedicalPassportField name="page4.assessment2.otherText" label="Other" />
      </div>
    </div>
  </div>
);

const CommonSubPartOneAlteredVariant = () => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <div>Anesthetic Plan:</div>
    <div style={{ lineHeight: '1.6em' }}>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', gap: '6px' }}>
        <MedicalPassportCheckbox name="page4.anestheticPlan.ga" label="GA" />
        <MedicalPassportCheckbox name="page4.anestheticPlan.mac" label="MAC" />
        <MedicalPassportCheckbox name="page4.anestheticPlan.sab" label="SAB" />
        <MedicalPassportCheckbox name="page4.anestheticPlan.pnb" label="PNB" />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <MedicalPassportCheckbox name="page4.anestheticPlan.moderateSedation" label="Moderate Sedation" />
        <MedicalPassportCheckbox name="page4.anestheticPlan.regionalBlock" label="Regional Block" />
        <MedicalPassportCheckbox name="page4.assessment2.other" />
        <MedicalPassportField name="page4.assessment2.otherText" label="Other" />
      </div>
    </div>
  </div>
);

const CommonPartOne = ({ variant }) => (
  <div
    style={{
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
    }}
  >
    <div style={{ border: '1px solid black', padding: '.5em', borderRight: 0 }}>
      <div style={{ display: 'flex', gap: '40px' }}>
        <div>ASA Class:</div>
        <MedicalPassportCheckbox label="1" name="page4.asaClass1" />
        <MedicalPassportCheckbox label="2" name="page4.asaClass2" />
        <MedicalPassportCheckbox label="3" name="page4.asaClass3" />
        <MedicalPassportCheckbox label="4" name="page4.asaClass4" />
      </div>
      <div style={{ lineHeight: '1.6em' }}>
        <div style={{ display: 'grid', gridTemplateColumns: '4fr 1fr 1fr' }}>
          <div>NPO Status Verified?</div>
          <div>
            <MedicalPassportCheckbox name="page4.NPOStatusVerifiedYes" label="Yes" />
          </div>
          <div>
            <MedicalPassportCheckbox name="page4.NPOStatusVerifiedNo" label="No" />
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '4fr 1fr 1fr' }}>
          <div>Pt. Reassessed Day of Surgery?</div>
          <div>
            <MedicalPassportCheckbox name="page4.reassuredDayOfSurgeryYes" label="Yes" />
          </div>
          <div>
            <MedicalPassportCheckbox name="page4.reassuredDayOfSurgeryNo" label="No" />
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '4fr 2fr' }}>
          <div>Postoperative Plan:</div>
          <div>
            <MedicalPassportCheckbox name="page4.pacu" label="PACU/Home" />
          </div>
        </div>
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: '1px solid black',
        padding: '.25em',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Assessment:</div>
        <div style={{ lineHeight: '1.6em' }}>
          <MedicalPassportCheckbox name="page4.noContradictionAnesthesia" label="No contradiction to anesthesia" />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MedicalPassportCheckbox name="page4.assessment1.other" />
            <MedicalPassportField name="page4.assessment1.otherText" label="Other" />
          </div>
        </div>
      </div>
      {variant === 'original' ? <CommonSubPartOneOriginalVariant /> : <CommonSubPartOneAlteredVariant />}
    </div>
  </div>
);

const Page4 = ({ hospitalName, defaultValue }) => {
  const { age, bmi } = getAgeAndBmi(
    defaultValue?.demographicinformation?.dob,
    defaultValue?.demographicinformation?.bmi
  );

  return (
    <div id="page4" className="page" style={{ fontSize: '0.8em' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <h3>{hospitalName || ''}</h3>
          <h3>ANESTHESIA ASSESSMENT</h3>
        </div>
        <div></div>
      </div>
      <div style={{ border: '1px solid black', borderBottom: 0, borderLeft: 0 }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '55% 45%',
            borderBottom: '1px solid black',
          }}
        >
          <div>
            <div
              style={{
                border: '1px solid black',
                borderTop: 0,
                minHeight: '60px',
                height: '100%',
                padding: '0.1rem 0.2rem 0 0',
                borderBottom: '0px',
              }}
            >
              <div
                style={{
                  paddingBottom: '35px',
                }}
              >
                <MedicalPassportTextArea name="procedure.name" label="Procedure" />
              </div>
              <div
                style={{
                  border: '1px solid black',
                  padding: '0.2rem 0.1rem 0 0',
                  borderBottom: 0,
                  borderRight: 0,
                  borderLeft: 0,
                }}
              >
                <MedicalPassportField
                  name="page4.activityLevel"
                  label="Activity Level"
                  path={['basicscreening', 'level of physical activity']}
                  reducer={toHighlightsString}
                />
              </div>
            </div>
          </div>
          <div>
            <div style={{ bottom: '5px', paddingLeft: '3px' }}>
              <div style={{ marginBottom: '0.2rem' }}>
                <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={16} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem' }}>
                <MedicalPassportField name="dob" label="DOB" path={['demographicinformation', 'dob']} />
                <MedicalPassportField name="procedure.serviceTime" label="DOS" fontWeight={700} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem' }}>
                <MedicalPassportField name="procedure.patient.providerId" label="MRN#" />
                <MedicalPassportField name="procedure.physician.name" label="Surgeon" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem', paddingBottom: '1px' }}>
                <MedicalPassportField
                  name="age"
                  label="Age"
                  path={['demographicinformation', 'dob']}
                  reducer={toAge}
                  warning={age > 70}
                />
                <MedicalPassportField name="sex" label="Sex" path={['demographicinformation', 'sex']} reducer={toSex} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem', paddingBottom: '1px' }}>
                <MedicalPassportField
                  name="cardiologist"
                  label="Cardiologist"
                  path={['Cardiac', 'yes', 'cardiologist', 'yes', 'cardiologist_name', 'cardiologist_name']}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem', paddingBottom: '1px' }}>
                <MedicalPassportField
                  name="procedure.pcp"
                  path={['demographicinformation', 'primaryCareDoctor']}
                  label="PCP"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            borderLeft: '1px solid black',
            borderBottom: '1px solid black',
          }}
        >
          <MedicalPassportTextArea
            label="Med Allergies/Sensitivities"
            name="allergies1"
            path={['allergies']}
            reducer={toHighlightsString}
            warning={true}
          />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 1fr)',
            borderLeft: '1px solid black',
            height: '30px',
          }}
        >
          <MedicalPassportCheckboxStyledWrapper
            borderLeft={'0'}
            children={
              <MedicalPassportCheckbox path={['basicscreening', 'smoking']} label="Smoking Hx" name="page3.smoking" />
            }
          />
          <MedicalPassportCheckboxStyledWrapper
            children={
              <MedicalPassportCheckbox path={['basicscreening', 'drinking']} label="ETOH Hx" name="page3.etoh" />
            }
          />
          <MedicalPassportCheckboxStyledWrapper
            children={
              <MedicalPassportCheckbox path={['basicscreening', 'drugs']} label="Drug Hx" name="page3.drugHx" />
            }
          />
          <MedicalPassportCheckboxStyledWrapper
            children={
              <MedicalPassportField name="ht" label="Ht" path={['demographicinformation', 'bmi']} reducer={toHeight} />
            }
          />
          <MedicalPassportCheckboxStyledWrapper
            children={
              <MedicalPassportField name="wt" label="Wt" path={['demographicinformation', 'bmi']} reducer={toWeight} />
            }
          />
          <MedicalPassportCheckboxStyledWrapper
            children={
              <MedicalPassportField
                name="bmi"
                label="BMI"
                path={['demographicinformation', 'bmi']}
                reducer={toBMI}
                warning={bmi !== '-' ? bmi > 40 : false}
              />
            }
          />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <div style={styles.medicationsInnerDiv}>
            <MedicalPassportTextArea
              label="Current Medications"
              name="medications1"
              path={['medications']}
              reducer={toHighlightsString}
            />
          </div>
          <div style={{ ...styles.medicationsInnerDiv }}>
            <MedicalPassportTextArea
              label="Surgical/Anesthesia Hx"
              name="anesthesia1"
              path={['priorSurgeries']}
              reducer={toHighlightsString}
            />
          </div>
        </div>
      </div>
      <div style={{ borderLeft: '1px solid black', borderRight: '1px solid black' }}>
        <MedicalPassportTextArea
          label={'Hx of Anesthesia Complications'}
          name="page4.hxAnesthesiaComplications"
          path={['anesthesiaComplications']}
          reducer={toQuestionYesNo}
        />
      </div>

      <section style={{ breakInside: 'avoid' }}>
        <div style={styles.greyBackground}>
          <div>Review of Systems</div>
        </div>
        <div
          style={{
            border: '1px solid black',
            lineHeight: '1.8em',
            borderLeft: 0,
            borderRight: 0,
          }}
        >
          <MedicalPassportRow2
            label="Cardiovascular"
            name="systems.cardiovascular"
            paths={cardiovascular}
            style={{ borderTop: 0 }}
            reducer={toHighlightsString2}
            index={0}
          />
          <MedicalPassportRow2
            label="Pulmonary"
            name="systems.pulmonary"
            paths={pulmonary}
            reducer={toHighlightsString2}
            index={1}
          />
          <MedicalPassportRow2
            label="Renal"
            name="systems.renal"
            paths={renal}
            reducer={toHighlightsString2}
            index={2}
          />
          <MedicalPassportRow2
            label="Hepatic"
            name="systems.hepatic"
            paths={hepatic}
            reducer={toHighlightsString2}
            index={3}
          />
          <MedicalPassportRow2
            label="Neurological"
            name="systems.neurological"
            paths={neurological}
            reducer={toHighlightsString2}
            index={4}
          />
          <MedicalPassportRow2
            label="Gastrointestinal"
            name="systems.gastrointestinal"
            paths={gastrointestinal}
            reducer={toHighlightsString2}
            index={5}
          />
          <MedicalPassportRow2
            label="Vascular"
            name="systems.vascular"
            paths={vascular}
            reducer={toHighlightsString2}
            index={6}
          />
          <MedicalPassportRow2
            label="Endocrine/Metabolic"
            name="systems.endocrine2"
            paths={endocrine}
            reducer={toHighlightsString2}
            index={7}
          />
          <MedicalPassportRow2
            label="Musculoskeletal"
            name="systems.musculoskeletal"
            paths={musculoskeletal}
            reducer={toHighlightsString2}
            index={8}
          />
          <MedicalPassportRow2
            label="Psychiatric"
            name="systems.psychiatric"
            paths={psychiatric}
            reducer={toHighlightsString2}
            index={9}
          />
          <MedicalPassportRow2
            label="Oncology"
            name="systems.ontological"
            paths={oncologic}
            reducer={toHighlightsString2}
            index={10}
          />
          <MedicalPassportRow2
            label="Infectious Disease"
            name="systems.infDis"
            paths={infectious}
            reducer={toHighlightsString2}
            index={11}
          />
          <MedicalPassportRow2
            label="Other"
            name="systems.other"
            paths={pain}
            reducer={toHighlightsString2}
            index={12}
          />
        </div>
      </section>
      <section style={{ breakInside: 'avoid' }}>
        <div style={styles.greyBackground}>Physical Exam/Labs/Anesthesia Plan</div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
          }}
        >
          <div style={{ paddingLeft: '.25em', paddingTop: '.25em' }}>Vital Signs</div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(6, 1fr)',
            }}
          >
            <MedicalPassportField name="page4.temp" label="Temp" />
            <MedicalPassportField name="page4.bp" label="BP" />
            <MedicalPassportField name="page4.pulse" label="Pulse" />
            <MedicalPassportField name="page4.resp" label="Resp" />
            <MedicalPassportField name="page4.o2Sat" label="O2Sat" />
            <MedicalPassportField name="page4.giu" label="GLU" />
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(6,1fr)', height: '80px' }}>
          <div style={{ border: '1px solid black', borderRight: 0 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '.25em' }}>
              <div>General</div>
              <MedicalPassportCheckbox
                name="page4.generalWnl"
                label="WNL"
                style={{ display: 'flex', alignItems: 'center' }}
              />
            </div>
          </div>
          <div style={{ border: '1px solid black', borderRight: 0 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: '15px',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '.25em' }}>
                <div>Heart</div>
                <MedicalPassportCheckbox
                  name="page4.heartWnl"
                  label="WNL"
                  style={{ display: 'flex', alignItems: 'center' }}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MedicalPassportCheckbox
                  name="page4.heartRegularRateRhythm"
                  label="Regular Rate + Rhythm"
                  style={{ display: 'flex' }}
                />
              </div>
            </div>
          </div>
          <div style={{ border: '1px solid black', borderRight: 0 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', padding: '.25em' }}>
              <div>Lungs</div>
              <div>
                <MedicalPassportCheckbox
                  name="page4.lungsWnl"
                  label="WNL"
                  style={{ display: 'flex', alignItems: 'center' }}
                />
                <MedicalPassportCheckbox
                  name="page4.lungsCta"
                  label="CTA"
                  style={{ display: 'flex', alignItems: 'center' }}
                />
              </div>
            </div>
          </div>
          <div style={{ border: '1px solid black', borderRight: 0 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '.25em' }}>
              <div>Teeth</div>
              <MedicalPassportCheckbox
                name="page4.teethWnl"
                label="WNL"
                style={{ display: 'flex', alignItems: 'center' }}
              />
            </div>
          </div>
          <div style={{ height: '67px', border: '1px solid black', padding: '.4em .25em', paddingBottom: '9px' }}>
            Airway Assessment:
          </div>
          <div
            style={{
              border: '1px solid black',
              borderLeft: 0,
              padding: '.25em',
            }}
          >
            <div>Other</div>
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3,1fr)',
            height: '30px',
            border: '1px solid black',
            borderBottom: 0,
            borderTop: 0,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0 .25em' }}>
            <div>Labs reviewed</div>
            <MedicalPassportCheckbox
              name="page4.labYes"
              label="Yes"
              style={{ display: 'flex', alignItems: 'center' }}
            />
            <MedicalPassportCheckbox name="page4.labNo" label="N/A" style={{ display: 'flex', alignItems: 'center' }} />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0 .25em' }}>
            <div>EKG/ECHO reviewed</div>
            <MedicalPassportCheckbox
              name="page4.ekgYes"
              label="Yes"
              style={{ display: 'flex', alignItems: 'center' }}
            />
            <MedicalPassportCheckbox name="page4.ekgNo" label="N/A" style={{ display: 'flex', alignItems: 'center' }} />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0 .25em' }}>
            <div>HCG</div>
            <MedicalPassportCheckbox
              name="page4.pregnantYes"
              label="Neg"
              style={{ display: 'flex', alignItems: 'center' }}
            />
            <MedicalPassportCheckbox
              name="page4.pregnantNo"
              label="N/A"
              style={{ display: 'flex', alignItems: 'center' }}
            />
          </div>
        </div>

        <CommonPartOne variant={'altered'} />
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
          <div style={{ textAlign: 'center' }}>
            <div>________________________________</div>
            <div>Anesthesia</div>
          </div>
          <div style={{ textAlign: 'center' }}>
            <div>________________________________</div>
            <div>Date/Time</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Page4;
