import React, { useState, VFC } from 'react';
import { StaffAvailability } from '../../../types/StaffAvailability';
import ImmutableSet from './types/ImmutableSet';
import StaffAvailabilityConcreteGroupEditor from './StaffAvailabilityConcreteGroupEditor';
import { Box, Paper, Theme } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dates from './types/Dates';
import classNames from 'clsx';
import ActionMenu from './ActionMenu';
import { StaffAvailabilityGroup } from './util/groupStaffAvailability';
import { DateAvailabilityRange } from './StaffAvailabilityEditor';
import { ptoColor } from './HolidayCalendar';

interface StaffAvailabilityGroupEditorProps extends StaffAvailabilityGroup {
  setSelectedDates: (datesOrFn: Dates | ((prev: Dates) => Dates)) => void;
  setHighlightedDates: (datesOrFn: Dates | ((prev: Dates) => Dates)) => void;
  createStaffAvailabilities: (
    dates: Dates,
    staffAvailability: Omit<StaffAvailability<Date>, 'id' | 'staffId' | 'date'>
  ) => Promise<void>;
  deleteStaffAvailabilities: (ids: ImmutableSet<StaffAvailability['id']>) => Promise<void>;
  staffId: number;
}

const StaffAvailabilityGroupEditor: VFC<StaffAvailabilityGroupEditorProps> = ({
  dates,
  setSelectedDates,
  setHighlightedDates,
  available,
  unavailable,
  createStaffAvailabilities,
  deleteStaffAvailabilities,
  staffId,
}) => {
  const classes = useStyles();

  const [highlighted, setHighlighted] = useState<boolean>(false);

  const highlightDates = () => {
    setHighlighted(true);
    setHighlightedDates(dates);
  };

  const unhighlightDates = () => {
    setHighlighted(false);
    setHighlightedDates(Dates.empty);
  };

  const theme = useTheme();

  return (
    <Paper
      variant="outlined"
      elevation={3}
      className={classNames(classes.paper, { [classes.highlighted]: highlighted })}
      onMouseOver={highlightDates}
      onMouseLeave={unhighlightDates}
    >
      <Box className={classes.boxHeader}>
        <DateAvailabilityRange dates={dates} />
        <Box>
          <ActionMenu
            actions={[
              ['Select This', () => setSelectedDates(dates)],
              ['Deselect This', () => setSelectedDates(prev => prev.delete(...dates.values()))],
              (available.ids.size() > 0 || unavailable.ids.size() > 0) && [
                'Remove All Entries',
                () => {
                  deleteStaffAvailabilities(available.ids.add(...unavailable.ids.values()));
                },
              ],
            ]}
          />
        </Box>
      </Box>

      <div>
        <StaffAvailabilityConcreteGroupEditor
          {...available}
          title="Availability"
          createStaffAvailabilities={staffAvailability =>
            createStaffAvailabilities(dates, { ...staffAvailability, available: true })
          }
          deleteStaffAvailabilities={deleteStaffAvailabilities}
          staffId={staffId}
          available={true}
          highlightColor={theme.palette.primary.main}
        />
      </div>
      <div>
        <StaffAvailabilityConcreteGroupEditor
          {...unavailable}
          title="PTO"
          createStaffAvailabilities={staffAvailability =>
            createStaffAvailabilities(dates, { ...staffAvailability, available: false })
          }
          deleteStaffAvailabilities={deleteStaffAvailabilities}
          staffId={staffId}
          available={false}
          highlightColor={ptoColor}
        />
      </div>
    </Paper>
  );
};

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    borderColor: theme.palette.common.white,
    borderWidth: 2,
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    '& > *:not(:last-child)': {
      marginBottom: '1rem',
    },
    marginBottom: '1rem',
  },
  highlighted: {
    outline: '2px solid white',
  },
  boxHeader: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default StaffAvailabilityGroupEditor;
