import { addDays, differenceInCalendarDays } from 'date-fns';

export type DateRange = { from: Date; to: Date };

export const generateDates = (dateRange: DateRange): Date[] => {
  const days = differenceInCalendarDays(dateRange?.to, dateRange?.from);
  return Array.from(Array(days).keys()).map(n => addDays(dateRange?.from, n));
};

export type Hour = {
  label: string;
  value: number;
};

export const generateHours = (fromHour: number, toHour: number): Hour[] => {
  const hours = toHour - fromHour + 1;
  return Array.from(Array(hours).keys()).map(n => {
    const hour = n + fromHour;
    if (hour < 10) {
      return {
        label: `0${hour}:00`,
        value: hour,
      };
    } else {
      return {
        label: `${hour}:00`,
        value: hour,
      };
    }
  });
};

/**
 * time: string, format "HH:MM"
 */
export const getHour = (time: string): number => parseInt(time.split(':')?.[0], 10);

/**
 * time: string, format "HH:MM"
 */
export const getMin = (time: string): number => parseInt(time.split(':')?.[1], 10);
