import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { useStyles } from '../surgeries/styles';
import { useSubscription } from '@apollo/client';
import StackView from '../../../../se/components/StackView';
import { Box, Typography } from '@material-ui/core';
import CollectionListOnly from '../../../../se/components/collection/CollectionListOnly';
import { consultationColumns } from '../columns';
import { consultationsSubscription } from '../../../../graph/surgeon/consultations';
import { Range } from '../../../core/DatePicker';
import { LocalDate, Period } from '@js-joda/core';
import { ConsultationStatus } from './enums';
import RouterLink from '../../../../se/components/RouterLink';
import SurgeonProcedureMobileListItem from '../../surgeonProcedures/SurgeonProcedureMobileListItem';
import EntityState from '../../../../se/components/EntityState';
import { ArrowBack } from '@material-ui/icons';

type Params = { tab: 'need-docs' | 'docs-updated' };

const EmptyState = (props: any) => <EntityState title="All Clear" hint="No items require your attention." {...props} />;

const ConsultationsWithUnresolvedState: FC<RouteComponentProps<Params>> = ({ location, history, match }) => {
  const classes = useStyles();

  const query = useSubscription(consultationsSubscription, {
    variables: {
      filter: {
        dateRange: Range.of(LocalDate.now().minusYears(1), Period.ofYears(1)),
      },
    },
  });

  const statuses = [ConsultationStatus.Consultation, ConsultationStatus.ConsultationConfirmed];

  return (
    <StackView>
      <Box display="flex" flexDirection="column" mt={1} mb={1}>
        <RouterLink
          to={{
            pathname: location.pathname.replace(`/requires-attention/${match.params.tab}`, ''),
          }}
        >
          <ArrowBack fontSize={'small'} />
          &nbsp; Back to Consultations
        </RouterLink>
        <div className={classes.titleSearch}>
          <Typography variant="h2" className={classes.title}>
            Requires Attention
          </Typography>
        </div>
      </Box>
      {/* @ts-ignore */}
      <CollectionListOnly
        match={match}
        location={location}
        history={history}
        data={query}
        getList={(query: { data: { consultations?: any[] } }) =>
          query.data?.consultations?.filter(row => statuses.find(s => s === row.status) !== undefined)
        }
        columns={consultationColumns}
        showUrlProvider={(id: any) =>
          history.push(location.pathname.replace(`/requires-attention/${match.params.tab}`, `/${id}`))
        }
        idProvider={(row: any) => row.id}
        hideColumns={false}
        useColumnSelection={true}
        MobileItemComponent={SurgeonProcedureMobileListItem}
        tableKey={location.pathname}
        defaultDisplayColumns={[
          '#',
          // 'Visit',
          'Name',
          'Consultation Date',
          'Physician',
          // 'Procedure Type',
          // 'DOB',
          'Age',
          'Sex',
          // 'Patient Email Address',
          // 'Patient Cell Phone Number',
          // 'Patient Home Phone Number',
          'Form %',
          // 'Last Invite',
          'Status',
          'Actions',
        ]}
        // View={View}
        // onViewClick={onViewClick}
        // Loading={Loading}
        // Error={Error}
        Empty={EmptyState}
        // FilterComponent={FilterComponent}
        // FooterComponent={FooterComponent}
        // filter={filter}
        // setFilter={setFilter}
        // highlightedProvider={highlightedProvider}
        // highlightedRowStyles={highlightedRowStyles}
        // highlightedRowStyleProvider={highlightedRowStyleProvider}
        // AdditionalBlock={AdditionalBlock}
        // containsSeparator={containsSeparator}
        // useCSVExport={useCSVExport}
        // simpleFilter={simpleFilter}
        // defaultSort={defaultSort}
        // searchSource={searchSource}
      />
    </StackView>
  );
};

export default ConsultationsWithUnresolvedState;
