import { Theme } from '@material-ui/core';

export default function FormLabel(theme: Theme) {
  return {
    MuiFormLabel: {
      root: {
        fontSize: theme.typography.h5.fontSize,
        fontWeight: 500,
      },
    },
  };
}
