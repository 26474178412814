import { differenceInYears } from 'date-fns';
import emptyReducer from './emptyReducer';

export const getAge = (date: Date | undefined): string | null =>
  date ? differenceInYears(new Date(), date).toString() : null;

const toAge = emptyReducer<string | null>(null).with({
  DateOfBirth: date => getAge(date),
});

export default toAge;
