import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import { listColumns } from './columns';
import { format } from 'date-fns';
import { unregisteredPatients } from '../../../graph/procedures';
import PatientInputFactory from '../../inputs/patient/PatientInput';
import scheme, { patientSubscription } from '../../../graph/patients';
import { mapEditItemProps, prepareCreateData } from '../patient/transducers';
import { PatientType } from '../../../types/Patient';
import EmptyPatientListHeader from '../patient/views/EmptyPatientListHeader';

const Empty = {
  title: 'There are no registered patients today.',
  hint: '',
  illustration: theme => theme.illustrations.patients,
};

// TODO: Use this input to create form in modal
const PatientCreationInput = PatientInputFactory(true, true);

const UnregisteredPatients = GraphQLCRUD({
  entityName: 'Patient',
  scheme: { ...scheme, list: unregisteredPatients },
  List: {
    tableKey: 'FScheduledProcedure',
    useColumnSelection: false,
    columns: listColumns,
    TitleAndActions: EmptyPatientListHeader,
    queryOptionsProvider: () => ({
      variables: {
        date: format(new Date(), 'YYYY-MM-DD'),
      },
    }),
    Empty,
  },
  Create: {
    Input: PatientCreationInput,
    Empty,
    prepareCreateData,
    initialValue: { type: PatientType.Out },
  },
});

export default UnregisteredPatients;
