import { Container } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

/**
 * NOTE: react-router-dom Switch component injects a "div" element into the DOM.
 * `switchControl` prop ensures that this div behaves in a way that ensures
 * proper layout.
 */

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    height: '100%',
  },
  switchControl: {
    '& > div:only-child': {
      flex: 1,
      minWidth: 0,
      minHeight: 0,
    },
  },
}));

export const MainContainer = ({ children, switchControl }) => {
  const classes = useStyles();

  return (
    <Container
      maxWidth={false}
      className={clsx(classes.container, {
        [classes.switchControl]: switchControl,
      })}
    >
      {children}
    </Container>
  );
};
