import React from 'react';
import { Answer, Field, Question } from './Containers';
import get from 'lodash/get';

export default ({ label, value, highlightAnswer }) => {
  const text = get(value, 'text', value);
  return (
    <Field>
      <Question>{label}</Question>
      <Answer style={{ color: highlightAnswer ? 'red' : null }}>{typeof text === 'string' ? text : ''}</Answer>
    </Field>
  );
};
