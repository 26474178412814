import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment UserData on User {
      id
      name
      email
      notificationNumbers
      subscribedToOpenPositionNotifications
      sensorEmailNotifications
      sensorPhoneNotifications
      role
      dateCreated
      lastLogin
      patientNameFormat
      lockdown
      hasPassword
    }
  `,
};

export const list = gql`
  query users {
    users {
      ...UserData
    }
  }
  ${fragments.all}
`;

export const organizationUserOptions = gql`
  query users {
    users {
      id
      name
      email
    }
  }
`;

export const item = gql`
  query user($id: Long!) {
    user(id: $id) {
      ...UserData
    }
  }
  ${fragments.all}
`;

export const create = gql`
  mutation createUser(
    $name: String!
    $email: String!
    $role: EMembershipRole!
    $patientNameFormat: PatientNameFormat!
    $notificationNumbers: String
  ) {
    createUser(
      name: $name
      email: $email
      role: $role
      patientNameFormat: $patientNameFormat
      notificationNumbers: $notificationNumbers
    ) {
      ...UserData
    }
  }
  ${fragments.all}
`;

export const update = gql`
  mutation editUser(
    $id: Long!
    $name: String
    $email: String
    $role: EMembershipRole
    $patientNameFormat: PatientNameFormat
    $notificationNumbers: String
    $subscribedToOpenPositionNotifications: Boolean
    $sensorEmailNotifications: Boolean
    $sensorPhoneNotifications: Boolean
  ) {
    editUser(
      id: $id
      name: $name
      email: $email
      role: $role
      patientNameFormat: $patientNameFormat
      notificationNumbers: $notificationNumbers
      subscribedToOpenPositionNotifications: $subscribedToOpenPositionNotifications
      sensorEmailNotifications: $sensorEmailNotifications
      sensorPhoneNotifications: $sensorPhoneNotifications
    ) {
      ...UserData
    }
  }
  ${fragments.all}
`;

export const remove = gql`
  mutation deleteUser($id: Long!) {
    deleteUser(id: $id)
  }
`;

export const clearLockdown = gql`
  mutation clearLockdown($id: Long!) {
    clearLockdown(id: $id)
  }
`;

export const monitoringUsers = gql`
  query monitoring {
    monitoring {
      id
      name
    }
  }
`;

export const groupAdminUsers = gql`
  query groupAdmin {
    groupAdmin {
      id
      name
    }
  }
`;

export default {
  list,
  item,
  create,
  update,
  remove,
};
