import groupBy from 'lodash/groupBy';
import { isAfter, parse } from 'date-fns';

const groupByDate = bookings =>
  bookings &&
  Object.entries(groupBy(bookings, booking => booking.date)).map(([, bookings]) => ({
    date: bookings[0].date,
    bookings,
  }));

const groupByHospital = bookings =>
  bookings &&
  Object.entries(groupBy(bookings, booking => booking.organizationId)).map(([, bookings]) => ({
    id: bookings[0].organizationId,
    name: bookings[0].organizationName,
    bookings,
  }));

const groupByRoom = bookings =>
  bookings &&
  Object.entries(groupBy(bookings, booking => booking.roomId)).map(([, bookings]) => ({
    id: bookings[0].roomId,
    name: bookings[0].roomName,
    bookings,
  }));

export const getScheduleData = bookings =>
  bookings &&
  groupByDate(bookings)
    .map(({ bookings, ...date }) => ({
      ...date,
      hospitals: groupByHospital(bookings).map(({ bookings, ...hospital }) => ({
        ...hospital,
        rooms: groupByRoom(bookings).map(({ bookings, ...room }) => ({
          ...room,
          bookings: bookings.map(booking => ({
            ...booking,
            procedures: booking.procedures.map(procedure => ({
              ...procedure,
              booking,
              room,
              hospital,
              date,
            })),
          })),
        })),
      })),
    }))
    .sort((l, r) => (isAfter(parse(l?.date, 'YYYY-MM-DD'), parse(r?.date, 'YYYY-MM-DD')) ? 1 : -1));
