import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import eq from 'lodash/fp/eq';
import filter from 'lodash/fp/filter';
import findIndex from 'lodash/fp/findIndex';
import identity from 'lodash/fp/identity';
import reduce from 'lodash/fp/reduce';
import update from 'lodash/fp/update';
import concat from 'lodash/fp/concat';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';
import size from 'lodash/fp/size';
import isObject from 'lodash/fp/isObject';
import uniqBy from 'lodash/fp/uniqBy';
import groupBy from 'lodash/fp/groupBy';
import mapValues from 'lodash/fp/mapValues';
import remove from 'lodash/fp/remove';
import toPairs from 'lodash/fp/toPairs';
import flatMap from 'lodash/fp/flatMap';
import tail from 'lodash/fp/tail';
import fromPairs from 'lodash/fp/fromPairs';
import set from 'lodash/fp/set';

const or = defaultValue => value => value || defaultValue;

export const getOperationRooms = flow(get('data.rooms'), filter(flow(get('type'), eq('OR'))));

export const getProcedures = flow(get('data.procedures'), procedures => {
  const colors = [
    '#7ED321',
    '#50E3C2',
    '#00A7F7',
    '#B057FF',
    '#FF5ABB',
    '#F55323',
    '#B29377',
    '#FFAC00',
    '#F8E71C',
    '#9B9B9B',
  ];

  const colorsMap = flow(
    map(get('physician')),
    filter(isObject),
    uniqBy('id'),
    groupBy('color'),
    mapValues(sortBy('id'))
  )(procedures);

  remove(colors, color => colorsMap[color]);

  const conflicts = flow(
    toPairs,
    flatMap(([color, physicians]) => tail(physicians)),
    map(physician => {
      const color = colors[0];
      colors.splice(0, 1);
      return [physician.id, color];
    }),
    fromPairs
  )(colorsMap);

  return map(
    update('physician', physician => {
      if (physician) {
        const newColor = conflicts[physician.id];

        if (newColor) {
          return set('color', newColor)(physician);
        }
      }

      return physician;
    })
  )(procedures);
});

export const transformScheduleOperationRooms = ({ rooms, procedures }) => {
  if (!rooms || !procedures) {
    return [];
  }

  return flow(
    identity,
    reduce((rooms, procedure) => {
      const roomIndex = procedure.orId
        ? findIndex(room => room.id === procedure.orId)(rooms)
        : procedure.or
        ? findIndex(room => room.name === procedure.or)(rooms)
        : -1;

      return roomIndex >= 0
        ? update(roomIndex, update('procedures', flow(or([]), concat(procedure))))(rooms)
        : procedure.or
        ? concat({
            id: procedure.orId,
            name: procedure.or,
            procedures: [procedure],
          })(rooms)
        : rooms;
    }, rooms),
    map(update('procedures', sortBy('startTime'))),
    sortBy(room => room.id)
  )(procedures);
};
