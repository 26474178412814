import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TextField, useTheme } from '@material-ui/core';
import { PostAnesthesiaRecoveryScoreV3Answer } from '../../../../types/Answer';
import produce from 'immer';
import { useSave } from './SaveProvider';
import { useSlideIn } from '../../../../form/Form';
import IMask from 'imask';
import { defaultsDeep } from 'lodash';

interface PostAnesthesiaRecoveryScoreProps {
  value?: PostAnesthesiaRecoveryScoreV3Answer;
  onChange: (value: PostAnesthesiaRecoveryScoreV3Answer) => void;
}

type ColumnKey = 'in5_1' | 'in5_2' | 'in5_3' | 'in15_1';
const columnsKeys: ColumnKey[] = ['in5_1', 'in5_2', 'in5_3', 'in15_1'];

const calcTotal = (
  value: PostAnesthesiaRecoveryScoreV3Answer,
  field: ColumnKey,
  prevFieldValue?: 0 | 1 | 2,
  nextFieldValue?: 0 | 1 | 2
) => {
  const oldTotal =
    (value?.[field]?.resp || 0) +
    (value?.[field]?.circ || 0) +
    (value?.[field]?.activity || 0) +
    (value?.[field]?.alertness || 0) +
    (value?.[field]?.oxygenation || 0);
  return (oldTotal || 0) - (prevFieldValue || 0) + (nextFieldValue || 0);
};

const isCompletedColumn = (key: ColumnKey, value: PostAnesthesiaRecoveryScoreV3Answer) =>
  ['resp', 'circ', 'activity', 'alertness', 'oxygenation'].every(
    j => value?.[key]?.[j] !== undefined && value?.[key]?.[j] !== null
  );

export const MaskedTimeTextField = ({ value, onChange } : any) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (!inputRef?.current) return;

    const militaryTimeMask = IMask(inputRef.current, {
      mask: 'HH{:}MM',
      blocks: {
        HH: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 23,
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59,
        },
      },
    });

    militaryTimeMask.on('complete', () => {
      requestAnimationFrame(() => {
      });
    });

    return () => militaryTimeMask.destroy();
  }, [onChange]);


  return (
    <TextField
      label={"Time"}
      inputRef={inputRef}
      value={value}
      onChange={e => onChange(e.target.value)}
    />
  );
};



const PostAnesthesiaRecoveryScoreV3 = ({ value: answer, onChange }: PostAnesthesiaRecoveryScoreProps) => {
  const classes = useStyles();

  const theme = useTheme();

  const { validationRequest } = useSave();
  const slideIn = useSlideIn();

  const value = useMemo(
    () =>
      answer || {
        in5_1: {},
        in5_2: {},
        in5_3: {},
        in15_1: {},
      },
    [answer]
  );

  useEffect(() => {
    if (validationRequest) {
      if (!value) {
        slideIn();
      }
    }
  }, [validationRequest, value, slideIn]);

  return (
    <div className={classes.centered}>
      <div className={classes.root}>
        <>
          {columnsKeys.map((e, i) => (
            <div
              style={{
                gridRow: '1 / span 23',
                gridColumn: `${i + 3} / span 1`,
                borderRadius: '0.5rem',
                backgroundColor: validationRequest
                  ? isCompletedColumn(columnsKeys[i], value)
                    ? theme.palette.secondary.light
                    : theme.palette.error.light
                  : theme.palette.secondary.light,
              }}
            />
          ))}
        </>

        <>
          <div
            style={{
              gridRow: '1 / span 1',
              gridColumn: '1 / span 2',
              textAlign: 'center',
              marginTop: '0.4rem',
              fontSize: '23px',
              fontWeight: 500,
            }}
          >
            Aldrete Score
          </div>
          <div
            style={{
              gridRow: '1 / span 1',
              gridColumn: `${3} / span 1`,
              textAlign: 'center',
              marginTop: '0.4rem',
              fontSize: '23px',
              fontWeight: 500,
            }}
          >
            <MaskedTimeTextField
              value={value?.in5_1?.time || ''}
              onChange={time => {
                onChange(
                  produce(value, draft => {
                    draft.in5_1.time = time;
                  })
                )
              }}
            />
          </div>
          <div
            style={{
              gridRow: '1 / span 1',
              gridColumn: `${4} / span 1`,
              textAlign: 'center',
              marginTop: '0.4rem',
              fontSize: '23px',
              fontWeight: 500,
            }}
          >
            <MaskedTimeTextField
              value={value?.in5_2?.time || ''}
              onChange={time => {
                onChange(
                  produce(value, draft => {
                    draft.in5_2.time = time;
                  })
                )
              }}
            />
          </div>
          <div
            style={{
              gridRow: '1 / span 1',
              gridColumn: `${5} / span 1`,
              textAlign: 'center',
              marginTop: '0.4rem',
              fontSize: '23px',
              fontWeight: 500,
            }}
          >
            <MaskedTimeTextField
              value={value?.in5_3?.time || ''}
              onChange={time => {
                onChange(
                  produce(value, draft => {
                    draft.in5_3.time = time;
                  })
                )
              }}
            />
          </div>
          <div
            style={{
              gridRow: '1 / span 1',
              gridColumn: `${6} / span 1`,
              textAlign: 'center',
              marginTop: '0.4rem',
              fontSize: '23px',
              fontWeight: 500,
            }}
          >
            <MaskedTimeTextField
              value={value?.in15_1?.time || ''}
              onChange={time => {
                onChange(
                  produce(value, draft => {
                    draft.in15_1.time = time;
                  })
                )
              }}
            />
          </div>
        </>
        <div style={{ gridRow: `2 / span 1`, gridColumn: '1 / span 6', height: '0.5rem', marginTop: '8px' }}>
          <div style={{ backgroundColor: '#1976D2', width: '100%', height: '1px' }} />
        </div>
        <Aspect
          row={3}
          title="Oxygenation:"
          two="2= Spo2 > 92% on RA"
          one="1= Spo2 > 90% on oxygen"
          zero="0= Spo2 < 90% on oxygen"
          value={{
            in5_1:  value.in5_1.oxygenation,
            in5_2:  value.in5_2.oxygenation,
            in5_3:  value.in5_3.oxygenation,
            in15_1: value.in15_1.oxygenation,
          }}
          onChange={v =>
            onChange(
              produce(value, draft => {
                draft.in5_1.oxygenation = v.in5_1;
                draft.in5_2.oxygenation = v.in5_2;
                draft.in5_3.oxygenation = v.in5_3;
                draft.in15_1.oxygenation = v.in15_1;
                draft.in5_1.total = calcTotal(value, 'in5_1', value.in5_1.oxygenation, v.in5_1);
                draft.in5_2.total = calcTotal(value, 'in5_2', value.in5_2.oxygenation, v.in5_2);
                draft.in5_3.total = calcTotal(value, 'in5_3', value.in5_3.oxygenation, v.in5_3);
                draft.in15_1.total = calcTotal(value, 'in15_1', value.in15_1.oxygenation, v.in15_1);
              })
            )
          }
        />
        <Aspect
          row={7}
          title="Respiration:"
          two="2= Breathes deeply, coughs freely"
          one="1= Dyspneic, shallow, or limited breathing"
          zero="0= Apnea"
          value={{
            in5_1:  value.in5_1.resp,
            in5_2:  value.in5_2.resp,
            in5_3:  value.in5_3.resp,
            in15_1: value.in15_1.resp,
          }}
          onChange={v =>
            onChange(
              produce(value, draft => {
                draft.in5_1.resp = v.in5_1;
                draft.in5_2.resp = v.in5_2;
                draft.in5_3.resp = v.in5_3;
                draft.in15_1.resp = v.in15_1;
                draft.in5_1.total = calcTotal(value, 'in5_1', value.in5_1.resp, v.in5_1);
                draft.in5_2.total = calcTotal(value, 'in5_2', value.in5_2.resp, v.in5_2);
                draft.in5_3.total = calcTotal(value, 'in5_3', value.in5_3.resp, v.in5_3);
                draft.in15_1.total = calcTotal(value, 'in15_1', value.in15_1.resp, v.in15_1);
              })
            )
          }
        />
        <Aspect
          row={11}
          title="Circulation:"
          two="2= Blood Pressure ±20mmHg of normal"
          one="1= Blood Pressure ±20-50mmHg of normal"
          zero="0= Blood Pressure ±50mmHg of normal"
          value={{
            in5_1:  value.in5_1.circ,
            in5_2:  value.in5_2.circ,
            in5_3:  value.in5_3.circ,
            in15_1: value.in15_1.circ,
          }}
          onChange={v =>
            onChange(
              produce(value, draft => {
                draft.in5_1.circ = v.in5_1;
                draft.in5_2.circ = v.in5_2;
                draft.in5_3.circ = v.in5_3;
                draft.in15_1.circ = v.in15_1;
                draft.in5_1.total = calcTotal(value, 'in5_1', value.in5_1.circ, v.in5_1);
                draft.in5_2.total = calcTotal(value, 'in5_2', value.in5_2.circ, v.in5_2);
                draft.in5_3.total = calcTotal(value, 'in5_3', value.in5_3.circ, v.in5_3);
                draft.in15_1.total = calcTotal(value, 'in15_1', value.in15_1.circ, v.in15_1);
              })
            )
          }
        />
        <Aspect
          row={15}
          title="Consciousness:"
          two="2= Fully awake"
          one="1= Arousable on calling"
          zero="0= Nonresponsive"
          value={{
            in5_1:  value.in5_1.alertness,
            in5_2:  value.in5_2.alertness,
            in5_3:  value.in5_3.alertness,
            in15_1: value.in15_1.alertness,
          }}
          onChange={v =>
            onChange(
              produce(value, draft => {
                draft.in5_1.alertness = v.in5_1;
                draft.in5_2.alertness = v.in5_2;
                draft.in5_3.alertness = v.in5_3;
                draft.in15_1.alertness = v.in15_1;
                draft.in5_1.total = calcTotal(value, 'in5_1', value.in5_1.alertness, v.in5_1);
                draft.in5_2.total = calcTotal(value, 'in5_2', value.in5_2.alertness, v.in5_2);
                draft.in5_3.total = calcTotal(value, 'in5_3', value.in5_3.alertness, v.in5_3);
                draft.in15_1.total = calcTotal(value, 'in15_1', value.in15_1.alertness, v.in15_1);
              })
            )
          }
        />
        <Aspect
          row={19}
          title="Activity:"
          two="2= Moves all extremities"
          one="1= Moves two extremities"
          zero="0= No movement"
          value={{
            in5_1:  value.in5_1.activity,
            in5_2:  value.in5_2.activity,
            in5_3:  value.in5_3.activity,
            in15_1: value.in15_1.activity,
          }}
          onChange={v =>
            onChange(
              produce(value, draft => {
                draft.in5_1.activity = v.in5_1;
                draft.in5_2.activity = v.in5_2;
                draft.in5_3.activity = v.in5_3;
                draft.in15_1.activity = v.in15_1;
                draft.in5_1.total = calcTotal(value, 'in5_1', value.in5_1.activity, v.in5_1);
                draft.in5_2.total = calcTotal(value, 'in5_2', value.in5_2.activity, v.in5_2);
                draft.in5_3.total = calcTotal(value, 'in5_3', value.in5_3.activity, v.in5_3);
                draft.in15_1.total = calcTotal(value, 'in15_1', value.in15_1.activity, v.in15_1);
              })
            )
          }
        />
        <>
          <div
            style={{
              gridRow: '23 / span 1',
              gridColumn: '1 / span 2',
              textAlign: 'right',
              marginBottom: '0.25rem',
              fontWeight: 500,
            }}
          >
            Total
          </div>

          {columnsKeys.map((e, i) => (
            <div
              style={{
                gridRow: '23 / span 1',
                gridColumn: `${3 + i} / span 1`,
                textAlign: 'center',
                marginBottom: '0.25rem',
                fontWeight: 500,
              }}
            >
              {calcTotal(value, e)}
            </div>
          ))}
        </>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  centered: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  root: {
    margin: 'auto',
    whiteSpace: 'nowrap',
    display: 'grid',
    gridTemplateColumns: 'min-content min-content repeat(5, 4rem)',
    fontSize: '14px',
    columnGap: '1rem',
    '& > div': {
      paddingTop: '0.25rem',
      paddingBottom: '0.25rem',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },
});

export default PostAnesthesiaRecoveryScoreV3;

interface AspectValue {
  in5_1?: 0 | 1 | 2;
  in5_2?: 0 | 1 | 2;
  in5_3?: 0 | 1 | 2;
  in15_1?: 0 | 1 | 2;
  in15_2?: 0 | 1 | 2;
  in30_1?: 0 | 1 | 2;
  in30_2?: 0 | 1 | 2;
  in60_1?: 0 | 1 | 2;
  in60_2?: 0 | 1 | 2;
}

interface AspectProps {
  row: number;
  title: string;
  two: string;
  one: string;
  zero: string;
  value: AspectValue;
  onChange: (value: AspectValue) => void;
  optionValue?: 0 | 1 | 2;
}

const AspectRow = ({ row, title, two, one, zero, value, optionValue, onChange }: AspectProps) => (
  <>
    <div
      style={{
        gridRow: `${row} / span 1`,
        gridColumn: '3 / span 1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Selection
        selected={value.in5_1 === optionValue}
        onClick={() =>
          onChange(
            produce(value, draft => {
              draft.in5_1 = draft.in5_1 === optionValue ? undefined : optionValue;
            })
          )
        }
      >
        {optionValue}
      </Selection>
    </div>
    <div
      style={{
        gridRow: `${row} / span 1`,
        gridColumn: '4 / span 1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Selection
        selected={value.in5_2 === optionValue}
        onClick={() =>
          onChange(
            produce(value, draft => {
              draft.in5_2 = draft.in5_2 === optionValue ? undefined : optionValue;
            })
          )
        }
      >
        {optionValue}
      </Selection>
    </div>
    <div
      style={{
        gridRow: `${row} / span 1`,
        gridColumn: '5 / span 1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Selection
        selected={value.in5_3 === optionValue}
        onClick={() =>
          onChange(
            produce(value, draft => {
              draft.in5_3 = draft.in5_3 === optionValue ? undefined : optionValue;
            })
          )
        }
      >
        {optionValue}
      </Selection>
    </div>
    <div
      style={{
        gridRow: `${row} / span 1`,
        gridColumn: '6 / span 1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Selection
        selected={value.in15_1 === optionValue}
        onClick={() =>
          onChange(
            produce(value, draft => {
              draft.in15_1 = draft.in15_1 === optionValue ? undefined : optionValue;
            })
          )
        }
      >
        {optionValue}
      </Selection>
    </div>
  </>
);

const Aspect = ({ row, title, two, one, zero, value, onChange }: AspectProps) => (
  <>
    <div style={{ gridRow: `${row} / span 1`, gridColumn: '1 / span 1' }}>{title}</div>
    <div style={{ gridRow: `${row} / span 1`, gridColumn: '2 / span 1' }}>{two}</div>
    <AspectRow {...{row, title, two, one, zero, value, onChange, optionValue: 2}} />
    <div style={{ gridRow: `${row + 1} / span 1`, gridColumn: '2 / span 1' }}>{one}</div>
    <AspectRow {...{row: row + 1, title, two, one, zero, value, onChange, optionValue: 1}} />
    <div style={{ gridRow: `${row + 2} / span 1`, gridColumn: '2 / span 1' }}>{zero}</div>
    <AspectRow {...{row: row + 2, title, two, one, zero, value, onChange, optionValue: 0}} />
    <div style={{ gridRow: `${row + 3} / span 1`, gridColumn: '1 / span 6', height: '0.5rem' }}>
      <div style={{ backgroundColor: '#1976D2', width: '100%', height: '1px' }} />
    </div>
  </>
);

interface SelectionProps {
  selected: boolean;
  onClick: () => void;
  children: ReactNode;
}

const Selection = ({ selected, onClick, children }: SelectionProps) => (
  <div
    style={{
      width: '28px',
      height: '28px',
      margin: '-28px',
      borderRadius: '50%',
      backgroundColor: selected ? '#1976D2' : 'transparent',
      color: selected ? 'white' : undefined,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      userSelect: 'none',
    }}
    onClick={onClick}
  >
    {children}
  </div>
);
