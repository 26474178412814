import { logError } from './error';
import { getNestedValue } from './data/object';

export const graphReject = error => {
  logError(error);

  const networkError = getNestedValue('networkError.message', error);
  const graphQLErrors = getNestedValue('graphQLErrors', error);

  return Promise.reject({
    error: networkError || graphQLErrors || undefined,
    errors: null,
  });
};
