import { graphql } from '@apollo/client/react/hoc';
import React, { Fragment } from 'react';
import { compose, mapProps } from 'recompose';
import MultiSelectInput from '../../../se/components/inputs/MultiSelectInput';
import { monitoringUsers } from '../../../graph/users';

const toSelectOptions = data => (data ? data.map(({ id, name }) => ({ value: id, label: name })) : data);
const fromSelectOptions = data => (data ? data.map(({ value, label }) => ({ id: value, name: label })) : data);

const monitoringOptions = ({ data: { loading, monitoring }, ...rest }) => ({
  options: toSelectOptions(monitoring),
  loading,
  ...rest,
});

const MonitoringAssignment = ({ value, options, onChange, loading, disabled, hasError, ...rest }) => {
  const handleChange = value => onChange(fromSelectOptions(value));

  return (
    <Fragment>
      <MultiSelectInput
        name={`monitors`}
        options={options}
        onChange={handleChange}
        value={toSelectOptions(value)}
        loading={loading}
        disabled={disabled}
      />
    </Fragment>
  );
};

export default compose(graphql(monitoringUsers), mapProps(monitoringOptions))(MonitoringAssignment);
