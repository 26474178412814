import React, { ReactNode, useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Timer from './Timer';
import config from '../../../../../config';

interface TimeoutProps {
  duration?: number | null;
  onTimeout: () => void;
  children: ReactNode;
}

const Timeout = ({ duration, onTimeout, children }: TimeoutProps) => {
  const [warn, setWarn] = useState(false);

  useEffect(() => {
    if (!duration) {
      return;
    }

    if (warn) {
      return;
    }

    let timeout = setTimeout(() => {
      setWarn(true);
    }, duration - 4000);

    const handleActivity = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setWarn(true);
      }, duration - 4000);
    };

    document.addEventListener('mousedown', handleActivity);
    document.addEventListener('mouseup', handleActivity);
    document.addEventListener('keydown', handleActivity);
    document.addEventListener('keyup', handleActivity);

    const handleMessage = (message: MessageEvent) => {
      if (message.origin !== config.careURL) {
        return;
      }

      if (message.data?.event === 'Activity') {
        handleActivity();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      clearTimeout(timeout);

      window.removeEventListener('message', handleMessage);

      document.removeEventListener('mousedown', handleActivity);
      document.removeEventListener('mouseup', handleActivity);
      document.removeEventListener('keydown', handleActivity);
      document.removeEventListener('keyup', handleActivity);
    };
  }, [warn]);

  return (
    <>
      {children}
      <Dialog open={warn} onClick={e => e.stopPropagation()}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography>Inactive Tablet</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">The form will close soon due to inactivity.</Typography>
          <Box mt={2} width="8ch" display="inline-block">
            {warn && <Timer duration={4000} onDone={onTimeout} />}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display="flex" style={{ gap: '0.5rem' }}>
            <Button onClick={onTimeout} color="primary" style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
              Discard Unsaved Changes & Exit
            </Button>
            <Button onClick={() => setWarn(false)} color="primary" variant="contained">
              Continue Editing
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Timeout;
