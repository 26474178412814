import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { setPatientReadyInPREP } from '../../../../graph/patients';

export const withSetPatientReadyInPREP = Component => props =>
  (
    <Mutation mutation={setPatientReadyInPREP}>
      {setPatientReadyInPREP => <Component {...props} setPatientReadyInPREP={setPatientReadyInPREP} />}
    </Mutation>
  );

export default withSetPatientReadyInPREP;
