import React from 'react';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import patientFeedbackSchema, {
  feedbackConfigMutation,
  feedbackConfigQuery,
  patientFeedback,
} from '../../../graph/feedback';
import caretakerFeedbackSchema, { caretakerFeedback } from '../../../graph/hospital/caretakerFeedback';
import { caretakerViewColumns, listColumns, patientViewColumns, StarRating } from './columns';
import styled from 'styled-components';
import { compose, withProps } from 'recompose';
import Filters from '../../pages/analytics/Filters';
import { CaretakerFeedbackStats, PatientFeedbackStats } from './FeedbackAnalytics';
import responsive, { breakpoint } from '../../../se/utilities/responsive';
import TitleAction from '../../../se/components/TitleAction';
import FeedbackMobileListItem from './FeedbackMobileListItem';
import { NamedRange } from '../../core/DatePicker';
import Typography from '@material-ui/core/Typography';
import pick from 'lodash/fp/pick';
import TabRoutes from '../../core/TabRoutes';
import EntityState from '../../../se/components/EntityState';
import users from '../../../../src/assets/images/illustrations/users.svg';
import { graphql } from '@apollo/client/react/hoc';
import EntityView from '../../../se/components/entity/EntityView';
import { format } from 'date-fns';
import { Box, Card } from '@material-ui/core';
import useModal from '../../../hooks/useModal';
import { useMutation, useQuery } from '@apollo/client';
import toArray from 'lodash/toArray';
import get from 'lodash/get';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import { RouterlessModal } from '../../../se/components/Modal';
import Form from '../../../se/components/Form';
import { CenteredSpinner } from '../../../se/components/Spinner';
import { withLabel } from '../../../se/components/Label';
import ReportRecipients from '../../pages/sensor/ReportRecipients';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import RatingInput from '../../../se/components/inputs/RatingInput';
import { withRouter } from 'react-router';

export const Row = styled.div`
  display: flex;
  grid-template-columns: auto 1fr;
  grid-gap: 2em;
  align-items: end;
  margin-bottom: 2em;

  > * {
    flex: 1;
  }

  ${responsive.md.andSmaller`
    grid-template-columns: 1fr;
    margin-top: 0;
    margin-bottom: 1rem;
  `};
`;

const Filter = ({ value, ...rest }) => (
  <Row>
    <Filters value={value} hideProcedureTypeSelectInput={true} {...rest} />
  </Row>
);

const ConfigurationInput = withProps({
  schema: {
    recipients: compose(withLabel('Recipients'))(ReportRecipients),
    maximumRating: compose(withLabel('Trigger rating'))(RatingInput),
  },
})(ObjectInput);

const Configuration = () => {
  const { open, openModal, closeModal } = useModal();
  const [mutate] = useMutation(feedbackConfigMutation);
  const { data, loading } = useQuery(feedbackConfigQuery);

  const handleSubmit = async values => {
    const recipients = toArray(get(values, 'recipients'));
    const maximumRating = get(values, 'maximumRating');

    const result = await mutate({
      variables: {
        recipients,
        maximumRating,
      },
      refetchQueries: [{ query: feedbackConfigQuery }],
    });

    if (get(result, 'data.updateFeedbackConfiguration')) {
      closeModal();
    }
  };

  return (
    <div>
      <IconButton className="material-icons" onClick={openModal} style={{ fontSize: '1rem', margin: '-0.5rem 0' }}>
        <SettingsIcon />
      </IconButton>
      {open && (
        <RouterlessModal
          title="Low Rating Alert Configuration"
          onClose={closeModal}
          fullWindow={window.innerWidth < breakpoint.md}
        >
          {!loading ? (
            <Form
              autoFocus
              initialValue={get(data, 'feedbackConfiguration')}
              input={ConfigurationInput}
              label="Save"
              onSubmit={handleSubmit}
              onCancel={closeModal}
            />
          ) : (
            <CenteredSpinner />
          )}
        </RouterlessModal>
      )}
    </div>
  );
};

const PatientFeedbackTitle = () => (
  <Box display="flex" alignItems="center" style={{ gap: '1rem' }}>
    <Typography variant="h2">Patient Feedback</Typography>
    <Configuration />
  </Box>
);

const CaretakerFeedbackTitle = () => (
  <Box display="flex" alignItems="center" style={{ gap: '1rem' }}>
    <Typography variant="h2">Caretaker Feedback</Typography>
  </Box>
);

const PatientEmptyState = props => (
  <EntityState
    title="There is no feedback at the moment"
    hint="Patient feedback will show up here if they provide one after their procedure"
    illustration={users}
    {...props}
  />
);

const CaretakerEmptyState = props => (
  <EntityState
    title="There is no caretaker feedback at the moment"
    hint="Caretaker feedback will show up here if they provide one after their procedure"
    illustration={users}
    {...props}
  />
);

const FeedbackView = withRouter(props => {
  const rating = props?.data?.rating;
  const suggestion = props?.data?.suggestion;
  const submittedAt = props?.data?.submittedAt;

  return (
    <>
      <EntityView {...props} />
      <Card style={{ padding: 16, marginTop: 16 }}>
        <StarRating rating={rating} />
        {suggestion && (
          <Typography variant="h4" gutterBottom>
            {suggestion}
          </Typography>
        )}
        <Typography variant="body2" color="textSecondary">{`Submitted at ${format(
          submittedAt,
          'MM/DD/YYYY HH:mm'
        )}`}</Typography>
      </Card>
    </>
  );
});

const PatientFeedback = GraphQLCRUD({
  entityName: 'Feedback',
  scheme: patientFeedbackSchema,
  List: {
    tableKey: 'Feedback',
    columns: listColumns,
    MobileItemComponent: FeedbackMobileListItem,
    TitleAndActions: () => <TitleAction Title={PatientFeedbackTitle} />,
    Title: () => <div />,
    FilterComponent: Filter,
    defaultFilterValues: {
      dateRange: NamedRange.lastSevenDays(),
    },
    pickFilter: pick([
      'name',
      'physician',
      'procedureType',
      'hospital',
      'status',
      'dateRange',
      'category',
      'speciality',
    ]),
    AdditionalBlock: PatientFeedbackStats,
    Empty: PatientEmptyState,
  },
  Show: {
    entityShowBackButtonTitle: 'Patient Feedback',
    columns: patientViewColumns,
    View: props => <FeedbackView {...props} entityName="Patient Feedback" />,
    itemProvider: graphql(patientFeedback, {
      options: ({ idProvider, ...rest }) => ({
        variables: {
          id: parseInt(idProvider(rest)),
          fetchPolicy: 'cache-and-network',
        },
      }),
    }),
  },
});

const CaretakerFeedback = GraphQLCRUD({
  entityName: 'CaretakerFeedback',
  scheme: caretakerFeedbackSchema,
  List: {
    tableKey: 'CaretakerFeedback',
    columns: listColumns,
    MobileItemComponent: FeedbackMobileListItem,
    TitleAndActions: () => <TitleAction Title={CaretakerFeedbackTitle} />,
    Title: () => <div />,
    FilterComponent: Filter,
    defaultFilterValues: {
      dateRange: NamedRange.lastSevenDays(),
    },
    pickFilter: pick([
      'name',
      'physician',
      'procedureType',
      'hospital',
      'status',
      'dateRange',
      'category',
      'speciality',
    ]),
    AdditionalBlock: CaretakerFeedbackStats,
    Empty: CaretakerEmptyState,
  },
  Show: {
    entityShowBackButtonTitle: 'Caretaker Feedback',
    columns: caretakerViewColumns,
    View: props => <FeedbackView {...props} entityName="Caretaker Feedback" />,
    itemProvider: graphql(caretakerFeedback, {
      options: ({ idProvider, ...rest }) => ({
        variables: {
          id: parseInt(idProvider(rest)),
          fetchPolicy: 'cache-and-network',
        },
      }),
    }),
  },
});

const Feedback = props => (
  <TabRoutes
    {...props}
    tabs={[
      {
        title: 'Patient Feedback',
        path: '/patient',
        component: PatientFeedback,
      },
      {
        title: 'Caretaker Feedback',
        path: '/caretaker',
        component: CaretakerFeedback,
      },
    ]}
  />
);

export default Feedback;
