import React, { useState } from 'react';
import { compose, withProps } from 'recompose';
import TextAreaInput from '../../../../se/components/inputs/TextAreaInput';
import ObjectInput from '../../../../se/components/inputs/ObjectInput';
import TriggerInput from './TriggerInput';
import { useMutation } from '@apollo/client';
import { Channel } from '../NotificationTemplates';
import TextInput from '../../../../se/components/inputs/TextInput';
import { withLabel } from '../../../../se/components/Label';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import TextsmsIcon from '@material-ui/icons/Textsms';
import EmailIcon from '@material-ui/icons/Email';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import AdditionalInfoTextArea from './AdditionalInfoTextArea';

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.getContrastText(theme.palette.background.default),
  },
}));

const NotificationTemplateInput = withProps({
  schema: {
    title: compose(
      withLabel(undefined, { section: true }, 'Template Title'),
      withProps({ style: { marginBottom: 0 } })
    )(TextInput),
    content: compose(
      withLabel(undefined, undefined, 'Message Body'),
      withProps({ rows: 3, placeholder: '' })
    )(TextAreaInput),
  },
})(ObjectInput);

export default ({ value, onChange }) => {
  // eslint-disable-next-line
  const [busy, setBusy] = useState(false);
  const classes = useStyles();

  const Input = NotificationTemplateInput;

  // TODO Update switch styles. See docs at https://www.npmjs.com/package/react-switch
  return (
    <Card variant="outlined">
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {<TextsmsIcon />}
          </Avatar>
        }
      />
      <CardContent>
        <Input
          formId={`notificationTemplates${value.id}`}
          value={value}
          style={{ width: '100%', marginBottom: '1em' }}
          onChange={onChange}
        />
      </CardContent>
    </Card>
  );
};
