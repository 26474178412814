import React from 'react';
import styled from 'styled-components';
import RouterLink from '../../../se/components/RouterLink';

const Outline = styled.div`
  border: 1px solid ${props => props.theme.border.color.default.string()};
  color: ${props => props.theme.textColor.string()};
  border-radius: 5px;
  padding: 0.4em 0.5em;
  cursor: pointer;
  :hover {
    filter: opacity(90%);
  }

  :active {
    filter: opacity(80%);
  }

  transition: all 100ms;
`;

const NameTag = ({ name, id, linkTo }) => (
  <RouterLink to={linkTo}>
    <Outline>{name}</Outline>
  </RouterLink>
);

export default NameTag;
