import React, { FC, useEffect, useState } from 'react';
import { Menu } from '@material-ui/core';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import SendNotificationStaffMember from './staff/SendNotification';
import SendNotificationAnesthesiologist from './anesthesiologist/SendNotification';
import SendNotificationVendor from './vendor/SendNotification';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { hasAssigned } from '../../../../graph/staff';

interface SendNotificationDropdownProps {
  date: Date;
}

const emptyString = ''; //had to do it like this as it had some bug when we put ''

export const TooltipWrapper: FC<{ title: string; isDisabled: boolean; children?: React.ReactNode }> = ({
  title,
  children,
  isDisabled,
}) => (
  <Tooltip title={isDisabled ? <Typography style={{ fontSize: 'small' }}>{title}</Typography> : emptyString}>
    <span>{children}</span>
  </Tooltip>
);

const SendNotificationDropdown: FC<SendNotificationDropdownProps> = ({ date }) => {
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | (EventTarget & HTMLAnchorElement) | null
  >(null);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [localDate, setLocalDate] = useState(new Date());
  useEffect(() => {
    !isNaN(date.getTime()) && setLocalDate(date);
  }, [date]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = useState<number | null>(null);

  const handleClickOpen = (menuItem: number) => {
    setOpen(menuItem);
    handleClose();
  };

  const handleClickClose = () => {
    setOpen(null);
  };

  const { data } = useQuery(hasAssigned, {
    variables: { date: format(localDate, 'YYYY-MM-DD') },
  });

  const notifyStaffMembers = data?.hasAssignedStaffMembers || false;
  const notifyAnesthesiologists = data?.hasAssignedAnesthesiologists || false;
  const notifyVendors = data?.hasAssignedVendors || false;
  const notify = notifyStaffMembers || notifyAnesthesiologists || notifyVendors;

  return (
    <>
      <TooltipWrapper title={'Nobody to notify'} isDisabled={!notify}>
        <IconButton disabled={!notify} onClick={handleClick} color="primary">
          <NotificationsActiveIcon />
        </IconButton>
      </TooltipWrapper>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <SendNotificationStaffMember
          date={localDate}
          openDialog={open === 1}
          handleOpenDialog={() => handleClickOpen(1)}
          handleCloseDialog={handleClickClose}
          notifyStaffMembers={notifyStaffMembers}
        />
        <SendNotificationAnesthesiologist
          date={localDate}
          openDialog={open === 2}
          handleOpenDialog={() => handleClickOpen(2)}
          handleCloseDialog={handleClickClose}
          notifyAnesthesiologists={notifyAnesthesiologists}
        />
        <SendNotificationVendor
          date={localDate}
          openDialog={open === 3}
          handleOpenDialog={() => handleClickOpen(3)}
          handleCloseDialog={handleClickClose}
          notifyVendors={notifyVendors}
        />
      </Menu>
    </>
  );
};

export default SendNotificationDropdown;
