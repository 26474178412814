import React from 'react';
import PropTypes from 'prop-types';
import { RouterlessModal } from '../se/components/Modal';
import { getNestedValue } from '../se/utilities/data/object';

export default class ModalForm extends React.Component {
  state = {};

  componentDidMount = () => {
    this.setState({ value: this.props.initialValue });
  };

  handleChange = value => {
    this.setState({ value });
  };

  handleSubmit = mutation => async () => {
    try {
      const mutationObject = {
        variables: {
          id: this.props.entityId,
          [this.props.variableName]: this.state.value,
        },
      };
      if (this.props.withRefetch) {
        mutationObject.refetchQueries = [{ query: this.props.scheme.item, variables: { id: this.props.entityId } }];
      }
      await mutation(mutationObject);
      this.props.onClose({ mutationFinished: true });
    } catch (error) {
      const networkError = getNestedValue('networkError.message', error);
      const graphQLErrors = getNestedValue('graphQLErrors', error);

      // eslint-disable-next-line no-throw-literal
      throw {
        error: networkError || graphQLErrors || undefined,
        errors: null, // TODO
      };
    }
  };

  render() {
    return (
      <RouterlessModal title={this.props.title} onClose={this.props.onClose}>
        <this.props.FormComponent value={this.state.value} onChange={this.handleChange} onSubmit={this.handleSubmit} />
      </RouterlessModal>
    );
  }
}

ModalForm.propTypes = {
  title: PropTypes.string.isRequired,
  variableName: PropTypes.string.isRequired,
  initialValue: PropTypes.any,
  scheme: PropTypes.object,
  entityId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  FormComponent: PropTypes.func.isRequired,
  withRefetch: PropTypes.bool,
};
