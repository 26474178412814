import { SaveRounded } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';
import ColumnSelect from './ColumnSelect';
import exportCSV from './exportCSV';
import responsive from '../../utilities/responsive';
import SettingsIcon from '@material-ui/icons/Settings';
import { Paper, Popper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Header = styled.div`
  display: flex;
  ${p =>
    p.showColumnSelection
      ? ''
      : `
    flex-direction: row-reverse;
  `}

  //> div {
  //  width: 100%;
  //  margin-right: 2em;
  //}
  //> button {
  //  min-width: 8em;
  //}

  ${responsive.md.andSmaller`
    flex-direction: column;
    > div {
      width: 100%;
      margin-bottom: 1em;
    }
  `};
`;

export default ({
  showColumnSelection,
  showCSVExport,
  selectedColumns,
  setSelectedColumns,
  columns,
  columnOptions,
  list,
  data,
}) => {
  if (!(showCSVExport || showColumnSelection)) {
    return null;
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'spring-popper' : undefined;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Header showColumnSelection={showColumnSelection}>
      <Box display="flex" alignItems="center" ml="auto" mt={1}>
        {showCSVExport && (
          <Button color="primary" startIcon={<SaveRounded />} onClick={() => exportCSV(list, columns, data)}>
            Export as CSV
          </Button>
        )}
        {showColumnSelection && matches && (
          <Box ml={1}>
            <Button size="small" onClick={handleClick} startIcon={<SettingsIcon />}>
              Configure Columns
            </Button>
            <Popper style={{ zIndex: 2 }} id={id} open={open} anchorEl={anchorEl}>
              <Paper>
                <ColumnSelect
                  keepOpen
                  options={columnOptions}
                  values={selectedColumns}
                  onChange={c => setSelectedColumns(c)}
                />
              </Paper>
            </Popper>
          </Box>
        )}
      </Box>
    </Header>
  );
};
