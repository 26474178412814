import { gql } from '@apollo/client';
import { fragments } from './procedures';

export const plannedSurgeriesSubscription = gql`
  subscription plannedSurgeries($filter: Filter) {
    plannedSurgeries(filter: $filter) {
      ...ProcedureDataWithoutEvents
      scheduleRequest {
        id
        status
        state
      }
      entryQuestionnaireProgress
      questionnaireInviteSentAt
      instructionsInviteSentAt
    }
  }
  ${fragments.withoutEvents}
`;

export const scheduledSurgeriesSubscription = gql`
  subscription scheduledSurgeries($filter: Filter) {
    scheduledSurgeries(filter: $filter) {
      ...ProcedureDataWithoutEvents
      scheduleRequest {
        id
        status
        state
        notesStatus
        issues {
          id
          state
        }
      }
      entryQuestionnaireProgress
      questionnaireInviteSentAt
      instructionsInviteSentAt
    }
  }
  ${fragments.withoutEvents}
`;

export const postProcedureNote = gql`
  mutation postProcedureNote($id: Long!, $note: String!) {
    postProcedureNote(id: $id, note: $note)
  }
`;
export const deleteProcedureNote = gql`
  mutation deleteProcedureNote($id: Long!) {
    deleteProcedureNote(id: $id)
  }
`;
