import React, { LegacyRef, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import { useSubscription } from '@apollo/client';
import { Patient } from '../../../types/Patient';
import { QuestionnaireAndAnswers } from '../../../types/Questionnaire';
import { sortBy } from 'lodash';
import { item as patientWithCharts } from '../../../graph/patientChart';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { tabletThemeLight } from '../../../themes/tabletTheme';
import Table, { TableProps } from '../../../form/components/Table';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createTheme';
import { AnesthesiaTableAnswer } from '../../../types/Answer';
import { additionalRows, defaultRows } from '../../../form/components/anesthesiaTableRowsV1';

const rows = [...additionalRows, ...defaultRows];
const columns = 13;

// @ts-ignore
const AnesthesiaTable = withRouter(({ patientId }: { patientId: number }) => {
  const { data: patientData } = useSubscription(patientWithCharts, {
    variables: { id: patientId },
    skip: !patientId,
  });
  const patient: Patient | undefined = patientData?.patient;

  const charts: QuestionnaireAndAnswers[] | undefined = sortBy(
    patient?.procedure?.anesthesiologistOrCharts || [],
    'questionnaire.order'
  );

  const mergedAnswers: any = charts?.reduce(
    (acc, e) => ({
      ...acc,
      ...JSON.parse(e?.questionnaireExchange?.answers || '{}'),
    }),
    {}
  );

  const anesthesiaTable: AnesthesiaTableAnswer | undefined = mergedAnswers?.anesthesiaTableAndChart?.table;

  const classes = useStyles({
    rows: rows.length,
    columns: columns,
  });

  const [zoom, setZoom] = useState(1);

  const tableRef = useRef<HTMLElement>();

  useEffect(() => {
    const table = tableRef.current;

    if (!table) {
      return;
    }

    let animationFrame: ReturnType<typeof requestAnimationFrame>;

    const animate = () => {
      const tableRoot = table.querySelector<HTMLDivElement>('div > div');

      if (tableRoot) {
        const tableWidth = tableRoot.offsetWidth;
        const tableHeight = tableRoot.offsetHeight;
        const clientWidth = document.body.clientWidth - 100;
        const clientHeight = document.body.clientHeight - 100;
        setZoom(Math.min(1, clientWidth / tableWidth, clientHeight / tableHeight));
      }

      animationFrame = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      cancelAnimationFrame(animationFrame);
    };
  }, []);

  return (
    <ThemeProvider theme={tabletThemeLight}>
      <Table
        rootRef={tableRef as LegacyRef<HTMLElement>}
        classes={classes}
        gridStyle={{ zoom }}
        rows={rows?.length}
        columns={columns}
        renderTop={column => (
          <div
            style={{
              flex: 1,
              alignSelf: 'stretch',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: column % 2 === 0 ? 'lightgray' : undefined,
            }}
          >
            {anesthesiaTable?.columns?.[column] || ''}
          </div>
        )}
        renderLeft={row => <>{rows?.[row]?.label}</>}
        renderCell={(row, column) => {
          return (
            <div
              style={{
                flex: 1,
                alignSelf: 'stretch',
                display: 'flex',
                justifyContent: 'space-evenly',
                background: column % 2 === 0 ? 'lightgray' : undefined,
                textAlign: 'center',
              }}
            >
              {anesthesiaTable?.data?.[rows?.[row]?.value]?.[column] || ''}
            </div>
          );
        }}
      />
    </ThemeProvider>
  );
});

export const useStyles = makeStyles<DefaultTheme, Pick<TableProps, 'rows' | 'columns'>>(theme => ({
  grid6: {
    display: 'grid',
    gridTemplateRows: 'repeat(2, 1fr)',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gap: '1em',
    margin: '1em 0',
  },
  grid: {
    display: 'grid',
    gridTemplateRows: ({ rows }) => `min-content repeat(${rows}, min-content)`,
    gridTemplateColumns: ({ columns }) => `min-content repeat(${columns}, min-content)`,
    width: 'min-content',
    margin: 'auto',
  },
}));

export default AnesthesiaTable;
