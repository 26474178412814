import React, { useState, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router';
import queryString from 'query-string';
import { compose } from 'recompose';
import pick from 'lodash/fp/pick';
import PWAInstallBanner from '../../se/components/PWAInstallBanner';
import ActualSchedule from './kiosk/SchedulePage';
import { unpackSessionObject } from './unpackSessionObject';
import { withSession } from '../../state/Session';
import ConnectionProvider from '../../components/pages/kiosk/ConnectionProvider';
import { ThemeProvider } from 'styled-components';
import { dark as darkTheme, light as lightTheme } from '../../theme';
import EmptyState from '../core/EmptyState';

import waitingRoomEmpty from '../../assets/images/waitingroom.svg';
import { ScopeProvider } from '../../contexts/ScopeContext';

const LogIn = ({ logIn, token, onSuccess, onError }) => {
  const [children, setChildren] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        await logIn(token);
        setChildren(onSuccess);
      } catch (e) {
        console.error(e);
        setChildren(onError);
      }
    })();
  }, [token, logIn, onError, onSuccess]);

  return children;
};

const Schedule = compose(
  withRouter,
  withSession(unpackSessionObject),
  withSession(pick(['createUsingAccessToken']))
)(({ location, session, accessToken, createUsingAccessToken }) => {
  const { token } = queryString.parse(location.search);

  return token && (!session || !session.valid || session.accessToken !== token) ? (
    <LogIn
      logIn={createUsingAccessToken}
      token={token}
      onSuccess={<Redirect to={`/schedule?token=${token}`} />}
      onError={
        <ThemeProvider theme={lightTheme}>
          <EmptyState
            image={waitingRoomEmpty}
            text="This session has expired. Ask your supervisor to renew the access."
          />
        </ThemeProvider>
      }
    />
  ) : session && session.valid ? (
    <ConnectionProvider accessToken={accessToken} organizationId={1}>
      <ScopeProvider>
        <ActualSchedule />
      </ScopeProvider>
    </ConnectionProvider>
  ) : (
    <ThemeProvider theme={lightTheme}>
      <EmptyState
        image={waitingRoomEmpty}
        text="You need to be authorized to view this page. Ask your supervisor for access."
      />
    </ThemeProvider>
  );
});

export default () => (
  <ThemeProvider theme={darkTheme}>
    <PWAInstallBanner>
      <Schedule />
    </PWAInstallBanner>
  </ThemeProvider>
);
