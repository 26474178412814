import React, { ReactNode, useMemo } from 'react';
import { z } from 'zod';
import { gql, useQuery } from '@apollo/client';
import FormContext from './FormContext';
import jp from 'jsonpath';
import LoadingIndicator from '../components/core/LoadingIndicator';
import get from 'lodash/get';
import { tryParseJson2 } from '../util/parseJson';

const PreFillWith = z.object({
  QuestionnaireExchange: z.object({
    questionnaireId: z.number(),
    jsonPath: z.string(),
  }),
});

interface PreFillProviderProps {
  preFillWith?: unknown;
  children: (preFill?: unknown) => React.ReactNode;
}

const PreFillProvider = ({ preFillWith, children }: PreFillProviderProps) => {
  const parsed = useMemo(() => PreFillWith.safeParse(preFillWith), [preFillWith]);

  if (parsed.success) {
    return (
      <FormContext.Consumer>
        {({ procedureId }) => (
          <QuestionnaireExchange procedureId={procedureId} {...parsed.data.QuestionnaireExchange}>
            {children}
          </QuestionnaireExchange>
        )}
      </FormContext.Consumer>
    );
  }

  return <>{children()}</>;
};

export default PreFillProvider;

interface QuestionnaireExchangeProps {
  procedureId: number;
  questionnaireId: number;
  jsonPath: string;
  children: (answers?: unknown) => ReactNode;
}

const QuestionnaireExchange = ({ procedureId, questionnaireId, jsonPath, children }: QuestionnaireExchangeProps) => {
  const { loading, error, data } = useQuery(
    gql`
      query questionnaireAnswersForProcedure($procedureId: Long!, $questionnaireId: Long!) {
        questionnaireAnswersForProcedure(procedureId: $procedureId, questionnaireId: $questionnaireId) {
          id
          answers
        }
      }
    `,
    {
      variables: { procedureId, questionnaireId },
    }
  );

  const value = useMemo(() => {
    try {
      return jp.query(tryParseJson2(get(data, 'questionnaireAnswersForProcedure.answers'), undefined), jsonPath)[0];
    } catch (e) {
      console.debug(e);
    }
  }, [data]);

  return loading ? <LoadingIndicator /> : <>{children(value)}</>;
};
