import React, {
  forwardRef,
  LegacyRef,
  MouseEvent,
  ReactNode,
  RefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import Table from './Table';
import { createStyles, FormControlLabel, Paper, Popper, Switch, Theme, useTheme } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createTheme';
import { AnesthesiaVitals as AnesthesiaVitalsAnswer, CreationInfo } from '../../types/Answer';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CloseIcon from '@material-ui/icons/Close';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { capitalize, defaultsDeep, updateWith } from 'lodash';
import { pickBy } from 'lodash/fp';
import { getCurrentTime, getDefaultEndTime } from './AnesthesiaChartV1';
import { toQuarter } from './AnesthesiaTableV1';
import {
  CloseRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  HighlightOffOutlined,
  UnfoldLessRounded,
} from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { getEndTime } from '../../components/entities/schedule/util/time';
import useClickAway from '../../hooks/useClickAway';
import { titlecase } from 'stringcase';
import VitalsButton from './VitalsButton';
import { ChartingSession } from '../../components/pages/kiosk/charting/modules/chartingStore';
import { useChartingSession } from '../../components/pages/kiosk/charting/modules/hooks';

interface AnesthesiaVitalsProps {
  portrait?: boolean;
  firstColumn?: number;
  startTime: string;
  onStartTimeChange?: (time: string) => void;
  endTime: string;
  onEndTimeChange?: (time: string) => void;
  answer: Record<string, AnesthesiaVitalsAnswer>;
  onChange: (value: AnesthesiaVitalsAnswer) => void;
  scale?: boolean;
  onHorizontalScroll?: (offset: number) => void;
  children?: ReactNode;
}

type SelectedCell = {
  value: number;
  row: number;
  column: number;
  type: 'SYS' | 'DIA' | 'HR' | 'RR';
};

const generateOptions = (value: number) => {
  const max = Math.floor(value / 10) * 10 + 10;
  return Array(11)
    .fill(0)
    .map((_, index) => index)
    .map(i => max - i);
};

const Cell = ({
  selectedCell,
  row,
  column,
  onClick,
  onClose,
  onItemClick,
}: SelectedCell & {
  selectedCell: SelectedCell | null;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  onClose: () => void;
  onItemClick: (v: number, t: 'SYS' | 'DIA' | 'HR' | 'RR') => void;
}) => {
  const theme = useTheme();

  const rootRef = useRef<HTMLDivElement>();

  const [follow, setFollow] = useState<HTMLElement | undefined>();

  const handleFocus = () => {
    const root = rootRef.current;

    if (!root) {
      return;
    }

    setFollow(root);
  };

  const handleBlur = () => {
    setFollow(undefined);
  };

  const childDivRef = useRef<HTMLDivElement>();
  const parentDivRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const childDiv = childDivRef.current;
    const parentDiv = parentDivRef.current;

    if (!childDiv || !parentDiv) {
      return;
    }

    if (follow) {
      let animationFrame: ReturnType<typeof requestAnimationFrame>;

      const animate = () => {
        const rect = follow.getBoundingClientRect();
        parentDiv.style.setProperty('position', 'fixed');
        childDiv.style.removeProperty('inset');
        if ((selectedCell?.value || 0) > 60) {
          parentDiv.style.setProperty('top', rect.top + 200 + 'px');
        } else {
          parentDiv.style.setProperty('top', rect.top + 50 + 'px');
        }
        parentDiv.style.setProperty('left', rect.left + 30 + 'px');
        parentDiv.style.setProperty('z-index', '10000');

        animationFrame = requestAnimationFrame(animate);
      };

      animate();

      return () => {
        cancelAnimationFrame(animationFrame);
      };
    } else {
      childDiv.style.setProperty('position', 'absolute');
      parentDiv.style.setProperty('position', 'static');
      parentDiv.style.removeProperty('top');
      parentDiv.style.removeProperty('left');
      childDiv.style.setProperty('inset', '0');
      parentDiv.style.removeProperty('z-index');
    }
  }, [follow]);

  const onClickAway = () => {
    handleBlur();
  };

  useClickAway(parentDivRef, onClickAway);

  return (
    <div
      ref={rootRef as any}
      style={{
        alignSelf: 'stretch',
        justifySelf: 'stretch',
        position: 'relative',
        width: '100%',
      }}
    >
      <div ref={parentDivRef as any}>
        {follow && selectedCell && selectedCell.row === row && selectedCell.column === column && (
          <Paper elevation={8} style={{ position: 'absolute', bottom: 0 }}>
            <Box
              p={2}
              style={{
                display: selectedCell && selectedCell.row === row && selectedCell.column === column ? 'flex' : 'none',
                flexDirection: 'column',
                gap: '0.2em',
                backgroundColor: theme.palette.background.paper,
              }}
            >
              {generateOptions(selectedCell?.value || 0).map(text => (
                <Button
                  key={text}
                  variant="outlined"
                  size="small"
                  style={{ borderRadius: 2, paddingLeft: 2, paddingRight: 2 }}
                  onClick={e => {
                    e.stopPropagation();
                    onItemClick(text, selectedCell?.type || 'SYS');
                    onClose();
                  }}
                >
                  {text}
                </Button>
              ))}
            </Box>
          </Paper>
        )}
        <div
          ref={childDivRef as any}
          style={{
            flex: 1,
            alignSelf: 'stretch',
            background: Math.floor(column / 3) % 2 === 0 ? theme.palette.grey['50'] : undefined,
            position: 'fixed',
          }}
          onClick={e => {
            onClick(e);
            handleFocus();
          }}
        />
      </div>
    </div>
  );
};

export interface AnesthesiaVitalsControls {
  scrollTo: (offset: number) => void;
}

const AnesthesiaVitals = forwardRef<AnesthesiaVitalsControls, AnesthesiaVitalsProps>(
  (
    {
      portrait,
      firstColumn,
      startTime,
      onStartTimeChange,
      endTime,
      onEndTimeChange,
      answer,
      onChange,
      scale,
      onHorizontalScroll,
      children,
    },
    ref
  ) => {
    const rows = 24;
    const rowHeight = '2em';
    const columnWidth = '2em';
    const theme = useTheme();

    const [tool, setTool] = useState<'BP' | 'HR' | 'RR' | 'rm'>('BP');
    const [lower, setLower] = useState<string>('');

    const answerRef = useLatestValue(answer);

    const defaultStartTimeInSeconds =
      15 *
      Math.floor(parseTime(toQuarter(startTime) || toQuarter(getCurrentTime()) || getCurrentTime()) / 60 / 15) *
      60;
    const startTimeInSeconds = Math.min(
      defaultStartTimeInSeconds,
      ...Object.keys(answerRef?.current || {}).map(parseTime)
    );
    const defaultEndTimeInSeconds = parseTime(toQuarter(endTime || getDefaultEndTime()) || getDefaultEndTime());
    const endTimeInSeconds = Math.max(defaultEndTimeInSeconds, ...Object.keys(answerRef?.current || {}).map(parseTime));
    const columns = 3 + Math.ceil((endTimeInSeconds - startTimeInSeconds) / 15 / 60) * 3;
    const classes = useStyles({ portrait, firstColumn, rows, rowHeight, columns, columnWidth });

    const [selectedCell, setSelectedCell] = useState<SelectedCell | null>(null);

    useEffect(() => {
      setSelectedCell(null);
    }, [tool]);

    const chartingSession = useChartingSession();

    useEffect(() => {
      if (defaultEndTimeInSeconds < endTimeInSeconds) {
        onEndTimeChange?.(formatTime(endTimeInSeconds));
      }
      // if (defaultStartTimeInSeconds > startTimeInSeconds) {
      //   onStartTimeChange?.(formatTime(startTimeInSeconds));
      // }
      if (endTimeInSeconds < startTimeInSeconds) {
        onEndTimeChange?.(getEndTime(answer?.startTime || getCurrentTime(), 60) || getDefaultEndTime());
      }
    }, [endTimeInSeconds, startTimeInSeconds, defaultStartTimeInSeconds, defaultEndTimeInSeconds]);

    const handleCellClick = (row: number, column: number) => (e: MouseEvent<HTMLElement>) => {
      const rect = (e.target as HTMLElement).getBoundingClientRect();
      const percentage = (e.clientY - rect.top) / rect.height;
      const nominalValue = (240 * (rows - row)) / rows;
      const value = nominalValue - Math.round(percentage * 10);
      const t = formatTime(parseTime(toQuarter(startTime) || getCurrentTime()) + column * 5 * 60);
      switch (tool) {
        case 'BP':
          if (lower === t) {
            onChange(
              defaultsDeep(
                {
                  [t]: {
                    BP: { DIA: value },
                    creationInfos: { BP: { DIA: creationInfoForChartingSessionAndDate(chartingSession, new Date()) } },
                  },
                },
                answerRef.current
              )
            );
            setLower('');
            setSelectedCell({ value, row, column, type: 'DIA' });
          } else {
            onChange(
              defaultsDeep(
                {
                  [t]: {
                    BP: { SYS: value },
                    creationInfos: { BP: { SYS: creationInfoForChartingSessionAndDate(chartingSession, new Date()) } },
                  },
                },
                answerRef.current
              )
            );
            setLower(t);
            setSelectedCell({ value, row, column, type: 'SYS' });
          }
          break;
        case 'HR':
          onChange(
            defaultsDeep(
              {
                [t]: {
                  HR: value,
                  creationInfos: { HR: creationInfoForChartingSessionAndDate(chartingSession, new Date()) },
                },
              },
              answerRef.current
            )
          );
          setLower('');
          setSelectedCell({ value, row, column, type: 'HR' });
          break;
        case 'RR':
          onChange(
            defaultsDeep(
              {
                [t]: {
                  RR: value,
                  creationInfos: { RR: creationInfoForChartingSessionAndDate(chartingSession, new Date()) },
                },
              },
              answerRef.current
            )
          );
          setLower('');
          setSelectedCell({ value, row, column, type: 'RR' });
          break;
        case 'rm':
          const min = nominalValue - 10;
          const max = nominalValue;
          const newValue = defaultsDeep({}, answerRef.current);

          const isDefinedAndInRange = (value: number | undefined) => value !== undefined && value >= min && value < max;

          const val = newValue[t];

          if (!val) {
            return;
          }

          if (isDefinedAndInRange(val.BP?.SYS)) {
            delete val.BP.SYS;
          }
          if (isDefinedAndInRange(val.BP?.DIA)) {
            delete val.BP.DIA;
          }
          if (isDefinedAndInRange(val.HR)) {
            delete val.HR;
          }
          if (isDefinedAndInRange(val.RR)) {
            delete val.RR;
          }

          if (val.creationInfos) {
            if (!val.BP) {
              delete val.creationInfos.BP;
            }
            if (val.creationInfos.BP) {
              if (!val.BP?.SYS) {
                delete val.creationInfos.BP.SYS;
              }
              if (!val.BP?.DIA) {
                delete val.creationInfos.BP.DIA;
              }
            }
            if (!val.HR) {
              delete val.creationInfos.HR;
            }
            if (!val.RR) {
              delete val.creationInfos.RR;
            }
          }

          onChange(newValue);
      }
    };

    const handleCellValue = (row: number, column: number) => (value: number, type: 'SYS' | 'DIA' | 'HR' | 'RR') => {
      const t = formatTime(parseTime(toQuarter(startTime) || getCurrentTime()) + column * 5 * 60);
      switch (tool) {
        case 'BP':
          onChange(
            defaultsDeep(
              {
                [t]: {
                  BP: { [type]: value },
                  creationInfos: {
                    [t]: {
                      [type]: creationInfoForChartingSessionAndDate(chartingSession, new Date()),
                    },
                  },
                },
              },
              answerRef.current
            )
          );
          break;
        case 'HR':
          onChange(
            defaultsDeep(
              {
                [t]: {
                  HR: value,
                  creationInfos: { HR: creationInfoForChartingSessionAndDate(chartingSession, new Date()) },
                },
              },
              answerRef.current
            )
          );
          setLower('');
          break;
        case 'RR':
          onChange(
            defaultsDeep(
              {
                [t]: {
                  RR: value,
                  creationInfos: { RR: creationInfoForChartingSessionAndDate(chartingSession, new Date()) },
                },
              },
              answerRef.current
            )
          );
          setLower('');
          break;
      }
    };

    const currentTimeInSeconds = useCurrentTimeInSeconds();
    const currentColumn = 2 + Math.floor((currentTimeInSeconds - startTimeInSeconds) / 60 / 5);
    const currentOffset = ((currentTimeInSeconds - startTimeInSeconds) % 5) / 5;

    const changeTool = (event: React.MouseEvent<HTMLElement>, newTool: 'BP' | 'HR' | 'RR' | 'rm') => {
      setTool(newTool);
    };

    const StyledToggleButtonGroup = withStyles(theme => ({
      grouped: {
        margin: theme.spacing(0.5),
        border: 'none',
        '&:not(:first-child)': {
          borderRadius: theme.shape.borderRadius,
        },
        '&:first-child': {
          borderRadius: theme.shape.borderRadius,
        },
      },
    }))(ToggleButtonGroup);

    const [zoom, setZoom] = useState(1);

    const tableRef = useRef<HTMLElement>();

    useEffect(() => {
      if (!scale) {
        return;
      }

      const table = tableRef.current;

      if (!table) {
        return;
      }

      let tableWidth: number | undefined;
      let tableHeight: number | undefined;

      let animationFrame: ReturnType<typeof requestAnimationFrame>;

      const animate = () => {
        const tableRoot = table.querySelector<HTMLDivElement>('div > div > div > div > div');

        if (tableRoot) {
          if (!tableWidth || !tableHeight) {
            tableRoot.style.setProperty('zoom', '1');
            tableWidth = tableRoot.offsetWidth;
            tableHeight = tableRoot.offsetHeight;
            tableRoot.style.removeProperty('zoom');
          }

          const clientWidth = document.body.clientWidth - 100;
          const clientHeight = document.body.clientHeight - 100;

          setZoom(Math.min(1, clientWidth / tableWidth, clientHeight / tableHeight));
        }

        animationFrame = requestAnimationFrame(animate);
      };

      animate();

      return () => cancelAnimationFrame(animationFrame);
    }, [answer, scale]);

    const [viewDetails, setViewDetails] = useState(false);
    const toggleDetails = () => {
      setViewDetails(prevViewDetails => !prevViewDetails);
    };

    useEffect(() => {
      if (!onHorizontalScroll) {
        return;
      }

      const table = tableRef.current;

      if (!table) {
        return;
      }

      const container = table.querySelector('div');

      if (!container) {
        return;
      }

      const handleScroll = () => {
        console.log('AnesthesiaVitals scroll', container.scrollLeft);
        onHorizontalScroll(container.scrollLeft);
      };

      container.addEventListener('scroll', handleScroll);

      return () => container.removeEventListener('scroll', handleScroll);
    }, [onHorizontalScroll]);

    useImperativeHandle(ref, () => ({
      scrollTo: offset => {
        const table = tableRef.current;

        if (!table) {
          return;
        }

        const container = table.querySelector('div');

        if (!container) {
          return;
        }

        container.scrollTo({
          left: offset,
        });
      },
    }));

    return (
      <div className={classes.root}>
        {Boolean(onChange) && !portrait && (
          <div className={`${classes.header} align-button-right`}>
            <Paper elevation={0} className={classes.paper}>
              <StyledToggleButtonGroup aria-label="Parameter button group" value={tool} onChange={changeTool} exclusive>
                <ToggleButton value="BP" className={classes.toggleButton}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                      <ExpandMoreRounded style={{ color: 'magenta', marginTop: -8, marginBottom: -16 }} />
                      <ExpandLessRounded style={{ color: 'blue', marginBottom: -4 }} />
                    </Box>
                    <Typography variant="caption" color="textSecondary" style={{ lineHeight: 1 }}>
                      BP
                    </Typography>
                  </Box>
                </ToggleButton>

                <ToggleButton value="HR" className={classes.toggleButton}>
                  <Box display="flex" style={{ alignItems: 'center', justifyContent: 'flex-start', gap: '1rem' }}>
                    <CloseRounded style={{ color: 'red' }} />
                    <Typography variant="caption" color="textSecondary" style={{ lineHeight: 1 }}>
                      HR
                    </Typography>
                  </Box>
                </ToggleButton>

                <ToggleButton value="RR" className={classes.toggleButton}>
                  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <RadioButtonUncheckedIcon style={{ color: 'green' }} />
                    <Typography variant="caption" color="textSecondary" style={{ lineHeight: 1 }}>
                      ETCO<sub style={{ verticalAlign: 'sub', fontSize: 'smaller' }}>2</sub>
                    </Typography>
                  </Box>
                </ToggleButton>

                <ToggleButton value="rm" className={classes.toggleButton}>
                  <Box display="flex" flexDirection="column">
                    <HighlightOffOutlined />
                    <Typography variant="caption" color="textSecondary" style={{ lineHeight: 1 }}>
                      Del
                    </Typography>
                  </Box>
                </ToggleButton>
              </StyledToggleButtonGroup>
            </Paper>
            <Button onClick={toggleDetails}>{viewDetails ? 'Hide Details' : 'View Details'}</Button>
          </div>
        )}
        <div style={{ flex: 1, display: 'flex', alignItems: 'stretch' }}>
          {Boolean(onChange) && portrait ? (
            <div
              className={`${classes.header} align-button-right`}
              style={{ width: firstColumn! / 2, flexDirection: 'column', alignItems: 'center', marginBottom: '1rem' }}
            >
              <Paper elevation={0} className={classes.paper} style={{ display: 'flex' }}>
                <StyledToggleButtonGroup
                  aria-label="Parameter button group"
                  value={tool}
                  onChange={changeTool}
                  exclusive
                  orientation="vertical"
                >
                  <ToggleButton value="BP" className={classes.toggleButton}>
                    <VitalsButton
                      title="BP"
                      icon={
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                          <ExpandMoreRounded style={{ color: 'magenta', marginTop: -8, marginBottom: -16 }} />
                          <ExpandLessRounded style={{ color: 'blue', marginBottom: -4 }} />
                        </Box>
                      }
                    />
                  </ToggleButton>

                  <ToggleButton value="HR" className={classes.toggleButton}>
                    <VitalsButton title="HR" icon={<CloseRounded style={{ color: 'red' }} />} />
                  </ToggleButton>

                  {/*<ToggleButton value="RR" className={classes.toggleButton}>*/}
                  {/*  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">*/}
                  {/*    <RadioButtonUncheckedIcon style={{ color: 'green' }} />*/}
                  {/*    <Typography variant="caption" color="textSecondary" style={{ lineHeight: 1 }}>*/}
                  {/*      ETCO<sub style={{ verticalAlign: 'sub', fontSize: 'smaller' }}>2</sub>*/}
                  {/*    </Typography>*/}
                  {/*  </Box>*/}
                  {/*</ToggleButton>*/}

                  <ToggleButton value="RR" className={classes.toggleButton}>
                    <VitalsButton title="ETCO2" icon={<RadioButtonUncheckedIcon style={{ color: 'green' }} />} />
                  </ToggleButton>

                  <ToggleButton value="rm" className={classes.toggleButton}>
                    <VitalsButton title="Del" icon={<HighlightOffOutlined />} />
                  </ToggleButton>
                </StyledToggleButtonGroup>
              </Paper>
              <Button onClick={toggleDetails}>{viewDetails ? 'Hide Details' : 'View Details'}</Button>
              {children}
            </div>
          ) : (
            <div style={{ width: firstColumn! / 2 }} />
          )}
          <Table
            firstColumn={firstColumn! / 2}
            rows={rows}
            columns={columns}
            rootRef={tableRef as LegacyRef<HTMLElement>}
            classes={{
              grid: classes.grid,
              topCellSpan: 3,
            }}
            gridStyle={{ zoom }}
            renderTop={column => (
              <div
                style={{
                  position: 'relative',
                  flex: 1,
                  alignSelf: 'stretch',
                  display: 'flex',
                  flexDirection: 'column',
                  background: Math.floor(column / 3) % 2 === 0 ? theme.palette.grey['50'] : undefined,
                }}
              >
                <div style={{ alignSelf: 'self-start', marginLeft: '0.2em' }}>
                  {formatTime(parseTime(toQuarter(startTime) || getCurrentTime()) + column * 15 * 60) || '-'}
                </div>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    textAlign: 'center',
                    borderTop: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <div style={{ borderRight: `1px solid ${theme.palette.divider}` }}>{column * 3 * 5}</div>
                  <div style={{ borderRight: `1px solid ${theme.palette.divider}` }}>{(1 + column * 3) * 5}</div>
                  <div>{(2 + column * 3) * 5}</div>
                </div>
              </div>
            )}
            renderLeft={row =>
              row > 0 && row % 2 === 0 && <div className={classes.left}>{(240 * (rows - row)) / rows}</div>
            }
            renderCell={(row, column) => (
              <Cell
                selectedCell={(tool !== 'rm' && selectedCell) || null}
                onClose={() => setSelectedCell(null)}
                row={row}
                column={column}
                onItemClick={handleCellValue(row, column)}
                onClick={handleCellClick(row, column)}
              />
            )}
          >
            <div
              style={{
                gridRow: `1 / span ${rows + 1}`,
                gridColumn: `${currentColumn} / span 1`,
                width: 2,
                zIndex: 20,
                position: 'relative',
                left: currentOffset * 100 + '%',
                background: 'rgba(0 0 255 / 0.2)',
              }}
            />
            {Object.entries(answer || {}).map(([t, value]) => {
              const entryTimeInSeconds = parseTime(t);

              const column = Math.floor((entryTimeInSeconds - startTimeInSeconds) / 60 / 5);
              const offset = ((entryTimeInSeconds - startTimeInSeconds) % 5) / 5;

              const SYSvalue = value.BP?.SYS;
              const DIAvalue = value.BP?.DIA;
              const HRvalue = value.HR;
              const RRvalue = value.RR;

              const SYStime = unixTimeToLocalHhMm(value.creationInfos?.BP?.SYS?.timestamp);
              const DIAtime = unixTimeToLocalHhMm(value.creationInfos?.BP?.DIA?.timestamp);
              const HRtime = unixTimeToLocalHhMm(value.creationInfos?.HR?.timestamp);
              const RRtime = unixTimeToLocalHhMm(value.creationInfos?.RR?.timestamp);

              return (
                <div
                  key={t}
                  style={{
                    gridRow: `2 / span ${rows}`,
                    gridColumn: `${2 + column} / span 1`,
                    pointerEvents: 'none',
                    position: 'relative',
                  }}
                >
                  {SYSvalue && (
                    <div>
                      {!viewDetails && (
                        <div
                          className={classes.SYS}
                          style={{
                            top: (1 - SYSvalue / 240) * 100 + '%',
                          }}
                        >
                          <KeyboardArrowDownIcon style={{ marginBottom: '-0.33em', zIndex: 10, color: 'magenta' }} />
                        </div>
                      )}
                      {viewDetails && (
                        <div
                          className={classes.SYS}
                          style={{
                            top: (1 - SYSvalue / 240) * 100 + '%',
                          }}
                        >
                          <div style={{ marginBottom: '-0.6em', zIndex: 10, color: 'magenta', fontSize: '.6rem' }}>
                            <div>{SYSvalue}@</div>
                            <div>{SYStime}</div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {DIAvalue && (
                    <div>
                      {!viewDetails && (
                        <div
                          className={classes.DIA}
                          style={{
                            top: (1 - DIAvalue / 240) * 100 + '%',
                          }}
                        >
                          <KeyboardArrowUpIcon style={{ marginTop: '-0.33em', zIndex: 10, color: 'blue' }} />
                        </div>
                      )}
                      {viewDetails && (
                        <div
                          className={classes.DIA}
                          style={{
                            top: (1 - DIAvalue / 240) * 100 + '%',
                          }}
                        >
                          <div style={{ marginTop: '-.33em', zIndex: 10, color: 'blue', fontSize: '.6rem' }}>
                            <div>{DIAvalue}@</div>
                            <div>{DIAtime}</div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {HRvalue && (
                    <div>
                      {!viewDetails && (
                        <div
                          className={classes.HR}
                          style={{
                            top: (1 - HRvalue / 240) * 100 + '%',
                          }}
                        >
                          <CloseIcon
                            style={{ marginTop: '-0.33em', zIndex: 10, color: 'red', transform: 'scale(0.75)' }}
                          />
                        </div>
                      )}
                      {viewDetails && (
                        <div
                          className={classes.HR}
                          style={{
                            top: (1 - HRvalue / 240) * 100 + '%',
                          }}
                        >
                          <div style={{ marginTop: '-0.33em', zIndex: 10, color: 'red', fontSize: '.6rem' }}>
                            <div>{HRvalue}@</div>
                            <div>{HRtime}</div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {RRvalue && (
                    <div>
                      {!viewDetails && (
                        <div
                          className={classes.RR}
                          style={{
                            gridRow: `${rows - Math.floor((240 - RRvalue) / 10)} / span 1`,
                            gridColumn: `${2 + column} / span 1`,
                            top: (1 - RRvalue / 240) * 100 + '%',
                          }}
                        >
                          <RadioButtonUncheckedIcon
                            style={{ marginTop: '-0.33em', zIndex: 10, color: 'green', transform: 'scale(0.75)' }}
                          />
                        </div>
                      )}
                      {viewDetails && (
                        <div
                          className={classes.RR}
                          style={{
                            gridRow: `${rows - Math.floor((240 - RRvalue) / 10)} / span 1`,
                            gridColumn: `${2 + column} / span 1`,
                            top: (1 - RRvalue / 240) * 100 + '%',
                          }}
                        >
                          <div style={{ marginTop: '-0.6em', zIndex: 10, color: 'green', fontSize: '.6rem' }}>
                            <div>{RRvalue}@</div>
                            <div>{RRtime}</div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </Table>
        </div>
      </div>
    );
  }
);

interface StyleProps {
  portrait?: boolean;
  firstColumn?: number;
  rows: number;
  rowHeight: string;
  columns: number;
  columnWidth: string;
}

const useStyles = makeStyles<DefaultTheme, StyleProps>(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  header: {
    margin: '1em 0',
    height: '60px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  toggleButton: {
    minWidth: '7ch',
  },
  paper: {
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
  },
  button: {
    minWidth: '60px',
    minHeight: '60px',
    borderRadius: 0,
    border: '1px solid Grey',
  },
  buttonIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    fontSize: '0.8em',
    zIndex: 1,
  },
  grid: {
    display: 'grid',
    gridTemplateRows: ({ rows, rowHeight }) => `min-content repeat(${rows}, ${rowHeight})`,
    gridTemplateColumns: ({ portrait, firstColumn, columns, columnWidth }) =>
      `${portrait && firstColumn ? `${firstColumn / 2}px` : `min-content`} repeat(${columns}, ${columnWidth})`,
  },
  left: {
    background: 'white',
    marginTop: '-2em',
    marginLeft: '0.5em',
    marginRight: '0.5em',
  },
  SYS: {
    width: 0,
    height: 0,
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    pointerEvents: 'none',
    position: 'absolute',
    left: '0.5em',
  },
  DIA: {
    width: 0,
    height: 0,
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    pointerEvents: 'none',
    position: 'absolute',
    left: '0.5em',
  },
  HR: {
    width: 0,
    height: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
    position: 'absolute',
    left: '1em',
  },
  RR: {
    width: 0,
    height: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
    position: 'absolute',
    left: '1.25em',
  },
}));

export default AnesthesiaVitals;

export function parseTime(time: string) {
  const [hours, minutes] = time.split(':').map(Number);
  return (hours ?? 0) * 60 * 60 + (minutes ?? 0) * 60;
}

export function formatTime(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}

export function useLatestValue<T>(value: T): RefObject<T> {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
}

export function useCurrentTimeInSeconds(): number {
  const [time, setTime] = useState(getCurrentTimeInSeconds());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(getCurrentTimeInSeconds());
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return time;
}

function getCurrentTimeInSeconds(): number {
  const now = new Date();
  return now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
}

function authorForChartingSession(chartingSession: ChartingSession | null): string {
  if (!chartingSession) {
    return 'Anonymous';
  }

  if (chartingSession.name) {
    return chartingSession.name;
  }

  if (chartingSession.id) {
    return `${capitalize(chartingSession.role)} #${chartingSession.id}`;
  }

  return `Unknown ${capitalize(chartingSession.role)}`;
}

export function creationInfoForChartingSessionAndDate(
  chartingSession: ChartingSession | null,
  date: Date
): CreationInfo {
  return {
    author: authorForChartingSession(chartingSession),
    timestamp: date.getTime(),
  };
}

function unixTimeToLocalHhMm(unixTimeInSeconds) {
  const date = new Date(unixTimeInSeconds);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}
