import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { Query } from '@apollo/client/react/components';
import { listUnscheduled } from '../../../graph/surgeon/procedures';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';
import EntityEmptyState from '../../../se/components/entity-states/EntityEmptyState';
import withStyles from '@material-ui/core/styles/withStyles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Search } from '@material-ui/icons';

const filter = (procedures, search) => {
  if (search) {
    const searchTokens = search
      .split(/\s+/)
      .filter(t => t)
      .map(s => s.toLowerCase());

    if (searchTokens.length > 0) {
      return procedures.filter(procedure => {
        const text = procedure.patient
          ? `${procedure.patient.name || procedure.patientName} ${procedure.patient.age}/${procedure.patient.sex}`
          : `${procedure.patientName}`;
        const patientTokens = text
          .split(/\s+/)
          .filter(t => t)
          .map(s => s.toLowerCase());

        return searchTokens.every(token => patientTokens.some(_ => _.indexOf(token) >= 0));
      });
    }
  }

  return procedures;
};

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  dialogContent: {
    padding: theme.spacing(1, 2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const ProcedureList = ({ procedures, search, onChoose }) => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const filteredProcedures = useMemo(() => filter(procedures, search), [procedures, search]);

  return filteredProcedures.length !== 0 ? (
    <List>
      {filteredProcedures.map(procedure => (
        <Fragment key={procedure.id}>
          <ListItem button alignItems="flex-start" disableGutters={isMdDown} onClick={() => onChoose(procedure)}>
            <ListItemText
              primary={
                <Box display="flex" justifyContent="space-between">
                  <Typography>
                    {procedure.patient ? procedure.patient.name || procedure.patientName : procedure.patientName}
                  </Typography>
                  {procedure.patient && (
                    <Typography>
                      {procedure.patient.age}/{procedure.patient.sex}
                    </Typography>
                  )}
                </Box>
              }
              secondary={procedure.procedureType}
            />
          </ListItem>
          <Divider />
        </Fragment>
      ))}
    </List>
  ) : (
    <Box my={6} mx={{ xs: 0, sm: 3 }}>
      <EntityEmptyState
        title="There are no unscheduled patients"
        hint="Make sure that the patient you are looking for is not already scheduled in the patient list"
        icon="person_search"
      />
    </Box>
  );
};

const AnchoredTopDialog = withStyles(theme => ({
  scrollPaper: {
    alignItems: 'flex-start',
    marginTop: theme.spacing(10),
  },
}))(Dialog);

const ChooseProcedureModal = ({ open, onChoose, onClose }) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');

  const renderUnscheduledList = useCallback(
    query =>
      query.loading ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress size="2rem" aria-label="Choose Procedure" />
        </Box>
      ) : query.error ? (
        'Error occurred'
      ) : (
        <ProcedureList procedures={query.data.procedures} search={search} onChoose={onChoose} />
      ),
    [search, onChoose]
  );

  return (
    <AnchoredTopDialog fullWidth maxWidth="sm" onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle
        id="simple-dialog-title"
        className={classes.root}
        children={
          <Fragment>
            <Typography>Select Unscheduled Patient</Typography>
            {onClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            ) : null}
          </Fragment>
        }
      />
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="unscheduled-patients-search"
              variant="filled"
              label="Search Unscheduled Patients"
              autoFocus
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize={'small'} />
                  </InputAdornment>
                ),
              }}
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Box overflow="auto" maxHeight="50vh">
              <Query query={listUnscheduled}>{renderUnscheduledList}</Query>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </AnchoredTopDialog>
  );
};

export default ChooseProcedureModal;
