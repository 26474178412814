import React from 'react';
import styled, { css } from 'styled-components';
import HtmlButton from '../../../../se/components/html/Button';
import responsive from '../../../../se/utilities/responsive';
import { withRouter } from 'react-router';
import { HashLink } from 'react-router-hash-link';

const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 0.75em 1em;
  background-color: rgba(255, 255, 255, 0.07);
  overflow-x: auto;
  overflow-y: hidden;
}
  & ::-webkit-scrollbar {
    width: 0 !important;
  }

  justify-content: ${props => (props.alignRight ? 'flex-end' : 'flex-start')};
`;

const Tab = styled(HtmlButton.withComponent(HashLink))`
  text-align: center;
  margin-right: 1em;

  ${props =>
    props.primary
      ? css`
          &:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-left: 0.375em solid transparent;
            border-right: 0.375em solid transparent;
            border-top: 0.375em solid #00a7f7;
            bottom: -0.375em;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        `
      : null}

  ${responsive.md.andSmaller`
    font-size: 0.8em;
  `}
`;

const ORNavigation = ({ rooms = [], location, alignRight }) => {
  const current = decodeURIComponent(location.hash).replace('#', '');
  return (
    <Container alignRight={alignRight}>
      {rooms.map(({ id, name }, i) => (
        <Tab
          key={name}
          to={`${location.search || ''}#${name}`}
          secondary={!(current === name) ? 'true' : undefined}
          primary={current === name || (i === 0 && current === '') ? 'true' : undefined}
          smooth
          children={name}
        />
      ))}
    </Container>
  );
};

export default withRouter(ORNavigation);
