export const cardiovascular = [
  ['Cardiac', 'CAD'],
  ['Cardiac', 'CongestiveHeartFailure'],
  ['Cardiac', 'DiagnosedAbnormalHeartRhythm'],
  ['Cardiac', 'HeartAttack'],
  ['Cardiac', 'HeartValve'],
  ['Cardiac', 'HighBloodPressure'],
  ['Cardiac', 'HighCholesterol'],
  ['Cardiac', 'InternalNerveStimulator'],
  ['Cardiac', 'LowBloodPressure'],
  ['Cardiac', 'Pacemaker'],
  ['Cardiac', 'Stents'],
  ['Cardiac', 'VascularDisease'],
  ['Cardiac', 'angina'],
  ['Cardiac', 'cardiacCatheterization'],
  ['Cardiac', 'cardiacEcho'],
  ['Cardiac', 'cardiacStressTest'],
  ['Cardiac', 'defibrillator'],
  ['Cardiac', 'cardiacComment'],
];

export const pulmonary = [
  ['Pulmonary', 'Asthma'],
  ['Pulmonary', 'COPD'],
  ['Pulmonary', 'Pneumonia'],
  ['Pulmonary', 'SleepApnea'],
  ['Pulmonary', 'Tuberculosis'],
  ['Pulmonary', 'oxygen'],
  ['Pulmonary', 'pulmonaryComment'],
  ['Pulmonary', 'symptoms'],
];

export const vascular = [
  ['Vascular', 'Anemia'],
  ['Vascular', 'Bleeding or blood clotting disorders'],
  ['Vascular', 'Glaucoma'],
  ['Vascular', 'History of blood clots'],
  ['Vascular', 'Vascular comment'],
  ['Vascular', 'blood thinners'],
  ['Vascular', 'Vascular disease'],
];

export const renal = [['medicalConditions', 'kidneyUrinary']];

export const hepatic = [
  ['Hepatic', 'liver failure'],
  ['Hepatic', 'Hepatitis'],
  ['Hepatic', 'other hepatic'],
  ['Hepatic', 'Hepatic comment'],
];

export const gastrointestinal = [['medicalConditions', 'gastrointestinal']];

export const liver = [['medicalConditions', 'liver']];

export const neurological = [['medicalConditions', 'neurological']];

export const hematological = [['medicalConditions', 'bloodDisorder']];

export const endocrine = [['medicalConditions', 'endocrine']];

export const musculoskeletal = [['medicalConditions', 'muscularSkeletal']];

export const airway = [
  ['PreProcedureAssessment', 'airwayProblem'],
  ['PreProcedureAssessment', 'teethProblem'],
];

export const derm = [
  ['medicalConditions', 'dermatology'],
  ['medicalConditions', 'EyesEarsNoseThroat'],
];

export const psychiatric = [['social', 'mentalHealth']];

export const gynaecological = [
  ['Gynaecological', 'pregnant'],
  ['Gynaecological', 'Gynecologic comment'],
];

export const oncologic = [
  ['Oncologic', 'Cancer'],
  ['Oncologic', 'Other oncologic'],
  ['Oncologic', 'Oncologic comment'],
];

export const infectious = [
  ['PreProcedureAssessment', 'contactwith1'],
  ['PreProcedureAssessment', 'contactwith2'],
  ['PreProcedureAssessment', 'contactwith3'],
  ['PreProcedureAssessment', 'fever'],
  ['PreProcedureAssessment', 'traveled'],
];

export const other = [
  ['medicalConditions', 'otherCondition'],
  ['medicalConditions', 'dermatology'],
];

export const ob = [['medicalConditions', 'OB']];

export const assist = [
  ['PreProcedureAssessment', 'contactLenses'],
  ['PreProcedureAssessment', 'assistiveDevice'],
];

export const substance = [
  ['social', 'alcoholAbuse'],
  ['social', 'drinking'],
  ['social', 'drugs'],
  ['social', 'smoking'],
];
