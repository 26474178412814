import compareDesc from 'date-fns/compare_desc';
import compareAsc from 'date-fns/compare_asc';

import { call } from './object';
import { isDefinedAndNotNull, isFunction, isNumber } from '../check';

export const push = value => array => [value, ...array];
export const pop = ([discard, ...array]) => array;

export const update = (index, f) => array => {
  const clone = array.slice(0);
  clone[index] = f(clone[index]);
  return clone;
};

export const get = index => array => array[index];
export const getFrom = array => index => array[index];

export const range = (length = 0, itemProvider) =>
  isNumber(length) ? Array.from(new Array(length), (_, i) => (isFunction(itemProvider) ? itemProvider() : i)) : [];

export const sortBy = key => sort => (l, r) => sort(l[key], r[key]);

export const sortAlphabetically = (l, r) => l.localeCompare(r);

export const sortUndefinedValuesFirst = (l, r) => {
  if (!isDefinedAndNotNull(l) && isDefinedAndNotNull(r)) {
    return -1;
  } else if (isDefinedAndNotNull(l) && !isDefinedAndNotNull(r)) {
    return 1;
  } else if (!isDefinedAndNotNull(l) && !isDefinedAndNotNull(r)) {
    return 0;
  }

  return 0;
};

export const sortNumbersAsc = (l, r) => Number(r) - Number(l);
export const sortNumberDesc = (l, r) => Number(l) - Number(r);

export const sortDatesAsc = (l, r) => compareAsc(new Date(l), new Date(r));
export const sortDatesDesc = (l, r) => compareDesc(new Date(l), new Date(r));

export const map = call('map');
