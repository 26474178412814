import React, { useCallback } from 'react';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { getProcedure } from '../../../graph/surgeon/procedures';
import { useQuery } from '@apollo/client';
import get from 'lodash/get';

const ActualLoader = ({ procedureId, onLoad }) => {
  const { data, loading } = useQuery(getProcedure, {
    variables: {
      id: procedureId,
    },
  });

  if (!loading) {
    onLoad(get(data, 'procedure', null));
  }

  return null;
};

const ProcedureLoader = ({ history, location, onLoad }) => {
  const params = queryString.parse(location.search);

  const handleLoad = useCallback(
    procedure => {
      history.replace(location.pathname);
      onLoad(procedure);
    },
    [onLoad, history, location.pathname]
  );

  return params.procedure ? <ActualLoader procedureId={parseInt(params.procedure, 10)} onLoad={handleLoad} /> : null;
};

export default withRouter(ProcedureLoader);
