import { createPdf } from '../../../../vendor/pdfMake';
import { cbEmptyIcon, cbFullIcon, defaultFooter, makeHeader, openPdf } from './shared';
import { Text } from 'slate';
import get from 'lodash/get';
import defaultTo from 'lodash/defaultTo';
import { extractTemplateVariable } from '../instructions/RichTextPage';
import { format, parse } from 'date-fns';
import { getInstructionAcceptances } from '../instructions/InstructionsAccepted';

const styles = {
  icon: { font: 'Glyphter' },
  checkmark: { font: 'Fontello' },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    marginBottom: 5,
    fontSize: 12,
  },
  subsection: {
    marginLeft: 8,
    marginRight: 8,
    fontSize: 8,
  },
  question: {
    opacity: 0.6,
    lineHeight: 1.6,
  },
  answer: {},
  tableheader: {
    bold: true,
  },
};

const instructionFooter = procedure => {
  const content = get(procedure, 'procedureInstructions', '{}');
  const exchange = getInstructionAcceptances(procedure);
  const mostRecentExchange = exchange.find(submission => get(submission, 'content') === content);
  const acceptedDate = get(mostRecentExchange, 'timestamp');

  return {
    fontSize: 7,
    text: [
      { text: get(procedure, 'patientName', ''), opacity: 0.5, fontSize: 7 },
      {
        text: [
          {
            text: [
              { text: ' | ', opacity: 0.6, fontSize: 7 },
              {
                text: acceptedDate ? 'Digital Form accepted: ' : 'Digital Form not accepted',
                opacity: 0.6,
                fontSize: 7,
              },
              { text: acceptedDate ? format(acceptedDate, 'MM/DD/YYYY') : '', opacity: 0.6, fontSize: 7 },
            ],
            opacity: 0.6,
          },
        ],
      },
    ],
  };
};

const docDefinitionTemplate = procedure => ({
  pageSize: 'LETTER',
  pageMargins: [40, 40, 40, 40],
  styles,
  defaultStyle: {
    fontSize: 9,
    lineHeight: 1.4,
  },
  footer: defaultFooter(procedure ? instructionFooter(procedure) : undefined),
  pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
    return currentNode.table && currentNode.pageNumbers.length > 1;
  },
});

const serializeLeaf = (node, variables) => {
  const decoration = { bold: node.bold, decoration: node.underline ? 'underline' : undefined, italics: node.italic };
  if (node.variable) {
    const [name, element] = extractTemplateVariable(node);

    const value = variables[name];
    if (element === 'checkbox') {
      return { text: [' ', value ? cbFullIcon : cbEmptyIcon, ' '], ...decoration, bold: true };
    } else if (element === 'date') {
      return {
        text: ` ${value ? format(parse(value, 'YYYY-MM-DD'), 'D MMMM YYYY') : '_____________'} `,
        ...decoration,
        bold: true,
      };
    } else {
      return { text: ` ${defaultTo(value, '_____________')} `, ...decoration, bold: true };
    }
  }

  return { text: node.text, ...decoration };
};

const serialize = (node, variables) => {
  if (Text.isText(node)) {
    return serializeLeaf(node, variables);
  }
  const children = get(node, 'children', node).map(n => serialize(n, variables));

  switch (node.type) {
    case 'bulleted-list':
      return { ul: children, marginLeft: 10, marginTop: 5, marginBottom: 5 };
    case 'heading-one':
      return { text: children, fontSize: 14, marginTop: 10, marginBottom: 10 };
    case 'heading-two':
      return { text: children, fontSize: 12, marginTop: 8, marginBottom: 8 };
    case 'list-item':
      return { text: children, fontSize: 8, opacity: 0.8 };
    case 'paragraph':
      return { text: children, fontSize: 8, marginTop: 3 };
    default:
      return children;
  }
};

const makeInstructions = ({ content, variables }) => {
  const serialized = serialize(content, variables);
  return { stack: serialized };
};

export const generateInstructionsPdf = ({ instructions, procedure, hospitalName, scheduleProvider }) => {
  const header = makeHeader({ procedure, hospitalName, scheduleProvider });

  const footer = {
    stack: [
      {
        stack: [
          { text: `I HAVE READ, UNDERSTAND AND WILL ABIDE BY THE ABOVE INSTRUCTIONS`, bold: true },
          {
            text: '___________________________________________________________________________________________________________________________________________',
            marginTop: 10,
          },
          {
            columns: [{ text: 'Signature' }, { text: 'Relationship (if patient is a minor)' }, { text: 'Date' }],
            widths: ['33%', '34%', '33%'],
            alignment: 'center',
          },
        ],
        fontSize: 8,
      },
    ],
    marginTop: 20,
  };

  const docDefinition = {
    ...docDefinitionTemplate(procedure),
    info: { title: `Entry Form Patient ${procedure.id}` },
    content: [header, makeInstructions(instructions), footer],
  };

  openPdf(createPdf(docDefinition), `PreOp_Instructions_${procedure.id}`);
};
