import React, { Component } from 'react';
import styled from 'styled-components';
import Button from '../../../se/components/Button';
import RoomSelection from './RoomSelection';

const RecordButton = styled(Button)`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 1.25em;
  margin-top: 0.5em;
  color: #fff;
  max-width: 600px;
  ${props =>
    props.isRecording
      ? `
    background-color: ${props.theme.dangerColor.string()};
  `
      : ''}
`;

class JourneyRecorder extends Component {
  render() {
    const { selectRoom, selectedRoom, isRecording, canRecord } = this.props;
    return (
      <React.Fragment>
        <RoomSelection
          rooms={this.props.rooms}
          onSelect={selectRoom}
          isRecording={isRecording}
          selectedRoom={selectedRoom}
        />
        <RecordButton
          onClick={isRecording ? this.props.stopRecording : this.props.startRecording}
          isRecording={isRecording}
          disabled={!canRecord}
        >
          {isRecording ? 'End Journey Recording' : 'Start Journey Recording'}
        </RecordButton>
      </React.Fragment>
    );
  }
}

export default JourneyRecorder;
