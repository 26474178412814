import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import get from 'lodash/fp/get';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';

import scheme, {
  grantScheduleAccess,
  resendScheduleAccessLink,
  revokeScheduleAccess,
  setScheduleAccessAllVendor,
} from '../../../graph/vendors';
import { baseColumns, viewColumns } from './columns';
import VendorInput from './VendorInput';
import ScheduleAccessEditor from '../../pages/ScheduleAccessEditor';
import EntityView from '../../../se/components/entity/EntityView';
import RepresentativesForm from './RepresentativesForm';
import Vendor from '../../../types/Vendor';
import ScheduleAccessToggle from '../../pages/ScheduleAccessToggle';

export const VendorScheduleAccessEditor = ({ vendor, onClose, refetchQueries = [], ...rest }: any) => (
  <ScheduleAccessEditor
    grantScheduleAccess={grantScheduleAccess}
    resendScheduleAccessLink={resendScheduleAccessLink}
    revokeScheduleAccess={revokeScheduleAccess}
    {...vendor}
    onClose={onClose}
    {...rest}
  >
    <ScheduleAccessToggle
      scheduleUserId={vendor?.id}
      scheduleAccessAll={vendor?.scheduleAccessAll}
      setScheduleAccessAll={setScheduleAccessAllVendor}
      refetchQueries={refetchQueries || []}
    />
  </ScheduleAccessEditor>
);

const VendorView = (props: { data: Vendor }) => (
  <Fragment>
    <EntityView {...props} />
    <RepresentativesForm vendor={props?.data} />
  </Fragment>
);

export default GraphQLCRUD({
  entityName: 'Vendor',
  List: {
    tableKey: 'Vendor',
    columns: baseColumns,
    simpleFilter: true,
    additionalRoutes: [
      {
        key: 'schedule-access',
        path: '/schedule-access/:id',
        Component: (props: any) => {
          const { data: item, loading } = useQuery(scheme.item, {
            variables: {
              id: parseInt(get('params.id')(props.match), 10),
            },
          });

          return !loading && item.vendor ? <VendorScheduleAccessEditor {...item} {...props} /> : null;
        },
      },
    ],
  },
  Show: {
    columns: viewColumns,
    View: VendorView,
  },
  Create: {
    Input: VendorInput,
  },
  Edit: {
    Input: VendorInput,
  },
  scheme,
});
