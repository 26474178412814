import React, { Fragment } from 'react';
import CSVReader from 'react-csv-reader';
import { H4, Paragraph } from '../../../se/components/typography';
import styled from 'styled-components';
import { compose, withState } from 'recompose';
import Button from '../../../se/components/Button';
import { differenceInMilliseconds, differenceInSeconds, format, isBefore, parse } from 'date-fns';
import config from '../../../config';
import { request } from '../minew/mockRequest';
import { withRouter } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Reader = styled.div`
  display: flex;
  justify-content: center;

  > div {
    display: flex;
    justify-content: center;
    flex-direction: column;

    > label {
      text-align: center;
    }

    > input {
      padding: 10px;
      display: block;
      margin: 15px auto;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
  }
`;

const StartButton = styled(Button)`
  margin: 3em;
  width: 20em;
  height: 4em;
  align-self: center;
`;

class Simulator extends React.PureComponent {
  state = { currentTick: 0, playing: false, end: false };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.timeline && this.state.playing) {
      this.tick();
    }
  }

  sync = async data => {
    try {
      return fetch(`${config.apiURL}/minew?hospital=${this.props.organizationId}`, {
        method: 'POST',
        body: request(data),
        headers: { 'Content-type': 'application/json' },
      });
    } catch (err) {
      console.error(err);
    }
  };

  simulationEnd = () => {
    this.setState({ end: true, playing: false });
  };

  restart = () => {
    this.setState({ end: false, playing: true, currentTick: 0 });
  };

  tick = () => {
    const timeline = this.props.timeline;
    const currentTick = this.state.currentTick;
    const current = timeline[currentTick];
    const next = currentTick + 1 < timeline.length ? timeline[currentTick + 1] : undefined;

    this.sync(current);
    if (!next) {
      return this.simulationEnd();
    }

    const nextTickIn = differenceInMilliseconds(next.receivedAt, current.receivedAt);

    setTimeout(() => {
      this.setState({ currentTick: currentTick + 1 });
    }, nextTickIn);
  };

  render() {
    const { timeline } = this.props;
    const { currentTick, playing, end } = this.state;

    return (
      <Container>
        <span>{`${format(timeline[currentTick].receivedAt, 'YY-MM-DD HH:mm:ss')} - ${Math.round(
          (currentTick / (timeline.length - 1)) * 100
        )} %`}</span>
        {!end && (
          <StartButton onClick={() => this.setState({ playing: !playing })}>{playing ? 'Pause' : 'Start'}</StartButton>
        )}
        {end && <StartButton onClick={this.restart}>Restart</StartButton>}
      </Container>
    );
  }
}

const Replay = ({ timeline, setTimeline, setPlaying, playing, match }) => {
  const handleLoad = data => {
    if (data && data.length > 0) {
      const header = data[0];
      setTimeline(
        data
          .slice(1)
          .map(row => row.map((item, i) => ({ [header[i]]: item })).reduce((acc, curr) => ({ ...acc, ...curr })))
          .map(({ receivedAt, gatewayTimestamp, beaconTimestamp, ...rest }) => ({
            ...rest,
            receivedAt: parse(receivedAt),
            beaconTimestamp: parse(beaconTimestamp),
            gatewayTimestamp: parse(gatewayTimestamp),
          }))
          .sort((l, r) => (isBefore(l.receivedAt, r.receivedAt) ? -1 : 1))
      );
    }
  };

  const durationInSeconds = () => {
    if (timeline) {
      const start = timeline[0].receivedAt;
      const end = timeline[timeline.length - 1].receivedAt;
      return differenceInSeconds(end, start);
    }
  };

  const formatDuration = duration => {
    if (duration) {
      return `${duration} s`;
    } else {
      return '-';
    }
  };

  const organizationId = match.params['organizationId'];

  return (
    <Fragment>
      <H4>Signal Replay</H4>
      <Container>
        <Reader>
          <CSVReader
            label="Select signal log csv export"
            onFileLoaded={handleLoad}
            parserOptions={{ skipEmptyLines: true }}
          />
        </Reader>
        <Paragraph>{`Simulation Duration: ${formatDuration(durationInSeconds())}`}</Paragraph>
        {timeline && <Simulator timeline={timeline} organizationId={organizationId} />}
      </Container>
    </Fragment>
  );
};

export default compose(withRouter, withState('timeline', 'setTimeline', null))(Replay);
