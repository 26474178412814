import { AnesthesiaTableRow } from './AnesthesiaTableV1';

export const rowsV2: AnesthesiaTableRow[] = [
  {
    label: 'O₂L/M (FIO₂)',
    unit: '',
    value: 'O2_FIO2',
  },
  {
    label: 'N₂O Air L/M',
    unit: '',
    value: 'N20_Air',
  },
  {
    label: 'SEVO',
    unit: '%',
    value: 'Sevo',
  },
  {
    label: 'Propofol',
    unit: 'mg',
    value: 'Prop',
  },
  {
    label: 'Lidocaine',
    unit: 'mg',
    value: 'Lido',
  },
  {
    label: 'Versed',
    unit: 'mg',
    value: 'Vers',
  },
  {
    label: 'Ofirmev',
    unit: 'mg',
    value: 'Ofirmev',
  },
  {
    label: 'Decadron',
    unit: 'mg',
    value: 'Dec',
  },
  {
    label: 'Ketamine',
    unit: 'mg',
    value: 'Ketamine',
  },
  {
    label: 'Toradol',
    unit: 'mg',
    value: 'Toradol',
  },
  {
    label: 'TXA',
    unit: 'mg',
    value: 'txa',
  },
  {
    label: 'Benadryl',
    unit: 'mg',
    value: 'Benadryl',
  },
  {
    label: 'Zofran',
    unit: 'mg',
    value: 'Zof',
  },
  {
    label: 'Ephedrine',
    unit: 'mg',
    value: 'Eph',
  },
  {
    label: 'Phenylephrine',
    unit: 'mg',
    value: 'Phenyl',
  },
  {
    label: 'LR',
    value: 'LR',
  },
  {
    label: 'EBL',
    value: 'EBL',
  },
  {
    label: 'ECG',
    value: 'ECG',
  },
  {
    label: 'Temp',
    value: 'Temp',
  },
  {
    label: 'SaO2',
    value: 'SaO2',
  },
  {
    label: 'ETCO2',
    value: 'Etco2',
  },
];
