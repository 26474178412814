import { z } from 'zod';

export default z
  .object({
    adhesive: z
      .object({
        reaction: z.string().nullable().optional(),
        notes: z.string().nullable().optional(),
      })
      .nullable()
      .optional(),
    latex: z
      .object({
        reaction: z.string().nullable().optional(),
        notes: z.string().nullable().optional(),
      })
      .nullable()
      .optional(),
    medications: z
      .object({
        name: z.string().nullable().optional(),
        reaction: z.string().nullable().optional(),
        notes: z.string().nullable().optional(),
      })
      .array()
      .nullable()
      .optional(),
    unknown: z
      .object({
        name: z.string().nullable().optional(),
        reaction: z.string().nullable().optional(),
        notes: z.string().nullable().optional(),
      })
      .array()
      .nullable()
      .optional(),
  })
  .nullable();
