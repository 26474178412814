import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment SubmissionData on CovidScreeningSubmission {
      id
      question
      answer
      dateSubmitted
    }
  `,
};

export const list = (category, name) => gql`
  query covidScreeningSubmissions($filter: CovidScreeningFilter) {
    ${name}: covidScreeningSubmissions(category: "${category}", filter: $filter) {
      ...SubmissionData
    }
  }
  ${fragments.all}
`;

export const listSubscription = (category, name) => gql`
  subscription covidScreeningSubmissions($filter: CovidScreeningFilter) {
    ${name}: covidScreeningSubmissions(category: "${category}", filter: $filter) {
      ...SubmissionData
    }
  }
  ${fragments.all}
`;

export const listSubscriptionSubmissionsForPatientsSC = gql`
  subscription covidScreeningSubmissions($filter: CovidScreeningFilter) {
    patientSubmissions: patientCovidScreeningSubmissionsSC(filter: $filter) {
      id
      procedure {
        id
        scheduledAt
        patientName
        patientPhoneNumber
        submissionId
        covidScreeningInviteSentAt
        covidScreeningInviteSMSCount
        lastCovidScreeningInviteSMS {
          id
          type
          timestamp
          patientPin
          channel
          text
          status
          content
          exchangeId
        }
      }
      submission {
        ...SubmissionData
      }
    }
  }
  ${fragments.all}
`;

export const listSubscriptionSubmissionsForPatientsSO = gql`
  subscription covidScreeningSubmissions($filter: CovidScreeningFilter) {
    patientSubmissions: patientCovidScreeningSubmissionsSO(filter: $filter) {
      id
      procedure {
        id
        scheduledAt
        patientName
        patientPhoneNumber
        submissionId
        covidScreeningInviteSentAt
        covidScreeningInviteSMSCount
        lastCovidScreeningInviteSMS {
          id
          type
          timestamp
          patientPin
          channel
          text
          status
          content
          exchangeId
        }
      }
      submission {
        ...SubmissionData
      }
    }
  }
  ${fragments.all}
`;

export const item = name => gql`
  query covidScreeningSubmission($id: String!) {
    ${name}: covidScreeningSubmission(submissionId: $id) {
      ...SubmissionData
    }
  }
  ${fragments.all}
`;

export const patientSubmission = gql`
  query covidScreeningSubmission($id: String!) {
    patientSubmission: patientCovidScreeningSubmission(submissionId: $id) {
      id
      procedure {
        id
        scheduledAt
        patientName
        patientPhoneNumber
        submissionId
        covidScreeningInviteSentAt
        covidScreeningInviteSMSCount
        lastCovidScreeningInviteSMS {
          id
          type
          timestamp
          patientPin
          channel
          text
          status
          content
          exchangeId
        }
      }
      submission {
        ...SubmissionData
      }
    }
  }
  ${fragments.all}
`;

export const covidScreeningQuestionQuery = gql`
  query covidScreening {
    covidScreening {
      id
      question
    }
  }
`;

export const enableCovidScreening = gql`
  mutation setCovidScreeningEnabled($id: Long!, $enabled: Boolean!) {
    setCovidScreeningEnabled(id: $id, enabled: $enabled)
  }
`;

export const sendCovidScreeningInvitation = gql`
  mutation sendCovidScreeningInvitation($procedureId: Long!) {
    sendCovidScreeningInvitation(procedureId: $procedureId)
  }
`;

export default {
  list,
  item,
};
