import React from 'react';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import { ScopeProvider } from '../../../contexts/ScopeContext';
import LegacyPredefinedForm from './print/LegacyPredefinedForm';
import ErrorBoundary from '../../../se/components/ErrorBoundary';
import queryString from 'query-string';
import { gql, useSubscription } from '@apollo/client';
import { AllChartingData, procedureBaseFields } from '../../../graph/patientChart';
import ChartingQuestionnairesContext from '../../../contexts/ChartingQuestionnairesContext';

const PrintRouter = ({ match, location }) => {
  const queryParams = queryString.parse(location.search);

  const { data: patientData } = useSubscription(
    gql`
    query procedure($procedureId: Long!) {
      procedure(id: $procedureId) {
        ${procedureBaseFields}
        ${AllChartingData}
      }
    }
  `,
    {
      variables: { procedureId: Number(queryParams.procedureId) },
      skip: !isFinite(Number(queryParams.procedureId)),
    }
  );

  if (!patientData) {
    return null;
  }

  return (
    <ChartingQuestionnairesContext.Provider
      value={{
        preOpCharts: patientData.procedure?.preOpCharts || [],
        orCharts: patientData.procedure?.orCharts || [],
        pacuCharts: patientData.procedure?.pacuCharts || [],
        physicianCharts: patientData.procedure?.physicianCharts || [],
        anesthesiologistPreOpCharts: patientData.procedure?.anesthesiologistPreOpCharts || [],
        anesthesiologistOrCharts: patientData.procedure?.anesthesiologistOrCharts || [],
      }}
    >
      <ErrorBoundary>
        <ScopeProvider>
          <Route path={`${match.path}/legacy-predefined-form`} component={LegacyPredefinedForm} />
        </ScopeProvider>
      </ErrorBoundary>
    </ChartingQuestionnairesContext.Provider>
  );
};

export default withRouter(PrintRouter);
