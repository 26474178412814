import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import clone from 'lodash/cloneDeep';
import set from 'lodash/set';

import { string } from '../utilities/random';
import { logError } from '../utilities/error';
import { isFunction, isString } from '../utilities/check';
import { getNestedValue } from '../utilities/data/object';

import Button from './Button';
import FormError, { ErrorMessageWrapper } from './errors/FormError';
import LinkButton from './LinkButton';

const randomString = string();

export const Context = React.createContext();

// TODO: Use this form to add to modal, initialize default state with procedureData you get when click on row
export default class Form extends PureComponent {
  static propTypes = {
    input: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    Footer: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.initialValue === undefined ? props.input.defaultValue : props.initialValue,
      busy: false,
      isDirty: false,
    };

    this.mounted = false;
    this.formId = randomString();
    this.originalValue = clone(this.state.value);

    this.keyCodeInputHandlers = {
      27: this.handleInputEscKeyEvent,
    };
  }

  handleInputEscKeyEvent = (event, keyPath) => {
    const value = { ...this.state.value };
    if (keyPath) {
      set(value, keyPath, getNestedValue(keyPath, this.originalValue));
    }
    this.handleChange(value);
    event.target.blur();
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleReset = () => {
    this.setState({ value: this.props.initialValue });
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({
      busy: true,
      hasError: false,
      error: undefined,
      errors: undefined,
    });

    try {
      await this.props.onSubmit(this.state.value);
      this.originalValue = clone(this.state.value);
      if (this.mounted) {
        this.setState({ isDirty: false });
        !this.props.withoutReset && this.handleReset();
      }
    } catch (error) {
      logError(error);

      const getFirst = values => {
        if (values && values.length > 0) {
          return values[0].message;
        }
      };

      const errors = getNestedValue('error', error) || [{ message: getNestedValue('message', error) }];

      this.setState({
        busy: false,
        hasError: true,
        error: getFirst(errors),
        errors: errors,
      });
    } finally {
      if (this.mounted) {
        this.setState({ busy: false });
      }
    }
  };

  handleChange = value => {
    const isDirty = JSON.stringify(value) !== JSON.stringify(this.originalValue);
    this.setState({ value, isDirty });
  };

  handleOnBusy = value => this.setState({ busy: value });
  handleOnError = ({ hasError, error, errors }) => {
    this.setState({ hasError, error, errors });
  };

  handleInputKeyDown = (event, ...rest) => {
    const handler = this.keyCodeInputHandlers[event.keyCode];
    if (isFunction(handler)) {
      handler(event, ...rest);
    }
  };

  render() {
    const {
      input: Input = () => null,
      inputProps = {},
      Footer,
      Error = FormError,
      autoFocus,
      label,
      isTransparent,
      context,
      onCancel,
      disabled,
    } = this.props;
    const { value, busy, isDirty, hasError, error } = this.state;
    return (
      <Context.Provider value={context}>
        <Fragment>
          {hasError && (
            <Error isVisible={hasError} id={this.formId}>
              <ErrorMessageWrapper>
                {isString(error)
                  ? error
                  : 'An unexpected error has occurred.\nPlease, try again or contact our support.'}
              </ErrorMessageWrapper>
            </Error>
          )}
          <form onSubmit={this.handleSubmit}>
            <Input
              autoFocus={autoFocus}
              rootValue={value}
              value={value}
              onChange={this.handleChange}
              disabled={busy || disabled}
              error={error}
              formId={this.formId}
              isDirty={isDirty}
              isTransparent={isTransparent}
              onKeyDown={this.handleInputKeyDown}
              {...inputProps}
            />
            {Footer ? (
              <Footer
                {...this.props}
                busy={busy || disabled}
                isDirty={isDirty}
                onBusy={this.handleOnBusy}
                onError={this.handleOnError}
                isTransparent={isTransparent}
                onCancel={onCancel || this.handleReset}
                disabled={disabled}
              />
            ) : (
              <Fragment>
                <Button
                  primary
                  busy={busy}
                  disabled={disabled || busy}
                  style={{ width: this.props.buttonFullWidth ? '100%' : 'inherit' }}
                >
                  {label}
                </Button>
                {onCancel && (
                  <LinkButton onClick={onCancel} style={{ marginLeft: '0.5em' }}>
                    Cancel
                  </LinkButton>
                )}
              </Fragment>
            )}
          </form>
        </Fragment>
      </Context.Provider>
    );
  }
}
