import React, { createContext, FC, useContext, useState } from 'react';
import StaffMemberT, { OtherSpecialty, PrimarySpecialty, Title } from '../../../../../types/StaffMember';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { StaffShiftScope } from './StaffShiftContext';
import { useMutation } from '@apollo/client';
import {
  assignStaffShiftProcedure,
  assignStaffShiftRoom,
  editOpenPositionProcedure,
  editOpenPositionRoom,
  listForShift,
  removeStaffShiftProcedure,
  removeStaffShiftRoom,
  sendNewOpenPositionNotification,
  setStaffShiftProcedureAdditionalText,
  setStaffShiftProcedureFrom,
  setStaffShiftProcedureTo,
  setStaffShiftProcedureWorkingTime,
  setStaffShiftRoomAdditionalText,
  setStaffShiftRoomFrom,
  setStaffShiftRoomTo,
  setStaffShiftRoomWorkingTime,
} from '../../../../../graph/staff';
import { StaffMemberScheduleAccessEditor } from '../../../../entities/staff/StaffMembers';
import { HiringManagerContact } from './OpenPositionNotification';
import { getOpenPositionId } from './OpenPositionForm';
import { ShiftType } from '../../../../../types/StaffShift';

interface StaffSlotContextData {
  staffMembers: {
    list: TypedDocumentNode<{ staffMembers: StaffMemberT[] }, StaffShiftScope>;
    variables: StaffShiftScope;
  };
  assign: (id: number, staffId?: number) => Promise<void>;
  remove: (id: number, shiftHospitalId: number, staffHospitalId: number) => Promise<void>;
  setFrom: (id: number, from?: string) => Promise<void>;
  setTo: (id: number, to?: string) => Promise<void>;
  setWorkingTime: (id: number, from?: string, to?: string) => Promise<void>;
  setAdditionalText: (id: number, additionalText?: string) => Promise<void>;
  editOpenPosition: (
    id: number,
    title?: string,
    primarySpecialty?: PrimarySpecialty,
    otherSpecialities?: OtherSpecialty[],
    description?: string
  ) => Promise<void>;
  openPositionDialog: boolean;
  openOpenPositionDialog: () => void;
  closeOpenPositionDialog: () => void;
  openAccessEditor: (id: StaffMemberT) => void;
  doSendNotification: (shiftId: number, contacts: HiringManagerContact[], message: string) => void;
}

const StaffSlotContext = createContext<StaffSlotContextData>({
  staffMembers: {} as any,
  assign: async () => {},
  remove: async () => {},
  setFrom: async () => {},
  setTo: async () => {},
  setWorkingTime: async () => {},
  setAdditionalText: async () => {},
  editOpenPosition: async () => {},
  openPositionDialog: false,
  openOpenPositionDialog: () => {},
  closeOpenPositionDialog: () => {},
  openAccessEditor: () => {},
  doSendNotification: () => {},
});

export function useStaffSlotContext() {
  return useContext(StaffSlotContext);
}

interface StaffSlotProcedureContextProps {
  hospitalId: number;
  date: string;
  procedureId: number;
  children: React.ReactNode;
}

export const StaffSlotProcedureContext: FC<StaffSlotProcedureContextProps> = ({
  hospitalId,
  date,
  procedureId,
  children,
}) => {
  const [doAssign] = useMutation(assignStaffShiftProcedure);
  const [doRemove] = useMutation(removeStaffShiftProcedure);
  const [setFromMutation] = useMutation(setStaffShiftProcedureFrom);
  const [setToMutation] = useMutation(setStaffShiftProcedureTo);
  const [setWorkingTimeMutation] = useMutation(setStaffShiftProcedureWorkingTime);
  const [setAdditionalTextMutation] = useMutation(setStaffShiftProcedureAdditionalText);
  const [editOpenPositionMutation] = useMutation(editOpenPositionProcedure);
  const [staffToEditAccess, setStaffToEditAccess] = useState<StaffMemberT | null>(null);

  const assign = async (id: number, staffId?: number) => {
    await doAssign({
      variables: {
        id,
        shiftHospitalId: hospitalId,
        staffHospitalId: hospitalId,
        staffId,
      },
    });
  };

  const remove = async (id: number) => {
    await doRemove({
      variables: {
        id,
        shiftHospitalId: hospitalId,
        staffHospitalId: hospitalId,
      },
    });
  };

  const setFrom = async (id: number, from?: string) => {
    await setFromMutation({
      variables: {
        id,
        from,
      },
    });
  };

  const setAdditionalText = async (id: number, additionalText?: string) => {
    await setAdditionalTextMutation({
      variables: {
        id,
        additionalText,
      },
    });
  };

  const setTo = async (id: number, to?: string) => {
    await setToMutation({
      variables: {
        id,
        to,
      },
    });
  };

  const setWorkingTime = async (id: number, from?: string, to?: string) => {
    await setWorkingTimeMutation({
      variables: {
        id,
        from,
        to,
      },
    });
  };

  const [openPositionDialog, setOpenPositionDialog] = useState(false);

  const openOpenPositionDialog = () => setOpenPositionDialog(true);
  const closeOpenPositionDialog = () => setOpenPositionDialog(false);

  const editOpenPosition = async (
    id: number,
    title?: Title,
    primarySpecialty?: PrimarySpecialty,
    otherSpecialties?: OtherSpecialty[],
    description?: string
  ) => {
    await editOpenPositionMutation({
      variables: {
        id,
        title,
        primarySpecialty,
        otherSpecialties,
        description,
      },
    });
  };

  const openAccessEditor = (staffMember: StaffMemberT) => {
    setStaffToEditAccess(staffMember);
  };

  const [doSend] = useMutation(sendNewOpenPositionNotification);

  const doSendNotification = async (shiftId: number, contacts: HiringManagerContact[], message: string) => {
    await doSend({
      variables: {
        openPositionId: getOpenPositionId(ShiftType.Procedure, shiftId, hospitalId),
        hiringManagersContacts: contacts,
        message,
      },
    });
  };

  const context = {
    staffMembers: {
      list: listForShift,
      variables: { procedureId, date },
    },
    assign,
    remove,
    setFrom,
    setTo,
    setWorkingTime,
    setAdditionalText,
    openPositionDialog,
    openOpenPositionDialog,
    closeOpenPositionDialog,
    editOpenPosition,
    openAccessEditor,
    doSendNotification,
  };

  return (
    <>
      <StaffSlotContext.Provider value={context}>{children}</StaffSlotContext.Provider>
      {staffToEditAccess && (
        <StaffMemberScheduleAccessEditor
          staffMember={staffToEditAccess}
          onClose={() => setStaffToEditAccess(null)}
          refetchQueries={[]}
        />
      )}
    </>
  );
};

interface StaffSlotRoomContextProps {
  hospitalId: number;
  roomId: number;
  date: string;
  children: React.ReactNode;
}

export const StaffSlotRoomContext: FC<StaffSlotRoomContextProps> = ({ hospitalId, roomId, date, children }) => {
  const [doAssign] = useMutation(assignStaffShiftRoom);
  const [doRemove] = useMutation(removeStaffShiftRoom);
  const [setFromMutation] = useMutation(setStaffShiftRoomFrom);
  const [setToMutation] = useMutation(setStaffShiftRoomTo);
  const [setWorkingTimeMutation] = useMutation(setStaffShiftRoomWorkingTime);
  const [setAdditionalTextMutation] = useMutation(setStaffShiftRoomAdditionalText);
  const [editOpenPositionMutation] = useMutation(editOpenPositionRoom);
  const [staffToEditAccess, setStaffToEditAccess] = useState<StaffMemberT | null>(null);

  const assign = async (id: number, staffId?: number) => {
    await doAssign({
      variables: {
        id,
        shiftHospitalId: hospitalId,
        staffHospitalId: hospitalId,
        staffId,
      },
    });
  };

  const remove = async (id: number, shiftHospitalId: number, staffHospitalId: number) => {
    await doRemove({
      variables: {
        id,
        shiftHospitalId,
        staffHospitalId,
      },
    });
  };

  const setFrom = async (id: number, from?: string) => {
    await setFromMutation({
      variables: {
        id,
        from,
      },
    });
  };
  const setAdditionalText = async (id: number, additionalText?: string) => {
    await setAdditionalTextMutation({
      variables: {
        id,
        additionalText,
      },
    });
  };

  const setTo = async (id: number, to?: string) => {
    await setToMutation({
      variables: {
        id,
        to,
      },
    });
  };

  const setWorkingTime = async (id: number, from?: string, to?: string) => {
    await setWorkingTimeMutation({
      variables: {
        id,
        from,
        to,
      },
    });
  };

  const [openPositionDialog, setOpenPositionDialog] = useState(false);

  const openOpenPositionDialog = () => setOpenPositionDialog(true);
  const closeOpenPositionDialog = () => setOpenPositionDialog(false);

  const editOpenPosition = async (
    id: number,
    title?: Title,
    primarySpecialty?: PrimarySpecialty,
    otherSpecialties?: OtherSpecialty[],
    description?: string
  ) => {
    await editOpenPositionMutation({
      variables: {
        id,
        title,
        primarySpecialty,
        otherSpecialties,
        description,
      },
    });
  };

  const openAccessEditor = (staffMember: StaffMemberT) => {
    setStaffToEditAccess(staffMember);
  };

  const [doSend] = useMutation(sendNewOpenPositionNotification);

  const doSendNotification = async (shiftId: number, contacts: HiringManagerContact[], message: string) => {
    await doSend({
      variables: {
        openPositionId: getOpenPositionId(ShiftType.Room, shiftId, hospitalId),
        hiringManagersContacts: contacts,
        message,
      },
    });
  };

  const context = {
    staffMembers: {
      list: listForShift,
      variables: { roomId, date },
    },
    assign,
    remove,
    setFrom,
    setTo,
    setWorkingTime,
    setAdditionalText,
    editOpenPosition,
    openPositionDialog,
    openOpenPositionDialog,
    closeOpenPositionDialog,
    openAccessEditor,
    doSendNotification,
  };

  return (
    <>
      <StaffSlotContext.Provider value={context}>{children}</StaffSlotContext.Provider>
      {staffToEditAccess && (
        <StaffMemberScheduleAccessEditor
          staffMember={staffToEditAccess}
          onClose={() => setStaffToEditAccess(null)}
          refetchQueries={[]}
        />
      )}
    </>
  );
};
