import { FormType } from '../../../types/Form';

export const InstructionTemplates = {
  HandAndFootSurgeryInstructions: 'HandAndFootSurgeryInstructions',
  GynecologySurgeryInstructions: 'GynecologySurgeryInstructions',
  ArthroscopicSurgeryInstructions: 'ArthroscopicSurgeryInstructions',
  PainManagementSurgeryInstructions: 'PainManagementSurgeryInstructions',
  PediatricSurgeryInstructions: 'PediatricSurgeryInstructions',
  GeneralSurgeryInstructions: 'GeneralSurgeryInstructions',
  CovidTestSurgeryInstructions: 'CovidTestSurgeryInstructions',
};

export const instructionTemplateLabels = {
  [InstructionTemplates.HandAndFootSurgeryInstructions]: 'Hand And Foot Pre-Op Instructions',
  [InstructionTemplates.GynecologySurgeryInstructions]: 'Gynecology Pre-Op Instructions',
  [InstructionTemplates.ArthroscopicSurgeryInstructions]: 'Arthroscopic Pre-Op Instructions',
  [InstructionTemplates.PainManagementSurgeryInstructions]: 'Pre-Op Instructions For Pain Management',
  [InstructionTemplates.PediatricSurgeryInstructions]: 'Pediatric Pre-Op Instructions',
  [InstructionTemplates.GeneralSurgeryInstructions]: 'General Pre-Op Instructions',
  [InstructionTemplates.CovidTestSurgeryInstructions]: 'Covid Test Appointment Instructions',
};

export const InstructionTemplatesView2Hospital15 = {
  PreOpAbdominoplastySurgeryInstructions: 'PreOpAbdominoplastySurgeryInstructions',
  PostOpAbdominoplastySurgeryInstructions: 'PostOpAbdominoplastySurgeryInstructions',
  BlepharoplastySurgeryInstructions: 'BlepharoplastySurgeryInstructions',
  BreastAugmentationSurgeryInstructions: 'BreastAugmentationSurgeryInstructions',
  BreastLiftSurgeryInstructions: 'BreastLiftSurgeryInstructions',
  BreastReductionSurgeryInstructions: 'BreastReductionSurgeryInstructions',
  CalfImplantSurgeryInstructions: 'CalfImplantSurgeryInstructions',
  ChinOrJawAugmentationSurgeryInstructions: 'ChinOrJawAugmentationSurgeryInstructions',
  FaceLiftSurgeryInstructions: 'FaceLiftSurgeryInstructions',
  FacialContourSurgeryInstructions: 'FacialContourSurgeryInstructions',
  PreOpFacialLaserResurfacingCO2LaserSurgeryInstructions: 'PreOpFacialLaserResurfacingCO2LaserSurgeryInstructions',
  PostOpFacialLaserResurfacingCO2LaserSurgeryInstructions: 'PostOpFacialLaserResurfacingCO2LaserSurgeryInstructions',
  HairRestorationPreopInstructions: 'HairRestorationPreopInstructions',
};

export const instructionTemplateLabelsView2Hospital15 = {
  [InstructionTemplatesView2Hospital15.PreOpAbdominoplastySurgeryInstructions]:
    'Tummy Tuck (Abdominoplasty) Pre-op Instructions',
  [InstructionTemplatesView2Hospital15.PostOpAbdominoplastySurgeryInstructions]:
    'Tummy Tuck (Abdominoplasty) Post-op Instructions',
  [InstructionTemplatesView2Hospital15.BlepharoplastySurgeryInstructions]:
    'Eyelid Surgery (Blepharoplasty) Post-Op Instructions',
  [InstructionTemplatesView2Hospital15.BreastAugmentationSurgeryInstructions]:
    'Breast Augmentation Post-op Instructions',
  [InstructionTemplatesView2Hospital15.BreastLiftSurgeryInstructions]: 'Breast Lift (Mastopexy) Post-Op Instructions',
  [InstructionTemplatesView2Hospital15.BreastReductionSurgeryInstructions]: 'Breast Reduction Post-Op Instructions',
  [InstructionTemplatesView2Hospital15.CalfImplantSurgeryInstructions]: 'Calf Implant Post-Op Instructions',
  [InstructionTemplatesView2Hospital15.ChinOrJawAugmentationSurgeryInstructions]:
    'Chin or Jaw Augmentation Post-Op Instructions',
  [InstructionTemplatesView2Hospital15.FaceLiftSurgeryInstructions]: 'Face & Neck Lift Post-Op Instructions',
  [InstructionTemplatesView2Hospital15.FacialContourSurgeryInstructions]: 'Facial Contour Post-Op Instructions',
  [InstructionTemplatesView2Hospital15.PreOpFacialLaserResurfacingCO2LaserSurgeryInstructions]:
    'Facial Laser Resurfacing (CO2 laser) Pre-Op Instructions',
  [InstructionTemplatesView2Hospital15.PostOpFacialLaserResurfacingCO2LaserSurgeryInstructions]:
    'Facial Laser Resurfacing (CO2 laser) Post-Op Instructions',
  [InstructionTemplatesView2Hospital15.HairRestorationPreopInstructions]: 'Hair Restoration Pre-op Instructions',
};

export const InstructionTemplatesViewHospital2 = {
  GynecologySurgeryInstructions: 'GynecologySurgeryInstructions',
  HandAndFootSurgeryInstructions: 'HandAndFootSurgeryInstructions',
  EyeLaserSurgeryInstructions: 'EyeLaserSurgeryInstructions',
  ArthroscopicSurgeryInstructions: 'ArthroscopicSurgeryInstructions',
  PainManagementSurgeryInstructions: 'PainManagementSurgeryInstructions',
  PediatricSurgeryInstructions: 'PediatricSurgeryInstructions',
  GeneralSurgeryInstructions: 'GeneralSurgeryInstructions',
  SpanishPediatricSurgeryInstructions: 'SpanishPediatricSurgeryInstructions',
  SpanishPainSurgeryInstructions: 'SpanishPainSurgeryInstructions',
  SpanishArthroscopicSurgeryInstructions: 'SpanishArthroscopicSurgeryInstructions',
  SpanishEyeLaserSurgeryInstructions: 'SpanishEyeLaserSurgeryInstructions',
  SpanishHandAndFootSurgeryInstructions: 'SpanishHandAndFootSurgeryInstructions',
  SpanishGeneralSurgeryInstructions: 'SpanishGeneralSurgeryInstructions',
};

export const instructionTemplateLabelsViewHospital2 = {
  [InstructionTemplatesViewHospital2.GynecologySurgeryInstructions]: 'GI Instruction',
  [InstructionTemplatesViewHospital2.HandAndFootSurgeryInstructions]: 'Hand And Foot Pre-Op Instructions',
  [InstructionTemplatesViewHospital2.EyeLaserSurgeryInstructions]: 'Eye Laser Pre-Op Instructions',
  [InstructionTemplatesViewHospital2.ArthroscopicSurgeryInstructions]: 'Arthroscopic Pre-Op Instructions',
  [InstructionTemplatesViewHospital2.PainManagementSurgeryInstructions]: 'Pre-Op Instructions For Pain Management',
  [InstructionTemplatesViewHospital2.PediatricSurgeryInstructions]: 'Pediatric Pre-Op Instructions',
  [InstructionTemplatesViewHospital2.GeneralSurgeryInstructions]: 'General Pre-Op Instructions',
  [InstructionTemplatesViewHospital2.SpanishPediatricSurgeryInstructions]: 'Pediatric Pre-Op Instructions (Spanish)',
  [InstructionTemplatesViewHospital2.SpanishPainSurgeryInstructions]:
    'Pre-Op Instructions For Pain Management (Spanish)',
  [InstructionTemplatesViewHospital2.SpanishArthroscopicSurgeryInstructions]:
    'Arthroscopic Pre-Op Instructions (Spanish)',
  [InstructionTemplatesViewHospital2.SpanishEyeLaserSurgeryInstructions]: 'Eye Laser Pre-Op Instructions (Spanish)',
  [InstructionTemplatesViewHospital2.SpanishHandAndFootSurgeryInstructions]:
    'Hand And Foot Pre-Op Instructions (Spanish)',
  [InstructionTemplatesViewHospital2.SpanishGeneralSurgeryInstructions]: 'General Pre-Op Instructions (Spanish)',
};

export const InstructionTemplatesView2Hospital22 = {
  HandAndFootSurgeryInstructions: 'HandAndFootSurgeryInstructions',
  GynecologySurgeryInstructions: 'GynecologySurgeryInstructions',
  ArthroscopicSurgeryInstructions: 'ArthroscopicSurgeryInstructions',
  PainManagementSurgeryInstructions: 'PainManagementSurgeryInstructions',
  PediatricSurgeryInstructions: 'PediatricSurgeryInstructions',
  GeneralSurgeryInstructions: 'GeneralSurgeryInstructions',
  CovidTestSurgeryInstructions: 'CovidTestSurgeryInstructions',
  SpanishGeneralSurgeryInstructions: 'SpanishGeneralSurgeryInstructions',
  SpanishHandAndFootSurgeryInstructions: 'SpanishHandAndFootSurgeryInstructions',
};

export const instructionTemplateLabelsView2Hospital22 = {
  [InstructionTemplatesView2Hospital22.HandAndFootSurgeryInstructions]: 'Hand And Foot Pre-Op Instructions',
  [InstructionTemplatesView2Hospital22.GynecologySurgeryInstructions]: 'Gynecology Pre-Op Instructions',
  [InstructionTemplatesView2Hospital22.ArthroscopicSurgeryInstructions]: 'Arthroscopic Pre-Op Instructions',
  [InstructionTemplatesView2Hospital22.PainManagementSurgeryInstructions]: 'Pre-Op Instructions For Pain Management',
  [InstructionTemplatesView2Hospital22.PediatricSurgeryInstructions]: 'Pediatric Pre-Op Instructions',
  [InstructionTemplatesView2Hospital22.GeneralSurgeryInstructions]: 'General Pre-Op Instructions',
  [InstructionTemplatesView2Hospital22.CovidTestSurgeryInstructions]: 'Covid Test Appointment Instructions',
  [InstructionTemplatesView2Hospital22.SpanishGeneralSurgeryInstructions]: 'GLP-1 PreOperative Instructions',
  [InstructionTemplatesViewHospital2.SpanishHandAndFootSurgeryInstructions]: 'Pre Surgery Instructions/ERAS Protocol',
};

export const InstructionTemplatesViewHospital55 = {
  CovidTestSurgeryInstructions: 'CovidTestSurgeryInstructions',
  HandAndFootSurgeryInstructions: 'HandAndFootSurgeryInstructions',
  GynecologySurgeryInstructions: 'GynecologySurgeryInstructions',
  ArthroscopicSurgeryInstructions: 'ArthroscopicSurgeryInstructions',
  PainManagementSurgeryInstructions: 'PainManagementSurgeryInstructions',
  PediatricSurgeryInstructions: 'PediatricSurgeryInstructions',
  GeneralSurgeryInstructions: 'GeneralSurgeryInstructions',
};

export const instructionTemplateLabelsViewHospital55 = {
  [InstructionTemplates.CovidTestSurgeryInstructions]: 'Voicemail Notification',
  [InstructionTemplates.HandAndFootSurgeryInstructions]: 'Hand And Foot Pre-Op Instructions',
  [InstructionTemplates.GynecologySurgeryInstructions]: 'Gynecology Pre-Op Instructions',
  [InstructionTemplates.ArthroscopicSurgeryInstructions]: 'Arthroscopic Pre-Op Instructions',
  [InstructionTemplates.PainManagementSurgeryInstructions]: 'Pre-Op Instructions For Pain Management',
  [InstructionTemplates.PediatricSurgeryInstructions]: 'Pediatric Pre-Op Instructions',
  [InstructionTemplates.GeneralSurgeryInstructions]: 'General Pre-Op Instructions',
};

export const formSaveTypeLabels = {
  Filled: 'Patient Forms',
  Uploaded: 'Uploaded Photos',
  Pdf: 'Pdf Forms',
};

export const formTypeLabels = {
  [FormType.PreOpForm]: 'PreOp Forms',
  [FormType.InTakeForm]: 'Registration Packages',
  [FormType.PreOpChartForm]: 'PreOp Chart Forms',
  [FormType.OrChartForm]: 'Or Chart Forms',
  [FormType.PacuChartForm]: 'PACU Chart Forms',
  [FormType.PhysicianChartForm]: 'Physician Chart Forms',
  [FormType.AnesthesiologistPreOpChartForm]: 'Anesthesia PreOp Chart Forms',
  [FormType.AnesthesiologistOrChartForm]: 'Anesthesia Or Chart Forms',
  [FormType.AnesthesiologistPacuChartForm]: 'Anesthesia PACU Chart Forms',
};
