import React from 'react';
import PropTypes from 'prop-types';
import Form from '../se/components/Form';
import { Mutation } from '@apollo/client/react/components';

const SimpleMutationForm = ({ scheme, Input, value, onChange, onSubmit, label = 'Update' }) => (
  <Mutation mutation={scheme.update}>
    {(mutate, { loading }) => (
      <Form autoFocus label={label} input={Input(value, onChange)} onSubmit={onSubmit(mutate)} busy={loading} />
    )}
  </Mutation>
);

SimpleMutationForm.propTypes = {
  scheme: PropTypes.object.isRequired,
  Input: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default SimpleMutationForm;
