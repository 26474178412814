import { graphql } from '@apollo/client/react/hoc';
import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import get from 'lodash/get';
import { compose, mapProps, withState } from 'recompose';
import { addMilliseconds, differenceInMilliseconds, format, getTime, isAfter, parse, subMilliseconds } from 'date-fns';
import { signalLogForPatient } from '../../../graph/signal';
import identity from 'lodash/identity';
import Big from 'big.js';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { getNestedValue } from '../../../se/utilities/data/object';
import { intermittentReduce } from './intermittentOps';
import Form from '../../../se/components/forms/InlineForm';
import NumberInput from '../../../se/components/inputs/NumberInput';
import { withLabel } from '../../../se/components/Label';
import LinkButton from '../../../se/components/LinkButton';
import Spinner from '../../../se/components/Spinner';
import Button from '../../../se/components/Button';
import { ZoomOut } from '@material-ui/icons';

const Container = styled.div`
  margin-top: 2em;
  width: 100%;
  height: 38rem;

  svg * {
    user-select: none;
  }

  position: relative;

  > .zoomOut {
    position: absolute;
    right: 3rem;
    top: 10.75rem;
    z-index: 1000;

    > i {
      font-size: 3rem;
    }
  }
`;

const SWInput = withLabel('Sliding Window')(NumberInput);

const colors = ['#5D8AA8', '#E32636', '#A4C639', '#FFBF00', '#FF9966', '#89CFF0', '#960018', '#ACE1AF', '#E7FEFF'];

const colorAt = idx => colors[idx % colors.length];

const opacityForLine = (gw, highlighted) => (!!highlighted ? (gw === highlighted ? 1 : 0.5) : 1);
const strokeForLine = (gw, highlighted) => (!!highlighted ? (gw === highlighted ? 1.5 : 2) : 1.5);

const leftpad = n => ('  ' + n).substr(String(n).length - 1);

const ProgressBar = ({ progress }) => (
  <div style={{ display: 'inline-flex', verticalAlign: 'top' }}>
    <p
      style={{ fontFamily: 'monospace', fontSize: '1.4rem', lineHeight: '1rem', fontWeight: '600', whiteSpace: 'pre' }}
    >
      {leftpad(Math.round(progress * 100))}%
    </p>
    <div
      style={{
        width: '6rem',
        height: '0.10rem',
        backgroundColor: 'rgba(255, 255, 255, 0.33)',
        borderRadius: '0.05rem',
        alignSelf: 'center',
        marginLeft: '0.5rem',
      }}
    >
      <div
        style={{
          width: `${progress * 100}%`,
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRadius: '0.1rem',
        }}
      />
    </div>
  </div>
);

class BackgroundTransformation extends React.PureComponent {
  state = {
    progress: 0,
    data: null,
  };

  componentDidMount() {
    this.prepareData();
  }

  componentWillUnmount() {
    this.stopDataPreparation();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.slidingWindow !== prevProps.slidingWindow ||
      this.props.useWattAveraging !== prevProps.useWattAveraging
    ) {
      this.stopDataPreparation();
      this.prepareData();
    }
  }

  updateProgress = (offset, factor) => progress => {
    this.setState({
      progress: offset + progress * factor,
      data: null,
    });
  };

  cache = {};

  cached = (key, computeValue, renderValue) => {
    if (key in this.cache) {
      renderValue(this.cache[key]);
    } else {
      computeValue(value => {
        this.cache[key] = value;
        renderValue(value);
      });
    }
  };

  prepareData = () => {
    const { signalLogForPatient, slidingWindow, useWattAveraging } = this.props;

    this.cached(
      slidingWindow + '-' + useWattAveraging,
      setData => {
        const signals = _.sortBy(signalLogForPatient, 'receivedAt');
        const signalsLength = signals.length;
        const from = parse(signals[0].receivedAt);
        const to = parse(signals[signalsLength - 1].receivedAt);
        const resolution = 2000;
        const samples = [...Array(Math.ceil(differenceInMilliseconds(to, from) / resolution))].map((_, index) =>
          addMilliseconds(from, resolution * index)
        );

        const f = useWattAveraging ? db => Math.pow(10, db / 10) : identity;
        const g = useWattAveraging ? w => 10 * Math.log10(w) : identity;

        this.stopDataPreparation = intermittentReduce(
          samples,
          {
            windowStartIndex: 0,
            windowEndIndex: 0,
            windows: {},
            result: [],
          },
          (acc, sample, index) => {
            while (
              acc.windowEndIndex < signalsLength - 1 &&
              !isAfter(parse(signals[acc.windowEndIndex + 1].receivedAt), sample)
            ) {
              const signal = signals[acc.windowEndIndex];
              const window = acc.windows[signal.gatewayId] || { sum: new Big(0), count: 0 };
              window.sum = window.sum.plus(f(signal.signalStrength));
              window.count += 1;
              acc.windows[signal.gatewayId] = window;
              acc.windowEndIndex += 1;
            }

            while (
              acc.windowStartIndex < acc.windowEndIndex &&
              !isAfter(parse(signals[acc.windowStartIndex + 1].receivedAt), subMilliseconds(sample, slidingWindow))
            ) {
              const signal = signals[acc.windowStartIndex];
              const window = acc.windows[signal.gatewayId];
              window.sum = window.sum.minus(f(signal.signalStrength));
              window.count -= 1;
              acc.windows[signal.gatewayId] = window;
              acc.windowStartIndex += 1;
            }

            const value = {
              timestamp: getTime(sample),
            };

            Object.entries(acc.windows).forEach(([gatewayId, { sum, count }]) => {
              if (count > 0) {
                value[gatewayId] = g(parseFloat(sum.div(count).toExponential(20)));
              }
            });

            if (
              acc.result.length === 0 ||
              index === samples.length - 1 ||
              !_.isEqual(_.omit(acc.result[acc.result.length - 1], 'timestamp'), _.omit(value, 'timestamp'))
            ) {
              acc.result.push(value);
            }

            return acc;
          },
          this.updateProgress(0, 1),
          ({ windows, result }) => {
            const gws = Object.keys(windows);

            setData({
              data: result,
              colors: gws.reduce((acc, gw, idx) => {
                acc[gw] = colorAt(idx);
                return acc;
              }, {}),
              gateways: gws,
            });
          }
        );
      },
      data => {
        this.setState({
          progress: null,
          data,
        });
      }
    );
  };

  stopDataPreparation = () => {};

  render() {
    const { style, children } = this.props;
    const { progress, data } = this.state;

    if (progress === null) {
      return children(data);
    } else {
      return (
        <div style={style}>
          Preparing data… <ProgressBar progress={progress} />
        </div>
      );
    }
  }
}

class SignalAnalysis extends React.PureComponent {
  state = {
    highlighted: null,
    toggledOff: {},
  };

  isInactive = gw => this.state.toggledOff[gw] !== undefined && this.state.toggledOff[gw];
  isActive = gw => !this.state.toggledOff[gw];

  handleLegendMouseEnter = ({ dataKey }) => {
    if (this.isActive(dataKey)) {
      this.setState({ highlighted: dataKey });
    }
  };
  handleLegendMouseLeave = () => this.setState({ highlighted: null });
  handleLegendItemClick = ({ dataKey }) => {
    const { toggledOff } = this.state;
    this.setState({ toggledOff: { ...toggledOff, [dataKey]: !toggledOff[dataKey] } });
  };

  getLabel = gw => {
    const { allGateways } = this.props;
    const gateway = allGateways.find(_ => _.id === gw);
    const room = getNestedValue('room.name', gateway);
    return `${gw} [${getNestedValue('threshold', gateway)}] ${room ? `(${room})` : ''}`;
  };

  getLegendPayload = (gateways, colors) =>
    gateways.map(gw => ({
      id: gw,
      dataKey: gw,
      value: this.getLabel(gw),
      color: colors[gw],
      type: 'triangle',
      inactive: this.isInactive(gw),
    }));

  handleMouseDown = e => {
    if (e) {
      this.setState({ refAreaLeft: e.activeLabel });
    }
  };

  handleMouseMove = e => {
    if (e && this.state.refAreaLeft) {
      this.setState({ refAreaRight: e.activeLabel });
    }
  };

  zoom = () => {
    let { refAreaLeft, refAreaRight } = this.state;

    if (refAreaLeft === refAreaRight || refAreaRight === '') {
      this.setState(() => ({
        refAreaLeft: null,
        refAreaRight: null,
      }));
      return;
    }

    if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    this.setState(() => ({
      refAreaLeft: null,
      refAreaRight: null,
      left: refAreaLeft,
      right: refAreaRight,
    }));
  };

  render() {
    const {
      signalLogForPatient = [],
      loading,
      slidingWindow,
      setSlidingWindow,
      useWattAveraging,
      setUseWattAveraging,
    } = this.props;
    const { highlighted, refAreaLeft, refAreaRight, left, right } = this.state;
    const style = { marginTop: '4em' };

    if (loading) {
      return (
        <p style={style}>
          Loading data… <Spinner size="1rem" style={{ verticalAlign: 'bottom' }} />
        </p>
      );
    } else if (signalLogForPatient.length === 0) {
      return <p style={style}>No data yet.</p>;
    } else {
      return (
        <BackgroundTransformation
          signalLogForPatient={signalLogForPatient}
          slidingWindow={slidingWindow}
          useWattAveraging={useWattAveraging}
          style={style}
        >
          {({ data, gateways, colors }) => (
            <Container>
              <p style={{ marginBottom: '1rem' }}>
                Using <strong style={{ fontWeight: 'bold' }}>{useWattAveraging ? 'W' : 'db'}</strong> averaging.{' '}
                <Button xs onClick={() => setUseWattAveraging(!useWattAveraging)}>
                  Toggle
                </Button>
              </p>
              <Form
                autoFocus
                initialValue={slidingWindow}
                input={SWInput}
                label="Sliding Window"
                onSubmit={value => setSlidingWindow(value)}
              />
              {left && right && (
                <LinkButton onClick={() => this.setState({ left: null, right: null })} className="zoomOut">
                  <ZoomOut fontSize={'small'} />
                </LinkButton>
              )}
              <ResponsiveContainer height="80%">
                <LineChart
                  data={data}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  onMouseDown={this.handleMouseDown}
                  onMouseMove={refAreaLeft ? this.handleMouseMove : null}
                  onMouseUp={this.zoom}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    type="number"
                    domain={left && right ? [left, right] : ['dataMin', 'dataMax']}
                    dataKey="timestamp"
                    tickFormatter={time => format(parse(time), 'HH:mm:ss')}
                    allowDataOverflow={left && right}
                  />
                  <YAxis unit={'dBm'} label={{ color: 'white' }} type={'number'} />
                  <Tooltip
                    isAnimationActive={false}
                    labelStyle={{ color: 'black' }}
                    labelFormatter={time => format(parse(time), 'HH:mm:ss')}
                    formatter={value => `${value.toFixed(1)} dBm`}
                    itemSorter={(a, b) => get(b, 'value') - get(a, 'value')}
                  />
                  <Legend
                    payload={this.getLegendPayload(gateways, colors)}
                    verticalAlign="top"
                    height={36}
                    iconType="triangle"
                    inactiveColor={'darkGray'}
                    onMouseEnter={this.handleLegendMouseEnter}
                    onMouseLeave={this.handleLegendMouseLeave}
                    onClick={this.handleLegendItemClick}
                  />
                  {gateways.map(gw => (
                    <Line
                      key={gw}
                      name={this.getLabel(gw)}
                      opacity={this.isActive(gw) ? opacityForLine(gw, highlighted) : 0}
                      dot={false}
                      type="stepBefore"
                      dataKey={`${gw}${this.isActive(gw) ? '' : ' '}`}
                      stroke={colors[gw]}
                      fill={colors[gw]}
                      strokeWidth={strokeForLine(gw, highlighted)}
                      isAnimationActive={false}
                    />
                  ))}
                  {refAreaLeft && refAreaRight && (
                    <ReferenceArea x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
                  )}
                </LineChart>
              </ResponsiveContainer>
            </Container>
          )}
        </BackgroundTransformation>
      );
    }
  }
}

export default compose(
  graphql(signalLogForPatient, { options: props => ({ variables: { patientId: props.patientId } }) }),
  withState('slidingWindow', 'setSlidingWindow', props => get(props, 'config.swPeriod', 40000)),
  withState('useWattAveraging', 'setUseWattAveraging', props => get(props, 'config.useWattAveraging', true)),
  mapProps(({ data: { signalLogForPatient = [], allGateways, loading }, ...rest }) => ({
    signalLogForPatient,
    allGateways,
    loading,
    ...rest,
  }))
)(SignalAnalysis);
