import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment CustomMessageData on CustomMessage {
      id
      title
      content
    }
  `,
};

export const list = gql`
  query customMessages {
    customMessages {
      ...CustomMessageData
    }
  }
  ${fragments.all}
`;

export const item = gql`
  query customMessage($id: Long!) {
    customMessage(id: $id) {
      ...CustomMessageData
    }
  }
  ${fragments.all}
`;

export const create = gql`
  mutation createCustomMessage($title: String!, $content: String!) {
    createCustomMessage(title: $title, content: $content) {
      ...CustomMessageData
    }
  }
  ${fragments.all}
`;

export const update = gql`
  mutation editCustomMessage($id: Long!, $title: String!, $content: String!) {
    editCustomMessage(id: $id, title: $title, content: $content) {
      ...CustomMessageData
    }
  }
  ${fragments.all}
`;

export const remove = gql`
  mutation deleteCustomMessage($id: Long!) {
    deleteCustomMessage(id: $id)
  }
`;

export default {
  list,
  item,
  create,
  update,
  remove,
};
