import React, { Fragment, useState, useMemo, useEffect, useRef } from 'react';
import { debounce } from 'lodash';

const SimpleTextFilter = ({ children, data = [] }) => {
  const [search] = useState('');
  const [filteredData, setFilteredData] = useState(data);

  // TODO: Extend filtering beyond `name` column
  // TODO: It’s basically a chore to future proof this by adding 'filterProviders' to custom columns
  const filter = (search, data) =>
    setFilteredData(search ? data.filter(row => row['name'].toLowerCase().indexOf(search.toLowerCase()) > -1) : data);

  const throttled = useRef(debounce(filter, 500));
  useEffect(() => throttled.current(search, data), [search, data]);

  const table = useMemo(() => children(filteredData), [filteredData, children]);

  return <Fragment>{table}</Fragment>;
};

export default SimpleTextFilter;
