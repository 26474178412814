import set from 'lodash/set';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import posed, { PoseGroup } from 'react-pose';
import styled, { css } from 'styled-components';
import { withTheme } from '../../se/theme';
import responsive from '../../se/utilities/responsive';
import { formatTimeEntered } from '../entities/common/transducers';
import { MONITOR_STATUS_ICONS, STATUSES } from '../entities/patient/enums';
import { OR_TIMES_S } from '../inputs/timeEdit/TimeEditModal';
import TurnoverClock from '../pages/kiosk/tablet/TurnoverClock';
import { isOrTimeActive, optionalFormat, showValueIf } from '../pages/kiosk/tablet/utils';
import { AirFiltrationIndicatorTimer } from '../pages/kiosk/widgets/AirFiltrationIndicator';
import PatientInfo from '../patient/PatientSlot/PatientInfo';

import PanelContainer from './PanelContainer';

export const Visualisation = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 2em;
  margin-top: ${props => (props.compactView ? '0' : 'auto')};
  flex: ${props => (props.compactView ? '1' : '0 1 100%')};
  align-items: center;

  ${responsive.sm.andSmaller`
    display: none;
  `};
`;

export const Icon = styled.img`
  max-width: ${props => (props.compactView ? '50%' : '100%')};

  height: calc(min(6vh, 16vw));

  ${props =>
    props.miniView &&
    css`
      max-width: initial;
      width: auto;
      height: 3vh;

      @media (max-height: 1000px) {
        height: 1vh;
      }
    `}
`;

const Footer = posed(styled.div`
  margin-top: ${props => (props.compactView ? '0' : 'auto')};
`)({
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 500,
      ease: [0.0, 0.01, 0.01, 0.99],
      delay: 500,
    },
  },
  exit: {
    y: 10,
    opacity: 0,
    transition: {
      duration: 500,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
});

const AnimatedVisualisation = posed(Visualisation)({
  enter: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 500,
      ease: [0.0, 0.01, 0.01, 0.99],
      delay: 500,
    },
  },
  exit: {
    scale: 0.5,
    opacity: 0,
    transition: {
      duration: 500,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
});

const TimesCompact = styled.div`
  display: flex;
  top: 1.5rem;
  flex-flow: column;
  color: ${withTheme(theme => theme.textColor.string())};
  white-space: nowrap;

  border-top: solid 2px alpha(${withTheme(theme => theme.textColor.string())}, 0.2);
  margin-top: 1em;
  padding-top: 1em;
  align-content: flex-start;
  font-size: 1.125em;

  > * + * {
    margin-top: 0.75rem;
  }
`;

const Entry = styled.div`
  display: flex;
  color: ${withTheme(theme => theme.textColor.string())};

  > * {
    flex: 1 0 50%;
  }
`;

const Label = styled.div`
  opacity: 0.75;
`;

const Time = styled.div`
  color: ${withTheme(theme => theme.textColor.string())};
  text-align: right;
`;

const PatientInfoWrapper = styled.div`
  padding: ${props => (props.preOpFocused || props.pacuFocused ? '0 1em' : '1.25vh 1em')};
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: -0.5rem;
  border-radius: 0.25rem;
  border: 2px solid ${props => (props.isPatientReady ? '#00B63E' : 'transparent')};
  background: ${props => (props.isPatientReady ? 'rgba(255,255,255,0.05)' : 'transparent')};

  ${props =>
    props.miniView &&
    css`
      padding: 0.5rem 1rem;
      border: none;
    `}
`;

const indicatorStyles = {
  margin: '0.5em',
  width: '4rem',
  height: '4rem',
  borderWidth: '2px',
};

const floatRight = {
  ...indicatorStyles,
  position: 'absolute',
  right: 0,
};

const floatCenter = {
  ...indicatorStyles,
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  margin: '0 auto',
};

const StatusWrapper = styled.div`
  margin: auto 0;
`;

const ClockWrapper = styled(StatusWrapper)`
  grid-column: 1 / span 2;
  margin: 0;
  margin-bottom: 3%;
  justify-content: center;
  align-items: center;
  font-size: 0.25rem;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  padding: 1rem;
  text-align: center;
`;

const TitleWithIndicator = ({ roomId, title, compactView }) => (
  <div style={{ display: 'flex' }}>
    <label>{title}</label>
    <AirFiltrationIndicatorTimer roomId={roomId} style={compactView ? floatRight : floatCenter} radius={32} />
  </div>
);

const Panel = ({
  status,
  statusLabel,
  monitorStatus,
  title,
  physician,
  scheduleProcedure,
  procedure,
  patient,
  patientType,
  enteredAtTime,
  timeoutTime,
  procedureStartTime,
  closingTime,
  compactView,
  allowWrap,
  miniView,
  numberOfRooms,
  bed,
  roomId,
  preOpFocused,
  pacuFocused,
  called,
  isHelp,
  isPreOp,
  room,
}) => {
  const rows = Math.ceil(numberOfRooms / 8);

  return (
    <PanelContainer
      title={<TitleWithIndicator title={title} roomId={roomId} compactView={compactView} />}
      fullOpacity={true}
      timeoutTime={timeoutTime}
      procedureStartTime={procedureStartTime}
      closingTime={closingTime}
      statusLabel={statusLabel}
      timeEntered={formatTimeEntered(enteredAtTime)}
      enteredTime={enteredAtTime}
      compactView={compactView && (!preOpFocused || !pacuFocused)}
      miniView={miniView}
      roomId={roomId}
      status={status}
      numberOfPanels={Math.ceil(numberOfRooms / rows)}
      scheduleProcedure={
        enteredAtTime ? set(scheduleProcedure, 'patient.events.orAt', enteredAtTime) : scheduleProcedure
      }
      preOpFocused={preOpFocused}
      pacuFocused={pacuFocused}
      isHelp={isHelp}
      room={room}
    >
      <PoseGroup animateOnMount>
        <AnimatedVisualisation key={status} compactView={compactView}>
          {status === 'Cleaning' || (status === 'Vacant' && room?.turnoverStartedAt) ? (
            <ClockWrapper>
              <TurnoverClock
                room={room}
                status={status}
                src={MONITOR_STATUS_ICONS[status]}
                compactView={compactView}
                miniView={miniView}
              />
            </ClockWrapper>
          ) : (
            <Icon src={MONITOR_STATUS_ICONS[status]} compactView={compactView} miniView={miniView} />
          )}
        </AnimatedVisualisation>
        <Footer key={status + 'footer'} compactView={compactView}>
          <PatientInfoWrapper preOpFocused={preOpFocused} pacuFocused={pacuFocused} miniView={miniView}>
            <PatientInfo
              status={monitorStatus}
              physician={physician}
              patient={patient}
              patientType={patientType}
              procedure={procedure}
              timeEntered={enteredAtTime}
              compactView={compactView}
              statusLabel={statusLabel}
              readyInOr={status === STATUSES.READY}
              bed={bed}
              allowWrap={allowWrap}
              miniView={miniView}
              preOpFocused={preOpFocused}
              pacuFocused={pacuFocused}
              hideChart={true}
              called={called}
              isPreOp={isPreOp}
            />
          </PatientInfoWrapper>
        </Footer>
      </PoseGroup>

      {compactView && (!preOpFocused || !pacuFocused) && (
        <Fragment>
          <TimesCompact>
            <Entry>
              <Label>Entered</Label>
              <Time>
                {showValueIf(optionalFormat(enteredAtTime, 'HH:mm'), isOrTimeActive(status, OR_TIMES_S.ENTERED_AT))}
              </Time>
            </Entry>
            <Entry>
              <Label>Time Out</Label>
              <Time>
                {showValueIf(optionalFormat(timeoutTime, 'HH:mm'), isOrTimeActive(status, OR_TIMES_S.TIMEOUT_START))}
              </Time>
            </Entry>
            <Entry>
              <Label>Procedure</Label>
              <Time>
                {showValueIf(
                  optionalFormat(procedureStartTime, 'HH:mm'),
                  isOrTimeActive(status, OR_TIMES_S.PROCEDURE_START)
                )}
              </Time>
            </Entry>
            <Entry>
              <Label>Closing</Label>
              <Time>
                {showValueIf(optionalFormat(closingTime, 'HH:mm'), isOrTimeActive(status, OR_TIMES_S.CLOSING_START))}
              </Time>
            </Entry>
          </TimesCompact>
        </Fragment>
      )}
    </PanelContainer>
  );
};

Panel.propTypes = {
  status: PropTypes.string.isRequired,
  physician: PropTypes.string,
  procedure: PropTypes.string,
  patient: PropTypes.string,
  bed: PropTypes.string,
};

export default Panel;
