import React from 'react';
import { openPdf } from '../../../../../../entities/procedures/pdf/shared';
import { format } from 'date-fns';
import { createPdf } from '../../../../../../../vendor/pdfMake';
import { Room, RoomType } from '../../../../../../../types/Room';
import StaffShift from '../../../../../../../types/StaffShift';

export interface StaffShiftExtended extends StaffShift {
  isProcedure?: boolean;
}

export type RoomWithProcedureFlag = Omit<Room, 'staffShifts'> & { staffShifts: Array<StaffShiftExtended> };

const getOrdinalSuffix = (day: number) => {
  const suffix = ['th', 'st', 'nd', 'rd'];
  const v = day % 100;
  return suffix[(v - 20) % 10] || suffix[v] || suffix[0];
};

const formatDateWithOrdinal = (date: Date) => {
  const day = format(date, 'DD');
  const dayWithSuffix = `${day}${getOrdinalSuffix(parseInt(day, 10))}`;
  const month = format(date, 'MMMM');
  const year = format(date, 'YYYY');
  const weekday = format(date, 'dddd');
  return `${weekday}, ${month} ${dayWithSuffix} ${year}`;
};

const getContent = (date: Date, rooms: RoomWithProcedureFlag[], operationRooms: Room[]) => {
  const row1 = [
    {
      text: `PSC DAILY ASSIGNMENT SHEET`,
      style: 'header',
      alignment: 'center',
      colSpan: 5,
    },
    '',
    '',
    '',
    '',
    {
      text: `DATE: ${format(date, 'MM.DD.YYYY')}`,
      style: 'subheader',
      alignment: 'center',
      colSpan: 1,
    },
  ];

  const row2 = [
    {
      text: `ADMINISTRATOR: ${''}`,
      style: 'subheader',
      alignment: 'left',
      colSpan: 2,
    },
    '',
    {
      text: `OR MANAGER: ${''}`,
      style: 'subheader',
      alignment: 'left',
      colSpan: 2,
    },
    '',
    {
      text: `QUALITY NURSE: ${''}`,
      style: 'subheader',
      alignment: 'left',
      colSpan: 2,
    },
    '',
  ];

  const row3 = [
    {
      text: `CHARGE NURSE (OR): ${''}`,
      style: 'subheader',
      colSpan: 3,
    },
    '',
    '',
    {
      text: `CHARGE NURSE (Preop/PACU): ${''}`,
      style: 'subheader',
      colSpan: 3,
    },
    '',
    '',
  ];

  const tableRow1 = [
    {
      text: `Start Time`,
      alignment: 'left',
    },
    {
      text: `SURGEON`,
      alignment: 'center',
    },
    {
      text: `OR STAFF`,
      alignment: 'center',
    },
  ];

  const orRoomStaff = rooms.filter(room => room.type === RoomType.OR);
  const orRooms = operationRooms.flatMap(e => [
    [
      {
        text: e?.name || '',
        alignment: 'center',
        colSpan: 3,
      },
      '',
      '',
    ],
    ...e.procedures.map((p, i) => [
      {
        text: p?.startTimeText || '',
        alignment: 'left',
      },
      {
        text: p?.physician?.name || '',
        alignment: 'left',
      },
      i === 0
        ? {
            text:
              e?.staffShifts
                ?.map(e => e?.staff?.name)
                .filter(name => name)
                .join(', ') || '',
            alignment: 'left',
            rowSpan: e.procedures.length,
          }
        : '',
    ]),
  ]);

  const preOpStaff = rooms.filter(room => room.type === RoomType.PreOp)?.flatMap(e => e?.staffShifts || []) || [];
  const pacuStaff = rooms.filter(room => room.type === RoomType.PACU)?.flatMap(e => e?.staffShifts || []) || [];

  return {
    stack: [
      {
        table: {
          headerRows: 3,
          widths: ['*', '*', '*', '*', '*', '*'],
          body: [row1, row2, row3],
        },
      },
      {
        columns: [
          {
            width: '70%',
            table: {
              headerRows: 1,
              widths: ['10%', '20%', '70%'],
              body: [tableRow1, ...orRooms],
            },
          },
          {
            width: '30%',
            table: {
              widths: ['*'],
              body: [
                [
                  {
                    text: `PRE-OP`,
                    alignment: 'center',
                  },
                ],
                ...preOpStaff.map(e => [
                  {
                    text: e?.staff?.name || '',
                    alignment: 'left',
                  },
                ]),
                [
                  {
                    text: `PACU`,
                    alignment: 'center',
                  },
                ],
                ...pacuStaff.map(e => [
                  {
                    text: e?.staff?.name || '',
                    alignment: 'left',
                  },
                ]),
              ],
            },
          },
        ],
        columnGap: 0,
      },
    ],
  };
};

export const StaffSchedulePdfGenerator = (
  hospitalName: string,
  date: Date,
  rooms: RoomWithProcedureFlag[],
  operationRooms: Room[]
) => {
  const docDefinition = {
    pageSize: 'LETTER',
    pageMargins: [40, 40, 40, 40],
    defaultStyle: {
      fontSize: 9,
      lineHeight: 1.4,
    },
    info: { title: `Staff Schedule` },
    header: {
      stack: [
        {
          text: formatDateWithOrdinal(date),
          style: 'header',
          alignment: 'center',
          margin: [0, 10, 0, 10],
        },
      ],
    },
    content: [
      getContent(
        date,
        rooms.map(room => ({ ...room, staffShifts: room.staffShifts.filter(staff => !!staff.staff?.name) })),
        operationRooms
      ),
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: 'justify',
      },
      subheader: {
        fontSize: 14,
        bold: true,
        alignment: 'justify',
        margin: [0, 0, 0, 10],
      },
      tableHeaderRoomTitle: {
        bold: true,
        fontSize: 16,
        color: 'black',
        fillColor: '#CCCCCC',
        margin: [2, 0, 0, -10],
      },
    },
  };

  openPdf(createPdf(docDefinition), `Staff_Schedule${format(date, 'MM_DD_YYYY_HH_mm_ss')}`);
};
