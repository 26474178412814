import Dates from '../types/Dates';
import ImmutableSet from '../types/ImmutableSet';
import { StaffAvailability } from '../../../../types/StaffAvailability';
import formatTimeRange from './formatTimeRange';

export type DateString = string;
export type AnonymousStaffAvailability = Omit<StaffAvailability<DateString>, 'staffId'>;

export interface StaffAvailabilityDateGroup {
  date: DateString;
  availabilities: AnonymousStaffAvailability[];
}

export interface StaffAvailabilityGroup {
  dates: Dates;
  available: StaffAvailabilityConcreteGroup;
  unavailable: StaffAvailabilityConcreteGroup;
}

export interface StaffAvailabilityConcreteGroup {
  timeRanges: (Pick<StaffAvailability, 'from' | 'to'> & { ids: ImmutableSet<StaffAvailability['id']> })[];
  ids: ImmutableSet<StaffAvailability['id']>;
}

export function groupAvailabilitiesByDate(availabilities: AnonymousStaffAvailability[]) {
  return Object.values(
    availabilities.reduce<{
      [date: string]: StaffAvailabilityDateGroup;
    }>((groups, availability) => {
      const existing = groups[availability.date];

      return {
        ...groups,
        [availability.date]: {
          date: availability.date,
          availabilities: [...(existing?.availabilities ?? []), availability],
        },
      };
    }, {})
  );
}

type TimeRangesWithIdsMap = {
  [timeRangeString: string]: {
    from?: string;
    to?: string;
    ids: ImmutableSet<StaffAvailability['id']>;
  };
};

function groupAvailabilitiesByPattern(availabilitiesByDate: ReturnType<typeof groupAvailabilitiesByDate>) {
  return Object.values(
    availabilitiesByDate.reduce<{
      [availabilitySignature: string]: {
        dates: Dates;
        available: {
          timeRanges: TimeRangesWithIdsMap;
          ids: ImmutableSet<StaffAvailability['id']>;
        };
        unavailable: {
          timeRanges: TimeRangesWithIdsMap;
          ids: ImmutableSet<StaffAvailability['id']>;
        };
      };
    }>((groups, { date, availabilities }) => {
      const availabilitySignature = availabilities
        .map(
          availability =>
            (availability.available ? 'a' : 'u') +
            formatTimeRange(availability.from ?? 'xx:xx', availability.to ?? 'xx:xx')
        )
        .join(',');

      const existing = groups[availabilitySignature];

      const available = availabilities.filter(availability => availability.available);
      const unavailable = availabilities.filter(availability => !availability.available);

      function toUniqueTimeRangesWithIds(
        defaultValue: TimeRangesWithIdsMap,
        availabilities: AnonymousStaffAvailability[]
      ): TimeRangesWithIdsMap {
        return availabilities.reduce<TimeRangesWithIdsMap>((groups, availability) => {
          const timeRangeString = formatTimeRange(availability.from ?? 'xx:xx', availability.to ?? 'xx:xx');
          const existing = groups[timeRangeString];

          return {
            ...groups,
            [timeRangeString]: {
              from: availability.from,
              to: availability.to,
              ids: (existing?.ids ?? ImmutableSet.empty<number>()).add(availability.id),
            },
          };
        }, defaultValue);
      }

      return {
        ...groups,
        [availabilitySignature]: {
          dates: (existing ? existing.dates : Dates.empty).add(date),
          available: {
            timeRanges: existing
              ? toUniqueTimeRangesWithIds(existing.available.timeRanges, available)
              : toUniqueTimeRangesWithIds({}, available),
            ids: (existing?.available.ids ?? ImmutableSet.empty<number>()).add(...available.map(a => a.id)),
          },
          unavailable: {
            timeRanges: existing
              ? toUniqueTimeRangesWithIds(existing.unavailable.timeRanges, unavailable)
              : toUniqueTimeRangesWithIds({}, unavailable),
            ids: (existing?.unavailable.ids ?? ImmutableSet.empty<number>()).add(...unavailable.map(a => a.id)),
          },
        },
      };
    }, {})
  ).map(e => ({
    ...e,
    available: {
      ...e.available,
      timeRanges: Object.values(e.available.timeRanges),
    },
    unavailable: {
      ...e.unavailable,
      timeRanges: Object.values(e.unavailable.timeRanges),
    },
  }));
}

const groupStaffAvailability = (
  staffAvailabilities: AnonymousStaffAvailability[],
  selectedDates: Dates
): [AnonymousStaffAvailability[], StaffAvailabilityGroup[]] => {
  const availabilities = staffAvailabilities.filter(staffAvailability => selectedDates.has(staffAvailability.date));

  const availabilitiesByDate: StaffAvailabilityDateGroup[] = groupAvailabilitiesByDate(availabilities);

  const groupedAvailabilities: StaffAvailabilityGroup[] = groupAvailabilitiesByPattern(availabilitiesByDate);

  const emptyDates: Dates = groupedAvailabilities.reduce(
    (dates, group) => dates.delete(...group.dates.values()),
    selectedDates
  );

  return [
    availabilities,
    (emptyDates.isEmpty()
      ? groupedAvailabilities
      : [
          ...groupedAvailabilities,
          {
            dates: emptyDates,
            available: { timeRanges: [], ids: ImmutableSet.empty<number>() },
            unavailable: { timeRanges: [], ids: ImmutableSet.empty<number>() },
          },
        ]
    ).sort(
      (a, b) =>
        (b.dates.size() - a.dates.size()) * 100 +
        b.available.ids.size() -
        a.available.ids.size() +
        b.unavailable.ids.size() -
        a.unavailable.ids.size()
    ),
  ];
};

export default groupStaffAvailability;
