import { Fade, Popover } from '@material-ui/core';
import React, { LegacyRef, ReactChild, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PopoverActions } from '@material-ui/core/Popover/Popover';

interface ExpandableViewProps {
  content: ReactChild;
  additional?: ReactChild;
}

const ExpandableView = ({ content, additional }: ExpandableViewProps) => {
  const classes = useStyles();
  const spanRef = useRef<HTMLElement>();
  const actionRef = useRef<PopoverActions>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>();
  const [padding, setPadding] = useState<string | number>(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const span = spanRef.current;

    if (!span) {
      return;
    }

    const cell = findCell(span);

    if (!cell) {
      return;
    }

    setAnchorEl(cell);

    const handleEnter = () => {
      const cellStyle = getComputedStyle(cell);

      const paddingY = (cell.offsetHeight - span.offsetHeight) / 2 + 'px';
      const paddingX = cellStyle.paddingLeft;

      setPadding(`${paddingY} ${paddingX}`);

      setOpen(true);
    };

    const handleLeave = () => {
      setOpen(false);
    };

    cell.addEventListener('mouseenter', handleEnter);
    cell.addEventListener('mouseleave', handleLeave);

    return () => {
      cell.removeEventListener('mouseenter', handleEnter);
      cell.removeEventListener('mouseleave', handleLeave);
    };
  }, []);

  return (
    <span ref={spanRef as unknown as LegacyRef<HTMLElement>}>
      {content}
      {anchorEl && additional && (
        <Popover
          action={actionRef}
          container={anchorEl}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={open}
          onClick={e => {
            e.stopPropagation();
          }}
          classes={{
            root: classes.popoverRoot,
            paper: classes.popoverPaper,
          }}
          TransitionComponent={Fade}
          disablePortal
          disableRestoreFocus
          disableScrollLock
        >
          <div style={{ padding }}>
            {content}
            {additional}
          </div>
        </Popover>
      )}
    </span>
  );
};

const useStyles = makeStyles(theme => ({
  popoverRoot: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    // pointerEvents: 'initial',
    cursor: 'initial',
  },
}));

function findCell(element: HTMLElement): HTMLElement | null {
  let current: HTMLElement | null = element;

  while (current && !current.classList.contains('cell')) {
    current = current.parentElement;
  }

  return current;
}

export default ExpandableView;
