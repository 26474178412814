import styles from '../../styles';
import React from 'react';
import { useSubscription } from '@apollo/client';
import { item } from '../../../../../../graph/procedures';
import { format } from 'date-fns';
import { ZonedDateTime } from '@js-joda/core';
import MedicalPassportField from '../../components/MedicalPassportField';
import toSex from '../../../../../../questionnaire/toSex';
import toAge from '../../../../../../questionnaire/toAge';
import { useValue } from '../../ValueContext';
import Barcode from '../../../Barcode';

// Not semantically same as other Pages, but it was asked from us to add it so this is the most painless way to do so
const Page8 = ({ formName, pageNumber, showQRCode, procedureId, value, defaultValue }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const { data } = useSubscription(item, {
    variables: { id: procedureId },
  });
  const procedureNotes = (data?.scheduledProcedure?.events || []).filter(event => event.type === 'Note');

  return (
    <div id="page7" className="page">
      <div
        style={{
          ...styles.center,
          marginBottom: '3em',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div />
          <h2>PreOp Notes</h2>
          <div>
            {showQRCode && (
              <Barcode
                formName={formName}
                pageNumber={pageNumber}
                providerId={defaultValue?.procedure?.patient?.providerId}
                serviceTime={defaultValue?.procedure?.serviceTime}
              />
            )}
          </div>
        </div>
        <div
          style={{
            marginBottom: '0.4rem',
            width: '100%',
            display: 'grid',
            gridTemplateRows: '1fr 1fr',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={16} />
            <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} />
            <MedicalPassportField name="procedure.patient.providerId" label="MRN" />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <MedicalPassportField name="procedure.physician.name" label="Surgeon" />
            <MedicalPassportField name="sex" label="Sex" path={['demographicInformation', 'sex']} reducer={toSex} />
            <MedicalPassportField name="age" label="Age" path={['demographicInformation', 'dob']} reducer={toAge} />
            <MedicalPassportField name="procedure.serviceTime" label="DOS" />
          </div>
        </div>
      </div>
      {procedureNotes.map((note, index) => (
        <div style={{ marginBottom: '3em' }}>
          {index !== 0 && (
            <div
              style={{
                borderTop: '3px solid #bbb',
                marginTop: '2em',
              }}
            />
          )}
          <h4>{note.user.name}</h4>
          <span>{note.text}</span>
          <h4 style={{ fontWeight: 600, float: 'right', paddingTop: '1em' }}>
            {format(ZonedDateTime.parse(note.timestamp).toLocalDateTime().toString(), 'MM/DD/YYYY HH:mm:ss')}
          </h4>
        </div>
      ))}
    </div>
  );
};

export default Page8;
