import React from 'react';

import type IllustrationProps from '../IllustrationProps';

export default ({ width, height, variant }: IllustrationProps) => {
  const color = variant === 'light' ? '#1976d2' : '#00a7f7';
  return (
    <svg viewBox="0 0 60 60.07" width={width} height={height}>
      <path
        fill={color}
        d="M19.29,34.74a.68.68,0,0,1-.68-.68l-.15-16a.68.68,0,0,1,.67-.68h0a.67.67,0,0,1,.68.67h0l.15,16a.69.69,0,0,1-.67.69h0Z"
      />
      <polygon
        fill={color}
        points="22.75 41.95 21.14 41.95 21.14 47.16 15.96 47.16 15.96 48.79 21.14 48.79 21.14 54.01 22.75 54.01 22.75 48.79 27.93 48.79 27.93 47.16 22.75 47.16 22.75 41.95"
      />
      <path
        fill={color}
        d="M40.15,34.33a.69.69,0,0,0,.33-.56l.22-16.13h0a11.19,11.19,0,0,0-3.36-8L38.92,3,32.45.09,26.29,0,19.83,3.17,21.49,10A11.14,11.14,0,1,0,37.65,25.33a10.87,10.87,0,0,0,1.64-2.23l-.15,10.61a19.34,19.34,0,0,0-28.89,17V60h1.34V50.68a18.17,18.17,0,0,1,11.82-17L29.55,41l6.53-7.2A18.37,18.37,0,0,1,47.84,50.9v7.75H32.3V60H49.18V50.9A19.63,19.63,0,0,0,40.15,34.33ZM21.39,3.91l5.2-2.55,5.56.08,5.21,2.33L36.19,8.65a11,11,0,0,0-13.58.29Zm8.55,3.91a9.77,9.77,0,0,1,9.4,9.54c-3.31-.76-6-4.89-6-4.93l-.55-.87-.58.85c0,.05-3.24,4.65-12.39,4.83A9.77,9.77,0,0,1,29.94,7.82Zm-.35,19.65a9.82,9.82,0,0,1-9.72-8.89c7.68-.15,11.49-3.24,12.86-4.69,1,1.39,3.48,4.26,6.57,4.84A9.83,9.83,0,0,1,29.59,27.47Zm0,11.43-4.8-5.69a17.88,17.88,0,0,1,9.89.06Z"
      />
      <path
        opacity={0.28}
        fill={color}
        d="M26.42,37.23A19.74,19.74,0,0,0,19.19,43c-2.93,3.59-2.76,8.75-2.68,10.67s.16,6.31.16,6.31H10.84S9.9,45.4,15.3,39.5s8.06-6,8.06-6Z"
      />
      <path
        opacity={0.28}
        fill={color}
        d="M19.66,17.93l6.5-.8s-.58,1.66,0,5.28,4.75,5.77,4.75,5.77-8.42-1.35-9.78-4.39A17.49,17.49,0,0,1,19.66,17.93Z"
      />
    </svg>
  );
};
