import { createContext } from 'react';
import { ParsedQuery } from 'query-string';

const SubmissionFormContext = createContext<{
  organizationId: string;
  submissionId?: string;
  queryParams?: ParsedQuery;
}>({
  organizationId: '',
});

export default SubmissionFormContext;
