import React from 'react';
import { Button, Chip } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { listOpenPositions, openPositionCount, openPositionCounterReset } from '../../../../graph/staff';
import { useSession } from '../../../../state/Session';
import { addDays, format } from 'date-fns';
import { decodeDateRange } from '../../../../se/hocs/withFilterQueryString';

export const OpenPositionCounterResetButton = () => {
  const session: any = useSession();
  const userId = session?.session?.user?.id;
  const [resetNotifications] = useMutation(openPositionCounterReset);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const dateRange = decodeDateRange(undefined)(params);
  const from = dateRange?.date ? format(dateRange?.date, 'YYYY-MM-DD') : format(Date.now(), 'YYYY-MM-DD');
  const to =
    dateRange?.date && dateRange?.period
      ? format(dateRange?.date.plusDays(dateRange?.period.days()), 'YYYY-MM-DD')
      : format(addDays(Date.now(), 30), 'YYYY-MM-DD');

  const reset = async () => {
    await resetNotifications({
      variables: { userId },
      refetchQueries: [
        { query: openPositionCount, variables: { userId } },
        {
          query: listOpenPositions,
          variables: {
            filter: {
              dateRange: {
                from,
                to,
              },
            },
          },
        },
      ],
    });
  };

  const { data } = useQuery(openPositionCount, {
    variables: {
      userId,
    },
  });

  const hasNotifications = (data?.openPositionCount || 0) > 0;

  return <>{hasNotifications && <Button onClick={reset}>Mark All As Read</Button>}</>;
};

const OpenPositionCounter = () => {
  const session: any = useSession();
  const userId = session?.session?.user?.id;

  const { data } = useQuery(openPositionCount, {
    variables: {
      userId,
    },
  });

  const notificationsCount = data?.openPositionCount || '0';
  const hasNotifications = (data?.openPositionCount || 0) > 0;

  return <>{hasNotifications && <Chip size="small" label={notificationsCount} color="primary" />}</>;
};

export default OpenPositionCounter;
