import React, { FC, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import DarkThemeProviderWrapper from '../../../../../DarkThemeProviderWrapper';
import Typography from '@material-ui/core/Typography';
import Filters from '../../../../pages/analytics/Filters';
import { addDays, format, parse, subDays } from 'date-fns';
import { staffingCostQuery } from '../../../../../graph/dashboard';
import { useQuery } from '@apollo/client';
import {
  ProcedureStaffCostPerDate,
  ProcedureStaffCostSummary,
  StaffCostPerDate,
  StaffCostSummary,
} from '../../../../../types/StaffCost';
import TableWithColumnSelector, { TableType } from '../../../../core/TableWithColumnSelector';
import { Range, toLocalDate } from '../../../../core/DatePicker';
import { CircularProgress } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';
import { Period } from '@js-joda/core';

function convertMinutesToHoursAndMinutes(durationInMinutes: number) {
  const hours = Math.floor(durationInMinutes / 60);
  const remainingMinutes = durationInMinutes % 60;

  return remainingMinutes ? (hours ? hours + 'h ' + remainingMinutes + 'min' : remainingMinutes + 'min') : hours + 'h';
}

const toTableData1 = (data: StaffCostSummary): TableType => ({
  configHeader: ['Staff Member', 'Title', 'Employment Type', 'Number of Hours', 'Hourly Rate', 'Staff Cost'],
  headers: [{
    id: 'h1',
    columns: ['Staff Member', 'Title', 'Employment Type', 'Number of Hours', 'Hourly Rate', 'Staff Cost'],
  }],
  rows: [...(data.staffCostDateRange || []).map(member => ({
    id: member?.id.toString(), columns: [
      member.staffMemberName,
      member.staffMemberTitle,
      member.employmentType,
      convertMinutesToHoursAndMinutes(member.minutes),
      `$${member.hourlyRate}`,
      `$${Number(member.cost).toFixed(2)}`,
    ],
  })), {
    id: 'total1',
    columns: ['TOTAL', '', '', convertMinutesToHoursAndMinutes(data.totalMinutes), '', `$${Number(data.totalCost).toFixed(2)}`],
  }],
});

const toTableData12 = (data: StaffCostPerDate): TableType => ({
  configHeader: ['Staff Member', 'Title', 'Employment Type', 'In', 'Out', 'Number of Hours', 'Hourly Rate', 'Staff Cost'],
  headers: [{
    id: 'h1',
    columns: ['Staff Member', 'Title', 'Employment Type', 'In', 'Out', 'Number of Hours', 'Hourly Rate', 'Staff Cost'],
  }],
  rows: [...(data.staffCost || []).map(member => ({
    id: member?.id.toString(), columns: [
      member.staffMemberName,
      member.staffMemberTitle,
      member.employmentType,
      member.in || '',
      member.out || '',
      convertMinutesToHoursAndMinutes(member.minutes),
      `$${member.hourlyRate}`,
      `$${Number(member.cost).toFixed(2)}`,
    ],
  })), {
    id: 'total1',
    columns: ['TOTAL', '', '', '', '', convertMinutesToHoursAndMinutes(data.totalMinutes), '', `$${Number(data.totalCost).toFixed(2)}`],
  }],
});

const toTableData2 = (data: ProcedureStaffCostPerDate): TableType => {
  const totalActualStaffingUtilization = (data.staffCost || []).reduce((acc, cost) => {
    return acc + cost.actualStaffingUtilization;
  }, 0);

  return {
    configHeader: [
      'Physician',
      'Procedure',
      'Schedule In',
      'Schedule Out',
      'Scheduled Duration',
      'Wheels In',
      'Wheels Out',
      'Actual Duration',
      'Scheduled Staffing Utilization',
      'Actual Staffing Utilization',
      'Scheduled Staffing Cost',
      'Actual Staffing Cost',
    ],
    headers: [{
      id: 'h1',
      columns: [
        'Physician',
        'Procedure',
        'Schedule In',
        'Schedule Out',
        'Scheduled Duration',
        'Wheels In',
        'Wheels Out',
        'Actual Duration',
        'Scheduled Staffing Utilization',
        'Actual Staffing Utilization',
        'Scheduled Staffing Cost',
        'Actual Staffing Cost',
      ],
    }],
    rows: [...(data.staffCost || []).map(cost => ({
        id: cost?.id.toString(), columns: [
          cost.physicianName,
          cost.procedure,
          cost.wheelsIn,
          cost.wheelsOut,
          convertMinutesToHoursAndMinutes(cost.durationInMinutes),
          cost.actualWheelsIn,
          cost.actualWheelsOut,
          convertMinutesToHoursAndMinutes(cost.actualDurationInMinutes),
          `${Number(cost.staffingUtilization).toFixed(2)}%`,
          `${Number(cost.actualStaffingUtilization).toFixed(2)}%`,
          `$${Number(cost.cost).toFixed(2)}`,
          `$${Number(cost.actualCost).toFixed(2)}`,
        ],
      })),
      {
        id: 'total1',
        columns: [
          'TOTAL',
          data?.staffCost.length + ' procedures',
          '',
          '',
          convertMinutesToHoursAndMinutes(data.totalMinutes),
          '',
          '',
          convertMinutesToHoursAndMinutes(data.actualTotalMinutes),
          '100%',
          `${Math.round(totalActualStaffingUtilization)}%`, // Display total actual staffing utilization as a whole number
          `$${Number(data.totalCost).toFixed(2)}`,
          `$${Number(data.actualTotalCost).toFixed(2)}`,
        ],
      },
    ],
  };
};

const StaffingCostPage: FC<RouteComponentProps> = ({ location }) => {

  const [dateRange, setDateRange] = useState<{ from: Date, to: Date }>({
    from: queryString.parse(location.search).date ? parse(queryString.parse(location.search).date + 'T00:00:00') : new Date(),
    to: queryString.parse(location.search).date ? addDays(parse(queryString.parse(location.search).date + 'T00:00:00'), 1) : addDays(new Date(), 1),
  });

  const handleDateRange = ({ dateRange } ) => {
    const value = dateRange;
    setDateRange({
      from: new Date(value.date.toString() + 'T00:00:00.000'),
      to: new Date(value.date.plus(value.period).toString() + 'T00:00:00.000')
    });
  }
  const { data: data, loading } = useQuery(staffingCostQuery, {
    variables: { dateRange: { from: format(dateRange.from, 'YYYY-MM-DD'), to: format(dateRange.to, 'YYYY-MM-DD') } },
  });

  const table1: StaffCostSummary = data?.staffingCost?.staffCostSummary;
  const table2: ProcedureStaffCostSummary = data?.staffingCost?.procedureStaffCostSummary;

  const showTable1 = table1 && format(dateRange.from, 'MM/DD/YYYY') !== format(subDays(dateRange.to, 1), 'MM/DD/YYYY');
  const showTable1Group = table1 && (table1?.staffCostPerDate?.length || 0) > 0;
  const showTable2Group = table2 && (table2?.procedureStaffCostPerDate?.length || 0) > 0;
  const noData = !showTable1 && !showTable1Group && !showTable2Group;

  return (
    // @ts-ignore
    <DarkThemeProviderWrapper>
      <Box m={4}>
        <Box mb={4} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h3">
            Staffing Cost
          </Typography>

          <Box my={2} style={{ width: '20%' }}>
            <Filters
              hideProcedureTypeSelectInput={true}
              hidePhysicianSelectInput={true}
              onChange={handleDateRange}
              value={{ dateRange: Range.of(toLocalDate(dateRange.from), Period.between(toLocalDate(dateRange.from), toLocalDate(dateRange.to))) }}
              reload={false}
            />
          </Box>
        </Box>

        {loading && (
          <Box display="flex" alignItems="center" m={2} style={{ gap: '1em'}}>
            <CircularProgress size={30}/>
            <Typography variant="body1" color="textSecondary">Loading...</Typography>
          </Box>
        )}

        {!loading && noData && (<Box mt={4}>
          <Typography variant="body1" color="textSecondary">There are no assigned shifts, so there is no analysis for a given date range.</Typography>
        </Box>)}

        {showTable1 && (
          <Box mt={2} mb={2}>
            <TableWithColumnSelector
              configName="staffing-cost-table-config"
              tableId={table1.id}
              tableName={`Staffing Cost, from ${format(dateRange.from, 'MM/DD/YYYY')} to ${format(
                subDays(dateRange.to, 1),
                'MM/DD/YYYY',
              )}`}
              excelFileName={`Staffing-Cost-${format(dateRange.from, 'MM/DD/YYYY')}-${format(
                subDays(dateRange.to, 1),
                'MM/DD/YYYY',
              )}`}
              {...toTableData1(table1)}
            />
          </Box>
        )}

        {table1 &&
          table1.staffCostPerDate.map(e => {
            const { configHeader, headers, rows } = toTableData12(e);
            return (
              <Box mt={2} mb={2} key={`${table1.id}-${e.date}`}>
                <TableWithColumnSelector
                  configName="staffing-cost-table-config"
                  tableId={`${table1.id}-${e.date}`}
                  tableName={`Staffing Cost, ${format(e.date, 'MM/DD/YYYY')}`}
                  excelFileName={`Staffing-Cost-${format(e.date, 'MM/DD/YYYY')}`}
                  configHeader={configHeader}
                  headers={headers}
                  rows={rows}
                />
              </Box>
            );
          })}

        {table2 &&
          table2.procedureStaffCostPerDate.map(e => {
            const { configHeader, headers, rows } = toTableData2(e);
            return (
              <Box mt={2} mb={2} key={`${table2.id}-${e.id}`}>
                <TableWithColumnSelector
                  configName="procedure-staffing-cost-table-config"
                  tableId={`${table2.id}-${e.id}`}
                  tableName={`Procedure Staffing Cost, ${format(e.date, 'MM/DD/YYYY')}`}
                  excelFileName={`SProcedure-Staffing-Cost-${format(e.date, 'MM/DD/YYYY')}`}
                  configHeader={configHeader}
                  headers={headers}
                  rows={rows}
                />
              </Box>
            );
          })}
      </Box>
    </DarkThemeProviderWrapper>
  );
};

export default StaffingCostPage;
