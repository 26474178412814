import React from 'react';
import OperationRoomTabletV2 from './v2/OperationRoomTabletV2';
import OperationRoomTabletV1 from './OperationRoomTabletV1';
import { useScope } from '../../../../hooks/useScope';

const OperationRoomTablet = (props: any) => {
  const scope = useScope();
  const isScopeLoaded = scope;
  const orTabletV2 = scope?.hospital?.modules?.orTabletV2;
  return isScopeLoaded ? (
    orTabletV2 ? (
      <OperationRoomTabletV2 {...props} />
    ) : (
      <OperationRoomTabletV1 {...props} />
    )
  ) : null;
};

export default OperationRoomTablet;
