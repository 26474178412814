import { withProps } from 'recompose';
import styled from 'styled-components';

const size = props => {
  if (props.xl) {
    return 'xl';
  } else if (props.l) {
    return 'l';
  } else {
    return 'm';
  }
};

export default withProps({ children: 'edit', className: 'material-icons' })(styled.i`
  font-size: ${props => props.theme.textSize[size(props)]};
  color: ${props => props.theme.linkColor.toString()};
  cursor: pointer;
  &:active {
    color: ${props => props.theme.linkColor.darken(0.2).toString()};
  }
`);
