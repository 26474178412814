import React, {useState} from 'react';
import styled from 'styled-components';
import { withDate } from '../../../HospitalInfo';
import { differenceInSeconds } from 'date-fns';

const Timer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10em;
  font-family: monospace;
  font-weight: 500;
`;

const leftPad = val => (String(val).length < 2 ? `0${val}` : val);

const formatDuration = seconds => {
  const hrs = Math.floor(seconds / 3600);
  const mnt = Math.floor((seconds - hrs * 3600) / 60);
  const sec = seconds % 60;
  return [leftPad(hrs), leftPad(mnt), leftPad(sec)].join(':');
};

const withSeconds = withDate(1000);

const CleaningClock = (props) => {

  const [cleaningStartedAtDefault] = useState(new Date());

  return (
    <Timer>
      {formatDuration(differenceInSeconds(props.date, props?.room?.cleaningStartedAt || cleaningStartedAtDefault))}
    </Timer>
  );
};

export default withSeconds(CleaningClock);
