import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment PartnerData on Partner {
      id
      name
    }
  `,
};

export const list = gql`
  query partners($partnershipRole: PartnershipRole) {
    partners(partnershipRole: $partnershipRole) {
      ...PartnerData
    }
  }
  ${fragments.all}
`;

export const listGroup = gql`
  query group {
    partners: group {
      ...PartnerData
    }
  }
  ${fragments.all}
`;

export default {
  list,
};
