import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';
import isNumber from 'lodash/isNumber';
import isArray from 'lodash/isArray';

type Path = (string | number | boolean)[];

function select(path: Path, questions: any, answers?: any): [any, any | undefined] | undefined {
  const remainingPath = [...path];

  let currentQuestion = questions;
  let currentAnswer = answers;

  while (currentQuestion && remainingPath.length > 0) {
    const nodeType = get(currentQuestion, 'type');

    const key = remainingPath[0];

    switch (nodeType) {
      case 'Sections':
        if (isBoolean(key)) {
          return undefined;
        }
        currentQuestion = get(
          get(currentQuestion, 'props.sections', []).find(([name]: any) => name === key),
          [1, 'body']
        );
        currentAnswer = get(currentAnswer, ['sections', key]);
        remainingPath.shift();

        break;
      case 'Object':
        if (isBoolean(key)) {
          return undefined;
        }
        currentQuestion = get(
          get(currentQuestion, 'props.schema', []).find(([name]: any) => name === key),
          1
        );
        currentAnswer = get(currentAnswer, ['schema', key]);
        remainingPath.shift();
        break;
      case 'Text':
        return undefined;
      case 'LongText':
        return undefined;
      case 'Checkboxes':
        if (isBoolean(key)) {
          return undefined;
        }

        const checkbox = isNumber(key)
          ? get(currentQuestion, ['props', 'checkboxes', key])
          : get(currentQuestion, ['props', 'checkboxes'], []).find((c: any) => (isArray(c) ? c[0] === key : c === key));

        if (!checkbox || !isArray(checkbox)) {
          return undefined;
        }

        currentQuestion = checkbox[1];
        currentAnswer = get(currentAnswer, ['checkboxes', checkbox[0], 1]);

        remainingPath.shift();
        break;
      case 'List':
        if (isBoolean(key)) {
          return undefined;
        }

        currentQuestion = get(currentQuestion, 'props.item');
        currentAnswer = get(currentAnswer, ['list', key]);

        remainingPath.shift();
        break;
      case 'YesNo':
        if (key === true || String(key).toLowerCase() === 'yes') {
          currentQuestion = get(currentQuestion, 'props.ifYes');
          currentAnswer = get(currentAnswer, ['yes', 1]);

          remainingPath.shift();
        } else if (key === false || String(key).toLowerCase() === 'no') {
          currentQuestion = get(currentQuestion, 'props.ifNo');
          currentAnswer = get(currentAnswer, ['yes', 1]);

          remainingPath.shift();
        } else {
          return undefined;
        }
        break;
      case 'OneOf':
        if (isBoolean(key)) {
          return undefined;
        }

        const option = isNumber(key)
          ? get(currentQuestion, ['props', 'options', key])
          : get(currentQuestion, ['props', 'options'], []).find((o: any) => (isArray(o) ? o[0] === key : o === key));

        if (!option || !isArray(option)) {
          return undefined;
        }

        currentQuestion = option[1];
        currentAnswer = get(currentAnswer, ['option', 1]);

        remainingPath.shift();
        break;
      case 'Medication':
        return undefined;
      case 'BodyMassIndex':
        return undefined;
      case 'CityStateZip':
        return undefined;
      case 'DateOfBirth':
        return undefined;
      case 'Date':
        return undefined;
      default:
        return undefined;
    }
  }

  return currentQuestion ? [currentQuestion, currentAnswer] : undefined;
}

export default function createSelector(
  path: Path
): (questions: any, answers?: any) => [any | undefined, any | undefined] | undefined {
  return (questions, answers) => select(path, questions, answers);
}
