export default function isMiddleEast(hospitalId?: number): boolean {
  return (
    window.location.hostname === 'care.view.me.ospitek.com' ||
    window.location.hostname === 'view.me.ospitek.com' ||
    ((window.location.hostname === 'care.testing.ospitek.com' ||
      window.location.hostname === 'testing.ospitek.com' ||
      window.location.hostname === 'localhost') &&
      hospitalId === 53)
  );
}
