import React, { useContext } from 'react';
import styled from 'styled-components';
import { H4 } from '../../../../se/components/typography';
import Button from '../../../../se/components/Button';
import has from 'lodash/has';
import config from '../../../../config';
import get from 'lodash/get';
import useHasAccessRight from '../../../../hooks/useHasAccessRight';
import Tooltip from '../../../Tooltip';
import { QuestionnaireEntry } from '../../../../types/Questionnaire';
import { withScope } from '../../../../../src/components/HospitalInfo';
import connectionProviderContext from '../../../pages/kiosk/connectionProviderContext';
import { QuestionnaireTypeContext } from '../QuestionnaireAnswers';

export const Actions = styled.div`
  display: flex;
  align-items: center;
  > * {
    white-space: nowrap;
  }

  @media (max-width: 768px) {
    > * {
      white-space: initial;
    }
  }
`;

const TitleAndActions = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const EntryFormWrapper = styled.div`
  padding-bottom: 1em;
  @media (min-width: 769px) {
    display: flex;
  }
`;

const QuestionnaireActions = ({
  entryQuestionnaire,
  editActionLabel,
  children,
}: {
  entryQuestionnaire: QuestionnaireEntry;
  editActionLabel?: any;
  children?: React.ReactNode;
}) => {
  const hasAnswers = !!get(entryQuestionnaire, 'answers');

  const openEditing = (accessToken: string, organizationId?: string) => () => {
    if (entryQuestionnaire) {
      window.open(
        `${config.careURL}/manage/questionnaire/${entryQuestionnaire.id}?accessToken=${encodeURIComponent(
          accessToken
        )}&organizationId=${organizationId}`,
        'questionnaire',
        `width=1024,height=750`
      );
    }
  };

  const questionnaireType = useContext(QuestionnaireTypeContext);

  const hasAccessRight = useHasAccessRight();
  const isAllowedToEdit = hasAccessRight('patient.edit');

  return (
    <connectionProviderContext.Consumer>
      {({ accessToken, organizationId }) => (
        <EntryFormWrapper>
          <TitleAndActions>
            <H4 style={{ marginRight: '0.5em' }}>Entry Form</H4>
            {hasAnswers && <Actions>{children}</Actions>}
          </TitleAndActions>
          <div style={{ marginLeft: 'auto' }}>
            <Tooltip
              content={!isAllowedToEdit ? 'You don’t have sufficient permissions to edit this document.' : null}
              delay={undefined}
            >
              <Button
                onClick={openEditing(accessToken, organizationId)}
                disabled={!has(entryQuestionnaire, 'id') || !isAllowedToEdit}
                busy={undefined}
              >
                {editActionLabel || 'Edit Form'}
              </Button>
            </Tooltip>
          </div>
        </EntryFormWrapper>
      )}
    </connectionProviderContext.Consumer>
  );
};

export default QuestionnaireActions;
