import { gql } from '@apollo/client';

export const createQuestionnaireAnswers = gql`
  mutation createQuestionnaireExchange($procedureId: Long!, $questionnaireId: Long!, $answers: Json!, $pin: String!) {
    createQuestionnaireExchange(
      procedureId: $procedureId
      questionnaireId: $questionnaireId
      answers: $answers
      pin: $pin
    )
  }
`;

export const updateQuestionnaireAnswers = gql`
  mutation updateQuestionnaireExchange(
    $questionnaireExchangeId: Long!
    $answers: Json!
    $pin: String!
    $baseAnswers: Json
  ) {
    updateQuestionnaireExchange(
      questionnaireExchangeId: $questionnaireExchangeId
      answers: $answers
      pin: $pin
      baseAnswers: $baseAnswers
    )
  }
`;

export const checkIfCompleted = gql`
  mutation checkIfCompleted($questions: Json!, $answers: Json!) {
    checkIfCompleted(questions: $questions, answers: $answers)
  }
`;
