import React from 'react';
import TransitionRules from './TransitionRules';
import TitleAction from '../../../se/components/TitleAction';
import { DefaultTitle } from '../../../se/components/entity/EntityRouter';
import styled from 'styled-components';
import { withTheme } from '../../../se/theme';
import { withRouter } from 'react-router';
import DischargeConfiguration from './DischargeConfiguration';
import ButtonRouterLink from '../../../se/components/ButtonRouterLink';

const Separator = styled.div`
  background-color: ${withTheme(theme => theme.textColor.alpha(0.08).string())};
  height: 2px;
  margin: 2em 0;
`;

const RulesConfiguration = ({ history }) => (
  <div>
    <TitleAction
      Title={() => <DefaultTitle>Transition Configuration</DefaultTitle>}
      actionStyle={{ style: { gridTemplateColumns: '0.5fr' } }}
    >
      <ButtonRouterLink to={`${history.location.pathname}?edit=true`}>Edit</ButtonRouterLink>
    </TitleAction>

    <DischargeConfiguration />
    <Separator />
    <TransitionRules />
  </div>
);

export default withRouter(RulesConfiguration);
