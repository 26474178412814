import React from 'react';
import styled from 'styled-components';
import TextInput from '../../../se/components/inputs/TextInput';
import responsive from '../../../se/utilities/responsive';
import { Mutation } from '@apollo/client/react/components';

const InlineTextInput = styled(TextInput)`
  outline: none;
  width: 15em;
  ${props => (props.disabled ? 'pointer-events: none' : undefined)};

  ${responsive.xs.andSmaller`
    width: 100%;
  `}
`;

class DelayedTextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  debouncedOnFilterChange = fn => val => {
    clearTimeout(this.timeout);
    this.setState({ value: val });
    this.timeout = setTimeout(() => fn(val), 250);
  };

  onChange = mutation => newValue => {
    const { value, variables, path } = this.props;
    if (value !== newValue) {
      mutation({ variables: { ...variables, [path]: newValue } });
    }
  };

  render() {
    const { mutation } = this.props;
    return (
      <Mutation mutation={mutation}>
        {update => (
          <InlineTextInput
            disabled={this.props.disabled}
            value={this.state.value}
            onChange={this.debouncedOnFilterChange(this.onChange(update))}
            style={this.props.style}
          />
        )}
      </Mutation>
    );
  }
}

export default DelayedTextInput;
