import React from 'react';

export const Context = React.createContext();

export const withPatientNameFormat = Component => props =>
  (
    <Context.Consumer>
      {patientNameFormat => <Component {...props} patientNameFormat={patientNameFormat} />}
    </Context.Consumer>
  );
