import React, { CSSProperties, ReactNode } from 'react';
import { useTheme } from '@material-ui/core';

interface TableCellTopProps {
  column: number;
  leftOffset: number;
  span: number;
  className?: string;
  style?: CSSProperties;
  children: (column: number) => ReactNode;
}

const TableCellTop = ({ span, column, leftOffset, className, style, children }: TableCellTopProps) => {
  const theme = useTheme();

  return (
    <div
      key={column}
      className={className}
      style={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 10,
        whiteSpace: 'nowrap',
        gridRow: `1 / span 1`,
        gridColumn: `${1 + column * span + leftOffset} / span ${span}`,
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `2px solid ${theme.palette.divider}`,
        borderRight: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...(style || {}),
      }}
    >
      {children(column)}
    </div>
  );
};

export default TableCellTop;
