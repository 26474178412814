import { graphql } from '@apollo/client/react/hoc';
import React from 'react';
import get from 'lodash/get';
import Logo from '../../../Logo';
import { scope } from '../../../../graph/scope';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const lineClamp = 2;

const useStyles = makeStyles(theme => ({
  hospitalName: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    minHeight: `calc(${theme.typography.body1.fontSize} * ${theme.typography.body1.lineHeight} * ${lineClamp})`,
    whiteSpace: 'normal',
    lineClamp: lineClamp,
    overflow: 'hidden',
    transitionDelay: theme.transitions.duration.standard,
    transition: theme.transitions.create('opacity', {
      delay: theme.transitions.duration.standard,
    }),
  },
  hideName: {
    opacity: 0,
    transition: theme.transitions.create('none', {
      delay: 0,
    }),
  },
}));

const Header = ({ data, match, compressed }) => {
  const classes = useStyles();

  return (
    <Box px={compressed ? 1 : 3} pb={3} pt={match && match.path === '/su/:organizationId' ? 3 : 2}>
      <Box pb={3}>
        <Logo />
      </Box>
      <Typography
        color="textSecondary"
        className={clsx(classes.hospitalName, {
          [classes.hideName]: compressed,
        })}
      >
        {get(data, 'scope.hospital.name')}
      </Typography>
    </Box>
  );
};

export default graphql(scope)(Header);
