import { Theme, alpha } from '@material-ui/core';

export default function StepIcon(theme: Theme) {
  return {
    MuiStepIcon: {
      root: {
        color: alpha(theme.palette.text.primary, 0.5),
      },
    },
  };
}
