export const errorMessage = code => {
  switch (code) {
    case 30001:
      return 'Queue overflow';
    case 30002:
      return 'Account suspended';
    case 30003:
      return 'Unreachable destination handset';
    case 30004:
      return 'Message blocked';
    case 30005:
      return 'Unknown destination handset';
    case 30006:
      return 'Landline or unreachable carrier';
    case 30007:
      return 'Carrier violation';
    case 30008:
      return 'Unknown error';
    case 30009:
      return 'Missing segment';
    case 30010:
      return 'Message price exceeds max price';
    default:
      return 'Unknown Error';
  }
};

export const errorDescription = code => {
  switch (code) {
    case 30001:
      return 'You tried to send too many messages too quickly, and your message queue overflowed. Try sending your message again after waiting for some time.';
    case 30002:
      return 'Your account was suspended between the time of message send and delivery. Please contact Ospitek support.';
    case 30003:
      return 'The destination handset you are trying to reach is switched off or otherwise unavailable.';
    case 30004:
      return 'The destination number you are trying to reach is blocked from receiving this message.';
    case 30005:
      return 'The destination number you are trying to reach is unknown and may no longer exist.';
    case 30006:
      return 'The destination number is unable to receive this message. Potential reasons could include trying to reach a landline or, in the case of short codes, an unreachable carrier.';
    case 30007:
      return 'Your message was flagged as objectionable by the carrier. To protect their subscribers, many carriers have implemented content or spam filtering.';
    case 30008:
      return 'The error does not fit into any of the above categories.';
    case 30009:
      return 'One or more segments associated with your multi-part inbound message was not received.';
    case 30010:
      return 'The price of your message exceeds the max price parameter.';
    default:
      return;
  }
};
