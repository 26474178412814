import { compose, mapProps, withProps } from 'recompose';
import { withLabel } from '../../../se/components/Label';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import TextInput from '../../../se/components/inputs/TextInput';
import { PhysicianNotificationNumbersInput } from '../NotificationNumbersInput';
import CheckboxInput from '../CheckboxInput';
import NumberInput from '../../../se/components/inputs/NumberInput';
import SelectInput from '../../../se/components/inputs/SelectInput';
import { PhysicianCategoryLabels } from '../../entities/physician/enums';
import { formatProviderId } from '../../../util/providerId';

export default withProps({
  schema: {
    name: compose(
      withLabel('Name'),
      withProps({
        placeholder: 'e.g., Mark Nordstrom',
        autoComplete: 'off',
        required: true,
      })
    )(TextInput),
    email: compose(
      withLabel('Email'),
      withProps({
        placeholder: 'e.g., john@clinic.edu',
        autoComplete: 'off',
        required: false,
      })
    )(TextInput),
    speciality: compose(
      withLabel('Speciality'),
      withProps({
        placeholder: 'e.g., Cardiology',
        autoComplete: 'off',
        required: false,
      })
    )(TextInput),
    category: compose(
      withLabel('Category'),
      withProps({
        placeholder: 'e.g., Eye',
        autoComplete: 'off',
        required: false,
        options: PhysicianCategoryLabels,
      })
    )(SelectInput),
    notificationNumbers: PhysicianNotificationNumbersInput,
    hstId: compose(
      withLabel('Provider Id'),
      withProps({
        placeholder: 'e.g., 192',
        autoComplete: 'off',
        required: false,
      }),
      mapProps(({ value, onChange, ...rest }) => ({
        value: formatProviderId(value),
        onChange: next => onChange('l/' + next),
        ...rest,
      }))
    )(NumberInput),
    shouldSendNotification: compose(withProps({ label: 'Send Notification' }))(CheckboxInput),
  },
})(ObjectInput);
