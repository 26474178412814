export const ConsultationStatus = {
  Consultation: 'Consultation',
  ConsultationConfirmed: 'ConsultationConfirmed',
  ConsultationNeedsReschedule: 'ConsultationNeedsReschedule',
  ConsultationComplete: 'ConsultationComplete',
  ConsultationCanceled: 'ConsultationCanceled',
  ConsultationNoShow: 'ConsultationNoShow',
};

export const ConsultationStatuses = {
  [ConsultationStatus.Consultation]: 'Consultation Scheduled',
  [ConsultationStatus.ConsultationConfirmed]: 'Consultation Confirmed',
  [ConsultationStatus.ConsultationNeedsReschedule]: 'Needs Reschedule',
  [ConsultationStatus.ConsultationComplete]: 'Consultation Completed',
  [ConsultationStatus.ConsultationCanceled]: 'Consultation Canceled',
  [ConsultationStatus.ConsultationNoShow]: 'No Show',
};
