import React, { Fragment } from 'react';
import Button from '../../../se/components/Button';
import { withApollo } from '@apollo/client/react/hoc';

export const PhysicianUploadConfirmation = withApollo(
  ({ filePicker, file, rowsImported, onSubmit, onCancel, client }) => {
    const onDone = async () => {
      // const {data} = await client.query({ query: physicians.list, options: { fetchPolicy: 'network-only', variables: {filter: {}} } });
      // await client.cache.writeQuery({query: physicians.list, data, variables: {filter: {}} });
      // console.log(data);

      document.location.reload(true);
      onCancel();
    };

    return (
      <Fragment>
        <p style={{ marginBottom: '1.5rem' }}>
          {rowsImported} physician{rowsImported === 1 ? '' : 's'} imported.
        </p>
        <div>
          <Button primary style={{ marginRight: '1rem' }} onClick={onDone}>
            Complete
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </div>
      </Fragment>
    );
  }
);
