import { workingHours } from '../../entities/schedule/util/dummyData';
import Box from '@material-ui/core/Box';
import React from 'react';

const totalWorkingHours = workingHours.end - workingHours.start;

const Frame = ({ from, to, children }) => {
  const left = ((from - workingHours.start) / totalWorkingHours) * 100;
  const width = ((to - from) / totalWorkingHours) * 100;

  return (
    <Box display="flex" position="absolute" left={`${left}%`} width={`${width}%`} height="100%">
      {children}
    </Box>
  );
};

export default Frame;
