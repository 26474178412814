import React, { useMemo, useEffect, useRef, useState } from 'react';
import createReducer from '../../../../../../questionnaire/reducer';
import { format } from 'date-fns';
import styles from '../../styles';
import { useValue } from '../../ValueContext';
import createSelector from '../../../../../../questionnaire/selector';
import MedicalPassportField from '../../components/MedicalPassportField';
import toAge from '../../../../../../questionnaire/toAge';
import toSex from '../../../../../../questionnaire/toSex';
import { capitalize, get } from 'lodash';
import getAgeAndBmi from './pageUtils';
import MedicalPassportTextArea2 from '../../components/MedicalPassportTextArea2';
import { calculateBMI } from '../../../../../../questionnaire/toBMI';
import { morbid, morbidlist } from '../paths';
import Barcode from '../../../Barcode';

const medicalHistoryReducer = createReducer({
  Sections: sections => null,
  Object: fields => {
    const visibleFields = fields.filter(field => field.child);

    return visibleFields.length === 0 ? null : visibleFields.length === 1 ? (
      visibleFields[0].child
    ) : (
      <ul>
        {visibleFields.map((field, i) => (
          <li key={i}>{field.child}</li>
        ))}
      </ul>
    );
  },
  Text: (label, text) =>
    text ? (
      <p>
        {label.replace(
          /^Use the comment box to clarify answers and provide additional information/g,
          'Additional Infectious information'
        )}
        : {text}
      </p>
    ) : null,
  LongText: (label, text) =>
    text ? (
      <p>
        {label}: {text}
      </p>
    ) : null,
  Checkboxes: (label, checkboxes) => {
    const visibleCheckboxes = checkboxes.filter(checkbox => checkbox.checked);
    return visibleCheckboxes.length === 0 ? null : visibleCheckboxes.length === 1 ? (
      <div>
        <div>{visibleCheckboxes[0].label}</div>
      </div>
    ) : (
      <div>{visibleCheckboxes[0].label}</div>
    );
  },
  List: (label, children) => {
    const visibleChildren = children.filter(child => child);
    return visibleChildren.length === 0 ? null : visibleChildren.length === 1 ? (
      visibleChildren[0]
    ) : (
      <ul>
        {visibleChildren.map((child, i) => (
          <li key={i}>{child}</li>
        ))}
      </ul>
    );
  },
  YesNo: (question, positive, child) => {
    const transformedQuestion = capitalize(
      question
        .replace(/\?/g, '')
        .replace(/^Do you have\s/g, '')
        .replace(/^Are you\s/g, '')
        .replace(/^Do you\s/g, '')
        .replace(/^Have you\s/g, '')
        .replace(/^currently take any oral or injectable\s/g, '')
    );
    return positive ? (
      <div>
        <div>{transformedQuestion}</div>
      </div>
    ) : null;
  },
  OneOf: (label, options, answer) =>
    answer ? (
      <div>
        <div>
          {label}: {answer.label}
        </div>
      </div>
    ) : null,
  Medication: value => (value ? <span>{value}</span> : null),
  BodyMassIndex: (heightFt, heightIn, weightLb) =>
    heightFt && weightLb ? calculateBMI(weightLb, heightFt, heightIn ? heightIn : 0) : '',
  DateOfBirth: date => (date ? <span>{format(date, 'MM/DD/YYYY')}</span> : null),
  Date: date => (date ? <span>{format(date, 'MM/DD/YYYY')}</span> : null),
});

const medicalHistoryReducer2 = createReducer({
  Text: (label, text) => text || null,

  Checkboxes: (label, checkboxes) => {
    const visibleCheckboxes = checkboxes.filter(checkbox => checkbox.checked);

    return visibleCheckboxes.length === 0
      ? null
      : visibleCheckboxes.length === 1
      ? `${visibleCheckboxes[0].label}${visibleCheckboxes[0].child ? ` (${visibleCheckboxes[0].child})` : ''}`
      : visibleCheckboxes
          .map(checkbox => `${checkbox.label.replace(/\?/g, '')}${checkbox.child ? ` (${checkbox.child})` : ''}`)
          .join(', ');
  },
});

const ClinicalItem = ({ content, style }) =>
  content && <div style={{ marginLeft: '1em', margin: '2px 0', fontSize: '16px', ...style }}>{content}</div>;

export const getSelectorContent = selectorValue => (selectorValue && medicalHistoryReducer(...selectorValue)) || null;
export const getSelectorContent2 = selectorValue => (selectorValue && medicalHistoryReducer2(...selectorValue)) || null;

const ClinicalList = ({ questionnaire, questionnaireAnswers }) => {
  const {
    heartrhythm,
    p_heartrhythm,
    cad,
    angina,
    heartattack,
    aicd,
    pacemaker,
    copd,
    asthma,
    pneumonia,
    homeoxygen,
    tb,
    dialysis,
    liverfailure,
    cirrhosis,
    hepatitis,
    seizures,
    palsy,
    stroke,
    multipleSclerosis,
    parkinson,
    alzheimer,
    dementia,
    mentaldisability,
    myasthenia,
    aphasia,
    esophageal,
    bloodthinner,
    bloodclotting,
    familybloodclotting,
    diabetes,
    cancer,
    radiation,
    dystrophy,
    weightloss,
    skillednursefacility,
    wheelchair,
    latexallergy,
    malignant,
    pseudocholinesterase,
    difficultintubation,
    autism,
    bmi,
    pregnant,
    cdiff,
    vre,
    cmv,
    antibiotic,
    p_antibiotic,
    hiv,
    condition,
    p_condition,
    traveled,
    p_traveled,
    symtom,
    p_symtom,
    covidtest,
    p_covidtest,
    immunocompromised,
    infectiouscmt,
    downsyndrome,
  } = useMemo(
    () => ({
      heartrhythm: getSelectorContent(
        createSelector(['Cardiac', 'AbnormalHeartRhythm'])(questionnaire, questionnaireAnswers)
      ),
      p_heartrhythm: getSelectorContent(
        createSelector(['P_Cardiac', 'AbnormalHeartRhythm'])(questionnaire, questionnaireAnswers)
      ),
      cad: getSelectorContent(createSelector(['Cardiac', 'CAD'])(questionnaire, questionnaireAnswers)),
      angina: getSelectorContent(createSelector(['Cardiac', 'ChestPain'])(questionnaire, questionnaireAnswers)),
      heartattack: getSelectorContent(createSelector(['Cardiac', 'HeartAttack'])(questionnaire, questionnaireAnswers)),
      pacemaker: getSelectorContent(
        createSelector(['GeneralHistory', 'pacemaker'])(questionnaire, questionnaireAnswers)
      ),
      aicd: getSelectorContent(createSelector(['GeneralHistory', 'AICD'])(questionnaire, questionnaireAnswers)),
      copd: getSelectorContent(createSelector(['Pulmonary', 'Emphysema'])(questionnaire, questionnaireAnswers)),
      // asthma: getSelectorContent(createSelector(['Pulmonary', 'Asthma'])(questionnaire, questionnaireAnswers)),
      // pneumonia: getSelectorContent(createSelector(['Pulmonary', 'Pneumonia'])(questionnaire, questionnaireAnswers)),
      homeoxygen: getSelectorContent(createSelector(['Pulmonary', 'oxygen'])(questionnaire, questionnaireAnswers)),
      // tb: getSelectorContent(createSelector(['Pulmonary', 'Tuberculosis'])(questionnaire, questionnaireAnswers)),
      asthma: getSelectorContent(createSelector(['P_Pulmonary', 'Asthma'])(questionnaire, questionnaireAnswers)),
      pneumonia: getSelectorContent(createSelector(['P_Pulmonary', 'Pneumonia'])(questionnaire, questionnaireAnswers)),
      tb: getSelectorContent(createSelector(['P_Pulmonary', 'fibrosis'])(questionnaire, questionnaireAnswers)),
      dialysis: getSelectorContent(createSelector(['Renal', 'dialysis'])(questionnaire, questionnaireAnswers)),
      liverfailure: getSelectorContent(
        createSelector(['Hepatic', 'liver failure'])(questionnaire, questionnaireAnswers)
      ),
      cirrhosis: getSelectorContent(createSelector(['Hepatic', 'cirrhosis'])(questionnaire, questionnaireAnswers)),
      hepatitis: getSelectorContent(createSelector(['Hepatic', 'Hepatitis'])(questionnaire, questionnaireAnswers)),
      seizures: getSelectorContent(createSelector(['Neurologic', 'Seizures'])(questionnaire, questionnaireAnswers)),
      palsy: getSelectorContent(createSelector(['Neurologic', 'cerebral'])(questionnaire, questionnaireAnswers)),
      stroke: getSelectorContent(createSelector(['Neurologic', 'Stroke'])(questionnaire, questionnaireAnswers)),
      multipleSclerosis: getSelectorContent(
        createSelector(['Neurologic', 'Sclerosis'])(questionnaire, questionnaireAnswers)
      ),
      parkinson: getSelectorContent(createSelector(['Neurologic', 'parkinson'])(questionnaire, questionnaireAnswers)),
      alzheimer: getSelectorContent(createSelector(['Neurologic', 'alzheimer'])(questionnaire, questionnaireAnswers)),
      dementia: getSelectorContent(createSelector(['Neurologic', 'dementia'])(questionnaire, questionnaireAnswers)),
      mentaldisability: getSelectorContent(
        createSelector(['Neurologic', 'mentalDisability'])(questionnaire, questionnaireAnswers)
      ),
      myasthenia: getSelectorContent(createSelector(['Neurologic', 'myasthenia'])(questionnaire, questionnaireAnswers)),
      aphasia: getSelectorContent(createSelector(['Neurologic', 'aphasia'])(questionnaire, questionnaireAnswers)),
      esophageal: getSelectorContent(createSelector(['GI', 'esophageal'])(questionnaire, questionnaireAnswers)),
      bloodthinner: getSelectorContent(
        createSelector(['Vascular', 'bloodThinners'])(questionnaire, questionnaireAnswers)
      ),
      bloodclotting: getSelectorContent(createSelector(['Vascular', 'Bleeding1'])(questionnaire, questionnaireAnswers)),
      familybloodclotting: getSelectorContent(
        createSelector(['Vascular', 'Bleeding2'])(questionnaire, questionnaireAnswers)
      ),
      diabetes: getSelectorContent(createSelector(['Endocrine', 'Diabetes'])(questionnaire, questionnaireAnswers)),
      cancer: getSelectorContent(createSelector(['Endocrine', 'Cancer'])(questionnaire, questionnaireAnswers)),
      radiation: getSelectorContent(
        createSelector(['Endocrine', 'Cancer', 'yes', 'treatment'])(questionnaire, questionnaireAnswers)
      ),
      dystrophy: getSelectorContent(
        createSelector(['P_Musculoskeletal', 'dystrophy'])(questionnaire, questionnaireAnswers)
      ),
      weightloss: getSelectorContent(
        createSelector(['medications', 'weightloss'])(questionnaire, questionnaireAnswers)
      ),
      skillednursefacility: getSelectorContent(
        createSelector(['GeneralHistory', 'nursingFacility'])(questionnaire, questionnaireAnswers)
      ),
      wheelchair: getSelectorContent2(
        createSelector(['basicscreening', 'device', 'yes'])(questionnaire, questionnaireAnswers)
      ),
      latexallergy: getSelectorContent(
        createSelector(['allergies', 'q1', 'Latex'])(questionnaire, questionnaireAnswers)
      )
        ? 'Latex allergy'
        : '',
      malignant: getSelectorContent(
        createSelector(['anesthesia complications', 'Malignant hyperthermia'])(questionnaire, questionnaireAnswers)
      ),
      pseudocholinesterase: getSelectorContent(
        createSelector(['anesthesia complications', 'Pseudocholinesterase deficiency'])(
          questionnaire,
          questionnaireAnswers
        )
      ),
      difficultintubation: getSelectorContent(
        createSelector(['anesthesia complications', 'Difficult intubation'])(questionnaire, questionnaireAnswers)
      ),
      autism: getSelectorContent(createSelector(['Psychiatric', 'autism'])(questionnaire, questionnaireAnswers)),
      bmi:
        getSelectorContent(createSelector(['basicscreening', 'bmi'])(questionnaire, questionnaireAnswers)) > 40
          ? 'BMI is ' +
            getSelectorContent(createSelector(['basicscreening', 'bmi'])(questionnaire, questionnaireAnswers))
          : '',
      pregnant: getSelectorContent(createSelector(['gynecology', 'pregnant'])(questionnaire, questionnaireAnswers)),
      cdiff: getSelectorContent(createSelector(['other', 'C-diff'])(questionnaire, questionnaireAnswers)),
      vre: getSelectorContent(createSelector(['other', 'VRE'])(questionnaire, questionnaireAnswers)),
      cmv: getSelectorContent(createSelector(['other', 'CMV'])(questionnaire, questionnaireAnswers)),
      antibiotic: getSelectorContent(
        createSelector(['other', 'antibioticResistant'])(questionnaire, questionnaireAnswers)
      ),
      p_antibiotic: getSelectorContent(
        createSelector(['other', 'antibioticResistant'])(questionnaire, questionnaireAnswers)
      ),
      hiv: getSelectorContent(createSelector(['other', 'HIV'])(questionnaire, questionnaireAnswers)),
      condition: getSelectorContent(createSelector(['other', 'Other conditions'])(questionnaire, questionnaireAnswers)),
      p_condition: getSelectorContent(
        createSelector(['P_other', 'Other conditions'])(questionnaire, questionnaireAnswers)
      ),
      traveled: getSelectorContent(createSelector(['other', 'traveled'])(questionnaire, questionnaireAnswers)),
      p_traveled: getSelectorContent(createSelector(['P_other', 'traveled'])(questionnaire, questionnaireAnswers)),
      symtom: getSelectorContent2(createSelector(['other', 'Recently'])(questionnaire, questionnaireAnswers)),
      p_symtom: getSelectorContent2(createSelector(['P_other', 'Recently'])(questionnaire, questionnaireAnswers)),
      covidtest: getSelectorContent(createSelector(['other', 'COVID19Test'])(questionnaire, questionnaireAnswers)),
      p_covidtest: getSelectorContent(createSelector(['P_other', 'COVID19Test'])(questionnaire, questionnaireAnswers)),
      immunocompromised: getSelectorContent(
        createSelector(['other', 'immunocompromisedState'])(questionnaire, questionnaireAnswers)
      ),
      infectiouscmt: getSelectorContent(
        createSelector(['other', 'infectiousCmt'])(questionnaire, questionnaireAnswers)
      ),
      downsyndrome: getSelectorContent(
        createSelector(['P_other', 'downSynderome'])(questionnaire, questionnaireAnswers)
      ),
    }),
    [questionnaire, questionnaireAnswers]
  );

  return (
    <>
      <ClinicalItem content={heartrhythm} />
      <ClinicalItem content={p_heartrhythm} />
      <ClinicalItem content={cad} />
      <ClinicalItem content={angina} />
      <ClinicalItem content={heartattack} style={{ marginBottom: '16px' }} />
      <ClinicalItem content={pacemaker} />
      <ClinicalItem content={aicd} style={{ marginBottom: '16px' }} />
      <ClinicalItem content={copd} />
      <ClinicalItem content={asthma} />
      <ClinicalItem content={pneumonia} />
      <ClinicalItem content={homeoxygen} />
      <ClinicalItem content={tb} style={{ marginBottom: '16px' }} />
      <ClinicalItem content={dialysis} style={{ marginBottom: '16px' }} />
      <ClinicalItem content={liverfailure} />
      <ClinicalItem content={cirrhosis} />
      <ClinicalItem content={hepatitis} style={{ marginBottom: '16px' }} />
      <ClinicalItem content={seizures} />
      <ClinicalItem content={palsy} />
      <ClinicalItem content={stroke} />
      <ClinicalItem content={multipleSclerosis} />
      <ClinicalItem content={parkinson} />
      <ClinicalItem content={alzheimer} />
      <ClinicalItem content={dementia} />
      <ClinicalItem content={mentaldisability} />
      <ClinicalItem content={myasthenia} />
      <ClinicalItem content={aphasia} style={{ marginBottom: '16px' }} />
      <ClinicalItem content={esophageal} style={{ marginBottom: '16px' }} />
      <ClinicalItem content={bloodthinner} />
      <ClinicalItem content={bloodclotting} />
      <ClinicalItem content={familybloodclotting} style={{ marginBottom: '16px' }} />
      <ClinicalItem content={diabetes} />
      <ClinicalItem content={cancer} />
      <ClinicalItem content={radiation} style={{ marginBottom: '16px' }} />
      <ClinicalItem content={dystrophy} style={{ marginBottom: '16px' }} />
      <ClinicalItem content={weightloss} style={{ marginBottom: '16px' }} />
      <ClinicalItem content={skillednursefacility} style={{ marginBottom: '16px' }} />
      <ClinicalItem content={wheelchair} style={{ marginBottom: '16px' }} />
      <ClinicalItem content={latexallergy} style={{ marginBottom: '16px' }} />
      <ClinicalItem content={malignant} />
      <ClinicalItem content={pseudocholinesterase} />
      <ClinicalItem content={difficultintubation} style={{ marginBottom: '16px' }} />
      <ClinicalItem content={autism} />
      <ClinicalItem content={bmi} />
      <ClinicalItem content={pregnant} style={{ marginBottom: '16px' }} />
      <ClinicalItem content={cdiff} />
      <ClinicalItem content={vre} />
      <ClinicalItem content={cmv} />
      <ClinicalItem content={antibiotic} />
      <ClinicalItem content={p_antibiotic} />
      <ClinicalItem content={hiv} />
      <ClinicalItem content={condition} />
      <ClinicalItem content={p_condition} />
      <ClinicalItem content={traveled} />
      <ClinicalItem content={p_traveled} />
      <ClinicalItem content={symtom} />
      <ClinicalItem content={p_symtom} />
      <ClinicalItem content={covidtest} />
      <ClinicalItem content={p_covidtest} />
      <ClinicalItem content={immunocompromised} />
      <ClinicalItem content={infectiouscmt} />
      <ClinicalItem content={downsyndrome} />
    </>
  );
};

const Page1 = ({
  formName,
  pageNumber,
  showQRCode,
  hospitalName,
  value,
  defaultValue,
  lastPreOpCompletedEvent,
  ...props
}) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const { questionnaire, questionnaireAnswers } = useValue();
  const { age } = getAgeAndBmi(defaultValue?.demographicInformation?.dob);
  const clinicalListRef = useRef(null);
  const [clinicalListHeight, setClinicalListHeight] = useState(0);

  useEffect(() => {
    const newClinicalListHeight = clinicalListRef.current.offsetHeight;
    setClinicalListHeight(newClinicalListHeight);
  }, []);

  const clinicalListMinHeight = clinicalListHeight > 1040 ? '130rem' : 'auto';

  const gender = get(questionnaireAnswers, 'sections.demographicInformation.schema.sex.option');

  let genderPoint = 0;
  if (gender == 'Male') {
    genderPoint = 2;
  } else if (gender == 'Female') {
    genderPoint = 1;
  }

  let agePoint = 0;
  if (age >= 60 && age <= 69) {
    agePoint += 1;
  } else if (age >= 70 && age <= 79) {
    agePoint += 2;
  } else if (age >= 80) {
    agePoint += 3;
  }

  let fallPoint = 0;
  if (get(questionnaireAnswers, 'sections.basicscreening.schema.hxOfFalls.yes')) {
    fallPoint = 20;
  }

  let oxygenPoint = 0;
  if (get(questionnaireAnswers, 'sections.Pulmonary.schema.oxygen.yes[0]')) {
    oxygenPoint = 1;
  }

  let morbidCount = 0;

  for (let i = 0; i < 28; i++) {
    if (get(questionnaireAnswers, morbidlist[i], '')) {
      morbidCount += 1;
    }
  }
  let morbidPoint = 0;
  if (morbidCount >= 3) {
    morbidPoint = 15;
  } else if (morbidCount >= 2) {
    morbidPoint = 10;
  } else if (morbidCount == 1) {
    morbidPoint = 5;
  }

  let mobilityPoint1 = 0;
  if (get(questionnaireAnswers, 'sections.basicscreening.schema.assistance1.yes')) {
    mobilityPoint1 = 10;
  }

  let mobilityPoint2 = 0;
  if (
    get(questionnaireAnswers, 'sections.basicscreening.schema.problemBalance.yes') ||
    get(questionnaireAnswers, 'sections.basicscreening.schema.device.yes[0]')
  ) {
    mobilityPoint2 = 10;
  }

  let visionPoint = 0;
  if (
    get(questionnaireAnswers, 'sections.GeneralHistory.schema.vision1.yes') ||
    get(questionnaireAnswers, 'sections.GeneralHistory.schema.vision2.yes') ||
    get(questionnaireAnswers, 'sections.GeneralHistory.schema.vision6.yes') ||
    get(questionnaireAnswers, 'sections.GeneralHistory.schema.vision7.yes')
  ) {
    visionPoint = 5;
  }

  let auditoryPoint = 0;
  if (
    get(questionnaireAnswers, 'sections.GeneralHistory.schema.hearing1.yes[0]') ||
    get(questionnaireAnswers, 'sections.GeneralHistory.schema.hearing2.yes[0]')
  ) {
    auditoryPoint = 2;
  }

  let fallRiskPoint =
    genderPoint +
    agePoint +
    fallPoint +
    oxygenPoint +
    morbidPoint +
    mobilityPoint1 +
    mobilityPoint2 +
    visionPoint +
    auditoryPoint;

  return (
    <div id="page1" className="page" style={{ height: '100%', paddingBottom: '0' }}>
      <div style={{ minHeight: '69.8rem', paddingBottom: '0' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '50% 50%',
            gridGap: '1rem',
            alignContent: 'start',
            marginBottom: '1rem',
            paddingTop: '1rem',
            fontSize: '16px',
          }}
        >
          <div style={styles.div}>
            <b>
              <div style={{ fontSize: '12px', paddingBottom: '0', lineHeight: '14px' }}>
                {(hospitalName || '').toUpperCase()}
              </div>
              <div style={{ fontSize: '20px', paddingBottom: '0' }}>Warning / Diagnostic Testing Information</div>
            </b>
            <div style={{ fontSize: '12px' }}>
              <MedicalPassportTextArea2
                name="procedure.name"
                label="Procedure"
                style={{ display: 'flex' }}
                fontSize={12}
                nobackground
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <div>
              {showQRCode && (
                <Barcode
                  formName={formName}
                  pageNumber={pageNumber}
                  providerId={defaultValue?.procedure?.patient?.providerId}
                  serviceTime={defaultValue?.procedure?.serviceTime}
                />
              )}
            </div>
            <div
              style={{
                fontSize: '12px',
                border: '1px solid black',
                padding: '0.2rem',
                lineHeight: '15px',
              }}
            >
              <MedicalPassportField
                name="procedure.patient.name"
                label="Patient"
                fontWeight={'bold'}
                fontSize={14}
                nobackground
              />
              <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={12} nobackground />
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '40% 60%',
                  alignContent: 'start',
                }}
              >
                <MedicalPassportField
                  name="dob"
                  label="DOB"
                  path={['demographicInformation', 'dob']}
                  fontSize={12}
                  nobackground
                />
                <MedicalPassportField
                  name="age"
                  label="Age"
                  path={['demographicInformation', 'dob']}
                  reducer={toAge}
                  warning={age > 70}
                  fontSize={12}
                  nobackground
                />
                <MedicalPassportField name="procedure.serviceTime" label="DOS" fontSize={12} nobackground />
                <MedicalPassportField
                  name="sex"
                  label="Sex"
                  path={['demographicInformation', 'sex']}
                  reducer={toSex}
                  fontSize={12}
                  nobackground
                />
              </div>
              <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={12} nobackground />
            </div>
          </div>
        </div>
        {lastPreOpCompletedEvent && (
          <div style={styles.div}>
            Pre-Op Performed By {lastPreOpCompletedEvent?.user?.name || ''}, Date{' '}
            {lastPreOpCompletedEvent?.timestamp ? format(lastPreOpCompletedEvent.timestamp, 'MM/DD/YYYY HH:mm') : ''}
          </div>
        )}
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '50% 50%',
            gridGap: '1rem',
            alignContent: 'start',
            fontSize: '16px',
          }}
        >
          <div
            style={{
              gridColumn: '1 / span 2',
              height: '1px',
              background: 'black',
            }}
          />
          <div>
            <div>
              <div style={{ fontWeight: 'bold' }}>CLINICALLY SIGNIFICANT ITEMS:</div>
              <div ref={clinicalListRef} style={{ position: 'relative', minHeight: clinicalListMinHeight }}>
                <ClinicalItem
                  content={fallRiskPoint >= 20 ? 'Fall Risk Score is ' + fallRiskPoint : ''}
                  style={{ marginBottom: '16px' }}
                />
                <ClinicalList questionnaire={questionnaire} questionnaireAnswers={questionnaireAnswers} />
              </div>
            </div>
          </div>
          <div style={styles.div}>
            <MedicalPassportField
              name="procedure.pcp"
              path={['demographicInformation', 'physician2', 'yes']}
              label="PCP"
              fontSize={'16px'}
              nobackground
            />
            Specialist:
            <MedicalPassportTextArea2
              name="procedure.specialist"
              path={['demographicInformation', 'specialist', 'yes']}
              fontSize={'16px'}
              nobackground
            />
          </div>
        </div>
      </div>
      <footer
        style={{
          display: 'grid',
          gridTemplateColumns: '13.25% 14.5% 11.5% 17% 0.05% 14.5% 29%',
          fontSize: '11px',
          height: '20px',
        }}
      >
        <div style={{ paddingTop: '3px' }}>Provided by Patient{'('}</div>
        <MedicalPassportField name="questionnaire.firstSubmittedAt" fontSize={11} nobackground />
        <div style={{ paddingTop: '3px' }}>{')'} Last updated by</div>
        <MedicalPassportField name="procedure.patient.name" fontSize={11} nobackground />
        <div style={{ paddingTop: '3px' }}>{'('}</div>
        <MedicalPassportField name="questionnaire.submittedAt" fontSize={11} nobackground />
        <div>
          {')'}{' '}
          <span style={{ fontSize: '14px', marginLeft: '9rem' }}>
            <b>Ospitek, Inc</b>
          </span>
        </div>
      </footer>
    </div>
  );
};

export default Page1;
