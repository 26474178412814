import emptyReducer from './emptyReducer';

export const calculateBMIStr = (weightLbStr?: string, heightFtStr?: string, heightInStr?: string): string | null => {
  const params = [weightLbStr, heightFtStr, heightInStr];
  const defined = params.every(e => e !== null && e !== undefined);
  if (!defined) return null;

  const parsed = params?.map(e => (e ? parseInt(e, 10) : NaN));
  if (!parsed.every(e => !isNaN(e)) || !parsed?.[1]) return null;

  const [weightLb, heightFt, heightIn] = parsed;

  const heightInInches = 12 * heightFt + heightIn;
  const bodyMassIndex = (703 * weightLb) / heightInInches / heightInInches;
  return isFinite(bodyMassIndex) ? bodyMassIndex.toFixed(1) : null;
};

export const calculateBMI = (weightLb: number, heightFt: number, heightIn: number) => {
  const heightInInches = 12 * heightFt + heightIn;
  const bodyMassIndex = (703 * weightLb) / heightInInches / heightInInches;
  return isFinite(bodyMassIndex) ? bodyMassIndex.toFixed(1) : '-';
};

const toBMI = emptyReducer<string | null>(null).with({
  BodyMassIndex: (heightFt, heightIn, weightLb) =>
    heightFt && weightLb ? calculateBMI(weightLb, heightFt, heightIn ? heightIn : 0) : '',
});

export default toBMI;
