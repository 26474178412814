import React from 'react';
import styles from '../styles';
import MedicalPassportTextArea from '../components/MedicalPassportTextArea';
import MedicalPassportAllergiesRow from '../components/MedicalPassportAllergiesRow';
import MedicalPassportField from '../components/MedicalPassportField';
import MedicalPassportCheckbox from '../components/MedicalPassportCheckbox';
import toHeight from '../../../../../questionnaire/toHeight';
import toHighlightsString from '../../../../../questionnaire/toHighlightsString';
import toWeightKgLbs from '../../../../../questionnaire/toWeightKgLbs';
import toAge from '../../../../../questionnaire/toAge';
import toSex from '../../../../../questionnaire/toSex';
import getAgeAndBmi from './pageUtils';
import { testReducer } from '../../../../../questionnaire/fallRiskReducers';
import { useValue } from '../ValueContext';
import get from 'lodash/get';
import toUsageString from '../../../../../questionnaire/toUsageString';
import skin from '../../../../../assets/images/skin_assessment.png';
import Barcode from '../../Barcode';

const activityAssessment = [
  {
    label: 'Ambulatory',
    name: 'ambulatory',
  },
  {
    label: 'Ambulatory w/ assistance',
    name: 'ambulatoryWithAssistance',
  },
];

const assistiveDevice = [
  {
    label: 'Crutches',
    name: 'crutches',
  },
  {
    label: 'Walker',
    name: 'walker',
  },
  {
    label: 'Wheelchair',
    name: 'wheelchair',
  },
  {
    label: 'Cane (See Fall Assessment Record)',
    name: 'cane',
  },
];

const checkboxesPage5 = [
  {
    text: 'Level of Consciousness',
    checkboxes: [
      {
        label: 'Alert/Oriented',
        name: 'page5.alertOriented',
      },
      {
        label: 'See Notes',
        name: 'page5.seeNotes1',
      },
    ],
  },
  {
    text: 'Emotional Status',
    checkboxes: [
      {
        label: 'Calm',
        name: 'page5.calm',
      },
      {
        label: 'Anxious',
        name: 'page5.anxious',
      },
      {
        label: 'See Notes',
        name: 'page5.seeNotes2',
      },
    ],
  },
];

const CommonPartOne = () => (
  <div>
    <div style={styles.sectionHeader}>NURSING NOTES</div>
    {[...Array(7)].map((e, index) => (
      <div style={styles.sectionEmptyRow} key={'note' + index}>
        {''}
      </div>
    ))}
  </div>
);

export const CommonPartP5P6 = ({ providerId, showQRCode, age }) => (
  <div style={{ ...styles.border, position: 'relative' }}>
    <div style={{ bottom: '5px', paddingLeft: '3px' }}>
      <div style={{ marginBottom: '0.2rem' }}>
        <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={16} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem' }}>
        <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} fontSize={14} />
        <MedicalPassportField name="procedure.serviceTime" label="DOS" fontWeight={700} fontSize={14} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem' }}>
        <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={14} />
        <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={14} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem', paddingBottom: '1px' }}>
        <MedicalPassportField
          name="age"
          label="Age"
          path={['demographicInformation', 'dob']}
          reducer={toAge}
          warning={age > 70}
          fontSize={14}
        />
        <MedicalPassportField
          name="sex"
          label="Sex"
          path={['demographicInformation', 'sex']}
          reducer={toSex}
          fontSize={14}
        />
      </div>
    </div>
  </div>
);

const Page5 = ({ formName, pageNumber, showQRCode, hospitalName, value, defaultValue }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const weightLbs = parseInt(defaultValue?.demographicInformation?.bmi?.weightLb);
  const { age } = getAgeAndBmi(defaultValue?.demographicInformation?.dob, defaultValue?.demographicInformation?.bmi);

  const { questionnaireAnswers } = useValue();
  let cardiacPacemaker = get(
    questionnaireAnswers,
    'sections.Cardiac.schema.cardiac_list.checkboxes["Pacemaker/AICD/Other Heart Monitor"][0]',
    false
  );
  let cardiacStent = get(
    questionnaireAnswers,
    'sections.Cardiac.schema.cardiac_list.checkboxes["Cardiac stents"][0]',
    false
  );

  return (
    <div id="page5" className="page" style={{ fontSize: '0.8em' }}>
      <div style={{ ...styles.twoColumns, paddingTop: '1rem' }}>
        <div>
          <div>{hospitalName || ''}</div>
          <MedicalPassportTextArea name="procedure.name" label="Procedure" fontSize={14} />
          <h3 style={{ margin: '0.5rem 0' }}>Pre-Procedure Nursing Assessment</h3>
        </div>
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '2px' }}>
            {showQRCode && (
              <Barcode
                formName={formName}
                pageNumber={pageNumber}
                providerId={defaultValue?.procedure?.patient?.providerId}
                serviceTime={defaultValue?.procedure?.serviceTime}
              />
            )}
          </div>
          <CommonPartP5P6 age={age} showQRCode={showQRCode} providerId={providerId} />
        </div>
      </div>
      <div style={styles.allergiesSection}>
        <div style={styles.activeTab}>ALLERGIES</div>
        <MedicalPassportTextArea
          name="allergies2"
          path={['allergies']}
          reducer={toHighlightsString}
          warning={true}
          fontSize={14}
        />
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <div style={{ lineHeight: '1.6em' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '3fr 1fr 1fr 1fr 4fr',
              fontWeight: 'bold',
              border: '1px solid black',
              borderTop: 0,
              borderBottom: 0,
              lineHeight: '1.6em',
              padding: '0 0.1rem',
            }}
          >
            <div style={{ borderRight: '1px solid black' }}>&nbsp;</div>
            <div style={{ borderRight: '1px solid black', padding: '0 0.1rem' }}>Yes</div>
            <div style={{ borderRight: '1px solid black', padding: '0 0.1rem' }}>No</div>
            <div style={{ borderRight: '1px solid black', padding: '0 0.1rem' }}>N/A</div>
            <div style={{ padding: '0 0.1rem' }}>Comments:</div>
          </div>
          <MedicalPassportAllergiesRow text="H&P on chart" name="page5.hp" />
          <MedicalPassportAllergiesRow text="Consent signed" name="page5.consentSigned" />
          <MedicalPassportAllergiesRow text="ID on & verified" name="page5.idVerified" />
          <MedicalPassportAllergiesRow text="Allergy band" name="page5.allergyBand" />
          <MedicalPassportAllergiesRow text="Sterilization permit" name="page5.sterilePermit" />
          <div style={{ fontSize: '.75rem' }}>
            <MedicalPassportAllergiesRow text="Dentures/Loose Teeth" name="page5.dentures" />
          </div>
          <MedicalPassportAllergiesRow text="Glasses/Contacts" name="page5.glasses" />
          <MedicalPassportAllergiesRow text="Valuables" name="page5.valuables" />
          <MedicalPassportAllergiesRow text="Labs complete" name="page5.labsComplete" />
          <MedicalPassportAllergiesRow text="EKG / Chest X-Ray" name="page5.ekg" />
          <div style={{ fontSize: '.68rem' }}>
            <MedicalPassportAllergiesRow text="Clearance: Med/Cardiac" name="page5.clearnance" />
          </div>
          <div style={{ borderBottom: '1px solid black' }}>
            <MedicalPassportAllergiesRow text="Pre-op clipping" name="page5.clipping" />
          </div>
        </div>
        <div
          style={{
            lineHeight: '1.8em',
            padding: '0 0.1rem 0 0.2rem',
            borderRight: '1px solid black',
          }}
        >
          <div style={{ display: 'flex', gap: '8px' }}>
            <MedicalPassportField name="page5.npoStatus" label="NPO Status" underline fontSize={14} />
            <MedicalPassportField name="page5.weightDOS" label="Weight DOS" underline fontSize={14} />
          </div>
          <div style={{ display: 'flex', gap: '8px' }}>
            <MedicalPassportField
              name="ht"
              label="Height"
              path={['demographicInformation', 'bmi']}
              reducer={toHeight}
              underline
              fontSize={14}
            />
            <MedicalPassportField
              name="wt"
              label="Weight"
              path={['demographicInformation', 'bmi']}
              reducer={toWeightKgLbs}
              warning={weightLbs > 500}
              underline
              fontSize={14}
            />
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '2fr 2fr 1fr', gap: '1em' }}>
            <MedicalPassportField name="page5.voided" label="Voided" underline fontSize={14} />
            <MedicalPassportField name="page5.hcg" label="HCG" underline fontSize={14} />
            <MedicalPassportCheckbox name="page5.waiver" label="Waiver" />
          </div>
          <div style={{ display: 'flex', gap: '5px' }}>
            <MedicalPassportField
              name="page5.painScale"
              label="Pain"
              path={['Pain', 'pain_list', 'Chronic pain', 'level']}
              underline
              reducer={toUsageString}
              fontSize={14}
            />
            Pain Scale 0-10
          </div>
          <div>
            <MedicalPassportField
              name="page5.painInfo"
              label=""
              paths={[
                ['Pain', 'pain_list', 'Pain comment'],
                ['Pain', 'pain_list', 'Chronic pain', 'pain_location'],
              ]}
              underline
              fontSize={14}
            />
          </div>
          <div style={{ display: 'flex', gap: '5px' }}>
            <div>IV:</div>
            <MedicalPassportCheckbox name="page5.lr" label="LR @ ML/hr" />
            <MedicalPassportCheckbox name="page5.ns" label="NS @ ML/hr" />
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              <MedicalPassportCheckbox name="page5.other3" label="Other: " />
              <div>
                <input type="text" name="handoff" style={{ ...styles.inputField, width: '55px' }} />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '5px' }}>
            <div>Cath:</div>
            <MedicalPassportCheckbox name="page5.22g" label="22G" />
            <MedicalPassportCheckbox name="page5.20g" label="20G" />
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              <MedicalPassportCheckbox name="page5.otherCath" label="Other: " />
              <MedicalPassportField name="page5.otherCathText" underline fontSize={14} />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div>Site:</div>
            <MedicalPassportCheckbox name="page5.right" label="Right" />
            <MedicalPassportCheckbox name="page5.left" label="Left" />
            <MedicalPassportCheckbox name="page5.hand" label="Hand" />
            <MedicalPassportCheckbox name="page5.forearm" label="Forearm" />
            <MedicalPassportCheckbox name="page5.anticubital" label="Anticubital" />
            <MedicalPassportCheckbox name="page5.other4" label="Other" />
          </div>
          <div style={{ display: 'flex', gap: '4px' }}>
            <MedicalPassportField name="page5.insertedBy" label="Inserted By" underline fontSize={14} />
            <MedicalPassportCheckbox name="page5.ivOrdered" label="NO IV ordered" />
          </div>
          <div>
            <MedicalPassportCheckbox name="page5.ivStarted" label="IV started by Anesthesia" />
            <div style={{ display: 'flex', gap: '4px', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}>
                <MedicalPassportCheckbox name="page5.metalOnTheBody" label="Metal/Prothesis in the body" />
                <MedicalPassportField name="page5.locationMetalOnTheBody" label="Location" underline fontSize={14} />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
                fontSize: '.7rem',
              }}
            >
              <div style={{ display: 'grid', gridTemplateColumns: '.1fr 3.3fr 2fr 1.4fr 4fr', gap: '0em' }}>
                <MedicalPassportCheckbox name="page5.glucoseCheck" />
                <MedicalPassportField name="page5.glucoseField" label="Glucose " underline fontSize={14} />

                <u>
                  <span>70-105 mg/dL</span>
                </u>
                <MedicalPassportCheckbox name="page5.nA" label="N/A" style={{ marginRight: '1em' }} />
                <span> &nbsp; Time: ____________</span>
              </div>
            </div>
            <MedicalPassportCheckbox name="page5.ekgStrip" label="EKG Strip" />
          </div>
        </div>
      </div>
      <div
        style={{
          borderRight: '1px solid black',
          borderLeft: '1px solid black',
        }}
      >
        <div
          style={{
            display: 'flex',
            paddingTop: '1rem',
          }}
        >
          <div style={{ display: 'flex', paddingRight: '2em', gap: '4px' }}>
            <div>Interpreter needed?</div>
            <MedicalPassportCheckbox
              name="page5.interpreterNeededYes"
              path={['basicscreening', 'interpreter']}
              label="Yes"
            />
            <MedicalPassportCheckbox name="page5.interpreterNeededNo" label="N/A" />
          </div>
          <div style={{ display: 'flex', gap: '4px' }}>
            BP ____________ , P ____________ , R __________ , 02 Sat _____________ % T _____________
          </div>
        </div>
      </div>

      <div style={styles.border}>
        {checkboxesPage5.map((e, i) => (
          <div style={{ borderBottom: '1px solid black' }} key={'allergies' + i}>
            <div
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                marginRight: '1.5rem',
                marginLeft: '0.2rem',
                fontWeight: 'bold',
              }}
            >
              {e.text}
            </div>
            {e.checkboxes.map(e => (
              <MedicalPassportCheckbox
                name={e.name}
                label={e.label}
                key={e.name}
                style={{ display: 'inline-flex', marginRight: '1.5rem' }}
              />
            ))}
          </div>
        ))}
        <div style={{ borderBottom: '1px solid black' }}>
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              marginRight: '1.5rem',
              marginLeft: '0.2rem',
              fontWeight: 'bold',
            }}
          >
            Respiratory Status
          </div>
          <MedicalPassportCheckbox
            name="page5.clear"
            label="Clear"
            key="page5.clear"
            style={{ display: 'inline-flex', marginRight: '1.5rem' }}
          />
          <MedicalPassportCheckbox
            name="page5.seeNotes3"
            label="See Notes"
            key="page5.seeNotes3"
            style={{ display: 'inline-flex', marginRight: '1.5rem' }}
          />
        </div>
        <div style={{ borderBottom: '1px solid black' }}>
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              marginRight: '1.5rem',
              marginLeft: '0.2rem',
              fontWeight: 'bold',
            }}
          >
            Cardiac
          </div>
          <input
            type="checkbox"
            name="page5.pacemaker"
            label="Pacemaker/AICD"
            key="page5.pacemaker"
            checked={cardiacPacemaker}
          />
          Pacemaker/AICD
          <input type="checkbox" name="page5.stents" label="Stents" key="page5.stents" checked={cardiacStent} />
          Stents
          <MedicalPassportCheckbox
            name="page5.pp"
            label="Pedal Pulses"
            key="page5.pp"
            style={{ display: 'inline-flex', marginRight: '.2rem' }}
          />
          <MedicalPassportCheckbox
            name="page5.L"
            label="L"
            key="page5.L"
            style={{ display: 'inline-flex', marginRight: '.2rem' }}
          />
          <MedicalPassportCheckbox
            name="page5.R"
            label="R"
            key="page5.R"
            style={{ display: 'inline-flex', marginRight: '.2rem' }}
          />
          <MedicalPassportCheckbox
            name="page5.edema"
            label="Edema"
            key="page5.edema"
            style={{ display: 'inline-flex', marginRight: '.2rem' }}
          />
          Y
          <input type="checkbox" name="page5.edemaY" label="Y" key="page5.edemaY" />
          N
          <input type="checkbox" name="page5.edemaN" label="N" key="page5.edemaN" />
        </div>
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            marginRight: '1.5rem',
            marginLeft: '0.2rem',
            fontWeight: 'bold',
          }}
        >
          Skin Condition
        </div>
        <MedicalPassportCheckbox
          name="page5.warmDryIntact"
          label="Warm/dry/intact"
          key="page5.warmDryIntact"
          style={{ display: 'inline-flex', marginRight: '1.5rem' }}
        />
        <MedicalPassportCheckbox
          name="page5.otherSkinCondition"
          label="Other"
          key="page5.otherSkinCondition"
          style={{ display: 'inline-flex', marginRight: '1.5rem' }}
        />
      </div>
      <div style={{ ...styles.sectionHeader }}>ACTIVITY ASSESSMENT</div>
      <div style={styles.border}>
        {activityAssessment.map(e => (
          <MedicalPassportCheckbox
            name={e.name}
            label={e.label}
            key={'assessment' + e.name}
            style={{ display: 'inline-block', marginRight: '0.5rem' }}
          />
        ))}
        {assistiveDevice.map(e => (
          <MedicalPassportCheckbox
            name={e.name}
            label={e.label}
            key={'assessment' + e.name}
            style={{ display: 'inline-block', marginRight: '0.5rem' }}
            path={['basicscreening', 'device', 'yes', 'list']}
            reducer={testReducer(e.name.toLowerCase())}
          />
        ))}
      </div>
      <div style={{ ...styles.sectionHeader }}>PREOPERATIVE MEDICATIONS</div>
      <div style={styles.border}>
        <div style={styles.fiveColumns}>
          <div>Medication</div>
          <div>Dose</div>
          <div>Route</div>
          <div>Time</div>
          <div>Initials</div>
        </div>
        {[...Array(6)].map((e, i) => (
          <div style={styles.fiveColumns} key={'medication' + i}>
            <MedicalPassportField name={`medication${i}`} label={`${i + 1}`} underline fontSize={14} />
            <MedicalPassportField name={`dose${i}`} underline fontSize={14} />
            <MedicalPassportField name={`route${i}`} underline fontSize={14} />
            <MedicalPassportField name={`time${i}`} underline fontSize={14} />
            <MedicalPassportField name={`initial${i}`} underline fontSize={14} />
          </div>
        ))}
      </div>

      <div style={styles.twoNotEqualColumns}>
        <div>
          <CommonPartOne />
          <div
            style={{
              display: 'flex',
              spaceBetween: 'justify-content',
            }}
          >
            <div style={styles.signatureBox1}>
              <div>
                <div style={{ paddingTop: '1.5rem' }}>______________________________</div>
                <div>Pre-Op Nurse Signature</div>
              </div>
              <div>
                <div style={{ marginLeft: '1rem', marginTop: '1.5rem' }}>____________________________________</div>
                <div>PACU Nurse Signature</div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: 'flex' }}>
          <img src={skin} alt="Hospital Logo" style={{ width: '100%' }} />
        </div>
      </div>
    </div>
  );
};

export default Page5;
