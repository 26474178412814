import React from 'react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true, error, info });
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <h1 style={{ fontSize: '1.6rem', marginBottom: '1rem' }}>Something went wrong.</h1>
          {import.meta.env.MODE === 'development' && (
            <>
              <p style={{ fontFamily: 'monospace' }}>{this.state.error.message}</p>
              <pre style={{ fontFamily: 'monospace', marginBottom: '1rem' }}>{this.state.info.componentStack}</pre>
            </>
          )}
        </>
      );
    }
    return this.props.children;
  }
}
