import React from 'react';
import isString from 'lodash/isString';
import Tooltip from '../../Tooltip';
import { Button } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

export const PrintLink = ({ onPrint, title, disabled }: { onPrint: any; title: string; disabled: string | false }) => (
  <Tooltip content={isString(disabled) ? disabled : null} delay={undefined}>
    <Button startIcon={<PictureAsPdfIcon />} onClick={onPrint} disabled={!!disabled}>
      {title}
    </Button>
  </Tooltip>
);
