import React, { FormEvent, MutableRefObject, useCallback, useMemo, useState, VFC } from 'react';
import { useQuery } from '@apollo/client';
import { staffAvailabilitySuggestedTimeRanges } from '../../../graph/staff';
import formatTimeRange from './util/formatTimeRange';
import ActionManipulation from './types/ActionManipulation';
import TimeRangeAutocomplete from '../kiosk/schedule/staff/TimeRangeAutocomplete';

interface TimeRangeFormProps {
  staffId?: number;
  available: boolean;
  onCancel?: () => void;
  onSubmit: (timeRange: { from?: string; to?: string }) => Promise<void>;
  actionManipulation: MutableRefObject<ActionManipulation>;
}

const TimeRangeForm: VFC<TimeRangeFormProps> = ({ staffId, available, onCancel, onSubmit, actionManipulation }) => {
  const [busy, setBusy] = useState(false);

  const [value, setValue] = useState<{
    from: string | null;
    to: string | null;
    isValid: boolean;
    isCompleted: boolean;
  }>({
    from: '',
    to: '',
    isValid: false,
    isCompleted: false,
  });

  const submit = useCallback(
    async (timeRange: { from?: string; to?: string }) => {
      setBusy(true);
      try {
        await onSubmit(timeRange);
      } finally {
        setBusy(false);
      }
    },
    [onSubmit]
  );

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (value.isValid && value.isCompleted && (value.from || value.to)) {
      submit({ from: value.from ? value.from : undefined, to: value.to ? value.to : undefined });
    }
  };

  const { data } = useQuery<
    { staffAvailabilitySuggestedTimeRanges: { from?: string; to?: string }[] },
    { staffId?: number; available: boolean }
  >(staffAvailabilitySuggestedTimeRanges, {
    variables: {
      staffId,
      available,
    },
  });

  const handleInputBlur = () => {
    if (onCancel) {
      actionManipulation.current.fire(onCancel);
    }
  };

  const timeRangeOptions = useMemo(
    () =>
      data?.staffAvailabilitySuggestedTimeRanges
        ?.map(e => ({ ...e, label: formatTimeRange(e.from, e.to) }))
        ?.filter(e => e.from || e.to) || [],
    [data?.staffAvailabilitySuggestedTimeRanges]
  );

  return (
    <form onSubmit={handleSubmit}>
      <TimeRangeAutocomplete
        defaultValue=""
        onInputChange={async (from, to, isValid, isCompleted) => {
          setValue({ from, to, isValid, isCompleted });
        }}
        onSubmit={async (from, to, isValid, isCompleted) => {
          if (isValid && isCompleted && (from || to)) {
            await submit({ from: from ? from : undefined, to: to ? to : undefined });
          }
        }}
        onBlur={handleInputBlur}
        disabled={busy}
        options={timeRangeOptions}
      />
    </form>
  );
};

export default TimeRangeForm;
