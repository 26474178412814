import React from 'react';
import MedicalPassportField from '../../components/MedicalPassportField';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import toAge from '../../../../../../questionnaire/toAge';
import toHeight from '../../../../../../questionnaire/toHeight';
import toWeight from '../../../../../../questionnaire/toWeight';
import toWeightLbs from '../../../../../../questionnaire/toWeightLbs';
import toBMI from '../../../../../../questionnaire/toBMI';
import get from 'lodash/get';
import { useValue } from '../../ValueContext';
import MedicationTextArea from '../../components/MedicationTextArea';
import styles from '../../styles';

const PatientInfo = () => {
  const { questionnaireAnswers } = useValue();
  let preferred = get(questionnaireAnswers, 'sections.demographicInformation.schema.preferred.option', '') + 'Phone';
  return (
    <div>
      <div style={styles.fiveColumns2}>
        <MedicalPassportField name="procedure.patient.name" label="Patient Name" />
        <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} />
        <MedicalPassportField name="age" label="Age" path={['demographicInformation', 'dob']} reducer={toAge} />
        <MedicalPassportField name="ht" label="HT" path={['demographicInformation', 'bmi']} reducer={toHeight} />
        <MedicalPassportField name="wt" label="WT" path={['demographicInformation', 'bmi']} reducer={toWeightLbs} />
      </div>
      <div style={styles.sixColumns2}>
        <MedicalPassportField
          name="primaryPhone"
          label="Primary Phone"
          fontSize={11}
          path={['demographicInformation', preferred]}
        />
        <MedicalPassportField
          name="homePhone"
          label="Home"
          fontSize={11}
          path={['demographicInformation', 'HomePhone']}
        />
        <MedicalPassportField
          name="cellPhone"
          label="Cell"
          fontSize={11}
          path={['demographicInformation', 'CellPhone']}
        />
        <MedicalPassportField
          name="workPhone"
          label="Work"
          fontSize={11}
          path={['demographicInformation', 'WorkPhone']}
        />
        <MedicalPassportField
          name="bmi"
          label="BMI"
          fontSize={10}
          path={['demographicInformation', 'bmi']}
          reducer={toBMI}
        />
        <MedicalPassportField
          name="kg"
          label="KG"
          fontSize={10}
          path={['demographicInformation', 'bmi']}
          reducer={toWeight}
        />
      </div>
      <div style={styles.sixNotEqualColumns4}>
        <MedicalPassportCheckbox name="rideHome" label="Ride Home" path={['demographicInformation', 'ridehomeInfo']} />
        <MedicationTextArea name="rideHomeInfo" label="" path={['demographicInformation', 'ridehomeInfo', 'yes']} />
        Procedure:
        <MedicationTextArea name="procedure.name" />
        <div>DOS:</div>
        <MedicationTextArea name="procedure.serviceTime" />
      </div>
      <div style={styles.twoNotEqualColumns1}>
        <MedicalPassportField name="procedure.physician.name" label="Surgeon" />
      </div>
    </div>
  );
};

export default PatientInfo;
