import React, { useCallback, useMemo, useReducer, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import format from 'date-fns/format';
import get from 'lodash/get';
import flow from 'lodash/fp/flow';
import EventIcon from '@material-ui/icons/Event';
import { useQuery } from '@apollo/client';
import { list } from '../../../graph/surgeon/bookings';
import { workingHours } from './util/dummyData';
import { getScheduleData } from './util/schedule';
import ChooseProcedureModal from './ChooseProcedureModal';
import ProcedureDetailsModal from './ProcedureDetailsModal';
import Timeline from '../../booking/timeline/Timeline';
import TimelineGroup from '../../booking/timeline/TimelineGroup';
import { withRouter } from 'react-router';
import AlertBar from '../../core/AlertBar';
import ProcedureLoader from './ProcedureLoader';
import { endOfMonth, startOfMonth } from 'date-fns';
import { MonthPicker } from '../../pages/blockSchedule/ActionBar';
import EntityEmptyState from '../../../se/components/entity-states/EntityEmptyState';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';
import { timeToHour } from './util/time';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexShrink: 0,

    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
}));

const bookingsToEvents = slots =>
  slots
    .reduce((events, slot) => {
      const startHour = timeToHour(slot.start);
      const endHour = timeToHour(slot.end);
      const procedureEvents = slot.procedures.reduce((procedureEvents, procedure) => {
        const startHour = timeToHour(procedure.serviceTime);
        const endHour = startHour + procedure.duration / 60;
        return [
          ...procedureEvents,
          { type: 'PROCEDURE_START', at: startHour, procedure },
          { type: 'PROCEDURE_END', at: endHour, procedure },
        ];
      }, []);
      return [
        ...events,
        { type: 'SLOT_START', at: startHour, slot },
        { type: 'SLOT_END', at: endHour, slot },
        ...procedureEvents,
      ];
    }, [])
    .sort((l, r) => {
      const result = l.at - r.at;

      if (result === 0) {
        if (l.type.indexOf('END') >= 0) {
          return -1;
        } else if (r.type.indexOf('END') >= 0) {
          return 1;
        }
      }

      return result;
    });

export const eventsToEntries = events =>
  events.reduce(
    ({ entries, stack, lastEventAt }, event) => {
      const nextEntries = [...entries];

      const activeProcedures = stack.filter(item => item.type === 'PROCEDURE');
      const activeSlots = stack.filter(item => item.type === 'SLOT');

      if (stack.length > 0) {
        if (activeProcedures.length > 1) {
          if (event.type === 'PROCEDURE_START' || event.type === 'PROCEDURE_END') {
            if (lastEventAt < event.at) {
              nextEntries.push({
                type: 'PROCEDURES',
                from: lastEventAt,
                to: event.at,
                procedures: activeProcedures.map(item => item.procedure).reverse(),
              });
            }
          }
        } else if (activeProcedures.length === 1) {
          if (event.type === 'PROCEDURE_START' || event.type === 'PROCEDURE_END') {
            if (lastEventAt < event.at) {
              nextEntries.push({
                type: 'PROCEDURE',
                from: lastEventAt,
                to: event.at,
                procedure: activeProcedures[0].procedure,
              });
            }
          }
        } else if (activeSlots.length > 1) {
          if (lastEventAt < event.at) {
            nextEntries.push({
              type: 'SLOTS',
              from: lastEventAt,
              to: event.at,
              slots: activeSlots.map(item => item.slot).reverse(),
            });
          }
        } else {
          // activeSlots === 1
          if (lastEventAt < event.at) {
            nextEntries.push({
              type: 'SLOT',
              from: lastEventAt,
              to: event.at,
              slot: activeSlots[0].slot,
            });
          }
        }
      }

      switch (event.type) {
        case 'SLOT_START': {
          const stackItem = { type: 'SLOT', slot: event.slot, from: event.at };
          const nextLastEventAt = activeProcedures.length > 0 ? lastEventAt : event.at;
          return { entries: nextEntries, stack: [stackItem, ...stack], lastEventAt: nextLastEventAt };
        }
        case 'SLOT_END': {
          const nextLastEventAt = activeProcedures.length > 0 ? lastEventAt : event.at;
          return {
            entries: nextEntries,
            stack: stack.filter(item => item.slot !== event.slot),
            lastEventAt: nextLastEventAt,
          };
        }
        case 'PROCEDURE_START': {
          const stackItem = { type: 'PROCEDURE', procedure: event.procedure, from: event.at };
          return { entries: nextEntries, stack: [stackItem, ...stack], lastEventAt: event.at };
        }
        case 'PROCEDURE_END':
          return {
            entries: nextEntries,
            stack: stack.filter(item => item.procedure !== event.procedure),
            lastEventAt: event.at,
          };
        default:
          return { entries, stack, lastEventAt };
      }
    },
    { entries: [], stack: [], lastEventAt: workingHours.start }
  ).entries;

const bookingsToEntries = flow(bookingsToEvents, eventsToEntries);

const Room = ({ name, bookings, onClick }) => {
  const entries = useMemo(() => bookingsToEntries(bookings), [bookings]);

  return <Timeline title={name} entries={entries} onClick={onClick} />;
};

function formState(state, action) {
  switch (action.type) {
    case 'SLOT':
      return {
        from: action.from,
        to: action.to,
        slot: action.slot,
        procedure: null,
        chooseProcedureDialogOpen: true,
        procedureDetailsDialogOpen: false,
      };
    case 'SLOTS':
      return {
        from: action.from,
        to: action.to,
        slot: action.slots[0],
        procedure: null,
        chooseProcedureDialogOpen: true,
        procedureDetailsDialogOpen: false,
      };
    case 'PROCEDURE':
      return state;
    // return {
    //   from: null,
    //   to: null,
    //   slot: null,
    //   procedure: action.procedure,
    //   chooseProcedureDialogOpen: false,
    //   procedureDetailsDialogOpen: true,
    // };
    case 'PROCEDURES':
      return state;
    // return {
    //   from: null,
    //   to: null,
    //   slot: null,
    //   procedure: action.procedures[0],
    //   chooseProcedureDialogOpen: false,
    //   procedureDetailsDialogOpen: true,
    // };
    case 'choose-procedure':
      return {
        ...state,
        procedure: action.procedure,
        chooseProcedureDialogOpen: false,
        procedureDetailsDialogOpen: true,
      };
    case 'go-back':
      return state.slot
        ? {
            ...state,
            procedure: null,
            chooseProcedureDialogOpen: true,
            procedureDetailsDialogOpen: false,
          }
        : {
            from: null,
            to: null,
            slot: null,
            procedure: null,
            chooseProcedureDialogOpen: false,
            procedureDetailsDialogOpen: false,
          };
    case 'cancel':
      return {
        from: null,
        to: null,
        slot: null,
        procedure: null,
        chooseProcedureDialogOpen: false,
        procedureDetailsDialogOpen: false,
      };
    default:
      return state;
  }
}

formState.defaultState = {
  from: null,
  to: null,
  slot: null,
  procedure: null,
  chooseProcedureDialogOpen: false,
  procedureDetailsDialogOpen: false,
};

const Schedule = ({ history }) => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(formState, formState.defaultState);
  const [procedure, setProcedure] = useState(null);

  const [date, setDate] = useState(Date.now());

  const { data, loading } = useQuery(list, {
    variables: {
      from: format(startOfMonth(date), 'YYYY-MM-DD'),
      to: format(endOfMonth(date), 'YYYY-MM-DD'),
    },
  });

  const dates = useMemo(() => !loading && getScheduleData(get(data, 'bookingsSO')), [loading, data]);

  const handleClick = useCallback(
    entry => {
      if (entry.type === 'SLOT' || entry.type === 'SLOTS') {
        dispatch(entry);

        if (procedure) {
          dispatch({ type: 'choose-procedure', procedure });
          setProcedure(null);
        }
      }
    },
    [dispatch, procedure]
  );

  const handleChoosePatient = useCallback(procedure => dispatch({ type: 'choose-procedure', procedure }), [dispatch]);
  const handleClosePatient = useCallback(() => dispatch({ type: 'cancel' }), [dispatch]);

  return loading ? (
    <Box display="flex" justifyContent="center" alignItems="center">
      <CircularProgress size="3rem" aria-label="Loading" />
    </Box>
  ) : (
    <Box display="flex" flexDirection="column" flex={1}>
      <ProcedureLoader onLoad={setProcedure} />
      {get(procedure, 'patient.name', null) && <AlertBar>Please select slot for {procedure.patient.name}</AlertBar>}
      <Box className={classes.header}>
        <Button onClick={() => history.goBack()}>Back</Button>
        <Typography variant="h2" gutterBottom>
          Schedule
        </Typography>
        <MonthPicker date={date} setDate={setDate} />
      </Box>
      {dates && dates.length !== 0 ? (
        dates.map(({ date, hospitals }) => (
          <Box key={date} mt={3}>
            <Box mt={2} display="flex" alignItems="center">
              <Box mr={2} borderTop={1} width={1} style={{ opacity: 0.15 }} />
              <Box mr={1}>
                <EventIcon style={{ opacity: 0.25 }} />
              </Box>
              <Box>
                <Typography noWrap display="block">
                  {format(date, 'D MMMM')}
                </Typography>
              </Box>
              <Box ml={2} borderTop={1} width={1} style={{ opacity: 0.15 }} />
            </Box>

            {hospitals.map(({ id, name, rooms }) => (
              <Box key={id} py={1}>
                <Typography gutterBottom>{name}</Typography>
                <TimelineGroup>
                  {rooms.map(({ id, name, bookings }) => (
                    <Room key={id} name={name} bookings={bookings} onClick={handleClick} />
                  ))}
                </TimelineGroup>
              </Box>
            ))}
          </Box>
        ))
      ) : (
        <Box my="auto">
          <EntityEmptyState title="No available schedule for this date" hint="" icon="event_busy" />
        </Box>
      )}

      <ChooseProcedureModal
        open={state.chooseProcedureDialogOpen}
        onChoose={handleChoosePatient}
        onClose={handleClosePatient}
      />

      <ProcedureDetailsModal
        date={date}
        open={state.procedureDetailsDialogOpen}
        from={state.from}
        to={state.to}
        slot={state.slot}
        procedure={state.procedure}
        onClose={() => dispatch({ type: 'cancel' })}
        onGoBack={() => dispatch({ type: 'go-back' })}
      />
    </Box>
  );
};

export default withRouter(Schedule);
