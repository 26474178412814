import { gql, useQuery } from '@apollo/client';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Mutation } from '@apollo/client/react/components';

import NotificationTemplates from '../entities/notificationTemplate/NotificationTemplates';
import CustomMessageTemplate from '../entities/notificationTemplate/CustomMessageTemplate';
import PatientNameFormatInput from '../inputs/PatientNameFormatInput';
import Button from '../../se/components/Button';
import ErrorFactory from '../../se/components/errors/Error';
import { withLabel } from '../../se/components/Label';
import InstructionsEditor from '../entities/InstructionsEditor/InstructionsEditor';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { OrganizationType } from '../entities/hospitals/enums';
import get from 'lodash/get';
import { a11yProps, TabPanel } from '../core/TabPanel';
import { withScope } from '../../contexts/ScopeContext';

const PatientNameFormatInputWithLabel = withLabel(
  'Patient Name Format',
  {},
  'This is how the patient name is displayed on the mobile patient journey page sent to the caretaker.'
)(PatientNameFormatInput);

const Error = ErrorFactory();

const Page = styled.div``;

export const SectionTitle = styled.h1`
  font-size: 2rem;
  margin-top: 1em;
  margin-bottom: 0em;
`;

export const SectionTitle2 = styled.h1`
  font-size: 2rem;
  margin-top: 1em;
  margin-bottom: 0em;
  margin-left: 2rem;
`;

const PatientNameWrapper = styled.div`
  form {
    margin-top: 2.5rem;
  }

  label {
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
`;

const familyMemberPagePatientNameFormat = gql`
  query familyMemberPagePatientNameFormat {
    familyMemberPagePatientNameFormat
  }
`;

const setFamilyMemberPagePatientNameFormat = gql`
  mutation setFamilyMemberPagePatientNameFormat($patientNameFormat: PatientNameFormat!) {
    setFamilyMemberPagePatientNameFormat(patientNameFormat: $patientNameFormat)
  }
`;

const FamilyMemberPage = () => {
  const [state, setState] = useState();
  const { data } = useQuery(familyMemberPagePatientNameFormat);
  const patientNameFormat = state || get(data, 'familyMemberPagePatientNameFormat') || 'InitialsOnly';
  const isChanged = patientNameFormat !== get(data, 'familyMemberPagePatientNameFormat');

  const onComplete = () => console.log('completed');

  const handleSubmit = mutation => e => {
    e.preventDefault();

    mutation({
      variables: {
        patientNameFormat,
      },
      refetchQueries: [{ query: familyMemberPagePatientNameFormat }],
    });
  };

  return (
    <Fragment>
      <SectionTitle>Patient Status Page</SectionTitle>
      <Mutation mutation={setFamilyMemberPagePatientNameFormat} onComplete={onComplete}>
        {(mutation, { loading, error }) => (
          <PatientNameWrapper>
            <form onSubmit={handleSubmit(mutation)}>
              <PatientNameFormatInputWithLabel
                value={patientNameFormat}
                onChange={setState}
                isClearable={false}
                isSearchable={false}
              />
              {isChanged && <Button disabled={loading}>Save</Button>}
              <Error isVisible={error !== undefined} id={`FamilyMemberPage-PatientNameFormat`}>
                <p>An unexpected error has occurred. Please, try again or contact our support.</p>
              </Error>
            </form>
          </PatientNameWrapper>
        )}
      </Mutation>
    </Fragment>
  );
};

export default withScope(({ scope }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isExchangeOnly = get(scope, 'hospital.exchangeOnly', false);
  const isSC = get(scope, 'hospital.type') === OrganizationType.SurgeryCenter;

  return (
    <Page>
      {isSC ? (
        <Fragment>
          <Tabs indicatorColor={'primary'} value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Patient Messaging" {...a11yProps(0)} />
            <Tab label="Caretaker Messaging" {...a11yProps(1)} />
            <Tab label="Staff Messaging" {...a11yProps(2)} />
            <Tab label="Surgical Instructions" {...a11yProps(3)} />
            <Tab label="Custom Messaging" {...a11yProps(4)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <NotificationTemplates destinations={[{ title: 'Patient Messaging', keys: ['Patient', 'Procedure'] }]} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FamilyMemberPage />
            <NotificationTemplates destinations={[{ title: 'Caretaker Messaging', keys: ['Caretaker'] }]} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <NotificationTemplates
              destinations={[
                {
                  title: 'Staff Messaging',
                  keys: ['Physician', 'StaffMember', 'Anesthesiologist', 'HiringManager', 'Vendor'],
                },
              ]}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <InstructionsEditor />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <CustomMessageTemplate destinations={[{ title: 'Custom Messaging', keys: ['Caretaker', 'Patient'] }]} />
          </TabPanel>
        </Fragment>
      ) : isExchangeOnly ? (
        <Fragment>
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" indicatorColor={'primary'}>
            <Tab label="Physicians Messaging" {...a11yProps(0)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <NotificationTemplates destinations={[{ title: 'Physician Messaging', keys: ['Physician'] }]} />
          </TabPanel>
        </Fragment>
      ) : (
        <Fragment>
          <Tabs indicatorColor={'primary'} value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Patient Messaging" {...a11yProps(0)} />
            <Tab label="Physicians Messaging" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <NotificationTemplates destinations={[{ title: 'Patient Messaging', keys: ['Patient', 'Procedure'] }]} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <NotificationTemplates destinations={[{ title: 'Physician Messaging', keys: ['Physician'] }]} />
          </TabPanel>
        </Fragment>
      )}
    </Page>
  );
});
