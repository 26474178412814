import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, TextField } from '@material-ui/core';
import { useStyles } from '../ScheduleProcedureForm';
import { FormParams, InsuranceDetails } from '../types/types';
import set from 'lodash/fp/set';

const InsuranceForm: FC<FormParams<InsuranceDetails>> = ({ value, setValue }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h3" gutterBottom>
        Insurance
      </Typography>

      <form className={classes.form} noValidate autoComplete="off">
        <Grid container spacing={4}>
          <Grid item md={6}>
            <TextField
              id="filled-required"
              label="Insurance Name"
              variant="filled"
              margin="dense"
              fullWidth
              value={value?.insuranceName?.value || ''}
              onChange={event => setValue(set('insuranceName.value', event.target.value)(value))}
            />
            <TextField
              id="filled-required"
              label="Policy Name"
              variant="filled"
              margin="dense"
              fullWidth
              value={value?.policyName?.value || ''}
              onChange={event => setValue(set('policyName.value', event.target.value)(value))}
            />

            {/*@ts-ignore*/}
            {/*<InsuranceCardUploadButton history={history} location={location} />*/}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default InsuranceForm;
