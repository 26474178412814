import { QuestionnaireAndAnswers } from '../../../../../types/Questionnaire';
import React from 'react';
import { useTheme } from '@material-ui/core';
import SectionButton from './SectionButton';
import illustrations from '../../../../illustrations';
import IllustrationProps from '../../../../illustrations/IllustrationProps';

interface InlineIconProps {
  children?: string;
  variant?: IllustrationProps['variant'];
}

const InlineIcon = ({ children, variant }: InlineIconProps) => {
  const theme: any = useTheme();

  if (!children) {
    return null;
  }

  const Illustration = illustrations[children];

  if (Illustration) {
    return <Illustration width={56} height={56} variant={variant} />;
  }

  return (
    <img alt="icon" width={56} height={56} src={theme?.illustrations?.[children]} style={{ pointerEvents: 'none' }} />
  );
};

const FormButtonDialog = ({
  form,
  handleClickOpen,
}: {
  form: QuestionnaireAndAnswers;
  handleClickOpen: (form: QuestionnaireAndAnswers) => void;
}) => {
  return (
    <SectionButton
      onClick={() => handleClickOpen(form)}
      Icon={<InlineIcon variant={form?.completed ? 'light' : 'dark'}>{form?.questionnaire?.icon}</InlineIcon>}
      text={form?.questionnaire?.name || 'Patient Form'}
      status={form?.completed}
    />
  );
};

export default FormButtonDialog;
