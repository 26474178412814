import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment ProcedureStepData on ProcedureStep {
      id
      name
      type
      order
      timestamp(patientId: $patientId)
    }
  `,
};

export const orTabletProcedureSteps = gql`
  query ($patientId: Long) {
    orTabletProcedureSteps(patientId: $patientId) {
      ...ProcedureStepData
    }
  }
  ${fragments.all}
`;

export const procedureSteps = gql`
  query ($patientId: Long) {
    procedureSteps(patientId: $patientId) {
      ...ProcedureStepData
    }
  }
  ${fragments.all}
`;

export const procedureStep = gql`
  query ($patientId: Long!, $procedureStepType: ProcedureStepType!) {
    procedureStep(patientId: $patientId, procedureStepType: $procedureStepType) {
      ...ProcedureStepData
    }
  }
  ${fragments.all}
`;

export const setProcedureStepTimestamp = gql`
  mutation setProcedureStepTimestamp(
    $procedureStepId: Long!
    $procedureStepType: ProcedureStepType!
    $patientId: Long!
    $timestamp: DateTime!
  ) {
    setProcedureStepTimestamp(
      procedureStepId: $procedureStepId
      procedureStepType: $procedureStepType
      patientId: $patientId
      timestamp: $timestamp
    )
  }
`;

export const setAnesthesiaEnd = gql`
  mutation setAnesthesiaEnd($patientId: Long!, $timestamp: DateTime!) {
    setAnesthesiaEnd(patientId: $patientId, timestamp: $timestamp)
  }
`;

export const setPatientEventTimestamp = gql`
  mutation setPatientEventTimestamp($patientId: Long!, $eventType: Json!, $timestamp: DateTime!) {
    setPatientEventTimestamp(patientId: $patientId, eventType: $eventType, timestamp: $timestamp)
  }
`;
