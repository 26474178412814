import { compose, withProps } from 'recompose';
import ObjectInput from '../../../se/components/inputs/ObjectInput';

import DailyHoursCapacity from '../DailyHoursCapacity';
import { withLabel } from '../../../se/components/Label';
import NumberInput from '../../../se/components/inputs/NumberInput';
import ToggleInput from '../../../se/components/inputs/ToogleInput';

export default withProps({
  schema: {
    availability: DailyHoursCapacity,
    airFiltrationDurationMin: compose(
      withLabel('Air filtration turnaround time in minutes'),
      withProps({ placeholder: 'e.g., 45', required: true })
    )(NumberInput),
    isHideInternalScreen: withProps({ label: 'Hide room on Internal screen' })(ToggleInput),
    isHideScheduleScreen: withProps({ label: 'Hide room on Schedule screen' })(ToggleInput),
    order: compose(withLabel('Order'))(NumberInput),
  },
})(ObjectInput);
