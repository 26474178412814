import { ProcedureEvent, ProcedureStatus } from '../types/ProcedureEvent';
import findLast from 'lodash/findLast';
import sortBy from 'lodash/sortBy';
import { Procedure } from '../types/Procedure';
import { RoomType } from '../types/Room';

export const getLastProcedureEvent = (
  events: Array<ProcedureEvent>,
  procedureStatus: ProcedureStatus
): ProcedureEvent | undefined => {
  const sorted = sortBy(events, 'timestamp');
  return findLast(sorted, ({ status }: ProcedureEvent) => status === procedureStatus);
};

export const getRoomTime = (procedure: Procedure) => {
  if (procedure?.patient?.status === 'Discharged') return null;

  const roomType = procedure?.patient?.room?.type;
  const events = procedure?.patient?.events;
  switch (roomType) {
    case RoomType.WaitingRoom:
      return events?.admittedAt;
    case RoomType.PreOp:
      return events?.preOpAt;
    case RoomType.OR:
      return events?.orAt;
    case RoomType.PACU:
      return events?.recoveryAt;
    case RoomType.POST_OP:
      return events?.postOpAt;
    default:
      return null;
  }
};

export default {
  getLastProcedureEvent,
};
