import ImmutableSet from './ImmutableSet';
import { DateString } from '../util/groupStaffAvailability';

export default class Dates {
  static empty: Dates = new Dates();
  static of(...dates: DateString[]): Dates {
    return Dates.from(dates);
  }
  static from(dates: DateString[]): Dates {
    return new Dates(ImmutableSet.from(dates));
  }

  private _set = ImmutableSet.empty<string>();

  private constructor(set: ImmutableSet<string> = ImmutableSet.empty<string>()) {
    this._set = set;
  }

  add(...dates: DateString[]): Dates {
    return new Dates(this._set.add(...dates));
  }

  delete(...dates: DateString[]): Dates {
    return new Dates(this._set.delete(...dates));
  }

  has(date: DateString): boolean {
    return this._set.has(date);
  }

  size(): number {
    return this._set.size();
  }

  isEmpty(): boolean {
    return this.size() === 0;
  }

  values(): DateString[] {
    return this._set.values();
  }
}
