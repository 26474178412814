import { QuestionnaireType } from './Questionnaire';

import { Role } from '../components/pages/kiosk/charting/modules/chartingStore';

export enum ConfigType {
  PatientChartField = 'PATIENT_CHART_FIELD',
  PatientChartButtons = 'PATIENT_CHART_BUTTONS',
  StaffTitle = 'STAFF_TITLE',
  ProcedureCancellationReason = 'PROCEDURE_CANCELLATION_REASON',
  ProcedureDelayReason = 'PROCEDURE_DELAY_REASON',
}

export type PatientChartButtonConfig = {
  chartType?: QuestionnaireType;
  groupOrder?: number;
  groupLabel?: string;
  buttonOrder?: number;
  buttonLabel?: string;
};

export type PatientChartRoleButtonConfig = {
  role?: Role;
  buttons?: PatientChartButtonConfig[];
};

export type PatientChartButtonsConfig = {
  buttons?: PatientChartRoleButtonConfig[];
};
