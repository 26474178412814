import React, { Dispatch, FC, SetStateAction } from 'react';
import { getDisposition } from '../timeline/Procedure';
import { Procedure as ProcedureT, Procedure } from '../../../../../types/Procedure';
import { differenceInHours, getHours, setHours, setMinutes, setSeconds } from 'date-fns';
import { currentDateInZone } from '../../../../../util/dateTime';
import { useScope } from '../../../../../hooks/useScope';
import { Box } from '@material-ui/core';
import { Room } from '../../../../../types/Room';
import OperatingRoomProcedures from '../timeline/OperatingRoomProcedures';
import { Time } from '../../../../entities/schedule/util/time';
import { Anesthesiologist } from '../anesthesiologist/PersonnelRoom';
import { fullNameToLastNameRest } from '../shared/procedureUtils';
import isEmpty from 'lodash/fp/isEmpty';
import clsx from 'clsx';
import { useStylesOverview } from '../overview/Overview';
import { Cursor, Dot, Grid, Rooms, Root, timelineUseStyles, XAxis, YAxis } from '../timeline/Timeline';

const AnesthesiologistView: FC<{
  currentOR?: string;
  isKiosk?: boolean;
  isFrontDesk?: boolean;
  editableStaff?: boolean;
  operationRooms: Room[];
  canUseScheduleViewProcedure?: boolean;
  isPowerUser?: boolean;
  date: Date;
  setEditProcedure?: Dispatch<SetStateAction<ProcedureT | null>>;
  showBedNumber?: boolean;
  scheduleStaffList?: any;
  showOverlay: boolean;
  editMode: boolean;
  openProcedureForm?: () => void;
  startTime: Time;
  duration: number | null | undefined;
  setStartTime?: (val: Time) => void;
  setDuration?: (val: number | null | undefined) => void;
  condensView?: boolean;
  unassignedProcedures: Procedure[];
  hasMoreThan6ORsView: boolean;
}> = ({
  currentOR,
  isFrontDesk,
  isKiosk,
  editableStaff,
  operationRooms,
  canUseScheduleViewProcedure,
  isPowerUser,
  date,
  setEditProcedure,
  showBedNumber,
  scheduleStaffList,
  showOverlay = false,
  editMode,
  openProcedureForm,
  startTime,
  duration,
  setStartTime,
  setDuration,
  condensView,
  unassignedProcedures,
  hasMoreThan6ORsView,
}) => {
  const filteredOperationRooms = operationRooms.filter(room =>
    room.name.toLowerCase().startsWith('or')
  );
  const canAddStaff = !isKiosk && editableStaff;
  const dayStart = setSeconds(setMinutes(setHours(date, 6), 0), 0);
  const dayEnd = setSeconds(setMinutes(setHours(date, 19), 0), 0);
  const HOUR_COUNT = differenceInHours(dayEnd, dayStart);
  const START_HOUR = getHours(dayStart);
  const hours = [...new Array(HOUR_COUNT + 1)].map((_, i) => `${i + START_HOUR}:00`);

  const scope = useScope();
  const tz = scope?.hospital.timezone.id;
  const hospitalId = scope?.hospital.id;
  const editableAnesthesiologist = !isKiosk && !isFrontDesk && editableStaff;

  const muiClasses = timelineUseStyles();

  const hasMoreThan6ORs = isKiosk ? false : filteredOperationRooms.length + (!isEmpty(unassignedProcedures) ? 1 : 0) > 6;

  const classes = useStylesOverview();

  const timelineContent = (
    <Root isKiosk={isKiosk} hasMoreThan6ORs={hasMoreThan6ORs} hasMoreThan6ORsView={hasMoreThan6ORsView}>
      <Rooms
        columns={filteredOperationRooms.length}
        hasMoreThan6ORs={hasMoreThan6ORs}
        hasMoreThan6ORsView={hasMoreThan6ORsView}
      >
        {filteredOperationRooms.map((room, i) => {
          const { id, name, procedures } = room;
          const anesthesiologistName = fullNameToLastNameRest(
            room?.anesthesiologistShifts?.[0]?.anesthesiologist?.name
          );
          const anesthesiologistShiftRoomId = room?.anesthesiologistShifts?.[0]?.id;

          const proceduresInRoom = procedures?.filter(procedure => procedure?.orId === room?.id);

          return (
            <Box className={classes.staffWrapper}>
              {/* @ts-ignore */}
              <Box className={clsx(classes.roomHeader)} onlyRoomName={!(canAddStaff && id !== undefined)}>
                <span style={{ flex: 1, textAlign: 'center' }}>{name}</span>
                <Box mr={1}>
                  {!!id && (
                    <Anesthesiologist
                      date={date}
                      roomId={id}
                      condensView={condensView}
                      editableAnesthesiologist={editableAnesthesiologist}
                      anesthesiologistName={anesthesiologistName}
                      anesthesiologistShiftRoomId={anesthesiologistShiftRoomId}
                      procedureQuery={proceduresInRoom}
                      roomType={room?.type}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Rooms>
      <Grid isKiosk={isKiosk} hasMoreThan6ORs={hasMoreThan6ORs} hasMoreThan6ORsView={hasMoreThan6ORsView}>
        <YAxis>
          {hours.map(tick => (
            <Box className={muiClasses.tick} key={tick}>
              {tick}
            </Box>
          ))}
        </YAxis>
        <Cursor y={getDisposition(dayStart, currentDateInZone(tz), dayEnd)}>
          <Dot />
        </Cursor>
        <XAxis>
          {filteredOperationRooms.map((room, i) => (
            <OperatingRoomProcedures
              operationRooms={filteredOperationRooms}
              key={room?.id || room?.name}
              index={i}
              date={date}
              room={room}
              currentOR={currentOR}
              isKiosk={isKiosk}
              canUseScheduleViewProcedure={canUseScheduleViewProcedure}
              isFrontDesk={isFrontDesk}
              isPowerUser={isPowerUser}
              showOverlay={showOverlay}
              editMode={editMode}
              showBedNumber={showBedNumber}
              setEditProcedure={setEditProcedure}
              dayStart={dayStart}
              dayEnd={dayEnd}
              openProcedureForm={openProcedureForm}
              startTime={startTime}
              duration={duration}
              setStartTime={setStartTime}
              setDuration={setDuration}
              anesthesiologistSchedule={true}
            />
          ))}
        </XAxis>
      </Grid>
    </Root>
  );

  if (isKiosk && scheduleStaffList) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: !!scheduleStaffList ? 'row' : 'column',
          width: '100%',
          height: '100%',
        }}
      >
        {timelineContent}
        {scheduleStaffList}
      </div>
    );
  }
  return timelineContent;
};

export default AnesthesiologistView;
