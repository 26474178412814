import React, { FC, Ref, useEffect, useRef, useState } from 'react';
import { createStyles, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import useClickAway from '../../../../../hooks/useClickAway';
import { useStaffSlotContext } from './StaffSlotContext';
import TimeRangeInput, { verifyTimeRange } from './TimeRangeInput';
import formatTimeRange from '../../../calendar/util/formatTimeRange';
import { useStaffShiftStore } from './StaffShift';
import { useStore } from 'zustand';

interface StaffMemberProps {
  assignedShiftId?: number;
  from?: string;
  to?: string;
  hideClock?: boolean;
  editableStaff?: boolean;
}

const StaffShiftTime: FC<StaffMemberProps> = ({ hideClock, assignedShiftId, from, to, editableStaff }) => {
  const classes = useStyles();
  const defaultValue = from && to ? `${from}-${to}` : from ? `${from}-` : '';
  const [localFrom, setLocalFrom] = useState<string | undefined>();

  useEffect(() => {
    if (localFrom) {
      const timeout = setTimeout(() => {
        setLocalFrom(undefined);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [localFrom]);

  useEffect(() => {
    if (from === localFrom) {
      setLocalFrom(undefined);
    }
  }, [from, localFrom]);

  const [localTo, setLocalTo] = useState<string | undefined>();

  useEffect(() => {
    if (localTo) {
      const timeout = setTimeout(() => {
        setLocalTo(undefined);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [localTo]);

  useEffect(() => {
    if (to === localTo) {
      setLocalTo(undefined);
    }
  }, [to, localTo]);

  const inputRef = useRef<HTMLInputElement>();

  const staffShiftStore = useStaffShiftStore();

  const mouseDownRef = useRef(false);

  const onClockClick = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    mouseDownRef.current = true;
    document.addEventListener(
      'mouseup',
      () => {
        mouseDownRef.current = false;

        const wrapper = wrapperRef.current;

        if (!wrapper) {
          return;
        }

        const input = wrapper.querySelector('input');

        if (!input) {
          return;
        }

        input.focus();
      },
      {
        once: true,
      }
    );
    editableStaff && staffShiftStore?.setState({ editTime: true, editText: false });
  };

  const focusInput = () => {
    const wrapper = wrapperRef.current;

    if (!wrapper) {
      return;
    }

    const input = wrapper.querySelector('input');

    if (!input) {
      return;
    }

    if (document.activeElement === input) {
      return;
    }

    input.focus();
  };

  const onTimeTextClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (editableStaff) {
      e.stopPropagation();
      mouseDownRef.current = true;
      document.addEventListener(
        'mouseup',
        () => {
          mouseDownRef.current = false;

          focusInput();
        },
        {
          once: true,
        }
      );
      staffShiftStore?.setState({ editTime: true, editText: false });
    }
  };

  const onClickAway = async () => {
    let value: string | undefined;

    const input = inputRef?.current;

    if (input) {
      value = input.value;
    } else {
      const wrapper = wrapperRef.current;

      if (wrapper) {
        const input = wrapper.querySelector('input');

        if (input) {
          value = input.value;
        }
      }
    }

    if (!value) {
      if (!mouseDownRef.current) {
        staffShiftStore?.setState({ editTime: false });
      }

      await saveWorkingTime(null, null);
      return;
    }

    const { isValid, isCompleted } = verifyTimeRange(value);
    const [from, to] = isValid ? value.split('-') : [null, null];

    if (isCompleted || isValid) {
      if (!mouseDownRef.current) {
        staffShiftStore?.setState({ editTime: false });
      }
      setLocalFrom(from || undefined);
      setLocalTo(to || undefined);
      await saveWorkingTime(from, to);
    }
  };

  const wrapperRef = useRef<HTMLElement>();
  useClickAway(wrapperRef, onClickAway);

  const editTime = useStore(staffShiftStore, state => state.editTime);

  useEffect(() => {
    if (editTime) {
      requestAnimationFrame(() => {
        focusInput();
      });
    }
  }, [editTime]);

  const showClock = !hideClock && !editTime && assignedShiftId && !to && !from;
  const showTime = !editTime && (localTo || to || localFrom || from);

  useEffect(() => {
    if (editTime) {
      inputRef.current?.focus();
    }
  }, [editTime]);

  const staffSlotContext = useStaffSlotContext();

  const saveWorkingTime = async (from: string | null, to: string | null) => {
    if (assignedShiftId) {
      setLocalFrom(from || undefined);
      setLocalTo(to || undefined);
      await staffSlotContext.setWorkingTime(assignedShiftId, from || undefined, to || undefined);
    }
  };

  const onInputChange = async (
    from: string | null,
    to: string | null,
    isValid: boolean,
    isCompleted: boolean,
    enter: boolean
  ) => {
    if (isCompleted || isValid) {
      setLocalFrom(from || undefined);
      setLocalTo(to || undefined);
      await saveWorkingTime(from, to);
      if (isCompleted || (enter && isValid)) {
        staffShiftStore?.setState({ editTime: false });
      }
    }
  };

  return (
    <>
      {showClock && (
        <IconButton size="small" className={classes.noteIcon} onMouseDown={onClockClick}>
          <AccessTimeIcon fontSize="small" />
        </IconButton>
      )}

      {editTime && assignedShiftId && (
        <div ref={wrapperRef as Ref<HTMLDivElement>}>
          <TimeRangeInput
            defaultValue={defaultValue}
            inputRef={inputRef as Ref<HTMLInputElement>}
            onInputChange={onInputChange}
            className={classes.noteInput}
          />
        </div>
      )}

      {showTime && (
        <Typography className={classes.noteText} variant="caption" onClick={onTimeTextClick}>
          {formatTimeRange(localFrom ?? from, localTo ?? to)}
        </Typography>
      )}
    </>
  );
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noteIcon: {
      marginLeft: '0.2rem',
      color: theme.palette.primary.light,
    },
    noteText: {
      lineHeight: 1,
      marginLeft: theme.spacing(1),
      color: theme.palette.text.primary,
    },
    noteInput: {
      marginLeft: theme.spacing(1),
      marginTop: -4,
      marginBottom: -4,
      paddingTop: 4,
      paddingBottom: 4,
      backgroundColor: 'rgb(0, 12, 63)',
      color: theme.palette.text.primary,
      width: '5rem',
      fontSize: '0.75rem',
      fontFamily:
        'Rubik,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 400,
      border: 'none',
      '&:focus': {
        outline: 'none',
      },
    },
  })
);

export default StaffShiftTime;
