import { Subscription } from '@apollo/client/react/components';
import React from 'react';
import { mapFilter } from '../../../se/components/GraphQLCRUD';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const Item = ({ title, value }) => (
  <Paper variant="outlined">
    <Box py={1.5} px={2}>
      <Typography variant="body1" color="textSecondary">
        {title}
      </Typography>
      <Typography variant="h6">{value}</Typography>
    </Box>
  </Paper>
);

export const DashboardFilter = ({ CustomFilter, subscription, items, ...props }) => (
  <div>
    <CustomFilter {...props} />
    <Subscription subscription={subscription} variables={{ filter: mapFilter(props.value) }}>
      {({ loading, error, data }) => (
        <Box mt={4}>
          <Grid container spacing={2}>
            {items.map(({ title, getter }, i) => (
              <Grid item xs={12} md={12 / items.length} key={i}>
                <Item title={title} value={getter(data)} key={title} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Subscription>
  </div>
);
