import React, { useState } from 'react';
import ProcedureEventLog from './ProcedureEventLog';
import { CircularProgress, Collapse, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { ProcedureEvent } from '../../../types/ProcedureEvent';

const useStyles = makeStyles(theme => ({
  button: {
    alignSelf: 'flex-start',
    marginTop: theme.spacing(2),
  },
}));

interface NotesProps {
  notes: Array<ProcedureEvent>;
  createNote: (note: string) => Promise<void>;
  deleteNote: (noteId: number) => Promise<void>;
}

const Notes = ({ notes, createNote, deleteNote }: NotesProps) => {
  const classes = useStyles();

  // @ts-ignore
  const [value, setValue] = useState(localStorage.getItem(window.location) || '');
  const [showButton, setShowButton] = useState(false);
  const [busy, setBusy] = useState(false);

  const createNewNote = async () => {
    setBusy(true);
    try {
      await createNote(value);
    } finally {
      setBusy(false);
      // @ts-ignore
      localStorage.setItem(window.location, '');
      setValue('');
    }
  };

  const writeNote = (e: any) => {
    const newValue = e.target.value;

    setValue(newValue);
    // @ts-ignore
    localStorage.setItem(window.location, newValue);
  };

  const handleShowButton = () => {
    setShowButton(true);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Notes
      </Typography>
      <Box display="flex" flexDirection="column">
        <TextField
          onFocus={handleShowButton}
          multiline
          rows={2}
          variant="outlined"
          label="Leave a note"
          value={value}
          onChange={e => writeNote(e)}
        />
        <Collapse in={showButton}>
          <Button
            variant="contained"
            color="primary"
            disabled={(value || '').length < 1}
            onClick={createNewNote}
            className={classes.button}
          >
            {busy ? <CircularProgress color="inherit" size={20} /> : 'Post note'}
          </Button>
        </Collapse>
      </Box>
      <Box mt={3}>
        <ProcedureEventLog events={notes} deleteNote={deleteNote} />
      </Box>
    </Box>
  );
};

export default Notes;
