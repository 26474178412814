import { gql } from '@apollo/client';
import { fragments as dashboardFragments } from './dashboard';

const sensorValueFragments = {
  all: gql`
    fragment SensorMeasurementData on SensorMeasurement {
      temperature {
        value
        unit
      }
      humidity
      battery
    }
  `,
};

export const fragments = {
  all: gql`
    fragment SensorData on Sensor {
      id
      location
      temperatureMin {
        value
        unit
      }
      temperatureMax {
        value
        unit
      }
      humidityMin
      humidityMax
      lastSeenAt
      lastSeen
      enabled
      measurement {
        ...SensorMeasurementData
      }
    }
    ${sensorValueFragments.all}
  `,
  base: gql`
    fragment SensorBase on Sensor {
      id
      location
      temperatureMin {
        value
        unit
      }
      temperatureMax {
        value
        unit
      }
      humidityMin
      humidityMax
      lastSeenAt
      lastSeen
      enabled
    }
  `,
};

export const sensorsDistribution = gql`
  query sensorsDistribution($dateRange: DateRange, $sensors: [String!]!) {
    sensorsDistribution(dateRange: $dateRange, sensors: $sensors) {
      ...StatisticsData
    }
  }
  ${dashboardFragments.all}
`;

export const subscription = gql`
  subscription {
    sensors {
      ...SensorData
    }
  }
  ${fragments.all}
`;

export const subscriptionAllSensors = gql`
  subscription {
    sensorsAll {
      ...SensorData
    }
  }
  ${fragments.all}
`;

export const update = gql`
  mutation editSensor(
    $id: String!
    $location: String
    $temperatureMin: TemperatureInput
    $temperatureMax: TemperatureInput
    $humidityMin: Float
    $humidityMax: Float
    $enabled: Boolean
  ) {
    editSensor(
      id: $id
      location: $location
      temperatureMin: $temperatureMin
      temperatureMax: $temperatureMax
      humidityMin: $humidityMin
      humidityMax: $humidityMax
      enabled: $enabled
    )
  }
`;

export const getSensorConfiguration = gql`
  query sensorConfiguration {
    sensorConfiguration {
      gracePeriodMinutes
      temperatureUnit
      measurementPoints
      reportRecipients
      autoResolve
    }
  }
`;

export const updateSensorGlobal = gql`
  mutation updateSensorGlobal(
    $gracePeriodMinutes: Int!
    $temperatureUnit: TemperatureUnit!
    $measurementPoints: [Int!]!
    $reportRecipients: [Long!]!
    $autoResolve: [Int!]!
  ) {
    updateSensorGlobal(
      gracePeriodMinutes: $gracePeriodMinutes
      temperatureUnit: $temperatureUnit
      measurementPoints: $measurementPoints
      reportRecipients: $reportRecipients
      autoResolve: $autoResolve
    )
  }
`;

export const remove = gql`
  mutation deleteSensor($id: String!) {
    deleteSensor(id: $id)
  }
`;

export const sensorReports = gql`
  mutation sensorReports($dateRange: DateRange, $sensors: [String!]!) {
    sensorReports(dateRange: $dateRange, sensors: $sensors) {
      sensor {
        ...SensorBase
      }
      report {
        date
        minTemperature {
          value
          unit
        }
        maxTemperature {
          value
          unit
        }
        minHumidity
        maxHumidity
        measurements {
          hour
          temperature {
            value
            unit
          }
          humidity
          issue {
            id
            resolvedAt
            createdAt
            action
            isResolved
          }
        }
        issues {
          id
          resolvedAt
          createdAt
          action
          isResolved
        }
      }
    }
  }
  ${fragments.base}
`;

export default {
  subscription,
  update,
  updateSensorGlobal,
  sensorsDistribution,
};
