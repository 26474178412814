import { parse } from 'date-fns';
import isNumber from 'lodash//isNumber';
import { defaultTo } from 'lodash';
import trim from 'lodash/trim';

const numberOrZero = value => (isNumber(value) ? value : 0);

const numberOrInf = (value, type) => (isNumber(value) ? value : type === 'ASC' ? Infinity : -Infinity);

export const sortNumber = (l, r) => numberOrZero(l) - numberOrZero(r);

export const sortAge = (l, r, type) => numberOrInf(ageToNumber(l), type) - numberOrInf(ageToNumber(r), type);

export const ageToNumber = age => {
  if (!age || isNumber(age)) {
    return age;
  }

  const match = age.match(/(\d+)m?/);

  if (!match) {
    return Number(age);
  }

  if (match[0].length > match[1].length) {
    return Number(match[1]) / 12;
  }

  return Number(match[1]);
};

export const sortDate = (l, r) => parse(defaultTo(l, 0)) - parse(defaultTo(r, 0));

export const sortString = (l, r) => trim(String(l)).localeCompare(trim(String(r)));
