import React from 'react';
import { withProps } from 'recompose';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import { DefaultTitle } from '../../../se/components/entity/EntityRouter';
import { baseColumns, markAsResolvedColumn } from './columns';
import Filters from '../../pages/analytics/Filters';
import scheme from '../../../graph/issues';
import { NamedRange } from '../../core/DatePicker';
import pick from 'lodash/fp/pick';

const Empty = {
  illustration: theme => theme.illustrations.rooms,
  hint: 'Everything looks good for the selected time period.',
};

export const List = {
  columns: [...baseColumns, markAsResolvedColumn],
  tableKey: 'Issues',
  Empty,
  Title: () => <DefaultTitle>Detected Issues</DefaultTitle>,
  FilterComponent: withProps({
    hidePhysicianSelectInput: true,
    hideProcedureTypeSelectInput: true,
    hideHospitalSelectInput: false,
  })(Filters),
  defaultFilterValues: {
    dateRange: NamedRange.lastSevenDays(),
  },
  pickFilter: pick(['name', 'physician', 'procedureType', 'hospital', 'status', 'dateRange', 'category', 'speciality']),
};

export default GraphQLCRUD({
  entityName: 'Issue',
  scheme,
  List,
});
