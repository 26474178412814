import React, { FC } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { RouteComponentProps } from 'react-router';
import { format } from 'date-fns';

const StaffingCostButton: FC<RouteComponentProps & { date?: string }> = ({ match, date }) => {
  const openNewTab = () => {
    window.open(`${match.url}/staffing-cost?date=${date || format(new Date(), 'YYYY-MM-DD')}`, '_blank');
  };

  return (
    <Tooltip title={'Download Staff Cost'}>
      <IconButton onClick={openNewTab} color="primary">
        <MonetizationOnIcon />
      </IconButton>
    </Tooltip>
  );
};

export default StaffingCostButton;
