import React, { useMemo } from 'react';
import CollectionList from '../../../se/components/collection/CollectionList';
import { subscription } from '../../../graph/physicians';
import get from 'lodash/get';
import set from 'lodash/set';
import { sortDate, sortNumber, sortString } from '../../../util/sort';
import { FormFulfillmentCell } from '../../entities/procedures/columns';
import { TruncatedText } from '../../../se/components/typography';
import { utilizedColor } from './Mininytics';
import { defaultTo, filter, groupBy, values, zip } from 'lodash';
import { format, getMonth, getYear, isThisYear, isWeekend, parse } from 'date-fns';
import { physicianStats } from '../../../graph/bookings';
import { roundFixed } from '../sensor/SensorList';
import { Subscription } from '@apollo/client/react/components';
import { Range } from '../../core/DatePicker';
import { LocalDate, Period } from '@js-joda/core';

const listColumns = [
  {
    title: 'Physician',
    lens: data => get(data, 'physician.name'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
    sortFunction: (l, r) => sortString(get(l, 'physician.name'), get(r, 'physician.name')),
  },
  {
    title: 'Category',
    lens: data => get(data, 'physician.category'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
    sortFunction: (l, r) => sortString(get(l, 'physician.category'), get(r, 'physician.category')),
  },
  {
    title: 'Speciality',
    lens: data => get(data, 'physician.speciality'),
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
    sortFunction: (l, r) => sortString(get(l, 'physician.speciality'), get(r, 'physician.speciality')),
  },
  {
    title: 'Booked Slots',
    lens: data => get(data, 'stat.bookedHours', 0),
    Component: props => <TruncatedText>{roundFixed(props.data)}h</TruncatedText>,
    sortFunction: (l, r) => sortNumber(get(l, 'stat.bookedHours'), get(r, 'stat.bookedHours')),
  },
  {
    title: 'Block Utilization',
    lens: data => get(data, 'stat.relativeUtilization', 0),
    Component: props => <FormFulfillmentCell percentage={props.data} color={utilizedColor} />,
    sortFunction: (l, r) => sortNumber(get(l, 'stat.relativeUtilization'), get(r, 'stat.relativeUtilization')),
  },
  {
    title: 'Utilized Hours',
    lens: data => get(data, 'stat.utilizedHours', 0),
    sortFunction: (l, r) => sortNumber(get(l, 'stat.utilizedHours'), get(r, 'stat.utilizedHours')),
    Component: props => <TruncatedText>{roundFixed(props.data)}h</TruncatedText>,
  },
];

const relativeUtilization = (l, r) => (r > 0 ? (l / r) * 100 : l);

const viewColumns = [
  {
    title: 'Month',
    lens: data => data,
    sortFunction: (l, r) => sortDate(get(l, 'date'), get(r, 'date')),
    Component: ({ data }) => <TruncatedText>{get(data, 'label')}</TruncatedText>,
  },
  {
    title: 'Booked Slots',
    lens: data => get(data, 'operationRoomBookingsHours', 0),
    sortFunction: (l, r) => sortNumber(get(l, 'operationRoomBookingsHours'), get(r, 'operationRoomBookingsHours')),
    Component: props => <TruncatedText>{roundFixed(props.data)}h</TruncatedText>,
  },
  {
    title: 'Block Utilization',
    lens: data =>
      relativeUtilization(get(data, 'scheduledPatientsHours', 0), get(data, 'operationRoomBookingsHours', 0)),
    sortFunction: (l, r) => sortNumber(get(l, 'scheduledPatientsHours'), get(r, 'scheduledPatientsHours')),
    Component: props => <FormFulfillmentCell percentage={props.data} color={utilizedColor} />,
  },
  {
    title: 'Utilized Hours',
    lens: data => get(data, 'scheduledPatientsHours', 0),
    sortFunction: (l, r) => sortNumber(get(l, 'scheduledPatientsHours'), get(r, 'scheduledPatientsHours')),
    Component: props => <TruncatedText>{roundFixed(props.data)}h</TruncatedText>,
  },
];

const transformDataPoint = point => {
  const date = parse(point.date);
  return {
    [point.id]: defaultTo(point.value, 0),
    date: point.date,
    label: format(date, 'MMM DD, YYYY'),
    month: `${getMonth(date)}_${getYear(date)}`,
    isWeekend: isWeekend(date),
  };
};

const groupByMonth = statistics =>
  groupBy(
    filter(
      zip(...statistics.map(feature => get(feature, 'distribution', []).map(v => ({ ...v, id: feature.id })))).map(
        distribution =>
          distribution.reduce(
            (acc, curr) => ({
              ...acc,
              ...transformDataPoint(curr),
              isPoint: true,
            }),
            {}
          )
      ),
      point => !point.isWeekend
    ),
    'month'
  );

const aggregateMonth = points => {
  const data = points.reduce((acc, curr) => ({
    scheduledPatientsHours: acc.scheduledPatientsHours + curr.scheduledPatientsHours,
    operationRoomBookingsHours: acc.operationRoomBookingsHours + curr.operationRoomBookingsHours,
    date: curr.date,
  }));

  return {
    scheduledPatientsHours: data.scheduledPatientsHours,
    operationRoomBookingsHours: data.operationRoomBookingsHours,
    label: format(data.date, isThisYear(data.date) ? 'MMMM' : 'MMMM, YYYY'),
    date: parse(data.date),
    byDay: points,
  };
};

const PhysicianDetail = ({ statistics }) => {
  const byMonth = useMemo(() => groupByMonth(defaultTo(statistics, [])), [statistics]);
  const aggregate = useMemo(() => values(byMonth).map(aggregateMonth), [byMonth]);

  return (
    <CollectionList
      idProvider={item => item.id}
      entityName={'Physician'}
      tableKey={'single-physician-performance'}
      defaultSort={{ title: 'Month', type: 'DESC' }}
      columns={viewColumns}
      data={{ data: aggregate }}
      getList={payload => payload.data}
      TitleAndActions={() => null}
      history={{}}
      location={{}}
    />
  );
};

const PhysicianList = ({ filter, setPhysician }) => (
  <Subscription subscription={subscription} variables={{ filter }}>
    {data => (
      <CollectionList
        idProvider={item => item.id}
        entityName={'Physicians'}
        tableKey={'physician-performance'}
        defaultSort={{ title: 'Block Utilization', type: 'DESC' }}
        columns={listColumns}
        data={data}
        getList={payload => get(payload, 'data.physicians')}
        setList={(payload, data) => set(payload, 'data.physicians', data)}
        TitleAndActions={() => null}
        history={{}}
        location={{}}
        onViewClick={data => setPhysician(get(data, 'physician.id'))}
      />
    )}
  </Subscription>
);

export default ({ physician, setPhysician, filter }) =>
  !physician ? (
    <PhysicianList filter={filter} setPhysician={setPhysician} />
  ) : (
    <Subscription
      subscription={physicianStats}
      variables={{
        filter: {
          physician,
          dateRange: Range.of(LocalDate.now().withDayOfMonth(1).minusMonths(11), Period.ofYears(1), LocalDate.now()),
        },
      }}
    >
      {data => <PhysicianDetail statistics={get(data, 'data.physicianStats', [])} />}
    </Subscription>
  );
