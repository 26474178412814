import React from 'react';
import styles from '../../styles';
import MedicalPassportField from '../../components/MedicalPassportField';
import toAge from '../../../../../../questionnaire/toAge';
import toSex from '../../../../../../questionnaire/toSex';
import getAgeAndBmi from './pageUtils';
import MedicalPassportTextArea2 from '../../components/MedicalPassportTextArea2';
import { additionalcontent } from './contentlimit';
import { other } from '../paths';
import toHighlightsString5 from '../../../../../../questionnaire/toHighlightsString5';
import toStringWithoutQuestions3 from '../../../../../../questionnaire/toStringWithoutQuestions3';
import Barcode from '../../../Barcode';

const Page8 = ({
  formName,
  pageNumber,
  showQRCode,
  hospitalName,
  value,
  defaultValue,
  lastPreOpCompletedEvent,
  ...props
}) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;

  const { age } = getAgeAndBmi(defaultValue?.demographicInformation?.dob, defaultValue?.demographicInformation?.bmi);

  const surgery = additionalcontent(toHighlightsString5, [['priorSurgeries']], 285);
  const medication = additionalcontent(toHighlightsString5, [['medications']], 215);
  const allergy = additionalcontent(toHighlightsString5, [['allergies']], 200);
  const otherlist = additionalcontent(toStringWithoutQuestions3, other, 150);

  return (
    <div id="page8" className="page" style={{ height: '100%', paddingBottom: '0' }}>
      <div style={{ minHeight: '69.8rem', paddingBottom: '0' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '50% 50%',
            gridGap: '1rem',
            alignContent: 'start',
            marginBottom: '1rem',
            paddingTop: '1rem',
            fontSize: '14px',
          }}
        >
          <div style={styles.div}>
            <b>
              <div style={{ fontSize: '12px', paddingBottom: '0', lineHeight: '14px' }}>
                {(hospitalName || '').toUpperCase()}
              </div>
              <div style={{ fontSize: '20px', paddingBottom: '0' }}>Additional Information</div>
            </b>
            <div style={{ fontSize: '12px' }}>
              <MedicalPassportTextArea2
                name="procedure.name"
                label="Procedure"
                style={{ display: 'flex' }}
                fontSize={12}
                nobackground
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <div>
              {showQRCode && (
                <Barcode
                  formName={formName}
                  pageNumber={pageNumber}
                  providerId={defaultValue?.procedure?.patient?.providerId}
                  serviceTime={defaultValue?.procedure?.serviceTime}
                />
              )}
            </div>
            <div
              style={{
                fontSize: '12px',
                border: '1px solid black',
                padding: '0.2rem',
                lineHeight: '15px',
              }}
            >
              <MedicalPassportField
                name="procedure.patient.name"
                label="Patient"
                fontWeight={'bold'}
                fontSize={14}
                nobackground
              />
              <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={12} nobackground />
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '40% 60%',
                  alignContent: 'start',
                }}
              >
                <MedicalPassportField
                  name="dob"
                  label="DOB"
                  path={['demographicInformation', 'dob']}
                  fontSize={12}
                  nobackground
                />
                <MedicalPassportField
                  name="age"
                  label="Age"
                  path={['demographicInformation', 'dob']}
                  reducer={toAge}
                  warning={age > 70}
                  fontSize={12}
                  nobackground
                />
                <MedicalPassportField name="procedure.serviceTime" label="DOS" fontSize={12} nobackground />
                <MedicalPassportField
                  name="sex"
                  label="Sex"
                  path={['demographicInformation', 'sex']}
                  reducer={toSex}
                  fontSize={12}
                  nobackground
                />
              </div>
              <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={12} nobackground />
            </div>
          </div>
        </div>

        <div style={{ fontSize: '20px' }}>
          <b>Nursing Assessment Additional Information</b>
        </div>
        <div
          style={{
            borderBottom: '1px solid black',
            width: '100%',
            fontSize: '14px',
            paddingTop: '0.5rem',
            marginBottom: '2px',
          }}
        >
          <b>SURGICAL/ANESTHESIA HISTORY</b>
        </div>
        <div>
          <MedicalPassportTextArea2 name="anesthesia1" value={surgery} fontSize={'0.8rem'} nobackground />
        </div>
        <div
          style={{
            borderBottom: '1px solid black',
            width: '100%',
            fontSize: '14px',
            paddingTop: '0.5rem',
            marginBottom: '2px',
          }}
        >
          <b>CURRENT MEDICATIONS</b>
        </div>
        <div>
          <MedicalPassportTextArea2 name="medications1" value={medication} fontSize={'0.8rem'} nobackground />
        </div>

        <div
          style={{
            borderBottom: '1px solid black',
            width: '100%',
            fontSize: '14px',
            paddingTop: '0.5rem',
            marginBottom: '2px',
          }}
        >
          <b>ADDITIONAL ALLERGIES/SENSITIVITIES</b>
        </div>
        <div>
          <MedicalPassportTextArea2 name="allergies1" value={allergy} fontSize={'0.8rem'} nobackground />
        </div>

        <div
          style={{
            borderBottom: '1px solid black',
            width: '100%',
            fontSize: '14px',
            paddingTop: '0.5rem',
            marginBottom: '2px',
          }}
        >
          <b>OTHER</b>
        </div>
        <div>
          <MedicalPassportTextArea2 name="other1" value={otherlist} fontSize={'0.8rem'} nobackground />
        </div>
      </div>
      <footer
        style={{
          display: 'grid',
          gridTemplateColumns: '13.25% 14.5% 11.5% 17% 0.05% 14.5% 29%',
          fontSize: '11px',
          height: '20px',
        }}
      >
        <div style={{ paddingTop: '3px' }}>Provided by Patient{'('}</div>
        <MedicalPassportField name="questionnaire.firstSubmittedAt" fontSize={11} nobackground />
        <div style={{ paddingTop: '3px' }}>{')'} Last updated by</div>
        <MedicalPassportField name="procedure.patient.name" fontSize={11} nobackground />
        <div style={{ paddingTop: '3px' }}>{'('}</div>
        <MedicalPassportField name="questionnaire.submittedAt" fontSize={11} nobackground />
        <div>
          {')'}{' '}
          <span style={{ fontSize: '14px', marginLeft: '9rem' }}>
            <b>Ospitek, Inc</b>
          </span>
        </div>
      </footer>
    </div>
  );
};

export default Page8;
