import { gql } from '@apollo/client';

export const fragments = {
  vendor: gql`
    fragment VendorData on Vendor {
      id
      name
      address
      email
      phoneNumber
      hasScheduleAccess
      scheduleAccessAll
      representatives {
        name
        email
        phoneNumber
      }
    }
  `,
};

export const list = gql`
  query vendors {
    vendors {
      ...VendorData
    }
  }
  ${fragments.vendor}
`;

export const item = gql`
  query vendor($id: Long!) {
    vendor(id: $id) {
      ...VendorData
    }
  }
  ${fragments.vendor}
`;

export const create = gql`
  mutation createVendor($name: String!, $address: String, $phoneNumber: String, $email: String) {
    createVendor(name: $name, address: $address, phoneNumber: $phoneNumber, email: $email) {
      ...VendorData
    }
  }
  ${fragments.vendor}
`;

export const update = gql`
  mutation editVendor($id: Long!, $address: String, $name: String!, $phoneNumber: String, $email: String) {
    editVendor(id: $id, name: $name, address: $address, phoneNumber: $phoneNumber, email: $email) {
      ...VendorData
    }
  }
  ${fragments.vendor}
`;

export const remove = gql`
  mutation deleteVendor($id: Long!) {
    deleteVendor(id: $id)
  }
`;

export const assignVendorProcedure = gql`
  mutation assignVendorProcedure($procedureId: Long!, $vendorId: Long!) {
    assignVendorProcedure(procedureId: $procedureId, vendorId: $vendorId)
  }
`;

export const setVendorProcedureState = gql`
  mutation setVendorProcedureState($procedureId: Long!, $vendorId: Long!, $state: ProcedureVendorState!) {
    setVendorProcedureState(procedureId: $procedureId, vendorId: $vendorId, state: $state)
  }
`;

export const removeVendorProcedure = gql`
  mutation removeVendorProcedure($procedureId: Long!, $vendorId: Long!) {
    removeVendorProcedure(procedureId: $procedureId, vendorId: $vendorId)
  }
`;

export const saveRepresentatives = gql`
  mutation saveRepresentatives($id: Long!, $representatives: [RepresentativeInput!]!) {
    saveRepresentatives(id: $id, representatives: $representatives)
  }
`;

export const grantScheduleAccess = gql`
  mutation grantScheduleAccessToVendor($id: Long!) {
    grantScheduleAccessToVendor(id: $id) {
      ...VendorData
    }
  }
  ${fragments.vendor}
`;

export const resendScheduleAccessLink = gql`
  mutation resendScheduleAccessLinkToVendor($id: Long!) {
    resendScheduleAccessLinkToVendor(id: $id) {
      ...VendorData
    }
  }
  ${fragments.vendor}
`;

export const setScheduleAccessAllVendor = gql`
  mutation setScheduleAccessAllVendor($id: Long!, $scheduleAccessAll: Boolean!) {
    setScheduleAccessAllVendor(id: $id, scheduleAccessAll: $scheduleAccessAll)
  }
`;

export const getScheduleAccessAllVendor = gql`
  query getScheduleAccessAllVendor($id: Long) {
    getScheduleAccessAllVendor(id: $id)
  }
`;

export const revokeScheduleAccess = gql`
  mutation revokeScheduleAccessToVendor($id: Long!) {
    revokeScheduleAccessToVendor(id: $id) {
      ...VendorData
    }
  }
  ${fragments.vendor}
`;

export const listAssignedVendors = gql`
  query listAssignedVendors($date: Date!) {
    listAssignedVendors(date: $date) {
      ...VendorData
    }
  }
  ${fragments.vendor}
`;

export const sendNewScheduleNotification = gql`
  mutation sendNewScheduleNotificationVendor($contacts: [ContactsInput!]!, $message: String!, $date: Date!) {
    sendNewScheduleNotificationVendor(contacts: $contacts, message: $message, date: $date)
  }
`;

export default {
  list,
  item,
  create,
  update,
  remove,
  grantScheduleAccess,
  resendScheduleAccessLink,
  revokeScheduleAccess,
  setScheduleAccessAllVendor,
};
