import { compose, lifecycle, mapProps, withHandlers } from 'recompose';
import withKeyEvent from '../se/hocs/withKeyEvent';
import queryString from 'query-string';

export default Component =>
  compose(
    withKeyEvent('debugKeyEvent', 'ctrl+d'),
    withHandlers({
      handleDebugKeyEvent:
        ({ history, location }) =>
        () => {
          const query = queryString.parse(location.search);
          const debug = query.debug === 'true';

          history.replace({
            pathname: history.url,
            search: queryString.stringify({ ...query, debug: !debug }),
          });
        },
    }),
    lifecycle({
      componentDidMount() {
        const { debugKeyEvent, handleDebugKeyEvent } = this.props;
        debugKeyEvent.listen(handleDebugKeyEvent);
      },
      componentWillUnmount() {
        const { debugKeyEvent, handleDebugKeyEvent } = this.props;
        debugKeyEvent.unlisten(handleDebugKeyEvent);
      },
    }),
    mapProps(props => {
      const { debug } = queryString.parse(props.location.search);
      return { ...props, debug: debug === 'true' };
    })
  )(Component);
