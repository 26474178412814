import React from 'react';
import styled from 'styled-components';
import { H2 } from '../../se/components/typography';
import responsive from '../../se/utilities/responsive';
import { Box } from '@material-ui/core';
import Home from './Home';
import Screens from './Screens';
import { Route, RouteComponentProps, Switch } from 'react-router';
import useSystemWideRecentlyOfflineScreens from './useSystemWideRecentlyOfflineScreens';
import useSystemWideRecentlyInactiveGateways from './useSystemWideRecentlyInactiveGateways';
import Gateways from './Gateways';
import useSystemWideActorStatuses from './useSystemWideActorStatuses';
import Actors from './Actors';

export const Title = styled(H2)`
  margin: 0.5em 0;
  ${responsive.md.andSmaller`
    font-size: 1.25rem;
  `}
`;

interface DashboardProps extends RouteComponentProps {}

const Dashboard = ({ match }: DashboardProps) => {
  const systemWideRecentlyOfflineScreens = useSystemWideRecentlyOfflineScreens();
  const systemWideRecentlyInactiveGateways = useSystemWideRecentlyInactiveGateways();
  const systemWideActorStatuses = useSystemWideActorStatuses();

  return (
    <Box my={2}>
      <Switch>
        <Route
          exact
          path={match.path}
          render={props => (
            <Home
              {...props}
              systemWideRecentlyOfflineScreens={systemWideRecentlyOfflineScreens}
              systemWideRecentlyInactiveGateways={systemWideRecentlyInactiveGateways}
              systemWideActorStatuses={systemWideActorStatuses}
            />
          )}
        />
        <Route
          path={match.path + '/screens'}
          render={props => <Screens {...props} {...systemWideRecentlyOfflineScreens} />}
        />
        <Route
          path={match.path + '/gateways'}
          render={props => <Gateways {...props} {...systemWideRecentlyInactiveGateways} />}
        />
        <Route path={match.path + '/actors'} render={props => <Actors {...props} {...systemWideActorStatuses} />} />
      </Switch>
    </Box>
  );
};

export default Dashboard;
