import { SvgIconProps, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Timer from './Timer';
import React from 'react';

interface StatusProps {
  Icon: React.ComponentType<SvgIconProps>;
  color: string;
  message: string;
  details?: string;
  onDone: () => void;
  duration?: number;
}

const Status = ({ Icon, color, message, details, onDone, duration = 4000 }: StatusProps) => {
  return (
    <Box px={4} textAlign="center">
      <Icon style={{ fontSize: 80, color: color }} />
      <Typography variant="h5" gutterBottom>
        {message}
      </Typography>
      {details && <Typography gutterBottom>{details}</Typography>}
      <Box mt={2} width="8ch" display="inline-block">
        <Timer duration={duration} onDone={onDone} />
      </Box>
    </Box>
  );
};

export default Status;
