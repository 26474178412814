import React, { Fragment } from 'react';
import styled from 'styled-components';
import renderNode from './renderNode';
import get from 'lodash/get';

const Tree = styled.div`
  display: flex;
  flex-direction: column;
  // border: 1px solid orange;
`;

export default ({ schema, value = {} }) => (
  <Tree>
    {schema.map(([id, field]) => (
      <Fragment key={id}>{renderNode(field, { id, value: get(value, `schema.${id}`) })}</Fragment>
    ))}
  </Tree>
);
