import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { EditingHeader, HospitalName, hospitalScheduleStyles, Row } from './HospitalSchedule';
import { format, isAfter } from 'date-fns';
import parse from 'date-fns/parse';
import Typography from '@material-ui/core/Typography';
import { Procedures } from '../kiosk/schedule/edit/Procedures';
import CheckboxInput from '../../inputs/CheckboxInput';
import { useTheme } from '@material-ui/core';
import groupBy from '../../../util/groupBy';
import formatRelative from '../../../util/formatRelative';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
// @ts-ignore
import dateRange from '../../../assets/images/icons/date_range.png';
import { useScheduleUserContext } from './schedule/vendor/context/ScheduleUserContext';
import { Procedure } from '../../../types/Procedure';
import StaffShiftRoom from '../../../types/StaffShift';
import StaffShiftRoomBox from './schedule/staff/StaffShiftRoomBox';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0.5em',
    marginTop: '1em',
    gap: '1em',
  },
  divider: {
    flex: 1,
    border: `0.02em solid rgba(255, 255, 255, 0.25)`,
  },
  content: {
    overflowY: 'scroll',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  fab: {
    width: 'fit-content',
    margin: '1em',
  },
}));

const UserSchedule: FC<{
  scope: any;
  hasScheduleAccessAllRight?: boolean;
  myProceduresChecked?: boolean;
  setMyProceduresChecked: Dispatch<SetStateAction<boolean>>;
}> = ({ scope, hasScheduleAccessAllRight, myProceduresChecked, setMyProceduresChecked }) => {
  const classes = useStyles();
  const theme = useTheme();

  const { loading, assignedProcedures, assignedRoomShifts, hasMore, loadMore } = useScheduleUserContext();

  const shiftsByDate = useMemo(() => {
    const grouped1 = groupBy(assignedProcedures, procedure => format(new Date(procedure.startTime), 'YYYY-MM-DD'));
    const grouped2 = groupBy(assignedRoomShifts, roomShift => roomShift.date);

    const keys = Array.from(new Set([...Object.keys(grouped1), ...Object.keys(grouped2)]));
    const obj = keys.reduce<{
      [key: string]: {
        procedureShifts: Procedure[];
        roomShifts: StaffShiftRoom[];
      };
    }>((acc, date) => {
      return {
        ...acc,
        [date]: {
          procedureShifts: (grouped1[date] || []).sort((l, r) =>
            isAfter(parse(l.startTime), parse(r.startTime)) ? 1 : -1
          ),
          roomShifts: grouped2[date] || [],
        },
      };
    }, {});

    return Object.entries(obj).sort((l, r) => (isAfter(parse(l[0]), parse(r[0])) ? 1 : -1));
  }, [assignedProcedures, assignedRoomShifts]);

  const hasAssignments = assignedProcedures?.length > 0 || assignedRoomShifts?.length > 0;

  const externalClasses = hospitalScheduleStyles();
  const isMaxWidth1920 = useMediaQuery('(max-width: 1920px)');

  return (
    <Box
      className={clsx(externalClasses.page, {
        [externalClasses.hdtv]: isMaxWidth1920,
      })}
    >
      <EditingHeader>
        <HospitalName>{scope?.hospital?.name}</HospitalName>
        <Row>
          {hasScheduleAccessAllRight && (
            <CheckboxInput
              label="My Procedures"
              name="myProcedures"
              value={myProceduresChecked}
              onChange={setMyProceduresChecked}
              layoutProps={{ style: { marginRight: theme.spacing(1) } }}
            />
          )}
        </Row>
      </EditingHeader>
      {hasAssignments || loading ? (
        <div className={classes.content}>
          {shiftsByDate.map(e => {
            const [date, data] = e;
            const procedures = data.procedureShifts;
            const roomShifts = data.roomShifts;
            return (
              <div style={{ width: '100%' }} key={date}>
                <div className={classes.row}>
                  <Typography variant="h6">{formatRelative(parse(date))}</Typography>
                  <Divider className={classes.divider} />
                </div>
                <div>
                  <Procedures procedures={procedures} />
                </div>
                <div>
                  {roomShifts?.map(shift => (
                    <StaffShiftRoomBox key={shift.id} shift={shift} />
                  ))}
                </div>
              </div>
            );
          })}
          {(hasMore || loading) && (
            <Fab
              variant="extended"
              size="large"
              color="primary"
              onClick={loadMore}
              disabled={loading}
              className={classes.fab}
            >
              {loading ? 'Loading…' : 'Load More'}
            </Fab>
          )}
          {!hasMore && !loading && (
            <div className={classes.row}>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                No more assignments
              </Typography>
            </div>
          )}
        </div>
      ) : (
        <div className={classes.content} style={{ justifyContent: 'center', flex: 1 }}>
          <div>
            <img
              src={dateRange}
              alt={'logo'}
              style={{
                fontSize: '4em',
                width: '1em',
                height: '1em',
                margin: '0 auto .125em',
                opacity: '.35',
              }}
            />
          </div>
          <Typography variant="body1" color="textSecondary">
            No assignments
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default UserSchedule;
