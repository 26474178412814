import React, { Fragment, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import DateFilter from './DateFilter';
import graphqlSelectInput from '../../inputs/graphqlSelectInput';
import { list as physicians } from '../../../graph/physicians';
import { listLastHundred } from '../../../graph/procedureTypes';
import hospitals from '../../../graph/hospitals';
import LinkButton from '../../../se/components/LinkButton';
import responsive, { breakpoint } from '../../../se/utilities/responsive';
import ButtonLink from '../../../se/components/ButtonLink';
import SelectInput from '../../../se/components/inputs/SelectInput';
import { withProps } from 'recompose';
import { intakeStatusLabels, procedureStatuseLabels } from '../../entities/procedures/ProcedureEventLog';
import CategoryPicker from './CategoryPicker';
import omit from 'lodash/omit';
import get from 'lodash/get';
import { useApolloClient, useQuery } from '@apollo/client';
import { createRanges, NamedRange, toLocalDate } from '../../core/DatePicker';
import withExchangeOnly from '../../../util/withExchangeOnly';
import Button from '@material-ui/core/Button';
import { InlineOmniSearchWithRouter, useOmniSearch } from '../../OmniSearch';
import Mousetrap from 'mousetrap';
import { SurgeryStatusesHospital, SurgeryStatusesSurgeonOffice } from '../../entities/surgeonOffice/surgeries/enums';
import { ConsultationStatuses } from '../../entities/surgeonOffice/consultations/enums';
import FilterListIcon from '@material-ui/icons/FilterList';
import { debounce } from 'lodash';
import TextSearch from './TextSearch';
import { listGroup } from '../../../graph/partners';
import { notUtilityRooms } from '../../../graph/rooms';

// TODO refactor this component to use Material UI theme and components

const RoomSelectInput = graphqlSelectInput({
  entityName: 'Room',
  label: 'Room',
  placeholder: 'Room',
  graphqlList: notUtilityRooms,
  getOptionValue: option => (option ? parseInt(option.value, 10) : undefined),
});

const Container = styled.div`
  display: flex;
  grid-template-columns: ${({ hasTextSearch }) => (hasTextSearch ? '1.6fr' : '')} 1fr 1fr ${({ hasStatusFilter }) =>
      hasStatusFilter ? '0.8fr' : ''} 1fr 0.3fr ${({ canToggleFilter }) => (canToggleFilter ? '0.3fr' : '')} auto;
  column-gap: 1em;
  justify-items: stretch;
  align-items: start;
  flex: 1;
  align-content: baseline;

  > * {
    margin: 0;
    flex: 1;
    min-width: 190px;
    > label {
      display: none;
    }
  }
  button {
    align-items: end;
    white-space: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
  }

  ${responsive.md.andSmaller`
    column-gap: .5em;
    display: flex;
    flex-flow: column;
    flex-grow: 0;
    flex-shrink: 0;

    > * {
      width: 100%;
      margin-top: .5rem;
    }

  `};
`;

const FilterAction = styled(LinkButton)`
  min-width: 94px;
  ${responsive.md.andSmaller`
    margin-top: 0.25rem;
    padding: calc(1rem * 0.75 - 0.125rem);
  `};
`;

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  > * + * {
    margin-top: 1em;
  }
  ${responsive.md.andSmaller`
    height: auto;
    display: ${props => (props.showOnMobile ? 'none' : 'flex')};
  `};

  .MuiTabs-flexContainer {
    flex-wrap: wrap;
    ${responsive.md.andSmaller`
      > * {
        margin-top: 1em;
      }
    `}
  }
`;

const PhysicianSelectInput = graphqlSelectInput({
  entityName: 'Physician',
  placeholder: 'Physician',
  graphqlList: physicians,
  sortOptions: true,
  menuWidthFollowContent: false,
});

const ProcedureTypeSelectInput = graphqlSelectInput({
  entityName: 'ProcedureType',
  placeholder: 'Procedure',
  graphqlList: listLastHundred,
  menuWidthFollowContent: false,
  sortOptions: true,
});

const HospitalSelectInput = graphqlSelectInput({
  entityName: 'Hospital',
  placeholder: 'Hospital',
  graphqlList: hospitals.list,
  sortOptions: true,
});

const PartnersSelectInput = graphqlSelectInput({
  entityName: 'Partner',
  placeholder: 'Hospital',
  graphqlList: listGroup,
  sortOptions: true,
});

const ProcedureStatusSelectInput = withProps({
  placeholder: 'Status',
  options: procedureStatuseLabels,
  isSearchable: false,
  menuWidthFollowContent: false,
  name: 'status',
})(SelectInput);

const IntakeStatusSelectInput = withProps({
  placeholder: 'Registration Package Status',
  options: intakeStatusLabels,
  isSearchable: false,
  menuWidthFollowContent: false,
  name: 'intakeStatus',
})(SelectInput);

const ConsultationStatusSelectInput = withProps({
  placeholder: 'Status',
  options: ConsultationStatuses,
  isSearchable: false,
  menuWidthFollowContent: false,
  name: 'procedureStatus',
})(SelectInput);

const SurgeryStatusHospitalSelectInput = withProps({
  placeholder: 'Status',
  options: SurgeryStatusesHospital,
  isSearchable: false,
  menuWidthFollowContent: false,
  name: 'scheduleTransferState',
})(SelectInput);

const SurgeryStatusSurgeonOfficeSelectInput = withProps({
  placeholder: 'Status',
  options: SurgeryStatusesSurgeonOffice,
  isSearchable: false,
  menuWidthFollowContent: false,
  name: 'scheduleTransferState',
})(SelectInput);

const SpecialitySelectInput = ({ value, onChange, ...rest }) => {
  const { data, loading } = useQuery(physicians);
  const options = useMemo(
    () =>
      omit(
        get(data, 'physicians', []).reduce((acc, { speciality }) => ({ ...acc, [speciality]: speciality }), {}),
        ['', null, undefined]
      ),
    [data]
  );

  return (
    <SelectInput
      {...rest}
      onChange={onChange}
      value={value}
      options={options}
      menuWidthFollowContent={false}
      loading={loading}
      sortOptions={true}
      placeholder={'Speciality'}
    />
  );
};

const defaults = { dateRange: NamedRange.lastSevenDays() };

const DebouncedTextInput = ({ value, onChange }) => {
  const [internal, setInternal] = useState(value);

  const throttled = useMemo(() => debounce(onChange, 500), [onChange]);

  const onChangeInternal = val => {
    setInternal(val);
    throttled(val);
  };

  return <TextSearch autoComplete="off" onChange={onChangeInternal} value={internal} />;
};

const Filters = props => {
  const {
    value,
    now = new Date(),
    searchSource,
    showNameSearchInput,
    hidePhysicianSelectInput,
    hideProcedureTypeSelectInput,
    hideHospitalSelectInput,
    hidePartnersSelectInput,
    hideProcedureStatusSelectInput,
    hideIntakeStatusSelectInput,
    hideConsultationStatusSelectInput,
    hideSurgeryStatusHospitalSelectInput,
    hideSurgeryStatusSurgeonOfficeSelectInput,
    hideCategorySelectInput,
    hideSpecialitySelectInput,
    hideRoomSelectInput,
    hideMobile,
    futurePicker,
    symmetricPicker,
    customPicker,
    dateTimePickerTitle,
    hideDateFilter,
    hideCustomFilterTabs,
    CustomFilterTabs,
    onChange,
    reload,
    dontReloadOn,
    history,
    location,
    match,
    defaultValue,
    datePickerRanges,
  } = props;

  const [state, setState] = useState(
    window.innerWidth <= breakpoint.lg
      ? { name: '', filterHidden: true, canToggleFilter: true }
      : { name: '', filterHidden: false, canToggleFilter: false }
  );

  const onFilterChange = key => val => {
    onChange({ ...props.value, [key]: val });
    ((dontReloadOn || []).filter(e => e === key) || []).length === 0 && reload && window.location.reload();
  };

  const clearAll = () => {
    onChange({ dateRange: defaultValue?.dateRange });
    reload && window.location.reload();
  };

  const asParsedInt = fn => val => fn(parseInt(val));

  const isMobile = window.innerWidth < breakpoint.md;

  const today = toLocalDate(now);

  const ranges = createRanges({ datePickerRanges, today, future: futurePicker, symmetric: symmetricPicker });

  const { openSource } = useOmniSearch();

  const apolloClient = useApolloClient();

  useEffect(() => {
    if (searchSource) {
      const mousetrap = new Mousetrap(document.body);

      mousetrap.bind('s', e => {
        if (['INPUT', 'TEXTAREA', 'SELECT'].indexOf(e.target.tagName ?? '') >= 0) {
          return;
        }

        e.preventDefault();

        openSource(searchSource);
      });

      return () => {
        mousetrap.reset();
      };
    }
  }, [openSource, searchSource]);

  if (state.filterHidden && !hideMobile) {
    return (
      <Container className={'hidden'}>
        <ButtonLink startIcon={<FilterListIcon />} onClick={() => setState({ ...state, filterHidden: false })}>
          Show Filters
        </ButtonLink>
      </Container>
    );
  }

  return (
    <Rows className={'visible'} showOnMobile={hideMobile}>
      {!hideCustomFilterTabs && (
        <CustomFilterTabs value={value && value.dateRange} onChange={onFilterChange('dateRange')} />
      )}

      <Container
        canToggleFilter={state.canToggleFilter}
        hasStatusFilter={!(hideProcedureStatusSelectInput || hideIntakeStatusSelectInput)}
      >
        {searchSource && (
          <InlineOmniSearchWithRouter
            source={[apolloClient, searchSource]}
            context={{
              physician: value.physician,
              speciality: value.speciality,
              dateRange: value.dateRange,
              status: value.procedureStatus,
            }}
            history={history}
            location={location}
            match={match}
          />
        )}
        {showNameSearchInput && (
          <DebouncedTextInput value={value && value.name ? value.name : ''} onChange={onFilterChange('name')} />
        )}
        {!hidePhysicianSelectInput && (
          <PhysicianSelectInput
            onChange={asParsedInt(onFilterChange('physician'))}
            value={value && value.physician ? value.physician : ''}
            menuWidthFollowContent
          />
        )}
        {!hideProcedureTypeSelectInput && (
          <ProcedureTypeSelectInput
            onChange={asParsedInt(onFilterChange('procedureType'))}
            value={value && value.procedureType ? value.procedureType : ''}
            variables={{ physician: value && value.physician }}
            menuWidthFollowContent
          />
        )}
        {!hideSpecialitySelectInput && (
          <SpecialitySelectInput
            onChange={onFilterChange('speciality')}
            value={value && value.speciality ? value.speciality : ''}
            menuWidthFollowContent
          />
        )}

        {!hideRoomSelectInput && (
          <RoomSelectInput
            placeholder="Room"
            onChange={onFilterChange('room')}
            value={value && value.room ? value.room : ''}
          />
        )}

        {!hideHospitalSelectInput && (
          <HospitalSelectInput
            onChange={asParsedInt(onFilterChange('hospital'))}
            value={value && value.hospital}
            variables={{ hospital: value && value.hospital ? value.hospital : '' }}
            menuWidthFollowContent
          />
        )}

        {!hidePartnersSelectInput && (
          <PartnersSelectInput
            onChange={asParsedInt(onFilterChange('partner'))}
            value={value && value.partner}
            variables={{
              partner: value && value.partner ? value.partner : '',
            }}
            menuWidthFollowContent
          />
        )}

        {!hideProcedureStatusSelectInput && (
          <ProcedureStatusSelectInput onChange={onFilterChange('status')} value={value && value.status} />
        )}

        {!hideIntakeStatusSelectInput && (
          <IntakeStatusSelectInput onChange={onFilterChange('intakeStatus')} value={value && value.intakeStatus} />
        )}

        {!hideConsultationStatusSelectInput && (
          <ConsultationStatusSelectInput
            onChange={onFilterChange('procedureStatus')}
            value={value && value.procedureStatus}
          />
        )}

        {!hideSurgeryStatusHospitalSelectInput && (
          <SurgeryStatusHospitalSelectInput
            onChange={onFilterChange('scheduleTransferState')}
            value={value && value.scheduleTransferState}
          />
        )}

        {!hideSurgeryStatusSurgeonOfficeSelectInput && (
          <SurgeryStatusSurgeonOfficeSelectInput
            onChange={onFilterChange('scheduleTransferState')}
            value={value && value.scheduleTransferState}
          />
        )}

        {props.children}
        {!hideDateFilter && (
          <DateFilter
            title={dateTimePickerTitle}
            today={today}
            value={value && value.dateRange}
            onChange={onFilterChange('dateRange')}
            ranges={ranges}
            customPicker={customPicker}
          />
        )}

        {!isMobile && (
          <Fragment>
            <Button onClick={clearAll}>Clear All</Button>
            {state.canToggleFilter && (
              <FilterAction onClick={() => setState({ ...state, filterHidden: true })}>Hide Filters</FilterAction>
            )}
          </Fragment>
        )}
      </Container>

      {!hideCategorySelectInput && (
        <CategoryPicker value={value && value.category} onChange={onFilterChange('category')} />
      )}

      {isMobile && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={clearAll}>Clear All</Button>
          {state.canToggleFilter && (
            <FilterAction onClick={() => setState({ ...state, filterHidden: true })}>Hide Filters</FilterAction>
          )}
        </div>
      )}
    </Rows>
  );
};

Filters.defaultProps = {
  defaultValue: defaults,
  showNameSearchInput: false,
  hidePhysicianSelectInput: false,
  hideProcedureTypeSelectInput: false,
  hideHospitalSelectInput: true,
  hidePartnersSelectInput: true,
  hideProcedureStatusSelectInput: true,
  hideIntakeStatusSelectInput: true,
  hideCategorySelectInput: true,
  hideSpecialitySelectInput: true,
  hideRoomSelectInput: true,
  hideConsultationStatusSelectInput: true,
  hideSurgeryStatusHospitalSelectInput: true,
  hideSurgeryStatusSurgeonOfficeSelectInput: true,
  hideMobile: false,
  hideDateFilter: false,
  hideCustomFilterTabs: true,
  reload: true,
};

export default withExchangeOnly(Filters);
