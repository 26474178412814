import React, { useMemo, useState } from 'react';
import { H4 } from '../../../../se/components/typography';
import { EventLog, EventLogItem } from '../views/components';
import { withRouter } from 'react-router';
import { isProcedureEventEditable, transform } from '../util/patientEvents';
import PatientEventTimestamp from './PatientEventTimestamp';
import Box from '@material-ui/core/Box';
import PatientEventText, { getEventText } from './PatientEventText';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { orTabletProcedureSteps } from '../../../../graph/procedureSteps';
import { toPatientStatus } from '../../../pages/kiosk/tablet/util/procedureSteps';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const PatientEvents = withRouter(({ location, events, patientId }) => {
  if (events.length === 0) return null;

  const transformed = useMemo(() => transform(events), [events]);

  const [history, setHistory] = useState(null);

  const { data } = useQuery(orTabletProcedureSteps);
  const procedureSteps = data?.orTabletProcedureSteps || [];

  return (
    <EventLog>
      <H4>Patient Journey</H4>
      {transformed.map(event => {
        const eventText = getEventText(
          event,
          procedureSteps.find(e => toPatientStatus(e.type) === event?.status)
        );
        return (
          <Box key={event?.id}>
            <EventLogItem>
              <PatientEventTimestamp
                patientId={patientId}
                event={event}
                eventText={eventText}
                editable={isProcedureEventEditable(event)}
              />
              <PatientEventText
                event={event}
                eventText={eventText}
                showHistory={history && history === event?.id}
                toggleShowHistory={() => setHistory(history === event?.id ? null : event?.id)}
              />
            </EventLogItem>
            {event?.id === history && (
              <Box ml={6} mb={2}>
                {event?.history.map(event => {
                  const eventText = getEventText(
                    event,
                    procedureSteps.find(e => toPatientStatus(e.type) === event?.status)
                  );
                  return (
                    <Row key={event?.id}>
                      <PatientEventTimestamp patientId={patientId} event={event} />
                      <PatientEventText
                        eventText={eventText}
                        event={event}
                        procedureStep={procedureSteps.find(e => toPatientStatus(e.type) === event?.status)}
                      />
                    </Row>
                  );
                })}
              </Box>
            )}
          </Box>
        );
      })}
    </EventLog>
  );
});

export default PatientEvents;
