import React from 'react';
import { TruncatedText } from '../../../se/components/typography';
import { formatPhoneNumber, processPhoneNumbersString } from '../../../se/components/inputs/PhoneInput';
import { sortNumber, sortString } from '../../../util/sort';
import get from 'lodash/get';
import { LinkColumn } from '../hospitals/columns';
import { formatProviderId } from '../../../util/providerId';

export const nameColumn = {
  title: 'Name',
  lens: item => item.name,
  sortFunction: (l, r) => sortString(l.name, r.name),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const emailColumn = {
  title: 'Email',
  lens: item => item.email,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const hstIdColumn = {
  title: 'Provider Id',
  lens: item => item.hstId,
  sortFunction: (l, r) => sortNumber(l.hstId, r.hstId),
  Component: props => <TruncatedText>{formatProviderId(props.data)}</TruncatedText>,
};

export const smsNotificationColumn = {
  title: 'SMS Notification Numbers',
  lens: data =>
    data.notificationNumbers !== null && data.notificationNumbers !== undefined
      ? formatPhoneNumber(processPhoneNumbersString(data.notificationNumbers).nationalNumbers)
      : undefined,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const sendNotificationColumn = {
  title: 'Notify',
  lens: item => item.shouldSendNotification,
  Component: props => <TruncatedText>{props.data ? 'Yes' : 'No'}</TruncatedText>,
};

export const specialityColumn = {
  title: 'Speciality',
  lens: item => item.speciality,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const categoryColumn = {
  title: 'Category',
  lens: item => item.category,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const googleReviewColumn = {
  title: 'Google Review Integration',
  lens: item => get(item, 'reviewIntegrations.google', {}),
  Component: LinkColumn,
};

const yelpReviewColumn = {
  title: 'Yelp! Review Integration',
  span: 2,
  lens: item => get(item, 'reviewIntegrations.yelp', {}),
  Component: LinkColumn,
};

export const baseColumns = [
  nameColumn,
  hstIdColumn,
  smsNotificationColumn,
  specialityColumn,
  sendNotificationColumn,
  categoryColumn,
];

export const listColumns = [...baseColumns];

export const viewColumns = [
  specialityColumn,
  hstIdColumn,
  sendNotificationColumn,
  smsNotificationColumn,
  emailColumn,
  categoryColumn,
  googleReviewColumn,
  yelpReviewColumn,
];
