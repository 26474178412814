import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { IconButton } from '@material-ui/core';
import React, { FC } from 'react';

const StaffShiftMenuButton: FC<{ onClick: (e: React.MouseEvent<HTMLElement>) => void }> = ({ onClick }) => (
  <IconButton style={{ padding: 0 }} size="small" color="primary" onClick={onClick}>
    <PersonAddIcon />
  </IconButton>
);

export default StaffShiftMenuButton;
