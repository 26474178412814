import { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { HolidayCalendar as HolidayCalendarType } from '../../../../types/HolidayCalendar';
import { createHoliday, deleteHoliday, listHoliday, updateHoliday } from '../../../../graph/holiday';
import { useState } from 'react';
import { DateString } from '../util/groupStaffAvailability';
import useDebouncedEffect from '../../../../hooks/useDebouncedEffect';
import { format } from 'date-fns';
import GroupedByDate from '../types/GroupedByDate';

const useHolidayCalendar = (from: string, to: string) => {
  const { data } = useQuery<{ listHolidayCalendar: HolidayCalendarType[] }, { from: string; to: string }>(listHoliday, {
    variables: {
      from,
      to,
    },
  });

  const [holidays, setHolidays] = useState<HolidayCalendarType<DateString>[]>([]);
  useDebouncedEffect(
    () => {
      if (data?.listHolidayCalendar) {
        setHolidays(
          data?.listHolidayCalendar.map(({ id, date, description }) => ({
            id,
            date: format(date, 'YYYYMMDD'),
            description,
          }))
        );
      }
    },
    350,
    [data?.listHolidayCalendar]
  );

  const holidaysPerDate = useMemo<GroupedByDate<HolidayCalendarType<DateString>>>(
    () =>
      holidays.reduce(
        (groups, holiday) => groups.add(holiday.date, holiday),
        GroupedByDate.empty<HolidayCalendarType<DateString>>()
      ),
    [holidays]
  );

  const [doCreateHoliday] = useMutation<
    any,
    {
      dates: string[];
      description?: string;
      from?: string;
      to?: string;
    }
  >(createHoliday, {
    refetchQueries: [
      {
        query: listHoliday,
        variables: {
          from,
          to,
        },
      },
    ],
  });

  const [doRemoveHoliday] = useMutation<
    any,
    {
      eventIds: number[];
    }
  >(deleteHoliday, {
    refetchQueries: [
      {
        query: listHoliday,
        variables: {
          from,
          to,
        },
      },
    ],
  });

  const [doUpdateHoliday] = useMutation<
    any,
    {
      eventIds: number[];
      description: string;
    }
  >(updateHoliday, {
    refetchQueries: [
      {
        query: listHoliday,
        variables: {
          from,
          to,
        },
      },
    ],
  });

  return {
    holidays,
    holidaysPerDate,
    doCreateHoliday,
    doRemoveHoliday,
    doUpdateHoliday,
  };
};

export default useHolidayCalendar;
