import React, { useState } from 'react';
import { PieChart, Pie, Sector, Cell } from 'recharts';
import withSize from '../../../hocs/withSize';
import { roundValue } from '../Analytics';
import { GraphContainer } from './MultiBarChart';

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 1;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  return (
    <g>
      <text x={cx} y={cy} dy={0} textAnchor="middle" fill={payload.legendColor || fill}>
        {payload.name}
      </text>
      <text x={cx} y={cy} dy={18} textAnchor="middle" fill={'white'}>
        {`${roundValue(value)}%`}
      </text>

      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="white">
        {`${(Math.round(value * 100) / 100).toFixed(1)}%`}
      </text>
      {/*<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">*/}
      {/*  {name}*/}
      {/*</text>*/}
    </g>
  );
};

const PieLayout = ({ data, colors, width, height, ratio }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = (val, i) => {
    setActiveIndex(i);
  };

  const radius = width / 3.5;
  return (
    <GraphContainer>
      <PieChart width={width} height={height || Math.floor(width * (ratio || 0.75))}>
        {/*<ResponsiveContainer width={'100%'} height={'100%'}>*/}
        {/*  <PieChart style={{ margin: 'auto', width: '100%' }}>*/}
        <Pie
          onMouseEnter={onPieEnter}
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          innerRadius={radius * 0.8}
          outerRadius={radius}
          fill="#8884d8"
          paddingAngle={2}
          dataKey="value"
          stroke={'#263778'}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </PieChart>
    </GraphContainer>
  );
};

export default withSize(PieLayout);
