import React from 'react';
import styled from 'styled-components';
import { H3, TruncatedText } from '../../se/components/typography';
import responsive from '../../se/utilities/responsive';
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  fade,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { formatRelative } from 'date-fns-3/formatRelative';
import { Link as RouterLink } from 'react-router-dom';
import {
  ANALYTICS,
  CHARTING_TABLET,
  EXIT_DISPLAY,
  INTERNAL_MONITOR,
  INTERNAL_MONITOR_LABELS,
  OPERATION_ROOM_MONITOR,
  PACU_TABLET,
  POST_OP_TABLET,
  PREP_TABLET,
  REVIEW_DISPLAY,
  SCHEDULE_MONITOR,
  WAITING_ROOM_MONITOR,
  WAITING_ROOM_TABLET,
} from '../entities/screens/enums';
import { ROOM_TYPE_LABELS } from '../entities/room/enums';
import { getNestedValue } from '../../se/utilities/data/object';
import { useTheme } from '@material-ui/core/styles';
import useSystemWideRecentlyInactiveGateways from './useSystemWideRecentlyInactiveGateways';

export const Title = styled(H3)`
  margin: 0;
  ${responsive.md.andSmaller`
    font-size: 1rem;
  `}
`;

interface GatewaysProps extends ReturnType<typeof useSystemWideRecentlyInactiveGateways> {}

const Gateways = ({ loading, data, error }: GatewaysProps) => {
  const theme = useTheme();

  return (
    <Box>
      <Box mb={2}>
        <Breadcrumbs>
          <Link component={RouterLink} to="/dashboard" color="inherit">
            Dashboard
          </Link>
          <Typography color="textPrimary">Gateways</Typography>
        </Breadcrumbs>
      </Box>
      <Paper>
        <Box p={2}>
          <Title>Gateways</Title>
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <pre>{JSON.stringify(error)}</pre>
          ) : (
            <TableContainer>
              <Table>
                <TableBody>
                  {data!.systemWideRecentlyInactiveGateways.map(g => (
                    <TableRow key={g.id}>
                      <TableCell component="th" scope="row">
                        <Link component={RouterLink} to={`/su/${g.hospital.id}/gateways`}>
                          {g.hospital.name}
                        </Link>
                      </TableCell>
                      <TableCell>{g.id}</TableCell>
                      <TableCell align="right">since {formatRelative(new Date(g.lastSeenAt), new Date())}</TableCell>
                      <TableCell>
                        {g.type}
                        {g.room ? <span> • {g.room.name}</span> : null}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default Gateways;
