import { withProps } from 'recompose';

import ObjectInput from './ObjectInput';
import EmailInput from './EmailInput';
import PasswordInput from './PasswordInput';

export default withProps({
  schema: {
    email: EmailInput,
    password: PasswordInput,
  },
})(ObjectInput);
