import React, { Fragment } from 'react';
import TextField from './TextField';
import get from 'lodash/get';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import renderNode from './renderNode';
import { ifArrayGetSecond } from './CheckboxesField';

export const parseSubField = (value, field) => {
  if (isArray(value)) {
    return ifArrayGetSecond(find(get(field, 'options', []), x => (isArray(x) ? x[0] === value[0] : false)));
  }
  return null;
};

export default ({ label, question, value, ...field }) => {
  const response = isArray(value) ? get(value[1], 'option') : get(value, 'option');
  const subField = parseSubField(response, field);

  if (subField) {
    return (
      <Fragment>
        <div>
          <TextField label={label || question} value={response[0]} />
          {renderNode(subField, { value: ifArrayGetSecond(response) })}
        </div>
      </Fragment>
    );
  } else {
    return <TextField label={label || question} value={response} />;
  }
};
