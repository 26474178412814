import { Box, darken } from '@material-ui/core';
import React from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';

interface CardAnesthesiologistNoteProps {
  note: string;
}

const CardAnesthesiologistNote = ({ note }: CardAnesthesiologistNoteProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <span>{note}</span>
    </Box>
  );
};

export const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '0.75em',
    padding: '0.125em 0.25em',
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: 1.2,
    backgroundColor: alpha(theme.palette.warning.main, 0.15),
    color: darken(theme.palette.warning.main, 0.85),
  },
}));

export default CardAnesthesiologistNote;
