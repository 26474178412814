import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import TextInput from '../../../se/components/inputs/TextInput';
import Button from '../../../se/components/Button';
import LinkButton from '../../../se/components/LinkButton';
import ListInput from './ListInput';
import { CheckCircle, Close } from '@material-ui/icons';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;
  > input {
    display: flex;
    flex: 4;
  }
  > button {
    display: flex;
    z-index: 9999;
  }
`;

const Input = ({ onRemove, ...rest }) => (
  <Row>
    <TextInput {...rest} />
    <Button onClick={onRemove}>
      <Close fontSize={'small'} />
    </Button>
  </Row>
);

const BraceletInput = ListInput(undefined, 'bracelet', Input, true, {});

class BraceletEntry extends Component {
  render() {
    return (
      <Fragment>
        <BraceletInput
          items={this.props.bracelets}
          onAdd={this.props.onAdd}
          onRemove={this.props.onRemove}
          onChange={this.props.onChange}
        />
        <LinkButton onClick={this.props.closeModal} style={{ marginTop: '0.5em' }}>
          <CheckCircle />
          <span style={{ marginLeft: '0.25em' }}>Finish Editing</span>
        </LinkButton>
      </Fragment>
    );
  }
}

export default BraceletEntry;
