import { alpha } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import { Icon } from '../SchedulePage';

const Time = ({ startTime, endTime, duration, late }) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.wrapper, {
        [classes.hasError]: !startTime,
      })}
    >
      {startTime ? (
        <Fragment>
          <div>{startTime}</div>
          <Box
            className={clsx(classes.endTime, {
              [classes.duration]: duration,
              [classes.delay]: late,
            })}
            delay={late}
            duration={duration}
          >
            {duration ? (
              <Box display="flex" flexDirection="column">
                {endTime}
              </Box>
            ) : (
              <Icon style={{ color: '#FF8900', marginTop: '.25em', opacity: 1 }}>error_outline</Icon>
            )}
          </Box>
        </Fragment>
      ) : (
        <Fragment>
          <Icon style={{ color: '#FF8900' }}>error_outline</Icon>
          <Typography className={classes.errorLabel}>Time missing</Typography>
        </Fragment>
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: alpha(theme.palette.text.primary, 0.1),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1.333333em',
    color: theme.palette.text.primary,
    marginRight: 1,
    padding: '0.2em 0.25em',
  },
  hasError: {
    backgroundColor: alpha(theme.palette.error.main, 0.25),
    lineHeight: '.65',
  },
  endTime: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1em',
    fontWeight: '400',
    color: theme.palette.text.primary,
  },
  duration: {
    opacity: '0.5',
    fontSize: '0.75em',
    marginTop: '0.25em',
  },
  delay: {
    margin: '0.125em 0',
  },
  errorLabel: {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
  },
}));

export default Time;
