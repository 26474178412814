import React, { ReactNode, useEffect, useState } from 'react';
import { Box, Collapse, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import isBoolean from 'lodash/isBoolean';
import { YesNoAnswer } from '../../types/Answer';
import { YesNoQuestion } from '../../types/Question';
import { ConditionContext, useSlideIn, YesNoQuestionChildren } from '../Form';
import { useSave } from '../../components/pages/kiosk/charting/SaveProvider';
import ReactHtmlParser from 'react-html-parser';

type YesNoInputProps = YesNoQuestion['props'] &
  YesNoQuestionChildren<ReactNode> & {
    name?: string;
    answer?: YesNoAnswer;
    onChange?: (newAnswer: YesNoAnswer) => void;
  };

const YesNoInput = ({ name, optional, label, answer, onChange, child, inline }: YesNoInputProps) => {
  const value = isBoolean(answer)
    ? answer
      ? 'Yes'
      : 'No'
    : isBoolean(answer?.value)
    ? answer?.value
      ? 'Yes'
      : 'No'
    : '';
  const booleanValue = isBoolean(answer) ? answer : isBoolean(answer?.value) ? answer?.value : undefined;

  const [invalid, setInvalid] = useState(false);

  const { validationRequest } = useSave();
  const slideIn = useSlideIn();

  useEffect(() => {
    if (optional) {
      return;
    }

    if (validationRequest) {
      if (answer === null || answer === undefined) {
        setInvalid(true);
        slideIn();
      }
    }
  }, [validationRequest, answer, optional, slideIn]);

  return (
    <Box>
      <FormControl fullWidth={true} error={invalid}>
        <div
          style={
            inline ? { display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 16 } : undefined
          }
        >
          <FormLabel>{ReactHtmlParser(label)}</FormLabel>
          <RadioGroup
            row
            aria-label={label}
            name={name}
            value={value}
            onChange={e => {
              onChange?.(e.target.value === 'Yes');
              setInvalid(false);
            }}
          >
            <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
            <FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
          </RadioGroup>
        </div>
      </FormControl>

      <Collapse in={isBoolean(booleanValue) && !!child}>
        <Box display="flex" pl={1.5}>
          <Box>
            <Divider style={{ width: 2 }} orientation="vertical" />
          </Box>
          <Box pl={4} flex={1}>
            <ConditionContext.Provider value={true}>{child}</ConditionContext.Provider>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default YesNoInput;
