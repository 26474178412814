const toHex = number => ('0' + Number(number).toString(16)).slice(-2).toUpperCase();

export const request = ({ signalStrength, beaconTimestamp, gatewayTimestamp, gatewayId, braceletId }) =>
  JSON.stringify([
    {
      gatewayFree: 91,
      timestamp: gatewayTimestamp.toISOString(),
      type: 'Gateway',
      gatewayLoad: 0.25,
      mac: `${gatewayId}`,
    },
    {
      ibeaconMinor: 0,
      ibeaconTxPower: -59,
      mac: `${braceletId}`,
      rssi: signalStrength,
      ibeaconUuid: 'E2C56DB5DFFB48D2B060D0F5A71096E0',
      rawData: `0201060303E1FF1016E1FFA108609B4D263F23AC504C5553`,
      type: 'Unknown',
      timestamp: beaconTimestamp.toISOString(),
      ibeaconMajor: 0,
      bleName: `MiniBeacon_${braceletId}`,
    },
  ]);

export const mockRequest = (gatewayId, beacons) => {
  const g = [
    {
      gatewayFree: 91,
      timestamp: new Date().toISOString(),
      type: 'Gateway',
      gatewayLoad: 0.25,
      mac: `${gatewayId}`,
    },
  ];
  const b = beacons.map(beacon => ({
    ibeaconMinor: 0,
    ibeaconTxPower: -59,
    mac: `${beacon.id}`,
    rssi: -Math.round(Math.random() * 10 + 20),
    ibeaconUuid: 'E2C56DB5DFFB48D2B060D0F5A71096E0',
    rawData: `0201060303E1FF1016E1FFA108${toHex(beacon.battery)}9B4D263F23AC504C5553`,
    type: 'Unknown',
    timestamp: new Date().toISOString(),
    ibeaconMajor: 0,
    bleName: `MiniBeacon_${beacon.id}`,
  }));

  return JSON.stringify([...g, ...b]);
};
