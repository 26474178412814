import { getHours, getMinutes } from 'date-fns';

export const convertDateForSlider = date => getHours(date) + getMinutes(date) / 60;

export const convertTimeForSlider = time =>
  time ? parseInt(time.split(':')[0], 10) + parseInt(time.split(':')[1]) / 60 : 0;

export const addDurationForSlider = (time, duration) =>
  time && duration ? convertTimeForSlider(time) + parseInt(duration, 10) / 60 : 0;

export const getScale = (begin, length) =>
  begin && length && [...Array(length).keys()].map((elem, index) => begin + index);
