export enum CovidScreeningStatus {
  Pass = 'Pass',
  CovidRisk = 'Covid Risk',
  NotFilled = 'Not Filled',
}

export enum Category {
  Patient = 'patient',
  Staff = 'staff',
  Other = 'other',
}

export enum OrganizationType {
  SurgeonOffice = 'SO',
  SurgeryCenter = 'SC',
}
