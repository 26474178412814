import React, {
  CSSProperties,
  forwardRef,
  LegacyRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import Table, { TableProps } from './Table';
import { Paper, TextField, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createTheme';
import { AnesthesiaTableAnswer, TableCreationInfo } from '../../types/Answer';
import TimeInputControl from '../../se/components/controls/TimeControl';
import { getEndTime } from '../../components/entities/schedule/util/time';
import {
  creationInfoForChartingSessionAndDate,
  formatTime,
  parseTime,
  useCurrentTimeInSeconds,
  useLatestValue,
} from './AnesthesiaVitals';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import isEqual from 'lodash/isEqual';
import { getCurrentTime, getDefaultEndTime } from './AnesthesiaChartV1';
import useClickAway from '../../hooks/useClickAway';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ScheduleIcon from '@material-ui/icons/Schedule';
import omit from 'lodash/omit';
import { defaultsDeep } from 'lodash';
import { rowsV2 } from './anesthesiaTableRowsV2';

import { useChartingSession } from '../../components/pages/kiosk/charting/modules/hooks';

interface AnesthesiaTableProps {
  firstColumn?: number;
  portrait?: boolean;
  startTime: string;
  onStartTimeChange?: (time: string) => void;
  endTime: string;
  onEndTimeChange?: (time: string) => void;
  answer?: AnesthesiaTableAnswer;
  onChange: (value: AnesthesiaTableAnswer, creationInfo?: TableCreationInfo) => void;
  onHorizontalScroll?: (offset: number) => void;
}

export type AnesthesiaTableCellData = string;
export type AnesthesiaTableRowValue = string;
export type AnesthesiaTableRow = { label: string; value: AnesthesiaTableRowValue; unit?: string };
export type AnesthesiaTableColumn = string;

type RowData = {
  row: AnesthesiaTableRow;
  value: string;
};

export type AnesthesiaColumnData = {
  column: string;
  values: RowData[];
};

interface InputProps {
  value: string;
  onChange: (v: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  style?: CSSProperties;
  darkerBackground?: boolean;
}

const Input = ({ value, onChange, onFocus, onBlur, className, style, darkerBackground }: InputProps) => {
  const rootRef = useRef<HTMLDivElement>();

  const [follow, setFollow] = useState<HTMLElement | undefined>();

  const handleFocus = () => {
    const root = rootRef.current;

    if (!root) {
      return;
    }

    onFocus?.();
    setFollow(root);
  };

  const handleBlur = () => {
    setFollow(undefined);
    onBlur?.();
  };

  return (
    <div
      ref={rootRef as any}
      style={{
        alignSelf: 'stretch',
        justifySelf: 'stretch',
        position: 'relative',
        width: '100%',
      }}
    >
      <Textarea
        follow={follow}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        darkerBackground={darkerBackground}
      />
    </div>
  );
};

interface TextareaProps {
  follow?: HTMLElement;
  value: string;
  onChange: (v: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  darkerBackground?: boolean;
}

const Textarea = ({ follow, value, onChange, onFocus, onBlur, darkerBackground }: TextareaProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>();
  const divRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const textarea = textareaRef.current;
    const div = divRef.current;

    if (!textarea || !div) {
      return;
    }

    if (follow) {
      let animationFrame: ReturnType<typeof requestAnimationFrame>;

      const animate = () => {
        const rect = follow.getBoundingClientRect();

        // textarea.style.setProperty('position', 'fixed');
        div.style.setProperty('position', 'fixed');
        textarea.style.removeProperty('inset');
        // textarea.style.setProperty('top', rect.top + 'px');
        div.style.setProperty('top', rect.top + 'px');
        // textarea.style.setProperty('left', rect.left + 'px');
        div.style.setProperty('left', rect.left + 'px');
        textarea.style.setProperty('width', 'auto');
        textarea.style.setProperty('height', 'auto');
        textarea.style.setProperty('width', Math.max(textarea.scrollWidth, follow.clientWidth) + 'px');
        textarea.style.setProperty('height', Math.max(textarea.scrollHeight + 5, follow.clientHeight) + 'px');
        // textarea.style.setProperty('z-index', '10000');
        div.style.setProperty('z-index', '10000');

        animationFrame = requestAnimationFrame(animate);
      };

      animate();

      return () => {
        cancelAnimationFrame(animationFrame);
      };
    } else {
      textarea.style.setProperty('position', 'absolute');
      div.style.setProperty('position', 'static');
      // textarea.style.removeProperty('top');
      div.style.removeProperty('top');
      // textarea.style.removeProperty('left');
      div.style.removeProperty('left');
      textarea.style.removeProperty('width');
      textarea.style.removeProperty('height');
      textarea.style.setProperty('inset', '0');
      // textarea.style.removeProperty('z-index');
      div.style.removeProperty('z-index');
    }
  }, [follow]);

  const theme = useTheme();

  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
    onFocus?.();
  };

  const onClickAway = () => {
    setFocused(false);
    onBlur?.();
  };

  useClickAway(divRef, onClickAway);

  return (
    <div ref={divRef as any}>
      {follow && (
        <Paper elevation={8} style={{ position: 'absolute', bottom: 0 }}>
          <Box pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
              Quick select amount
            </Typography>
            <IconButton size="small" onClick={onClickAway}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box p={2} style={{ display: 'grid', gridTemplateColumns: 'repeat(4, max-content)', gap: '0.5em' }}>
            {['0.1', '0.25', '0.35', '0.5', '1', '2', '4', '8', '10', '25', '50', '100', '200', '500', '1000'].map(
              text => (
                <Button
                  key={text}
                  variant="outlined"
                  size="small"
                  style={{ zIndex: 10001, borderRadius: 4, paddingLeft: 6, paddingRight: 6 }}
                  onClick={e => {
                    e.stopPropagation();
                    onChange(`${value || ''}${value ? ',' : ''}${text}@${getCurrentTime()}`);
                  }}
                >
                  {text}
                </Button>
              )
            )}
            <Button
              variant="outlined"
              size="small"
              style={{ zIndex: 10001, borderRadius: 4, paddingLeft: 6, paddingRight: 6 }}
              onClick={e => {
                e.stopPropagation();
                onChange(`${value || ''} ${getCurrentTime()}`.trim().replace(/\s+/g, ' '));
              }}
            >
              <ScheduleIcon />
            </Button>
          </Box>
        </Paper>
      )}
      <textarea
        ref={textareaRef as Ref<HTMLTextAreaElement>}
        autoComplete="off"
        value={value}
        onChange={e => onChange(e.target.value)}
        onFocus={handleFocus}
        // onBlur={handleBlur}
        // className={className}
        style={{
          position: 'fixed',
          background: 'transparent',
          margin: focused ? -1 : 0,
          padding: focused ? 1 : 2,
          border: focused ? `2px solid ${theme.palette.primary.main}` : `none`,
          outline: 0,
          resize: 'none',
          backgroundColor: darkerBackground ? theme.palette.grey['50'] : 'white',
          // ...(style || {}),
        }}
        inputMode="numeric"
      />
    </div>
  );
};

export const toQuarter = (time?: string) =>
  time?.split(':')?.[0]
    ? `${time?.split(':')?.[0]}:${String(Math.floor((parseInt(time?.split(':')?.[1]) || 0) / 15) * 15).padStart(
        2,
        '0'
      )}`
    : null;

export const defaultColumns = (size: number = 13, time: string): AnesthesiaTableColumn[] =>
  Array(size)
    .fill(0)
    .map((_, index) => index)
    .map(i => getEndTime(toQuarter(time), i * 15))
    .filter(e => !!e) as AnesthesiaTableColumn[];

export const getColumns = (startTime: string, endTime: string): AnesthesiaTableColumn[] => {
  const startTimeInSeconds =
    15 * Math.floor(parseTime(toQuarter(startTime) || toQuarter(getCurrentTime()) || getCurrentTime()) / 60 / 15) * 60;
  const endTimeInSeconds =
    15 *
    Math.floor(parseTime(toQuarter(endTime) || toQuarter(getDefaultEndTime()) || getDefaultEndTime()) / 60 / 15) *
    60;
  const size =
    endTimeInSeconds - startTimeInSeconds > 0 ? 1 + Math.ceil((endTimeInSeconds - startTimeInSeconds) / 15 / 60) : 6;
  return Array(size)
    .fill(0)
    .map((_, index) => index)
    .map(i => getEndTime(toQuarter(startTime), i * 15))
    .filter(e => !!e) as AnesthesiaTableColumn[];
};

export interface AnesthesiaTableControls {
  scrollTo: (offset: number) => void;
}

const AnesthesiaTableV2 = forwardRef<AnesthesiaTableControls, AnesthesiaTableProps>(
  (
    {
      firstColumn,
      portrait,
      startTime,
      onStartTimeChange,
      endTime,
      onEndTimeChange,
      answer,
      onChange,
      onHorizontalScroll,
    },
    ref
  ) => {
    const theme = useTheme();

    const answerRef = useLatestValue(answer);

    const rows: AnesthesiaTableRow[] = rowsV2;

    const columns: string[] = useMemo(() => getColumns(startTime || getCurrentTime(), endTime), [startTime, endTime]);

    const classes = useStyles({
      firstColumn,
      rows: rows.length,
      columns: columns.length,
    });

    const chartingSession = useChartingSession();

    const onFieldChange = (row: number, column: number, text: string) => {
      const answer = answerRef?.current;
      onChange(
        defaultsDeep(
          {
            [rows?.[row]?.value]: defaultsDeep({ [columns[column]]: text }, answer?.[rows?.[row]?.value] || {}),
          },
          answer || {}
        ),
        {
          ...creationInfoForChartingSessionAndDate(chartingSession, new Date()),
          item: rows?.[row]?.value,
          time: columns[column],
          value: text,
        }
      );
    };

    const currentTimeInSeconds = useCurrentTimeInSeconds();
    const timeInSeconds = parseTime(startTime ?? formatTime(currentTimeInSeconds));
    const startTimeTemp = 15 * Math.floor(timeInSeconds / 60 / 15) * 60;
    const currentColumn = 2 + Math.floor((currentTimeInSeconds - startTimeTemp) / 60 / 15);
    const currentOffset = ((currentTimeInSeconds - startTimeTemp) % (60 * 15)) / 60 / 15;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleOptionToggle = (option: AnesthesiaTableRow, checked: boolean) => {
      const answer = answerRef?.current;
      if (checked) {
        onChange(defaultsDeep({ [option.value]: {} }, answer || {}));
      } else {
        onChange(omit(answer, option?.value));
      }
    };

    const isSelected = option => !!rows.find(e => e.value === option.value);

    const [focus, setFocus] = useState<{ row: number; column: number } | null>(null);

    const tableRef = useRef<HTMLElement>();

    useEffect(() => {
      if (!onHorizontalScroll) {
        return;
      }

      const table = tableRef.current;

      if (!table) {
        return;
      }

      const container = table.querySelector('div');

      if (!container) {
        return;
      }

      const handleScroll = () => {
        console.log('AnesthesiaTable scroll', container.scrollLeft);
        onHorizontalScroll(container.scrollLeft);
      };

      container.addEventListener('scroll', handleScroll);

      return () => container.removeEventListener('scroll', handleScroll);
    }, [onHorizontalScroll]);

    useImperativeHandle(ref, () => ({
      scrollTo: offset => {
        const table = tableRef.current;

        if (!table) {
          return;
        }

        const container = table.querySelector('div');

        if (!container) {
          return;
        }

        container.scrollTo({
          left: offset,
        });
      },
    }));

    return (
      <>
        {!portrait && (
          <div
            className={classes.header}
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <TimeInputControl
              Input={TextField}
              label={'Entered OR'}
              variant={'filled'}
              name="time"
              value={startTime || ''}
              onChange={t => onStartTimeChange?.(t)}
              style={{ width: '15em' }}
            />
          </div>
        )}
        <Table
          classes={classes}
          firstColumn={firstColumn}
          rows={rows.length}
          columns={columns.length}
          rootRef={tableRef as LegacyRef<HTMLElement>}
          renderTop={column => (
            <div
              style={{
                flex: 1,
                alignSelf: 'stretch',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                paddingLeft: '0.2em',
                background:
                  focus?.column === column ? '#C0C0C0' : column % 2 === 0 ? theme.palette.grey['50'] : undefined,
              }}
            >
              {columns[column]}
            </div>
          )}
          renderLeft={row => (
            <div
              style={{
                flex: 1,
                alignSelf: 'stretch',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '.5em',
                background: focus?.row === row ? '#C0C0C0' : undefined,
              }}
            >
              <div>
                {rows?.[row]?.label} {rows?.[row]?.unit}
              </div>
            </div>
          )}
          renderCell={(row, column) => (
            <Input
              value={answer?.[rows[row]?.value]?.[columns?.[column]] || ''}
              onChange={text => onFieldChange(row, column, text)}
              onFocus={() => {
                setFocus({ row, column });
              }}
              onBlur={() => setFocus(prev => (isEqual(prev, { row, column }) ? null : prev))}
              darkerBackground={column % 2 === 0}
            />
          )}
        >
          <div
            style={{
              gridRow: `1 / span ${rows.length + 1}`,
              gridColumn: `${currentColumn} / span 1`,
              width: 2,
              zIndex: 20,
              position: 'relative',
              left: currentOffset * 100 + '%',
              background: 'rgba(0 0 255 / 0.2)',
            }}
          />
        </Table>
      </>
    );
  }
);

const useStyles = makeStyles<DefaultTheme, Pick<TableProps, 'firstColumn' | 'rows' | 'columns'>>(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '1em',
    margin: '1em 0',
    height: '60px',
  },
  grid: {
    display: 'grid',
    gridTemplateRows: ({ rows }) => `2em repeat(${rows}, 2em)`,
    gridTemplateColumns: ({ firstColumn, columns }) =>
      `${firstColumn ? `${firstColumn}px` : '15em'} repeat(${columns}, 6em)`,
    margin: 0,
  },
  container: {
    alignItems: 'start',
  },
}));

export default AnesthesiaTableV2;
