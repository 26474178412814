import React, { Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link, matchPath } from 'react-router-dom';
import styled from 'styled-components';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { withTheme } from '../theme';
import responsive from '../utilities/responsive';
import { withRouter } from 'react-router';
import { compose, withState } from 'recompose';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import Icon from '@material-ui/core/Icon';
import { ArrowBack, ExpandLess, ExpandMore } from '@material-ui/icons';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Hidden } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const drawerWidth = 280;

const useStyles = makeStyles(theme => ({
  listItem: {
    color: 'inherit',
  },
  nested: {
    paddingLeft: theme.spacing(4),
    color: 'inherit',
  },
  menuButton: {
    marginRight: 36,
    zIndex: 1000000,
  },
  hide: {
    opacity: 0,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  header: {
    opacity: 1,
    transition: 'opacity .3s ease',
  },
  hidden: {
    opacity: 0,
  },
  prominent: {
    color: theme.palette.primary.main,
  },
  prominentIcon: {
    opacity: 1,
  },
}));

const HeaderContainer = styled.div`
  display: flex;
  flex-flow: column;
  padding: calc(2 * ${withTheme(theme => theme.defaultSpacing)});
  justify-content: center;
  font-weight: 500;
  font-size: 1.75rem;
  ${responsive.md.andSmaller`
    padding-top: 4.5rem;
  `};
`;

export const Header = ({ children }) => <HeaderContainer>{children}</HeaderContainer>;

const Sidebar = ({
  location,
  highlight,
  setHighlight,
  Header,
  Footer,
  links,
  match,
  classNames,
  styles,
  toggleMenu,
  setToggleMenu,
  separatorColor,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMd = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });
  const [expanded, setExpanded] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(!isMd);

  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
    setExpanded(null);
  };

  const handleClick = label => {
    expanded === label ? setExpanded(null) : setExpanded(label);
  };

  const isSuperAdmin = match && match.path === '/su/:organizationId';

  return (
    <aside>
      <Drawer
        variant="permanent"
        classes={{
          root: clsx(classes.drawer, {
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          }),
          paper: clsx({
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          }),
        }}
      >
        {isSuperAdmin && (
          <Hidden mdUp>
            <List>
              <ListItem component={Link} to={'/'} className={classes.prominent}>
                <ListItemIcon className={classes.prominentIcon}>
                  <ArrowBack fontSize={'small'} color={'primary'} />
                </ListItemIcon>
                <ListItemText>Back to superadmin</ListItemText>
              </ListItem>
            </List>
            <Divider />
          </Hidden>
        )}
        <Box pt={isSuperAdmin && !isMd ? 6 : 0}>
          {Header && <Header compressed={!drawerOpen} location={location} {...props} />}
        </Box>
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerOpen} aria-label={drawerOpen ? 'Collapse sidebar' : 'Expand sidebar'}>
            {drawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        {links && (
          <List component="nav" aria-label="main navigation" key="links">
            {links.map(
              (
                {
                  to,
                  exact,
                  externalLink,
                  queryParams,
                  label,
                  subPages,
                  icon,
                  target,
                  separator,
                  hideOnMobile,
                  NotificationComponent,
                },
                index
              ) => (
                <Fragment key={to || index}>
                  {separator ? (
                    <Divider />
                  ) : to || externalLink ? (
                    <Fragment>
                      {!hideOnMobile && to && (
                        <ListItem
                          className={classes.listItem}
                          component={Link}
                          to={{
                            pathname: to,
                            search: queryParams || location.search,
                          }}
                          selected={
                            (!!matchPath(location.pathname, {
                              path: to,
                              exact: exact || false,
                              strict: false,
                            }) &&
                              true) ||
                            undefined
                          }
                          target={target}
                        >
                          <ListItemIcon>
                            <Icon>{icon}</Icon>
                          </ListItemIcon>
                          <ListItemText>
                            {label} {!!NotificationComponent && <NotificationComponent />}
                          </ListItemText>
                        </ListItem>
                      )}
                      {!hideOnMobile && externalLink && (
                        <a
                          href={externalLink}
                          target={'_blank'}
                          rel="noreferrer"
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          <ListItem className={classes.listItem}>
                            <ListItemIcon>
                              <Icon>{icon}</Icon>
                            </ListItemIcon>
                            <ListItemText>
                              {label} {!!NotificationComponent && <NotificationComponent />}
                            </ListItemText>
                          </ListItem>
                        </a>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <ListItem button onClick={() => handleClick(label)}>
                        <ListItemIcon>
                          <Icon>{icon}</Icon>
                        </ListItemIcon>
                        <ListItemText>
                          {label} {!expanded && !!NotificationComponent && <NotificationComponent />}
                        </ListItemText>
                        {expanded === label ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                    </Fragment>
                  )}
                  {subPages && (
                    <Collapse in={expanded === label} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {subPages.map(({ to, icon, label, hideOnMobile, target, NotificationComponent }) => (
                          <Fragment key={to}>
                            {!hideOnMobile && (
                              <ListItem
                                component={Link}
                                target={target}
                                // hideOnMobile={hideOnMobile}
                                to={to}
                                icon={icon}
                                selected={
                                  (!!matchPath(location.pathname, {
                                    path: to,
                                    exact: true,
                                    strict: false,
                                  }) &&
                                    'true') ||
                                  undefined
                                }
                                className={classes.nested}
                              >
                                <ListItemIcon>
                                  <Icon>{icon}</Icon>
                                </ListItemIcon>
                                <ListItemText>
                                  {label} {!!NotificationComponent && <NotificationComponent />}
                                </ListItemText>
                              </ListItem>
                            )}
                          </Fragment>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </Fragment>
              )
            )}
          </List>
        )}
        {Footer && (
          <Box mt="auto">
            <Footer sidebarOpen={drawerOpen} {...props} />
          </Box>
        )}
      </Drawer>
    </aside>
  );
};

export default compose(
  withRouter,
  withState('highlight', 'setHighlight'),
  withState('toggleMenu', 'setToggleMenu', false)
)(Sidebar);
