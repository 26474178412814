import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createGlobalStyle } from 'styled-components';
import { Helmet } from 'react-helmet';

import { CenteredSpinner } from './Spinner';
import EntityErrorState from './entity-states/EntityErrorState';
import { getVerificationError, isReady, withSession } from '../../state/Session';

const ResetCss = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
     v2.0 | 20110126
     License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
`;

const GlobalCss = createGlobalStyle`
  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  body {
  }

  * {
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: inherit;
  }

  #root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  #select-menu {
    position: relative;
    z-index: 1203;
  }
`;

export const Application = ({
  title,
  Loading,
  Error,
  sessionIsReady,
  sessionVerificationError,
  resetCss,
  children,
}) => (
  <Fragment>
    {title ? <Helmet titleTemplate={`%s — ${title}`} defaultTitle={title} /> : null}
    {!sessionIsReady && <Loading size={'5rem'} />}

    {sessionIsReady && children}

    {!sessionIsReady && sessionVerificationError && <Error />}
    {resetCss ? (
      <Fragment>
        <ResetCss />
        <GlobalCss />
      </Fragment>
    ) : null}
  </Fragment>
);

Application.defaultProps = {
  resetCss: true,
  Loading: CenteredSpinner,
  Error: EntityErrorState,
};

Application.propTypes = {
  resetCss: PropTypes.bool.isRequired,
  Loading: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  title: PropTypes.string,
};

export default compose(
  withRouter,
  withSession(({ session }) => ({
    sessionIsReady: isReady(session),
    sessionVerificationError: getVerificationError(session),
  }))
)(Application);
