import React, { Fragment } from 'react';
import { format } from 'date-fns';

import AlertBar from './AlertBar';
import { useTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { withSession } from '../../state/Session';

export const UnauthorizedIndicator = withSession(s => ({ logOut: s.destroy }))(({ logOut }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: 'black',
        zIndex: 90000,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <p style={{ fontSize: '150%', fontWeight: 'bold', marginBottom: theme.spacing(1) }}>
        Your IP address is not authorized to access.
      </p>
      <Button variant="contained" color="primary" onClick={logOut}>
        Log out
      </Button>
    </div>
  );
});
