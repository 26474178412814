import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import set from 'lodash/fp/set';
import isArray from 'lodash/fp/isArray';

import FormError, { ErrorMessageWrapper } from '../errors/FormError';
import { isDefinedAndNotNull, isFunction } from '../../utilities/check';
import { excludeFromMap } from '../../utilities/data/object';

const generateKeyName = (keyName, name) => `${keyName ? `${keyName}.` : ''}${name}`;

const generateUniqueName = (formId, name, keyName) => `${formId}.${keyName ? `${keyName}.` : ''}${name}`;

export const generateInputProps = (name, Component, index, props, valueRef) => {
  const {
    formId,
    keyName,
    flatten,
    autoFocus,
    rootValue,
    value,
    initialValue,
    onChange,
    onKeyDown,
    disabled,
    error,
    label,
    ...rest
  } = props;

  const hasError = !!(error && isDefinedAndNotNull(error[name]));
  const inputValue = { ...initialValue, ...value };
  const uniqueName = generateUniqueName(formId, name, keyName);

  const handleKeyDown = (event, keyNameValue) => {
    if (isFunction(onKeyDown)) {
      onKeyDown(event, keyNameValue || generateKeyName(keyName, name), inputValue[name]);
    }
  };

  const handleChange = value =>
    flatten[name] ? onChange({ ...valueRef.current, ...value }) : onChange({ ...valueRef.current, [name]: value });

  return {
    formId,
    valueKey: generateKeyName(keyName, name),
    name: uniqueName,
    key: uniqueName,
    autoFocus: !keyName && index === 0 && autoFocus,
    rootValue,
    value: flatten[name] ? inputValue : inputValue[name],
    onChange: handleChange,
    isDisabled: disabled,
    hasError,
    error: hasError ? error[name] : undefined,
    onKeyDown: isFunction(handleKeyDown) ? handleKeyDown : undefined,
    ...excludeFromMap(rest, ['name']),
  };
};

const ObjectInput = props => {
  const { formId, schema, error } = props;
  const hasError = !!(error && error['']);

  const entries = isArray(schema) ? schema : Object.entries(schema);

  const { initialValue, value } = props;

  const valueRef = useRef({ ...initialValue, ...value });

  useEffect(() => {
    valueRef.current = { ...initialValue, ...value };
  }, [initialValue, value]);

  return props.render ? (
    props.render(
      entries.reduce((acc, [name, Component], i) => {
        const inputProps = generateInputProps(name, Component, i, props, valueRef);
        return set(name, <Component {...inputProps} />)(acc);
      }, {})
    )
  ) : (
    <Fragment>
      <FormError id={`form-${formId}`} isVisible={hasError}>
        {hasError ? <ErrorMessageWrapper>{error['']}</ErrorMessageWrapper> : ''}
      </FormError>
      {entries.map(([name, Component], i) => {
        if (Component) {
          const inputProps = generateInputProps(name, Component, i, props, valueRef);
          return <Component {...inputProps} />;
        } else {
          return undefined;
        }
      })}
    </Fragment>
  );
};

ObjectInput.defaultProps = {
  flatten: {},
};

ObjectInput.propTypes = {
  formId: PropTypes.string.isRequired,
  schema: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  autoFocus: PropTypes.bool,
  value: PropTypes.any,
  initialValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  flatten: PropTypes.object,
};

export default ObjectInput;
