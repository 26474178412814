import React, { useEffect, useState } from 'react';
// import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { parse } from 'date-fns';
import { DateAnswer } from '../../types/Answer';
import { DateQuestion } from '../../types/Question';
import { Box } from '@material-ui/core';

type DateInputProps = DateQuestion['props'] & {
  answer?: DateAnswer;
  onChange?: (newAnswer: DateAnswer) => void;
};

// TODO Jelena: check this
const DateInput = ({ label, answer, onChange }: DateInputProps) => {
  // TODO Jelena: check this
  const [date, setDate] = useState<Date | null>(answer ? parse(answer) : null);

  useEffect(() => {
    if (date === null && answer) {
      // TODO Jelena: check this
      answer && setDate(parse(answer));
    }
  }, [answer, date]);

  // const handleChange = useCallback(
  //   (newValue: Date | null) => {
  //     setDate(newValue);
  //     if (newValue && isValid(newValue) && isDate(newValue)) {
  //       onChange?.(format(newValue, 'MM/dd/yyyy'));
  //     }
  //   },
  //   [onChange]
  // );

  return (
    <Box my={2}>
      {/*<LocalizationProvider dateAdapter={AdapterDateFns}>*/}
      {/*  <DesktopDatePicker*/}
      {/*    label={label}*/}
      {/*    inputFormat="MM/dd/yyyy"*/}
      {/*    value={date}*/}
      {/*    onChange={(newValue) => handleChange(newValue)}*/}
      {/*    renderInput={(params: any) => <TextField variant="filled" fullWidth={true} {...params} />}*/}
      {/*   />*/}
      {/*</LocalizationProvider>*/}
    </Box>
  );
};

export default DateInput;
