import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { format } from 'date-fns';

const CurrentTime = () => {
  const [time, setTime] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return <Typography variant="h5">{format(time, 'HH:mm')}</Typography>;
};

export default CurrentTime;
