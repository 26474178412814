import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AdditionalInfoWrapper } from '../../../patient/PatientSlot/PatientSlotAdditionalInfo';
import { Box } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { PACU, PRE_OP } from '../../../entities/room/enums';
import { withRouter } from 'react-router';
import PatientInfo from '../../../patient/PatientSlot/PatientInfo';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  grid-row: header;
  padding: 2.25rem 2rem;
  overflow: hidden;
`;

const Back = styled.button`
  background: transparent;
  cursor: pointer;
  border: none;
  color: ${props => props.theme.linkColor.string()};
  border-right: 1px solid rgba(255, 255, 255, 0.25);
  padding-right: 1rem;

  i {
    font-size: 2.25rem;
  }
`;

const Primary = styled.div`
  font-size: 1.5em;
  color: white;
  margin: 0;
  line-height: 1.2;
  display: flex;
  align-items: baseline;
`;

const Secondary = styled(AdditionalInfoWrapper)`
  @media (max-height: 900px) {
    font-size: 1.125rem;
  }
`;

const PatientHeader = withRouter(({ roomType, patient, goBack, hasNoteModule }) => {
  const isPreOp = roomType === PRE_OP;
  const isPacu = roomType === PACU;

  return (
    <HeaderWrapper>
      <Back onClick={goBack}>
        <i className="material-icons">
          <ArrowBack fontSize={'large'} />
        </i>
      </Back>
      {patient ? (
        <PatientInfo
          patient={patient?.name}
          patientType={patient?.patientType}
          procedure={patient?.procedure}
          physician={patient?.physician}
          preOpNote={patient?.preOpNote}
          showPreOpNote={isPreOp && hasNoteModule}
          pacuNote={patient?.pacuNote}
          showPacuNote={isPacu && hasNoteModule}
          timeEntered={patient?.timeEntered}
          called={patient?.called}
        />
      ) : (
        <Box>
          <Primary>Patient Moved Out</Primary>
          <Secondary>Go back to select page</Secondary>
        </Box>
      )}
    </HeaderWrapper>
  );
});

export const PatientHeaderProps = {
  goBack: PropTypes.func.isRequired,
  patient: PropTypes.shape({
    name: PropTypes.string.isRequired,
    procedure: PropTypes.string,
    timeEntered: PropTypes.instanceOf(Date),
  }).isRequired,
};

PatientHeader.propTypes = PatientHeaderProps;

export default PatientHeader;
