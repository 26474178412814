import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ArrowIcon, ListItemLink } from '../../../../se/components/collection/mobile/ListItem';
import {
  Row,
  Column,
  Label,
  Value,
  SubtleValue,
  TwoItemSection,
  WrapValue,
} from '../../../../se/components/collection/mobile/shared';
import RouterLink from '../../../../se/components/RouterLink';

export const PatientTableSeparator = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0px;
  opacity: 0.7;
  padding: 0 1rem;
`;

export const PatientTableSeparatorText = styled.p`
  flex-basis: 180px;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 500;
`;

export const PatientTableSeparatorLine = styled.div`
  background: #fff;
  height: 1px;
  width: 100%;
`;

const PatientMobileListItem = ({
  history,
  location,
  columns,
  item,
  context,
  index: i,
  showUrlProvider,
  idProvider,
  onClick,
}) => {
  if (item.separator) {
    return (
      <PatientTableSeparator>
        <PatientTableSeparatorText>{item.label}</PatientTableSeparatorText>
        <PatientTableSeparatorLine />
      </PatientTableSeparator>
    );
  }
  const nameColumn = columns.find(_ => _.key === 'name');
  // const idColumn = columns.find(_ => _.key === 'patientId');
  const physicianColumn = columns.find(_ => _.key === 'physician');
  const procedureTypeColumn = columns.find(_ => _.key === 'procedureType');
  const braceletIdColumn = columns.find(_ => _.key === 'braceletId');
  const roomOrDateOfVisit = columns.find(_ => _.key === 'roomOrDateOfVisit');

  return (
    <Fragment>
      <ListItemLink
        as={showUrlProvider ? RouterLink : undefined}
        to={
          showUrlProvider
            ? {
                pathname: showUrlProvider(idProvider(item)),
                search: location.search,
              }
            : undefined
        }
        onClick={onClick}
      >
        <ArrowIcon />
        <Row style={{ marginBottom: '0.75rem' }}>
          <nameColumn.Component data={nameColumn.lens(item, context)} />
          {roomOrDateOfVisit && <SubtleValue>({roomOrDateOfVisit.lens(item, context)})</SubtleValue>}
        </Row>

        <TwoItemSection style={{ marginBottom: '0.5rem' }}>
          <Column>
            <Label>{physicianColumn.title}</Label>
            <Value>{physicianColumn.lens(item, context)}</Value>
          </Column>
        </TwoItemSection>
        <TwoItemSection style={{ marginBottom: '0.5rem' }}>
          <Column>
            <Label>{procedureTypeColumn.title}</Label>
            <WrapValue>{procedureTypeColumn.lens(item, context)}</WrapValue>
          </Column>
        </TwoItemSection>
        <TwoItemSection style={{ marginBottom: '0.5rem' }}>
          {braceletIdColumn && (
            <Column>
              <Label>{braceletIdColumn.title}</Label>
              <Value>
                <braceletIdColumn.Component data={braceletIdColumn.lens(item, context)} />
              </Value>
            </Column>
          )}
          {/*<Column>*/}
          {/*<Label>{idColumn.title}</Label>*/}
          {/*<Value>*/}
          {/*<idColumn.Component data={idColumn.lens(item, context)} />*/}
          {/*</Value>*/}
          {/*</Column>*/}
        </TwoItemSection>
      </ListItemLink>
    </Fragment>
  );
};

export default PatientMobileListItem;
