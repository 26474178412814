import React, { Component } from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';
import LinkButton from '../../../se/components/LinkButton';
import NumberInput from '../../../se/components/inputs/NumberInput';
import { withLabel } from '../../../se/components/Label';

import { mockRequest } from './mockRequest';
import Icon from '../../core/Icon';
import config from '../../../config';

const BatteryInput = compose(withLabel('Battery Level'))(NumberInput);

const Content = styled.div`
  padding: calc(2.5 * ${props => props.theme.defaultSpacing});
  width: 100%;
  code {
    padding: 2px;
    margin: 2px;
    border-radius: 4px;
    border: 1px gray solid;
    background: whitesmoke;
    color: crimson;
    font-size: 0.8em;
  }
`;

const P = styled.p`
  margin: 1em 0 1em 0;
`;

const BeaconList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const BeaconItem = styled.div`
  margin-bottom: 2em;
  padding: 1em;
  margin: 0.25em;
  background: rgba(255, 255, 255, 0.1);
  > p {
    font-weight: 700;
    margin-bottom: 1em;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  > button {
    margin: 0 0.25em;
  }
`;

class MinewTestUI extends Component {
  componentDidMount = () => {
    this.sync();
    this.interval = setInterval(this.sync, 4000);
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  state = {
    gateways: [
      'Gateway_TEST_ENROLLMENT',
      'Gateway_TEST_PRE-OP',
      'Gateway_TEST_OR_1',
      'Gateway_TEST_OR_2',
      'Gateway_TEST_Recovery',
      'Gateway_TEST_POST_OP',
    ],
    beacons: [
      { id: 'AC233F_BEACON_TEST_0001', gateway: undefined, battery: 100 },
      { id: 'AC233F_BEACON_TEST_0002', gateway: undefined, battery: 100 },
      { id: 'AC233F_BEACON_TEST_0003', gateway: undefined, battery: 100 },
    ],
  };

  changeLocation = (index, location) => {
    const { beacons } = this.state;
    beacons[index].gateway = location;
    this.setState({ beacons });
    this.sync();
  };

  updateBattery = (index, battery) => {
    const { beacons } = this.state;
    beacons[index].battery = battery;
    this.setState({ beacons });
    this.sync();
  };

  sync = () => {
    const organizationId = this.props.match.params['organizationId'];
    Promise.all(
      this.state.gateways.map(gateway =>
        fetch(`${config.apiURL}/minew?hospital=${organizationId}`, {
          method: 'POST',
          body: mockRequest(
            gateway,
            this.state.beacons.filter(b => b.gateway === gateway)
          ),
          headers: { 'Content-type': 'application/json' },
        })
      )
    );
  };

  render() {
    return (
      <Content>
        <div style={{ maxWidth: 920 }}>
          <P>
            This tool will initialize the following gateways: <code>Gateway_TEST_ENROLLMENT</code>,{' '}
            <code>Gateway_TEST_PRE-OP</code>, <code>Gateway_TEST_OR_1</code>, <code>Gateway_TEST_OR_2</code>, and{' '}
            <code>Gateway_TEST_PACU</code>.
          </P>
          <P>
            And three bracelets: <code>AC233F_Beacon_TEST_0001</code>, <code>AC233F_Beacon_TEST_0002</code>, and{' '}
            <code>AC233F_Beacon_TEST_0003</code>
          </P>
          <P>
            Assign the gateways to the desired rooms in the administrator section. Then use the buttons here to move the
            bracelets between gateways.
          </P>
          <P>The tool will ping the server every 4 seconds, or whenever you perform a manual action.</P>
          <P>
            {' '}
            <Icon style={{ margin: 0, fontSize: '1em', opacity: 1, color: 'orange' }} className="material-icons">
              warning
            </Icon>{' '}
            The tool is only active when this tab is open. Multiple people using the tool will result is strange
            behaviour.
          </P>
        </div>
        <BeaconList>
          {this.state.beacons.map((beacon, index) => (
            <BeaconItem key={beacon.id}>
              <p style={{ opacity: 0.7 }}>{beacon.id}</p>
              <p style={{ color: beacon.gateway ? 'inherit' : 'crimson' }}>{beacon.gateway || 'Out of range'}</p>

              <BatteryInput
                value={beacon.battery}
                name={`battery_${index}`}
                onChange={value => this.updateBattery(index, value)}
              />

              <ButtonGroup>
                <LinkButton onClick={() => this.changeLocation(index, 'Gateway_TEST_ENROLLMENT')}>
                  <Icon className="material-icons">router</Icon> Move to TEST_ENROLLMENT
                </LinkButton>
                <LinkButton onClick={() => this.changeLocation(index, 'Gateway_TEST_PRE-OP')}>
                  <Icon className="material-icons">assignment</Icon> Move to TEST_PRE-OP
                </LinkButton>
                <LinkButton onClick={() => this.changeLocation(index, 'Gateway_TEST_OR_1')}>
                  <Icon className="material-icons">local_hospital</Icon> Move to TEST_OR_1
                </LinkButton>
                <LinkButton onClick={() => this.changeLocation(index, 'Gateway_TEST_OR_2')}>
                  <Icon className="material-icons">local_hospital</Icon> Move to TEST_OR_2
                </LinkButton>
                <LinkButton onClick={() => this.changeLocation(index, 'Gateway_TEST_Recovery')}>
                  <Icon className="material-icons">hotel</Icon> Move to TEST_PACU
                </LinkButton>
                <LinkButton onClick={() => this.changeLocation(index, 'Gateway_TEST_POST_OP')}>
                  <Icon className="material-icons">hotel</Icon> Move to TEST_POST_OP
                </LinkButton>
                <LinkButton onClick={() => this.changeLocation(index, undefined)}>
                  <Icon className="material-icons">signal_wifi_off</Icon> Put out of range
                </LinkButton>
              </ButtonGroup>
            </BeaconItem>
          ))}
        </BeaconList>
      </Content>
    );
  }
}

export default MinewTestUI;
