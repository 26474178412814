import { toLocalTime } from '../components/pages/kiosk/schedule/staff/StaffMember';

enum ValueType {
  BP = 'BP',
  HR = 'HR',
  RESP = 'RESP',
  SaO2 = 'SaO2',
  TEMP = 'TEMP',
  Liquids = 'Liquids',
  Solids = 'Solids',
  BMI = 'BMI',
  BLOOD_GLUCOSE = 'BLOOD_GLUCOSE',
}

interface Config {
  name: string;
  type: ValueType;
  path: string;
  unit?: string;
  min?: string;
  max?: string;
}

const isOutOfRangeInt = (value?: string, min?: string, max?: string): boolean => {
  const v = parseInt(value || '', 10);
  const [minParsed, maxParsed] = [parseInt(min || '', 10), parseInt(max || '', 10)];

  const cond1 = !isNaN(minParsed) ? v < minParsed : false;
  const cond2 = !isNaN(maxParsed) ? v > maxParsed : false;
  return v ? cond1 || cond2 : false;
};

const isOutOfRangeFloat = (value?: string, min?: string, max?: string): boolean => {
  const v = parseFloat(value || '');
  const [minParsed, maxParsed] = [parseFloat(min || ''), parseFloat(max || '')];

  const cond1 = !isNaN(minParsed) ? v < minParsed : false;
  const cond2 = !isNaN(maxParsed) ? v > maxParsed : false;
  return v ? cond1 || cond2 : false;
};

const isOutOfRangeTime = (value?: string, min?: string, max?: string): boolean => {
  const params = [toLocalTime(value || ''), toLocalTime(min || ''), toLocalTime(max || '')];
  if (params.some(e => !e)) return false;

  return (
    (params[0] && params[1] ? params[0].isBefore(params[1]) : false) ||
    (params[0] && params[2] ? params[0]?.isAfter(params[2]) : false)
  );
};

export const isValueOutOfRange = (config: Config, value?: string): boolean => {
  if ([value, config?.max, config?.min].every(e => !e)) return false;

  switch (config.type) {
    case ValueType.BP: {
      const [sys, dia] = value ? value.split('/') : [];
      const [sysMin, diaMin] = config?.min ? config?.min?.split('/') : [];
      const [sysMax, diaMax] = config?.max ? config?.max?.split('/') : [];
      const isOutSys = isOutOfRangeInt(sys, sysMin, sysMax);
      const isOutDia = isOutOfRangeInt(dia, diaMin, diaMax);
      return isOutSys || isOutDia;
    }
    case ValueType.BMI:
    case ValueType.TEMP:
      return isOutOfRangeFloat(value, config?.min, config?.max);
    case ValueType.Liquids:
    case ValueType.Solids:
      return isOutOfRangeTime(value, config?.min, config?.max);
    default: {
      return isOutOfRangeInt(value, config?.min, config?.max);
    }
  }
};
