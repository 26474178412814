import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  root: {
    flex: 1,
    display: 'flex',
    minHeight: 0,
  },
  content: {
    position: 'relative',
    flexGrow: 1,
    minWidth: 0,
  },
});

export default ({ Sidebar, children, styles, ...props }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {Sidebar && <Sidebar {...props} />}
      <main className={classes.content}>{React.Children.only(children)}</main>
    </Box>
  );
};
