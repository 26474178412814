import React, { Fragment } from 'react';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import EntityView from '../../../se/components/entity/EntityView';

import scheme from '../../../graph/physicians';

import { listColumns, viewColumns } from './columns';
import PhysicianInput from '../../inputs/surgeonPhysician/PhysicianInput';
import PhysicianMobileListItem from '../physician/PhysicianMobileListItem';
import { PhysicianUploadButton } from '../../inputs/upload/UploadButton';
import Signature from './Signature';

const PhysicianAndProcedureTypesView = props => (
  <Fragment>
    <EntityView {...props} />
    <Signature {...props} />
  </Fragment>
);

export default GraphQLCRUD({
  entityName: 'Physician',
  List: {
    tableKey: 'Physicians',
    columns: listColumns,
    Actions: PhysicianUploadButton,
    MobileItemComponent: PhysicianMobileListItem,
    simpleFilter: true,
  },
  Show: {
    columns: viewColumns,
    View: PhysicianAndProcedureTypesView,
  },
  Create: {
    Input: PhysicianInput,
  },
  Edit: {
    Input: PhysicianInput,
  },
  scheme,
});
