import { Query } from '@apollo/client/react/components';
import React from 'react';
import { RouterlessModal } from '../../../se/components/Modal';
import get from 'lodash/get';
import Form from '../../../se/components/Form';
import { compose, withProps } from 'recompose';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import { withLabel } from '../../../se/components/Label';
import TextInput from '../../../se/components/inputs/TextInput';
import NumberInput from '../../../se/components/inputs/NumberInput';
import ToogleInputShow from '../../../se/components/inputs/ToogleInputShow';
import { useMutation } from '@apollo/client';
import { getSensorConfiguration, update } from '../../../graph/sensors';
import pick from 'lodash/pick';
import { sanitize } from '../../../se/components/GraphQLCRUD';
import { temperatureLabel } from './SensorList';
import { chain, omit } from 'lodash';
import { isDefinedAndNotNull } from '../../../se/utilities/check';
import { withSession } from '../../../state/Session';
import { unpackSessionObject } from '../../pages/unpackSessionObject';
const InlineObjectInput = props => (
  <div style={{ display: 'flex' }}>
    <ObjectInput {...props} />
  </div>
);

const Label = ({ value }) => {
  const style = value ? { marginRight: '2em', marginLeft: '-3em' } : null;
  return <label style={{ margin: 'auto', fontSize: '1.2em', opacity: 0.8, ...style }}>{value}</label>;
};

const TemperatureInput = withProps({
  schema: {
    value: withProps({
      placeholder: 'e.g, 18',
      autoComplete: 'off',
      required: false,
      step: 0.1,
    })(NumberInput),
    unit: withProps(props => ({ ...props, value: temperatureLabel(props.value) }))(Label),
  },
})(InlineObjectInput);

const SensorConfigInput = withProps({
  schema: {
    location: compose(
      withLabel('Location'),
      withProps({
        placeholder: 'e.g., medication dispenser',
        autoComplete: 'off',
        required: false,
      })
    )(TextInput),
    temperatureMin: compose(withLabel('Temperature Min'))(TemperatureInput),
    temperatureMax: compose(withLabel('Temperature Max'))(TemperatureInput),
    humidityMin: compose(
      withLabel('Humidity Min'),
      withProps({
        placeholder: 'e.g, 45',
        autoComplete: 'off',
        required: false,
      })
    )(NumberInput),
    humidityMax: compose(
      withLabel('Humidity Max'),
      withProps({
        placeholder: 'e.g, 65',
        autoComplete: 'off',
        required: false,
      })
    )(NumberInput),
    enabled: compose(
          withSession(unpackSessionObject),
          withProps(props => ({
            autoComplete: 'off',
            required: false,
            label: 'Sensor Enabled',
            shouldShow: props.isSuperAdmin
          }))
        )(ToogleInputShow)
  },
})(ObjectInput);

const omitIfEmpty = (obj, path) => (isDefinedAndNotNull(get(obj, `${path}.value`)) ? obj : omit(obj, path));

export default ({ data, open, setOpen, id }) => {
  const [submit] = useMutation(update);

  const handleSubmit = async val => {
    try {
      await submit({
        variables: sanitize({
          id,
          ...pick(omitIfEmpty(omitIfEmpty(val, 'temperatureMin'), 'temperatureMax'), [
            'temperatureMin',
            'temperatureMax',
            'humidityMin',
            'humidityMax',
            'location',
            'enabled'
          ]),
        }),
      });
    } catch (e) {
    } finally {
      setOpen(false);
    }
  };

  return open ? (
    <Query query={getSensorConfiguration}>
      {({ data: config }) => (
        <RouterlessModal title={`${id} Sensor`} onClose={() => setOpen(false)}>
          <Form
            autoFocus
            initialValue={chain(data)
              .set('temperatureMin.unit', get(config, 'sensorConfiguration.temperatureUnit'))
              .set('temperatureMax.unit', get(config, 'sensorConfiguration.temperatureUnit'))
              .value()}
            input={SensorConfigInput}
            label={'Save'}
            onSubmit={handleSubmit}
            onCancel={() => setOpen(false)}
          />
        </RouterlessModal>
      )}
    </Query>
  ) : null;
};
