import React from 'react';
import styled from 'styled-components';
import PickerHeader from './PickerHeader';
import { useMutation } from '@apollo/client';
import { editProcedurePriority } from '../../../../graph/procedures';

const Content = styled.div`
  padding: 0 1em 1em;
  display: flex;
  flex-direction: column;
`;

const PrioritySlots = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  flex: 1;
`;

const PrioritySlotWrapper = styled.button`
  border: none;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  font-size: 4rem;
  border-radius: 0.25rem;

  &:active {
    background: #00b641;
  }

  &:disabled {
    opacity: 0.25;
  }
`;

const PrioritySlot = ({
  procedureId,
  priority,
  disabled,
  onClick,
}: {
  procedureId: number;
  priority: number;
  disabled: boolean;
  onClick: (priority: number, procedureId: number) => void;
}) => (
  <PrioritySlotWrapper disabled={disabled} onClick={() => !disabled && onClick(priority, procedureId)}>
    <span>{priority}</span>
  </PrioritySlotWrapper>
);

const PatientPriorityPicker = ({
  procedureId,
  assignedPriority,
  goToStartPage,
  onCancel,
}: {
  procedureId: number;
  assignedPriority?: number;
  goToStartPage: () => void;
  onCancel: () => void;
}) => {
  // Move the useMutation hook inside the component
  const [edit, { loading, error }] = useMutation(editProcedurePriority);

  const handleAssignPriority = async (priority: number, procedureId: number) => {
    try {
      await edit({
        variables: {
          id: procedureId,
          procedurePriority: priority,
        },
      });
    } catch (error) {
      console.error('Error assigning priority:', error);
    }
    goToStartPage();
  };

  return (
    <Content>
      <PickerHeader onCancel={onCancel} title={'Procedure Priority'} bedInput={undefined} />
      <PrioritySlots>
        {Array.from(Array(10).keys()).map((p: number) => (
          <PrioritySlot
            key={p}
            disabled={p + 1 === assignedPriority}
            priority={p + 1}
            procedureId={procedureId}
            onClick={handleAssignPriority}
          />
        ))}
      </PrioritySlots>
    </Content>
  );
};

export default PatientPriorityPicker;
