import React from 'react';
import { Box, TextField } from '@material-ui/core';
import { SelectAnswer } from '../../types/Answer';
import { SelectQuestion } from '../../types/Question';
import { Autocomplete } from '@material-ui/lab';
import ReactHtmlParser from 'react-html-parser';

type SelectInputProps = SelectQuestion['props'] & {
  name?: string;
  answer?: SelectAnswer;
  onChange?: (newAnswer: SelectAnswer) => void;
};

const AutocompleteInput = ({ name, label, options, answer, onChange }: SelectInputProps) => (
  <Autocomplete
    value={answer || null}
    onChange={(event, newValue) => {
      console.debug('auto', newValue);
      onChange?.(newValue);
    }}
    options={options}
    getOptionLabel={option => ReactHtmlParser(option.label)}
    fullWidth={true}
    style={{ width: 300 }}
    renderInput={params => (
      <TextField
        {...params}
        label={label ? ReactHtmlParser(label) : 'Select'}
        variant="filled"
        fullWidth={true}
        style={{ width: '100%' }}
      />
    )}
  />
);

export default AutocompleteInput;
