import React, { createContext, FC, useContext, useMemo } from 'react';
import StaffShiftT from '../../../../../types/StaffShift';
import StaffMember from './StaffMember';
import StaffSlot from './StaffSlot';
import StaffSlotMenu from './StaffSlotMenu';
import StaffEmptySlotMenu from './StaffEmptySlotMenu';
import { Procedure } from '../../../../../types/Procedure';
import { createStore, StoreApi } from 'zustand';

interface StaffShiftStore {
  editTime: boolean;
  editText: boolean;
}

export function useStaffShiftStore(): StoreApi<StaffShiftStore> {
  const context = useContext(StaffShiftStoreContext);

  if (context === null) {
    throw new Error('useStaffShiftStore must be used within a StaffShiftStoreContext');
  }

  return context;
}

export function useCreateStaffShiftStore(): StoreApi<StaffShiftStore> {
  return useMemo(
    () =>
      createStore<StaffShiftStore>(() => ({
        editTime: false,
        editText: false,
      })),
    []
  );
}

export const stubStaffShiftStore: StoreApi<StaffShiftStore> = {
  setState: () => {},
  getState: () => ({ editTime: false, editText: false }),
  subscribe: () => () => {},
  destroy: () => {},
};

export const StaffShiftStoreContext = createContext<StoreApi<StaffShiftStore> | null>(null);

interface StaffShiftProps extends StaffShiftT {
  procedure?: Procedure;
  isKiosk?: boolean;
  inlineText?: boolean;
  type: 'RoomShift' | 'ProcedureShift';
  editableStaff?: boolean;
}

const StaffShift: FC<StaffShiftProps> = ({
  id,
  staff,
  inlineText,
  openPosition,
  from,
  to,
  shiftState,
  additionalText,
  procedure,
  hourlyRate,
  isKiosk,
  type,
  editableStaff,
  ...rest
}) => {
  const store = useMemo(
    () =>
      createStore<StaffShiftStore>(() => ({
        editTime: false,
        editText: false,
      })),
    []
  );

  return (
    <StaffShiftStoreContext.Provider value={store}>
      {staff ? (
        isKiosk || !editableStaff ? (
          <StaffMember
            {...staff}
            inlineText={inlineText}
            procedure={procedure}
            editableStaff={editableStaff}
            shiftState={shiftState}
            from={from}
            to={to}
            additionalText={additionalText}
            shiftId={id}
            type={type}
            hideClock
            hideText
          />
        ) : (
          <StaffSlotMenu
            id={id}
            staff={staff}
            from={from}
            to={to}
            additionalText={additionalText}
            hourlyRate={hourlyRate}
            {...rest}
          >
            {onClick => (
              <StaffMember
                {...staff}
                inlineText={inlineText}
                procedure={procedure}
                editableStaff={editableStaff}
                onClick={onClick}
                shiftState={shiftState}
                from={from}
                to={to}
                additionalText={additionalText}
                shiftId={id}
                type={type}
                hideClock
                hideText
              />
            )}
          </StaffSlotMenu>
        )
      ) : isKiosk || !editableStaff ? (
        <StaffSlot id={id} from={from} to={to} openPosition={openPosition} />
      ) : (
        <StaffEmptySlotMenu
          id={id}
          from={from}
          to={to}
          shiftState={shiftState}
          additionalText={additionalText}
          openPosition={openPosition}
          procedure={procedure}
          hourlyRate={hourlyRate}
          {...rest}
        >
          {onClick => <StaffSlot id={id} from={from} to={to} onClick={onClick} openPosition={openPosition} />}
        </StaffEmptySlotMenu>
      )}
    </StaffShiftStoreContext.Provider>
  );
};

export default StaffShift;
