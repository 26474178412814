import React from 'react';
import { Popover, Typography, IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import { light } from '../../../../../theme';

const DayProceduresPopover = ({
  title,
  anchorElement,
  data,
  isOpen,
  onClose,
  canUseScheduleViewProcedure,
  setEditProcedureMonthly,
}) => {
  const classes = useStyles();

  return (
    <Popover
      id="mouse-over-popover"
      classes={{
        paper: classes.popover,
      }}
      open={isOpen}
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={onClose}
      disableScrollLock
    >
      <Box p={1} minWidth={250} maxWidth={400}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pl={1}>
          <Typography variant="h5">{title}</Typography>
          <IconButton
            onClick={e => {
              e.stopPropagation();
              onClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse' }}>
          <tbody>
            {data?.map(procedure => (
              <tr
                className={classes.tr}
                onClick={
                  canUseScheduleViewProcedure
                    ? e => {
                        e.stopPropagation();
                        setEditProcedureMonthly(procedure);
                        onClose();
                      }
                    : undefined
                }
              >
                <td className={classes.td}>
                  <Typography variant="body2">{get(procedure, 'startTimeText')}</Typography>
                </td>
                <td className={classes.td} style={{ maxWidth: '300px' }}>
                  <Typography component={'span'} variant="body2">
                    {get(procedure, 'procedureType')}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Popover>
  );
};

const useStyles = makeStyles(() => ({
  popover: {
    backgroundColor: light.popover.background.string(),
    boxShadow: 'none',
    maxWidth: 500,
  },
  td: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: '0 .5em',
  },
  tr: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '&:hover': {
      background: '#273a60',
    },
    transition: 'all 0.2s ease',
  },
}));

export default DayProceduresPopover;
