import React from 'react';
import styled from 'styled-components';
import { withTheme } from '../../../theme';
import { KeyboardArrowRight } from '@material-ui/icons';

export const ListItemLink = styled.div`
  background-color: ${props =>
    props.hover === 'true'
      ? withTheme(theme => theme.panel.background.darken(0.1).string())
      : withTheme(theme => theme.panel.background.string())};
  border-radius: ${withTheme(theme => theme.border.radius.default)};
  border: ${props => (props.highlighted ? '1px solid darkorange' : 'none')};
  transition: all 0.1s ease;
  padding: 1rem;
  margin-bottom: 1rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 41%;
  i {
    font-size: 2rem;
    color: ${p => p.theme.textColor.string()};
  }
`;

export const ArrowIcon = () => (
  <IconWrapper>
    <KeyboardArrowRight fontSize={'inherit'} />
  </IconWrapper>
);
