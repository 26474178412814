import React, { createRef, ReactNode, RefObject, useEffect, useState } from 'react';

interface ColumnsResolverProps {
  elements: number;
  onResolve: (columns: number) => void;
  children: (columns: number, protoRef: RefObject<HTMLElement>) => ReactNode;
}

const ColumnsResolver = ({ elements, onResolve, children }: ColumnsResolverProps) => {
  const [columns, setColumns] = useState<number | null | undefined>(undefined);

  const protoRef = createRef<HTMLElement>();

  useEffect(() => {
    if (columns === undefined) {
      return;
    }

    const containerHeight = protoRef.current?.parentElement?.offsetHeight ?? 0;
    const contentHeight = protoRef.current?.scrollHeight ?? 0;

    if (columns === null) {
      setColumns(1);
    } else if (contentHeight > containerHeight) {
      setColumns(c => (c ?? columns) + 1);
    } else {
      onResolve(columns);
    }
  }, [columns, onResolve, protoRef]);

  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize.width && windowSize.height) {
      setColumns(null);
    }
  }, [elements, windowSize.width, windowSize.height]);

  return <>{children(columns ?? 1, protoRef)}</>;
};

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<{ width?: number; height?: number }>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export default ColumnsResolver;
