import { createContext, useContext } from 'react';
import get from 'lodash/get';
import update from 'lodash/fp/update';
import set from 'lodash/fp/set';

export const Value = createContext({
  value: {},
  setValue: () => {},
});

export function useValue(path) {
  const { value, setValue, defaultValue, questionnaire, questionnaireAnswers } = useContext(Value);

  return {
    value: get(value, path),
    setValue: arg => {
      if (typeof arg === 'function') {
        setValue(update(path, arg));
      } else {
        setValue(set(path, arg));
      }
    },
    defaultValue: get(defaultValue, path),
    questionnaire,
    questionnaireAnswers,
  };
}
