export const cardiovascular = [
  ['Cardiac', 'yes', 'AHR'],
  ['Cardiac', 'yes', 'Aneurysm'],
  ['Cardiac', 'yes', 'CHF'],
  ['Cardiac', 'yes', 'HBP'],
  ['Cardiac', 'yes', 'MI'],
  ['Cardiac', 'yes', 'cardiologist'],
];

export const vascular = [
  ['Vascular', 'yes', 'bleeding'],
  ['Vascular', 'yes', 'anemia'],
  ['Vascular', 'yes', 'bloodClot'],
  ['Vascular', 'yes', 'bloodThinner'],
];

export const pulmonary = [
  ['Pulmonary', 'yes', 'asthma'],
  ['Pulmonary', 'yes', 'copd'],
  ['Pulmonary', 'yes', 'hospitalization'],
  ['Pulmonary', 'yes', 'pneumonia'],
  ['Pulmonary', 'yes', 'pulmonologist'],
  ['Pulmonary', 'yes', 'sleepApnea'],
  ['Pulmonary', 'yes', 'sleepApnea', 'snore', 'yes'],
  ['Pulmonary', 'yes', 'sleepApnea', ''],
  ['Pulmonary', 'yes', 'sleepApnea'],

  ['Pulmonary', 'yes', 'tuberculosis'],
];

export const renal = [
  ['Renal', 'yes', 'dialysis'],
  ['Renal', 'yes', 'insufficiency'],
  ['Renal', 'yes', 'kidneyStone'],
  ['Renal', 'yes', 'nephrologist'],
];

export const hepatic = [
  ['Hepatic', 'yes', 'hepatitis'],
  ['Hepatic', 'yes', 'liverFailure'],
];

export const neurological = [
  ['Neurologic', 'yes', 'RLS'],
  ['Neurologic', 'yes', 'multipleSclerosis'],
  ['Neurologic', 'yes', 'neurologist'],
  ['Neurologic', 'yes', 'neuropathy'],
  ['Neurologic', 'yes', 'seizure'],
  ['Neurologic', 'yes', 'stroke'],
];

export const gastrointestinal = [
  ['GI', 'yes', 'IBD'],
  ['GI', 'yes', 'acidReflux'],
  ['GI', 'yes', 'difficultySwallowing'],
  ['GI', 'yes', 'diverticulitis'],
  ['GI', 'yes', 'gastroparesis'],
  ['GI', 'yes', 'ulcers'],
];

export const hematological = [];

export const endocrine = [
  ['Endocrine', 'yes', 'diabetic'],
  ['Endocrine', 'yes', 'thyroid'],
];

export const musculoskeletal = [
  ['Musculoskeletal', 'yes', 'arthritis'],
  ['Musculoskeletal', 'yes', 'chronicPain'],
  ['Musculoskeletal', 'yes', 'jawpain'],
  ['Musculoskeletal', 'yes', 'limitedRange'],
  ['Musculoskeletal', 'yes', 'osteoporosis'],
];

export const psychiatric = [
  ['Psychiatric', 'yes', 'ADD'],
  ['Psychiatric', 'yes', 'PTSD'],
  ['Psychiatric', 'yes', 'anxiety'],
  ['Psychiatric', 'yes', 'bipolar'],
  ['Psychiatric', 'yes', 'depression'],
  ['Psychiatric', 'yes', 'schizophrenia'],
];

export const gynaecological = [];

export const oncologic = [['Oncologic']];

export const infectious = [
  ['Infectious', 'InfectiousDisease'],
  ['Infectious', 'covid'],
  ['Infectious', 'dermatology'],
  ['Infectious', 'symptoms'],
  ['Infectious', 'traveled'],
];

export const other = [];

export const pain = [];
