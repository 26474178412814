import { createStore } from 'zustand';

export type Role = 'staff' | 'anesthesiologist' | 'physician';

export interface ChartingSession {
  id: number;
  name: string;
  pin: string;
  role: Role;
}

export const chartingStore = createStore<{
  session: ChartingSession | null;
  pinTimeout: number | null;
  keepAlive: number;
}>(() => ({ session: null, pinTimeout: null, keepAlive: 0 }));
