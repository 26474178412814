export const SUPER_ADMIN = 'SuperAdmin';
export const GROUP_ADMIN = 'GroupAdmin';
export const MONITORING = 'Monitoring';
export const ADMIN = 'Administrator';
export const FRONT_DESK = 'FrontDesk';
export const NURSING = 'Nursing';
export const NURSE = 'Nurse';
export const SCHEDULER = 'Scheduler';
export const OPERATIONS_MANAGER = 'OperationsManager';
export const BUSINESS_MANAGER = 'BusinessManager';
export const PREOP_NURSE = 'PreOPNurse';
export const SCHEDULE = 'Schedule';
export const SENSOR_SUPERVISOR = 'SensorSupervisor';
export const PHYSICIAN = 'Physician';
export const ANESTHESIOLOGIST = 'Anesthesiologist';
export const STAFF_MEMBER = 'StaffMember';
