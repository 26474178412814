import { graphql } from '@apollo/client/react/hoc';
import React, { Fragment } from 'react';
import { compose, mapProps } from 'recompose';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import styled, { css } from 'styled-components';
import { getNestedValue } from '../../../se/utilities/data/object';
import { isArray, isDefinedAndNotNull } from '../../../se/utilities/check';

import { Helmet } from 'react-helmet';
import PanelStatus from '../../Panel/PanelStatus';
import PanelSlots from '../../Panel/PanelSlots';
import HospitalInfo from '../../HospitalInfo';
import responsive from '../../../se/utilities/responsive';
import waitingRoomEmpty from '../../../assets/images/waitingroom.svg';
import preopEmpty from '../../../assets/images/preop.svg';
import pacuEmpty from '../../../assets/images/postoperation.svg';
import { listSubscription } from '../../../graph/rooms';
import {
  createMapOfRoomsByType,
  mapFamilyReadyPACU,
  mapFamilyReadyPOSTOP,
  mapMonitorOperationRoom,
  mapMonitorRoom,
  mapORReady,
  mapPacuReady,
  mapPostOpReady,
  mapPrepReady,
  mapWaitingReady,
  monitorHideProcedureType,
} from '../../entities/room/transducers';

import { eventTime, patientEnteredTime } from './tablet/utils';
import { PROCEDURE_STATUSES } from '../../entities/patient/enums';

import discharge from '../../../assets/sound/discharge.mp3';
import orReady from '../../../assets/sound/orReady.mp3';
import waitingReady from '../../../assets/sound/waitingReady.mp3';
import readyToPickup from '../../../assets/sound/readyToPickup.mp3';
import holdProcedure from '../../../assets/sound/holdProcedure.mp3';
import closing from '../../../assets/sound/closing.mp3';
import blockNerve from '../../../assets/sound/holdProcedure.mp3'; // change to blockNerve sound
import help from '../../../assets/sound/holdProcedure.mp3'; // change to help sound
import ClientUpdater, { ClientUpdaterPresetTvInternal } from '../../ClientUpdater';
import { INTERNAL_MONITOR } from '../../entities/screens/enums';
import { getGlobalAvgTimesConfiguration } from '../../../graph/procedureTypes';
import { PACU, POST_OP, PRE_OP, ROOM_TYPES } from '../../entities/room/enums';
import { isHelpFn, getLogEntries } from './tablet/utils';
import { withScope } from '../../../contexts/ScopeContext';

const Root = styled.div`
  width: 100vw;
  background-color: ${props => props.theme.backgroundColor.string()};
  ${props =>
    props.preOpFocused || props.pacuFocused
      ? ' display: grid; grid-template-rows: 1fr 5fr 12fr;'
      : 'display: flex;  flex-flow: column;'}
  padding: 0.5rem 1rem 1rem;
  min-height: 100vh;
  height: fit-content;
`;

const PanelsStatus = styled.div`
  display: flex;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  flex: 1 1 50%;

  > div {
    margin: 0.25rem;
  }

  ${responsive.sm.andSmaller`
    flex-flow: column;
  `};

  ${props =>
    props.wrap &&
    css`
      flex-wrap: wrap;
      flex: 1 1 50%;
    `}

  ${props =>
    props.miniView &&
    css`
      flex: 0 0 50%;
    `}
`;

export const sum = arr => arr.reduce((l, r) => l + r, 0);

export const soundAlert = async (alert, condition) => {
  if (condition) {
    try {
      const sound = new Audio(alert);
      await sound.play();
    } catch (e) {
      console.warn(e);
    }
  }
};

const makePanels = (room, carouselSize, panelNumber, hospitalId, imageEmpty, hasPreOpPriorityModule, hasNoteModule) => {
  const { name, capacity, patients, type } = room;
  let newPanels = [];

  for (let i = 0; i < panelNumber; i++) {
    const oneEmptyPanel = panelNumber === 1 && patients?.length === 0;
    const twoEmptyPanel = panelNumber === 2 && patients?.length === 0 && i === 0;
    const threeEmptyPanel = panelNumber === 3 && patients?.length === 0 && i === 1;
    newPanels.push(
      <PanelSlots
        capacity={capacity}
        patientsCount={patients?.length || 0}
        patients={
          panelNumber === 1
            ? patients
            : patients.slice(i * carouselSize, i + 1 !== panelNumber ? (i + 1) * carouselSize : undefined)
        }
        name={i === 0 ? name : undefined}
        roomType={type}
        image={imageEmpty}
        showBed={true}
        showPriority={hasPreOpPriorityModule}
        showPreOpNote={type === PRE_OP && hasNoteModule}
        showPacuNote={type === PACU && hasNoteModule}
        highlightReady
        carouselSize={carouselSize}
        fusePanels={i + 1 !== panelNumber}
        lastInRow={i + 1 === panelNumber}
        showEmptyContent={oneEmptyPanel || twoEmptyPanel || threeEmptyPanel}
      />
    );
  }

  return newPanels;
};

const RoomPanelSlots = ({
  room,
  hospitalId,
  carouselSize,
  panelNumber,
  imageEmpty,
  hasPreOpPriorityModule,
  hasNoteModule,
}) => {
  const panelSlots = makePanels(
    room,
    carouselSize,
    panelNumber,
    hospitalId,
    imageEmpty,
    hasPreOpPriorityModule,
    hasNoteModule
  );

  return <>{panelSlots.map(panel => panel)}</>;
};

const BottomPanelStatus = ({
  isMini,
  waitingRoom,
  preOp,
  pacu,
  postOp,
  hasPostop,
  hospitalId,
  preOpFocused,
  pacuFocused,
  hasPreOpPriorityModule,
  hasNoteModule,
}) => {
  // This can change depending on what number of panels are needed, for now it is static as we do not have a need for dynamic changes
  const CAROUSEL_SIZE_PREOP_FOCUSED = 7;
  const PREOP_PANEL_NUMBER = 3;
  const PACU_PANEL_NUMBER = 3;
  const WAITING_ROOM_PANEL_NUMBER = 2;
  const POSTOP_ROOM_PANEL_NUMBER = 2;

  if (preOpFocused) {
    return (
      <PanelsStatus miniView={isMini}>
        <>
          {waitingRoom && (
            <RoomPanelSlots
              room={waitingRoom}
              hospitalId={hospitalId}
              focused={true}
              screenType={INTERNAL_MONITOR}
              panelNumber={WAITING_ROOM_PANEL_NUMBER}
              carouselSize={CAROUSEL_SIZE_PREOP_FOCUSED}
              imageEmpty={waitingRoomEmpty}
            />
          )}
          {preOp && preOp.id && (
            <RoomPanelSlots
              room={preOp}
              hospitalId={hospitalId}
              focused={true}
              roomType={PRE_OP}
              screenType={INTERNAL_MONITOR}
              panelNumber={PREOP_PANEL_NUMBER}
              carouselSize={CAROUSEL_SIZE_PREOP_FOCUSED}
              imageEmpty={preopEmpty}
              hasPreOpPriorityModule={hasPreOpPriorityModule}
              hasNoteModule={hasNoteModule}
            />
          )}
        </>
      </PanelsStatus>
    );
  }

  if (pacuFocused) {
    return (
      <PanelsStatus miniView={isMini}>
        <>
          {pacu && pacu.id && (
            <RoomPanelSlots
              room={pacu}
              name={pacu?.name}
              roomType={PACU}
              hospitalId={hospitalId}
              focused={true}
              screenType={INTERNAL_MONITOR}
              panelNumber={PACU_PANEL_NUMBER}
              carouselSize={CAROUSEL_SIZE_PREOP_FOCUSED}
              imageEmpty={pacuEmpty}
              highlightReady
              patientsCount={pacu?.patients?.length || 0}
              showEmptyContent
              showPacuNote={hasNoteModule}
              showBed={true}
            />
          )}
          {hasPostop && postOp && postOp.id && (
            <RoomPanelSlots
              room={postOp}
              hospitalId={hospitalId}
              focused={true}
              roomType={POST_OP}
              panelNumber={POSTOP_ROOM_PANEL_NUMBER}
              carouselSize={CAROUSEL_SIZE_PREOP_FOCUSED}
              screenType={INTERNAL_MONITOR}
              name={postOp?.name}
              patientsCount={postOp?.patients?.length || 0}
              imageEmpty={pacuEmpty}
              showBed={true}
              highlightReady
              showEmptyContent
            />
          )}
        </>
      </PanelsStatus>
    );
  }

  return (
    <PanelsStatus miniView={isMini}>
      <>
        {waitingRoom && (
          <RoomPanelSlots
            room={waitingRoom}
            hospitalId={hospitalId}
            focused={false}
            screenType={INTERNAL_MONITOR}
            panelNumber={1}
            carouselSize={5}
            imageEmpty={waitingRoomEmpty}
          />
        )}
        {preOp && preOp.id && (
          <RoomPanelSlots
            room={preOp}
            hospitalId={hospitalId}
            focused={false}
            roomType={PRE_OP}
            screenType={INTERNAL_MONITOR}
            panelNumber={1}
            carouselSize={5}
            imageEmpty={preopEmpty}
            hasPreOpPriorityModule={hasPreOpPriorityModule}
            hasNoteModule={hasNoteModule}
          />
        )}
      </>
      <>
        {pacu && pacu.id && (
          <PanelSlots
            {...pacu}
            name={pacu?.name}
            roomType={PACU}
            patientsCount={pacu?.patients?.length || 0}
            screenType={INTERNAL_MONITOR}
            image={pacuEmpty}
            showBed={true}
            highlightReady
            showEmptyContent
            showPacuNote={hasNoteModule}
          />
        )}

        {hasPostop && postOp && postOp.id && (
          <PanelSlots
            {...postOp}
            name={postOp?.name}
            patientsCount={postOp?.patients?.length || 0}
            roomType={POST_OP}
            screenType={INTERNAL_MONITOR}
            image={pacuEmpty}
            showBed={true}
            highlightReady
            showEmptyContent
          />
        )}
      </>
    </PanelsStatus>
  );
};

export const MonitorContent = ({
  operationRooms,
  waitingRoom,
  preOp,
  pacu,
  postOp,
  hasPostop,
  hospitalId,
  preOpFocused,
  pacuFocused,
  hasPreOpPriorityModule,
  hasNoteModule,
  isMini,
}) => {
  const hasOtherStatuses = waitingRoom || preOp || pacu || postOp;

  return (
    <Root preOpFocused={preOpFocused} pacuFocused={pacuFocused}>
      <HospitalInfo />
      {operationRooms.length > 0 && !hasOtherStatuses ? (
        <PanelsStatus miniView={isMini} wrap="true">
          {operationRooms.map(operationRoom => (
            <PanelStatus
              key={operationRoom.name}
              roomId={operationRoom.id}
              title={operationRoom.name}
              monitorStatus={operationRoom.monitorStatus}
              status={operationRoom.status}
              statusLabel={operationRoom.statusLabel}
              scheduleProcedure={operationRoom.procedure}
              procedure={getNestedValue('patient.procedureType.name', operationRoom)}
              physician={getNestedValue('patient.physician.name', operationRoom)}
              patient={getNestedValue('patient.name', operationRoom)}
              patientType={operationRoom?.patient?.type}
              enteredAtTime={patientEnteredTime(getNestedValue('patient', operationRoom), operationRoom)}
              timeoutTime={eventTime(PROCEDURE_STATUSES.TIME_OUT, getNestedValue('patient', operationRoom))}
              procedureStartTime={eventTime(PROCEDURE_STATUSES.ONGOING, getNestedValue('patient', operationRoom))}
              closingTime={eventTime(PROCEDURE_STATUSES.CLOSING, getNestedValue('patient', operationRoom))}
              compactView={operationRooms.length > 3}
              allowWrap={true}
              miniView={operationRooms.length > 8}
              numberOfRooms={operationRooms.length}
              preOpFocused={preOpFocused}
              pacuFocused={pacuFocused}
              isHelp={isHelpFn(getLogEntries(getNestedValue('patient', operationRoom)))}
              called={getNestedValue('patient.called') && hasOtherStatuses}
              isPreOp={preOp}
              room={operationRoom}
            />
          ))}
        </PanelsStatus>
      ) : operationRooms.length === 0 && hasOtherStatuses ? (
        <BottomPanelStatus
          waitingRoom={waitingRoom}
          preOp={preOp}
          pacu={pacu}
          postOp={postOp}
          hasPostop={hasPostop}
          hospitalId={hospitalId}
          preOpFocused={preOpFocused}
          pacuFocused={pacuFocused}
          hasPreOpPriorityModule={hasPreOpPriorityModule}
          hasNoteModule={hasNoteModule}
          isMini={isMini}
        />
      ) : (
        <>
          <PanelsStatus miniView={isMini} wrap="true">
            {operationRooms.map(operationRoom => (
              <PanelStatus
                key={operationRoom.name}
                roomId={operationRoom.id}
                title={operationRoom.name}
                monitorStatus={operationRoom.monitorStatus}
                status={operationRoom.status}
                statusLabel={operationRoom.statusLabel}
                scheduleProcedure={operationRoom.procedure}
                procedure={getNestedValue('patient.procedureType.name', operationRoom)}
                physician={getNestedValue('patient.physician.name', operationRoom)}
                patient={getNestedValue('patient.name', operationRoom)}
                patientType={operationRoom?.patient?.type}
                enteredAtTime={patientEnteredTime(getNestedValue('patient', operationRoom), operationRoom)}
                timeoutTime={eventTime(PROCEDURE_STATUSES.TIME_OUT, getNestedValue('patient', operationRoom))}
                procedureStartTime={eventTime(PROCEDURE_STATUSES.ONGOING, getNestedValue('patient', operationRoom))}
                closingTime={eventTime(PROCEDURE_STATUSES.CLOSING, getNestedValue('patient', operationRoom))}
                compactView={operationRooms.length > 3}
                allowWrap={true}
                miniView={operationRooms.length > 8}
                numberOfRooms={operationRooms.length}
                preOpFocused={preOpFocused}
                pacuFocused={pacuFocused}
                isHelp={isHelpFn(getLogEntries(getNestedValue('patient', operationRoom)))}
                called={getNestedValue('patient.name.called')}
                isPreOp={preOp}
                room={operationRoom}
              />
            ))}
          </PanelsStatus>
          <BottomPanelStatus
            waitingRoom={waitingRoom}
            preOp={preOp}
            pacu={pacu}
            postOp={postOp}
            hasPostop={hasPostop}
            hospitalId={hospitalId}
            preOpFocused={preOpFocused}
            pacuFocused={pacuFocused}
            hasPreOpPriorityModule={hasPreOpPriorityModule}
            hasNoteModule={hasNoteModule}
            isMini={isMini}
          />
        </>
      )}
    </Root>
  );
};

class Monitor extends React.Component {
  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    if (!this.props.loading) {
      if (this.prepWaitingNumberFromProps(nextProps) > this.prepWaitingNumberFromProps(this.props)) {
        this.readyForPrepReadyAlert();
      }

      if (this.readyForORNumberFromProps(nextProps) > this.readyForORNumberFromProps(this.props)) {
        this.readyForORAlert();
      }

      if (this.readyForSurgeonNumberFromProps(nextProps) > this.readyForSurgeonNumberFromProps(this.props)) {
        this.readyForSurgeonAlert();
      }

      if (this.holdProcedureNumberFromProps(nextProps) > this.holdProcedureNumberFromProps(this.props)) {
        this.holdProcedureAlert();
      }

      if (this.blockNerveNumberFromProps(nextProps) > this.blockNerveNumberFromProps(this.props)) {
        this.blockNerveAlert();
      }
      if (this.blockNerveNumberFromProps2(nextProps) > this.blockNerveNumberFromProps2(this.props)) {
        this.blockNerveAlert();
      }

      if (this.helpNumberFromProps(nextProps) > this.helpNumberFromProps(this.props)) {
        this.helpAlert();
      }

      if (this.pacuWaitingNumberFromProps(nextProps) > this.pacuWaitingNumberFromProps(this.props)) {
        this.readyToSeeFamilyAlert();
      }

      if (this.postOpWaitingNumberFromProps(nextProps) > this.postOpWaitingNumberFromProps(this.props)) {
        this.readyToSeeFamilyAlert();
      }

      if (this.familyHereNumberFromProps(nextProps) > this.familyHereNumberFromProps(this.props)) {
        this.familyHereAlert();
      }

      if (this.readyToPickupNumberFromProps(nextProps) > this.readyToPickupNumberFromProps(this.props)) {
        this.readyToPickupAlert();
      }

      if (this.closingProps(nextProps) > this.closingProps(this.props)) {
        this.closingAlert();
      }

      if (this.patientNumberFromProps(nextProps) < this.patientNumberFromProps(this.props)) {
        this.dischargeAlert();
      }
    }
  }

  readyForPrepReadyAlert = () => soundAlert(waitingReady, getNestedValue('configuration.prepReadySound', this.props));
  readyForORAlert = () => soundAlert(orReady, getNestedValue('configuration.orReadySound', this.props));
  readyForSurgeonAlert = () =>
    soundAlert(waitingReady, getNestedValue('configuration.readyForSurgeonSound', this.props));
  holdProcedureAlert = () => soundAlert(holdProcedure, getNestedValue('configuration.holdProcedureSound', this.props));
  blockNerveAlert = () => soundAlert(blockNerve, getNestedValue('configuration.blockNerveSound', this.props));
  helpAlert = () => soundAlert(help, getNestedValue('configuration.helpSound', this.props));
  readyToSeeFamilyAlert = () => soundAlert(waitingReady, getNestedValue('configuration.pacuReadySound', this.props));
  familyHereAlert = () => soundAlert(waitingReady, getNestedValue('configuration.waitingReadySound', this.props));
  readyToPickupAlert = () => soundAlert(readyToPickup, getNestedValue('configuration.readyToPickupSound', this.props));
  dischargeAlert = () => soundAlert(discharge, getNestedValue('configuration.dischargeSound', this.props));
  closingAlert = () => soundAlert(closing, getNestedValue('configuration.closingSound', this.props));

  patientNumberFromProps = ({ operationRooms = [], waitingRoom, preOp, pacu, postOp }) =>
    sum([
      (getNestedValue('patients', waitingRoom) || []).length,
      (getNestedValue('patients', preOp) || []).length,
      (getNestedValue('patients', pacu) || []).length,
      (getNestedValue('patients', postOp) || []).length,
      sum(operationRooms.map(or => (getNestedValue('patients', or) || []).length)),
    ]);

  closingProps = ({ operationRooms = [] }) => {
    return operationRooms.filter(or => getNestedValue('status', or) === 'Closing').length;
  };
  patientRoomNumberFromProps = type => props => {
    const patients = getNestedValue('patients', props[type] || {});
    if (Array.isArray(patients)) {
      return patients.filter(_ => _.ready).length;
    }
    return 0;
  };

  waitingNumberFromProps = this.patientRoomNumberFromProps('waitingRoom');
  pacuWaitingNumberFromProps = this.patientRoomNumberFromProps('pacu');
  postOpWaitingNumberFromProps = this.patientRoomNumberFromProps('postOp');
  prepWaitingNumberFromProps = this.patientRoomNumberFromProps('waitingRoom');

  readyForSurgeonNumberFromProps = props => {
    const patients = getNestedValue('patients', props['preOp']) || {};
    if (Array.isArray(patients)) {
      return patients.filter(_ => _.readyForSurgeon).length;
    }
    return 0;
  };

  holdProcedureNumberFromProps = props => {
    const patients = getNestedValue('patients', props['preOp']) || {};
    if (Array.isArray(patients)) {
      return patients.filter(_ => _.isHoldProcedure).length;
    }
    return 0;
  };

  blockNerveNumberFromProps = props => {
    const patients = getNestedValue('patients', props['preOp']) || {};
    if (Array.isArray(patients)) {
      return patients.filter(_ => _.isBlockNerve).length;
    }
    return 0;
  };

  blockNerveNumberFromProps2 = props => {
    const postOp = getNestedValue('patients', props['postOp']) || [];
    const pacu = getNestedValue('patients', props['pacu']) || [];

    return [...postOp, ...pacu].filter(_ => Boolean(_.isBlockNerve)).length;
  };

  helpNumberFromProps = props => {
    const patients = getNestedValue('patients', props['OR']) || {};
    if (Array.isArray(patients)) {
      return patients.filter(_ => _.isHelp).length;
    }
    return 0;
  };

  readyForORNumberFromProps = props => {
    const patients = getNestedValue('patients', props['preOp']) || {};
    if (Array.isArray(patients)) {
      return patients.filter(p => p.readyForOr).length;
    }
    return 0;
  };
  familyHereNumberFromProps = props => {
    const postOp = getNestedValue('patients', props['postOp']) || [];
    const pacu = getNestedValue('patients', props['pacu']) || [];

    return [...postOp, ...pacu].filter(
      _ => Boolean(_.familyReady) || Boolean(_.familyReadyPACU) || Boolean(_.familyReadyPOSTOP)
    ).length;
  };

  readyToPickupNumberFromProps = props => {
    const postOp = getNestedValue('patients', props['postOp']) || [];
    const pacu = getNestedValue('patients', props['pacu']) || [];

    return [...postOp, ...pacu]?.filter(_ => !!_.caretakerMessages).length;
  };

  render() {
    const { scope, operationRooms, waitingRoom, preOp, pacu, postOp, configuration } = this.props;
    const hospitalId = scope?.hospital?.id;
    const hasPreOpPriorityModule = scope?.hospital?.modules?.preOpPriority?.hasPreOpPriority || false;
    const hasNoteModule = scope?.hospital?.modules?.noteTablet || false;

    const roomsWithOrder = operationRooms.filter(room => room?.order !== null).sort((a, b) => a.order - b.order);
    const roomsWithoutOrder = operationRooms.filter(room => room?.order === null);

    const orderedRooms = [
      ...roomsWithOrder,
      ...roomsWithoutOrder.filter(room => room?.name?.toUpperCase()?.startsWith('OR')),
      ...roomsWithoutOrder.filter(room => !room?.name?.toUpperCase()?.startsWith('OR')),
    ];

    return (
      <Fragment>
        <Helmet>
          <meta name="viewport" content="width=1200, initial-scale=0" />
        </Helmet>
        <MonitorContent
          operationRooms={orderedRooms}
          waitingRoom={waitingRoom}
          preOp={preOp}
          pacu={pacu}
          postOp={postOp}
          hasPostop={scope?.hospital?.modules?.hasPostop && configuration?.showPostOp !== false}
          hospitalId={hospitalId}
          preOpFocused={configuration?.preOpFocused}
          pacuFocused={configuration?.pacuFocused}
          hasNoteModule={hasNoteModule}
          hasPreOpPriorityModule={hasPreOpPriorityModule}
          isMini={false}
        />
        {[...orderedRooms, waitingRoom, preOp, pacu, postOp]
          .filter(room => room && room.patients)
          .every(({ patients = [] }) => patients.length <= 0) && <ClientUpdater {...ClientUpdaterPresetTvInternal} />}
      </Fragment>
    );
  }
}

Monitor.defaultProps = {
  operationRooms: [],
  waitingRoom: {},
  pacu: {},
  preOp: {},
  postOp: {},
};

Monitor.propTypes = {
  operationRooms: PropTypes.array,
  waitingRoom: PropTypes.object,
  pacu: PropTypes.object,
  preOp: PropTypes.object,
  postOp: PropTypes.object,
};

const flattenRoomPatients = rooms =>
  rooms
    .map(_ => mapMonitorRoom(_))
    .reduce((acc, curr) => ({
      ...acc,
      patients: [...acc.patients, ...curr.patients],
      capacity: acc.capacity + curr.capacity,
    }));

const flattenRoomPatients2 = rooms => rooms.map(_ => mapMonitorRoom(_));

const injectExpectedWRDuration = (wrRoom, expectedWRDuration) => [
  {
    ...wrRoom[0],
    patients: (wrRoom[0].patients || []).map(p => ({
      ...p,
      procedureType: {
        ...p.procedureType,
        expectedWRDuration: get(expectedWRDuration, 'averageTimesConfiguration.expectedWRDuration', undefined),
      },
    })),
  },
];
export default compose(
  // branch(({ version }) => version === 'protobuf', renderComponent(ProtobufMonitor)),
  graphql(listSubscription),
  mapProps(({ data, ...rest }) => ({
    internalMonitor: isDefinedAndNotNull(data) ? data['internalMonitor'] || [] : [],
    error: data.error,
    loading: data.loading,
    ...rest,
  })),
  withScope,
  mapProps(({ internalMonitor, ...rest }) => ({
    internalMonitor: monitorHideProcedureType(internalMonitor, !getNestedValue('configuration.showProcedure', rest)),
    ...rest,
  })),
  mapProps(({ internalMonitor, patientNameFormat, scope, ...rest }) => ({
    ...createMapOfRoomsByType(
      internalMonitor,
      patientNameFormat,
      get(scope, 'hospital.id'),
      get(scope, 'hospital.groupId')
    ),
    scope,
    ...rest,
  })),
  graphql(getGlobalAvgTimesConfiguration),
  mapProps(({ data, operationRooms, waitingRooms, preOps, pacus, postOps, ...rest }) => ({
    ...rest,
    operationRooms: isArray(operationRooms) ? operationRooms.map(mapMonitorOperationRoom) : [],
    waitingRoom: isArray(waitingRooms) ? flattenRoomPatients(injectExpectedWRDuration(waitingRooms, data)) : undefined,
    preOp: isArray(preOps) ? flattenRoomPatients(preOps) : undefined,
    pacu: isArray(pacus) ? flattenRoomPatients(pacus) : undefined,
    // flattenRoomPatients2 will return array of post ops, flattenRoomPatients will reduce to one room
    postOp: isArray(postOps) ? flattenRoomPatients2(postOps) : undefined,
  })),
  mapProps(({ waitingRoom, operationRooms, preOp, pacu, postOp, ...rest }) => {
    // this config is named wrong showOrRooms because previously it is used only for or rooms
    const config = rest?.configuration?.showOrRooms || [];
    const isConfigEmptyOrIncludes = id => !config || config.length === 0 || config.includes(id);

    return {
      ...rest,
      waitingRoom: isConfigEmptyOrIncludes(waitingRoom?.id) ? mapWaitingReady(waitingRoom) : null,
      operationRooms:
        config && config.length > 0
          ? mapORReady(operationRooms).filter(r => config.includes(r.id))
          : mapORReady(operationRooms),
      preOp: isConfigEmptyOrIncludes(preOp?.id) ? mapPrepReady(preOp) : null,
      pacu: isConfigEmptyOrIncludes(pacu?.id) ? mapFamilyReadyPACU(mapPacuReady(pacu)) : null,
      postOp:
        postOp?.filter(e => isConfigEmptyOrIncludes(e?.id))?.length > 0
          ? mapFamilyReadyPOSTOP(mapPostOpReady(postOp?.filter(e => isConfigEmptyOrIncludes(e?.id))?.[0]))
          : null,
    };
  })
)(Monitor);
