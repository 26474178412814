import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment DictationTemplateData on DictationTemplate {
      id
      title
      template
      physicianId
    }
  `,
};

export const list = gql`
  query dictationTemplates($pin: String!) {
    dictationTemplates(pin: $pin) {
      ...DictationTemplateData
    }
  }
  ${fragments.all}
`;

export const create = gql`
  mutation createDictationTemplate($title: String!, $content: String!, $pin: String!) {
    createDictationTemplate(title: $title, content: $content, pin: $pin)
  }
`;

export const update = gql`
  mutation updateDictationTemplate($id: Long!, $title: String!, $content: String!, $pin: String!) {
    updateDictationTemplate(id: $id, title: $title, content: $content, pin: $pin)
  }
`;

export const remove = gql`
  mutation removeDictationTemplate($id: Long!, $pin: String!) {
    removeDictationTemplate(id: $id, pin: $pin)
  }
`;

export default {
  list,
  create,
  update,
  remove,
};
