import React from 'react';
import { renderFormAnswer } from '../../../form/Form';
import { tryParseJson } from '../../../util/parseJson';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core';
import { Category } from '../../../types/CovidScreening';
import ConfigContext, { defaultConfig } from '../../../contexts/ConfigContext';
import SubmissionFormContext from '../../../contexts/SubmissionFormContext';
import { useScope } from '../../../hooks/useScope';

interface CovidScreeningSubmissionProps {
  category: Category;
  data?: {
    question?: string;
    answer?: string;
    dateSubmitted?: string;
    id: string;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.up('lg')]: {
      root: {
        maxWidth: '40em',
      },
    },
  })
);

const CovidScreeningSubmission = ({ category, data }: CovidScreeningSubmissionProps) => {
  const scope = useScope();
  const classes = useStyles();

  const submissionId = data?.id;
  const covidScreeningOrganizationId = scope?.hospital?.covidScreening?.id;
  return (
    <ConfigContext.Provider value={defaultConfig}>
      <SubmissionFormContext.Provider value={{ organizationId: covidScreeningOrganizationId, submissionId }}>
        <div className={classes.root}>
          {renderFormAnswer({ category }, tryParseJson(data?.question), tryParseJson(data?.answer))}
        </div>
      </SubmissionFormContext.Provider>
    </ConfigContext.Provider>
  );
};

export default CovidScreeningSubmission;
