import { capitalize } from 'lodash';
import toString from './toString';

const toStringWithoutQuestions3 = toString.with({
  OneOf: (label, options, answer) =>
    answer ? 
    `${label
      .replace(/Frequency/g, 'Freq')
      .replace(/Manufacturer/g, 'Mfg')
      .replace(/^The activity that most closely represents your level of physical activity is/g, 'Activity Level')
      .replace(/^What is your cigarette smoking status\?/g, 'Smoking Status')
      .replace(/Usual pain level\? \(1=very mild, 10=worst imaginable\)/g, 'Usual pain level')
      .replace(/^Does your neck measure more than 15 3\/4 inches \(40 cm\) around \(the collar size\)\?/g, 'Neck circumference greater than 15 3/4 in')
    }:${(answer.label)}${answer.child ? ` (${answer.child})` : ''}` : null,
  YesNo: (question, positive, child) => {
    const item = capitalize(
      question
        .replace(/\?/g, '')
        .replace(/^Do you have\s/g, '')
        .replace(/^Are you a\s/g, '')
        .replace(/^Are you\s/g, '')
        .replace(/^Any chance you may be\s/g, '')
        .replace(/^Did you\s/g, '')
        .replace(/^Any\s/g, '')
        .replace(/^Have you ever\s/g, '')
        .replace(/^Have you\s/g, '')
        .replace(/^Is it a\s/g, '')
        .replace(/^Do you\s/g, '')
        .replace(/^Does your\s/g, '')
        .replace(/^Has\s/g, '')
        .replace(/^Is there any\s/g, '')
        .replace(/^Well controlled on medication/g, 'controlled')
        .replace(/\(low blood flow from narrowed or blocked arteries\)/g, '')
        .replace(/an AICD \(implanted cardiac defibrillator\)/g, 'Implanted Defibrillator')
        .replace(/been a cigar, pipe, e-cigarette or hookah smoker, or used a tobacco vaporizer or chewing tobacco/g, '')
        .replace(/^Diagnosed with Sleep apnea/g, 'Sleep Apnea')
        .replace(/an internal nerve stimulator \(such as a bladder stimulator, spinal cord stimulator, or deep brain stimulator\)/g, 'Nerve stimulator')
        .replace(/\(difficulty with language or speech\)/g, '')
        .replace(/\(including pre\/borderline\/diet-controlled\)/g, '')
        .replace(/Had radiation and\/or chemotherapy treatment within 30 days of upcoming procedure/g, 'Had radiation and or chemotherapy treatment within 30 days')
        .replace(/Lower extremity immobilizing cast within 30 days of procedure/g, 'Lower extremity immobilizing cast within 30 days')
        .replace(/Currently have any skin breakdown or open wounds/g, 'Skin breakdown or open wounds')
        .replace(/\(Clostridium difficile\)/g, '')
        .replace(/\(Vancomycin-resistant Enterococcus\)/g, '')
        .replace(/\(Cytomegalovirus\)/g, '')
        .replace(/traveled outside of the US in the last 30 days/g, 'Recent travel outside of the US')
        .replace(/had a positive Covid-19 test in last 30 days/g, 'positive Covid-19 test')
        .replace(/\(such as use a wheelchair, cane or walker\)/g, '')
        .replace(/\(such as dressing, bathing, bathroom, etc.\)/g, '')
        .replace(/\(unable to see distant objects clearly\)/g, '')
        .replace(/\(unable to see close objects clearly\)/g, '')
        .replace(/dental work other than fillings/g, 'dental work')
        .replace(/had a recent trauma to an extremity or major trauma to body/g, 'Recent major trauma')
    );
    return positive ? `${item}${child ? ` (${child})` : ''}` : `${child ? `${child}` : ''}`;
  },
});

export default toStringWithoutQuestions3;
