import React, { Fragment } from 'react';
import { Answer, InnerField, MainQuestion, Question } from './Containers';
import get from 'lodash/get';

export default ({ value }) => {
  const { city, state, zipCode } = get(value, 'cityStateZip', {});

  return (
    <Fragment>
      <div>
        <MainQuestion>Location</MainQuestion>
        {city && (
          <InnerField>
            <Question>City</Question>
            <Answer>{city}</Answer>
          </InnerField>
        )}
        {state && (
          <InnerField>
            <Question>State</Question>
            <Answer>{state}</Answer>
          </InnerField>
        )}
        {zipCode && (
          <InnerField>
            <Question>Zip Code</Question>
            <Answer>{zipCode}</Answer>
          </InnerField>
        )}
      </div>
    </Fragment>
  );
};
