import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { Document, Page, pdfjs } from 'react-pdf';
import styled, { css } from 'styled-components';
import { Link } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useHasAccessRight from '../../../hooks/useHasAccessRight';
import Box from '@material-ui/core/Box';
import NoPermissionText from '../../NoPermissionText';
import clsx from 'clsx';
import { ArrowDownward, Print as PrintIcon } from '@material-ui/icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ToolbarItem = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  z-index: 5;
  transition: all 0.4s ease;

  opacity: 0.6;

  :hover {
    opacity: 1;
  }

  ${props =>
    !props.visible &&
    css`
      opacity: 0;
    `}
`;

const PageStepperContainer = styled(ToolbarItem)`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 1em;
  transform: translateX(-50%);

  p {
    padding: 0 0.5em;
  }
`;

const Action = styled(ToolbarItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1em;
  height: 1.75em;
  width: 2.5em;
  justify-content: center;
  align-content: center;
  padding: 0.2em 1em;
  a {
    display: flex;
  }
`;

const Pager = ({ numPages, pageNumber, prevPage, nextPage, width, visible }) => (
  <PageStepperContainer visible={visible} style={{ marginLeft: width / 2, color: 'white' }}>
    <Button onClick={prevPage} disabled={pageNumber <= 1} style={{ color: 'white' }}>
      ‹
    </Button>
    <p>
      {pageNumber} / {numPages}
    </p>
    <Button onClick={nextPage} disabled={pageNumber >= numPages} style={{ color: 'white' }}>
      ›
    </Button>
  </PageStepperContainer>
);

const Download = ({ url }) => (
  <Link href={url} style={{ color: 'white', fontSize: '1rem' }}>
    <ArrowDownward fontSize={'small'} />
  </Link>
);

const Print = ({ url }) => {
  const print = async () => {
    try {
      const data = await fetch(url);
      const blob = await data.blob();
      const blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <IconButton onClick={print} style={{ color: 'white', fontSize: '1rem' }}>
      <PrintIcon fontSize={'small'} />
    </IconButton>
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    position: 'relative',
  },
  blurred: {
    backgroundColor: 'white',
    color: theme.palette.background.default,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 100,
  },
}));

export default ({ url, width = 800 }) => {
  const theme = useTheme();
  const classes = useStyles();
  const hasAccessRight = useHasAccessRight();
  const isAllowedToView = hasAccessRight('patient.view');
  const isAllowedToEdit = hasAccessRight('patient.edit');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
  const prevPage = () => setPageNumber(pageNumber - 1);
  const nextPage = () => setPageNumber(pageNumber + 1);
  const visible = numPages !== null;
  return (
    <div
      style={{
        margin: '1em 0',
        position: 'relative',
        minHeight: '1035px',
        width: width + 5,
        border: `${theme.palette.type === 'dark' ? 'default' : `1px solid ${theme.palette.divider}`}`,
      }}
    >
      {isAllowedToEdit && (
        <>
          <Pager
            numPages={numPages}
            pageNumber={pageNumber}
            prevPage={prevPage}
            nextPage={nextPage}
            width={width}
            visible={visible}
          />
          <Action visible={visible} style={{ left: width, transform: 'translateX(-120%)' }}>
            <Download url={url} />
          </Action>
          <Action visible={visible} style={{ left: width, transform: 'translateX(-240%)' }}>
            <Print url={url} />
          </Action>
        </>
      )}
      <Box className={clsx({ [classes.content]: !isAllowedToView })}>
        {!isAllowedToView && (
          <Box className={clsx({ [classes.blurred]: !isAllowedToView })}>
            <NoPermissionText withText={true} style={{ top: '50%', left: '20%' }} />
          </Box>
        )}
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} width={width} />
        </Document>
      </Box>
    </div>
  );
};
