import React, { useEffect, useState } from 'react';
import { gql, useSubscription } from '@apollo/client';
import produce from 'immer';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

interface TrackingSessionBeaconsProps {
  sessionId: string;
  baseUrl: string;
}

const TrackingSessionBeacons = ({ sessionId, baseUrl }: TrackingSessionBeaconsProps) => {
  const { data } = useSubscription<{
    trackingSessionBeacons: string;
  }>(
    gql`
      subscription trackingSessionBeacons($trackingSessionId: String!) {
        trackingSessionBeacons(trackingSessionId: $trackingSessionId)
      }
    `,
    {
      variables: { trackingSessionId: sessionId },
    }
  );

  const [entries, setEntries] = useState<NonNullable<typeof data>['trackingSessionBeacons'][]>([]);

  useEffect(() => {
    if (data?.trackingSessionBeacons) {
      const beacon = data.trackingSessionBeacons;

      setEntries(
        produce(prev => {
          const index = prev.indexOf(beacon);

          if (index === -1) {
            prev.push(beacon);
          }
        })
      );
    }
  }, [data?.trackingSessionBeacons]);

  return (
    <div>
      {entries.map(entry => (
        <div key={entry}>
          <Link component={RouterLink} to={baseUrl + '/' + entry}>
            {entry}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default TrackingSessionBeacons;
