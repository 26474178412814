import React, { FC, useContext, useState } from 'react';
import { format } from 'date-fns';
import TableWithColumnSelector, { OnClickFn, TableType } from '../../core/TableWithColumnSelector';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useQuery } from '@apollo/client';
import { physicianUtilizationAnalyticsQuery, physicianUtilizationAnalyticsTotalsQuery } from '../../../graph/dashboard';
import { PhysicianUtilizationTableData, PhysicianUtilizationTableTotalsData } from '../../../types/Analytics';
import { getMinutes } from './util';
import PhysicianPatientsAnalytics from './PhysicianPatientsAnalytics';
import Button from '@material-ui/core/Button';
import { ArrowBack } from '@material-ui/icons';
import { TimelineColumnLegend } from './components/ColumnLegend';
import { CircularProgress, Grid, useTheme } from '@material-ui/core';
import imagePIPO from '../../../assets/images/illustrations/info/PIPO.svg';
import imagePOPI from '../../../assets/images/illustrations/info/PHYSICIAN_POPI.svg';
import poToCut from '../../../assets/images/illustrations/info/po_to_cut.svg';
import startToClose from '../../../assets/images/illustrations/info/start_to_close.svg';
import piToStart from '../../../assets/images/illustrations/info/patient_in_to_start.svg';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import DelaysBreakdown from './v2/DelaysBreakdown';
import { ChartWrapper } from './v2/PieCharts';
import PhysicianUtilizationChart from './v2/PhysicianUtilizationChart';
import CustomTooltip from './v2/charts/CustomTooltip';
import { useScope } from '../../../hooks/useScope';
import { PieChartData } from './v2/PieChart';
import { AggregationContext } from './Aggregation';

const toTableData = (
  data: PhysicianUtilizationTableData[],
  totals: PhysicianUtilizationTableTotalsData,
  onClick?: OnClickFn,
  hasPostOp?: boolean
): TableType => {
  const aggregation = useContext(AggregationContext);
  const aggregationTitle = aggregation === 'average' ? 'Avg.' : 'Med.';
  const postOpHeader = hasPostOp ? [`POSTOP ${aggregationTitle}`] : [];
  const baseHeaders = [
    'Physician',
    'Procedures',
    'Wheels-In, Wheels-Out',
    'Physician Turnover',
    'Patient In to Start',
    'Start to Close',
    'PO to Cut',
    'OR Daily Usage Time',
    'Aggregate Daily Patients OR Time',
    'Physician OR Utilization Index',
    `WR ${aggregationTitle}`,
    `Pre OP ${aggregationTitle}`,
    `Recovery ${aggregationTitle}`,
    ...postOpHeader,
    `Pre OP to Exit ${aggregationTitle}`,
    'Pre Op to Exit Total',
    'Surgery Days',
    `FCOTS ${aggregationTitle}`,
  ];

  const pipoOverE2EPercentage = totals?.pipoOverE2EPercentage?.toFixed(2)
    ? `${(totals?.pipoOverE2EPercentage * 100)?.toFixed(2)}%`
    : '-';
  const total = [
    totals?.physician || 'Total',
    totals?.numberOfProcedures?.toString() || '-',
    getMinutes(aggregation === 'average' ? totals?.pipoAvg : totals?.pipoMed),
    getMinutes(aggregation === 'average' ? totals?.popiPhysicianAvg : totals?.popiPhysicianMed),
    getMinutes(aggregation === 'average' ? totals?.piToStartAvg : totals?.piToStartMed),
    getMinutes(aggregation === 'average' ? totals?.startToCloseAvg : totals?.startToCloseMed),
    getMinutes(aggregation === 'average' ? totals?.poToCutAvg : totals?.poToCutMed),
    getMinutes(totals?.firstPIToLastPOTotal),
    getMinutes(totals?.pipoTotal),
    pipoOverE2EPercentage,
    getMinutes(aggregation === 'average' ? totals?.wrAvg : totals?.wrMed),
    getMinutes(aggregation === 'average' ? totals?.preOpAvg : totals?.preOpMed),
    getMinutes(aggregation === 'average' ? totals?.recoveryAvg : totals?.recoveryMed),
    ...(hasPostOp ? [getMinutes(aggregation === 'average' ? totals?.postOpAvg : totals?.postOpMed)] : []),
    getMinutes(aggregation === 'average' ? totals?.preOpToExitAvg : totals?.preOpToExitMed),
    getMinutes(totals?.preOpToExitTotal),
    totals?.surgeryDays?.toString(),
    formatDurationInMinutes(aggregation === 'average' ? totals?.fcotsDelayAvg : totals?.fcotsDelayMed, 1),
  ];

  const totalRow = {
    id: 'total',
    columns: total,
  };

  return {
    configHeader: [...baseHeaders],
    headers: [
      {
        id: 'h1',
        columns: [
          'Physician',
          'Procedures',
          {
            text: 'Wheels-In, Wheels-Out',
            InfoComponent: () => (
              <TimelineColumnLegend
                graphic={imagePIPO}
                title="Wheels-In, Wheels-Out"
                description={`${
                  aggregation === 'average' ? 'Average' : 'Median'
                } operating room time for patients under the care of the same physician. Operating room time is "wheels-in, wheels-out".`}
              />
            ),
          },
          {
            text: 'Physician Turnover',
            InfoComponent: () => (
              <TimelineColumnLegend
                graphic={imagePOPI}
                title="Physician Turnover"
                description="Time of Patient Procedure End to next patient First Cut, by surgeon"
              />
            ),
          },
          {
            text: 'Patient In to Start',
            InfoComponent: () => (
              <TimelineColumnLegend
                graphic={piToStart}
                title="Patient In to Start"
                description="Time from patient in to case start"
              />
            ),
          },
          {
            text: 'Start to Close',
            InfoComponent: () => (
              <TimelineColumnLegend
                graphic={startToClose}
                title="Start to Close"
                description="Time from case start to start of closing"
              />
            ),
          },
          {
            text: 'PO to cut',
            InfoComponent: () => (
              <TimelineColumnLegend
                graphic={poToCut}
                title="PO to cut"
                description="Time from surgeons previous cases patient out of room, to time of next procedures start time."
              />
            ),
          },
          {
            text: 'OR Daily Usage Time',
            InfoComponent: () => (
              <Box p={2} width="30ch">
                <Typography variant="body2" color="textSecondary">
                  Time from the first patient entering the operating room to the time the last patient leaving the
                  operating room under the care of a specific physician.
                </Typography>
              </Box>
            ),
          },
          {
            text: 'Aggregate Daily Patients OR Time',
            InfoComponent: () => (
              <Box p={2} width="30ch">
                <Typography variant="body2" color="textSecondary">
                  Aggregate operating room time for all patients under the care of the same physician. Operating room
                  time is "wheels-in, wheels-out".
                </Typography>
              </Box>
            ),
          },
          {
            text: 'Physician OR Utilization Index',
            InfoComponent: () => (
              <Box p={2} width="30ch">
                <Typography variant="body2" color="textSecondary">
                  The ratio between Aggregate Daily Patients OR Time and OR Daily Usage Time.
                </Typography>
              </Box>
            ),
          },
          `WR ${aggregationTitle}`,
          `Pre OP ${aggregationTitle}`,
          `Recovery ${aggregationTitle}`,
          ...postOpHeader,
          `Pre OP to Exit ${aggregationTitle}`,
          'Pre Op to Exit Total',
          'Surgery Days',
          `FCOTS ${aggregation === 'average' ? 'Average' : 'Median'}`,
        ],
      },
    ],
    rows: (data || [])
      .map(e => ({
        id: e?.id.toString(),
        columns: [
          e.physician,
          e.numberOfProcedures.toString(),
          getMinutes(aggregation === 'average' ? e.pipoAvg : e.pipoMed),
          getMinutes(aggregation === 'average' ? e.popiPhysicianAvg : e.popiPhysicianMed),
          getMinutes(aggregation === 'average' ? e.piToStartAvg : e.piToStartMed),
          getMinutes(aggregation === 'average' ? e.startToCloseAvg : e.startToCloseMed),
          getMinutes(aggregation === 'average' ? e.poToCutAvg : e.poToCutMed),
          getMinutes(e.firstPIToLastPOTotal),
          getMinutes(e.pipoTotal),
          `${(e.pipoOverE2EPercentage * 100).toFixed(2)}%`,
          getMinutes(aggregation === 'average' ? e.wrAvg : e.wrMed),
          getMinutes(aggregation === 'average' ? e.preOpAvg : e.preOpMed),
          getMinutes(aggregation === 'average' ? e.recoveryAvg : e.recoveryMed),
          ...(hasPostOp ? [getMinutes(aggregation === 'average' ? e.postOpAvg : e.postOpMed)] : []),
          getMinutes(aggregation === 'average' ? e.preOpToExitAvg : e.preOpToExitMed),
          getMinutes(e.preOpToExitTotal),
          e.surgeryDays.toString(),
          formatDurationInMinutes(aggregation === 'average' ? e.fcotsDelayAvg : e.fcotsDelayMed, 1),
        ],
        onClick,
      }))
      .concat([totalRow]),
  };
};

const toPieChartData = (totals: PhysicianUtilizationTableTotalsData): PieChartData => ({
  subtitle: isFinite(totals.numberOfProcedures)
    ? totals.numberOfProcedures === 1
      ? '1 procedure'
      : `${totals.numberOfProcedures} procedures`
    : '-',
  inOr: totals.inOrAvg,
  inSurgery: totals.inSurgeryAvg,
  closing: totals.closingAvg,
  procedureComplete: totals.procedureCompleteAvg,
  cleaning: totals.cleaningAvg,
  orReady: totals.orReadyAvg,
});

const PhysicianUtilizationAnalytics: FC<{ filter: any; setFilter: any }> = ({ filter, setFilter }) => {
  const dateRangeJSON = filter?.dateRange?.toJSON();
  const dateRange = { from: format(dateRangeJSON.from, 'YYYY-MM-DD'), to: format(dateRangeJSON.to, 'YYYY-MM-DD') };

  const { data, loading } = useQuery(physicianUtilizationAnalyticsQuery, {
    variables: { dateRange },
    fetchPolicy: 'cache-and-network',
  });
  const scope: any = useScope();
  const hasPostOp = scope?.hospital?.modules?.hasPostop;
  const analyticsV2PieCharts = scope?.hospital?.modules?.analyticsV2PieCharts || false;
  const [physicianId, setPhysicianId] = useState<{
    idNumber: number;
    idString: string;
  } | null>(null);

  const { data: totalsData, loading: totalsLoading } = useQuery(physicianUtilizationAnalyticsTotalsQuery, {
    variables: { dateRange },
    fetchPolicy: 'cache-and-network',
  });
  const onPhysicianClick = (physicianId: string) => {
    const id = physicianId?.split('#')?.[0];
    setPhysicianId({ idNumber: Number(id), idString: physicianId });
  };

  const physicianUtilizationAnalyticsTotals = totalsData?.physicianUtilizationAnalyticsTotals;

  const table: TableType = toTableData(
    data?.physicianUtilizationAnalytics || [],
    physicianUtilizationAnalyticsTotals || [],
    onPhysicianClick,
    hasPostOp
  );

  const pieChartData = toPieChartData(
    data?.physicianUtilizationAnalytics || [],
    totalsData?.physicianUtilizationAnalyticsTotals || []
  );

  const onBack = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setPhysicianId(null);
  };

  const theme = useTheme();

  if (physicianId) {
    const physicianAnalytics = (data?.physicianUtilizationAnalytics || []).find(e => e?.id === physicianId.idString);

    const fcotsDelayAvg = physicianAnalytics?.fcotsDelayAvg;
    const fcotsDelayDist = physicianAnalytics?.fcotsDelayDist;
    const fcotsDelayReasons = physicianAnalytics?.fcotsDelayReasons;

    return (
      <>
        <Button variant="contained" startIcon={<ArrowBack />} onClick={onBack} style={{ marginBottom: 8 }}>
          Back to Physician Utilization Analytics
        </Button>

        {analyticsV2PieCharts && (
          <Grid container spacing={1} style={{ height: '100%', marginBottom: 8 }}>
            <Grid item lg={4} xs={6}>
              <ChartWrapper title="Physician Utilization">
                <PhysicianUtilizationChart value={physicianUtilizationAnalyticsTotals?.pipoOverE2EPercentage || 0} />
              </ChartWrapper>
            </Grid>
            <Grid item lg={4} xs={6}>
              <ChartWrapper
                title="FCOTS Delay"
                header={
                  <Typography variant="h1">{fcotsDelayAvg ? Math.round(fcotsDelayAvg) + ' min' : 'n/a'}</Typography>
                }
              >
                <ResponsiveContainer width="100%" height="100%" aspect={2.5}>
                  <BarChart data={fcotsDelayDist?.map((value, i) => ({ value, title: titles[i] })) ?? []}>
                    <Bar dataKey="value" fill="#00A7F7" />
                    <XAxis type="category" dataKey="title" stroke="rgba(255,255,255,0.25)" />
                  </BarChart>
                </ResponsiveContainer>
              </ChartWrapper>
            </Grid>
            <Grid item lg={4} xs={6}>
              <ChartWrapper title="Delays">
                <Box display="flex" flexDirection="column">
                  <DelaysBreakdown reasons={fcotsDelayReasons ?? []} />
                </Box>
              </ChartWrapper>
            </Grid>
          </Grid>
        )}
        <PhysicianPatientsAnalytics
          physicianId={physicianId.idNumber}
          physicianName={physicianAnalytics?.physician}
          dateRange={dateRange}
        />
      </>
    );
  }

  if (loading) {
    return (
      <Box display="flex" alignItems="center" m={2} style={{ gap: '1em' }}>
        <CircularProgress size={30} />
        <Typography variant="body1" color="textSecondary">
          Loading...
        </Typography>
      </Box>
    );
  }

  const fcotsDelayAvg = totalsData?.physicianUtilizationAnalyticsTotals?.fcotsDelayAvg;
  const fcotsDelayDist = totalsData?.physicianUtilizationAnalyticsTotals?.fcotsDelayDist;
  const fcotsDelayReasons = totalsData?.physicianUtilizationAnalyticsTotals?.fcotsDelayReasons;

  return (
    <>
      {analyticsV2PieCharts &&
        (physicianUtilizationAnalyticsTotals || fcotsDelayAvg || fcotsDelayDist || fcotsDelayReasons) && (
          <Grid container spacing={1} style={{ marginBottom: 8 }}>
            <Grid item lg={4} xs={6}>
              <ChartWrapper title="Physician Utilization">
                <PhysicianUtilizationChart value={physicianUtilizationAnalyticsTotals?.pipoOverE2EPercentage || 0} />
              </ChartWrapper>
            </Grid>

            <Grid item lg={4} xs={6}>
              <ChartWrapper
                title="FCOTS Delay"
                header={
                  <Typography variant="h1">{fcotsDelayAvg ? Math.round(fcotsDelayAvg) + ' min' : 'n/a'}</Typography>
                }
              >
                <ResponsiveContainer width="100%" height="100%" aspect={2.5}>
                  <BarChart data={fcotsDelayDist?.map((value, i) => ({ value, title: titles[i] })) ?? []}>
                    <Bar
                      dataKey="value"
                      fill="#00A7F7"
                      data={fcotsDelayDist?.map((value, i) => ({ value, title: titles[i] })) ?? []}
                    />
                    <XAxis type="category" dataKey="title" stroke="rgba(255,255,255,0.25)" />
                    <Tooltip
                      content={({ active, payload, label }) => (
                        <CustomTooltip active={active} payload={payload} label={label} unit="case" useTitle />
                      )}
                      cursor={{ fill: 'rgba(255,255,255,0.15)' }}
                      contentStyle={{
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: 4,
                        border: 0,
                      }}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </ChartWrapper>
            </Grid>

            <Grid item lg={4} xs={6}>
              <ChartWrapper title="Delays">
                <DelaysBreakdown reasons={fcotsDelayReasons ?? []} />
              </ChartWrapper>
            </Grid>
          </Grid>
        )}
      <TableWithColumnSelector
        configName="physician-table-config"
        tableId="Physician-Analysis-table"
        tableName={`Analysis by Physician`}
        excelFileName={`Physician-Analysis-${format(dateRange.from, 'MM/DD/YYYY')}-${format(
          dateRange.to,
          'MM/DD/YYYY'
        )}`}
        {...table}
      />
    </>
  );
};

export default PhysicianUtilizationAnalytics;

export const formatDurationInMinutes = (duration?: number, decimals: number = 0) => {
  if (duration === undefined) {
    return '-';
  }

  return Math.round(duration).toFixed(decimals) + ' ' + (duration === 1 ? 'min' : 'mins');
};

const titles = ['0–5m', '5–10m', '10–20m', '20–30m', '30m+'];
