import React, { FC, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { dark as darkTheme } from './theme';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles';
import { muiThemeDark } from './muiTheme';
import { CssBaseline } from '@material-ui/core';

const DarkThemeProviderWrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <ThemeProvider theme={darkTheme}>
    <MUIThemeProvider theme={muiThemeDark}>
      <CssBaseline/>
      {children}
    </MUIThemeProvider>
  </ThemeProvider>
);

export default DarkThemeProviderWrapper;
