import { useChartingSession } from './hooks';
import { useQuery } from '@apollo/client';
import { list } from '../../../../../graph/config';
import { ConfigType, PatientChartButtonsConfig } from '../../../../../types/HospitalConfig';
import { tryParseJson } from '../../../../../util/parseJson';
import { ChartingSession } from './chartingStore';
import { Tab } from '../../../../core/TabRoutes';
import { Charting } from '../ChartingPatientsNavigation';
import { QuestionnaireType } from '../../../../../types/Questionnaire';
import getPatientChartTabs from './getPatientChartTabs';

const defaultTabs = (chartingSession?: ChartingSession | null): Tab[] => [
  {
    title: 'PreOp Charting',
    path: '/preop',
    component: Charting(null, QuestionnaireType.PreOpChart, chartingSession),
  },
  {
    title: 'OR Charting',
    path: '/or',
    component: Charting(null, QuestionnaireType.OrChart, chartingSession),
  },
  {
    title: 'PACU Charting',
    path: '/pacu',
    component: Charting(null, QuestionnaireType.PacuChart, chartingSession),
  },
  {
    title: 'Physician Charting',
    path: '/physician',
    component: Charting(null, QuestionnaireType.PhysicianChart, chartingSession),
  },
  {
    title: 'Anesthesiologist PreOp Charting',
    path: '/anesthesiologist-preop',
    component: Charting(null, QuestionnaireType.AnesthesiologistPreOpChart, chartingSession),
  },
  {
    title: 'Anesthesiologist OR Charting',
    path: '/anesthesiologist-or',
    component: Charting(null, QuestionnaireType.AnesthesiologistOrChart, chartingSession),
  },
  {
    title: 'Anesthesiologist PACU Charting',
    path: '/anesthesiologist-pacu',
    component: Charting(null, QuestionnaireType.AnesthesiologistPacuChart, chartingSession),
  },
];

const useTabs = () => {
  const chartingSession = useChartingSession();

  const { data: configData } = useQuery(list, {
    variables: {
      type: ConfigType.PatientChartButtons,
    },
  });
  const tabsConfig: PatientChartButtonsConfig | null = tryParseJson(configData?.configs?.[0]?.values);

  return tabsConfig ? getPatientChartTabs(tabsConfig, chartingSession) : defaultTabs(chartingSession);
};

export default useTabs;
