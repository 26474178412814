import { differenceInMinutes } from 'date-fns';

export const getColor = (status: string, hospitalId?: number, defaultColor: string | null = '#00A7F7') => {
  const applyColor =
    (window.location.hostname === 'localhost' && hospitalId === 10) ||
    (window.location.hostname === 'testing.ospitek.com' && hospitalId === 10) ||
    (window.location.hostname === 'view3.ospitek.com' && hospitalId === 3) ||
    (window.location.hostname === 'view4.ospitek.com' && hospitalId === 2);

  if (applyColor) {
    switch (status) {
      case 'WR':
        return '#8a5117';
      case 'PRE-OP':
        return 'rgb(255, 142, 161, 75%)';
      case 'OR':
        return '#0047AB';
      case 'AnestStart':
      case 'TimeOut':
      case 'Ready':
      case 'Ongoing':
        return '#00FF00';
      case 'CallNextPatient':
        return '#00FF00';
      case 'Closing':
        return '#ffbf00';
      case 'SurgeonLeftOR':
        return '#ffbf00';
      case 'Dressing':
        return '#A020F0';
      case 'End':
      case 'AnestEnd':
        return '#F55323';
      default:
        return defaultColor;
    }
  }

  return defaultColor;
};

export const getRelativeDuration = (dayStart: Date, duration: number, dayEnd: Date) => {
  if (duration) {
    return (duration * 100) / differenceInMinutes(dayEnd, dayStart);
  } else {
    return 10;
  }
};
