import TextField from '@material-ui/core/TextField';
import React, { FC } from 'react';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';

export const DateInput: FC<{ min?: string; max?: string } & TextFieldProps> = ({ min, max, ...rest }) => (
  <TextField
    {...rest}
    type="date"
    variant="filled"
    margin="dense"
    InputProps={{
      inputProps: {
        min: min,
        max: max,
      },
    }}
    InputLabelProps={{
      shrink: true,
    }}
    fullWidth
  />
);
