import { Box } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface CardProcedureTypeProps {
  procedureType: string;
}

const CardProcedureType = ({ procedureType }: CardProcedureTypeProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <span>{procedureType}</span>
    </Box>
  );
};

export const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '0.75em',
    fontWeight: 'bold',
    paddingLeft: '0.25em',
    paddingRight: '0.25em',
  },
}));

export default CardProcedureType;
