import { LocalDate } from '@js-joda/core';
import { format } from 'date-fns';

const formatRelative = (date: Date): string => {
  const localDate = LocalDate.of(date.getFullYear(), date.getMonth() + 1, date.getDate());
  if (localDate.equals(LocalDate.now())) {
    return 'Today';
  } else if (localDate.equals(LocalDate.now().plusDays(1))) {
    return 'Tomorrow';
  } else {
    return format(date, 'ddd, D MMM');
  }
};

export default formatRelative;
