import React, { Fragment } from 'react';

import HtmlInput from '../html/Input';
import InputError from '../errors/InputError';

export default ({
  Input = HtmlInput,
  Error = InputError,
  placeholder = '',
  isHidden,
  autoFocus,
  value,
  onChange,
  isDisabled,
  name = 'text',
  error,
  hasError,
  htmlType,
  maxLength,
  autocomplete,
  ...rest
}) => (
  <Fragment>
    <Input
      id={name}
      type={isHidden ? 'hidden' : htmlType ? htmlType : 'text'}
      name={name}
      autoComplete={autocomplete === false ? 'off' : 'on'}
      autoCapitalize="off"
      autoCorrect="off"
      placeholder={placeholder}
      autoFocus={autoFocus || false}
      value={value || ''}
      checked={!!value}
      onChange={e => {
        const nextValue = htmlType === 'checkbox' ? e.target.checked : e.target.value;
        onChange(isFinite(maxLength) ? nextValue.substring(0, maxLength) : nextValue);
      }}
      disabled={isDisabled}
      hasError={hasError}
      {...rest}
    />
    <Error isVisible={hasError} id={name}>
      {hasError ? error : ''}
    </Error>
  </Fragment>
);
