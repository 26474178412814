import React, { Fragment } from 'react';
import { ArrowIcon, ListItemLink } from '../../../se/components/collection/mobile/ListItem';
import { Row, Column, Label, Value } from '../../../se/components/collection/mobile/shared';
import RouterLink from '../../../se/components/RouterLink';

const HospitalMobileListItem = ({
  history,
  location,
  columns,
  item,
  context,
  index: i,
  showUrlProvider,
  idProvider,
  onClick,
}) => {
  if (item.separator) {
    return null;
  }
  const nameColumn = columns.find(_ => _.key === 'name');
  const addressColumn = columns.find(_ => _.key === 'address');
  const contactColumn = columns.find(_ => _.key === 'contact');

  return (
    <Fragment>
      <ListItemLink
        as={showUrlProvider ? RouterLink : undefined}
        to={
          showUrlProvider
            ? {
                pathname: showUrlProvider(idProvider(item)),
                search: location.search,
              }
            : undefined
        }
        onClick={onClick}
      >
        <ArrowIcon />
        <Row style={{ marginBottom: '0.75rem' }}>
          {nameColumn && (
            <Value trimText>
              <nameColumn.Component data={nameColumn.lens(item, context)} />
            </Value>
          )}
        </Row>
        <Column style={{ marginBottom: '0.75rem' }}>
          <Label>{addressColumn.title}</Label>
          <Value trimText>
            <addressColumn.Component data={addressColumn.lens(item, context)} />
          </Value>
        </Column>
        <Column>
          <Label>{contactColumn.title}</Label>
          <Value trimText>
            <contactColumn.Component data={contactColumn.lens(item, context)} />
          </Value>
        </Column>
      </ListItemLink>
    </Fragment>
  );
};

export default HospitalMobileListItem;
