import Box from '@material-ui/core/Box';
import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { createStyles, fade, Link, makeStyles, Popover } from '@material-ui/core';
import get from 'lodash/get';
import { compose } from 'recompose';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import purple from '@material-ui/core/colors/purple';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { hourToTime, timeToHour } from '../../../entities/schedule/util/time';
import { withScope } from '../../../../contexts/ScopeContext';

const Procedure = ({ scope, location, from, to, procedure, onClick, ...props }) => {
  const classes = useStyles();
  const procedureStart = get(procedure, 'serviceTime', '');
  const duration = get(procedure, 'duration', '');
  const procedureEnd = hourToTime(timeToHour(procedureStart) + duration / 60);
  const patientName = get(procedure, 'patientName', '');
  const patientId = get(procedure, 'patient.id', '');
  const patientAge = get(procedure, 'patient.age', '');
  const patientSex = get(procedure, 'patient.sex', '');
  const procedureType = get(procedure, 'procedureType', '');
  const physicianName = get(procedure, 'patient.physician.name', '');
  const communal = get(procedure, 'booking.forOrganizationId') !== get(scope, 'hospital.id');
  const other = get(procedure, 'organizationId') !== get(scope, 'hospital.id');

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return other ? (
    <Box flex={1} className={clsx(classes.root, { [classes.communal]: communal, [classes.other]: true })}>
      <Box color="warning.light">
        <LockOutlinedIcon fontSize="small" />
      </Box>
    </Box>
  ) : (
    <Fragment>
      <Box
        flex={1}
        component={Link}
        href={location.pathname.replace('/schedule-procedure', `/surgeries/${patientId}`)}
        style={{ textDecoration: 'none' }}
        onClick={() => onClick({ from, to, procedure, ...props })}
        className={clsx(classes.root, { [classes.communal]: communal })}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Typography className={classes.bookingTitle} variant="caption">
          {physicianName}
        </Typography>
      </Box>

      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        disableRestoreFocus
      >
        <Box p={2} minWidth={300}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="caption" color="textSecondary" gutterBottom>
                Scheduled for
              </Typography>
              <Typography variant="body2">
                {procedureStart} – {procedureEnd}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="caption" color="textSecondary" gutterBottom>
                Physician
              </Typography>
              <Typography variant="body2">{physicianName}</Typography>
            </Grid>

            <Grid item>
              <Typography variant="caption" color="textSecondary" gutterBottom>
                Patient
              </Typography>
              <Box display="flex" justifyContent="space-between">
                <Box mr={2}>
                  <Typography>{patientName}</Typography>
                </Box>
                <Typography color="textSecondary">
                  {patientAge}/{patientSex}
                </Typography>
              </Box>
              <Typography variant="body2">{procedureType}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </Fragment>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.primary,
      backgroundColor: fade(theme.palette.primary.dark, 1),
      paddingLeft: theme.spacing(0.5),
      borderRadius: theme.shape.borderRadius,
      border: `2px solid ${theme.palette.primary.main}`,
      borderLeftWidth: `.5rem`,
      minWidth: 0,

      transition: 'background .05s ease-in-out',
      cursor: 'pointer',
      outline: 'none',

      '&:hover': {
        backgroundColor: fade(theme.palette.primary.main, 0.5),
      },
    },
    bookingTitle: {
      lineHeight: 1.15,
      fontWeight: theme.typography.fontWeightMedium,
      minWidth: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 2,
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
    communal: {
      backgroundColor: fade(purple[500], 0.5),
      borderColor: fade(purple[500], 0.5),

      '&:hover': {
        backgroundColor: fade(purple[500], 0.5),
      },
    },
    other: {
      backgroundColor: fade(purple[500], 0.25),
      borderColor: fade(purple[500], 0.25),
      cursor: 'initial',

      '&:hover': {
        backgroundColor: fade(purple[500], 0.25),
      },
    },
  })
);

export default compose(withRouter, withScope)(Procedure);
