import React, { useState, useEffect } from 'react';
import Icon from '../core/Icon';
import withSize from '../../hocs/withSize';
import styled from 'styled-components';
import { Text, TruncatedText } from '../../se/components/typography';

const Inline = styled.div`
  display: flex;
  width: 100%;
`;

const IconButton = styled(Icon)`
  cursor: pointer;
  opacity: 0.6;
  transition: all 0.3s;
  padding: 0 0.3em;
  margin: 0;
  :hover {
    opacity: 0.8;
  }
  user-select: none;
  ${props => props.expanded && `transform: rotateX(180deg);`}
`;

const SizeAwareText = withSize(({ text, horizontalOverflow, onOverflow, indicator }) => {
  useEffect(() => {
    if (indicator !== horizontalOverflow) {
      onOverflow(horizontalOverflow);
    }
  }, [horizontalOverflow, indicator, onOverflow]);

  return <TruncatedText>{text}</TruncatedText>;
});

export default ({ text, onClick }) => {
  const [expanded, setExpanded] = useState(false);
  const [overflow, setOverflow] = useState(false);

  return (
    <Inline
      onClick={e => {
        e.stopPropagation();
        setExpanded(!expanded);
        if (onClick) {
          onClick(e);
        }
      }}
    >
      {expanded ? (
        <Text style={{ whiteSpace: 'pre-line' }}>{text}</Text>
      ) : (
        <SizeAwareText text={text} onOverflow={setOverflow} indicator={overflow} />
      )}
      {overflow && (
        <IconButton className={'material-icons'} expanded={expanded} onClick={() => setExpanded(!expanded)}>
          expand_more
        </IconButton>
      )}
    </Inline>
  );
};
