import React from 'react';
import styled from 'styled-components';
import LinkButton from '../../../../se/components/LinkButton';

const TitleBar = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  padding: 1.75rem 0;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: 1.5rem;
`;

export default ({ onCancel, title, bedInput }) => (
  <TitleBar>
    {bedInput ? (
      <div style={{ display: 'flex' }}>
        <Title style={{ marginRight: '1em' }}>{title}</Title>
        {bedInput}
      </div>
    ) : (
      <Title>{title}</Title>
    )}

    <LinkButton onClick={onCancel}>Cancel</LinkButton>
  </TitleBar>
);
