import { getAge } from '../../../../../../questionnaire/toAge';
import { parse } from 'date-fns';
import { calculateBMI } from '../../../../../../questionnaire/toBMI';

const getAgeAndBmi = (dob, bmiObject) => {
  const formattedDob = dob?.split('/').reverse().join('/');
  const age = getAge(parse(formattedDob, 'MM/DD/YYYY'));
  const bmi = calculateBMI(
    parseInt(bmiObject?.weightLb, 10),
    parseInt(bmiObject?.heightFt, 10),
    parseInt(bmiObject?.heightIn, 10)
  );

  return { age, bmi };
};

export default getAgeAndBmi;
