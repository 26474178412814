import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import theme from '../../../se/theme';
import { useMutation } from '@apollo/client';
import { markTransferIssueConfirmed, markTransferIssueResolved } from '../../../graph/scheduleRequests';
import { format } from 'date-fns';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { ZonedDateTime } from '@js-joda/core';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const RequestedDocumentsContainer = ({ requestedDocuments, isSO }) => {
  const classes = useStyles();
  const [handleMarkTransferIssueResolved, { loading: loadingMarkAsResolved }] = useMutation(markTransferIssueResolved);
  const [handleMarkTransferIssueConfirmed, { loading: loadingMarkAsConfirmed }] =
    useMutation(markTransferIssueConfirmed);
  const [clicked, setClicked] = useState([]);

  return (
    <Box display="flex" flexDirection="column">
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            {requestedDocuments.map(requestDocument => (
              <TableRow hover key={requestDocument.id}>
                <TableCell component="th" scope="row">
                  <Box
                    display="flex"
                    alignItems="center"
                    color={requestDocument.resolved ? 'text.disabled' : 'inherit'}
                    style={{ textDecoration: requestDocument.resolved ? 'line-through' : 'none' }}
                  >
                    <Box display="flex" mr={1}>
                      {requestDocument.resolved ? (
                        <CheckCircleOutlineIcon color="disabled" />
                      ) : (
                        <ErrorOutlineIcon color="error" />
                      )}
                    </Box>
                    {requestDocument.message}
                  </Box>
                </TableCell>
                <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                  {format(
                    ZonedDateTime.parse(requestDocument.raisedAt).toLocalDateTime().toString(),
                    'YYYY-MM-DD HH:mm'
                  )}
                </TableCell>
                <TableCell align="right">
                  <Box my={-1}>
                    {isSO ? (
                      requestDocument.resolved ? null : (
                        <Button
                          color="primary"
                          onClick={async () => {
                            setClicked(prevState => [...prevState, requestDocument.id]);
                            await handleMarkTransferIssueResolved({ variables: { id: requestDocument.id } });
                            setClicked(prevState => prevState.filter(e => e !== requestDocument.id));
                          }}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {loadingMarkAsResolved && !!clicked.find(e => e === requestDocument.id) && (
                            <CircularProgress size={20} style={{ marginRight: 4 }} />
                          )}
                          Mark as resolved
                        </Button>
                      )
                    ) : requestDocument.resolved ? (
                      <Button disabled startIcon={<CheckIcon />}>
                        Resolved
                      </Button>
                    ) : null}
                    {!isSO ? (
                      requestDocument.confirmed ? null : (
                        <Box ml={2}>
                          <Button
                            color="primary"
                            onClick={async () => {
                              setClicked(prevState => [...prevState, requestDocument.id]);
                              await handleMarkTransferIssueConfirmed({ variables: { id: requestDocument.id } });
                              setClicked(prevState => prevState.filter(e => e !== requestDocument.id));
                            }}
                          >
                            {loadingMarkAsConfirmed && !!clicked.find(e => e === requestDocument.id) && (
                              <CircularProgress size={20} style={{ marginRight: 4 }} />
                            )}
                            Mark as confirmed
                          </Button>
                        </Box>
                      )
                    ) : requestDocument.resolved ? (
                      <Typography color="textSecondary" style={{ marginLeft: theme.defaultSpacing }}>
                        Confirmed
                      </Typography>
                    ) : null}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RequestedDocumentsContainer;
