import { gql } from '@apollo/client';

export const enrollmentReader = gql`
  subscription {
    enrollmentReader {
      braceletId
      patientId
      batteryLevel
    }
  }
`;
