import React, { useMemo } from 'react';
import createReducer from '../../../../../../questionnaire/reducer';
import { format } from 'date-fns';
import styles from '../../styles';
import { useValue } from '../../ValueContext';
import createSelector from '../../../../../../questionnaire/selector';
import MedicalPassportField from '../../components/MedicalPassportField';
import toAge from '../../../../../../questionnaire/toAge';
import toSex from '../../../../../../questionnaire/toSex';
import MedicalPassportTextArea from '../../components/MedicalPassportTextArea';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import toStringWithoutQuestions from '../../../../../../questionnaire/toStringWithoutQuestions';
import { capitalize } from 'lodash';
import getAgeAndBmi from './pageUtils';
import Barcode from '../../../Barcode';

const medicalHistoryReducer = createReducer({
  Sections: sections => null,
  Object: fields => {
    const visibleFields = fields.filter(field => field.child);

    return visibleFields.length === 0 ? null : visibleFields.length === 1 ? (
      visibleFields[0].child
    ) : (
      <ul>
        {visibleFields.map((field, i) => (
          <li key={i}>{field.child}</li>
        ))}
      </ul>
    );
  },
  Text: (label, text) =>
    text ? (
      <p>
        {label}: {text}
      </p>
    ) : null,
  LongText: (label, text) =>
    text ? (
      <p>
        {label}: {text}
      </p>
    ) : null,
  Checkboxes: (label, checkboxes) => {
    const visibleCheckboxes = checkboxes.filter(checkbox => checkbox.checked);
    return visibleCheckboxes.length === 0 ? null : visibleCheckboxes.length === 1 ? (
      <div>
        <div>{visibleCheckboxes[0].label}</div>
        {visibleCheckboxes[0].child && <div>{visibleCheckboxes[0].child}</div>}
      </div>
    ) : (
      <ul style={{ listStyleType: 'circle' }}>
        {visibleCheckboxes.map((checkbox, i) => (
          <li key={i}>
            <div>{checkbox.label}</div>
            {checkbox.child && <div>{checkbox.child}</div>}
          </li>
        ))}
      </ul>
    );
  },
  List: (label, children) => {
    const visibleChildren = children.filter(child => child);
    return visibleChildren.length === 0 ? null : visibleChildren.length === 1 ? (
      visibleChildren[0]
    ) : (
      <ul>
        {visibleChildren.map((child, i) => (
          <li key={i}>{child}</li>
        ))}
      </ul>
    );
  },
  YesNo: (question, positive, child) => {
    const transformedQuestion = capitalize(
      question
        .replace(/\?/g, '')
        .replace(/^Do you have\s/g, '')
        .replace(/^Are you\s/g, '')
        .replace(/^Have you\s/g, '')
    );
    return positive ? (
      <div>
        <div>{transformedQuestion}</div>
        {child ? <div>{child}</div> : null}
      </div>
    ) : null;
  },
  OneOf: (label, options, answer) =>
    answer ? (
      <div>
        <div>
          {label}: {answer.label}
        </div>
        {answer.child && <div>{answer.child}</div>}
      </div>
    ) : null,
  Medication: value => (value ? <span>{value}</span> : null),
  BodyMassIndex: (heightFt, heightIn, weightLb) => null,
  DateOfBirth: date => (date ? <span>{format(date, 'MM/DD/YYYY')}</span> : null),
  Date: date => (date ? <span>{format(date, 'MM/DD/YYYY')}</span> : null),
});

const ClinicalItem = ({ title, content }) =>
  content && (
    <>
      <h4 style={{ marginBottom: '-0.1em' }}>{title}</h4>
      <div style={{ marginLeft: '1em' }}>{content}</div>
    </>
  );

export const getSelectorContent = selectorValue => (selectorValue && medicalHistoryReducer(...selectorValue)) || null;

const ClinicalList = ({ questionnaire, questionnaireAnswers }) => {
  const {
    cardiac,
    pulmonary,
    renal,
    hepatic,
    neurologic,
    gi,
    vascular,
    endocrine,
    musculoskeletal,
    psychiatric,
    oncologic,
    infectious,
    pain,
    female,
    physicalActivity,
  } = useMemo(
    () => ({
      cardiac: getSelectorContent(createSelector(['Cardiac'])(questionnaire, questionnaireAnswers)),
      pulmonary: getSelectorContent(createSelector(['Pulmonary'])(questionnaire, questionnaireAnswers)),
      renal: getSelectorContent(createSelector(['Renal'])(questionnaire, questionnaireAnswers)),
      hepatic: getSelectorContent(createSelector(['Hepatic'])(questionnaire, questionnaireAnswers)),
      neurologic: getSelectorContent(createSelector(['Neurologic'])(questionnaire, questionnaireAnswers)),
      gi: getSelectorContent(createSelector(['GI'])(questionnaire, questionnaireAnswers)),
      vascular: getSelectorContent(createSelector(['Vascular'])(questionnaire, questionnaireAnswers)),
      endocrine: getSelectorContent(createSelector(['Endocrine'])(questionnaire, questionnaireAnswers)),
      musculoskeletal: getSelectorContent(createSelector(['Musculoskeletal'])(questionnaire, questionnaireAnswers)),
      psychiatric: getSelectorContent(createSelector(['Psychiatric'])(questionnaire, questionnaireAnswers)),
      oncologic: getSelectorContent(createSelector(['Oncologic'])(questionnaire, questionnaireAnswers)),
      infectious: getSelectorContent(createSelector(['Infectious'])(questionnaire, questionnaireAnswers)),
      pain: getSelectorContent(createSelector(['Pain'])(questionnaire, questionnaireAnswers)),
      female: getSelectorContent(createSelector(['basicscreening', 'female'])(questionnaire, questionnaireAnswers)),
      physicalActivity: getSelectorContent(
        createSelector(['basicscreening', 'level of physical activity'])(questionnaire, questionnaireAnswers)
      ),
    }),
    [questionnaire, questionnaireAnswers]
  );

  return (
    <>
      <ClinicalItem title={'Female'} content={female} />
      <ClinicalItem title={'Level of Physical Activity'} content={physicalActivity} />
      <ClinicalItem title={'Cardiac (Heart)'} content={cardiac} />
      <ClinicalItem title={'Pulmonary (Lung)'} content={pulmonary} />
      <ClinicalItem title={'Genitourinary'} content={renal} />
      <ClinicalItem title={'Hepatic (Liver)'} content={hepatic} />
      <ClinicalItem title={'Neurologic (Nervous System)'} content={neurologic} />
      <ClinicalItem title={'GI (Stomach and Intestines)'} content={gi} />
      <ClinicalItem title={'Vascular diseases'} content={vascular} />
      <ClinicalItem title={'Endocrine (Glands)'} content={endocrine} />
      <ClinicalItem title={'Musculoskeletal (Muscles/Bones)'} content={musculoskeletal} />
      <ClinicalItem title={'Psychiatric/Social'} content={psychiatric} />
      <ClinicalItem title={'Oncologic (Cancer)'} content={oncologic} />
      <ClinicalItem title={'Infectious Diseases'} content={infectious} />
      <ClinicalItem title={'Pain'} content={pain} />
    </>
  );
};

const Page1 = ({
  formName,
  pageNumber,
  showQRCode,
  hospitalName,
  value,
  defaultValue,
  lastPreOpCompletedEvent,
  ...props
}) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const { questionnaire, questionnaireAnswers } = useValue();

  const { age, bmi } = getAgeAndBmi(
    defaultValue?.demographicInformation?.dob,
    defaultValue?.demographicInformation?.bmi
  );
  let color = bmi > 35 ? 'red' : 'inherit';
  let bmiFlag = bmi === '-' ? false : true;
  return (
    <div id="page1" className="page">
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '50% 50%',
          gridGap: '1rem',
          alignContent: 'start',
          marginBottom: '1rem',
        }}
      >
        <div style={styles.div}>
          <h3>{(hospitalName || '').toUpperCase()}</h3>
          <MedicalPassportTextArea name="procedure.name" label="Procedure" style={{ display: 'flex' }} />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <div>
            {showQRCode && (
              <Barcode
                formName={formName}
                pageNumber={pageNumber}
                providerId={defaultValue?.procedure?.patient?.providerId}
                serviceTime={defaultValue?.procedure?.serviceTime}
              />
            )}
          </div>
          <div
            style={{
              border: '1px solid black',
              padding: '1rem',
              lineHeight: '25px',
            }}
          >
            <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={16} />
            <MedicalPassportField name="procedure.patient.providerId" label="MRN#" />
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '40% 60%',
                alignContent: 'start',
              }}
            >
              <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} />
              <MedicalPassportField
                name="age"
                label="Age"
                path={['demographicInformation', 'dob']}
                reducer={toAge}
                warning={age > 70}
              />
              <MedicalPassportField name="procedure.serviceTime" label="DOS" />
              <MedicalPassportField name="sex" label="Sex" path={['demographicInformation', 'sex']} reducer={toSex} />
            </div>
            <MedicalPassportField name="procedure.physician.name" label="Surgeon" />
            <MedicalPassportField
              name="procedure.pcp"
              path={['demographicInformation', 'primaryCareDoctor']}
              label="PCP"
            />
          </div>
        </div>
      </div>
      {lastPreOpCompletedEvent && (
        <div style={styles.div}>
          Pre-Op Performed By {lastPreOpCompletedEvent?.user?.name || ''}, Date{' '}
          {lastPreOpCompletedEvent?.timestamp ? format(lastPreOpCompletedEvent.timestamp, 'MM/DD/YYYY HH:mm') : ''}
        </div>
      )}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '50% 50%',
          gridGap: '1rem',
          alignContent: 'start',
        }}
      >
        <div
          style={{
            gridColumn: '1 / span 2',
            height: '1px',
            background: 'black',
          }}
        />
        <div>
          <div>
            <div style={{ fontWeight: 'bold' }}>CLINICALLY SIGNIFICANT ITEMS:</div>
            <div>{bmiFlag && <ClinicalItem title={'BMI'} content={<span style={{ color: color }}>{bmi}</span>} />}</div>
            <ClinicalList questionnaire={questionnaire} questionnaireAnswers={questionnaireAnswers} />
          </div>
        </div>
        <div style={styles.div}>
          <div style={{ fontWeight: 'bold' }}>PERSONAL INFORMATION:</div>
          <MedicalPassportField
            label="Primary Language"
            name="demographicInfoForm.primaryLanguage"
            path={['demographicInformation', 'primary language']}
            reducer={toStringWithoutQuestions}
          />
          <MedicalPassportCheckbox
            label="Okay to leave a message on machine."
            name="demographicInfoForm.okay.value"
            path={['demographicInformation', 'okay']}
          />
          <div>If not, where can we leave a message:</div>
          <MedicalPassportField
            name="demographicInfoForm.okay.message"
            path={['demographicInformation', 'okay', false]}
            reducer={toStringWithoutQuestions}
          />
          <MedicalPassportField
            label="Do you need a interpreter?"
            name="demographicInfoForm.interpreter"
            path={['demographicInformation', 'primary language']}
            reducer={toStringWithoutQuestions}
          />
          <div style={{ fontWeight: 'bold' }}>
            RESPONSIBLE PARTY: * You must have a responsible party stay with you for 24hrs after procedure.
          </div>
          <MedicalPassportField
            label="Name"
            name="contactInfoForm.responsibleParty.name"
            path={['contactinformation', 'responsibleparty', true, 'name']}
          />
          <MedicalPassportField
            label="Mobile Phone"
            name="contactInfoForm.responsibleParty.mobilePhone"
            path={['contactinformation', 'responsibleparty', true, 'Mobile Phone']}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div style={{ fontWeight: 'bold' }}>RIDE HOME INFORMATION:</div>
            <MedicalPassportCheckbox
              name="page1.sameAsResParty"
              label="Same as responsible party"
              style={{ display: 'flex', alignItems: 'center', fontSize: 14 }}
            />
          </div>
          <MedicalPassportField
            label="Name"
            name="contactInfoForm.rideHomeInformation.name"
            path={['contactinformation', 'ridehomeinformation', true, 'name']}
          />
          <MedicalPassportField
            label="Phone"
            name="contactInfoForm.rideHomeInformation.phone"
            path={['contactinformation', 'ridehomeinformation', true, 'phone']}
          />
        </div>
      </div>
    </div>
  );
};

export default Page1;
