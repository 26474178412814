import React, { useEffect, useState } from 'react';
import {
  alpha,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  makeStyles,
} from '@material-ui/core';
import { CheckboxesAnswer, YesCheckboxAnswerWithChildren } from '../../types/Answer';
import { CheckboxesQuestion } from '../../types/Question';
import { isBoolean } from '../../se/utilities/check';
import { useSave } from '../../components/pages/kiosk/charting/SaveProvider';
import isArray from 'lodash/isArray';
import { ConditionContext, useSlideIn } from '../Form';
import SubFormCard from '../components/SubFormCard';
import clsx from 'clsx';
import ReactHtmlParser from 'react-html-parser';

type CheckboxesInputProps = CheckboxesQuestion['props'] & {
  name?: string;
  answer?: CheckboxesAnswer;
  onChange?: (newAnswer: CheckboxesAnswer) => void;
};

const CheckboxesInput = ({
  label,
  checkboxes,
  grid,
  omitNone,
  optional,
  horizontal,
  horizontalChild = false,
  answer,
  onChange,
}: CheckboxesInputProps) => {
  const { validationRequest } = useSave();
  const slideIn = useSlideIn();
  const classes = useStyles();
  const hasChildren = checkboxes.some(checkbox => !!checkbox?.child);
  const [invalid, setInvalid] = useState(false);
  const completed = count(Object.entries(answer ?? {}), ([, value]) => (isArray(value) ? value[0] : value));

  console.debug('CheckboxesInput', grid);

  useEffect(() => {
    if (optional) {
      return;
    }

    if (validationRequest) {
      if (!answer || !(completed > 0)) {
        setInvalid(true);
        slideIn();
      }
    }
  }, [validationRequest, answer, optional, completed, slideIn]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changed = !omitNone
      ? { [event.target.name]: event.target.checked, none: false }
      : { [event.target.name]: event.target.checked };
    onChange?.(answer ? { ...answer, ...changed } : changed);
    setInvalid(false);
  };

  const clickNone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changed = { [event.target.name]: event.target.checked };
    onChange?.(changed);
    setInvalid(false);
  };

  return (
    <FormControl className={classes.formControl} data-component="CheckboxesInput" component="fieldset" error={invalid}>
      {label && <FormLabel component="legend">{ReactHtmlParser(label)}</FormLabel>}
      <FormGroup className={clsx(classes.formGroup, { [classes.formGroupGrid]: grid })} row={horizontal}>
        {checkboxes.map(checkbox => {
          const { name, label } = checkbox;
          const checkboxAnswer: boolean | YesCheckboxAnswerWithChildren | undefined = answer?.[name];
          const checkboxValue = isBoolean(checkboxAnswer)
            ? !!checkboxAnswer
            : !!(checkboxAnswer as YesCheckboxAnswerWithChildren)?.value;
          return !!checkbox?.child ? (
            horizontalChild ? (
              <Box ml={checkboxValue && horizontal ? 0 : -2} mr={checkboxValue ? 2 : 0}>
                <SubFormCard
                  key={name}
                  mainItem={
                    <FormControlLabel
                      style={{ whiteSpace: horizontal ? 'nowrap' : 'normal' }}
                      control={<Checkbox color="primary" checked={checkboxValue} onChange={handleChange} name={name} />}
                      label={ReactHtmlParser(label)}
                    />
                  }
                  showChildren={checkboxValue}
                  children={
                    checkbox?.child ? (
                      <ConditionContext.Provider value={true}>{checkbox?.child}</ConditionContext.Provider>
                    ) : undefined
                  }
                />
              </Box>
            ) : (
              // TODO Resolve these magic numbers
              <Box ml={-2} mr={grid ? (checkboxValue ? '7px' : '21px') : 0}>
                <SubFormCard
                  key={name}
                  mainItem={
                    <FormControlLabel
                      style={{ whiteSpace: horizontal ? 'nowrap' : 'normal' }}
                      control={<Checkbox color="primary" checked={checkboxValue} onChange={handleChange} name={name} />}
                      label={ReactHtmlParser(label)}
                    />
                  }
                  showChildren={checkboxValue}
                  children={
                    checkbox?.child ? (
                      <ConditionContext.Provider value={true}>{checkbox?.child}</ConditionContext.Provider>
                    ) : undefined
                  }
                />
              </Box>
            )
          ) : (
            <FormControlLabel
              key={name}
              control={<Checkbox color="primary" checked={checkboxValue} onChange={handleChange} name={name} />}
              label={ReactHtmlParser(label)}
            />
          );
        })}
        {!omitNone && (
          <FormControlLabel
            key="none"
            control={
              <Checkbox color="primary" checked={!!answer?.['none'] || false} onChange={clickNone} name="none" />
            }
            label="None"
          />
        )}
      </FormGroup>
    </FormControl>
  );
};

const useStyles = makeStyles(theme => ({
  formControl: {
    flex: 1,
  },
  formGroup: {
    marginTop: theme.spacing(1),
    alignItems: 'flex-start',
  },
  formGroupGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > *': {
      flex: '0 1 calc(25% - 16px + 11px)',
    },
  },
  card: {
    minWidth: 275,
    marginLeft: theme.spacing(-2),
    marginTop: theme.spacing(-0.5),
  },
  cardActive: {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    marginBottom: theme.spacing(1),
  },
  cardHide: {
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardContent: {
    paddingTop: theme.spacing(0.5),
    '&:last-child': {
      paddingBottom: theme.spacing(0),
    },
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const count = <T,>(arr: T[], predicate: (item: T) => boolean) =>
  arr.reduce((acc, item) => (predicate(item) ? acc + 1 : acc), 0);

export default CheckboxesInput;
