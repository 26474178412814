import omit from 'lodash/omit';
import { compose, withProps } from 'recompose';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import NameInput from '../NameInput';
import EmailInput from '../../../se/components/inputs/EmailInput';
import { CreatePasswordInput } from '../../../se/components/inputs/PasswordInput';

import RoleSelectInput from '../RoleSelectInput';
import { withLabel } from '../../../se/components/Label';
import PatientNameFormatInput from '../PatientNameFormatInput';
import { ADMIN, NURSE, PREOP_NURSE, SENSOR_SUPERVISOR, SUPER_ADMIN } from '../../entities/role/enums';
import { PhysicianNotificationNumbersInput } from '../NotificationNumbersInput';
import { OrganizationType } from '../../entities/hospitals/enums';
import { pick } from 'lodash/object';
import { withSession } from '../../../state/Session';
import { unpackSessionObject } from '../../pages/unpackSessionObject';
import { withScope } from '../../../contexts/ScopeContext';

const omitIf = (obj, cond) => (cond ? omit(obj, ['patientNameFormat', 'notificationNumbers']) : obj);

const omitKeyIf = (obj, key, cond) => (cond ? omit(obj, [key]) : obj);

const omitModules = (obj, modules) =>
  omitKeyIf(
    omitKeyIf(obj, SENSOR_SUPERVISOR, !modules.temperatureReporting),
    PREOP_NURSE,
    !modules.patientRegistration
  );

const organizationTypeRoles = ({ roleOptions, hospital, roles }) => {
  const isAdministrator = !!roles?.find(role => role === ADMIN || role === SUPER_ADMIN);
  if (hospital && hospital.type === OrganizationType.SurgeonOffice) {
    return pick(roleOptions, isAdministrator ? [ADMIN, NURSE] : [NURSE]);
  } else {
    return omitModules(
      isAdministrator ? omit(roleOptions, [NURSE]) : omit(roleOptions, [ADMIN, NURSE]),
      hospital?.modules || {}
    );
  }
};

export default (roleOptions, isCreate, isSuper) =>
  withProps({
    schema: omitIf(
      {
        name: NameInput,
        email: withProps({ autoComplete: 'off', required: true })(EmailInput),
        notificationNumbers: PhysicianNotificationNumbersInput,
        role: compose(
          withSession(unpackSessionObject),
          withScope,
          withProps(props => ({
            ...props,
            required: true,
            options: organizationTypeRoles({
              hospital: props?.scope?.hospital || {},
              roleOptions,
              roles: [...(props?.session?.membership?.metadata || []), ...(props?.session?.user?.metadata || [])],
            }),
          }))
        )(RoleSelectInput),
        patientNameFormat: withLabel('Patient Name Format')(PatientNameFormatInput),
      },
      isSuper
    ),
  })(ObjectInput);
