import { Box } from '@material-ui/core';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { pure } from 'recompose';

import Row from './Row';
import List from './List';
import EntityLoadingState from '../entity-states/EntityLoadingState';
import EntityErrorState from '../entity-states/EntityErrorState';
import EntityEmptyState from '../entity-states/EntityEmptyState';

import { isArray } from '../../utilities/check';

const State = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

// https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-networkStatus
const apolloNetworkStatus = {
  onVariablesChange: 2,
};

const asState = Component => props =>
  (
    <State>
      <Component {...props} />
    </State>
  );

const StateWrapper = ({ data, getList, Loading, Error, Empty, children }) => {
  const isLoading = data.loading && data.networkStatus !== apolloNetworkStatus.onVariablesChange;
  const hasError = !!data.error;
  const isEmpty = !isArray(getList(data)) || !getList(data).length;

  const ErrorState = asState(Error);
  const LoadingState = asState(Loading);
  const EmptyState = asState(Empty);

  if (isLoading && isEmpty) {
    return <LoadingState />;
  } else if (hasError) {
    return <ErrorState error={data.error} />;
  } else if (isEmpty) {
    return (
      <Box flex={1} py={12}>
        <EmptyState />
      </Box>
    );
  } else {
    return children;
  }
};

const CollectionListOnly = ({
  match,
  location,
  history,
  data,
  getList,
  columns,
  defaultDisplayColumns,
  hideColumns,
  View,
  onViewClick,
  showUrlProvider,
  idProvider,
  Loading,
  Error,
  Empty,
  FilterComponent,
  FooterComponent,
  filter,
  setFilter,
  highlightedProvider,
  highlightedRowStyles,
  highlightedRowStyleProvider,
  AdditionalBlock,
  containsSeparator,
  useColumnSelection,
  useCSVExport,
  MobileItemComponent,
  tableKey,
  simpleFilter,
  defaultSort,
  searchSource,
}) => (
  <Fragment>
    {FilterComponent && setFilter && (
      <FilterComponent onChange={setFilter} value={filter} searchSource={searchSource} />
    )}

    <StateWrapper Loading={Loading} Error={Error} Empty={Empty} getList={getList} data={data}>
      <Fragment>
        {AdditionalBlock && <AdditionalBlock filter={filter} />}
        <List
          Component={View}
          getList={getList}
          data={data}
          history={history}
          location={location}
          hideColumns={hideColumns}
          columns={columns}
          defaultDisplayColumns={defaultDisplayColumns}
          showUrlProvider={showUrlProvider}
          idProvider={idProvider}
          onViewClick={onViewClick}
          highlightedProvider={highlightedProvider}
          highlightedRowStyles={highlightedRowStyles}
          highlightedRowStyleProvider={highlightedRowStyleProvider}
          containsSeparator={containsSeparator}
          tableKey={tableKey}
          defaultSort={defaultSort}
          useColumnSelection={useColumnSelection}
          useCSVExport={useCSVExport}
          MobileItemComponent={MobileItemComponent}
          simpleFilter={!(FilterComponent && setFilter) && simpleFilter}
        />
      </Fragment>
    </StateWrapper>
    {FooterComponent && <FooterComponent />}
  </Fragment>
);

CollectionListOnly.defaultProps = {
  data: {},
  View: Row,
  Loading: EntityLoadingState,
  Error: EntityErrorState,
  Empty: EntityEmptyState,
  containsSeparator: false,
  useColumnSelection: false,
  useCSVExport: false,
};

CollectionListOnly.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      lens: PropTypes.func.isRequired,
      Component: PropTypes.func.isRequired,
    }).isRequired
  ).isRequired,
  data: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.any,
  }).isRequired,
  View: PropTypes.func,
  onViewClick: PropTypes.func,
  showUrlProvider: PropTypes.func,
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  highlightedProvider: PropTypes.func,
  highlightedRowStyles: PropTypes.object,
  highlightedRowStyleProvider: PropTypes.func,
  AdditionalBlock: PropTypes.func,
  containsSeparator: PropTypes.bool,
  tableKey: PropTypes.string,
  useColumnSelection: PropTypes.bool,
  simpleFilter: PropTypes.bool,
  defaultSort: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.oneOf(['ASC', 'DESC']),
  }),
  searchSource: PropTypes.object,
};

export default pure(CollectionListOnly);
