import React from 'react';
import styled from 'styled-components';
import { isArray } from '../../se/utilities/check';

const asArray = object => (isArray(object) ? object : [object]);

const DIRECTION = {
  LEFT: -1,
  RIGHT: 1,
};

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 auto;
  position: relative;
  width: ${props => props.panels * 100}%;
`;

const Panel = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translateX(${props => `${(props.idx || 0) * -100 + (props.visible ? 0 : (props.direction || 1) * 100)}%`});
  transition: 400ms all ease-in;
  transition-delay: 100ms;
  overflow: hidden;
  flex: 1;

  > * {
    margin: 0.25rem 0;
  }
`;

class Carousel extends React.PureComponent {
  state = { current: 0, panels: [] };

  UNSAFE_componentWillMount() {
    this.mounted = true;
    setTimeout(this.changeSlide(this), this.props.interval);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  changeSlide = that => () => {
    if (that.mounted) {
      that.setState({
        current: (that.state.current + 1) % that.slideCount(asArray(that.props.children), that.props.size),
      });
      setTimeout(that.changeSlide(that), that.props.interval);
    }
  };

  slideCount = (items, size) => Math.ceil(items.length / size);

  partition = (items = [], size) =>
    [...Array(this.slideCount(items, size))].map((_, idx) => items.slice(idx * size, idx * size + size));

  render() {
    const { children, size } = this.props;
    const panels = this.partition(asArray(children), size);
    return (
      <Container panels={this.slideCount(children, size)}>
        {panels.map((panel, idx) => (
          <Panel
            idx={idx}
            visible={idx === this.state.current}
            direction={
              idx - this.state.current === 1 || (this.state.current - idx === panels.length - 1 && panels.length > 2)
                ? DIRECTION.LEFT
                : DIRECTION.RIGHT
            }
            key={idx}
          >
            {panel}
          </Panel>
        ))}
      </Container>
    );
  }
}

export default Carousel;
