import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

export const Column = styled(Row)`
  flex-direction: column;
`;

export const TwoItemSection = styled(Row)`
  font-size: 0.9rem;
`;

export const Label = styled.div`
  opacity: 0.8;
  color: ${p => p.theme.textColor.darken(0.5).string()};
  margin-bottom: 0.25rem;
`;

export const Value = styled.div`
  color: ${p => p.theme.textColor.string()};
  line-height: 1.25;
  ${p =>
    p.trimText &&
    `  
    white-space: normal !important;
    > div {
      white-space: normal !important;
    }
    span {
      white-space: normal !important;
    }  
  `}
`;

export const WrapValue = styled(Value)`
  word-break: break-all;
  padding-right: 1em;
`;

export const SubtleValue = styled(Label)`
  margin-bottom: 0;
  margin-left: 0.5rem;
  font-size: 0.9rem;
`;
