import toString from './toString';
import { capitalize } from 'lodash';

const toHighlightsString = toString.with({
  Object: fields => {
    const visibleFields = fields.filter(field => field.child);

    return visibleFields.length === 0
      ? null
      : visibleFields.length === 1
      ? visibleFields[0].child
      : visibleFields
          .map(field => field.child)
          .map(c => {
            const rest = c!.startsWith('· ') ? c!.substr(2) : c!;
            return '· ' + rest;
          })
          .join('\n');
  },
  Checkboxes: (label, checkboxes) => {
    const visibleCheckboxes = checkboxes.filter(checkbox => checkbox.checked);

    return visibleCheckboxes.length === 0
      ? null
      : visibleCheckboxes.length === 1
      ? `${visibleCheckboxes[0].label}${visibleCheckboxes[0].child ? ` (${visibleCheckboxes[0].child})` : ''}`
      : visibleCheckboxes
          .map(
            checkbox =>
              `· ${checkbox.label.replace(/\?/g, '')}${
                checkbox.child ? `:\n${checkbox.child.replaceAll('* · ', '* ').replaceAll(', ·', ', ')}` : ''
              }`
          )
          .join('\n');
  },
  List: (label, children) => {
    const visibleChildren = children.filter(child => child);

    return visibleChildren.length === 0
      ? null
      : visibleChildren
          .map(c => {
            const rest = c!.startsWith('· ') ? c!.substr(2) : c!;
            return `* ${rest.replace(/\n· /g, ', ')}`;
          })
          .join('\n');
  },
  YesNo: (question, positive, child) => {
    if (child) {
      return child;
    } else if (positive) {
      return capitalize(
        question
          .replace(/\?/g, '')
          .replace(/^Do you have\s/g, '')
          .replace(/^Are you\s/g, '')
          .replace(/^Were you\s/g, '')
      );
    } else {
      return '';
    }
  },
  OneOf: (label, options, answer) => (answer ? `${answer.label}${answer.child ? ` (${answer.child})` : ''}` : null),
});

export default toHighlightsString;
