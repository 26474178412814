import useHasAccessRight from '../../../hooks/useHasAccessRight';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import NoPermissionText from '../../NoPermissionText';
import React, { forwardRef, ReactNode, useEffect, useMemo, useRef } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import styled from 'styled-components';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import { useTheme } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import Frame from 'react-frame-component';

interface FormIframeProps {
  children: ReactNode;
}

const FormIframe = forwardRef<HTMLIFrameElement, FormIframeProps>(({ children }, ref) => {
  const hasAccessRight = useHasAccessRight();
  const isAllowedToView = hasAccessRight('patient.view');

  const classes = useStyles();

  return (
    <Box className={clsx({ [classes.content]: !isAllowedToView })}>
      {!isAllowedToView && (
        <Box className={clsx({ [classes.blurred]: !isAllowedToView })}>
          <NoPermissionText withText={true} style={{ top: '50%', left: '25%' }} />
        </Box>
      )}
      <RootFrame ref={ref}>{children}</RootFrame>
    </Box>
  );
});

export default FormIframe;

const useStyles = makeStyles(theme => ({
  content: {
    position: 'relative',
    textAlign: 'center',
    verticalAlign: 'center',
  },
  blurred: {
    backgroundColor: 'white',
    color: theme.palette.background.default,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 100,
  },
}));

const pageSize = {
  width: 215.9,
  height: 279.4,
};

const tabletPageSize = {
  width: 182,
  height: 259.4,
};

const scale = 0.8;

const StyledFrame = styled(Frame)`
  width: ${pageSize.width + 40}mm; // add 20mm to left and right spacing
  font-size: ${scale}rem;
`;

interface RootFrameProps {
  children: ReactNode;
  isTablet?: boolean;
  allowPageBreaks?: boolean;
}

export const RootFrame = React.forwardRef<HTMLIFrameElement, RootFrameProps>(
  ({ children, isTablet, allowPageBreaks }, ref) => {
    const rootRef = useRef();

    const frameRef = r => {
      rootRef.current = r;

      if (!ref) {
        return;
      }

      if (isFunction(ref)) {
        ref(r);
      } else {
        ref.current = r;
      }
    };

    useEffect(() => {
      const curr = get(rootRef, 'current.node');

      if (!curr) {
        return;
      }

      let animationFrame;

      const loop = () => {
        curr.style.width = curr?.contentWindow?.document?.documentElement?.scrollWidth + 'px';
        curr.style.height = curr?.contentWindow?.document?.documentElement?.scrollHeight + 'px';
        requestAnimationFrame(loop);
      };

      loop();

      return () => {
        cancelAnimationFrame(animationFrame);
      };
    }, []);

    const theme = useTheme();
    const backgroundColor = theme.palette.background.paper;
    const head = useMemo(
      () =>
        ReactHtmlParser(`
    <style>
      html {
        background-color: ${backgroundColor};
      }
      body {
        margin: 0;
        margin-bottom: -0.5rem;
      }
      .page {
        min-height: ${pageSize?.height}mm;
        margin: 0;
        margin-bottom: 0.5rem;
        font-size: 1em;
        background: white;
        color: black;
        border: 1px solid rgba(0, 0, 0, 0.3);
        box-shadow: 2px 2px 7px 4px rgba(0, 0, 0, 0.15);
        padding: 2rem;
      }

      @media screen {
        .page {
          width: ${pageSize?.width}mm;
        }
      }

      @media print {
        body {
          font-size: 3.9mm;
          line-height: 1.15;
        }
        .page {
          border: none;
        }
        @page {
          width: ${pageSize.width}mm;
          min-height: ${pageSize.height}mm;
          ${
            allowPageBreaks
              ? `
              margin-top: 2rem;
              margin-bottom: 2rem;
            `
              : `
              margin: 0;
            `
          }
        }

        .page {
          width: ${pageSize.width}mm;
          ${
            allowPageBreaks
              ? `
              padding-top: 0;
              padding-bottom: 0;
            `
              : `
              min-height: ${pageSize.height}mm;
              margin: 0;

            `
          }
          page-break-after: always;
          font-size: 1em;
          box-shadow: none;
        }
      }
    </style>
  `),
      [allowPageBreaks, backgroundColor]
    );

    return (
      <StyledFrame ref={frameRef} head={head} frameBorder="0" scrolling="no">
        <style>
          {`
          html {
            font-family: sans-serif;
          }
          p {
            margin: 0;
          }
          @media print {
            ::-webkit-scrollbar {
              display: none;
            }

            html, body {
              background: none;
              -webkit-print-color-adjust: exact;
              print-color-adjust: exact;
            }

            .page {
              border: none;
            }

            textarea {
              resize: none;
            }
          `}
        </style>
        {children}
      </StyledFrame>
    );
  }
);
