import { listColumns, viewColumns } from './columns';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import scheme from '../../../graph/equipment';
import EquipmentMobileListItem from './EquipmentMobileListItem';
import EquipmentInput from './EquipmentInput';

const Equipments = GraphQLCRUD({
  entityName: 'Equipment',
  scheme,
  List: {
    tableKey: 'Equipment',
    columns: listColumns,
    MobileItemComponent: EquipmentMobileListItem,
    simpleFilter: true,
  },
  Create: {
    Input: EquipmentInput,
  },
  Show: {
    columns: viewColumns,
  },
  Edit: {
    Input: EquipmentInput,
  },
});

export default Equipments;
