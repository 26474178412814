import React from 'react';

import type IllustrationProps from '../IllustrationProps';

export default ({ width, height, variant }: IllustrationProps) => {
  const color = variant === 'light' ? '#1976d2' : '#00a7f7';
  return (
    <svg viewBox="0 0 60 60.01" width={width} height={height}>
      <rect opacity={0.28} fill={color} x="5.07" y="7.05" width="10.04" height="51.62" />
      <path
        fill={color}
        d="M43.62,6.53V3.84H33a4,4,0,0,0-8,0l-10.07,0V6.53H4.61V60h49l.1-53.48ZM16.14,5.12l10,0V4.4h0V4.19a.54.54,0,0,1,0-.18,2.77,2.77,0,1,1,5.53.18V4.4h0v.72H42.34V9.84H16.14ZM52.35,58.81H5.89V7.73h9v3.4H43.62V7.73h8.81Z"
      />
      <rect fill={color} x="12.45" y="44.27" width="33.37" height="1.28" />
      <rect fill={color} x="12.26" y="49.13" width="33.37" height="1.28" />
      <polygon
        fill={color}
        points="35.89 26.39 33.83 33 31.51 33.05 29.07 27.85 26.67 33.53 22.77 20.96 18.91 32.78 18.23 31.35 12.24 31.46 12.27 33.01 17.27 32.91 19.18 36.9 22.73 26.02 26.45 38 29.14 31.62 30.55 34.61 34.96 34.51 35.9 31.52 36.82 34.45 46 34.52 46.01 32.98 37.95 32.91 35.89 26.39"
      />
    </svg>
  );
};
