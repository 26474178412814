import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Box, CssBaseline, Divider } from '@material-ui/core';
import { QuestionnaireAndAnswers, QuestionnaireType } from '../../../types/Questionnaire';
import { matchPath } from 'react-router-dom';
import WhiteThemeProviderWrapper from '../../../WhiteThemeProviderWrapper';
import { get } from 'lodash/fp';
import { useStyles } from '../../pages/kiosk/charting/screens/Dictation';
import { useQuery, useSubscription } from '@apollo/client';
import { item as patientWithCharts } from '../../../graph/patientChart';
import { Patient } from '../../../types/Patient';
import { sortBy } from 'lodash';
import { getChartingData } from '../../pages/kiosk/charting/utils';
import { calculateBMIStr } from '../../../questionnaire/toBMI';
import { parseHeight } from '../../../form/questions/TextInput';
import { useLastLocation } from 'react-router-last-location';
import { useChartingSession } from '../../pages/kiosk/charting/modules/hooks';
import { list } from '../../../graph/config';
import { ConfigType, PatientChartButtonsConfig } from '../../../types/HospitalConfig';
import { tryParseJson } from '../../../util/parseJson';
import ChartingButtons from '../../pages/kiosk/charting/ChartingButtons';
import { getEnteredAt } from '../../pages/kiosk/charting/screens/ChartingProfile';
import { DictationsContextProvider } from '../../../contexts/DictationsContext';
import { ThemeProvider } from '@material-ui/core/styles';
import MyPatientInfo from '../../pages/kiosk/charting/PatientInfo';
import DictationPanel from '../../pages/kiosk/charting/DictationPanel';
import { tabletThemeLight } from '../../../themes/tabletTheme';
import { History } from 'history';
import { LastLocationType } from 'react-router-last-location/src/LastLocationContext';
import { goBack } from './ActiveAndPastPatientCharting';

const ActiveAndPastPatientDictation = ({
  hospitalId,
  questionnaireType,
  history,
  match,
}: {
  hospitalId: number;
  questionnaireType: QuestionnaireType;
} & RouteComponentProps) => {
  const patientId = parseInt(get('params.id')(match), 10);

  const classes = useStyles();

  const { data: patientData } = useSubscription(patientWithCharts, {
    variables: { id: patientId },
    skip: !patientId,
  });
  const patient: Patient | undefined = patientData?.patient;

  const charts: QuestionnaireAndAnswers[] | undefined = sortBy(
    getChartingData(questionnaireType, patient),
    'questionnaire.order'
  );

  const mergedAnswers = charts?.reduce(
    (acc, e) => ({
      ...acc,
      ...JSON.parse(e?.questionnaireExchange?.answers || '{}'),
    }),
    {}
  );
  const calculatedValues = {
    bmi: calculateBMIStr((mergedAnswers as any)?.wt, ...parseHeight((mergedAnswers as any)?.ht)),
  };
  const combined = { ...mergedAnswers, ...calculatedValues };

  const lastLocation = useLastLocation();

  const handleGoBack = () => goBack(history, lastLocation);

  const fallRiskAssessment = patient?.procedure?.fallRiskAssessment;

  const chartingSession = useChartingSession();
  const { data: configData } = useQuery(list, {
    variables: {
      type: ConfigType.PatientChartButtons,
    },
  });
  const tabsConfig: PatientChartButtonsConfig | null = tryParseJson(configData?.configs?.[0]?.values);
  const ChartingButtonsComponent = ChartingButtons(tabsConfig, chartingSession?.role || 'staff');
  const enteredAt = getEnteredAt(questionnaireType, patient);

  return (
    <Box
      style={{
        backgroundColor: 'white',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1300,
        paddingTop: matchPath(location.pathname, '/su/:id') ? 48 : 0,
        overflow: 'auto',
      }}
    >
      <WhiteThemeProviderWrapper>
        <DictationsContextProvider>
          <ThemeProvider theme={tabletThemeLight}>
            <CssBaseline />
            <Box className={classes.viewport}>
              <Box className={classes.container}>
                <Box display="flex" justifyContent="flex-end">
                  <ChartingButtonsComponent data={patient} />
                </Box>
                <Divider />
                <MyPatientInfo
                  onBack={handleGoBack}
                  patientName={patient?.name}
                  physicianName={patient?.physician?.name}
                  anesthesiologistName={patient?.procedure?.anesthesiologist?.name}
                  anesthesiaType={patient?.procedure?.anesthesiaType}
                  anesthesiaReviewed={patient?.procedure?.anesthesiaReviewed}
                  procedureTypeName={patient?.procedureType?.name}
                  enteredAt={enteredAt}
                  dateOfBirth={patient?.procedure?.patientDateOfBirth}
                  age={patient?.procedure?.patientAge}
                  sex={patient?.procedure?.patientSex}
                  hstId={patient?.procedure?.patientHstId}
                  fallRiskAssessment={
                    !Boolean(fallRiskAssessment)
                      ? undefined
                      : fallRiskAssessment!.toLowerCase().indexOf('high') > -1
                      ? 'high'
                      : fallRiskAssessment!.toLowerCase().indexOf('low') > -1
                      ? 'low'
                      : undefined
                  }
                  allergies={patient?.procedure?.allergies}
                />
                <DictationPanel patientId={patientId} procedureId={patient?.procedure?.id} />
              </Box>
            </Box>
          </ThemeProvider>
        </DictationsContextProvider>
      </WhiteThemeProviderWrapper>
    </Box>
  );
};

export default ActiveAndPastPatientDictation;
