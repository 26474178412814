import { Theme } from '@material-ui/core';

export default function CssBaseline(theme: Theme) {
  return {
    MuiCssBaseline: {
      '@global': {
        html: {
          'overscroll-behavior-y': 'none',
        },
      },
    },
  };
}
