import { compose, withProps } from 'recompose';
import SelectInput from '../../../se/components/inputs/SelectInput';
import { PrimarySpecialtyLabel } from '../../../types/StaffMember';
import { withLabel } from '../../../se/components/Label';

const PrimarySpecialtySelectInput = compose(
  withLabel('Primary Specialty'),
  withProps({
    options: PrimarySpecialtyLabel,
    placeholder: 'e.g., Pre Op',
    required: false,
  })
)(SelectInput);

export default PrimarySpecialtySelectInput;
