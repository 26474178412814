import React from 'react';
import styled from 'styled-components';
import renderNode from './renderNode';
import get from 'lodash/get';
import { Column } from './Containers';
import { withTheme } from '../../../../se/theme';

const ListItem = styled.div`
  border-bottom: 1px solid ${withTheme(theme => theme.border.color.default.string())};
`;

export default ({ item, value }) => {
  const list = get(value, 'list', []);
  return (
    <Column>
      {list.map((answer, i) => (
        <ListItem key={i}>{renderNode(item, { value: answer })}</ListItem>
      ))}
    </Column>
  );
};
