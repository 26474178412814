import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { FC } from 'react';

const ChartButtons: FC<{
  onAdd: () => void;
  onDelete: () => void;
}> = ({ onAdd, onDelete }) => {
  return (
    <Box display="flex" alignItems="flex-end" flexDirection="column" style={{ gap: '1rem' }} py={4}>
      <Fab color="primary" aria-label="add" onClick={onAdd}>
        <AddIcon />
      </Fab>
      <Fab color="inherit" aria-label="delete" onClick={onDelete}>
        <DeleteIcon />
      </Fab>
    </Box>
  );
};

export default ChartButtons;
