import React from 'react';
import styled from 'styled-components';
import NameTag from './NameTag';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    margin-top: 0.2em;
    margin-right: 0.5em;
  }

  > *:last-child {
    margin-right: 0.5em;
  }
`;

export default ({ tags = [], linkForElement }) => (
  <Container>
    {tags.map(({ id, name }) => (
      <NameTag key={id} name={name} linkTo={linkForElement(id)} />
    ))}
  </Container>
);
