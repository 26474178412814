import React from 'react';
import { mapProps } from 'recompose';
import { useQuery } from '@apollo/client';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import scheme, {
  grantScheduleAccess,
  resendScheduleAccessLink,
  revokeScheduleAccess,
  setScheduleAccessAll,
} from '../../../graph/staff';
import { baseColumns, viewColumns } from './columns';
import StaffMemberInput from '../../inputs/staff/StaffMemberInput';
import ScheduleAccessEditor from '../../pages/ScheduleAccessEditor';
import EntityEmptyState from '../../../se/components/entity-states/EntityEmptyState';
import { withTheme } from '@material-ui/core';
import StaffMember, { OtherSpecialtyLabel } from '../../../types/StaffMember';
import { RouteComponentProps } from 'react-router';
import ScheduleAccessToggle from '../../pages/ScheduleAccessToggle';

const Empty = withTheme(
  mapProps(({ theme, ...props }: any) => ({
    title: `There are no staff members at the moment.`,
    hint: 'Add new staff members through the Schedule interface',
    ...props,
    illustration: theme.illustrations.users,
  }))(EntityEmptyState)
);

export const StaffMemberScheduleAccessEditor = ({
  staffMember,
  onClose,
  refetchQueries = [],
  ...rest
}: any & { staffMember: StaffMember }) => (
  <ScheduleAccessEditor
    grantScheduleAccess={grantScheduleAccess}
    resendScheduleAccessLink={resendScheduleAccessLink}
    revokeScheduleAccess={revokeScheduleAccess}
    {...staffMember}
    id={staffMember?.staffId}
    onClose={onClose}
    {...rest}
  >
    <ScheduleAccessToggle
      scheduleUserId={staffMember?.staffId}
      scheduleAccessAll={staffMember?.scheduleAccessAll}
      setScheduleAccessAll={setScheduleAccessAll}
      refetchQueries={refetchQueries || []}
    />
  </ScheduleAccessEditor>
);

export default GraphQLCRUD({
  entityName: 'StaffMember',
  List: {
    tableKey: 'StaffMember',
    columns: baseColumns,
    simpleFilter: true,
    Empty,
    idProvider: (item: StaffMember) => item.staffId,
    additionalRoutes: [
      {
        key: 'schedule-access',
        path: '/schedule-access/:id',
        Component: (props: RouteComponentProps<{ id: string }> & { data: StaffMember } & { baseUrl: string }) => {
          const staffMemberId = parseInt(props?.match?.params?.id, 10);
          const { data: item, loading } = useQuery(scheme.item, {
            variables: {
              id: staffMemberId,
            },
          });

          const onClose = () => {
            props.history.push(props.baseUrl);
          };

          return !loading && item.staffMember ? (
            <StaffMemberScheduleAccessEditor
              staffMember={item.staffMember}
              refetchQueries={[{ query: scheme.item, variables: { id: staffMemberId } }]}
              onClose={onClose}
              {...props}
            />
          ) : null;
        },
      },
    ],
  },
  Show: {
    columns: viewColumns,
  },
  Create: {
    Input: StaffMemberInput,
    prepareCreateData: (
      data: StaffMember & {
        firstName: string;
        lastName: string;
        otherSpecialties: { value: string; label: string }[];
        title: { id: string; name: string };
      }
    ) => ({
      ...data,
      name:
        data?.firstName && data?.lastName ? `${data?.firstName} ${data?.lastName}` : data?.firstName || data?.lastName,
      otherSpecialties: data?.otherSpecialties?.map((s: { value: string; label: string }) => s.value),
    }),
  },
  Edit: {
    Input: StaffMemberInput,
    prepareUpdateData: (
      data: StaffMember & {
        firstName: string;
        lastName: string;
        otherSpecialties: { value: string; label: string }[];
        title: { id: string; name: string };
      }
    ) => ({
      ...data,
      id: data.staffId,
      name:
        data?.firstName && data?.lastName ? `${data?.firstName} ${data?.lastName}` : data?.firstName || data?.lastName,
      otherSpecialties: data?.otherSpecialties?.map((s: { value: string; label: string }) => s.value),
    }),
    mapEditItemProps: ({ data }: any) => ({
      data: {
        ...data,
        staffMember: {
          ...data?.staffMember,
          firstName: data?.staffMember?.name?.split(/\s+/, 1)?.[0],
          lastName: data?.staffMember?.name?.split(/\s+/)?.slice(1).join(' '),
          otherSpecialties: data?.staffMember?.otherSpecialties?.map((s: string) => ({
            value: s,
            label: (OtherSpecialtyLabel as any)[s],
          })),
          title: data?.staffMember?.titleJson,
        },
      },
    }),
  },
  scheme,
});
