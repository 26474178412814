import React from 'react';
import Filters, { PhysicianDropdown } from '../kiosk/schedule/Filters';
import Button from '../../../se/components/Button';
import Icon from '../../core/Icon';
import styled from 'styled-components';
import responsive from '../../../se/utilities/responsive';
import { addMonths, parse, subMonths } from 'date-fns';

const ActionBarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  > a {
    justify-content: flex-start;
    width: 10em;
  }
  justify-content: flex-end;

  ${responsive.md.andSmaller`
    flex-flow: column;
    align-items: flex-start;
    margin-bottom: 1em;
    margin-top: 1em;

    > * + * {
      margin-top: 1em;
    }
    min-width: 0
  `}
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  height: 2.8em;
  > * + * {
    margin-left: 1em;
  }

  > * {
    height: 100%;
  }

  #select {
    height: 100%;
    > div {
      height: 100%;
    }
  }
  ${responsive.md.andSmaller`
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    margin-left: 0;

    > * {
      margin-top: 0;
      flex: 1;
    }
  `}
`;

const DateNavigation = styled.div`
  display: flex;

  > * + * {
    margin-left: 0.5em;
  }
`;

// const DatePicker = styled(DateFilter)`
//   width: 16.5rem;
//   background-color: ${props => props.theme.button.default.backgroundColor.string()};
//   input {
//     border-color: ${props => props.theme.button.default.backgroundColor.string()};
//   }
// `;

export const MonthPicker = ({ date, setDate }) => (
  <DateNavigation>
    <Button style={{ padding: '0 .75em' }} onClick={() => setDate(subMonths(date, 1))}>
      <Icon className="material-icons" style={{ margin: 0, opacity: 1 }}>
        keyboard_arrow_left
      </Icon>
    </Button>
    <Filters
      date={date}
      withoutPhysician
      onChange={({ date }) => setDate(parse(date))}
      noSpacingStyles
      style={{ minWidth: '10em' }}
      buttonFormat={'MMMM YYYY'}
    />{' '}
    <Button style={{ padding: '0 .75em' }} onClick={() => setDate(addMonths(date, 1))}>
      <Icon className="material-icons" style={{ margin: 0, opacity: 1 }}>
        keyboard_arrow_right
      </Icon>
    </Button>
  </DateNavigation>
);

const ActionBar = ({
  date,
  setDate,
  physician,
  setPhysician,
  colorMode,
  setColorMode,
  // dateRange,
  // setDateRange,
  // disabledDatePicker,
}) => (
  <ActionBarWrapper>
    <Actions>
      <Button onClick={() => setColorMode(!colorMode)} disabled={!setColorMode} style={{ minWidth: '10rem' }}>
        {!colorMode ? 'Category View' : 'Physician View'}
      </Button>
      <PhysicianDropdown onChange={setPhysician} value={physician} style={{ width: '20em' }} />
      {setDate && <MonthPicker date={date} setDate={setDate} />}
      {/* TODO Jovan Update DatePicker/DateFilter */}
      {/*{setDateRange && <DatePicker value={dateRange} onChange={setDateRange} disabled={disabledDatePicker} />}*/}
    </Actions>
  </ActionBarWrapper>
);

export default ActionBar;
