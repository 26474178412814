export const cardiovascular = [['Cardiac', 'cardiac_list']];

export const vascular = [['Vascular', 'vascular_list']];

export const pulmonary = [['Pulmonary', 'pulmonary_list']];

export const renal = [['Renal', 'renal_list']];

export const hepatic = [['Hepatic', 'hepatic_list']];

export const neurological = [['Neurologic', 'neurologic_list']];

export const gastrointestinal = [['GI', 'GI_list']];

export const hematological = [
  ['Hematological', 'Anemia'],
  ['Hematological', 'History of blood clots'],
  ['Hematological', 'blood thinners'],
  ['Hematological', 'contagious diseases'],
  ['Hematological', 'blood clotting'],
  ['Hematological', 'Blood transfusion'],
  ['Hematological', 'HIV'],
  ['Hematological', 'Varicose veins or venous stasis'],
  ['Hematological', 'Other hematologic conditions'],
  ['Hematological', 'Hematologic comment'],
];

export const endocrine = [['Endocrine', 'endocrine_list']];

export const musculoskeletal = [['Musculoskeletal', 'endocrine_list']];

export const psychiatric = [['Psychiatric', 'psychiatric_list']];

export const gynaecological = [
  ['Gynaecological', 'pregnant'],
  ['Gynaecological', 'Gynecologic comment'],
];

export const oncologic = [['Oncologic', 'oncologic_list']];

export const infectious = [['Infectious', 'infectious_list']];

export const other = [
  ['Other', 'Glaucoma'],
  ['Other', 'Other conditions'],
  ['Other', 'Cancer'],
  ['Other', 'Other oncologic'],
  ['Other', 'Oncologic comment'],
  ['Other', 'MRSA'],
  ['Other', 'C-diff'],
  ['Other', 'ORSA'],
  ['Other', 'CMV'],
  ['Other', 'Shingles'],
  ['Other', 'Antibiotic Resistant Infection'],
  ['Other', 'Other Infectious Diseases'],
  ['Other', 'West African'],
  ['Other', 'traveled'],
  ['Other', 'blood or body fluids'],
  ['Other', 'Other infectious'],
  ['Other', 'Infectious Diseases comment'],
  ['Other', 'contagious diseases'],
  ['Other', 'Recently'],
  ['Other', 'Contagions comment'],
  ['Other', 'Other comment'],
  ['Other', 'skin breakdow'],
  ['Other', 'bruises or rashes'],
  ['Other', 'Other skin'],
  ['Other', 'Skin Integrity comment'],
  ['Other', 'chronic pain'],
];

export const pain = [
  ['Pain', 'pain_list', 'Chronic pain'],
  ['Pain', 'pain_list', 'Pain comment'],
];
