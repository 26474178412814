import React, { FC, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { item as getVendor, saveRepresentatives } from '../../../graph/vendors';
import Vendor, { Representative } from '../../../types/Vendor';
import { Box, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Form from '../../../se/components/Form';
import { compose, withProps } from 'recompose';
import { withLabel } from '../../../se/components/Label';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import TextInputComponent from '../../../se/components/inputs/TextInput';
import PhoneInputComponent from '../../../se/components/inputs/PhoneInput';
import { EmailInput as EmailInputComponent } from '../../../se/components/inputs/EmailInput';
import { sanitize } from '../../../se/components/GraphQLCRUD';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface RepresentativesFormProps {
  vendor?: Vendor;
}

const TextInput = TextInputComponent as any;
const PhoneInput = PhoneInputComponent as any;
const EmailInput = EmailInputComponent as any;

const RepresentativeInput = withProps({
  schema: {
    name: compose(withLabel('Name'), withProps({ placeholder: 'e.g., Mark Nordstrom', required: true }))(TextInput),
    // @ts-ignore
    phoneNumber: withProps({ placeholder: 'e.g., (619) 412-9512' })(withLabel('Mobile Phone Number')(PhoneInput)),
    // @ts-ignore
    email: withProps({ placeholder: 'john@xyz.com' })(withLabel('Email Address')(EmailInput)),
  },
})(ObjectInput);

const RepresentativesForm: FC<RepresentativesFormProps> = ({ vendor }) => {
  const classes = useStyles();
  const [save] = useMutation(saveRepresentatives);
  const [edit, setEdit] = useState(false);
  const [item, setItem] = useState<Representative | null>(null);

  // graphql return __typename arg in object (causing problem when we mutate), we set __typename = undefined
  const representatives: Representative[] = useMemo(
    () => sanitize(vendor?.representatives || []),
    [vendor?.representatives]
  );

  const onSubmitCreate = async (item: Representative) => {
    await save({
      variables: {
        id: vendor?.id,
        representatives: [...representatives, item],
      },
      refetchQueries: [{ query: getVendor, variables: { id: vendor?.id } }],
    });
    setEdit(false);
  };

  const onSubmitUpdate = (ind: number) => async (item: Representative) => {
    await save({
      variables: {
        id: vendor?.id,
        representatives: representatives.map((e, i) => (i === ind ? item : e)),
      },
      refetchQueries: [{ query: getVendor, variables: { id: vendor?.id } }],
    });
    setEdit(false);
  };

  const onDelete = async (item: Representative, ind: number) => {
    await save({
      variables: {
        id: vendor?.id,
        representatives: representatives.filter((e, i) => !(e.name === item.name && ind === i)),
      },
      refetchQueries: [{ query: getVendor, variables: { id: vendor?.id } }],
    });
  };

  return (
    <Box mt={2}>
      {edit && !item && (
        <>
          <Typography variant={'h4'} gutterBottom>
            New Representative
          </Typography>
          <Form
            initialValue={item}
            input={RepresentativeInput}
            onSubmit={onSubmitCreate}
            onCancel={() => setEdit(false)}
            label={'Add New Representative'}
          />
        </>
      )}
      {representatives.map(
        (e, ind) =>
          edit &&
          e.name === item?.name && (
            <>
              <Typography variant={'h4'} gutterBottom>
                Edit Representative
              </Typography>
              <Form
                initialValue={item}
                input={RepresentativeInput}
                onSubmit={onSubmitUpdate(ind)}
                onCancel={() => {
                  setEdit(false);
                  setItem(null);
                }}
                label={'Edit Representative'}
              />
            </>
          )
      )}
      {!edit && (
        <Box className={classes.edit}>
          <Typography variant={'h4'}>Representatives</Typography>
          <IconButton onClick={_ => setEdit(true)} edge="end" aria-label="edit">
            <EditIcon />
          </IconButton>
        </Box>
      )}
      <List className={classes.list}>
        {representatives.map((item, ind) => (
          <ListItem key={`${item.name}-${ind}`} className={classes.item}>
            <Box>
              <ListItemText
                primary={item.name}
                secondary={
                  item.phoneNumber && item.email
                    ? `${item.phoneNumber} • ${item.email}`
                    : item.phoneNumber || item.email
                }
              />
            </Box>
            <ListItemSecondaryAction>
              <IconButton
                onClick={_ => {
                  setEdit(true);
                  setItem(item);
                }}
                aria-label="edit"
              >
                <EditIcon />
              </IconButton>
              <IconButton onClick={_ => onDelete(item, ind)} edge="end" aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export const useStyles = makeStyles(theme => ({
  edit: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  item: {
    border: '1px solid #1f2c61',
  },
  list: {
    '& > *': {
      marginBottom: theme.spacing(0.5),
    },
  },
}));

export default RepresentativesForm;
