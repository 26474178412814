export const Formats = {
  CSV: 'csv',
  XLS: 'xls',
  XLSX: 'xlsx',
  TXT: 'txt',
};

export const MediaType = {
  [Formats.CSV]: '.csv, text/csv',
  [Formats.XLS]: 'application/vnd.ms-excel',
  [Formats.XLSX]: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  [Formats.TXT]: 'text/plain',
};

export const getMediaTypes = formats => formats.map(f => MediaType[f]).join(', ');
