import React from 'react';
import { openPdf } from '../../../../../../entities/procedures/pdf/shared';
import { format, isAfter, isBefore, parse, isEqual } from 'date-fns';
import { createPdf } from '../../../../../../../vendor/pdfMake';
import { Room } from '../../../../../../../types/Room';
import StaffShift from '../../../../../../../types/StaffShift';
import { Procedure } from '../../../../../../../types/Procedure';
import { ZonedDateTime } from '@js-joda/core';
import { lighten } from '@material-ui/core';

export interface StaffShiftExtended extends StaffShift {
  isProcedure?: boolean;
}

export type RoomWithProcedureFlag = Omit<Room, 'staffShifts'> & { staffShifts: Array<StaffShiftExtended> };

function toPairs(array: Room[]): any {
  const result: Room[][] = [];
  for (let i = 0; i < array.length; i += 2) {
    result.push([array?.[i], array?.[i + 1]]);
  }
  return result;
}

function isAfterTime(time1?: string, time2?: string) {
  if (!time1 || !time2) return undefined;
  const baseDate = '1970-01-01';

  const date1 = parse(`${baseDate}T${time1.length < 5 ? `0${time1}` : time1}`);
  const date2 = parse(`${baseDate}T${time2.length < 5 ? `0${time2}` : time2}`);

  return isAfter(date1, date2);
}

function isBeforeTime(time1?: string, time2?: string) {
  if (!time1 || !time2) return undefined;
  const baseDate = '1970-01-01';

  const date1 = parse(`${baseDate}T${time1.length < 5 ? `0${time1}` : time1}`);
  const date2 = parse(`${baseDate}T${time2.length < 5 ? `0${time2}` : time2}`);

  return isBefore(date1, date2);
}

function isEqualTime(time1?: string, time2?: string) {
  if (!time1 || !time2) return undefined;

  const baseDate = '1970-01-01';

  const date1 = parse(`${baseDate}T${time1.length < 5 ? `0${time1}` : time1}`);
  const date2 = parse(`${baseDate}T${time2.length < 5 ? `0${time2}` : time2}`);

  return isEqual(date1, date2);
}

const emptyCell = (lastRow: boolean = false) => ({ text: '', style: 'tableCell', border: [false, false, true, lastRow ? true : false], fillColor: 'white' });

const after14Hours = (procedures: Procedure[]): boolean => {
  const startTime = '12:00';
  const list = procedures?.filter(e => isAfterTime(e.endTimeText, "14:00") && isBeforeTime(e.startTimeText, "14:00"));
  return list?.length > 0;
}

const getTimeRow = (startTime: string, endTime: string, procedures1: Procedure[], procedures2: Procedure[], maxRowSpan: number, bold: boolean = false, lastRow: boolean = false) => {
  const p1 = procedures1?.find(e => isEqualTime(e.startTimeText, startTime) || (isAfterTime(e.startTimeText, startTime) && isBeforeTime(e.startTimeText, endTime)));
  const p2 = procedures2?.find(e => isEqualTime(e.startTimeText, startTime) || (isAfterTime(e.startTimeText, startTime) && isBeforeTime(e.startTimeText, endTime)));

  const bilaterally1 = p1?.procedureType?.toLowerCase()?.includes('right') ? 'Right' : p2?.procedureType?.toLowerCase()?.includes('left') ? 'Left' : '';
  const bilaterally2 = p2?.procedureType?.toLowerCase()?.includes('right') ? 'Right' : p2?.procedureType?.toLowerCase()?.includes('left') ? 'Left' : '';


  const rowSpan1 = maxRowSpan < (Math.ceil((p1?.duration || 0) / 15) || 1) ? maxRowSpan : Math.ceil((p1?.duration || 0) / 15) || 1;
  const rowSpan2 = maxRowSpan < (Math.ceil((p2?.duration || 0) / 15) || 1) ? maxRowSpan : Math.ceil((p2?.duration || 0) / 15) || 1;

  return [
    { text: startTime, rowSpan: bold ? 4 : 1, bold: bold, style: 'tableCell', border: [true, false, true, true] },
    p1 ? {
      rowSpan: rowSpan1,
      text: [
        `${p1?.physician?.name || ''}    ${p1?.patientName || ''} ${p1?.patientSex || ''}${p1?.patientAge || ''}     ${p1?.anesthesiologist?.name || ''} ${p1?.anesthesiaType || ''}\n`,
        `${p1?.procedureType}  `,
        { text: `  ${bilaterally1}\n`, bold: true },
        `${'Appointment type'}     ${p1?.patientDateOfBirth ? `DOB# ${p1?.patientDateOfBirth}` : ''}`,
      ],
      style: 'tableCell',
      border: [true, true, true, true],
      borderColor: p1?.color ? [p1.color, p1.color, p1.color, p1.color] : ['black', 'black', 'black', 'black'],
    } : emptyCell(lastRow),
    p2 ? {
      rowSpan: rowSpan2,
      text: [
        `${p2?.physician?.name || ''}    ${p2?.patientName || ''} ${p2?.patientSex || ''}${p2?.patientAge || ''}     ${p2?.anesthesiologist?.name || ''} ${p2?.anesthesiaType || ''}\n`,
        `${p2?.procedureType}  `,
        { text: `  ${bilaterally2}\n`, bold: true },
        `${'Appointment type'}     ${p2?.patientDateOfBirth ? `${p2?.patientDateOfBirth ? `DOB# ${p2?.patientDateOfBirth}` : ''}` : ''}`,
      ],
      style: 'tableCell',
      border: [true, true, true, true],
      borderColor: p2?.color ? [p2.color, p2.color, p2.color, p2.color] : ['black', 'black', 'black', 'black'],
    } : emptyCell(lastRow),
  ];
};

const getTable = (data: any[], last: boolean = false) => {
  return ({
    pageBreak: last ? null : 'after',
    style: 'tableExample',
    table: {
      heights: 15,
      headerRows: 1,
      widths: ['5%', '*', '*'],
      body: data,
    },
  });
};

const getOrdinalSuffix = (day: number) => {
  const suffix = ['th', 'st', 'nd', 'rd'];
  const v = day % 100;
  return suffix[(v - 20) % 10] || suffix[v] || suffix[0];
};

const formatDateWithOrdinal = (date: Date) => {
  const day = format(date, 'DD');
  const dayWithSuffix = `${day}${getOrdinalSuffix(parseInt(day, 10))}`;
  const month = format(date, 'MMMM');
  const year = format(date, 'YYYY');
  const weekday = format(date, 'dddd');
  return `${weekday}, ${month} ${dayWithSuffix} ${year}`;
};

const getContent = (hospitalName: string, date: Date, rooms: RoomWithProcedureFlag[], operationRooms: Room[]) => {

  const data = toPairs(operationRooms);

  const tables = data.flatMap((e, i) => {
    const isAfter14_0 = after14Hours(e?.[0]?.procedures);
    const isAfter14_1 = after14Hours(e?.[1]?.procedures);
    const beginFrom = isAfter14_0 || isAfter14_1 ? 12 : 14;

    return [getTable([
      [{ text: '', style: 'tableHeader' }, { text: e?.[0]?.name || '', style: 'tableHeader' }, {
        text: e?.[1]?.name || '',
        style: 'tableHeader',
      }],

      ...Array.from({ length: 13 - 6 + 1 }, (v, i) => i + 6).flatMap(i => {

        const hour = i > 9 ? `${i}` : `0${i}`;
        const hourNext = i + 1 > 9 ? `${i + 1}` : `0${i + 1}`;
        const maxRowSpan = 13 - i + 1;
        return [
          getTimeRow(`${hour}:00`, `${hour}:15`, e?.[0]?.procedures, e?.[1]?.procedures, maxRowSpan * 4, true),
          getTimeRow(`${hour}:15`, `${hour}:30`, e?.[0]?.procedures, e?.[1]?.procedures, maxRowSpan * 4 - 1),
          getTimeRow(`${hour}:30`, `${hour}:45`, e?.[0]?.procedures, e?.[1]?.procedures, maxRowSpan * 4 - 2),
          getTimeRow(`${hour}:45`, `${hourNext}:00`, e?.[0]?.procedures, e?.[1]?.procedures, maxRowSpan * 4 - 3, false, hour === "13"),
        ];
      }),
    ]),
      getTable([
        [{ text: '', style: 'tableHeader' }, { text: e?.[0]?.name || '', style: 'tableHeader' }, {
          text: e?.[1]?.name || '',
          style: 'tableHeader',
        }],

        ...Array.from({ length: 18 - beginFrom + 1 }, (v, i) => i + beginFrom).flatMap(i => {

          const hour = i > 9 ? `${i}` : `0${i}`;
          const hourNext = i + 1 > 9 ? `${i + 1}` : `0${i + 1}`;
          const maxRowSpan = 18 - i + 1;
          return [
            getTimeRow(`${hour}:00`, `${hour}:15`, e?.[0]?.procedures, e?.[1]?.procedures, maxRowSpan * 4, true),
            getTimeRow(`${hour}:15`, `${hour}:30`, e?.[0]?.procedures, e?.[1]?.procedures, maxRowSpan * 4 - 1),
            getTimeRow(`${hour}:30`, `${hour}:45`, e?.[0]?.procedures, e?.[1]?.procedures, maxRowSpan * 4 - 2),
            getTimeRow(`${hour}:45`, `${hourNext}:00`, e?.[0]?.procedures, e?.[1]?.procedures, maxRowSpan  * 4 - 3, false, hour === "18"),
          ];
        }),
      ], i === data.length - 1),
    ];
  });

  return {
    stack: tables,
  };
};

export const ORSchedulePdfGenerator = (
  hospitalName: string,
  date: Date,
  rooms: RoomWithProcedureFlag[],
  operationRooms: Room[],
) => {
  const docDefinition = {
    pageSize: 'LETTER',
    pageMargins: [40, 40, 40, 40],
    defaultStyle: {
      fontSize: 9,
      lineHeight: 1.4,
    },
    info: { title: `OR Schedule` },
    header: {
      stack: [
        {
          text: formatDateWithOrdinal(date),
          style: 'headerText',
          alignment: 'center',
          margin: [0, 10, 0, 10],
        },
      ],
    },
    content: [
      getContent(
        hospitalName,
        date,
        rooms.map(room => ({ ...room, staffShifts: room.staffShifts.filter(staff => !!staff.staff?.name) })),
        operationRooms,
      ),
    ],
    styles: {
      headerText: {
        fontSize: 16,
        bold: true,
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: 'black',
        alignment: 'center',
      },
      tableExample: {},
      tableCell: {},
    },
  };

  openPdf(createPdf(docDefinition), `OR_Schedule${format(date, 'MM_DD_YYYY_HH_mm_ss')}`);
};
