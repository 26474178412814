import React, { FC, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useStyles } from '../ScheduleProcedureForm';
import { AutocompleteInputSetter } from '../components/AutocompleteInputSetter';
import { Autocomplete } from '@material-ui/lab';
import { FormParams, RepresentativePicked, VendorDetails } from '../types/types';
import set from 'lodash/fp/set';
import { phoneNumberFormatPipeline } from '../../../../../../se/components/inputs/PhoneInput';
import { PhoneNumberInput } from '../components/PhoneNumberInput';
import { EmailInput } from '../components/EmailInput';
import { representativePickedInitial, vendorPickedInitial } from '../utils/values';
import { transformValue } from '../utils/functions';
import flow from 'lodash/fp/flow';
import InputAdornment from '@material-ui/core/InputAdornment';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { useMutation, useQuery } from '@apollo/client';
import {
  create as createVendor,
  item as getVendor,
  list as vendors,
  saveRepresentatives,
} from '../../../../../../graph/vendors';
import Vendor, { Representative } from '../../../../../../types/Vendor';
import { sanitize } from '../../../../../../se/components/GraphQLCRUD';

const formatPrice = (value: string | null | undefined) => {
  if (!value) return value;
  if (value === '0') return '';

  return parseFloat(value).toFixed(2);
};

const VendorForm: FC<FormParams<VendorDetails>> = ({ value, setValue }) => {
  const classes = useStyles();

  const { data } = useQuery(getVendor, {
    variables: {
      id: value?.vendorInfo?.id?.value,
    },
    skip: !value?.vendorInfo?.id?.value,
  });

  const selectedVendor = sanitize(data?.vendor);
  const representatives = selectedVendor?.representatives || [];

  const isRepresentativeDisabled = !value?.vendorInfo?.representatives;

  const [vendor, setVendor] = useState<Vendor | null | undefined>();

  const [create] = useMutation(createVendor, {
    refetchQueries: [{ query: vendors }],
  });

  const [updateRepresentatives] = useMutation(saveRepresentatives);

  const createOption = async (text: string) => {
    const res = await create({
      variables: {
        name: text,
      },
    });
    const created = res?.data?.createVendor;
    setVendor(created);
    return created;
  };

  const updateRepresentativesOptions = async (representativeName: string) => {
    const currentVendor = vendor || selectedVendor;
    if (!!currentVendor) {
      const vendorId: number = currentVendor?.id;
      const representatives: Representative[] = currentVendor?.representatives || [];
      const updatedRepresentatives =
        representatives.filter(e => e.name === representativeName)?.length === 0
          ? [...representatives, { name: representativeName }]
          : representatives;
      await updateRepresentatives({
        variables: {
          id: vendorId,
          representatives: updatedRepresentatives,
        },
        refetchQueries: [{ query: vendors }, { query: getVendor, variables: { id: vendorId } }],
      });
      setVendor({ ...currentVendor, representatives: updatedRepresentatives as Representative[] });
    }
  };

  const updateRepresentativesFields = (fieldName: string) => async (fieldValue: string) => {
    const currentVendor = vendor || selectedVendor;
    if (!!currentVendor) {
      const vendorId: number = currentVendor?.id;
      const representatives: Representative[] = currentVendor?.representatives || [];
      const updatedRepresentatives = representatives.map((e: Representative) =>
        e.name === value?.representative?.name?.value ? { ...e, [fieldName]: fieldValue } : e
      );
      await updateRepresentatives({
        variables: {
          id: vendorId,
          representatives: updatedRepresentatives,
        },
        refetchQueries: [{ query: vendors }, { query: getVendor, variables: { id: vendorId } }],
      });

      setVendor({ ...currentVendor, representatives: updatedRepresentatives as Representative[] });
    }
  };

  return (
    <>
      <Typography variant="h3" gutterBottom>
        Vendor
      </Typography>
      <form className={classes.form} noValidate autoComplete="off">
        <Grid container spacing={4}>
          <Grid item md={6}>
            <AutocompleteInputSetter
              list={vendors}
              label={'Vendor Name'}
              variant="filled"
              margin="dense"
              fullWidth
              onChange={event => {
                if (event?.constructor?.name === 'SyntheticEvent') {
                  setValue(set('vendorInfo.name.value', event?.target?.value)(value));
                } else {
                  setValue(
                    flow(
                      set('vendorInfo', transformValue(event || {}, vendorPickedInitial)),
                      set('representative', !!event ? value?.representative : undefined)
                    )(value)
                  );
                }
              }}
              onBlurCreateOption={createOption}
              clearInput={() => setValue(flow(set('vendorInfo', undefined), set('representative', undefined))(value))}
              value={value?.vendorInfo?.name?.value}
            />
            <TextField
              id="filled-required"
              label="Implant"
              variant="filled"
              margin="dense"
              fullWidth
              value={value?.implant?.value || ''}
              onChange={event => setValue(set('implant.value', event.target.value)(value))}
            />
            <TextField
              id="filled-required"
              label="Price"
              InputProps={{
                inputProps: {
                  min: 0,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon style={{ marginRight: '-0.5em', fontSize: 20 }} />
                  </InputAdornment>
                ),
              }}
              variant="filled"
              margin="dense"
              type={'number'}
              onBlur={() => setValue(set('price.value', formatPrice(value?.price?.value))(value))}
              fullWidth
              value={value?.price?.value}
              onChange={event => setValue(set('price.value', event.target.value)(value))}
            />
          </Grid>
          <Grid item md={6}>
            <Autocomplete
              id="vendor-rep-name"
              options={representatives}
              getOptionLabel={(option: RepresentativePicked) => option?.name || ''}
              renderInput={params => (
                <TextField
                  {...params}
                  id="filled-required"
                  label="Vendor Representative Name"
                  onChange={event => setValue(set('representative.name.value', event.target.value)(value))}
                  variant="filled"
                  margin="dense"
                  value={value?.representative?.name?.value || ''}
                  fullWidth
                  disabled={isRepresentativeDisabled}
                />
              )}
              disabled={isRepresentativeDisabled}
              //@ts-ignore
              value={value?.representative}
              onChange={(event, representative) =>
                setValue(
                  set('representative', transformValue(representative || {}, representativePickedInitial))(value)
                )
              }
              inputValue={value?.representative?.name?.value ?? ''}
              onBlur={() =>
                value?.representative?.name?.value && updateRepresentativesOptions(value?.representative?.name?.value)
              }
            />
            <PhoneNumberInput
              label="Vendor Representative Phone Number"
              value={value?.representative?.phoneNumber?.value || ''}
              onChange={event =>
                setValue(set('representative.phoneNumber.value', phoneNumberFormatPipeline(event.target.value))(value))
              }
              disabled={isRepresentativeDisabled}
              onBlur={() =>
                value?.representative?.phoneNumber?.value &&
                updateRepresentativesFields('phoneNumber')(value?.representative?.phoneNumber?.value)
              }
            />
            <EmailInput
              error={!!value?.representative?.email?.error}
              helperText={value?.representative?.email?.error}
              label="Vendor Representative Email"
              value={value?.representative?.email?.value || ''}
              onChange={event => setValue(set('representative.email.value', event.target.value)(value))}
              disabled={isRepresentativeDisabled}
              onBlur={() =>
                value?.representative?.email?.value &&
                updateRepresentativesFields('email')(value?.representative?.email?.value)
              }
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default VendorForm;
