import { graphql } from '@apollo/client/react/hoc';
import React, { forwardRef, Fragment } from 'react';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { compose, mapProps } from 'recompose';
import posed, { PoseGroup } from 'react-pose';
import responsive from '../../../se/utilities/responsive';
import { getNestedValue } from '../../../se/utilities/data/object';
import { isDefinedAndNotNull } from '../../../se/utilities/check';

import emphasize from '../../animations/emphasize';
import waitingRoomEmpty from '../../../assets/images/waitingroom.svg';
import PanelEmpty from '../../Panel/PanelEmpty';
import HospitalInfo from '../../HospitalInfo';
import {
  OR,
  PACU,
  PATIENT_JOURNEY_LABELS,
  POST_OP,
  PRE_OP,
  ROOM_TYPE_LABELS,
  ROOM_TYPES,
  WAITING_ROOM,
} from '../../entities/room/enums';
import { listSubscription } from '../../../graph/patients';
import { sortByIdDesc } from '../../entities/common/transducers';
import PatientIcon from '../../patient/PatientIcon';
import withSize from '../../../hocs/withSize';
import ClientUpdater, { ClientUpdaterPresetTvWaitingRoom } from '../../ClientUpdater';
import { getLogEntries, isPatientReady, isReadyForPickupPACU, isReadyForPickupPOSTOP } from './tablet/utils';
import waitingReady from '../../../assets/sound/waitingReady.mp3';
import { soundAlert } from './Monitor';
import ColumnsResolver from './ColumnsResolver';
import isMiddleEast from '../../../util/isMiddleEast';
import { withScope } from '../../../contexts/ScopeContext';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  background-color: ${props => props.theme.backgroundColor.string()};
  overflow: hidden;
  height: 100vh;
`;

const PatientsRoot = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 2.5rem 0;

  overflow: hidden;
`;

const ColumnName = styled.div`
  font-size: calc(
    ${props => (props.small ? '0.67' : '1')} *
      ${props => (props.numberOfPatients > 14 ? '3vh' : props.numberOfPatients <= 5 ? '5vw' : '3vw')}
  );

  text-transform: uppercase;
  font-weight: 100;
  color: white;
  padding-left: 2.5rem;

  @media (max-height: 900px) {
    font-size: 1.5rem;
    padding-left: 1rem;
  }
`;

const Legend = styled.div`
  margin-bottom: 1rem;
  flex-shrink: 0;
  column-count: ${props => props.columns};
  column-gap: 3rem;

  & ${ColumnName}:nth-child(2n) {
    text-align: right;
    padding-right: 2.5rem;

    @media (max-height: 900px) {
      padding-right: 1rem;
    }
  }
`;

const Slot = styled.div`
  display: inline-block;
`;

const SlotContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 0.25rem;

  background-color: ${props =>
    props.withHighlightBorder ? 'rgba(0, 182, 62, .5) !important' : 'rgba(255, 255, 255, 0.07)'};
  padding: 1.25vw;
  padding: min(1.25vw, 1.25vh);
  font-size: ${props => (props.numberOfPatients <= 5 ? '5vw' : '3vw')};
  font-weight: 100;
  line-height: 2rem;
  border-radius: 0.25rem;
  border: ${props => (props.withHighlightBorder ? '2px solid #00B63E' : '2px solid transparent')};

  ${responsive.md.andSmaller`
    font-size: 2rem;
  `};

  @media (max-height: 900px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const SlotWrapper = styled.div`
  display: inline-block;
  position: absolute;
  top: -0.125rem;
  bottom: -0.125rem;
  left: -0.125rem;
  right: -0.125rem;
`;

const Slots = styled.div`
  column-gap: 3rem;
  column-fill: auto;

  height: calc(100vh - 6.375rem - 4.75rem);

  margin: 0;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  & ${Slot}:nth-child(${props => (props.columns > 1 ? '4n' : '2n')}),
  & ${Slot}:nth-child(${props => (props.columns > 1 ? '4n+2' : '2n')}) {
    & ${SlotContent} {
      background: transparent;
    }
  }
`;

const Name = styled.div`
  color: white;
  width: 3em;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: ${props => (props.numberOfPatients > 14 ? '.5em' : 'inherit')};

  @media (max-width: 1400px) {
    font-size: 0.75em;
  }

  @media (max-height: 700px) {
    font-size: 0.75em;
  }
`;

const Icon = styled.div`
  color: white;
  width: 1em;
  white-space: nowrap;

  font-size: ${props => (props.numberOfPatients > 14 ? '.9em' : 'inherit')};
  margin-left: ${props => (props.numberOfPatients > 14 ? '.2em' : '.3em')};

  @media (max-width: 1400px) {
    font-size: 0.75em;
  }

  @media (max-height: 700px) {
    font-size: 0.75em;
  }
`;

const Status = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  margin-left: auto;
  font-size: ${props => (props.numberOfPatients > 14 ? '.5em' : '.75em')};
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: flex-end;

  @media (max-height: 700px) {
    font-size: 0.5em;
  }
`;

const Highlight = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  border-radius: 0.25rem;
  z-index: 100;
  width: 100%;
  height: 100%;
  border: 0.125rem solid transparent;
  animation: 1s ${emphasize} ease-out alternate 12;
`;

const PatientIconWrapper = styled.div`
  color: rgba(0, 0, 0, 0.5);
  margin-right: ${props => (props.numberOfPatients > 14 ? '.1em' : '0.5em')};
  margin-left: ${props => (props.numberOfPatients > 14 ? '-.25em' : '0')};
  transform: ${props => (props.numberOfPatients > 14 ? 'scale(.5) translateZ(1px)' : 'none')};
`;

const ColumnNameWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  background-color: #0b93d3;
  padding: 1.5rem 0;
  border-radius: 0 0 0.25rem 0.25rem;
`;

const HospitalInfoWrapper = styled.div`
  padding-top: 1rem;
  background-color: rgba(255, 255, 255, 0.07);
`;

const PatientSlot = forwardRef(
  (
    {
      icon,
      color,
      name,
      status,
      statusTranslation,
      numberOfPatients,
      withHighlightBorder,
      children,
      readyForPickup,
      scaleFactor,
      style,
      contentStyle,
    },
    ref
  ) => (
    <Slot ref={ref} numberOfPatients={numberOfPatients} style={style}>
      <SlotContent withHighlightBorder={withHighlightBorder} style={contentStyle}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <PatientIconWrapper numberOfPatients={numberOfPatients}>
            <PatientIcon backgroundColor={color} icon={icon} />
          </PatientIconWrapper>
          <Name numberOfPatients={numberOfPatients}>
            {name}
            {readyForPickup && (
              <Icon numberOfPatients={numberOfPatients} className="material-icons">
                time_to_leave
              </Icon>
            )}
          </Name>
          <Status numberOfPatients={numberOfPatients}>{status}</Status>
          {children}
        </div>
        {statusTranslation && (
          <div
            style={{
              textAlign: 'right',
              marginTop: '0.25em',
              marginBottom: '0.25em',
              zoom: scaleFactor,
            }}
          >
            {statusTranslation}
          </div>
        )}
      </SlotContent>
    </Slot>
  )
);

const AnimatedPatientSlot = posed(PatientSlot)({
  preEnter: {
    opacity: 0,
    transition: { type: 'spring' },
  },
  enter: {
    position: 'relative',
    opacity: 1,
    y: 0,
    transition: {
      duration: 500,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
  exit: {
    position: 'relative',
    opacity: 0,
    y: -20,
    transition: {
      duration: 500,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
});

export const getArabicTranslation = (roomType, hospitalId) => {
  if (isMiddleEast(hospitalId)) {
    switch (roomType) {
      case WAITING_ROOM:
        return 'غرفة الانتظار';
      case PRE_OP:
        return 'التجهيز للعملية';
      case OR:
        return 'في العمليات';
      case PACU:
        return 'في الإفاقة';
      case POST_OP:
        return 'قسم الانعاش';
      default:
        return '';
    }
  }
};

export const getRoomName = (roomName = '', translation) => (translation ? `${roomName} ${translation}` : roomName);

function clamp(value, min, max) {
  return Math.min(max, Math.max(min, value));
}

class WaitingRoomMonitor extends React.PureComponent {
  state = { columns: 1 };

  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
    this.legendRef = React.createRef();

    if (!props.configuration?.useCssGridLayout) {
      setTimeout(() => this.setState({ columns: this.numberOfColumns() }), 2000);
    }

    this.handleResolve = this.handleResolve.bind(this);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    if (!this.props.loading) {
      if (this.readyToSeeFamilyNumberFromProps(nextProps) > this.readyToSeeFamilyNumberFromProps(this.props)) {
        this.readyToSeeFamilyAlert();
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.configuration?.useCssGridLayout) {
      const prevColumns = prevState.columns;
      const newColumns = this.numberOfColumns();
      if (newColumns !== prevColumns) {
        this.setState({ columns: newColumns });
      }
    }
  }

  readyToSeeFamilyNumberFromProps = ({ patients = [] }) =>
    patients.filter(p => !!(p.isPACUReady || p.isPOSTOPReady)).length;

  readyToSeeFamilyAlert = () => soundAlert(waitingReady, getNestedValue('configuration.pacuReadySound', this.props));

  getElementWidth = ref => (ref && ref.current ? Math.round(ref.current.getBoundingClientRect().width || 0) : 0);

  getContainerWidth = () => this.getElementWidth(this.containerRef);
  getSlotWidth = () => {
    if (this.containerRef && this.containerRef.current) {
      const childContainer = this.containerRef.current.firstElementChild;

      if (childContainer) {
        const width = childContainer.getBoundingClientRect().width || 0;
        if (isFinite(width) && width > 0) {
          return width;
        }
      }
    }

    return 0; // ??
  };

  numberOfColumns = () => {
    const columns = Math.round(this.getContainerWidth() / this.getSlotWidth());
    return Math.max(1, isFinite(columns) ? columns : 1);
  };

  handleResolve = columns => this.setState({ columns });

  render() {
    const { patients, configuration } = this.props;
    const columns = configuration?.useCssGridLayout ? this.state.columns : Math.ceil(patients.length / 7);

    const hospitalId = this.props?.scope?.hospital?.id;

    const scaleFactor = (Math.sin(clamp(patients.length, 8, 24) / 5) + 2) / 3;

    return configuration?.useCssGridLayout ? (
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100vw"
        height="100vh"
        padding="0.5rem"
        paddingTop="0"
        display="flex"
        flexDirection="column"
        alignItems="stretch"
      >
        {patients.length > 0 ? (
          <Box flex={1} position="relative">
            <ColumnsResolver elements={patients.length} onResolve={this.handleResolve}>
              {(columns, protoRef) => (
                <Box
                  ref={protoRef}
                  style={{ visibility: 'hidden', pointerEvents: 'none' }}
                  position="absolute"
                  top={0}
                  bottom={0}
                  left={0}
                  right={0}
                  display="grid"
                  gridTemplateColumns={`repeat(${columns}, minmax(auto, ${100 / columns}%))`}
                  alignItems="stretch"
                  alignContent="start"
                >
                  {[...Array(columns)].map((_, i) => (
                    <Box key={i}>
                      <ColumnNameWrapper numberOfPatients={patients.length}>
                        <ColumnName
                          small={isMiddleEast(hospitalId)}
                          numberOfPatients={patients.length}
                          style={{ padding: 0, marginLeft: '2vw' }}
                        >
                          Patient
                          {isMiddleEast(hospitalId) && <div>{'المريض'}</div>}
                        </ColumnName>
                        <ColumnName
                          small={isMiddleEast(hospitalId)}
                          numberOfPatients={patients.length}
                          style={{ padding: 0, marginLeft: 'auto', marginRight: '2vw' }}
                        >
                          Status
                          {isMiddleEast(hospitalId) && <div style={{ textAlign: 'right' }}>{'الحالة'}</div>}
                        </ColumnName>
                      </ColumnNameWrapper>
                    </Box>
                  ))}
                  {patients.map((patient, i) => {
                    const roomType = patient?.room?.type;

                    return (
                      <PatientSlot
                        key={patient.id}
                        columns={columns}
                        color={patient.color}
                        icon={patient.icon}
                        name={patient.name}
                        readyForPickup={
                          (patient.isFamilyNotifiedPACU && roomType === PACU) ||
                          (patient.isFamilyNotifiedPOSTOP && roomType === POST_OP)
                        }
                        status={PATIENT_JOURNEY_LABELS[roomType || '']}
                        statusTranslation={getArabicTranslation(roomType, hospitalId)}
                        numberOfPatients={patients.length}
                        withHighlightBorder={
                          (patient.isPACUReady && roomType === PACU) || (patient.isPOSTOPReady && roomType === POST_OP)
                        }
                        scaleFactor={scaleFactor}
                        style={{ flex: 1, padding: '0.5rem', display: 'flex', alignItems: 'stretch' }}
                        contentStyle={{
                          flex: 1,
                          backgroundColor: Math.floor(i / columns) % 2 ? 'transparent' : null,
                          position: 'relative',
                          alignItems: 'stretch',
                        }}
                      >
                        <SlotWrapper>
                          <Highlight
                            key={`${
                              roomType === ROOM_TYPES.OR ? ROOM_TYPE_LABELS[roomType || 'Unknown'] : patient?.room?.name
                            }`}
                          />
                        </SlotWrapper>
                      </PatientSlot>
                    );
                  })}
                </Box>
              )}
            </ColumnsResolver>
            <Box
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
              display="grid"
              gridTemplateColumns={`repeat(${columns}, minmax(auto, ${100 / columns}%))`}
              alignItems="stretch"
              alignContent="start"
            >
              {[...Array(columns)].map((_, i) => (
                <Box key={i}>
                  <ColumnNameWrapper numberOfPatients={patients.length}>
                    <ColumnName
                      small={isMiddleEast(hospitalId)}
                      numberOfPatients={patients.length}
                      style={{ padding: 0, marginLeft: '2vw' }}
                    >
                      Patient
                      {isMiddleEast(hospitalId) && <div>{'المريض'}</div>}
                    </ColumnName>
                    <ColumnName
                      small={isMiddleEast(hospitalId)}
                      numberOfPatients={patients.length}
                      style={{ padding: 0, marginLeft: 'auto', marginRight: '2vw' }}
                    >
                      Status
                      {isMiddleEast(hospitalId) && <div style={{ textAlign: 'right' }}>{'الحالة'}</div>}
                    </ColumnName>
                  </ColumnNameWrapper>
                </Box>
              ))}
              <PoseGroup animateOnMount>
                {patients.map((patient, i) => {
                  const roomType = patient?.room?.type;

                  return (
                    <AnimatedPatientSlot
                      key={patient.id}
                      columns={columns}
                      color={patient.color}
                      icon={patient.icon}
                      name={patient.name}
                      readyForPickup={
                        (patient.isFamilyNotifiedPACU && roomType === PACU) ||
                        (patient.isFamilyNotifiedPOSTOP && roomType === POST_OP)
                      }
                      status={PATIENT_JOURNEY_LABELS[roomType || '']}
                      statusTranslation={getArabicTranslation(roomType, hospitalId)}
                      numberOfPatients={patients.length}
                      withHighlightBorder={
                        (patient.isPACUReady && roomType === PACU) || (patient.isPOSTOPReady && roomType === POST_OP)
                      }
                      scaleFactor={scaleFactor}
                      style={{ flex: 1, padding: '0.5rem', display: 'flex', alignItems: 'stretch' }}
                      contentStyle={{
                        flex: 1,
                        backgroundColor: Math.floor(i / columns) % 2 ? 'transparent' : null,
                        position: 'relative',
                        alignItems: 'stretch',
                      }}
                    >
                      <SlotWrapper>
                        <Highlight
                          key={`${
                            roomType === ROOM_TYPES.OR ? ROOM_TYPE_LABELS[roomType || 'Unknown'] : patient?.room?.name
                          }`}
                        />
                      </SlotWrapper>
                    </AnimatedPatientSlot>
                  );
                })}
              </PoseGroup>
            </Box>
          </Box>
        ) : (
          <Box flex={1} display="flex" justifyContent="center">
            <PanelEmpty large image={waitingRoomEmpty} text="Waiting for patients…" />
          </Box>
        )}
        <HospitalInfoWrapper>
          <HospitalInfo />
        </HospitalInfoWrapper>
        {patients.length <= 0 && <ClientUpdater {...ClientUpdaterPresetTvWaitingRoom} />}
      </Box>
    ) : (
      <Fragment>
        <Root>
          <PatientsRoot patientsNum={patients.length} columns={columns}>
            {patients.length > 0 ? (
              <Fragment>
                <Legend columns={columns} ref={this.legendRef}>
                  {[...Array(columns)].map((_, i) => (
                    <Box key={i}>
                      <ColumnNameWrapper numberOfPatients={patients.length}>
                        <ColumnName
                          small={isMiddleEast(hospitalId)}
                          numberOfPatients={patients.length}
                          style={{ padding: 0, marginLeft: '2vw' }}
                        >
                          Patient
                          {isMiddleEast(hospitalId) && <div>{'المريض'}</div>}
                        </ColumnName>
                        <ColumnName
                          small={isMiddleEast(hospitalId)}
                          numberOfPatients={patients.length}
                          style={{ padding: 0, marginLeft: 'auto', marginRight: '2vw' }}
                        >
                          Status
                          {isMiddleEast(hospitalId) && <div style={{ textAlign: 'right' }}>{'الحالة'}</div>}
                        </ColumnName>
                      </ColumnNameWrapper>
                    </Box>
                  ))}
                </Legend>
                <Slots ref={this.containerRef} columns={columns}>
                  <PoseGroup animateOnMount>
                    {patients.map(patient => {
                      const roomType = patient?.room?.type;
                      return (
                        <AnimatedPatientSlot
                          columns={columns}
                          key={patient.id}
                          color={patient.color}
                          icon={patient.icon}
                          name={patient.name}
                          readyForPickup={
                            (patient.isFamilyNotifiedPACU && roomType === PACU) ||
                            (patient.isFamilyNotifiedPOSTOP && roomType === POST_OP)
                          }
                          status={PATIENT_JOURNEY_LABELS[roomType || '']}
                          statusTranslation={getArabicTranslation(roomType, hospitalId)}
                          numberOfPatients={patients.length}
                          withHighlightBorder={
                            (patient.isPACUReady && roomType === PACU) ||
                            (patient.isPOSTOPReady && roomType === POST_OP)
                          }
                          scaleFactor={scaleFactor}
                        >
                          <SlotWrapper>
                            <Highlight
                              key={`${
                                roomType === ROOM_TYPES.OR
                                  ? ROOM_TYPE_LABELS[roomType || 'Unknown']
                                  : patient?.room?.name
                              }`}
                            />
                          </SlotWrapper>
                        </AnimatedPatientSlot>
                      );
                    })}
                  </PoseGroup>
                </Slots>
              </Fragment>
            ) : (
              <PanelEmpty large image={waitingRoomEmpty} text="Waiting for patients…" />
            )}
          </PatientsRoot>
          <HospitalInfoWrapper>
            <HospitalInfo />
          </HospitalInfoWrapper>
        </Root>
        {patients.length <= 0 && <ClientUpdater {...ClientUpdaterPresetTvWaitingRoom} />}
      </Fragment>
    );
  }
}

const groupByType = (acc, patient) => [
  getNestedValue('room.type', patient) === PRE_OP ? [...acc[0], patient] : [...acc[0]],
  getNestedValue('room.type', patient) === OR ? [...acc[1], patient] : [...acc[1]],
  getNestedValue('room.type', patient) === PACU ? [...acc[2], patient] : [...acc[2]],
  getNestedValue('room.type', patient) === POST_OP ? [...acc[3], patient] : [...acc[3]],
];

export default compose(
  graphql(listSubscription),
  mapProps(({ data, ...rest }) => ({
    patients: isDefinedAndNotNull(data) ? data['waitingRoom'] || [] : [],
    loading: data.loading,
    error: data.error,
    ...rest,
  })),
  withScope,
  mapProps(({ patients, scope, ...rest }) => ({
    ...rest,
    scope,
    patients: (patients || [])
      .filter(patient => ![WAITING_ROOM, 'Unknown'].includes(getNestedValue('room.type', patient)))
      .map(patient => ({
        ...patient,
        isPACUReady: isPatientReady(getLogEntries(patient))(PACU),
        isPOSTOPReady: isPatientReady(getLogEntries(patient))(POST_OP),
        isFamilyNotifiedPACU: isReadyForPickupPACU(getLogEntries(patient)),
        isFamilyNotifiedPOSTOP: isReadyForPickupPOSTOP(getLogEntries(patient)),
      }))
      .reduce(groupByType, [[], [], [], []])
      .reduce((acc, group) => [...acc, ...group.sort(sortByIdDesc)], []),
  })),
  withSize
)(WaitingRoomMonitor);
