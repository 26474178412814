import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core"

const InputLabel = withStyles((theme) => ({
  root: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
}))(Typography);

export default InputLabel;
