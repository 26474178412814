import Toolbar from '@material-ui/core/Toolbar';
import { Box, Divider, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import React, { ReactNode } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CurrentTime } from '../../charting/components';
import { WatchLaterRounded } from '@material-ui/icons';

interface DialogHeaderProps {
  title: string;
  subtitle?: string | ReactNode;
  children?: ReactNode;
}

const DialogHeader = ({ title, subtitle, children }: DialogHeaderProps) => {
  const classes = useStyles();

  return (
    <AppBar elevation={0} color="inherit" position="sticky" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.header}>
          <Box>
            <Typography variant="h4" className={classes.title}>
              {title}
            </Typography>
            {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
          </Box>
          <Box display="flex" alignItems="center" style={{ gap: 16 }}>
            <Box className={classes.time}>
              <Box className={classes.timeIcon}>
                <WatchLaterRounded />
              </Box>
              <CurrentTime />
            </Box>
          </Box>
        </Box>
        <Box alignSelf="stretch">{children}</Box>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles(theme => ({
  appBar: {
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    // backgroundColor: theme.palette.secondary.light,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  title: {
    flex: 1,
  },
  time: {
    flex: 1,
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
  },
  timeIcon: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.4,
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default DialogHeader;
