import React from 'react';

import config from '../config';
import AlertBar from './core/AlertBar';

const ONE_MINUTE = 60 * 1000;
// const FOUR_SECONDS = 4 * 1000;
const POLLING_INTERVAL = ONE_MINUTE;

export const ClientUpdaterPresetTvInternal = {
  thisVersion: config.appVersionTvInternal,
  serverVersionPath: '/version-tv-internal',
};
export const ClientUpdaterPresetTvSchedule = {
  thisVersion: config.appVersionTvSchedule,
  serverVersionPath: '/version-tv-schedule',
};
export const ClientUpdaterPresetTvWaitingRoom = {
  thisVersion: config.appVersionTvWaitingRoom,
  serverVersionPath: '/version-tv-waiting-room',
};

class ClientUpdater extends React.Component {
  state = {
    updatingIn: null,
  };

  componentDidMount() {
    this.checker = setTimeout(this.checkForUpdate, POLLING_INTERVAL);
  }

  componentWillUnmount() {
    clearTimeout(this.checker);
    clearTimeout(this.counter);
  }

  checkForUpdate = async () => {
    let newVersionAvailable = undefined;

    try {
      const headers = new Headers();
      headers.append('pragma', 'no-cache');
      headers.append('cache-control', 'no-cache');
      const response = await fetch(config.appURL + this.props.serverVersionPath, {
        method: 'GET',
        cache: 'no-store',
        headers,
      });

      if (response.status === 200) {
        const version = await response.text();

        newVersionAvailable = version.trim() !== this.props.thisVersion.trim();
      } else {
        console.warn('Unexpected response got from server when polling for current version.', response);
      }
    } catch (e) {
      console.error(e);
    } finally {
      if (newVersionAvailable === true) {
        this.setState(
          {
            updatingIn: 10,
          },
          this.countDown
        );
      } else if (newVersionAvailable === false) {
        this.checker = setTimeout(this.checkForUpdate, POLLING_INTERVAL);
      } else {
        this.checker = setTimeout(this.checkForUpdate, POLLING_INTERVAL / 4);
      }
    }
  };

  countDown = () => {
    const { updatingIn } = this.state;

    if (updatingIn <= 0) {
      document.location.reload(true);
    } else {
      this.counter = setTimeout(() => {
        this.setState(
          {
            updatingIn: updatingIn - 1,
          },
          this.countDown
        );
      }, 1000);
    }
  };

  render() {
    const { updatingIn } = this.state;

    return updatingIn === null ? null : (
      <AlertBar>
        {updatingIn > 0
          ? `The screen will be updated in ${updatingIn} second${updatingIn === 1 ? '' : 's'}.`
          : `The screen will be updated any moment now.`}
      </AlertBar>
    );
  }
}

export default config.appURL
  ? ({ thisVersion, serverVersionPath }) =>
      thisVersion !== undefined && serverVersionPath !== undefined ? (
        <ClientUpdater thisVersion={thisVersion} serverVersionPath={serverVersionPath} />
      ) : config.appVersion ? (
        <ClientUpdater thisVersion={config.appVersion} serverVersionPath="/version" />
      ) : null
  : () => null;
