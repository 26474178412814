import React from 'react';
import update from 'lodash/fp/update';
import flow from 'lodash/fp/flow';
import form from '../../../graph/surgeon/forms';
import { CenteredSpinner } from '../../../se/components/Spinner';
import isString from 'lodash/isString';
import FormContent from './FormContent';
import { useSubscription } from '@apollo/client';
import merge from 'lodash/merge';
import get from 'lodash/fp/get';

const tryParseJson = (data: any) => {
  if (isString(data)) {
    try {
      return JSON.parse(data);
    } catch (e) {
      return data;
    }
  } else {
    return data;
  }
};

const HtmlForm = ({
  id,
  patientId,
  procedureId,
  lastPreOpCompletedEvent,
  headerBackground,
  headerOffset,
  MoreActionsComponent,
}: any) => {
  const { data, loading } = useSubscription(form.getSubscription, {
    variables: { id, procedureId },
  });

  const formData = flow(
    update('entryQuestionnaire.questions', tryParseJson),
    update('entryQuestionnaire.answers', tryParseJson),
    update('entryQuestionnaire.defaultValues', tryParseJson),
    update('entryQuestionnaire.defaultValues', (entryQuestionnaire: any) =>
      merge(get('defaultValues', entryQuestionnaire), get('answers', entryQuestionnaire))
    )
  )(data?.form || {});

  return !loading ? (
    <FormContent
      {...formData}
      patientId={patientId}
      procedureId={procedureId}
      lastPreOpCompletedEvent={lastPreOpCompletedEvent}
      withActions
      headerBackground={headerBackground}
      headerOffset={headerOffset}
      MoreActionsComponent={MoreActionsComponent}
    />
  ) : (
    <CenteredSpinner size={undefined} strokeAccessor={undefined} />
  );
};

export default HtmlForm;
