import React, { Fragment, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import Button from '../../se/components/Button';
import Modal from '../../se/components/Modal';
import { useToaster } from '../core/Toaster';
import Typography from '@material-ui/core/Typography';

const ScheduleAccessEditor = ({
  history,
  baseUrl,
  id,
  name,
  hasScheduleAccess,
  grantScheduleAccess,
  resendScheduleAccessLink,
  revokeScheduleAccess,
  onClose,
  children,
  style,
}) => {
  const toaster = useToaster();

  const [busy, setBusy] = useState(false);

  const [doGrantAccess] = useMutation(grantScheduleAccess, {
    variables: {
      id,
    },
  });

  const [doResendLink] = useMutation(resendScheduleAccessLink, {
    variables: {
      id,
    },
  });

  const [doRevokeAccess] = useMutation(revokeScheduleAccess, {
    variables: {
      id,
    },
  });

  const grantAccess = async () => {
    try {
      setBusy(true);
      await doGrantAccess();
      toaster.success(`Access Granted and Link Sent to ${name}.`);
      onClose ? onClose() : history.push(baseUrl);
    } catch (e) {
      toaster.error(e.message);
      console.error(e);
    } finally {
      setBusy(false);
    }
  };

  const resendLink = async () => {
    try {
      setBusy(true);
      await doResendLink();
      toaster.success(`Link Resent to ${name}.`);
      onClose ? onClose() : history.push(baseUrl);
    } catch (e) {
      toaster.error(e.message);
      console.error(e);
    } finally {
      setBusy(false);
    }
  };

  const revokeAccess = async () => {
    try {
      setBusy(true);
      await doRevokeAccess();
      toaster.success(`Access Revoked from ${name}`);
      onClose ? onClose() : history.push(baseUrl);
    } catch (e) {
      toaster.error(e.message);
      console.error(e);
    } finally {
      setBusy(false);
    }
  };

  useEffect(() => {
    const modal = document.getElementById('spicy-modal');

    if (modal) {
      modal.style.position = 'relative';
      modal.style.zIndex = '2000';

      return () => {
        modal.style.removeProperty('position');
        modal.style.removeProperty('zIndex');
      };
    }
  }, []);

  return (
    <Modal
      title="SchedulePage Access"
      withRouter={false}
      onClose={onClose}
      closeTo={onClose ? undefined : baseUrl}
      style={style}
    >
      <Typography variant="h6" gutterBottom>
        {name}
      </Typography>
      {children}
      {hasScheduleAccess ? (
        <Fragment>
          <p>Has access to schedule.</p>
          <div style={{ marginTop: '2rem' }}>
            <Button disabled={busy} primary style={{ marginRight: '1rem' }} onClick={resendLink}>
              Resend Link
            </Button>
            <Button disabled={busy} danger style={{ marginRight: '1rem' }} onClick={revokeAccess}>
              Revoke Access
            </Button>
            <Button disabled={busy} onClick={() => (onClose ? onClose() : history.push(baseUrl))}>
              Cancel
            </Button>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <p>Does not have access to schedule.</p>
          <div style={{ marginTop: '2rem' }}>
            <Button disabled={busy} primary style={{ marginRight: '1rem' }} onClick={grantAccess}>
              Grant Access and Send Link
            </Button>
            <Button disabled={busy} onClick={() => (onClose ? onClose() : history.push(baseUrl))}>
              Cancel
            </Button>
          </div>
        </Fragment>
      )}
    </Modal>
  );
};

export default ScheduleAccessEditor;
