import { Query } from '@apollo/client/react/components';
import React, { useState } from 'react';
import { withProps } from 'recompose';
import get from 'lodash/get';
import { RouterlessModal } from '../../../../se/components/Modal';
import { breakpoint } from '../../../../se/utilities/responsive';
import { useMutation } from '@apollo/client';
import ObjectInput from '../../../../se/components/inputs/ObjectInput';
import Form from '../../../../se/components/Form';
import { getScheduleNotificationConfiguration, updateScheduleNotificationConfig } from '../../../../graph/procedures';
import { CenteredSpinner } from '../../../../se/components/Spinner';
import { withLabel } from '../../../../se/components/Label';
import { compact } from 'lodash/array';
import NotificationRecipients from './NotificationRecipients';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';

const ScheduleNotificationConfigInput = withProps({
  schema: {
    notificationRecipients: withLabel('Schedule Update Notification Recipients')(NotificationRecipients),
  },
})(ObjectInput);

export default ({ style, refetch }) => {
  const [open, setOpen] = useState(false);

  const [mutate] = useMutation(updateScheduleNotificationConfig);

  const handleSubmit = async values => {
    const result = await mutate({
      variables: {
        notificationRecipients: compact(get(values, 'notificationRecipients', [])),
      },
      refetchQueries: [{ query: getScheduleNotificationConfiguration }],
    });

    if (get(result, 'data.updateScheduleNotificationConfig')) {
      setOpen(false);
    }
  };

  const mapInput = (val = {}) => ({
    ...val,
  });

  return (
    <Query query={getScheduleNotificationConfiguration}>
      {({ loading, data = {} }) => (
        <div>
          <IconButton onClick={() => setOpen(true)} style={style}>
            <SettingsIcon />
          </IconButton>
          {open && (
            <RouterlessModal
              title={'Schedule Notification Configuration'}
              onClose={() => setOpen(false)}
              fullWindow={window.innerWidth < breakpoint.md}
            >
              {!loading ? (
                <Form
                  autoFocus
                  initialValue={mapInput(get(data, 'scheduleNotificationConfiguration'))}
                  input={ScheduleNotificationConfigInput}
                  label={'Save'}
                  onSubmit={handleSubmit}
                  onCancel={() => setOpen(false)}
                />
              ) : (
                <CenteredSpinner />
              )}
            </RouterlessModal>
          )}
        </div>
      )}
    </Query>
  );
};
