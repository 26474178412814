import React, { useMemo } from 'react';
import {
  Box,
  Breadcrumbs,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import useSystemWideRecentlyOfflineScreens from './useSystemWideRecentlyOfflineScreens';
import useSystemWideRecentlyInactiveGateways from './useSystemWideRecentlyInactiveGateways';
import useSystemWideActorStatuses from './useSystemWideActorStatuses';

interface SectionProps extends RouteComponentProps {
  systemWideRecentlyOfflineScreens: ReturnType<typeof useSystemWideRecentlyOfflineScreens>;
  systemWideRecentlyInactiveGateways: ReturnType<typeof useSystemWideRecentlyInactiveGateways>;
  systemWideActorStatuses: ReturnType<typeof useSystemWideActorStatuses>;
}

const Home = ({
  match,
  history,
  systemWideRecentlyOfflineScreens,
  systemWideRecentlyInactiveGateways,
  systemWideActorStatuses,
}: SectionProps) => {
  const offlineScreens = systemWideRecentlyOfflineScreens.data?.systemWideRecentlyOfflineScreens.length ?? 0;
  const inactiveGateways = systemWideRecentlyInactiveGateways.data?.systemWideRecentlyInactiveGateways.length ?? 0;
  const hospitals = systemWideActorStatuses.data?.hospitals;
  const [unresponsiveActors, affectedHospitals] = useMemo<[number, number[]]>(() => {
    if (!Array.isArray(hospitals)) {
      return [0, []];
    }

    var unresponsiveActors = 0;
    const affectedHospitals = new Set<number>();

    for (let org of hospitals) {
      const orgId = org.id;

      if (!org) {
        continue;
      }

      if (org['snapshot-router']) {
        const snapshotRouter = org['snapshot-router'];

        if (snapshotRouter === 'UNRESPONSIVE') {
          unresponsiveActors += 1;
          affectedHospitals.add(orgId);
        } else {
          if (snapshotRouter.processors) {
            for (let processorKey in snapshotRouter.processors) {
              const processor = snapshotRouter.processors[processorKey];

              if (!processor) {
                continue;
              }

              if (processor === 'UNRESPONSIVE') {
                unresponsiveActors += 1;
                affectedHospitals.add(orgId);
              } else {
                if (processor.downstream === 'UNRESPONSIVE') {
                  unresponsiveActors += 1;
                  affectedHospitals.add(orgId);
                }
                if (processor.downstreamFilter === 'UNRESPONSIVE') {
                  unresponsiveActors += 1;
                  affectedHospitals.add(orgId);
                }
                if (processor.patientActionDeferrer === 'UNRESPONSIVE') {
                  unresponsiveActors += 1;
                  affectedHospitals.add(orgId);
                }
              }
            }
          }
        }
      }

      if (org['enrollment-reader']) {
        const enrollmentReader = org['enrollment-reader'];

        if (enrollmentReader === 'UNRESPONSIVE') {
          unresponsiveActors += 1;
          affectedHospitals.add(orgId);
        }
      }

      if (org['exit-gateway']) {
        const exitGateway = org['exit-gateway'];

        if (exitGateway === 'UNRESPONSIVE') {
          unresponsiveActors += 1;
          affectedHospitals.add(orgId);
        }
      }

      if (org['sensor-monitor']) {
        const sensorMonitor = org['sensor-monitor'];

        if (sensorMonitor === 'UNRESPONSIVE') {
          unresponsiveActors += 1;
          affectedHospitals.add(orgId);
        }
      }

      if (org['subscription-registry']) {
        const subscriptionRegistry = org['subscription-registry'];

        if (subscriptionRegistry === 'UNRESPONSIVE') {
          unresponsiveActors += 1;
          affectedHospitals.add(orgId);
        }
      }

      if (org['gateway-router']) {
        const gatewayRouter = org['gateway-router'];

        if (gatewayRouter === 'UNRESPONSIVE') {
          unresponsiveActors += 1;
          affectedHospitals.add(orgId);
        }
      }
    }

    return [unresponsiveActors, [...affectedHospitals.values()]];
  }, [hospitals]);

  return (
    <Box>
      <Box mb={2}>
        <Breadcrumbs>
          <Typography color="textPrimary">Dashboard</Typography>
        </Breadcrumbs>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Card>
            <CardActionArea onClick={() => history.push(match.path + '/screens')}>
              <CardContent>
                {systemWideRecentlyOfflineScreens.loading ? (
                  <CircularProgress />
                ) : systemWideRecentlyOfflineScreens.error ? (
                  <pre>{JSON.stringify(systemWideRecentlyOfflineScreens.error)}</pre>
                ) : offlineScreens > 0 ? (
                  <Typography>
                    {offlineScreens} offline screen{offlineScreens === 1 ? '' : 's'} in the last 24 hours
                  </Typography>
                ) : (
                  <Typography>No offline screens in the last 24 hours</Typography>
                )}
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardActionArea onClick={() => history.push(match.path + '/gateways')}>
              <CardContent>
                {systemWideRecentlyInactiveGateways.loading ? (
                  <CircularProgress />
                ) : systemWideRecentlyInactiveGateways.error ? (
                  <pre>{JSON.stringify(systemWideRecentlyInactiveGateways.error)}</pre>
                ) : inactiveGateways > 0 ? (
                  <Typography>
                    {inactiveGateways} inactive gateway{inactiveGateways === 1 ? '' : 's'} in the last week
                  </Typography>
                ) : (
                  <Typography>No inactive gateways in the last week</Typography>
                )}
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardActionArea onClick={() => history.push(match.path + '/actors')}>
              <CardContent>
                {systemWideActorStatuses.loading ? (
                  <CircularProgress />
                ) : systemWideActorStatuses.error ? (
                  <pre>{JSON.stringify(systemWideActorStatuses.error)}</pre>
                ) : unresponsiveActors > 0 ? (
                  <Typography>
                    {unresponsiveActors} unresponsive actor{unresponsiveActors === 1 ? '' : 's'} in{' '}
                    {affectedHospitals.length} hospital{affectedHospitals.length === 1 ? '' : 's'}
                  </Typography>
                ) : (
                  <Typography>All actors are operational</Typography>
                )}
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
