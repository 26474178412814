import styled from 'styled-components';
import { withTheme } from '../../../../se/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1em 0;
`;

export const QuestionnaireWrapper = styled.div`
  @media (min-width: 769px) {
    display: flex;
    align-items: baseline;
    margin-bottom: 0.5em;
    justify-content: space-between;
`;

export const Label = styled.label`
  color: ${withTheme(theme => theme.textColor.string())};
  font-size: 0.9em;
  align-self: center;
  margin-right: 0;
  margin-left: auto;
`;
