import { gql } from '@apollo/client';

export const fragments = {
  base: gql`
    fragment LocationData on Location {
      id
      name
    }
  `,
};

export const list = gql`
  query locations($organizationId: Long!) {
    locations(organizationId: $organizationId) {
      ...LocationData
    }
  }
  ${fragments.base}
`;

export const create = gql`
  mutation createOrganizationLocation($organizationId: Long!, $location: LocationInput!) {
    createOrganizationLocation(organizationId: $organizationId, location: $location)
  }
`;

export const update = gql`
  mutation updateOrganizationLocation($organizationId: Long!, $location: LocationInput!) {
    updateOrganizationLocation(organizationId: $organizationId, location: $location)
  }
`;

export const remove = gql`
  mutation removeOrganizationLocation($organizationId: Long!, $location: LocationInput!) {
    removeOrganizationLocation(organizationId: $organizationId, location: $location)
  }
`;
