import React, { ReactNode } from 'react';
import { Box, Button, IconButton } from '@material-ui/core';
import { ListAnswer, ObjectAnswer } from '../../types/Answer';
import { ListQuestion, ObjectQuestion } from '../../types/Question';
import AddIcon from '@material-ui/icons/Add';
import { ConditionContext } from '../Form';
import { DeleteForeverOutlined } from '@material-ui/icons';

type ListInputProps = ListQuestion['props'] & {
  grow?: number;
  name?: string;
  answer?: ListAnswer;
  onChange?: (newAnswer: ListAnswer) => void;
};

const ListInput = ({ grow, name, label, answer, children, onChange }: ListInputProps) => {
  const onAdd = () => {
    const i = answer ? answer?.length : 0;
    const key = `${Math.random()}-${i}`;
    onChange?.(answer ? [...answer, { key }] : [{ key }]);
  };

  const onDelete = (index: number) => {
    onChange?.((answer as ObjectAnswer<ObjectQuestion>[]).filter((e, i) => i !== index));
  };

  return (
    <ConditionContext.Consumer>
      {condition => (
        <Box py={4} display="flex" flexDirection="column" style={{ gap: '1.5rem' }}>
          <Box>
            <Button variant="outlined" onClick={onAdd} startIcon={<AddIcon />} size="large">
              Add {label}
            </Button>
          </Box>

          {children &&
            children?.length > 0 &&
            (children as ReactNode[]).map((listItem, index) => (
              <Box
                width={grow ? '100%' : undefined}
                display="flex"
                alignItems="center"
                key={answer?.[index]?.key}
                style={{ gap: '1rem' }}
              >
                {grow ? (
                  <Box flex={1} display="flex">
                    {listItem}
                  </Box>
                ) : (
                  listItem
                )}

                {condition && children?.length === 1 ? null : (
                  <IconButton onClick={() => onDelete(index)} aria-label="delete">
                    <DeleteForeverOutlined />
                  </IconButton>
                )}
              </Box>
            ))}
        </Box>
      )}
    </ConditionContext.Consumer>
  );
};

export default ListInput;
