import { Query } from '@apollo/client/react/components';
import React, { useState } from 'react';
import { compose, withProps } from 'recompose';
import get from 'lodash/get';
import { RouterlessModal } from '../../../se/components/Modal';
import { breakpoint } from '../../../se/utilities/responsive';
import { useMutation } from '@apollo/client';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import Form from '../../../se/components/Form';
import { getSensorConfiguration, updateSensorGlobal } from '../../../graph/sensors';
import { CenteredSpinner } from '../../../se/components/Spinner';
import NumberInput from '../../../se/components/inputs/NumberInput';
import { withLabel } from '../../../se/components/Label';
import SelectInput from '../../../se/components/inputs/SelectInput';
import MultiSelectInput from '../../../se/components/inputs/MultiSelectInput';
import { isDefinedNotNullAndFinite } from '../../../se/utilities/check';
import { compact } from 'lodash/array';
import ReportRecipients from './ReportRecipients';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';

export const hourOptions = [
  'Midnight',
  '1 AM',
  '2 AM',
  '3 AM',
  '4 AM',
  '5 AM',
  '6 AM',
  '7 AM',
  '8 AM',
  '9 AM',
  '10 AM',
  '11 AM',
  'Noon',
  '1 PM',
  '2 PM',
  '3 PM',
  '4 PM',
  '5 PM',
  '6 PM',
  '7 PM',
  '8 PM',
  '9 PM',
  '10 PM',
  '11 PM',
];

const SensorGlobalConfigInput = withProps({
  schema: {
    gracePeriodMinutes: compose(
      withLabel('Grace period in minutes, allowing value to be out of bounds'),
      withProps({ required: true, min: 0 })
    )(NumberInput),
    temperatureUnit: compose(
      withLabel('Temperature unit'),
      withProps({
        required: true,
        options: {
          C: 'Celsius ℃',
          F: 'Fahrenheit ℉',
        },
      })
    )(SelectInput),
    measurementPoints: compose(
      withLabel('Daily moments relevant for the sensor report'),
      withProps({
        required: true,
        options: hourOptions.map((label, i) => ({ value: i, label })),
      })
    )(MultiSelectInput),
    reportRecipients: withLabel('Daily report recipients')(ReportRecipients),
    autoResolve: compose(
      withLabel('Automatically resolve alerts at'),
      withProps({
        options: hourOptions.map((label, i) => ({ value: i, label })),
      })
    )(MultiSelectInput),
  },
})(ObjectInput);

const toSelectOptions = data => data.map(val => ({ value: val, label: hourOptions[val] }));
const mapInput = (val = {}) => ({
  ...val,
  measurementPoints: toSelectOptions(get(val, 'measurementPoints', [])),
  autoResolve: toSelectOptions(get(val, 'autoResolve', [])),
});

export default ({ style, refetch }) => {
  const [open, setOpen] = useState(false);

  const [mutate] = useMutation(updateSensorGlobal);

  const handleSubmit = async values => {
    const result = await mutate({
      variables: {
        gracePeriodMinutes: get(values, 'gracePeriodMinutes'),
        temperatureUnit: get(values, 'temperatureUnit'),
        measurementPoints: get(values, 'measurementPoints', [])
          .map(_ => _.value)
          .filter(isDefinedNotNullAndFinite),
        reportRecipients: compact(get(values, 'reportRecipients', [])),
        autoResolve: get(values, 'autoResolve', [])
          .map(_ => _.value)
          .filter(isDefinedNotNullAndFinite),
      },
      refetchQueries: compact([{ query: getSensorConfiguration }, refetch]),
    });

    if (get(result, 'data.updateSensorGlobal')) {
      setOpen(false);
    }
  };

  return (
    <Query query={getSensorConfiguration}>
      {({ loading, data = {} }) => (
        <div>
          <IconButton onClick={() => setOpen(true)} style={style}>
            <SettingsIcon />
          </IconButton>
          {open && (
            <RouterlessModal
              title={'Sensor Global Configuration'}
              onClose={() => setOpen(false)}
              fullWindow={window.innerWidth < breakpoint.md}
            >
              {!loading ? (
                <Form
                  autoFocus
                  initialValue={mapInput(get(data, 'sensorConfiguration'))}
                  input={SensorGlobalConfigInput}
                  label={'Save'}
                  onSubmit={handleSubmit}
                  onCancel={() => setOpen(false)}
                />
              ) : (
                <CenteredSpinner />
              )}
            </RouterlessModal>
          )}
        </div>
      )}
    </Query>
  );
};
