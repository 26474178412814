import { IconButton } from '@material-ui/core';
import React, { FC } from 'react';
import { mdiPackageVariantClosedPlus } from '@mdi/js';
import Icon from '@mdi/react';

const VendorMenuButton: FC<{ onClick: (e: React.MouseEvent<HTMLElement>) => void }> = ({ onClick }) => (
  <IconButton style={{ padding: 0 }} size="small" onClick={onClick}>
    <Icon path={mdiPackageVariantClosedPlus} title="Add Vendor" size={1} color="rgb(0, 167, 247)" />
  </IconButton>
);

export default VendorMenuButton;
