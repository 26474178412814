import { keyframes } from 'styled-components';

const emphasize = keyframes`
  0% {
    border-color: rgba(0, 237, 250, 0);
    box-shadow: 0 0 4px rgba(0, 237, 250, 0);
  }
  100% {
    border-color: rgba(0, 237, 250, 1);
    box-shadow: 0 0 8px rgba(0, 237, 250, 1);
  }
`;

export default emphasize;
