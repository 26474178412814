import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import Application from '../se/components/Application';
import styled, { createGlobalStyle } from 'styled-components';

import config from '../config';
import Kiosk from './pages/Kiosk';
import SuperAdminOrganization from './pages/SuperAdminOrganization';
import User from './pages/User';
import responsive from '../se/utilities/responsive';
import ExternalSchedule from './pages/ExternalSchedule';
import Initialization from './pages/user/Initialization';
import Inject from './pages/Inject';
import Print from './pages/Print';

const GlobalCss = createGlobalStyle`
  .react-tooltip-lite {
    background: #333;
    color: white;
  }

  .react-tooltip-lite-arrow {
    border-color: #333;
  }

  .DateRangePickerOverride {
    ${responsive.xs.andSmaller`
      display: flex;
      flex-direction: column;
    `}
  }

  .DateRangePickerOverride .rdrStaticRanges {
    ${responsive.xs.andSmaller`
      flex-flow: wrap;
      justify-content: space-between;
    `}
  }
`;

const ApplicationVersion = styled.div`
  position: fixed;
  right: 0.25rem;
  bottom: 0.25rem;
  color: rgba(255, 255, 255, 0.125);
  font-size: 0.5rem;
  font-weight: 600;
  z-index: 1000;
`;

export default () => (
  <Application title={'Ospitek View'} Loading={Initialization}>
    <Switch>
      <Route path="/schedule" component={ExternalSchedule} />
      <Route exact path="/kiosk" render={() => <Redirect to="/kiosk/1" />} />
      <Route path="/kiosk/:organizationId" component={Kiosk} />
      <Route path="/inject/:organizationId" component={Inject} />
      <Route path="/print/:organizationId" component={Print} />
      <Route path="/su/:organizationId" component={SuperAdminOrganization} />
      <Route component={User} />
    </Switch>
    <GlobalCss />
    {config.appVersion && <ApplicationVersion>{config.appVersion.substring(0, 8)}</ApplicationVersion>}
  </Application>
);
