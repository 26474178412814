import { compose, withProps } from 'recompose';
import { withLabel } from '../../../se/components/Label';
import NumberInput from '../../../se/components/inputs/NumberInput';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import React, { Fragment, useState } from 'react';
import Button from '../../../se/components/Button';
import { RouterlessModal } from '../../../se/components/Modal';
import { breakpoint } from '../../../se/utilities/responsive';
import Form from '../../../se/components/Form';
import { CenteredSpinner } from '../../../se/components/Spinner';
import { useMutation, useQuery } from '@apollo/client';
import { getGlobalAvgTimesConfiguration, setGlobalAvgTimesConfig } from '../../../graph/procedureTypes';
import get from 'lodash/get';

const GlobalAverageTimesGlobalConfigInput = withProps({
  schema: {
    expectedWRDuration: compose(
      withLabel('Avg. time in Waiting Room (mins)'),
      withProps({ required: true, min: 0 })
    )(NumberInput),
    expectedPreOpDuration: compose(
      withLabel('Avg. time in PRE-OP (mins)'),
      withProps({ required: true, min: 0 })
    )(NumberInput),
    expectedDuration: compose(withLabel('Avg. time in OR (mins)'), withProps({ required: true, min: 0 }))(NumberInput),
    expectedPacuDuration: compose(
      withLabel('Avg. time in Recovery (mins)'),
      withProps({ required: true, min: 0 })
    )(NumberInput),
  },
})(ObjectInput);

export const GlobalAverageTimesButton = () => {
  const [open, setOpen] = useState(false);

  const { loading, data } = useQuery(getGlobalAvgTimesConfiguration);
  const [setGlobalConfig] = useMutation(setGlobalAvgTimesConfig, {
    refetchQueries: [{ query: getGlobalAvgTimesConfiguration }],
  });

  const handleSubmit = async values => {
    try {
      await setGlobalConfig({
        variables: {
          expectedWRDuration: get(values, 'expectedWRDuration', 0),
          expectedPreOpDuration: get(values, 'expectedPreOpDuration', 0),
          expectedDuration: get(values, 'expectedDuration', 0),
          expectedPacuDuration: get(values, 'expectedPacuDuration', 0),
        },
      });
    } finally {
      setOpen(false);
    }
  };

  return (
    <Fragment>
      <Button onClick={() => setOpen(true)}>Set Global Avg. Times</Button>
      {open && (
        <RouterlessModal
          title={'Avg. Times Global Configuration'}
          onClose={() => setOpen(false)}
          fullWindow={window.innerWidth < breakpoint.md}
        >
          {!loading ? (
            <Form
              autoFocus
              initialValue={get(data, 'averageTimesConfiguration')}
              input={GlobalAverageTimesGlobalConfigInput}
              label={'Save'}
              onSubmit={handleSubmit}
              onCancel={() => setOpen(false)}
            />
          ) : (
            <CenteredSpinner />
          )}
        </RouterlessModal>
      )}
    </Fragment>
  );
};
