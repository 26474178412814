import React, { useState } from 'react';
import SimpleReactLightbox from 'simple-react-lightbox';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Lightbox from 'lightbox-react';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Image from 'material-ui-image';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { defaultTo, get } from 'lodash';
import clsx from 'clsx';
import useHasAccessRight from '../../../hooks/useHasAccessRight';
import { ArrowDownward, Print } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    padding: theme.spacing(0, 2, 2),
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  panel: {
    display: 'flex',
    flexDirection: 'column',
    border: `${theme.palette.type === 'dark' ? 'default' : `1px solid ${theme.palette.divider}`}`,
  },
  appBar: {},
  title: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  tile: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: '100%',
    height: 0,
    position: 'relative',
  },
  tooltip: {
    flex: 1,
    width: '100%',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
  },
  blurred: {
    filter: 'blur(1rem)',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Document = ({ title, icon, formType, images }) => {
  const hasAccessRight = useHasAccessRight();
  const isAllowedToView = hasAccessRight('patient.view');
  const theme = useTheme();
  const [preview, setPreview] = useState(null);
  const classes = useStyles();

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const isMd = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  const handleImageOpen = index => {
    setPreview(index);
  };

  const handleImageClose = () => {
    setPreview(null);
  };

  const handleThumbnailClick = url => {
    const thumbIndex = onlyImages().findIndex(item => item.url === url);
    if (thumbIndex > -1) {
      setLightboxIndex(thumbIndex);
    } else {
      setLightboxIndex(0);
    }
    setLightboxOpen(true);
  };

  const handleDownloadLightBox = () => {
    const lightboxImages = onlyImages();
    let index = lightboxIndex;
    if (index < 0 || index > lightboxImages.length - 1) index = 0;

    let imageName = lightboxImages[index].name || '';
    imageName = imageName.substring(0, imageName.lastIndexOf('_'));

    const urlToDownload = lightboxImages[index].url;
    const link = document.createElement('a');
    link.download = imageName;
    link.target = '_blank';
    link.href = urlToDownload;
    link.text = 'Image download';
    link.dispatchEvent(new MouseEvent('click'));
    URL.revokeObjectURL(link.href);
  };

  const handlePrintImage = () => {
    const lightboxImages = onlyImages();
    let index = lightboxIndex;
    if (index < 0 || index > lightboxImages.length - 1) index = 0;

    const urlToPrint = lightboxImages[index].url;

    const win = window.open();
    win.document.write(`<img style='max-width: 100%' src='${urlToPrint}' onload="window.print()" />`);
    win.focus();
  };

  const onlyImages = () =>
    images.filter(image => image.type === 'picture').map(image => ({ name: image.name, url: image.data }));

  const lightboxMainSrc = () => onlyImages()[lightboxIndex].url;

  const lightboxNextSrc = () => {
    const items = onlyImages();
    return items[(lightboxIndex + 1) % items.length].url;
  };

  const lightboxPrevSrc = () => {
    const items = onlyImages();
    return items[(lightboxIndex + items.length - 1) % items.length].url;
  };

  const lightboxMovePrev = () => {
    const items = onlyImages();
    setLightboxIndex((lightboxIndex + items.length - 1) % items.length);
  };

  const lightboxMoveNext = () => {
    setLightboxIndex((lightboxIndex + 1) % onlyImages().length);
  };

  return (
    <Paper elevation={0} className={classes.panel} style={{ flex: 1 }}>
      <Box pl={2} pr={1} py={1} display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Box mr={1} display="flex">
            <Icon color="disabled">{icon}</Icon>
          </Box>
          <Typography variant="subtitle2">{title}</Typography>
        </Box>
      </Box>

      {lightboxOpen && (
        <Lightbox
          mainSrc={lightboxMainSrc()}
          nextSrc={lightboxNextSrc()}
          prevSrc={lightboxPrevSrc()}
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={lightboxMovePrev}
          onMoveNextRequest={lightboxMoveNext}
          toolbarButtons={[
            <IconButton onClick={handleDownloadLightBox}>
              <ArrowDownward fontSize={'small'} />
            </IconButton>,
            <IconButton onClick={handlePrintImage}>
              <Print fontSize={'small'} />
            </IconButton>,
          ]}
        />
      )}

      {images.length !== 0 ? (
        <div className={classes.root}>
          <GridList cols={isMd ? 4 : 6} cellHeight="auto" style={{ flex: 1 }}>
            {images.map((image, index) => (
              <GridListTile key={index}>
                <Box
                  display="flex"
                  alignItems="stretch"
                  className={clsx(classes.tile, { [classes.blurred]: !isAllowedToView })}
                >
                  {image.type === 'picture' ? (
                    <Tooltip
                      title={
                        isAllowedToView ? null : 'You don’t have sufficient permissions to edit or view this document.'
                      }
                    >
                      <Image
                        className={[formType, image.name].join(' ')}
                        key={index + formType + image.name}
                        src={image.data}
                        onClick={() => isAllowedToView && handleThumbnailClick(image.data)}
                        color={theme.palette.background.default}
                        loading={<CircularProgress size={16} />}
                        animationDuration={1500}
                        aspectRatio={1}
                        style={{
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                        }}
                        imageStyle={{
                          margin: 'auto',
                          objectFit: 'cover',
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={
                        isAllowedToView
                          ? get(image, 'name', '').substring(0, get(image, 'name', '').lastIndexOf('_'))
                          : 'You don’t have sufficient permissions to edit or view this document.'
                      }
                      className={classes.tooltip}
                    >
                      <IconButton onClick={() => isAllowedToView && handleImageOpen(index)}>
                        <Icon fontSize="large" color="primary">
                          picture_as_pdf
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </GridListTile>
            ))}
          </GridList>
        </div>
      ) : (
        <Box pb={2} px={2} flex={1} textAlign="center" display="flex" alignItems="center" justifyContent="center">
          <Typography variant="body2" color="textSecondary" style={{ opacity: 0.35 }}>
            No docs here.
          </Typography>
        </Box>
      )}

      <Dialog
        fullScreen={images[preview] && images[preview].type === 'file'}
        open={preview !== null}
        onClose={handleImageClose}
        TransitionComponent={Transition}
      >
        {images[preview] ? (
          images[preview].type === 'picture' ? (
            <img src={images[preview].data} style={{ maxWidth: '100%' }} alt="preview" />
          ) : (
            <React.Fragment>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleImageClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  {images[preview].name.substring(0, get(images, `[${preview}].name`, '').lastIndexOf('_'))}
                </Typography>
              </Toolbar>
              <iframe
                title={images[preview].name}
                src={images[preview].data}
                style={{ width: '100%', height: '100%' }}
              />
            </React.Fragment>
          )
        ) : null}
      </Dialog>
    </Paper>
  );
};

const enrichFile = ({ path, ...rest }) => {
  if (path && path.url) {
    let extractedName = path.url.substring(path.url.lastIndexOf('/') + 1);
    extractedName = extractedName.substring(3, extractedName.indexOf('?'));
    return {
      ...rest,
      type: path.url.indexOf('.pdf') > -1 ? 'file' : 'picture',
      data: path.url,
      name: decodeURI(extractedName),
    };
  } else {
    return { ...rest };
  }
};

const UploadedForms = ({ forms = [], procedureId }) => (
  <Grid container spacing={1} style={{ marginTop: '1em' }}>
    {defaultTo(forms, [])
      .filter(item => item.files.length !== 0)
      .map((item, index) => (
        <SimpleReactLightbox key={index}>
          <Grid item xs={12} sm={6} md={4} lg={4} key={index} style={{ display: 'flex' }}>
            <Document
              title={item.name}
              icon={item.icon}
              procedure={procedureId}
              form={item.formId}
              formType={item.name}
              images={item.files.map(enrichFile)}
            />
          </Grid>
        </SimpleReactLightbox>
      ))}
  </Grid>
);

export default UploadedForms;
