import { compose, withProps } from 'recompose';
import { withLabel } from '../../../se/components/Label';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import TextInputComponent from '../../../se/components/inputs/TextInput';
import { NotificationNumbersInput } from '../../inputs/NotificationNumbersInput';
import AddressInput from '../../inputs/AddressInput';

const TextInput = TextInputComponent as any;

export default withProps({
  schema: {
    name: compose(
      withLabel('Name'),
      withProps({
        placeholder: 'e.g., Mark Nordstrom',
        autoComplete: 'off',
        required: true,
      })
    )(TextInput),
    address: AddressInput,
    phoneNumber: NotificationNumbersInput,
    email: compose(
      withLabel('Email'),
      withProps({
        placeholder: 'e.g., john@clinic.edu',
        autoComplete: 'off',
        required: false,
      })
    )(TextInput),
  },
})(ObjectInput);
