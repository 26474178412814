import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from '@material-ui/core';
import config from '../../../../config';
import { useOrganizationId } from '../../../../contexts/OrganizationIdContext';
import { gql, useMutation, useSubscription } from '@apollo/client';
import { useAccessToken } from '../AccessToken';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import PauseIcon from '@material-ui/icons/Pause';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PhysicianChartingForm from './PhysicianChartingForm';
import AlertDialog from '../../../AlertDialog';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDictationContext } from '../../../../contexts/DictationsContext';
import { useChartingSession } from './modules/hooks';

interface DictationPanelProps {
  patientId: number;
  procedureId?: number;
  withAccordion?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  animatedItem: {
    animation: `$myEffect 1000ms ${theme.transitions.easing.easeInOut}`,
  },
  '@keyframes myEffect': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
}));

const DictationPanel = ({ patientId, procedureId, withAccordion }: DictationPanelProps) => {
  const theme = useTheme();
  const organizationId = useOrganizationId();
  const accessToken = useAccessToken();
  const chartingSession = useChartingSession();
  const classes = useStyles();

  const [newDictationId, setNewDictationId] = useState<number | null>(null);

  // const recorderControls = useAudioRecorder(
  //   {
  //     noiseSuppression: true,
  //     echoCancellation: true,
  //   },
  //   e => {
  //     console.error('onNotAllowedOrFound', e);
  //   }
  // );

  const submitRef = useRef<() => Promise<void>>(() => Promise.resolve());

  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [recording, setRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);

  useEffect(() => {
    return () => {
      if (mediaStream) {
        mediaStream.getTracks().forEach(track => track.stop());
      }
    };
  }, [mediaStream]);

  useEffect(() => {
    if (
      !recording &&
      mediaRecorder?.state !== 'paused' &&
      recordedChunks.length > 0 &&
      organizationId &&
      accessToken &&
      chartingSession
    ) {
      const blob = new Blob(recordedChunks, { type: 'audio/mp4' });
      void (async () => {
        const searchParams = new URLSearchParams();
        searchParams.append('hospital', organizationId);
        searchParams.append('token', accessToken);
        searchParams.append('patientId', patientId.toString());
        searchParams.append('pin', chartingSession.pin);

        const response = await fetch(`${config.apiURL}/physician/dictation?${searchParams.toString()}`, {
          method: 'POST',
          body: blob,
        });

        console.log(response);

        const json = await response.json();

        setNewDictationId(json?.dictationId);
      })();
      console.log('Recording stopped. Blob:', blob);
    }
  }, [recording, recordedChunks, organizationId, accessToken, patientId, chartingSession?.pin]);

  const dictations = useSubscription(
    gql`
      subscription dictations($patientId: Long!, $pin: String!) {
        dictations(patientId: $patientId, pin: $pin) {
          id
          transcription
          audioFile
        }
      }
    `,
    {
      variables: {
        patientId,
        pin: chartingSession!.pin,
      },
      skip: !patientId,
    }
  );

  const { setNewDictation } = useDictationContext();

  useEffect(() => {
    if (
      newDictationId &&
      !!((dictations.data?.dictations || []).find(e => e.id === newDictationId)?.transcription || '')
    ) {
      setNewDictation((dictations.data?.dictations || []).find(e => e.id === newDictationId)?.transcription || '');
      setNewDictationId(null);
    }
  }, [dictations, newDictationId]);

  const start = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        setMediaStream(stream);

        const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        const mimeType = isChrome ? 'audio/webm; codecs=opus' : 'audio/mp4';

        const recorder = new MediaRecorder(stream, { mimeType });
        setMediaRecorder(recorder);

        recorder.ondataavailable = e => {
          setRecordedChunks(prev => [...prev, e.data]);
        };

        recorder.onstart = () => {
          setRecording(true);
        };

        recorder.onstop = () => {
          setRecording(false);
        };

        recorder.onpause = () => {
          setRecording(false);
        };

        recorder.onresume = () => {
          setRecording(true);
        };

        setRecordedChunks([]);
        recorder.start(1000);
      })
      .catch(error => {
        console.error('Error accessing media devices:', error);
      });
  };

  const stop = () => {
    if (mediaRecorder) {
      mediaRecorder?.stop();
    }
  };

  const pause = () => {
    mediaRecorder?.pause();
  };

  const resume = () => {
    mediaRecorder?.resume();
  };

  const RecordButton = withStyles(theme => ({
    root: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
  }))(Button);

  const [removeDictation] = useMutation(
    gql`
      mutation removeDictation($dictationId: Long!) {
        removeDictation(dictationId: $dictationId)
      }
    `
  );

  const [dictationId, setDictationId] = useState<number | null>(null);

  const handleRemove = async () => {
    await removeDictation({
      variables: { dictationId },
    });
  };

  const DictationComponent = (
    <Box minHeight={0} overflow="hidden" display="flex" style={{ gap: 16, borderBottom: '1px solid gray' }}>
      <Box display="flex" flexDirection="column" minHeight={0} style={{ width: '30%' }}>
        <Box py={2}>
          {mediaRecorder?.state === 'recording' ? (
            <Box display="flex" alignItems="baseline" style={{ gap: theme.spacing(2) }}>
              <RecordButton
                variant="contained"
                startIcon={<PauseIcon className={classes.animatedItem} />}
                aria-label="pause"
                onClick={pause}
              >
                Pause recording
              </RecordButton>
            </Box>
          ) : mediaRecorder?.state === 'paused' ? (
            <Box display="flex" style={{ gap: theme.spacing(1) }}>
              <Box color={theme.palette.error.main}>
                <RecordButton variant="contained" aria-label="resume" onClick={resume}>
                  Resume
                </RecordButton>
              </Box>
              <Button variant="outlined" color="primary" aria-label="done" onClick={stop}>
                Done
              </Button>
            </Box>
          ) : (
            <RecordButton variant="contained" startIcon={<FiberManualRecordIcon />} aria-label="delete" onClick={start}>
              Start recording
            </RecordButton>
          )}
        </Box>

        <List style={{ overflow: 'auto' }}>
          {(dictations.data?.dictations || []).map((d, index) => (
            <>
              <ListItem disableGutters key={index}>
                <Box display="flex" flexDirection="column" width="100%">
                  <Box display="flex" alignItems="center" style={{ gap: 8 }}>
                    <audio src={d.audioFile} controls preload="auto" style={{ width: '100%' }} />
                    <Box>
                      <IconButton aria-label="delete" onClick={() => setDictationId(d.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="space-between" style={{ gap: 8 }}>
                    <ListItemText primary={`Dictation #${d.id}`} secondary={d.transcription} />
                  </Box>
                </Box>
              </ListItem>
              {index !== dictations.data?.dictations.length - 1 && <Divider component="li" />}
            </>
          ))}
        </List>
      </Box>

      {!!procedureId && (
        <Box overflow="auto" flex={1.5}>
          <PhysicianChartingForm patientId={patientId} procedureId={procedureId} />
        </Box>
      )}
      <AlertDialog
        title={'Remove Transcription'}
        subtitle={'Are you sure you want to remove this transcription?'}
        open={dictationId !== null}
        setOpen={() => setDictationId(null)}
        onConfirm={() => handleRemove()}
      />
    </Box>
  );

  return withAccordion ? (
    <Accordion variant="outlined">
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="dictation-content" id="dictation-header">
        <Box p={2}>
          <Typography variant="h3" gutterBottom>
            Dictations ({dictations.data?.dictations.length || 0})
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{DictationComponent}</AccordionDetails>
    </Accordion>
  ) : (
    <>{DictationComponent}</>
  );
};

export default DictationPanel;

// const formatDurationInSeconds = (duration: number) => {
//   const minutes = Math.floor(duration / 60);
//   const seconds = duration % 60;
//   return `${padLeft(minutes.toString(10), 2, '0')}:${padLeft(seconds.toString(10), 2, '0')}`;
// };

const padLeft = (value: string, length: number, char: string) => value.padStart(length, char);
