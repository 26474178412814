import React, { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core';

interface TableCellProps {
  row: number;
  column: number;
  topOffset: number;
  leftOffset: number;
  className?: string;
  style?: CSSProperties;
  children: (row: number, column: number) => ReactNode;
}

const TableCell = ({ row, column, topOffset, leftOffset, className, style, children }: TableCellProps) => {
  const theme = useTheme();

  return (
    <div
      className={clsx(className)}
      style={{
        whiteSpace: 'pre',
        gridRow: `${1 + row + topOffset} / span 1`,
        gridColumn: `${1 + column + leftOffset} / span 1`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderRight: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...(style || {}),
      }}
    >
      {children(row, column)}
    </div>
  );
};

export default TableCell;
