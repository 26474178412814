import { alpha, Theme } from '@material-ui/core';

export default function FilledInput(theme: Theme) {
  const light = theme.palette.type === 'light';

  return {
    MuiFilledInput: {
      root: {
        backgroundColor: alpha(theme.palette.primary.light, 0.1),
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.light, 0.2),
        },
        '&$focused': {
          backgroundColor: alpha(theme.palette.secondary.main, 0.1),
        },
      },
      underline: {
        '&:after': {
          borderBottom: `2px solid ${theme.palette.secondary.main}`,
        },
      },
    },
  };
}
