import React, { ReactNode } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface DataItemProps {
  name: string | undefined;
  value: ReactNode | undefined;
  unit: string | null;
  isRemarkable?: boolean;
}

export const DataItem = ({ name, value, unit, isRemarkable }: DataItemProps) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      draggable={false}
      style={{ color: isRemarkable ? theme.palette.error.main : null }}
    >
      <Typography style={{ textTransform: 'uppercase' }}>{name}</Typography>
      <Typography variant="h1" className={classes.value}>
        {value || '-'}
      </Typography>
      <Typography style={{ visibility: !!(value && unit) ? 'visible' : 'hidden' }}>{unit || '-'}</Typography>
    </Box>
  );
};

const useStyles = makeStyles({
  procedureText: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    fontWeight: 600,
  },
  value: {
    fontFamily: '"Oswald", sans-serif',
  },
});

export default DataItem;
