import React, { CSSProperties, ReactNode, VFC } from 'react';
import CalendarMonth from './CalendarMonth';
import Dates from './types/Dates';
import YearMonth from './types/YearMonth';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface CalendarYearProps {
  year: number;
  selectedDates: Dates;
  setSelectedDates: (datesOrFn: Dates | ((prev: Dates) => Dates)) => void;
  highlightedDates: Dates;
  renderDate?: (date: Date) => ReactNode;
  renderDateTooltip?: (date: Date) => ReactNode;
  style?: CSSProperties;
}

const CalendarYear: VFC<CalendarYearProps> = ({
  year,
  selectedDates,
  setSelectedDates,
  highlightedDates,
  renderDate,
  renderDateTooltip,
  style,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={style}>
      <Typography variant="h4" gutterBottom>
        {year}
      </Typography>
      <div className={classes.calendars}>
        {[...Array(12)].map((_, i) => (
          <CalendarMonth
            key={i}
            yearMonth={YearMonth.of(year, i)}
            renderDate={renderDate}
            renderDateTooltip={renderDateTooltip}
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            highlightedDates={highlightedDates}
          />
        ))}
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    userSelect: 'none',
  },
  calendars: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
}));

export default CalendarYear;
