import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTheme } from '@material-ui/core';
import { PostAnesthesiaRecoveryScoreAnswer, PostAnesthesiaRecoveryScoreV2Answer } from '../../../../types/Answer';
import produce from 'immer';
import { useSave } from './SaveProvider';
import { useSlideIn } from '../../../../form/Form';

interface PostAnesthesiaRecoveryScoreProps {
  value?: PostAnesthesiaRecoveryScoreV2Answer;
  onChange: (value: PostAnesthesiaRecoveryScoreV2Answer) => void;
}

type ColumnKey = 'in' | 'in15' | 'in30' | 'out'
const columns = ['IN', '15', '30', 'OUT'];
const columnsKeys: ColumnKey[] = ['in', 'in15', 'in30', 'out'];

const calcTotal = (
  value: PostAnesthesiaRecoveryScoreV2Answer,
  field: ColumnKey,
  prevFieldValue?: 0 | 1 | 2,
  nextFieldValue?: 0 | 1 | 2
) => {
  const oldTotal =
    (value?.[field]?.resp || 0) +
    (value?.[field]?.circ || 0) +
    (value?.[field]?.activity || 0) +
    (value?.[field]?.loc || 0) +
    (value?.[field]?.o2saturation || 0);
  return (oldTotal || 0) - (prevFieldValue || 0) + (nextFieldValue || 0);
};

const isCompletedColumn = (key: ColumnKey, value: PostAnesthesiaRecoveryScoreV2Answer) =>
  ['resp', 'circ', 'activity', 'loc', 'o2saturation'].every(
    j => value?.[key]?.[j] !== undefined && value?.[key]?.[j] !== null
  );

const PostAnesthesiaRecoveryScoreV2 = ({ value: answer, onChange }: PostAnesthesiaRecoveryScoreProps) => {
  const classes = useStyles();

  const theme = useTheme();

  const { validationRequest } = useSave();
  const slideIn = useSlideIn();

  const value = useMemo(
    () =>
      answer || {
        in: {},
        in15: {},
        in30: {},
        out: {},
      },
    [answer]
  );

  useEffect(() => {
    if (validationRequest) {
      if (!value) {
        slideIn();
      }
    }
  }, [validationRequest, value, slideIn]);

  return (
    <div className={classes.centered}>
      <div className={classes.root}>
        <>
          {columnsKeys.map((e, i) => (
            <div
              style={{
                gridRow: '1 / span 23',
                gridColumn: `${i + 3} / span 1`,
                borderRadius: '0.5rem',
                backgroundColor: validationRequest
                  ? isCompletedColumn(columnsKeys[i], value)
                    ? theme.palette.secondary.light
                    : theme.palette.error.light
                  : theme.palette.secondary.light,
              }}
            />
          ))}
        </>

        <>
          <div
            style={{
              gridRow: '1 / span 1',
              gridColumn: '1 / span 2',
              textAlign: 'center',
              marginTop: '0.4rem',
              fontSize: '23px',
              fontWeight: 500,
            }}
          >
            ALDRETE
          </div>
          {columns.map((e, i) => (<div
            style={{
              gridRow: '1 / span 1',
              gridColumn: `${i + 3} / span 1`,
              textAlign: 'center',
              marginTop: '0.4rem',
              fontSize: '23px',
              fontWeight: 500,
            }}
          >
            {e}
          </div>))}
        </>
        <div style={{ gridRow: `2 / span 1`, gridColumn: '1 / span 6', height: '0.5rem', marginTop: '8px' }}>
          <div style={{ backgroundColor: '#1976D2', width: '100%', height: '1px' }} />
        </div>
        <Aspect
          row={3}
          title="Activity"
          value={{
            in:  value.in.activity,
            in15:  value.in15.activity,
            in30:  value.in30.activity,
            out: value.out.activity,
          }}
          onChange={v =>
            onChange(
              produce(value, draft => {
                draft.in.activity = v.in;
                draft.in15.activity = v.in15;
                draft.in30.activity = v.in30;
                draft.out.activity = v.out;
                draft.in.total = calcTotal(value, 'in', value.in.activity, v.in);
                draft.in15.total = calcTotal(value, 'in15', value.in15.activity, v.in15);
                draft.in30.total = calcTotal(value, 'in30', value.in30.activity, v.in30);
                draft.out.total = calcTotal(value, 'out', value.out.activity, v.out);
              })
            )
          }
        />
        <Aspect
          row={7}
          title="Respiration"
          value={{
            in:  value.in.resp,
            in15:  value.in15.resp,
            in30:  value.in30.resp,
            out: value.out.resp,
          }}
          onChange={v =>
            onChange(
              produce(value, draft => {
                draft.in.resp = v.in;
                draft.in15.resp = v.in15;
                draft.in30.resp = v.in30;
                draft.out.resp = v.out;
                draft.in.total = calcTotal(value, 'in', value.in.resp, v.in);
                draft.in15.total = calcTotal(value, 'in15', value.in15.resp, v.in15);
                draft.in30.total = calcTotal(value, 'in30', value.in30.resp, v.in30);
                draft.out.total = calcTotal(value, 'out', value.out.resp, v.out);
              })
            )
          }
        />
        <Aspect
          row={11}
          title="Circulation"
          value={{
            in:  value.in.circ,
            in15:  value.in15.circ,
            in30:  value.in30.circ,
            out: value.out.circ,
          }}
          onChange={v =>
            onChange(
              produce(value, draft => {
                draft.in.circ = v.in;
                draft.in15.circ = v.in15;
                draft.in30.circ = v.in30;
                draft.out.circ = v.out;
                draft.in.total = calcTotal(value, 'in', value.in.circ, v.in);
                draft.in15.total = calcTotal(value, 'in15', value.in15.circ, v.in15);
                draft.in30.total = calcTotal(value, 'in30', value.in30.circ, v.in30);
                draft.out.total = calcTotal(value, 'out', value.out.circ, v.out);
              })
            )
          }
        />
        <Aspect
          row={15}
          title="LOC"
          value={{
            in:  value.in.loc,
            in15:  value.in15.loc,
            in30:  value.in30.loc,
            out: value.out.loc,
          }}
          onChange={v =>
            onChange(
              produce(value, draft => {
                draft.in.loc = v.in;
                draft.in15.loc = v.in15;
                draft.in30.loc = v.in30;
                draft.out.loc = v.out;
                draft.in.total = calcTotal(value, 'in', value.in.loc, v.in);
                draft.in15.total = calcTotal(value, 'in15', value.in15.loc, v.in15);
                draft.in30.total = calcTotal(value, 'in30', value.in30.loc, v.in30);
                draft.out.total = calcTotal(value, 'out', value.out.loc, v.out);
              })
            )
          }
        />
        <Aspect
          row={19}
          title="O₂ Saturation"
          value={{
            in:  value.in.o2saturation,
            in15:  value.in15.o2saturation,
            in30:  value.in30.o2saturation,
            out: value.out.o2saturation,
          }}
          onChange={v =>
            onChange(
              produce(value, draft => {
                draft.in.o2saturation = v.in;
                draft.in15.o2saturation = v.in15;
                draft.in30.o2saturation = v.in30;
                draft.out.o2saturation = v.out;
                draft.in.total = calcTotal(value, 'in', value.in.o2saturation, v.in);
                draft.in15.total = calcTotal(value, 'in15', value.in15.o2saturation, v.in15);
                draft.in30.total = calcTotal(value, 'in30', value.in30.o2saturation, v.in30);
                draft.out.total = calcTotal(value, 'out', value.out.o2saturation, v.out);
              })
            )
          }
        />
        <>
          <div
            style={{
              gridRow: '23 / span 1',
              gridColumn: '1 / span 2',
              textAlign: 'right',
              marginBottom: '0.25rem',
              fontWeight: 500,
            }}
          >
            Total
          </div>

          {columnsKeys.map((e, i) => (
            <div
              style={{
                gridRow: '23 / span 1',
                gridColumn: `${3 + i} / span 1`,
                textAlign: 'center',
                marginBottom: '0.25rem',
                fontWeight: 500,
              }}
            >
              {calcTotal(value, e)}
            </div>
          ))}
        </>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  centered: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  root: {
    margin: 'auto',
    whiteSpace: 'nowrap',
    display: 'grid',
    gridTemplateColumns: 'min-content min-content repeat(5, 4rem)',
    fontSize: '14px',
    columnGap: '1rem',
    '& > div': {
      paddingTop: '0.25rem',
      paddingBottom: '0.25rem',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },
});

export default PostAnesthesiaRecoveryScoreV2;

interface AspectValue {
  in?: 0 | 1 | 2;
  in15?: 0 | 1 | 2;
  in30?: 0 | 1 | 2;
  out?: 0 | 1 | 2;
}

interface AspectProps {
  row: number;
  title: string;
  value: AspectValue;
  onChange: (value: AspectValue) => void;
  optionValue?: 0 | 1 | 2;
}

const AspectRow = ({ row, value, optionValue, onChange }: AspectProps) => (
  <>
    <div
      style={{
        gridRow: `${row} / span 1`,
        gridColumn: '3 / span 1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Selection
        selected={value.in === optionValue}
        onClick={() =>
          onChange(
            produce(value, draft => {
              draft.in = draft.in === optionValue ? undefined : optionValue;
            })
          )
        }
      >
        {optionValue}
      </Selection>
    </div>
    <div
      style={{
        gridRow: `${row} / span 1`,
        gridColumn: '4 / span 1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Selection
        selected={value.in15 === optionValue}
        onClick={() =>
          onChange(
            produce(value, draft => {
              draft.in15 = draft.in15 === optionValue ? undefined : optionValue;
            })
          )
        }
      >
        {optionValue}
      </Selection>
    </div>
    <div
      style={{
        gridRow: `${row} / span 1`,
        gridColumn: '5 / span 1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Selection
        selected={value.in30 === optionValue}
        onClick={() =>
          onChange(
            produce(value, draft => {
              draft.in30 = draft.in30 === optionValue ? undefined : optionValue;
            })
          )
        }
      >
        {optionValue}
      </Selection>
    </div>
    <div
      style={{
        gridRow: `${row} / span 1`,
        gridColumn: '6 / span 1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Selection
        selected={value.out === optionValue}
        onClick={() =>
          onChange(
            produce(value, draft => {
              draft.out = draft.out === optionValue ? undefined : optionValue;
            })
          )
        }
      >
        {optionValue}
      </Selection>
    </div>
  </>
);

const Aspect = ({ row, title, value, onChange }: AspectProps) => (
  <>
    <div style={{ gridRow: `${row} / span 1`, gridColumn: '1 / span 1' }}>{title}</div>
    <div style={{ gridRow: `${row} / span 1`, gridColumn: '2 / span 1', visibility: 'hidden' }}>{'-'}</div>
    <AspectRow {...{row, title, value, onChange, optionValue: 2}} />
    <div style={{ gridRow: `${row + 1} / span 1`, gridColumn: '2 / span 1', visibility: 'hidden' }}>{'-'}</div>
    <AspectRow {...{row: row + 1, title, value, onChange, optionValue: 1}} />
    <div style={{ gridRow: `${row + 2} / span 1`, gridColumn: '2 / span 1', visibility: 'hidden' }}>{'-'}</div>
    <AspectRow {...{row: row + 2, title, value, onChange, optionValue: 0}} />
    <div style={{ gridRow: `${row + 3} / span 1`, gridColumn: '1 / span 6', height: '0.5rem' }}>
      <div style={{ backgroundColor: '#1976D2', width: '100%', height: '1px' }} />
    </div>
  </>
);

interface SelectionProps {
  selected: boolean;
  onClick: () => void;
  children: ReactNode;
}

const Selection = ({ selected, onClick, children }: SelectionProps) => (
  <div
    style={{
      width: '28px',
      height: '28px',
      margin: '-28px',
      borderRadius: '50%',
      backgroundColor: selected ? '#1976D2' : 'transparent',
      color: selected ? 'white' : undefined,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      userSelect: 'none',
    }}
    onClick={onClick}
  >
    {children}
  </div>
);
