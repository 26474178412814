import React, { FC, useEffect, useMemo } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { listAssignedStaffMembers, sendNewScheduleNotification } from '../../../../../graph/staff';
import { format } from 'date-fns';
import StaffMember from '../../../../../types/StaffMember';
import { useScope } from '../../../../../hooks/useScope';
import { Destination } from '../../../../../types/NotificationTemplate';
import SendNotificationDialog, { Contact } from './SendNotificationDialog';
import { MenuItem } from '@material-ui/core';
import { TooltipWrapper } from '../SendNotificationDropdown';

type StaffMemberContact = {
  staffId: number;
  staffHospitalId: number;
  phoneNumber: string;
};

const SendNotification: FC<{
  openDialog: boolean;
  handleOpenDialog: () => void;
  handleCloseDialog: () => void;
  date: Date;
  notifyStaffMembers: boolean;
}> = ({ openDialog, handleOpenDialog, handleCloseDialog, date, notifyStaffMembers }) => {
  const { data } = useQuery(listAssignedStaffMembers, {
    variables: { date: format(date, 'YYYY-MM-DD') },
    skip: !openDialog,
  });

  const client = useApolloClient();
  useEffect(() => {
    openDialog &&
      client.refetchQueries({
        // @ts-ignore
        include: [{ query: listAssignedStaffMembers, variables: { date: format(date, 'YYYY-MM-DD') } }],
      });
  }, [openDialog, date]);

  const recipients = useMemo(
    () => (data?.listAssignedStaffMembers || []).map((e: StaffMember) => ({ ...e, id: e.staffId })),
    [data]
  );

  const [doSend] = useMutation(sendNewScheduleNotification);

  const scope = useScope();

  const sendNotifications = async (recipients: Contact[], message: String) => {
    const staffContacts: StaffMemberContact[] = recipients.map(e => ({
      staffId: e.id,
      staffHospitalId: scope?.hospital?.id,
      phoneNumber: e.phoneNumber,
    }));

    await doSend({
      variables: {
        staffContacts,
        message,
        date: format(date, 'YYYY-MM-DD'),
      },
    });
  };

  return (
    <>
      <TooltipWrapper title={'No staff to notify'} isDisabled={!notifyStaffMembers}>
        <MenuItem disabled={!notifyStaffMembers} onClick={handleOpenDialog}>
          Staff Members
        </MenuItem>
      </TooltipWrapper>
      {openDialog && (
        <SendNotificationDialog
          date={date}
          open={openDialog}
          recipients={recipients}
          sendNotifications={sendNotifications}
          destination={Destination.StaffMember}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  );
};

export default SendNotification;
