import { Box, Button, Fade, Typography } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CurrentTime } from '../../charting/components';
import { green, pink } from '@material-ui/core/colors';
import { chartingStore } from '../../charting/modules/chartingStore';
import { useChartingSession } from '../../charting/modules/hooks';

const FooterSession = () => {
  const classes = useStyles();
  const session = useChartingSession();

  const isAnesthesiologist = session?.role === 'anesthesiologist';
  const isPhysician = session?.role === 'physician';

  return (
    <Box className={classes.footer} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Fade in={!!session}>
        <Typography>Editing as {session && (session.name || '')}</Typography>
      </Fade>
      <Fade in={!!session}>
        <div style={{ justifyContent: 'center' }}>
          <Button
            startIcon={
              <Lock style={{ color: isAnesthesiologist ? pink[100] : isPhysician ? green[100] : 'rgb(0, 167, 247)' }} />
            }
            onClick={() => chartingStore.setState({ session: null })}
            classes={{ root: classes.root }}
            className={classes[session?.role || 'anesthesiologist']}
          >
            Lock
          </Button>
        </div>
      </Fade>
      <div style={{ justifyContent: 'flex-end' }}>
        <CurrentTime />
      </div>
    </Box>
  );
};

export const useStyles = makeStyles(theme => ({
  footer: {
    display: 'flex',
    height: theme.spacing(3),
    gap: theme.spacing(3),
    '& > *': {
      flex: 1,
      display: 'flex',
    },
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(4, 5),
    },
    gap: theme.spacing(4),
  },
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 18,
    fontWeight: 600,
    padding: theme.spacing(1.5, 3),
    color: theme.palette.common.white,
    borderRadius: 32,
    display: 'flex',
    lineHeight: 1.375,
    backgroundColor: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  anesthesiologist: {
    backgroundColor: pink[500],
    '&:hover': {
      backgroundColor: pink[700],
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: pink[700],
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
  physician: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: green[700],
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
}));

export default FooterSession;
