import { compose, withProps } from 'recompose';
import SelectInput from '../../../se/components/inputs/SelectInput';
import { CompensationLabel } from '../../../types/StaffMember';
import { withLabel } from '../../../se/components/Label';

const CompensationSelectInput = compose(
  withLabel('Compensation'),
  withProps({
    options: CompensationLabel,
    placeholder: 'e.g., Hourly',
    required: false,
  })
)(SelectInput);

export default CompensationSelectInput;
