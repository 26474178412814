import React from 'react';
import Header from './components/Header';
import MedicalPassportTextArea from '../components/MedicalPassportTextArea';
import MedicalPassportRow from '../components/MedicalPassportRow';
import MedicalPassportRow2 from '../components/MedicalPassportRow2';
import MedicationTextArea from '../components/MedicationTextArea';
import MedicalPassportField from '../components/MedicalPassportField';
import styles from '../styles';
import toHighlightsString from '../../../../../questionnaire/toHighlightsString';
import toHighlightsString2 from '../../../../../questionnaire/toHighlightsString2';
import toHighlightsString3 from '../../../../../questionnaire/toHighlightsString3';
import { useValue } from '../ValueContext';
import {
  cardiovascular,
  endocrine,
  gastrointestinal,
  gynaecological,
  hematological,
  musculoskeletal,
  neurological,
  other,
  psychiatric,
  pulmonary,
  renal,
  liver,
  airway,
  ob,
} from './paths';
import Footer from './components/Footer';
import toAge from '../../../../../questionnaire/toAge';
import toHeight from '../../../../../questionnaire/toHeight';
import toWeight from '../../../../../questionnaire/toWeight';
import toBMI from '../../../../../questionnaire/toBMI';
import get from 'lodash/get';
import toWeightLbs from '../../../../../questionnaire/toWeightLbs';

function yesOrNo(value) {
  if (value === true) {
    return 'yes';
  } else if (value === false) {
    return 'no';
  }
  return '';
}

const Page5 = () => {
  const { questionnaire, questionnaireAnswers } = useValue();
  console.log(questionnaireAnswers);
  let familyProblem =
    yesOrNo(get(questionnaireAnswers, 'sections.PreProcedureAssessment.schema.associatedProblem.yes[0]', '')) +
    ', ' +
    get(
      questionnaireAnswers,
      'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text.text',
      ''
    );
  let alcohol = yesOrNo(get(questionnaireAnswers, 'sections.social.schema.drinking.yes', ''));
  let drugs = yesOrNo(get(questionnaireAnswers, 'sections.social.schema.drugs.yes', ''));
  // allergy
  const allergyList = get(questionnaireAnswers, 'sections.allergies.schema.q1.yes[1].schema.allergy_list.list', []);
  const allergyList2 = get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[0]', false);
  const allergyList3 = get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[0]', false);

  const allergyName = [];
  const reaction = [];

  for (let i = 0; i < allergyList.length; i++) {
    allergyName.push(get(allergyList[i], 'schema.q1_name.text', ''));
    reaction.push(get(allergyList[i], 'schema.q1_reaction.text', ''));
  }

  if (allergyList2) {
    allergyName.push('Latex');
    reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[1].schema.q2_reaction.text', ''));
  }

  if (allergyList3) {
    allergyName.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_name.text', ''));
    reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_reaction.text', ''));
  }

  function combineArrays(arr1, arr2) {
    let result = '';

    for (let i = 0; i < arr1.length && i < arr2.length; i++) {
      result += arr1[i] + ', ' + arr2[i] + '\n';
    }

    return result;
  }

  const smoker =
    get(questionnaireAnswers, 'sections.social.schema.smoking.option[0]', '') === 'N'
      ? get(questionnaireAnswers, 'sections.social.schema.smoking.option', '')
      : get(questionnaireAnswers, 'sections.social.schema.smoking.option[0]', '');

  function isSmoker(value) {
    if (value === 'Former Smoker') {
      return (
        'Former Smoker, ' +
        get(questionnaireAnswers, 'sections.social.schema.smoking.option[1].schema.former_amount.option') +
        ', ' +
        get(questionnaireAnswers, 'sections.social.schema.smoking.option[1].schema.former_quit.text')
      );
    } else if (value === 'Current Smoker') {
      return (
        'Current Smoker, ' +
        get(questionnaireAnswers, 'sections.social.schema.smoking.option[1].schema.current_amount.option') +
        ', ' +
        get(questionnaireAnswers, 'sections.social.schema.smoking.option[1].schema.current_howLong.text')
      );
    } else if (value === 'Never Smoked') {
      return 'Never Smoked';
    }
    return '';
  }

  return (
    <div id="page5" className="page">
      <Header pageNumber={4} pageCount={10} />
      <div style={styles.twoNotEqualColumns}>
        <div>
          <div style={styles.oneColumn}>Surgical History and Physical/Medication Reconciliation</div>
          <div style={styles.oneColumnLeftAlign}>
            <MedicalPassportField label="PreOp Diagnosis" name="page5.preOpDiag" />
          </div>
          <div style={styles.oneColumnLeftAlign}>
            <MedicalPassportField label="Planned Procedure" name="procedure.name" />
          </div>
          <div style={styles.sixEqualColumns}>
            <MedicalPassportField
              label="Age"
              name="page5.age"
              path={['demographicInformation', 'dob']}
              reducer={toAge}
            />
            <MedicalPassportField
              label="Sex"
              name="page5.sex"
              fontSize=".8rem"
              path={['demographicInformation', 'sex']}
              reducer={toHighlightsString}
            />
            <MedicalPassportField
              label="Ht"
              name="page5.Ht"
              path={['demographicInformation', 'bmi']}
              reducer={toHeight}
            />
            <MedicalPassportField
              label="Wt"
              name="page5.Wt"
              path={['demographicInformation', 'bmi']}
              reducer={toWeightLbs}
              fontSize={11}
            />
            <MedicalPassportField
              label="KG"
              name="page5.KG"
              path={['demographicInformation', 'bmi']}
              reducer={toWeight}
            />
            <MedicalPassportField
              label="BMI"
              name="page5.BMI"
              path={['demographicInformation', 'bmi']}
              reducer={toBMI}
            />
          </div>
        </div>
        <div>
          <div style={styles.oneColumnNoLeftYesBottom}>Polaris Surgery Center</div>
          <div style={{ borderRight: '1px solid black', fontSize: '.8rem' }}>
            <MedicalPassportField label="Patient Name" name="procedure.patient.name" />
          </div>
          <div style={{ borderRight: '1px solid black', fontSize: '.75rem' }}>
            <div style={styles.twoNotEqualColumnsNoBorder}>
              <MedicalPassportField label="DOB" name="page5.DOB" path={['demographicInformation', 'dob']} />
              <MedicalPassportField label="Physician" name="procedure.physician.name" />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black', borderBottom: '1px solid black', fontSize: '.75rem' }}>
            <div style={styles.twoNotEqualColumnsNoBorder}>
              <MedicalPassportField label="DOS" name="procedure.serviceTime" />
              <MedicalPassportField label="Account #" name="page5.AccountNum" />
            </div>
          </div>
        </div>
        <br></br>
      </div>
      <div style={styles.threeEqualColumns3}>
        <div style={{ marginRight: '.3rem' }}>
          <MedicalPassportTextArea
            label="Previous Surgical History"
            name="anesthesia1"
            path={['priorSurgeries']}
            reducer={toHighlightsString}
          />
        </div>
        <div style={{ marginRight: '.3rem' }}>
          <MedicalPassportTextArea
            label={'Current Medications'}
            name="medications1"
            path={['medications', 'medicationList']}
            reducer={toHighlightsString}
          />
        </div>
        <div>
          <MedicationTextArea
            label={'Allergies/Sensitivities'}
            name="allergies1"
            value={combineArrays(allergyName, reaction)}
          />
        </div>
      </div>
      <div style={styles.twoEqualColumns3}>
        <div style={{ borderRight: '1px solid black', borderBottom: '1px solid black' }}>
          Family History/SurgicalProblems
          <div>
            <MedicalPassportField label="PONV" name="page5.PONV" />
            <div style={styles.twoNotEqualColumns}>
              <div style={{ fontSize: '.9rem' }}>Family/Self problems with anesthesia:</div>
              <MedicationTextArea name="page5.problemAnesthesia" value={familyProblem} />
            </div>
          </div>
        </div>
        <div style={{ borderBottom: '1px solid black' }}>
          <div>
            <MedicalPassportTextArea label="Social History - Alcohol" name="page5.alc" path={['social', 'drinking']} />
            <MedicalPassportTextArea label="Social Drugs" name="page5.drugs" path={['social', 'drugs']} />
            <MedicalPassportField label="Cigarettes" name="page5.cigs" value={isSmoker(smoker)} />
          </div>
        </div>
      </div>
      <br></br>

      <div style={styles.greyBackground}>Review of Systems</div>
      <section style={{}}>
        <div
          style={{
            border: '1px solid black',
            lineHeight: '1.8em',
            borderLeft: 0,
            borderRight: 0,
          }}
        >
          <MedicalPassportRow2
            label="1. Airway"
            name="systems.airway"
            index={0}
            style={{ borderTop: 0 }}
            paths={airway}
            reducer={toHighlightsString2}
          />
          <MedicalPassportRow2
            label="2. Pulmonary"
            name="systems.pulmonary"
            paths={pulmonary}
            index={1}
            reducer={toHighlightsString2}
          />
          <MedicalPassportRow2
            label="3. Cardiovascular"
            name="systems.cardiovascular"
            paths={cardiovascular}
            index={2}
            reducer={toHighlightsString2}
          />
          <MedicalPassportRow2
            label="4. Gastrointestinal"
            name="systems.gastrointestinal"
            paths={gastrointestinal}
            index={3}
            reducer={toHighlightsString2}
          />
          <MedicalPassportRow2
            label="5. Renal"
            name="systems.renal"
            paths={renal}
            index={4}
            reducer={toHighlightsString2}
          />
          <MedicalPassportRow2
            label="6. Endocrine"
            name="systems.endocrine2"
            paths={endocrine}
            index={5}
            reducer={toHighlightsString2}
          />
          <MedicalPassportRow2
            label="7. Liver"
            name="systems.liver"
            index={6}
            paths={liver}
            reducer={toHighlightsString2}
          />
          <MedicalPassportRow2
            label="8. Neurological"
            name="systems.neurological"
            paths={neurological}
            index={7}
            reducer={toHighlightsString2}
          />

          <MedicalPassportRow2
            label="9. Musculo-skeletal"
            name="systems.musculoskeletal"
            paths={musculoskeletal}
            index={8}
            reducer={toHighlightsString2}
          />
          <MedicalPassportRow2
            label="10. Hematological"
            name="systems.hematological"
            paths={hematological}
            index={9}
            reducer={toHighlightsString2}
          />
          <MedicalPassportRow2
            label="11. Psychiatric"
            name="systems.psychiatric"
            paths={psychiatric}
            index={10}
            reducer={toHighlightsString2}
          />
          <MedicalPassportRow2
            label="12. Other"
            name="systems.other"
            paths={other}
            index={11}
            reducer={toHighlightsString2}
          />
          <MedicalPassportRow2
            label="13. OB/Gyn"
            name="systems.gyn"
            paths={ob}
            index={12}
            reducer={toHighlightsString2}
          />
        </div>
      </section>
      <Footer pageNumber={4} pageCount={10} />
    </div>
  );
};

export default Page5;
