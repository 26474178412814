import React from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';

import { TruncatedText } from '../../../se/components/typography';
import { withTheme } from '../../../se/theme';
import { isDefinedAndNotNull } from '../../../se/utilities/check';

const colorForLevel = ({ batteryLevel }, theme) => {
  if (batteryLevel <= 10) {
    return theme.dangerColor.string();
  } else if (batteryLevel <= 20) {
    return theme.warningColor.string();
  } else if (batteryLevel <= 30) {
    return theme.textColor.darken(0.2).string();
  } else {
    return theme.textColor.string();
  }
};

const IssueColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${withTheme((theme, props) => colorForLevel(props.data, theme))};
`;

const batteryLevel = level => (isDefinedAndNotNull(level) ? `${level} %` : 'Never Reported');

const columns = [
  {
    title: 'ID',
    lens: data => data,
    Component: ({ data }) => (
      <IssueColumn data={data}>
        <TruncatedText>{data.id}</TruncatedText>
      </IssueColumn>
    ),
  },
  {
    title: 'Battery Level',
    lens: data => data,
    Component: ({ data }) => (
      <IssueColumn data={data}>
        <TruncatedText>{batteryLevel(data.batteryLevel)}</TruncatedText>
      </IssueColumn>
    ),
  },
  {
    title: 'Updated At',
    lens: data => data,
    Component: ({ data }) => (
      <IssueColumn data={data}>
        <TruncatedText>{format(data.updatedAt, 'MM/DD/YYYY HH:mm:ss')}</TruncatedText>
      </IssueColumn>
    ),
  },
];

export default columns;
