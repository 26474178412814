import React from 'react';
import { Route, RouteComponentProps } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';
import TrackingHomepage from './homepage/TrackingHomepage';
import TrackingSession from './session/TrackingSession';
import TrackingSessionBeacon from './session/beacon/TrackingSessionBeacon';

interface TrackingProps extends RouteComponentProps {}

const Tracking = ({ match }: TrackingProps) => (
  <Switch>
    <Route exact path={match.path} render={props => <TrackingHomepage baseUrl={match.url} {...props} />} />
    <Route
      exact
      path={`${match.path}/:sessionId`}
      render={props => <TrackingSession baseUrl={match.url} {...props} />}
    />
    <Route
      exact
      path={`${match.path}/:sessionId/:beaconId`}
      render={props => <TrackingSessionBeacon baseUrl={match.url} {...props} />}
    />
    <Route path="*" render={() => <Redirect to={match.url} />} />
  </Switch>
);

export default Tracking;
