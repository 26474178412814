import color from 'color';

const colors = {
  primary: color('#00A7F7'),
  lightest: color('white'),
  lighter: color('white').mix(color('#E6ECEF')), // TODO Review
  light: color('#E6ECEF'),
  dark: color('#000C3F'),
  text: color('#000C3F'),
  sky: color('#00A7F7'),
  dusk: color('#1a2553'),
  orange: color('#ff8b00'),
};

export default colors;
