import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment IssueData on Issue {
      id
      localId
      type
      priority
      screenCode
      screenType {
        type
        room {
          id
          name
        }
      }
      gatewayId
      braceletId
      room {
        id
        name
      }
      createdAt
      resolvedAt
      isResolved
      organizationId
      organizationName
    }
  `,
};

export const list = gql`
  query issues($filter: Filter!) {
    issues(filter: $filter) {
      ...IssueData
    }
  }
  ${fragments.all}
`;

export const get = gql`
  query issue($issueId: Long!, $organizationId: Long!) {
    issue(issueId: $issueId, organizationId: $organizationId) {
      ...IssueData
    }
  }
  ${fragments.all}
`;

export const resolveIssue = gql`
  mutation resolveIssue($issueId: Long!, $organizationId: Long!) {
    resolveIssue(issueId: $issueId, organizationId: $organizationId)
  }
`;

export default {
  get,
  list,
  resolveIssue,
};
