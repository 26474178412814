import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import FormContext from '../FormContext';
import InputWrapper from '../InputWrapper';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Link,
  makeStyles,
  TextField,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import PhoneInput from './PhoneInput';
import IMask from 'imask';
import TranslatedMessage from '../TranslatedMessage';
import DefaultValueContext from '../DefaultValueContext';
import DistinguishTitle from '../DistinguishTitle';

// TODO: Move this to schema
const implicitMaxLength = question => {
  if (question.startsWith('Year')) {
    return 12;
  } else if (question.startsWith('Type')) {
    return 10;
  } else if (question.startsWith('Last')) {
    return 10;
  } else if (question.startsWith('What year did you quit?')) {
    return 5;
  } else if (question.startsWith('Location')) {
    return 10;
  } else if (question.startsWith('When was it placed')) {
    return 14;
  } else if (question.startsWith('When was your last seizure? MM/YY')) {
    return 6;
  } else if (question.startsWith('Body Part')) {
    return 20;
  } else if (question.startsWith('Usage')) {
    return 20;
  } else if (question.startsWith('Extremity')) {
    return 25;
  } else if (question.startsWith('Cigarettes per day')) {
    return 25;
  } else if (question.startsWith('When was your last menstrual period')) {
    return 25;
  } else if (question.startsWith('How Many')) {
    return 10;
  } else if (question.startsWith('Emergency Address')) {
    return 100;
  }
  return 34;
};

const LimitHelper = ({ limit, used }) => {
  if (used > 0) {
    const remaining = limit - used;
    return (
      <FormattedMessage
        id="questionnaire.text.limit.used"
        defaultMessage={`{remaining, plural,
          =1 {One character}
          other {# characters}} remaining`}
        values={{ remaining }}
      />
    );
  } else {
    return (
      <FormattedMessage
        id="questionnaire.text.limit.unused"
        defaultMessage={`Maximum length: {limit, plural,
          =1 {One character}
          other {# characters}}`}
        values={{ limit }}
      />
    );
  }
};

export const Translation = ({ text }) => {
  const [input, setInput] = useState(text);

  useEffect(() => {
    const timeout = setTimeout(() => setInput(text), 2000);
    return () => clearTimeout(timeout);
  }, [text]);

  const { data } = useQuery(
    gql`
      query translation($input: String!) {
        translation(input: $input) {
          source {
            text
            language
          }
          target {
            text
            language
          }
        }
      }
    `,
    {
      variables: {
        input,
      },
    }
  );

  const classes = useTranslationStyles();

  const sourceLanguage = get(data, 'translation.source.language');
  const targetText = get(data, 'translation.target.text');

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = e => {
    e.preventDefault();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  if (!sourceLanguage || sourceLanguage === 'en') {
    return null;
  }

  return (
    <>
      <span className={classes.root}>
        <Link href="." onClick={handleClickOpen}>
          <span>Translated:</span>
          <InfoOutlinedIcon fontSize="inherit" />
        </Link>{' '}
        <span>{targetText}</span>
      </span>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Translations Disclaimer</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Translations to English are provided by Amazon Translate and Amazon Comprehend services and are intended
            solely as a convenience. IT IS PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const useTranslationStyles = makeStyles({
  root: {
    marginTop: '0.5rem',
    fontSize: '0.75em',
  },
});

const SSNField = props => {
  const input = useRef();

  useEffect(() => {
    const mask = IMask(input.current, {
      mask: '000-00-0000',
    });
    return () => mask.destroy();
  }, []);

  return <TextField inputRef={input} placeholder="000-00-0000" {...props} />;
};

const UpdateProgress = ({ value, optional, text, onChange }) => {
  useEffect(() => {
    if ((value?.progress || 0) === 0 && !optional && (text?.length || 0) > 0) {
      onChange({ text: text, progress: 1 });
    }
  }, [value, onChange, text, optional]);

  return null;
};

const TextInput = ({
  label,
  limit,
  type,
  length,
  validators,
  distinguish,
  optional,
  section,
  explanation,
  ...props
}) => {
  const [helpVisible, setHelpVisible] = useState(false);
  const [error, setError] = useState(null);
  const actualLimit =
    (limit >= 0 ? limit : section !== 'demographicInformation' ? implicitMaxLength(label) : 0) || undefined;

  const rules = validators ? validators.map(type => validatorFn[type]?.(FieldType.Text) ?? (() => '')) : [];
  const required = !!validators?.find(e => e === ValidatorType.isRequired);

  const defaultValues = useContext(DefaultValueContext);

  return (
    <FormContext.Consumer>
      {({ name, value, onChange }) => {
        const text = value?.text || get(defaultValues, `sections.${name}.text`, '') || '';
        return (
          <>
            <UpdateProgress value={value} text={text} onChange={onChange} optional={optional} />
            <InputWrapper>
              {distinguish && <DistinguishTitle title={distinguish} />}
              {type === 'tel' ? (
                <PhoneInput
                  type="tel"
                  label={<FormattedMessage id={label} defaultMessage={`${label}${required ? ' *' : ''}`} />}
                  id={name}
                  name={name}
                  value={text}
                  onChange={text => onChange({ text, progress: get(value, 'progress', 0) })}
                  onBlur={() => {
                    onChange(set(cloneDeep(value || {}), 'progress', text.length > 0 && !optional ? 1 : 0));
                    setHelpVisible(false);
                  }}
                  onFocus={() => setHelpVisible(true)}
                  maxLength={actualLimit}
                  autoComplete="off"
                  error={!!error}
                  helperText={
                    <>
                      {!!error && <span>{error}</span>}
                      {explanation && <TranslatedMessage message={explanation} />}
                    </>
                  }
                  hasError={!!error}
                  {...props}
                />
              ) : type === 'ssn' ? (
                <SSNField
                  type="text"
                  variant="filled"
                  label={<FormattedMessage id={label} defaultMessage={`${label}${required ? ' *' : ''}`} />}
                  id={name}
                  name={name}
                  value={text}
                  onChange={e => onChange({ text: e.target.value, progress: get(value, 'progress', 0) })}
                  onBlur={() => {
                    onChange(set(cloneDeep(value || {}), 'progress', text.length > 0 && !optional ? 1 : 0));
                    setHelpVisible(false);
                  }}
                  onFocus={() => setHelpVisible(true)}
                  maxLength={actualLimit}
                  autoComplete="off"
                  error={!!error}
                  helperText={
                    <Fragment>
                      {!!error && <span>{error}</span>}
                      {helpVisible && actualLimit > 0 && (
                        <span>
                          <LimitHelper limit={actualLimit} used={text.length} />
                          <br />
                        </span>
                      )}
                      {explanation && <TranslatedMessage message={explanation} />}
                    </Fragment>
                  }
                  {...props}
                />
              ) : (
                <TextField
                  type="text"
                  variant="filled"
                  label={<FormattedMessage id={label} defaultMessage={`${label}${required ? ' *' : ''}`} />}
                  id={name}
                  name={name}
                  value={text}
                  onChange={e => onChange({ text: e.target.value, progress: get(value, 'progress', 0) })}
                  onBlur={() => {
                    onChange(set(cloneDeep(value || {}), 'progress', text.length > 0 && !optional ? 1 : 0));
                    setHelpVisible(false);
                  }}
                  onFocus={() => setHelpVisible(true)}
                  maxLength={actualLimit}
                  autoComplete="off"
                  inputProps={{
                    maxLength: actualLimit,
                  }}
                  error={!!error}
                  helperText={
                    <Fragment>
                      {!!error && (
                        <FormHelperText style={{ color: 'red' }}>
                          <TranslatedMessage message={error} />
                        </FormHelperText>
                      )}
                      {helpVisible && actualLimit > 0 && (
                        <span>
                          <LimitHelper limit={actualLimit} used={text.length} />
                          <br />
                        </span>
                      )}
                      {explanation && <TranslatedMessage message={explanation} />}
                    </Fragment>
                  }
                  {...props}
                />
              )}
              {/*{get(value, 'progress', 0) > 0 && '✅'}*/}
            </InputWrapper>
          </>
        );
      }}
    </FormContext.Consumer>
  );
};

TextInput.defaultValue = { text: '', progress: 0 };

export default TextInput;
