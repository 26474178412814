import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useSave } from '../../components/pages/kiosk/charting/SaveProvider';
import SwipeableViews from 'react-swipeable-views';
import { Box, createStyles, Tab, Tabs, Theme, useTheme, withStyles } from '@material-ui/core';
import SliderProvider from '../../components/pages/kiosk/charting/SliderProvider';
import useId from '../questions/util/useId';
import SlideProvider from '../../components/pages/kiosk/charting/SlideProvider';

interface SlidesProps {
  slides: [string, [string, number][]][];
  renderFields: (fieldNames: string[] | undefined, index: number, autofocus: boolean) => ReactNode;
  autofocus?: boolean;
}

interface TabPanelProps {
  id: string;
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, id, ...other } = props;

  return (
    <Box
      flex={1}
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${id}-${index}`}
      aria-labelledby={`action-tab-${id}-${index}`}
      {...other}
    >
      <Box px={5} py={3} display="flex" flexDirection="column" justifyContent="center">
        {children}
      </Box>
    </Box>
  );
}

const AntTabs = withStyles((theme: Theme) => ({
  root: {},
  indicator: {
    height: 8,
    backgroundColor: theme.palette.secondary.main,
    bottom: 'calc(50% - 4px)',
    borderRadius: 4,
  },
}))(Tabs);

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&$selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&:focus': {
        color: '#40a9ff',
      },
    },
    wrapper: {
      display: 'block',
      backgroundColor: theme.palette.divider,
      height: 8,
      width: '100%',
      borderRadius: 4,
      fontSize: 0,
    },
    selected: {},
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface StyledTabProps {
  label: string;
}

export const DefaultSlideContext = createContext<number>(0);

const Slides = ({ slides, renderFields, autofocus }: SlidesProps) => {
  const rootRef = useRef<HTMLDivElement | undefined>();
  const theme = useTheme();

  const [rootSize, setRootSize] = useState<{ width: number; height: number } | undefined>();

  useEffect(() => {
    const root = rootRef.current;

    if (!root) {
      return;
    }

    setRootSize({ width: root.clientWidth, height: root.clientHeight });

    const observer = new ResizeObserver(() => {
      setRootSize({ width: root.clientWidth, height: root.clientHeight });
    });

    observer.observe(root);

    return () => {
      observer.unobserve(root);
      observer.disconnect();
    };
  }, []);

  const { forceSave, save } = useSave();

  const [value, setValue] = useState<number>(0);

  const defaultSlide = useContext(DefaultSlideContext);

  useEffect(() => {
    setValue(defaultSlide);
  }, []);

  function a11yProps(id: string, index: any) {
    return {
      id: `action-tab-${id}-${index}`,
      'aria-controls': `action-tabpanel-${id}-${index}`,
    };
  }

  const handleChange = (event: unknown, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const id = useId();

  // @ts-ignore
  return (
    <>
      {slides?.length > 1 && (
        <AntTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="action tabs example"
          disableRipple
        >
          {slides.map((slide, i) => (
            <AntTab key={i} label={`Page ${i}`} {...a11yProps(id, i)} />
          ))}
        </AntTabs>
      )}

      <Box mx={-5} flex="1" display="flex" flexDirection="column">
        <SliderProvider id={id}>
          <SwipeableViews
            style={{ height: '100%' }}
            containerStyle={{ height: '100%' }}
            slideStyle={{ height: '100%', display: 'flex', justifyContent: 'center' }}
            enableMouseEvents
            index={value}
            onChangeIndex={handleChangeIndex}
            // ignoreNativeScroll
          >
            {slides.map(([key, fields], i) => (
              <SlideProvider key={i} slide={i}>
                <TabPanel value={value} index={i} id={id}>
                  {renderFields(
                    fields.map(f => f[0]),
                    i,
                    Boolean(autofocus) && i === 0
                  )}
                </TabPanel>
              </SlideProvider>
            ))}
          </SwipeableViews>
        </SliderProvider>
      </Box>
    </>
  );
};

export default Slides;
