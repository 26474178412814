import get from 'lodash/get';
import { smsStatusType } from './ProcedureEventLog';

export const smsInvitationCount = invitations =>
  (invitations || []).filter(_ => _.channel === 'SMS' && _.status === smsStatusType.delivered).length;
export const emailInvitationCount = invitations => (invitations || []).filter(_ => _.channel === 'Email').length;

export const getLogEvents = (event, status) => data => {
  const events = get(data, 'events', []).filter(e => e.type === event) || [];
  return status ? events.filter(e => e.status === status) : events;
};

export const getMultipleLogEvents = event => data => get(data, 'events', []).filter(e => event.includes(e.type)) || [];

export const getLogEvent = (event, status) => data => {
  if (status) {
    return get(data, 'events', []).find(e => e.type === event && e.status === status) || {};
  } else {
    return get(data, 'events', []).find(e => e.type === event) || {};
  }
};
