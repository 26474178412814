import { graphql } from '@apollo/client/react/hoc';
import React from 'react';
import { compose } from 'recompose';
import LinkButton from '../../../../se/components/LinkButton';

import { discharge, item } from '../../../../graph/patients';

const dischargeConfirmMessage = 'Are you sure you want to discharge this patient?';

const BaseDischargeLinkButton = ({ id, mutate }) => {
  const handleDischarge = async () => {
    if (!window.confirm(dischargeConfirmMessage)) {
      return undefined;
    }

    try {
      await mutate({ variables: { id } });
    } catch (error) {
      console.error('Discharge of a patient failed', error.message);
    }
  };

  return <LinkButton onClick={handleDischarge}>Discharge</LinkButton>;
};

export default compose(
  graphql(discharge, {
    options: ({ id }) => ({
      refetchQueries: [
        {
          query: item,
          variables: {
            id: parseInt(id, 10),
          },
        },
      ],
    }),
  })
)(BaseDischargeLinkButton);
