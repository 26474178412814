import styled, { css } from 'styled-components';
import React from 'react';
import responsive from '../../../se/utilities/responsive';
import { PhysicianCategory, PhysicianSpecialityColors } from '../../entities/physician/enums';
import { fullNameToDrLastName } from '../kiosk/schedule/shared/procedureUtils';
import Tooltip from '../../Tooltip';
import { defaultTo } from 'lodash';
import { roundValue } from '../Analytics';
import { utilizedColor } from './Mininytics';

export const CellBasis = styled.div`
  padding: 0.583em 0.5em 0.583em 0.75em;
  height: 4em;
  display: flex;
  align-items: center;
  position: relative;
  ${props =>
    props.grayed &&
    css`
      filter: brightness(80%);
    `}
`;

export const HeaderCell = styled(CellBasis)`
  background-color: rgba(255, 255, 255, 0.08);
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.35);
  flex-flow: column;

  padding: 0.583em 0.5em;
  height: 100%;
`;

const CellContainer = styled(CellBasis)`
  background-color: ${props => props.color || '#313a64'};
  position: absolute;
  left: calc(${props => props.start}%);
  right: calc(100% - ${props => props.end}%);
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
  :active {
    opacity: 0.8;
  }

  ${props =>
    props.highlighted &&
    css`
      background-color: #5a67a1;
    `}
  transition: all .2s ease;
  > span {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    align-items: center;
  }
`;

export const EmptyCell = styled(CellContainer)`
  background-color: transparent;
  :hover {
    background-color: #273a60 !important;
  }
  :active {
    background-color: #313a64 !important;
  }

  transition: all 0.2s ease;
`;

const BookedBy = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.4;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  z-index: 1;
  ${responsive.md.andSmaller`
    word-break: break-all;
    flex-direction: column;
    line-height: 1.4;

    > span {
      margin-top: .25em;
    }
  `}
`;

const SpecialtyIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0.25em;
  background-color: ${props => (props.color ? props.color : 'rgba(255,255,255,.1)')};
`;

const Utilization = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left 0;
  width: ${props => props.percent}%;
  background-color: ${utilizedColor};
  opacity: 0.4;
  z-index: 0;
`;

const colorForCategory = category => {
  if (category === PhysicianCategory.Endoscopy) {
    return '#FFAC00';
  } else if (category === PhysicianCategory.Eye) {
    return '#F55323';
  } else if (category === PhysicianCategory.Pain) {
    return '#50E3C2';
  } else if (category === PhysicianCategory.Surgical) {
    return '#7ED321';
  } else {
    return '#313a64';
  }
};

const colorForSpeciality = speciality => {
  if (speciality in PhysicianSpecialityColors) {
    return PhysicianSpecialityColors[speciality].color;
  }
  return PhysicianSpecialityColors.default.color;
};

export default ({
  physician,
  organization,
  category,
  speciality,
  onClick,
  grayed,
  highlighted,
  start,
  end,
  colorMode,
  utilization,
}) => {
  const tooltipContent = `
    <span style="display: flex; text-align: left">
      ${organization || (physician ? `Dr. ${physician}` : 'Open Block')}
      <br/>Speciality: ${defaultTo(speciality, '')}
      <br/>Category: ${defaultTo(category, '')}
      <br/>Utilization: ${roundValue(utilization)} %
    </span>
  `;
  return (
    <CellContainer
      onClick={onClick}
      grayed={grayed}
      highlighted={highlighted}
      start={start}
      end={end}
      color={colorMode ? colorForCategory(category) : undefined}
    >
      <Utilization percent={roundValue(utilization)} />
      <Tooltip delay={300} content={tooltipContent}>
        {!colorMode && (
          <BookedBy>
            <SpecialtyIndicator color={colorForSpeciality(speciality)} />
            <span>
              {organization ||
                (physician ? (end - start < 40 ? fullNameToDrLastName(physician) : physician) : 'Open Block')}
            </span>
          </BookedBy>
        )}
      </Tooltip>
    </CellContainer>
  );
};
