import React from 'react';
import { compose, withProps } from 'recompose';
import { withLabel } from '../../se/components/Label';
import { withDescription } from '../../se/components/Description';
import PhoneInput from '../../se/components/inputs/PhoneInput';
import CheckboxInput from './CheckboxInput';

const Checkbox = compose(
  withProps({
    label: 'Send link to Patient Status Page and notify patient’s caretaker automatically when the procedure is over.',
    name: 'notificationCheckbox',
  })
)(CheckboxInput);

export const CaretakerNotificationNumbersInput = compose(
  withLabel('Caretaker Phone Number'),
  withDescription('To add multiple phone numbers, separate them with a comma.'),
  withProps({ placeholder: 'Enter caretaker’s mobile phone number for notifications' })
)(PhoneInput);

export const PhysicianNotificationNumbersInput = compose(
  withLabel('Phone Number'),
  withDescription('To add multiple phone numbers, separate them with a comma.'),
  withProps({ placeholder: 'Enter mobile phone number for notifications' })
)(PhoneInput);

export const NotificationNumbersInput = compose(
  withLabel('Phone Number'),
  withDescription('This phone number will be used for schedule sharing.'),
  withProps({
    placeholder: 'Enter mobile phone number',
    autoComplete: 'off',
  })
)(PhoneInput);

export class OptionalNotificationNumbersInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showInput: !!props.value };
  }

  handleCheckboxChange = value => {
    this.setState({ showInput: value });
    if (value === false) {
      this.props.onChange('');
    }
  };

  render() {
    return (
      <React.Fragment>
        <Checkbox value={this.state.showInput} onChange={this.handleCheckboxChange} />
        {this.state.showInput && CaretakerNotificationNumbersInput(this.props)}
      </React.Fragment>
    );
  }
}
