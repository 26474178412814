import React, { FC, useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { useClickawayPresence } from '../../../../../contexts/ClickawayPresence';

type Option = { from?: string; to?: string; label: string };

interface TimeRangeAutocompleteProps {
  inputRef?: any;
  className?: string;
  defaultValue: string;
  placeholder?: string;
  onInputChange: (from: string | null, to: string | null, isValid: boolean, isCompleted: boolean) => Promise<void>;
  onSubmit: (from: string | null, to: string | null, isValid: boolean, isCompleted: boolean) => Promise<void>;
  onBlur?: () => void;
  disabled?: boolean;
  options?: Option[];
}

// const TimePattern = /^2[0-3]:[0-5]\d|[01]\d:[0-5]\d|[1-9]:[0-5]\d$/;
const TimeRangePattern =
  /^(2[0-3]:[0-5]\d|[01]\d:[0-5]\d|[1-9]:[0-5]\d)-(2[0-3]:[0-5]\d|[01]\d:[0-5]\d|[1-9]:[0-5]\d)$/;
const OnlyFromTimePattern = /^(2[0-3]:[0-5]\d|[01]\d:[0-5]\d|[1-9]:[0-5]\d)-{0,1}$/;

const addColon = (value: string, deletion: any) => (deletion ? value.substr(0, value.length - 1) : `${value}:`);
const addDash = (value: string, deletion: any) => (deletion ? value.substr(0, value.length - 1) : `${value}-`);

/* Time Range Format: MM:SS-MM:SS e.g. 07:00-11:00 */
const ACCEPTABLE_STATES = [
  /* match empty string */
  { pattern: /^$/ },
  /* match 20:XX, 21:XX, 22:XX, 23:XX */
  { pattern: /^2[0-3]$/, transform: addColon },
  { pattern: /^2[0-3]:$/ },
  { pattern: /^2[0-3]:[0-5]$/ },
  { pattern: /^2[0-3]:[0-5]\d$/, transform: addDash },
  { pattern: /^2[0-3]:[0-5]\d-$/ },
  { pattern: /^2[0-3]:[0-5]\d-[3-9]$/, transform: addColon },
  { pattern: /^2[0-3]:[0-5]\d-[1-2]$/ },
  { pattern: /^2[0-3]:[0-5]\d-[1-9]:$/ },
  { pattern: /^2[0-3]:[0-5]\d-[1-9]:[0-5]$/ },
  { pattern: /^2[0-3]:[0-5]\d-[1-9]:[0-5]\d$/ },
  { pattern: /^2[0-3]:[0-5]\d-[01]\d$/, transform: addColon },
  { pattern: /^2[0-3]:[0-5]\d-[01]\d:$/ },
  { pattern: /^2[0-3]:[0-5]\d-[01]\d:[0-5]$/ },
  { pattern: /^2[0-3]:[0-5]\d-[01]\d:[0-5]\d$/ },
  { pattern: /^2[0-3]:[0-5]\d-2[0-3]$/, transform: addColon },
  { pattern: /^2[0-3]:[0-5]\d-2[0-3]:$/ },
  { pattern: /^2[0-3]:[0-5]\d-2[0-3]:[0-5]$/ },
  { pattern: /^2[0-3]:[0-5]\d-2[0-3]:[0-5]\d$/ },
  { pattern: /^2[0-3]:[0-5]\d-\d$/ },
  // { pattern: /^2[0-3]:[0-5]\d-\d:$/ }, //
  // { pattern: /^2[0-3]:[0-5]\d-\d\d:$/ }, //
  /* match 0X:XX, 1X:XX */
  { pattern: /^[01]\d$/, transform: addColon },
  { pattern: /^[01]\d:$/ },
  { pattern: /^[01]\d:[0-5]$/ },
  { pattern: /^[01]\d:[0-5]\d$/, transform: addDash },
  { pattern: /^[01]\d:[0-5]\d-$/ },
  { pattern: /^[01]\d:[0-5]\d-[3-9]$/, transform: addColon },
  { pattern: /^[01]\d:[0-5]\d-[1-2]$/ },
  { pattern: /^[01]\d:[0-5]\d-[1-9]:$/ },
  { pattern: /^[01]\d:[0-5]\d-[1-9]:[0-5]$/ },
  { pattern: /^[01]\d:[0-5]\d-[1-9]:[0-5]\d$/ },
  { pattern: /^[01]\d:[0-5]\d-[01]\d$/, transform: addColon },
  { pattern: /^[01]\d:[0-5]\d-[01]\d:$/ },
  { pattern: /^[01]\d:[0-5]\d-[01]\d:[0-5]$/ },
  { pattern: /^[01]\d:[0-5]\d-[01]\d:[0-5]\d$/ },
  { pattern: /^[01]\d:[0-5]\d-2[0-3]$/, transform: addColon },
  { pattern: /^[01]\d:[0-5]\d-2[0-3]:$/ },
  { pattern: /^[01]\d:[0-5]\d-2[0-3]:[0-5]$/ },
  { pattern: /^[01]\d:[0-5]\d-2[0-3]:[0-5]\d$/ },
  { pattern: /^[01]\d:[0-5]\d-\d$/ },
  /* match 1:XX, 2:XX, 3:XX, ... 9:XX */
  { pattern: /^[3-9]$/, transform: addColon },
  { pattern: /^[1-2]$/ },
  { pattern: /^[1-9]:$/ },
  { pattern: /^[1-9]:[0-5]$/ },
  { pattern: /^[1-9]:[0-5]\d$/, transform: addDash },
  { pattern: /^[1-9]:[0-5]\d-$/ },
  { pattern: /^[1-9]:[0-5]\d-[3-9]$/, transform: addColon },
  { pattern: /^[1-9]:[0-5]\d-[1-2]$/ },
  { pattern: /^[1-9]:[0-5]\d-[1-9]:$/ },
  { pattern: /^[1-9]:[0-5]\d-[1-9]:[0-5]$/ },
  { pattern: /^[1-9]:[0-5]\d-[1-9]:[0-5]\d$/ },
  { pattern: /^[1-9]:[0-5]\d-[01]\d$/, transform: addColon },
  { pattern: /^[1-9]:[0-5]\d-[01]\d:$/ },
  { pattern: /^[1-9]:[0-5]\d-[01]\d:[0-5]$/ },
  { pattern: /^[1-9]:[0-5]\d-[01]\d:[0-5]\d$/ },
  { pattern: /^[1-9]:[0-5]\d-2[0-3]$/, transform: addColon },
  { pattern: /^[1-9]:[0-5]\d-2[0-3]:$/ },
  { pattern: /^[1-9]:[0-5]\d-2[0-3]:[0-5]$/ },
  { pattern: /^[1-9]:[0-5]\d-2[0-3]:[0-5]\d$/ },
  { pattern: /^[1-9]:[0-5]\d-\d$/ },
  /* match any number */
  { pattern: /^\d$/ },
];

const TimeRangeAutocomplete: FC<TimeRangeAutocompleteProps> = ({
  defaultValue,
  onInputChange,
  className,
  onBlur,
  onSubmit,
  options,
}) => {
  const [open, setOpen] = useState(true);
  const [input, setInput] = useState(defaultValue || '');
  const [option] = useState<Option | null>(null);

  const clickawayPresence = useClickawayPresence();

  useEffect(() => {
    if (open) {
      return clickawayPresence.register();
    }
  }, [open, clickawayPresence]);

  const save = async (value: string, enterOrSelect: boolean) => {
    const timeRangeMatch = value.match(TimeRangePattern);
    const onlyFromTimeMatch = value.match(OnlyFromTimePattern);
    const isValid = !!(timeRangeMatch && timeRangeMatch.length === 3) || !!onlyFromTimeMatch || value === '';
    const isCompleted = !!(timeRangeMatch && timeRangeMatch.length === 3);
    const [from, to] = isValid ? value.split('-') : [null, null];
    await onInputChange(from, to, isValid, isCompleted || enterOrSelect);
  };

  const onChange = async (value: string) => {
    const acceptableState = ACCEPTABLE_STATES.find(state => {
      const match = value.match(state.pattern);

      return match && match.length === 1 && match[0].length === value.length;
    });

    if (acceptableState) {
      setInput(
        acceptableState.transform ? acceptableState.transform(value, value.length < (input || '').length) : value
      );
    }

    await save(value, false);
  };

  const onKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;
    if (key === 'Enter') {
      await save(input, true);
      setInput('');
    }
  };

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options || []}
      getOptionLabel={option => option.label}
      value={option}
      onChange={async (event, newValue: Option | null) => {
        if (newValue) {
          const { from, to } = newValue;
          const timeRange = to ? `${from}-${to}` : from;
          timeRange && (await onChange(timeRange));
          timeRange && (await onSubmit(from || null, to || null, true, true));
          setInput('');
        }
      }}
      inputValue={input}
      onInputChange={async (event, value, reason) => {
        await onChange(value);
      }}
      renderInput={params => (
        <TextField
          {...params}
          label="Enter Time"
          variant="standard"
          onKeyPress={onKeyPress}
          className={className}
          onBlur={onBlur}
          autoFocus={true}
        />
      )}
    />
  );
};

export default TimeRangeAutocomplete;
