import { Box } from '@material-ui/core';
import React from 'react';
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
import { Label } from '../timeline/components/AnesthesiologistCardCompactHeader';
import { CommentRounded } from '@material-ui/icons';

interface CardSubheaderProps {
  patientInitials?: string;
  labelColor?: string | null;
  labelText: string;
  anesthesiologistName: string;
  anesthesiaType: string;
  hasAnesthesiologistNotes?: boolean;
}

const CardSubheader = ({
  patientInitials,
  labelColor,
  labelText,
  anesthesiologistName,
  anesthesiaType,
  hasAnesthesiologistNotes,
}: CardSubheaderProps) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box
      className={classes.root}
      style={{
        backgroundColor: alpha(labelColor || theme.palette.primary.main, 0.08),
      }}
    >
      <Box className={classes.patient}>
        {patientInitials}
        <Label backgroundColor={labelColor || 'primary'} text={labelText} />
      </Box>

      <Box className={classes.anesthesiologistWrapper}>
        <span className={classes.anesthesiologist}>{anesthesiologistName}</span>
        {/*<span className={classes.anesthesiaType}>{anesthesiaType}</span>*/}
        {hasAnesthesiologistNotes && (
          <Box color="warning.dark" lineHeight={0}>
            <CommentRounded fontSize="inherit" color="inherit" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '0.75em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '0.25em',
    paddingRight: '0.25em',
    gap: '0.25ch',
  },
  patient: {
    display: 'flex',
    gap: '0.5ch',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  anesthesiologistWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5ch',
    minWidth: 0,
  },
  anesthesiologist: {
    fontWeight: 'bold',
    overflow: 'hidden',
    position: 'relative',
  },
  anesthesiaType: {
    fontWeight: 'bold',
  },
}));

export default CardSubheader;
