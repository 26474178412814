import React, { useEffect, useMemo, useState } from 'react';
import { Box, createStyles, makeStyles, MenuItem, MenuList, Popover, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Spinner from '../../../../../se/components/Spinner';
import Anesthesiologist from '../../../../../types/Anesthesiologist';
import { useMutation, useQuery } from '@apollo/client';
import { list as listAnesthesiologists } from '../../../../../graph/anesthesiologists';
import { editProcedureAnesthesiologist } from '../../../../../graph/procedures';
import IconButton from '@material-ui/core/IconButton';
import { AddCircle } from '@material-ui/icons';

const AnesthesiologistOr = ({
  procedureId,
  editableAnesthesiologist,
}: {
  procedureId: number;
  editableAnesthesiologist?: boolean;
}) => {
  const classes = useStyles();

  const searchEl = React.useRef<HTMLInputElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (open) {
      searchEl.current?.focus();
    } else {
      setSearch('');
    }
  }, [open]);

  const [search, setSearch] = useState('');

  const handleSearchUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  const openEditor = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    editableAnesthesiologist && setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data } = useQuery(listAnesthesiologists);
  const anesthesiologists: Anesthesiologist[] = useMemo(() => data?.anesthesiologists || [], [data?.anesthesiologists]);

  const filteredAnesthesiologists = useMemo(() => {
    const chunks = search.toLowerCase().split(/\s+/);
    return (anesthesiologists || []).filter((a: Anesthesiologist) => {
      const name = a.name.toLowerCase();
      return chunks.every(chunk => name.indexOf(chunk) >= 0);
    });
  }, [search, anesthesiologists]);

  const [setAnesthesiologist] = useMutation(editProcedureAnesthesiologist);

  const selectAnesthesiologist = async (anesthesiologistId: number) => {
    setAnesthesiologist({
      variables: { id: procedureId, anesthesiologistId },
    });
  };

  return (
    <>
      <IconButton size="small" onClick={e => openEditor(e)}>
        <AddCircle color="secondary" />
      </IconButton>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClick={e => e.stopPropagation()}
        onClose={handleClose}
        getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {anesthesiologists ? (
          <Box className={classes.root}>
            <TextField
              size="small"
              variant="outlined"
              value={search}
              onChange={handleSearchUpdate}
              inputRef={searchEl}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" className={classes.searchIcon} />,
                autoCapitalize: 'off',
                autoComplete: 'off',
                autoCorrect: 'off',
                classes: {
                  root: classes.inputRoot,
                  inputAdornedStart: classes.inputAdornedStart,
                },
              }}
              classes={{
                root: classes.textFieldRoot,
              }}
            />
            <MenuList onClick={e => e.stopPropagation()} className={classes.menu}>
              {filteredAnesthesiologists.map((a: Anesthesiologist) => (
                <MenuItem key={a.id} onClick={() => selectAnesthesiologist(a.id)}>
                  {a.name}
                </MenuItem>
              ))}
            </MenuList>
          </Box>
        ) : (
          <div className={classes.spinnerContainer}>
            <Spinner />
          </div>
        )}
      </Popover>
    </>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      maxHeight: '38rem',
      overflow: 'hidden',
    },
    menu: {
      maxHeight: '28rem',
      overflowY: 'scroll',
    },
    textFieldRoot: {
      margin: '1rem',
      marginBottom: 0,
      padding: 0,
    },
    inputRoot: {
      width: '14rem',
      fontSize: '0.8125em',
      padding: '0.25em',
    },
    searchIcon: {
      marginLeft: '0.25em',
      marginRight: '0.25em',
    },
    inputAdornedStart: {
      paddingLeft: 0,
    },
    spinnerContainer: {
      padding: '1rem',
    },
    editLabel: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  })
);

export default AnesthesiologistOr;
