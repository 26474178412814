import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { withProps } from 'recompose';
import Page from '../../../../se/components/Page';
import Sidebar from '../../../../se/components/Sidebar';
import ChangePassword from '../ChangePassword';
import Header from '../admin/Header';
import Footer from '../admin/Footer';
import { dark as darkTheme } from '../../../../theme';
import { Context as PatientNameFormatContext } from '../../../../contexts/PatientNameFormat';
import { withSession } from '../../../../state/Session';
import get from 'lodash/fp/get';
import SensorPage from '../../sensor/SensorPage';
import Training from '../../training/Training';
import { muiThemeDark } from '../../../../muiTheme';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles';
import { MainContainer } from '../../MainContainer';
import { CssBaseline } from '@material-ui/core';
import { OmniSearchProvider, OmniSearchSources } from '../../../OmniSearch';
import activeAndPastProceduresSource from '../../../../omnisearch/sources/hospital/activeAndPastProcedures';
import scheduledProceduresSource from '../../../../omnisearch/sources/hospital/scheduledProcedures';
import { InvertColors, Theaters } from '@material-ui/icons';

const routes = [
  {
    path: `/sensors`,
    to: `/sensors`,
    label: 'Temp & Humidity',
    icon: <InvertColors />,
    component: SensorPage,
  },
  {
    path: `/training`,
    to: `/training`,
    label: 'Video Tutorials',
    icon: <Theaters />,
    component: Training,
  },
];

const FrontSidebar = withProps({
  Header,
  Footer,
  links: routes,
})(Sidebar);

const Homepage = ({ patientNameFormat }) => (
  <ThemeProvider theme={darkTheme}>
    <MUIThemeProvider theme={muiThemeDark}>
      <style>
        {`body {
          background: ${muiThemeDark.palette.background.default};
        }`}
      </style>
      <CssBaseline />
      <PatientNameFormatContext.Provider value={patientNameFormat}>
        <OmniSearchProvider>
          <OmniSearchSources sources={[activeAndPastProceduresSource, scheduledProceduresSource]} />
          <Page Sidebar={FrontSidebar}>
            <MainContainer switchControl>
              <Switch>
                {routes.map(({ path, component }, index) => (
                  <Route key={path || index} path={path} component={component} />
                ))}
                <Route path="/change-password" component={ChangePassword} />
                <Route render={() => <Redirect to="/sensors" />} />
              </Switch>
            </MainContainer>
          </Page>
        </OmniSearchProvider>
      </PatientNameFormatContext.Provider>
    </MUIThemeProvider>
  </ThemeProvider>
);

export default withSession(get('session.account.metadata'))(Homepage);
