import { useSubscription } from '@apollo/client';
import { useEffect } from 'react';
import type { ZodType, ZodTypeDef } from 'zod';

export default function useValidatedSubscription<TData, TVariables>(
  type: ZodType<TData, ZodTypeDef, TData>,
  ...args: Parameters<typeof useSubscription<TData, TVariables>>
): ReturnType<typeof useSubscription<TData, TVariables>> {
  const subscription = useSubscription<TData, TVariables>(...args);

  useEffect(() => {
    if (!subscription.data) {
      return;
    }

    const result = type.safeParse(subscription.data);

    if (!result.success) {
      console.warn('Unexpected data received', result.error);
    }
  }, [subscription.data]);

  return subscription;
}
