import { graphql } from '@apollo/client/react/hoc';
import React, { Fragment } from 'react';
import { compose, mapProps, withProps, withState } from 'recompose';
import update from 'lodash/fp/update';
import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';
import get from 'lodash/fp/get';
import has from 'lodash/fp/has';
import isObject from 'lodash/isObject';
import styled from 'styled-components';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import { LastHundredProcedureTypesSelectInput } from '../procedureType/ProcedureTypeSelectInput';
import PhysicianSelectInput from '../physician/PhysicianSelectInput';
import { CaretakerNotificationNumbersInput } from '../NotificationNumbersInput';
import BraceletIdInput from '../BraceletIdInput';
import { withPatientNameFormat } from '../../../contexts/PatientNameFormat';
import { Context as FormContext } from '../../../se/components/Form';
import CreatableSelect from '../../../se/components/inputs/CreatableSelect';
import { withLabel } from '../../../se/components/Label';
import { format } from 'date-fns';
import { dailyProcedures } from '../../../graph/procedures';
import TextAreaInput from '../../../se/components/inputs/TextAreaInput';
import CheckboxInput from '../CheckboxInput';
import { OptionalPhoneNumberInput } from '../PhoneNumberInput';
import PatientTypeButtonGroup from '../PatientTypeButtonGroup';
import { withToday } from '../../../util/today';
import { withScope } from '../../../contexts/ScopeContext';

const MutedText = styled.p`
  margin-bottom: 1rem;
  font-size: 0.8rem;
  font-weight: 500;
  opacity: 0.5;
`;

const hideIfNeeded = Component =>
  withPatientNameFormat(({ patientNameFormat, ...props }) => (
    <FormContext.Consumer>
      {context =>
        context && context.isEdit && patientNameFormat === 'PatientId' ? (
          <MutedText>Note: patient name is hidden and can not be changed.</MutedText>
        ) : (
          <Component {...props} />
        )
      }
    </FormContext.Consumer>
  ));

/**
 *
 * @param {boolean=} withBraceletId
 * @param {boolean=} autoDetectBraceletId
 * @return {React.Component}
 */
export default (withBraceletId, autoDetectBraceletId) => {
  const schema = {};
  if (withBraceletId) {
    schema.bracelet = compose(withProps({ autoDetectBraceletId, required: true }))(BraceletIdInput);
  }

  schema.type = PatientTypeButtonGroup;
  schema.name = compose(
    hideIfNeeded,
    withLabel('Name'),
    withToday,
    graphql(dailyProcedures, {
      options: ({ today }) => ({
        variables: { date: format(today, 'YYYY-MM-DD') },
        fetchPolicy: 'cache-and-network',
      }),
    }),
    mapProps(props => ({
      loading: props.data.loading,
      options: props.data.procedures
        ? props.data.procedures
            .filter(procedure => !procedure.patientId)
            .map(procedure => ({
              value: procedure.id,
              procedure,
            }))
        : undefined,
      formatOptionLabel: ({ label, procedure }) =>
        label || (
          <Fragment>
            {get('patientName')(procedure)}
            <span style={{ marginLeft: '0.5em', opacity: 0.5 }}>{get('startTimeText')(procedure)}</span>
          </Fragment>
        ),
      filterOption: ({ data, ...asd }, str) =>
        !has('procedure')(data) ||
        (get('procedure.patientName')(data) || '').toLowerCase().indexOf(str.toLowerCase()) >= 0,
      placeholder: 'e.g., Mark Nordstrom',
      ...props,
      value: isObject(props.value) ? props.value : props.value ? { label: props.value, value: props.value } : undefined,
    }))
  )(CreatableSelect);
  schema.physician = withProps({ required: true })(PhysicianSelectInput);
  schema.procedureType = LastHundredProcedureTypesSelectInput;
  schema.notificationNumbers = CaretakerNotificationNumbersInput;
  schema.caretakerConsent = withProps({
    label:
      'Discussed program and obtained verbal understanding and consent from caretaker. They will immediately receive a link to Patient Status Page and will be notified automatically when the procedure is over.',
    name: 'cartakerConsentCheckBox',
  })(CheckboxInput);
  schema.phoneNumber = OptionalPhoneNumberInput;
  schema.note = compose(withLabel('Note'), withProps({ rows: 1, placeholder: 'Enter note' }))(TextAreaInput);

  return compose(
    withProps({ schema }),
    withState('prevName', 'setPrevName'),
    withScope,
    mapProps(props =>
      flow(
        update('onChange', onChange => value => {
          const name = value.name;
          const braceletId = get('bracelet.braceletId', value);
          const consent = get('caretakerConsent', value);
          const note = get('note', value);

          if (name !== props.prevName) {
            props.setPrevName(name);

            if (name.procedure) {
              onChange(
                flow(
                  set('physician', name.procedure.physicianId),
                  set('procedureType', name.procedure.procedureTypeId),
                  set(
                    'braceletId',
                    get('scope.hospital.modules.automaticPatientTracking')(props) ? braceletId : 'manual'
                  ),
                  set(
                    'phoneNumber',
                    [name.procedure.patientHomePhone, name.procedure.patientCellPhone]
                      .filter(phone => !!phone)
                      .join(', ')
                  ),
                  set(
                    'notificationNumbers',
                    [name.procedure.caretakerCellPhone, name.procedure.familyCellPhone]
                      .filter(phone => !!phone)
                      .join(', ')
                  )
                )(value)
              );
            } else {
              onChange(
                set(
                  'braceletId',
                  get('scope.hospital.modules.automaticPatientTracking')(props) ? braceletId : 'manual'
                )(value)
              );
            }
          } else {
            onChange(
              flow(
                set(
                  'braceletId',
                  get('scope.hospital.modules.automaticPatientTracking')(props) ? braceletId : 'manual'
                ),
                set('caretakerConsent', consent),
                set('note', note)
              )(value)
            );
          }
        }),
        set(
          'schema.procedureType',
          get('scope.hospital.modules.automaticPatientTracking')(props)
            ? LastHundredProcedureTypesSelectInput
            : undefined
        )
      )(props)
    )
  )(ObjectInput);
};
