import React from 'react';
import styles from '../../styles';
import { useValue } from '../../ValueContext';
import MedicalPassportField from '../../components/MedicalPassportField';
import toAge from '../../../../../../questionnaire/toAge';
import toSex from '../../../../../../questionnaire/toSex';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import toStringWithoutQuestions from '../../../../../../questionnaire/toStringWithoutQuestions';
import getAgeAndBmi from './pageUtils';
import get from 'lodash/get';
import toHighlightsString from '../../../../../../questionnaire/toHighlightsString';
import { useQuery } from '@apollo/client';
import { FormType } from '../../../../../../../src/types/Form';
import { procedureForms } from '../../../../../../graph/surgeon/forms';
import MedicalPassportTextArea2 from '../../components/MedicalPassportTextArea2';
import toHighlightsString3 from '../../../../../../questionnaire/toHighlightsString3';
import Barcode from '../../../Barcode';

const Page2 = ({
  formName,
  pageNumber,
  showQRCode,
  hospitalName,
  value,
  defaultValue,
  lastPreOpCompletedEvent,
  ...props
}) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const { questionnaireAnswers } = useValue();
  const procedureId = props?.procedureId;
  const { data: queryData } = useQuery(procedureForms, { variables: { procedureId, formType: FormType.PreOpForm } });
  const forms = queryData?.procedureForms || [];
  const signedForms = forms.filter(form => form.shouldSign);
  const policyAcknowledgement = Object.keys(
    get(questionnaireAnswers, 'sections.documentElectronicSignature.value', '')
  );
  let policyFormList = [];

  for (let i = 0; i < signedForms.length; i++) {
    if (signedForms[i].isSigned) {
      policyFormList.push(
        'By signing below, I attest to reading and understanding the center’s ' + signedForms[i].name
      );
    }
  }

  for (let i = 0; i < policyAcknowledgement.length; i++) {
    policyFormList.push(
      'By signing below, I attest to reading and understanding the center’s ' + policyAcknowledgement[i]
    );
  }

  const adName = get(questionnaireAnswers, 'sections.demographicInformation.schema.advanceDirective.option[1].text');
  const { age } = getAgeAndBmi(defaultValue?.demographicInformation?.dob, defaultValue?.demographicInformation?.bmi);

  const rideEmgergencySame = get(
    questionnaireAnswers,
    'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.sameEmergency.yes[0]'
  );
  let riderName = '';
  let riderRelationship = '';
  let riderPhone = '';
  let riderOtherPhone = '';
  if (rideEmgergencySame == false) {
    riderName = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.sameEmergency.yes[1].schema.RH_name.text'
    );
    riderRelationship = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.sameEmergency.yes[1].schema.RH_relationship.option'
    );
    riderPhone = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.sameEmergency.yes[1].schema.RH_phone.text'
    );
    riderOtherPhone = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.sameEmergency.yes[1].schema.RH_otherphone.text'
    );
  } else {
    riderName = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.familyEmergency.yes[1].schema.FE_name.text'
    );
    riderRelationship = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.familyEmergency.yes[1].schema.FE_relationship.option'
    );
    riderPhone = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.familyEmergency.yes[1].schema.FE_MobilePhone.text'
    );
    riderOtherPhone = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.familyEmergency.yes[1].schema.FE_HomePhone.text'
    );
  }

  function ridehomeReducer1() {
    const rideHomeYesNo = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.RH_consenst1.yes[0]',
      null
    );
    if (rideHomeYesNo === true) {
      return true;
    }
    return false;
  }
  function ridehomeReducer2() {
    const rideHomeYesNo = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.RH_consenst1.yes[0]',
      null
    );
    if (rideHomeYesNo === false) {
      return true;
    }
    return false;
  }
  function ridehomeReducer3() {
    const rideHomeYesNo = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.RH_consenst1.yes[0]',
      null
    );
    if (rideHomeYesNo === null) {
      return true;
    }
    return false;
  }
  function financialReducer1() {
    const rideHomeYesNo = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.RH_consenst2.yes[0]',
      null
    );
    if (rideHomeYesNo === true) {
      return true;
    }
    return false;
  }
  function financialReducer2() {
    const rideHomeYesNo = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.RH_consenst2.yes[0]',
      null
    );
    if (rideHomeYesNo === false) {
      return true;
    }
    return false;
  }
  function financialReducer3() {
    const rideHomeYesNo = get(
      questionnaireAnswers,
      'sections.contactinformation.schema.ridehomeinformation.yes[1].schema.RH_consenst2.yes[0]',
      null
    );
    if (rideHomeYesNo === null) {
      return true;
    }
    return false;
  }

  return (
    <div id="page2" className="page" style={{ height: '100%', paddingBottom: '0' }}>
      <div style={{ minHeight: '69.8rem', paddingBottom: '0' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '50% 50%',
            gridGap: '1rem',
            alignContent: 'start',
            marginBottom: '0rem',
            paddingTop: '1rem',
            fontSize: '16px',
          }}
        >
          <div style={styles.div}>
            <b>
              <div style={{ fontSize: '12px', paddingBottom: '0', lineHeight: '14px' }}>
                {(hospitalName || '').toUpperCase()}
              </div>
              <div style={{ fontSize: '20px', paddingBottom: '0' }}>Demographic & Insurance Information</div>
            </b>
            <div style={{ fontSize: '12px' }}>
              <MedicalPassportTextArea2
                name="procedure.name"
                label="Procedure"
                style={{ display: 'flex' }}
                fontSize={12}
                nobackground
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <div>
              {showQRCode && (
                <Barcode
                  formName={formName}
                  pageNumber={pageNumber}
                  providerId={defaultValue?.procedure?.patient?.providerId}
                  serviceTime={defaultValue?.procedure?.serviceTime}
                />
              )}
            </div>
            <div
              style={{
                fontSize: '12px',
                border: '1px solid black',
                padding: '0.2rem',
                lineHeight: '15px',
              }}
            >
              <MedicalPassportField
                name="procedure.patient.name"
                label="Patient"
                fontWeight={'bold'}
                fontSize={14}
                nobackground
              />
              <MedicalPassportField name="procedure.patient.providerId" label="MRN#" fontSize={12} nobackground />
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '40% 60%',
                  alignContent: 'start',
                }}
              >
                <MedicalPassportField
                  name="dob"
                  label="DOB"
                  path={['demographicInformation', 'dob']}
                  fontSize={12}
                  nobackground
                />
                <MedicalPassportField
                  name="age"
                  label="Age"
                  path={['demographicInformation', 'dob']}
                  reducer={toAge}
                  warning={age > 70}
                  fontSize={12}
                  nobackground
                />
                <MedicalPassportField name="procedure.serviceTime" label="DOS" fontSize={12} nobackground />
                <MedicalPassportField
                  name="sex"
                  label="Sex"
                  path={['demographicInformation', 'sex']}
                  reducer={toSex}
                  fontSize={12}
                  nobackground
                />
              </div>
              <MedicalPassportField name="procedure.physician.name" label="Surgeon" fontSize={12} nobackground />
            </div>
          </div>
        </div>
        <br />
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '50% 50%',
            alignContent: 'start',
            marginTop: 5,
          }}
        >
          <strong
            style={{
              borderBottom: '2px solid black',
              marginBottom: '1px',
            }}
          >
            Personal Information
          </strong>
          <div
            style={{
              borderBottom: '2px solid black',
              marginBottom: '1px',
            }}
          />
        </div>
        <div style={styles.threeEqualColumns3}>
          <div
            style={{
              fontSize: '14px',
            }}
          >
            <MedicalPassportField name="procedure.patient.name" label="Patient" fontSize={14} nobackground />
            <MedicalPassportField
              name="dob"
              label="DOB"
              path={['demographicInformation', 'dob']}
              fontSize={14}
              nobackground
            />
            <MedicalPassportField
              name="sex"
              label="Sex"
              path={['demographicInformation', 'sex']}
              reducer={toSex}
              fontSize={14}
              nobackground
            />
            <MedicalPassportField
              name="address"
              label="Address"
              paths={[['demographicInformation', 'address']]}
              fontSize={14}
              nobackground
            />
            <MedicalPassportTextArea2
              name="additionalAddress"
              paths={[['demographicInformation', 'additinalAddress']]}
              fontSize={14}
              nobackground
            />
          </div>
          <div
            style={{
              fontSize: '14px',
            }}
          >
            <MedicalPassportField
              name="homePhone"
              label="Home Phone"
              path={['demographicInformation', 'Home Phone']}
              fontSize={14}
              nobackground
            />
            <MedicalPassportField
              name="mobilePhone"
              label="Mobile Phone"
              path={['demographicInformation', 'Mobile Phone']}
              fontSize={14}
              nobackground
            />
            <MedicalPassportField
              name="email"
              label="Email"
              path={['demographicInformation', 'email']}
              fontSize={14}
              nobackground
            />
            <div></div>
          </div>
          <div
            style={{
              fontSize: '14px',
            }}
          >
            <MedicalPassportField
              name="race"
              label="Race"
              path={['demographicInformation', 'race']}
              fontSize={14}
              reducer={toHighlightsString}
              nobackground
            />
            <MedicalPassportTextArea2
              name="advanceDirective"
              label="Advance Directive"
              fontSize={14}
              path={['demographicInformation', 'advanceDirective']}
              reducer={toStringWithoutQuestions}
              nobackground
            />
            <MedicalPassportField
              name="primaryLanguage"
              label="Primary Language"
              paths={[['demographicInformation', 'primary language']]}
              reducer={toHighlightsString3}
              fontSize={14}
              nobackground
            />
            <MedicalPassportTextArea2
              name="interpreter"
              paths={[['demographicInformation', 'interpreter']]}
              reducer={toHighlightsString3}
              fontSize={14}
              nobackground
            />
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '30% 70%' }}>
          <MedicalPassportCheckbox
            name="facility"
            path={['GeneralHistory', 'nursingFacility']}
            label="Live in Skilled Nursing Facility"
          />
          <MedicalPassportTextArea2
            name="facilitInfo"
            paths={[['GeneralHistory', 'nursingFacility', 'yes']]}
            reducer={toHighlightsString3}
            fontSize={14}
            nobackground
          />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '50% 50%',
            alignContent: 'start',
            marginTop: 5,
          }}
        >
          <strong
            style={{
              borderBottom: '2px solid black',
              marginBottom: '1px',
            }}
          >
            Advance Directive Information
          </strong>
          <div
            style={{
              borderBottom: '2px solid black',
              marginBottom: '1px',
            }}
          />
        </div>
        <div style={styles.threeEqualColumns3}>
          <div
            style={{
              fontSize: '14px',
            }}
          >
            <MedicalPassportField name="adName" label="Name" fontSize={14} nobackground value={adName} />
          </div>
        </div>
        <div style={{ ...styles.threeEqualColumns3, fontSize: '14px', marginTop: '10px' }}>
          FAMILY EMERGENCY CONTACT:
        </div>
        <div style={styles.threeEqualColumns3}>
          <div
            style={{
              fontSize: '14px',
            }}
          >
            <MedicalPassportField
              name="famEmergencyName"
              label="Name"
              path={['contactinformation', 'familyEmergency', 'yes', 'FE_name']}
              fontSize={14}
              nobackground
            />
            <MedicalPassportField
              name="famRelationship"
              label="Relationship"
              fontSize={14}
              nobackground
              path={['contactinformation', 'familyEmergency', 'yes', 'FE_relationship']}
              reducer={toHighlightsString}
            />
          </div>
          <div
            style={{
              fontSize: '14px',
            }}
          >
            <MedicalPassportField
              name="famEmergencyHome"
              label="Other Phone"
              fontSize={14}
              nobackground
              path={['contactinformation', 'familyEmergency', 'yes', 'FE_HomePhone']}
            />
            <MedicalPassportField
              name="famEmergencyMobile"
              label="Mobile Phone"
              path={['contactinformation', 'familyEmergency', 'yes', 'FE_MobilePhone']}
              fontSize={14}
              nobackground
            />
          </div>
          <div
            style={{
              fontSize: '14px',
              marginBottom: 10,
            }}
          ></div>
        </div>
        <br />
        <div style={{ ...styles.threeEqualColumns3, fontSize: '14px' }}>OTHER EMERGENCY CONTACT:</div>
        <div style={styles.threeEqualColumns3}>
          <div
            style={{
              fontSize: '14px',
            }}
          >
            <MedicalPassportField
              name="otherEmergencyName"
              label="Name"
              fontSize={14}
              path={['contactinformation', 'otherEmergency', 'yes', 'OE_name']}
              nobackground
            />
            <MedicalPassportField
              name="otherRelationship"
              label="Relationship"
              fontSize={14}
              path={['contactinformation', 'otherEmergency', 'yes', 'OE_relationship']}
              nobackground
            />
          </div>
          <div
            style={{
              fontSize: '14px',
            }}
          >
            <MedicalPassportField
              name="otherEmergencyHome"
              label="Other Phone"
              fontSize={14}
              nobackground
              path={['contactinformation', 'otherEmergency', 'yes', 'OE_HomePhone']}
            />
            <MedicalPassportField
              name="otherEmergencyMobile"
              label="Mobile Phone"
              fontSize={14}
              nobackground
              path={['contactinformation', 'otherEmergency', 'yes', 'OE_MobilePhone']}
            />
          </div>
          <div
            style={{
              fontSize: '14px',
            }}
          ></div>
        </div>

        <div style={styles.twoEqualColumnsNoBorder}>
          <div
            style={{
              display: 'grid',
              alignContent: 'start',
              marginTop: 10,
            }}
          >
            <strong>PRIMARY HEALTHCARE INSURANCE</strong>
            <div
              style={{
                borderBottom: '2px solid black',
                marginBottom: '1px',
              }}
            />
            <div
              style={{
                fontSize: '14px',
              }}
            >
              <MedicalPassportField
                name="phiCompany"
                label="Company"
                path={['InsuranceInformation', 'PrimaryInsurance', 'yes', 'PI_company']}
                nobackground
                fontSize={14}
              />

              <MedicalPassportField
                name="phiPhone"
                label="Phone"
                path={['InsuranceInformation', 'PrimaryInsurance', 'yes', 'PI_phone']}
                nobackground
                fontSize={14}
              />
              <MedicalPassportField
                name="phiAddress"
                label="Address"
                paths={[
                  ['InsuranceInformation', 'PrimaryInsurance', 'yes', 'PI_address'],
                  ['InsuranceInformation', 'PrimaryInsurance', 'yes', 'PI_additinalAddress'],
                ]}
                nobackground
                fontSize={14}
              />
              <br></br>
              <MedicalPassportField
                name="phiPolicyNumber"
                label="Policy #"
                path={['InsuranceInformation', 'PrimaryInsurance', 'yes', 'PI_policy']}
                nobackground
                fontSize={14}
              />
              <MedicalPassportField
                name="phiGroupNumber"
                label="Group #"
                path={['InsuranceInformation', 'PrimaryInsurance', 'yes', 'PI_group']}
                nobackground
                fontSize={14}
              />
              <MedicalPassportField
                name="phiPolicyHolder"
                label="Policy Holder Name"
                path={['InsuranceInformation', 'PrimaryInsurance', 'yes', 'PI_name']}
                nobackground
                fontSize={14}
              />
              <MedicalPassportField
                name="phiHolderRelationship"
                label="Policy Holder Relationship"
                path={['InsuranceInformation', 'PrimaryInsurance', 'yes', 'PI_relationship']}
                nobackground
                fontSize={14}
              />
              <MedicalPassportField
                name="phiHolderAddress"
                label="Policy Holder Address"
                paths={[
                  ['InsuranceInformation', 'PrimaryInsurance', 'yes', 'PI_PHaddress'],
                  ['InsuranceInformation', 'PrimaryInsurance', 'yes', 'PI_PHadditinalAddress'],
                ]}
                nobackground
                fontSize={14}
              />
              <br></br>
              <MedicalPassportField
                name="phiHolderHomePhone"
                label="Policy Holder Home Phone"
                path={['InsuranceInformation', 'PrimaryInsurance', 'yes', 'PI_PHphone']}
                nobackground
                fontSize={14}
              />
              <MedicalPassportField
                name="phiEmployer"
                label="Employer"
                path={['InsuranceInformation', 'PrimaryInsurance', 'yes', 'PI_employer']}
                nobackground
                fontSize={14}
              />
              <MedicalPassportField
                name="phiEmployerPhone"
                label="Employer Phone"
                nobackground
                path={['InsuranceInformation', 'PrimaryInsurance', 'yes', 'PI_employerPhone']}
                fontSize={14}
              />
              <MedicalPassportField
                name="phiEmployerAddress"
                label="Employer Address"
                paths={[
                  ['InsuranceInformation', 'PrimaryInsurance', 'yes', 'PI_employerAddress'],
                  ['InsuranceInformation', 'PrimaryInsurance', 'yes', 'PI_employerAdditinalAddress'],
                ]}
                nobackground
                fontSize={14}
              />
            </div>
          </div>

          <div
            style={{
              display: 'grid',
              alignContent: 'start',
              marginTop: 10,
            }}
          >
            <b>SECONDARY HEALTHCARE INSURANCE</b>
            <div
              style={{
                borderBottom: '2px solid black',
                marginBottom: '1px',
              }}
            />
            <div
              style={{
                fontSize: '14px',
              }}
            >
              <MedicalPassportField
                name="shiCompany"
                label="Company"
                path={['InsuranceInformation', 'secondaryInsurance', 'yes', 'SI_company']}
                nobackground
                fontSize={14}
              />
              <MedicalPassportField
                name="shiPhone"
                label="Phone"
                path={['InsuranceInformation', 'secondaryInsurance', 'yes', 'SI_phone']}
                nobackground
                fontSize={14}
              />
              <MedicalPassportField
                name="shiAddress"
                label="Address"
                nobackground
                paths={[
                  ['InsuranceInformation', 'secondaryInsurance', 'yes', 'SI_address'],
                  ['InsuranceInformation', 'secondaryInsurance', 'yes', 'SI_additinalAddress'],
                ]}
                fontSize={14}
              />
              <br></br>
              <MedicalPassportField
                name="shiPolicyNumber"
                label="Policy #"
                nobackground
                path={['InsuranceInformation', 'secondaryInsurance', 'yes', 'SI_policy']}
                fontSize={14}
              />
              <MedicalPassportField
                name="shiGroupNumber"
                label="Group #"
                path={['InsuranceInformation', 'secondaryInsurance', 'yes', 'SI_group']}
                nobackground
                fontSize={14}
              />
              <MedicalPassportField
                name="shiPolicyHolder"
                label="Policy Holder Name"
                path={['InsuranceInformation', 'secondaryInsurance', 'yes', 'SI_name']}
                nobackground
                fontSize={14}
              />
              <MedicalPassportField
                name="shiHolderRelationship"
                label="Policy Holder Relationship"
                path={['InsuranceInformation', 'secondaryInsurance', 'yes', 'SI_relationship']}
                nobackground
                fontSize={14}
              />
              <MedicalPassportField
                name="shiHolderAddress"
                label="Policy Holder Address"
                paths={[
                  ['InsuranceInformation', 'secondaryInsurance', 'yes', 'SI_PHaddress'],
                  ['InsuranceInformation', 'secondaryInsurance', 'yes', 'SI_PHadditinalAddress'],
                ]}
                nobackground
                fontSize={14}
              />
              <br></br>
              <MedicalPassportField
                name="shiHolderHomePhone"
                label="Policy Holder Home Phone"
                path={['InsuranceInformation', 'secondaryInsurance', 'yes', 'SI_PHphone']}
                nobackground
                fontSize={14}
              />
              <MedicalPassportField
                name="shiEmployer"
                label="Employer"
                path={['InsuranceInformation', 'secondaryInsurance', 'yes', 'SI_employer']}
                nobackground
                fontSize={14}
              />
              <MedicalPassportField
                name="shiEmployerPhone"
                label="Employer Phone"
                path={['InsuranceInformation', 'secondaryInsurance', 'yes', 'SI_employerPhone']}
                nobackground
                fontSize={14}
              />
              <MedicalPassportField
                name="shiEmployerAddress"
                label="Employer Address"
                paths={[
                  ['InsuranceInformation', 'secondaryInsurance', 'yes', 'SI_employerAddress'],
                  ['InsuranceInformation', 'secondaryInsurance', 'yes', 'SI_employerAdditinalAddress'],
                ]}
                nobackground
                fontSize={14}
              />
            </div>
          </div>
        </div>

        <div style={styles.twoEqualColumnsNoBorder}>
          <div
            style={{
              display: 'grid',
              alignContent: 'start',
              marginTop: 5,
            }}
          >
            <b>PRIMARY CARE PHYSICIAN</b>
            <div
              style={{
                borderBottom: '2px solid black',
                marginBottom: '1px',
              }}
            />
            <div
              style={{
                fontSize: '14px',
              }}
            >
              <MedicalPassportField
                name="pcpName"
                label="Name"
                path={['demographicInformation', 'physician2', 'yes', 'primaryCareDoctor']}
                nobackground
                fontSize={14}
              />
              <MedicalPassportField
                name="pcpPhone"
                label="Phone"
                path={['demographicInformation', 'physician2', 'yes', 'primaryCareDoctorPhone']}
                nobackground
                fontSize={14}
              />
            </div>
          </div>

          <div
            style={{
              display: 'grid',
              alignContent: 'start',
              marginTop: 5,
            }}
          >
            <b>SPECIALIST</b>
            <div
              style={{
                borderBottom: '2px solid black',
                marginBottom: '1px',
              }}
            />
            <div
              style={{
                fontSize: '14px',
              }}
            >
              <MedicalPassportField
                name="specialistName"
                label="Name"
                paths={[
                  ['demographicInformation', 'specialist', 'yes', '0', 'specialist_name'],
                  ['demographicInformation', 'specialist', 'yes', '0', 'specialist_specialty'],
                ]}
                fontSize={14}
                nobackground
              />
              <MedicalPassportField
                name="specialistPhone"
                label="Phone"
                path={['demographicInformation', 'specialist', 'yes', '0', 'specialist_phone']}
                fontSize={14}
                nobackground
              />
            </div>
          </div>
        </div>
        <div
          style={{
            fontSize: '14px',
          }}
        >
          <div>
            <MedicalPassportCheckbox
              name="page2.contactphysician"
              label="May contact physician"
              path={['demographicInformation', 'physician1']}
            />
          </div>
        </div>
        <div style={styles.twoEqualColumnsNoBorder}>
          <div
            style={{
              display: 'grid',
              alignContent: 'start',
              marginTop: 10,
            }}
          >
            <b>RIDE HOME INFORMATION</b>
            <div
              style={{
                borderBottom: '2px solid black',
                paddingBottom: '1px',
              }}
            />
            <div
              style={{
                fontSize: '14px',
              }}
            >
              <MedicalPassportField name="rideHomeName" label="Name" fontSize={14} value={riderName} nobackground />
              <MedicalPassportField
                name="rideHomeRelationship"
                label="Relationship"
                fontSize={14}
                value={riderRelationship}
                reducer={toHighlightsString}
                nobackground
              />
              <MedicalPassportField name="rideHomePhone" label="Phone" fontSize={14} value={riderPhone} nobackground />
              <MedicalPassportField
                name="rideotherPhone"
                label="Other Phone"
                fontSize={14}
                value={riderOtherPhone}
                nobackground
              />
            </div>
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '82% 6% 6% 6%', borderBottom: 0, fontSize: '.75em' }}>
          <div
            style={{
              fontSize: '.75rem',
            }}
          >
            <MedicalPassportField
              name="shareMedical"
              label="Okay to share medical info with: (Scheduling, Treatment, Discharge, etc.) with Ride Home or Other"
              nobackground
              path={['contactinformation', 'ridehomeinformation', 'yes', 'RH_consenst1', 'yes']}
            />
          </div>
          <div>
            <input type="checkbox" name="shareMedicalYes" label="Yes" checked={ridehomeReducer1()} />
            Yes
          </div>
          <div>
            <input type="checkbox" name="shareMedicalNo" label="No" checked={ridehomeReducer2()} />
            No
          </div>
          <div>
            <input type="checkbox" name="shareMedicalNA" label="N/A" checked={ridehomeReducer3()} />
            N/A
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '82% 6% 6% 6%', borderBottom: 0, fontSize: '.75em' }}>
          <div
            style={{
              fontSize: '.75rem',
            }}
          >
            <MedicalPassportField
              name="shareFinancial"
              label="Okay to share financial info with: (Billing, payments, insurance, etc.) with Ride Home or Other"
              nobackground
              path={['contactinformation', 'ridehomeinformation', 'yes', 'RH_consenst2', 'yes']}
            />
          </div>
          <div>
            <input type="checkbox" name="shareFinancialYes" label="Yes" checked={financialReducer1()} />
            Yes
          </div>
          <div>
            <input type="checkbox" name="shareFinancialNo" label="No" checked={financialReducer2()} />
            No
          </div>
          <div>
            <input type="checkbox" name="shareFinancialNA" label="N/A" checked={financialReducer3()} />
            N/A
          </div>
        </div>
        <div style={{ ...styles.darkGreyBackground, fontSize: '14px', marginTop: '10px' }}>
          PATIENT POLICIES ACKNOWLEDGEMENT
        </div>
        <div style={{ fontSize: '14px' }}>
          {policyFormList[0]}
          <br></br>
          {policyFormList[1]}
          <br></br>
          {policyFormList[2]}
          <br></br>
          {policyFormList[3]}
          <br></br>
          {policyFormList[4]}
        </div>
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <div style={styles.signatureBox1}>
            <div>
              <div style={{ marginTop: '1.5rem', marginRight: '1rem' }}>
                _____________________________________________________________
              </div>
              <div style={{ textAlign: 'left', fontSize: '.75rem' }}>Patient Signature</div>
            </div>
          </div>
          <div style={styles.signatureBox1}>
            <div>
              <div style={{ marginTop: '1.5rem' }}>____________________________</div>
              <div style={{ textAlign: 'left', fontSize: '.75rem' }}>Time/Date</div>
            </div>
          </div>
        </div>
      </div>
      <footer
        style={{
          display: 'grid',
          gridTemplateColumns: '13.25% 14.5% 11.5% 17% 0.05% 14.5% 29%',
          fontSize: '11px',
          height: '20px',
        }}
      >
        <div style={{ paddingTop: '3px' }}>Provided by Patient{'('}</div>
        <MedicalPassportField name="questionnaire.firstSubmittedAt" fontSize={11} nobackground />
        <div style={{ paddingTop: '3px' }}>{')'} Last updated by</div>
        <MedicalPassportField name="procedure.patient.name" fontSize={11} nobackground />
        <div style={{ paddingTop: '3px' }}>{'('}</div>
        <MedicalPassportField name="questionnaire.submittedAt" fontSize={11} nobackground />
        <div>
          {')'}{' '}
          <span style={{ fontSize: '14px', marginLeft: '9rem' }}>
            <b>Ospitek, Inc</b>
          </span>
        </div>
      </footer>
    </div>
  );
};

export default Page2;
