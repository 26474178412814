import React, { FC } from 'react';
import { RouteComponentProps, RouteProps } from 'react-router';
import { matchPath } from 'react-router-dom';
import { alpha, Box, Tab, Tabs, Theme, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { ToggleButtonGroup } from '@material-ui/lab';
import { green, pink } from '@material-ui/core/colors';
import groupBy from '../../../../util/groupBy';
import { makeStyles } from '@material-ui/core/styles';

import { useChartingSession } from './modules/hooks';

export interface Tab extends RouteProps {
  title: string;
  path?: string;
}

interface TabRoutesProps extends RouteComponentProps<{ id?: string }> {
  tabs: Tab[];
}
withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}))(ToggleButtonGroup);

const useStyles = makeStyles((theme: Theme) => ({
  anesthesiologist: () => ({
    height: 4,
    backgroundColor: pink[600],
  }),
  physician: () => ({
    height: 4,
    backgroundColor: green[600],
  }),
  staff: () => ({
    height: 4,
    backgroundColor: theme.palette.primary.main,
  }),
}));

const CustomTab = withStyles((theme: Theme) => ({
  root: (props: { group: string; role: string | undefined }) => ({
    ...theme.typography.button,
    backgroundColor: theme.palette.grey[300],
    borderBottom: `4px solid ${
      props.group
        ? props.group === 'Anesthesiologist'
          ? pink[200]
          : props.group === 'Physician'
          ? green[200]
          : alpha(theme.palette.primary.main, 0.5)
        : props.role === 'anesthesiologist'
        ? pink[200]
        : props.role === 'physician'
        ? green[200]
        : alpha(theme.palette.primary.main, 0.5)
    }`,
    minWidth: 100,
  }),
}))(Tab);

const CustomTabs = ({ tabs, groupName, currentValue, handleChange }) => {
  const classes = useStyles();
  const session = useChartingSession();

  return groupName ? (
    <Box>
      <Typography variant="subtitle2" gutterBottom>
        {groupName + ' Charting'}
      </Typography>
      <Tabs
        value={currentValue}
        onChange={handleChange}
        aria-label="charting tabs"
        classes={{
          indicator: groupName
            ? groupName === 'Anesthesiologist'
              ? classes.anesthesiologist
              : groupName === 'Physician'
              ? classes.physician
              : classes.staff
            : session?.role === 'anesthesiologist'
            ? classes.anesthesiologist
            : session?.role === 'physician'
            ? classes.physician
            : classes.staff,
        }}
      >
        {tabs.map(tab => {
          return <CustomTab key={tab.path} label={tab.title} value={tab.path} group={groupName} role={session?.role} />;
        })}
      </Tabs>
    </Box>
  ) : (
    <Box>
      <Tabs
        value={currentValue}
        onChange={handleChange}
        aria-label="charting tabs"
        classes={{
          indicator: groupName
            ? groupName === 'Anesthesiologist'
              ? classes.anesthesiologist
              : groupName === 'Physician'
              ? classes.physician
              : classes.staff
            : session?.role === 'anesthesiologist'
            ? classes.anesthesiologist
            : session?.role === 'physician'
            ? classes.physician
            : classes.staff,
        }}
      >
        {tabs.map(tab => {
          return <CustomTab key={tab.path} label={tab.title} value={tab.path} group={groupName} role={session?.role} />;
        })}
      </Tabs>
    </Box>
  );
};

const GroupedTabNavigation: FC<TabRoutesProps> = ({ history, location, match, tabs }) => {
  const value = tabs.find(tab =>
    matchPath(location.pathname, {
      ...tab,
      path: `${match.url}${tab.path}`,
    })
  )?.path;

  const groups = Object.entries(groupBy(tabs, item => item?.group || ''));
  const handleChange = (event: any, newValue: any) => {
    history.replace(`${match.url}${newValue ?? tabs?.[0]?.path}${window.location.search}`.replaceAll(value || '', ''));
  };

  return (
    <Box style={{ display: 'flex', gap: 16, justifyContent: 'flex-end' }}>
      {groups.map(group => (
        <CustomTabs currentValue={value} tabs={group?.[1]} groupName={group?.[0]} handleChange={handleChange} />
      ))}
    </Box>
  );
};

export default GroupedTabNavigation;
