import React, { FC, useMemo } from 'react';
import Timeline from '../../kiosk/schedule/timeline/Timeline';
import { getProcedures, transformScheduleOperationRooms } from '../../kiosk/schedule/transform';
import { useSubscription } from '@apollo/client';
import { format } from 'date-fns';
import { listWithStaffShiftSubscription as listProceduresSubscription } from '../../../../graph/procedures';
import get from 'lodash/get';
import { Room } from '../../../../types/Room';
import { Time } from '../../../entities/schedule/util/time';

const ProceduresPreview: FC<{
  date: Date;
  room: Room | undefined;
  showOverlay: boolean;
  editMode: boolean;
  startTime: Time;
  duration?: number | null;
  setStartTime?: (val: Time) => void;
  setDuration?: (val: number | null | undefined) => void;
  showGraphs?: boolean;
}> = ({ date, room, showOverlay, editMode, startTime, duration, setStartTime, setDuration, showGraphs }) => {
  const proceduresQuery = useSubscription(listProceduresSubscription, {
    variables: {
      date: format(date, 'YYYY-MM-DD'),
    },
  });

  const operationRooms = useMemo(() => {
    const procedures = getProcedures(proceduresQuery).filter(procedure => procedure.orId === get(room, 'id'));

    return transformScheduleOperationRooms({ rooms: [room], procedures } as any);
  }, [room, proceduresQuery]);

  return (
    <Timeline
      date={date}
      operationRooms={operationRooms}
      showOverlay={showOverlay}
      editMode={editMode}
      startTime={startTime}
      duration={duration}
      setStartTime={setStartTime}
      setDuration={setDuration}
      showGraphs={showGraphs}
    />
  );
};

export default ProceduresPreview;
