import React, { Dispatch, FC, SetStateAction } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const AlertDialog: FC<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
  title: string;
  subtitle: string;
}> = ({ open, setOpen, onConfirm, title, subtitle }) => (
  <Dialog open={open} onClose={() => setOpen(false)}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{subtitle}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setOpen(false)} color="primary">
        No
      </Button>
      <Button
        onClick={() => {
          onConfirm();
          setOpen(false);
        }}
        color="primary"
        autoFocus
      >
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

export default AlertDialog;
