import StaffMember, { OtherSpecialty, PrimarySpecialty, Title } from './StaffMember';
import { Procedure, ShiftState } from './Procedure';
import { Room } from './Room';

export default interface StaffShift {
  id: number;
  shiftState: ShiftState;
  staff?: StaffMember;
  date?: string;
  from?: string;
  to?: string;
  additionalText?: string;
  note?: string;
  openPosition?: OpenPositionDescription;
  hourlyRate?: number;
}

export default interface StaffShiftRoom {
  id: number;
  shiftState: ShiftState;
  staff?: StaffMember;
  coworkersShifts?: StaffShift[];
  procedures?: Procedure[];
  room?: Room;
  date?: string;
  from?: string;
  to?: string;
  note?: string;
}

export interface OpenPositionDescription {
  primarySpecialty?: PrimarySpecialty | null;
  otherSpecialties?: OtherSpecialty[] | null;
  title?: Title | null;
  description?: string | null;
  isProcedure?: boolean;
  isRoom?: boolean;
}

export enum StaffShiftState {
  CreatedOpenPosition = 'CREATED_OPEN_POSITION',
  BookOpenPosition = 'BOOK_OPEN_POSITION',
  ApprovedByHiringManager = 'APPROVED_BY_HIRING_MANAGER',
  RejectedByHiringManager = 'REJECTED_BY_HIRING_MANAGER',
  ApprovedByStaffMember = 'APPROVED_BY_STAFF_MEMBER',
  RejectedByStaffMember = 'REJECTED_BY_STAFF_MEMBER',
  Assigned = 'ASSIGNED',
  Deleted = 'DELETED',
  Opened = 'OPENED',
}

export enum StaffShiftLogType {
  ChangedState = 'ChangedState',
  MessageDeliveryOpenPositionInvite = 'MessageDelivery@OpenPositionInvite',
  OpenPositionInvite = 'OpenPositionInvite',
}

export interface StaffShiftLog {
  type: StaffShiftLogType;
  state: StaffShiftState;
  userId: number;
  userHospitalId: number;
  userName: string;
  userPhoneNumber?: string;
  at: string;
  messageStatus: string;
}

export enum ShiftType {
  Room = 'r',
  Procedure = 'p',
}

export interface OpenPosition {
  id: string;
  staffHospitalId?: number;
  staffHospitalName?: string;
  staff?: StaffMember;
  openPositionSmsCount?: string;
  date: string;
  from?: string;
  to?: string;
  unseen?: boolean;
  note?: string;
  description?: OpenPositionDescription;
  staffShiftId: number;
  staffShiftHospitalGroupId: number;
  staffShiftHospitalId?: number;
  staffShiftHospitalName?: string;
  state: StaffShiftState;
  logs: StaffShiftLog[];
}
