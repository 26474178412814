import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export default function useDebouncedEffect(effect: EffectCallback, delay: number, deps?: DependencyList) {
  const data = useRef<() => void | undefined>();

  useEffect(() => {
    const clearFunc = data.current;

    const handler = setTimeout(() => {
      if (clearFunc && typeof clearFunc === 'function') {
        clearFunc();
      }
      const destructor = effect();

      data.current = (destructor as any) || undefined;
    }, delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, ...(deps ?? [])]);
}
