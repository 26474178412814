import { compose, withProps } from 'recompose';
import { withLabel } from '../../se/components/Label';
import SelectInput from '../../se/components/inputs/SelectInput';

export default compose(
  withLabel('Role'),
  withProps({
    placeholder: 'e.g., Nurse',
    required: true,
  })
)(SelectInput);
