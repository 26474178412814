import React from 'react';
import Row from '../../../../se/components/collection/Row';
import { TableCell, TableRow } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  tableCell: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  divider: {
    flex: 1,
    marginLeft: theme.spacing(2),
  },
}));

export const SeparatorListRowView = ({ item, columns }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell colSpan={columns.length} className={classes.tableCell}>
        <Box display="flex" alignItems="center">
          <Typography display="block" variant="button" color="textSecondary">
            {item.label}
          </Typography>
          <Divider className={classes.divider} />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default props => {
  const { item } = props;
  if (!item) {
    return null;
  }

  const Component = item.separator ? SeparatorListRowView : Row;

  return <Component {...props} />;
};
