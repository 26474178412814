import React, { FC, Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { list } from '../../../graph/rooms';
import { RoomType } from '../../../types/Room';
import MultiSelectInput from '../../../se/components/inputs/MultiSelectInput';


const RoomMultiSelectInput: FC<any> = ({ value, onChange }) => {

  const { data, loading } = useQuery(list);

  if (loading) return null;

  const options = (data?.rooms || [])?.filter(e => e.type === RoomType.OR).map(e => ({
    label: e.name,
    value: e.id,
  }));

  const transformedValue = options.filter(e => (value || []).includes(e.value));

  return (
    <Fragment>
      <MultiSelectInput
        name={"or-rooms-select"}
        options={options}
        onChange={updated => {
          onChange(updated?.map(e => e.value));
        }}
        value={transformedValue}
        loading={false}
        disabled={false}
      />
    </Fragment>
  );
};

export default RoomMultiSelectInput;
