import { graphql } from '@apollo/client/react/hoc';
import React from 'react';
import { get as getScreen, updateScreen } from '../../../graph/screens';
import { branch, compose, mapProps, withProps, withState } from 'recompose';
import defaults from 'lodash/defaults';
import get from 'lodash/get';
import merge from 'lodash/merge';
import TextInput from '../../../se/components/inputs/TextInput';
import { getNestedValue } from '../../../se/utilities/data/object';
import Modal from '../../../se/components/Modal';
import CheckboxInput from '../../inputs/CheckboxInput';
import { withLabel } from '../../../se/components/Label';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import Form from '../../../se/components/Form';
import {
  CHARTING_TABLET,
  INTERNAL_MONITOR,
  OPERATION_ROOM_MONITOR,
  PACU_TABLET,
  POST_OP_TABLET,
  REVIEW_DISPLAY,
  EXIT_DISPLAY,
  WAITING_ROOM_TABLET,
  SCHEDULE_MONITOR,
  WAITING_ROOM_MONITOR,
  ANALYTICS,
  HOSPITAL_OVERVIEW,
} from './enums';
import { CenteredSpinner } from '../../../se/components/Spinner';
import PatientNameFormatInput from '../../inputs/PatientNameFormatInput';
import NumberInput from '../../../se/components/inputs/NumberInput';
import RatingInput from '../../../se/components/inputs/RatingInput';
import PhysicianNameFormatInput from '../../inputs/PhysicianNameFormatInput';
import SelectInput from '../../../se/components/inputs/SelectInput';
import RoomMultiSelectInput from './RoomMultiSelectInput';
import RoomMultiSelectInputAll from './RoomMultiSelectInputAll';
import ToggleInput from '../../../se/components/inputs/ToogleInput';
import { withScope } from '../../../contexts/ScopeContext';

const DescriptionInput = compose(
  withLabel('Monitor Description'),
  withProps({
    placeholder: 'e.g., By the corridor',
    autoComplete: 'off',
    required: false,
  })
)(TextInput);

const InternalMonitorConfigInput = withProps({
  schema: {
    description: DescriptionInput,
    sound: compose(
      withLabel('Sound Settings', { section: true }),
      withProps({
        schema: {
          prepReadySound: withProps({ label: 'Play sound when patient is in Waiting Room and ready for PreOp' })(
            CheckboxInput
          ),
          orReadySound: withProps({ label: 'Play sound when patient is in PreOp and ready for OR' })(CheckboxInput),
          readyForSurgeonSound: withProps({ label: 'Play sound when patient is in PreOp and ready for surgeon' })(
            CheckboxInput
          ),
          holdProcedureSound: withProps({ label: 'Play sound when the procedure is on hold' })(CheckboxInput),
          blockNerveSound: withProps({ label: 'Play sound when the patient nerve is blocked by anesthesia' })(
            CheckboxInput
          ),
          // Sound config for both Recovery and Post-Op room
          pacuReadySound: withProps({ label: 'Play sound when patient is ready to see family' })(CheckboxInput),
          waitingReadySound: withProps({ label: 'Play sound when the front desk hits Family Here' })(CheckboxInput),
          readyToPickupSound: withProps({
            label: 'Play sound when caretaker message for pick up confirmation is received',
          })(CheckboxInput),
          dischargeSound: withProps({ label: 'Play sound when patient is Discharged' })(CheckboxInput),
          closingSound: withProps({ label: 'Play sound when closing starts' })(CheckboxInput),
        },
      })
    )(ObjectInput),
    privacy: compose(
      withLabel('Patient Privacy Settings', { section: true }),
      withProps({
        schema: {
          showProcedure: withProps({ label: 'Display procedure type on screen' })(CheckboxInput),
          patientNameFormat: withLabel('Patient Name Format')(PatientNameFormatInput),
        },
      })
    )(ObjectInput),
    physician: compose(
      withLabel('Physician Settings', { section: true }),
      withProps({
        schema: {
          physicianNameFormat: withLabel('Physician Name Format')(PhysicianNameFormatInput),
        },
      })
    )(ObjectInput),
    notifications: compose(
      withLabel('Notification Settings', { section: true }),
      withProps({
        schema: {
          sendNotifications: withProps({ label: 'Send notifications about screen connectivity status' })(CheckboxInput),
        },
      })
    )(ObjectInput),
    screenFocus: compose(
      withLabel('Select Screens Settings', { section: true }),
      withProps({
        schema: {
          preOpFocused: withProps({ label: 'PreOp Focused View' })(CheckboxInput),
          pacuFocused: withProps({ label: 'Pacu Focused View' })(CheckboxInput),
        },
      })
    )(ObjectInput),
    showOrRooms: withLabel('Show Rooms')(RoomMultiSelectInputAll),
    showPostOp: compose(
      withScope,
      branch(
        ({ scope }) => !scope.hospital?.modules?.hasPostop,
        () => () => null
      ),
      withProps({ label: 'Show PostOP' }),
      mapProps(({ value, ...props }) => ({ value: value !== false, ...props }))
    )(CheckboxInput),
    lightMode: withProps({ label: 'Light Mode Internal' })(ToggleInput),
  },
  flatten: {
    sound: true,
    privacy: true,
    physician: true,
    notifications: true,
    screenFocus: true,
  },
})(ObjectInput);

const WaitingRoomConfigInput = withProps({
  schema: {
    description: DescriptionInput,
    sound: compose(
      withLabel('Sound Settings', { section: true }),
      withProps({
        schema: {
          // Sound config for both Recovery and Post-Op room
          pacuReadySound: withProps({ label: 'Play sound when patient is ready to see family' })(CheckboxInput),
        },
      })
    )(ObjectInput),
    privacy: compose(
      withLabel('Patient Privacy Settings', { section: true }),
      withProps({
        schema: {
          patientNameFormat: withLabel('Patient Name Format')(PatientNameFormatInput),
        },
      })
    )(ObjectInput),
    notifications: compose(
      withLabel('Notification Settings', { section: true }),
      withProps({
        schema: {
          sendNotifications: withProps({ label: 'Send notifications about screen connectivity status' })(CheckboxInput),
        },
      })
    )(ObjectInput),
    useCssGridLayout: compose(
      withLabel('Implementation Settings', { section: true }),
      withProps({
        schema: {
          useCssGridLayout: withProps({ label: 'Use CSS grid layout' })(CheckboxInput),
        },
      })
    )(ObjectInput),
  },
  flatten: {
    sound: true,
    privacy: true,
    notifications: true,
    useCssGridLayout: true,
  },
})(ObjectInput);

const ScheduleConfigInput = withProps({
  schema: {
    description: DescriptionInput,
    isTimeline: withProps({ label: 'Show schedule in Timeline view' })(CheckboxInput),
    anesthesiologistSchedule: withProps({ label: 'Anesthesiologist Schedule' })(CheckboxInput),
    notifications: compose(
      withLabel('Notification Settings', { section: true }),
      withProps({
        schema: {
          sendNotifications: withProps({ label: 'Send notifications about screen connectivity status' })(CheckboxInput),
        },
      })
    )(ObjectInput),
    privacy: compose(
      withLabel('Patient Privacy Settings', { section: true }),
      withProps({
        schema: {
          patientNameFormat: withLabel('Patient Name Format')(PatientNameFormatInput),
        },
      })
    )(ObjectInput),
    showBedNumber: withProps({ label: 'Show bed number' })(CheckboxInput),
    showStaffListOnSchedule: withProps({ label: 'Show staff list on Schedule' })(CheckboxInput),
    showOrRooms: withLabel('Show OR Rooms')(RoomMultiSelectInput),
    lightMode: withProps({ label: 'Light Mode Schedule' })(ToggleInput),
  },
  flatten: {
    privacy: true,
    notifications: true,
  },
})(ObjectInput);

const RecoveryConfigInput = withProps({
  schema: {
    description: DescriptionInput,
    sound: compose(
      withLabel('Sound Settings', { section: true }),
      withProps({
        schema: {
          readyToPickupSound: withProps({
            label: 'Play sound when caretaker message for pick up confirmation is received',
          })(CheckboxInput),
        },
      })
    )(ObjectInput),
    privacy: compose(
      withLabel('Patient Privacy Settings', { section: true }),
      withProps({
        schema: {
          patientNameFormat: withLabel('Patient Name Format')(PatientNameFormatInput),
        },
      })
    )(ObjectInput),
    notifications: compose(
      withLabel('Notification Settings', { section: true }),
      withProps({
        schema: {
          sendNotifications: withProps({ label: 'Send notifications about screen connectivity status' })(CheckboxInput),
        },
      })
    )(ObjectInput),
  },
  flatten: {
    sound: true,
    privacy: true,
    notifications: true,
  },
})(ObjectInput);

const ChartingConfigInput = withProps({
  schema: {
    description: DescriptionInput,
    privacy: compose(
      withLabel('Patient Privacy Settings', { section: true }),
      withProps({
        schema: {
          patientNameFormat: withLabel('Patient Name Format')(PatientNameFormatInput),
        },
      })
    )(ObjectInput),
    notifications: compose(
      withLabel('Notification Settings', { section: true }),
      withProps({
        schema: {
          sendNotifications: withProps({ label: 'Send notifications about screen connectivity status' })(CheckboxInput),
        },
      })
    )(ObjectInput),
    chartingPinTimeout: compose(
      withScope,
      branch(
        ({ scope }) => !scope.hospital?.modules?.patientCharting,
        () => null
      ),
      withLabel('Charting PIN Timeout'),
      withProps({
        options: {
          30: '30 seconds',
          60: '1 minute',
          120: '2 minutes',
          180: '3 minutes',
          240: '4 minutes',
          300: '5 minutes',
          null: 'Never',
        },
        name: 'chartingPinTimeout',
        isClearable: false,
      }),
      mapProps(({ value, onChange, ...props }) => ({
        value: value === null ? 'null' : String(value),
        onChange: value => {
          onChange(value === 'null' ? null : Number(value));
        },
        ...props,
      }))
    )(SelectInput),
  },
  flatten: {
    privacy: true,
    notifications: true,
  },
})(ObjectInput);

const ReviewsConfigInput = withProps({
  schema: {
    description: DescriptionInput,
    privacy: compose(
      withLabel('Patient Privacy Settings', { section: true }),
      withProps({
        schema: {
          patientNameFormat: withLabel('Patient Name Format')(PatientNameFormatInput),
        },
      })
    )(ObjectInput),
    notifications: compose(
      withLabel('Notification Settings', { section: true }),
      withProps({
        schema: {
          sendNotifications: withProps({ label: 'Send notifications about screen connectivity status' })(CheckboxInput),
        },
      })
    )(ObjectInput),
    limit: compose(
      withLabel('Max. Number of Reviews', { section: true }),
      withProps({
        min: 1,
        required: true,
        style: {
          marginBottom: '1rem',
        },
      })
    )(NumberInput),
    minimumRating: compose(
      withLabel('Minimum Rating', { section: true }),
      withProps({
        style: {
          marginBottom: '2rem',
        },
      })
    )(RatingInput),
  },
  flatten: {
    privacy: true,
    notifications: true,
  },
})(ObjectInput);

const ExitDisplayConfigInput = withProps({
  schema: {
    description: DescriptionInput,
  },
})(ObjectInput);

const WaitingRoomTabletConfigInput = withProps({
  schema: {
    description: DescriptionInput,
    questionnaireSessionTimeout: compose(
      withLabel('Questionnaire & Registration Package Timeout'),
      withProps({
        options: {
          30: '30 seconds',
          60: '1 minute',
          120: '2 minutes',
          180: '3 minutes',
          240: '4 minutes',
          300: '5 minutes',
          null: 'Never',
        },
        name: 'questionnaireSessionTimeout',
        isClearable: false,
      }),
      mapProps(({ value, onChange, ...props }) => ({
        value: value === null ? 'null' : String(value),
        onChange: value => {
          onChange(value === 'null' ? null : Number(value));
        },
        ...props,
      }))
    )(SelectInput),
    showRideHome: withProps({ label: 'Show Ride Home' })(CheckboxInput),
    showPreOpWr: withProps({ label: 'Show PreOp Questionnaire' })(CheckboxInput),
    showIntakeWr: withProps({ label: 'Show Registration Package Questionnaire' })(CheckboxInput),
  },
})(ObjectInput);

const ConfigInput = withProps({
  schema: {
    description: DescriptionInput,
    privacy: compose(
      withLabel('Patient Privacy Settings', { section: true }),
      withProps({
        schema: {
          patientNameFormat: withLabel('Patient Name Format')(PatientNameFormatInput),
        },
      })
    )(ObjectInput),
    notifications: compose(
      withLabel('Notification Settings', { section: true }),
      withProps({
        schema: {
          sendNotifications: withProps({ label: 'Send notifications about screen connectivity status' })(CheckboxInput),
        },
      })
    )(ObjectInput),
  },
  flatten: {
    privacy: true,
    notifications: true,
  },
})(ObjectInput);

const inputForScreenType = screenType => {
  switch (screenType) {
    case INTERNAL_MONITOR:
      return InternalMonitorConfigInput;
    case WAITING_ROOM_MONITOR:
      return WaitingRoomConfigInput;
    case SCHEDULE_MONITOR:
      return ScheduleConfigInput;
    case PACU_TABLET:
      return RecoveryConfigInput;
    case POST_OP_TABLET:
      return RecoveryConfigInput;
    case CHARTING_TABLET:
      return ChartingConfigInput;
    case REVIEW_DISPLAY:
      return ReviewsConfigInput;
    case EXIT_DISPLAY:
      return ExitDisplayConfigInput;
    case WAITING_ROOM_TABLET:
      return WaitingRoomTabletConfigInput;
    case ANALYTICS:
      return ConfigInput;
    case HOSPITAL_OVERVIEW:
      return ConfigInput;
    default:
      return ConfigInput;
  }
};

const defaultPatientNameFormatForScreenType = screenType => {
  switch (screenType) {
    case OPERATION_ROOM_MONITOR:
      return 'FullName';
    default:
      return 'InitialsOnly';
  }
};

const ScreenConfig = ({ baseUrl, history, match, updateScreen, busy, setBusy, data }) => {
  const handleSubmit = async value => {
    setBusy(true);
    try {
      const code = getNestedValue('params.id', match);
      const preparedValue = defaults(value, {
        showProcedure: false,
        patientNameFormat: defaultPatientNameFormatForScreenType(screenType),
        sendNotifications: true,
      });
      await updateScreen({ variables: { screenCode: code, ...preparedValue, showPostOp: value.showPostOp !== false } });
      history.push(baseUrl);
    } finally {
      setBusy(false);
    }
  };

  const loading = getNestedValue('loading', data);
  const screenType = getNestedValue('screen.connection.type.type', data);
  const Input = inputForScreenType(screenType);
  const preparedData = merge(
    {},
    {
      patientNameFormat: defaultPatientNameFormatForScreenType(screenType),
      limit: 10,
      minimumRating: 4,
      sendNotifications: false,
    },
    get(data, 'screen.configuration')
  );

  return (
    <Modal title="Configure Screen" closeTo={baseUrl}>
      {!loading ? (
        <Form autoFocus initialValue={preparedData} input={Input} label={'Save'} onSubmit={handleSubmit} />
      ) : (
        <CenteredSpinner />
      )}
    </Modal>
  );
};

export default compose(
  withState('busy', 'setBusy', false),
  graphql(updateScreen, { name: 'updateScreen' }),
  graphql(getScreen, { options: ({ match }) => ({ variables: { screenCode: getNestedValue('params.id', match) } }) })
)(ScreenConfig);
