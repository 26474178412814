import React, { useEffect, useMemo } from 'react';
import { STATUSES as ROOM_STATUSES } from '../../../../entities/room/enums';
// @ts-ignore
import prevSound from '../../../../../assets/sound/discharge.mp3';
// @ts-ignore
import nextSound from '../../../../../assets/sound/orReady.mp3';
import { soundAlert } from '../../Monitor';
import { throttle } from 'lodash';
import Mousetrap from 'mousetrap';
import { useMutation, useQuery } from '@apollo/client';
import { orTabletProcedureSteps } from '../../../../../graph/procedureSteps';
import { Patient, PatientStatus } from '../../../../../types/Patient';
import {
  completeCleaning as completeCleaningMutation,
  setPatientStatus,
  skipCleaning as skipCleaningMutation,
} from '../../../../../graph/rooms';
import { getProcedureState, ProcedureState, toPatientStatus } from '../util/procedureSteps';
import Cleaning from './Cleaning';
import ProcedureHandling from './ProcedureHandling';
import { ProcedureStep } from '../../../../../types/ProcedureStep';
import { Procedure } from '../../../../../types/Procedure';
import { Room } from '../../../../../types/Room';
import { getNestedValue } from '../../../../../se/utilities/data/object';

export const withAlertSound = (sound: any) => (fn: any) => async () => {
  try {
    // we need to play it right away, as Safari prevents autoplay
    soundAlert(sound, true);
    await fn();
  } catch (e) {
    console.warn('Operation failed. Not playing sound notification');
  }
};

const CurrentProcedure = ({
  nextProcedure,
  roomId,
  room,
  working,
  withWorking,
  nextProceduresInOperationRoom,
  nextRemainingProcedure,
  notifyPhysician,
  loading,
}: {
  nextProcedure: Procedure;
  roomId: number;
  room: Room & { status: string; patient: Patient & { duration?: string } };
  working: boolean;
  withWorking: any;
  nextProceduresInOperationRoom: Procedure[];
  nextRemainingProcedure: Procedure;
  notifyPhysician: () => Promise<void>;
  loading: boolean;
}) => {
  const { patient, status } = room;
  const { physician, procedureType, procedure } = patient;

  const withAlertSoundPrev = withAlertSound(prevSound);
  const withAlertSoundNext = withAlertSound(nextSound);

  const [completeCleaning] = useMutation(completeCleaningMutation);
  const [skipCleaning] = useMutation(skipCleaningMutation);

  const { data } = useQuery(orTabletProcedureSteps, {
    variables: {
      patientId: patient?.id,
    },
  });

  const procedureState: ProcedureState | null = useMemo(() => {
    if (status === ROOM_STATUSES.CLEANING) {
      return {
        steps: null,
        index: null,
        prev: null,
        next: () => updateRoomStatus(roomId),
        nextLabel: 'Cleaning Completed',
      };
    }

    const steps = data?.orTabletProcedureSteps || [];
    const state = getProcedureState(steps, status as PatientStatus);
    return {
      steps: steps.map((e: ProcedureStep, i: number) => ({
        ...e,
        highlight: i === (state?.index || 0),
        show: i <= (state?.index || 0),
      })),
      index: state?.index || null,
      prev: state?.prev?.type ? withAlertSoundPrev(() => changeStatus(toPatientStatus(state?.prev?.type))) : null,
      next: state?.next?.type ? withAlertSoundNext(() => changeStatus(toPatientStatus(state?.next?.type))) : null,
      nextLabel: state?.next?.name || null,
    };
  }, [data, status, roomId]);

  const [setStatus] = useMutation(setPatientStatus);

  const changeStatus = async (status: PatientStatus | null) => {
    status &&
      (await setStatus({
        variables: {
          id: patient?.id,
          status,
        },
        refetchQueries: [{ query: orTabletProcedureSteps, variables: { patientId: patient?.id } }],
      }));
  };

  const updateRoomStatus = async (id?: number) => {
    id && (await completeCleaning({ variables: { id } }));
  };

  const updateSkipCleaning = async (id?: number) => {
    id && (await skipCleaning({ variables: { id } }));
  };

  const isCleaning = status === ROOM_STATUSES.CLEANING;

  // forward button handler
  // @ts-ignore
  useEffect(() => {
    Mousetrap.bind(
      'l',
      throttle(() => {
        procedureState?.next?.();
      }, 1000)
    );
    return () => Mousetrap.unbind('l');
  });

  // back button handler
  // @ts-ignore
  useEffect(() => {
    Mousetrap.bind(
      'k',
      throttle(() => {
        procedureState?.prev?.();
      }, 1000)
    );
    return () => Mousetrap.unbind('k');
  });

  if (!nextRemainingProcedure && loading === false && getNestedValue('awaitingCleanup', room) === true) {
    console.log('Skipping cleaning as there are no upcoming procedures.');
    updateSkipCleaning(roomId);
  }

  return (
    <>
      {isCleaning ? (
        <Cleaning
          status={status}
          room={room}
          nextProcedure={nextProcedure}
          procedureState={procedureState}
          working={working}
          withWorking={withWorking}
        />
      ) : (
        <>
          <ProcedureHandling
            status={status}
            room={room}
            physician={physician}
            patient={patient}
            procedureType={procedureType}
            procedureState={procedureState}
            nextProceduresInOperationRoom={nextProceduresInOperationRoom}
            notifyPhysician={notifyPhysician}
            editableAnesthesiologist={true}
          />
        </>
      )}
    </>
  );
};

export default CurrentProcedure;
