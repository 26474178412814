import React from 'react';
import { useQuery } from '@apollo/client';
import { scope } from '../graph/scope';
import get from 'lodash/get';

export const Scope = React.createContext(null);

export const withScope = Component => props =>
  <Scope.Consumer>{scope => <Component {...props} scope={scope} />}</Scope.Consumer>;

export const ScopeProvider = ({ children }) => {
  const { data } = useQuery(scope);

  return <Scope.Provider value={get(data, 'scope')}>{children}</Scope.Provider>;
};
