import { graphql } from '@apollo/client/react/hoc';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { listNursing } from '../../../../graph/rooms';
import { compose, mapProps } from 'recompose';
import PickerHeader from './PickerHeader';
import { ROOM_TYPES } from '../../../entities/room/enums';
import { filter } from 'lodash';

const Content = styled.div`
  padding: 0 2em 2em;
  display: flex;
  flex-direction: column;
`;

const Slots = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(3, 1fr);
  flex: 1;
`;

const Slot = styled.button`
  border: none;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  font-size: 1.6em;
  border-radius: 0.25rem;

  &:active {
    background: #00b641;
  }

  &:disabled {
    opacity: 0.25;
  }
`;

const PatientRoomPicker = ({ rooms = [], loading, onSelectRoom, goToStartPage, onCancel }) => {
  const [working, setWorking] = useState(false);

  const handleAssignRoom = roomId => async () => {
    setWorking(true);
    try {
      await onSelectRoom(roomId);
      goToStartPage();
    } catch (e) {
      console.warn(e);
    }

    setWorking(false);
  };

  return (
    <Content>
      <PickerHeader onCancel={onCancel} title={'Choose OR'} />
      <Slots>
        {rooms.map(room => (
          <Slot key={room.id} disabled={working || room.patient} onClick={handleAssignRoom(room.id)}>
            {room.name}
          </Slot>
        ))}
      </Slots>
    </Content>
  );
};

PatientRoomPicker.propTypes = {
  rooms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  onSelectRoom: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  goToStartPage: PropTypes.func.isRequired,
};

export default compose(
  graphql(listNursing),
  mapProps(({ data, ...rest }) => ({
    ...rest,
    loading: data.loading,
    rooms: filter(data.rooms, { type: ROOM_TYPES.OR }),
  }))
)(PatientRoomPicker);
