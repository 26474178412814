import React from 'react';
import { branch, compose, renderComponent, withState } from 'recompose';
import EntityView from '../../../../se/components/entity/EntityView';
import { getNestedValue } from '../../../../se/utilities/data/object';
import { RouteComponentProps, withRouter } from 'react-router';
import withDebugKey from '../../../../hocs/withDebugKey';
import { withSession } from '../../../../state/Session';
import get from 'lodash/get';
import { NamedRange } from '../../../core/DatePicker';
import { convertHoursAndMinutesForSlider, getRoomEntry, procedureAccuracy } from '../../../../util/procedureAccuracy';
import Box from '@material-ui/core/Box';
import { STATUSES } from '../../../entities/patient/enums';
import { unpackSessionObject } from '../../unpackSessionObject';
import { getRoom } from '../../../entities/common/transducers';
import { TrackingPanel } from '../../../entities/patient/views/dataFields';
import ChangeLocation from '../../../entities/patient/views/ChangeLocationForm';
import { Patient } from '../../../../types/Patient';
import useCareAppAccess from '../../../entities/procedures/utils/useCareAppAccess';
import { useConnectionProviderContext } from '../connectionProviderContext';
import { ThemeProvider } from '@material-ui/core/styles';
import { tabletThemeLight } from '../../../../themes/tabletTheme';
import { alpha, makeStyles } from '@material-ui/core';
import FooterSession from '../tablet/components/FooterSession';
import ChartingQuestionnairesContext from '../../../../contexts/ChartingQuestionnairesContext';
import ChartingForms from './ChartingForms';
import PatientFileGenerator from '../../../entities/patient/views/PatientFileGenerator';
import { FormType } from '../../../../types/Form';
import FileUploader from '../../../FileUploader';
import { withScope } from '../../../../contexts/ScopeContext';
import { useChartingSession } from './modules/hooks';

export const withOptionalDebug = (Component: React.FC) =>
  compose(
    withSession(unpackSessionObject),
    branch((props: any) => props.isSuperAdmin || props.isMonitoringEngineer, renderComponent(withDebugKey(Component)))
  )(Component);

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: alpha(theme.palette.common.white, 0.8),
    backdropFilter: 'blur(10px)',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    position: 'fixed',
    bottom: 0,
    left: 0,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    borderTop: `1px solid ${theme.palette.divider}`,
    width: '100%',
  },
}));

const PatientViewContent = (props: { data: Patient } & RouteComponentProps & any) => {
  const { scope } = props;
  const { accessToken } = useConnectionProviderContext();
  useCareAppAccess(get(scope, 'hospital.id'), accessToken);
  const timeZone = get(scope, 'hospital.timezone.id');
  const { id, dischargedAt, voidedAt, room, braceletId, status } = props.data || {};
  const orRoomTimes = get(props.data, 'orRoomTimes', []);
  const startTime = get(props.data, 'procedure.startTime', false);
  const duration = get(props.data, 'procedure.duration', false);
  const [enteredAt, exitedAt] = orRoomTimes.length > 0 ? getRoomEntry(orRoomTimes) : [0, 0];
  const calculate = startTime && duration && enteredAt && exitedAt;
  const procedureSchedulingAccuracy = calculate
    ? procedureAccuracy(startTime, duration, enteredAt, exitedAt, timeZone)
    : false;
  const [scheduledRange, actualRange, sliderRange] = calculate
    ? convertHoursAndMinutesForSlider(startTime, duration, enteredAt, exitedAt, timeZone)
    : [
        [0, 0],
        [0, 0],
        [6, 18],
      ];
  const classes = useStyles();
  const isActivePatient = !(dischargedAt || voidedAt);

  const procedure = props?.data?.procedure;
  const procedureId = procedure?.id;

  const chartingSession = useChartingSession();

  return (
    <>
      <EntityView {...props} />

      <Box mt={2} flex={1}>
        {/* @ts-ignore */}
        <TrackingPanel
          isActive={isActivePatient}
          isDischarged={!!dischargedAt}
          isCanceled={!!dischargedAt && status === STATUSES.CANCELED}
          patientId={id}
          room={getRoom(room)}
          braceletId={braceletId}
          setEditModalOpen={props.setEditModalOpen}
          procedureSchedulingAccuracy={procedureSchedulingAccuracy}
          scheduledRange={scheduledRange}
          actualRange={actualRange}
          sliderRange={sliderRange}
        />
      </Box>

      {props.editModalOpen.room && (
        <Box mb={2}>
          <ChangeLocation
            patientId={props.data.id}
            initialValue={getNestedValue('id', room)}
            setEditModalOpen={props.setEditModalOpen}
          />
        </Box>
      )}

      <ThemeProvider theme={tabletThemeLight}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'right' }}>
          {procedureId && (
            <PatientFileGenerator
              procedureId={procedureId}
              formTypes={[
                FormType.PreOpForm,
                FormType.InTakeForm,
                FormType.PreOpChartForm,
                FormType.OrChartForm,
                FormType.PacuChartForm,
                FormType.PhysicianChartForm,
                FormType.AnesthesiologistOrChartForm,
                FormType.AnesthesiologistPreOpChartForm,
                FormType.AnesthesiologistPacuChartForm,
              ]}
              chartingTablet={true}
            />
          )}
        </div>
        {procedureId && <FileUploader procedureId={procedureId} editDisabled={true} />}

        <Box mb={10}>
          <ChartingQuestionnairesContext.Provider
            value={{
              preOpCharts: procedure?.preOpCharts || [],
              orCharts: procedure?.orCharts || [],
              pacuCharts: procedure?.pacuCharts || [],
            }}
          >
            <ChartingForms
              hospitalId={props?.data?.hospital?.id}
              patientId={props?.data?.id}
              procedureId={procedureId}
              questionnaire={props?.data?.procedure?.entryQuestionnaire}
              procedure={props?.data?.procedure || {}}
              pacuCharts={props?.data?.procedure?.pacuCharts}
            />
          </ChartingQuestionnairesContext.Provider>
        </Box>
        <Box className={classes.footer}>
          <FooterSession />
        </Box>
      </ThemeProvider>
    </>
  );
};

export default compose(
  withScope,
  withRouter,
  withState('filter', 'setFilter', {
    dateRange: NamedRange.lastSevenDays(),
  }),
  withState('signalLogShown', 'setSignalLogShown', false),
  withState('editModalOpen', 'setEditModalOpen', {
    room: false,
    braceletId: false,
  }),
  withOptionalDebug
)(PatientViewContent);
