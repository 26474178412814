import React, { useContext, useMemo } from 'react';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import FormContext from './FormContext';
import renderFormNode from './renderFormNode';
import components from './components';
import makeName from './makeName';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
});

const ListInput = ({ item }) => {
  const classes = useStyles();
  const { name, value, onChange } = useContext(FormContext);

  const list = get(value, 'list', [{ list: [components()[item.type].defaultValue], progress: 0 }]);

  const node = useMemo(() => renderFormNode(item), [item]);

  const add = e => {
    e.preventDefault();
    onChange({ list: [...list, components()[item.type].defaultValue], progress: 0 });
  };

  const remove = index => e => {
    e.preventDefault();
    const nextList = [...list];
    nextList.splice(index, 1);
    onChange({ list: nextList, progress: 0 });
  };

  return (
    <div>
      <ul className={classes.list}>
        {list.map((v, i) => (
          <li key={i}>
            <FormContext.Provider
              value={{
                name: makeName(name, i),
                value: get(value, ['list', i], components()[item.type].defaultValue),
                onChange: v =>
                  onChange(
                    set(set(cloneDeep(value || components()[item.type].defaultValue), ['list', i], v), 'progress', 0)
                  ),
              }}
            >
              {node}
            </FormContext.Provider>
            <Button onClick={remove(i)} startIcon={<RemoveCircleOutline />}>
              <FormattedMessage id="questionnaire.list.remove" defaultMessage="Remove" />
            </Button>
            <Box my={3}>
              <Divider />
            </Box>
          </li>
        ))}
      </ul>
      <Button onClick={add} startIcon={<AddCircleOutline />}>
        <FormattedMessage id="questionnaire.list.add" defaultMessage="Add" />
      </Button>
    </div>
  );
};

ListInput.defaultValue = undefined;

export default ListInput;
