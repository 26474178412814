import React from 'react';
import { setMinutes, setHours, addMinutes, getHours, getMinutes, addMilliseconds } from 'date-fns';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import queryString from 'query-string';

export const withTime = updateInterval => Component =>
  class WithDate extends React.Component {
    constructor(props) {
      super(props);
      const dateOverride = queryString.parse(props.location.search).date;
      const date = dateOverride ? new Date(dateOverride + 'T00:00:00') : new Date();
      this.state = { time: date };
    }

    componentDidMount() {
      const old = queryString.parse(this.props.location.search) || {};

      if (old.time) {
        const [hrs, min] = old.time.split('-');
        const time = setMinutes(setHours(this.state.time, hrs), min);
        this.setState({ time });

        this.updateInterval = setInterval(() => {
          this.setState({ time: addMinutes(this.state.time, 5) });
        }, updateInterval);
      } else {
        // This will drift, dont use in production
        this.updateInterval = setInterval(() => {
          this.setState({ time: addMilliseconds(this.state.time, updateInterval) });
        }, updateInterval);
      }
    }

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
      if (queryString.parse(nextProps.location.search).date !== queryString.parse(this.props.location.search).date) {
        const dateOverride = queryString.parse(nextProps.location.search).date;
        const date = new Date(dateOverride + `T00:00:00`);

        this.setState({ time: setHours(setMinutes(date, getMinutes(this.state.time)), getHours(this.state.time)) });
      }
    }

    componentWillUnmount() {
      clearInterval(this.updateInterval);
    }
    render() {
      return <Component {...this.props} now={this.state.time} />;
    }
  };

export default compose(withRouter, withTime(5000));
