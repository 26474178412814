import { gql } from '@apollo/client';

export const fragmentTransferIssue = gql`
  fragment ScheduleTransferIssueData on ScheduleTransferIssue {
    id
    message
    state
    resolved
    confirmed
    raisedAt
    resolvedAt
    confirmedAt
  }
`;

const fragmentScheduleRequest = gql`
  fragment ScheduleTransferData on ScheduleTransfer {
    id
    fromOrgId
    toOrgId
    status
    state
    sourceProcedureId
    fromOrg {
      id
      name
    }
    toOrg {
      id
      name
    }
    externalProvider {
      id
      provider
    }
    payload {
      s3PatientFile {
        url
      }
      physician {
        id
        name
        speciality
      }
      patient {
        id
        name
        cellPhone
        emailAddress
        sex
        dateOfBirth
      }
      uploads {
        formId
        name
        icon
        files {
          fileName
          path {
            url
          }
        }
      }
      forms
      signedByPhysician
    }
    transferType
    forDate
    scheduleTransferDate
    scheduleTransferTime
    duration
    hasUnseenChange
    issues {
      ...ScheduleTransferIssueData
    }
    updates {
      id
      type
      timestamp
      patientPin
      cancelReason
      channel
      content
      text
      status
      exchangeId
      user {
        id
        name
      }
    }
    notesStatus
    notes {
      id
      type
      timestamp
      patientPin
      cancelReason
      channel
      content
      text
      status
      exchangeId
      user {
        id
        name
      }
    }
  }
  ${fragmentTransferIssue}
`;

export const list = gql`
  query scheduleRequests {
    scheduleRequests: scheduleTransferRequests {
      ...ScheduleTransferData
    }
  }
  ${fragmentScheduleRequest}
`;

export const listSubscription = gql`
  subscription scheduleRequests($filter: FilterT) {
    scheduleRequests: scheduleTransferRequests(filter: $filter) {
      ...ScheduleTransferData
    }
  }
  ${fragmentScheduleRequest}
`;

export const markTransferIssueResolved = gql`
  mutation markTransferIssueResolved($id: Long!) {
    markTransferIssueResolved(id: $id)
  }
`;

export const markTransferIssueConfirmed = gql`
  mutation markTransferIssueConfirmed($id: Long!) {
    markTransferIssueConfirmed(id: $id)
  }
`;

export const item = gql`
  query scheduleRequest($id: Long!) {
    scheduleRequest: scheduleTransferRequest(id: $id) {
      ...ScheduleTransferData
    }
  }
  ${fragmentScheduleRequest}
`;

export const itemSubscription = gql`
  subscription scheduleRequest($id: Long!) {
    scheduleRequest: scheduleTransferRequest(id: $id) {
      ...ScheduleTransferData
    }
  }
  ${fragmentScheduleRequest}
`;

export const itemForProcedure = gql`
  subscription scheduleRequest($procedureId: Long!) {
    scheduleTransferRequest: scheduleTransferRequestForProcedure(procedureId: $procedureId) {
      ...ScheduleTransferData
      issues {
        ...ScheduleTransferIssueData
      }
    }
  }
  ${fragmentTransferIssue}
  ${fragmentScheduleRequest}
`;

export const update = gql`
  mutation updateRequestTransfer(
    $id: Long!
    $surgicalCenterId: Long!
    $formIds: [Long!]!
    $signPhysician: Boolean!
    $scheduleTransferDate: Date!
    $scheduleTransferTime: String!
    $duration: Int!
    $bookingId: String
  ) {
    updateRequestTransfer(
      id: $id
      surgicalCenterId: $surgicalCenterId
      formIds: $formIds
      scheduleTransferDate: $scheduleTransferDate
      scheduleTransferTime: $scheduleTransferTime
      duration: $duration
      signPhysician: $signPhysician
      bookingId: $bookingId
    )
  }
`;

export const updateDocs = gql`
  mutation updateRequestTransferDocuments($id: Long!, $formIds: [Long!]!, $signPhysician: Boolean!) {
    updateRequestTransferDocuments(id: $id, formIds: $formIds, signPhysician: $signPhysician)
  }
`;

export const create = gql`
  mutation createRequestTransfer(
    $surgicalCenterId: Long!
    $procedureId: Long!
    $formIds: [Long!]!
    $signPhysician: Boolean!
    $scheduleTransferDate: Date!
    $scheduleTransferTime: String!
    $duration: Int!
    $scheduleTransferType: ScheduleTransferTransferTypeType!
    $bookingId: String
  ) {
    createRequestTransfer(
      surgicalCenterId: $surgicalCenterId
      procedureId: $procedureId
      formIds: $formIds
      scheduleTransferDate: $scheduleTransferDate
      scheduleTransferTime: $scheduleTransferTime
      duration: $duration
      scheduleTransferType: $scheduleTransferType
      signPhysician: $signPhysician
      bookingId: $bookingId
    )
  }
`;

export const updateTransferStatus = gql`
  mutation updateTransferStatus(
    $id: Long!
    $scheduleTransferRequestStatus: ScheduleTransferRequestStatusType!
    $scheduleTransferStatusMessage: String
    $hstId: HstId
  ) {
    updateTransferStatus(
      id: $id
      scheduleTransferRequestStatus: $scheduleTransferRequestStatus
      scheduleTransferStatusMessage: $scheduleTransferStatusMessage
      hstId: $hstId
    )
  }
`;

export const createRequestedDocument = gql`
  mutation requestAdditionalDocuments($requestId: Long!, $text: String) {
    requestAdditionalDocuments(requestId: $requestId, text: $text)
  }
`;

export const procedureRequestsStatistics = gql`
  subscription procedureRequestsStatistics($filter: FilterT) {
    procedureRequestsStatistics(filter: $filter) {
      totalRequested
      totalScheduled
      missingInformation
    }
  }
`;

export const markRequestTransferChangeAsSeen = gql`
  mutation markRequestTransferChangeAsSeen($scheduleTransferId: Long!) {
    markRequestTransferChangeAsSeen(scheduleTransferId: $scheduleTransferId) {
      ...ScheduleTransferData
    }
  }
  ${fragmentScheduleRequest}
`;

export const postSourceProcedureNote = gql`
  mutation postSourceProcedureNote($id: Long!, $note: String!) {
    postSourceProcedureNote(id: $id, note: $note)
  }
`;

export const deleteSourceProcedureNote = gql`
  mutation deleteSourceProcedureNote($id: Long!, $noteId: Long!) {
    deleteSourceProcedureNote(id: $id, noteId: $noteId)
  }
`;

export default {
  list: listSubscription,
  item: itemSubscription,
  update,
  create,
};
