import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import styled from 'styled-components';
import { compose, withProps } from 'recompose';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import scheme, { resolveAlert } from '../../../graph/alerts';
import { sortDate, sortNumber, sortString } from '../../../util/sort';
import { Text, TruncatedText } from '../../../se/components/typography';
import format from 'date-fns/format';
import EntityView from '../../../se/components/entity/EntityView';
import get from 'lodash/get';
import pick from 'lodash/fp/pick';
import { Actions } from './SensorGrid';
import Form from '../../../se/components/Form';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import { withLabel } from '../../../se/components/Label';
import TextAreaInput from '../../../se/components/inputs/TextAreaInput';
import { colorForValue, roundFixed, temperatureLabel } from './SensorList';
import AlertMobileListItem from './AlertMobileListItem';
import Button from '../../../se/components/Button';
import ExpandableText from '../../core/ExpandableText';
import { NamedRange } from '../../core/DatePicker';
import Row from '../../../se/components/collection/Row';
import { SeparatorListRowView } from '../../entities/patient/views/PatientListView';

const idColumn = {
  title: '#',
  lens: item => item.id,
  sortFunction: (l, r) => sortString(l.name, r.name),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const sensorIdColumn = {
  title: 'Sensor Id',
  lens: item => item.sensorId,
  sortFunction: (l, r) => sortString(l.sensorId, r.sensorId),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

const timeColumn = {
  title: 'Time',
  lens: item => item,
  Component: ({ data }) => (
    <TruncatedText>{data.createdAt ? format(data.createdAt, 'MM/DD/YYYY HH:mm') : 'N/A'}</TruncatedText>
  ),
  sortFunction: (l, r) => sortDate(l.createdAt, r.createdAt),
};

const resolvedAtColumn = {
  title: 'Resolved At',
  lens: item => item,
  Component: ({ data }) => (
    <TruncatedText>{data.resolvedAt ? format(data.resolvedAt, 'MM/DD/YYYY HH:mm') : 'N/A'}</TruncatedText>
  ),
  sortFunction: (l, r) => sortDate(l.resolvedAt, r.resolvedAt),
};

const locationColumn = {
  title: 'Location',
  lens: item => get(item, 'sensor.location'),
  sortFunction: (l, r) => sortString(get(l, 'sensor.location'), get(r, 'sensor.location')),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const temperatureColumn = {
  title: 'Temp',
  lens: data => ({
    value: get(data, 'temperature.value'),
    unit: get(data, 'temperature.unit'),
    min: get(data, 'sensor.temperatureMin.value'),
    max: get(data, 'sensor.temperatureMax.value'),
  }),
  sortFunction: (l, r) => sortNumber(get(l, 'temperature.value'), get(r, 'temperature.value')),
  Component: ({ data: { value, unit, min, max } }) => (
    <Text style={{ color: colorForValue(value, min, max) }}>{`${roundFixed(value)} ${temperatureLabel(unit)}`}</Text>
  ),
};

const humidityColumn = {
  title: 'Humidity',
  lens: data => ({ value: data.humidity, min: get(data, 'sensor.humidityMin'), max: get(data, 'sensor.humidityMax') }),
  sortFunction: (l, r) => sortNumber(l.humidity, r.humidity),
  Component: ({ data: { value, min, max } }) => (
    <Text style={{ color: colorForValue(value, min, max) }}>{value ? `${roundFixed(value)} %` : undefined}</Text>
  ),
};

const actionColumn = {
  title: 'Action',
  lens: item => item.action,
  sortFunction: (l, r) => sortString(l.action, r.action),
  style: { maxWidth: '40em', minWidth: '20em' },
  Component: props => (
    <ExpandableText
      text={props.data}
      onClick={e => {
        e.stopPropagation();
      }}
    />
  ),
};

export const alertStatus = {
  RESOLVED: 'RESOLVED',
  ACTIVE: 'ACTIVE',
};

const statusColumn = {
  title: 'Status',
  lens: item => (item.isResolved ? alertStatus.RESOLVED : alertStatus.ACTIVE),
  sortFunction: (l, r) => sortString(l.action, r.action),
  Component: props => (
    <TruncatedText style={{ color: props.data === alertStatus.RESOLVED ? 'green' : 'red' }}>{props.data}</TruncatedText>
  ),
};

const ResolveIssueInput = withProps({
  schema: {
    action: compose(withProps({ required: true, rows: 5 }), withLabel('Comment / Action'))(TextAreaInput),
  },
})(ObjectInput);

const Wrapper = styled.div`
  > * + * {
    margin-top: 1em;
  }
`;

const Footer = ({ busy, isDirty, isResolved }) =>
  isDirty || !isResolved ? (
    <Actions>
      <Button disabled={busy} primary>
        {isResolved ? 'Update Comment' : 'Resolve Issue'}
      </Button>
    </Actions>
  ) : null;

const AlertPage = props => {
  const onSubmit = mutation => async data => {
    await mutation({
      variables: { ...data },
      refetchQueries: [{ query: scheme.item, variables: { id: get(props, 'data.id') } }],
    });
  };

  return (
    <Wrapper>
      <EntityView {...props} />
      <Mutation
        mutation={resolveAlert}
        refetchQueries={[{ query: scheme.item, variables: { id: get(props, 'data.id') } }]}
      >
        {(mutate, { loading }) => (
          // TODO: Here is some example for how to use
          <Form
            label={''}
            autoFocus
            input={ResolveIssueInput}
            onSubmit={onSubmit(mutate)}
            busy={loading}
            Footer={withProps({ isResolved: get(props, 'data.isResolved') })(Footer)}
            withoutReset={true}
            initialValue={{
              id: get(props, 'data.id'),
              action: get(props, 'data.action'),
            }}
          />
        )}
      </Mutation>
    </Wrapper>
  );
};

export const sortByResolved = (a, b) => a.isResolved - b.isResolved;

const sensorListView = props => {
  const { item } = props;

  if (!item) {
    return null;
  }

  const Component = item.separator ? SeparatorListRowView : Row;

  return <Component {...props} />;
};

const mapListProps = props => {
  const { data } = props;

  const sortedSensorAlerts = data.sensorAlerts?.slice().sort(sortByResolved);

  const firstResolved = sortedSensorAlerts?.find(e => e.isResolved === true);

  if (!firstResolved) return props;

  const separatorIndex = sortedSensorAlerts?.indexOf(firstResolved);

  sortedSensorAlerts?.splice(separatorIndex, 0, { separator: true, label: 'Resolved Alerts' });

  return {
    ...props,
    data: {
      ...data,
      sensorAlerts: sortedSensorAlerts,
    },
  };
};

const baseColumns = [timeColumn, locationColumn, temperatureColumn, humidityColumn, statusColumn, resolvedAtColumn];

const listColumns = [idColumn, ...baseColumns, actionColumn];

const SensorAlerts = GraphQLCRUD({
  entityName: 'SensorAlert',
  List: {
    Empty: () => (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '20em' }}>
        There are no issues for the selected period.
      </div>
    ),
    //separator logic
    containsSeparator: true,
    View: sensorListView,
    mapListProps,

    tableKey: 'SensorAlerts',
    columns: listColumns,
    MobileItemComponent: AlertMobileListItem,
    TitleAndActions: () => null,
    defaultFilterValues: {
      dateRange: NamedRange.lastSevenDays(),
    },
    pickFilter: pick([
      'name',
      'physician',
      'procedureType',
      'hospital',
      'status',
      'dateRange',
      'category',
      'speciality',
    ]),
    queryOptionsProvider: props => ({
      variables: {
        sensors: props.sensorIds,
        filter: props.parentFilter,
      },
    }),
  },
  Show: {
    View: AlertPage,
    titleProvider: data => get(data, 'sensor.location', data.id),
    columns: [idColumn, sensorIdColumn, ...baseColumns],
    hideTitle: true,
  },
  scheme,
});

export default SensorAlerts;
