import React, { ReactNode, createContext, useContext } from 'react';

const slideContext = createContext<number | null>(null);

export const useSlide = (): number | null => useContext(slideContext);

interface SlideProviderProps {
  slide: number;
  children: ReactNode;
}

const SlideProvider = ({ slide, children }: SlideProviderProps) => (
  <slideContext.Provider value={slide}>{children}</slideContext.Provider>
);

export default SlideProvider;
