import React, { FC, ReactNode } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router';
import { matchPath } from 'react-router-dom';
import { Tab } from '@material-ui/core';
import groupBy from '../../../../util/groupBy';

import GroupedTabNavigation from './GroupedTabNavigation';

export interface Tab extends RouteProps {
  title: string;
  path?: string;
}

interface TabRoutesProps extends RouteComponentProps<{ id?: string }> {
  tabs: Tab[];
  tabsStyles?: React.CSSProperties;
  orElse?: ReactNode;
}

const GroupedTabRoutes: FC<TabRoutesProps> = ({ history, location, match, tabs, tabsStyles, orElse }) => {
  const value =
    tabs.find(tab =>
      matchPath(location.pathname, {
        ...tab,
        path: `${match.url}${tab.path}`,
      })
    )?.path ?? tabs?.[0]?.path;
  const isExact = location?.pathname === `${match.url}${value}`;

  const groups = Object.entries(groupBy(tabs, item => item?.group || ''));
  const handleChange = (event: any, newValue: any) => {
    history.replace(`${match.url}${newValue ?? tabs?.[0]?.path}${window.location.search}`.replaceAll(value || '', ''));
  };

  return (
    <>
      {isExact && <GroupedTabNavigation tabs={tabs} history={history} location={location} match={match} />}
      <Switch>
        {tabs.map(tab => (
          <Route key={tab.path} {...tab} path={`${match.url}${tab.path}`} />
        ))}
        <Redirect to={`${match.url}${tabs[0].path}`} />
      </Switch>
    </>
  );
};

export default GroupedTabRoutes;
