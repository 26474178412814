import React from 'react';
import { RouteComponentProps } from 'react-router';
import { DefaultTitle } from '../../../../se/components/entity/EntityRouter';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import { gql, useSubscription } from '@apollo/client';
import { format } from 'date-fns';
import humanFileSize from '../../../../util/humanFileSize';
import TrackingSessionBeacons from './TrackingSessionBeacons';
import CurrentTrackingSession from '../CurrentTrackingSession';

interface TrackingSessionProps extends RouteComponentProps<{ sessionId: string }> {
  baseUrl: string;
}

const TrackingSession = ({ match, history, baseUrl }: TrackingSessionProps) => {
  const classes = useStyles();

  const sessionId = match.params.sessionId;

  const { data } = useSubscription<{
    trackingSession: {
      id: string;
      startedBy: number;
      startedAt: string;
      stoppedBy: number;
      stoppedAt: string;
      bytesLogged: number;
    };
  }>(
    gql`
      subscription trackingSession($trackingSessionId: String!) {
        trackingSession(trackingSessionId: $trackingSessionId) {
          id
          startedBy
          startedAt
          stoppedBy
          stoppedAt
          bytesLogged
        }
      }
    `,
    {
      variables: { trackingSessionId: sessionId },
    }
  );

  const session = data?.trackingSession;

  return (
    <div className={classes.root}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link component={RouterLink} to={baseUrl}>
          Tracking Insights
        </Link>
        <Typography>Session {session ? format(session.startedAt, 'MMMM D, YYYY; HH:mm') : sessionId}</Typography>
      </Breadcrumbs>
      <CurrentTrackingSession history={history} baseUrl={match.url} />
      <DefaultTitle>Session {session ? format(session.startedAt, 'MMMM D, YYYY; HH:mm') : sessionId} </DefaultTitle>
      {session && <div>{humanFileSize(session.bytesLogged)} logged</div>}
      <TrackingSessionBeacons sessionId={sessionId} baseUrl={match.url} />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

export default TrackingSession;
