import React from 'react';
import Box from '@material-ui/core/Box';
import { Popover, Typography, Grid } from '@material-ui/core';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import { light } from '../../../../../theme';

const ProcedureSlotPopover = ({ anchorElement, data, isOpen, onClose }) => {
  const classes = useStyles();

  return (
    <Popover
      id="mouse-over-popover"
      style={{ pointerEvents: 'none' }}
      classes={{
        paper: classes.popover,
      }}
      open={isOpen}
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      disableRestoreFocus
      onClose={onClose}
    >
      <Box p={2} minWidth={250} maxWidth={350}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="caption" color="textSecondary" gutterBottom>
              Procedure
            </Typography>
            <Typography variant="body2">{get(data, 'procedureType')}</Typography>
          </Grid>

          <Grid item>
            <Typography variant="caption" color="textSecondary" gutterBottom>
              Scheduled for
            </Typography>
            <Typography variant="body2">
              {get(data, 'startTimeText')} – {get(data, 'endTimeText')}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="caption" color="textSecondary" gutterBottom>
              Physician
            </Typography>
            <Typography variant="body2">{get(data, 'physician.name') || get(data, 'physicianNameHST')}</Typography>
          </Grid>

          <Grid item>
            <Typography variant="caption" color="textSecondary" gutterBottom>
              Patient
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Box mr={2}>
                <Typography>{get(data, 'patientName')}</Typography>
              </Box>
              <Typography color="textSecondary">
                {get(data, 'patientAge')}/{get(data, 'patientSex') || '-'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  );
};

const useStyles = makeStyles(() => ({
  popover: {
    backgroundColor: light.popover.background.string(),
    pointerEvents: 'none',
    boxShadow: 'none',
    maxWidth: 500,
  },
}));

export default ProcedureSlotPopover;
