import { RouterlessModal } from '../../../../../se/components/Modal';
import { Box, Button, createStyles, makeStyles, Typography } from '@material-ui/core';
import PrimarySpecialtySelectInput from '../../../../inputs/staff/PrimarySpecialtySelectInput';
import OtherSpecialtiesSelectInput from '../../../../inputs/staff/OtherSpecialtiesSelectInput';
import TitleSelectInput from '../../../../inputs/staff/TitleSelectInput';
import React, { FC, useEffect, useState } from 'react';
import TimeRangeInput from './TimeRangeInput';
import { compose, withProps } from 'recompose';
import { withLabel } from '../../../../../se/components/Label';
import TextAreaInput from '../../../../../se/components/inputs/TextAreaInput';
import { OtherSpecialty, OtherSpecialtyLabel } from '../../../../../types/StaffMember';
import { useStaffSlotContext } from './StaffSlotContext';
import { OpenPositionDescription, ShiftType } from '../../../../../types/StaffShift';
import OpenPositionNotification from './OpenPositionNotification';

interface EmptySlotFormProps {
  id: number;
  from?: string;
  to?: string;
  openPosition?: OpenPositionDescription;
}

const TextArea = TextAreaInput as any;
const DescriptionInput = compose(withLabel('Description'), withProps({ rows: 4 }))(TextArea);

type Option = { label: string; value: OtherSpecialty };
type State = OpenPositionDescription & { timeRange?: string; otherSpecialties: Option[] };

export const getOpenPositionId = (shitType?: ShiftType, shiftId?: number, hospitalId?: number) =>
  [shitType, shiftId, hospitalId].map(e => e ?? '').join('.');

const OpenPositionForm: FC<EmptySlotFormProps> = ({ id, from, to, openPosition }) => {
  const classes = useStyles();
  const staffSlotContext = useStaffSlotContext();

  const [state, setState] = useState<State>({
    timeRange: '',
    primarySpecialty: null,
    otherSpecialties: [],
    title: null,
    description: null,
  });

  useEffect(() => {
    const defaultValue = openPosition
      ? Object.assign(openPosition, {
          timeRange: from && to ? `${from}-${to}` : from ? `${from}-` : '',
          otherSpecialties:
            openPosition?.otherSpecialties?.map((e: OtherSpecialty) => ({
              label: OtherSpecialtyLabel[e],
              value: e,
            })) || [],
        })
      : {
          timeRange: '',
          primarySpecialty: null,
          otherSpecialties: [],
          title: null,
          description: null,
        };
    setState(defaultValue);
  }, [openPosition, from, to]);

  const onChange = (field: string) => (value: string | { label: string; value: string }[]) => {
    setState((prev: State) => ({ ...prev, [field]: value }));
  };

  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const save = async () => {
    const [from, to] = state.timeRange?.split('-') || [];

    await staffSlotContext.setWorkingTime(id, from || undefined, to || undefined);
    await staffSlotContext.editOpenPosition(
      id,
      state.title || undefined,
      state.primarySpecialty || undefined,
      (state.otherSpecialties || [])?.map((e: Option) => (e as Option).value) || [],
      state.description || undefined
    );
    staffSlotContext.closeOpenPositionDialog();
    handleClickOpen();
  };

  return (
    <>
      {staffSlotContext.openPositionDialog && (
        <RouterlessModal onClose={staffSlotContext.closeOpenPositionDialog} title={<Box>Edit Empty Slot</Box>}>
          <Box className={classes.form}>
            <Typography gutterBottom>Time slot</Typography>
            <TimeRangeInput
              inputRef={null}
              className={classes.textInput}
              defaultValue={state?.timeRange || ''}
              onInputChange={async (from: string | null, to: string | null, isValid: boolean, isCompleted: boolean) => {
                if (isValid) {
                  onChange('timeRange')(to ? `${from}-${to}` : `${from}-`);
                }
              }}
            />
            <TitleSelectInput value={state?.title} onChange={onChange('title')} />
            <PrimarySpecialtySelectInput
              value={state?.primarySpecialty}
              getOptionValue={(option: { label: string; value: string }) => option.value}
              onChange={onChange('primarySpecialty')}
            />
            <OtherSpecialtiesSelectInput
              placeholder={'Select more Specialties'}
              value={state?.otherSpecialties}
              onChange={onChange('otherSpecialties')}
            />
            <DescriptionInput
              /* @ts-ignore */
              value={state?.description}
              onChange={onChange('description')}
              placeholder={'Enter some description'}
            />
          </Box>
          <Box>
            <Button onClick={save} variant={'contained'} color={'primary'}>
              Save
            </Button>
          </Box>
        </RouterlessModal>
      )}
      {open && (
        <OpenPositionNotification
          shiftId={id}
          open={open}
          handleClickOpen={handleClickOpen}
          handleClickClose={handleClickClose}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    form: {
      minWidth: 250,
    },
    textInput: {
      background: 'rgb(0,12,63)',
      borderWidth: '0.125rem',
      borderStyle: 'solid',
      borderColor: 'rgb(47,55,90)',
      borderRadius: '0.125rem',
      color: 'rgb(230,236,239)',
      padding: 'calc(1rem * 0.75 - 0.125rem)',
      transform: '150ms all ease-in-out',
      fontSize: '1rem',
      lineHeight: 'calc(1.3 * 0.75rem)',
      minWidth: '100%',
      '&:focus': {
        color: 'rgba(230,236,239,0.5)',
        borderColor: 'rgb(0, 167, 247)',
        outline: 'none',
      },
      marginBottom: theme.spacing(2),
    },
  })
);

export default OpenPositionForm;
