import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { setHoldProcedure } from '../../../../graph/patients';

const withHoldProcedure = Component => props =>
  (
    <Mutation mutation={setHoldProcedure}>
      {setHoldProcedure => <Component {...props} setHoldProcedure={setHoldProcedure} />}
    </Mutation>
  );

export default withHoldProcedure;
