import React, { useEffect, useState } from 'react';
import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  TextField,
} from '@material-ui/core';
import { SelectAnswer } from '../../types/Answer';
import { SelectQuestion } from '../../types/Question';
import { useSave } from '../../components/pages/kiosk/charting/SaveProvider';
import { useSlideIn } from '../Form';
import ReactHtmlParser from 'react-html-parser';
import omit from 'lodash/omit';

type SelectInputProps = SelectQuestion['props'] & {
  name?: string;
  answer?: SelectAnswer;
  onChange?: (newAnswer: SelectAnswer) => void;
};

// TODO This component throws the controlled vs uncontrolled component error. See if you can fix it.

const SelectInput = ({ name, label, optional, options, answer, onChange }: SelectInputProps) => {
  const classes = useStyles();
  const [invalid, setInvalid] = useState(false);
  const [ifYesLabel, setIfYesLabel] = useState(answer?.ifYes|| '');

  const { validationRequest } = useSave();
  const slideIn = useSlideIn();

  useEffect(() => {
    if (optional) {
      return;
    }

    if (validationRequest) {
      if (!answer) {
        setInvalid(true);
        slideIn();
      }
    }
  }, [validationRequest, answer, optional, slideIn]);

  const handleChildChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLabel = event.target.value;
    setIfYesLabel(newLabel); // Update the label of ifYes
    const updatedAnswer = {
      ...answer,
      ifYes: newLabel
    };
    onChange?.(updatedAnswer);
  };

  return (
    <FormControl fullWidth variant="filled" className={classes.formControl} error={invalid}>
      <InputLabel htmlFor="age-native-simple">{label ? ReactHtmlParser(label) : 'Select'}</InputLabel>
      <Select
        value={answer?.label || null}
        onChange={(event, newValue) => {
          const selectedOption = options.find(e => e?.label === event?.target?.value) || null;
          onChange?.(omit(selectedOption, [ 'child']));
          setInvalid(false);
        }}
      >
        {options.map(option => (
          <MenuItem key={option.name} value={option.label}>
            {ReactHtmlParser(option.label)}
          </MenuItem>
        ))}
      </Select>
      {answer?.ifYes && (
        <TextField
          label="Other"
          value={ifYesLabel}
          onChange={handleChildChange}
          variant="filled"
        />
      )}
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

export default SelectInput;
