import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import React from 'react';
import Spinner from '../se/components/Spinner';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';

const ModalWithActions = ({
  title,
  handleCancel,
  open,
  confirmActionLabel,
  handleConfirmAction,
  busy = false,
  size = 'sm',
  children,
  disabled = false,
}) => (
  <div>
    {open && (
      <Dialog
        fullWidth={true}
        maxWidth={size}
        open={open}
        onClose={handleCancel}
        aria-labelledby="form-dialog-title"
        onClick={e => e.stopPropagation()}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">{title}</Typography>
            <IconButton onClick={handleCancel}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button disabled={busy || disabled} onClick={handleConfirmAction} color="primary" variant="contained">
            {confirmActionLabel}
          </Button>
          {busy && (
            <Spinner
              style={{
                verticalAlign: 'middle',
                marginLeft: '0.5rem',
              }}
            />
          )}
        </DialogActions>
      </Dialog>
    )}
  </div>
);

export default ModalWithActions;
