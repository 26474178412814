import React, { Fragment } from 'react';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import scheme from '../../../graph/feedback';
import columns from './columns';
import styled from 'styled-components';
import Filters from '../../pages/analytics/Filters';
import FeedbackAnalytics from './FeedbackAnalytics';
import responsive from '../../../se/utilities/responsive';
import TitleAction from '../../../se/components/TitleAction';
import { DefaultTitle } from '../../../se/components/entity/EntityRouter';
import FeedbackMobileListItem from './FeedbackMobileListItem';
import { NamedRange } from '../../core/DatePicker';
import pick from 'lodash/fp/pick';

const Row = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 2em;
  align-items: end;
  margin-bottom: 2em;

  ${responsive.md.andSmaller`
    grid-template-columns: 1fr;
    margin-top: 0;
    margin-bottom: 1rem;
  `};
`;

const Header = ({ value, ...rest }) => (
  <Row>
    <Filters value={value} {...rest} hideProcedureTypeSelectInput={true} />
  </Row>
);

const Title = () => (
  <Fragment>
    <DefaultTitle>Feedback</DefaultTitle>
  </Fragment>
);

const Empty = {
  title: 'There is no feedback at the moment',
  hint: 'Patient feedback will show up here if they provide one after their procedure',
  illustration: theme => theme.illustrations.users,
};

export default GraphQLCRUD({
  entityName: 'Feedback',
  scheme: { ...scheme, list: scheme.listWPhysician },
  List: {
    tableKey: 'Feedback',
    columns: columns,
    MobileItemComponent: FeedbackMobileListItem,
    TitleAndActions: () => <TitleAction Title={Title} />,
    Title: () => <div />,
    FilterComponent: Header,
    defaultFilterValues: {
      dateRange: NamedRange.lastSevenDays(),
    },
    pickFilter: pick(['name', 'dateRange', 'physician', 'procedureStatus', 'procedureType']),
    AdditionalBlock: FeedbackAnalytics,
    Empty,
  },
});
