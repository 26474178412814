import React, { ReactNode, useEffect, useMemo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Button,
  Checkbox,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  useTheme,
} from '@material-ui/core';
import { CirculationCheckTableAnswer } from '../../../../types/Answer';
import produce from 'immer';
import { alpha, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { RadioButtonUncheckedTwoTone } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useSave } from './SaveProvider';
import { useSlideIn } from '../../../../form/Form';

interface CirculationCheckTableProps {
  value?: CirculationCheckTableAnswer;
  onChange: (value: CirculationCheckTableAnswer) => void;
}

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    root: {
      '&:nth-of-type(odd)': {
        // backgroundColor: theme.palette.action.hover,
      },
      '&:last-of-type': {
        // backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      borderRight: `2px solid ${theme.palette.divider}`,
      '&:last-of-type': {
        borderRight: 'none',
      },
    },
    body: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderRight: `2px solid ${theme.palette.divider}`,
      borderBottom: 'none',
      '&:first-of-type': {
        // borderRight: 0,
      },
      '&:last-of-type': {
        borderRight: 'none',
      },
    },
  })
)(TableCell);

const CirculationCheckTable = ({ value: answer, onChange }: CirculationCheckTableProps) => {
  const { validationRequest } = useSave();
  const classes = useStyles();
  const slideIn = useSlideIn();

  const value = useMemo(
    () =>
      answer || {
        in5_1: {},
        in5_2: {},
        in5_3: {},
        in15_1: {},
        in15_2: {},
        in30_1: {},
        in30_2: {},
        in60_1: {},
        in60_2: {},
      },
    [answer]
  );

  useEffect(() => {
    if (validationRequest) {
      if (!value) {
        slideIn();
      }
    }
  }, [validationRequest, value, slideIn]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <StyledTableRow className={classes.mainRow}>
            <StyledTableCell>Circulation Check</StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>5</StyledTableCell>
            <StyledTableCell>5</StyledTableCell>
            <StyledTableCell>5</StyledTableCell>
            <StyledTableCell>15</StyledTableCell>
            <StyledTableCell>15</StyledTableCell>
            <StyledTableCell>30</StyledTableCell>
            <StyledTableCell>30</StyledTableCell>
            <StyledTableCell>60</StyledTableCell>
            <StyledTableCell>60</StyledTableCell>
          </StyledTableRow>
        </TableHead>

        <TableBody>
          <CirculationCheckTableRow
            title="Pulse"
            validationRequest={validationRequest}
            optionLabels={['Strong', 'Normal', 'Weak', 'Absent', 'NA']}
            optionValues={['3+', '2+', '1+', '0', 'NA']}
            value={{
              in5_1:  value.in5_1.pulse,
              in5_2:  value.in5_2.pulse,
              in5_3:  value.in5_3.pulse,
              in15_1: value.in15_1.pulse,
              in15_2: value.in15_2.pulse,
              in30_1: value.in30_1.pulse,
              in30_2: value.in30_2.pulse,
              in60_1: value.in60_1.pulse,
              in60_2: value.in60_2.pulse,
            }}
            onChange={v =>
              onChange(
                produce(value, draft => {
                  draft.in5_1.pulse = v.in5_1;
                  draft.in5_2.pulse = v.in5_2;
                  draft.in5_3.pulse = v.in5_3;
                  draft.in15_1.pulse = v.in15_1;
                  draft.in15_2.pulse = v.in15_2;
                  draft.in30_1.pulse = v.in30_1;
                  draft.in30_2.pulse = v.in30_2;
                  draft.in60_1.pulse = v.in60_1;
                  draft.in60_2.pulse = v.in60_2;
                })
              )
            }
          />
          <CapRefillAspect
            title="Cap Refill"
            validationRequest={validationRequest}
            optionLabels={['<3 seconds', '>3 seconds']}
            optionValues={['2+', '1+']}
            value={{
              in5_1:  value.in5_1.capRefill,
              in5_2:  value.in5_2.capRefill,
              in5_3:  value.in5_3.capRefill,
              in15_1: value.in15_1.capRefill,
              in15_2: value.in15_2.capRefill,
              in30_1: value.in30_1.capRefill,
              in30_2: value.in30_2.capRefill,
              in60_1: value.in60_1.capRefill,
              in60_2: value.in60_2.capRefill,
            }}
            onChange={v =>
              onChange(
                produce(value, draft => {
                  draft.in5_1.capRefill = v.in5_1;
                  draft.in5_2.capRefill = v.in5_2;
                  draft.in5_3.capRefill = v.in5_3;
                  draft.in15_1.capRefill = v.in15_1;
                  draft.in15_2.capRefill = v.in15_2;
                  draft.in30_1.capRefill = v.in30_1;
                  draft.in30_2.capRefill = v.in30_2;
                  draft.in60_1.capRefill = v.in60_1;
                  draft.in60_2.capRefill = v.in60_2;
                })
              )
            }
          />
          <ColorAspect
            title={'Color'}
            validationRequest={validationRequest}
            optionLabels={['Pink', 'Pale', 'Blue']}
            optionValues={['pink', 'pale', 'blue']}
            value={{
              in5_1:  value.in5_1.color,
              in5_2:  value.in5_2.color,
              in5_3:  value.in5_3.color,
              in15_1: value.in15_1.color,
              in15_2: value.in15_2.color,
              in30_1: value.in30_1.color,
              in30_2: value.in30_2.color,
              in60_1: value.in60_1.color,
              in60_2: value.in60_2.color,
            }}
            onChange={v =>
              onChange(
                produce(value, draft => {
                  draft.in5_1.color = v.in5_1;
                  draft.in5_2.color = v.in5_2;
                  draft.in5_3.color = v.in5_3;
                  draft.in15_1.color = v.in15_1;
                  draft.in15_2.color = v.in15_2;
                  draft.in30_1.color = v.in30_1;
                  draft.in30_2.color = v.in30_2;
                  draft.in60_1.color = v.in60_1;
                  draft.in60_2.color = v.in60_2;
                })
              )
            }
          />
          <TemperatureAspect
            title={'Temperature'}
            validationRequest={validationRequest}
            optionLabels={['Warm', 'Cool']}
            optionValues={['warm', 'cool']}
            value={{
              in5_1:  value.in5_1.temperature,
              in5_2:  value.in5_2.temperature,
              in5_3:  value.in5_3.temperature,
              in15_1: value.in15_1.temperature,
              in15_2: value.in15_2.temperature,
              in30_1: value.in30_1.temperature,
              in30_2: value.in30_2.temperature,
              in60_1: value.in60_1.temperature,
              in60_2: value.in60_2.temperature,
            }}
            onChange={v =>
              onChange(
                produce(value, draft => {
                  draft.in5_1.temperature = v.in5_1;
                  draft.in5_2.temperature = v.in5_2;
                  draft.in5_3.temperature = v.in5_3;
                  draft.in15_1.temperature = v.in15_1;
                  draft.in15_2.temperature = v.in15_2;
                  draft.in30_1.temperature = v.in30_1;
                  draft.in30_2.temperature = v.in30_2;
                  draft.in60_1.temperature = v.in60_1;
                  draft.in60_2.temperature = v.in60_2;
                })
              )
            }
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles(theme => ({
  mainRow: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}));

export default CirculationCheckTable;

type CirculationCheckEnum = '3+' | '2+' | '1+' | '0' | 'NA';

interface CirculationCheckAspectValue {
  in5_1?: CirculationCheckEnum;
  in5_2?: CirculationCheckEnum;
  in5_3?: CirculationCheckEnum;
  in15_1?: CirculationCheckEnum;
  in15_2?: CirculationCheckEnum;
  in30_1?: CirculationCheckEnum;
  in30_2?: CirculationCheckEnum;
  in60_1?: CirculationCheckEnum;
  in60_2?: CirculationCheckEnum;
}

interface CirculationCheckAspectProps {
  validationRequest: number;
  title: string;
  optionLabels: string[];
  optionValues: CirculationCheckEnum[];
  value: CirculationCheckAspectValue;
  onChange: (value: CirculationCheckAspectValue) => void;
}

const columns = ['in5_1', 'in5_2', 'in5_3', 'in15_1', 'in15_2', 'in30_1', 'in30_2', 'in60_1', 'in60_2'];

const CirculationCheckTableRow = ({
  validationRequest,
  title,
  optionLabels,
  optionValues,
  value,
  onChange,
}: CirculationCheckAspectProps) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      {optionLabels.map((_, index) => (
        <StyledTableRow className={clsx({ [classes.mainRow]: index + 1 === optionLabels.length })}>
          {index === 0 && (
            <StyledTableCell
              padding="none"
              rowSpan={optionLabels.length}
              style={{
                color: 'inherit',
              }}
            >
              {title}
            </StyledTableCell>
          )}
          <StyledTableCell padding="none">{optionLabels?.[index] || ''}</StyledTableCell>

          {columns.map(column => (
            <Selection
              selected={value[column] === optionValues[index]}
              onClick={() => {
                onChange(
                  produce(value, draft => {
                    draft[column] = draft[column] === optionValues[index] ? undefined : optionValues[index];
                  })
                );
              }}
            >
              {optionValues[index]}
            </Selection>
          ))}
        </StyledTableRow>
      ))}
    </>
  );
};

type CapRefillEnum = '2+' | '1+';

interface CapRefillAspectValue {
  in5_1?: CapRefillEnum;
  in5_2?: CapRefillEnum;
  in5_3?: CapRefillEnum;
  in15_1?: CapRefillEnum;
  in15_2?: CapRefillEnum;
  in30_1?: CapRefillEnum;
  in30_2?: CapRefillEnum;
  in60_1?: CapRefillEnum;
  in60_2?: CapRefillEnum;
}

interface CapRefillAspectProps {
  validationRequest: number;
  title: string;
  optionLabels: string[];
  optionValues: CapRefillEnum[];
  value: CapRefillAspectValue;
  onChange: (value: CapRefillAspectValue) => void;
}

const CapRefillAspect = ({
  title,
  optionLabels,
  optionValues,
  value,
  onChange,
  validationRequest,
}: CapRefillAspectProps) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      {optionLabels.map((_, index) => (
        <StyledTableRow className={clsx({ [classes.mainRow]: index + 1 === optionLabels.length })}>
          {index === 0 && (
            <StyledTableCell
              padding="none"
              rowSpan={optionLabels.length}
              style={{
                color: 'inherit',
              }}
            >
              {title}
            </StyledTableCell>
          )}
          <StyledTableCell padding="none">{optionLabels?.[index] || ''}</StyledTableCell>

          {columns.map(column => (
            <Selection
              selected={value[column] === optionValues[index]}
              onClick={() => {
                onChange(
                  produce(value, draft => {
                    draft[column] = draft[column] === optionValues[index] ? undefined : optionValues[index];
                  })
                );
              }}
            >
              {optionValues[index]}
            </Selection>
          ))}
        </StyledTableRow>
      ))}
    </>
  );
};

type TemperatureEnum = 'warm' | 'cool';

interface TemperatureAspectValue {
  in5_1?: TemperatureEnum;
  in5_2?: TemperatureEnum;
  in5_3?: TemperatureEnum;
  in15_1?: TemperatureEnum;
  in15_2?: TemperatureEnum;
  in30_1?: TemperatureEnum;
  in30_2?: TemperatureEnum;
  in60_1?: TemperatureEnum;
  in60_2?: TemperatureEnum;
}

interface TemperatureAspectProps {
  validationRequest: number;
  title: string;
  optionLabels: string[];
  optionValues: TemperatureEnum[];
  value: TemperatureAspectValue;
  onChange: (value: TemperatureAspectValue) => void;
}

const TemperatureAspect = ({
  title,
  optionLabels,
  optionValues,
  value,
  onChange,
  validationRequest,
}: TemperatureAspectProps) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      {optionLabels.map((_, index) => (
        <StyledTableRow className={clsx({ [classes.mainRow]: index + 1 === optionLabels.length })}>
          {index === 0 && (
            <StyledTableCell
              padding="none"
              rowSpan={optionLabels.length}
              style={{
                color: 'inherit',
              }}
            >
              {title}
            </StyledTableCell>
          )}
          <StyledTableCell padding="none">{optionLabels?.[index] || ''}</StyledTableCell>

          {columns.map(column => (
            <BlueCheckbox
              checked={value[column] === optionValues[index]}
              onChange={() =>
                onChange(
                  produce(value, draft => {
                    draft[column] = draft[column] === optionValues[index] ? undefined : optionValues[index];
                  })
                )
              }
            />
          ))}
        </StyledTableRow>
      ))}
    </>
  );
};

type ColorEnum = 'pink' | 'pale' | 'blue';

interface ColorAspectValue {
  in5_1?: ColorEnum;
  in5_2?: ColorEnum;
  in5_3?: ColorEnum;
  in15_1?: ColorEnum;
  in15_2?: ColorEnum;
  in30_1?: ColorEnum;
  in30_2?: ColorEnum;
  in60_1?: ColorEnum;
  in60_2?: ColorEnum;
}

interface ColorAspectProps {
  validationRequest: number;
  title: string;
  optionLabels: string[];
  optionValues: ColorEnum[];
  value: ColorAspectValue;
  onChange: (value: ColorAspectValue) => void;
}

const ColorAspect = ({ title, optionLabels, optionValues, value, onChange, validationRequest }: ColorAspectProps) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      {optionLabels.map((_, index) => (
        <StyledTableRow className={clsx({ [classes.mainRow]: index + 1 === optionLabels.length })}>
          {index === 0 && (
            <StyledTableCell
              padding="none"
              rowSpan={optionLabels.length}
              style={{
                color: 'inherit',
              }}
            >
              {title}
            </StyledTableCell>
          )}
          <StyledTableCell padding="none">{optionLabels?.[index] || ''}</StyledTableCell>

          {columns.map(column => (
            <BlueCheckbox
              checked={value[column] === optionValues[index]}
              onChange={() =>
                onChange(
                  produce(value, draft => {
                    draft[column] = draft[column] === optionValues[index] ? undefined : optionValues[index];
                  })
                )
              }
            />
          ))}
        </StyledTableRow>
      ))}
    </>
  );
};

const BlueCheckbox = withStyles({
  root: {
    color: 'rgba(0, 0, 0, 0.54)',
    '&$checked': {
      color: 'rgb(25, 118, 210)',
    },
  },
  checked: {},
})(props => (
  <StyledTableCell style={{ textAlign: 'center' }}>
    <Checkbox
      icon={<RadioButtonUncheckedTwoTone color="primary" />}
      checkedIcon={<CheckCircleIcon color="primary" />}
      {...props}
      style={{ padding: 0 }}
    />
  </StyledTableCell>
));

interface SelectionProps {
  selected: boolean;
  onClick: () => void;
  children: ReactNode;
}

const Selection = ({ selected, onClick, children }: SelectionProps) => (
  <StyledTableCell>
    <Button
      disableElevation
      variant={selected ? 'contained' : 'text'}
      color={selected ? 'primary' : 'default'}
      size="small"
      onClick={onClick}
    >
      {children}
    </Button>
  </StyledTableCell>
);
