import React, { Fragment } from 'react';
import { Answer, InnerField, MainQuestion, Question } from './Containers';
import get from 'lodash/get';

export const calculateBMI = ({ weightLb, heightFt, heightIn }) => {
  const weightInPounds = parseInt(weightLb, 10);
  const heightInInches = 12 * parseInt(heightFt, 10) + parseInt(heightIn, 10);
  const bodyMassIndex = (703 * weightInPounds) / heightInInches / heightInInches;
  return isFinite(bodyMassIndex) ? bodyMassIndex.toFixed(0) : '-';
};

export default ({ value }) => {
  const { heightFt, heightIn, weightLb } = get(value, 'bodyMassIndex', {});
  return (
    <Fragment>
      <div>
        <MainQuestion>Body Mass Index</MainQuestion>
        {heightFt && heightIn && (
          <InnerField>
            <Question>Height</Question>
            <Answer>{`${heightFt}'${heightIn}"`}</Answer>
          </InnerField>
        )}
        {weightLb && (
          <InnerField>
            <Question>Weight</Question>
            <Answer>{`${weightLb} lb`}</Answer>
          </InnerField>
        )}
        <InnerField>
          <Question>Body Mass Index</Question>
          <Answer>{calculateBMI({ heightFt, heightIn, weightLb })}</Answer>
        </InnerField>
      </div>
    </Fragment>
  );
};
