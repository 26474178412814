import React, { Fragment } from 'react';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import RoomInput from '../../inputs/room/RoomInput';
import RoomView from './views/RoomView';
import scheme from '../../../graph/rooms';
import { baseColumns, nameColumn } from './columns';
import RoomMobileListItem from './views/RoomMobileListItem';

const RoomAndGateways = props => (
  <Fragment>
    <RoomView {...props} />
  </Fragment>
);

const Empty = { illustration: theme => theme.illustrations.rooms };

export default GraphQLCRUD({
  entityName: 'Room',
  scheme,
  List: {
    tableKey: 'Rooms',
    columns: [nameColumn, ...baseColumns],
    MobileItemComponent: RoomMobileListItem,
    Empty,
  },
  Create: { Input: RoomInput, Empty },
  Show: { View: RoomAndGateways, Empty, columns: [...baseColumns] },
  Edit: { Input: RoomInput, Empty },
});
