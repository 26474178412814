import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import config from '../../../config';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 2em 1em;
  background: ${props =>
    props.error ? props.theme.warningColor.alpha(0.4).string() : props.theme.successColor.alpha(0.6).string()};
`;

export default ({ code }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const verifyCode = useCallback(async () => {
    if (!code) {
      return;
    }

    const response = await fetch(`${config.apiURL}/identity/confirmation?code=${encodeURIComponent(code)}`, {
      method: 'GET',
    });

    if (response.status !== 200) {
      setError(true);
    } else {
      setSuccess(true);
    }

    return null;
  }, [setError, setSuccess, code]);

  useEffect(() => {
    verifyCode();
  }, [verifyCode]);

  return error || success ? (
    <Container error={error}>
      {success && <label style={{ color: '#000000c2' }}>Account reactivated. Please Login to proceed.</label>}
      {error && !success && <label>Account reactivated failed. Try to login again.</label>}
    </Container>
  ) : null;
};
