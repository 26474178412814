import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { camelcase, titlecase } from 'stringcase';
import { compose, mapProps, withProps } from 'recompose';

import { isFunction, isObject, isString } from '../utilities/check';
import CollectionRouter from './collection/CollectionRouter';
import CollectionList from './collection/CollectionList';
import EntityRouter from './entity/EntityRouter';
import EntityCreate from './entity/EntityCreate';
import EntityEdit from './entity/EntityEdit';
import EntityEmptyState from './entity-states/EntityEmptyState';
import withFilterQueryString from '../hocs/withFilterQueryString';
import { withTheme } from '@material-ui/core';
import useHasAccessRight from '../../hooks/useHasAccessRight';
import { useMemo } from 'react';

export const entityStatePropTypes = {
  title: PropTypes.string,
  illustration: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  illustrationLight: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  icon: PropTypes.string,
  hint: PropTypes.string,
};

export const commonEntityStatePropTypes = [PropTypes.shape(entityStatePropTypes), PropTypes.func];

export const statePropTypes = {
  Loading: PropTypes.oneOfType(commonEntityStatePropTypes),
  Error: PropTypes.oneOfType(commonEntityStatePropTypes),
  Empty: PropTypes.oneOfType(commonEntityStatePropTypes),
};

export const propTypes = {
  entityName: PropTypes.string.isRequired,
  List: PropTypes.shape({
    itemsProvider: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    idProvider: PropTypes.func,
    View: PropTypes.func,
    mapListProps: PropTypes.func,
    ...statePropTypes,
  }).isRequired,
  Create: PropTypes.shape({
    Input: PropTypes.func.isRequired,
    withHandleCreate: PropTypes.func.isRequired,
  }),
  Show: PropTypes.shape({
    itemProvider: PropTypes.func.isRequired,
    titleProvider: PropTypes.func.isRequired,
    View: PropTypes.func.isRequired,
    Title: PropTypes.func,
    Actions: PropTypes.func,
    ...statePropTypes,
    hiddenEditMobile: PropTypes.bool,
    entityShowBackButtonTitle: PropTypes.string,
  }),
  Edit: PropTypes.shape({
    Input: PropTypes.func.isRequired,
    withHandleUpdate: PropTypes.func.isRequired,
    withHandleRemove: PropTypes.func,
    mapEditItemProps: PropTypes.func,
    ...statePropTypes,
  }),
};

const Impl = ({ higherOrderProps, ...props }) => {
  const hasAccessRight = useHasAccessRight();

  const Component = useMemo(() => {
    const { entityName, idType, List, Create, Show, Edit, baseRouteUrlAffix, additionalRoutes, searchSource } =
      higherOrderProps;
    const entityNameSingle = camelcase(entityName);
    const entityNamePlural = camelcase(pluralize(entityName));

    const accessRightEntity = entityName.toLowerCase().replace(/\s+/g, '');

    PropTypes.checkPropTypes(propTypes, higherOrderProps, 'prop', 'CRUD');

    return withProps({
      idType,
      baseRouteUrlAffix,
      additionalRoutes,
      List:
        hasAccessRight(accessRightEntity + '.list') && List && List.itemsProvider
          ? compose(
              withFilterQueryString(List.defaultFilterValues || {}, List.FilterPrefix),
              List.itemsProvider,
              withProps({
                ...List,
                searchSource,
                entityName,
                entityNameSingle,
                entityNamePlural,
                getList: data => data[entityNamePlural],
                columns: List.columns,
                containsSeparator: List.containsSeparator,
                useColumnSelection: List.useColumnSelection,
                useCSVExport: List.useCSVExport,
                tableKey: List.tableKey,
                hideColumns: List.hideColumns,
                idProvider: List.idProvider,
                createActionTitle: Create ? Create.actionTitle : undefined,
                FilterComponent: List.FilterComponent,
                FooterComponent: List.FooterComponent,
                MobileItemComponent: List.MobileItemComponent,
                Empty: isFunction(List.Empty)
                  ? List.Empty
                  : withTheme(
                      mapProps(({ theme }) => ({
                        title: `There are no ${titlecase(entityNamePlural)} at the moment.`,
                        hint: `Create a ${titlecase(
                          entityNameSingle
                        )} to get started. You can always customize it later.`,
                        ...(isObject(List.Empty) ? List.Empty : {}),
                        illustration: isObject(List.Empty)
                          ? isFunction(List.Empty.illustration)
                            ? List.Empty.illustration(theme) || theme.illustrations.users
                            : isString(List.Empty.illustration)
                            ? theme.illustrations[List.Empty.illustration] || theme.illustrations.users
                            : List.Empty.illustration || theme.illustrations.users
                          : theme.illustrations.users,
                      }))(EntityEmptyState)
                    ),
              }),
              mapProps(props => (isFunction(List.mapListProps) ? { ...props, ...List.mapListProps(props) } : props)),
              ...(List.effects || [])
            )(CollectionList)
          : undefined,
      Create:
        hasAccessRight(accessRightEntity + '.create') && Create && Create.withHandleCreate && Create.Input
          ? compose(
              withProps({
                ...Create,
                entityName,
                entityNameSingle,
                entityNamePlural,
                Input: Create.Input,
                title: Create.actionTitleButton || Create.actionTitle,
              }),
              Create.withHandleCreate
            )(EntityCreate)
          : undefined,
      Show:
        hasAccessRight(accessRightEntity + '.show') && Show && Show.itemProvider && Show.View
          ? compose(
              withProps({
                ...Show,
                entityName,
                entityNameSingle,
                entityNamePlural,
                getValue: data => data[entityNameSingle],
                titleProvider: Show.titleProvider,
                View: Show.View,
                Title: Show.Title,
                Actions: Show.Actions,
                Empty: isFunction(Show.Empty)
                  ? Show.Empty
                  : withProps({
                      title: `${entityName} you are searching for doesn’t exist.`,
                      hint: `Somebody might've had deleted this ${entityNameSingle}. Make sure that requested ${entityNameSingle} exists.`,
                      ...(isObject(Show.Empty) ? Show.Empty : {}),
                    })(EntityEmptyState),
              }),
              Show.itemProvider,
              ...(Show.effects || [])
            )(EntityRouter)
          : undefined,
      Edit:
        hasAccessRight(accessRightEntity + '.edit') && Edit && Edit.withHandleUpdate && Edit.Input
          ? compose(
              withProps({
                ...Edit,
                entityNameSingle,
                entityNamePlural,
                Input: Edit.Input,
                Empty: isFunction(Edit.Empty)
                  ? Edit.Empty
                  : withProps({
                      title: `${entityName} you are searching for doesn’t exist.`,
                      hint: `Somebody might've had deleted this ${entityNameSingle}. Make sure that requested ${entityNameSingle} exists.`,
                      ...(isObject(Edit.Empty) ? Edit.Empty : {}),
                    })(EntityEmptyState),
              }),
              Edit.withHandleUpdate,
              Edit.withHandleRemove ? Edit.withHandleRemove : Component => Component,
              mapProps(props =>
                isFunction(Edit.mapEditItemProps) ? { ...props, ...Edit.mapEditItemProps(props) } : props
              )
            )(EntityEdit)
          : undefined,
    })(CollectionRouter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Component {...props} />;
};

const CRUD = higherOrderProps => props => <Impl higherOrderProps={higherOrderProps} {...props} />;

export default CRUD;
