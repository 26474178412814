import React, { Fragment } from 'react';
import { withProps } from 'recompose';
import { Redirect, Route } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { Switch } from 'react-router-dom';
import Page from '../../../../se/components/Page';
import Sidebar from '../../../../se/components/Sidebar';

import ChangePassword from '../ChangePassword';
import Header from '../admin/Header';
import { dark as darkTheme } from '../../../../theme';
import ThemeBasedCSS from '../../../ThemeBasedCSS';
import Footer from '../admin/Footer';
import { List, Show } from '../../../entities/hospitals/Hospitals';
import Issues from '../../../entities/issues/Issues';
import Bracelets from '../../../entities/bracelets/Bracelets';
import { Context as PatientNameFormatContext } from '../../../../contexts/PatientNameFormat';
import { withSession } from '../../../../state/Session';
import Training from '../../training/Training';
import { muiThemeDark } from '../../../../muiTheme';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { MainContainer } from '../../MainContainer';
import { OmniSearchProvider, OmniSearchSources } from '../../../OmniSearch';
import activeAndPastProceduresSource from '../../../../omnisearch/sources/hospital/activeAndPastProcedures';
import scheduledProceduresSource from '../../../../omnisearch/sources/hospital/scheduledProcedures';
import { DeviceHub, Domain, NotificationImportant, Router, Theaters } from '@material-ui/icons';
import GatewayRouting from '../../../entities/gatewayRouting/GatewayRouting';
import Gateways from '../../../entities/gateways/Gateways';
import { unpackSessionObject } from '../../unpackSessionObject';
import GraphQLCRUD from '../../../../se/components/GraphQLCRUD';
import hospitalScheme from '../../../../graph/hospitals';

const HospitalsCRUD = GraphQLCRUD({
  entityName: 'Hospital',
  scheme: hospitalScheme,
  List,
  Show,
});

const routes = [
  {
    to: '/hospitals',
    label: 'Hospitals',
    icon: <Domain />,
    component: HospitalsCRUD,
  },
  {
    to: '/issues',
    label: 'Issues',
    icon: <NotificationImportant />,
    component: Issues,
  },
  {
    to: '/routing',
    label: 'Gateway Routing',
    icon: <DeviceHub />,
    component: GatewayRouting,
  },
  {
    to: '/gateways',
    label: 'Gateways',
    icon: <Router />,
    component: Gateways,
  },
  {
    path: `/training`,
    to: `/training`,
    label: 'Video Tutorials',
    icon: <Theaters />,
    component: Training,
  },
];
const MonitorEngineerHeader = withProps({
  title: 'Monitor Engineer',
})(Header);

const AdminSidebar = withProps({
  Header: MonitorEngineerHeader,
  links: routes,
  Footer,
})(Sidebar);

const Homepage = ({ patientNameFormat }) => (
  <ThemeProvider theme={darkTheme}>
    <MUIThemeProvider theme={muiThemeDark}>
      <style>
        {`body {
          background: ${muiThemeDark.palette.background.default};
        }`}
      </style>
      <CssBaseline />
      <PatientNameFormatContext.Provider value={patientNameFormat}>
        <OmniSearchProvider>
          <OmniSearchSources sources={[activeAndPastProceduresSource, scheduledProceduresSource]} />
          <Fragment>
            <Page Sidebar={AdminSidebar}>
              <MainContainer switchControl>
                <Switch>
                  <Route exact path={'/'} render={() => <Redirect to={'/hospitals'} />} />
                  {routes.map(({ to, component }, i) => (
                    <Route key={i} path={to} component={component} />
                  ))}
                  <Route path={'/:organizationId/bracelets'} component={Bracelets} />
                  <Route path="/change-password" component={ChangePassword} />
                  <Route render={() => <Redirect to={'/hospitals'} />} />
                </Switch>
              </MainContainer>
            </Page>
            <ThemeBasedCSS />
          </Fragment>
        </OmniSearchProvider>
      </PatientNameFormatContext.Provider>
    </MUIThemeProvider>
  </ThemeProvider>
);

export default withSession(unpackSessionObject)(Homepage);
