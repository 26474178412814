import { useQuestionnaireValue } from '../../hooks';

const getcontent = (reducer, paths, n) => {
  const text = useQuestionnaireValue(reducer, ...paths);
  if (text == null) {
    if (paths[0][0] == 'allergies') {
      return 'NKA'
    }
    else if(paths[0][0] == 'medications'||paths[0][0]=='priorSurgeries') {
      return 'No'
    }
    else {
      return '';
    }
  } else if (text.length < n) {
    return text;
  } else {
    return 'SEE ADDENDUM';
  }
};

const additionalcontent = (reducer, paths, n) => {
  const text = useQuestionnaireValue(reducer, ...paths);
  if (text == null) {
    return 'No additional info';
  } else if (text.length >= n) {
    return text;
  } else {
    return 'No additional info';
  }
};

export { getcontent, additionalcontent };
