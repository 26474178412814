import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { Box, Chip, createStyles, makeStyles, MenuItem, MenuList, Popover, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import StaffMemberT from '../../../../../types/StaffMember';
import Spinner from '../../../../../se/components/Spinner';
import StaffMember from './StaffMember';
import { Subscription, SubscriptionResult } from '@apollo/react-components';
import { useApolloClient } from '@apollo/client';
import { useStaffSlotContext } from './StaffSlotContext';
import StaffShiftT from '../../../../../types/StaffShift';
import { Edit, RemoveCircleOutline } from '@material-ui/icons';
import OpenPositionForm from './OpenPositionForm';
import { Procedure } from '../../../../../types/Procedure';
import { useScope } from '../../../../../hooks/useScope';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import IconButton from '@material-ui/core/IconButton';
import OpenPositionNotification from './OpenPositionNotification';
import { StaffShiftStoreContext, useCreateStaffShiftStore } from './StaffShift';

interface StaffEmptySlotMenuItemProps {
  staffMember: StaffMemberT;
  toggleStaffMember: (staffMember: StaffMemberT) => (event: React.MouseEvent<HTMLElement>) => Promise<void>;
}

const StaffEmptySlotMenuItem: FC<StaffEmptySlotMenuItemProps> = ({ staffMember, toggleStaffMember }) => {
  const classes = useStyles();

  const staffShiftStore = useCreateStaffShiftStore();

  return (
    <MenuItem key={staffMember.id} onClick={toggleStaffMember(staffMember)}>
      <StaffShiftStoreContext.Provider value={staffShiftStore}>
        <StaffMember {...staffMember} isMenuListItem interactive className={classes.staffMember} />
      </StaffShiftStoreContext.Provider>
    </MenuItem>
  );
};

interface StaffSlotMenuProps extends StaffShiftT {
  procedure?: Procedure;
  children: (onClick: (event: React.MouseEvent<HTMLElement>) => void) => ReactElement<any, any> | null;
}

const StaffEmptySlotMenu: FC<StaffSlotMenuProps> = ({ id, from, to, procedure, openPosition, staff, children }) => {
  const classes = useStyles();

  const searchEl = React.useRef<HTMLInputElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (open) {
      searchEl.current?.focus();
    } else {
      setSearch('');
    }
  }, [open]);

  const [search, setSearch] = useState('');

  const handleSearchUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const staffSlotContext = useStaffSlotContext();
  const scope = useScope();
  const toggleStaffMember = (staffMember: StaffMemberT) => async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    await staffSlotContext.assign(id, staffMember?.staffId);

    handleClose();
  };

  const removeSlot = async () => {
    await staffSlotContext.remove(id, scope?.hospital.id, scope?.hospital.id);
    handleClose();
  };

  const startEditingSlot = () => {
    staffSlotContext.openOpenPositionDialog();
    handleClose();
  };

  const [staffMembers, setStaffMembers] = useState<StaffMemberT[] | null>(null);

  const filteredStaffMembers = useMemo(() => {
    const chunks = search.toLowerCase().split(/\s+/);
    return (staffMembers || []).filter((staffMember: StaffMemberT) => {
      const staffMemberName = staffMember.name.toLowerCase();
      return chunks.every(chunk => staffMemberName.indexOf(chunk) >= 0);
    });
  }, [search, staffMembers]);

  const apolloClient = useApolloClient();

  return (
    <>
      {children(handleButtonClick)}
      {open && (
        <Subscription
          client={apolloClient as any}
          subscription={staffSlotContext.staffMembers.list}
          variables={staffSlotContext.staffMembers.variables}
        >
          {({ data }: SubscriptionResult) => {
            if (data?.staffMembers) {
              setStaffMembers(data?.staffMembers);
            }
            return null;
          }}
        </Subscription>
      )}
      <OpenPositionForm id={id} from={from} to={to} openPosition={openPosition} />
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClick={e => e.stopPropagation()}
        onClose={handleClose}
        getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {staffMembers ? (
          <Box className={classes.root}>
            <TextField
              size="small"
              variant="outlined"
              value={search}
              onChange={handleSearchUpdate}
              inputRef={searchEl}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" className={classes.searchIcon} />,
                autoCapitalize: 'off',
                autoComplete: 'off',
                autoCorrect: 'off',
                classes: {
                  root: classes.inputRoot,
                  inputAdornedStart: classes.inputAdornedStart,
                },
              }}
              classes={{
                root: classes.textFieldRoot,
              }}
            />
            <MenuList onClick={e => e.stopPropagation()} className={classes.menuListStaff}>
              {filteredStaffMembers
                .filter(_ => !_.assignedShift)
                .map((staffMember: StaffMemberT) => (
                  <StaffEmptySlotMenuItem
                    key={staffMember?.id}
                    staffMember={staffMember}
                    toggleStaffMember={toggleStaffMember}
                  />
                ))}
            </MenuList>
            <MenuList onClick={e => e.stopPropagation()} className={classes.menuListActions}>
              <MenuItem key="button-edit" onClick={startEditingSlot}>
                <Chip
                  icon={<Edit className={classes.edit} />}
                  label={'Edit Slot'}
                  size="small"
                  className={classes.removeChip}
                />
              </MenuItem>
              <MenuItem key="button-rm" onClick={removeSlot}>
                <Chip
                  icon={<RemoveCircleOutline className={classes.remove} />}
                  label={'Remove Slot'}
                  size="small"
                  className={classes.removeChip}
                />
              </MenuItem>
              <MenuItem key="button-notify" onClick={() => setOpenModal(true)}>
                <Chip
                  icon={
                    <IconButton>
                      <NotificationsActiveIcon color={'primary'} />
                    </IconButton>
                  }
                  label={'Notify'}
                  size="small"
                />
              </MenuItem>
            </MenuList>
            {openModal && (
              <OpenPositionNotification
                shiftId={id}
                open={openModal}
                handleClickOpen={() => setOpenModal(true)}
                handleClickClose={() => setOpenModal(false)}
              />
            )}
          </Box>
        ) : (
          <div className={classes.spinnerContainer}>
            <Spinner className={classes.spinner} />
          </div>
        )}
      </Popover>
    </>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      maxHeight: '38rem',
      overflow: 'hidden',
    },
    menuListStaff: {
      maxHeight: '28rem',
      overflowY: 'scroll',
    },
    menuListActions: {
      borderTop: `1px solid rgba(127, 127, 127, 0.2)`,
      position: 'sticky',
      backgroundColor: 'inherit',
      bottom: 0,
      zIndex: 500,
    },
    textFieldRoot: {
      margin: '1rem',
      marginBottom: 0,
      padding: 0,
    },
    inputRoot: {
      width: '14rem',
      fontSize: '0.8125em',
      padding: '0.25em',
    },
    searchIcon: {
      marginLeft: '0.25em',
      marginRight: '0.25em',
    },
    inputAdornedStart: {
      paddingLeft: 0,
    },
    spinnerContainer: {
      padding: '1rem',
    },
    spinner: {},
    remove: {
      color: 'red',
    },
    edit: {
      color: theme.palette.primary.light,
    },
    staffMember: {
      flex: 1,
    },
    removeChip: {
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
  })
);

export default StaffEmptySlotMenu;
