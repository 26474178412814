import React from 'react';
import TextAnswerComponent from './TextAnswer';
import { TextAnswer } from '../../types/Answer';
import ReactHtmlParser from 'react-html-parser';

interface Props {
  label?: string;
  answer?: TextAnswer;
}

const TextInputAnswer = ({ label, answer }: Props) => (
  <TextAnswerComponent question={ReactHtmlParser(label)} answer={answer} />
);

export default TextInputAnswer;
