import React, { FC, useState } from 'react';
import { editPacuNote, editPreOpNote, editBlockNerveFollow } from '../../../graph/patients';
import { useMutation } from '@apollo/client';
import { Note, Patient } from '../../../types/Patient';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const BlockFollowsInput: FC<{
  open: boolean;
  handleClose: () => void;
  patient?: Patient;
  onSelect: (action: string) => void;
  isBlockNerve: boolean;
  onClick?: (note: Note) => void;
}> = ({ open, handleClose, patient, onClick, onSelect, isBlockNerve }) => {
  const [saveForPacu, setSaveForPacu] = useState<boolean>(!!patient?.blockNerveFollow);

  const [editPacu] = useMutation(editBlockNerveFollow);

  const onSave = async () => {
    onSelect(isBlockNerve ? 'undoBlockNerve' : 'blockNerve');
    handleClose();
  };

  const handleSaveForPacu = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSaveForPacu(checked);
    await editPacu({ variables: { id: patient?.id, blockFollow: checked } });
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
      fullWidth
      onClick={e => e.stopPropagation()}
    >
      <DialogTitle id="form-dialog-title">Follows to Pacu</DialogTitle>
      <DialogContent>
        <Box mt={4} display="flex" flexDirection="column">
          <FormControlLabel
            label="Block follows Patient"
            control={<Checkbox checked={saveForPacu} onChange={handleSaveForPacu} />}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button color="primary" onClick={onSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BlockFollowsInput;
