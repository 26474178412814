import React from 'react';
import { useMutation } from '@apollo/client';
import Notes from '../procedures/Notes';
import { deletePreopNote, postPreopNote } from '../../../graph/procedures';
import { ProcedureEvent } from '../../../types/ProcedureEvent';

interface ProcedureNotesProps {
  procedureId: number;
  procedureNotes: Array<ProcedureEvent>;
}

const ProcedureNotes = ({ procedureId, procedureNotes }: ProcedureNotesProps) => {
  const [create] = useMutation(postPreopNote);
  const [remove] = useMutation(deletePreopNote);

  const createNote = async (note: string) => {
    await create({
      variables: {
        id: procedureId,
        preopNote: note,
      },
    });
  };

  const deleteNote = async (noteId: number) => {
    await remove({
      variables: {
        id: noteId,
      },
    });
  };

  return <Notes notes={procedureNotes} createNote={createNote} deleteNote={deleteNote} />;
};

export default ProcedureNotes;
