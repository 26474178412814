import { defaultTo } from 'lodash';
import React, { Fragment, useCallback, useMemo } from 'react';
import { createEditor } from 'slate';
import get from 'lodash/get';
import { Editable, Slate, withReact } from 'slate-react';
import styled from 'styled-components';

import InputLeaf from './TemplateEdit';

const Separator = styled.div`
  margin: 1em auto;
  width: 95%;
  height: 1px;
  opacity: 0.6;
  border-top: 1px solid rgba(0, 12, 63, 0.15);
  border-bottom: none;
`;

const empty = [
  {
    type: 'paragraph',
    children: [
      {
        text: '',
      },
    ],
  },
];

const ParagraphSection = styled.div`
  font-size: 1em;
  line-height: 1.5;
  opacity: 0.65;
`;

const H1 = styled.h1`
  font-size: 2.5em;
  margin-top: 1em;
  margin-bottom: 1em;
  line-height: 1.4;
`;

const H2 = styled.h2`
  font-size: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
  line-height: 1.4;
`;

const Paragraph = styled.div`
  font-size: 1em;
  line-height: 1.5em;
  margin: 1em auto;
`;

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return (
        <ParagraphSection style={{ marginLeft: '2em' }}>
          <ol {...attributes}>{children}</ol>
        </ParagraphSection>
      );
    case 'heading-one':
      return <H1 {...attributes}>{children}</H1>;
    case 'heading-two':
      return <H2 {...attributes}>{children}</H2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'separator':
      return (
        <Fragment>
          {children}
          <Separator {...attributes} />
        </Fragment>
      );
    default:
      return <Paragraph {...attributes}>{children}</Paragraph>;
  }
};

export const extractTemplateVariable = node =>
  get(node, 'text', '')
    .replace(/[\s]/gi, '')
    .split('<')
    .map(str => str.replace(/[^\w]/gi, ''));

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.variable) {
    const [name, element] = extractTemplateVariable(get(children, 'props.leaf'));
    if (element && name) {
      children = <InputLeaf name={name} element={element} disabled={['arrivalDate'].includes(name)} />;
    }
  }

  if (leaf.bold) {
    children = <strong style={{ fontWeight: 500 }}>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em style={{ fontStyle: 'italic' }}>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const RichTextView = ({ value }) => {
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), []);

  return (
    <Slate editor={editor} value={defaultTo(value, empty)} onChange={() => {}}>
      <Editable readOnly renderElement={renderElement} renderLeaf={renderLeaf} />
    </Slate>
  );
};

export default RichTextView;
