import { Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React from 'react';
import { withProps } from 'recompose';
import icon from '../../../../assets/images/scan_bracelet.png';
import RouterLink from '../../../../se/components/RouterLink';
import ProtectedContent from '../../../../security/ProtectedContent';
import { ScheduleUploadConfirmation } from '../../../inputs/upload/ScheduleUploadConfirmation';
import UploadButton from '../../../inputs/upload/UploadButton';
import { UPLOAD_RESOURCE } from '../../../inputs/upload/Uploader';
import { AutomaticPatientTracking } from '../../../ModuleOptional';

const AutoTrackingHeader = () => (
  <Paper variant="outlined">
    <Box px={2} py={1.5} style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
      <Box style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
        <Box>
          <img src={icon} alt="" style={{ width: 40 }} />
        </Box>
        <Typography variant="body2">
          To enter the patient into the system, <br />
          place the bracelet on the scanning device.
        </Typography>
      </Box>
      <Box style={{ alignSelf: 'stretch', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Box flex={1}>
          <Divider orientation="vertical" />
        </Box>
        <Typography variant="caption" color="textSecondary">
          or
        </Typography>
        <Box flex={1}>
          <Divider orientation="vertical" />
        </Box>
      </Box>
      <Button variant="outlined" component={RouterLink} to="/patients/create">
        Register patient
      </Button>
    </Box>
  </Paper>
);

const ManualTrackingHeader = () => (
  <ProtectedContent
    accessRight="patient.create"
    content={
      <Button color="primary" variant="outlined" component={RouterLink} to={'/patients/create'}>
        Create Patient
      </Button>
    }
  />
);

const EnrollmentHeader = AutomaticPatientTracking(AutoTrackingHeader, ManualTrackingHeader);

export const PatientImport = withProps({
  resource: UPLOAD_RESOURCE.SCHEDULE,
  ButtonComponent: <Button startIcon={<CloudUploadIcon />}>Import Patients</Button>,
  UploadConfirmation: ScheduleUploadConfirmation,
})(UploadButton);

const ManualOptionalImport = AutomaticPatientTracking(null, PatientImport);

export default () => (
  <Box style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
    <ManualOptionalImport />
    <EnrollmentHeader />
  </Box>
);
