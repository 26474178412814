import { Query } from '@apollo/client/react/components';
import React from 'react';
import { list as getRooms } from '../../../graph/rooms';

export default Component => props =>
  (
    <Query query={getRooms}>
      {({ loading, error, data }) => <Component rooms={{ loading, error, data }} {...props} />}
    </Query>
  );
