import { compose, withProps } from 'recompose';
import SelectInput from '../../../se/components/inputs/SelectInput';
import { EmploymentLabel } from '../../../types/StaffMember';
import { withLabel } from '../../../se/components/Label';

const EmploymentSelectInput = compose(
  withLabel('Employment'),
  withProps({
    options: EmploymentLabel,
    placeholder: 'e.g., Full Time',
    required: false,
  })
)(SelectInput);

export default EmploymentSelectInput;
