import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { createStaticRanges, DateRangePicker } from 'react-date-range';
import { IsoFields, LocalDate, Month, Period } from '@js-joda/core';
import format from 'date-fns/format';
import omit from 'lodash/omit';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { breakpoint } from '../../se/utilities/responsive';
import { compact } from 'lodash';

const Container = styled.div`
  background: white;
  color: #000;
`;

const formatLocalDate = date => format(new Date(date.toString() + 'T00:00:00.000'), 'Do MMM ’YY');

export class Range {
  date;
  period;

  constructor(date, period) {
    this.date = date;
    this.period = period;
  }

  firstIncluded() {
    return this.date;
  }

  lastIncluded() {
    return this.date.plus(this.period).minusDays(1);
  }

  lastExcluded() {
    return this.date.plus(this.period);
  }

  equals(other) {
    return this.firstIncluded().equals(other.firstIncluded()) && this.lastExcluded().equals(other.lastExcluded());
  }

  toString(today = LocalDate.now()) {
    const namedRange = namedRanges(today).find(r => r.equals(this));

    if (namedRange) {
      return namedRange.name;
    }

    if (
      this.firstIncluded().dayOfMonth() === 1 &&
      this.lastExcluded().dayOfMonth() === 1 &&
      Period.between(this.firstIncluded(), this.lastExcluded()).equals(Period.ofMonths(1))
    ) {
      return format(this.firstIncluded().toString(), 'MMMM YYYY');
    }

    if (
      this.firstIncluded().dayOfMonth() === 1 &&
      this.firstIncluded().month() === Month.JANUARY &&
      this.lastExcluded().dayOfMonth() === 1 &&
      this.lastExcluded().month() === Month.JANUARY &&
      Period.between(this.firstIncluded(), this.lastExcluded()).equals(Period.ofYears(1))
    ) {
      return format(this.firstIncluded().toString(), 'YYYY');
    }

    return `${formatLocalDate(this.firstIncluded())} – ${formatLocalDate(this.lastIncluded())}`;
  }

  toJSON() {
    return {
      from: this.date.toJSON(),
      to: this.date.plus(this.period).toJSON(),
    };
  }
}

Range.of = function (date, period, today = LocalDate.now()) {
  const range = new Range(date, period);
  const namedRange = namedRanges(today).find(r => r.equals(range));
  return namedRange || range;
};

Range.between = function (from, to, today = LocalDate.now()) {
  return Range.of(from, Period.between(from, to), today);
};

export class NamedRange extends Range {
  name;

  constructor(date, period, name) {
    super(date, period);
    this.name = name;
  }
}

export const todayRange = (today = LocalDate.now()) => new NamedRange(today, Period.ofDays(1), 'Today');
export const tomorrowRange = (today = LocalDate.now()) =>
  new NamedRange(today.plusDays(1), Period.ofDays(1), 'Tomorrow');
export const nextSevenDaysRange = (today = LocalDate.now()) => new NamedRange(today, Period.ofWeeks(1), 'Next 7 Days');
export const lastSevenDaysRange = (today = LocalDate.now()) =>
  new NamedRange(today.plusDays(1).minusWeeks(1), Period.ofWeeks(1), 'Last 7 Days');
export const nextSevenDaysExcludingTodayRange = (today = LocalDate.now()) =>
  new NamedRange(today.plusDays(1), Period.ofWeeks(1), 'Next 7 Days');
export const lastSevenDaysExcludingTodayRange = (today = LocalDate.now()) =>
  new NamedRange(today.minusWeeks(1), Period.ofWeeks(1), 'Last 7 Days');
export const lastMonthRange = (today = LocalDate.now()) =>
  new NamedRange(today.withDayOfMonth(1).minusMonths(1), Period.ofMonths(1), 'Last Month');
export const restOfMonthRange = (today = LocalDate.now()) =>
  new NamedRange(today, Period.between(today, today.withDayOfMonth(1).plusMonths(1)), 'Rest of the Month');
export const next90DaysRange = (today = LocalDate.now()) => new NamedRange(today, Period.ofDays(90), 'Next 90 Days');
export const next90DaysExcludingTodayRange = (today = LocalDate.now()) =>
  new NamedRange(today.plusDays(1), Period.ofDays(90), 'Next 90 Days');
export const last90DaysRange = (today = LocalDate.now()) =>
  new NamedRange(today.minusDays(90), Period.ofDays(90), 'Last 90 Days');
export const last10AndNext90DaysRange = (today = LocalDate.now()) =>
  new NamedRange(today.minusDays(9), Period.ofDays(100), 'Last 10 and Next 90 days');
export const next30DaysRange = (today = LocalDate.now()) => new NamedRange(today, Period.ofDays(30), 'Next 30 Days');
export const next30DaysExcludingTodayRange = (today = LocalDate.now()) =>
  new NamedRange(today.plusDays(1), Period.ofDays(30), 'Next 30 Days');
export const last30DaysRange = (today = LocalDate.now()) =>
  new NamedRange(today.minusDays(30), Period.ofDays(30), 'Last 30 Days');
export const nextMonthRange = (today = LocalDate.now()) =>
  new NamedRange(today.withDayOfMonth(1).plusMonths(1), Period.ofMonths(1), 'Next Month');
export const restOfQuarterRange = (today = LocalDate.now()) =>
  new NamedRange(
    today,
    Period.between(today, today.with(IsoFields.DAY_OF_QUARTER, 1).plusMonths(3)),
    'Rest of the Quarter'
  );
export const restOfYearRange = (today = LocalDate.now()) =>
  new NamedRange(today, Period.between(today, today.withDayOfYear(1).plusYears(1)), 'Rest of the Year');
export const thisMonthRange = (today = LocalDate.now()) =>
  new NamedRange(today.withDayOfMonth(1), Period.ofMonths(1), 'This Month');
export const thisQuarterRange = (today = LocalDate.now()) =>
  new NamedRange(today.with(IsoFields.DAY_OF_QUARTER, 1), Period.ofMonths(3), 'This Quarter');
export const thisYearRange = (today = LocalDate.now()) =>
  new NamedRange(today.withDayOfYear(1), Period.ofYears(1), 'This Year');
export const lastYearRange = (today = LocalDate.now()) =>
  new NamedRange(today.withDayOfYear(1).minusYears(1), Period.ofYears(1), 'Last Year');
export const allYearRange = (today = LocalDate.now()) =>
  new NamedRange(today.withDayOfYear(1), Period.ofYears(1), 'This Year');

NamedRange.today = todayRange;
NamedRange.tomorrow = tomorrowRange;
NamedRange.nextSevenDays = nextSevenDaysRange;
NamedRange.lastSevenDays = lastSevenDaysRange;
NamedRange.nextSevenDaysRangeExcludingToday = nextSevenDaysExcludingTodayRange;
NamedRange.lastSevenDaysRangeExcludingToday = lastSevenDaysExcludingTodayRange;
NamedRange.lastMonth = lastMonthRange;
NamedRange.restOfMonth = restOfMonthRange;
NamedRange.next90Days = next90DaysRange;
NamedRange.next90DaysExcludingToday = next90DaysExcludingTodayRange;
NamedRange.last10AndNext90Days = last10AndNext90DaysRange;
NamedRange.next30Days = next30DaysRange;
NamedRange.next30DaysExcludingToday = next30DaysExcludingTodayRange;
NamedRange.last30Days = last30DaysRange;
NamedRange.nextMonth = nextMonthRange;
NamedRange.restOfQuarter = restOfQuarterRange;
NamedRange.restOfYear = restOfYearRange;
NamedRange.thisMonth = thisMonthRange;
NamedRange.thisQuarter = thisQuarterRange;
NamedRange.thisYear = thisYearRange;
NamedRange.lastYear = lastYearRange;
NamedRange.allYear = allYearRange;

export const namedRanges = (today = LocalDate.now()) => [
  todayRange(today),
  tomorrowRange(today),
  nextSevenDaysRange(today),
  lastSevenDaysRange(today),
  nextSevenDaysExcludingTodayRange(today),
  lastSevenDaysExcludingTodayRange(today),
  lastMonthRange(today),
  restOfMonthRange(today),
  next90DaysRange(today),
  next90DaysExcludingTodayRange(today),
  last90DaysRange(today),
  last10AndNext90DaysRange(today),
  next30DaysRange(today),
  next30DaysExcludingTodayRange(today),
  last30DaysRange(today),
  nextMonthRange(today),
  lastMonthRange(today),
  restOfQuarterRange(today),
  restOfYearRange(today),
  thisMonthRange(today),
  thisQuarterRange(today),
  thisYearRange(today),
  lastYearRange(today),
  allYearRange(today),
];

const ranges = (today = LocalDate.now()) => [
  todayRange(today),
  lastSevenDaysRange(today),
  lastMonthRange(today),
  thisMonthRange(today),
  thisQuarterRange(today),
  thisYearRange(today),
  lastYearRange(today),
];

const customRanges = [
  {
    label: 'Today',
    range: () => todayRange,
  },
  {
    label: 'Next 30 days',
    range: () => next30DaysRange,
  },
  {
    label: 'Last 30 days',
    range: () => last30DaysRange,
  },
  {
    label: 'Next 90 days',
    range: () => next90DaysRange,
  },
  {
    label: 'Last 90 days',
    range: () => last90DaysRange,
  },
  {
    label: 'This Year',
    range: () => thisYearRange,
  },
  {
    label: 'Last Year',
    range: () => lastYearRange,
  },
];

const futureRanges = (symmetric, today = LocalDate.now()) =>
  compact([
    todayRange(today),
    tomorrowRange(today),
    nextSevenDaysRange(today),
    restOfMonthRange(today),
    nextMonthRange(today),
    symmetric && last10AndNext90DaysRange(today),
    next90DaysRange(today),
    next30DaysRange(today),
    restOfQuarterRange(today),
    restOfYearRange(today),
    symmetric && allYearRange(today),
  ]);

function toPickerRange(range) {
  return {
    label: range.name,
    range: () => ({
      startDate: new Date(range.firstIncluded().toString() + 'T00:00:00.000'),
      endDate: new Date(range.lastIncluded().toString() + 'T00:00:00.000'),
      id: range.name.split(' ').join(''),
      quickFilter: true,
    }),
  };
}

const MIN_DATE = LocalDate.of(2017, Month.JANUARY, 1);

/**
 * Converts JavaScript Date to js-joda LocalDate.
 *
 * @param date JavaScript Date object
 * @returns {LocalDate} js-joda LocalDate object
 */
export const toLocalDate = date => LocalDate.of(date.getFullYear(), date.getMonth() + 1, date.getDate());

export function createRanges({ datePickerRanges, today = LocalDate.now(), future = false, symmetric = false }) {
  return createStaticRanges(
    (datePickerRanges ? datePickerRanges(today) : future ? futureRanges(symmetric, today) : ranges(today)).map(
      toPickerRange
    )
  );
}

const staticCustomRanges = createStaticRanges(customRanges);

const DatePicker = ({ customPicker, ...props }) => {
  const defaultToday = useMemo(() => LocalDate.now(), []);
  const defaultValue = useMemo(() => Range.of(defaultToday, Period.ofDays(1), defaultToday), [defaultToday]);
  const defaultOnChange = useMemo(() => () => {}, []);
  const defaultRanges = useMemo(() => createRanges({ today: defaultToday }), [defaultToday]);

  const today = props.today || defaultToday;
  const value = props.value || defaultValue;
  const onChange = props.onChange || defaultOnChange;
  const ranges = props.ranges || defaultRanges;

  const other = omit(props, ['today', 'value', 'onChange', 'ranges']);

  const range = useMemo(
    () => ({
      startDate: new Date(value.date.toString() + 'T00:00:00.000'),
      endDate: new Date(value.date.plus(value.period).minusDays(1).toString() + 'T00:00:00.000'),
      key: 'selection',
    }),
    [value.date, value.period]
  );

  const [, setSelectionInProgress] = useState(false);

  const handleChange = useCallback(
    range => {
      const startDate = toLocalDate(range.selection.startDate);
      const endDate = toLocalDate(range.selection.endDate).plusDays(1);
      if (range.selection.quickFilter) {
        setSelectionInProgress(false);
        onChange(Range.between(startDate, endDate, today), true);
      } else {
        setSelectionInProgress(wasInProgress => {
          onChange(Range.between(startDate, endDate, today), wasInProgress);
          return !wasInProgress;
        });
      }
    },
    [today, onChange]
  );

  const minDate = useMemo(() => new Date(MIN_DATE.toString() + 'T00:00:00.000'), []);
  const maxDate = useMemo(() => new Date(today.plusYears(1).toString() + 'T00:00:00.000'), [today]);

  const datePickerRanges = useMemo(() => [range], [range]);

  return (
    <Container {...other}>
      <DateRangePicker
        className="DateRangePickerOverride"
        showMonthAndYearPickers={window.innerWidth >= breakpoint.sm}
        style={window.innerWidth <= breakpoint.sm ? { display: 'none' } : undefined}
        ranges={datePickerRanges}
        staticRanges={customPicker ? staticCustomRanges : ranges}
        onChange={handleChange}
        minDate={minDate}
        maxDate={maxDate}
      />
    </Container>
  );
};

DatePicker.propTypes = {
  today: PropTypes.instanceOf(LocalDate),
  value: PropTypes.instanceOf(Range).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DatePicker;
