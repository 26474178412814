import React from 'react';
import TextAnswerComponent from './TextAnswer';
import { NotesAnswer } from '../../types/Answer';
import ReactHtmlParser from 'react-html-parser';

interface Props {
  label: string;
  answer?: NotesAnswer;
}

const NotesInputAnswer = ({ label, answer }: Props) => (
  <TextAnswerComponent question={ReactHtmlParser(label)} answer={answer} />
);

export default NotesInputAnswer;
