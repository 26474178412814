import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { discharge } from '../../../../graph/patients';

const withDischargeMutation = Component => props =>
  (
    <Mutation mutation={discharge}>
      {dischargePatient => <Component {...props} dischargePatient={dischargePatient} />}
    </Mutation>
  );

export default withDischargeMutation;
