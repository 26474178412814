import { Subscription } from '@apollo/client/react/components';
import React from 'react';
import styled from 'styled-components';
import { roomSubscription } from '../../../../graph/rooms';
import get from 'lodash/get';
import ProgressRing from './ProgressRing';
import colors from '../../../../colors';
import { formatTime, useFiltrationProgress, useSoundAlert, withTick } from './utils';
import { differenceInMinutes } from 'date-fns';
import { TimerOuterRim } from './Common';
import { defaultTo } from 'lodash/util';
import { AirFilterTimeModuleOptional } from '../../../ModuleOptional';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.8rem;
`;

const Counter = styled.label`
  font-family: monospace;
  color: ${colors.orange.string()};
  font-size: 0.7rem;
  font-weight: 600;
`;

const IndicatorSimple = ({ airFiltrationEndsAt, warningEndAt, warningDuration, style, radius = 15 }) => {
  const now = new Date();
  const progress = useFiltrationProgress({ now, warningEndAt, warningDuration });

  return (
    <Container style={style}>
      <ProgressRing
        style={{ position: 'absolute' }}
        color={colors.orange.alpha(0.9).string()}
        radius={radius}
        stroke={2}
        progress={progress}
      />
      <Counter>{Math.max(differenceInMinutes(airFiltrationEndsAt, now), 0)}</Counter>
    </Container>
  );
};

const IndicatorTimer = ({ warningEndAt, warningDuration, style, radius = 46, stroke = 4 }) => {
  const now = new Date();
  const progress = useFiltrationProgress({ now, warningEndAt, warningDuration });

  return (
    <TimerOuterRim style={style}>
      <ProgressRing
        style={{ position: 'absolute' }}
        color={colors.orange.alpha(0.9).string()}
        radius={radius}
        stroke={stroke}
        progress={progress}
      />
      <Counter>{formatTime(now, warningEndAt)}</Counter>
    </TimerOuterRim>
  );
};

const Switch = ({ Component, airFiltrationEndsAt, airFiltrationDurationMin, airFiltrationWarningOn, ...rest }) => {
  useSoundAlert(airFiltrationWarningOn);

  if (!airFiltrationWarningOn) {
    return null;
  }

  return (
    <Component
      {...rest}
      airFiltrationWarningOn={airFiltrationWarningOn}
      warningEndAt={airFiltrationEndsAt}
      warningDuration={defaultTo(airFiltrationDurationMin, 20) * 60}
    />
  );
};

const RoomData =
  Component =>
  ({ roomId, ...rest }) =>
    (
      <Subscription subscription={roomSubscription} variables={{ roomId: roomId }} skip={!roomId}>
        {({ data }) => <Switch Component={Component} {...rest} {...get(data, 'room', {})} />}
      </Subscription>
    );

export const AirFiltrationIndicatorTimer = AirFilterTimeModuleOptional(RoomData(withTick(IndicatorTimer)));
export const AirFiltrationIndicatorSimple = AirFilterTimeModuleOptional(RoomData(withTick(IndicatorSimple)));
