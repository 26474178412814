import { Mutation, Query } from '@apollo/client/react/components';
import React, { useState, useEffect } from 'react';
import Button from '../../../se/components/Button';
import ErrorFactory from '../../../se/components/errors/Error';
import NotificationTemplateInput from './components/NotificationTemplateInput';
import CustomMessageInput from './components/CustomMessageInput';
import { list, update } from '../../../graph/notificationTemplates';
import { SectionTitle, SectionTitle2 } from '../../pages/Configuration';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useMutation, useQuery } from '@apollo/client';
import customMessages, {
  list as listMessage,
  update as updateMessage,
  create as createMessage,
  remove as removeMessage,
} from '../../../graph/customMessages';

const Error = ErrorFactory();

const isDirty = (state, template) =>
  state[template.id] &&
  (state[template.id].content !== template.content ||
    state[template.id].title !== template.title ||
    state[template.id].trigger !== template.trigger ||
    state[template.id].subject !== template.subject ||
    state[template.id].additionalInfo !== template.additionalInfo);

export const Channel = {
  SMS: 'SMS',
  Email: 'Email',
};

export default ({ destinations }) => {
  const [state, setState] = useState({});
  const [createCustomMessage, { loading, error }] = useMutation(createMessage);
  const [removeCustomMessage] = useMutation(removeMessage);

  const handleChange = value => setState({ [value.id]: value });

  const onComplete = () => console.log('completed');

  const handleSubmit2 = (mutation, id) => e => {
    e.preventDefault();

    mutation({
      variables: {
        id: id,
        title: state[id].title,
        content: state[id].content,
      },
      refetchQueries: [{ query: list, variables: { id } }],
    });
  };

  // Fetch the list of customMessages
  const { data } = useQuery(listMessage);

  const removeCustomMessagesWithEmptyTitleAndContent = async () => {
    const customMessages = get(data, 'customMessages', []);
    // Filter the customMessages with empty title and content
    const messagesToDelete = customMessages.filter(message => {
      return !message.title;
    });
    console.log('Message to delete:', messagesToDelete);

    // Delete the filtered messages
    for (const message of messagesToDelete) {
      await removeCustomMessage({
        variables: {
          id: message.id,
        },
        refetchQueries: [{ query: listMessage }],
      })
        .then(response => {
          console.log('Custom message deleted:', response);
        })
        .catch(error => {
          console.error('Error deleting custom message:', error);
        });
    }
  };

  useEffect(() => {
    removeCustomMessagesWithEmptyTitleAndContent();
  }, []);

  // Function to add a new empty custom message
  const addCustomMessage = () => {
    // Call the createCustomMessage mutation with the newCustomMessage data
    createCustomMessage({
      variables: {
        title: '',
        content: '',
      },
      refetchQueries: [{ query: listMessage }],
    })
      .then(response => {
        // Handle the successful creation of the custom message if needed
        console.log('Custom message created:', response);
      })
      .catch(error => {
        // Handle any errors that occur during the mutation
        console.error('Error creating custom message:', error);
      });
  };

  // Function to add a new empty custom message
  const deleteCustomMessage = id => {
    // Call the createCustomMessage mutation with the newCustomMessage data
    removeCustomMessage({
      variables: {
        id: id,
      },
      refetchQueries: [{ query: listMessage }],
    })
      .then(response => {
        // Handle the successful creation of the custom message if needed
        console.log('Custom message deleted:', response);
      })
      .catch(error => {
        // Handle any errors that occur during the mutation
        console.error('Error deleting custom message:', error);
      });
  };

  return (
    <>
      <Query query={listMessage}>
        {({ loading, error, data = {} }) => (
          <>
            <Box mb={4}>
              <SectionTitle2>Custom Messages</SectionTitle2>
              <Button onClick={addCustomMessage} style={{ marginLeft: '2rem', marginBottom: '2rem' }}>
                Add Template Message
              </Button>
            </Box>
            <Grid container spacing={8}>
              <Mutation mutation={updateMessage} onComplete={onComplete}>
                {(mutation, { loading, error }) =>
                  get(data, 'customMessages', [])
                    .slice()
                    .reverse()
                    .map((message, i) => (
                      <div style={{ width: '50.5%' }}>
                        <Grid container spacing={1} key={i} style={{ marginLeft: '2rem', marginBottom: '2rem' }}>
                          {' '}
                          <Grid item xs={12} key={message.id}>
                            <form onSubmit={handleSubmit2(mutation, message.id)}>
                              <CustomMessageInput value={state[message.id] || message} onChange={handleChange} />
                              <Button onClick={() => deleteCustomMessage(message.id)}>Remove</Button>

                              {isDirty(state, message) && <Button disabled={loading}>Save</Button>}
                              {/* <Error isVisible={error !== undefined} id={`CustomMessages-${message.id}`}>
                              <p>An unexpected error has occurred. Please, try again or contact our support.</p>
                            </Error> */}
                            </form>
                          </Grid>
                        </Grid>
                      </div>
                    ))
                }
              </Mutation>
            </Grid>
          </>
        )}
      </Query>
    </>
  );
};
