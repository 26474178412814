import React, { Fragment } from 'react';
import styled from 'styled-components';

import ServerEventClient from '../../../essentials/ServerEventClient';
import { requestSession } from './kioskSession';
import ClientUpdater from '../../ClientUpdater';
import {
  ANALYTICS,
  CHARTING_TABLET,
  INTERNAL_MONITOR,
  OPERATION_ROOM_MONITOR,
  PACU_TABLET,
  POST_OP_TABLET,
  PREP_TABLET,
  REVIEW_DISPLAY,
  EXIT_DISPLAY,
  SCHEDULE_MONITOR,
  WAITING_ROOM_MONITOR,
  WAITING_ROOM_TABLET,
  HOSPITAL_OVERVIEW,
} from '../../entities/screens/enums';

const Root = styled.div`
  background: ${props => props.theme.backgroundColor.string()};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.textColor.string()};
  height: 100vh;
`;

const Message = styled.div`
  // background: rgba(255,255,255,.07);
  font-size: 2vw;
  border-radius: 0.5rem;
  text-align: center;

  span {
    opacity: 0.35;
  }
`;

const Code = styled.div`
  font-size: 5vw;
  margin-top: 1.5rem;
  display: flex;
`;

const Number = styled.div`
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 0.25rem;
  margin: 0 0.25rem;
  text-align: center;
  flex: 1 0 auto;
`;

const resolvePath = (type, room) => {
  if (type === WAITING_ROOM_MONITOR) {
    return '/waiting-room';
  } else if (type === INTERNAL_MONITOR) {
    return '/monitor';
  } else if (type === SCHEDULE_MONITOR) {
    return `/schedule`;
  } else if (type === OPERATION_ROOM_MONITOR && isFinite(room)) {
    return `/tablet/${room}`;
  } else if (type === PACU_TABLET && isFinite(room)) {
    return `/nursing/pacu/${room}`;
  } else if (type === PREP_TABLET && isFinite(room)) {
    return `/nursing/prep/${room}`;
  } else if (type === POST_OP_TABLET && isFinite(room)) {
    return `/nursing/post-op/${room}`;
  } else if (type === CHARTING_TABLET) {
    return `/charting`;
  } else if (type === REVIEW_DISPLAY) {
    return `/reviews`;
  } else if (type === EXIT_DISPLAY) {
    return `/exit`;
  } else if (type === WAITING_ROOM_TABLET) {
    return `/waiting-room-tablet`;
  } else if (type === ANALYTICS) {
    return `/analytics`;
  } else if (type === HOSPITAL_OVERVIEW) {
    return `/caretaker-communication`;
  }
};

export default class Authorize extends React.Component {
  state = {
    error: false,
    code: null,
    secret: null,
  };

  constructor(props) {
    super(props);
    this.source = new ServerEventClient(this.assignmentHandler);
  }

  async componentDidMount() {
    this.setState(await requestSession(this.props.organizationId));
  }

  componentWillUnmount() {
    this.source.close();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.secret === null && this.state.secret) {
      const queryParams = { organization: this.props.organizationId, code: this.state.code, secret: this.state.secret };
      this.source.open(queryParams);
    }
  }

  assignmentHandler = data => {
    const {
      sessionToken: token,
      metadata: { type, room },
    } = data;

    if (token) {
      this.props.setSession({ token, path: resolvePath(type, room) });
    }
  };

  render() {
    const { error, code, secret } = this.state;
    const codeString = code ? code.toString() : '';
    const codeArray = codeString.split('');

    return (
      <Fragment>
        <Root>
          {error ? (
            <Message>
              <span>Unexpected error</span>
            </Message>
          ) : (
            <Message>
              <span>Logging in…</span>
              {code && secret && (
                <Code>
                  {codeArray.map((number, i) => (
                    <Number key={i}>{number}</Number>
                  ))}
                </Code>
              )}
            </Message>
          )}
        </Root>
        <ClientUpdater />
      </Fragment>
    );
  }
}
