export const formatProviderId = (id?: string | number) => {
  if (!id || typeof id === 'number') {
    return id;
  }

  const match = id.match(/^[sl]\//);

  if (!match) {
    return id;
  }

  return id.substring(2);
};
