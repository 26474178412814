import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, withHandlers, withProps, withState } from 'recompose';

import Button from '../Button';
import LinkButton from '../LinkButton';
import Form from '../Form';
import { getNestedValue } from '../../utilities/data/object';

const RowButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CenteredLinkButton = styled(LinkButton)`
  align-self: center;
`;

const Footer = ({
  label = 'Submit',
  dangerLabel = 'Remove',
  busy,
  disabled,
  isDirty,
  handleDangerLinkButtonClick,
  isDangerActionInProgress,
  onCancel,
}) => (
  <RowButtons>
    <div>
      <Button disabled={disabled || isDangerActionInProgress} busy={!isDangerActionInProgress && busy} primary>
        {label}
      </Button>
      {onCancel && (
        <CenteredLinkButton style={{ marginLeft: '0.5em' }} onClick={onCancel}>
          Cancel
        </CenteredLinkButton>
      )}
    </div>

    <CenteredLinkButton danger type="button" onClick={handleDangerLinkButtonClick} disabled={busy || disabled}>
      {dangerLabel}
    </CenteredLinkButton>
  </RowButtons>
);

Footer.propTypes = {
  label: PropTypes.string.isRequired,
  dangerLabel: PropTypes.string,
  handleDangerLinkButtonClick: PropTypes.func,
};

const EnhancedFooter = compose(
  withState('isDangerActionInProgress', 'setIsDangerActionInProgress', false),
  withHandlers({
    handleDangerLinkButtonClick: props => async () => {
      const { setIsDangerActionInProgress, onDangerLinkButtonClick, onBusy, onError } = props;

      try {
        onBusy(true);
        onError({
          hasError: false,
          error: undefined,
          errors: undefined,
        });
        setIsDangerActionInProgress(true);
        await onDangerLinkButtonClick();
      } catch (error) {
        onError({
          hasError: true,
          error: getNestedValue('error', error),
          errors: getNestedValue('errors', error),
        });
      } finally {
        onBusy(false);
        setIsDangerActionInProgress(false);
      }
    },
  })
)(Footer);

export default compose(
  withProps({
    Footer: EnhancedFooter,
  })
)(Form);
