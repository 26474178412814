import { Theme, alpha } from '@material-ui/core';

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      root: {
        ...theme.typography.button,
        borderRadius: 32,
        display: 'flex',
        padding: '.75rem 1.5rem',
      },
      label: {
        lineHeight: 1.375,
      },
      /* Styles applied to the root element if `variant="outlined"`. */
      outlined: {
        padding: '.75rem 1.5rem',
        border: `1px solid ${
          theme.palette.type === 'light' ? alpha(theme.palette.text.primary, 0.23) : 'rgba(255, 255, 255, 0.23)'
        }`,
        '&$disabled': {
          border: `1px solid ${theme.palette.action.disabledBackground}`,
        },
      },
      /* Styles applied to the root element if `variant="outlined"` and `color="primary"`. */
      outlinedPrimary: {
        color: theme.palette.primary.main,
        border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
        '&:hover': {
          border: `1px solid ${theme.palette.primary.main}`,
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
          // Reset on touch devices, it doesn’t add specificity
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      },
      containedPrimary: {
        color: '#fff',
      },
      sizeSmall: {
        padding: '.5rem 1rem',
        fontSize: theme.typography.pxToRem(14),
      },
      sizeLarge: {
        fontSize: '1.2rem',
        padding: '1rem 2rem',
      },
    },
  };
}
