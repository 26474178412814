import { makeHeader, openPdf } from './shared';
import { makeBmiSection } from './SummaryPdfGenerator';
import { createPdf } from '../../../../vendor/pdfMake';
import { docDefinitionTemplate, makeNotesPage, parseElement } from './FullPdfGenerator';

export const makeLayout = (questions, answers) => {
  const body = parseElement(questions, {
    value: answers,
    skipSections: {
      demographicInformation: true,
      documentElectronicSignature: true,
    },
  });

  const sections = [body[10], body[11], ...body.slice(0, 10), body[12]];

  return {
    fontSize: 8,
    stack: sections,
  };
};

export const generateFullPdfMedicalPassport = ({
  questions,
  answers,
  procedure,
  hospitalName,
  preOpBy,
  preOpAt,
  scheduleProvider,
  notes,
}) => {
  const header = makeHeader({
    procedure,
    hospitalName,
    marginBottom: 5,
    preOpInfo: preOpAt ? { preOpBy, preOpAt } : undefined,
    scheduleProvider,
  });
  const body = makeLayout(questions, answers);

  const docDefinition = {
    ...docDefinitionTemplate(procedure, [40, 40, 40, 40]),
    info: { title: `Full Form Patient ${procedure.id}` },
    content: [
      header,
      {
        stack: [makeBmiSection(answers, { marginBottom: 0, fontSize: 8, color: 'black' })],
      },
      body,
      notes.length > 0 ? makeNotesPage(notes) : undefined,
    ],
  };

  openPdf(createPdf(docDefinition), `Full_Form_Questionnaire_Patient_${procedure.id}`);
};
