import React, { useCallback, useRef, useState } from 'react';
import { ProcedureStep } from '../../../../../types/ProcedureStep';
import styled from 'styled-components';
import { format } from 'date-fns';
import { useMutation } from '@apollo/client';
import { orTabletProcedureSteps, setProcedureStepTimestamp } from '../../../../../graph/procedureSteps';
import { Patient } from '../../../../../types/Patient';
import { setTimeToDate } from '../util/procedureSteps';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const TimestampValueInput = styled.input<{ highlight?: boolean }>`
  font-style: normal;
  font-weight: 700;
  font-size: calc(1.8rem + 0.390625vw);
  color: ${props => (props.highlight ? '#00A7F7' : '#ffffff')};
  z-index: 3;
  position: relative;
  outline: none;
  border: 2px solid #00a7f7;
  border-radius: 0.6rem;
  background-color: #1f2c61;
  padding: 0 1rem;

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
`;

const ProcedureStepTime = ({
  index,
  procedureStep,
  patient,
}: {
  index: number;
  procedureStep: ProcedureStep & { highlight: boolean; show: boolean };
  patient?: Patient & { duration?: string | undefined };
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [save] = useMutation(setProcedureStepTimestamp);
  const classes = useStyles();

  const initialTime =
    procedureStep?.show && procedureStep?.timestamp ? format(procedureStep?.timestamp, 'HH:mm') : undefined;
  const [edit, setEdit] = useState(false);
  const [time, setTime] = useState(initialTime);

  const canEdit = !!initialTime;
  const trySetEdit = useCallback(() => {
    canEdit && !edit && setEdit(value => !value);
    inputRef?.current?.focus();
    inputRef?.current?.showPicker();
  }, [canEdit, edit]);

  const saveAndClose = useCallback(async () => {
    patient?.id &&
      (await save({
        variables: {
          patientId: patient?.id,
          procedureStepId: procedureStep?.id,
          procedureStepType: procedureStep?.type,
          timestamp: setTimeToDate(new Date(), time),
        },
        refetchQueries: [{ query: orTabletProcedureSteps, variables: { patientId: patient?.id } }],
      }));
    setEdit(false);
  }, [patient, procedureStep, time, save]);

  return (
    <>
      {edit && <Overlay onClick={saveAndClose} />}
      <Box className={clsx(classes.root, { [classes.highlight]: procedureStep?.highlight })} onClick={trySetEdit}>
        <Typography variant="h4" color="textSecondary">
          {procedureStep?.name}
        </Typography>
        {!edit && (
          <Typography variant="h1" style={{ fontWeight: 'bold', fontSize: '5em' }}>
            {initialTime || '-'}
          </Typography>
        )}
        {edit && (
          <TimestampValueInput
            ref={inputRef}
            type="time"
            value={time}
            onChange={e => {
              setTime(e?.target?.value);
            }}
          />
        )}
      </Box>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  highlight: {
    padding: theme.spacing(2),
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    marginBottom: -theme.spacing(2),
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: theme.spacing(1),
  },
  input: {
    fontSize: '5em',
    fontWeight: 'bold',
    zIndex: 3,
    padding: 0,
    fontFamily: theme.typography.fontFamily,
    '&>input': {
      padding: 0,
      '&::-webkit-calendar-picker-indicator': {
        display: 'none',
      },
    },
  },
}));

export default ProcedureStepTime;
