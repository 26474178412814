import React from 'react';
import ModalForm from '../../../ModalForm';
import SimpleMutationForm from '../../../SimpleMutationForm';
import RoomSelectInput from '../../../inputs/room/ManualRoomSelectInput';
import patientScheme from '../../../../graph/patients';

const scheme = {
  item: patientScheme.item,
  update: patientScheme.setRoom,
};

const Input = (value, onChange) => props => <RoomSelectInput {...props} value={value} onChange={onChange} />;

const ChangeLocationForm = ({ value, onChange, onSubmit }) => (
  <SimpleMutationForm scheme={scheme} Input={Input} value={value} onChange={onChange} onSubmit={onSubmit} />
);

export default class ChangeLocation extends React.Component {
  render() {
    return (
      <ModalForm
        title="Current Location"
        variableName="roomId"
        entityId={this.props.patientId}
        initialValue={this.props.initialValue}
        onClose={() => this.props.setEditModalOpen({ room: false })}
        scheme={scheme}
        FormComponent={ChangeLocationForm}
        withRefetch
      />
    );
  }
}
