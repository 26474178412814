import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import FormContext from '../FormContext';
import InputWrapper from '../InputWrapper';
import InputLabel from '../InputLabel';
import makeName from '../makeName';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TranslatedMessage from '../TranslatedMessage';
import DefaultValueContext from '../DefaultValueContext';
import { parse } from 'date-fns';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: theme.spacing(0, -1),

    '& .MuiTextField-root': {
      margin: theme.spacing(0, 1),
    },
  },
}));

const DateOfBirthInput = ({ optional, explanation, validators }) => {
  const classes = useStyles();
  const intl = useIntl();

  const [error, setError] = useState(null);
  const { name, value, onChange } = useContext(FormContext);

  const dayRef = useRef();
  const yearRef = useRef();

  const monthName = makeName(name, 'month');
  const dayName = makeName(name, 'day');
  const yearName = makeName(name, 'year');

  const defaultValues = useContext(DefaultValueContext);

  const month =
    get(value, ['dateOfBirth', 'month'], '') ||
    get(defaultValues, `sections.${name}.dateOfBirth.month`, '') ||
    parse(localStorage.getItem('patientDateOfBirth')).getMonth() + 1;
  const day =
    get(value, ['dateOfBirth', 'day'], '') ||
    get(defaultValues, `sections.${name}.dateOfBirth.day`, '') ||
    parse(localStorage.getItem('patientDateOfBirth')).getDate();
  const year =
    get(value, ['dateOfBirth', 'year'], '') ||
    get(defaultValues, `sections.${name}.dateOfBirth.year`, '') ||
    parse(localStorage.getItem('patientDateOfBirth')).getFullYear();

  const handleChange = (name, min, max) => e => {
    const nextInputvalue = e.target.value.replace(/\D/g, '');
    const numericalNextInputValue = parseInt(nextInputvalue, '10');
    if (numericalNextInputValue < min || numericalNextInputValue > max) {
      return;
    }
    if (name === 'month') {
      if (nextInputvalue.length >= e.target.maxLength && dayRef.current) {
        dayRef.current.focus();
        dayRef.current.setSelectionRange(0, dayRef.current.value.length);
      }
    } else if (name === 'day') {
      if (nextInputvalue.length >= e.target.maxLength && yearRef.current) {
        yearRef.current.focus();
        yearRef.current.setSelectionRange(0, yearRef.current.value.length);
      }
    }
    const nextValue = set(value ? cloneDeep(value) : {}, ['dateOfBirth', name], nextInputvalue);
    const ok = (name, length) => get(nextValue, ['dateOfBirth', name], '').length >= length;
    const done = ok('month', 1) && ok('day', 1) && ok('year', 4);
    onChange(cloneDeep(set(nextValue, 'progress', done ? (!optional ? 1 : 0) : 0)));
  };

  useEffect(() => {
    const done =
      (month?.toString() || '')?.length >= 1 &&
      (day?.toString() || '')?.length >= 1 &&
      (year?.toString() || '')?.length >= 4;
    if ((value?.progress || 0) === 0 && !optional && done) {
      const paddedDay = (day?.toString() || '')?.length === 1 ? `0${day}` : day.toString();
      const paddedMonth = (month?.toString() || '')?.length === 1 ? `0${month}` : month.toString();
      onChange({ dateOfBirth: { day: paddedDay, month: paddedMonth, year: year.toString() }, progress: 1 });
    }
  }, [day, month, year, onChange, value, optional]);

  const padDigits = field => e => {
    try {
      const val = e.target.value;

      if (val && val.length === 1) {
        e.target.value = `0${val}`;
      }

      handleChange(field)(e);
    } catch (e) {}
  };

  const handleYearBlur = e => {
    try {
      const val = e.target.value;
      if (val && val.length === 2) {
        const end00Year = new Date().getFullYear() % 1000;
        if (parseInt(val, 10) > end00Year) {
          e.target.value = `19${val}`;
        } else {
          e.target.value = `20${val}`;
        }
      }

      handleChange('year')(e);
    } catch (e) {}
  };

  const dateObj = useMemo(() => ({ year, month, day }), [year, month, day]);
  const rules = validators ? validators.map(type => validatorFn[type]?.(FieldType.Date) ?? (() => '')) : [];
  const required = !!validators?.find(e => e === ValidatorType.isRequired);

  return (
    <InputWrapper>
      <FormControl variant="filled">
        <InputLabel htmlFor={!month ? monthName : !day ? dayName : !year ? yearName : monthName}>
          <FormattedMessage id="questionnaire.dob.title" defaultMessage="Date of Birth" />
        </InputLabel>

        <div className={classes.root}>
          <TextField
            placeholder={intl.formatMessage({ id: 'questionnaire.dob.placeholder.month', defaultMessage: 'MM' })}
            variant="filled"
            label={<FormattedMessage id="questionnaire.dob.month" defaultMessage="Month" />}
            type="text"
            id={monthName}
            name={monthName}
            value={month}
            onChange={handleChange('month', 0, 12)}
            onBlur={padDigits('month')}
            required={required}
            error={!!error}
            inputProps={{
              length: 2,
              maxLength: 2,
            }}
          />
          <TextField
            type="text"
            placeholder={intl.formatMessage({ id: 'questionnaire.dob.placeholder.day', defaultMessage: 'DD' })}
            variant="filled"
            label={<FormattedMessage id="questionnaire.dob.day" defaultMessage="Day" />}
            inputRef={dayRef}
            id={dayName}
            name={dayName}
            value={day}
            onChange={handleChange('day', 0, 31)}
            onBlur={padDigits('day')}
            required={required}
            error={!!error}
            inputProps={{
              length: 2,
              maxLength: 2,
            }}
          />
          <TextField
            type="text"
            variant="filled"
            label={<FormattedMessage id="questionnaire.dob.year" defaultMessage="Year" />}
            placeholder={intl.formatMessage({ id: 'questionnaire.dob.placeholder.year', defaultMessage: 'YYYY' })}
            inputRef={yearRef}
            id={yearName}
            name={yearName}
            value={year}
            onChange={handleChange('year', 0, new Date().getFullYear())}
            onBlur={handleYearBlur}
            required={required}
            error={!!error}
            inputProps={{
              length: 4,
              maxLength: 4,
            }}
          />
        </div>
        {!!error && (
          <FormHelperText style={{ color: 'red' }}>
            <TranslatedMessage message={error} />
          </FormHelperText>
        )}
        {explanation && (
          <FormHelperText>
            <TranslatedMessage message={explanation} />
          </FormHelperText>
        )}
      </FormControl>
    </InputWrapper>
  );
};

DateOfBirthInput.defaultValue = { dateOfBirth: {}, progress: 0 };

export default DateOfBirthInput;
