import { isArray, isFunction } from '../check';
import { range } from '../data/array';

export const int =
  (min = -Number.MAX_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER) =>
  () =>
    Math.random() * (max - min) + min;

export const sample = array => () => isArray(array) ? array[Math.floor(Math.random() * array.length)] : undefined;

export const natural = int(0);

export const bool =
  (likelihood = 80) =>
  () =>
    Math.round(Math.random() * 100) < likelihood;

export const string = () => () => Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(36);

export const optional = (valueProvider, likelihood) => () =>
  bool(likelihood)() ? (isFunction(valueProvider) ? valueProvider() : undefined) : undefined;

export const branch = (valueProvider, fallbackValueProvider, likelihood) => () =>
  bool(likelihood)()
    ? isFunction(valueProvider)
      ? valueProvider()
      : undefined
    : isFunction(fallbackValueProvider)
    ? fallbackValueProvider()
    : undefined;

export const listOf = itemProvider => () => range(Math.floor(exponential(10)()), itemProvider);

const exponential =
  (expectation = 1) =>
  () =>
    -expectation * Math.log(Math.random());

export const delay = (callback, timeValue) => setTimeout(callback, exponential(timeValue || 500)());

export const promise = (successProvider = () => true, errorProvider = () => true, likelihood) =>
  branch(
    () => new Promise(resolve => delay(() => resolve(successProvider()))),
    () => new Promise((_, reject) => delay(() => reject(errorProvider()))),
    likelihood || 70
  );
