import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import InputError from '../errors/InputError';
import StarIcon from '@material-ui/icons/Star';
import { createStyles, makeStyles } from '@material-ui/core';
import classNames from 'clsx';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  label {
    margin: auto;
    margin-left: -4.8em;
    font-size: 1em;
    opacity: 0.8;
  }
`;

export default ({
  Error = InputError,
  value,
  onChange,
  isDisabled,
  name,
  error,
  hasError,
  defaultValue,
  allowEmpty,
  ...rest
}) => {
  const styles = useStyles();
  const [isFocus, setFocus] = useState(false);

  const actualValue = Math.min(Math.max(Math.round(value || defaultValue), 1), 5) || 0;
  const hasValue = actualValue > 0;

  const handleFocus = () => setFocus(true);
  const handleBlur = () => setFocus(false);
  const handleChange = e => {
    const value = e.target.value.toString();
    const lastDigit = Math.min(Math.max(parseInt(value.charAt(value.length - 1), 10), 1), 5) || null;
    onChange(lastDigit);
  };
  const handleClick = rating => () => onChange(rating);

  return (
    <Fragment>
      <Wrapper {...rest}>
        <input
          id={name}
          name={name}
          type="number"
          min="1"
          max="5"
          value={actualValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          className={styles.input}
        />
        <div
          className={classNames(styles.starsContainer, {
            [styles.starsContainerFocus]: isFocus,
          })}
        >
          {[5, 4, 3, 2, 1].map(rating => (
            <div
              key={rating}
              className={classNames(styles.starContainer, {
                [styles.starContainerSelected]: hasValue && value >= rating,
              })}
              onClick={handleClick(rating)}
            >
              <StarIcon className={styles.star} />
            </div>
          ))}
        </div>
      </Wrapper>
      <Error isVisible={hasError} id={name}>
        {hasError ? error : ''}
      </Error>
    </Fragment>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    input: {
      width: 0,
      height: 0,
      position: 'absolute',
      opacity: 0,
      zIndex: -100,
    },
    starsContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      cursor: 'pointer',
      '& > div$starContainerSelected': {
        color: 'rgb(244, 174, 61)',
      },
      '&:hover > div$starContainerSelected': {
        color: 'rgb(50, 44, 50)',
      },
      '&:hover > div:hover, &:hover > div:hover + div, &:hover > div:hover + div + div, &:hover > div:hover + div + div + div, &:hover > div:hover + div + div + div + div':
        {
          color: 'rgb(244, 174, 61)',
        },
    },
    starsContainerFocus: {
      outline: `solid 2px ${theme.palette.primary.main}`,
    },
    starContainer: {
      height: '2rem',
      color: 'rgb(50, 44, 50)',
    },
    starContainerSelected: {
      color: 'rgb(244, 174, 61)',
    },
    star: {
      fontSize: '2rem',
    },
  })
);
