import React from 'react';
import { RouteComponentProps } from 'react-router';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import CurrentTrackingSession from '../CurrentTrackingSession';
import PastTrackingSessions from './PastTrackingSessions';
import { Breadcrumbs, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

interface TrackingHomepageProps extends RouteComponentProps {
  baseUrl: string;
}

const TrackingHomepage = ({ match, history }: TrackingHomepageProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Typography>Tracking Insights</Typography>
      </Breadcrumbs>

      <CurrentTrackingSession history={history} baseUrl={match.url} />
      <PastTrackingSessions baseUrl={match.url} />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

export default TrackingHomepage;
