import { useMutation, useQuery } from '@apollo/client';
import { getNotificationTemplate } from '../../../graph/notificationTemplates';
import { notifyReadyForAnesthesiologist } from '../../../graph/patients';
import Tooltip from '../../Tooltip';
import React, { useState } from 'react';
import { getLogEntries, isPatientReadyForAnesthesiologistNotifyFn } from './tablet/utils';
import Anesthesiologist from '../../../types/Anesthesiologist';
import { Patient } from '../../../types/Patient';
import { CustomModal } from './tablet/OperationRoomTabletV1';
import { fontSizeIconSide, SideOption } from './pacuAndPrep/PatientOptions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useTheme } from '@material-ui/core/styles';
import { SendingSetting } from '../../entities/notificationTemplate/NotificationTemplates';

const NotifyAnesthesiologistModal = CustomModal('This text message will be sent to the anesthesiologist.');

export const NotifyButton = ({
  notifiedLabel,
  notNotifiedLabel,
  isNotified,
  notifyEnabled,
  message,
  notify,
}: {
  notifiedLabel: string;
  notNotifiedLabel: string;
  isNotified: boolean;
  message: string;
  notify: () => Promise<void>;
  notifyEnabled: boolean;
}) => {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    if (!isNotified && notifyEnabled) {
      setIsModalOpen(true);
    }
  };
  const handleConfirm = async () => {
    await notify();

    setIsModalOpen(false);
  };
  return (
    <>
      <SideOption
        smsIndicator={false}
        icon={
          isNotified ? (
            <CheckCircleIcon
              style={{
                opacity: '0.7',
                fontSize: fontSizeIconSide,
                color: theme.palette.success.main,
              }}
            />
          ) : (
            <NotificationsIcon
              color={!notifyEnabled ? 'disabled' : 'primary'}
              style={{
                fontSize: fontSizeIconSide,
              }}
            />
          )
        }
        label={isNotified ? notifiedLabel : notNotifiedLabel}
        disabled={!notifyEnabled || isNotified}
        onClick={openModal}
      />
      {isModalOpen && (
        <NotifyAnesthesiologistModal
          handleCancel={() => setIsModalOpen(false)}
          handleConfirm={handleConfirm}
          messageToSend={message}
        />
      )}
    </>
  );
};

const AnesthesiologistNotifyButton = ({
  patient,
  anesthesiologist,
}: {
  patient: Patient;
  anesthesiologist: Anesthesiologist;
}) => {
  const patientLogs = getLogEntries(patient);
  const { data: template } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'ReadyForAnesthesiologistNotify' },
  });

  const [notifyAnesthesiologist] = useMutation(notifyReadyForAnesthesiologist, {
    variables: {
      id: patient?.id,
    },
  });

  const notify = async () => {
    await notifyAnesthesiologist();
  };

  const isAnesthesiologistNotified = isPatientReadyForAnesthesiologistNotifyFn(patientLogs);
  const readyForAnesthesiologistNotification = template?.getNotificationTemplate?.[0] || null;
  const shouldSendNotification = anesthesiologist?.shouldSendNotification || null;
  const hasPhoneNumber = anesthesiologist?.phoneNumber || null;
  const messageContent = readyForAnesthesiologistNotification?.content;

  const notifyAnesthesiologistEnabled =
    readyForAnesthesiologistNotification?.sendingSetting !== SendingSetting.Off &&
    hasPhoneNumber &&
    shouldSendNotification;
  const notifyAnesthesiologistTooltip =
    readyForAnesthesiologistNotification?.sendingSetting === SendingSetting.Off
      ? 'Anesthesiologist messaging has been turned off. Go to message configuration to enable it.'
      : !shouldSendNotification
      ? 'Anesthesiologist messaging has been turned off. Anesthesiologist doesn’t want to receive notification.'
      : !hasPhoneNumber
      ? 'Anesthesiologist does not have a phone number.'
      : null;

  return notifyAnesthesiologistTooltip ? (
    <Tooltip delay={0} content={notifyAnesthesiologistTooltip}>
      <NotifyButton
        isNotified={isAnesthesiologistNotified}
        notifyEnabled={notifyAnesthesiologistEnabled}
        notNotifiedLabel={'Notify Anesthesiologist'}
        notifiedLabel={'Anesthesiologist Notified'}
        message={messageContent}
        notify={notify}
      />
    </Tooltip>
  ) : (
    <NotifyButton
      isNotified={isAnesthesiologistNotified}
      notifyEnabled={notifyAnesthesiologistEnabled}
      notNotifiedLabel={'Notify Anesthesiologist'}
      notifiedLabel={'Anesthesiologist Notified'}
      message={messageContent}
      notify={notify}
    />
  );
};

export default AnesthesiologistNotifyButton;
