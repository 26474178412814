import React from 'react';
import { DescriptionQuestion } from '../../types/Question';

type DescriptionProps = DescriptionQuestion['props'];

const Description = ({ htmlContent }: DescriptionProps) => (
  <div dangerouslySetInnerHTML={{ __html: htmlContent }}/>
);

export default Description;
