import React, { useState } from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import FormContext from '../FormContext';
import SingleCheckboxInput from '../SingleCheckboxInput';
import InputWrapper from '../InputWrapper';
import Question from '../Question';
import makeName from '../makeName';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FormControl, FormGroup, FormHelperText, Paper } from '@material-ui/core';
import TranslatedMessage from '../TranslatedMessage';
import DistinguishTitle from '../DistinguishTitle';

const useStyles = makeStyles(theme => ({
  noneOf: {
    padding: theme.spacing(0, 1.5),
    margin: theme.spacing(0, -1.5),
    transition: `all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
    background: 'transparent',
  },
  emphasized: {
    background: theme.palette.divider,
  },
}));

const CheckboxesInput = ({ label, question, checkboxes, omitnone, explanation, distinguish, validators }) => {
  const classes = useStyles();
  const intl = useIntl();

  const [error, setError] = useState(null);

  return (
    <InputWrapper>
      {distinguish && <DistinguishTitle title={distinguish} />}
      <FormControl>
        <Question>
          {(label && <FormattedMessage id={label} defaultMessage={label} />) ||
            (question && <FormattedMessage id={question} defaultMessage={question} />) || (
              <FormattedMessage id="questionnaire.checkboxes.question" defaultMessage="Check all that apply" />
            )}
        </Question>

        <FormContext.Consumer>
          {({ name, value, onChange }) => {
            const handleChange = v => {
              const isComplete =
                Object.values(v || {})
                  .map(x => !!x)
                  .indexOf(true) > -1;
              onChange({ checkboxes: v, progress: isComplete ? 1 : 0 });
            };

            return (
              <FormGroup>
                {checkboxes.map((checkbox, i) => {
                  const label = isArray(checkbox) ? checkbox[0] : checkbox;
                  const ifChecked = isArray(checkbox) ? checkbox[1] : undefined;

                  return (
                    <FormContext.Provider
                      key={i}
                      value={{
                        name: makeName(name, label),
                        value: get(value, ['checkboxes', label]),
                        onChange: v => handleChange({ ...get(value, 'checkboxes', {}), [label]: v }),
                      }}
                    >
                      <SingleCheckboxInput
                        label={intl.formatMessage({ id: label, defaultMessage: label })}
                        ifChecked={ifChecked}
                        error={!!error}
                        omitNoneIfChecked
                      />
                    </FormContext.Provider>
                  );
                })}
                {!omitnone && (
                  <FormContext.Provider
                    value={{
                      name: makeName(name, 'none'),
                      value: get(value, 'progress', 0) > 0 && isEmpty(get(value, 'checkboxes')),
                      onChange: v => {
                        onChange(
                          v ? { checkboxes: [], progress: omitnone ? 0 : 1 } : { checkboxes: null, progress: 0 }
                        );
                      },
                    }}
                  >
                    <Paper
                      elevation={get(value, 'progress', 0) === 0 ? 1 : 0}
                      className={clsx(classes.noneOf, {
                        [classes.emphasized]: get(value, 'progress', 0) === 0,
                      })}
                    >
                      <SingleCheckboxInput
                        label={
                          <FormattedMessage id="questionnaire.checkboxes.none" defaultMessage="None of the above" />
                        }
                        error={!!error}
                      />
                    </Paper>
                  </FormContext.Provider>
                )}
              </FormGroup>
            );
          }}
        </FormContext.Consumer>

        {!!error && (
          <FormHelperText style={{ color: 'red' }}>
            <TranslatedMessage message={error} />
          </FormHelperText>
        )}

        {explanation && (
          <FormHelperText>
            <TranslatedMessage message={explanation} />
          </FormHelperText>
        )}
      </FormControl>
    </InputWrapper>
  );
};

CheckboxesInput.defaultValue = { checkboxes: {}, progress: 0 };

export default CheckboxesInput;
