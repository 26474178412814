import PropTypes from 'prop-types';
import color from 'color';
import merge from 'lodash/merge';

const textSize = {
  xs: '0.75rem',
  s: '0.875rem',
  m: '1rem',
  l: '1.2rem',
  xl: '1.5rem',
};

const lineHeight = {
  xs: `calc(1.3 * ${textSize.xs})`,
  s: `calc(1.4 * ${textSize.s})`,
  m: `calc(1.5 * ${textSize.m})`,
  l: `calc(1.35 * ${textSize.l})`,
  xl: `calc(1.4 * ${textSize.xl})`,
};

const fontWeight = {
  bold: '600',
  medium: '500',
  regular: '400',
  thin: '300',
};

const colors = {
  text: color('#071334'),
  danger: color('#E34141'),
  warning: color('#ffc100'),
  primary: color('#00A7F7'),
  background: color('white'),
};

const defaultSpacing = '1rem';

const border = {
  width: {
    default: '0.125rem',
  },
  radius: {
    default: '0.125rem',
  },
  color: {
    default: color('#E6ECEF'),
    focus: colors.primary,
    warning: colors.warning,
  },
};

const button = {
  default: {
    backgroundColor: color('#E9EFF0'),
    color: colors.text,
    disabled: {
      opacity: '0.4',
    },
  },
  primary: {
    backgroundColor: colors.primary,
    color: color('#fff'),
    disabled: {
      opacity: '0.4',
    },
  },
  danger: {
    backgroundColor: colors.danger,
    color: color('#fff'),
    disabled: {
      opacity: '0.4',
    },
  },
};

const link = {
  primaryColor: colors.primary,
  dangerColor: colors.danger,
  warningColor: colors.warning,
  disabled: {
    opacity: '0.4',
  },
  underlined: false,
};

const list = {
  backgroundColor: colors.background.darken(0.05),
  backgroundColorHover: colors.background.darken(0.1),
};

const input = {
  padding: `calc(${defaultSpacing} * 0.75 - ${border.width.default})`,
  backgroundColor: 'transparent',
  disabled: {
    opacity: '0.4',
  },
};

const sidebar = {
  backgroundColor: colors.background,
  textColor: colors.text,
};

/**
 * Default theme values.
 */
const theme = {
  defaultSpacing,
  backgroundColor: colors.background,
  textColor: colors.text,
  linkColor: colors.primary,
  dangerColor: colors.danger,
  warningColor: colors.warning,
  warningTextColor: colors.warning.darken(0.8),
  textSize,
  labelOpacity: 0.45,
  lineHeight,
  fontWeight,
  border,
  transition: '150ms all ease-in-out',
  button,
  link,
  list,
  input,
  fontFamily: {
    fixedWidth: 'monospace',
  },
  sidebar,
  successColor: color('#009624'),
};

export default theme;

export const types = {
  size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
};

export const withTheme = f => props => f(merge({}, theme, props.theme), props);
