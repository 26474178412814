import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import DefaultForm from '../Form';
import SubmitAndDangerLinkButtonForm from '../forms/SubmitAndDangerLinkButtonForm';
import { isDefinedAndNotNull, isFunction } from '../../utilities/check';
import EntityLoadingState from '../entity-states/EntityLoadingState';
import EntityErrorState from '../entity-states/EntityErrorState';
import EntityEmptyState from '../entity-states/EntityEmptyState';

const EntityEdit = props => {
  const {
    backUrlProvider,
    onRemoveUrlProvider,
    idProvider,
    data,
    getValue,
    Input,
    handleUpdate,
    handleRemove,
    history,
    Loading,
    Error,
    Empty,
    replace,
  } = props;

  const handleUpdateSubmit = async data => {
    await handleUpdate(data);

    if (isFunction(backUrlProvider)) {
      history.replace(backUrlProvider(idProvider(props)));
    }
  };

  const handleRemoveSubmit = data => async () => {
    if (window.confirm('Are you sure you want to delete this record?')) {
      await handleRemove(data);

      if (isFunction(onRemoveUrlProvider)) {
        history.replace(onRemoveUrlProvider());
      }
    }
  };

  const Form = handleRemove ? SubmitAndDangerLinkButtonForm : DefaultForm;
  const value = getValue(data);
  const hasValue = isDefinedAndNotNull(value);

  return (
    <Modal title="Edit" closeTo={backUrlProvider(idProvider(props))} replace={replace}>
      {data.loading ? (
        <Loading />
      ) : data.error ? (
        <Error error={data.error} />
      ) : !hasValue ? (
        <Empty />
      ) : (
        <Form
          autoFocus
          initialValue={value}
          input={Input}
          label={'Update'}
          onDangerLinkButtonClick={handleRemove ? handleRemoveSubmit(idProvider(props)) : undefined}
          onSubmit={handleUpdateSubmit}
          context={{
            isCreate: false,
            isEdit: true,
          }}
        />
      )}
    </Modal>
  );
};

EntityEdit.defaultProps = {
  Loading: EntityLoadingState,
  Error: EntityErrorState,
  Empty: EntityEmptyState,
};

EntityEdit.propTypes = {
  backUrlProvider: PropTypes.func,
  onRemoveUrlProvider: PropTypes.func,
  idProvider: PropTypes.func.isRequired,
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    error: PropTypes.any,
  }),
  getValue: PropTypes.func.isRequired,
  Input: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleRemove: PropTypes.func,
  history: PropTypes.object.isRequired,
  Loading: PropTypes.func.isRequired,
  Error: PropTypes.func.isRequired,
  Empty: PropTypes.func.isRequired,
};

export default EntityEdit;
