import get from 'lodash/get';
import isArray from 'lodash/isArray';

interface Reducer<T> {
  (questions: any, answers?: any): T;
  spec: ReducerSpec<T>;
  with<O extends T>(overrides: Partial<ReducerSpec<O>>): Reducer<O>;
}

interface Section<T> {
  name: string;
  child: T;
}

interface Field<T> {
  name: string;
  child: T;
}

interface Checkbox<T> {
  label: string;
  checked: boolean;
  child?: T;
}

interface Option {
  label: string;
  selected: boolean;
}

interface Selection<T> {
  label: string;
  child?: T;
}

interface ReducerSpec<T> {
  Sections: (sections: Section<T>[]) => T;
  Object: (fields: Field<T>[]) => T;
  Text: (label: string, text?: string) => T;
  LongText: (label: string, text?: string) => T;
  Checkboxes: (label: string, checkboxes: Checkbox<T>[]) => T;
  List: (label: string, children: T[]) => T;
  YesNo: (question: string, positive?: boolean, child?: T) => T;
  OneOf: (label: string, options: Option[], answer?: Selection<T>) => T;
  Medication: (value?: string) => T;
  BodyMassIndex: (heightFt?: number, heightIn?: number, weightLb?: number) => T;
  CityStateZip: (city?: string, state?: string, zipCode?: string) => T;
  DateOfBirth: (date?: Date) => T;
  Date: (date?: Date) => T;
}

export default function createReducer<T>(spec: ReducerSpec<T>): Reducer<T> {
  const reducer: Reducer<T> = Object.assign(
    (questions: any, answers: any) => {
      const nodeType = get(questions, 'type');
      const lowerCaseNodeType = nodeType.toLowerCase();

      switch (lowerCaseNodeType) {
        case 'sections':
          return spec.Sections(
            get(questions, 'props.sections', []).map(([name, q]: any) => ({
              name,
              child: reducer(q, get(answers, ['sections', name])),
            }))
          );
        case 'object':
          return spec.Object(
            get(questions, 'props.schema', []).map(([name, q]: any) => ({
              name,
              child: reducer(q, get(answers, ['schema', name])),
            }))
          );
        case 'text':
          return spec.Text(get(questions, 'props.label'), get(answers, 'text'));
        case 'longtext':
          return spec.LongText(get(questions, 'props.label'), get(answers, 'text'));
        case 'checkboxes':
          return spec.Checkboxes(
            get(questions, 'props.label', []),
            get(questions, 'props.checkboxes', []).map((checkbox: any) => {
              if (isArray(checkbox)) {
                const [label, q] = checkbox;
                const response = get(answers, ['checkboxes', label]);
                const child = reducer(q, get(answers, ['checkboxes', label, 1]));
                if (isArray(response)) {
                  return { label, checked: !!response[0], child };
                } else {
                  return { label, checked: !!response, child };
                }
              } else {
                const response = get(answers, ['checkboxes', checkbox]);

                if (isArray(response)) {
                  return { label: checkbox, checked: !!response[0] };
                } else {
                  return { label: checkbox, checked: !!response };
                }
              }
            })
          );
        case 'list': {
          const item = get(questions, 'props.item');
          return spec.List(
            get(questions, 'props.label'),
            get(answers, 'list', []).map((answer: any) => reducer(item, answer))
          );
        }
        case 'yesno': {
          const question = get(questions, 'props.question');
          const answer = get(answers, 'yes');
          const ifYes = get(questions, 'props.ifYes');
          const ifNo = get(questions, 'props.ifNo');

          if (isArray(answer)) {
            const [positive] = answer;

            return spec.YesNo(
              question,
              positive,
              positive
                ? ifYes && reducer(ifYes, get(answers, ['yes', 1]))
                : ifNo && reducer(ifNo, get(answers, ['yes', 1]))
            );
          } else {
            return spec.YesNo(question, answer);
          }
        }
        case 'oneof': {
          const selectedOption = get(answers, 'option');

          let child;

          const options = get(questions, 'props.options', []).map((option: any) => {
            if (isArray(option)) {
              const [, questions] = option;
              child = reducer(questions, get(answers, ['option', 1]));
              if (selectedOption && selectedOption[0] === option[0]) {
                return {
                  label: option[0],
                  selected: true,
                  child,
                };
              } else {
                return {
                  label: option[0],
                  selected: false,
                };
              }
            }

            const thisLabel = isArray(option) ? option[0] : option;

            return {
              label: thisLabel,
              selected: isArray(selectedOption) ? thisLabel === selectedOption[0] : thisLabel === selectedOption,
            };
          });

          const answer = selectedOption
            ? {
                label: isArray(selectedOption) ? selectedOption[0] : selectedOption,
                child: options.find((option: any) => option.selected)?.child,
              }
            : undefined;

          return spec.OneOf(get(questions, 'props.label', ''), options, answer);
        }
        case 'medication':
          return spec.Medication(get(answers, 'text'));
        case 'bodymassindex': {
          const heightFtInt = parseInt(get(answers, 'bodyMassIndex.heightFt'), 10);
          const heightInInt = parseInt(get(answers, 'bodyMassIndex.heightIn'), 10);
          const weightLbInt = parseInt(get(answers, 'bodyMassIndex.weightLb'), 10);
          const heightFt = isNaN(heightFtInt) ? undefined : heightFtInt;
          const heightIn = isNaN(heightInInt) ? undefined : heightInInt;
          const weightLb = isNaN(weightLbInt) ? undefined : weightLbInt;
          return spec.BodyMassIndex(heightFt, heightIn, weightLb);
        }
        case 'citystatezip': {
          const city = get(answers, 'cityStateZip.city');
          const state = get(answers, 'cityStateZip.state');
          const zipCode = get(answers, 'cityStateZip.zipCode');
          return spec.CityStateZip(city, state, zipCode);
        }
        case 'dateofbirth': {
          const year = parseInt(get(answers, 'dateOfBirth.year'), 10);
          const month = parseInt(get(answers, 'dateOfBirth.month'), 10);
          const day = parseInt(get(answers, 'dateOfBirth.day'), 10);
          const date = new Date(year, month - 1, day);

          if (isNaN(date.getTime())) {
            return spec.DateOfBirth();
          } else {
            return spec.DateOfBirth(date);
          }
        }
        case 'date': {
          const year = parseInt(get(answers, 'date.year'), 10);
          const month = parseInt(get(answers, 'date.month'), 10);
          const day = parseInt(get(answers, 'date.day'), 10);
          const date = new Date(year, month - 1, day);

          if (isNaN(date.getTime())) {
            return spec.Date();
          } else {
            return spec.Date(date);
          }
        }
        default:
          console.warn('Ignoring unknown node type', nodeType, questions, answers);
          throw new Error(`Unknown node type ${nodeType}`);
      }
    },
    {
      spec,
      with: <O extends T>(overrides: Partial<ReducerSpec<O>>) => createReducer<O>(Object.assign({}, spec, overrides)),
    }
  );

  return reducer;
}
