import React, { FC, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ExcelGenerator from '../ExcelGenerator';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import isString from 'lodash/isString';
import { InfoOutlined } from '@material-ui/icons';
import procedures from '../../assets/images/illustrations/procedures.svg';
import EntityState from '../../se/components/EntityState';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { tryParseJson, tryStringifyJson } from '../../util/parseJson';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  table: {
    width: '100%',
    overflowX: 'auto',
  },
  tableRow: {
    cursor: 'pointer',
  },
  paper: {
    width: '100%',
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    padding: theme.spacing(1),
  },
}));

const ColumnSelection: FC<{
  columns: string[];
  selectedColumns: string[];
  handleColumnToggle: (column: string) => void;
}> = ({ columns, selectedColumns, handleColumnToggle }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-controls="settings-menu" aria-haspopup="true" onClick={handleClick} color="inherit">
        <SettingsIcon/>
      </IconButton>
      <Menu id="dropdown-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <Box display="flex" flexDirection="column" p={2}>
          {columns.map((column, index) => (
            <FormControlLabel
              key={column}
              control={
                <Checkbox
                  checked={selectedColumns.includes(column)}
                  onChange={() => handleColumnToggle(column)}
                  value={column}
                />
              }
              label={column}
            />
          ))}
        </Box>
      </Menu>
    </div>
  );
};

export type AlignType = 'left' | 'right';

export type OnClickFn = (id: string) => void;

export type ColumnType =
  | {
  text: string;
  colSpan?: number;
  align?: AlignType;
  InfoComponent?: FC;
}
  | string;

export type RowType = {
  id: string;
  onClick?: OnClickFn;
  columns: ColumnType[];
};

export type TableType = {
  configHeader: ColumnType[];
  headers: RowType[];
  rows: RowType[];
};

const toText = (column: ColumnType): string => (isString(column) ? column : column?.text);
const toColSpan = (column: ColumnType): number => (isString(column) ? 1 : column?.colSpan || 1);
const toAlign = (column: ColumnType, defaultAlign: AlignType = 'left'): AlignType =>
  isString(column) ? defaultAlign : column?.align || 'left';
const toInfoComponent = (column: ColumnType): FC | null => (isString(column) ? null : column?.InfoComponent || null);
const toExcelData = (headers: RowType[], rows: RowType[], config: number[]): string[][] => [
  ...headers.map(e => e.columns.filter((e, i) => config.includes(i)).map(e => toText(e))),
  ...rows.map(e => e.columns.filter((e, i) => config.includes(i)).map(e => toText(e))),
];

const MyTableCell: FC<{ column: ColumnType; defaultAlign: AlignType }> = ({ column, defaultAlign = 'left' }) => {
  const classes = useStyles();
  const InfoComponent = toInfoComponent(column);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [showMore, setShowMore] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (InfoComponent) {
    return (
      <>
        <TableCell align={toAlign(column, defaultAlign)} colSpan={toColSpan(column)}>
          <Box display="flex" flexDirection="row" alignItems="right" justifyContent="right" style={{ gap: '0.5em' }}>
            <Box whiteSpace="nowrap">{toText(column)}</Box>
            <IconButton size="small" aria-describedby={id} onClick={handleClick}>
              <InfoOutlined fontSize="small" color="primary"/>
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <InfoComponent/>
            </Popover>
          </Box>
        </TableCell>
      </>
    );
  }

  const text = toText(column);
  const isLargerText = text?.length > 38;

  if (isLargerText) {
    return (
      <TableCell align={toAlign(column, defaultAlign)} colSpan={toColSpan(column)}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          {isLargerText && (<IconButton onClick={() => setShowMore(v => !v)}>
            {showMore ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
          </IconButton>)}
          <Box style={showMore ? { width: '20em' } : {
            whiteSpace: 'nowrap',
            maxWidth: '20em',
            overflow: 'hidden',
          }}>{text}</Box>
        </Box>
      </TableCell>
    );
  }

  return (
    <TableCell style={{ whiteSpace: 'nowrap' }} align={toAlign(column, defaultAlign)} colSpan={toColSpan(column)}>
      {text}
    </TableCell>
  );
};

const TableWithColumnSelector: FC<{
  tableId: string;
  configName: string;
  configHeader: ColumnType[];
  headers: RowType[];
  rows: RowType[];
  tableName: string;
  excelFileName?: string;
  withExportFile?: boolean;
}> = ({ tableId, configName, configHeader, headers, rows, tableName, excelFileName, withExportFile = true }) => {
  const classes = useStyles();
  const tableHeader: string[] = configHeader?.map(toText) || [];
  const [selectedColumns, setSelectedColumns] = useState(tableHeader);

  useEffect(() => {
    const defaultConfig = tryParseJson(localStorage.getItem(configName));
    if (defaultConfig && Array.isArray(defaultConfig)) {
      setSelectedColumns(defaultConfig);
    }
  }, [configName]);

  const handleColumnToggle = column => {
    const temp = selectedColumns.includes(column)
      ? selectedColumns.filter(col => col !== column)
      : [...selectedColumns, column];
    setSelectedColumns(temp);
    const tempString = tryStringifyJson(temp);
    tempString && localStorage.setItem(configName, tempString);
  };

  if (tableHeader?.length <= 0) {
    return null;
  }

  const tableIndexes: number[] = tableHeader.reduce((res, e, i) => {
    if (selectedColumns.includes(e)) {
      return [...res, i];
    } else {
      return res;
    }
  }, [] as number[]);

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.root}>
        <Box display="flex" flex={1} flexDirection="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">{tableName}</Typography>
          <Box display="flex">
            {withExportFile && (<ExcelGenerator excelFileName={excelFileName} data={toExcelData(headers, rows, tableIndexes)}/>)}
            <ColumnSelection
              columns={tableHeader}
              selectedColumns={selectedColumns}
              handleColumnToggle={handleColumnToggle}
            />
          </Box>
        </Box>
      </Toolbar>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            {headers.map(row => {
              const rowId = row?.id;
              const configured = row.columns.filter((e, i) => tableIndexes.includes(i));
              return (
                <TableRow key={`${tableId}-${rowId}`}>
                  {configured.map((column, i) => (
                    <MyTableCell key={`${tableId}-${rowId}-${i}`} column={column}
                                 defaultAlign={i === 0 ? 'left' : 'right'}/>
                  ))}
                </TableRow>
              );
            })}
          </TableHead>
          <TableBody>
            {rows && rows.length > 0 ? (
              rows.map(row => {
                const rowId = row?.id;
                const configured = row.columns.filter((e, i) => tableIndexes.includes(i));
                return (
                  <TableRow className={classes.tableRow} key={`${tableId}-${rowId}`} onClick={() => row?.onClick?.(rowId)} hover>
                    {configured.map((column, i) => (
                      <MyTableCell key={`${tableId}-${rowId}-${i}`} column={column}
                                   defaultAlign={i === 0 ? 'left' : 'right'}/>
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={headers[0].columns.length}>
                  <Box py={20}>
                    <EntityState title="There is no data for this period" illustration={procedures}/>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableWithColumnSelector;
