import { AnesthesiaTableRow } from './AnesthesiaTableV1';
import { sortBy } from 'lodash';

export const defaultRows: AnesthesiaTableRow[] = [
  {
    label: 'Oxygen (O2)',
    unit: 'L/min',
    value: 'Oxy',
  },
  {
    label: 'Air',
    unit: 'L/min',
    value: 'Air',
  },
  {
    label: 'SEVO (Sevoflurane)',
    unit: '%',
    value: 'Sevo',
  },
  {
    label: 'Propofol',
    unit: 'mg',
    value: 'Prop',
  },
  {
    label: 'Lidocaine',
    unit: 'mg',
    value: 'Lido',
  },
  {
    label: 'Rocuronium',
    unit: 'mg',
    value: 'Rocu',
  },
  {
    label: 'Succinylcholine',
    unit: 'mg',
    value: 'Succ',
  },
  {
    label: 'Fentanyl',
    unit: 'mcg',
    value: 'Fent',
  },
  {
    label: 'Decadron (Dexamethasone)',
    unit: 'mg',
    value: 'Dec',
  },
  {
    label: 'Zofran (Ondansetron)',
    unit: 'mg',
    value: 'Zof',
  },
  {
    label: 'Ephedrine',
    unit: 'mg',
    value: 'Eph',
  },
  {
    label: 'Versed',
    unit: 'mg',
    value: 'Vers',
  },
  {
    label: 'Temp',
    value: 'Temp',
  },
  {
    label: 'EKG',
    value: 'Ekg',
  },
  {
    label: 'SPO2',
    value: 'Spo2',
  },
  {
    label: 'ETCO2',
    value: 'Etco2',
  },
  {
    label: 'FIO2',
    value: 'Fio2',
  },
  {
    label: 'Vent',
    value: 'Vent',
  },
  {
    label: 'RR',
    value: 'Rr',
  },
  {
    label: 'PIP',
    value: 'Pip',
  },
  {
    label: 'Position Check',
    value: 'Pos',
  },
];
export const medicationRows: AnesthesiaTableRow[] = [
  {
    label: 'Oxygen (O2)',
    unit: 'L/min',
    value: 'Oxy',
  },
  {
    label: 'Air',
    unit: 'L/min',
    value: 'Air',
  },
  {
    label: 'SEVO (Sevoflurane)',
    unit: '%',
    value: 'Sevo',
  },
  {
    label: 'Propofol',
    unit: 'mg',
    value: 'Prop',
  },
  {
    label: 'Lidocaine',
    unit: 'mg',
    value: 'Lido',
  },
  {
    label: 'Rocuronium',
    unit: 'mg',
    value: 'Rocu',
  },
  {
    label: 'Succinylcholine',
    unit: 'mg',
    value: 'Succ',
  },
  {
    label: 'Fentanyl',
    unit: 'mcg',
    value: 'Fent',
  },
  {
    label: 'Decadron (Dexamethasone)',
    unit: 'mg',
    value: 'Dec',
  },
  {
    label: 'Zofran (Ondansetron)',
    unit: 'mg',
    value: 'Zof',
  },
  {
    label: 'Ephedrine',
    unit: 'mg',
    value: 'Eph',
  },
  {
    label: 'Versed',
    unit: 'mg',
    value: 'Vers',
  },
];
export const otherRows: AnesthesiaTableRow[] = [
  {
    label: 'Temp',
    value: 'Temp',
  },
  {
    label: 'EKG',
    value: 'Ekg',
  },
  {
    label: 'SPO2',
    value: 'Spo2',
  },
  {
    label: 'ETCO2',
    value: 'Etco2',
  },
  {
    label: 'FIO2',
    value: 'Fio2',
  },
  {
    label: 'Vent',
    value: 'Vent',
  },
  {
    label: 'RR',
    value: 'Rr',
  },
  {
    label: 'PIP',
    value: 'Pip',
  },
  {
    label: 'Position Check',
    value: 'Pos',
  },
];

export const additionalRows: AnesthesiaTableRow[] = sortBy(
  [
    {
      label: 'Atropine',
      unit: 'mg',
      value: 'Atro',
    },
    {
      label: 'Demerol',
      unit: 'mg',
      value: 'Dem',
    },
    {
      label: 'Diphenhydramine',
      unit: 'mg',
      value: 'Diph',
    },
    {
      label: 'Esmolol',
      unit: 'mg',
      value: 'Esm',
    },
    {
      label: 'Epinephrine',
      unit: 'mcg',
      value: 'Epin',
    },
    {
      label: 'Flumazenil',
      unit: 'mg',
      value: 'Flum',
    },
    {
      label: 'Furosemide',
      unit: 'mg',
      value: 'Furo',
    },
    {
      label: 'Glycopyrrolate',
      unit: 'mg',
      value: 'Gly',
    },
    {
      label: 'Hydralazine',
      unit: 'mg',
      value: 'Hydra',
    },
    {
      label: 'Hydromorphone',
      unit: 'mg',
      value: 'Hydro',
    },
    {
      label: 'Labetalol',
      unit: 'mg',
      value: 'Labet',
    },
    {
      label: 'Narcan',
      unit: 'mg',
      value: 'Narc',
    },
    {
      label: 'Neostigmine',
      unit: 'mg',
      value: 'Neo',
    },
    {
      label: 'Phenylephrine',
      unit: 'mg',
      value: 'Phenyl',
    },
    {
      label: 'Phenergan',
      unit: 'mg',
      value: 'Phener',
    },
    {
      label: 'Propranolol',
      unit: 'mg',
      value: 'Propran',
    },
    {
      label: 'Reglan',
      unit: 'mg',
      value: 'Regl',
    },
    {
      label: 'Sugammadex',
      unit: 'mg',
      value: 'Suga',
    },
    {
      label: 'Toradol',
      unit: 'mg',
      value: 'Tora',
    },
    {
      label: 'Tranexamic acid (TXA)',
      unit: 'g',
      value: 'Tran',
    },
  ],
  ['label'],
);
