import React, { Fragment } from 'react';
import { format } from 'date-fns';

import AlertBar from './AlertBar';

export const OfflineIndicator = ({ changedAt }) => (
  <AlertBar>
    {/* prettier-ignore */}
    <span>The system is currently offline. Attempting to restore connection...&nbsp;</span>
    {changedAt && (
      <Fragment>
        <span>{`Last time info received ${format(changedAt, 'MM/DD/YYYY')} @`}&nbsp;</span>
        <strong>{format(changedAt, 'HH:mm')}</strong>
      </Fragment>
    )}
  </AlertBar>
);
