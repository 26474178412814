import React, { ReactNode, useState } from 'react';
import { optionalText } from '../tablet/utils';
import { Box, Chip, Collapse, Link, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ArrowBackTwoTone, ExpandLessRounded, ExpandMoreRounded, LinkRounded } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import FallRiskWarning, { FallRiskWarningProps } from './components/FallRiskWarning';
import { Link as RouterLink, matchPath, withRouter } from 'react-router-dom';
import { generatePath, RouteComponentProps } from 'react-router';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import AllergiesWarning from '../../../../allergies/AllergiesWarning';
import AllergiesView from '../../../../allergies/AllergiesView';

export const InfoBox = ({
  label,
  name,
  info,
  children,
  allergies,
  fallRiskAssessment,
  compact,
}: {
  label: ReactNode;
  name: ReactNode;
  info?: ReactNode;
  children?: ReactNode;
  allergies?: ReactNode;
  fallRiskAssessment?: FallRiskWarningProps;
  compact?: boolean;
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.infoBox, { [classes.infoBoxCompact]: compact })}>
      {!compact && (
        <Typography color="textSecondary" className={classes.patientName}>
          {label}
          <AllergiesWarning allergies={allergies} />
          {fallRiskAssessment && <FallRiskWarning fallRiskAssessment={fallRiskAssessment} />}
        </Typography>
      )}
      <Typography variant={compact ? 'h5' : 'h4'}>{name}</Typography>
      {info && (
        <Typography className={classes.ellipsis} variant={compact ? 'subtitle2' : 'body1'}>
          {info} {compact && <AllergiesWarning allergies={allergies} />}{' '}
          {compact && fallRiskAssessment && <FallRiskWarning fallRiskAssessment={fallRiskAssessment} />}
        </Typography>
      )}
      {children}
    </Box>
  );
};

const PatientInfo = ({
  onBack,
  patientName,
  physicianName,
  anesthesiologistName,
  anesthesiaType,
  anesthesiaReviewed,
  procedureTypeName,
  enteredAt,
  dateOfBirth,
  age,
  sex,
  hstId,
  fallRiskAssessment,
  allergies,
  compact,
  keepOpen = false,
  save,
}: {
  onBack?: () => void;
  patientName?: string;
  physicianName?: string;
  anesthesiologistName?: string;
  anesthesiaType?: string;
  anesthesiaReviewed?: boolean;
  procedureTypeName?: string;
  enteredAt?: string;
  dateOfBirth?: string;
  age?: string;
  sex?: string;
  hstId?: number;
  fallRiskAssessment?: string;
  allergies: unknown;
  compact?: boolean;
  keepOpen?: boolean;
  save?: () => void;
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(keepOpen);

  return (
    <Box>
      <Box className={classes.root}>
        {/*{onBack && <BackBar onClick={onBack} />}*/}
        {onBack && (
          <>
            <Box display="flex" alignItems="center">
              <IconButton
                onClick={onBack}
                classes={{
                  root: classes.backButton,
                }}
              >
                <ArrowBackTwoTone color="primary" />
              </IconButton>
            </Box>

            <Box>
              <Divider orientation="vertical" />
            </Box>
          </>
        )}
        <Box onClick={() => setOpen(keepOpen ? true : !open)} className={classes.info}>
          <InfoBox compact={compact} label="Physician" name={optionalText(physicianName)}>
            <Collapse in={open} collapsedSize={24}>
              <Typography variant={compact ? 'subtitle2' : 'body1'}>{optionalText(procedureTypeName)}</Typography>
            </Collapse>
          </InfoBox>

          <InfoBox
            compact={compact}
            label="Anesthesiologist"
            name={optionalText(anesthesiologistName)}
            info={`Anesthesia type ${optionalText(anesthesiaType)}`}
          >
            <Collapse in={open}>
              <Box pt={2}>
                <Typography gutterBottom>Reviewed</Typography>
                <Typography variant="h5">{anesthesiaReviewed ? 'Yes' : 'No'}</Typography>
              </Box>
            </Collapse>
          </InfoBox>

          <Box flex={1} alignItems="center" display="flex" justifyContent="space-between" minWidth={0}>
            <InfoBox
              compact={compact}
              label="Patient"
              name={<PatientName save={save}>{optionalText(patientName)}</PatientName>}
              info={optionalText(`${dateOfBirth || ''} • ${age} • ${sex}`)}
              allergies={allergies}
              fallRiskAssessment={fallRiskAssessment}
            >
              <Collapse in={open}>
                <Box pt={2}>
                  <Box mb={3} display="flex" style={{ gap: 32 }}>
                    <Box>
                      <Typography gutterBottom color="textSecondary">
                        Allergies
                      </Typography>
                      <Typography variant="h5">
                        <AllergiesView short allergies={allergies} />
                      </Typography>
                    </Box>
                  </Box>

                  <Box display="flex" style={{ gap: 24 }}>
                    <Box>
                      <Typography gutterBottom color="textSecondary">
                        Entered at
                      </Typography>
                      <Typography variant="h5">{enteredAt || ''}</Typography>
                    </Box>

                    <Box>
                      <Divider orientation="vertical" />
                    </Box>

                    <Box display="flex" style={{ gap: 32 }}>
                      <Box>
                        <Typography gutterBottom color="textSecondary">
                          HST ID
                        </Typography>
                        <Typography variant="h5">{hstId}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Collapse>
            </InfoBox>
          </Box>
        </Box>
      </Box>
      {!compact && !keepOpen ? (
        <Box display="flex" alignItems="center" style={{ gap: 16 }} onClick={() => setOpen(keepOpen ? true : !open)}>
          <Box flex={1}>
            <Divider />
          </Box>
          <Chip
            clickable
            icon={open ? <ExpandLessRounded /> : <ExpandMoreRounded />}
            variant="outlined"
            label="Details"
          />
          <Box flex={1}>
            <Divider />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '5% 2% 94%',
    marginBottom: theme.spacing(2),
  },
  info: {
    display: 'flex',
    gap: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  infoBox: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    overflow: 'hidden',
    minWidth: 0,
  },
  backButton: {
    backgroundColor: theme.palette.secondary.light,
  },
  infoBoxCompact: {
    gap: theme.spacing(0),
  },
  patientName: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  ellipsis: {
    minWidth: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export default PatientInfo;

interface PatientNameProps {
  children?: string;
  save?: () => void;
}

const PatientName = withRouter(({ history, children, save }: PatientNameProps & RouteComponentProps) => {
  // const match = matchPath(history.location.pathname, '/kiosk/:id(d+)/charting/:chart(w+-chart)/:chartId(d+)');
  const match = matchPath<{
    id: string;
    chart: string;
    chartId: string;
  }>(history.location.pathname, '/kiosk/:id(\\d+)/charting/:chart(.+-chart)/:chartId(\\d+)');

  const handleClick = () => {
    if (save) {
      save();
    }
  };

  if (match) {
    return (
      <Link
        component={RouterLink}
        to={
          generatePath('/kiosk/:id/charting/:chart/:patientId', {
            id: match.params.id,
            chart: match.params.chart.replace('-chart', ''),
            patientId: match.params.chartId,
          }) + history.location.search
        }
        onClick={handleClick}
      >
        <Box component="span" display="flex" alignItems="center" sx={{ gap: 8 }}>
          {children}
          <Typography color="secondary" style={{ fontWeight: 'bold' }}>
            Documents
          </Typography>
        </Box>
      </Link>
    );
  } else {
    return <>{children}</>;
  }
});
