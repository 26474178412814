import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { setPatientReadyInPACU } from '../../../../graph/patients';

const withSetPatientReadyInPACU = Component => props =>
  (
    <Mutation mutation={setPatientReadyInPACU}>
      {setPatientReadyInPACU => <Component {...props} setPatientReadyInPACU={setPatientReadyInPACU} />}
    </Mutation>
  );

export default withSetPatientReadyInPACU;
