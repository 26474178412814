import React from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import { PhysicianCategory, PhysicianCategoryLabels } from '../../entities/physician/enums';
import { findIndex } from 'lodash';

const a11yProps = idx => ({
  id: `cat-filter-${idx}`,
  'aria-controls': `cat-filter-${idx}`,
});

const CategoryPicker = ({ value, onChange }) => {
  const categories = Object.keys(PhysicianCategory);
  const handleChange = (evt, newVal) => {
    if (newVal) {
      onChange(categories[newVal - 1]);
    } else {
      onChange(undefined);
    }
  };
  return (
    <Box>
      <Tabs
        value={value ? findIndex(categories, v => v === value) + 1 : 0}
        onChange={handleChange}
        aria-label="Category Filter"
        indicatorColor={'primary'}
      >
        <Tab label={'All'} {...a11yProps(0)} index={0} />
        {categories.map((cat, i) => (
          <Tab key={i + 1} label={PhysicianCategoryLabels[cat]} {...a11yProps(i + 1)} index={i + 1} />
        ))}
      </Tabs>
    </Box>
  );
};

export default CategoryPicker;
