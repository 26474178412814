import { useStyles as useFileUploaderStyles } from '../FileUploader';
import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
// @ts-ignore
import SimpleReactLightbox from 'simple-react-lightbox';
import { UploadSection } from '../../types/Upload';
import FileUploaderSection from './FileUploaderSection';
import {
  confirmDocumentUpload,
  hospitalUploadedDocuments,
  removeHospitalUploadedDocument,
  uploadDocumentRequest,
} from '../../graph/hospitals';
import useHasAccessRight from '../../hooks/useHasAccessRight';

interface FileUploaderProps {
  entityId: number;
  entity: any;
  uploadSections: UploadSection[];
}

const FileUploader: FC<FileUploaderProps> = ({ entityId, entity, uploadSections }) => {
  const classes = useFileUploaderStyles();
  const hasAccessRight = useHasAccessRight();
  const isAllowedToView = hasAccessRight('hospital.view');
  const isAllowedToEdit = hasAccessRight('hospital.edit');

  return (
    <Grid container spacing={1}>
      {uploadSections?.map((section: UploadSection, index: number) => (
        <SimpleReactLightbox key={index}>
          <Grid
            item
            xs={12}
            sm={section.mainCategory ? 12 : uploadSections.length >= 2 ? 6 : false}
            md={section.mainCategory ? 12 : uploadSections.length >= 3 ? 4 : false}
            lg={section.mainCategory ? 12 : uploadSections.length >= 3 ? 4 : false}
            key={index}
            className={classes.gridItem}
          >
            <FileUploaderSection
              isAllowedToView={isAllowedToView}
              isAllowedToEdit={isAllowedToEdit}
              entityId={entityId}
              entity={entity}
              section={section}
              uploadedFilesQuery={hospitalUploadedDocuments}
              deleteUploadedFileMutation={removeHospitalUploadedDocument}
              confirmFileUploadMutation={confirmDocumentUpload}
              uploadFileRequestQuery={uploadDocumentRequest}
            />
          </Grid>
        </SimpleReactLightbox>
      ))}
    </Grid>
  );
};

export default FileUploader;
