import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import { calculateBMI } from '../components/BodyMassIndexField';
import { isNull, isUndefined } from '../../../../se/utilities/check';
import flatMap from 'lodash/flatMap';
import flatten from 'lodash/flatten';
import flattenDeep from 'lodash/flattenDeep';
import chunk from 'lodash/chunk';

import { createPdf } from '../../../../vendor/pdfMake';
import { getCbResponse, getCbValue, ifArrayGetSecond, respondedNone } from '../components/CheckboxesField';
import { parseSubField } from '../components/OneOfField';
import isEmpty from 'lodash/isEmpty';
import { compact } from 'lodash/array';
import {
  cbEmptyIcon,
  defaultFooter,
  elementParserFactory,
  iconText,
  makeHeader,
  openPdf,
  pad,
  removeQuestion,
  separate,
  wrapInBrackets,
} from './shared';

const wrap = el => (isArray(el) ? el : [el]);

const Line = `<svg width="530%" height="1"><line x1="0" y1="0" x2="100%" y2="0" style="stroke:rgba(0,0,0, 0.4); stroke-width: 1" /></svg>`;

const makeSubsection = fields => {
  if (fields) {
    const subsection = wrap(compact(fields)).map(el => ({ text: separate(el, true) }));
    const rows = chunk(subsection, 3).map(pad(3));
    if (rows.length > 0) {
      return {
        layout: 'noBorders',
        table: {
          widths: ['*', '*', '*'],
          headerRows: 0,
          body: rows,
        },
      };
    } else {
      return TextValueField({ value: 'None' });
    }
  } else {
    return TextValueField({ value: 'None' });
  }
};

const Sections = ({ sections, value, skipSections, skipUnderline, ...rest }) => {
  const filtered = isObject(skipSections) ? sections.filter(section => !skipSections[section[0]]) : sections;
  return filtered.map(([id, field]) => {
    const fields = parseElement(field.body, { value: get(value, `sections.${id}`), isRoot: true, ...rest });

    return {
      stack: [
        { text: field.name, style: 'sectionTitle' },
        makeSubsection(fields),
        !skipUnderline ? { svg: Line, marginTop: 5 } : undefined,
      ],
      style: 'section',
    };
  });
};

const ObjectField = ({ schema, value, ...rest }) => {
  if (!value) {
    return null;
  }

  return flatMap(schema, ([id, field]) => parseElement(field, { value: get(value, `schema.${id}`), ...rest })).filter(
    _ => !!_
  );
};

const TextField = ({ label, value, highlightAnswer, ...rest }) => {
  const text = get(value, 'text', value);

  if (!value || value === 'No' || value === '' || text === '' || text === 'No' || (!text && !value)) {
    return null;
  }
  if (isObject(label) && label.text) {
    return { text: removeQuestion(label.text), color: highlightAnswer ? 'red' : 'black', opacity: 0.6 };
  }
  if (isObject(label)) {
    return null;
  } else {
    return { text: removeQuestion(label), color: highlightAnswer ? 'red' : 'black', opacity: 0.6 };
  }
};

const TextValueField = ({ label, value, ...rest }) => TextField({ label: value, value, ...rest });

const ListField = ({ item, value, label, ...rest }) => {
  const list = get(value, 'list', []).map(answer => parseElement(item, { ...rest, value: answer }));
  return list.map(wrapInBrackets);
};

const parseNestedField = ({ checkbox, value }) => {
  const el = parseElement(checkbox[1], {
    value: ifArrayGetSecond(get(getCbValue(value), checkbox[0])),
  });

  return !el && !get(getCbResponse(value), checkbox[0])
    ? null
    : compact(
        flatten([
          TextField({
            label: checkbox[0],
            value: get(getCbResponse(value), checkbox[0]) ? 'Yes' : respondedNone(value) ? 'No' : '',
            highlightAnswer: get(getCbResponse(value), checkbox[0]) && get(checkbox[1], 'important'),
          }),
          get(getCbResponse(value), checkbox[0]) ? el : undefined,
        ])
      );
};

const CheckboxesField = ({ checkboxes, question, value }) => {
  const list = checkboxes
    .map(checkbox =>
      isArray(checkbox)
        ? parseNestedField({ checkbox, value })
        : TextField({
            label: checkbox,
            value: `${get(getCbValue(value), checkbox) ? 'Yes' : respondedNone(value) ? 'No' : ''}`,
          })
    )
    .filter(_ => !(isArray(_) && (isNull(_[0]) || _.length === 0)) && !isNull(_) && !isUndefined(_));

  return list && list.length > 0 && get(value, 'progress') > 0 && !isEmpty(get(value, 'checkboxes')) ? list : null;
};

const YesNoField = ({ ifYes, ifNo, value, question, important, isRoot }) => {
  const response = get(value, 'yes');
  if (!response) {
    return null;
  } else {
    const yesNo = isArray(response) ? response[0] : response;
    if (isRoot && get(ifYes, 'type') === 'List') {
      return flatMap([
        !!response && ifYes ? parseElement(ifYes, { value: response[1] }) : undefined,
        !important && !!response && ifNo ? parseElement(ifNo, { value: response[1] }) : undefined,
      ]);
    } else {
      return [
        flatten([
          TextField({
            label: question,
            value: yesNo ? 'Yes' : isUndefined(yesNo) || isNull(yesNo) ? '' : 'No',
            highlightAnswer: important,
          }),
          flattenDeep([
            !!response && ifYes ? parseElement(ifYes, { value: response[1] }) : undefined,
            !important && !!response && ifNo ? parseElement(ifNo, { value: response[1] }) : undefined,
          ]),
        ]),
      ];
    }
  }
};

const OneOfField = ({ label, question, value, isRoot, ...field }) => {
  const response = isArray(value) ? get(value[1], 'option') : get(value, 'option');
  const subField = parseSubField(response, field);
  if (subField) {
    return [TextValueField({ label: label, value: response[0] }), parseElement(subField, { value: response[1] })];
  } else {
    if (isRoot && response) {
      return [[TextValueField({ label: label, value: label }), TextValueField({ label: label, value: response })]];
    } else {
      return TextValueField({ label: label, value: response });
    }
  }
};

const noop = () => null;

const components = {
  Sections: Sections,
  Object: ObjectField,
  Text: TextValueField,
  LongText: TextValueField,
  Checkboxes: CheckboxesField,
  List: ListField,
  DateOfBirth: noop,
  BodyMassIndex: noop,
  YesNo: YesNoField,
  OneOf: OneOfField,
};

const parseElement = elementParserFactory(components);

const FT = 30.48;
const IN = 2.54;
const LBS = 0.453592;

export const makeBmiSection = (answers, options) => {
  const value = get(answers, 'sections.demographicInformation.schema.bmi');
  const { marginBottom = 25, fontSize } = options || {};
  const { heightFt, heightIn, weightLb } = get(value, 'bodyMassIndex', {});
  return {
    stack: [
      {
        layout: 'noBorders',
        table: {
          headerRows: 0,
          body: [
            [
              `Height: ${
                heightIn && heightFt
                  ? `${heightFt}'${heightIn}'' (${Math.round(heightFt * FT + heightIn * IN)}cm)`
                  : '-'
              }`,
              `Weight: ${weightLb ? `${weightLb} lb (${Math.round(weightLb * LBS)}kg)` : '-'}`,
              {
                text: `Body Mass Index: ${calculateBMI({ heightFt, heightIn, weightLb })}`,
                color: calculateBMI({ heightFt, heightIn, weightLb }) > 40 ? 'red' : 'inherit',
              },
            ],
          ],
        },
      },
    ],
    marginBottom: marginBottom,
    fontSize: fontSize,
  };
};

export const dayOfService = {
  stack: [
    `
    Pre-Op Visit Date_____________________Time________________BP___________P________R________T________02 Sat__________Signature_____________________
    DOS ______________________Time_________________NPO Since_____________ BP___________P________R________T________02 Sat__________Pain Level______`,
    {
      text: [
        `Hgb (10-16gm)______________ Glucose (65-200)__________________ EKG_____________________ Preg Test_______________________ Voided   `,
        iconText(cbEmptyIcon, 'YES'),
        '   ',
        iconText(cbEmptyIcon, 'NO'),
      ],
    },
    `IV: Y   N   20, 22, 24  Saline Flush/IV (   ), Site________________________ Antibiotic_______________________________Signature_____________________________`,
    {
      columns: [
        { text: 'ANESTHESIOLOGIST: ASA Class ___________________________ Airway: ', width: 'auto', marginRight: 10 },
        {
          headerRows: 0,
          layout: {
            paddingTop: () => -2,
            paddingBottom: () => -2,
            defaultBorder: false,
          },
          table: {
            body: [
              [iconText(cbEmptyIcon, 'Class I'), iconText(cbEmptyIcon, 'Class II'), iconText(cbEmptyIcon, 'N/A')],
              [iconText(cbEmptyIcon, 'Class III (base uvula)'), iconText(cbEmptyIcon, 'Class IV (uvula not seen)'), ''],
            ],
          },
        },
      ],
    },
    {
      text: [
        'Heart:  ',
        cbEmptyIcon,
        ' RRR ____________________________________________________________________________________________________________________________________',
      ],
    },
    {
      text: [
        'Lungs: ',
        cbEmptyIcon,
        ' CTAB ___________________________________________________________________________________________________________________________________',
      ],
    },
    `___________________________________________________________________________________________________________________________________________________
    ___________________________________________________________________________________________________________________________________________________
    ___________________________________________________________________________________________________________________________________________________`,
    {
      columns: [
        { text: 'ANESTHESIA PLAN: ', width: '*' },
        {
          width: 'auto',
          headerRows: 0,
          layout: {
            paddingTop: () => -2,
            paddingBottom: () => -2,
            defaultBorder: false,
          },
          table: {
            body: [
              [
                iconText(cbEmptyIcon, 'GENERAL'),
                iconText(cbEmptyIcon, 'SPINAL/EPIDURAL'),
                iconText(cbEmptyIcon, 'MAC'),
                iconText(cbEmptyIcon, 'REGIONAL'),
                iconText(cbEmptyIcon, 'CONSCIOUS SEDATION'),
                iconText(cbEmptyIcon, 'LOCAL ONLY'),
              ],
            ],
          },
        },
      ],
    },
    `RISKS AND BENEFITS explained to Patient / Surrogate Decision Maker. UNDERSTANDS AND AGREES.
    MD Signature____________________________________________________________________________ Date __________________________ Time ____________________
    `,
  ],
  style: { marginTop: 0, lineHeight: 1.6, fontSize: 8, opacity: 0.8 },
  breakMultiplePages: true,
};

// custom dayOfService component for Walnut Creek
export const dayOfService28 = {
  stack: [
    {
      text: `PreOp RN signature____________________________________________________________ Date __________________________ Time ____________________`,
      marginTop: 15,
    },
  ],
  style: { marginTop: 0, lineHeight: 1.6, fontSize: 8, opacity: 0.8 },
  breakMultiplePages: true,
};

export const dayOfService27 = {
  stack: [
    {
      text: [
        { text: `Airway:   `, bold: true },
        iconText(cbEmptyIcon, 'MP ______ 1 2 3 4   '),
        iconText(cbEmptyIcon, 'Full ROM   '),
        iconText(cbEmptyIcon, 'Nothing Loose   '),
        iconText(cbEmptyIcon, 'limited ROM   '),
        iconText(cbEmptyIcon, 'Dentures   '),
        iconText(cbEmptyIcon, 'Caps   '),
        iconText(cbEmptyIcon, '__________________________________________'),
      ],
    },
    {
      text: [
        { text: `Lungs:   `, bold: true },
        iconText(cbEmptyIcon, 'Clear   '),
        iconText(cbEmptyIcon, 'Pt denies SOB   '),
        iconText(
          cbEmptyIcon,
          '____________________________________________________________________________________________________________'
        ),
      ],
    },
    {
      text: [
        { text: `Cardiac:   `, bold: true },
        iconText(cbEmptyIcon, 'RRR   '),
        iconText(cbEmptyIcon, 'No Murmurs   '),
        iconText(cbEmptyIcon, 'Pt denies CP   '),
        iconText(cbEmptyIcon, '> 4 METS  '),
        iconText(cbEmptyIcon, '______________________________________________________________________________'),
      ],
    },
    {
      text: [
        { text: `Gastric:   `, bold: true },
        iconText(cbEmptyIcon, 'Pt NPO   '),
        iconText(cbEmptyIcon, 'GLP-1 Medication stopped 1 week   '),
        iconText(cbEmptyIcon, 'N/V   '),
      ],
    },
    {
      text: [{ text: `ASA PS:       `, bold: true }, `1       2       3       4       5       E`],
    },
    {
      text: [
        { text: `Anesthesia type:`, bold: true, decoration: 'underline' },
        `     `,
        iconText(cbEmptyIcon, 'Spinal w/MAC   '),
        iconText(cbEmptyIcon, 'General   '),
        iconText(cbEmptyIcon, 'Block   '),
        `_______________________________`,
      ],
    },
    {
      text: [
        iconText(
          cbEmptyIcon,
          "The medical record has been reviewed and the patient is cleared for anesthesia. I have discussed the risks benefits and alternatives of the anesthetic technique(s) indicated above with the patient and he/she consents to the discuessed anesthetics(s). All of the patient's questions have been answered."
        ),
      ],
    },
    {
      text: [
        iconText(
          cbEmptyIcon,
          "I have confirmed patient's indentity with two patient identifiers, and patient states correct side, site and procedure to match consent and surgical schedule."
        ),
      ],
    },
    ``,
    `_______________________________________________         ___________________________________________________________        ____________________`,
    ` Anesthesia Provider Signature                                                Print name                                                Date                                         Time             `,
  ],
  style: { marginTop: 0, lineHeight: 1.6, fontSize: 8, opacity: 0.8 },
  breakMultiplePages: true,
};

const preOpSignature = {
  text: `Pre-Op _______________________________________, Date _____________________ Time ______________________ Signature __________________________________`,
  marginLeft: 40,
  marginRight: 40,
};

const mdSignature = showPoweredBy => ({
  columns: [
    {
      stack: [
        { text: `_______________________________________ MD Signature, Date ________________ Time ________________` },
      ],
      width: 'auto',
    },
    showPoweredBy ? { text: 'Powered by Ospitek', alignment: 'right', opacity: 0.5 } : undefined,
  ],
  marginLeft: 40,
  marginRight: 40,
  marginTop: showPoweredBy ? 10 : 0,
  justify: 'spaceBetween',
});

const docDefinitionTemplate = {
  pageSize: 'LETTER',
  pageMargins: [40, 40, 40, 40],
};

export const parseCompact = (questions, answers, skipSections, options) =>
  parseElement(questions, {
    value: answers,
    skipSections,
    ...options,
  });

export const generateSummaryPdf = ({
  questions,
  answers,
  procedure,
  hospitalName,
  preOpBy,
  preOpAt,
  scheduleProvider,
}) => {
  const header = makeHeader({
    procedure,
    hospitalName,
    marginBottom: 5,
    preOpInfo: preOpAt ? { preOpBy, preOpAt } : undefined,
    scheduleProvider,
  });
  const body = parseElement(questions, {
    value: answers,
    skipSections: { demographicInformation: true, medications: true, documentElectronicSignature: true },
  });

  const docDefinition = {
    ...docDefinitionTemplate,
    info: { title: `Summary Page Patient ${procedure.id}` },
    content: [header, makeBmiSection(answers), body, dayOfService],
    styles: {
      icon: { font: 'Glyphter' },
      checkmark: { font: 'Fontello' },
      section: {
        marginBottom: 10,
      },
      sectionTitle: {
        marginBottom: 5,
        fontSize: 11,
        fontWeight: 700,
      },
      question: {
        opacity: 0.6,
        lineHeight: 1.2,
      },
    },
    defaultStyle: {
      fontSize: 8,
      lineHeight: 1.2,
    },
    footer: (currentPage, pageCount) => ({
      stack: [
        currentPage === pageCount - 1 || pageCount === 1 ? preOpSignature : undefined,
        currentPage === pageCount ? mdSignature(pageCount === 1) : undefined,
        pageCount > 1 ? defaultFooter()(currentPage, pageCount) : undefined,
      ],
    }),
    pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
      return (
        currentNode.text &&
        typeof currentNode.text === 'string' &&
        currentNode.text.endsWith('UNDERSTANDS AND AGREES.') &&
        currentNode.pageNumbers.length > 1
      );
    },
  };

  openPdf(createPdf(docDefinition), `Summary_Page_Patient_${procedure.id}`);
};

// custom summary pdfs for Walnut Creek
export const generateSummaryPdf28 = ({
  questions,
  answers,
  procedure,
  hospitalName,
  preOpBy,
  preOpAt,
  scheduleProvider,
}) => {
  const header = makeHeader({
    procedure,
    hospitalName,
    marginBottom: 5,
    preOpInfo: preOpAt ? { preOpBy, preOpAt } : undefined,
    scheduleProvider,
  });
  const body = parseElement(questions, {
    value: answers,
    skipSections: { demographicInformation: true, medications: true, documentElectronicSignature: true },
  });

  const docDefinition = {
    ...docDefinitionTemplate,
    info: { title: `Summary Page Patient ${procedure.id}` },
    content: [header, makeBmiSection(answers), body],
    styles: {
      icon: { font: 'Glyphter' },
      checkmark: { font: 'Fontello' },
      section: {
        marginBottom: 10,
      },
      sectionTitle: {
        marginBottom: 5,
        fontSize: 11,
        fontWeight: 700,
      },
      question: {
        opacity: 0.6,
        lineHeight: 1.2,
      },
    },
    defaultStyle: {
      fontSize: 8,
      lineHeight: 1.2,
    },
    footer: (currentPage, pageCount) => ({
      stack: [pageCount > 1 ? defaultFooter()(currentPage, pageCount) : undefined],
    }),
    pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
      return (
        currentNode.text &&
        typeof currentNode.text === 'string' &&
        currentNode.text.endsWith('UNDERSTANDS AND AGREES.') &&
        currentNode.pageNumbers.length > 1
      );
    },
  };

  openPdf(createPdf(docDefinition), `Summary_Page_Patient_${procedure.id}`);
};
