import React from 'react';

import type IllustrationProps from '../IllustrationProps';

export default ({ width, height, variant }: IllustrationProps) => {
  const color = variant === 'light' ? '#1976d2' : '#00a7f7';

  return (
    <svg viewBox="0 0 60 60" width={width} height={height}>
      <path
        fill={color}
        d="M44.44,3V0H15.69V3h-10V60h37.1l11.91,0L54.75,3ZM17,1.33h26.2V6H17ZM53.38,58.61l-10.65.06H6.92V4.34h8.77v3H44.44v-3h9Z"
      />
      <path
        fill={color}
        d="M46.15,22.37H35.43V33.14H46.15V25.8l4.17-4.26-1.1-1.08L46.15,23.6Zm-1.28,9.49H36.7V23.65h8.17v1.26l-3.7,3.79L38.49,26,37.4,27.09l3.78,3.8,3.69-3.78Z"
      />
      <path fill={color} d="M35.68,48.42H46.4V37.65H35.68ZM37,38.94h8.17v8.2H37Z" />
      <rect fill={color} x="12.91" y="43.87" width="17.21" height="1.28" />
      <rect fill={color} x="13.39" y="23.21" width="17.21" height="1.28" />
      <rect fill={color} x="13.3" y="28.06" width="17.21" height="1.28" />
      <rect fill={color} x="13" y="38.82" width="17.21" height="1.28" />
      <rect opacity={0.28} fill={color} x="6.92" y="4.36" width="10.04" height="55.03" />
    </svg>
  );
};
