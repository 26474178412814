import React, { FC } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { Paper, Typography, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { renderActiveShape } from './charts/ORPerformancePieChart';

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper>
        <Box p={2}>
          <Typography gutterBottom>{payload[0].name}</Typography>
          {payload.map((p, i) => (
            <>
              <Box key={i} display="flex" alignItems="center" style={{ width: '16ch' }}>
                <Typography variant="h4">{`${Math.round(p.value)}%`}</Typography>
              </Box>
            </>
          ))}
        </Box>
      </Paper>
    );
  }

  return null;
};

const COLORS = ['#00A7F7', '#2B3C87'];

const PhysicianUtilizationChart: FC<{ value: number }> = ({ value }) => {
  const theme = useTheme();

  const utilization = value * 100;

  const data = [
    { name: 'Utilization', value: utilization },
    { name: 'Rest', value: 100 - utilization },
  ];

  return (
    <ResponsiveContainer width="100%" height="100%" aspect={2 / 1}>
      <PieChart>
        <Pie
          startAngle={90}
          endAngle={-270}
          dataKey="value"
          data={data}
          innerRadius="70%"
          outerRadius="100%"
          paddingAngle={1}
          activeShape={props => renderActiveShape(props, `${utilization.toFixed(1)}%`, 'utilization')}
          activeIndex={0}
        >
          {data.map((entry, index) => (
            <Cell
              stroke="none"
              style={{ outline: 'none' }}
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
          <Cell stroke="none" style={{ outline: 'none' }} fill="#00a7f7" />
        </Pie>
        <Tooltip
          content={({ active, payload, label }) => <CustomTooltip active={active} payload={payload} label={label} />}
          cursor={{ fill: 'rgba(255,255,255,0.15)' }}
          contentStyle={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: 4,
            border: 0,
          }}
        />
        <Legend content={'Utilization'} align="left" verticalAlign="bottom" iconType="circle" iconSize={8} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PhysicianUtilizationChart;
