export enum QuestionnaireType {
  PreOp = 'PreOp',
  PreOpChart = 'PreOpChart',
  OrChart = 'OrChart',
  PacuChart = 'PacuChart',
  PhysicianChart = 'PhysicianChart',
  AnesthesiologistPreOpChart = 'AnesthesiologistPreOpChart',
  AnesthesiologistOrChart = 'AnesthesiologistOrChart',
  AnesthesiologistPacuChart = 'AnesthesiologistPacuChart',
  Intake = 'InTakeForm',
}

export default interface Questionnaire {
  id: number;
  name?: string;
  icon?: string;
  type: QuestionnaireType;
  content: string;
  order?: number;
  v2?: boolean;
}

export interface QuestionnaireEntry {
  id: number;
  name: string;
  progress: number;
  questions: string;
  answers?: string;
  submittedAt?: string;
  firstSubmittedAt?: string;
  questionnaireVersion?: number;
}

export interface QuestionnaireExchange {
  id: number;
  procedureId: number;
  answers: string;
  submittedAt: string;
  firstSubmittedAt?: string;
}

export enum QuestionnaireStatus {
  Completed = 'Completed',
  PartiallyCompleted = 'PartiallyCompleted',
  NotCompleted = 'NotCompleted',
}

export interface QuestionnaireAndAnswers {
  id: number;
  questionnaire: Questionnaire;
  questionnaireExchange?: QuestionnaireExchange;
  completed?: QuestionnaireStatus;
  defaultAnswers?: string;
}
