import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { listHiringManagers } from '../../../../../graph/staff';
import { getNotificationTemplate } from '../../../../../graph/notificationTemplates';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';
import { RouteComponentProps, withRouter } from 'react-router';
import { useStaffSlotContext } from './StaffSlotContext';
import omit from 'lodash/omit';
import { breakpoint } from '../../../../../se/utilities/responsive';

export type HiringManagerContact = {
  id: number;
  name: string;
  hospitalId: number;
  hospitalName: string;
  email: string;
  phoneNumber: string;
};

interface OpenPositionNotificationProps {
  shiftId: number;
  open: boolean;
  handleClickOpen: () => void;
  handleClickClose: () => void;
}

const OpenPositionNotification = withRouter(
  ({ open, handleClickClose, shiftId }: OpenPositionNotificationProps & RouteComponentProps) => {
    const { data: notification } = useQuery(getNotificationTemplate, {
      variables: { trigger: 'OpenPositionCreated' },
    });
    const notificationText = notification?.getNotificationTemplate[0]?.content || '';
    const [message, setMessage] = useState<string>('');

    const [sending, setSending] = useState<boolean>(false);
    const [sent, setSent] = useState<boolean>(false);

    const isMobile = window.innerWidth < breakpoint.md;

    useEffect(() => {
      setMessage(notificationText);
    }, [notificationText]);

    const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setMessage(e.target.value);
    };

    const { data } = useQuery(listHiringManagers);
    const classes = useStyles();

    useEffect(() => {
      setSent(false);
    }, [open]);

    const [checkboxes, setCheckboxes] = useState({});

    useEffect(() => {
      setCheckboxes(
        (data?.listHiringManagers || []).reduce(
          (acc: any, curr: HiringManagerContact) => ({
            ...acc,
            [curr.id]: true,
          }),
          {}
        )
      );
    }, [data]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
    };

    const staffSlotContext = useStaffSlotContext();

    const sendNotification = async () => {
      setSending(true);
      setSent(false);
      const contacts: HiringManagerContact[] = ((data?.listHiringManagers || []) as HiringManagerContact[]).reduce(
        (acc, curr) => {
          const contact: HiringManagerContact | null =
            (checkboxes as any)[curr.id] && !!curr.phoneNumber
              ? (omit(curr, ['__typename']) as HiringManagerContact)
              : null;
          return !!contact ? [...acc, contact] : [...acc];
        },
        [] as HiringManagerContact[]
      );

      await staffSlotContext.doSendNotification(shiftId, contacts, message);

      setSending(false);
      setSent(true);
      setTimeout(() => handleClickClose(), 1000); //fixme without this?
      //fixme i guess its this?
    };

    return (
      <Dialog
        onClose={handleClickClose}
        open={open}
        classes={{ paper: isMobile ? classes.paperMobile : classes.paper }}
      >
        <DialogTitle>
          <Typography variant="h6">Send notification of the new open position to the hiring managers</Typography>
          {handleClickClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <Box className={classes.dialog}>
            <Box className={isMobile ? classes.contentMobile : classes.content}>
              {!isMobile && (
                <FormControl component="fieldset">
                  <FormLabel component="legend">Send to</FormLabel>
                  <FormGroup>
                    {(data?.listHiringManagers || []).map((e: HiringManagerContact) => (
                      <div key={e.id} style={{ display: 'flex' }}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={!!(checkboxes as any)[e.id]} onChange={handleChange} name={`${e.id}`} />
                          }
                          label={e.name}
                        />
                      </div>
                    ))}
                  </FormGroup>
                </FormControl>
              )}
              <TextField
                label="Send message with open position link"
                multiline
                style={{
                  width: isMobile ? 240 : undefined,
                  alignSelf: isMobile ? 'center' : undefined,
                }}
                rows={10}
                variant="filled"
                fullWidth={true}
                value={message}
                onChange={handleMessageChange}
              />
              {isMobile && (
                <FormControl style={{ marginTop: '1em' }} component="fieldset">
                  <FormLabel component="legend">Send to</FormLabel>
                  <FormGroup>
                    {(data?.listHiringManagers || []).map((e: HiringManagerContact) => (
                      <div key={e.id} style={{ display: 'flex' }}>
                        <FormControlLabel
                          control={
                            <Checkbox checked={!!(checkboxes as any)[e.id]} onChange={handleChange} name={`${e.id}`} />
                          }
                          label={e.name}
                        />
                      </div>
                    ))}
                  </FormGroup>
                </FormControl>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className={classes.action}>
          {sent && (
            <Box className={classes.success}>
              <DoneAllIcon style={{ color: 'green' }} />
              <Typography style={{ color: 'green' }}>Notifications Sent</Typography>
            </Box>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={event => {
              event.stopPropagation();
              sendNotification();
            }}
            fullWidth={false}
            disabled={sending}
          >
            Send Notifications
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    minWidth: '60rem',
    minHeight: '40rem',
  },
  paperMobile: {
    minWidth: 280,
    minHeight: 600,
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '6fr 6fr',
    marginBottom: theme.spacing(1),
  },
  contentMobile: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1),
  },
  action: {
    margin: theme.spacing(3),
  },
  success: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
    display: 'flex',
    flexDirection: 'row',
  },
}));

export default OpenPositionNotification;
