import { RouteComponentProps, withRouter } from 'react-router';
import { Patient } from '../../../../types/Patient';
import { QuestionnaireType } from '../../../../types/Questionnaire';
import React from 'react';
import { getUrlPart } from './utils';
import { ChartingButton } from './ChartingPatients';
import { PatientChartButtonsConfig } from '../../../../types/HospitalConfig';
import Box from '@material-ui/core/Box';
import { Divider, Typography } from '@material-ui/core';
import groupBy from '../../../../util/groupBy';
import Button from '@material-ui/core/Button';
import { red } from '@material-ui/core/colors';
import { useScope } from '../../../../hooks/useScope';
import { Role } from './modules/chartingStore';
import { useChartingSession } from './modules/hooks';
import { matchPath } from 'react-router-dom';

export const DictationButton = withRouter(
  ({
    patientId,
    history,
    location,
  }: {
    patientId?: number;
  } & RouteComponentProps) => {
    const chartingSession = useChartingSession();
    const scope = useScope();
    const hospitalId = scope?.hospital?.id;
    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();

      const matchSuperUserPatient = matchPath(
        history.location.pathname,
        `/su/:hospitalId/patients/:patientId/:questionnaireType`
      );

      if (matchSuperUserPatient) {
        history.push(`${matchSuperUserPatient.url}/dictation${window.location.search}`);
        return;
      }

      const matchUserPatient = matchPath(history.location.pathname, `/patients/:patientId/:questionnaireType`);

      if (matchUserPatient) {
        history.push(`${matchUserPatient.url}/dictation${window.location.search}`);
        return;
      }

      history.push(`/kiosk/${hospitalId}/charting/physician-chart/${patientId}/dictation`);
    };

    return chartingSession?.role === 'physician' ? (
      <Button
        variant="contained"
        onClick={handleClick}
        style={{ borderRadius: 0, backgroundColor: red[600], color: 'rgb(255, 255, 255)' }}
      >
        Dictation
      </Button>
    ) : null;
  }
);

export const PatientChartButton = withRouter(
  ({
    buttonLabel,
    patient,
    questionnaireType,
    history,
    location,
    match,
    colorScheme,
  }: {
    buttonLabel?: string;
    patient: Patient;
    questionnaireType: QuestionnaireType;
    colorScheme?: string;
  } & RouteComponentProps) => {
    const scope = useScope();
    const hospitalId = scope?.hospital?.id;

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();

      const matchSuperUserPatient = matchPath(history.location.pathname, '/su/:hospitalId/patients/:patientId');

      if (matchSuperUserPatient) {
        history.push(`${matchSuperUserPatient.url}/${getUrlPart(questionnaireType)}${window.location.search}`);
        return;
      }

      const matchUserPatient = matchPath(history.location.pathname, '/patients/:patientId');

      if (matchUserPatient) {
        history.push(`${matchUserPatient.url}/${getUrlPart(questionnaireType)}${window.location.search}`);
        return;
      }

      history.push(
        `/kiosk/${hospitalId}/charting/${getUrlPart(questionnaireType)}-chart/${patient?.id}${window.location.search}`
      );
    };

    return (
      <ChartingButton
        buttonLabel={buttonLabel}
        patient={patient}
        questionnaireType={questionnaireType}
        handleClick={handleClick}
        colorScheme={colorScheme}
      />
    );
  }
);

const ChartingButtons =
  (tabsConfig: PatientChartButtonsConfig | null, role: Role) =>
  (
    props: {
      data: Patient;
    } & RouteComponentProps
  ) => {
    const chartingSession = useChartingSession();
    if (tabsConfig) {
      const roleConfig = (tabsConfig?.buttons || []).find(e => e?.role?.toLowerCase() === role?.toLowerCase());
      const groups = Object.entries(groupBy(roleConfig?.buttons || [], item => item?.groupLabel || ''));
      const { id } = props.data || {};

      const ChartingButtonsComponent = (
        <Box display="flex" style={{ gap: 8 }}>
          {groups.map(group => {
            return group?.[0] ? (
              <Box display="flex" flexDirection="column">
                <Typography variant="subtitle2" gutterBottom>
                  {group?.[0] + ' Charting'}
                </Typography>
                <Box display="flex" style={{ gap: 2 }}>
                  {((group?.[1] as any[]) || []).map(e => (
                    <PatientChartButton
                      buttonLabel={e.buttonLabel}
                      patient={props?.data}
                      questionnaireType={e.chartType || QuestionnaireType.PreOpChart}
                    />
                  ))}
                </Box>
              </Box>
            ) : (
              <Box display="flex" style={{ gap: 2 }}>
                {((group?.[1] as any[]) || []).map(e => (
                  <PatientChartButton
                    buttonLabel={e.buttonLabel}
                    patient={props?.data}
                    questionnaireType={e.chartType || QuestionnaireType.PreOpChart}
                  />
                ))}
              </Box>
            );
          })}
        </Box>
      );

      return chartingSession?.role === 'physician' ? (
        <Box display="flex" style={{ gap: 8 }}>
          {ChartingButtonsComponent}
          <Box px={1}>
            <Divider orientation="vertical" />
          </Box>
          <DictationButton patientId={id} />
        </Box>
      ) : (
        ChartingButtonsComponent
      );
    }

    return (
      <Box display="flex" flexDirection="row" style={{ gap: '1em' }}>
        <PatientChartButton patient={props?.data} questionnaireType={QuestionnaireType.PreOpChart} />
        <PatientChartButton patient={props?.data} questionnaireType={QuestionnaireType.OrChart} />
        <PatientChartButton patient={props?.data} questionnaireType={QuestionnaireType.PacuChart} />
        <PatientChartButton patient={props?.data} questionnaireType={QuestionnaireType.PhysicianChart} />
        <PatientChartButton patient={props?.data} questionnaireType={QuestionnaireType.AnesthesiologistPreOpChart} />
        <PatientChartButton patient={props?.data} questionnaireType={QuestionnaireType.AnesthesiologistOrChart} />
        <PatientChartButton patient={props?.data} questionnaireType={QuestionnaireType.AnesthesiologistPacuChart} />
      </Box>
    );
  };

export default ChartingButtons;
