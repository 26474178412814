import React from 'react';
import { DataField, DataTitle, DataValue, MainWrapper } from '../../../../se/components/entity/EntityView';
import { isDefinedAndNotNull } from '../../../../se/utilities/check';

export default ({ columns = [], data }) => (
  <MainWrapper numberOfColumns={columns.length}>
    {columns.map(({ title, lens, Component }, index) =>
      isDefinedAndNotNull(lens(data)) ? (
        <DataField key={index}>
          <DataTitle>{title}</DataTitle>
          <DataValue>
            <Component data={lens(data)} />
          </DataValue>
        </DataField>
      ) : null
    )}
  </MainWrapper>
);
