import React from 'react';
import { ThemeProvider } from 'styled-components';
import { dark as darkTheme } from '../../../theme';
import LoadingIndicator from '../../core/LoadingIndicator';
import { muiThemeDark } from '../../../muiTheme';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles';
import Box from '@material-ui/core/Box';

export default () => (
  <ThemeProvider theme={darkTheme}>
    <MUIThemeProvider theme={muiThemeDark}>
      <CssBaseline />
      <Box display="flex" height={1} alignItems="center" justifyContent="center">
        <LoadingIndicator />
      </Box>
    </MUIThemeProvider>
  </ThemeProvider>
);
