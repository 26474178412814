import { assignInitials, getPatientNameAsInitials } from '../../../entities/patient/transducers';
import set from 'lodash/set';
import get from 'lodash/get';

export const mapInitialsForPatientList = (patients, patientNameFormat, scope) => {
  let intermediate;
  switch (patientNameFormat) {
    case 'LastNameInitial':
      intermediate = patients.map(patient => ({
        ...patient,
        ...getPatientNameAsInitials(patient.name, 1, true),
      }));

      return intermediate.map(assignInitials(intermediate, true));
    case 'FirstNameInitial':
      intermediate = patients.map(patient => ({
        ...patient,
        ...getPatientNameAsInitials(patient.name, 1, false, true),
      }));

      return intermediate.map(assignInitials(intermediate, false, true));
    case 'FullName':
      return patients.map(patient => set(patient, 'initials', patient.name));
    case 'PatientId':
      return patients.map(patient =>
        set(
          patient,
          'initials',
          (get(scope, 'hospital.id') ? `G${get(scope, 'hospital.groupId')}H${get(scope, 'hospital.id')}-` : '#') +
            patient.id
        )
      );
    default:
      intermediate = patients.map(patient => ({
        ...patient,
        ...getPatientNameAsInitials(patient.name, 1, false),
      }));

      return intermediate.map(assignInitials(intermediate, false));
  }
};
