import { compose, withProps } from 'recompose';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import TextInput from '../../../se/components/inputs/TextInput';
import { withLabel } from '../../../se/components/Label';

export default withProps({
  schema: {
    name: compose(
      withLabel('Name'),
      withProps({
        placeholder: 'e.g., Scissors',
        autoComplete: 'off',
        required: true,
      })
    )(TextInput),
  },
})(ObjectInput);
