import {
  AnesthesiologistPicked,
  PhysicianPicked,
  ProcedureTypesPicked,
  RepresentativePicked,
  RoomPicked,
  VendorPicked,
} from '../types/types';
import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';

export const roomPickedInitial: RoomPicked = {
  id: undefined,
  name: undefined,
  type: undefined,
};
export const procedureTypesPicked: ProcedureTypesPicked = {
  id: undefined,
  name: undefined,
};
export const physicianPickedInitial: PhysicianPicked = {
  id: undefined,
  name: undefined,
  email: undefined,
  notificationNumbers: undefined,
};

export const anesthesiologistPickedInitial: AnesthesiologistPicked = {
  id: undefined,
  name: undefined,
  email: undefined,
  phoneNumber: undefined,
};

export const vendorPickedInitial: VendorPicked = {
  id: undefined,
  name: undefined,
  representatives: undefined,
};

export const representativePickedInitial: RepresentativePicked = {
  id: undefined,
  name: undefined,
  email: undefined,
  phoneNumber: undefined,
};

export const setValueAndResetError = <T>(path: string, value: string | number, obj: T): T =>
  flow(set(`${path}.value`, value), set(`${path}.error`, undefined))(obj);
