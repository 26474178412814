import { Box, Typography } from '@material-ui/core';
import React from 'react';
import Divider from '@material-ui/core/Divider';

interface Props {
  question?: string;
  answer: string | undefined;
}

const TextAnswer = ({ question, answer }: Props) => (
  <div>
    {question && <Typography color="textSecondary">{question}</Typography>}
    {answer && <Typography>{answer}</Typography>}
    {!answer && <Typography>Not answered</Typography>}
    <Box my={1}>
      <Divider />
    </Box>
  </div>
);

export default TextAnswer;
