import React, { Dispatch, FC, SetStateAction } from 'react';
import waitingRoomEmpty from '../../../assets/images/waitingroom.svg';
import { ScheduleUploadButton } from '../../inputs/upload/UploadButton';
import EmptyState from '../../core/EmptyState';
import { isToday } from 'date-fns';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router';
import Timeline from './schedule/timeline/Timeline';
import Overview from './schedule/overview/Overview';
import { Room } from '../../../types/Room';
import { formats } from './HospitalSchedule';
import { Procedure as ProcedureT, Procedure } from '../../../types/Procedure';
import { useScope } from '../../../hooks/useScope';

const ScheduleUploadButtonWrapper = styled.div`
  @media (min-width: 1920px) {
    display: none;
  }
`;

const getEmptyText = (isKiosk?: boolean, physician?: any, date?: Date, readonly?: boolean, myOnly?: boolean) => {
  const dateLabel = date && isToday(date) ? 'today' : 'selected day';

  if (isKiosk) {
    return `There is no schedule for ${dateLabel}.`;
  } else if (physician) {
    return `Selected physician has no scheduled operations for ${dateLabel}`;
  } else if (readonly) {
    return myOnly ? `There are no procedures for you for ${dateLabel}.` : `There is no schedule for ${dateLabel}.`;
  } else {
    return `There is no schedule for ${dateLabel}. Go ahead and upload the schedule CSV or XLS file.`;
  }
};

const EmptySchedule: FC<
  {
    date?: Date;
    timelineView?: boolean;
    physician?: any;
    isScheduleUser?: boolean;
    isKiosk?: boolean;
    myProceduresOnly?: boolean;
    canUseScheduleViewProcedure?: boolean;
    hospitalId?: number;
    currentOR?: any;
    operationRooms?: Room[];
    isFrontDesk?: boolean;
    isPowerUser?: boolean;
    showBedNumber?: boolean;
    showOverlay?: boolean;
    setEditProcedure: Dispatch<SetStateAction<ProcedureT | null>>;
    unassignedProcedures?: Procedure[];
    scheduleFooter?: any;
    openProcedureForm: () => void;
  } & RouteComponentProps
> = ({
  date,
  timelineView,
  physician,
  isScheduleUser,
  isKiosk,
  myProceduresOnly,
  canUseScheduleViewProcedure,
  hospitalId,
  currentOR,
  operationRooms,
  isFrontDesk,
  isPowerUser,
  setEditProcedure,
  unassignedProcedures,
  showBedNumber,
  scheduleFooter,
  openProcedureForm,
  showOverlay,
  match,
  history,
  location,
}) => {
  const scope = useScope();
  const createProcedureSchedule = scope?.hospital?.modules?.createProcedureSchedule;

  if (createProcedureSchedule) {
    const EmptyScheduleComponent: any = timelineView ? Timeline : Overview;
    return (
      <EmptyScheduleComponent
        date={date}
        hospitalId={hospitalId}
        isKiosk={isKiosk}
        currentOR={currentOR}
        isFrontDesk={isFrontDesk}
        isPowerUser={isPowerUser}
        editableStaff={isPowerUser}
        operationRooms={operationRooms}
        setEditProcedure={setEditProcedure}
        unassignedProcedures={unassignedProcedures}
        canUseScheduleViewProcedure={canUseScheduleViewProcedure}
        showBedNumber={showBedNumber}
        scheduleFooter={scheduleFooter}
        history={history}
        match={match}
        openProcedureForm={openProcedureForm}
        showOverlay={showOverlay}
      />
    );
  }

  return (
    <EmptyState
      image={waitingRoomEmpty}
      text={
        isScheduleUser
          ? getEmptyText(isKiosk, physician, date, true, myProceduresOnly)
          : getEmptyText(isKiosk, physician, date)
      }
    >
      {!isScheduleUser && !isKiosk && (
        <ScheduleUploadButtonWrapper>
          <ScheduleUploadButton history={history} location={location} formats={formats} />
        </ScheduleUploadButtonWrapper>
      )}
    </EmptyState>
  );
};

export default withRouter(EmptySchedule);
