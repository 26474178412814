import React, { FC } from 'react';
import { Box, Chip, createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core';
import StaffMemberT, { PrimarySpecialtyColors } from '../../../../../types/StaffMember';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import CheckCircle from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import classNames from 'clsx';
import StaffShiftTime from './StaffShiftTime';
import StaffShiftText from './StaffShiftText';
import formatTimeRange from '../../../calendar/util/formatTimeRange';
import { Procedure, ShiftState } from '../../../../../types/Procedure';
import { LocalTime } from '@js-joda/core';
import { ShiftStateIcon } from '../vendor/Vendors';
import { useScheduleUserContext } from '../vendor/context/ScheduleUserContext';

interface StaffMemberProps extends StaffMemberT {
  busy?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void | Promise<void>;
  toggleStaffMember?: (event: React.MouseEvent<HTMLElement>) => Promise<void>;
  className?: string;
  shiftState: ShiftState;
  from?: string;
  to?: string;
  additionalText?: string;
  shiftId?: number;
  procedure?: Procedure;
  hideClock?: boolean;
  hideText?: boolean;
  interactive?: boolean;
  editableStaff?: boolean;
  isMenuListItem?: boolean;
  type: 'RoomShift' | 'ProcedureShift';
  inlineText?: boolean;
  formattedName?: string;
}

export const getStaffFormattedName = (name?: String, titleAbbr?: string | null) => {
  if (!name) return null;
  const [firstName, lastName] = name?.split(/\s+/);
  const lastNameInitial = lastName?.charAt(0).toUpperCase();

  return titleAbbr
    ? firstName && lastName
      ? `${firstName} ${lastNameInitial}. ${titleAbbr}`
      : `${name} ${titleAbbr}`
    : firstName && lastName
    ? `${firstName} ${lastNameInitial}.`
    : name;
};

export const toLocalTime = (time: string): LocalTime | null => {
  const [hours, minutes] = time.split(':');
  if (!hours && !minutes) return null;

  const hoursNumber = parseInt(hours, 10);
  const minutesNumber = parseInt(minutes, 10);

  return isFinite(hoursNumber) && isFinite(minutesNumber) ? LocalTime.of(hoursNumber, minutesNumber, 0, 0) : null;
};

export const isPTO = (from?: string, to?: string, ptoFrom?: string, ptoTo?: string): boolean => {
  if (!ptoFrom && !ptoTo) return true;
  if (!from && !to) return true;

  const fromTime = from ? toLocalTime(from) : toLocalTime('00:00');
  const toTime = to ? toLocalTime(to) : toLocalTime('23:59');
  const ptoFromTime = ptoFrom ? toLocalTime(ptoFrom) : toLocalTime('00:00');
  const ptoToTime = ptoTo ? toLocalTime(ptoTo) : toLocalTime('23:59');

  if (
    (fromTime && ptoFromTime?.isAfter(fromTime) && toTime && ptoFromTime?.isBefore(toTime)) ||
    (fromTime && ptoToTime?.isAfter(fromTime) && toTime && ptoToTime?.isBefore(toTime))
  )
    return true;

  return !!(fromTime && ptoFromTime?.isBefore(fromTime) && toTime && ptoToTime?.isAfter(toTime));
};

const StaffMemberLabel: FC<StaffMemberProps> = ({
  inlineText,
  busy,
  shiftId,
  hideClock,
  hideText,
  onClick,
  interactive,
  editableStaff,
  className,
  shiftState,
  from,
  to,
  type,
  additionalText,
  isMenuListItem,
  procedure,
  formattedName,
  ...staffMember
}) => {
  const classes = useStyles();
  const { name, titleAbbr, primarySpecialty, assignedShift, availability } = staffMember;
  const hasPTO = (availability || [])?.some(
    e => !e.available && isPTO(procedure?.startTimeText, procedure?.endTimeText, e.from, e.to)
  );

  return (
    <Box className={classes.content}>
      <Box>{formattedName}</Box>
      {!busy && (
        <>
          <StaffShiftTime
            from={assignedShift?.from || from}
            to={assignedShift?.to || to}
            assignedShiftId={assignedShift?.id || shiftId}
            hideClock={hideClock}
            editableStaff={editableStaff}
          />
          <StaffShiftText
            additionalText={assignedShift?.additionalText || additionalText}
            assignedShiftId={assignedShift?.id || shiftId}
            hideText={hideText}
            editableStaff={editableStaff}
          />
        </>
      )}

      {!inlineText && hasPTO && !isMenuListItem && (
        <Tooltip
          title={
            <Box>
              {(availability || [])
                .filter(e => !e.available)
                .map(e => (
                  <Box>{`${formatTimeRange(e.from, e.to)} PTO`}</Box>
                ))}
            </Box>
          }
        >
          <WarningIcon className={classes.warning} />
        </Tooltip>
      )}

      {!inlineText && <ShiftStateIcon shiftState={shiftState} />}
    </Box>
  );
};

const StaffMember: FC<StaffMemberProps> = ({
  busy,
  shiftId,
  hideClock,
  hideText,
  onClick,
  interactive,
  editableStaff,
  className,
  shiftState,
  from,
  to,
  type,
  additionalText,
  isMenuListItem,
  procedure,
  inlineText,
  ...staffMember
}) => {
  const { name, titleAbbr, primarySpecialty, assignedShift, availability } = staffMember;
  const classes = useStyles();
  const formattedName = getStaffFormattedName(name, titleAbbr);
  const hasPTO = (availability || [])?.some(
    e => !e.available && isPTO(procedure?.startTimeText, procedure?.endTimeText, e.from, e.to)
  );

  const { scheduleUserId, setEditing, isEditing } = useScheduleUserContext();

  const onChipClick = e => {
    if (onClick) {
      onClick(e);
    } else if (scheduleUserId) {
      e.stopPropagation();
      setEditing(isEditing(shiftId ?? null, type) ? null : shiftId ?? null, type);
    }
  };

  return inlineText ? (
    <StaffMemberLabel
      {...staffMember}
      busy={busy}
      shiftId={shiftId}
      hideClock={hideClock}
      hideText={hideText}
      onClick={onClick}
      interactive={interactive}
      editableStaff={editableStaff}
      className={className}
      shiftState={shiftState}
      from={from}
      to={to}
      type={type}
      additionalText={additionalText}
      isMenuListItem={isMenuListItem}
      procedure={procedure}
      formattedName={formattedName}
      inlineText={inlineText}
    />
  ) : (
    <div className={classNames(classes.root, className)}>
      <Chip
        icon={
          <FiberManualRecordOutlinedIcon
            className={classes.icon}
            style={{
              color: primarySpecialty ? PrimarySpecialtyColors[primarySpecialty] : undefined,
            }}
          />
        }
        label={
          <StaffMemberLabel
            {...staffMember}
            busy={busy}
            shiftId={shiftId}
            hideClock={hideClock}
            hideText={hideText}
            onClick={onClick}
            interactive={interactive}
            editableStaff={editableStaff}
            className={className}
            shiftState={shiftState}
            from={from}
            to={to}
            type={type}
            additionalText={additionalText}
            isMenuListItem={isMenuListItem}
            procedure={procedure}
            formattedName={formattedName}
          />
        }
        size="small"
        classes={{
          root: classes.chipRoot,
          sizeSmall: classes.sizeSmall,
          labelSmall: classes.labelSmall,
        }}
        onClick={onChipClick}
        className={classNames(
          classes.chip,
          { [classes.interactive]: interactive !== undefined ? interactive : Boolean(onClick) },
          { [classes.notAvailable]: hasPTO && isMenuListItem }
        )}
      />

      <CheckCircle
        className={classNames(
          classes.checkmark,
          busy ? classes.checkmarkBusy : !assignedShift || hasPTO ? classes.checkmarkNone : undefined
        )}
      />
      {hasPTO && isMenuListItem && (
        <Tooltip
          title={
            <Box>
              {(availability || [])
                .filter(e => !e.available)
                .map(e => (
                  <Box>{`${formatTimeRange(e.from, e.to)} PTO`}</Box>
                ))}
            </Box>
          }
        >
          <WarningIcon className={classes.warning} />
        </Tooltip>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    chip: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      fontWeight: 500,
    },
    chipRoot: {
      fontFamily: 'inherit',
      fontSize: 'inherit',
    },
    sizeSmall: {
      height: '1.55em',
    },
    labelSmall: {
      marginTop: -1 * theme.spacing(0.5),
      marginBottom: -1 * theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      fontFamily: 'inherit',
    },
    icon: {
      color: 'rgba(127, 127, 127, 0.2)',
    },
    checkmark: {
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
      fontSize: '0.9rem',
      color: theme.palette.success.main,
      transition: 'opacity 0.2s ease-in-out',
    },
    checkmarkBusy: {
      opacity: '0.5',
    },
    checkmarkNone: {
      opacity: '0',
      display: 'none',
    },
    warning: {
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
      fontSize: '0.9rem',
      color: theme.palette.warning.main,
    },
    noteIcon: {
      marginLeft: '0.2rem',
      color: theme.palette.primary.light,
    },
    content: {
      display: 'flex',
      gap: '0.2em',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    noteInput: {
      marginLeft: theme.spacing(1),
      color: 'inherit',
      backgroundColor: theme.palette.background.default,
      border: 'none',
      '&:focus': {
        outline: 'none',
      },
    },
    interactive: {
      cursor: 'pointer',
    },
    notAvailable: {
      opacity: 0.2,
    },
  })
);

export default StaffMember;
