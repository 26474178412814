import { Box, Button, Paper, TextField } from '@material-ui/core';
import React, { SyntheticEvent, useEffect, useRef, useState, VFC } from 'react';
import { DateAvailabilityRange, useStyles } from './StaffAvailabilityEditor';
import ActionMenu from './ActionMenu';
import Dates from './types/Dates';
import classNames from 'clsx';
import { useStyles as useStylesGroupEditor } from './StaffAvailabilityGroupEditor';
import ActionButton from './ActionButton';
import ActionManipulation from './types/ActionManipulation';
import ImmutableSet from './types/ImmutableSet';
import { HolidayCalendar } from '../../../types/HolidayCalendar';
import { holidayColor } from './HolidayCalendar';

interface HolidayEditorProps {
  selectedDates: Dates;
  setSelectedDates: (datesOrFn: Dates | ((prev: Dates) => Dates)) => void;
  highlightedDates: Dates;
  setHighlightedDates: (datesOrFn: Dates | ((prev: Dates) => Dates)) => void;
  createHoliday: (dates: Dates, description?: string) => Promise<void>;
  deleteHoliday: (ids: ImmutableSet<HolidayCalendar['id']>) => Promise<void>;
  updateHoliday: (ids: ImmutableSet<HolidayCalendar['id']>, description: string) => Promise<void>;
  holidayGroup: HolidayCalendar[];
  freeGroup: Dates;
}

interface HolidayConcreteEditorProps extends Omit<HolidayEditorProps, 'setSelectedDates' | 'selectedDates'> {
  dates: Dates;
  newHoliday: boolean;
  initialDescription?: string;
}

const HolidayConcreteEditor: VFC<HolidayConcreteEditorProps> = ({
  setHighlightedDates,
  dates,
  holidayGroup,
  createHoliday,
  updateHoliday,
  deleteHoliday,
  freeGroup,
  newHoliday = false,
  initialDescription,
}) => {
  const [highlighted, setHighlighted] = useState<boolean>(false);

  const classes = useStylesGroupEditor();

  const highlightDates = () => {
    setHighlighted(true);
    setHighlightedDates(dates);
  };

  const unhighlightDates = () => {
    setHighlighted(false);
    setHighlightedDates(Dates.empty);
  };

  const actionManipulation = useRef(new ActionManipulation());

  const [isHolidayBeingEdited, setIsHolidayBeingEdited] = useState(false);

  const [description, setDescription] = useState<string | undefined>(undefined);
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    setDescription(initialDescription);
  }, [initialDescription]);

  const isEdited = initialDescription !== description;

  const onSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (!(isEdited && initialDescription === undefined)) {
      await updateHoliday(ImmutableSet.from(holidayGroup.map(holiday => holiday.id)), description!);
      setIsTouched(false);
    }
  };
  return (
    <Paper
      variant="outlined"
      elevation={3}
      className={classNames(classes.paper, { [classes.highlighted]: highlighted })}
      onMouseOver={highlightDates}
      onMouseLeave={unhighlightDates}
    >
      <DateAvailabilityRange dates={newHoliday ? freeGroup : Dates.from(holidayGroup.map(holiday => holiday.date))} />
      <Box display="flex" flexDirection="column">
        <Box display="flex" mb={1}>
          <ActionButton
            size="small"
            variant="contained"
            style={{
              backgroundColor: !newHoliday ? holidayColor : 'transparent',
            }}
            onClick={async () => {
              if (newHoliday) {
                await createHoliday(freeGroup, description);
              } else {
                await deleteHoliday(ImmutableSet.from(holidayGroup.map(holiday => holiday.id)));
              }
              setIsHolidayBeingEdited(prev => !prev);
            }}
            loadingSize={15}
            onMouseDown={actionManipulation.current.preventOnClick()}
          >
            {newHoliday ? 'Add holiday' : 'Remove holiday'}
          </ActionButton>
        </Box>
        {(isHolidayBeingEdited || !newHoliday) && (
          <form noValidate autoComplete="off" onSubmit={onSubmit}>
            <TextField
              autoFocus={true}
              fullWidth={true}
              value={description}
              onChange={value => {
                setDescription(value.target.value);
                setIsTouched(true);
              }}
              defaultValue={description}
              id="standard-basic"
              variant={'standard'}
              label={'Holiday description'}
            />
            {isTouched && (
              <Button style={{ marginTop: 5 }} color={'primary'} variant={'outlined'} type={'submit'}>
                Save
              </Button>
            )}
          </form>
        )}
      </Box>
    </Paper>
  );
};

const HolidayEditor: VFC<HolidayEditorProps> = ({
  selectedDates,
  setSelectedDates,
  highlightedDates,
  setHighlightedDates,
  createHoliday,
  deleteHoliday,
  updateHoliday,
  holidayGroup,
  freeGroup,
}) => {
  const classes = useStyles();

  const [description, setDescription] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (holidayGroup.length > 0) {
      const firstDescription = holidayGroup[0].description;
      holidayGroup.every(holiday => holiday.description === firstDescription)
        ? setDescription(firstDescription)
        : setDescription('');
    }
  }, [holidayGroup]);

  return (
    <div className={classes.drawer}>
      <Box className={classes.boxHeader}>
        <DateAvailabilityRange dates={selectedDates} />
        <Box>
          <ActionMenu actions={[['Deselect All Dates', () => setSelectedDates(Dates.empty)]]} />
        </Box>
      </Box>
      {freeGroup.size() > 0 && (
        <HolidayConcreteEditor
          createHoliday={createHoliday}
          deleteHoliday={deleteHoliday}
          highlightedDates={highlightedDates}
          setHighlightedDates={setHighlightedDates}
          dates={selectedDates}
          holidayGroup={holidayGroup}
          freeGroup={freeGroup}
          newHoliday={true}
          updateHoliday={updateHoliday}
        />
      )}
      {holidayGroup.length > 0 && (
        <HolidayConcreteEditor
          createHoliday={createHoliday}
          deleteHoliday={deleteHoliday}
          highlightedDates={highlightedDates}
          setHighlightedDates={setHighlightedDates}
          dates={selectedDates}
          holidayGroup={holidayGroup}
          freeGroup={freeGroup}
          newHoliday={false}
          initialDescription={description}
          updateHoliday={updateHoliday}
        />
      )}
    </div>
  );
};

export default HolidayEditor;
