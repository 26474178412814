import React, { useEffect } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { sendAnalyticsReport } from '../../../../graph/dashboard';
import useModal from '../../../../hooks/useModal';
import { RouterlessModal } from '../../../../se/components/Modal';
import { withProps } from 'recompose';
import ObjectInput from '../../../../se/components/inputs/ObjectInput';
import { withLabel } from '../../../../se/components/Label';
import Form from '../../../../se/components/Form';
import { breakpoint } from '../../../../se/utilities/responsive';
import TextInput from '../../../../se/components/inputs/TextInput';
import { YearMonth } from '@js-joda/core';

const MonthYearInput = withProps({
  schema: {
    monthYear: withLabel('Enter month')(TextInput),
  },
})(ObjectInput);

const DetailedReportModal = ({ onBusy, onClose }: { onBusy: () => () => void; onClose: () => void }) => {
  const client = useApolloClient();
  const [reportQueryWorking, setReportQueryWorking] = React.useState<boolean>(false);
  const [lastDetailedReport, setLastDetailedReport] = React.useState<any>(null);
  const [sendReport] = useMutation(sendAnalyticsReport);

  useEffect(() => {
    if (lastDetailedReport !== null) {
      const timeout = setTimeout(() => {
        setLastDetailedReport(null);
      }, 60000);

      return () => clearTimeout(timeout);
    }
  }, [lastDetailedReport]);

  const handleSubmit = async values => {
    const onDone = onBusy();

    try {
      await sendReport({
        variables: {
          monthYear: values.monthYear,
        },
      });
    } finally {
      onDone();
    }
  };

  return (
    <RouterlessModal title={'Send Analytics Report'} onClose={onClose} fullWindow={window.innerWidth < breakpoint.md}>
      <Form
        autoFocus
        initialValue={{ monthYear: YearMonth.now().toString() }}
        input={MonthYearInput}
        label={'Send'}
        onSubmit={handleSubmit}
        onCancel={onClose}
      />
    </RouterlessModal>
  );
};

export default DetailedReportModal;
