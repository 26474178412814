import React from 'react';
import styled, { css } from 'styled-components';
import Button from '../../../../se/components/Button';
import Dropdown from '../../../core/Dropdown';
import { Calendar } from 'react-date-range';
import { format, parse } from 'date-fns';
import TextInput from '../../../../se/components/inputs/TextInput';
import { withTemplateCtx } from './TemplateContext';
import get from 'lodash/get';
import CheckboxInput from '../../../inputs/CheckboxInput';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  > * + * {
    margin-top: 1em;
  }
`;

const InputPlaceholder = styled.label`
  background-color: ${props => props.theme.button.primary.backgroundColor.string()};
  color: ${props => props.theme.button.primary.color.string()};
  border-bottom: 1px solid ${props => props.theme.textColor.string()};
  line-height: 1.5;
  padding: 0 1em;
  margin: 0.2em 0;
  :hover {
    ${props =>
      !props.disabled &&
      css`
        background-color: ${props => props.theme.button.primary.backgroundColor.darken(0.1).string()};
      `}
  }
  transition: all 0.1s ease;

  ${props =>
    props.disabled &&
    css`
      background-color: ${props => props.theme.backgroundColor.string()};
      color: ${props => props.theme.textColor.string()};
      font-weight: 500;
      cursor: auto;
    `}
`;

const DropdownInput = ({ Element, onChange, placeholder, formatter, shouldConfirm, disabled, ...rest }) => {
  const dropdownContent = ({ close }) => {
    const handleChange = value => {
      onChange(value);
      if (!shouldConfirm) {
        close();
      }
    };

    return props => (
      <Container>
        <Element {...props} onChange={handleChange} />
        {shouldConfirm && <Button onClick={() => close()}>Ok</Button>}
      </Container>
    );
  };
  return (
    <Dropdown content={dropdownContent} contentProps={rest} style={{ display: 'inline-flex' }} disabled={disabled}>
      <InputPlaceholder disabled={disabled}>
        {rest.value ? (formatter ? formatter(rest.value) : rest.value) : placeholder}
      </InputPlaceholder>
    </Dropdown>
  );
};

const DateInput = ({ value, onChange, ...props }) => (
  <Calendar date={value ? parse(value) : new Date()} onChange={val => onChange(format(val, 'YYYY-MM-DD'))} {...props} />
);

const InputLeaf = ({ element, name, variables, setVariables, disabled }) => {
  const onChange = value => {
    setVariables({ ...variables, [name]: value });
  };

  const value = get(variables, name, '');
  if (element === 'date') {
    return (
      <DropdownInput
        value={value}
        Element={DateInput}
        onChange={onChange}
        formatter={val => format(parse(val), 'D MMMM YYYY')}
        placeholder={'Click to edit date'}
        disabled={disabled}
      />
    );
  }
  if (element === 'time') {
    return (
      <DropdownInput
        value={value}
        Element={TextInput}
        onChange={onChange}
        shouldConfirm
        placeholder={'Click to edit time'}
        disabled={disabled}
      />
    );
  }

  if (element === 'text') {
    return (
      <DropdownInput
        value={value}
        Element={TextInput}
        onChange={onChange}
        shouldConfirm
        placeholder={'Click to enter text'}
        disabled={disabled}
      />
    );
  }

  if (element === 'checkbox') {
    return (
      <CheckboxInput
        value={value}
        onChange={onChange}
        name={name}
        layoutProps={{ style: { display: 'inline-flex', marginBottom: '0.4em' } }}
        disabled={disabled}
      />
    );
  }

  return null;
};

export default withTemplateCtx(InputLeaf);
