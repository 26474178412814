import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { setStatus as setProcedureStatus } from '../../../graph/procedures';
import { surgeonProcedureStatuses } from '../surgeonProcedures/enums';
import { ConsultationStatus } from './consultations/enums';
import { SurgeryStatus } from './surgeries/enums';
import Box from '@material-ui/core/Box';
import ButtonWithOptions, { Option } from '../../../se/components/buttons/ButtonWithOptions';
import { updateTransferStatus as updateTransferStatusMutation } from '../../../graph/scheduleRequests';
import get from 'lodash/get';
import { OrganizationType } from '../hospitals/enums';
import ConfirmCancellationDialog from './ConfirmCancellationDialog';
import { ScheduleTransferStatus } from '../scheduleRequests/enums';
import { withRouter } from 'react-router';
import BookingDialog from './BookingDialog';
import RescheduleConsultationDialog from './RescheduleConsultationDialog';
import { update } from '../../../graph/surgeon/procedures';
import parse from 'date-fns/parse';
import { toLocalDate } from '../../core/DatePicker';
import { LocalDate } from '@js-joda/core';
import { withScope } from '../../../contexts/ScopeContext';

interface ScheduleTransferState {
  __typename: string;
  seen?: boolean;
}

interface ChangeStatusButtonProps {
  id: number;
  transferId: number;
  status?: string;
  state?: ScheduleTransferState;
  scheduleData?: {
    dateOfService: string;
    externalProvider?: {
      id: string;
      provider: string;
    };
  };
  consultation?: {
    consultationDate: string;
    consultationTime: string;
  };
  variant?: 'text' | 'outlined' | 'contained';
}

const ChangeStatusButton = ({
  data: { id, transferId, status, state, variant, scheduleData, consultation },
  ...rest
}: {
  data: ChangeStatusButtonProps;
}) => {
  const [updateStatus, { loading: loadingStatus }] = useMutation(setProcedureStatus);
  const [updateState, { loading: loadingState }] = useMutation(updateTransferStatusMutation);
  const [rescheduleConsultation] = useMutation(update);
  const isSurgeonOffice = get(rest, 'scope.hospital.type') === OrganizationType.SurgeonOffice;
  const consultationDate = get(consultation, 'consultationDate');
  const consultationTime = get(consultation, 'consultationTime');
  const dateOfService = get(scheduleData, 'dateOfService');
  const externalProvider = get(scheduleData, 'externalProvider');
  const history = get(rest, 'history');
  const location = get(rest, 'location');

  const [openSurgeryCancelDialog, setOpenSurgeryCancelDialog] = useState<boolean>(false);
  const [openConsultationCancelDialog, setOpenConsultationCancelDialog] = useState<boolean>(false);
  const [openBookingDialog, setOpenBookingDialog] = useState<boolean>(false);
  const [openRescheduleConsultationDialog, setOpenRescheduleConsultationDialog] = useState<boolean>(false);

  const changeStatus = useCallback(
    (status: string) => async () => {
      await updateStatus({
        variables: { id, status },
      });
    },
    [id, updateStatus]
  );

  const redirectToSurgerySchedulePage = useCallback(() => {
    const prefix = (location.pathname.match(/\/su\/(\d+)/) ?? [''])[0];
    history.push(`${prefix}/schedule-procedure?procedure=${id}`);
  }, [history, id, location.pathname]);

  const actionsFor = useCallback(
    (status?: string, state?: ScheduleTransferState) => {
      if (isSurgeonOffice) {
        if (status && status.indexOf('Consultation') === 0) {
          if (status === surgeonProcedureStatuses.Consultation) {
            // Consultation Scheduled
            const isPastConsultation =
              consultationDate && toLocalDate(parse(consultationDate)).isBefore(LocalDate.now());
            return isPastConsultation
              ? [
                  {
                    label: 'Mark as Completed',
                    status: ConsultationStatus.ConsultationComplete,
                    primary: true,
                    onClick: changeStatus(ConsultationStatus.ConsultationComplete),
                    loading: true,
                  },
                  {
                    label: 'Reschedule Consultation',
                    onClick: () => setOpenRescheduleConsultationDialog(true),
                  },
                  {
                    label: 'Cancel Consultation',
                    status: ConsultationStatus.ConsultationCanceled,
                    onClick: () => setOpenConsultationCancelDialog(true),
                  },
                ]
              : [
                  {
                    label: 'Confirm Consultation',
                    status: ConsultationStatus.ConsultationConfirmed,
                    primary: true,
                    onClick: changeStatus(ConsultationStatus.ConsultationConfirmed),
                    loading: true,
                  },
                  {
                    label: 'Reschedule Consultation',
                    onClick: () => setOpenRescheduleConsultationDialog(true),
                  },
                  {
                    label: 'Cancel Consultation',
                    status: ConsultationStatus.ConsultationCanceled,
                    onClick: () => setOpenConsultationCancelDialog(true),
                  },
                ];
          } else if (status === surgeonProcedureStatuses.ConsultationConfirmed) {
            // Consultation Confirmed
            return [
              {
                label: 'Mark as Completed',
                status: ConsultationStatus.ConsultationComplete,
                primary: true,
                onClick: changeStatus(ConsultationStatus.ConsultationComplete),
                loading: true,
              },
              {
                label: 'Mark as No show',
                status: ConsultationStatus.ConsultationNoShow,
                onClick: changeStatus(ConsultationStatus.ConsultationNoShow),
                loading: true,
              },
              {
                label: 'Reschedule Consultation',
                onClick: () => setOpenRescheduleConsultationDialog(true),
              },
              {
                label: 'Cancel Consultation',
                status: ConsultationStatus.ConsultationCanceled,
                onClick: () => setOpenConsultationCancelDialog(true),
              },
            ];
          } else if (status === surgeonProcedureStatuses.ConsultationNeedsReschedule) {
            // Needs Reschedule
            return [
              {
                label: 'Reschedule Consultation',
                primary: true,
                onClick: () => setOpenRescheduleConsultationDialog(true),
              },
              {
                label: 'Cancel Consultation',
                status: ConsultationStatus.ConsultationCanceled,
                onClick: () => setOpenConsultationCancelDialog(true),
              },
            ];
          } else if (status === surgeonProcedureStatuses.ConsultationComplete) {
            // Consultation Completed
            return [
              {
                label: 'Plan Surgery',
                status: SurgeryStatus.SurgeryPlanned,
                primary: true,
                onClick: changeStatus(SurgeryStatus.SurgeryPlanned),
                loading: true,
              },
              {
                label: 'Mark as not Completed',
                status: ConsultationStatus.ConsultationConfirmed,
                onClick: changeStatus(ConsultationStatus.ConsultationConfirmed),
                loading: true,
              },
            ];
          } else if (status === surgeonProcedureStatuses.ConsultationCanceled) {
            // Consultation Canceled
            return [
              {
                label: 'Reschedule Consultation',
                onClick: () => setOpenRescheduleConsultationDialog(true),
              },
            ];
          } else if (status === surgeonProcedureStatuses.ConsultationNoShow) {
            // No Show
            return [
              {
                label: 'Reschedule Consultation',
                onClick: () => setOpenRescheduleConsultationDialog(true),
              },
              {
                label: 'Change back to Confirmed',
                status: ConsultationStatus.ConsultationConfirmed,
                onClick: changeStatus(ConsultationStatus.ConsultationConfirmed),
                loading: true,
              },
            ];
          } else {
            return [];
          }
        } else if (!state) {
          // Surgery Planned
          return [
            {
              label: 'Schedule Surgery',
              primary: true,
              onClick: redirectToSurgerySchedulePage,
            },
            {
              label: 'Remove from planned',
              onClick: changeStatus(ConsultationStatus.ConsultationConfirmed),
              loading: true,
            },
          ];
        } else if (state?.__typename === 'Requested') {
          // Surgery Scheduled
          return [
            {
              label: 'Reschedule Surgery',
              onClick: redirectToSurgerySchedulePage,
            },
            {
              label: 'Cancel Surgery',
              onClick: () => setOpenSurgeryCancelDialog(true),
            },
          ];
        } else if (state?.__typename === 'Confirmed') {
          // Case Scheduled
          return [
            {
              label: 'Reschedule Surgery',
              onClick: redirectToSurgerySchedulePage,
            },
            {
              label: 'Cancel Surgery',
              onClick: () => setOpenSurgeryCancelDialog(true),
            },
          ];
        } else if (state?.__typename === 'Cancelled') {
          // Surgery Canceled
          return [
            {
              label: 'Reschedule Surgery',
              onClick: redirectToSurgerySchedulePage,
            },
          ];
        } else if (state?.__typename === 'Rescheduled') {
          // Surgery Rescheduled
          return [
            {
              label: 'Reschedule Surgery',
              onClick: redirectToSurgerySchedulePage,
            },
            {
              label: 'Cancel Surgery',
              onClick: () => setOpenSurgeryCancelDialog(true),
            },
          ];
        } else {
          return [];
        }
      } else {
        if (state) {
          if (state?.__typename === 'Requested' && !state?.seen) {
            return [
              {
                label: 'Mark Request as Received',
                primary: true,
                onClick: async () => {
                  await updateState({
                    variables: {
                      id: transferId,
                      scheduleTransferRequestStatus: ScheduleTransferStatus.Received,
                    },
                  });
                },
                loading: true,
              },
              {
                label: 'Cancel Procedure',
                onClick: () => setOpenSurgeryCancelDialog(true),
              },
            ];
          } else if ((state?.__typename === 'Requested' && state?.seen) || state?.__typename === 'Rescheduled') {
            return [
              {
                label: 'Book Procedure',
                primary: true,
                onClick: () => setOpenBookingDialog(true),
              },
              {
                label: 'Cancel Procedure',
                onClick: () => setOpenSurgeryCancelDialog(true),
              },
            ];
          } else if (state?.__typename === 'Confirmed') {
            return [
              {
                label: 'Cancel Procedure',
                onClick: () => setOpenSurgeryCancelDialog(true),
              },
            ];
          } else if (state?.__typename === 'Cancelled') {
            return [];
          } else {
            return [];
          }
        } else {
          return [];
        }
      }
    },
    [changeStatus, consultationDate, isSurgeonOffice, redirectToSurgerySchedulePage, transferId, updateState]
  );

  const actions = useMemo(() => actionsFor(status, state), [actionsFor, status, state]);
  const [options, setOptions] = useState<Array<Option>>(actions);

  useEffect(() => {
    setOptions(actions);
  }, [actions]);

  return (
    <Box display="flex" flexDirection="row" justifyContent="flex-end">
      <ButtonWithOptions
        options={options}
        primaryOptions={options.filter(o => o?.primary)}
        secondaryOptions={options.filter(o => !o.primary)}
        loading={loadingStatus || loadingState}
        variant={variant}
      />
      <RescheduleConsultationDialog
        open={openRescheduleConsultationDialog}
        setOpen={setOpenRescheduleConsultationDialog}
        onConfirmAction={(date, time) => async () => {
          await rescheduleConsultation({
            variables: {
              id,
              consultationDate: date,
              consultationTime: time,
            },
          });
          await changeStatus(ConsultationStatus.Consultation)();
        }}
        data={{
          consultationDate,
          consultationTime,
        }}
      />
      <ConfirmCancellationDialog
        open={openConsultationCancelDialog}
        setOpen={setOpenConsultationCancelDialog}
        onConfirmAction={(input: string) => async () => {
          await updateStatus({
            variables: { id, status: ConsultationStatus.ConsultationCanceled },
          });
        }}
        title={'Cancel Consultation'}
        text={'Please write below reason for canceling this consultation.'}
      />
      <ConfirmCancellationDialog
        open={openSurgeryCancelDialog}
        setOpen={setOpenSurgeryCancelDialog}
        onConfirmAction={(input: string) => async () => {
          await updateState({
            variables: {
              id: transferId,
              scheduleTransferRequestStatus: ScheduleTransferStatus.Canceled,
              scheduleTransferStatusMessage: input,
            },
          });
        }}
      />
      <BookingDialog
        open={openBookingDialog}
        setOpen={setOpenBookingDialog}
        data={{
          dateOfService,
          externalProvider,
        }}
        onConfirmAction={(input: number) => async () => {
          await updateState({
            variables: {
              id: transferId,
              scheduleTransferRequestStatus: ScheduleTransferStatus.Approved,
              hstId: input,
            },
          });
        }}
      />
    </Box>
  );
};

export default withRouter(withScope(ChangeStatusButton));
