import { gql } from '@apollo/client';

export const fragments = {
  s: gql`
    fragment BraceletS on Bracelet {
      id
      batteryLevel
      updatedAt
    }
  `,
};

export const list = gql`
  query bracelets {
    bracelets {
      ...BraceletS
    }
  }
  ${fragments.s}
`;

export default {
  list,
};
