import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CustomTooltip from './CustomTooltip';
import { renderLegend } from './ORPerformancePieChart';
import React from 'react';
import { ChartWrapper } from '../PieCharts';
import { useTheme } from '@material-ui/core';

const BarUtilizationByOr = ({ data }) => {
  const theme = useTheme();

  return (
    <ChartWrapper title="Utilization % By OR">
      <ResponsiveContainer width="100%" height="100%" aspect={0.85}>
        <BarChart
          barGap={20}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.25)" />
          <XAxis type="category" dataKey="title" stroke="rgba(255,255,255,0.25)" />
          <YAxis hide type="number" domain={[0, 100]} axisLine={false} />
          <Tooltip
            content={({ active, payload, label }) => (
              <CustomTooltip active={active} payload={payload} label={label} unit="%" />
            )}
            cursor={{ fill: 'rgba(255,255,255,0.15)' }}
            contentStyle={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: 4,
              border: 0,
            }}
          />
          <Bar dataKey="pipoUtilization" stackId="a" fill="#00A7F7" />
          <Legend content={renderLegend} align="left" verticalAlign="bottom" iconType="circle" iconSize={8} />
        </BarChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};

export default BarUtilizationByOr;
