import React from 'react';
import { compose, withProps } from 'recompose';
import { getNestedValue } from '../../se/utilities/data/object';
import { withRouter } from 'react-router';
import ScreenRouter from './kiosk/ScreenRouter';
import { withSession } from '../../state/Session';
import queryString from 'query-string';
import ConnectionProvider from './kiosk/ConnectionProvider';

const Inject = ({ accessToken, isSuperAdmin, organizationId }) => {
  const queryParams = queryString.parse(location.search);

  if (queryParams.token) {
    return (
      <ConnectionProvider
        accessToken={queryParams.token}
        organizationId={organizationId}
        isHuman={true}
        isSuperAdmin={isSuperAdmin}
      >
        <ScreenRouter
          accessToken={queryParams.token}
          organizationId={organizationId}
          isHuman={true}
          isSuperAdmin={isSuperAdmin}
          inject
        />
      </ConnectionProvider>
    );
  }

  return (
    <ScreenRouter
      accessToken={accessToken}
      organizationId={organizationId}
      isHuman={true}
      isSuperAdmin={isSuperAdmin}
      inject
    />
  );
};

export default compose(
  withRouter,
  withProps(({ match }) => ({ organizationId: getNestedValue('params.organizationId', match) })),
  withSession(({ session }) => ({
    accessToken: getNestedValue('token', session),
  }))
)(Inject);
