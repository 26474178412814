import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import keyBy from 'lodash/keyBy';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import useLocalStorage from 'use-localstorage-hook';
import { isFunction } from '../../utilities/check';
import ListHeader from './ListHeader';
import { getSortedList, nextType } from './listHelpers';
import MobileListView from './MobileListView';
import Pager from './Pager';
import SimpleTextFilter from './SimpleTextFilter';

const ColumnName = styled.div`
  white-space: nowrap;
  text-align: left;
  padding: 1em;
  font-weight: 500;
  opacity: 0.65;
  display: flex;
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
  transition: all 160ms;
  :hover {
    opacity: 0.5;
  }
`;

const ItemListGrid = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  > * {
    margin-bottom: 0.25rem;
  }
`;

const RegularList = ({
  Component,
  hideColumns,
  columns,
  showUrlProvider,
  idProvider,
  onViewClick,
  getList,
  data,
  history,
  location,
  highlightedProvider,
  highlightedRowStyles,
  highlightedRowStyleProvider,
  containsSeparator,
  tableKey,
  useColumnSelection,
  useCSVExport,
  MobileItemComponent,
  simpleFilter,
  defaultSort,
}) => {
  const handleViewClick = (data, index) => () => {
    if (isFunction(onViewClick)) {
      onViewClick(data, index);
    }
  };
  const columnOptions = columns.map(_ => ({ title: _.title }));
  const columnKeys = keyBy(columnOptions, 'title');
  const [selectedColumns, setSelectedColumns] = useLocalStorage(tableKey, columnOptions);
  const cleanSelectedColumns = selectedColumns.filter(col => col.title in columnKeys);
  const [_currentSort, setCurrentSort] = useLocalStorage(
    `${tableKey}-sort`,
    defaultSort || { type: undefined, title: undefined }
  );
  const currentSort = isEmpty(_currentSort) ? defaultSort || {} : _currentSort;

  if (window.innerWidth < 992 && MobileItemComponent) {
    return (
      <MobileListView
        Component={Component}
        hideColumns={hideColumns}
        columns={columns}
        showUrlProvider={showUrlProvider}
        idProvider={idProvider}
        onViewClick={onViewClick}
        getList={getList}
        data={data}
        history={history}
        location={location}
        highlightedProvider={highlightedProvider}
        highlightedRowStyles={highlightedRowStyles}
        highlightedRowStyleProvider={highlightedRowStyleProvider}
        containsSeparator={containsSeparator}
        tableKey={tableKey}
        useColumnSelection={useColumnSelection}
        useCSVExport={false}
        MobileItemComponent={MobileItemComponent}
      />
    );
  }
  const sortColumn = columns.find(_ => _.title === currentSort.title && currentSort.type);

  const list = sortColumn
    ? getSortedList(getList(data), containsSeparator, currentSort, sortColumn, data)
    : getList(data);

  const visibleColumns = useColumnSelection
    ? columns.filter(({ title }) => !!selectedColumns.find(_ => _.title === title))
    : columns;

  return (
    <Fragment>
      <ListHeader
        showColumnSelection={!hideColumns && useColumnSelection}
        showCSVExport={useCSVExport}
        selectedColumns={cleanSelectedColumns}
        setSelectedColumns={setSelectedColumns}
        columns={columns}
        columnOptions={columnOptions}
        tableKey={tableKey}
        list={list}
        data={data}
      />

      <SimpleTextFilter onChange={val => val} active={simpleFilter} data={list} columns={columns}>
        {filtered => (
          <Pager data={filtered} currentSort={currentSort} tableKey={tableKey}>
            {pagedData => (
              <ItemListGrid columns={cleanSelectedColumns.length}>
                {hideColumns
                  ? null
                  : visibleColumns.map(({ title, sortDisabled }, i) => (
                      <ColumnName
                        key={i}
                        disabled={sortDisabled}
                        onClick={() =>
                          sortDisabled
                            ? {}
                            : setCurrentSort({
                                type: title !== currentSort.title ? 'ASC' : nextType(currentSort.type),
                                title,
                              })
                        }
                      >
                        <div>{title}</div>
                        <div>
                          {currentSort.title === title && currentSort.type === 'ASC' && <ArrowDropDown />}
                          {currentSort.title === title && currentSort.type === 'DESC' && <ArrowDropUp />}
                          {currentSort.title !== title && <div style={{ width: 19, height: 19 }} />}
                        </div>
                      </ColumnName>
                    ))}
                {pagedData.map((item, i) => (
                  <Component
                    key={`${idProvider(item)}-${i}`}
                    history={history}
                    location={location}
                    columns={visibleColumns}
                    item={item}
                    context={{ data }}
                    index={i}
                    showUrlProvider={showUrlProvider}
                    idProvider={idProvider}
                    onClick={handleViewClick(item, i)}
                    highlighted={((highlightedProvider ? highlightedProvider(item) : false) && 'true') || undefined}
                    highlightedRowStyles={
                      highlightedRowStyleProvider ? highlightedRowStyleProvider(item) : highlightedRowStyles
                    }
                  />
                ))}
              </ItemListGrid>
            )}
          </Pager>
        )}
      </SimpleTextFilter>
    </Fragment>
  );
};

export default RegularList;
