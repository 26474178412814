import React from 'react';
import { useValue } from '../ValueContext';
import { useQuestionnaireBooleanValue } from '../hooks';
import toBoolean from '../../../../../questionnaire/toBoolean';

const BiggerMedicalPassportCheckbox = ({
  label,
  name,
  value: checkboxValue,
  path,
  reducer,
  style,
  negative,
  onCheckboxChange,
}) => {
  const { defaultValue, value, setValue } = useValue(name);
  const questionnaireValue = useQuestionnaireBooleanValue(reducer || toBoolean, path);

  const questionnaire = negative ? !(questionnaireValue || defaultValue) : questionnaireValue || defaultValue;

  const handleChange = e => {
    const isChecked = e.target.checked;
    setValue(isChecked); // Update the value in ValueContext
    onCheckboxChange(isChecked ? checkboxValue : 0, name); // Call the onCheckboxChange prop with the updated checked status
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center', paddingTop: '13px', ...style }}>
      <label>
        <input
          type="checkbox"
          name={name}
          value={checkboxValue}
          checked={value ?? (questionnaire || false)}
          style={{ transform: 'scale(2)', marginRight: '15px' }}
          onChange={handleChange} // Update the onChange event handler to call handleChange
        />
        {label}
      </label>
    </div>
  );
};

export default BiggerMedicalPassportCheckbox;
