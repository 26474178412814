import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { setPatientEventTimestamp } from '../../../../graph/procedureSteps';
import { EventLogTime1, EventLogTime2 } from '../views/components';
import { format } from 'date-fns';
import { ZonedDateTime } from '@js-joda/core';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import { compose, withProps } from 'recompose';
import { withLabel } from '../../../../se/components/Label';
import ObjectInput from '../../../../se/components/inputs/ObjectInput';
import { CancelableForm, DateInput, formatTime } from '../../../inputs/timeEdit/TimeEditForm';
import { RouterlessModal } from '../../../../se/components/Modal';

export const convertToDateTime = (timestamp, time) => {
  const dateTime = new Date(timestamp);
  const [hours, minutes] = time.split(':').map(e => parseInt(e, 10));
  dateTime.setHours(hours);
  dateTime.setMinutes(minutes);
  return dateTime;
};

const getEventType = event => {
  switch (event?.type) {
    case 'Created':
    case 'Discharged':
    case 'BlockNerve':
    case 'CleaningCompleted':
      return { __type: `${event?.type}$` };
    case 'Entered':
      return { __type: 'Entered', roomId: event?.room?.id, manualEntryBy: null, manualTimestamp: null };
    case 'Exited':
      return { __type: 'Exited', roomId: event?.room?.id, manualEntryBy: null };
    case 'ProcedureStatusSet':
      return {
        __type: 'ProcedureStatusSet',
        status: { [event?.status]: {} },
        manualEntryBy: null,
        manualTimestamp: null,
      };
    default:
      return null;
  }
};

export const FormInput = eventText =>
  withProps({
    schema: {
      time: compose(
        withLabel(eventText),
        withProps({
          autoFocus: true,
        })
      )(DateInput),
    },
  })(ObjectInput);

const PatientEventTimestamp = ({ patientId, event, eventText, editable = false }) => {
  const [edit, setEdit] = useState(false);

  const [save] = useMutation(setPatientEventTimestamp);

  const toggleEdit = () => {
    setEdit(prev => !prev);
  };

  const saveTimestamp = async time => {
    const eventType = getEventType(event);
    const newDateTime = convertToDateTime(event?.timestamp, time);
    newDateTime.toISOString() !== new Date(event?.timestamp).toISOString() &&
      patientId &&
      eventType &&
      (await save({
        variables: {
          patientId,
          eventType: JSON.stringify(eventType),
          timestamp: newDateTime,
        },
      }));
    toggleEdit();
  };

  const onSubmit = async val => {
    await saveTimestamp(val?.time);
  };

  return editable ? (
    <Box onClick={e => e.stopPropagation()} display="flex" flexDirection="row" alignItems="center">
      <EventLogTime2 onClick={toggleEdit} edit={edit}>
        {format(ZonedDateTime.parse(event?.timestamp).toLocalDateTime().toString(), 'MM/DD/YYYY HH:mm')}
        <EditIcon color="primary" style={{ fontSize: '1.2rem' }} />
      </EventLogTime2>
      {edit && (
        <RouterlessModal title="Update Time" onClose={toggleEdit}>
          <CancelableForm
            autoFocus
            label="Update"
            onClose={toggleEdit}
            initialValue={{
              time: formatTime(ZonedDateTime.parse(event?.timestamp).toLocalDateTime().toString()),
            }}
            input={FormInput(eventText)}
            onSubmit={onSubmit}
            busy={false}
          />
        </RouterlessModal>
      )}
    </Box>
  ) : (
    <Box display="flex" flexDirection="row" alignItems="center">
      <EventLogTime1>
        {format(ZonedDateTime.parse(event?.timestamp).toLocalDateTime().toString(), 'MM/DD/YYYY HH:mm')}
      </EventLogTime1>
    </Box>
  );
};

export default PatientEventTimestamp;
