import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { TruncatedText } from '../../../se/components/typography';
import { formatPhoneNumber, processPhoneNumbersString } from '../../../se/components/inputs/PhoneInput';
import RouterLink from '../../../se/components/RouterLink';
import Vendor from '../../../types/Vendor';
import { Block, Check } from '@material-ui/icons';

const Text = TruncatedText as any;

export const nameColumn = {
  title: 'Name',
  lens: (item: Vendor) => item.name,
  Component: (props: { data: string }) => <Text>{props.data}</Text>,
};

export const addressColumn = {
  title: 'Address',
  lens: (item: Vendor) => item.address,
  Component: (props: { data: string }) => <Text>{props.data}</Text>,
};

export const phoneNumber = {
  title: 'Phone Number',
  lens: (item: Vendor) =>
    item.phoneNumber !== null && item.phoneNumber !== undefined
      ? formatPhoneNumber(processPhoneNumbersString(item.phoneNumber).nationalNumbers)
      : undefined,
  Component: (props: { data: string }) => <Text>{props.data}</Text>,
};

export const scheduleAccessColumn = {
  title: 'Schedule Access',
  lens: (item: Vendor) => item,
  Component: withRouter((props: { data: Vendor } & RouteComponentProps) => (
    <RouterLink onClick={e => e.stopPropagation()} to={`${props.match.url}/schedule-access/${props.data.id}`}>
      {props.data.hasScheduleAccess ? <Check fontSize={'small'} /> : <Block fontSize={'small'} />}
      <Text>{props.data.hasScheduleAccess ? 'Yes' : 'No'}</Text>
    </RouterLink>
  )),
};

export const emailColumn = {
  title: 'Email',
  lens: (item: Vendor) => item,
  Component: (props: { data: Vendor }) => <Text>{props.data?.email}</Text>,
};

export const baseColumns = [nameColumn, addressColumn, phoneNumber, emailColumn, scheduleAccessColumn];
export const viewColumns = [addressColumn, phoneNumber, emailColumn];
