import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { TruncatedText } from '../../../se/components/typography';
import { formatPhoneNumber, processPhoneNumbersString } from '../../../se/components/inputs/PhoneInput';
import RouterLink from '../../../se/components/RouterLink';
import StaffMember, {
  Compensation,
  CompensationLabel,
  Employment,
  EmploymentLabel,
  OtherSpecialty,
  OtherSpecialtyLabel,
  PrimarySpecialty,
  PrimarySpecialtyColors,
  PrimarySpecialtyLabel,
} from '../../../types/StaffMember';
import LaunchIcon from '@material-ui/icons/Launch';
import LinkButton from '../../../se/components/LinkButton';
import { Block, Check } from '@material-ui/icons';
import { gql } from '@apollo/client';
import { fragments } from '../../../graph/staff';
import { PinColumn } from '../common/columns';
import { formatProviderId } from '../../../util/providerId';
import { useScope } from '../../../hooks/useScope';

const Text = TruncatedText as any;

export const nameColumn = {
  title: 'Name',
  lens: (data: StaffMember) => data.name,
  Component: (props: { data: string }) => <Text>{props.data}</Text>,
};

export const providerIdColumn = {
  title: 'Provider ID',
  lens: (data: StaffMember) => data.hstId,
  Component: (props: { data: string }) => <Text>{formatProviderId(props.data)}</Text>,
};

export const emailColumn = {
  title: 'Email',
  lens: (data: StaffMember) => data.email,
  Component: (props: { data: string }) => <Text>{props.data}</Text>,
};

export const primarySpecialtyColumn = {
  title: 'Primary Specialty',
  lens: (data: StaffMember) => data.primarySpecialty,
  Component: (props: { data: PrimarySpecialty }) => (
    <Text>
      {PrimarySpecialtyLabel[props.data] && <span style={{ color: PrimarySpecialtyColors[props.data] }}>●&nbsp;</span>}
      {PrimarySpecialtyLabel[props.data]}
    </Text>
  ),
};

export const otherSpecialtiesColumn = {
  title: 'Other Specialties',
  lens: (data: StaffMember) => data.otherSpecialties,
  Component: (props: { data: OtherSpecialty[] }) => (
    <Text>{props.data && props.data.map(s => OtherSpecialtyLabel[s]).join(', ')}</Text>
  ),
};

export const titleColumn = {
  title: 'Title',
  lens: (data: StaffMember) => data.title,
  Component: (props: { data: string }) => <Text>{props.data || '-'}</Text>,
};

export const employmentColumn = {
  title: 'Employment',
  lens: (data: StaffMember) => data.employment,
  Component: (props: { data: Employment }) => <Text>{EmploymentLabel[props.data]}</Text>,
};

export const compensationColumn = {
  title: 'Compensation',
  lens: (data: StaffMember) => data.compensation,
  Component: (props: { data: Compensation }) => <Text>{CompensationLabel[props.data]}</Text>,
};

export const hourlyRateColumn = {
  title: 'Hourly Rate',
  lens: (data: StaffMember) => data.hourlyRate,
  Component: (props: { data: string }) => <Text>${props.data}</Text>,
};

export const phoneNumber = {
  title: 'Phone Number',
  lens: (data: StaffMember) =>
    data.phoneNumber !== null && data.phoneNumber !== undefined
      ? formatPhoneNumber(processPhoneNumbersString(data.phoneNumber).nationalNumbers)
      : undefined,
  Component: (props: { data: string }) => <Text>{props.data}</Text>,
};

export const scheduleAccessColumn = {
  title: 'Schedule Access',
  lens: (data: StaffMember) => data,
  Component: withRouter((props: RouteComponentProps & { data: StaffMember }) => (
    <RouterLink onClick={e => e.stopPropagation()} to={`${props.match.url}/schedule-access/${props?.data?.staffId}`}>
      {props.data.hasScheduleAccess ? <Check fontSize={'small'} /> : <Block fontSize={'small'} />}
      <Text>{props.data.hasScheduleAccess ? 'Yes' : 'No'}</Text>
    </RouterLink>
  )),
};

export const pinColumn = PinColumn<StaffMember & { pin?: string }>({
  getId: data => data.staffId,
  getName: data => data.name,
  getPin: data => data.pin,
  createPinMutation: gql`
    mutation staffCreatePin($id: Long!, $pin: String!) {
      staffSetPin(id: $id, pin: $pin) {
        ...StaffMember
        pin
      }
    }
    ${fragments.staffMemberOnly}
  `,
  issuePinMutation: gql`
    mutation staffIssuePin($id: Long!) {
      staffIssuePin(id: $id) {
        ...StaffMember
        pin
      }
    }
    ${fragments.staffMemberOnly}
  `,
  removePinMutation: gql`
    mutation staffRemovePin($id: Long!) {
      staffRemovePin(id: $id) {
        ...StaffMember
        pin
      }
    }
    ${fragments.staffMemberOnly}
  `,
});

const calendarColumn = {
  title: 'Calendar',
  lens: (data: StaffMember) => data,
  Component: withRouter((props: RouteComponentProps & { data: StaffMember }) => {
    const { hospital } = useScope();

    const onClick = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      window.open(`/kiosk/${hospital?.id}/calendar/staff/${props?.data?.staffId}`);
    };

    return (
      <LinkButton onClick={onClick}>
        <LaunchIcon style={{ fontSize: 20, marginRight: 2 }} />
        Open Calendar
      </LinkButton>
    );
  }),
};

export const baseColumns = [nameColumn, primarySpecialtyColumn, phoneNumber, scheduleAccessColumn, pinColumn];

export const viewColumns = [
  providerIdColumn,
  phoneNumber,
  emailColumn,
  primarySpecialtyColumn,
  otherSpecialtiesColumn,
  titleColumn,
  employmentColumn,
  compensationColumn,
  hourlyRateColumn,
  calendarColumn,
];
