import { ApolloClient, useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { fragments } from '../../../graph/surgeon/procedures';
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Source } from '../../../components/OmniSearch';
import * as H from 'history';
import { format } from 'date-fns';

interface Procedure {
  id: number;
  status?: string;
  appointmentDateTime?: string;
  appointmentTime?: string;
  appointmentDate?: string;
  serviceDateTime?: string;
  serviceTime?: string;
  serviceDate?: string;
  consultationDateTime?: string;
  consultationTime?: string;
  consultationDate?: string;
  duration?: number;
  visit: number;
  notes?: string;
  procedureType?: string;
  events: {
    id: number;
    type: string;
    timestamp?: string;
    patientPin?: string;
    cancelReason?: string;
    channel?: string;
    content?: string;
    user: {
      id: number;
      name?: string;
    };
    text?: string;
    status?: string;
    exchangeId?: number;
  };
  patient?: {
    id: number;
    name?: string;
    homePhone?: string;
    cellPhone?: string;
    emailAddress?: string;
    dateOfBirth?: string;
    sex?: string;
    age?: string;
    initials?: string;
    ratingInviteSent?: boolean;
    ratingInviteSentAt?: string;
    physician?: {
      id: number;
      hstId?: number;
      name?: string;
      email?: string;
    };
    createdAt?: string;
  };
}

const proceduresSource: Source<Procedure> = {
  name: 'Patient',
  useSource: (input: string, client: ApolloClient<any>) => {
    const { data, loading, error } = useQuery<{ searchProcedures?: Procedure[] }, { search: string }>(
      gql`
        query searchProcedures($search: String!) {
          searchProcedures(search: $search) {
            ...ProcedureData
          }
        }
        ${fragments.all}
      `,
      {
        client,
        variables: {
          search: input,
        },
      }
    );

    return {
      data: data?.searchProcedures ?? [],
      loading,
      error,
    };
  },
  noOptionsText: (input: string) => (input.length > 0 ? 'No patients found.' : 'Start typing to search patients.'),
  renderOption: (procedure: Procedure, { selected: boolean }) => {
    const secondaryText = [
      procedure.consultationDateTime
        ? `Consultation date: ${format(procedure.consultationDateTime, 'MM/DD/YYYY HH:mm')}`
        : 'No consultation date',
      procedure.serviceDateTime
        ? `Date of service: ${format(procedure.serviceDateTime, 'MM/DD/YYYY HH:mm')}`
        : 'No date of service',
      procedure.patient?.physician?.name || 'No physician',
    ].join(' • ');

    return (
      <Box py={1} display="flex" alignItems="center">
        <Box>
          <Box display="flex" alignItems="baseline">
            <Typography>{procedure.patient?.name || '-'}</Typography>
            {procedure.patient?.dateOfBirth && (
              <Box ml={1}>
                <Typography variant="body2" color="textSecondary">
                  DOB: {procedure.patient?.dateOfBirth}
                </Typography>
              </Box>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant="body2" color="textSecondary">
              {secondaryText}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  },
  getOptionDisabled: (option: Procedure) => false,
  getOptionLabel: (option: Procedure) => option.patient?.name ?? '-',
  onSelect: (option: Procedure, location: H.Location, history: H.History) => {
    const prefix = (location.pathname.match(/\/su\/(\d+)/) ?? [''])[0];
    const isConsultations = location.pathname.includes('consultations');

    isConsultations
      ? history.push(`${prefix}/consultations/${option.id}`)
      : history.push(`${prefix}/surgeries/${option.id}`);
  },
};

export default proceduresSource;
