import React from 'react';
import { AirFiltrationIndicatorTimer } from '../../widgets/AirFiltrationIndicator';
import ProcedureProgress from '../ProcedureProgress';
import CleaningClock from '../CleaningClock';
import { ButtonLabel, MainButton } from '../common';
import { useStyles, WithWorking } from './OperationRoomTabletV2';
import Box from '@material-ui/core/Box';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Procedure from '../../schedule/overview/Procedure';
import { Room } from '../../../../../types/Room';
import styled from 'styled-components';
import responsiveStyles from '../../../../../se/utilities/responsive';
import { ProcedureState } from '../util/procedureSteps';

const responsive = responsiveStyles as any;

const StatusWrapper = styled.div`
  margin: auto 0;
`;

const StatusWrapperAlt = styled(StatusWrapper)`
  position: relative;
  grid-column: 1 / span 2;
  margin: 0;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ClockWrapper = styled(StatusWrapper)`
  grid-column: 1 / span 2;
  margin: 0;
  margin-bottom: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
`;

const Actions = styled.div`
  display: flex;
  padding: 0 1.75rem;

  ${responsive.sm.andSmaller`
    flex-flow: column-reverse;
    padding: 0 .25rem;
  `};
`;

const ActionsAlt = styled(Actions)`
  grid-column: 1 / span 2;
  padding-bottom: 2.5rem;
`;
const Cleaning = ({
  status,
  room,
  procedureState,
  working,
  withWorking,
  nextProcedure,
}: {
  status: any;
  working: boolean;
  withWorking: any;
  nextProcedure: Procedure;
  room: Room;
  procedureState: ProcedureState | null;
}) => {
  const classes = useStyles();
  return (
    <Box width="100vw" height="100vh" display="flex" flexDirection="column" justifyContent="center">
      <StatusWrapperAlt>
        <AirFiltrationIndicatorTimer roomId={room?.id} style={{ position: 'absolute', left: '3rem', top: '2rem' }} />
        <ProcedureProgress status={status} noIcon />
      </StatusWrapperAlt>

      <ClockWrapper>
        <CleaningClock room={room} />
      </ClockWrapper>

      <ActionsAlt>
        <MainButton onClick={withWorking(procedureState?.next)} disabled={working}>
          <WithWorking working={working} size={'4rem'}>
            <Box fontSize={'6.5em'}>
              <ChevronRightIcon fontSize={'inherit'} />
            </Box>
          </WithWorking>
          <ButtonLabel>{procedureState?.nextLabel}</ButtonLabel>
        </MainButton>
      </ActionsAlt>

      {nextProcedure && (
        <>
          <Divider />

          <Box width="100%" p={2} flexDirection="flex">
            <Box mb={2}>
              <Typography align="center" variant="h6" gutterBottom>
                Upcoming Procedure
              </Typography>
            </Box>
            <Box display="block" className={classes.dialogContent}>
              {/* @ts-ignore */}
              <Procedure
                onClick={undefined}
                procedure={nextProcedure}
                isUnassigned={false}
                date={new Date()}
                isPatientIconVisible={true}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Cleaning;
