import { graphql } from '@apollo/client/react/hoc';
import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { compose, mapProps, withProps } from 'recompose';
import get from 'lodash/fp/get';
import Page from '../../../../se/components/Page';
import Sidebar from '../../../../se/components/Sidebar';
import ChangePassword from '../ChangePassword';
import Header from '../admin/Header';
import Footer from '../admin/Footer';
import { dark as darkTheme, light as lightTheme } from '../../../../theme';
import ActivePatients from '../../../entities/patient/ActivePatients';
import { enrollmentReader } from '../../../../graph/enrollmentReader';
import { Context as PatientNameFormatContext } from '../../../../contexts/PatientNameFormat';
import Modal from '../../../../se/components/Modal';
import { withSession } from '../../../../state/Session';
import ExternalSchedule from '../../ExternalSchedule';
import { unpackSessionObject } from '../../unpackSessionObject';
import { isDefinedAndNotNull } from '../../../../se/utilities/check';
import Training from '../../training/Training';
import { compact } from 'lodash';
import UnregisteredPatients from '../../../entities/frontProcedures/UnregisteredPatients';
import { PacuTabletApp, PostOpTabletApp, PrepTabletApp } from '../../kiosk/ScreenRouter';
import { list as roomsQuery } from '../../../../graph/rooms';
import { useQuery } from '@apollo/client';
import { ROOM_TYPES } from '../../../entities/room/enums';
import find from 'lodash/find';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { muiThemeLight } from '../../../../muiTheme';
import { MainContainer } from '../../MainContainer';
import { OmniSearchProvider, OmniSearchSources } from '../../../OmniSearch';
import activeAndPastProceduresSource from '../../../../omnisearch/sources/hospital/activeAndPastProcedures';
import scheduledProceduresSource from '../../../../omnisearch/sources/hospital/scheduledProcedures';
import ScheduledRequests from '../../../entities/scheduleRequests/ScheduledRequests';
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';
import { CovidScreeningSC } from '../../../entities/covidScreening/CovidScreeningSubmissions';
import {
  AccessibilityNew,
  Description,
  Event,
  Hotel,
  Schedule,
  School,
  ShoppingCart,
  Theaters,
  Today,
  VideoLabel,
} from '@material-ui/icons';
import ScheduledProcedures from '../../../entities/procedures/ScheduledProcedures';
import InTakeForms from '../../../entities/procedures/InTakeForms';
import { withScope } from '../../../../contexts/ScopeContext';

const TabletWrapper = withStyles(theme => ({
  root: {
    margin: theme.spacing(-2, -3, 2),
  },
}))(Box);

const PrepAppScreen = props => {
  const { data: rooms } = useQuery(roomsQuery);
  const room = find(get('rooms')(rooms), ({ type }) => type === ROOM_TYPES.PRE_OP);
  return (
    <ThemeProvider theme={darkTheme}>
      <TabletWrapper>
        <PrepTabletApp roomId={get('id')(room)} {...props} />
      </TabletWrapper>
    </ThemeProvider>
  );
};

const PacuAppScreen = props => {
  const { data: rooms } = useQuery(roomsQuery);
  const room = find(get('rooms')(rooms), ({ type }) => type === ROOM_TYPES.PACU);
  return (
    <ThemeProvider theme={darkTheme}>
      <TabletWrapper>
        <PacuTabletApp roomId={get('id')(room)} {...props} />
      </TabletWrapper>
    </ThemeProvider>
  );
};

const PostOpAppScreen = props => {
  const { data: rooms } = useQuery(roomsQuery);
  const room = find(get('rooms')(rooms), ({ type }) => type === ROOM_TYPES.POST_OP);
  return (
    <ThemeProvider theme={darkTheme}>
      <TabletWrapper>
        <PostOpTabletApp roomId={get('id')(room)} {...props} />
      </TabletWrapper>
    </ThemeProvider>
  );
};

const makeRoutes = ({ modules, ospitekOrdersIntegrations, organizationId }) =>
  modules.lightMode
    ? compact([
        {
          path: `/patients`,
          to: `/patients`,
          label: 'All Patients',
          icon: <AccessibilityNew />,
          component: ActivePatients,
        },
        modules.inTakeForms
          ? {
              path: `/registration-packages`,
              to: `/registration-packages`,
              label: 'Registration Packages',
              icon: <Description />,
              component: InTakeForms,
            }
          : undefined,
        modules.patientRegistration
          ? {
              path: `/scheduled-patients`,
              to: `/scheduled-patients`,
              label: 'PreOp Registration',
              icon: <Schedule />,
              component: ScheduledProcedures,
            }
          : undefined,
        modules.covidScreening
          ? {
              path: `/covid-screening`,
              to: `/covid-screening`,
              label: 'Covid Screening',
              icon: 'coronavirus',
              component: CovidScreeningSC,
            }
          : undefined,
        {
          path: `/preop`,
          to: `/preop`,
          label: 'PreOp Patients',
          icon: 'mediation',
          component: PrepAppScreen,
        },
        {
          path: `/pacu`,
          to: `/pacu`,
          label: 'Recovery Patients',
          icon: <Hotel />,
          component: PacuAppScreen,
        },
        modules.hasPostop
          ? {
              path: `/post-op`,
              to: `/post-op`,
              label: 'PostOp Patients',
              icon: <Hotel />,
              component: PostOpAppScreen,
            }
          : undefined,
        modules.acceptsProcedureTransfer
          ? {
              path: `/scheduled-requests`,
              to: `/scheduled-requests`,
              label: 'Schedule requests',
              icon: <Today />,
              component: ScheduledRequests,
            }
          : undefined,
      ])
    : compact([
        {
          path: `/patients`,
          to: `/patients`,
          label: 'Patients',
          icon: <AccessibilityNew />,
          component: ActivePatients,
        },
        modules.inTakeForms
          ? {
              path: `/registration-packages`,
              to: `/registration-packages`,
              label: 'Registration Packages',
              icon: <Description />,
              component: InTakeForms,
            }
          : undefined,
        modules.patientRegistration
          ? {
              path: `/scheduled-patients`,
              to: `/scheduled-patients`,
              label: 'PreOp Registration',
              icon: <Schedule />,
              component: ScheduledProcedures,
            }
          : undefined,
        modules.covidScreening
          ? {
              path: `/covid-screening`,
              to: `/covid-screening`,
              label: 'Covid Screening',
              icon: 'coronavirus',
              component: CovidScreeningSC,
            }
          : undefined,
        modules.acceptsProcedureTransfer
          ? {
              path: `/scheduled-requests`,
              to: `/scheduled-requests`,
              label: 'Schedule requests',
              icon: <Today />,
              component: ScheduledRequests,
            }
          : undefined,
        { separator: true },
        {
          path: `/kiosk/${organizationId}/monitor`,
          to: `/kiosk/${organizationId}/monitor`,
          target: '_blank',
          label: 'Operations Screen',
          icon: <VideoLabel />,
        },
        {
          path: `/schedule`,
          to: `/schedule`,
          label: 'OR Schedule Screen',
          icon: <Event />,
          component: ExternalSchedule,
        },
        { separator: true },
        modules.ospitekOrdersIntegrations
          ? {
              externalLink: ospitekOrdersIntegrations?.externalLink?.link,
              label: 'Orders',
              icon: <ShoppingCart />,
            }
          : undefined,
        {
          path: `/training`,
          to: `/training`,
          label: 'Video Tutorials',
          icon: <Theaters />,
          component: Training,
        },
      ]);

const getBasePathAndUrl = match => {
  if (!isDefinedAndNotNull(match) || !isDefinedAndNotNull(!match.path) || !isDefinedAndNotNull(!match.url)) {
    return ['', ''];
  }
  return [match.path === '/' ? '' : match.path, match.url === '/' ? '' : match.url];
};

const FrontSidebar = compose(
  withSession(unpackSessionObject),
  withRouter,
  withScope,
  withProps({
    Header,
    links: [],
    Footer,
  }),
  mapProps(props => {
    const { organization, match, search } = props;
    const organizationId = organization;
    const [basePath, baseUrl] = getBasePathAndUrl(match);

    const routes = makeRoutes({
      modules: Object.assign(
        {},
        {
          covidScreening: Boolean(props.scope?.hospital?.covidScreening),
          inTakeForms: Boolean(props.scope?.hospital?.modules?.inTakeForms),
          ospitekOrdersIntegrations: !!props.scope?.hospital?.ospitekOrdersIntegrations?.externalLink?.enabled,
        },
        get('scope.hospital.modules')(props)
      ),
      basePath,
      baseUrl,
      organizationId,
      search,
      ospitekOrdersIntegrations: props.scope?.hospital?.ospitekOrdersIntegrations,
    });

    return {
      ...props,
      links: routes,
      separatorColor: 'rgba(0,0,0,0.1)',
    };
  })
)(Sidebar);

class Homepage extends React.PureComponent {
  state = { braceletId: undefined, batteryLevel: undefined };

  componentDidUpdate = () => {
    if (!this.props.data.loading) {
      const { braceletId, patientId, batteryLevel } = this.props.data.enrollmentReader || {};

      if (this.state.braceletId !== braceletId && (braceletId || '').toLowerCase() !== 'ac233fa1cdc9') {
        this.setState({ braceletId, batteryLevel });

        if (braceletId && !patientId) {
          if (batteryLevel <= 20) {
            this.props.history.replace('/patients');
          } else {
            this.props.history.replace(`/patients/create?braceletId=${braceletId}`);
          }
        } else if (!!patientId) {
          this.props.history.replace(`/patients/${patientId}/edit`);
        }
      }
    }
  };
  render() {
    const { patientNameFormat } = this.props;
    const { braceletId, batteryLevel } = this.state;
    const routes = makeRoutes({
      modules: Object.assign(
        {},
        {
          covidScreening: !!this.props.scope?.hospital?.covidScreening,
        },
        get('scope.hospital.modules')(this.props)
      ),
    })
      .filter(_ => !_.separator)
      .filter(_ => !_.externalLink);

    return (
      <ThemeProvider theme={lightTheme}>
        <MuiThemeProvider theme={muiThemeLight}>
          <CssBaseline />
          <PatientNameFormatContext.Provider value={patientNameFormat}>
            <OmniSearchProvider>
              <OmniSearchSources sources={[activeAndPastProceduresSource, scheduledProceduresSource]} />
              <Page Sidebar={FrontSidebar}>
                <MainContainer switchControl>
                  {braceletId && batteryLevel <= 20 ? (
                    <Modal
                      title={braceletId}
                      onClose={() => this.setState({ braceletId: null, batteryLevel: null })}
                      closeTo="/patients"
                    >
                      <p>
                        Please put this bracelet in the bracelet box for recycling, and get another new one for this
                        patient.
                      </p>
                    </Modal>
                  ) : null}
                  <Switch>
                    {routes.map(({ path, component, separator }, index) => (
                      <Route key={path || index} path={path} component={component} />
                    ))}
                    <Route path="/change-password" component={ChangePassword} />
                    <Route render={() => <Redirect to="/patients" />} />
                  </Switch>
                </MainContainer>
              </Page>
            </OmniSearchProvider>
          </PatientNameFormatContext.Provider>
        </MuiThemeProvider>
      </ThemeProvider>
    );
  }
}

export default compose(withSession(get('session.account.metadata')), withScope, graphql(enrollmentReader))(Homepage);
