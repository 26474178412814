import { lighten, Theme } from '@material-ui/core';

export default function Button(theme: Theme) {
  return {
    MuiFab: {
      root: {
        ...theme.typography.button,
        width: 64,
        height: 64,
        color: theme.palette.text.primary,
        backgroundColor: lighten(theme.palette.primary.main, 0.9),
        '&:hover': {
          backgroundColor: lighten(theme.palette.primary.main, 0.85),
          // Reset on touch devices, it doesn’t add specificity
          '@media (hover: none)': {
            backgroundColor: lighten(theme.palette.primary.main, 0.9),
          },
        },
      },
      extended: {
        borderRadius: 56 / 2,
        minWidth: 56,
        height: 56,
        fontSize: '1.2rem',
      },
    },
  };
}
