import { createContext, useContext } from 'react';

interface ConnectionProviderContext {
  accessToken: string;
  organizationId?: string;
  connected: boolean;
}

const connectionProviderContext = createContext<ConnectionProviderContext | undefined>(undefined);

export default connectionProviderContext;

export const useConnectionProviderContext = () => {
  const context = useContext(connectionProviderContext);

  if (!context) {
    throw new Error('useConnectionProviderContext must be used within a ConnectionProviderContext');
  }

  return context;
};
