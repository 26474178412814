export enum FormType {
  PreOpForm = 'PRE_OP_FORM',
  InTakeForm = 'IN_TAKE_FORM',
  PreOpChartForm = 'PREOP_CHART_FORM',
  OrChartForm = 'OR_CHART_FORM',
  PacuChartForm = 'PACU_CHART_FORM',
  PhysicianChartForm = 'PHYSICIAN_CHART_FORM',
  AnesthesiologistOrChartForm = 'ANESTHESIOLOGIST_OR_CHART_FORM',
  AnesthesiologistPreOpChartForm = 'ANESTHESIOLOGIST_PREOP_CHART_FORM',
  AnesthesiologistPacuChartForm = 'ANESTHESIOLOGIST_PACU_CHART_FORM',
}

export enum FormSaveType {
  Filled = 'Filled',
  Uploaded = 'Uploaded',
}

export default interface Form {
  id: number;
  name?: string;
  type: FormType;
  saveType: FormSaveType;
  content: string;
  order?: number;
  isSigned?: boolean;
  config?: string;
}
