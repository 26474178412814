import { StaffSchedulePdfGenerator as StaffSchedulePdfGeneratorV1 } from './v1/StaffSchedulePdfGenerator';
import { StaffSchedulePdfGenerator as StaffSchedulePdfGeneratorV2 } from './v2/StaffSchedulePdfGenerator';
import { ORSchedulePdfGenerator as ORSchedulePdfGeneratorV1 } from './v1/ORSchedulePdfGenerator';
import { ORSchedulePdfGenerator as ORSchedulePdfGeneratorV2 } from './v2/ORSchedulePdfGenerator';
import { ORSchedulePdfGenerator as ORSchedulePdfGeneratorV3 } from './v3/ORSchedulePdfGenerator';
import { ORSchedulePdfGenerator as ORSchedulePdfGeneratorV4 } from './v4/ORSchedulePdfGenerator';
import { ORSchedulePdfGenerator as ORSchedulePdfGeneratorV5 } from './v5/ORSchedulePdfGenerator';

export const generateSchedulePdf = (pdfName: string) => {
  return ({
    'Staff Schedule v1': StaffSchedulePdfGeneratorV1,
    'Staff Schedule v2': StaffSchedulePdfGeneratorV2,
    'OR Schedule v1': ORSchedulePdfGeneratorV1,
    'OR Schedule v2': ORSchedulePdfGeneratorV2,
    'OR Schedule v3': ORSchedulePdfGeneratorV3,
    'OR Schedule v4': ORSchedulePdfGeneratorV4,
    'OR Schedule v5': ORSchedulePdfGeneratorV5,
  })[pdfName];
}
