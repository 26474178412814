import { InlineColumn, ResendActionsInTakeWrapper, ResendActionsQuestionnaireWrapper } from '../columns';
import { TruncatedText } from '../../../../se/components/typography';
import { format } from 'date-fns';
import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import find from 'lodash/find';
import get from 'lodash/get';
import { eventTypes } from '../ProcedureEventLog';
import { Typography } from '@material-ui/core';
import { ZonedDateTime } from '@js-joda/core';
import { QuestionnaireTypeContext } from '../QuestionnaireAnswers';
import { Procedure } from '../../../../types/Procedure';

const Inline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 2rem;
`;

const QuestionnaireInviteWrapper = styled.div`
  flex: 1;
  @media (max-width: 768px) {
    padding-top: 1rem;
  }
`;

const QuestionnaireInvite: FC<{ procedure: Procedure }> = ({ procedure }) => {
  const questionnaireType = useContext(QuestionnaireTypeContext);

  const invite = find(
    get(procedure, 'events'),
    ({ type }) =>
      type === (questionnaireType === 'InTake Form' ? eventTypes.InTakeFormSent : eventTypes.QuestionnaireSent)
  );

  return (
    <QuestionnaireInviteWrapper>
      <Typography variant="body1" color="textSecondary">
        <strong>{questionnaireType === 'InTake Form' ? 'Registration Package' : questionnaireType} Invite</strong>
      </Typography>
      <Inline>
        <InlineColumn>
          {invite && <TruncatedText>{`Patient PIN: ${invite.patientPin}`}</TruncatedText>}
          <TruncatedText style={{ opacity: 0.6 }}>
            {invite
              ? `Sent at ${format(
                  ZonedDateTime.parse(invite.timestamp).toLocalDateTime().toString(),
                  'MM/DD/YYYY HH:mm'
                )}`
              : 'Not Sent'}
          </TruncatedText>
        </InlineColumn>
        {questionnaireType === 'InTake Form' ? (
          <ResendActionsInTakeWrapper data={procedure} />
        ) : (
          <ResendActionsQuestionnaireWrapper data={procedure} />
        )}
      </Inline>
    </QuestionnaireInviteWrapper>
  );
};

export default QuestionnaireInvite;
