import Form, { FormType } from '../../../../types/Form';
import { useQuery } from '@apollo/client';
import PatientChartForm from '../../../entities/surgeonProcedures/PatientChartForm';
import { QuestionnaireEntry, QuestionnaireType, QuestionnaireAndAnswers } from '../../../../types/Questionnaire';
import { Procedure } from '../../../../types/Procedure';
import React, { useState } from 'react';
import { getLastProcedureEvent } from '../../../../util/procedureEvents';
import { ProcedureStatus } from '../../../../types/ProcedureEvent';
import { getPrintFn } from '../../../entities/procedures/QuestionnaireAnswers';
import { getFullPdfGenerator, getMedPdfGenerator } from '../../../entities/procedures/pdf/getPdfGenerator';
import useHasAccessRight from '../../../../hooks/useHasAccessRight';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { alpha, makeStyles, Step, StepButton, StepLabel, Stepper, Typography } from '@material-ui/core';
import Forms from '../../../entities/procedures/Forms';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { list, procedureForms } from '../../../../graph/surgeon/forms';
import { useScope } from '../../../../hooks/useScope';
import PatientChartFormGroup from '../../../entities/surgeonProcedures/PatientChartFormGroup';

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: alpha(theme.palette.common.white, 0.8),
    backdropFilter: 'blur(10px)',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    position: 'fixed',
    bottom: 0,
    left: 0,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    borderTop: `1px solid ${theme.palette.divider}`,
    width: '100%',
  },
}));

export const getQuestionnaireType = (formType: FormType): QuestionnaireType => {
  switch (formType) {
    case FormType.PreOpChartForm:
      return QuestionnaireType.PreOpChart;
    case FormType.OrChartForm:
      return QuestionnaireType.OrChart;
    case FormType.PacuChartForm:
      return QuestionnaireType.PacuChart;
    case FormType.PhysicianChartForm:
      return QuestionnaireType.PhysicianChart;
    case FormType.AnesthesiologistPreOpChartForm:
      return QuestionnaireType.AnesthesiologistPreOpChart;
    case FormType.AnesthesiologistOrChartForm:
      return QuestionnaireType.AnesthesiologistOrChart;
    case FormType.AnesthesiologistPacuChartForm:
      return QuestionnaireType.AnesthesiologistPacuChart
    default:
      return QuestionnaireType.PreOpChart;
  }
};

export const getFormType = (type: QuestionnaireType): FormType => {
  switch (type) {
    case QuestionnaireType.PreOpChart:
      return FormType.PreOpChartForm;
    case QuestionnaireType.OrChart:
      return FormType.OrChartForm;
    case QuestionnaireType.PacuChart:
      return FormType.PacuChartForm;
    case QuestionnaireType.PhysicianChart:
      return FormType.PhysicianChartForm;
    case QuestionnaireType.AnesthesiologistPreOpChart:
      return FormType.AnesthesiologistPreOpChartForm;
    case QuestionnaireType.AnesthesiologistOrChart:
      return FormType.AnesthesiologistOrChartForm;
    case QuestionnaireType.AnesthesiologistPacuChart:
      return FormType.AnesthesiologistPacuChartForm;
    default:
      return FormType.PreOpForm;
  }
};

export const findByFormType = (forms?: Form[], formType: FormType): Form | undefined =>
  (forms || []).find(f => f.type === formType);

export const findAllByFormType = (forms?: Form[], formTypes: FormType[]): Form[] => {
  if (!forms || !formTypes.length) {
    return [];
  }

  const foundForms: Form[] = [];

  for (const formType of formTypes) {
    const matchingForms = forms.filter(f => f.type === formType);
    foundForms.push(...matchingForms);
  }

  return foundForms;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const CustomStepIcon = ({ index, active }: { index: number; active: boolean }) => (
  <Box
    width={32}
    height={32}
    display="flex"
    alignItems="center"
    justifyContent="center"
    bgcolor={active ? '#00A7F7' : 'text.secondary'}
    borderRadius={100}
    color="white"
  >
    {index}
  </Box>
);

const ChartingForms = ({
  hospitalId,
  patientId,
  procedureId,
  questionnaire,
  procedure,
  pacuCharts,
}: {
  hospitalId: number;
  patientId: number;
  procedureId: number;
  questionnaire: QuestionnaireEntry;
  procedure: Procedure;
  pacuCharts: QuestionnaireAndAnswers[];
}) => {
  const { data: formsData } = useQuery(list);

  const preOpFormTypes = [FormType.PreOpChartForm, FormType.AnesthesiologistPreOpChartForm, FormType.PhysicianChartForm];
  const orFormTypes = [FormType.OrChartForm, FormType.AnesthesiologistOrChartForm];
  const pacuFormTypes = [FormType.PacuChartForm, FormType.AnesthesiologistPacuChartForm];

  const preOpChartForms = findAllByFormType(formsData?.forms, preOpFormTypes);
  const orChartForms = findAllByFormType(formsData?.forms, orFormTypes);
  const pacuChartForms = findAllByFormType(formsData?.forms, pacuFormTypes);

  interface FormGroup {
    id: string;
    name: string;
    forms: Form[];
  }

  const chartFormGroups: FormGroup[] = [
    ...toOptionalFormGroup('preOp', 'PreOp Chart', preOpChartForms),
    ...toOptionalFormGroup('or', 'OR Chart', orChartForms),
    ...toOptionalFormGroup('pacu', 'PACU Chart', pacuChartForms),
  ];

  const [tab, setTab] = useState<number>(null);
  const tabNumber = tab || 0;

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const questionnaireVersion = questionnaire?.questionnaireVersion;
  const { data: queryData, loading, error } = useQuery(procedureForms, { variables: { procedureId } });
  const forms = (queryData?.procedureForms || [])
    .filter(form =>
      form?.content?.includes('medicalPassport')
        ? (questionnaireVersion === 1 && form?.content === 'medicalPassport') ||
          `medicalPassport${questionnaireVersion}` === form?.content
        : true
    )
    .filter(form => form.formType === FormType.PreOpForm);
  const intakeForms = (queryData?.procedureForms || []).filter(form => form.formType === FormType.InTakeForm);
  const lastPreOpCompletedEvent = getLastProcedureEvent(procedure?.events || [], ProcedureStatus.PreOpCompleted);

  const handleStep = (step: number) => () => {
    setTab(step);
  };

  const scope = useScope();
  const onPrint = getPrintFn(questionnaire, pacuCharts, procedure, scope);

  const fullPdfGenerator = getFullPdfGenerator(hospitalId);

  const medicationPdf = getMedPdfGenerator(hospitalId);

  const hasAccessRight = useHasAccessRight();
  const isAllowedToView = hasAccessRight('patient.view');

  const classes = useStyles();

  return (
    <Paper variant="outlined">
      <Box px={4} mt={4}>
        <Typography variant="h3">Documents</Typography>
      </Box>
      <Box pt={1} pb={2}>
        <Stepper nonLinear activeStep={tabNumber}>
          <Step>
            <StepButton color="inherit" onClick={handleStep(0)}>
              <StepLabel
                StepIconProps={{
                  icon: <CustomStepIcon active={tabNumber === 0} index={1} />,
                }}
              >
                PreOp Registration
              </StepLabel>
            </StepButton>
          </Step>
          <Step>
            <StepButton color="inherit" onClick={handleStep(1)}>
              <StepLabel
                StepIconProps={{
                  icon: <CustomStepIcon active={tabNumber === 1} index={2} />,
                }}
              >
                Registration Package
              </StepLabel>
            </StepButton>
          </Step>
          {chartFormGroups.map(({ name }, index) => (
            <Step key={2 + index}>
              <StepButton color="inherit" onClick={handleStep(2 + index)}>
                <StepLabel
                  StepIconProps={{
                    icon: <CustomStepIcon active={tabNumber === 2 + index} index={3 + index} />,
                  }}
                >
                  {name}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box px={4} minWidth={0}>
        <Box>
          <TabPanel value={tabNumber} index={0}>
            <Forms
              patientId={patientId}
              procedureId={procedureId}
              forms={forms}
              lastPreOpCompletedEvent={lastPreOpCompletedEvent}
              additionalButtons={[
                {
                  title: 'Full Form',
                  icon: <PictureAsPdfIcon />,
                  onClick: onPrint(fullPdfGenerator),
                },
                {
                  title: 'Medication Rec. Form',
                  icon: <PictureAsPdfIcon />,
                  onClick: onPrint(medicationPdf),
                },
              ]}
            ></Forms>
          </TabPanel>
          <TabPanel value={tabNumber} index={1}>
            <Forms patientId={patientId} procedureId={procedureId} forms={intakeForms} lastPreOpCompletedEvent={lastPreOpCompletedEvent} />
          </TabPanel>
          {chartFormGroups.map(({ id, name, forms }, index) => (
            <TabPanel key={`${id}-panel`} value={tabNumber} index={2 + index}>
              <PatientChartFormGroup
                hospitalId={hospitalId}
                patientId={patientId}
                procedureId={procedureId}
                forms={forms}
              />
            </TabPanel>
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

export default ChartingForms;

const toOptionalFormGroup = (id: string, fallbackName: string, forms: Form[]): FormGroup[] =>
  forms.length > 0
    ? [
        {
          id: id,
          name: forms.length === 1 ? forms[0].name : fallbackName,
          forms: forms,
        },
      ]
    : [];
