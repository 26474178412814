import { alpha } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import componentsOverrides from './tablet/overrides';
import shadows from './tablet/styles/shadows';

const swatches = {
  primary: '#000C3F',
  secondary: '#00A7F7',
};

const typography = {
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  // fontSize: 20,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontSize: '2.488rem',
  },
  h2: {
    fontSize: '2.074rem',
  },
  h3: {
    fontSize: '1.728rem',
  },
  h4: {
    fontSize: '1.44rem',
    fontWeight: 500,
    lineHeight: 1.2,
  },
  h5: {
    fontSize: '1.2rem',
    fontWeight: 500,
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  button: {
    fontSize: '1rem',
    fontWeight: 700,
    textTransform: 'none',
  },
};

const paletteLight = {
  type: 'light',
  primary: {
    main: swatches.primary,
    dark: '#000a26',
  },
  secondary: {
    light: '#C6E6F5',
    main: swatches.secondary,
    dark: '#0070b5',
  },
  text: {
    primary: swatches.primary,
    secondary: 'rgba(0,12,64,0.55)',
  },
  divider: '#00385229',
  action: {
    active: '#000C3F',
    selected: alpha(swatches.primary, 0.1),
    hoverOpacity: 0.1,
    disabled: alpha(swatches.primary, 0.26),
    disabledBackground: alpha(swatches.primary, 0.12),
  },
};

const paletteDark = {
  type: 'dark',
  primary: {
    main: swatches.primary,
    dark: '#000a26',
  },
  background: {
    paper: swatches.primary,
    default: swatches.primary,
  },
  secondary: {
    light: '#C6E6F5',
    main: swatches.secondary,
    dark: '#0070b5',
  },
  text: {
    primary: '#fff',
    secondary: 'rgba(255,255,255,0.55)',
  },
};

export const tabletThemeLight = createTheme({
  palette: paletteLight,
  typography: typography,
});

export const tabletThemeDark = createTheme({
  palette: paletteDark,
  typography: typography,
});

tabletThemeLight.shadows = shadows(tabletThemeLight);
tabletThemeLight.overrides = componentsOverrides(tabletThemeLight);
