import React from 'react';
import { compose, withProps } from 'recompose';
import { getNestedValue } from '../../se/utilities/data/object';
import { withRouter } from 'react-router';
import ScreenRouter from './kiosk/ScreenRouter';
import KioskMachine from './kiosk/KioskMachine';
import { withSession } from '../../state/Session';
import { unpackSessionObject } from './unpackSessionObject';
import { AccessToken } from './kiosk/AccessToken';

const Kiosk = ({ accessToken, session, unpackedSession, isHuman, isSuperAdmin, organizationId }) => (
  <AccessToken accessToken={accessToken}>
    {isHuman ? (
      <ScreenRouter
        accessToken={accessToken}
        organizationId={organizationId}
        isHuman={true}
        isSuperAdmin={isSuperAdmin}
      />
    ) : (
      <KioskMachine organizationId={organizationId} />
    )}
  </AccessToken>
);

export default compose(
  withRouter,
  withProps(({ match }) => ({ organizationId: getNestedValue('params.organizationId', match) })),
  withSession(({ session }) => ({
    isHuman: session && session.account && session.user,
    accessToken: getNestedValue('token', session),
    session,
    ...unpackSessionObject({ session }),
  }))
)(Kiosk);
