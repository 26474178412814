import React from 'react';
import styled from 'styled-components';
import renderNode from './renderNode';
import get from 'lodash/get';
import { H4 } from '../../../../se/components/typography';
import responsive from '../../../../se/utilities/responsive';
import { withTheme } from '../../../../se/theme';

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1em 0 4em;
  border-bottom: 3px solid ${withTheme(theme => theme.border.color.default.string())};
  break-inside: avoid;
`;

const Sections = styled.div`
  column-count: 2;
  column-gap: 2em;
  column-rule: 1px solid ${withTheme(theme => theme.border.color.default.string())};

  ${responsive.lg.andSmaller`
    column-count: 1;
  `};
`;

export default ({ sections, value }) => (
  <Sections>
    {sections.map(([id, field]) => (
      <Section key={id}>
        {field.name && <H4>{field.name}</H4>}
        <div>{renderNode(field.body, { id, value: get(value, `sections.${id}`) })}</div>
      </Section>
    ))}
  </Sections>
);
