import React from 'react';
import styled from 'styled-components';
import { Icon } from '../../SchedulePage';
import { formatMinutes } from '../shared/procedureUtils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;

  span {
    font-size: 1.16666em;
  }
`;

export default ({ duration }) => (
  <Wrapper>
    <Icon style={{ opacity: 0.25, fontSize: '1.333em', marginRight: '.125em' }}>timer</Icon>
    <span>{formatMinutes(duration)}</span>
  </Wrapper>
);
