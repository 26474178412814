import { gql } from '@apollo/client';
import { fragments as statisticsFragments } from './dashboard';

export const fragments = {
  all: gql`
    fragment BookingData on Booking {
      id
      roomId
      physician {
        id
        name
        speciality
        category
        color
      }
      organization {
        id
        name
      }
      start
      startTime
      end
      endTime
      repeat
      endsAfterWeeks
      date
      notes
      masterBookingId
      createdAt
      utilization
    }
  `,
};

export const list = gql`
  subscription bookings($from: Date!, $to: Date!) {
    bookings(from: $from, to: $to) {
      ...BookingData
    }
  }
  ${fragments.all}
`;

export const bookingStats = gql`
  subscription bookingStats($filter: Filter!) {
    bookingStats(filter: $filter) {
      ...StatisticsData
    }
  }
  ${statisticsFragments.all}
`;

export const physicianStats = gql`
  subscription physicianStats($filter: Filter!) {
    physicianStats(filter: $filter) {
      ...StatisticsData
    }
  }
  ${statisticsFragments.all}
`;

export const create = gql`
  mutation createBooking(
    $id: Long
    $masterBookingId: Long
    $roomId: Long!
    $physician: Long
    $organization: Long
    $date: Date!
    $fromMinute: Int!
    $endsAfterWeeks: Int
    $toMinute: Int!
    $repeat: Int
    $notes: String
    $speciality: String
  ) {
    createBooking(
      id: $id
      masterBookingId: $masterBookingId
      roomId: $roomId
      physician: $physician
      organizationId: $organization
      date: $date
      fromMinute: $fromMinute
      toMinute: $toMinute
      repeat: $repeat
      notes: $notes
      speciality: $speciality
      endsAfterWeeks: $endsAfterWeeks
    )
  }
`;

export const update = gql`
  mutation updateBooking(
    $id: Long
    $masterBookingId: Long
    $roomId: Long!
    $physician: Long
    $organization: Long
    $date: Date!
    $fromMinute: Int!
    $endsAfterWeeks: Int
    $toMinute: Int!
    $repeat: Int
    $notes: String
    $speciality: String
    $updateRest: Boolean
  ) {
    updateBooking(
      id: $id
      masterBookingId: $masterBookingId
      roomId: $roomId
      physician: $physician
      organizationId: $organization
      date: $date
      fromMinute: $fromMinute
      toMinute: $toMinute
      repeat: $repeat
      notes: $notes
      speciality: $speciality
      endsAfterWeeks: $endsAfterWeeks
      updateRest: $updateRest
    )
  }
`;

export const remove = gql`
  mutation removeBooking($id: Long!, $removeRest: Boolean) {
    removeBooking(id: $id, removeRest: $removeRest)
  }
`;
