import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import ToggleInput from '../../../se/components/inputs/ToogleInput';
import { Paragraph } from '../../../se/components/typography';
import { enableCovidScreening } from '../../../graph/covidScreening';

interface Props {
  data?: {
    id: string;
    covidScreening?: any;
  };
}

const CovidScreeningToggle = ({ data: props }: Props) => {
  const [setEnabled, { loading, data }] = useMutation(enableCovidScreening);

  const [value, setValue] = useState<boolean>(!!props?.covidScreening);

  const isEnabled = !!props?.covidScreening;

  useEffect(() => setValue(isEnabled), [isEnabled]);

  useEffect(() => {
    if (data === true || data === false) setValue(data);
  }, [data]);

  const handleChange = (enabled: boolean) => {
    setValue(enabled);

    setEnabled({
      variables: {
        id: props?.id,
        enabled,
      },
    }).catch(() => {
      setValue(!enabled);
    });
  };

  return (
    <Box display="flex">
      <Box mr={0.5}>
        {/* @ts-ignore */}
        <ToggleInput isDisabled={loading} value={value} onChange={handleChange} style={{ margin: 0 }} />
      </Box>
      <Box flexGrow={1}>
        <Paragraph style={{ margin: 0, whiteSpace: 'pre-wrap' }}>Covid Screening</Paragraph>
      </Box>
    </Box>
  );
};

export default CovidScreeningToggle;
