import React, { useState } from 'react';
import styled from 'styled-components';
import { withTheme } from '../../../../theme';
import { withDate } from '../../../HospitalInfo';
import { formatDuration } from '../../../../util/duration';

const Timer = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  font-size: 1.25em;
  font-family: monospace;
  font-weight: 500;
  opacity: 0.75;
  color: ${withTheme(theme => theme.textColor.string())};
  margin-right: 10em;
`;

const withSeconds = withDate(1000);

const CleaningTime = props => {
  const [cleaningStartedAtDefault] = useState(new Date());

  return (
    <Timer>
      Cleaning Time:{' '}
      {formatDuration(
        props?.room?.cleaningStartedAt || cleaningStartedAtDefault,
        props?.room?.turnoverStartedAt || props.date,
        true
      )}
    </Timer>
  );
};

export default withSeconds(CleaningTime);
