import { Typography, withStyles } from '@material-ui/core';

const AnalyticsPanelTitle = withStyles({
  root: {
    fontSize: '0.75rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
  },
})(Typography);

export default AnalyticsPanelTitle;
