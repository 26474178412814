import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';

export default ({ image, text, children, ...props }) => (
  <Box style={{ margin: 'auto', textAlign: 'center', padding: '1em', maxWidth: '22em' }} {...props}>
    {image && <img style={{ maxWidth: '12em' }} src={image} alt />}
    {text && <Typography>{text}</Typography>}
    {children && <div>{children}</div>}
  </Box>
);
