import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { updateDocs } from '../../../graph/scheduleRequests';
import FormSectionsInput from '../../inputs/FormSectionsInput';
import Form from '../../../se/components/Form';
import { RouterlessModal } from '../../../se/components/Modal';
import { withProps } from 'recompose';
import { withLabel } from '../../../se/components/Label';
import { FieldRow } from '../../pages/kiosk/schedule/edit/ProcedureEditor';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import SignPhysicianInput from '../../inputs/SignPhysicianInput';
import Grid from '@material-ui/core/Grid';
import { ArrowBack } from '@material-ui/icons';

const ProcedureDocumentsInput = () =>
  withProps({
    schema: {
      formIds: withLabel('Sections from patient file to transfer')(FormSectionsInput),
      signPhysician: SignPhysicianInput,
    },
    render: props => {
      const { formIds, signPhysician } = props;
      return (
        <Fragment>
          <FieldRow>{formIds}</FieldRow>
          <FieldRow style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
            <Grid container>
              <Grid item md={6} />
              <Grid item md={6}>
                {signPhysician}
              </Grid>
            </Grid>
          </FieldRow>
        </Fragment>
      );
    },
  })(ObjectInput);

const ProcedureDocumentsModal = ({ procedure, physician, open, onClose, onGoBack }) => {
  const [busy, setBusy] = useState(false);
  const [updateSchedule] = useMutation(updateDocs);

  const [id, setId] = useState(0);

  useEffect(() => setId(prev => prev + 1), [setId]);

  const Input = useMemo(() => ProcedureDocumentsInput(), []);

  const handleSubmit = async value => {
    setBusy(true);
    try {
      await updateSchedule({
        variables: {
          id: procedure.scheduleRequest.id,
          formIds: value.formIds,
          signPhysician: value.signPhysician,
        },
        refetchQueries: [],
      });
      onClose();
    } catch (e) {
      console.error(e);
    } finally {
      setBusy(false);
    }
  };

  return (
    <Fragment>
      {open && (
        <RouterlessModal
          title={
            <Box display="flex">
              <Box mr={1}>
                <IconButton color="primary" edge="start" onClick={onGoBack} aria-label="delete">
                  <ArrowBack fontSize={'small'} />
                </IconButton>
              </Box>
              {procedure && (
                <Box flex={1}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography component="h2" variant="h6">
                      {procedure.patient ? procedure.patient.name || procedure.patientName : procedure.patientName}{' '}
                      {procedure.patient && (
                        <Box component="span" ml={1} color="text.secondary">
                          {procedure.patient.age}/{procedure.patient.sex}
                        </Box>
                      )}
                    </Typography>
                  </Box>
                  {procedure.procedureType && <Typography color="textSecondary">{procedure.procedureType}</Typography>}
                </Box>
              )}
            </Box>
          }
          onClose={onClose}
          width="60em"
        >
          <Form
            key={`pdoc-${id}`}
            autoFocus
            label="Update"
            input={Input}
            inputProps={{ procedure, physician }}
            onSubmit={handleSubmit}
            busy={busy}
            initialValue={{
              formIds: procedure.scheduleRequest.payload.forms || [],
              signPhysician: procedure.scheduleRequest.payload.signedByPhysician || false,
            }}
          />
        </RouterlessModal>
      )}
    </Fragment>
  );
};

export default ProcedureDocumentsModal;
