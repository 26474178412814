import React from 'react';
import { deserialize } from 'react-serialize';
import createComponents from './components';

const renderFormNode = (node: unknown, props?: unknown) => {
  const components = createComponents();

  return props ? React.cloneElement(deserialize(node, { components }), props) : deserialize(node, { components });
};

export default renderFormNode;
