import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { useMutation, useSubscription } from '@apollo/client';
import {
  scheduleUserProcedures,
  setProcedureShiftState as setProcedureShiftStateMutation,
} from '../../../../../../graph/procedures';
import { scheduleUserRoomShifts, setRoomShiftState as setRoomShiftStateMutation } from '../../../../../../graph/rooms';
import { Procedure, ShiftState } from '../../../../../../types/Procedure';
import { ScheduleUserType } from '../../../../../../types/ScheduleUser';
import StaffShiftRoom from '../../../../../../types/StaffShift';

interface ScheduleUserContextData {
  scheduleUserId?: number | null;
  scheduleUserType?: ScheduleUserType | null;
  loading?: boolean;
  assignedProcedures: Procedure[];
  assignedRoomShifts: StaffShiftRoom[];
  isAssignedProcedure: (procedure: Procedure) => boolean;
  isAnsweredProcedure: (procedure: Procedure) => boolean;
  isAssignedRoomShift: (shift: StaffShiftRoom) => boolean;
  isAnsweredRoomShift: (shift: StaffShiftRoom) => boolean;
  hasMore: boolean;
  loadMore: () => void;
  setEditing: (id: number | null, type: 'ProcedureShift' | 'RoomShift' | null) => void;
  isEditing: (id: number | null, type: 'ProcedureShift' | 'RoomShift' | null) => boolean;
  acceptProcedure: (procedureId: number) => Promise<void>;
  rejectProcedure: (procedureId: number) => Promise<void>;
  acceptRoomShift: (shiftId: number) => Promise<void>;
  rejectRoomShift: (shiftId: number) => Promise<void>;
}

const ScheduleUserContext = createContext<ScheduleUserContextData>({
  scheduleUserId: null,
  scheduleUserType: null,
  loading: true,
  assignedProcedures: [],
  assignedRoomShifts: [],
  isAssignedProcedure: () => false,
  isAnsweredProcedure: () => false,
  isAssignedRoomShift: () => false,
  isAnsweredRoomShift: () => false,
  hasMore: true,
  loadMore: () => {},
  setEditing: () => {},
  isEditing: () => false,
  acceptProcedure: async () => {},
  rejectProcedure: async () => {},
  acceptRoomShift: async () => {},
  rejectRoomShift: async () => {},
});

export function useScheduleUserContext() {
  return useContext(ScheduleUserContext);
}

export const ScheduleUserProvider: FC<
  { scheduleUserId?: number | null; scheduleUserType?: ScheduleUserType | null } & {
    children: React.ReactNode;
  }
> = ({ scheduleUserId, scheduleUserType, children }) => {
  const [limit, setLimit] = useState(10);

  const [procedureShiftsData, setProcedureShiftsData] = useState<any>(undefined);

  const proceduresSubscription = useSubscription(scheduleUserProcedures, {
    variables: {
      scheduleUserId,
      scheduleUserType,
      offset: 0,
      limit,
    },
  });

  useEffect(() => {
    if (proceduresSubscription.loading) {
      return;
    }
    setProcedureShiftsData(proceduresSubscription.data);
  }, [proceduresSubscription.data]);

  const [roomShiftsData, setRoomShiftsData] = useState<any>(undefined);

  const roomShiftsSubscription = useSubscription(scheduleUserRoomShifts, {
    variables: {
      scheduleUserId,
      scheduleUserType,
      offset: 0,
      limit,
    },
    skip:
      scheduleUserType === ScheduleUserType.Vendor ||
      scheduleUserType === ScheduleUserType.Physician ||
      scheduleUserType === ScheduleUserType.Anesthesiologist,
  });

  useEffect(() => {
    if (roomShiftsSubscription.loading) {
      return;
    }
    setRoomShiftsData(roomShiftsSubscription.data);
  }, [roomShiftsSubscription.data]);

  const [entity, setEntity] = useState<{ id: number | null; type: 'ProcedureShift' | 'RoomShift' | null } | null>(null);

  const procedures = procedureShiftsData?.scheduleUserProcedures?.entities || [];
  const roomShifts = roomShiftsData?.scheduleUserRoomShifts?.entities || [];

  const setEditing = (id: number | null, type: 'ProcedureShift' | 'RoomShift' | null) => {
    if (!scheduleUserId) return;
    switch (scheduleUserType) {
      case ScheduleUserType.Vendor:
        if (
          type === 'ProcedureShift' &&
          procedures?.find(p =>
            p.assignedVendors?.find(
              e => e.id === id && e.vendor?.id === scheduleUserId && scheduleUserType === ScheduleUserType.Vendor
            )
          )
        ) {
          setEntity(id && type ? { id, type } : null);
        } else {
          setEntity(null);
        }
        return;
      case ScheduleUserType.StaffMember:
        if (
          type === 'ProcedureShift' &&
          procedures?.find(p =>
            p.staffShifts?.find(
              e =>
                e.id === id && e.staff?.staffId === scheduleUserId && scheduleUserType === ScheduleUserType.StaffMember
            )
          )
        ) {
          setEntity(id && type ? { id, type } : null);
        } else if (
          type === 'RoomShift' &&
          roomShifts?.find(
            e => e.id === id && e.staff?.staffId === scheduleUserId && scheduleUserType === ScheduleUserType.StaffMember
          )
        ) {
          setEntity(id && type ? { id, type } : null);
        } else {
          setEntity(null);
        }
        return;
      default:
        return;
    }
  };
  const isEditing = (id: number | null, type: 'ProcedureShift' | 'RoomShift' | null) => {
    if (!scheduleUserId) return false;

    return entity ? entity?.id === id && entity?.type === type : false;
  };

  const loading =
    scheduleUserType === ScheduleUserType.Vendor ||
    scheduleUserType === ScheduleUserType.Physician ||
    scheduleUserType === ScheduleUserType.Anesthesiologist
      ? proceduresSubscription.loading || false
      : proceduresSubscription.loading || roomShiftsSubscription.loading || false;
  const hasMore =
    procedureShiftsData?.scheduleUserProcedures?.hasNextPage ||
    roomShiftsData?.scheduleUserRoomShifts?.hasNextPage ||
    false;

  const loadMore = () => setLimit(prev => prev + 10);

  const [setProcedureShiftState] = useMutation(setProcedureShiftStateMutation);
  const [setRoomShiftState] = useMutation(setRoomShiftStateMutation);

  const changeProcedureState = async (procedureId: number, state: ShiftState) => {
    if (!scheduleUserId) return;

    await setProcedureShiftState({
      variables: {
        procedureId,
        scheduleUserType,
        scheduleUserId,
        state,
      },
    });
    setEntity(null);
  };

  const changeRoomShiftState = async (shiftId: number, state: ShiftState) => {
    if (!scheduleUserId) return;

    await setRoomShiftState({
      variables: {
        shiftId,
        scheduleUserType,
        scheduleUserId,
        state,
      },
    });
    setEntity(null);
  };

  const isAssignedProcedure = (procedure: Procedure) => {
    if (!scheduleUserId) return false;

    switch (scheduleUserType) {
      case ScheduleUserType.Vendor:
        return procedure?.assignedVendors?.find(e => e?.vendor?.id === scheduleUserId) !== undefined;
      case ScheduleUserType.StaffMember:
        return procedure?.staffShifts?.find(e => e?.staff?.staffId === scheduleUserId) !== undefined;
      default:
        return false;
    }
  };

  const isAnsweredProcedure = (procedure: Procedure) => {
    if (!scheduleUserId) return false;

    switch (scheduleUserType) {
      case ScheduleUserType.Vendor: {
        const assigned = procedure?.assignedVendors?.find(e => e?.vendor?.id === scheduleUserId);
        return assigned ? assigned?.shiftState !== ShiftState.Pending : false;
      }
      case ScheduleUserType.StaffMember: {
        const assigned = procedure?.staffShifts?.find(e => e?.staff?.staffId === scheduleUserId);
        return assigned ? assigned?.shiftState !== ShiftState.Pending : false;
      }
      default:
        return false;
    }
  };

  const isAssignedRoomShift = (shift: StaffShiftRoom) => {
    if (!scheduleUserId) return false;

    switch (scheduleUserType) {
      case ScheduleUserType.StaffMember:
        return shift?.staff?.staffId === scheduleUserId;
      default:
        return false;
    }
  };

  const isAnsweredRoomShift = (shift: StaffShiftRoom) => {
    if (!scheduleUserId) return false;

    switch (scheduleUserType) {
      case ScheduleUserType.StaffMember:
        return shift?.staff?.staffId === scheduleUserId && shift.shiftState !== ShiftState.Pending;
      default:
        return false;
    }
  };

  return (
    <ScheduleUserContext.Provider
      value={{
        scheduleUserId,
        scheduleUserType,
        loading,
        assignedProcedures: procedures,
        assignedRoomShifts: roomShifts,
        isAssignedProcedure,
        isAnsweredProcedure,
        isAssignedRoomShift,
        isAnsweredRoomShift,
        hasMore,
        loadMore,
        setEditing,
        isEditing,
        acceptProcedure: procedureId => changeProcedureState(procedureId, ShiftState.Accepted),
        rejectProcedure: procedureId => changeProcedureState(procedureId, ShiftState.Rejected),
        acceptRoomShift: shiftId => changeRoomShiftState(shiftId, ShiftState.Accepted),
        rejectRoomShift: shiftId => changeRoomShiftState(shiftId, ShiftState.Rejected),
      }}
    >
      {children}
    </ScheduleUserContext.Provider>
  );
};
