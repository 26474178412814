export default class GroupedByDate<T> {
  static empty<T>() {
    return new GroupedByDate<T>({});
  }

  private map: { [key: string]: T[] };

  private constructor(map: { [key: string]: T[] }) {
    this.map = map;
  }

  add(date: string, record: T): GroupedByDate<T> {
    const previous = this.map[date];

    return new GroupedByDate<T>({ ...this.map, [date]: [...(previous ? previous : []), record] });
  }

  get(date: string): T[] {
    return this.map[date] ?? [];
  }

  size(): number {
    return Object.keys(this.map).length;
  }
}
