import React, { FormEvent, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import {
  Button,
  FormControl,
  InputLabel,
  Paper,
  Select,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { gql, useMutation, useQuery } from '@apollo/client';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Status from './Status';
import { MONTHS } from './utils';
import { CheckCircleOutlined } from '@material-ui/icons';
// @ts-ignore
import waitingRoomCover from '../../../../../assets/images/waiting_room_cover.png';
import QuestionnaireForm from './QuestionnaireForm';
// @ts-ignore
import { withLabel } from '../../../../../se/components/Label';
import TextInput from '../../../../../se/components/inputs/TextInput';
import Timeout from './Timeout';
// @ts-ignore
import ospitekLogo from '../../../../../assets/images/ospitek_logo_white.svg';
import { tryParseJson } from '../../../../../util/parseJson';
import RideHomeForm from './RideHomeForm';
import { useScope } from '../../../../../hooks/useScope';
import { SendingSetting } from '../../../../entities/notificationTemplate/NotificationTemplates';
import { getNotificationTemplate } from '../../../../../graph/notificationTemplates';

interface WaitingRoomTabletProps {
  configuration: {
    questionnaireSessionTimeout?: number | null;
    showRideHome?: boolean;
    showPreOpWr?: boolean;
    showIntakeWr?: boolean;
  };
}

const WaitingRoomTablet = ({ configuration }: WaitingRoomTabletProps) => {
  const scope = useScope();
  const theme = useTheme();

  const [dateOfBirth, setDateOfBirth] = useState<string | undefined>(undefined);
  const [done, setDone] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [hasPhoneNumber, setHasPhoneNumber] = useState(false);
  const [askRideHome, setAskRideHome] = useState<number | undefined>(undefined);
  const [preOpForm, setPreOpForm] = useState<[number, string] | undefined>(undefined);
  const [inTakeForm, setInTakeForm] = useState<[number, string] | undefined>(undefined);

  const [month, setMonth] = useState<number | undefined>(undefined);
  const [day, setDay] = useState<number | undefined>(undefined);
  const [year, setYear] = useState<number | undefined>(undefined);

  const { data: questionnaireMessage } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'QuestionnaireInvite', channel: 'SMS', destination: 'Procedure' },
  });
  const questionnaireMessageEnabled = questionnaireMessage?.getNotificationTemplate?.[0]?.sendingSetting !== SendingSetting.Off || false;

  const { data: intakeMessage } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'IntakeFormInvite', channel: 'SMS', destination: 'Procedure' },
  });
  const intakeMessageEnabled = intakeMessage?.getNotificationTemplate?.[0]?.sendingSetting !== SendingSetting.Off || false;

  useEffect(() => {
    console.debug('Waiting Room Tablet Configuration', configuration);
  }, [configuration]);

  useEffect(() => {
    if (!!month && !!day && !!year) {
      setDateOfBirth(`${year}-${month > 9 ? month : '0' + month}-${day > 9 ? day : '0' + day}`);
    }
  }, [month, day, year]);

  const [setArrived, { loading: busy }] = useMutation(gql`
    mutation setArrived($lastName: String!, $dateOfBirth: Date!) {
      setArrived(name: $lastName, date: $dateOfBirth)
    }
  `);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!dateOfBirth || busy) {
      return;
    }

    try {
      const result =
        (await setArrived({
          variables: {
            lastName,
            dateOfBirth,
          },
        })) ?? {};

      const { status, hasPhoneNumber, askRideHome, preOpForm, inTakeForm } = tryParseJson(result.data.setArrived) as {
        status: string;
        hasPhoneNumber?: boolean;
        askRideHome?: number;
        preOpForm?: [number, string];
        inTakeForm?: [number, string];
      };

      if (status === 'Incomplete') {
        setDone(true);
        setHasPhoneNumber(hasPhoneNumber ?? false);
        setAskRideHome(askRideHome);
        setPreOpForm(preOpForm);
        setInTakeForm(inTakeForm);
      } else if (status !== 'Done') {
        setError(status);
      } else {
        setDone(true);
      }
    } catch (e) {
      console.error(e);
      setError('Unexpected Error');
    }
  };

  const reset = () => {
    setDone(false);
    setError(undefined);
    setHasPhoneNumber(false);
    setAskRideHome(undefined);
    setPreOpForm(undefined);
    setInTakeForm(undefined);
    setDateOfBirth(undefined);
    setMonth(undefined);
    setDay(undefined);
    setYear(undefined);
    setLastName('');
  };

  const [grow, setGrow] = useState(false);
  const [lastName, setLastName] = useState('');
  const questionnaireSessionTimeout =
    configuration?.questionnaireSessionTimeout === undefined
      ? 30000
      : configuration?.questionnaireSessionTimeout === null
      ? null
      : configuration.questionnaireSessionTimeout * 1000;
  const showRideHome = configuration?.showRideHome ?? false;
  const showPreOpWr = configuration?.showPreOpWr ?? false;
  const showIntakeWr = configuration?.showIntakeWr ?? false;

  return (
    <ThemeProvider
      theme={(theme: Theme) =>
        createTheme({
          ...theme,
          typography: {
            fontSize: 22,
          },
        })
      }
    >
      <Box display="flex" flex={1} bgcolor="#000C3F">
        <Box
          flex={1}
          alignSelf={grow ? 'stretch' : 'initial'}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          px={8}
          style={{}}
        >
          <Box
            m={grow ? undefined : 'auto'}
            mx={grow ? theme.spacing(0.5) : undefined}
            display="flex"
            flex={grow ? 1 : undefined}
            alignSelf={grow ? 'stretch' : 'initial'}
            flexDirection="column"
            alignItems="center"
          >
            {!grow && (
              <Box px={8} mb={18} mt={12} textAlign="center">
                <Box mb={3}>
                  <Typography variant="h5" style={{ lineHeight: 1.4, fontWeight: 700, color: '#fff' }}>
                    Welcome to {scope?.hospital?.name ? `${scope.hospital.name}` : 'Welcome!'}
                  </Typography>
                </Box>
                <Typography style={{ color: '#fff' }} gutterBottom>
                  Please confirm your arrival by providing the information <br /> in the form below.
                </Typography>
              </Box>
            )}
            <Paper
              style={{
                display: 'flex',
                zIndex: 1,
                borderRadius: 16,
                flex: grow ? 1 : undefined,
                alignSelf: grow ? 'stretch' : 'initial',
                marginTop: theme.spacing(4),
                marginBottom: theme.spacing(4),
              }}
            >
              <Box p={grow ? 0 : 6} minWidth="70ch" flex={1} display="flex" flexDirection="column">
                {error ? (
                  <Box mt={12}>
                    <Status
                      Icon={CancelOutlinedIcon}
                      color={theme.palette.error.main}
                      message="Please go to the front desk!"
                      details={
                        error === 'Not Found'
                          ? 'We could not find your appointment.'
                          : 'There was an error looking up your appointment.'
                      }
                      onDone={reset}
                    />
                  </Box>
                ) : done ? (
                  askRideHome && showRideHome ? (
                    <RideHomeForm procedureId={askRideHome} onDone={() => setAskRideHome(undefined)} />
                  ) : preOpForm && showPreOpWr ? (
                    <Timeout
                      duration={questionnaireSessionTimeout}
                      onTimeout={() => {
                        setPreOpForm(undefined);
                        setInTakeForm(undefined);
                      }}
                    >
                      <QuestionnaireForm
                        key="PreOp"
                        type="PreOp"
                        exchangeId={preOpForm[0]}
                        accessToken={preOpForm[1]}
                        dateOfBirth={dateOfBirth}
                        messageEnabled={questionnaireMessageEnabled}
                        hasPhoneNumber={hasPhoneNumber}
                        onDone={() => setPreOpForm(undefined)}
                        onStarted={() => setGrow(true)}
                        onStopped={() => setGrow(false)}
                      />
                    </Timeout>
                  ) : inTakeForm && showIntakeWr ? (
                    <Timeout
                      duration={questionnaireSessionTimeout}
                      onTimeout={() => {
                        setPreOpForm(undefined);
                        setInTakeForm(undefined);
                      }}
                    >
                      <QuestionnaireForm
                        key="InTake"
                        type="InTake"
                        exchangeId={inTakeForm[0]}
                        accessToken={inTakeForm[1]}
                        dateOfBirth={dateOfBirth}
                        messageEnabled={intakeMessageEnabled}
                        hasPhoneNumber={hasPhoneNumber}
                        onDone={() => setInTakeForm(undefined)}
                        onStarted={() => setGrow(true)}
                        onStopped={() => setGrow(false)}
                      />
                    </Timeout>
                  ) : (
                    <Status
                      Icon={CheckCircleOutlined}
                      color={theme.palette.success.main}
                      message={
                        scope?.hospital?.name
                          ? `Thank you for registering at ${scope.hospital.name}`
                          : 'Thank you for registering!'
                      }
                      details="Please have a seat and our team will be attending to you shortly."
                      onDone={reset}
                    />
                  )
                ) : (
                  <Box textAlign="center" display="flex" flexDirection="column" justifyContent="middle" maxWidth="62ch">
                    <Box
                      flexDirection="column"
                      component="form"
                      onSubmit={handleSubmit}
                      display="flex"
                      justifyContent="center"
                      textAlign="left"
                      mt={2}
                      style={{ gap: '2ch' }}
                    >
                      <FormControl variant="outlined" style={{ flex: 3 }}>
                        <TextField
                          label="Last Name"
                          variant="outlined"
                          value={lastName}
                          onChange={e => setLastName(e.target.value)}
                          autoComplete="off"
                          autoCapitalize="off"
                          autoCorrect="off"
                        />
                      </FormControl>
                      <DateOfBirthInput
                        year={year}
                        month={month}
                        day={day}
                        onYearChange={setYear}
                        onMonthChange={setMonth}
                        onDayChange={setDay}
                      />
                      <Box mt={2} display="flex" flexDirection="column">
                        <Button
                          size="large"
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={!dateOfBirth || busy}
                        >
                          {busy ? 'Confirming…' : 'Confirm'}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Paper>
          </Box>

          <Box py={4} mt={5} justifyContent="space-between" alignItems="center" width="100%" display="flex">
            <Box>
              <img width={120} src={ospitekLogo} alt="Ospitek logo" />
            </Box>
            <Typography style={{ color: '#fff' }}>{scope?.hospital?.name}</Typography>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default WaitingRoomTablet;

export const NameInput = withLabel('Last Name')(TextInput);

interface DateOfBirthInputProps {
  year?: number;
  month?: number;
  day?: number;
  onYearChange: (year: number) => void;
  onMonthChange: (month: number) => void;
  onDayChange: (day: number) => void;
}

const DateOfBirthInput = withLabel('Date of Birth')(
  ({ year, month, day, onYearChange, onMonthChange, onDayChange }: DateOfBirthInputProps) => {
    return (
      <Box display="flex" style={{ gap: '1ch' }}>
        <FormControl variant="outlined" style={{ flex: 3 }}>
          <InputLabel htmlFor="month-select">Month</InputLabel>
          <Select
            native
            onChange={option => onMonthChange(option.target.value as number)}
            label="Month"
            inputProps={{
              name: 'month',
              id: 'month-select',
            }}
          >
            <option aria-label="None" value="" />
            {Array.from({ length: 12 }, (_, i) => i + 1).map(i => (
              <option value={i} key={i}>
                {MONTHS[i]}
              </option>
            ))}
          </Select>{' '}
        </FormControl>{' '}
        <FormControl variant="outlined" style={{ flex: 2 }}>
          <InputLabel htmlFor="day-select">Day</InputLabel>
          <Select
            native
            onChange={option => onDayChange(option.target.value as number)}
            label="Day"
            inputProps={{
              name: 'day',
              id: 'day-select',
            }}
          >
            <option aria-label="None" value="" />
            {Array.from({ length: 31 }, (_, i) => i + 1).map(i => (
              <option value={i} key={i}>
                {i}
              </option>
            ))}
          </Select>{' '}
        </FormControl>{' '}
        <FormControl variant="outlined" style={{ flex: 2 }}>
          <InputLabel htmlFor="year-select">Year</InputLabel>
          <Select
            native
            onChange={option => onYearChange(option.target.value as number)}
            label="Year"
            inputProps={{
              name: 'year',
              id: 'year-select',
            }}
          >
            <option aria-label="None" value="" />
            {Array.from({ length: 120 }, (_, i) => new Date().getFullYear() - i)
              .reverse()
              .map(i => (
                <option value={i} key={i}>
                  {i}
                </option>
              ))}
          </Select>
        </FormControl>
      </Box>
    );
  }
);
