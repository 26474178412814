import React, { FC, useState } from 'react';
import { physicianPickedInitial, setValueAndResetError } from '../utils/values';
import set from 'lodash/fp/set';
import { transformValue } from '../utils/functions';
import { AutocompleteInputSetter } from './AutocompleteInputSetter';
import { FormParams, StaffDetails } from '../types/types';
import { useMutation } from '@apollo/client';
import {
  create as createPhysician,
  list as physicians,
  update as updatePhysician,
} from '../../../../../../graph/physicians';
import { PhoneNumberInput } from './PhoneNumberInput';
import { phoneNumberFormatPipeline } from '../../../../../../se/components/inputs/PhoneInput';
import { EmailInput } from './EmailInput';
import Anesthesiologist from '../../../../../../types/Anesthesiologist';

const PhysicianAutocomplete: FC<FormParams<StaffDetails>> = ({ value, setValue }) => {
  const [physician, setPhysician] = useState<Anesthesiologist | null | undefined>();

  const [create] = useMutation(createPhysician, {
    refetchQueries: [{ query: physicians }],
  });

  const [update] = useMutation(updatePhysician, {
    refetchQueries: [{ query: physicians }],
  });

  const createOption = async (text: string) => {
    const res = await create({
      variables: {
        name: text,
      },
    });
    const created = res?.data?.createPhysician;
    setPhysician(created);
    return created;
  };

  const updateOption = (field: string) => async (text: string) => {
    if (!!physician) {
      const res = await update({
        variables: {
          ...physician,
          [field]: text,
        },
      });

      const updated = res?.data?.editPhysician;
      setPhysician(updated);
      return updated;
    }
    return null;
  };

  return (
    <>
      <AutocompleteInputSetter
        list={physicians}
        label={'Surgeon Name'}
        variant="filled"
        margin="dense"
        fullWidth
        required
        error={!!value?.physician?.name?.error}
        helperText={value?.physician?.name?.error}
        onChange={event => {
          if (event?.constructor?.name === 'SyntheticEvent') {
            setValue(setValueAndResetError('physician.name', event.target.value, value));
          } else {
            setValue(set('physician', transformValue(event || {}, physicianPickedInitial))(value));
          }
        }}
        onBlurCreateOption={createOption}
        value={value?.physician?.name?.value || ''}
      />
      <PhoneNumberInput
        label="Surgeon Phone Number"
        value={value?.physician?.notificationNumbers?.value || ''}
        onChange={event =>
          setValue(set('physician.notificationNumbers.value', phoneNumberFormatPipeline(event.target.value))(value))
        }
        onBlur={() => updateOption('notificationNumbers')(value?.physician?.notificationNumbers?.value || '')}
      />
      <EmailInput
        label="Surgeon Email"
        error={!!value?.physician?.email?.error}
        helperText={value?.physician?.email?.error}
        value={value?.physician?.email?.value || ''}
        onChange={event => setValue(setValueAndResetError('physician.email', event.target.value, value))}
        onBlur={() => updateOption('email')(value?.physician?.email?.value || '')}
      />
    </>
  );
};

export default PhysicianAutocomplete;
