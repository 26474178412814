import React from 'react';
import Box from '@material-ui/core/Box';
import Logo from '../Logo';
import { LinearProgress } from '@material-ui/core';

export default () => (
  <Box width={200} textAlign="center" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
    <Logo />
    <Box width={100} mt={3}>
      <LinearProgress aria-label="loading indicator" />
    </Box>
  </Box>
);
