import React, { ComponentType, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Box, CircularProgress, Theme, Typography, useTheme } from '@material-ui/core';
import { PinInput } from './index';
import { CheckCircleOutlined } from '@material-ui/icons';
import Status from '../../tablet/waitingRoom/Status';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import Image from 'material-ui-image';
import { useScope } from '../../../../../hooks/useScope';
// @ts-ignore
import waitingRoomCover from '../../../../../assets/images/waiting_room_cover.png';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';

import { Role } from '../modules/chartingStore';

export interface PinDialogProps {
  onEnter: (staff: PinVerificationSuccess & { pin: string }) => void;
  CloseButton?: ComponentType;
}

export const PinDialog = ({ onEnter, CloseButton }: PinDialogProps) => {
  const [pin, setPin] = useState<string>('');

  const { verifyPin, pinVerificationInProgress } = usePinVerification();
  const [error, setError] = useState<string | null>(null);
  const [result, setResult] = useState<PinVerificationSuccess | null>(null);

  const handleComplete = async (pin: string) => {
    const result = await verifyPin(pin);

    if (result) {
      setResult(result);
    } else {
      setPin('');
      setError('Invalid pin');
    }
  };

  const theme = useTheme();
  const scope = useScope();

  return (
    <Box display="flex" flex={1} alignItems="center" justifyContent="center" position="relative">
      {CloseButton && (
        <Box position="absolute" top={theme.spacing(5)} left={theme.spacing(5)}>
          <CloseButton />
        </Box>
      )}
      <ThemeProvider
        theme={(theme: Theme) =>
          createTheme({
            ...theme,
            typography: {
              fontSize: 22,
            },
          })
        }
      >
        <Box display="flex" flex={1}>
          <Box flex={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center" px={8}>
            {error ? (
              <Status
                Icon={CancelOutlinedIcon}
                color={theme.palette.error.main}
                message="Invalid pin!"
                details="Please try again."
                duration={1500}
                onDone={() => setError(null)}
              />
            ) : result ? (
              <Status
                Icon={CheckCircleOutlined}
                color={theme.palette.success.main}
                message="Logging in…"
                duration={1000}
                onDone={() => {
                  const data = { ...result, pin };
                  setPin('');
                  setResult(null);
                  onEnter(data);
                }}
              />
            ) : (
              <Box textAlign="center" display="flex" flexDirection="column" justifyContent="middle" maxWidth="62ch">
                <Typography variant="h1" gutterBottom>
                  {scope?.hospital?.name ? `Welcome to ${scope.hospital.name}` : 'Welcome!'}
                </Typography>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h3" gutterBottom>
                    Please enter your PIN.
                    {pinVerificationInProgress && (
                      <CircularProgress
                        size={24}
                        style={{ marginTop: '-0.5em', marginBottom: '-0.25em', marginLeft: '0.5em' }}
                      />
                    )}
                  </Typography>
                </Box>
                <Box mt={3}>
                  <PinInput
                    value={pin}
                    onChange={setPin}
                    onComplete={handleComplete}
                    disabled={pinVerificationInProgress}
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Box m={4} borderRadius={16} flex={1} overflow="hidden">
            <Image
              style={{ height: '100%', width: '100%' }}
              imageStyle={{ objectFit: 'cover' }}
              src={waitingRoomCover}
            />
          </Box>
        </Box>
      </ThemeProvider>
    </Box>
  );
};

export default PinDialog;

interface PinVerification {
  verifyPin: (pin: string) => Promise<PinVerificationSuccess | undefined>;
  pinVerificationInProgress: boolean;
}

interface PinVerificationSuccess {
  id: string;
  name: string;
  role: Role;
}

const usePinVerification = (): PinVerification => {
  const [verifyStaffPin, { loading: staffPinVerificationInProgress }] = useMutation(gql`
    mutation verifyStaffPin($pin: String!) {
      verifyStaffPin(pin: $pin) {
        id
        name
      }
    }
  `);

  const [verifyAnesthesiologistPin, { loading: anesthesiologistPinVerificationInProgress }] = useMutation(gql`
    mutation verifyAnesthesiologistPin($pin: String!) {
      verifyAnesthesiologistPin(pin: $pin) {
        id
        name
      }
    }
  `);

  const [verifyPhysicianPin, { loading: physicianPinVerificationInProgress }] = useMutation(gql`
    mutation verifyPhysicianPin($pin: String!) {
      verifyPhysicianPin(pin: $pin) {
        id
        name
      }
    }
  `);

  const verifyPin = async (pin: string) => {
    const staffPinVerification = await verifyStaffPin({ variables: { pin } });
    const anesthesiologistPinVerification = await verifyAnesthesiologistPin({ variables: { pin } });
    const physicianPinVerification = await verifyPhysicianPin({ variables: { pin } });

    const { data } = await Promise.race([
      staffPinVerification,
      anesthesiologistPinVerification,
      physicianPinVerification,
    ]);

    if (data.verifyStaffPin) {
      return { ...data.verifyStaffPin, role: 'staff' };
    } else if (data.verifyAnesthesiologistPin) {
      return { ...data.verifyAnesthesiologistPin, role: 'anesthesiologist' };
    } else if (data.verifyPhysicianPin) {
      return { ...data.verifyPhysicianPin, role: 'physician' };
    }

    const [staffPinVerificationResult, anesthesiologistPinVerificationResult, physicianPinVerificationResult] =
      await Promise.all([staffPinVerification, anesthesiologistPinVerification, physicianPinVerification]);

    const combinedData = {
      ...staffPinVerificationResult.data,
      ...anesthesiologistPinVerificationResult.data,
      ...physicianPinVerificationResult.data,
    };

    if (combinedData.verifyStaffPin) {
      return { ...combinedData.verifyStaffPin, role: 'staff' };
    } else if (combinedData.verifyAnesthesiologistPin) {
      return { ...combinedData.verifyAnesthesiologistPin, role: 'anesthesiologist' };
    } else if (combinedData.verifyPhysicianPin) {
      return { ...combinedData.verifyPhysicianPin, role: 'physician' };
    }
  };

  const pinVerificationInProgress =
    staffPinVerificationInProgress || anesthesiologistPinVerificationInProgress || physicianPinVerificationInProgress;

  return { verifyPin, pinVerificationInProgress };
};
