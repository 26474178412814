export default function formatTimeRange(from?: string, to?: string): string {
  if (!from && !to) {
    return 'All-day';
  } else if (!to) {
    return `From ${from}`;
  } else if (!from) {
    return `To ${to}`;
  }
  return (from ?? '') + '–' + (to ?? '');
}
