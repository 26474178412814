import { compose, withProps } from 'recompose';
import ObjectInput from '../../../se/components/inputs/ObjectInput';

import RoomTypeSelectInput from './RoomTypeSelectInput';
import TextInput from '../../../se/components/inputs/TextInput';
import { withLabel } from '../../../se/components/Label';

const NameInput = compose(
  withLabel('Name'),
  withProps({ placeholder: 'e.g., Operating Room', required: true })
)(TextInput);

export default withProps({
  schema: {
    name: NameInput,
    typeSwitch: RoomTypeSelectInput,
  },
  flatten: {
    typeSwitch: true,
  },
})(ObjectInput);
