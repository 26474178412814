import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import CustomTooltip from './CustomTooltip';
import { renderLegend } from './ORPerformancePieChart';
import React from 'react';
import { ChartWrapper } from '../PieCharts';
import { useTheme } from '@material-ui/core';

const BarCleaningByOr = ({ data }) => {
  const theme = useTheme();

  return (
    <ChartWrapper title="Cleaning By OR">
      <ResponsiveContainer width="100%" height="100%" aspect={0.85}>
        <BarChart
          width={300}
          height={300}
          barGap={20}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.25)" />
          <XAxis type="category" dataKey="title" stroke="rgba(255,255,255,0.25)" />
          <Tooltip
            content={({ active, payload, label }) => (
              <CustomTooltip active={active} payload={payload} label={label} unit="minute" decimal/>
            )}
            cursor={{ fill: 'rgba(255,255,255,0.15)' }}
            contentStyle={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: 4,
              border: 0,
            }}
          />
          <Bar dataKey="cleaning" stackId="a" fill="#6A78B6" />
          <Legend content={renderLegend} align="left" verticalAlign="bottom" iconType="circle" iconSize={8} />
        </BarChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};

export default BarCleaningByOr;
