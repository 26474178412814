import React, { useState } from 'react';
import { TableCreationInfo } from '../../types/Answer';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { format as formatDate } from 'date-fns';

interface AnesthesiaTableCreationInfoButtonProps {
  tableCreationInfos: TableCreationInfo[];
}

const AnesthesiaTableCreationInfoButton = ({ tableCreationInfos }: AnesthesiaTableCreationInfoButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Button variant="contained" size="small" onClick={() => setOpen(true)} style={{ minHeight: '5em' }}>
        Medication Events
      </Button>
      <Dialog fullWidth={true} open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle>Medication Events</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Medication</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>Author</TableCell>
                  <TableCell>Timestamp</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableCreationInfos
                  .sort((a, b) => a.timestamp - b.timestamp)
                  .map((tableCreationInfo, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {tableCreationInfo.item}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {tableCreationInfo.time}
                      </TableCell>
                      <TableCell>{tableCreationInfo.value}</TableCell>
                      <TableCell>{tableCreationInfo.author}</TableCell>
                      <TableCell>{formatDate(tableCreationInfo.timestamp, 'MM/DD/YYYY HH:mm:ss')}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions style={{ position: 'sticky', bottom: 0 }}>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AnesthesiaTableCreationInfoButton;
