import Button from '@material-ui/core/Button';
import React from 'react';

/**
 * HTML `<a>` disguised as a Button.
 */
export default ({ children, ...rest }) => (
  <Button variant="contained" component="a" {...rest}>
    {children}
  </Button>
);
