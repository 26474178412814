import toString from './toString';
import { capitalize } from 'lodash';

const onlylabel = toString.with({
  YesNo: (question, positive, child) => {
    if (child) {
      return (
        '+' +
        capitalize(
          question
            .replace(/\?/g, '')
            .replace(/^Do you have\s/g, 'Has ')
            .replace(/^Are you a\s/g, '')
            .replace(/^Are you\s/g, '')
            .replace(/^Any chance you may be\s/g, '')
            .replace(/^Did you\s/g, 'Has ')
            .replace(/^Any\s/g, 'Has ')
            .replace(/^Have you ever\s/g, '')
            .replace(/^Have you\s/g, 'Has ')
            .replace(/^Is it a\s/g, '')
            .replace(/^Do you\s/g, '')
        )
      );
    } else if (positive) {
      return (
        '+' +
        capitalize(
          question
            .replace(/\?/g, '')
            .replace(/^Do you have\s/g, 'Has ')
            .replace(/^Are you a\s/g, '')
            .replace(/^Are you\s/g, '')
            .replace(/^Any chance you may be\s/g, '')
            .replace(/^Did you\s/g, 'Has ')
            .replace(/^Any\s/g, 'Has ')
            .replace(/^Have you ever\s/g, '')
            .replace(/^Have you\s/g, 'Has ')
            .replace(/^Is it a\s/g, '')
            .replace(/^Do you\s/g, '')
        )
      );
    } else {
      return '';
    }
  },
  OneOf: (label, options, answer) => {
    if (answer) {
      return `${answer.label}${answer.child ? ` (${answer.child})` : ''}`;
    } else {
      return null;
    }
  },
});

export default onlylabel;
