import { alpha } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';
import { withTheme } from '../../../theme';

const emphasize = keyframes`
  0% {
    border-color: rgba(0, 237, 250, 0);
    box-shadow: 0 0 4px rgba(0, 237, 250, 0);
  }
  100% {
    border-color: rgba(0, 237, 250, 1);
    box-shadow: 0 0 8px rgba(0, 237, 250, 1);
  }
`;

const setReady = keyframes`
  0% {
    border-color: rgba(0, 182, 62, 0);
    box-shadow: 0 0 4px rgba(0, 182, 62, 0);
  }
  100% {
    border-color: rgba(0, 182, 62, 1);
    box-shadow: 0 0 4px rgba(0, 182, 62, 1);
  }
`;

const PatientSlotContainer = styled.div`
  display: flex;
  background-color: ${withTheme(theme => alpha(theme.secondaryTextColor.string(), 0.05))};
  border-radius: 4px;
  border: 2px solid transparent;
  padding: ${props => (props.size === 'large' ? '1.5vh 1.5em' : '1.25vh 1em')};
  animation: 0.5s ${props => (props.border && props.size !== 'large' ? setReady : emphasize)} ease-in-out alternate
    ${props => (props.border ? '9' : '8')};
  ${props => (props.preventAnimation ? 'animation: none;' : '')}
  animation-delay: 250ms;

  @media (max-height: 900px) {
    padding-top: ${props => (props.size === 'large' ? '1.125rem' : '1vh')};
    padding-bottom: ${props => (props.size === 'large' ? '1.125rem' : '1vh')};
  }

  ${props => props.border && `border-color: ${props.border};`}
`;

export default PatientSlotContainer;
