import { useEffect } from 'react';
import { useStore } from 'zustand';
import { chartingStore } from './chartingStore';

export const useChartingSession = () => useStore(chartingStore, store => store.session);
export const useChartingPinTimeout = () => useStore(chartingStore, store => store.pinTimeout);
export const useChartingSessionKeepAlive = () => useStore(chartingStore, store => store.keepAlive);

export const useKeepAliveChartingSession = (keep: boolean) => {
  useEffect(() => {
    if (keep) {
      chartingStore.setState(({ keepAlive }) => ({ keepAlive: keepAlive + 1 }));

      return () => chartingStore.setState(({ keepAlive }) => ({ keepAlive: keepAlive - 1 }));
    }
  }, [keep]);
};
