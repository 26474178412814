import React from 'react';
import { createStyles, fade, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import purple from '@material-ui/core/colors/purple';

const tooltipTitle = 'This is an open slot that anyone can use if they are available at the time.';

const EmptyInteractable = ({ onClick, communal }) => {
  const classes = useStyles();

  return (
    <Tooltip disableHoverListener={!communal} title={tooltipTitle}>
      <Box
        flex={1}
        component="button"
        onClick={onClick}
        className={clsx(classes.root, { [classes.communal]: communal })}
      />
    </Tooltip>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      background: fade(theme.palette.primary.main, 0.2),
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${fade(theme.palette.text.primary, 0.1)}`,
      transition: 'background .05s ease-in-out',
      cursor: 'pointer',
      outline: 'none',

      '&:hover': {
        background: fade(theme.palette.primary.main, 0.5),
      },
    },
    communal: {
      background: fade(purple[500], 0.2),

      '&:hover': {
        background: fade(purple[500], 0.5),
      },
    },
  })
);

export default EmptyInteractable;
