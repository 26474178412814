import React, { FC, useState } from 'react';
import { CustomModal } from '../tablet/v2/OperationRoomTabletV2';
import CancellationSelectInput from '../schedule/edit/CancellationSelectInput';
import Box from '@material-ui/core/Box';
import { cancelVisit } from '../../../../graph/patients';
import { useMutation } from '@apollo/client';

const DispatchCancelVisit = CustomModal(
  `Patient’s visit will be completed and marked as canceled. This action cannot be undone.`
);

const PatientVisitCancellationModal: FC<{ patientId: number; onDone: () => void }> = ({ patientId, onDone }) => {
  const [value, setValue] = useState(null);
  const [cancelProcedureMutation] = useMutation(cancelVisit);

  const handleConfirm = async () => {
    try {
      await cancelProcedureMutation({ variables: { id: patientId, reason: value } });
      onDone();
    } catch (e: any) {}
  };

  return (
    <DispatchCancelVisit handleConfirm={handleConfirm} handleCancel={onDone}>
      <Box width={'35%'} mt={2} mb={2}>
        <CancellationSelectInput value={value} onChange={setValue} />
      </Box>
    </DispatchCancelVisit>
  );
};

export default PatientVisitCancellationModal;
