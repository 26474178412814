import React, { FC } from 'react';
import { Box, Checkbox } from '@material-ui/core';
import { useMultipleSelectionContext } from './MultipleStaffShiftSelection';

interface MultipleStaffShiftSelectionCheckboxProps {
  roomId?: number;
  procedureId?: number;
}

const MultipleSelectionCheckbox: FC<MultipleStaffShiftSelectionCheckboxProps> = ({ roomId, procedureId }) => {
  const { roomIds, procedureIds, addProcedure, addRoom, removeProcedure, removeRoom } =
    useMultipleSelectionContext();

  const checked = procedureId ? !!procedureIds.find(e => e === procedureId) : !!roomIds.find(e => e === roomId);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.target.checked
      ? procedureId !== undefined
        ? procedureId && addProcedure(procedureId)
        : roomId && addRoom(roomId)
      : procedureId !== undefined
      ? procedureId && removeProcedure(procedureId)
      : roomId && removeRoom(roomId);
  };

  return (
    <Box onClick={e => e.stopPropagation()}>
      <Checkbox checked={checked} onChange={handleChange} />
    </Box>
  );
};

export default MultipleSelectionCheckbox;
