import { useLayoutEffect } from 'react';
import merge from 'lodash/merge';

interface PreFillIfNeededProps<T> {
  preFill: T;
  value: T;
  apply: (value: T) => void;
}

const PreFillIfNeeded = <T,>({ preFill, value, apply }: PreFillIfNeededProps<T>) => {
  useLayoutEffect(() => {
    if (
      typeof preFill === 'object' &&
      typeof value === 'object' &&
      Object.keys(preFill!).some(key => !value!.hasOwnProperty(key))
    ) {
      apply(merge({}, preFill, value));
    }
  }, [preFill, value, apply]);

  return null;
};

export default PreFillIfNeeded;
