import { Box } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface CardHeaderProps {
  startTime?: string;
  endTime?: string;
  physicianName: string;
}

const CardHeader = ({ startTime, endTime, physicianName }: CardHeaderProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      {startTime}–{endTime}
      <span style={{ fontWeight: 'bold' }}>{physicianName}</span>
    </Box>
  );
};

export const useStyles = makeStyles(theme => ({
  header: {
    fontSize: '0.75em',
    paddingRight: '0.25em',
    paddingLeft: '0.25em',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default CardHeader;
