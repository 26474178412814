import React, { useMemo } from 'react';
import { compose, withProps } from 'recompose';
import SelectInput from '../../se/components/inputs/SelectInput';
import { withLabel } from '../../se/components/Label';
import { isArray } from '../../se/utilities/check';
import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';

const Label = styled.div`
  margin-bottom: 0.75rem;
  font-size: 1rem;
`;

export const GraphqlSelectInput = ({
  label = null,
  graphqlList,
  variables,
  entityName,
  placeholder,
  getOptionValue,
  required,
  sortOptions,
  additionalOptions = {},
  transformOptions,
  hideComponentIf = null,
  ...rest
}) => {
  const { data, loading, error } = useQuery(graphqlList, { variables: variables });
  const entityNameSingle = entityName.charAt(0).toLowerCase() + entityName.substr(1);
  const entityNamePlural = `${entityNameSingle}s`;
  const entityData = get(data, entityNamePlural);

  const items = useMemo(() => {
    if (isArray(entityData)) {
      const transformed = transformOptions ? transformOptions(entityData) : entityData;
      return transformed.reduce((acc, { id, name }) => ({ ...acc, [id]: name ? name : id }), {});
    } else {
      return [];
    }
  }, [entityData, transformOptions]);

  return (
    !(hideComponentIf && hideComponentIf(entityData)) && (
      <Box>
        {label && <Label>{label}</Label>}
        <SelectInput
          options={{ ...additionalOptions, ...items }}
          placeholder={placeholder}
          getOptionValue={getOptionValue}
          required={required}
          sortOptions={sortOptions}
          isLoading={loading}
          error={error}
          {...rest}
        />
      </Box>
    )
  );
};

export default ({ label, ...props }) =>
  compose(label === undefined ? _ => _ : withLabel(label), withProps(props))(GraphqlSelectInput);
