import React from 'react';
import EmptyInteractable from './EmptyInteractable';

import { withScope } from '../../../../contexts/ScopeContext';

const Slot = withScope(({ onClick, ...props }) => {
  const communal = !props.slot.forOrganizationId;

  return <EmptyInteractable onClick={() => onClick(props)} communal={communal} />;
});

export default Slot;
