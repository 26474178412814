import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Patient } from '../../../../types/Patient';
import { QuestionnaireAndAnswers, QuestionnaireType } from '../../../../types/Questionnaire';
import { sortBy } from 'lodash';
import clsx from 'clsx';
import FormButtonDialog from './components/FormButtonDialog';
import { PacuChartsContext } from './PacuChartsContext';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import { AssignmentRounded } from '@material-ui/icons';
import FormDialog from './components/FormDialog';
import ChartingForm from './ChartingForm';
import { getFormType } from './ChartingForms';
import { getChartingData } from './utils';
import { ChartIndicators } from './ChartInfo';
import { chartingStore } from './modules/chartingStore';
import { PreOpChartsContext } from './PreOpChartsContext';

interface SetChartingPinTimeoutProps {
  value: number;
}

export const SetChartingPinTimeout = ({ value }: SetChartingPinTimeoutProps) => {
  useEffect(() => {
    chartingStore.setState({ pinTimeout: value });
  }, [value]);

  return null;
};

export const ChartingSections = ({
                                   indicators,
                                   patient,
                                   patientId,
                                   questionnaireType,
                                   hospitalId,
                                 }: {
  indicators: any;
  patient: Patient | undefined;
  patientId: number;
  questionnaireType: QuestionnaireType;
  hospitalId: number;
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [form, setForm] = useState<QuestionnaireAndAnswers | null>(null);

  const charts: QuestionnaireAndAnswers[] | undefined = sortBy(
    getChartingData(questionnaireType, patient),
    'questionnaire.order',
  );

  const [defaultSlide, setDefaultSlide] = useState(0);

  const handleClickOpen = (form: QuestionnaireAndAnswers) => {
    setOpen(true);
    setForm(form);
  };

  const openChartingWithSlide = (chartingId: number, slide: number) => {
    const chart = (charts || []).find(e => e?.questionnaire?.id === chartingId);
    if (chart) {
      handleClickOpen(chart);
      setDefaultSlide(slide);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setForm(null);
    setDefaultSlide(0);
  };

  const [view, setView] = useState('sections');

  return (
    <PacuChartsContext.Provider value={patient?.procedure?.pacuCharts ?? []}>
      <PreOpChartsContext.Provider value={patient?.procedure?.preOpCharts ?? []}>
        <Box display="flex" alignItems="stretch" style={{ gap: 48 }} flex={1}>
          <Box>
            <ToggleButtonGroup orientation="vertical" value={view} exclusive>
              <ToggleButton value="module" aria-label="module" onClick={() => setView('sections')}>
                <ViewModuleIcon/>
              </ToggleButton>
              <ToggleButton value="quilt" aria-label="quilt" onClick={() => setView('form')}>
                <AssignmentRounded/>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {(charts || []).map(
            (f: QuestionnaireAndAnswers, i: number) =>
              !!patient && (
                // f.questionnaire.content
                <FormDialog
                  defaultSlide={defaultSlide}
                  key={f.id}
                  questionnaireType={questionnaireType}
                  patient={patient}
                  handleClickOpen={handleClickOpen}
                  handleClose={handleClose}
                  form={f}
                  index={i}
                  open={open && f.id === form?.id}
                />
              ),
          )}

          {view === 'sections' ? (
            <Box display="flex" flex={1} flexDirection="column">
              {(questionnaireType === QuestionnaireType.PreOpChart ||
                  questionnaireType === QuestionnaireType.AnesthesiologistPreOpChart) &&
                !!patient && <ChartIndicators combined={indicators}/>}
              <Box className={clsx(classes.grid, { [classes.increaseColumns]: charts.length >= 7 })}>
                {(charts || []).map((f: QuestionnaireAndAnswers, i: number) => (
                  <Box key={f.id} display="flex">
                    {!!patient && <FormButtonDialog handleClickOpen={handleClickOpen} form={f}/>}
                  </Box>
                ))}
              </Box>
            </Box>
          ) : !!patient ? (
            <ChartingForm
              hospitalId={hospitalId}
              patient={patient}
              formType={getFormType(questionnaireType)}
              openChartingWithSlide={openChartingWithSlide}
            />
          ) : null}
        </Box>
      </PreOpChartsContext.Provider>
    </PacuChartsContext.Provider>
  );
};

export const useStyles = makeStyles(theme => ({
  grid: {
    flex: 1,
    padding: theme.spacing(4),
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridAutoRows: '1fr',
    gap: theme.spacing(2),
    alignItems: 'stretch',
    justifyContent: 'center',
  },
  increaseColumns: {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
}));
