import { Query } from '@apollo/client/react/components';
import React from 'react';
import { feedbackStats } from '../../../graph/feedback';
import { feedbackStats as caretakerFeedbackStats } from '../../../graph/hospital/caretakerFeedback';
import pick from 'lodash/fp/pick';
import { Analytics } from '../analytics/Analytics';

const pickFilter = pick([
  'name',
  'physician',
  'procedureType',
  'hospital',
  'status',
  'dateRange',
  'category',
  'speciality',
]);

export const PatientFeedbackStats = ({ filter: { dateRange, ...rest } }) => (
  <Query
    query={feedbackStats}
    variables={{ filter: { dateRange: dateRange.toJSON(), ...pickFilter(rest) } }}
    fetchPolicy={'network-only'}
  >
    {({ data }) => (
      <Analytics data={data} pathFeedbackStats={'feedbackStats'} pathRatingStats={'hospitalRatingStats'} />
    )}
  </Query>
);

export const CaretakerFeedbackStats = ({ filter: { dateRange, ...rest } }) => (
  <Query
    query={caretakerFeedbackStats}
    variables={{ filter: { dateRange: dateRange.toJSON(), ...rest } }}
    fetchPolicy={'network-only'}
  >
    {({ data }) => (
      <Analytics
        data={data}
        pathFeedbackStats={'caretakerFeedbackStats'}
        pathRatingStats={'caretakerHospitalRatingStats'}
      />
    )}
  </Query>
);
