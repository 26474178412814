import Dates from '../types/Dates';
import { DateString } from './groupStaffAvailability';

const parseIntoDate = (date: DateString) => {
  const year = date.slice(0, 4);
  const month = date.slice(4, 6);
  const day = date.slice(6);
  return `${month}/${day}/${year}`;
};

const parseIntoDateWithOutYear = (date: DateString) => {
  const month = date.slice(4, 6);
  const day = date.slice(6);
  return `${month}/${day}`;
};

const splitIntoGroups = (array: number[]): number[][] => {
  let groups: number[][] = [[]]; // Check this
  let lastIndex = 0;
  let currentEntry = 0;
  array.forEach((number, index) => {
    if (index < array.length) {
      if (number !== array[index + 1] - 1) {
        groups[currentEntry] = array.slice(lastIndex, index + 1);
        lastIndex = index + 1;
        currentEntry = currentEntry + 1;
      }
    }
  });

  return groups;
};

const mergeGroups = (groups: number[][]) => {
  let numberOfSingleGroups = 0;
  groups.forEach(group => {
    if (group.length === 1) {
      numberOfSingleGroups++;
    }
  });
  let newGroup: number[][] = [[]];
  if (numberOfSingleGroups >= 2) {
    groups.forEach(group => {
      if (group.length === 1) newGroup[0].push(group[0]);
      else newGroup.push(group);
    });
    return newGroup;
  } else return groups;
};

const isSequential = (dateGroup: number[]) =>
  dateGroup.every((num, i) => i === dateGroup.length - 1 || num === dateGroup[i + 1] - 1);

export default function groupTitle(dates: Dates): string[] {
  const dateGroups: number[][] = splitIntoGroups(
    dates
      .values()
      .map(date => Number(date))
      .sort((a, b) => a - b)
  );

  const mergedGroups = mergeGroups(dateGroups);

  return mergedGroups.map(group =>
    !(isSequential(group) && group.length !== 1)
      ? group.length === 1
        ? parseIntoDate(String(group[0]))
        : `${group.length} dates selected`
      : `${parseIntoDateWithOutYear(String(group[0]))} – ${parseIntoDate(String(group[group.length - 1]))}`
  );
}
