import React, { FC } from 'react';
import Thread from './Thread';
import Composer from './Composer';
import { gql, useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';

interface ChatProps {
  threadId?: string | null;
  createThread: () => Promise<string>;
  messageContent: string;
  resetSelectedTab: () => void;
}

const Chat: FC<ChatProps> = ({ threadId, createThread, messageContent, resetSelectedTab }) => {
  const [sendMessage] = useMutation(gql`
    mutation sendMessage($threadId: String!, $content: String!) {
      sendMessage(threadId: $threadId, content: $content)
    }
  `);

  const handleSend = async (content: string) => {
    sendMessage({
      variables: {
        threadId: threadId || (await createThread()),
        content,
      },
    }).then(() => {
      resetSelectedTab(); // Reset the selected tab after sending a message
    });
  };

  let maxHeight = '1000px';

  if (/iPhone|iPad|iPod/.test(window.navigator.userAgent)) {
    maxHeight = '600px';
  } else if (/Android|Samsung|Tablet/.test(window.navigator.userAgent)) {
    maxHeight = '550px';
  }

  return (
    <>
      {threadId ? <Thread threadId={threadId} /> : <Box style={{ flex: '1 1 100px', width: 500, minHeight: 0 }}></Box>}
      <Composer initialMessage={messageContent} onSend={handleSend} />
    </>
  );
};

export default Chat;
