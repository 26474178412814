import React from 'react';
import { FormattedMessage } from 'react-intl';

function TranslatedMessage({ message }) {
  if (typeof message === 'string') {
    return <FormattedMessage id={message} defaultMessage={message} />;
  } else {
    return <FormattedMessage {...message} />;
  }
}

export default TranslatedMessage;
