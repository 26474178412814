import { compose, withProps } from 'recompose';
import ObjectInput from './ObjectInput';
import PasswordInput from './PasswordInput';
import { withLabel } from '../Label';
import { withDescription } from '../Description';

export default withProps({
  schema: {
    oldPassword: withLabel('Old Password')(PasswordInput),
    newPassword: compose(
      withLabel('New Password'),
      withDescription(
        'Password must be at least 8 characters long and contain numbers, lowercase and uppercase letters'
      )
    )(PasswordInput),
  },
})(ObjectInput);
