import React, { FC } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router';
import { matchPath } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import { Box, Theme, withStyles } from '@material-ui/core';
import { QuestionnaireType } from '../../types/Questionnaire';

export interface Tab extends RouteProps {
  chartType?: QuestionnaireType;
  title: string;
  group?: string;
  path?: string;
}

interface TabRoutesProps extends RouteComponentProps<{ id?: string }> {
  tabs: Tab[];
  tabsStyles?: React.CSSProperties;
}

export const Pills = withStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(0, -1),
  },
}))(Tabs);

export const Pill = withStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(0, 1),
    borderRadius: theme.spacing(1),
  },
}))(MuiTab);

const TabRoutes: FC<TabRoutesProps> = ({ history, location, match, tabs, tabsStyles }) => {
  const handleChange = (event: any, newValue: any) => {
    history.replace(`${match.url}${tabs[newValue]?.path ?? tabs[0].path}${location.search}`);
  };

  const value = tabs.findIndex(tab => matchPath(location.pathname, { ...tab, path: `${match.url}${tab.path}` }));
  const isExact = location?.pathname === `${match.url}${tabs[value]?.path}`;

  return (
    <>
      {isExact && (
        <Box style={tabsStyles}>
          <Tabs
            indicatorColor={'primary'}
            value={value < 0 ? 0 : value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="simple tabs example"
          >
            {tabs.map(tab => (
              <MuiTab key={tab.path} label={tab.title} />
            ))}
          </Tabs>
        </Box>
      )}
      <Switch>
        {tabs.map(tab => (
          <Route key={tab.path} {...tab} path={`${match.url}${tab.path}`} />
        ))}
        <Redirect to={`${match.url}${tabs[0].path}`} />
      </Switch>
    </>
  );
};

export default TabRoutes;
