import React from 'react';
import { compose } from 'recompose';
import identity from 'lodash/identity';
import RouterLink from '../../../../se/components/RouterLink';
import { getNestedValue } from '../../../../se/utilities/data/object';
import { withSession } from '../../../../state/Session';
import HipaaBadge from '../../../HipaaBadge';
import { withCrispCtx } from '../../../../vendor/CrispProvider';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@mdi/react';
import { mdiLockReset, mdiLogout } from '@mdi/js';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  listItem: {
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  hipaaBadgeCollapsed: {
    opacity: 'none',
  },
});

const Footer = ({ session, destroy, crisp, sidebarOpen }) => {
  const classes = useStyles();
  const destroySession = () => {
    if (crisp) {
      crisp.clearSession();
    }
    destroy();
  };

  return (
    <Box>
      <HipaaBadge />
      <List
        component="nav"
        aria-label="footer nav"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader" style={sidebarOpen ? {} : { opacity: 0 }}>
            Hi, {(getNestedValue('user.name', session) || String()).split(' ')[0]}
          </ListSubheader>
        }
      >
        <ListItem className={classes.listItem} component={RouterLink} to="/change-password">
          <ListItemIcon>
            <Icon path={mdiLockReset} size={1} />
          </ListItemIcon>
          <ListItemText primary="Change password" />
        </ListItem>

        <ListItem button onClick={destroySession}>
          <ListItemIcon>
            <Icon path={mdiLogout} size={1} />
          </ListItemIcon>
          <ListItemText primary="Log out" />
        </ListItem>
      </List>
    </Box>
  );
};

export default compose(withSession(identity), withCrispCtx)(Footer);
