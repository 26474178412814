import React from 'react';
import get from 'lodash/get';
import { withScope } from '../contexts/ScopeContext';

const ModuleOptionalFactory =
  module =>
  (Component, OrComponent = null) =>
    withScope(props =>
      get(props, `scope.hospital.modules.${module}`, false) ? (
        Component ? (
          <Component {...props} />
        ) : null
      ) : OrComponent ? (
        <OrComponent {...props} />
      ) : null
    );

export const TemperatureModuleOptional = ModuleOptionalFactory('temperatureReporting');
export const RegistrationModuleOptional = ModuleOptionalFactory('patientRegistration');
export const ScheduleModuleOptional = ModuleOptionalFactory('blockSchedule');
export const AirFilterTimeModuleOptional = ModuleOptionalFactory('airFiltrationTimer');
export const AutomaticPatientTracking = ModuleOptionalFactory('automaticPatientTracking');
export const LightMode = ModuleOptionalFactory('lightMode');
