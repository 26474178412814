import styled from 'styled-components';
import { Modal, Subtitle, Title } from '../../pages/kiosk/tablet/Modal';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  cancel: {
    position: 'absolute',
    top: '2rem',
    right: '2rem',
  },
}));

export const Content = styled.div`
  padding: 0 2em 2em;
  display: flex;
  flex-direction: column;
`;

const Slots = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  flex: 1;
`;

const Slot = styled.button`
  border: none;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e6ecef;
  border: 2px solid #328af2;
  border-radius: 0.25rem;
  padding: 2em;
  cursor: pointer;

  font-size: 1.6em;

  &:hover {
    background: #00a7f7;
  }

  &:active {
    background: #328af2;
  }

  &:disabled {
    opacity: 0.25;
    background: transparent;
    border-color: transparent;
    cursor: initial;
  }

  transition: all 0.1s ease;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  display: block;
  flex-direction: column;
`;

const Header = styled.div`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 1em;
`;

export const RoomPicker = ({ onChoose, onCancel, working, rooms = [] }) => {
  const classes = useStyles();

  return (
    <Modal
      style={{ overflow: 'hidden', padding: 0 }}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Wrapper>
        <Header>
          <IconButton onClick={onCancel} className={classes.cancel}>
            <Icon fontSize="large">cancel</Icon>
          </IconButton>
          <Title>Choose Room</Title>
          <Subtitle prominent>
            Patient will be moved to this Room immediately or{' '}
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          </Subtitle>
        </Header>
        <Content>
          <Slots>
            {rooms.map(room => (
              <Slot key={room.id} disabled={working || room.patient} onClick={() => onChoose(room.id)}>
                {room.name}
              </Slot>
            ))}
          </Slots>
        </Content>
      </Wrapper>
    </Modal>
  );
};
