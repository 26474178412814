import { useMutation } from '@apollo/client';
import { editProcedure, reactivateProcedure } from '../../../../../graph/procedures';
import get from 'lodash/fp/get';
import { format } from 'date-fns';
import map from 'lodash/fp/map';
import React, { useState } from 'react';
import SubmitAndDangerLinkButtonForm from '../../../../../se/components/forms/SubmitAndDangerLinkButtonForm';
import Modal from '../../../../../se/components/Modal';
import { compose, mapProps, withProps } from 'recompose';
import { withLabel } from '../../../../../se/components/Label';
import { list as listRooms } from '../../../../../graph/rooms';
import flow from 'lodash/fp/flow';
import concat from 'lodash/fp/concat';
import filter from 'lodash/fp/filter';
import uniq from 'lodash/fp/uniq';
import reduce from 'lodash/fp/reduce';
import SelectNumberInput from '../../../../../se/components/inputs/SelectNumberInput';
import SelectInput from '../../../../../se/components/inputs/SelectInput';
import TimeInputControl from '../../../../../se/components/controls/TimeControl';
import NumberInput from '../../../../../se/components/inputs/NumberInput';
import PhysicianSelectInput from '../../../../inputs/physician/PhysicianSelectInput';
import AnesthesiologistSelectInput from '../../../../inputs/anesthesiologist/AnesthesiologistSelectInput';
import TextInput from '../../../../../se/components/inputs/TextInput';
import TextAreaInput from '../../../../../se/components/inputs/TextAreaInput';
import ObjectInput from '../../../../../se/components/inputs/ObjectInput';
import AnesthesiologistNoteInput from '../../../../../se/components/inputs/AnesthesiologistNoteInput';
import styled from 'styled-components';
import { withQuery } from '@apollo/client/react/hoc';
import { MobilePhoneNumberInput } from '../../../../entities/frontProcedures/columns';
import { anesthesiaTypes } from '../anesthesiologist/AnesthesiaType';
import { hasAssigned } from '../../../../../graph/staff';
import ProcedureCancellationModal from './ProcedureCancellationModal';
import { unparsedAllergiesSummary } from '../../../../../allergies/AllergiesView';

export const FieldRow = styled.div`
  display: flex;
  margin: 0 -0.5rem;

  > div {
    overflow: hidden;
    flex: 1 0 0;
    margin: 0 0.5rem;
    box-sizing: border-box;
  }
`;

const ProcedureInput = withProps({
  schema: {
    operationRoom: compose(
      withLabel('Operation Room'),
      withProps({
        name: 'operationRoom',
        required: true,
        isClearable: false,
      }),
      withQuery(listRooms, { options: { fetchPolicy: 'cache-and-network' } }),
      mapProps(({ operationRooms, ...props }: any) => ({
        ...props,
        options: flow(
          concat(
            flow(
              get('data.rooms'),
              filter((room: any) => room.type === 'OR'),
              map(get('name'))
            )(props)
          ),
          uniq,
          reduce((acc, operationRoom) => ({ ...acc, [operationRoom]: operationRoom }), {})
        )(operationRooms),
      }))
    )(SelectInput),
    startTime: withProps({ required: true })(withLabel('Start Time')(TimeInputControl)),
    duration: withProps({ min: 5, max: 1440, required: true })(withLabel('Duration')(NumberInput)),
    physicianId: withProps({ required: true })(PhysicianSelectInput),
    anesthesiologistId: AnesthesiologistSelectInput,
    anesthesiaType: compose(
      withLabel('Anes. Type'),
      withProps({
        options: anesthesiaTypes.reduce((r, e) => ({ ...r, [e]: e }), {}),
        placeholder: 'e.g., G',
      })
    )(SelectInput),
    patientName: compose(withLabel('Patient Name'), withProps({ placeholder: 'e.g., Mark Nordstrom' }))(TextInput),
    patientAge: compose(
      withLabel('Age'),
      withProps({ min: 0 }),
      mapProps(({ onChange, ...props }: any) => ({
        onChange: value => {
          if ((value || '').match(/^\d*\s?(m|mo)?$/)) {
            onChange(value);
          }
        },
        onBlur: e => {
          if (!(e.target.value || '').match(/^\d+\s?(m|mo)?$/)) {
            onChange(null);
          }
        },
        ...props,
      }))
    )(TextInput),
    patientSex: compose(withLabel('Sex'), withProps({ maxLength: 1 }))(TextInput),
    patientCellPhone: MobilePhoneNumberInput,
    procedureType: compose(
      withLabel('Procedure Type'),
      withProps({ placeholder: 'e.g., Neuroimaging' })
    )(TextAreaInput),
    notes: withProps({ rows: 4 })(withLabel('Notes')(TextAreaInput)),
    anesthesiologistNotes: compose(
      withProps({ options: ['Drugs in room', 'MH', 'Latex', 'Glidescope', 'ICD/Magnet'] }),
      withLabel('Anesthesiologist Notes')
    )(AnesthesiologistNoteInput),
    allergy: compose(
      withLabel('Allergies'),
      mapProps(props => ({ ...props!, isDisabled: true, value: unparsedAllergiesSummary(props.value) }))
    )(TextAreaInput),
    procedurePriority: compose(
      withLabel('Procedure Priority'),
      withProps({
        options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        placeholder: 'Select Procedure Priority',
      })
    )(SelectNumberInput),
  },
  render: props => {
    const {
      operationRoom,
      startTime,
      duration,
      physicianId,
      anesthesiologistId,
      anesthesiaType,
      patientName,
      patientAge,
      patientSex,
      patientCellPhone,
      procedureType,
      notes,
      allergies,
      procedurePriority,
      anesthesiologistNotes,
    } = props;

    return (
      <div>
        <FieldRow>
          <div style={{ flex: '0 1 calc(45% - 1rem)' }}>{operationRoom}</div>
        </FieldRow>
        <FieldRow>
          <div style={{ flex: '0 1 calc(30% - 1rem)' }}>{startTime}</div>
          <div style={{ flex: '0 1 calc(30% - 1rem)' }}>{duration}</div>
        </FieldRow>
        <FieldRow>
          <div style={{ flex: '0 1 calc(60% - 1rem)' }}>{physicianId}</div>
        </FieldRow>
        <FieldRow>
          <div>{anesthesiologistId}</div>
          <div>{anesthesiaType}</div>
        </FieldRow>
        <FieldRow>
          <div style={{ flex: '0 1 calc(60% - 1rem)' }}>{patientName}</div>
          <div>{patientAge}</div>
          <div>{patientSex}</div>
        </FieldRow>
        <div>{patientCellPhone}</div>
        <div>{procedureType}</div>
        <div>{notes}</div>
        <div>{anesthesiologistNotes}</div>
        <div>{allergies}</div>
        <div>{procedurePriority}</div>
      </div>
    );
  },
})(ObjectInput);

const ProcedureEditor = ({ date, operationRooms, procedure, onDone, onClose, fullWindow }) => {
  const [doUpdateProcedure] = useMutation(editProcedure);

  const [reactivateProcedureMutation] = useMutation(reactivateProcedure);

  const [cancelModal, setCancelModal] = useState<boolean>(false);

  const toggleCancelModal = () => {
    setCancelModal(v => !v);
  };

  const onCancelProcedure = () => {
    toggleCancelModal();
    onDone();
  };

  const handleReactivateProcedure = async () => {
    if (window.confirm('Are you sure you want to reactivate this procedure?')) {
      await reactivateProcedureMutation({ variables: { id: procedure?.id } });
      onDone();
    }
  };

  const handleSubmit = async ({ ...data }) => {
    try {
      await doUpdateProcedure({
        variables: {
          id: get('id')(procedure),
          date: format(date, 'YYYY-MM-DD'),
          ...data,
        },
        refetchQueries: [{ query: hasAssigned, variables: { date: format(date, 'YYYY-MM-DD') } }],
      });
    } catch (e: any) {
      const validationErrorMessage = (e?.message.match(/^GraphQL error: Validation error: (.*)$/) || [])[1];

      if (validationErrorMessage) {
        throw new Error(validationErrorMessage);
      } else {
        throw e;
      }
    }
    onDone();
  };

  const initialValue = {
    operationRoom: get('or')(procedure),
    startTime: get('startTimeText')(procedure),
    duration: get('duration')(procedure),
    physicianId: get('physician.id')(procedure),
    anesthesiologistId: get('anesthesiologist.id')(procedure),
    anesthesiaType: get('anesthesiaType')(procedure) || '',
    patientName: get('patient.name')(procedure) || get('patientName')(procedure) || '',
    patientAge: get('patientAge')(procedure),
    patientSex: get('patientSex')(procedure) || '',
    patientCellPhone: get('patientCellPhone')(procedure) || undefined,
    procedureType: get('procedureType')(procedure) || '',
    notes: get('notes')(procedure),
    allergies: get('allergies')(procedure),
    procedurePriority: get('procedurePriority')(procedure),
    color: get('color')(procedure),
    anesthesiologistNotes: get('anesthesiologistNotes')(procedure),
  };

  if (cancelModal) {
    return <ProcedureCancellationModal procedureId={procedure?.id} onDone={onCancelProcedure} />;
  }

  return (
    <Modal title="Edit Procedure" withRouter={false} onClose={onClose} fullWindow={fullWindow}>
      <SubmitAndDangerLinkButtonForm
        input={ProcedureInput}
        inputProps={{ operationRooms }}
        initialValue={initialValue}
        label="Save"
        onSubmit={handleSubmit}
        onDangerLinkButtonClick={procedure.isCanceled ? handleReactivateProcedure : toggleCancelModal}
        onCancel={onClose}
        dangerLabel={procedure.isCanceled ? 'Reactivate Procedure' : 'Cancel Procedure'}
        context={{
          isCreate: false,
          isEdit: true,
        }}
      />
    </Modal>
  );
};

export default ProcedureEditor;
