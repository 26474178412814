import toString from './toString';
import { capitalize } from 'lodash';

const toHighlightsString2 = toString.with({
  YesNo: (question, positive, child) => {
    const item = capitalize(
      question
        .replace(/\?/g, '')
        .replace(/^Do you have\s/g, 'Has ')
        .replace(/^Are you a\s/g, '')
        .replace(/^Any chance you may be\s/g, '')
        .replace(/^Did you\s/g, 'Has ')
        .replace(/^Any\s/g, 'Has ')
        .replace(/^Have you ever\s/g, '')
        .replace(/^Have you\s/g, 'Has ')
        .replace(/^Is it a\s/g, '')
        .replace(/^Do you\s/g, '')
    );
    return positive ? `${item}${child ? ` (${child})` : ''}` : '';
  },
});

export default toHighlightsString2;
