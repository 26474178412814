import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment AlertData on Alert {
      id
      type
      priority
      gatewayId
      braceletId
      sensorId
      roomId
      room {
        id
        name
      }
      sensor {
        id
        location
        temperatureMin {
          value
          unit
        }
        temperatureMax {
          value
          unit
        }
        humidityMin
        humidityMax
      }
      createdAt
      resolvedAt
      isResolved
      action
      temperature {
        value
        unit
      }
      humidity
    }
  `,
};

export const list = gql`
  query sensorAlerts($filter: Filter!, $sensors: [String!]!) {
    sensorAlerts(filter: $filter, sensors: $sensors) {
      ...AlertData
    }
  }
  ${fragments.all}
`;

export const item = gql`
  query sensorAlert($id: Long!) {
    sensorAlert(id: $id) {
      ...AlertData
    }
  }
  ${fragments.all}
`;

export const resolveAlert = gql`
  mutation resolveAlert($id: Long!, $action: String) {
    resolveAlert(id: $id, action: $action)
  }
`;

export default {
  item,
  list,
  update: resolveAlert,
};
