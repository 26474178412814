import { gql } from '@apollo/client';
import { fragments } from './procedures';

export const consultationsSubscription = gql`
  subscription consultations($filter: Filter) {
    consultations(filter: $filter) {
      ...ProcedureDataWithoutEvents
      entryQuestionnaireProgress
      questionnaireInviteSentAt
      instructionsInviteSentAt
      questionnaireInviteSMSCount
      questionnaireInviteEmailCount
      lastQuestionnaireInviteSMS {
        id
        type
        timestamp
        patientPin
        channel
        text
        status
        content
        exchangeId
      }
    }
  }
  ${fragments.withoutEvents}
`;
