import React, { FC, useEffect, useMemo, useState } from 'react';
import { createStyles, makeStyles, MenuItem, MenuList, Popover, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import StaffMemberT from '../../../../../types/StaffMember';
import Spinner from '../../../../../se/components/Spinner';
import StaffMember from './StaffMember';
import AddStaffSlot from './AddStaffSlot';
import { Subscription, SubscriptionResult } from '@apollo/react-components';
import { useApolloClient } from '@apollo/client';
import StaffShiftMenuButton from './StaffShiftMenuButton';
import { useStaffShiftContext } from './StaffShiftContext';
import { light } from '../../../../../theme';
import { Procedure } from '../../../../../types/Procedure';
import { useScope } from '../../../../../hooks/useScope';
import { useStaffSlotContext } from './StaffSlotContext';
import { StaffShiftStoreContext, useCreateStaffShiftStore } from './StaffShift';
import useDebounced from '../../../../../util/useDebounced';

interface StaffShiftMenuProps {
  procedure?: Procedure;
  staffMember: StaffMemberT;
  toggleStaffMember: (staffMember: StaffMemberT) => (event: React.MouseEvent<HTMLElement>) => Promise<void>;
  editableStaff?: boolean;
}

const StaffShiftMenuItem: FC<StaffShiftMenuProps> = ({ staffMember, procedure, toggleStaffMember, editableStaff }) => {
  const classes = useStyles();
  const [busy, setBusy] = useState(false);

  const staffShiftStore = useCreateStaffShiftStore();

  const onClick = async (e: React.MouseEvent<HTMLElement>) => {
    if (!staffMember?.assignedShift?.id) {
      staffShiftStore.setState({ editTime: true });
    } else {
      staffShiftStore.setState({ editTime: false });
    }
    try {
      setBusy(true);
      await toggleStaffMember(staffMember)(e);
    } finally {
      setBusy(false);
    }
  };

  const debouncedBusy = useDebounced(busy, 1000) && busy;

  return (
    <MenuItem onKeyDown={e => e.stopPropagation()} key={staffMember.id} onClick={onClick}>
      <StaffShiftStoreContext.Provider value={staffShiftStore}>
        <StaffMember
          {...staffMember}
          busy={debouncedBusy}
          className={classes.staffMember}
          procedure={procedure}
          editableStaff={editableStaff}
          additionalText={staffMember?.additionalText}
          interactive
          isMenuListItem={true}
        />
      </StaffShiftStoreContext.Provider>
    </MenuItem>
  );
};

const StaffShiftMenu = ({ procedure }: { procedure?: Procedure }) => {
  const classes = useStyles();

  const searchEl = React.useRef<HTMLInputElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (open) {
      searchEl.current?.focus();
    } else {
      setSearch('');
    }
  }, [open]);

  const [search, setSearch] = useState('');

  const handleSearchUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const staffShiftContext = useStaffShiftContext();
  const staffSlotContext = useStaffSlotContext();

  const addSlot = async () => {
    await staffShiftContext.add();
    staffSlotContext.openOpenPositionDialog();
    handleClose();
  };
  const scope = useScope();
  const toggleStaffMember = (staffMember: StaffMemberT) => async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (!!staffMember.assignedShift) {
      await staffShiftContext.remove(staffMember.assignedShift.id, scope?.hospital?.id, scope?.hospital?.id);
    } else {
      await staffShiftContext.add(staffMember?.staffId, staffMember?.hourlyRate);
    }
  };

  const [staffMembers, setStaffMembers] = useState<StaffMemberT[] | null>(null);

  const filteredStaffMembers = useMemo(() => {
    const chunks = search.toLowerCase().split(/\s+/);
    return (staffMembers || []).filter((staffMember: StaffMemberT) => {
      const staffMemberName = staffMember.name.toLowerCase();
      return chunks.every(chunk => staffMemberName.indexOf(chunk) >= 0);
    });
  }, [search, staffMembers]);

  const apolloClient = useApolloClient();

  return (
    <>
      <StaffShiftMenuButton onClick={handleButtonClick} />
      {open && (
        <Subscription
          client={apolloClient as any}
          subscription={staffShiftContext.staffMembers.list}
          variables={staffShiftContext.staffMembers.variables}
        >
          {({ data }: SubscriptionResult) => {
            if (data?.staffMembers) {
              setStaffMembers(data?.staffMembers);
            }
            return null;
          }}
        </Subscription>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClick={e => e.stopPropagation()}
        onClose={handleClose}
        getContentAnchorEl={null}
        keepMounted
        classes={{
          paper: classes.popover,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {staffMembers ? (
          <>
            <TextField
              size="small"
              variant="outlined"
              value={search}
              onChange={handleSearchUpdate}
              inputRef={searchEl}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" className={classes.searchIcon} />,
                autoCapitalize: 'off',
                autoComplete: 'off',
                autoCorrect: 'off',
                autoFocus: true,
                classes: {
                  root: classes.inputRoot,
                  inputAdornedStart: classes.inputAdornedStart,
                },
              }}
              classes={{
                root: classes.textFieldRoot,
              }}
            />
            <MenuList onClick={e => e.stopPropagation()}>
              <MenuItem onClick={addSlot}>
                <AddStaffSlot interactive />
              </MenuItem>
              {filteredStaffMembers.map((staffMember: StaffMemberT) => (
                <StaffShiftMenuItem
                  key={staffMember.id}
                  staffMember={staffMember}
                  procedure={procedure}
                  toggleStaffMember={toggleStaffMember}
                  editableStaff={true}
                />
              ))}
            </MenuList>
          </>
        ) : (
          <div className={classes.spinnerContainer}>
            <Spinner className={classes.spinner} />
          </div>
        )}
      </Popover>
    </>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    textFieldRoot: {
      margin: '1rem',
      marginBottom: 0,
      padding: 0,
    },
    inputRoot: {
      width: '14rem',
      fontSize: '0.8125em',
      padding: '0.25em',
      color: 'white',
    },
    searchIcon: {
      marginLeft: '0.25em',
      marginRight: '0.25em',
    },
    inputAdornedStart: {
      paddingLeft: 0,
    },
    spinnerContainer: {
      padding: '1rem',
    },
    spinner: {},
    staffMember: {
      flex: 1,
    },
    popover: {
      backgroundColor: light.popover.background.string(),
    },
  })
);

export default StaffShiftMenu;
