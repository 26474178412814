import { gql, useSubscription } from '@apollo/client';
import Box from '@material-ui/core/Box';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import discharge from '../../../../assets/sound/discharge.mp3';
import { useScope } from '../../../../hooks/useScope';
import { tabletThemeDark } from '../../../../themes/tabletTheme';
import { HospitalInfo } from '../../../HospitalInfo';

const ExitPage = () => {
  const subscription = useSubscription(gql`
    subscription exitGateway {
      exitGateway {
        braceletId
        patientId
        batteryLevel
      }
    }
  `);
  const scope = useScope();
  const hospitalName = scope?.hospital?.name;
  const classes = useStyles();

  const exitGateway = subscription.data?.exitGateway;

  const braceletDetected = Boolean(exitGateway);

  useEffect(() => {
    if (braceletDetected) {
      try {
        playSound();
      } catch (e) {
        console.warn(e);
      }
    }
  }, [braceletDetected]);

  return (
    <ThemeProvider theme={tabletThemeDark}>
      <Box
        className={clsx(classes.page, {
          [classes.warning]: braceletDetected,
        })}
      >
        <HospitalInfo scope={scope} />

        <Box className={classes.content}>
          <Typography variant="h1" className={classes.message}>
            {braceletDetected
              ? 'Please take off the patient’s bracelet.'
              : 'We appreciate your trust in our care. Wishing you a speedy and smooth recovery!'}
          </Typography>
          {subscription.error && <pre>{JSON.stringify(subscription.error)}</pre>}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

async function playSound() {
  const sound = new Audio(discharge);
  await sound.play();
  await sleep(300);
  const sound1 = new Audio(discharge);
  await sound1.play();
  await sleep(300);
  const sound2 = new Audio(discharge);
  await sound2.play();
  await sleep(300);
  const sound3 = new Audio(discharge);
  await sound3.play();
}

async function sleep(duration) {
  return new Promise(resolve => setTimeout(resolve, duration));
}

const useStyles = makeStyles(theme => ({
  page: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hospitalName: {
    fontSize: '2rem',
    fontWeight: '500',
    textAlign: 'center',
  },
  message: {
    fontSize: '11vmin',
    fontWeight: '500',
    maxWidth: '16ch',
    textAlign: 'center',
  },
  warning: {
    backgroundColor: theme.palette.error.dark,
    animation: `$flash 800ms ${theme.transitions.easing.sharp} infinite alternate`,
  },
  '@keyframes flash': {
    '0%': {
      backgroundColor: theme.palette.error.dark,
    },
    '100%': {
      backgroundColor: theme.palette.error.main,
    },
  },
}));

export default ExitPage;
