import { useEffect, useRef } from 'react';
import { RouteChildrenProps, RouteProps } from 'react-router';
import { chartingStore } from './modules/chartingStore';
import { useChartingPinTimeout, useChartingSession, useChartingSessionKeepAlive } from './modules/hooks';

const ChartingTimeout = ({ history, location, match }: RouteChildrenProps) => {
  const timeoutRef = useRef(0);
  const session = useChartingSession();

  const keepAlive = useChartingSessionKeepAlive();
  const chartingPinTimeout = useChartingPinTimeout();

  const timeout =
    chartingPinTimeout === undefined ? 60000 : chartingPinTimeout === null ? null : chartingPinTimeout * 1000;

  useEffect(() => {
    if (!session && match?.url && location.pathname !== match?.url) {
      history.replace(match.url + location.search);
    }
  }, [session, match?.url, location.pathname, location.search]);

  useEffect(() => {
    if (session && keepAlive === 0 && timeout !== null) {
      // @ts-ignore
      timeoutRef.current = setTimeout(() => {
        chartingStore.setState({ session: null });
      }, timeout);

      return () => clearTimeout(timeoutRef.current);
    }
  }, [keepAlive, session, timeout]);

  useEffect(() => {
    if (session && keepAlive === 0 && timeout !== null) {
      const debounce = () => {
        clearTimeout(timeoutRef.current);

        // @ts-ignore
        timeoutRef.current = setTimeout(() => {
          chartingStore.setState({ session: null });
        }, timeout);
      };

      document.body.addEventListener('keydown', debounce);
      document.body.addEventListener('keypress', debounce);
      document.body.addEventListener('keyup', debounce);
      document.body.addEventListener('touchstart', debounce);
      document.body.addEventListener('touchmove', debounce);
      document.body.addEventListener('touchend', debounce);
      document.body.addEventListener('mousedown', debounce);
      document.body.addEventListener('mousemove', debounce);
      document.body.addEventListener('mouseup', debounce);

      return () => {
        document.body.removeEventListener('keydown', debounce);
        document.body.removeEventListener('keypress', debounce);
        document.body.removeEventListener('keyup', debounce);
        document.body.removeEventListener('touchstart', debounce);
        document.body.removeEventListener('touchmove', debounce);
        document.body.removeEventListener('touchend', debounce);
        document.body.removeEventListener('mousedown', debounce);
        document.body.removeEventListener('mousemove', debounce);
        document.body.removeEventListener('mouseup', debounce);
      };
    }
  }, [keepAlive, timeout, session]);

  return null;
};

export default ChartingTimeout;
