import React, { ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react';
import { TextField } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface PinInputProps {
  value: string;
  onChange: (value: string) => void;
  onComplete: (value: string) => void;
  disabled?: boolean;
}

const PinInput = ({ value, onChange, onComplete, disabled }: PinInputProps) => {
  const classes = usePinInputStyles();
  const refs = [
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
    useRef<HTMLInputElement>(),
  ];
  const current = value.length < refs.length ? refs[value.length].current : undefined;

  const handleFocus = (i: number) => () => {
    if (value.length > i) {
      onChange(value.substring(0, i));
    } else if (value.length < i) {
      refs[value.length].current!.focus();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const nextValue = e.target.value.toUpperCase().replace(/[^A-Z0-9]+/g, '');
    onChange(value + nextValue.slice(0, 6 - value.length));
  };

  const handleKeydown = (i: number) => (e: KeyboardEvent<HTMLInputElement>) => {
    const key = e.keyCode || e.charCode;

    if (key === 8 || key === 46) {
      refs[i].current!.focus();
      if (value.length > 0) {
        onChange(value.slice(0, i - 1));
      }
    }
  };

  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }
    if (current && !disabled) {
      current.focus();
    }
    if (value.length === 6) {
      onComplete(value);
    }
  }, [current, disabled, value, onComplete]);

  return (
    <div className={classes.inputWrapper}>
      {refs.map((ref, i) => (
        <TextField
          variant="filled"
          type="number"
          key={i}
          inputRef={ref}
          inputProps={{
            autoFocus: value.length === 0 && i === 0,
            onKeyDown: handleKeydown(i),
          }}
          onFocus={handleFocus(i)}
          value={value[i] || ''}
          onChange={handleChange}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

const usePinInputStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100%',
  },
  inputWrapper: {
    display: 'flex',
    margin: theme.spacing(0, -1),

    '& > * > * > input': {
      fontSize: '2rem',
      padding: '1rem 0.6rem',
      textAlign: 'center',
    },

    '& > *': {
      margin: theme.spacing(0, 1),
    },

    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, -0.25),

      '& > *': {
        margin: theme.spacing(0, 0.25),
      },
    },
  },
}));

export default PinInput;
