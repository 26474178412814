import React, { FC } from 'react';
import useHasAccessRight from '../hooks/useHasAccessRight';

interface ProtectedContentProps {
  accessRight: string;
  content: React.ReactNode;
  fallback?: React.ReactNode;
}

const ProtectedContent: FC<ProtectedContentProps> = ({ accessRight, content, fallback }) => {
  const hasAccessRight = useHasAccessRight();

  return <>{hasAccessRight(accessRight) ? content : fallback || null}</>;
};

export default ProtectedContent;
