import React, { useEffect, useMemo, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import TabNavigation from '../sensor/TabNavigation';
import styled from 'styled-components';
import responsive from '../../../se/utilities/responsive';
import { Header } from '../sensor/SensorPage';
import { DefaultTitle } from '../../../se/components/entity/EntityRouter';
import BlockSchedule from './BlockSchedule';
import { roundValue } from '../Analytics';
import BookingAnalytics from './BookingAnalytics';
import PhysicianPerformance from './PhysicianPerformance';
import ActionBar from './ActionBar';
import get from 'lodash/get';
import RouterLink from '../../../se/components/RouterLink';
import { withSession } from '../../../state/Session';
import { unpackSessionObject } from '../unpackSessionObject';
import { compose } from 'recompose';
import { useSubscription } from '@apollo/client';
import { bookingStats } from '../../../graph/bookings';
import { endOfMonth, startOfMonth } from 'date-fns';
import { defaultTo, find } from 'lodash';
import Mininytics from './Mininytics';
import { Range, toLocalDate } from '../../core/DatePicker';
import { ArrowBack, InsertChartOutlined, Person, Schedule } from '@material-ui/icons';
import { withScope } from '../../../contexts/ScopeContext';

const Page = styled.div`
  color: ${props => props.theme.textColor.string()};
  padding: 2em 3em;

  ${responsive.md.andSmaller`
    padding: 1em;
  `}
`;

const TitleStack = styled.div`
  display: flex;
  flex-direction: column;
`;

const tabs = match => [
  {
    id: 'block-schedule',
    name: 'Block Schedule',
    icon: <Schedule />,
    pathname: match.url,
    Component: BlockSchedule,
    exact: true,
  },
  {
    id: 'analytics',
    name: 'Block Analytics',
    icon: <InsertChartOutlined />,
    pathname: `${match.url}/analytics`,
    Component: BookingAnalytics,
  },
  {
    id: 'performance',
    name: 'Physician Analytics',
    icon: <Person />,
    pathname: `${match.url}/performance`,
    Component: PhysicianPerformance,
  },
];

const NarrowHeader = styled(Header)`
  h2 {
    margin: 0.2em 0 0.4em 0;
  }
  margin-bottom: 1em;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: start;
  justify-self: flex-end;
  height: 100%;
  justify-content: space-between;
`;

const BlockSchedulePage = ({ match, location, isAdmin, isSuperAdmin, isGroupAdmin, organizationId }) => {
  const routes = useMemo(() => tabs(match), [match]);
  const [date, setDate] = useState(new Date());
  const [dateRange, setDateRange] = useState();
  const [physician, setPhysician] = useState();
  const [colorMode, setColorMode] = useState(false);
  const isPowerUser = isSuperAdmin || isGroupAdmin || isAdmin;

  useEffect(() => {
    setDateRange(Range.between(toLocalDate(startOfMonth(date)), toLocalDate(endOfMonth(date))));
  }, [date, setDateRange]);

  const filter = {
    dateRange: {
      from: toLocalDate(startOfMonth(date)),
      to: toLocalDate(endOfMonth(date)),
    },
    physician,
  };
  const statQuery = useSubscription(bookingStats, { variables: { filter }, skip: !dateRange });

  const stats = get(statQuery, 'data.bookingStats', []);
  const operationRoomBookings = defaultTo(
    find(stats, stat => stat.id === 'operationRoomBookings'),
    {}
  );
  const scheduledPatients = defaultTo(
    find(stats, stat => stat.id === 'scheduledPatients'),
    {}
  );
  const bookings = roundValue(operationRoomBookings.value);
  const utilization = roundValue(scheduledPatients.value);

  return (
    <Page>
      <NarrowHeader>
        <TitleStack>
          <RouterLink to={isAdmin ? '/' : organizationId ? `/su/${organizationId}` : '/'}>
            <ArrowBack fontSize={'small'} />
            {` Back${isPowerUser ? ' to admin' : ''}`}
          </RouterLink>
          <DefaultTitle>Block Schedule</DefaultTitle>
          <TabNavigation tabs={routes} location={location} />
        </TitleStack>
        <Container>
          <Switch>
            <Route
              exact
              path={`${match.url}`}
              render={() => (
                <ActionBar
                  date={date}
                  setDate={setDate}
                  physician={physician}
                  setPhysician={setPhysician}
                  setColorMode={setColorMode}
                  colorMode={colorMode}
                />
              )}
            />
            <Route
              path={'*'}
              render={() => (
                <ActionBar
                  date={date}
                  setDate={setDate}
                  physician={physician}
                  setPhysician={setPhysician}
                  colorMode={colorMode}
                  // disabledDatePicker={!!(location.pathname === `${match.url}/performance` && !!physician)}
                />
              )}
            />
          </Switch>
          <Mininytics bookings={bookings} utilization={utilization} />
        </Container>
      </NarrowHeader>
      <Switch>
        {routes.map(({ id, exact, pathname, Component }, index) => (
          <Route
            key={id || pathname || index}
            exact={exact}
            path={pathname}
            render={() => (
              <Component
                date={date}
                physician={physician}
                setPhysician={setPhysician}
                colorMode={colorMode}
                statistics={stats}
                loading={get(stats, 'data.bookingStats.loading')}
                filter={filter}
              />
            )}
          />
        ))}
      </Switch>
    </Page>
  );
};

export default compose(withScope, withSession(unpackSessionObject))(BlockSchedulePage);
