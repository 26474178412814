import React, { FC, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { Box, Typography } from '@material-ui/core';
import StackView from '../../../../se/components/StackView';
import { useStyles } from './styles';
import { useSubscription } from '@apollo/client';
import { scheduledSurgeryColumns } from '../columns';
import CollectionListOnly from '../../../../se/components/collection/CollectionListOnly';
import { scheduledSurgeriesSubscription } from '../../../../graph/surgeon/surgeries';
import RouterLink from '../../../../se/components/RouterLink';
import SurgeonProcedureMobileListItem from '../../surgeonProcedures/SurgeonProcedureMobileListItem';
import EntityState from '../../../../se/components/EntityState';
import { ArrowBack } from '@material-ui/icons';

type Params = { tab: 'need-docs' | 'docs-updated' };

const EmptyState = (props: any) => <EntityState title="All Clear" hint="No items require your attention." {...props} />;

const SurgeriesWithMissingDocuments: FC<RouteComponentProps<Params>> = ({ location, history, match }) => {
  const classes = useStyles();

  const query = useSubscription(scheduledSurgeriesSubscription);

  const filteredList = useMemo(
    () => (query: { data: { scheduledSurgeries?: any[] } }) =>
      query.data?.scheduledSurgeries?.filter(
        e =>
          JSON.parse(e?.scheduleRequest?.state || '{}').__typename !== 'Cancelled' &&
          (e?.scheduleRequest?.issues || []).some((issue: any) => JSON.parse(issue.state).__typename === 'Requested')
      ),
    []
  );

  return (
    <StackView>
      <Box display="flex" flexDirection="column" mt={1} mb={1}>
        <RouterLink
          to={{
            pathname: location.pathname.replace(`/requires-attention/${match.params.tab}`, ''),
          }}
        >
          <ArrowBack fontSize={'small'} />
          &nbsp; Back to Surgeries
        </RouterLink>
        <div className={classes.titleSearch}>
          <Typography variant="h2" className={classes.title}>
            Requires Attention
          </Typography>
        </div>
      </Box>
      {/* @ts-ignore */}
      <CollectionListOnly
        match={match}
        location={location}
        history={history}
        data={query}
        getList={filteredList}
        columns={scheduledSurgeryColumns}
        hideColumns={false}
        showUrlProvider={(id: any) =>
          history.push(location.pathname.replace(`/requires-attention/${match.params.tab}`, `/${id}`))
        }
        idProvider={(row: any) => row.id}
        useColumnSelection={true}
        tableKey={location.pathname}
        defaultDisplayColumns={[
          '#',
          // 'Visit',
          'Name',
          'Consultation Date',
          'Physician',
          // 'Procedure Type',
          // 'DOB',
          'Age',
          'Sex',
          // 'Patient Email Address',
          // 'Patient Cell Phone Number',
          // 'Patient Home Phone Number',
          'Form %',
          // 'Last Invite',
          'Status',
        ]}
        MobileItemComponent={SurgeonProcedureMobileListItem}
        // View={View}
        // onViewClick={onViewClick}
        // Loading={Loading}
        // Error={Error}
        Empty={EmptyState}
        // FilterComponent={FilterComponent}
        // FooterComponent={FooterComponent}
        // filter={filter}
        // setFilter={setFilter}
        // highlightedProvider={highlightedProvider}
        // highlightedRowStyles={highlightedRowStyles}
        // highlightedRowStyleProvider={highlightedRowStyleProvider}
        // AdditionalBlock={AdditionalBlock}
        // containsSeparator={containsSeparator}
        // useCSVExport={useCSVExport}
        // simpleFilter={simpleFilter}
        // defaultSort={defaultSort}
        // searchSource={searchSource}
      />
    </StackView>
  );
};

export default SurgeriesWithMissingDocuments;
