import React, { createContext, useContext } from 'react';
import { format } from 'date-fns';
import { generateDetailedPdf } from './pdf/PdfGenerator';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import QuestionnaireInvite from './questionnaire/QuestionnaireInvite';
import QuestionnaireFulfilment from './questionnaire/QuestionnaireFulfilment';
import QuestionnaireActions from './questionnaire/QuestionnaireActions';
import QuestionnaireAnswersPreview from './questionnaire/QuestionnaireAnswersPreview';
import parseJson from './utils/parseJson';
import { generateSummaryPdf, generateSummaryPdf28 } from './pdf/SummaryPdfGenerator';
import { Label, QuestionnaireWrapper, Wrapper } from './questionnaire/Common';
import useHasAccessRight from '../../../hooks/useHasAccessRight';
import { ProcedureStatus } from '../../../types/ProcedureEvent';
import { getLastProcedureEvent } from '../../../util/procedureEvents';
import { getFullPdfGenerator, getMedPdfGenerator } from './pdf/getPdfGenerator';
import { PrintLink } from './PrintLink';
import Questionnaire, { QuestionnaireAndAnswers, QuestionnaireEntry } from '../../../types/Questionnaire';
import { Procedure } from '../../../types/Procedure';
import { useScope } from '../../../hooks/useScope';

const QuestionnaireAnswers = ({ onPrint, ...rest }: any) => {
  const hasAccessRight = useHasAccessRight();
  const isAllowedToView = hasAccessRight('patient.view');
  const scope = useScope();
  const hospitalId = scope?.hospital?.id;
  let generateSummaryPdfSwitch = generateSummaryPdf;

  if (
    (window.location.hostname === 'view2.ospitek.com' && hospitalId === 28) ||
    (window.location.hostname === 'testing.ospitek.com' && hospitalId === 96)
  ) {
    generateSummaryPdfSwitch = generateSummaryPdf28;
  }

  const questionnaireType = useContext(QuestionnaireTypeContext);

  return (
    <QuestionnaireAnswersPreview {...rest}>
      {questionnaireType !== 'InTake Form' && (
        <PrintLink
          onPrint={onPrint(generateDetailedPdf)}
          title={`Detailed ${questionnaireType}`}
          disabled={!isAllowedToView ? 'You don’t have sufficient permissions to open this document.' : false}
        />
      )}
      {questionnaireType !== 'InTake Form' && (
        <PrintLink
          onPrint={onPrint(generateSummaryPdfSwitch)}
          title={`Summary ${questionnaireType}`}
          disabled={!isAllowedToView ? 'You don’t have sufficient permissions to open this document.' : false}
        />
      )}
    </QuestionnaireAnswersPreview>
  );
};

export const getPrintFn =
  (entryQuestionnaire: QuestionnaireEntry, pacuCharts: QuestionnaireAndAnswers[], procedure: Procedure, scope: any) => (fn: any) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const event = getLastProcedureEvent(procedure?.events || [], ProcedureStatus.PreOpCompleted);

    fn({
      questions: parseJson(entryQuestionnaire.questions),
      answers: parseJson(entryQuestionnaire.answers),
      pacuCharts: pacuCharts,
      procedure,
      hospitalName: get(scope, 'hospital.name'),
      preOpBy: get(event, 'user.name'),
      // @ts-ignore
      preOpAt: get(event, 'timestamp') ? format(get(event, 'timestamp'), 'MM/DD/YYYY HH:mm') : undefined,
      scheduleProvider: get(scope, 'hospital.scheduleProvider'),
      notes: sortBy(
        get(procedure, 'events', []).filter(_ => _.type === 'Note'),
        'timestamp'
      ),
      showPreOpNote: get(scope, 'hospital.modules.showPreOpNote'),
    });
  };

export const QuestionnaireTypeContext = createContext('Questionnaire');

export default ({
  pacuCharts,
  entryQuestionnaire,
  procedure,
}: {
  pacuCharts: QuestionnaireAndAnswers[];
  entryQuestionnaire: QuestionnaireEntry;
  procedure: Procedure;
}) => {
  const scope = useScope();
  const hospitalId = scope?.hospital?.id;
  const onPrint = getPrintFn(entryQuestionnaire, pacuCharts, procedure, scope);

  const fullPdfGenerator = getFullPdfGenerator(hospitalId);
  const medicationPdf = getMedPdfGenerator(hospitalId);

  const hasAccessRight = useHasAccessRight();
  const isAllowedToView = hasAccessRight('patient.view');

  const questionnaireType = useContext(QuestionnaireTypeContext);

  return (
    <Wrapper>
      <div>
        <QuestionnaireWrapper>
          <QuestionnaireFulfilment
            formProgress={get(
              procedure,
              questionnaireType === 'InTake Form' ? 'inTakeForm.progress' : 'entryQuestionnaire.progress'
            )}
          />
          <QuestionnaireInvite procedure={procedure} />
        </QuestionnaireWrapper>
        <QuestionnaireActions entryQuestionnaire={entryQuestionnaire}>
          {questionnaireType !== 'InTake Form' && (
            <>
              <PrintLink
                onPrint={onPrint(fullPdfGenerator)}
                title="Full Form"
                disabled={!isAllowedToView ? 'You don’t have sufficient permissions to open this document.' : false}
              />
              <PrintLink
                onPrint={onPrint(medicationPdf)}
                title="Medication Rec. Form"
                disabled={!isAllowedToView ? 'You don’t have sufficient permissions to open this document.' : false}
              />
            </>
          )}
        </QuestionnaireActions>
        {!entryQuestionnaire && <Label>Entry form is not filled yet</Label>}
        {entryQuestionnaire && <QuestionnaireAnswers {...entryQuestionnaire} onPrint={onPrint} />}
      </div>
    </Wrapper>
  );
};
