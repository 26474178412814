import React from 'react';
import styled from 'styled-components';
import posed, { PoseGroup } from 'react-pose';
import { withTheme } from '../../se/theme';
import responsive from '../../se/utilities/responsive';

const Bar = styled.div`
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0 1em;
`;

const BarBody = styled.div`
  color: ${withTheme(theme => theme.warningTextColor.string())};
  background-color: ${withTheme(theme => theme.warningColor.string())};
  font-size: ${withTheme(theme => theme.textSize.m)};
  line-height: 1.35em;
  padding: 1em 1.5em;
  border-radius: ${withTheme(theme => theme.border.radius.default)};

  opacity: 0.95;

  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  margin: 0 auto;

  > strong {
    font-weight: 500;
  }

  ${responsive.lg.andSmaller`
    width: auto;
  `};

  ${responsive.md.andSmaller`
    flex-direction: column;
    text-align: center;
    padding: .75em 1em;
  `};
`;

const AnimatedBar = posed(Bar)({
  preEnter: {
    opacity: 0,
    transition: { type: 'spring' },
  },
  enter: {
    position: 'fixed',
    opacity: 1,
    y: 20,
    transition: {
      duration: 300,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
  exit: {
    position: 'fixed',
    opacity: 0,
    y: -20,
    transition: {
      duration: 300,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
});

export default ({ children }) => (
  <PoseGroup animateOnMount>
    <AnimatedBar key={1}>
      <BarBody>{children}</BarBody>
    </AnimatedBar>
  </PoseGroup>
);
