import { compose, mapProps, withProps } from 'recompose';
import ObjectInput from '../../../se/components/inputs/ObjectInput';

import TextInput from '../../../se/components/inputs/TextInput';
import AddressInput from '../AddressInput';
import PhoneInput from '../../../se/components/inputs/PhoneInput';
import { withLabel } from '../../../se/components/Label';
import NotesInput from '../NotesInput';
import NumberInput from '../../../se/components/inputs/NumberInput';
import { LinkInput, TimezoneInput } from '../hospitals/HospitalInput';
import get from 'lodash/get';
import ToggleInput from '../../../se/components/inputs/ToogleInput';

const PhoneNumberInput = compose(
  withLabel('Contact Phone Number'),
  withProps({ placeholder: 'e.g., (619) 412-9512' })
)(PhoneInput);

export default withProps({
  schema: {
    name: withProps({ placeholder: 'e.g. Capital Medical Clinic', required: true })(withLabel('Name')(TextInput)),
    address: AddressInput,
    contact: PhoneNumberInput,
    notes: NotesInput,
    reviewIntegrations: compose(
      withLabel('Review Integrations', { section: true }),
      withProps({
        schema: {
          google: withLabel('Google')(LinkInput),
          yelp: withLabel('Yelp!')(LinkInput),
        },
      })
    )(ObjectInput),
    questionnaireValidMonths: withProps({ min: 0, required: true })(
      withLabel('Questionnaire valid in months for repeated patients')(NumberInput)
    ),
    passwordRefreshDays: withProps({ min: 0 })(
      withLabel('Period in days after which user is forced to change password')(NumberInput)
    ),
    sessionTimeout: withProps({ min: 60 })(withLabel('Session Expiration Timeout (in seconds)')(NumberInput)),
    timezone: mapProps(props => ({ ...props, value: get(props, 'value.id', get(props, 'value')) }))(TimezoneInput),
    exchangeOnly: withProps({ label: 'Limit to Document Exchange Only' })(ToggleInput),
  },
})(ObjectInput);
