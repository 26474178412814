import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import Spinner from '../../../se/components/Spinner';
import Box from '@material-ui/core/Box';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { patientFile, list } from '../../../graph/surgeon/forms';
import { useApolloClient, useQuery } from '@apollo/client';
import { hasConsent, hasSignature } from '../../../graph/surgeon/PhysicianSO';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import Typography from '@material-ui/core/Typography';
import { formSaveTypeLabels } from './enums';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import useHasAccessRight from '../../../hooks/useHasAccessRight';
import Tooltip from '../../Tooltip';

const PatientFileGenerator = ({ procedureId, physician }) => {
  const hasAccessRight = useHasAccessRight();
  const isAllowedToEdit = hasAccessRight('patient.edit');

  const [busy, setBusy] = useState(false);
  const [open, setOpen] = useState(false);
  const [signatureCheck, setSignatureCheck] = useState(false);

  const [state, setState] = useState({});
  const { data: formQuery } = useQuery(list);
  const reportForms = useMemo(() => get(formQuery, 'forms', []), [formQuery]);

  const { data: hasSignatureQuery } = useQuery(hasSignature, { variables: { id: physician ? physician.id : -1 } });
  const { data: hasConsentQuery } = useQuery(hasConsent, { variables: { id: physician ? physician.id : -1 } });
  const physicanHasSignature = get(hasSignatureQuery, 'hasSignature', false);
  const physicanHasConsent = get(hasConsentQuery, 'hasConsent', false);

  const sections = useMemo(() => groupBy(reportForms, _ => _.saveType), [reportForms]);

  useEffect(() => {
    setState(reportForms.reduce((acc, curr) => ({ ...acc, [curr.id]: true }), {}));
  }, [reportForms]);

  const anyChecked = useMemo(() => Object.values(state).includes(true), [state]);

  const client = useApolloClient();

  const handleSignatureCheck = () => {
    setSignatureCheck(!signatureCheck);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleGenerate = async () => {
    if (anyChecked) {
      setBusy(true);
      try {
        const formIds = Object.entries(state)
          .filter(([_, value]) => !!value)
          .map(([key, _]) => parseInt(key, 10))
          .sort((a, b) => reportForms.find(x => x.id === a).order - reportForms.find(x => x.id === b).order);

        const buildResult = await client.query({
          query: patientFile,
          variables: { procedureId, ids: formIds, mdSignature: signatureCheck },
          fetchPolicy: 'network-only',
        });

        if (buildResult.data) {
          const link = document.createElement('a');
          link.download = 'PatientFile' + procedureId + Date.now() + '.pdf';
          link.target = '_blank';
          link.href = buildResult.data.patientFile;
          link.text = 'PDF download';
          link.dispatchEvent(new MouseEvent('click'));
          URL.revokeObjectURL(link.href);
        }
      } finally {
        setBusy(false);
      }
      setOpen(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleCheckboxChange = (id, checked) => {
    setState({ ...state, [id]: checked });
  };

  const selectAll = () => {
    setState(Object.keys(state).reduce((acc, curr) => ({ ...acc, [curr]: true }), {}));
  };

  const deselectAll = () => {
    setState(Object.keys(state).reduce((acc, curr) => ({ ...acc, [curr]: false }), {}));
  };

  return (
    <Fragment>
      <Hidden smDown>
        {/* @ts-ignore */}
        <Tooltip content={!isAllowedToEdit ? 'You don’t have sufficient permissions to edit this document.' : null}>
          <Box ml={2}>
            <Button color="primary" onClick={handleClickOpen} disabled={!isAllowedToEdit}>
              Generate Medical File
            </Button>
          </Box>
        </Tooltip>
      </Hidden>
      <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleCancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Generate Medical File</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item md={6}>
              <DialogContentText>Please select sections for patient file.</DialogContentText>
            </Grid>
            <Grid item md={6}>
              {physicanHasSignature ? (
                physicanHasConsent ? (
                  <FormControlLabel
                    control={<Checkbox checked={signatureCheck} onChange={handleSignatureCheck} name="signature" />}
                    label={`Physician ${physician.name} allows the use of their eSignature on these forms.`}
                  />
                ) : (
                  <label>{`Physican ${physician.name} has not consented to signature usage`}</label>
                )
              ) : (
                <label>Physician signature is not uploaded</label>
              )}
            </Grid>
          </Grid>
          <Box mt={2} display="flex" flexDirection="row" width="100%">
            <FormGroup style={{ width: '100%' }}>
              <Grid container>
                <Grid item xs={12}>
                  <Box mb={2}>
                    <Button color="primary" size="small" onClick={selectAll}>
                      Select all
                    </Button>
                    <Button color="primary" size="small" onClick={deselectAll}>
                      Deselect all
                    </Button>
                  </Box>
                </Grid>
                {Object.entries(sections)
                  .sort((a, b) => a[0].localeCompare(b[0]))
                  .map(([type, items], index) => (
                    <Grid item md={6} key={`section-patient-file-generator-${index}`}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formSaveTypeLabels[type]}
                      </Typography>
                      <Box display="flex" flexDirection="column">
                        {items.map(item => (
                          <FormControlLabel
                            key={`${type}-${item.id}`}
                            control={
                              <Checkbox
                                checked={state[item.id]}
                                onChange={e => handleCheckboxChange(item.id, e.target.checked)}
                                name={item.name}
                              />
                            }
                            label={item.name}
                          />
                        ))}
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button disabled={busy || !anyChecked} onClick={handleGenerate} color="primary">
            Generate & Download
          </Button>

          {busy && (
            <Spinner
              style={{
                verticalAlign: 'middle',
                marginLeft: '0.5rem',
              }}
            />
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default PatientFileGenerator;
