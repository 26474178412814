import { Operation } from '@apollo/client/link/core/types';
import randomId from '../form/questions/util/randomId';
import { tryParseJson } from '../util/parseJson';

export interface OperationsRepository<TKey = unknown> {
  add(operation: Operation): Promise<TKey>;
  remove(key: TKey): Promise<void>;
  list(): Promise<(Operation & { key: TKey })[]>;
}

export class LocalStorageOperationsRepository implements OperationsRepository<string> {
  readonly context: string;

  constructor(context: string) {
    this.context = context;
  }

  add({ query, variables, operationName }: Operation): Promise<string> {
    const id = randomId(5);

    try {
      localStorage.setItem(`operation-${this.context}-${id}`, JSON.stringify({ query, variables, operationName }));
    } catch (e) {
      console.warn('Unable to add operation', e);
    }

    try {
      const operations = tryParseJson(localStorage.getItem(`operations-${this.context}`));
      if (Array.isArray(operations)) {
        operations.push(id);
        localStorage.setItem(`operations-${this.context}`, JSON.stringify(operations));
      } else {
        localStorage.setItem(`operations-${this.context}`, JSON.stringify([id]));
      }
    } catch (e) {
      console.warn('Unable to add operation to index', e);
    }

    return Promise.resolve(id);
  }

  list(): Promise<(Operation & { key: string })[]> {
    const operationIds = tryParseJson(localStorage.getItem(`operations-${this.context}`));

    if (Array.isArray(operationIds)) {
      return Promise.resolve(
        operationIds
          .map(id => {
            const operation = tryParseJson(localStorage.getItem(`operation-${this.context}-${id}`));
            return operation ? { ...operation, key: id } : null;
          })
          .filter(Boolean) as (Operation & { key: string })[]
      );
    }

    return Promise.resolve([]);
  }

  remove(id: string): Promise<void> {
    try {
      localStorage.removeItem(`operation-${this.context}-${id}`);
    } catch (e) {
      console.warn('Unable to remove operation', e);
    }

    try {
      const operations = tryParseJson(localStorage.getItem(`operations-${this.context}`));
      if (Array.isArray(operations)) {
        const resultingOperations = operations.filter(o => o !== id);

        if (resultingOperations.length === 0) {
          localStorage.removeItem(`operations-${this.context}`);
        } else {
          localStorage.setItem(`operations-${this.context}`, JSON.stringify(resultingOperations));
        }
      }
    } catch (e) {
      console.warn('Unable to remove operation from index', e);
    }

    return Promise.resolve();
  }
}
