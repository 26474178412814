import { withProps } from 'recompose';
import graphqlSelectInput from '../graphqlSelectInput';
import { list } from '../../../graph/physicians';

export default withProps({
  isClearable: false,
})(
  graphqlSelectInput({
    entityName: 'Physician',
    label: 'Physician',
    placeholder: 'e.g., Mark Nordstrom',
    graphqlList: list,
    getOptionValue: option => (option ? parseInt(option.value, 10) : undefined),
    isClearable: false,
    sortOptions: true,
  })
);
