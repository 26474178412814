import React from 'react';
import Header from './components/Header';
import MedicalPassportTextArea from '../components/MedicalPassportTextArea';
import MedicalPassportRow from '../components/MedicalPassportRow';
import MedicalPassportRow2 from '../components/MedicalPassportRow2';
import MedicationTextArea from '../components/MedicationTextArea';
import MedicalPassportField from '../components/MedicalPassportField';
import MedicalPassportCheckbox from '../components/MedicalPassportCheckbox';
import styles from '../styles';
import toHighlightsString from '../../../../../questionnaire/toHighlightsString';
import toHighlightsString2 from '../../../../../questionnaire/toHighlightsString2';
import {
  cardiovascular,
  endocrine,
  gastrointestinal,
  gynaecological,
  hematological,
  hepatic,
  musculoskeletal,
  neurological,
  other,
  psychiatric,
  pulmonary,
  renal,
  derm,
  infectious,
  liver,
  assist,
  substance,
  ob,
} from './paths';
import toAge from '../../../../../questionnaire/toAge';
import toHeight from '../../../../../questionnaire/toHeight';
import toWeight from '../../../../../questionnaire/toWeight';
import toBMI from '../../../../../questionnaire/toBMI';
import toWeightLbs from '../../../../../questionnaire/toWeightLbs';
import get from 'lodash/get';
import { useValue } from '../ValueContext';

const Page7 = () => {
  const { questionnaireAnswers } = useValue();

  function yesOrNo(value) {
    if (value === true) {
      return 'yes';
    } else if (value === false) {
      return 'no';
    }
    return '';
  }
  let familyProblem =
    yesOrNo(get(questionnaireAnswers, 'sections.PreProcedureAssessment.schema.associatedProblem.yes[0]', '')) +
    ', ' +
    get(
      questionnaireAnswers,
      'sections.PreProcedureAssessment.schema.associatedProblem.yes[1].schema.associatedProblem_text.text',
      ''
    );

  // allergy
  const allergyList = get(questionnaireAnswers, 'sections.allergies.schema.q1.yes[1].schema.allergy_list.list', []);
  const allergyList2 = get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[0]', false);
  const allergyList3 = get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[0]', false);

  const allergyName = [];
  const reaction = [];

  for (let i = 0; i < allergyList.length; i++) {
    allergyName.push(get(allergyList[i], 'schema.q1_name.text', ''));
    reaction.push(get(allergyList[i], 'schema.q1_reaction.text', ''));
  }

  if (allergyList2) {
    allergyName.push('Latex');
    reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[1].schema.q2_reaction.text', ''));
  }

  if (allergyList3) {
    allergyName.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_name.text', ''));
    reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_reaction.text', ''));
  }

  function combineArrays(arr1, arr2) {
    let result = '';

    for (let i = 0; i < arr1.length && i < arr2.length; i++) {
      result += arr1[i] + ', ' + arr2[i] + '\n';
    }

    return result;
  }

  return (
    <div id="page7" className="page">
      <Header pageNumber={6} pageCount={10} />
      <strong>Pre Operative Anesthesia Assessment</strong>
      <div style={styles.twoNotEqualColumns}>
        <div>
          <div style={styles.oneColumnLeftAlign}>
            <MedicalPassportField label="Planned Procedure" name="procedure.name" />
          </div>
          <div style={styles.sixEqualColumns}>
            <MedicalPassportField
              label="Age"
              name="page5.age"
              path={['demographicInformation', 'dob']}
              reducer={toAge}
            />
            <MedicalPassportField
              label="Sex"
              name="page5.sex"
              fontSize=".6rem"
              path={['demographicInformation', 'sex']}
              reducer={toHighlightsString}
            />
            <MedicalPassportField
              label="Ht"
              name="page5.Ht"
              path={['demographicInformation', 'bmi']}
              reducer={toHeight}
            />
            <MedicalPassportField
              label="Wt"
              name="page5.Wt"
              path={['demographicInformation', 'bmi']}
              reducer={toWeightLbs}
              fontSize={11}
            />
            <MedicalPassportField
              label="KG"
              name="page5.KG"
              path={['demographicInformation', 'bmi']}
              reducer={toWeight}
            />
            <MedicalPassportField
              label="BMI"
              name="page5.BMI"
              fontSize={9.5}
              path={['demographicInformation', 'bmi']}
              reducer={toBMI}
            />
          </div>
          <div style={{ fontSize: '.75rem' }}>Printed data provided through online survey:</div>
        </div>
        <div>
          <div
            style={{
              borderTop: '1px solid black',
              borderLeft: '1px solid black',
              borderRight: '1px solid black',
              fontSize: '.8rem',
            }}
          >
            <MedicalPassportField label="Patient Name" name="procedure.patient.name" />
            <MedicalPassportField label="DOB" name="page5.DOB" path={['demographicInformation', 'dob']} />
            <MedicalPassportField label="Physician" name="procedure.physician.name" />
            <MedicalPassportField label="DOS" name="procedure.serviceTime" />
          </div>
        </div>
      </div>

      <div style={styles.twoNotEqualColumns}>
        <div>
          <div style={styles.greyBackground}>History of Anesthesia Complications</div>
          <div style={styles.oneColumnLeftAlign}>
            <MedicalPassportField label="Patient" name="page7.anesthesiaPatientName" />
            <MedicalPassportField label="Family Member" name="page7.anesthesiaFamilyMember" value={familyProblem} />
          </div>
          <div style={styles.greyBackground}>Review of Systems</div>
          <section style={{}}>
            <div
              style={{
                border: '1px solid black',
                lineHeight: '1.8em',
                borderLeft: 0,
                borderRight: 0,
              }}
            >
              <div style={{ fontSize: '.8rem' }}>
                <MedicalPassportRow2
                  label="Cardiovascular"
                  name="systems.cardiovascular"
                  paths={cardiovascular}
                  style={{ borderTop: 0 }}
                  index={0}
                  reducer={toHighlightsString2}
                />
              </div>
              <MedicalPassportRow2
                label="Pulmonary"
                name="systems.pulmonary"
                paths={pulmonary}
                index={1}
                reducer={toHighlightsString2}
              />
              <MedicalPassportRow2
                label="Renal"
                name="systems.renal"
                paths={renal}
                index={2}
                reducer={toHighlightsString2}
              />
              <MedicalPassportRow2
                label="Hepatic"
                name="systems.hepatic"
                paths={liver}
                index={3}
                reducer={toHighlightsString2}
              />
              <div style={{ fontSize: '.8rem' }}>
                <MedicalPassportRow
                  label="Neurological"
                  name="systems.neurological"
                  paths={neurological}
                  index={4}
                  reducer={toHighlightsString2}
                />
              </div>
              <div style={{ fontSize: '.8rem' }}>
                <MedicalPassportRow2
                  label="Gastrointestinal"
                  name="systems.gastrointestinal"
                  paths={gastrointestinal}
                  index={5}
                  reducer={toHighlightsString2}
                />
              </div>
              <div style={{ fontSize: '.85rem' }}>
                <MedicalPassportRow2
                  label="Hematological"
                  name="systems.hematological"
                  paths={hematological}
                  index={6}
                  reducer={toHighlightsString2}
                />
              </div>
              <MedicalPassportRow2
                label="Endocrine / Metabolic"
                name="systems.endocrine2"
                paths={endocrine}
                index={7}
                reducer={toHighlightsString2}
              />
              <MedicalPassportRow2
                label="Musculo-skeletal"
                name="systems.musculoskeletal"
                paths={musculoskeletal}
                index={8}
                reducer={toHighlightsString2}
              />

              <MedicalPassportRow2
                label="Psychiatric"
                name="systems.psychiatric"
                paths={psychiatric}
                index={9}
                reducer={toHighlightsString2}
              />
              <MedicalPassportRow2
                label="OB/Gyn"
                name="systems.gyn"
                paths={ob}
                index={10}
                reducer={toHighlightsString2}
              />
              <MedicalPassportRow2
                label="Coronavirus"
                name="systems.coivd"
                index={11}
                paths={infectious}
                reducer={toHighlightsString2}
              />
              <div style={{ fontSize: '.7rem' }}>
                <MedicalPassportRow2
                  label="ENT / Dermatological"
                  name="systems.ent"
                  index={12}
                  paths={derm}
                  reducer={toHighlightsString2}
                />
              </div>
              <MedicalPassportRow2
                label="Substance Use"
                name="systems.gyn"
                paths={substance}
                index={13}
                reducer={toHighlightsString2}
              />

              <div style={{ fontSize: '.7rem' }}>
                <MedicalPassportRow2
                  label="Other MRSA, glasses, contacts, assistive devices"
                  name="systems.other"
                  paths={assist}
                  index={14}
                  reducer={toHighlightsString2}
                />
              </div>
            </div>
          </section>
        </div>

        <div style={{ paddingLeft: '5px' }}>
          <div style={styles.greyBackground}>Allergies</div>
          <div>
            <MedicationTextArea label="" name="allergies1" value={combineArrays(allergyName, reaction)} />
          </div>
          <div style={styles.greyBackground}>Current Medications</div>
          <div>
            <MedicalPassportTextArea
              label={''}
              name="medications1"
              path={['medications', 'medicationList']}
              reducer={toHighlightsString}
            />
          </div>
          <div style={styles.greyBackground}>Previous Surgical History</div>
          <div>
            <MedicalPassportTextArea
              label=""
              name="anesthesia1"
              path={['priorSurgeries']}
              reducer={toHighlightsString}
            />
          </div>
        </div>
      </div>
      <div style={styles.greyBackground}>Physical Exam / Labs / Anesthesia Plan</div>
      <div style={styles.twoNotEqualColumns4}>
        <div>
          <div style={styles.threeEqualColumns3}>
            <div style={{ borderRight: '1px solid black' }}>
              <strong>
                <u>Neuro</u>
              </strong>
              <MedicalPassportCheckbox name="page6.oriented" label="Oriented" />
            </div>
            <div style={{ borderRight: '1px solid black' }}>
              <strong>
                <u>Cardiac</u>
              </strong>
              <MedicalPassportCheckbox name="page6.normalRhythm" label="Normal Rhythm" />
              <MedicalPassportCheckbox name="page6.noMurmurs" label="No Murmurs" />
            </div>
            <div style={{ borderRight: '1px solid black' }}>
              <strong>
                <u>Pulmonary</u>
              </strong>
              <MedicalPassportCheckbox name="page6.clear" label="Clear to Auscultation Wheezes/Rhonchi/Rales" />
            </div>
          </div>
          <div>
            <div style={styles.twoNotEqualColumnsNoBorder}>
              <div style={{ borderTop: '1px solid black', borderRight: '1px solid black', paddingRight: '10px' }}>
                <strong>
                  <u>EKG</u>
                </strong>
              </div>
              <div style={{ borderTop: '1px solid black', borderRight: '1px solid black', paddingRight: '10px' }}>
                <strong>
                  <u>Labs</u>
                </strong>
                <div style={styles.threeNotEqualColumns}>
                  <MedicalPassportField label="Na" name="page7.Na" />
                  <MedicalPassportField label="Hgb" name="page7.Hgb" />
                  <MedicalPassportField label="Accucheck" name="page7.Accucheck" />
                </div>

                <div style={styles.threeEqualColumns3}>
                  <MedicalPassportField label="K" name="page7.k" />
                  <MedicalPassportField label="Hct" name="page7.Hct" />
                </div>
                <div style={styles.threeEqualColumns3}>
                  <MedicalPassportField label="Cr" name="page7.Cr" />
                  <MedicalPassportField label="PLT" name="page7.PLT" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <strong>
            <u>Airway</u>
          </strong>
          <MedicalPassportField label="Class" name="page7.Class" />
          <br></br>
          <div style={{ display: 'flex' }}>
            OralOpening
            <MedicalPassportCheckbox name="page7.NormalAir" label="Normal" />
          </div>
          Dentition
          <div style={{ display: 'flex' }}>
            <MedicalPassportCheckbox name="page7.normalDentition" label="Normal" />
            <MedicalPassportCheckbox name="page7.dentures" label="Dentures" />
          </div>
          <MedicalPassportCheckbox name="page7.chipped" label="Chipped / Broken" />
        </div>
      </div>
    </div>
  );
};

export default Page7;
