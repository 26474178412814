export const insertNewBracelet = state => {
  const bracelets = state.bracelets;
  bracelets.push('');
  return { ...state, bracelets };
};

export const removeBracelet = index => state => {
  let bracelets = state.bracelets;
  if (bracelets.length > 1) {
    bracelets.splice(index, 1);
  } else if (bracelets.length === 1) {
    bracelets = [];
  }
  return { ...state, bracelets };
};

export const updateBracelet = (value, index) => state => {
  const bracelets = state.bracelets;
  bracelets[index] = value;
  return { ...state, bracelets };
};
