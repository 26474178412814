import React, { FC } from 'react';
import { Procedure as ProcedureT } from '../../../../../../types/Procedure';
import get from 'lodash/get';
import { Anesthesiologist, Physician } from '../../anesthesiologist/Personnel';
import { Bed } from '../../Patient';
import { PatientStat } from '../../shared/PatientStat';
import { getColor } from '../Procedure';
import { Box, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const Label = ({ text, backgroundColor }: { text: string; backgroundColor: string }) => (
  <Box borderRadius={4} bgcolor={backgroundColor} style={{ fontWeight: 'bold', color: 'white' }} px={0.5}>
    {text}
  </Box>
);

const CardTiny: FC<{
  procedure: ProcedureT;
  physicianName?: string;
  anesthesiologistName?: string;
  anesthesiaType?: string;
  color: string;
  status: string;
  showBedNumber: boolean;
  hideStartTime?: boolean;
  inOrStatus: string;
  hospitalId?: number;
}> = ({
  procedure,
  physicianName,
  anesthesiologistName,
  anesthesiaType,
  color,
  status,
  hideStartTime,
  showBedNumber,
  inOrStatus,
  hospitalId,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      {!hideStartTime && <div className={classes.startTime}>{get(procedure, 'startTimeText')}</div>}
      <Box className={classes.content}>
        <Physician physicianName={physicianName} color={color} inline />
        <Box display="flex" alignItems="center" style={{ gap: 8 }}>
          <Anesthesiologist
            procedureId={procedure.id}
            anesthesiologistName={anesthesiologistName}
            anesthesiaType={anesthesiaType}
            inline
            style={{ fontWeight: 400 }}
          />
          {/*<PatientName*/}
          {/*  name={procedure?.patient?.name || procedure?.patientName}*/}
          {/*  age={get(procedure, 'patientAge')}*/}
          {/*  sex={get(procedure, 'patientSex')}*/}
          {/*  inline*/}
          {/*/>*/}
          <PatientStat inline right>
            {/* {isCanceled ? 'Canceled' : noShow ? 'NoShow' : status} */}
            <Label
              backgroundColor={getColor(status, hospitalId, theme.palette.primary.light)}
              text={inOrStatus ? inOrStatus : status}
            />
            {showBedNumber && !!procedure?.patient?.bed && <Bed bed={procedure?.patient?.bed} />}
          </PatientStat>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    lineHeight: 'normal',
    alignItems: 'center',
    paddingLeft: '0.375em',
    height: '100%',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
  },
  startTime: {
    minWidth: '3em',
  },
}));

export default CardTiny;
