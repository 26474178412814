import { gql, useQuery } from '@apollo/client';
import { z } from 'zod';
import useValidatedQuery from './useValidatedQuery';

const SystemWideRecentlyInactiveGateways = z.object({
  systemWideRecentlyInactiveGateways: z.array(
    z.object({
      hospital: z.object({
        id: z.number(),
        name: z.string(),
      }),
      id: z.string(),
      lastSeenAt: z.string(),
      type: z.enum(['GATEWAY', 'ENROLMENT_READER', 'DISCHARGE_GATEWAY', 'EXIT_GATEWAY']),
      room: z
        .object({
          id: z.number(),
          name: z.string(),
          type: z.string(),
        })
        .nullable()
        .optional(),
    })
  ),
});

type SystemWideRecentlyInactiveGateways = z.infer<typeof SystemWideRecentlyInactiveGateways>;

export default function useSystemWideRecentlyOfflineScreens(): ReturnType<
  typeof useQuery<SystemWideRecentlyInactiveGateways>
> {
  return useValidatedQuery(
    SystemWideRecentlyInactiveGateways,
    gql`
      query systemWideRecentlyInactiveGateways($filter: Filter!) {
        systemWideRecentlyInactiveGateways(filter: $filter) {
          hospital {
            id
            name
          }
          id
          lastSeenAt
          type
          room {
            id
            name
            type
          }
        }
      }
    `,
    {
      variables: {
        filter: {},
      },
    }
  );
}
