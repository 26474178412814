import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment TransitionRule on TransitionRule {
      fromRoom
      toRoom
      timeoutSeconds
    }
  `,
};

export const list = gql`
  query transitionRules {
    transitionRules {
      ...TransitionRule
    }
  }
  ${fragments.all}
`;

export const batchUpdate = gql`
  mutation batchUpdateTransitionRule($transitionRules: [TransitionRuleInput!]!) {
    batchUpdateTransitionRule(transitionRules: $transitionRules) {
      ...TransitionRule
    }
  }
  ${fragments.all}
`;

export const update = gql`
  mutation updateTransitionRule($fromRoom: RoomType!, $toRoom: RoomType!, $timeoutSeconds: Int!) {
    updateTransitionRule(from: $fromRoom, to: $toRoom, timeoutSeconds: $timeoutSeconds) {
      ...TransitionRule
    }
  }
  ${fragments.all}
`;

export const create = gql`
  mutation createTransitionRule($fromRoom: RoomType!, $toRoom: RoomType!, $timeoutSeconds: Int!) {
    createTransitionRule(fromRoom: $fromRoom, toRoom: $toRoom, timeoutSeconds: $timeoutSeconds) {
      ...TransitionRule
    }
  }
  ${fragments.all}
`;

export const remove = gql`
  mutation removeTransitionRule($fromRoom: RoomType!, $toRoom: RoomType!) {
    removeTransitionRule(fromRoom: $fromRoom, toRoom: $toRoom)
  }
`;

export default {
  list,
  batchUpdate,
  create,
  update,
  remove,
};
