import React, { ComponentClass, FunctionComponent, MouseEvent, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MessageIcon from '@material-ui/icons/Message';
import { Badge } from '@material-ui/core';

interface DialogPropsLike {
  open: boolean;
  handleClose: () => void;
}

interface ChatButtonProps<DialogProps extends DialogPropsLike> {
  hasUnreadMessages: boolean;
  chatComponent: FunctionComponent<DialogProps> | ComponentClass<DialogProps>;
  chatProps: Omit<DialogProps, keyof DialogPropsLike>;
}

function ChatButton<DialogProps extends DialogPropsLike>({
  hasUnreadMessages,
  chatComponent: Chat,
  chatProps,
}: ChatButtonProps<DialogProps>) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = (e: MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dialogProps = { ...chatProps, open, handleClose } as DialogProps;

  return (
    <>
      <Badge color="secondary" badgeContent={1} invisible={!hasUnreadMessages}>
        <IconButton color="primary" size="small" edge="end" aria-label="delete" onClick={handleClickOpen}>
          <MessageIcon fontSize="large" />
        </IconButton>
      </Badge>
      <Chat {...dialogProps} />
    </>
  );
}

export default ChatButton;
