import { graphql } from '@apollo/client/react/hoc';
import React, { Fragment } from 'react';
import { compose, mapProps } from 'recompose';
import MultiSelectInput from '../../../se/components/inputs/MultiSelectInput';
import { organizationUserOptions } from '../../../graph/users';
import { defaultTo } from 'lodash';

const toSelectOptions = (data, options = []) => (data ? data.map(id => options.find(_ => _.value === id)) : data);
const fromSelectOptions = data => (data ? data.map(({ value }) => value) : data);

const options = ({ data: { loading, users }, ...rest }) => ({
  options: defaultTo(users, []).map(({ id, name, email }) => ({
    label: `${name} <${email}>`,
    value: id,
  })),
  loading,
  ...rest,
});

const ReportRecipients = ({ name = 'reportRecipients', value, options, onChange, loading, disabled }) => {
  const handleChange = value => onChange(fromSelectOptions(value));

  return (
    <Fragment>
      <MultiSelectInput
        name={name}
        options={options}
        onChange={handleChange}
        value={toSelectOptions(value, options)}
        loading={loading}
        disabled={disabled}
      />
    </Fragment>
  );
};

export default compose(graphql(organizationUserOptions), mapProps(options))(ReportRecipients);
