import React from 'react';
import styles from '../../styles';
import MedicalPassportField from '../../components/MedicalPassportField';
import MedicalPassportTextArea from '../../components/MedicalPassportTextArea';
import MedicalPassportRow from '../../components/MedicalPassportRow';
import {
  cardiovascular,
  endocrine,
  gastrointestinal,
  gynaecological,
  hematological,
  hepatic,
  musculoskeletal,
  neurological,
  other,
  psychiatric,
  pulmonary,
  renal,
} from '../paths';
import toAge, { getAge } from '../../../../../../questionnaire/toAge';
import toBMI, { calculateBMI } from '../../../../../../questionnaire/toBMI';
import toWeight from '../../../../../../questionnaire/toWeight';
import toHeight from '../../../../../../questionnaire/toHeight';
import toSex from '../../../../../../questionnaire/toSex';
import toUsageString from '../../../../../../questionnaire/toUsageString';
import toHighlightsString from '../../../../../../questionnaire/toHighlightsString';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import { parse } from 'date-fns';

const Page3 = ({ hospitalName, defaultValue }) => {
  const age = getAge(parse(defaultValue?.demographicInformation?.dob, 'MM/DD/YYYY'));
  const bmi = calculateBMI(
    parseInt(defaultValue?.demographicInformation?.bmi?.weightLb, 10),
    parseInt(defaultValue?.demographicInformation?.bmi?.heightFt, 10),
    parseInt(defaultValue?.demographicInformation?.bmi?.heightIn, 10)
  );

  return (
    <div id="page3" className="page" style={{ fontSize: '0.8em' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
          <h2>History & Physical</h2>
          <div>{hospitalName || ''}</div>
        </div>
        <div></div>
      </div>
      <div style={{ border: '1px solid black', borderBottom: 0, borderLeft: 0 }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '55% 45%',
          }}
        >
          <div>
            <div
              style={{
                border: '1px solid black',
                borderTop: 0,
                minHeight: '60px',
                height: '100%',
                borderBottom: 0,
                padding: '0.1rem 0.2rem 0',
              }}
            >
              <MedicalPassportTextArea name="procedure.name" label="Procedure" />
            </div>
          </div>
          <div>
            <div style={{ bottom: '5px', paddingLeft: '3px' }}>
              <div style={{ marginBottom: '0.2rem' }}>
                <div style={{ marginBottom: '0.2rem' }}>
                  <MedicalPassportField
                    name="procedure.patient.name"
                    label="Patient"
                    fontWeight={'bold'}
                    fontSize={16}
                  />
                </div>
                <MedicalPassportField label="Surgical Date" name="procedure.serviceTime" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem' }}>
                <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} />
                <MedicalPassportField name="procedure.serviceTime" label="DOS" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem' }}>
                <MedicalPassportField name="procedure.patient.providerId" label="MRN#" />
                <MedicalPassportField name="procedure.physician.name" label="Surgeon" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.2rem' }}>
                <MedicalPassportField
                  name="age"
                  label="Age"
                  path={['demographicInformation', 'dob']}
                  reducer={toAge}
                  warning={age > 70}
                />
                <MedicalPassportField name="sex" label="Sex" path={['demographicInformation', 'sex']} reducer={toSex} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <MedicalPassportField
                  name="ht"
                  label="Ht"
                  path={['demographicInformation', 'bmi']}
                  reducer={toHeight}
                />
                <MedicalPassportField
                  name="wt"
                  label="Wt"
                  path={['demographicInformation', 'bmi']}
                  reducer={toWeight}
                />
                <MedicalPassportField
                  name="bmi"
                  label="BMI"
                  path={['demographicInformation', 'bmi']}
                  reducer={toBMI}
                  warning={bmi !== '-' ? bmi > 40 : false}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '55% 45%' }}>
          <div style={{ borderLeft: '1px solid black', borderRight: '1px solid black' }}>
            <div
              style={{
                border: '1px solid black',
                padding: '0.2rem 0.1rem',
                borderBottom: 0,
                borderRight: 0,
                borderLeft: 0,
              }}
            >
              <MedicalPassportField name="preOpDiagnosis" label="Preop Diagnosis" />
            </div>
            <div
              style={{
                border: '1px solid black',
                borderBottom: 0,
                borderRight: 0,
                borderLeft: 0,
                lineHeight: '1.6em',
                padding: '0 0.1rem',
              }}
            >
              <MedicalPassportTextArea
                name="smokingPage4"
                label="Smoking"
                paths={[
                  ['basicscreening', 'smoking'],
                  ['basicscreening', 'smoking status'],
                ]}
                reducer={toUsageString}
              />
              <MedicalPassportTextArea
                name="etohPage4"
                label="ETOH"
                paths={[
                  ['basicscreening', 'drinking'],
                  ['basicscreening', 'alcohol abuse'],
                ]}
                reducer={toUsageString}
              />
              <MedicalPassportTextArea
                name="drugHxPage4"
                label="Drug Hx"
                path={['basicscreening', 'drugs']}
                reducer={toUsageString}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={styles.medicationsGrid}>
        <div style={styles.medicationsInnerDiv}>
          <MedicalPassportTextArea
            label="Med Allergies/Sensitivities"
            name="allergies1"
            path={['allergies']}
            reducer={toHighlightsString}
          />
        </div>
        <div style={styles.medicationsInnerDiv}>
          <MedicalPassportTextArea
            label="Current Medications"
            name="medications1"
            path={['medications']}
            reducer={toHighlightsString}
          />
        </div>
        <div style={{ ...styles.medicationsInnerDiv, borderRight: '1px solid black' }}>
          <MedicalPassportTextArea
            label="Surgical/Anesthesia Hx"
            name="anesthesia1"
            path={['priorSurgeries']}
            reducer={toHighlightsString}
          />
        </div>
      </div>
      <div
        style={{
          border: '1px solid black',
          borderTop: '0',
        }}
      >
        <div style={{ ...styles.medicationsInnerDiv, borderRight: '1px solid black' }}>
          <MedicalPassportTextArea
            label="History of Present Illness and Response to Care"
            name="illness1"
            // path={['medicalHistory']}
          />
        </div>
      </div>

      <section style={{ breakInside: 'avoid' }}>
        <div style={styles.greyBackground}>
          <div>Review of Systems</div>
        </div>
        <div
          style={{
            border: '1px solid black',
            lineHeight: '1.8em',
            borderLeft: 0,
            borderRight: 0,
          }}
        >
          <MedicalPassportRow
            label="Cardiovascular"
            name="systems.cardiovascular"
            paths={cardiovascular}
            style={{ borderTop: 0 }}
            index={0}
          />
          <MedicalPassportRow label="Pulmonary" name="systems.pulmonary" paths={pulmonary} index={1} />
          <MedicalPassportRow label="Renal" name="systems.renal" paths={renal} index={2} />
          <MedicalPassportRow label="Hepatic" name="systems.hepatic" paths={hepatic} index={3} />
          <MedicalPassportRow label="Neurological" name="systems.neurological" paths={neurological} index={4} />
          <MedicalPassportRow
            label="Gastrointestinal"
            name="systems.gastrointestinal"
            paths={gastrointestinal}
            index={5}
          />
          <MedicalPassportRow label="Hematological" name="systems.hematological" paths={hematological} index={6} />
          <MedicalPassportRow label="Endocrine/Metabolic" name="systems.endocrine2" paths={endocrine} index={7} />
          <MedicalPassportRow
            label="Musculoskeletal"
            name="systems.musculoskeletal"
            paths={musculoskeletal}
            index={8}
          />
          <MedicalPassportRow label="Psychiatric" name="systems.psychiatric" paths={psychiatric} index={9} />
          <MedicalPassportRow label="OB/Gyn" name="systems.gyn" paths={gynaecological} index={10} />
          <MedicalPassportRow label="Other" name="systems.other" paths={other} index={11} />
        </div>
      </section>

      <section style={{ breakInside: 'avoid' }}>
        <div
          style={{
            ...styles.greyBackground,
            textAlign: 'center',
          }}
        >
          Physical Exam
        </div>
        <div
          style={{
            lineHeight: '1.6em',
            border: '1px solid black',
            borderBottom: 0,
          }}
        >
          <MedicalPassportField name="page3.diagnosis" label="Diagnosis" />
          <MedicalPassportField name="page3.treatmentPlan" label="Treatment Plan" />
        </div>
        <div style={{ border: '1px solid black', padding: '15px 5px', lineHeight: '1.6em' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex' }}>
                <MedicalPassportField name="page3.bp" label="BP" />
              </div>
              <div style={{ display: 'flex' }}>
                <MedicalPassportField name="page3.pulse" label="Pulse" />
              </div>
              <div style={{ display: 'flex' }}>
                <MedicalPassportField name="page3.resp" label="Resp" />
              </div>
              <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                <MedicalPassportField name="page3.O2Sat" label="O2 Sat" />
              </div>
            </div>
            <div style={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center' }}>
              <MedicalPassportCheckbox label="Pre-Op Scrub x 5 min" name="page3.preOpScrub" />
            </div>
          </div>
          <div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 2fr 2fr' }}>
              <div>Mental Status:</div>
              <div>
                <MedicalPassportCheckbox label="A&O x 3" name="page3.mentalStatus.a&o3" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <MedicalPassportCheckbox name="page3.mentalStatus.other" />
                <MedicalPassportField name="page3.mentalStatus.otherText" label="Other" />
              </div>
              <div style={{ paddingLeft: '30%' }}>Preop IV Antibiotic</div>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 2fr 2fr' }}>
              <div>Heart:</div>
              <div>
                <MedicalPassportCheckbox label="WNL" name="page3.heart.wnl" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <MedicalPassportCheckbox name="page3.heart.other" />
                <MedicalPassportField name="page3.heart.otherText" label="Other" />
              </div>
              <div style={{ paddingLeft: '30%' }}>
                <MedicalPassportCheckbox label="Ancef 1gm/2gm" name="page3.antibiotic.ancef" />
              </div>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 2fr 2fr' }}>
              <div>Lungs:</div>
              <div>
                <MedicalPassportCheckbox label="WNL" name="page3.lungs.wnl" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <MedicalPassportCheckbox name="page3.lungs.other" />
                <MedicalPassportField name="page3.lungs.otherText" label="Other" />
              </div>
              <div style={{ paddingLeft: '30%' }}>
                <MedicalPassportCheckbox label="Cleocin 900mg" name="page3.antibiotic.cleocin" />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '4px' }}>
            <div style={{ textDecoration: 'underline', fontWeight: 'bold' }}>ASSESSMENT:</div>
            <div>
              <MedicalPassportCheckbox
                label="History and medical condition reviewed, no changes: pt cleared for procedure"
                name="page3.assessment1"
              />
              <MedicalPassportCheckbox
                label="Medical condition and diagnosis appropriate for planned anesthetic"
                name="page3.assessment2"
              />
              <MedicalPassportCheckbox
                label="I have explained the procedure/surgery including appropriate alternatives, benefits, side effects and risks. I have answered all the patient’s/guardian’s questions. Patient/guardian accepts proposed plan."
                name="page3.assessment3"
              />
            </div>
          </div>
          <div style={{ display: 'flex', gap: '15px' }}>
            <div>Physician Signature: ________________________</div>
            <MedicalPassportField name="page3.date" label="Date/Time" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Page3;
