import styled from 'styled-components';

import createError, { BaseError, basePoseConfig } from './Error';
import { withTheme } from '../../theme';

const poseConfig = {
  ...basePoseConfig,
  enter: {
    height: 'auto',
    opacity: 1,
    transition: {
      duration: 300,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
  exit: {
    ...basePoseConfig.exit,
    height: 0,
    opacity: 0,
  },
};

const InputError = styled(BaseError)`
  font-size: ${withTheme((theme, props) => theme.textSize[props.size || 'xs'] || theme.textSize.xs)};
  font-weight: ${withTheme((theme, props) => theme.fontWeight[props.weight || 'medium'] || theme.textSize.medium)};
  line-height: 2;
  padding: 0;
  padding-left: 0.5rem;
  margin-bottom: 0.125rem;
`;

export default createError(InputError, poseConfig);
