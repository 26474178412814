import React, { createContext, useContext, useMemo } from 'react';
import { ReactNode } from 'react';

interface SliderContext {
  slideTo: (index: number) => void;
}

const sliderContext = createContext<SliderContext | null>(null);

export const useSlider = (): SliderContext | null => useContext(sliderContext);

interface SliderProviderProps {
  id: string;
  slide: number;
  children: ReactNode;
}

const SliderProvider = ({ id, children }: SliderProviderProps) => {
  const slider = useMemo(() => {
    let requestedSlide: number = Number.POSITIVE_INFINITY;
    let timeout: ReturnType<typeof setTimeout>;

    const slideIntoView = (index: number) => () => {
      requestedSlide = Number.POSITIVE_INFINITY;

      const tab = document.getElementById(`action-tab-${id}-${index}`);

      if (!tab) {
        return;
      }

      tab.click();
    };

    return {
      slideTo: (index: number) => {
        if (index < requestedSlide) {
          requestedSlide = index;

          clearTimeout(timeout);

          timeout = setTimeout(slideIntoView(index), 100);
        }
      },
    };
  }, []);

  return <sliderContext.Provider value={slider}>{children}</sliderContext.Provider>;
};

export default SliderProvider;
