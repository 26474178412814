export enum Destination {
  Patient = 'Patient',
  Procedure = 'Procedure',
  Physician = 'Physician',
  Caretaker = 'Caretaker',
  StaffMember = 'StaffMember',
  Anesthesiologist = 'Anesthesiologist',
  Vendor = 'Vendor',
  HiringManager = 'HiringManager',
}
