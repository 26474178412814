import { createContext, useContext } from 'react';
import { QuestionnaireAndAnswers } from '../../../../types/Questionnaire';

export const PacuChartsContext = createContext<QuestionnaireAndAnswers[] | null>(null);

export const usePacuCharts = () => {
  const value = useContext(PacuChartsContext);

  if (value === null) {
    throw new Error('usePacuCharts must be used within a PacuChartsContext');
  }

  return value;
};
