import { compose, withProps } from 'recompose';
import { withLabel } from '../../se/components/Label';
import TextAreaInput from '../../se/components/inputs/TextAreaInput';

export default compose(
  withLabel('Notes'),
  withProps({
    placeholder: 'Administrative information relevant to the other users',
    rows: 6,
    style: { resize: 'none' },
  })
)(TextAreaInput);
