import { ComponentType, createElement, useEffect, useState } from 'react';

export const withToday =
  <TProps extends {}>(component: ComponentType<TProps & { today: Date }>) =>
  (props: TProps) => {
    const today = useToday();

    return createElement<TProps & { today: Date }>(component, { ...props, today });
  };

export const useToday = (): Date => {
  const [today, setToday] = useState(getToday());

  useEffect(() => {
    const tick = () => setToday(getToday());

    let timeout: ReturnType<typeof setTimeout>;

    const schedule = () => {
      const tomorrow = new Date();
      tomorrow.setHours(0, 0, 0, 0);
      tomorrow.setDate(tomorrow.getDate() + 1);

      const now = new Date();

      timeout = setTimeout(() => {
        tick();
        schedule();
      }, tomorrow.getTime() - now.getTime());
    };

    schedule();

    return () => clearTimeout(timeout);
  }, []);

  return today;
};

export const getToday = (): Date => {
  const today = new Date();

  today.setHours(0, 0, 0, 0);

  return today;
};
