export const intermittentReduce = (array, initialAccumulator, step, onProgress, onCompleted) => {
  const length = array.length;

  const progressIncrement = 1 / length;

  let index = 0,
    accumulator = initialAccumulator;

  let timeout = setTimeout(function loop() {
    if (index < length) {
      const item = array[index++];

      accumulator = step(accumulator, item, index);

      onProgress(progressIncrement * index);

      timeout = setTimeout(loop);
    } else {
      onCompleted(accumulator);
    }
  });

  return () => {
    clearTimeout(timeout);
  };
};

export const intermittentMap = (array, step, onProgress, onCompleted) =>
  intermittentReduce(
    array,
    [],
    (acc, item) => {
      acc.push(step(item));
      return acc;
    },
    onProgress,
    onCompleted
  );
