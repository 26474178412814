import React from 'react';
import styled from 'styled-components';
import ModalForm from '../../../ModalForm';
import scheme from '../../../../graph/patients';
import SimpleMutationForm from '../../../SimpleMutationForm';
import TextInput from '../../../../se/components/inputs/TextInput';
import { withLabel } from '../../../../se/components/Label';

const BraceletIdInput = withLabel('Bracelet ID')(styled(TextInput)`
  width: 100%;
  margin-bottom: 2em;
`);

const Input = (value, onChange) => props => <BraceletIdInput {...props} value={value} onChange={onChange} />;

const ChangeBraceletForm = ({ value, onChange, onSubmit }) => (
  <SimpleMutationForm scheme={scheme} Input={Input} value={value} onChange={onChange} onSubmit={onSubmit} />
);

export default class ChangeBracelet extends React.Component {
  render() {
    return (
      <ModalForm
        title="Update Bracelet Id"
        variableName="braceletId"
        entityId={this.props.patientId}
        initialValue={this.props.initialValue}
        onClose={() => this.props.setEditModalOpen({ braceletId: false })}
        scheme={scheme}
        FormComponent={ChangeBraceletForm}
        withRefetch
      />
    );
  }
}
