import React from 'react';
import styled from 'styled-components';
import createError, { BaseError } from './Error';
import { InfoOutlined } from '@material-ui/icons';

const FormError = styled(BaseError)`
  margin-bottom: 1.5rem;
`;

const ErrorFlexbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

ErrorFlexbox.Icon = styled.div`
  margin-right: 12px;
  i {
    font-size: 2em;
  }
`;

export const ErrorMessageWrapper = ({ children }) => (
  <ErrorFlexbox>
    <ErrorFlexbox.Icon>
      <InfoOutlined fontSize={'small'} />
    </ErrorFlexbox.Icon>
    <div>{children}</div>
  </ErrorFlexbox>
);

export default createError(FormError);
