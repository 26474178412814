import React, { createContext, ReactNode, useContext } from 'react';

const accessTokenContext = createContext<string | undefined>(undefined);

export const useAccessToken = () => useContext(accessTokenContext);

interface AccessTokenProps {
  accessToken: string | undefined;
  children: ReactNode;
}

export const AccessToken = ({ accessToken, children }: AccessTokenProps) => {
  return <accessTokenContext.Provider value={accessToken}>{children}</accessTokenContext.Provider>;
};
