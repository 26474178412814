import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Button,
  Checkbox,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField,
  Theme,
  useTheme,
} from '@material-ui/core';
import { CirculationCheckTableV2Answer } from '../../../../types/Answer';
import produce from 'immer';
import { alpha, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { RadioButtonUncheckedTwoTone } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useSave } from './SaveProvider';
import { useSlideIn } from '../../../../form/Form';
import { MaskedTimeTextField } from './PostAnesthesiaRecoveryScoreV3';
import IMask from 'imask';
import Box from '@material-ui/core/Box';

const columns = ['in5_1', 'in5_2', 'in5_3'];

interface CirculationCheckTableProps {
  value?: CirculationCheckTableV2Answer;
  onChange: (value: CirculationCheckTableV2Answer) => void;
}

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    root: {
      '&:nth-of-type(odd)': {
        // backgroundColor: theme.palette.action.hover,
      },
      '&:last-of-type': {
        // backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      borderRight: `2px solid ${theme.palette.divider}`,
      '&:last-of-type': {
        borderRight: 'none',
      },
      width: '200px',
    },
    body: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderRight: `2px solid ${theme.palette.divider}`,
      borderBottom: 'none',
      '&:first-of-type': {
        // borderRight: 0,
      },
      '&:last-of-type': {
        borderRight: 'none',
      },
    },
  }),
)(TableCell);

const CirculationCheckTableV2 = ({ value: answer, onChange }: CirculationCheckTableProps) => {
  const { validationRequest } = useSave();
  const classes = useStyles();
  const slideIn = useSlideIn();

  const value = useMemo(
    () =>
      answer || {
        in5_1: {},
        in5_2: {},
        in5_3: {},
      },
    [answer],
  );

  useEffect(() => {
    if (validationRequest) {
      if (!value) {
        slideIn();
      }
    }
  }, [validationRequest, value, slideIn]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <StyledTableRow className={classes.mainRow}>
            <StyledTableCell>Neurovascular Check</StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>
              <Box display="flex" flexDirection="row" style={{ gap: '0.5em' }}>
                <MaskedTimeTextField
                  value={value?.in5_1?.time || ''}
                  onChange={time => {
                    onChange(
                      produce(value, draft => {
                        draft.in5_1.time = time;
                      }),
                    );
                  }}
                />
              </Box>
            </StyledTableCell>
            <StyledTableCell>
              <Box display="flex" flexDirection="row" style={{ gap: '0.5em' }}>
                <MaskedTimeTextField
                  value={value?.in5_2?.time || ''}
                  onChange={time => {
                    onChange(
                      produce(value, draft => {
                        draft.in5_2.time = time;
                      }),
                    );
                  }}
                />
              </Box>
            </StyledTableCell>
            <StyledTableCell>
              <Box display="flex" flexDirection="row" style={{ gap: '0.5em' }}>
                <MaskedTimeTextField
                  value={value?.in5_3?.time || ''}
                  onChange={time => {
                    onChange(
                      produce(value, draft => {
                        draft.in5_3.time = time;
                      }),
                    );
                  }}
                />
              </Box>
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>

        <TableBody>
          <ColorAspect
            title="Color"
            validationRequest={validationRequest}
            optionLabels={['Normal', 'Capillary Refill < 3', 'Pale', 'Blue/Gray']}
            optionValues={['normal', 'capillaryRefill3', 'pale', 'blueGray']}
            value={{
              in5_1: value?.in5_1?.color,
              in5_2: value?.in5_2?.color,
              in5_3: value?.in5_3?.color,
            }}
            onChange={v =>
              onChange(
                produce(value, draft => {
                  draft.in5_1.color = v.in5_1;
                  draft.in5_2.color = v.in5_2;
                  draft.in5_3.color = v.in5_3;
                }),
              )
            }
          />
          <SensoryAspect
            title="Sensory"
            validationRequest={validationRequest}
            optionLabels={['Normal', 'Decreased', 'Tingling', 'Numb', 'Absent']}
            optionValues={['normal', 'decreased', 'tingling', 'numb', 'absent']}
            value={{
              in5_1: value?.in5_1?.sensory,
              in5_2: value?.in5_2?.sensory,
              in5_3: value?.in5_3?.sensory,
            }}
            onChange={v =>
              onChange(
                produce(value, draft => {
                  draft.in5_1.sensory = v.in5_1;
                  draft.in5_2.sensory = v.in5_2;
                  draft.in5_3.sensory = v.in5_3;
                }),
              )
            }
          />
          <TemperatureAspect
            title="Temp"
            validationRequest={validationRequest}
            optionLabels={['Warm/Normal', 'Cold', 'Hot', 'Edema Y/N']}
            optionValues={['warmNormal', 'cold', 'hot', 'edemaYN']}
            value={{
              in5_1: value?.in5_1?.temperature,
              in5_2: value?.in5_2?.temperature,
              in5_3: value?.in5_3?.temperature,
            }}
            onChange={v =>
              onChange(
                produce(value, draft => {
                  draft.in5_1.temperature = v.in5_1;
                  draft.in5_2.temperature = v.in5_2;
                  draft.in5_3.temperature = v.in5_3;
                }),
              )
            }
          />
          <MovementAspect
            title="Movement"
            validationRequest={validationRequest}
            optionLabels={['Strong/Normal', 'Decreased', 'Absent']}
            optionValues={['strongNormal', 'decreased', 'absent']}
            value={{
              in5_1: value?.in5_1?.movement,
              in5_2: value?.in5_2?.movement,
              in5_3: value?.in5_3?.movement,
            }}
            onChange={v =>
              onChange(
                produce(value, draft => {
                  draft.in5_1.movement = v.in5_1;
                  draft.in5_2.movement = v.in5_2;
                  draft.in5_3.movement = v.in5_3;
                }),
              )
            }
          />
          <PulseAspect
            title="Pulse"
            validationRequest={validationRequest}
            optionLabels={['+1', '+2', '+3', 'Absent']}
            optionValues={['+1', '+2', '+3', 'absent']}
            value={{
              in5_1: value?.in5_1?.pulse,
              in5_2: value?.in5_2?.pulse,
              in5_3: value?.in5_3?.pulse,
            }}
            onChange={v =>
              onChange(
                produce(value, draft => {
                  draft.in5_1.pulse = v.in5_1;
                  draft.in5_2.pulse = v.in5_2;
                  draft.in5_3.pulse = v.in5_3;
                }),
              )
            }
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles(theme => ({
  mainRow: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}));

export default CirculationCheckTableV2;

type TemperatureEnum = 'warmNormal' | 'cold' | 'hot' | 'edemaYN';

interface TemperatureAspectValue {
  in5_1?: TemperatureEnum;
  in5_2?: TemperatureEnum;
  in5_3?: TemperatureEnum;
}

interface TemperatureAspectProps {
  validationRequest: number;
  title: string;
  optionLabels: string[];
  optionValues: TemperatureEnum[];
  value: TemperatureAspectValue;
  onChange: (value: TemperatureAspectValue) => void;
}

const TemperatureAspect = ({
                             title,
                             optionLabels,
                             optionValues,
                             value,
                             onChange,
                             validationRequest,
                           }: TemperatureAspectProps) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      {optionLabels.map((_, index) => (
        <StyledTableRow className={clsx({ [classes.mainRow]: index + 1 === optionLabels.length })}>
          {index === 0 && (
            <StyledTableCell
              padding="none"
              rowSpan={optionLabels.length}
              style={{
                color: 'inherit',
              }}
            >
              {title}
            </StyledTableCell>
          )}
          <StyledTableCell padding="none">{optionLabels?.[index] || ''}</StyledTableCell>

          {columns.map(column => (
            <BlueCheckbox
              checked={value[column] === optionValues[index]}
              onChange={() =>
                onChange(
                  produce(value, draft => {
                    draft[column] = draft[column] === optionValues[index] ? undefined : optionValues[index];
                  }),
                )
              }
            />
          ))}
        </StyledTableRow>
      ))}
    </>
  );
};

type MovementEnum = 'strongNormal' | 'decreased' | 'absent';

interface MovementAspectValue {
  in5_1?: MovementEnum;
  in5_2?: MovementEnum;
  in5_3?: MovementEnum;
}

interface MovementAspectProps {
  validationRequest: number;
  title: string;
  optionLabels: string[];
  optionValues: MovementEnum[];
  value: MovementAspectValue;
  onChange: (value: MovementAspectValue) => void;
}

const MovementAspect = ({
                          title,
                          optionLabels,
                          optionValues,
                          value,
                          onChange,
                          validationRequest,
                        }: MovementAspectProps) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      {optionLabels.map((_, index) => (
        <StyledTableRow className={clsx({ [classes.mainRow]: index + 1 === optionLabels.length })}>
          {index === 0 && (
            <StyledTableCell
              padding="none"
              rowSpan={optionLabels.length}
              style={{
                color: 'inherit',
              }}
            >
              {title}
            </StyledTableCell>
          )}
          <StyledTableCell padding="none">{optionLabels?.[index] || ''}</StyledTableCell>

          {columns.map(column => (
            <BlueCheckbox
              checked={value[column] === optionValues[index]}
              onChange={() =>
                onChange(
                  produce(value, draft => {
                    draft[column] = draft[column] === optionValues[index] ? undefined : optionValues[index];
                  }),
                )
              }
            />
          ))}
        </StyledTableRow>
      ))}
    </>
  );
};

type PulseEnum = '+1' | '+2' | '+3' | 'absent';

interface PulseAspectValue {
  in5_1?: PulseEnum;
  in5_2?: PulseEnum;
  in5_3?: PulseEnum;
}

interface PulseAspectProps {
  validationRequest: number;
  title: string;
  optionLabels: string[];
  optionValues: PulseEnum[];
  value: PulseAspectValue;
  onChange: (value: PulseAspectValue) => void;
}

const PulseAspect = ({
                       title,
                       optionLabels,
                       optionValues,
                       value,
                       onChange,
                       validationRequest,
                     }: PulseAspectProps) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      {optionLabels.map((_, index) => (
        <StyledTableRow className={clsx({ [classes.mainRow]: index + 1 === optionLabels.length })}>
          {index === 0 && (
            <StyledTableCell
              padding="none"
              rowSpan={optionLabels.length}
              style={{
                color: 'inherit',
              }}
            >
              {title}
            </StyledTableCell>
          )}
          <StyledTableCell padding="none">{optionLabels?.[index] || ''}</StyledTableCell>

          {columns.map(column => (
            <BlueCheckbox
              checked={value[column] === optionValues[index]}
              onChange={() =>
                onChange(
                  produce(value, draft => {
                    draft[column] = draft[column] === optionValues[index] ? undefined : optionValues[index];
                  }),
                )
              }
            />
          ))}
        </StyledTableRow>
      ))}
    </>
  );
};

type ColorEnum = 'normal' | 'capillaryRefill3' | 'pale' | 'blueGray';

interface ColorAspectValue {
  in5_1?: ColorEnum;
  in5_2?: ColorEnum;
  in5_3?: ColorEnum;
}

interface ColorAspectProps {
  validationRequest: number;
  title: string;
  optionLabels: string[];
  optionValues: ColorEnum[];
  value: ColorAspectValue;
  onChange: (value: ColorAspectValue) => void;
}

const ColorAspect = ({ title, optionLabels, optionValues, value, onChange, validationRequest }: ColorAspectProps) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      {optionLabels.map((_, index) => (
        <StyledTableRow className={clsx({ [classes.mainRow]: index + 1 === optionLabels.length })}>
          {index === 0 && (
            <StyledTableCell
              padding="none"
              rowSpan={optionLabels.length}
              style={{
                color: 'inherit',
              }}
            >
              {title}
            </StyledTableCell>
          )}
          <StyledTableCell padding="none">{optionLabels?.[index] || ''}</StyledTableCell>

          {columns.map(column => (
            <BlueCheckbox
              checked={value[column] === optionValues[index]}
              onChange={() =>
                onChange(
                  produce(value, draft => {
                    draft[column] = draft[column] === optionValues[index] ? undefined : optionValues[index];
                  }),
                )
              }
            />
          ))}
        </StyledTableRow>
      ))}
    </>
  );
};

type SensoryEnum = 'normal' | 'decreased' | 'tingling' | 'numb' | 'absent';

interface SensoryAspectValue {
  in5_1?: SensoryEnum;
  in5_2?: SensoryEnum;
  in5_3?: SensoryEnum;
}

interface SensoryAspectProps {
  validationRequest: number;
  title: string;
  optionLabels: string[];
  optionValues: SensoryEnum[];
  value: SensoryAspectValue;
  onChange: (value: SensoryAspectValue) => void;
}

const SensoryAspect = ({
                         title,
                         optionLabels,
                         optionValues,
                         value,
                         onChange,
                         validationRequest,
                       }: SensoryAspectProps) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      {optionLabels.map((_, index) => (
        <StyledTableRow className={clsx({ [classes.mainRow]: index + 1 === optionLabels.length })}>
          {index === 0 && (
            <StyledTableCell
              padding="none"
              rowSpan={optionLabels.length}
              style={{
                color: 'inherit',
              }}
            >
              {title}
            </StyledTableCell>
          )}
          <StyledTableCell padding="none">{optionLabels?.[index] || ''}</StyledTableCell>

          {columns.map(column => (
            <BlueCheckbox
              checked={value[column] === optionValues[index]}
              onChange={() =>
                onChange(
                  produce(value, draft => {
                    draft[column] = draft[column] === optionValues[index] ? undefined : optionValues[index];
                  }),
                )
              }
            />
          ))}
        </StyledTableRow>
      ))}
    </>
  );
};

const BlueCheckbox = withStyles({
  root: {
    color: 'rgba(0, 0, 0, 0.54)',
    '&$checked': {
      color: 'rgb(25, 118, 210)',
    },
  },
  checked: {},
})(props => (
  <StyledTableCell style={{ textAlign: 'center' }}>
    <Checkbox
      icon={<RadioButtonUncheckedTwoTone color="primary"/>}
      checkedIcon={<CheckCircleIcon color="primary"/>}
      {...props}
      style={{ padding: 0 }}
    />
  </StyledTableCell>
));

interface SelectionProps {
  selected: boolean;
  onClick: () => void;
  children: ReactNode;
}

const Selection = ({ selected, onClick, children }: SelectionProps) => (
  <StyledTableCell>
    <Button
      disableElevation
      variant={selected ? 'contained' : 'text'}
      color={selected ? 'primary' : 'default'}
      size="small"
      onClick={onClick}
    >
      {children}
    </Button>
  </StyledTableCell>
);
