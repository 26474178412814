import { gql } from '@apollo/client';

export const fragments = {
  m: gql`
    fragment SignalLogData on SignalLog {
      id
      gatewayId
      braceletId
      signalStrength
      receivedAt
    }
  `,
  all: gql`
    fragment RoomDataAll on Room {
      id
      gatewayId
      braceletId
      signalStrength
      receivedAt
      beaconTimestamp
      gatewayTimestamp
    }
  `,
};

export const signalLogForPatient = gql`
  query signalLogForPatient($patientId: Long!) {
    signalLogForPatient(patientId: $patientId) {
      ...SignalLogData
    }

    allGateways {
      id
      room {
        name
      }
      threshold
    }
  }
  ${fragments.m}
`;

export const signalProcessing = {
  item: gql`
    query gwConfig {
      signalProcessingConfig {
        id
        swPeriod
        useWattAveraging
        useAveragingStage
        minimumSamples
      }
    }
  `,
  setSlidingWindowPeriod: gql`
    mutation setSlidingWindowPeriod($swPeriod: Long!) {
      setSlidingWindowPeriod(swPeriod: $swPeriod) {
        id
        swPeriod
        useWattAveraging
        useAveragingStage
        minimumSamples
      }
    }
  `,
  setUseWattAveraging: gql`
    mutation setUseWattAveraging($useWattAveraging: Boolean!) {
      setUseWattAveraging(useWattAveraging: $useWattAveraging) {
        id
        swPeriod
        useWattAveraging
        useAveragingStage
        minimumSamples
      }
    }
  `,
  setUseAveragingStage: gql`
    mutation setUseAveragingStage($useAveragingStage: Boolean!) {
      setUseAveragingStage(useAveragingStage: $useAveragingStage) {
        id
        swPeriod
        useWattAveraging
        minimumSamples
        useAveragingStage
      }
    }
  `,
  setMinimumSamples: gql`
    mutation setMinimumSamples($minimumSamples: Int!) {
      setMinimumSamples(minimumSamples: $minimumSamples) {
        id
        swPeriod
        useWattAveraging
        minimumSamples
        useAveragingStage
      }
    }
  `,
};
