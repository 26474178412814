import React, {
  CSSProperties,
  Dispatch,
  HTMLInputTypeAttribute,
  Ref,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Box from '@material-ui/core/Box';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';
import { format, parse } from 'date-fns';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Slider from '@material-ui/core/Slider';
import { alpha, withStyles } from '@material-ui/core/styles';
import DataItem from './components/DataItem';
import orderBy from 'lodash/orderBy';
import styled from 'styled-components';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';
import isFunction from 'lodash/isFunction';
import { clamp, sortedIndexBy } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import { Edit, TableChartOutlined, TimelineRounded } from '@material-ui/icons';
import ChartListViewV1 from './ChartListViewV1';
import ChartButtons from './ChartButtons';
import clsx from 'clsx';
import IMask from 'imask';
import { ToggleButtonGroup } from '@material-ui/lab';
import ToggleButton from '@material-ui/lab/ToggleButton';
import MeasurementInput, { MeasurementInputControl } from './MeasurmentInput';
import useDebounced from '../../../../util/useDebounced';
import ChartListViewV2 from './ChartListViewV2';

export interface MeasurementsV2 {
  key: string;
  o2therapy?: number;
  o2Sat?: number;
  sys?: number;
  dia?: number;
  bpm?: number;
  resp?: number;
  painLevel?: number;
  csm?: number;
  extremityPulses?: number;
  temp?: number;
  meds?: {
    medsName: string;
  };
  timestamp?: number;
  timestampString?: string;
}

const CustomSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
    '&$vertical': {
      width: 8,
    },
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: 'currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover': {
      boxShadow: '0px 0px 0px 8px rgba(84, 199, 97, 0.16)',
    },
    '&$active': {
      boxShadow: '0px 0px 0px 12px rgba(84, 199, 97, 0.16)',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  vertical: {
    '& $rail': {
      width: 8,
    },
    '& $track': {
      width: 8,
    },
    '& $thumb': {
      marginLeft: -8,
      marginBottom: -11,
    },
  },
})(Slider);

const TimestampValueInput = styled.input<{ highlight?: boolean; selectOnFocus?: boolean }>`
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  background: transparent;
  white-space: nowrap;
  color: inherit;
  text-align: center;

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

interface EditableProps {
  value: number;
  onChange: (v: number) => void;
  inputRef: any;
  style?: CSSProperties;
  type?: HTMLInputTypeAttribute;
  selectOnFocus?: boolean;
  highlightOnFocus?: boolean;
  numOfDigits?: number;
}

export const Editable = ({
  inputRef,
  value,
  onChange,
  style,
  type = 'text',
  selectOnFocus,
  highlightOnFocus,
  numOfDigits,
}: EditableProps) => {
  const classes = useEditableStyles();

  useEffect(() => {
    if (!inputRef?.current && numOfDigits) {
      return;
    }

    const mask = IMask(inputRef?.current, {
      mask: '0'.repeat(numOfDigits || 2),
    });

    return () => mask.destroy();
  }, [inputRef?.current]);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setTimeout(() => {
      e.target.select();
    });
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!'0123456789'.includes(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
      e.preventDefault();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);

    if (!isFinite(newValue)) {
      e.preventDefault();
      return;
    }

    onChange(newValue);
  };

  return (
    <Box display="grid" className={classes.container}>
      <input
        ref={inputRef}
        draggable={false}
        type={type || 'text'}
        pattern="[0-9]*"
        inputMode="numeric"
        value={value || undefined}
        placeholder={'0'.repeat(numOfDigits || 2)}
        onFocus={selectOnFocus ? handleFocus : undefined}
        // onFocus={handleFocus}
        // onMouseUp={handleMouseUp}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        className={clsx(classes.input, { [classes.highlightOnFocus]: highlightOnFocus })}
        style={style}
      />
      {highlightOnFocus && <div className={classes.highlight} />}
      <span className={classes.span}>{value}</span>
    </Box>
  );
};

const useEditableStyles = makeStyles(theme => ({
  input: {
    fontSize: 'inherit',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    color: 'inherit',
    background: 'inherit',
    padding: 0,
    margin: 0,
    border: 'none',
    outline: 'none',
    gridRow: '1 / span 1',
    gridColumn: '1 / span 1',
    width: '100%',
    '&::placeholder': {
      color: alpha(theme.palette.primary.main, 0.3),
    },
  },
  span: {
    gridRow: '1 / span 1',
    gridColumn: '1 / span 1',
    pointerEvents: 'none',
    visibility: 'hidden',
  },
  container: {
    position: 'relative',
  },
  highlight: {
    display: 'none',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'white',
    zIndex: -1,
    margin: '-8px',
  },
  highlightOnFocus: {
    '&:focus + div': {
      display: 'block',
    },
  },
}));

interface TimeEditableProps {
  value: string;
  onChange: (v: string) => void;
  onComplete: () => void;
  disabled?: boolean;
  inputRef: any;
  selectOnFocus?: boolean;
}

export const TimeEditable = ({ inputRef, value, onChange, onComplete, disabled, selectOnFocus }: TimeEditableProps) => {
  return (
    <Box position="relative">
      <TimestampValueInput
        ref={inputRef}
        type="time"
        value={value}
        onChange={e => {
          onChange(e?.target?.value);
        }}
        disabled={disabled}
        selectOnFocus={selectOnFocus}
      />
      <Box position="absolute" top={0} bottom={0} left={0} right={0} style={{ opacity: 0.5 }}>
        <MeasurementInput
          format="MilitaryTime"
          preFill
          value={value}
          onChange={onChange}
          onComplete={onComplete}
          disabled={disabled}
        />
      </Box>
    </Box>
  );
};

interface ChartFormProps {
  value: MeasurementsV2;
  onChange: Dispatch<SetStateAction<MeasurementsV2>>;
  disabled?: boolean;
  children: React.ReactNode;
}

const ChartForm = ({ value, onChange, disabled, children }: ChartFormProps) => {
  const classes = useStyles();
  const originalDate = useMemo(() => new Date(Number(value.timestamp)), []);

  const timeRef = useRef<MeasurementInputControl>();
  const bpRef = useRef<MeasurementInputControl>();
  const bpmRef = useRef<MeasurementInputControl>();
  const respRef = useRef<MeasurementInputControl>();
  const o2therapyRef = useRef<MeasurementInputControl>();
  const tempRef = useRef<MeasurementInputControl>();

  const onSliderChange = (field: string) => (_: React.ChangeEvent<{}>, newValue: number | number[]) =>
    onChange(v => ({ ...v, [field]: newValue } as MeasurementsV2));
  const onTextChange = (field: string) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(v => ({ ...v, [field]: e?.target?.value } as MeasurementsV2));
  };

  // @ts-ignore
  return (
    <Box display="flex" flex={1} flexDirection="row" justifyContent="space-between">
      <Box display="flex" style={{ gap: '3rem', filter: disabled ? 'grayscale(1) opacity(0.25)' : undefined }}>
        <Box display="flex" alignItems="start" style={{ gap: '0.5rem' }}>
          <Box className={classes.row} style={{ gap: '0.5rem' }}>
            <Box className={classes.column} style={{ gap: '1rem' }}>
              <CustomSlider
                orientation="vertical"
                value={value.sys}
                onChange={onSliderChange('sys')}
                min={60}
                max={250}
                style={{ height: '12rem', color: '#000C3F' }}
                disabled={disabled}
              />
            </Box>
            <Box className={classes.column} style={{ gap: '1rem' }}>
              <CustomSlider
                orientation="vertical"
                value={value.dia}
                onChange={onSliderChange('dia')}
                min={40}
                max={200}
                style={{ height: '12rem', color: '#1976D2' }}
                disabled={disabled}
              />
            </Box>
          </Box>
          <Box width="8rem">
            <DataItem
              name="BP"
              value={
                <Box display="flex" style={{ gap: 6 }}>
                  <MeasurementInput
                    ref={bpRef as Ref<MeasurementInputControl>}
                    format="BloodPressure"
                    autoFocus
                    selectOnFocus
                    value={value.sys || value.dia ? `${value.sys ?? ''}/${value.dia ?? ''}` : ''}
                    onChange={bp => {
                      const [sys, dia] = bp.split('/').map(e => parseInt(e, 10));

                      onChange(
                        v =>
                          ({
                            ...v,
                            sys,
                            dia,
                          } as MeasurementsV2)
                      );
                    }}
                    onComplete={() => bpmRef.current?.focus()}
                    disabled={disabled}
                  />
                  <Box>
                    <IconButton
                      onClick={() => bpRef.current?.focus()}
                      style={{ visibility: disabled ? 'hidden' : undefined }}
                    >
                      <Edit />
                    </IconButton>
                  </Box>
                </Box>
              }
              unit="mm Hg"
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="start" style={{ gap: '0.5rem' }}>
          <Box className={classes.column} style={{ gap: '1rem' }}>
            <CustomSlider
              orientation="vertical"
              min={20}
              max={200}
              value={value.bpm}
              onChange={onSliderChange('bpm')}
              style={{ height: '12rem', color: '#4B9731' }}
              disabled={disabled}
            />
          </Box>
          <Box width="4rem">
            <DataItem
              name="PULSE"
              value={
                <Box display="flex" style={{ gap: 8 }}>
                  <MeasurementInput
                    ref={bpmRef as Ref<MeasurementInputControl>}
                    format="HeartRate"
                    selectOnFocus
                    value={value.bpm ? String(value.bpm) : ''}
                    onChange={bpm => {
                      onChange(
                        v =>
                          ({
                            ...v,
                            bpm: parseInt(bpm, 10),
                          } as MeasurementsV2)
                      );
                    }}
                    onComplete={() => respRef.current?.focus()}
                    disabled={disabled}
                  />
                  <Box>
                    <IconButton
                      onClick={() => bpmRef.current?.focus()}
                      style={{ visibility: disabled ? 'hidden' : undefined }}
                    >
                      <Edit />
                    </IconButton>
                  </Box>
                </Box>
              }
              unit={null}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="start" style={{ gap: '0.5rem' }}>
          <Box className={classes.column} style={{ gap: '1rem' }}>
            <CustomSlider
              orientation="vertical"
              min={0}
              max={60}
              value={value.resp}
              onChange={onSliderChange('resp')}
              style={{ height: '12rem', color: '#5C5C62' }}
              disabled={disabled}
            />
          </Box>
          <Box width="3rem">
            <DataItem
              name="RESP"
              value={
                <Box display="flex" style={{ gap: 8 }}>
                  <MeasurementInput
                    ref={respRef as Ref<MeasurementInputControl>}
                    format="RespirationRate"
                    selectOnFocus
                    value={value.resp ? String(value.resp) : ''}
                    onChange={bpm => {
                      onChange(
                        v =>
                          ({
                            ...v,
                            resp: parseInt(bpm, 10),
                          } as MeasurementsV2)
                      );
                    }}
                    onComplete={() => o2therapyRef.current?.focus()}
                    disabled={disabled}
                  />
                  <Box>
                    <IconButton
                      onClick={() => respRef.current?.focus()}
                      style={{ visibility: disabled ? 'hidden' : undefined }}
                    >
                      <Edit />
                    </IconButton>
                  </Box>
                </Box>
              }
              unit={null}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="start" style={{ gap: '0.5rem' }}>
          <Box className={classes.column} style={{ gap: '1rem' }}>
            <CustomSlider
              orientation="vertical"
              min={0}
              max={100}
              value={value.o2therapy}
              onChange={onSliderChange('o2')}
              style={{ height: '12rem', color: '#EE4B2B' }}
              disabled={disabled}
            />
          </Box>
          <Box width="3rem">
            <DataItem
              name="O2"
              value={
                <Box display="flex" style={{ gap: 8 }}>
                  <MeasurementInput
                    ref={o2therapyRef as Ref<MeasurementInputControl>}
                    format="O2"
                    selectOnFocus
                    value={value.o2therapy ? String(value.o2therapy) : ''}
                    onChange={bpm => {
                      onChange(
                        v =>
                          ({
                            ...v,
                            o2: parseInt(bpm, 10),
                          } as MeasurementsV2)
                      );
                    }}
                    onComplete={() => tempRef.current?.focus()}
                    disabled={disabled}
                  />
                  <Box>
                    <IconButton
                      onClick={() => o2therapyRef.current?.focus()}
                      style={{ visibility: disabled ? 'hidden' : undefined }}
                    >
                      <Edit />
                    </IconButton>
                  </Box>
                </Box>
              }
              unit={null}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="start" style={{ gap: '0.5rem' }}>
          <Box className={classes.column} style={{ gap: '1rem' }}>
            <CustomSlider
              orientation="vertical"
              min={35}
              max={41}
              step={0.1}
              value={value.temp}
              onChange={onSliderChange('temp')}
              style={{ height: '12rem', color: '#89CFF0' }}
              disabled={disabled}
            />
          </Box>
          <Box width="4.5rem">
            <DataItem
              name="TEMP"
              value={
                <Box display="flex" style={{ gap: 6 }}>
                  <MeasurementInput
                    ref={tempRef as Ref<MeasurementInputControl>}
                    format="TEMP"
                    selectOnFocus
                    value={value.temp ? value.temp.toFixed(1) : ''}
                    onChange={temp => {
                      onChange(v => ({
                        ...v,
                        temp: parseFloat(temp),
                      }));
                    }}
                    onComplete={() => timeRef.current?.focus()}
                    disabled={disabled}
                  />
                  <Box>
                    <IconButton
                      onClick={() => tempRef.current?.focus()}
                      style={{ visibility: disabled ? 'hidden' : undefined }}
                    >
                      <Edit />
                    </IconButton>
                  </Box>
                </Box>
              }
              unit={'°C'}
            />
          </Box>
        </Box>
        <Box width="3rem" paddingLeft="2rem">
          <DataItem
            name="TIME"
            value={
              <Box display="flex" alignItems="start" style={{ gap: 8 }}>
                <MeasurementInput
                  ref={timeRef as Ref<MeasurementInputControl>}
                  format="MilitaryTime"
                  preFill
                  selectOnFocus
                  value={value.timestampString ?? ''}
                  onChange={timestamp => {
                    onChange(
                      v =>
                        ({
                          ...v,
                          ...(timestamp.length === 5
                            ? {
                                timestamp: new Date(
                                  originalDate.toISOString().substring(0, 10) + 'T' + timestamp
                                ).getTime(),
                              }
                            : {}),
                          timestampString: timestamp,
                        } as MeasurementsV2)
                    );
                  }}
                  onComplete={() => timeRef.current?.blur()}
                  disabled={disabled}
                />
                <Box>
                  <IconButton
                    onClick={() => timeRef.current?.focus()}
                    style={{ visibility: disabled ? 'hidden' : undefined }}
                  >
                    <Edit />
                  </IconButton>
                </Box>
              </Box>
            }
            unit={null}
          />
        </Box>
      </Box>
      <Box display="flex">{children}</Box>
    </Box>
  );
};

export interface Medication {
  timestamp: number;
  name: string;
}

export type Value = {
  measurments: MeasurementsV2[];
  signature?: {
    signature: string;
    signedBy: string;
  };
};

interface ChartProps {
  value: Value;
  medications: Medication[] | null | undefined;
  onChange: (val: Value) => void;
}

const ChartV2 = ({ value: { measurments: ms, signature }, medications, onChange }: ChartProps) => {
  const classes = useStyles();
  const [listView, setListView] = useState(true);
  const measurments = ms.filter(m => m.timestamp);
  const [selectedIndex, setSelectedIndex] = useState<number>(NaN);
  const debouncedSelectedIndex = useDebounced(selectedIndex);

  useLayoutEffect(() => {
    if (listView) {
      setSelectedIndex(NaN);
    } else {
      setSelectedIndex(prev => (isNaN(prev) ? measurments.length - 1 : prev));
    }
  }, [listView]);

  const data = useMemo(
    () =>
      measurments.map(entry => {
        if (isFinite(entry.sys ?? NaN)) {
          return { ...entry, dif: entry.sys! - entry.dia! };
        }
        return entry;
      }),
    [measurments, selectedIndex]
  );

  const mouseDownRef = useRef(false);

  const select = (timestamp: number) => {
    const index = measurments.findIndex(m => m.timestamp === timestamp);
    setSelectedIndex(index);
  };

  const handleMouseDown = (state: CategoricalChartState) => {
    mouseDownRef.current = true;
    state && select(Number(state.activeLabel));
  };

  const handleMouseMove = (state: CategoricalChartState) => {
    if (mouseDownRef.current) {
      state && select(Number(state.activeLabel));
    }
  };

  const handleMouseUp = (state: CategoricalChartState) => {
    mouseDownRef.current = false;
    state && select(Number(state.activeLabel));
  };

  const minTimestamp = measurments.length > 0 ? measurments[0].timestamp ?? -Infinity : -Infinity;
  const maxTimestamp = measurments.length > 0 ? measurments[measurments.length - 1].timestamp ?? Infinity : Infinity;

  const getSecondsSinceStartOfDay = date => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    return (date - startOfDay) / 1000;
  };

  const onAdd = () => {
    const currentTime = new Date();
    const timeInSeconds = getSecondsSinceStartOfDay(currentTime);

    const newMeasurements = {
      sys: undefined,
      dia: undefined,
      bpm: undefined,
      resp: undefined,
      o2therapy: undefined,
      o2Sat: undefined,
      painLevel: undefined,
      csm: undefined,
      extremityPulses: undefined,
      temp: undefined,
      timestamp: timeInSeconds,
      timestampString: format(new Date(), 'HH:mm'),
    };
    const newValue = orderBy([...measurments, newMeasurements], ['timestamp'], ['asc']);
    onChange({ measurments: newValue });
    const index = newValue.findIndex(m => m === newMeasurements);
    setSelectedIndex(index >= 0 ? index : NaN);
  };

  const onAddPreFill = () => {
    const currentTime = new Date();
    const timeInSeconds = getSecondsSinceStartOfDay(currentTime);

    const newMeasurements = {
      sys: 120,
      dia: 80,
      bpm: 60,
      resp: 20,
      o2therapy: 60,
      o2Sat: 60,
      painLevel: 2,
      csm: 1,
      extremityPulses: 1,
      temp: 36.0,
      timestamp: timeInSeconds,
      timestampString: format(new Date(), 'HH:mm'),
    };

    const newValue = orderBy([...measurments, newMeasurements], ['timestamp'], ['asc']);
    onChange({ measurments: newValue, signature });
    const index = newValue.findIndex(m => m === newMeasurements);
    setSelectedIndex(index >= 0 ? index : NaN);
  };

  const onDelete = () => {
    const newValue = [...measurments];
    newValue.splice(debouncedSelectedIndex, 1);
    onChange({ measurments: newValue, signature });
    if (newValue.length <= debouncedSelectedIndex) {
      setSelectedIndex(newValue.length > 0 ? newValue.length - 1 : NaN);
    }
  };

  const onUpdate = valueOrUpdater => {
    const prev = measurments;
    const next = [...prev];
    const nextValue = isFunction(valueOrUpdater) ? valueOrUpdater(prev[selectedIndex]) : valueOrUpdater;
    const targetIndex = sortedIndexBy(next, nextValue, 'timestamp');
    if (targetIndex === selectedIndex) {
      next.splice(selectedIndex, 1, nextValue);
    } else if (targetIndex < selectedIndex) {
      next.splice(targetIndex, 0, nextValue);
      next.splice(selectedIndex + 1, 1);
      setSelectedIndex(targetIndex);
    } else {
      next.splice(targetIndex, 0, nextValue);
      next.splice(selectedIndex, 1);
      setSelectedIndex(targetIndex - 1);
    }
    onChange({ measurments: next, signature });
  };

  const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: boolean) => {
    setListView(nextView);
  };

  return (
    <Box className={classes.container}>
      {/*<Box py={2}>*/}
      {/*  <ToggleButtonGroup*/}
      {/*    style={{ position: 'sticky', top: 120 }}*/}
      {/*    orientation="vertical"*/}
      {/*    value={listView}*/}
      {/*    exclusive*/}
      {/*    onChange={handleChange}*/}
      {/*  >*/}
      {/*    <ToggleButton value={true} aria-label="list">*/}
      {/*      <TableChartOutlined />*/}
      {/*    </ToggleButton>*/}
      {/*    <ToggleButton value={false} aria-label="module">*/}
      {/*      <TimelineRounded />*/}
      {/*    </ToggleButton>*/}
      {/*  </ToggleButtonGroup>*/}
      {/*</Box>*/}
      {listView && (
        <>
          <ChartListViewV2
            value={{ measurments, signature }}
            onChange={onUpdate}
            onSignatureChange={onChange}
            selectedIndex={selectedIndex}
            onChangeSelectedIndex={setSelectedIndex}
          />
          <ChartButtons onAdd={onAdd} onDelete={onDelete} />
        </>
      )}
      {/*{!listView && (*/}
      {/*  <Box flex={1} padding="2rem" display="flex" flexDirection="column" alignItems="stretch" style={{ gap: '2rem' }}>*/}
      {/*    <Box display="flex" alignItems="start" paddingLeft="2rem" style={{ gap: '2rem' }}>*/}
      {/*      {isFinite(selectedIndex) && measurments[selectedIndex] ? (*/}
      {/*        <ChartForm value={measurments[selectedIndex]} onChange={onUpdate}>*/}
      {/*          <ChartButtons onAdd={onAddPreFill} onDelete={onDelete} />*/}
      {/*        </ChartForm>*/}
      {/*      ) : (*/}
      {/*        <ChartForm*/}
      {/*          value={{*/}
      {/*            sys: 120,*/}
      {/*            dia: 80,*/}
      {/*            bpm: 60,*/}
      {/*            resp: 20,*/}
      {/*            o2therapy: 60,*/}
      {/*            o2Sat: 60,*/}
      {/*            painLevel: 2,*/}
      {/*            csm: 1,*/}
      {/*            extremityPulses: 1,*/}
      {/*            temp: 36.0,*/}
      {/*            timestamp: new Date().getTime(),*/}
      {/*            timestampString: format(new Date(), 'HH:mm'),*/}
      {/*          }}*/}
      {/*          onChange={() => {}}*/}
      {/*          disabled*/}
      {/*        >*/}
      {/*          <ChartButtons onAdd={onAddPreFill} onDelete={onDelete} />*/}
      {/*        </ChartForm>*/}
      {/*      )}*/}
      {/*    </Box>*/}

      {/*    <Box flex={1} display="flex" alignItems="stretch" style={{ gap: '2rem' }}>*/}
      {/*      <Box flex={1} display="flex" flexDirection="column" alignItems="stretch">*/}
      {/*        <Box flex={1} display="grid">*/}
      {/*          <Box gridRow="1 / span 1" gridColumn="1 / span 1">*/}
      {/*            <ResponsiveContainer height="100%" width="100%">*/}
      {/*              <ComposedChart*/}
      {/*                data={data}*/}
      {/*                margin={{ left: -25 }}*/}
      {/*                onMouseDown={handleMouseDown}*/}
      {/*                onMouseMove={handleMouseMove}*/}
      {/*                onMouseUp={handleMouseUp}*/}
      {/*              >*/}
      {/*                <CartesianGrid stroke="#f5f5f5" />*/}
      {/*                <XAxis*/}
      {/*                  type="number"*/}
      {/*                  dataKey="timestamp"*/}
      {/*                  domain={[minTimestamp, maxTimestamp]}*/}
      {/*                  tickCount={measurments.length}*/}
      {/*                  padding={{ left: 20, right: 20 }}*/}
      {/*                  ticks={measurments.map(e => Number(e.timestamp)).filter(isFinite)}*/}
      {/*                  tickFormatter={time => format(parse(time), 'HH:mm')}*/}
      {/*                />*/}
      {/*                <YAxis*/}
      {/*                  yAxisId="left"*/}
      {/*                  type="number"*/}
      {/*                  domain={[0, 220]}*/}
      {/*                  label={{ color: 'white' }}*/}
      {/*                  padding={{ top: 20 }}*/}
      {/*                  ticks={[20, 40, 60, 80, 100, 120, 140, 160, 180, 200, 220]}*/}
      {/*                />*/}
      {/*                <ZAxis type="number" range={[400]} />*/}
      {/*                /!*<Tooltip content={CustomTooltip} />*!/*/}
      {/*                /!*<Tooltip />*!/*/}
      {/*                {isFinite(selectedIndex) && measurments[selectedIndex] && (*/}
      {/*                  <ReferenceLine*/}
      {/*                    x={Number(measurments[selectedIndex].timestamp)}*/}
      {/*                    yAxisId="left"*/}
      {/*                    strokeWidth={50}*/}
      {/*                    stroke="rgba(0 0 0 / 0.0625)"*/}
      {/*                  />*/}
      {/*                )}*/}
      {/*                <Bar yAxisId="left" stackId="bp" dataKey="dia" barSize={5} fill="transparent" />*/}
      {/*                <Bar*/}
      {/*                  yAxisId="left"*/}
      {/*                  stackId="bp"*/}
      {/*                  dataKey="dif"*/}
      {/*                  barSize={5}*/}
      {/*                  fill="#1976D2"*/}
      {/*                  shape={props => {*/}
      {/*                    const { fill, width, height, x, y } = props;*/}

      {/*                    const arrowAngle = (45 * Math.PI) / 180;*/}
      {/*                    const arrowLength = 10;*/}
      {/*                    const arrowX = Math.sin(arrowAngle) * arrowLength;*/}
      {/*                    const arrowY = Math.cos(arrowAngle) * arrowLength;*/}
      {/*                    const topArrow = [*/}
      {/*                      [x + width / 2 - arrowX, y - arrowY],*/}
      {/*                      [x + width / 2, y],*/}
      {/*                      [x + width / 2 + arrowX, y - arrowY],*/}
      {/*                    ];*/}
      {/*                    const bottomArrow = [*/}
      {/*                      [x + width / 2 - arrowX, y + height + arrowY],*/}
      {/*                      [x + width / 2, y + height],*/}
      {/*                      [x + width / 2 + arrowX, y + height + arrowY],*/}
      {/*                    ];*/}
      {/*                    const topArrowPoints = topArrow.map(point => point.join(',')).join(' ');*/}
      {/*                    const bottomArrowPoints = bottomArrow.map(point => point.join(',')).join(' ');*/}

      {/*                    return (*/}
      {/*                      <g>*/}
      {/*                        <polyline*/}
      {/*                          points={topArrowPoints}*/}
      {/*                          stroke={fill}*/}
      {/*                          strokeWidth={5}*/}
      {/*                          fill="none"*/}
      {/*                          strokeLinecap="round"*/}
      {/*                        />*/}
      {/*                        <polyline*/}
      {/*                          points={bottomArrowPoints}*/}
      {/*                          stroke={fill}*/}
      {/*                          strokeWidth={5}*/}
      {/*                          fill="none"*/}
      {/*                          strokeLinecap="round"*/}
      {/*                        />*/}
      {/*                        <rect {...props} />*/}
      {/*                      </g>*/}
      {/*                    );*/}
      {/*                  }}*/}
      {/*                />*/}
      {/*                <Scatter yAxisId="left" name="Medication" dataKey="meds" fill="#DC004E" shape="triangle" />*/}
      {/*                <Line*/}
      {/*                  connectNulls*/}
      {/*                  yAxisId="left"*/}
      {/*                  type="monotone"*/}
      {/*                  dataKey="bpm"*/}
      {/*                  stroke="#4B9731"*/}
      {/*                  strokeWidth={3}*/}
      {/*                />*/}
      {/*                <Line*/}
      {/*                  connectNulls*/}
      {/*                  yAxisId="left"*/}
      {/*                  type="monotone"*/}
      {/*                  dataKey="resp"*/}
      {/*                  stroke="#979797"*/}
      {/*                  strokeWidth={3}*/}
      {/*                />*/}
      {/*                <Line connectNulls yAxisId="left" type="monotone" dataKey="o2" stroke="#EE4B2B" strokeWidth={3} />*/}
      {/*                <Line*/}
      {/*                  connectNulls*/}
      {/*                  yAxisId="left"*/}
      {/*                  type="monotone"*/}
      {/*                  dataKey="temp"*/}
      {/*                  stroke="#89CFF0"*/}
      {/*                  strokeWidth={3}*/}
      {/*                />*/}
      {/*              </ComposedChart>*/}
      {/*            </ResponsiveContainer>*/}
      {/*          </Box>*/}
      {/*          <Box gridRow="1 / span 1" gridColumn="1 / span 1" style={{ pointerEvents: 'none' }}>*/}
      {/*            {medications === undefined ? null : medications === null ? null : (*/}
      {/*              <ResponsiveContainer width="100%" height="100%">*/}
      {/*                <ScatterChart margin={{ left: -25 }}>*/}
      {/*                  <XAxis*/}
      {/*                    type="number"*/}
      {/*                    dataKey="timestamp"*/}
      {/*                    domain={[minTimestamp, maxTimestamp]}*/}
      {/*                    tickCount={0}*/}
      {/*                    padding={{ left: 20, right: 20 }}*/}
      {/*                    // ticks={value.map(e => Number(e.timestamp)).filter(isFinite)}*/}
      {/*                    tickFormatter={time => format(parse(time), 'HH:mm')}*/}
      {/*                    stroke="transparent"*/}
      {/*                  />*/}
      {/*                  <YAxis*/}
      {/*                    type="number"*/}
      {/*                    domain={[0, 1]}*/}
      {/*                    dataKey="y"*/}
      {/*                    label={{ color: 'transparent' }}*/}
      {/*                    tickCount={0}*/}
      {/*                  />*/}
      {/*                  <Scatter*/}
      {/*                    data={medications*/}
      {/*                      .filter(m => m.timestamp >= minTimestamp && m.timestamp <= maxTimestamp)*/}
      {/*                      .map(m => ({ ...m, y: 0 }))}*/}
      {/*                    fill="#DC004E"*/}
      {/*                    shape="triangle"*/}
      {/*                  />*/}
      {/*                </ScatterChart>*/}
      {/*              </ResponsiveContainer>*/}
      {/*            )}*/}
      {/*          </Box>*/}
      {/*        </Box>*/}
      {/*      </Box>*/}
      {/*    </Box>*/}
      {/*  </Box>*/}
      {/*)}*/}
    </Box>
  );
};
const useStyles = makeStyles(theme => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    flex: 1,
    display: 'flex',
    minHeight: 0,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  maxWidth: {
    width: '100%',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  square: {
    height: '1.5rem',
    width: '1.5rem',
    background: 'black',
    opacity: 0.5,
  },
  triangle: {
    width: 0,
    height: 0,
    borderLeft: '13px solid transparent',
    borderRight: '13px solid transparent',
    borderBottom: '26px solid #DC004E',
    opacity: 0.5,
  },
  numberInput: {
    '&:-webkit-inner-spin-button': {
      opacity: 1,
    },
    '&:-webkit-outer-spin-button': {
      opacity: 1,
    },
  },
  time: {
    fontSize: '3rem',
    width: '6rem',
    height: '5rem',
    background: '#D6EAFF',
    border: '2px solid #1976D2',
    borderRadius: '8px',
    padding: '1rem',
  },
  tooltip: {
    background: 'white',
    padding: '1rem',
    border: '1px solid #979797',
    borderRadius: '4px',
  },
}));
export default ChartV2;
