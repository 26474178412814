import Object from './ObjectInput';
import List from './ListInput';
import Text from './inputs/TextInput';
import DateOfBirth from './inputs/DateOfBirthInput';
import Date from './inputs/DateInput';
import BodyMassIndex from './inputs/BodyMassIndexInput';
import YesNo from './inputs/YesNoInput';
import LongText from './inputs/TextInput';
import OneOf from './inputs/OneOfInput';
import Checkboxes from './inputs/CheckboxesInput';

const components = () => ({
  Object,
  List,
  Text,
  DateOfBirth,
  Date,
  BodyMassIndex,
  YesNo,
  LongText,
  OneOf,
  Checkboxes,
});

export default components;
