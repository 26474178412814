import React, { Fragment, useEffect, useState } from 'react';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { compose } from 'recompose';
import config from '../../../config';

import { BaseError as Error } from '../../../se/components/errors/Error';
import { withSession } from '../../../state/Session';
import { unpackSessionObject } from '../../pages/unpackSessionObject';
import Spinner from '../../../se/components/Spinner';
import { withScope } from '../../../contexts/ScopeContext';

export const UPLOAD_RESOURCE = {
  SCHEDULE: 'schedule',
  PHYSICIAN: 'physician',
};

const Uploader = ({ UploadConfirmation, filePicker, file, session, scope, onCancel, onDone, resource }) => {
  const [{ uploadError, uploadResult, confirmationError }, setState] = useState({});

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const hospitalId = get(scope, 'hospital.id');

    if (!hospitalId) {
      return;
    }

    (async () => {
      try {
        const result = await fetch(
          `${config.apiURL}/${resource}?hospital=${hospitalId}&token=${encodeURIComponent(get(session, 'token'))}`,
          {
            method: 'POST',
            body: file,
            signal,
            headers: {
              'X-Filename': file.name,
            },
          }
        );

        if (Math.floor(result.status / 100) === 2) {
          const body = await result.json();
          setState({ uploadResult: body });
        } else {
          setState({ uploadError: result.statusText });
        }
      } catch (error) {
        setState({ uploadError: error });
      }
    })();

    return () => {
      controller.abort();
    };
  }, [file, scope, session, resource]);

  const handleConfirmation = async (result, urlParameters = '') => {
    try {
      const hospitalId = get(scope, 'hospital.id');

      if (!hospitalId) {
        return;
      }

      if (isArray(result) && result.length === 0) {
        onDone();
        return;
      }

      await fetch(
        `${config.apiURL}/${resource}?hospital=${hospitalId}&token=${encodeURIComponent(
          get(session, 'token')
        )}${urlParameters}`,
        {
          method: 'POST',
          body: file,
          headers: {
            'X-Filename': file.name,
          },
        }
      );

      onDone(result);
    } catch (error) {
      console.error(error);
      setState({ uploadResult, confirmationError: error });
    }
  };

  return uploadError ? (
    <Fragment>
      <Error>Failed to complete the upload.</Error>
    </Fragment>
  ) : uploadResult ? (
    <Fragment>
      {confirmationError && <Error>Unable to confirm the upload.</Error>}
      <UploadConfirmation
        filePicker={filePicker}
        file={file}
        onSubmit={handleConfirmation}
        onCancel={onCancel}
        {...uploadResult}
      />
    </Fragment>
  ) : (
    <Spinner size="2rem" />
  );
};

export default compose(withSession(unpackSessionObject), withScope)(Uploader);
