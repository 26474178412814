import React from 'react';
import PropTypes from 'prop-types';
import titlecase from 'stringcase/lib/titlecase';

import Modal from '../Modal';
import Form from '../Form';

const EntityCreate = ({ entityName, title, backUrlProvider, initialValue, Input, handleCreate, history }) => {
  const handleSubmit = async data => {
    await handleCreate(data);
    history.push(backUrlProvider());
  };

  return (
    <Modal title={title || `New ${titlecase(entityName)}`} closeTo={backUrlProvider()}>
      <Form
        autoFocus
        initialValue={initialValue}
        input={Input}
        label={title || 'Create'}
        onSubmit={handleSubmit}
        context={{
          isCreate: true,
          isEdit: false,
        }}
      />
    </Modal>
  );
};

EntityCreate.propTypes = {
  entityName: PropTypes.string.isRequired,
  backUrlProvider: PropTypes.func.isRequired,
  Input: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default EntityCreate;
