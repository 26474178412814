import React, { createContext, forwardRef, ReactNode, useContext, useImperativeHandle, useState } from 'react';

interface SaveContext {
  forceSave: boolean;
  save: () => void;
  validationRequest: number;
  clearForceSave: () => void;
}

const saveContext = createContext<SaveContext | null>(null);

export const useSave = (): SaveContext => {
  const save = useContext(saveContext);

  if (save === null) {
    throw new Error('SaveProvider not found');
  }

  return save;
};

export interface SaveProviderHandle {
  requestValidation: () => void;
  clearValidationRequest: () => void;
}

interface SaveProviderProps {
  forceSave: boolean;
  onSave: () => void;
  onClearForceSave: () => void;
  children: ReactNode;
}

const SaveProvider = forwardRef<SaveProviderHandle, SaveProviderProps>(
  ({ forceSave, onSave, onClearForceSave, children }, ref) => {
    const [validationRequest, setValidationRequest] = useState(0);

    useImperativeHandle(
      ref,
      () => ({
        requestValidation: () => {
          setValidationRequest(r => r + 1);
        },
        clearValidationRequest: () => {
          setValidationRequest(0);
        },
      }),
      []
    );

    return (
      <saveContext.Provider
        value={{ forceSave, save: onSave, validationRequest: validationRequest, clearForceSave: onClearForceSave }}
      >
        {children}
      </saveContext.Provider>
    );
  }
);

export default SaveProvider;
