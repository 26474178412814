import React from 'react';
import styled from 'styled-components';
import { withDate } from '../../../HospitalInfo';
import { format } from 'date-fns';

const Clock = styled.div`
  display: flex;
  flex-flow: row;
  align-items: baseline;
  justify-content: center;
  font-family: monospace;

  font-size: 5rem;
  font-weight: 600;
  line-height: 1.5;
`;

const HoursMinutes = styled.label`
  padding-right: 0.1em;
`;

const Seconds = styled.label`
  font-size: 0.5em;
`;

const withSeconds = withDate(1000);

const WallClock = ({ date }) => (
  <Clock>
    <HoursMinutes>{format(date, 'HH:mm')}</HoursMinutes>
    <Seconds>{format(date, 'ss')}</Seconds>
  </Clock>
);

export default withSeconds(WallClock);
