import { Mutation } from '@apollo/client/react/components';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import round from 'lodash/round';

import get from 'lodash/get';
import { remove } from '../../../graph/sensors';
import { Text, TruncatedText } from '../../../se/components/typography';
import CollectionList from '../../../se/components/collection/CollectionList';
import responsive from '../../../se/utilities/responsive';
import { sortDate, sortNumber } from '../../../util/sort';
import isFinite from 'lodash/isFinite';
import LinkButton from '../../../se/components/LinkButton';
import SensorConfiguration from './SensorConfiguration';
import Tooltip from '../../Tooltip';
import { distanceFromSeconds } from '../gateway/Gateways';
import { isDefinedAndNotNull } from '../../../se/utilities/check';
import { withRouter } from 'react-router';
import { DeleteOutline, Edit } from '@material-ui/icons';

export const roundFixed = v => (isFinite(v) ? round(v, 1).toFixed(1) : '-');
const MinMax = ({ data: { min, max } }) => <TruncatedText>{`${roundFixed(min)} / ${roundFixed(max)}`}</TruncatedText>;

export const RemoveButton = ({ id }) => (
  <Mutation mutation={remove}>
    {(mutate, { loading }) => (
      <LinkButton danger onClick={() => mutate({ variables: { id } })} disabled={!!loading}>
        <Tooltip content="Remove from list">
          <DeleteOutline fontSize={'small'} />
        </Tooltip>
      </LinkButton>
    )}
  </Mutation>
);

export const ConfigButton = ({ data }) => {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <SensorConfiguration data={data} id={data.id} open={open} setOpen={setOpen} />
      <LinkButton onClick={() => setOpen(true)}>
        <Tooltip content="Edit parameters">
          <Edit />
        </Tooltip>
      </LinkButton>
    </Fragment>
  );
};

export const colorForValue = (value, min, max) => {
  if (!value || value === '-') {
    return undefined;
  } else if ((value <= min && isFinite(min)) || (value >= max && isFinite(max))) {
    return 'red';
  } else {
    return 'lime';
  }
};

export const TEMP_UNIT = {
  Celsius: 'C',
  Fahrenheit: 'F',
};

export const temperatureLabel = unit => {
  if (unit === TEMP_UNIT.Celsius) {
    return '℃';
  } else if (unit === TEMP_UNIT.Fahrenheit) {
    return '℉';
  } else {
    return '';
  }
};

export const currentTempColumn = {
  title: 'Temp',
  lens: data => ({
    value: get(data, 'measurement.temperature.value'),
    unit: get(data, 'measurement.temperature.unit'),
    min: get(data, 'temperatureMin.value'),
    max: get(data, 'temperatureMax.value'),
  }),
  sortFunction: (l, r) => sortNumber(get(l, 'measurement.temperature.value'), get(r, 'measurement.temperature.value')),
  Component: ({ data: { value, unit, min, max } }) => (
    <Text style={{ color: colorForValue(value, min, max) }}>{`${roundFixed(value)} ${temperatureLabel(unit)}`}</Text>
  ),
};

export const currentHumidityColumn = {
  title: 'Humidity',
  lens: data => ({ value: get(data, 'measurement.humidity'), min: data.humidityMin, max: data.humidityMax }),
  sortFunction: (l, r) => sortNumber(get(l, 'measurement.humidity'), get(r, 'measurement.humidity')),
  Component: ({ data: { value, min, max } }) => (
    <Text style={{ color: colorForValue(value, min, max) }}>{`${roundFixed(value)} %`}</Text>
  ),
};

export const tempMinMaxColumn = {
  title: 'T Min/Max',
  lens: data => ({ min: get(data, 'temperatureMin.value'), max: get(data, 'temperatureMax.value') }),
  Component: MinMax,
};

export const humidityMinMaxColumn = {
  title: 'H Min/Max',
  lens: data => ({ min: data.humidityMin, max: data.humidityMax }),
  Component: MinMax,
};

export const columns = [
  {
    title: 'ID',
    lens: data => data.id,
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  {
    title: 'Location',
    lens: data => data.location,
    Component: props => <TruncatedText>{props.data}</TruncatedText>,
  },
  currentTempColumn,
  currentHumidityColumn,
  tempMinMaxColumn,
  humidityMinMaxColumn,
  {
    title: 'Battery',
    lens: data => get(data, 'measurement.battery'),
    sortFunction: (l, r) => sortNumber(get(l, 'measurement.battery'), get(r, 'measurement.battery')),
    Component: ({ data }) => <TruncatedText>{isFinite(data) ? `${data} %` : '-'}</TruncatedText>,
  },
  {
    title: 'Last Sync',
    lens: data => get(data, 'lastSeen'),
    sortFunction: (l, r) => sortDate(l.lastSeen, r.lastSeen),
    Component: ({ data }) => (
      <TruncatedText>{isDefinedAndNotNull(data) ? `${distanceFromSeconds(data)} ago` : '-'}</TruncatedText>
    ),
  },
  {
    title: '',
    lens: data => data,
    Component: ConfigButton,
  },
  {
    title: '',
    lens: data => data,
    Component: ({ data }) => <RemoveButton id={data.id} />,
  },
];

const Container = styled.div`
  ${responsive.md.andSmaller`
    margin-bottom: 7em;
  `}
`;

const SensorList = ({ sensors }) => (
  <Container>
    <CollectionList
      idProvider={item => item.id}
      entityName={'Sensors'}
      columns={columns}
      data={sensors}
      getList={data => data}
      TitleAndActions={() => null}
      history={{}}
      location={{}}
    />
  </Container>
);

export default withRouter(SensorList);
