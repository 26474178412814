import { gql } from '@apollo/client';
import { reviewIntegrationFragments } from './hospitals';

export const fragments = {
  small: gql`
    fragment HospitalDataS on Hospital {
      id
      name
      address
      contact
    }
  `,
  all: gql`
    fragment HospitalData on Hospital {
      id
      name
      address
      contact
      notes
      monitors {
        id
        name
      }
      groupAdmins {
        id
        name
      }
      sessionTimeout
      questionnaireValidMonths
      passwordRefreshDays
      reviewIntegrations {
        google {
          ...ReviewBase
        }
        yelp {
          ...ReviewBase
        }
      }
      timezone {
        id
        name
        offset
      }
      exchangeOnly
      covidScreening {
        id
        question
      }
      locations {
        id
        name
      }
    }
    ${reviewIntegrationFragments.base}
  `,
};

export const list = gql`
  query surgeonOffices {
    surgeonOffices {
      ...HospitalDataS
    }
  }
  ${fragments.small}
`;

export const item = gql`
  query surgeonOffice($id: Long!) {
    surgeonOffice(id: $id) {
      ...HospitalData
    }
  }
  ${fragments.all}
`;

export const create = gql`
  mutation createSurgeonOffice(
    $name: String!
    $address: String
    $contact: String
    $notes: String
    $sessionTimeout: Int!
    $questionnaireValidMonths: Int!
    $passwordRefreshDays: Int
    $reviewIntegrations: ReviewIntegrationsInput
    $timezone: String
    $exchangeOnly: Boolean
  ) {
    createSurgeonOffice(
      name: $name
      address: $address
      contact: $contact
      notes: $notes
      sessionTimeout: $sessionTimeout
      questionnaireValidMonths: $questionnaireValidMonths
      passwordRefreshDays: $passwordRefreshDays
      reviewIntegrations: $reviewIntegrations
      timezone: $timezone
      exchangeOnly: $exchangeOnly
    ) {
      ...HospitalData
    }
  }
  ${fragments.all}
`;

export const update = gql`
  mutation editSurgeonOffice(
    $id: Long!
    $name: String
    $address: String
    $contact: String
    $notes: String
    $sessionTimeout: Int!
    $questionnaireValidMonths: Int!
    $passwordRefreshDays: Int
    $reviewIntegrations: ReviewIntegrationsInput
    $timezone: String
    $exchangeOnly: Boolean
  ) {
    editSurgeonOffice(
      id: $id
      name: $name
      address: $address
      contact: $contact
      notes: $notes
      sessionTimeout: $sessionTimeout
      questionnaireValidMonths: $questionnaireValidMonths
      passwordRefreshDays: $passwordRefreshDays
      reviewIntegrations: $reviewIntegrations
      timezone: $timezone
      exchangeOnly: $exchangeOnly
    ) {
      ...HospitalData
    }
  }
  ${fragments.all}
`;

export const remove = gql`
  mutation deleteSurgeonOffice($id: Long!) {
    deleteSurgeonOffice(id: $id)
  }
`;

export default {
  list,
  item,
  create,
  update,
  remove,
};
