import React, { CSSProperties } from 'react';
import Box from '@material-ui/core/Box';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  noPermission: {
    textAlign: 'center',
    position: 'absolute',
    zIndex: 500,
  },
}));

const NoPermissionText = ({ withText = false, style }: { withText: boolean; style?: CSSProperties }) => {
  const classes = useStyles();
  return (
    <Box className={classes.noPermission} component="p" style={style}>
      <VisibilityOffIcon fontSize="large" color="primary" />
      {withText && <Typography>You don’t have sufficient permissions to view this document.</Typography>}
    </Box>
  );
};

export default NoPermissionText;
