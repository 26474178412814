import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PanelContainer from '../../Panel/PanelContainer';
import responsive, { breakpoint } from '../../../se/utilities/responsive';
import MultiBarChart from './MultiBarChart';
import { groupBy } from 'lodash';
import { roundValue } from '../Analytics';
import { HideControl } from './GraphLayout';

const Value = styled.h2`
  display: flex;
  flex: 1;
  font-size: 2em;
  line-height: 1.8;
  margin: 0;
  margin-bottom: 1.5rem;
  color: white;
  ${responsive.md.andSmaller`
      font-size: 1.2em;
      margin-bottom: 0;
  `};
`;

export const barColors = [
  '#00A7F7',
  '#F55323',
  '#263778',
  '#cecccc',
  '#FFAC00',
  '#7ED321',
  '#F8E71C',
  '#50E3C2',
  '#B057FF',
  '#FF5ABB',
  '#B29377',
];

const LegendEntry = styled.div`
  display: flex;
  flex-direction: row;
  height: 4em;
  align-items: center;
  justify-content: center;
  flex: 1;

  span {
    display: flex;
    align-items: center;
  }

  label {
    margin: 0;
    opacity: 0.4;
  }

  > span + span {
    margin-left: 0.8em;
  }

  ${props =>
    props.isMobile &&
    css`
      margin-bottom: 0.5em;
      margin-top: -1em;
    `}
`;

const Dot = styled.div`
  width: 0.85em;
  height: 0.85em;
  border-radius: 50%;
  margin-right: 0.5em;
  background: ${props => props.color};
`;
const unitValue = (value, unit, digits = 1) => [roundValue(value), unit].join(' ');

export default ({ distribution, barsData, title, ratio, style }) => {
  const labels = groupBy(distribution, 'date');
  const isMobile = window.innerWidth < breakpoint.md;

  const [hidden, setHidden] = useState(window.innerWidth < 500);

  return (
    <PanelContainer title={title} compact style={style}>
      {isMobile ? (
        <LegendEntry isMobile>
          {barsData.map(({ value, unit, title, color }, i) => (
            <span key={`${title}-${i}`}>
              <Dot color={color} />
              <label>{title}</label>
              <Value style={{ marginLeft: '1em', fontSize: '1.1rem' }}>{unitValue(value, unit)}</Value>
            </span>
          ))}
        </LegendEntry>
      ) : (
        <div style={{ display: 'flex' }}>
          <LegendEntry>
            {barsData.map(({ value, unit, title, color }, i) => (
              <span key={`${title}-${i}`}>
                <Dot color={color} />
                <label>{title}</label>
              </span>
            ))}
          </LegendEntry>
        </div>
      )}
      <HideControl hidden={hidden} setHidden={setHidden} />
      {!hidden && <MultiBarChart bars={barsData} value={distribution} labels={labels} ratio={ratio} />}
    </PanelContainer>
  );
};
