import React from 'react';
import Header from './components/Header';
import MedicalPassportTextArea from '../components/MedicalPassportTextArea';
import MedicalPassportField from '../components/MedicalPassportField';
import MedicalPassportCheckbox from '../components/MedicalPassportCheckbox';
import styles from '../styles';
import toAge from '../../../../../questionnaire/toAge';
import toHeight from '../../../../../questionnaire/toHeight';
import toWeight from '../../../../../questionnaire/toWeight';
import toBMI from '../../../../../questionnaire/toBMI';
import toWeightLbs from '../../../../../questionnaire/toWeightLbs';
import toHighlightsString from '../../../../../questionnaire/toHighlightsString';

const Page8 = () => {
  return (
    <div id="page8" className="page">
      <Header pageNumber={8} pageCount={11} />
      <strong>Pre Operative Anesthesia Assessment</strong>
      <div style={styles.twoNotEqualColumns}>
        <div>
        <div style={{...styles.twoNotEqualColumns1, border:'1px solid black'}}>
            <div>Planned Procedure</div>
            <MedicalPassportTextArea name="procedure.name" />
          </div>
          <div style={{...styles.sixEqualColumns, borderTop:'0'}}>
            <MedicalPassportField
              label="Age"
              name="page5.age"
              path={['demographicInformation', 'dob']}
              reducer={toAge}
            />
            <MedicalPassportField
              label="Sex"
              name="page5.sex"
              fontSize=".6rem"
              path={['demographicInformation', 'sex']}
              reducer={toHighlightsString}
            />
            <MedicalPassportField
              label="Ht"
              name="page5.Ht"
              path={['demographicInformation', 'bmi']}
              reducer={toHeight}
            />
            <MedicalPassportField
              label="Wt"
              name="page5.Wt"
              path={['demographicInformation', 'bmi']}
              reducer={toWeightLbs}
            />
            <MedicalPassportField
              label="KG"
              name="page5.KG"
              path={['demographicInformation', 'bmi']}
              reducer={toWeight}
            />
            <MedicalPassportField
              label="BMI"
              name="page5.BMI"
              fontSize={9.5}
              path={['demographicInformation', 'bmi']}
              reducer={toBMI}
            />
          </div>
          <div style={{ fontSize: '.75rem' }}>Printed data provided through online survey:</div>
        </div>
        <div>
          <div
            style={{
              borderTop: '1px solid black',
              borderLeft: '1px solid black',
              borderRight: '1px solid black',
              fontSize: '.8rem',
            }}
          >
            <MedicalPassportField label="Patient Name" name="procedure.patient.name" />
            <MedicalPassportField label="DOB" name="page5.DOB" path={['demographicInformation', 'dob']} />
            <MedicalPassportField label="Physician" name="procedure.physician.name" />
            <MedicalPassportField label="DOS" name="procedure.serviceTime" />
          </div>
        </div>
      </div>
      <div style={styles.greyBackground}>Comments</div>
      <div style={styles.oneColumn}>
        <MedicalPassportTextArea label="" name="comments" />
      </div>
      <div style={styles.twoNotEqualColumnsNoBorder}>
        <div style={{ borderTop: '1px solid black', borderRight: '1px solid black' }}>
          <div style={styles.greyBackground}>
            <div style={{ display: 'flex', paddingRight: '10px' }}>
              ASA
              <MedicalPassportCheckbox name="page8.asa1" label="1" />
              <MedicalPassportCheckbox name="page8.asa2" label="2" />
              <MedicalPassportCheckbox name="page8.asa3" label="3" />
              <MedicalPassportCheckbox name="page8.asa4" label="4" />
            </div>
          </div>
          <div style={{ borderLeft: '1px solid black' }}>
            <br></br>
            <div style={{ display: 'flex' }}>
              *NPO Status Verified?
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <MedicalPassportCheckbox name="page8.NPOYes" label="Yes" />
              <MedicalPassportCheckbox name="page8.NPONo" label="No" />
            </div>
            <br></br>
            <div style={{ display: 'flex', fontSize: '.98rem' }}>
              *Pt. Reassessed Day of Surgery?
              <MedicalPassportCheckbox name="page8.PTYes" label="Yes" />
              <MedicalPassportCheckbox name="page8.PTNo" label="No" />
            </div>
            <br></br>
          </div>
        </div>
        <div style={{ borderTop: '1px solid black', borderRight: '1px solid black' }}>
          <div style={styles.twoNotEqualColumns1}>
            Assessment:
            <div>
              <div style={{ display: 'flex' }}>
                <MedicalPassportCheckbox name="page8.noContraindications" label="No contraindications to anesthesia" />
              </div>
              <div style={{ display: 'flex' }}>
                <MedicalPassportCheckbox name="page8.planOther1" label="Other" />
                <MedicalPassportField label="" name="page8.planOtherField1" />
              </div>
            </div>
          </div>
          <br></br>
          <div style={styles.twoNotEqualColumns1}>
            Anesthetic Plan:
            <div>
              <div style={styles.threeNotEqualColumns}>
                <MedicalPassportCheckbox name="page8.planGA" label="GA" />
                <MedicalPassportCheckbox name="page8.planMAC" label="MAC" />
                <MedicalPassportCheckbox name="page8.planRegionalBlock" label="Regional Block" />
              </div>
              <div style={{ display: 'flex' }}>
                <MedicalPassportCheckbox name="page8.planOther2" label="Other" />
                <MedicalPassportField label="" name="page8.planOtherField2" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ borderBottom: '1px solid black' }}>
        <div style={styles.oneColumn}>
          <div style={{ display: 'flex' }}>
            <strong>Informed consent obtained.</strong>
            &nbsp;&nbsp;&nbsp;
            <MedicalPassportCheckbox
              name="page8.consent"
              label="I have discussed the risks, benefits and alternatives of opiods/nonopioids and the planned anesthesia with the patient and/or family"
            />
          </div>
          <div style={{ display: 'flex' }}>
            <div style={styles.signatureBox1}>
              <div>
                <div style={{ paddingTop: '1.5rem' }}>_________________________ &nbsp;&nbsp;&nbsp;</div>
                <div style={{ textAlign: 'left', fontSize: '.8rem' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  Anesthesiologist
                </div>
              </div>
            </div>
            <div style={styles.signatureBox1}>
              <div>
                <div style={{ paddingTop: '1.5rem' }}>_________________________ &nbsp;&nbsp;&nbsp;</div>
                <div style={{ textAlign: 'left', fontSize: '.8rem' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  CRNA
                </div>
              </div>
            </div>
            <div style={styles.signatureBox1}>
              <div>
                <div style={{ paddingTop: '1.5rem' }}>______________ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div style={{ textAlign: 'left', fontSize: '.8rem' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Date
                </div>
              </div>
            </div>
            <div style={styles.signatureBox1}>
              <div>
                <div style={{ paddingTop: '1.5rem' }}>______________ &nbsp;&nbsp;&nbsp;</div>
                <div style={{ textAlign: 'left', fontSize: '.8rem' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Time
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page8;
