import React, { ReactNode } from 'react';
import TextAnswer from './TextAnswer';
import { YesNoAnswer } from '../../types/Answer';
import isBoolean from 'lodash/isBoolean';
import { YesNoQuestion } from '../../types/Question';
import { YesNoQuestionChildren } from '../Form';
import ReactHtmlParser from 'react-html-parser';

type Props = YesNoQuestion['props'] &
  YesNoQuestionChildren<ReactNode> & {
    answer?: YesNoAnswer;
  };

const YesNoTextAnswer = ({ label, answer, child }: Props) => {
  const value = isBoolean(answer)
    ? answer
      ? 'Yes'
      : 'No'
    : isBoolean(answer?.value)
    ? answer?.value
      ? 'Yes'
      : 'No'
    : '';
  const booleanValue = isBoolean(answer) ? answer : isBoolean(answer?.value) ? answer?.value : undefined;
  return (
    <div>
      <TextAnswer question={ReactHtmlParser(label)} answer={value} />
      {(booleanValue === true || booleanValue === false) && child}
    </div>
  );
};

export default YesNoTextAnswer;
