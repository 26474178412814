import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Backdrop,
  CircularProgress,
  createStyles,
  InputAdornment,
  makeStyles,
  Paper,
  Popper,
  TextField,
  Theme,
} from '@material-ui/core';
import pluralize from 'pluralize';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import Mousetrap, { ExtendedKeyboardEvent } from 'mousetrap';
import { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete/Autocomplete';
import { ValuesType } from 'utility-types';
import { ApolloClient, useApolloClient } from '@apollo/client';
import * as H from 'history';

export function debounce<T extends (...args: any[]) => any>(
  ms: number,
  callback: T
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
  let timer: NodeJS.Timeout | undefined;

  return (...args: Parameters<T>) => {
    if (timer) {
      clearTimeout(timer);
    }
    return new Promise<ReturnType<T>>(resolve => {
      // @ts-ignore
      timer = setTimeout(() => {
        const returnValue = callback(...args) as ReturnType<T>;
        resolve(returnValue);
      }, ms);
    });
  };
}

type SourceState<T> = { data: T[]; loading: boolean; error: any };

export interface Source<T> {
  name: string;
  useSource: (input: string, client: ApolloClient<any>, context?: { [key: string]: any }) => SourceState<T>;
  groupBy?: (option: T, context?: { [key: string]: any }) => string;
  noOptionsText: (input: string) => React.ReactNode;
  renderOption: AutocompleteProps<T, any, any, any>['renderOption'];
  getOptionDisabled?: AutocompleteProps<T, any, any, any>['getOptionDisabled'];
  getOptionLabel?: AutocompleteProps<T, any, any, any>['getOptionLabel'];
  getOptionSelected?: AutocompleteProps<T, any, any, any>['getOptionSelected'];
  onSelect: (option: T, location: H.Location, history: H.History) => void | Promise<void>;
}

type SourceValue<S> = S extends [any, Source<infer T>] ? T : never;
type SourceValues<S> = S extends Array<[any, Source<any>]> ? SourceValue<ValuesType<S>> : never;

interface InlineOmniSearchProps<Src extends [ApolloClient<any>, Source<any>]> extends RouteComponentProps {
  source: Src;
  width?: number;
  context?: { [key: string]: any };
}

interface OmniSearchProps<Sources extends Array<[ApolloClient<any>, Source<any>]>> extends RouteComponentProps {
  sources: Sources;
  index: number;
  open: boolean;
  onClose: () => void | Promise<void>;
}

const SearchPopper = (props: any) => <Popper {...props} placement="bottom-start" />;

const PaperComponent = (props: any) => <Paper {...props} style={{ width: 'fit-content' }} />;

function InlineOmniSearch<Src extends [ApolloClient<any>, Source<any>]>({
  history,
  location,
  source,
  width,
  context,
}: InlineOmniSearchProps<Src>) {
  const classes = useStyles();
  const [input, setInput] = React.useState<string>('');
  const [search, setSearch] = React.useState<string>('');

  const debouncedSetSearch = useMemo(() => debounce(350, setSearch), [setSearch]);

  useEffect(() => {
    const trimmed = input.trim();

    if (trimmed.length >= 3) {
      debouncedSetSearch(trimmed);
    } else {
      debouncedSetSearch('');
    }
  }, [debouncedSetSearch, input]);

  const [apolloClient, originalSource] = source;
  const sourceState: SourceState<any> = originalSource.useSource(search, apolloClient, context);

  const label = `Search`;
  const groupBy = source[1].groupBy;
  const noOptionsText = source[1].noOptionsText(search);
  const data: SourceValue<Src>[] = sourceState.data.map(item => item as SourceValue<Src>);
  const loading = sourceState.loading;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const handleSelect = (optionValue: SourceValue<Src> | null) => {
    if (optionValue) {
      originalSource.onSelect(optionValue, location, history);
    }
  };

  const renderOption = (optionValue: SourceValue<Src>, state: AutocompleteRenderOptionState) => {
    if (optionValue) {
      if (originalSource.renderOption) {
        return originalSource.renderOption(optionValue, state);
      }
    }
    return null;
  };

  const getOptionLabel = (optionValue: SourceValue<Src>) => {
    if (optionValue) {
      if (originalSource.getOptionLabel) {
        return originalSource.getOptionLabel(optionValue);
      }
    }
    return '-';
  };

  return (
    <Autocomplete
      inputValue={input}
      onInputChange={(event, value) => setInput(value)}
      clearOnBlur={false}
      value={null}
      onChange={(event, input) => handleSelect(input)}
      groupBy={groupBy ? entry => groupBy(entry, context) : undefined}
      noOptionsText={noOptionsText}
      renderOption={renderOption}
      options={data ?? []}
      filterOptions={x => x}
      className={classes.searchField}
      loading={loading}
      getOptionLabel={getOptionLabel}
      PaperComponent={PaperComponent}
      PopperComponent={SearchPopper}
      renderInput={({ inputProps, InputProps, ...params }) => (
        <TextField
          ref={InputProps.ref}
          inputProps={inputProps}
          placeholder={label}
          variant="outlined"
          margin="dense"
          onBlur={() => setInput('')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: loading ? (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            ) : undefined,
          }}
          style={{ margin: 0, width: width }}
          {...params}
        />
      )}
    />
  );
}

function OmniSearch<Sources extends Array<[ApolloClient<any>, Source<any>]>>({
  history,
  location,
  sources,
  index,
  open,
  onClose,
}: OmniSearchProps<Sources>) {
  const classes = useStyles();
  const [input, setInput] = React.useState<string>('');
  const [search, setSearch] = React.useState<string>('');
  const [selectedSource, setSelectedSource] = React.useState(0);
  // const handleChangeSelectedSource = (_: any, newSelectedSource: number) => setSelectedSource(newSelectedSource);

  const debouncedSetSearch = useMemo(() => debounce(350, setSearch), [setSearch]);

  useEffect(() => {
    if (input.length >= 3) {
      debouncedSetSearch(input.trim());
    } else {
      debouncedSetSearch('');
    }
  }, [debouncedSetSearch, input]);

  useEffect(() => {
    if (open) {
      setSelectedSource(index);
    }
  }, [open, index]);

  useEffect(() => {
    if (open) {
      setInput('');
    }
  }, [open]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const originalSources = useMemo(() => sources, []);

  useEffect(() => {
    if (
      sources.length !== originalSources.length ||
      sources.some((source, index) => source !== originalSources[index])
    ) {
      console.warn('Property "sources" on OmniSearch should not change. It’s still using initial value.');
    }
  }, [sources, originalSources]);

  const sourceStates: Array<SourceState<any>> = [];

  for (let i = 0, l = originalSources.length; i < l; i++) {
    const [apolloClient, originalSource] = originalSources[i];
    sourceStates.push(originalSource.useSource(search, apolloClient, undefined));
  }

  const label = selectedSource === 0 ? 'Search Anything' : `Search ${pluralize(sources[selectedSource - 1][1].name)}`;
  const groupBy = selectedSource === 0 ? undefined : sources[selectedSource - 1][1].groupBy;
  const noOptionsText =
    selectedSource === 0
      ? search.length > 0
        ? 'No results found.'
        : 'Start typing to search anything.'
      : sources[selectedSource - 1][1].noOptionsText(search);
  const data: SourceValues<Sources>[] =
    selectedSource === 0
      ? sourceStates.flatMap((state, index) =>
          state.data.map(item => [...new Array(index), item] as SourceValues<Sources>)
        )
      : sourceStates[selectedSource - 1].data.map(
          item => [...new Array(selectedSource - 1), item] as SourceValues<Sources>
        );
  const loading = sourceStates.some(state => state.loading);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const handleSelect = (optionValues: SourceValues<Sources> | null) => {
    if (optionValues) {
      for (let i = 0, l = originalSources.length; i < l; i++) {
        const optionValue = optionValues[i];
        if (optionValue) {
          const source = originalSources[i];
          if (source) {
            source[1].onSelect(optionValue, location, history);
            onClose();
          }
        }
      }
    }
  };

  const backdropRef = useRef<HTMLElement>();
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (open) {
      inputRef.current?.focus();
    }
  }, [open, selectedSource]);

  const handleBackdropClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target === backdropRef.current) {
      onClose();
    }
  };

  useEffect(() => {
    if (open) {
      const mousetrap = new Mousetrap(backdropRef.current);
      mousetrap.bind('esc', onClose);
      return () => {
        mousetrap.reset();
      };
    }
  }, [open, onClose]);

  const renderOption = (optionValues: SourceValues<Sources>, state: AutocompleteRenderOptionState) => {
    for (let i = 0, l = originalSources.length; i < l; i++) {
      const optionValue = optionValues[i];
      if (optionValue) {
        const source = originalSources[i];
        if (source && source[1].renderOption) {
          return source[1].renderOption(optionValue, state);
        } else {
          break;
        }
      }
    }
    return null;
  };

  const getOptionLabel = (optionValues: SourceValues<Sources>) => {
    for (let i = 0, l = originalSources.length; i < l; i++) {
      const optionValue = optionValues[i];
      if (optionValue) {
        const source = originalSources[i];
        if (source[1].getOptionLabel) {
          return source[1].getOptionLabel(optionValue);
        } else {
          break;
        }
      }
    }
    return '-';
  };

  return (
    <Backdrop ref={backdropRef} className={classes.backdrop} open={open} onClick={handleBackdropClick}>
      <div className={classes.root}>
        <Autocomplete
          open={open}
          inputValue={input}
          onInputChange={(event, value) => setInput(value)}
          clearOnBlur={false}
          value={null}
          onChange={(event, input) => handleSelect(input)}
          groupBy={groupBy}
          noOptionsText={noOptionsText}
          renderOption={renderOption}
          options={data ?? []}
          filterOptions={x => x}
          loading={loading}
          className={classes.searchField}
          getOptionLabel={getOptionLabel}
          renderInput={({ inputProps, InputProps }) => (
            <TextField
              ref={InputProps.ref}
              inputRef={inputRef}
              inputProps={inputProps}
              label={label}
              variant="filled"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: loading ? (
                  <InputAdornment position="end">
                    <CircularProgress size={20} />
                  </InputAdornment>
                ) : undefined,
              }}
            />
          )}
        />
      </div>
    </Backdrop>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      paddingTop: theme.spacing(20),
      alignItems: 'flex-start',
    },
    root: {
      width: '35rem',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    tab: {
      fontSize: theme.typography.body2.fontSize,
    },
    popper: {
      maxWidth: 'fit-content',
    },
    searchField: {
      width: '35rem',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  })
);

interface Context {
  registerSources: (apolloClient: ApolloClient<any>, ...sources: Source<any>[]) => void | Promise<void>;
  deregisterSources: (...sources: Source<any>[]) => void | Promise<void>;
  openSource: (...sources: Source<any>[]) => void | Promise<void>;
}

const OmniSearchContext = React.createContext<Context>({
  registerSources: () => {},
  deregisterSources: () => {},
  openSource: () => {},
});

export function useOmniSearch() {
  return useContext(OmniSearchContext);
}

export function useSources(...sources: Source<any>[]) {
  const { registerSources, deregisterSources } = useOmniSearch();
  const apolloClient = useApolloClient();

  useEffect(() => {
    registerSources(apolloClient, ...sources);
    return () => {
      deregisterSources(...sources);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerSources, deregisterSources, apolloClient, ...sources]);
}

export const OmniSearchSources: FC<{ sources: Source<any>[] }> = ({ sources }) => {
  useSources(...sources);

  return null;
};

export const OmniSearchProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [sources, setSources] = useState<Array<[ApolloClient<any>, Source<any>]>>([]);
  const [index, setIndex] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [key, setKey] = useState<number>(0);

  useEffect(() => {
    if (sources.length === 0) {
      setOpen(false);
    }
  }, [sources.length]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const registerSources = useCallback((apolloClient: ApolloClient<any>, ...sources: Source<any>[]) => {
    const newSources: Array<[ApolloClient<any>, Source<any>]> = sources.map(source => [apolloClient, source]);
    setKey(prev => prev + 1);
    setSources(prev => [...prev, ...newSources]);
  }, []);

  const deregisterSources = useCallback((...sources: Source<any>[]) => {
    setKey(prev => prev + 1);
    setSources(prev => prev.filter(([, source]) => sources.indexOf(source) < 0));
  }, []);

  const openSource = useCallback(
    (source?: Source<any>) => {
      if (source) {
        const index = sources.findIndex(([, src]) => src === source);
        if (index < 0) {
          console.warn('OmniSearch source not found.', source, sources);
        } else {
          setIndex(index + 1);
          setOpen(true);
        }
      } else {
        setIndex(0);
        setOpen(true);
      }
    },
    [sources]
  );

  const openSourceRef = useRef<Context['openSource']>(openSource);

  useEffect(() => {
    openSourceRef.current = openSource;
  }, [openSource]);

  const context = useMemo(
    () => ({
      registerSources,
      deregisterSources,
      openSource: (source?: Source<any>) => {
        if (source) {
          openSourceRef.current(source);
        }
      },
    }),
    [registerSources, deregisterSources, openSourceRef]
  );

  useEffect(() => {
    const mousetrap = new Mousetrap(document.body);

    mousetrap.bind('/', (e: ExtendedKeyboardEvent) => {
      if (['INPUT', 'TEXTAREA', 'SELECT'].indexOf((e.target as HTMLElement).tagName ?? '') >= 0) {
        return;
      }

      setOpen(wasOpen => {
        if (!wasOpen) {
          e.preventDefault();

          setIndex(0);
        }

        return true;
      });
    });

    return () => {
      mousetrap.reset();
    };
  }, []);

  return (
    <OmniSearchContext.Provider value={context}>
      {children}
      <OmniSearchWithRouter
        key={`OmniSearch-${key}`}
        sources={sources}
        index={index}
        open={open}
        onClose={handleClose}
      />
    </OmniSearchContext.Provider>
  );
};

const OmniSearchWithRouter = withRouter(OmniSearch);

export const InlineOmniSearchWithRouter = withRouter(InlineOmniSearch);

export default OmniSearch;
