import Box from '@material-ui/core/Box';
import { TextField, Typography } from '@material-ui/core';
import ModalWithActions from '../../ModalWithActions';
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';

interface ConfirmCancellationDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onConfirmAction: (input: string) => () => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
  title?: string;
  confirmActionLabel?: string;
  text?: string;
}

const ConfirmCancellationDialog = ({
  open = false,
  setOpen,
  onConfirmAction,
  onCancel,
  title = 'Cancel Procedure',
  confirmActionLabel = 'Confirm Cancellation',
  text = 'Please write below reason for canceling this procedure.',
}: ConfirmCancellationDialogProps) => {
  const [input, setInput] = useState('');

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const handleConfirmAction = () => {
    onConfirmAction(input)();
    setOpen(false);
  };

  const handleCancel = () => {
    onCancel?.();
    setOpen(false);
  };

  return (
    <ModalWithActions
      open={open}
      handleCancel={handleCancel}
      handleConfirmAction={handleConfirmAction}
      confirmActionLabel={confirmActionLabel}
      title={title}
    >
      <Box display="flex" flexDirection="column">
        <Typography color="textSecondary">{text}</Typography>
        <Box mb={2} />
        <TextField multiline rowsMax={4} rows={4} variant="outlined" onChange={onChange} value={input} />
      </Box>
    </ModalWithActions>
  );
};

export default ConfirmCancellationDialog;
