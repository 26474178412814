import React, { useState } from 'react';
import styled from 'styled-components';
import { associateGateway, disassociateGateway } from '../../../graph/gateways';
import graphqlSelectInput from '../../inputs/graphqlSelectInput';
import { hospitalOptions } from '../../../graph/hospitals';
import LinkButton from '../../../se/components/LinkButton';
import { Mutation } from '@apollo/client/react/components';

const Inline = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 20em;
`;

const HospitalSelectInput = graphqlSelectInput({
  entityName: 'Hospital',
  placeholder: 'Hospital',
  graphqlList: hospitalOptions,
});

export default ({ gatewayId, organizationId }) => {
  const [hospital, setHospital] = useState(organizationId);
  const [working, setWorking] = useState(false);

  const onAssociate = action => async () => {
    setWorking(true);
    await action({ variables: { organizationId: parseInt(hospital), id: gatewayId } });
    setWorking(false);
  };

  const onDisassociate = action => async () => {
    setWorking(true);
    await action({ variables: { id: gatewayId } });
    setWorking(false);
  };

  return (
    <Inline>
      <HospitalSelectInput
        value={hospital}
        onChange={setHospital}
        style={{ flex: '1 0 auto', marginRight: '2em' }}
        isDisabled={!!organizationId}
      />
      {!organizationId ? (
        <Mutation mutation={associateGateway}>
          {action => (
            <LinkButton onClick={onAssociate(action)} disabled={working || !hospital}>
              Associate
            </LinkButton>
          )}
        </Mutation>
      ) : (
        <Mutation mutation={disassociateGateway}>
          {action => (
            <LinkButton danger onClick={onDisassociate(action)} disabled={working}>
              Disassociate
            </LinkButton>
          )}
        </Mutation>
      )}
    </Inline>
  );
};
