import config from '../config';

class ServerEventClient {
  // TODO: We should do some type of back-off strategy here
  RECONNECT_TIMEOUT = 4000;

  constructor(onMessage, onError) {
    this.onMessage = onMessage;
    this.onError = onError;
    this.source = null;
    this.queryParams = null;
  }

  open = queryParams => {
    if (this.source !== null) {
      this.close();
    }
    this.queryParams = queryParams;

    const params = Object.keys(queryParams)
      .map(key => `${key}=${encodeURIComponent(queryParams[key])}`)
      .join('&');

    this.source = new EventSource(`${config.apiURL}/identity/session?${params}`);
    this.source.onmessage = this.handleMessage;
    this.source.onerror = this.handleError;
  };

  close = () => {
    if (this.source !== null) {
      this.source.close();
      this.source = null;
    }
  };

  handleMessage = event => {
    if (event.data && this.onMessage) {
      this.onMessage(JSON.parse(event.data) || {});
    }
  };

  handleError = event => {
    switch (event.target.readyState) {
      case EventSource.CONNECTING:
        break;
      case EventSource.CLOSED:
        setTimeout(() => this.open(this.queryParams), this.RECONNECT_TIMEOUT);
        break;
      default:
        break;
    }

    if (event.data && this.onError) {
      this.onError(event);
    }
  };
}

export default ServerEventClient;
