import React from 'react';
import styled from 'styled-components';
import TextInput from '../../se/components/inputs/TextInput';
import { withTheme } from '../../se/theme';

const CheckboxLayout = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  position: relative;

  > input {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
  }

  input + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  input + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
    flex-basis: 1.25rem;
    background: transparent;
    border: 0.125rem solid ${withTheme(theme => theme.border.color.focus.string())};
    border-radius: 0.125rem;
    align-self: baseline;
  }

  // Box hover
  input:hover + label:before {
    background: ${withTheme(theme => theme.border.color.focus.alpha(0.5).string())};
  }

  // Box focus
  input:focus + label:before {
    box-shadow: 0 0 0 0.125rem rgba(0, 0, 0, 0.12);
  }

  // Box checked
  input:checked + label:before {
    background: ${withTheme(theme => theme.border.color.focus.string())};
  }

  // Checkmark
  input:checked + label:after {
    font-family: 'Material Icons';
    content: '\\e5ca';
    font-size: 1.25rem;
    line-height: 1;
    color: ${withTheme(theme => theme.backgroundColor.string())};
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const CheckboxLabel = styled.label`
  position: relative;
  display: flex;
  line-height: 1.375;
  align-items: flex-end;
`;

export default props => (
  <CheckboxLayout {...(props.layoutProps || {})}>
    <TextInput {...props} htmlType="checkbox" />
    <CheckboxLabel htmlFor={props.name}>{props.label}</CheckboxLabel>
  </CheckboxLayout>
);
