import React, { ReactNode, useEffect, useRef, VFC } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CalendarDate, { EmptyCalendarDate } from './CalendarDate';
import Dates from './types/Dates';
import { addDays, format, startOfWeek } from 'date-fns';
import YearMonth from './types/YearMonth';

interface CalendarMonthProps {
  yearMonth: YearMonth;
  renderDate?: (date: Date) => ReactNode;
  renderDateTooltip?: (date: Date) => ReactNode;
  selectedDates: Dates;
  setSelectedDates: (datesOrFn: Dates | ((prev: Dates) => Dates)) => void;
  highlightedDates: Dates;
}

const CalendarMonth: VFC<CalendarMonthProps> = ({
  yearMonth,
  renderDate,
  renderDateTooltip,
  selectedDates,
  setSelectedDates,
  highlightedDates,
}) => {
  const classes = useStyles();
  const myRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      yearMonth.startOfMonth.getTime() ===
      YearMonth.of(new Date().getFullYear(), new Date().getMonth()).startOfMonth.getTime()
    ) {
      myRef?.current?.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={myRef} className={classes.root}>
      <Typography variant="h5">{format(yearMonth.startOfMonth, 'MMM YYYY')}</Typography>
      <div className={classes.calendar}>
        {headers}
        {[...Array(yearMonth.startOfMonth.getDay())].map((_, index) => (
          <EmptyCalendarDate key={index} />
        ))}
        {yearMonth.dates.map((date, index) => (
          <CalendarDate
            key={index}
            date={date}
            renderDate={renderDate}
            renderDateTooltip={renderDateTooltip}
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            highlightedDates={highlightedDates}
          />
        ))}
      </div>
    </div>
  );
};

const headers = [0, 1, 2, 3, 4, 5, 6].map(weekDay => (
  <EmptyCalendarDate key={weekDay}>
    {format(addDays(startOfWeek(format(new Date())), weekDay), 'ddd')}
  </EmptyCalendarDate>
));

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
    marginBottom: '2rem',
  },
  calendar: {
    width: 370,
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
  },
}));

export default CalendarMonth;
