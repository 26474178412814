import React from 'react';
import styled from 'styled-components';
import TextField from './TextField';
import { InnerField, Question } from './Containers';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import renderNode from './renderNode';
import { withTheme } from '../../../../se/theme';

export const getCbValue = value => get(isArray(value) ? value[1] : value, `checkboxes`, {});

export const getCbResponse = value => get(isArray(value) ? value[0] : value, `checkboxes`, {});

export const ifArrayGetSecond = value => (isArray(value) ? value[1] : getCbValue(value));

export const respondedNone = value => get(value, 'progress', 0) > 0 && isEmpty(get(value, 'checkboxes'));

const CheckboxField = styled.div`
  & + div {
    border-top: 1px solid ${withTheme(theme => theme.border.color.default.string())};
  }
`;

const Checkboxes = styled.div`
  > :last-child {
    border-bottom: none !important;
  }
`;

export default ({ checkboxes, question, value = {} }) => (
  <Checkboxes>
    {question && <Question>{question}</Question>}
    {checkboxes.map(checkbox =>
      isArray(checkbox) ? (
        <CheckboxField key={checkbox[0]}>
          <TextField
            label={checkbox[0]}
            value={get(getCbResponse(value), checkbox[0]) ? 'Yes' : respondedNone(value) ? 'No' : ''}
            highlightAnswer={get(checkbox[1], 'important') && get(getCbResponse(value), checkbox[0])}
          />
          <InnerField>
            {get(getCbResponse(value), checkbox[0]) &&
              renderNode(checkbox[1], { value: ifArrayGetSecond(getCbValue(value)[checkbox[0]]) })}
          </InnerField>
        </CheckboxField>
      ) : (
        <div
          key={checkbox}
          style={{ borderBottom: `1px solid ${withTheme(theme => theme.border.color.default.string())}` }}
        >
          <TextField
            label={checkbox}
            value={`${get(getCbValue(value), checkbox) ? 'Yes' : respondedNone(value) ? 'No' : ''}`}
          />
        </div>
      )
    )}
  </Checkboxes>
);
