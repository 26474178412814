import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import { setPatientNotReadyInPOSTOP } from '../../../../graph/patients';

export const withSetPatientNotReadyInPOSTOP = Component => props =>
  (
    <Mutation mutation={setPatientNotReadyInPOSTOP}>
      {setPatientNotReadyInPOSTOP => <Component {...props} setPatientNotReadyInPOSTOP={setPatientNotReadyInPOSTOP} />}
    </Mutation>
  );

export default withSetPatientNotReadyInPOSTOP;
