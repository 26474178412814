import { Theme } from '@material-ui/core';

export default function Chip(theme: Theme) {
  return {
    MuiChip: {
      root: {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightMedium,
      },
      icon: {
        color: theme.palette.type === 'light' ? theme.palette.grey[700] : theme.palette.grey[300],
      },
      outlined: {
        border: `1px solid ${theme.palette.type === 'light' ? theme.palette.divider : '#ffffff1f'}`,
      },
    },
  };
}
