import React from 'react';

import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { getScale } from '../../entities/schedule/util/slider';
import { workingHours } from '../../entities/schedule/util/dummyData';

const totalWorkingHours = workingHours.end - workingHours.start;

const hours = getScale(workingHours.start, totalWorkingHours + 1);

const TimelineGroup = ({ children }) => (
  <Box>
    {children}
    <Box my={0.5}>
      <Grid container spacing={1}>
        <Grid item xs={1} />
        <Grid item xs={11}>
          <Box flex={1} position="relative">
            {hours.map(x => (
              <Box
                key={x}
                position="absolute"
                top={0}
                left={`${((x - workingHours.start) / totalWorkingHours) * 100}%`}
                width="2em"
                ml="-1em"
                textAlign="center"
                fontSize=".65em"
              >
                {x}
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  </Box>
);

export default TimelineGroup;
