import React, { Fragment, useMemo, useState } from 'react';
import set from 'lodash/fp/set';
import sortBy from 'lodash/fp/sortBy';
import mapValues from 'lodash/fp/mapValues';
import isEmpty from 'lodash/fp/isEmpty';
import format from 'date-fns/format';
import styled from 'styled-components';

import Button from '../../../se/components/Button';

import Checkbox from '../CheckboxInput';

const Dates = styled.div`
  margin-bottom: 1.5rem;
`;

const Date = styled.div`
  ${props => (props.selected ? 'background-color: rgba(255,255,255,0.25);' : '')}

  padding: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;

  margin-bottom: 0.5rem;

  display: flex;
  align-items: center;

  > div {
    margin-right: 1rem;
    pointer-events: none;
    user-select: none;
  }

  > div:first-child {
    margin-bottom: 0;
    > input + label:before {
      margin-right: 0;
    }
  }
`;

export const ScheduleUploadConfirmation = ({
  filePicker,
  file,
  proceduresToImport,
  proceduresToImportByDate,
  onSubmit,
  onCancel,
}) => {
  const initialDates = useMemo(() => mapValues(_ => true)(proceduresToImportByDate), [proceduresToImportByDate]);

  const [dates, setDates] = useState(initialDates);

  const entries = sortBy(([date]) => date)(Object.entries(proceduresToImportByDate));

  const onConfirm = () => {
    const result = Object.entries(dates)
      .filter(([date, selected]) => selected)
      .map(([date]) => date);

    const urlParams = `&dates=${encodeURIComponent(JSON.stringify(result))}`;
    onSubmit(result.sort().reverse()[0], urlParams);
  };

  return isEmpty(entries) ? (
    <Fragment>
      <p style={{ marginBottom: '1.5rem' }}>No procedures found.</p>
      {filePicker('Change File')}
    </Fragment>
  ) : (
    <Fragment>
      <p style={{ marginBottom: '1.5rem' }}>
        {proceduresToImport} procedure{proceduresToImport === 1 ? '' : 's'} found.
      </p>
      <Dates>
        {entries.map(([date, count]) => (
          <Date key={date} selected={dates[date]} onClick={() => setDates(set(date, !dates[date])(dates))}>
            <Checkbox value={dates[date]} />
            <div>{format(new window.Date(date + 'T00:00:00'), 'MMMM D, YYYY')}</div>
            <div>
              {count} procedure{count === 1 ? '' : 's'}.
            </div>
          </Date>
        ))}
      </Dates>
      <div>
        <Button
          disabled={Object.entries(dates).filter(([_, selected]) => selected).length === 0}
          primary
          style={{ marginRight: '1rem' }}
          onClick={onConfirm}
        >
          Complete
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </div>
    </Fragment>
  );
};
