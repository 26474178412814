import { addDays, addMonths, isBefore } from 'date-fns';

export default class YearMonth {
  static of(year: number, month: number): YearMonth {
    return new YearMonth(new Date(year, month, 1));
  }

  readonly startOfMonth: Date;

  private constructor(startOfMonth: Date) {
    this.startOfMonth = startOfMonth;
  }

  get dates(): Date[] {
    const result: Date[] = [];

    const startOfNextMonth = addMonths(this.startOfMonth, 1);

    for (let date = this.startOfMonth; isBefore(date, startOfNextMonth); date = addDays(date, 1)) {
      result.push(date);
    }

    return result;
  }
}
