import React, { createContext, FC, useContext, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { assignMultipleShifts, listAssignedStaffMembers } from '../../../../../graph/staff';
import { editColors } from '../../../../../graph/procedures';

interface MultipleSelectionContextState {
  roomIds: number[];
  procedureIds: number[];
  selectedItems: number;
  deselectAll: () => void;
  addRoom: (id: number) => void;
  removeRoom: (id: number) => void;
  addProcedure: (id: number) => void;
  removeProcedure: (id: number) => void;
  assignStaffMember: (id: number, hourlyRate?: number) => void;
  addOpenPosition: () => void;
  addColor: (color: string | null) => void;
}

const MultipleSelectionContext = createContext<MultipleSelectionContextState>({
  roomIds: [],
  procedureIds: [],
  selectedItems: 0,
  deselectAll: () => {},
  addRoom: () => {},
  removeRoom: () => {},
  addProcedure: () => {},
  removeProcedure: () => {},
  assignStaffMember: () => {},
  addOpenPosition: () => {},
  addColor: () => {},
});

export function useMultipleSelectionContext() {
  return useContext(MultipleSelectionContext);
}

interface MultipleStaffShiftSelectionContextProviderProps {
  date: string;
  children: React.ReactNode;
}

export const MultipleStaffShiftSelectionContextProvider: FC<MultipleStaffShiftSelectionContextProviderProps> = ({
  date,
  children,
}) => {
  const [roomIds, setRoomIds] = useState<number[]>([]);
  const [procedureIds, setProcedureIds] = useState<number[]>([]);

  const addRoom = (id: number) => setRoomIds((prev: number[]) => [...prev, id]);
  const removeRoom = (id: number) => setRoomIds((prev: number[]) => prev.filter(e => e !== id));

  const addProcedure = (id: number) => setProcedureIds((prev: number[]) => [...prev, id]);
  const removeProcedure = (id: number) => setProcedureIds((prev: number[]) => prev.filter(e => e !== id));

  const deselectAll = () => {
    setProcedureIds([]);
    setRoomIds([]);
  };

  const [assign] = useMutation(assignMultipleShifts);
  const [editColor] = useMutation(editColors);

  const assignStaffMember = async (staffId: number, hourlyRate?: number) => {
    await assign({
      variables: {
        date,
        staffId,
        roomIds,
        procedureIds,
        hourlyRate,
      },
      refetchQueries: [{ query: listAssignedStaffMembers, variables: { date } }],
    });
  };

  const addOpenPosition = async () => {
    await assign({
      variables: {
        date,
        roomIds,
        procedureIds,
      },
    });
  };

  const addColor = async (color: string | null) => {
    await editColor({
      variables: {
        procedureIds,
        color,
      },
    });
  }

  const selectedItems = useMemo(() => roomIds.length + procedureIds.length, [roomIds, procedureIds]);

  const context = {
    roomIds,
    procedureIds,
    selectedItems,
    deselectAll,
    addRoom,
    removeRoom,
    addProcedure,
    removeProcedure,
    assignStaffMember,
    addOpenPosition,
    addColor,
  };

  return (
    <MultipleSelectionContext.Provider value={context}>
      {children}
    </MultipleSelectionContext.Provider>
  );
};
