import React from 'react';
import styled from 'styled-components';
import ac_unit from '../../assets/images/icons/ac_unit.png';
import airline_seat_flat from '../../assets/images/icons/airline_seat_flat.png';
import airline_seat_individual_suite from '../../assets/images/icons/airline_seat_individual_suite.png';
import album from '../../assets/images/icons/album.png';
import attach_file from '../../assets/images/icons/attach_file.png';
import beach_access from '../../assets/images/icons/beach_access.png';
import brush from '../../assets/images/icons/brush.png';
import bug_report from '../../assets/images/icons/bug_report.png';
import cake from '../../assets/images/icons/cake.png';
import call from '../../assets/images/icons/call.png';
import casino from '../../assets/images/icons/casino.png';
import color_lens from '../../assets/images/icons/color_lens.png';
import create from '../../assets/images/icons/create.png';
import date_range from '../../assets/images/icons/date_range.png';
import directions_boat from '../../assets/images/icons/directions_boat.png';
import event_seat from '../../assets/images/icons/event_seat.png';
import explore from '../../assets/images/icons/explore.png';
import extension from '../../assets/images/icons/extension.png';
import format_paint from '../../assets/images/icons/format_paint.png';
import golf_course from '../../assets/images/icons/golf_course.png';
import home from '../../assets/images/icons/home.png';
import language from '../../assets/images/icons/language.png';
import local_florist from '../../assets/images/icons/local_florist.png';
import local_hospital from '../../assets/images/icons/local_hospital.png';
import local_shipping from '../../assets/images/icons/local_shipping.png';
import mic from '../../assets/images/icons/mic.png';
import person from '../../assets/images/icons/person.png';
import radio from '../../assets/images/icons/radio.png';
import send from '../../assets/images/icons/send.png';
import settings from '../../assets/images/icons/settings.png';
import theaters from '../../assets/images/icons/theaters.png';
import toys from '../../assets/images/icons/toys.png';
import videogame_asset from '../../assets/images/icons/videogame_asset.png';
import watch from '../../assets/images/icons/watch.png';
import wb_sunny from '../../assets/images/icons/wb_sunny.png';
import work from '../../assets/images/icons/work.png';

const icons = {
  ac_unit: ac_unit,
  airline_seat_flat: airline_seat_flat,
  airline_seat_individual_suite: airline_seat_individual_suite,
  album: album,
  attach_file: attach_file,
  beach_access: beach_access,
  brush: brush,
  bug_report: bug_report,
  cake: cake,
  call: call,
  casino: casino,
  color_lens: color_lens,
  create: create,
  date_range: date_range,
  directions_boat: directions_boat,
  event_seat: event_seat,
  explore: explore,
  extension: extension,
  format_paint: format_paint,
  golf_course: golf_course,
  home: home,
  language: language,
  local_florist: local_florist,
  local_hospital: local_hospital,
  local_shipping: local_shipping,
  mic: mic,
  person: person,
  radio: radio,
  send: send,
  settings: settings,
  theaters: theaters,
  toys: toys,
  videogame_asset: videogame_asset,
  watch: watch,
  wb_sunny: wb_sunny,
  work: work,
};

const PatientIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  text-align: center;
  overflow: hidden;
  padding: 0.25rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
`;

const CustomIcon = styled.img`
  width: ${props => (props.inline ? '1em' : '2.5vw')};
  opacity: 0.5;
`;

export default ({ icon, backgroundColor, inline }) => {
  const source = icons[icon];
  return (
    source && (
      <PatientIcon style={{ backgroundColor }}>
        <CustomIcon src={source} inline={inline} />
      </PatientIcon>
    )
  );
};
