import { gql, useSubscription } from '@apollo/client';
import { z } from 'zod';
import useValidatedSubscription from './useValidatedSubscription';

const SystemWideRecentlyOfflineScreensData = z.object({
  systemWideRecentlyOfflineScreens: z.array(
    z.object({
      id: z.number(),
      code: z.string(),
      type: z.object({
        type: z.string(),
        room: z
          .object({
            id: z.number(),
            name: z.string(),
          })
          .nullable()
          .optional(),
      }),
      organization: z.object({
        id: z.number(),
        name: z.string(),
      }),
      description: z.string().nullable().optional(),
      clientVersion: z.string().nullable().optional(),
      lastSeen: z.number(),
    })
  ),
});

type SystemWideRecentlyOfflineScreensData = z.infer<typeof SystemWideRecentlyOfflineScreensData>;

export default function useSystemWideRecentlyOfflineScreens(): ReturnType<
  typeof useSubscription<SystemWideRecentlyOfflineScreensData>
> {
  return useValidatedSubscription(
    SystemWideRecentlyOfflineScreensData,
    gql`
      subscription systemWideRecentlyOfflineScreens {
        systemWideRecentlyOfflineScreens {
          id
          code
          type {
            type
            room {
              id
              name
            }
          }
          organization {
            id
            name
          }
          description
          clientVersion
          lastSeen
        }
      }
    `
  );
}
