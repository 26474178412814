import { withProps } from 'recompose';

import SelectInput from '../../se/components/inputs/SelectInput';

export const options = {
  FullName: 'Full Name (e.g. Dr. John Doe)',
  FirstNameOnly: 'First Name Only (e.g. Dr. John)',
  LastNameOnly: 'Last Name Only (e.g. Dr. Doe)',
};

export default withProps({
  options,
  name: 'nameFormat',
  required: true,
})(SelectInput);
