import React, { Fragment } from 'react';

import HtmlInput from '../../../../se/components/html/TextArea';
import InputError from '../../../../se/components/errors/InputError';
import { Typography } from '@material-ui/core';

export default ({
  Input = HtmlInput,
  Error = InputError,
  placeholder = '',
  isHidden,
  autoFocus,
  value,
  onChange,
  isDisabled,
  name = 'text',
  error,
  hasError,
  htmlType,
  column,
  rows,
  ...rest
}) => (
  <Fragment>
    {!!value && (
      <Fragment>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          This message will show on the Family member page to provide information about where to pick up the patient.
        </Typography>
        <Input
          id={name}
          type={isHidden ? 'hidden' : htmlType ? htmlType : 'text'}
          name={name}
          autoComplete="on"
          autoCapitalize="off"
          autoCorrect="off"
          placeholder={placeholder}
          autoFocus={autoFocus || false}
          value={value || ''}
          onChange={e => onChange(e.target.value)}
          disabled={isDisabled}
          hasError={hasError}
          column={column}
          rows={rows}
          {...rest}
        />
      </Fragment>
    )}
    <Error isVisible={hasError} id={name}>
      {hasError ? error : ''}
    </Error>
  </Fragment>
);
