import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import theme from '../../../theme';
import keyBy from 'lodash/keyBy';
import { isDefinedNotNullAndFinite } from '../../../se/utilities/check';
import isFinite from 'lodash/isFinite';
import round from 'lodash/round';

const roundFixed = v => (isFinite(v) ? round(v, 1).toFixed(1) : 0);

const Chart = ({ data, unit, color, min, max }) => {
  const labels = keyBy(data, 'date');
  return (
    <div style={{ width: '100%', height: 150 }}>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={false} />
          <XAxis
            dataKey="date"
            tickFormatter={p => (labels[p] ? labels[p].name : '')}
            tick={{
              stroke: theme.colors.lighter.string(),
              fill: theme.colors.lighter.string(),
              fontSize: '0.8em',
              strokeWidth: '0.1',
            }}
          />
          <YAxis
            unit={unit}
            tick={{
              stroke: theme.colors.lighter.string(),
              fill: theme.colors.lighter.string(),
              fontSize: '0.8em',
              strokeWidth: '0.1',
            }}
            type="number"
            domain={[dataMin => roundFixed(dataMin - 10), dataMax => roundFixed(dataMax + 10)]}
          />

          {window.innerWidth > 500 && (
            <Tooltip
              separator=": "
              cursor={{ fill: '#00a4fa' }}
              labelStyle={{ color: 'black' }}
              itemStyle={{ color: theme.colors.dark.string() }}
              formatter={x => x}
            />
          )}

          <Line type="monotone" dataKey="value" stroke={color} dot={false} unit={unit} />
          <ReferenceLine
            y={isDefinedNotNullAndFinite(min) ? min : undefined}
            ifOverflow="extendDomain"
            stroke={'orange'}
            strokeOpacity={0.6}
          />
          <ReferenceLine
            y={isDefinedNotNullAndFinite(max) ? max : undefined}
            ifOverflow="extendDomain"
            stroke={'orange'}
            strokeOpacity={0.6}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
