import { Paper, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React from 'react';

const CustomTooltipPie = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper>
        <Box p={2}>
          <Typography gutterBottom>{payload[0].name}</Typography>
          {payload.map((p, i) => (
            <>
              <Box key={i} display="flex" alignItems="center" style={{ width: '16ch' }}>
                <Typography variant="h4">{Math.round(p.value)} m</Typography>
              </Box>
            </>
          ))}
        </Box>
      </Paper>
    );
  }

  return null;
};

export default CustomTooltipPie;
