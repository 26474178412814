import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { useSubscription } from '@apollo/client';
import { Patient } from '../../../types/Patient';
import { QuestionnaireAndAnswers } from '../../../types/Questionnaire';
import { sortBy } from 'lodash';
import { item as patientWithCharts } from '../../../graph/patientChart';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { tabletThemeLight } from '../../../themes/tabletTheme';
import { TableProps } from '../../../form/components/Table';
import { defaultColumns } from '../../../form/components/AnesthesiaTableV1';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createTheme';
import AnesthesiaVitals from '../../../form/components/AnesthesiaVitals';
import { AnesthesiaChartAnswer } from '../../../types/Answer';
import { getEndTime } from '../../entities/schedule/util/time';
import { getCurrentTime } from '../../../form/components/AnesthesiaChartV1';
import { additionalRows, defaultRows } from '../../../form/components/anesthesiaTableRowsV1';

const rows = [...additionalRows, ...defaultRows];
const columns = 13;

const AnesthesiaVitalsPage = withRouter(({ patientId }: { patientId: string }) => {
  const { data: patientData } = useSubscription(patientWithCharts, {
    variables: { id: patientId },
    skip: !patientId,
  });
  const patient: Patient | undefined = patientData?.patient;

  const charts: QuestionnaireAndAnswers[] | undefined = sortBy(
    patient?.procedure?.anesthesiologistOrCharts || [],
    'questionnaire.order'
  );

  const mergedAnswers: any = charts?.reduce(
    (acc, e) => ({
      ...acc,
      ...JSON.parse(e?.questionnaireExchange?.answers || '{}'),
    }),
    {}
  );

  const data: AnesthesiaChartAnswer | undefined = mergedAnswers?.anesthesiaTableAndChart;

  const columns = defaultColumns(13, getEndTime(data?.time, 0) || getCurrentTime());

  return (
    <ThemeProvider theme={tabletThemeLight}>
      <AnesthesiaVitals columns={columns} time={data?.time || '12:00'} answer={data?.vitals || {}} scale />
    </ThemeProvider>
  );
});

export const useStyles = makeStyles<DefaultTheme, Pick<TableProps, 'rows' | 'columns'>>(theme => ({
  grid6: {
    display: 'grid',
    gridTemplateRows: 'repeat(2, 1fr)',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gap: '1em',
    margin: '1em 0',
  },
  grid: {
    display: 'grid',
    gridTemplateRows: ({ rows }) => `2em repeat(${rows}, 2em)`,
    gridTemplateColumns: ({ columns }) => `13em repeat(${columns}, 3em)`,
    width: 'min-content',
    margin: 'auto',
  },
}));

export default AnesthesiaVitalsPage;
