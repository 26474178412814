import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import { gql, useSubscription } from '@apollo/client';
import { format } from 'date-fns';
import humanFileSize from '../../../../util/humanFileSize';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';

interface PastTrackingSessionsProps {
  baseUrl: string;
}

const PastTrackingSessions = ({ baseUrl }: PastTrackingSessionsProps) => {
  const classes = useStyles();

  const { loading, error, data: sessions } = usePastTrackingSessions();

  return (
    <>
      {loading ? (
        'Loading past sessions…'
      ) : error ? (
        'Failed to load past sessions.'
      ) : sessions.length === 0 ? (
        'No past sessions.'
      ) : (
        <div className={classes.root}>
          {sessions.map(session => (
            <div key={session.id} className={classes.session}>
              <div className={classes.sessionStartedAt}>{format(session.startedAt, 'MMMM D, YYYY; HH:mm')}</div>
              <div className={classes.sessionBytesLogged}>{humanFileSize(session.bytesLogged)} logged</div>
              <Link component={RouterLink} to={baseUrl + '/' + session.id}>
                View Insights →
              </Link>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    justifyContent: 'start',
    gap: theme.spacing(1),
  },
  session: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  sessionStartedAt: {
    fontWeight: 'bold',
  },
  sessionBytesLogged: {},
}));

interface PastTrackingSession {
  id: string;
  startedBy: number;
  startedAt: string;
  stoppedBy?: number;
  stoppedAt?: string;
  bytesLogged: number;
}

const usePastTrackingSessions = () => {
  const { loading, error, data } = useSubscription<{
    pastTrackingSessions: PastTrackingSession[];
  }>(
    gql`
      subscription pastTrackingSessions {
        pastTrackingSessions {
          id
          startedBy
          startedAt
          stoppedBy
          stoppedAt
          bytesLogged
        }
      }
    `
  );

  const [sessions, setSessions] = useState<PastTrackingSession[]>([]);

  useEffect(() => {
    if (data) {
      setSessions(data.pastTrackingSessions);
    }
  }, [data]);

  return { loading, error, data: sessions };
};

export default PastTrackingSessions;
