import React, { Fragment } from 'react';
import { Redirect, Route, withRouter } from 'react-router';
import { Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { compose, mapProps, withProps } from 'recompose';
import { isDefinedAndNotNull } from '../../../../se/utilities/check';
import Page from '../../../../se/components/Page';
import Sidebar from '../../../../se/components/Sidebar';

import ChangePassword from '../ChangePassword';
import Analytics from '../../Analytics';
import { dark as darkTheme } from '../../../../theme';
import ThemeBasedCSS from '../../../../components/ThemeBasedCSS';
import Patients from '../../../entities/patient/ActiveAndPastPatients';
import ProcedureTypes from '../../../entities/procedure-type/ProcedureTypes';
import Physicians from '../../../entities/physician/Physicians';
import { unpackSessionObject } from '../../unpackSessionObject';
import MinewTestUI from '../../minew';
import ReplaySignal from '../../replay';
import { withSession } from '../../../../state/Session';
import { Context as PatientNameFormatContext } from '../../../../contexts/PatientNameFormat';
import Feedback from '../../../entities/feedback/Feedback';
import get from 'lodash/fp/get';
import identity from 'lodash/identity';
import { muiThemeDark } from '../../../../muiTheme';
import Training from '../../training/Training';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import Footer from '../admin/Footer';
import Header from '../admin/Header';
import { CssBaseline } from '@material-ui/core';
import { MainContainer } from '../../MainContainer';
import { OmniSearchProvider, OmniSearchSources } from '../../../OmniSearch';
import activeAndPastProceduresSource from '../../../../omnisearch/sources/hospital/activeAndPastProcedures';
import scheduledProceduresSource from '../../../../omnisearch/sources/hospital/scheduledProcedures';
import { CovidScreeningSC } from '../../../entities/covidScreening/CovidScreeningSubmissions';
import {
  AccessibilityNew,
  AirlineSeatFlat,
  EnhancedEncryption,
  Event,
  Healing,
  InsertChartOutlined,
  LocalHospital,
  Settings,
  Theaters,
  ThumbUp,
  Tv,
  VideoLabel,
  Schedule
} from '@material-ui/icons';
import Equipment from '../../../entities/equipment/Equipment';
import { mdiNeedle, mdiPackageVariantClosed } from '@mdi/js';
import { Icon } from '@mdi/react';
import OpenPositionCounter from '../../kiosk/openPositions/OpenPositionCounter';
import StaffMembers from '../../../entities/staff/StaffMembers';
import Anesthesiologists from '../../../entities/anesthesiologist/Anesthesiologists';
import Vendors from '../../../entities/vendor/Vendors';
import OpenPositions from '../../kiosk/openPositions/OpenPositions';
import { withScope } from '../../../../contexts/ScopeContext';

const getSystemScreenRoutes = (basePath, baseUrl, organizationId) => [
  {
    path: `/kiosk/${organizationId}/monitor`,
    to: `/kiosk/${organizationId}/monitor`,
    target: '_blank',
    label: 'Operations Screen',
    icon: <VideoLabel />,
  },
  {
    path: `/kiosk/${organizationId}/schedule`,
    to: `/kiosk/${organizationId}/schedule`,
    label: 'OR Schedule Screen',
    icon: <Event />,
  },
  {
    path: `/kiosk/${organizationId}/waiting-room`,
    to: `/kiosk/${organizationId}/waiting-room`,
    target: '_blank',
    label: 'Waiting Room Screen',
    icon: <Tv />,
    hideOnMobile: true,
  },
  {
    path: `/kiosk/${organizationId}/block-schedule`,
    to: `/kiosk/${organizationId}/block-schedule`,
    label: 'Block Schedule',
    icon: <Schedule />,
  }
];

const getRoutes = (organizationId, basePath, baseUrl, isOperationsManager, isBusinessManager, modules) =>
  [
    {
      separator: true,
    },
    {
      path: `${basePath}/patients`,
      to: `${baseUrl}/patients`,
      label: 'Patients',
      icon: <AccessibilityNew />,
      component: Patients,
    },
    {
      path: `${basePath}/analytics`,
      to: `${baseUrl}/analytics`,
      label: 'Analytics',
      icon: <InsertChartOutlined />,
      component: Analytics,
    },
    {
      path: `${basePath}/feedback`,
      to: `${baseUrl}/feedback`,
      label: 'Feedback',
      icon: <ThumbUp />,
      component: Feedback,
    },
    modules?.covidScreening
      ? {
          path: `${basePath}/covid-screening`,
          to: `${baseUrl}/covid-screening`,
          label: 'Covid Screening',
          icon: 'coronavirus',
          component: CovidScreeningSC,
        }
      : undefined,
    {
      separator: true,
    },
    {
      label: 'Staffing',
      icon: <Settings />,
      NotificationComponent: OpenPositionCounter,
      subPages: [
        {
          path: `${basePath}/positions`,
          to: `${baseUrl}/positions`,
          label: 'Open Positions',
          NotificationComponent: OpenPositionCounter,
          icon: <AccessibilityNew />,
          component: OpenPositions,
        },
        {
          path: `/kiosk/${organizationId}/calendar`,
          to: `/kiosk/${organizationId}/calendar`,
          target: '_blank',
          label: 'Calendar',
          icon: <Event />,
        },
        {
          path: `${basePath}/physicians`,
          to: `${baseUrl}/physicians`,
          label: 'Physicians',
          icon: <LocalHospital />,
          component: Physicians,
        },
        {
          path: `${basePath}/staff`,
          to: `${baseUrl}/staff`,
          label: 'Staff Members',
          icon: <EnhancedEncryption />,
          component: StaffMembers,
        },
        {
          path: `${basePath}/anesthesiologists`,
          to: `${baseUrl}/anesthesiologists`,
          label: 'Anesthesiologists',
          icon: <AirlineSeatFlat />,
          component: Anesthesiologists,
        },
        {
          path: `${basePath}/vendors`,
          to: `${baseUrl}/vendors`,
          label: 'Vendors',
          icon: <Icon path={mdiPackageVariantClosed} title="Vendors" size={1} color="#fff" />,
          component: Vendors,
        },
      ],
    },
    {
      separator: true,
    },
    {
      label: 'Configuration',
      icon: <Settings />,
      subPages: [
        {
          path: `${basePath}/procedure-types`,
          to: `${baseUrl}/procedure-types`,
          label: 'Procedure Types',
          icon: <Healing />,
          component: ProcedureTypes,
        },
        {
          path: `${basePath}/equipment`,
          to: `${baseUrl}/equipment`,
          label: 'Equipment',
          icon: <Icon path={mdiNeedle} size={'24px'} />,
          component: Equipment,
        },
      ],
    },
    {
      separator: true,
    },
    {
      path: `${basePath}/training`,
      to: `${baseUrl}/training`,
      label: 'Video Tutorials',
      icon: <Theaters />,
      component: Training,
    },
  ].filter(identity);

const getBasePathAndUrl = match => {
  if (!isDefinedAndNotNull(match) || !isDefinedAndNotNull(!match.path) || !isDefinedAndNotNull(!match.url)) {
    return ['', ''];
  }
  return [match.path === '/' ? '' : match.path, match.url === '/' ? '' : match.url];
};

const AdminSidebar = compose(
  withSession(unpackSessionObject),
  withRouter,
  withScope,
  withProps({
    Header,
    links: [],
    Footer,
  }),
  mapProps(props => {
    const { organization, match, isOperationsManager, isBusinessManager, search } = props;
    const [basePath, baseUrl] = getBasePathAndUrl(match);
    const modules = Object.assign(
      {},
      {
        covidScreening: !!props.scope?.hospital?.covidScreening,
      },
      get('scope.hospital.modules')(props)
    );
    return {
      ...props,
      links: [
        ...getSystemScreenRoutes(basePath, baseUrl, organization, search),
        ...getRoutes(props?.scope?.hospital?.id, basePath, baseUrl, isOperationsManager, isBusinessManager, modules),
      ],
    };
  })
)(Sidebar);

const Homepage = props => {
  const [basePath, baseUrl] = getBasePathAndUrl(props.match);
  const modules = Object.assign(
    {},
    {
      covidScreening: !!props.scope?.hospital?.covidScreening,
    },
    get('scope.hospital.modules')(props)
  );
  const organizationId = props.scope?.hospital?.id;
  const switchRoutes = getRoutes(
    organizationId,
    basePath,
    baseUrl,
    props.isOperationsManager,
    props.isBusinessManager,
    modules
  ).filter(_ => !_.separator);

  return (
    <ThemeProvider theme={darkTheme}>
      <MUIThemeProvider theme={muiThemeDark}>
        <style>
          {`body {
            background: ${muiThemeDark.palette.background.default};
          }`}
        </style>
        <CssBaseline />
        <PatientNameFormatContext.Provider
          value={props.isSuperAdmin ? 'PatientId' : props.patientNameFormat || 'FullName'}
        >
          <OmniSearchProvider>
            <OmniSearchSources sources={[activeAndPastProceduresSource, scheduledProceduresSource]} />
            <Fragment>
              <Page Sidebar={AdminSidebar}>
                <MainContainer switchControl>
                  <Switch>
                    <Route exact path={`${basePath}/`} render={() => <Redirect to={`${baseUrl}/analytics`} />} />
                    {switchRoutes.map(({ path, component, subPages }, i) => [
                      path ? <Route key={i} path={path} component={component} /> : null,
                      ...(subPages
                        ? subPages.map(subPage => (
                            <Route
                              key={path ? `${path}${subPage.path}` : subPage.path}
                              path={path ? `${path}${subPage.path}` : subPage.path}
                              component={subPage.component}
                            />
                          ))
                        : []),
                    ])}
                    <Route path="/change-password" component={ChangePassword} />
                    {props.isSuperAdmin && <Route path={`${basePath}/tools/minew`} component={MinewTestUI} />}
                    {props.isSuperAdmin && <Route path={`${basePath}/tools/replay`} component={ReplaySignal} />}
                    <Route render={() => <Redirect to={`${baseUrl}/analytics`} />} />
                  </Switch>
                </MainContainer>
              </Page>
              <ThemeBasedCSS />
            </Fragment>
          </OmniSearchProvider>
        </PatientNameFormatContext.Provider>
      </MUIThemeProvider>
    </ThemeProvider>
  );
};

export default compose(
  withSession(unpackSessionObject),
  withRouter,
  withScope,
  withSession(get('session.account.metadata'))
)(Homepage);
