import React from 'react';
import styled from 'styled-components';
import HipaaBadge from './HipaaBadge';

const CopyrightWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default styled.div.attrs({
  children: (
    <CopyrightWrapper>
      <HipaaBadge />
      <span style={{ opacity: 0.45, marginLeft: '.5em' }}>
        ©{new Date().getFullYear()} Ospitek. All Rights Reserved.
      </span>
    </CopyrightWrapper>
  ),
})`
  font-size: 0.875em;
  grid-column: 2;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
