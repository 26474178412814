import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import AnesthesiaNotes from '../../components/entities/procedures/AnesthesiaNotes';
import { gql, useSubscription } from '@apollo/client';

interface AnesthesiaCommentsButtonProps {
  procedureId: number;
}

const AnesthesiaNotesButton = ({ procedureId }: AnesthesiaCommentsButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const { data } = useSubscription(
    gql`
      subscription procedure($id: Long!) {
        procedure(id: $id) {
          events {
            id
            type
            timestamp
            patientPin
            channel
            content
            user {
              id
              name
            }
            text
            status
            anesthesiaReviewed
            exchangeId
          }
        }
      }
    `,
    {
      variables: {
        id: procedureId,
      },
    }
  );

  const notes = (data?.procedure?.events || []).filter(event => event.type === 'AnesthesiaNote');

  return (
    <>
      <Button variant="contained" size="small" onClick={() => setOpen(true)} style={{ minHeight: '5em' }}>
        Anesthesia Notes
      </Button>
      <Dialog fullWidth={true} open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
        {/*<DialogTitle>Anesthesia Notes</DialogTitle>*/}
        <DialogContent>
          <AnesthesiaNotes procedureId={procedureId} procedureNotes={notes} />
        </DialogContent>
        <DialogActions style={{ position: 'sticky', bottom: 0 }}>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AnesthesiaNotesButton;
