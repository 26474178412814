import { useMutation, useQuery } from '@apollo/client';
import { getNotificationTemplate } from '../../../graph/notificationTemplates';
import { notifyReadyForSurgeon } from '../../../graph/patients';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Tooltip from '../../Tooltip';
import React, { useState } from 'react';
import { fontSizeIconSide, SideOption } from './pacuAndPrep/PatientOptions';
import { useTheme } from '@material-ui/core/styles';
import { getLogEntries, isPatientReadyForSurgeonNotifyFn } from './tablet/utils';
import Physician from '../../../types/Physician';
import { Patient } from '../../../types/Patient';
import { CustomModal } from './tablet/OperationRoomTabletV1';
import { SendingSetting } from '../../entities/notificationTemplate/NotificationTemplates';

const NotifyPhysicianModal = CustomModal(
  'This text message will be sent to the physician when his/her patient is ready to be seen in PreOp.'
);

export const NotifyButton = ({
  notifiedLabel,
  notNotifiedLabel,
  isNotified,
  notifyEnabled,
  message,
  notify,
}: {
  notifiedLabel: string;
  notNotifiedLabel: string;
  isNotified: boolean;
  message: string;
  notify: () => Promise<void>;
  notifyEnabled: boolean;
}) => {
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    if (!isNotified && notifyEnabled) {
      setIsModalOpen(true);
    }
  };
  const handleConfirm = async () => {
    await notify();

    setIsModalOpen(false);
  };
  return (
    <>
      <SideOption
        smsIndicator={false}
        icon={
          isNotified ? (
            <CheckCircleIcon
              style={{
                opacity: '0.7',
                fontSize: fontSizeIconSide,
                color: theme.palette.success.main,
              }}
            />
          ) : (
            <NotificationsIcon
              color={!notifyEnabled ? 'disabled' : 'primary'}
              style={{
                fontSize: fontSizeIconSide,
              }}
            />
          )
        }
        label={isNotified ? notifiedLabel : notNotifiedLabel}
        disabled={!notifyEnabled || isNotified}
        onClick={openModal}
      />
      {isModalOpen && (
        <NotifyPhysicianModal
          handleCancel={() => setIsModalOpen(false)}
          handleConfirm={handleConfirm}
          messageToSend={message}
        />
      )}
    </>
  );
};

const PhysicianNotifyButton = ({ patient, physician }: { patient?: Patient; physician?: Physician }) => {
  const patientLogs = getLogEntries(patient);
  const { data: template } = useQuery(getNotificationTemplate, {
    variables: { trigger: 'ReadyForSurgeonNotify' },
  });

  const [notifyPhysician] = useMutation(notifyReadyForSurgeon, {
    variables: {
      id: patient?.id,
    },
  });

  const notify = async () => {
    await notifyPhysician();
  };

  const isSurgeonNotified = isPatientReadyForSurgeonNotifyFn(patientLogs);
  const readyForSurgeonNotification = template?.getNotificationTemplate?.[0] || null;
  const shouldSendNotification = physician?.shouldSendNotification || null;
  const hasPhoneNumber = physician?.notificationNumbers || null;

  const messageContent = readyForSurgeonNotification?.content;
  const notifyPhysicianEnabled =
    readyForSurgeonNotification?.sendingSetting !== SendingSetting.Off && hasPhoneNumber && shouldSendNotification;
  const notifyPhysicianTooltip =
    readyForSurgeonNotification?.sendingSetting === SendingSetting.Off
      ? 'Physician messaging has been turned off. Go to message configuration to enable it.'
      : !shouldSendNotification
      ? 'Physician messaging has been turned off. Physician doesn’t want to receive notification.'
      : !hasPhoneNumber
      ? 'Physician does not have a phone number.'
      : null;

  return notifyPhysicianTooltip ? (
    <Tooltip delay={0} content={notifyPhysicianTooltip}>
      <NotifyButton
        isNotified={isSurgeonNotified}
        notifyEnabled={notifyPhysicianEnabled}
        notNotifiedLabel={'Notify Physician'}
        notifiedLabel={'Physician Notified'}
        message={messageContent}
        notify={notify}
      />
    </Tooltip>
  ) : (
    <NotifyButton
      isNotified={isSurgeonNotified}
      notifyEnabled={notifyPhysicianEnabled}
      notNotifiedLabel={'Notify Physician'}
      notifiedLabel={'Physician Notified'}
      message={messageContent}
      notify={notify}
    />
  );
};

export default PhysicianNotifyButton;
