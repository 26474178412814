import { alpha, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import styled from 'styled-components';
import { withTheme } from '../../../../../se/theme';

const Sheet = styled.div`
  position: absolute;
  height: 4em;

  border-radius: 4px;
  background: ${withTheme(theme => theme.backgroundColor.string())};

  // z-index: ${props => 100 - props.order};
  z-index: 1;

  width: ${props => 100 - props.order * 2}%;

  bottom: ${props => 0.4375 * props.order}em;

  border: 1px solid ${props => props.theme.backgroundColor.string()};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Stack = styled.div`
  position: relative;
  width: 100%;

  margin-bottom: 0.5em;

  display: flex;
  justify-content: center;
  align-items: center;

  height: ${props => (props.visible ? '5em' : 0)};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: all 100ms ease;
`;

const Label = styled.label`
  color: ${withTheme(theme => theme.textColor.string())};
  font-size: 1.2em;
  z-index: 101;
`;

const Condenser = ({ count, isTop }) => {
  const classes = useStyles({ order: 0 });

  return (
    <Stack visible={count > 0}>
      {[...Array(Math.min(count, 1))].map((_, i) => (
        <Box className={classes.root} key={i} order={i}>
          {i === 0 && (
            <Typography>{`${count} ${isTop ? 'Previous' : 'Upcoming'} Procedure${count > 1 ? 's' : ''}`}</Typography>
          )}
        </Box>
      ))}
    </Stack>
  );
};

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    height: '4em',
    borderRadius: '4px',
    backgroundColor: theme.palette.background.default,
    zIndex: 1,
    width: props => `${100 - props.order * 2}%`,
    bottom: props => `${0.4375 * props.order}em`,
    border: `1px solid ${theme.palette.background.default}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default Condenser;
