export const getProceduresDiff = (old, procedures) => {
  const assignProcedures = procedures.map(p => p.id).filter(p => !old.some(_ => _.id === p));

  const unassignProcedures = old.map(o => o.id).filter(o => !procedures.some(_ => _.id === o));

  return {
    assignProcedures,
    unassignProcedures,
  };
};
