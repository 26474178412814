import { gql } from '@apollo/client';
import { fragments as statisticsFragments } from '../dashboard';

export const fragments = {
  all: gql`
    fragment FeedbackData on Feedback {
      id
      rating
      suggestion
      submittedAt
      patientId
      physician {
        id
        name
      }
      patient {
        id
        name
        dateOfSurgery
        phoneNumber
      }
      caretakerPhoneNumber
    }
  `,
};

export const list = gql`
  query ($filter: Filter) {
    caretakerFeedbacks(filter: $filter) {
      ...FeedbackData
    }
  }
  ${fragments.all}
`;

export const caretakerFeedback = gql`
  query caretakerFeedback($id: Long!) {
    caretakerFeedback(id: $id) {
      ...FeedbackData
    }
  }
  ${fragments.all}
`;

export const listWPhysician = gql`
  query ($filter: Filter) {
    caretakerFeedbacks(filter: $filter) {
      ...FeedbackData
      physician {
        id
        name
      }
    }
  }
  ${fragments.all}
`;

export const feedbackStats = gql`
  query caretakerFeedbackStats($filter: Filter) {
    caretakerFeedbackStats(filter: $filter) {
      ...StatisticsData
    }

    caretakerHospitalRatingStats(filter: $filter) {
      averageRating
      population
      voters
      votes
      turnout
    }
  }
  ${statisticsFragments.all}
`;

export const hospitalRatingStats = gql`
  query caretakerHospitalRatingStats($filter: Filter) {
    caretakerHospitalRatingStats(filter: $filter) {
      averageRating
      population
      voters
      votes
      turnout
    }
  }
`;

export default {
  list,
  listWPhysician,
  feedbackStats,
};
