import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { compose } from 'recompose';
import { withSession } from '../../../state/Session';
import identity from 'lodash/identity';
import { withCrispCtx } from '../../../vendor/CrispProvider';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alert: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '1rem',
    padding: '1rem',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const NoPermission = ({ destroy, crisp }: { destroy: any; crisp: any }) => {
  const classes = useStyles();
  const destroySession = () => {
    if (crisp) {
      crisp.clearSession();
    }
    destroy();
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.alert}>
        <ErrorOutlineIcon fontSize="large" color="secondary" />
        <Typography variant="h4">No Permissions</Typography>
        <Typography>
          It seems that you don’t have permissions to access this page. Try going back with a link below. And if the
          error persists contact our support.
        </Typography>
        <Button variant="contained" color="primary" onClick={destroySession}>
          Log out
        </Button>
      </Box>
    </Box>
  );
};

// @ts-ignore
export default compose(withSession(identity), withCrispCtx)(NoPermission);
