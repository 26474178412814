import { useEffect, useState } from 'react';

function useNow(resolutionInMillis: number = 10000) {
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), resolutionInMillis);
    return () => clearInterval(interval);
  }, [resolutionInMillis]);

  return now;
}

export default useNow;
