import { ElementType } from 'react';
import IllustrationProps from './IllustrationProps';
import assesments from './all/assesments';
import checkin from './all/checkin';
import diagnostic from './all/diagnostic';
import medications from './all/medications';
import nurse from './all/nurse';

const illustrations: Record<string, ElementType<IllustrationProps> | undefined> = {
  assesments,
  checkin,
  diagnostic,
  medications,
  nurse,
};

export default illustrations;
