import styled from 'styled-components';
import { withTheme } from '../../../../se/theme';

export const Question = styled.span`
  font-weight: 500;
  color: ${withTheme(theme => theme.textColor.alpha(0.45).string())};
  margin-right: 0.5em;
`;

export const MainQuestion = styled(Question)`
  margin: 0.5em 0;
`;

export const Answer = styled.span`
  margin-left: 0.5em;
`;

export const Field = styled.div`
  display: flex;
  line-height: 1.4;
  // background-color: rgba(255,255,255,.15);
  align-items: flex-start;
  margin: 0.5em 0;

  > * {
    flex: 1;
  }
`;

export const InnerField = styled(Field)`
  font-size: 0.875em;
  margin: 0.375em 0;
  background-color: transparent;
  padding-left: 1em;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  > :last-child {
    border-bottom: none !important;
  }
`;
