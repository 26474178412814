import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Editable, Slate, useSlate, withReact } from 'slate-react';
import { createEditor, Editor, Node, Transforms } from 'slate';
import isHotkey from 'is-hotkey';
import { withHistory } from 'slate-history';
import { Button, EditorIcon as Icon, Toolbar } from './components';
import { SectionTitle } from '../../pages/Configuration';
import { defaultTo, last } from 'lodash';
import { withTheme } from '../../../se/theme';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
};

const Wrapper = styled.div`
  padding: calc(1rem * 0.75 - 0.125rem);
  border-width: 0.125em;
  border-style: solid;
  border-color: ${withTheme(theme => theme.border.color.default.string())};
  border-radius: 0.125rem;
  line-height: 1.5;
`;

const H2 = styled.h2`
  font-size: 1.35rem;
  margin-top: 0.5em;
  margin-bottom: 0.25em;
`;

const BulletedList = styled.ul`
  list-style: initial;
  margin-left: 2em;
`;

const Separator = styled.div`
  margin: 1em auto;
  width: 95%;
  height: 1px;
  opacity: 0.6;
  background: ${props => props.theme.textColor.string()};
`;

const empty = [
  {
    type: 'paragraph',
    children: [
      {
        text: ' ',
      },
    ],
  },
];

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <BulletedList {...attributes}>{children}</BulletedList>;
    case 'heading-one':
      return <SectionTitle {...attributes}>{children}</SectionTitle>;
    case 'heading-two':
      return <H2 {...attributes}>{children}</H2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'separator':
      return (
        <Fragment>
          {children}
          <Separator {...attributes} />
        </Fragment>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong style={{ fontWeight: 600 }}>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em style={{ fontStyle: 'italic' }}>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.variable) {
    children = <q style={{ color: '#5eff93' }}>{children}</q>;
  }

  return <span {...attributes}>{children}</span>;
};

const RichTextField = ({ onChange, value, isDisabled }) => {
  const [internalValue, setValue] = useState(defaultTo(value, empty));
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const _onChange = value => {
    onChange(value);
    setValue(value);
  };

  useEffect(() => {
    if (value !== internalValue) {
      const list = [];
      for (let node of Node.nodes(editor)) {
        if (node[1].length > 0) {
          list.push(node);
        }
      }

      const end = last(list);
      if (end) {
        Transforms.setSelection(editor, { anchor: { path: [0, 0], offset: 0 }, focus: { path: end[1], offset: 1000 } });
        Transforms.delete(editor);
      }
      Editor.insertFragment(editor, value);
    }
  }, [internalValue, value, editor]);

  return (
    <Slate editor={editor} value={internalValue} onChange={_onChange}>
      <Wrapper>
        <Toolbar>
          <MarkButton format="bold" icon="format_bold" />
          <MarkButton format="italic" icon="format_italic" />
          <MarkButton format="underline" icon="format_underlined" />
          <BlockButton format="heading-one" icon="looks_one" />
          <BlockButton format="heading-two" icon="looks_two" />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" />
          <MarkButton format="variable" icon="code" />
        </Toolbar>
        <Editable
          readOnly={isDisabled}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter some text…"
          spellCheck
          onKeyDown={event => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </Wrapper>
    </Slate>
  );
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  });

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

export default RichTextField;
