import React from 'react';
import styles from '../styles';

const Header = ({ pageNumber, pageCount }) => (
  <div style={styles.rowSpaceBetween}>
    <div>
      {pageNumber} of {pageCount}
    </div>
    <div>Polaris Surgery Center</div>
    <div />
  </div>
);

export default Header;
