import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import Distribution from './Distribution';
import PanelContainer from '../../../Panel/PanelContainer';
import responsive from '../../../../se/utilities/responsive';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

export const Value = styled.h2`
  display: flex;
  flex: 1;
  font-size: 2em;
  line-height: 1.3;
  margin: 0;
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
  color: ${props => (!!props.color ? props.color : 'white')};
  ${responsive.md.andSmaller`
      font-size: 1.2em;
      margin-bottom: 0;
  `};
`;

const ToggleButton = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  > i {
    font-size: 1.5rem;
    cursor: pointer;
  }
`;

export const HideControl = ({ hidden, setHidden }) => (
  <Fragment>
    {hidden && window.innerWidth < 500 ? (
      <ToggleButton onClick={() => setHidden(false)}>
        <KeyboardArrowDown fontSize={'small'} />
      </ToggleButton>
    ) : null}
    {!hidden && window.innerWidth < 500 ? (
      <ToggleButton onClick={() => setHidden(true)}>
        <KeyboardArrowUp fontSize={'small'} />
      </ToggleButton>
    ) : null}
  </Fragment>
);

export default ({
                  title,
                  value,
                  distribution,
                  unit,
                  ratio,
                  hideValue,
                  tooltipLabel,
                  isDecimal,
                  style,
                  children,
                  noPadding,
                  noValue,
                  color = undefined,
                }) => {
  const [hidden, setHidden] = useState(window.innerWidth < 500);
  const computedValue =
    noValue || hideValue ? <span>&nbsp;</span> : [isDecimal ? value.toFixed(1) : value.toFixed(0), unit].join(' ');

  return (
    <PanelContainer title={title} compact style={style} noPadding={noPadding} titleColor={color}>
      <div style={{ display: 'flex' }}>
        {!noValue && <Value color={color}>{computedValue}</Value>}
        <HideControl hidden={hidden} setHidden={setHidden} />
      </div>
      {!hidden &&
        (children ? (
          children
        ) : (
          <Distribution
            color={color}
            distribution={distribution}
            unit={unit}
            label={title}
            ratio={ratio}
            tooltipLabel={tooltipLabel}
          />
        ))}
    </PanelContainer>
  );
};
