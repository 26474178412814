import React from 'react';
import omit from 'lodash/omit';
import update from 'lodash/fp/update';
import { allColumns, baseColumns } from './columns';
import HospitalMobileListItem from '../hospitals/HospitalMobileListItem';
import DoctorOfficeInput from '../../inputs/surgeonOffices/DoctorOfficeInput';
import scheme from '../../../graph/surgeonOffices';
import PWAInstallBanner from '../../../se/components/PWAInstallBanner';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import ButtonRouterLink from '../../../se/components/ButtonRouterLink';
import { unpackSessionObject } from '../../pages/unpackSessionObject';
import { withSession } from '../../../state/Session';
import { flow } from 'lodash';

const Empty = { illustration: theme => theme.illustrations.rooms };

export const List = {
  columns: [...baseColumns],
  tableKey: 'DoctorOffices',
  Empty,
  MobileItemComponent: HospitalMobileListItem,
};

const setDefaultSessionTimeout = update('sessionTimeout', sessionTimeout => sessionTimeout || 10 * 60);
const updateTimezone = update('timezone', timezone => (timezone && timezone.id ? timezone.id : timezone));

export const Create = {
  Input: DoctorOfficeInput,
  Empty,
  prepareCreateData: flow(setDefaultSessionTimeout, updateTimezone),
};

export const ShowActions = ({ data = {} }) => <ButtonRouterLink to={`/su/${data.id}`}>Manage</ButtonRouterLink>;

export const Show = {
  columns: allColumns,
  Empty,
  Actions: ShowActions,
};

export const Edit = {
  Input: DoctorOfficeInput,
  Empty,
  prepareUpdateData: flow(setDefaultSessionTimeout, updateTimezone),
};

const CRUD = GraphQLCRUD({
  entityName: 'SurgeonOffice',
  scheme,
  List,
  Create,
  Show,
  Edit,
});

const RU = GraphQLCRUD({
  entityName: 'SurgeonOffice',
  scheme: omit(scheme, 'remove'),
  List,
  Show,
});

const DoctorOffices = ({ isGroupAdmin }) => (
  <React.Fragment>
    <PWAInstallBanner />
    {isGroupAdmin ? <RU /> : <CRUD />}
  </React.Fragment>
);

export default withSession(unpackSessionObject)(DoctorOffices);
