import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import get from 'lodash/get';
import { differenceInMinutes, parse } from 'date-fns';
import {
  fullNameToDrLastName,
  fullNameToDrLastNameMiddleEast,
  fullNameToLastNameNoDr,
  fullNameToLastNameRestTrunkated,
  fullNameToLastNameRest,
  isDimmed,
  isNoShow,
  NormalizedStatus,
  normalizeStatus,
} from '../shared/procedureUtils';
import has from 'lodash/has';
import { translateToTz } from '../../../../../util/dateTime';
import { useScope } from '../../../../../hooks/useScope';
import { Procedure as ProcedureT } from '../../../../../types/Procedure';
import isMiddleEast from '../../../../../util/isMiddleEast';
import { getLogEntries, isHelpFn, isPreOpAsDPUOptimized } from '../../tablet/utils';
import { Box } from '@material-ui/core';
import { getInOrStatusLabel } from '../statusUtils';
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import CardTiny from '../timeline/components/CardTiny';
import { getDisposition } from '../timeline/Procedure';
import CardHeader from './CardHeader';
import CardSubheader from './CardSubheader';
import CardAnesthesiologistNote from './CardAnesthesiologistNote';
import CardProcedureType from './CardProcedureType';
import { getColor, getRelativeDuration } from './utils';

const Header = styled.div<any>`
  display: flex;
  background-color: rgba(255, 255, 255, 0.05);
  box-sizing: border-box;
  font-size: 1em;
  flex-direction: column;
  position: relative;
  z-index: 1;

  ${props =>
    props.isInactive
      ? css`
          opacity: 0.3;
        `
      : null}

  ${props =>
    props.isDisabled
      ? css`
          opacity: 0.3;
          pointer-events: none;
          border: none;
          animation: none;
        `
      : null}

  ${props =>
    props.stretch
      ? css`
          height: 100%;
        `
      : null}

  ${props =>
    props.row
      ? css`
          flex-direction: row;
        `
      : null}

  ${props =>
    props.column
      ? css`
          flex-direction: column;
          height: 100%;

          > * {
            flex: 1 0 auto;
            padding-top: 0;
            padding-bottom: 0;
          }
        `
      : null}
`;

const FULL_HEIGHT_DURATION = 140;
const secondInMili = 1000;
const minDurationForStaffMembersToShow = 35;
const minDuration = 42;

const AnesthesiologistProcedure: FC<{
  procedure: ProcedureT;
  dayStart: Date;
  dayEnd: Date;
  onClick: () => void;
  isTouchDevice: boolean;
  showBedNumber: boolean;
  isKiosk?: boolean;
  date: Date;
  inlineText?: boolean;
}> = ({ procedure, dayStart, dayEnd, onClick, isTouchDevice, showBedNumber }) => {
  const scope = useScope();
  const hospitalId = scope?.hospital?.id;

  const [fullHeight, setFullHeight] = useState(false);

  let hoverTimeout: NodeJS.Timeout;

  const handleMouseEnter = () => {
    if (!fullHeight) {
      hoverTimeout = setTimeout(() => {
        setFullHeight(!fullHeight);
      }, 1 * secondInMili);
    }
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    setFullHeight(false);
  };

  const handleOnClick = () => {
    if (isTouchDevice) {
      setFullHeight(!fullHeight);
      setTimeout(() => setFullHeight(false), 10 * secondInMili);
    }
    onClick();
  };

  // @ts-ignore
  const startTime = translateToTz(get(scope, 'hospital.timezone.id'))(parse(get(procedure, 'startTime')));
  const duration = !isTouchDevice
    ? procedure?.duration || 0
    : !fullHeight
    ? procedure?.duration || 0
    : FULL_HEIGHT_DURATION;
  const normalizedStatus = normalizeStatus(
    procedure?.patient?.status,
    procedure?.patient?.room,
    isMiddleEast(hospitalId)
  );
  const status = has(procedure, 'patient.id') ? normalizedStatus : 'PreAdm';

  const noShow = isNoShow(procedure);
  const isInactive = isDimmed(procedure);
  const isCanceled = get(procedure, 'isCanceled');

  const physicianName = isMiddleEast(hospitalId)
    ? fullNameToDrLastNameMiddleEast(procedure?.physician?.name)
    : procedure?.physician?.name;
  const color = get(procedure, 'physician.color') || get(procedure, 'physicianColor') || '#888888';

  const anesthesiologistName = isMiddleEast(hospitalId)
    ? fullNameToDrLastName(procedure?.anesthesiologist?.name)
    : fullNameToLastNameRest(procedure?.anesthesiologist?.name);
  const anesthesiaType = procedure?.anesthesiaType;

  const isDPU = isPreOpAsDPUOptimized(procedure?.patient?.room?.type, procedure?.patient?.events);
  const isHelp = isHelpFn(getLogEntries(get(procedure, 'patient')));
  const procedureColor = procedure?.color || getColor(status, hospitalId, null);

  const classes = useStyles({ length });

  if ((duration || 0) <= 15) {
    return (
      <Box
        className={classes.card}
        style={{
          top: `calc(0.4rem + ${getDisposition(dayStart, startTime, dayEnd)}%)`,
          zIndex: fullHeight ? 5 : 1,
          height: `${getRelativeDuration(dayStart, 15, dayEnd)}%`,
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box
          className={clsx(classes.helpContainer, {
            [classes.helpContainerAnimation]:
              isHelp && ![NormalizedStatus.Completed, NormalizedStatus.Canceled].includes(normalizedStatus),
          })}
        >
          <Box
            className={clsx(classes.wrapper, {
              [classes.disabled]: [NormalizedStatus.Completed, NormalizedStatus.Canceled].includes(normalizedStatus),
              [classes.onClick]: !isInactive && onClick,
              [classes.isDpuFromOr]: isDPU,
              [classes.customBorderColor]: procedureColor,
            })}
            style={procedureColor && !isHelp ? { borderColor: procedureColor } : undefined}
            onClick={handleOnClick}
          >
            <Header
              stretch
              isInactive
              isDisabled={[NormalizedStatus.Completed, NormalizedStatus.Canceled].includes(normalizedStatus)}
            >
              <CardTiny
                procedure={procedure}
                physicianName={physicianName}
                anesthesiologistName={anesthesiologistName}
                anesthesiaType={anesthesiaType}
                isCanceled={isCanceled}
                noShow={noShow}
                showBedNumber={showBedNumber}
                color={color}
                status={status}
                hospitalId={hospitalId}
                inOrStatus={
                  normalizedStatus === NormalizedStatus.In_OR && getInOrStatusLabel(procedure?.patient?.status)
                }
                stretch
              />
            </Header>
          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box
        className={classes.card}
        style={{
          top: `calc(0.4rem + ${getDisposition(dayStart, startTime, dayEnd)}%)`,
          zIndex: fullHeight ? 5 : 1,
          height: `${getRelativeDuration(dayStart, duration <= 30 ? minDuration : duration, dayEnd)}%`,
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box
          className={clsx(classes.helpContainer, {
            [classes.helpContainerAnimation]:
              isHelp &&
              !isInactive &&
              ![NormalizedStatus.Completed, NormalizedStatus.Canceled].includes(normalizedStatus),
            [classes.helpContainerBackground]: fullHeight,
          })}
        >
          <Box
            className={clsx(classes.wrapper, {
              [classes.onClick]: !isInactive && onClick,
              [classes.isDpuFromOr]: isDPU,
              [classes.customBorderColor]: procedureColor,
            })}
            style={procedureColor && !isHelp ? { borderColor: procedureColor } : undefined}
            onClick={handleOnClick}
          >
            <Box
              className={clsx(classes.body, {
                [classes.completed]: isInactive,
                [classes.disabled]: [NormalizedStatus.Completed, NormalizedStatus.Canceled].includes(normalizedStatus),
              })}
            >
              <CardHeader
                startTime={get(procedure, 'startTimeText')}
                endTime={get(procedure, 'endTimeText')}
                physicianName={physicianName}
              />
              <CardSubheader
                patientInitials={procedure.patientInitials?.replace(' ', '')}
                labelColor={getColor(status, hospitalId)}
                labelText={
                  normalizedStatus === NormalizedStatus.In_OR && getInOrStatusLabel(procedure?.patient?.status)
                    ? normalizedStatus === NormalizedStatus.In_OR && getInOrStatusLabel(procedure?.patient?.status)
                    : status
                }
                anesthesiaType={anesthesiaType}
                anesthesiologistName={anesthesiologistName}
                hasAnesthesiologistNotes={!!procedure.anesthesiologistNotes}
              />
              {duration <= 45 && <CardProcedureType procedureType={procedure.procedureType?.replaceAll('\n', ' ')} />}
              {procedure.anesthesiologistNotes && <CardAnesthesiologistNote note={procedure.anesthesiologistNotes} />}
              {duration > 45 && <CardProcedureType procedureType={procedure.procedureType?.replaceAll('\n', ' ')} />}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
};

export const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    position: 'absolute',
    left: 0,
    fontWeight: 500,
    boxSizing: 'border-box',
    fontFamily: '"Roboto", sans-serif',
    letterSpacing: -0.2,
  },
  staffMembersBox: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '0.25em',
    paddingRight: '0.25em',
  },

  header: {
    fontSize: '0.75em',
    paddingRight: '0.25em',
    paddingLeft: '0.25em',
    display: 'flex',
    justifyContent: 'space-between',
  },

  wrapper: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.type === 'light' ? '#fff' : '#222c59',
    borderRadius: '0.16666em',
    boxShadow: `1px 1px 0 ${theme.palette.type === 'light' ? alpha(theme.palette.common.black, 0.1) : '#121d4d'}`,
    overflow: 'hidden',
    border: '2px solid #fff',

    '&:after': {
      content: '',
      display: 'block',
      borderRadius: '0 0 0.16666em 0.16666em',
      position: 'absolute',
      bottom: 0,
      left: '0.5em',
      right: '0.5em',
      height: '0.4em',
    },
  },
  anesthesiologist: {
    fontWeight: 'bold',
    overflow: 'hidden',
    position: 'relative',
  },
  disabled: {
    opacity: 0.3,
    pointerEvents: 'none',
    border: 'none',
    animation: 'none',
  },
  onClick: {
    cursor: 'pointer',
    userSelect: 'none',
    transition: 'transform 100ms ease-in-out, background-color 150ms ease-in-out',

    '&:hover': {
      backgroundColor: alpha(theme.palette.text.primary, 0.05),
    },

    '&:active': {
      backgroundColor: alpha(theme.palette.primary.dark, 0.075),
    },
  },
  isDpuFromOr: {
    border: '1px solid purple',
  },
  customBorderColor: {
    border: '2px solid transparent',
  },
  noteIndicator: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    backgroundColor: theme.palette.text.primary,
  },
  completed: {
    opacity: 0.3,

    '&:after': {
      content: '',
      background: 'linear-gradient(0, rgba(34, 44, 87, 1) 0%, rgba(34, 44, 87, 0) 100%)',
    },
  },

  body: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: alpha(theme.palette.background.paper, 0.05),
    fontSize: '1em',
    position: 'relative',
    zIndex: 1,
  },
  stretch: {
    height: '100%',
  },
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
    height: '100%',

    '& > *': {
      flex: '1 0 auto',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  staffMembers: {
    display: 'flex',
    minWidth: 0,
    gap: '0.5em',

    '& > div': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontSize: 'inherit',
      fontFamily: 'inherit',
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
  },

  '@keyframes emphasize': {
    '0%': {
      borderColor: 'rgba(255, 0, 0, 0)',
      boxShadow: '0 0 4px rgba(255, 0, 0, 0)',
    },
    '100%': {
      borderColor: 'rgba(255, 0, 0, 1)',
      boxShadow: '0 0 30px rgba(255, 0, 0, 1)',
    },
  },
  helpContainer: {
    border: '3px solid transparent',
    height: '100%',
  },
  helpContainerAnimation: {
    animation: `1.2s $emphasize ease-in alternate infinite`,
  },
  helpContainerBackground: {
    background: theme.palette.background.default,
  },
}));

export default AnesthesiologistProcedure;
