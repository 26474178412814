import React, { Fragment, useEffect, useMemo, useCallback, useRef, useState } from 'react';
import { FilePicker } from 'react-file-picker';
import { format } from 'date-fns';
import { withRouter } from 'react-router';

import Button from '../../../se/components/Button';
import Modal from '../../../se/components/Modal';
import Uploader, { UPLOAD_RESOURCE } from './Uploader';
import { ScheduleUploadConfirmation } from './ScheduleUploadConfirmation';
import { withProps } from 'recompose';
import { PhysicianUploadConfirmation } from './PhysicianUploadConfirmation';
import { Formats, getMediaTypes } from './formats';

const DefaultFormat = [Formats.CSV];
const DefaultMediaType = getMediaTypes([Formats.CSV]);

const useAssertUnchanged = (value, name) => {
  const original = useMemo(() => value, [value]);

  useEffect(() => {
    if (value !== original) {
      console.warn(name, 'should not change through rerenders.', 'It changed to', value, 'from', original);
    }
  }, [value, original, name]);
};

const UploadButton = withRouter(
  ({ formats, history, location, UploadConfirmation, resource, title, className, ButtonComponent }) => {
    const fileInputRef = useRef();

    useAssertUnchanged(formats, 'UploadButton formats prop');
    useAssertUnchanged(history, 'UploadButton history prop');
    useAssertUnchanged(resource, 'UploadButton resource prop');
    useAssertUnchanged(ButtonComponent, 'UploadButton ButtonComponent prop');

    useEffect(() => {
      if (fileInputRef.current.getElementsByTagName('input')[0] !== undefined) {
        formats
          ? fileInputRef.current.getElementsByTagName('input')[0].setAttribute('accept', getMediaTypes(formats))
          : fileInputRef.current.getElementsByTagName('input')[0].setAttribute('accept', DefaultMediaType);
      }
    }, [formats, fileInputRef]);

    const [file, setFile] = useState(null);

    const handleChange = useCallback(
      file => {
        setFile(file);
      },
      [setFile]
    );

    const handleError = useCallback(() => {
      setFile(null);
      alert('Please select .csv file.');
    }, [setFile]);

    const filePicker = useCallback(
      text => (
        <div ref={fileInputRef}>
          <FilePicker extensions={formats ? formats : DefaultFormat} onChange={handleChange} onError={handleError}>
            {ButtonComponent || <Button className={className}>{text}</Button>}
          </FilePicker>
        </div>
      ),
      [ButtonComponent, formats, className, handleChange, handleError]
    );

    const handleCancel = useCallback(() => setFile(null), [setFile]);

    const handleDone = useCallback(
      date => {
        setFile(null);
        if (history) {
          history.replace(`${location.pathname}?date=${format(date, 'YYYY-MM-DD')}`);
        }
      },
      [history, location.pathname]
    );

    return (
      <Fragment>
        {filePicker(title)}
        {file && (
          <Modal title={title} withRouter={false} onClose={handleCancel}>
            <Uploader
              filePicker={filePicker}
              file={file}
              onCancel={handleCancel}
              resource={resource}
              UploadConfirmation={UploadConfirmation}
              onDone={handleDone}
            />
          </Modal>
        )}
      </Fragment>
    );
  }
);

export const ScheduleUploadButton = withProps({
  resource: UPLOAD_RESOURCE.SCHEDULE,
  title: 'Upload Schedule',
  UploadConfirmation: ScheduleUploadConfirmation,
})(UploadButton);

export const PhysicianUploadButton = withProps({
  resource: UPLOAD_RESOURCE.PHYSICIAN,
  title: 'Upload Physicians',
  UploadConfirmation: PhysicianUploadConfirmation,
})(UploadButton);

export default UploadButton;
