import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { setAnesthesiaReviewed, setStatus as setProcedureStatus } from '../../../graph/procedures';
import React from 'react';
import { DefaultTitle } from '../../../se/components/entity/EntityRouter';
import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';
import PatientFileGenerator from './PatientFileGenerator';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import { withProps } from 'recompose';
import SelectInput from '../../../se/components/inputs/SelectInput';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const StatusWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 1rem;
  min-width: 16rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const StatusSelectInput = withProps({
  placeholder: 'Status',
  isSearchable: false,
  menuWidthFollowContent: true,
  isClearable: true,
  name: 'status',
})(SelectInput);

export const RenderTitle = ({
  data,
  generatePatientProfile,
  procedureStatuses,
  isSo,
  procedureStatus,
  procedureStatusLabels,
}) => {
  const classes = useStyles();

  const [updateProcedureStatus] = useMutation(setProcedureStatus);
  const handleProcedureStatusChange = async e => {
    await updateProcedureStatus({
      variables: {
        id: data.id,
        status: get(e, 'target.value', e),
      },
    });
  };

  const [updateAnesthesiaReviewed] = useMutation(setAnesthesiaReviewed);
  const handleAnesthesiaReviewedChange = async e => {
    await updateAnesthesiaReviewed({
      variables: {
        id: data.id,
        anesthesiaReviewed: e.target.checked,
      },
    });
  };

  return (
    <DefaultTitle>
      {data ? `#${defaultTo(data.id, '#')} - ${data.patientName ? data.patientName : 'Unknown'}` : '-'}
      <StatusWrapper>
        <Box mr={2}>
          {isSo ? (
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="grouped-select">Status</InputLabel>
              <Select value={get(data, 'status')} id="grouped-select" onChange={handleProcedureStatusChange}>
                {procedureStatus
                  ? Object.entries(procedureStatus).map(([key, value], index) => [
                      <ListSubheader>{key}</ListSubheader>,
                      value.map((status, id) => (
                        <MenuItem id={id} value={status}>
                          {procedureStatusLabels[status]}
                        </MenuItem>
                      )),
                      index < Object.entries(procedureStatus).length - 1 ? <Divider /> : null,
                    ])
                  : null}
              </Select>
            </FormControl>
          ) : (
            <StatusSelectInput
              onChange={handleProcedureStatusChange}
              value={get(data, 'status')}
              options={procedureStatuses}
              isClearable={!isSo}
            />
          )}
        </Box>
      </StatusWrapper>
      {generatePatientProfile && (
        <FormControl className={classes.formControl}>
          <PatientFileGenerator
            procedureId={get(data, 'id')}
            physician={data && data.patient && data.patient.physician}
          />
        </FormControl>
      )}
      <FormControlLabel
        control={
          <Checkbox checked={get(data, 'anesthesiaReviewed', false)} onChange={handleAnesthesiaReviewedChange} />
        }
        label="Anesthesia Reviewed"
      />
    </DefaultTitle>
  );
};
