import React from 'react';
import { renderFormAnswer } from '../../../form/Form';
import { tryParseJson } from '../../../util/parseJson';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core';
import { Category } from '../../../types/CovidScreening';
import SubmissionFormContext from '../../../contexts/SubmissionFormContext';
import ConfigContext, { defaultConfig } from '../../../contexts/ConfigContext';
import { useScope } from '../../../hooks/useScope';

interface CovidScreeningSubmissionProps {
  data?: {
    submission?: {
      question?: string;
      answer?: string;
      dateSubmitted?: string;
      id: string;
    };
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [theme.breakpoints.up('lg')]: {
      root: {
        maxWidth: '40em',
      },
    },
  })
);

const PatientCovidScreeningSubmission = ({ data }: CovidScreeningSubmissionProps) => {
  const scope = useScope();
  const classes = useStyles();

  const submissionId = data?.submission?.id;
  const covidScreeningOrganizationId = scope?.hospital?.covidScreening?.id;

  return (
    <ConfigContext.Provider value={defaultConfig}>
      <SubmissionFormContext.Provider value={{ organizationId: covidScreeningOrganizationId, submissionId }}>
        <div className={classes.root}>
          {renderFormAnswer(
            { category: Category.Patient },
            tryParseJson(data?.submission?.question),
            tryParseJson(data?.submission?.answer)
          )}
        </div>
      </SubmissionFormContext.Provider>
    </ConfigContext.Provider>
  );
};

export default PatientCovidScreeningSubmission;
