import { OtherSpecialtyLabel } from '../../../types/StaffMember';
import { compose, withProps } from 'recompose';
import { withLabel } from '../../../se/components/Label';
import MultiSelectInput from '../../../se/components/inputs/MultiSelectInput';

const OtherSpecialtiesSelectInput = compose(
  withLabel('Other Specialties'),
  withProps({
    required: true,
    options: Object.entries(OtherSpecialtyLabel).map(([key, value], index, arr) => ({ label: value, value: key })),
  })
)(MultiSelectInput);

export default OtherSpecialtiesSelectInput;
