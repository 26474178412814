import React, { Fragment, useMemo } from 'react';
import Cell, { EmptyCell } from './Cell';
import { setHours, differenceInMinutes } from 'date-fns';
import get from 'lodash/get';
import { orderBy } from 'lodash';
import { getMinutesOfDay } from '../../../util/dateTime';

export const BookedSlot = ({
  physician,
  organization,
  onClick,
  grayed,
  physicianSelection,
  startTime,
  endTime,
  colorMode,
  utilization,
}) => {
  const startPosition = ((getMinutesOfDay(startTime) - 360) / 720) * 100;
  const endPosition = ((getMinutesOfDay(endTime) - 360) / 720) * 100;

  const clickHandler = () => {
    onClick({ from: getMinutesOfDay(startTime), to: getMinutesOfDay(endTime), physician });
  };

  return (
    <Cell
      color={get(physician, 'color')}
      physician={get(physician, 'name')}
      organization={get(organization, 'name')}
      category={get(physician, 'category')}
      speciality={get(physician, 'speciality')}
      onClick={clickHandler}
      grayed={grayed}
      highlighted={physicianSelection === get(physician, 'id')}
      start={startPosition}
      end={endPosition}
      colorMode={colorMode}
      utilization={utilization}
    />
  );
};

const EmptySlot = ({ startTime, endTime, onClick }) => {
  const startPosition = ((getMinutesOfDay(startTime) - 360) / 720) * 100;
  const endPosition = ((getMinutesOfDay(endTime) - 360) / 720) * 100;
  const clickHandler = () => {
    onClick({ from: getMinutesOfDay(startTime), to: getMinutesOfDay(endTime) });
  };

  return (
    <EmptyCell
      onClick={clickHandler}
      start={startPosition}
      end={endPosition}
      style={{ backgroundColor: 'transparent' }}
    />
  );
};

const getAvailableSlots = (booked, date) => {
  const availableSlots = [];
  const bookings = [{ end: setHours(date, 6) }, ...booked];
  for (let i = 0; i < bookings.length; i++) {
    const start = bookings[i].end;
    const end = get(bookings, `[${i + 1}].start`, setHours(date, 18));

    if (differenceInMinutes(end, start) > 1) {
      availableSlots.push({ startTime: start, endTime: end });
    }
  }
  return availableSlots;
};

export default ({ bookings = [], onClick, grayed, physicianSelection, date, colorMode }) => {
  const sorted = useMemo(() => orderBy(bookings, 'start'), [bookings]);
  const availableSlots = useMemo(() => getAvailableSlots(sorted, date), [sorted, date]);

  return (
    <Fragment>
      {availableSlots.map((slot, i) => (
        <EmptySlot key={`empty-${i}`} {...slot} onClick={onClick} />
      ))}
      {bookings.map((booking, i) => (
        <BookedSlot
          key={i}
          physician={booking.physician}
          organization={booking.organization}
          onClick={val => onClick({ ...val, booking })}
          grayed={grayed}
          physicianSelection={physicianSelection}
          startTime={booking.start}
          endTime={booking.end}
          colorMode={colorMode}
          utilization={booking.utilization}
        />
      ))}
    </Fragment>
  );
};
