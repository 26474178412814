import React, { useState } from 'react';
import ErrorFactory from '../../../se/components/errors/Error';
import { useMutation } from '@apollo/client';
import { create, remove, update } from '../../../graph/locations';
import { OrganizationLocation } from '../../../types/locations';
import Box from '@material-ui/core/Box';
import { Button, Grid, TextField } from '@material-ui/core';

const Error = ErrorFactory();

interface AddMultipleLocationsProps {
  data?: {
    id: number;
    locations: Array<OrganizationLocation>;
  };
}

type OrganizationLocationFormData = OrganizationLocation & { isDirty?: boolean; isLoading?: boolean };

const AddMultipleLocations = ({ data }: AddMultipleLocationsProps) => {
  const organizationId = data?.id;
  const [locations, setLocations] = useState<Array<OrganizationLocationFormData>>(data?.locations || []);
  const [error, setError] = useState<any>(null);

  const [createLocation] = useMutation(create, {
    onError: e => {
      console.log(e);
      setError(e);
    },
  });

  const [updateLocation] = useMutation(update, {
    onError: e => {
      console.log(e);
      setError(e);
    },
  });

  const [removeLocation] = useMutation(remove, {
    onError: e => {
      console.log(e);
      setError(e);
    },
  });

  const onChange = (location: OrganizationLocation, index: number) => {
    setLocations(prevState => prevState.map((e, i) => (i === index ? { ...location, isDirty: true } : e)));
  };

  const addNewLocation = () => {
    setLocations(prevState => [...prevState, { name: '', organizationId, isDirty: true }]);
  };

  const saveNewLocation = async (location: OrganizationLocation, index: number) => {
    const res = await createLocation({
      variables: {
        organizationId,
        location: {
          ...location,
          organizationId,
        },
      },
    });
    //@ts-ignore
    const locationId = res.data.createOrganizationLocation;
    setLocations(prevState =>
      prevState.map((e, i) => (i === index ? { ...location, id: locationId, isDirty: false } : e))
    );
  };

  const updateExistingLocation = async (location: OrganizationLocation, index: number) => {
    await updateLocation({
      variables: {
        organizationId,
        location: {
          ...location,
          organizationId,
        },
      },
    });
    setLocations(prevState => prevState.map((e, i) => (i === index ? { ...location, isDirty: false } : e)));
  };

  const deleteLocation = async (location: OrganizationLocation, index: number) => {
    await removeLocation({
      variables: {
        organizationId,
        location: {
          ...location,
          organizationId,
        },
      },
    });
    setLocations(prevState => prevState.filter((e, i) => i !== index));
  };

  return (
    <Box mt={2}>
      {locations.map(({ id, name, isDirty }, index) => (
        <Grid container spacing={1} alignItems="center">
          {/* @ts-ignore */}
          <Grid item xs={10}>
            <TextField
              label="Location Name"
              onChange={(e: any) => onChange({ name: e.target.value, id, organizationId }, index)}
              value={name}
              fullWidth={true}
              variant="filled"
            />
          </Grid>
          <Grid item xs={2}>
            {!isDirty && <Button onClick={() => deleteLocation({ name, id, organizationId }, index)}>Remove</Button>}
            {!!isDirty && (
              <Button
                onClick={() =>
                  id
                    ? updateExistingLocation({ name, id, organizationId }, index)
                    : saveNewLocation(
                        {
                          name,
                          id,
                          organizationId,
                        },
                        index
                      )
                }
              >
                Save
              </Button>
            )}
          </Grid>
        </Grid>
      ))}
      <Button onClick={addNewLocation}>Add a new location</Button>
      <Error isVisible={!!error} id="LocationsFormError">
        <p>An unexpected error has occurred. Please, try again or contact our support.</p>
      </Error>
    </Box>
  );
};

export default AddMultipleLocations;
