import React from 'react';
import Header from './components/Header';
import PatientInfo from './components/PatientInfo';
import styles, { getGridHeaderChildStyles, getGridParentStyles, getGridChildStyles2 } from './styles';
import MedicalPassportCheckbox from '../components/MedicalPassportCheckbox';
import { useValue } from '../ValueContext';
import MedicationReconciliationField from '../components/MedicationReconciliationField';
import MedicalPassportRadioGroup from '../components/MedicalPassportRadioGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box } from '@material-ui/core';
import { CSSProperties } from 'react';
import get from 'lodash/get';
import Footer from './components/Footer';
import MedicalPassportTextArea from '../components/MedicalPassportTextArea';
import MedicalPassportField from '../components/MedicalPassportField';
import MedicationTextArea from '../components/MedicationTextArea';

const Page3 = () => {
  const { questionnaireAnswers } = useValue();

  // medication
  function checkedCont(value) {
    if (value === 'Continue Med') {
      return true;
    }
    return false;
  }
  function checkedHold(value) {
    if (value === 'Hold medication') {
      return true;
    }
    return false;
  }
  function checkedNew(value) {
    if (value === 'New Prescription given') {
      return true;
    }
    return false;
  }
  function checkedPCP(value) {
    if (value === 'Check with PCP') {
      return true;
    }
    return false;
  }

  function formatDate(inputDate) {
    const day = inputDate.substr(0, 2);
    const month = inputDate.substr(2, 2);
    const year = inputDate.substr(4);

    return `${day}/${month}/${year}`;
  }

  const medicationList = get(
    questionnaireAnswers,
    'sections.medications.schema.medicationList.yes[1].schema.medication_list.list',
    []
  );
  let nameArr = [medicationList.length];
  let doseArr = [medicationList.length];
  let routeArr = [medicationList.length];
  let freqArr = [medicationList.length];
  let result = [medicationList.length];
  let purposeArr = [medicationList.length];
  let last = [medicationList.length];
  let status = [medicationList.length];

  for (let i = 0; i < medicationList.length; i++) {
    nameArr[i] = get(medicationList[i], 'schema.name.text', '');
    doseArr[i] = get(medicationList[i], 'schema.dosage.text', '');
    routeArr[i] = get(medicationList[i], 'schema.route.option', '');
    freqArr[i] = get(medicationList[i], 'schema.frequency.option', '');
    result[i] = nameArr[i] + ',' + doseArr[i] + ',' + routeArr[i] + ',' + freqArr[i];
    last[i] = get(medicationList[i], 'schema.lastTaken.text', '');

    purposeArr[i] = get(medicationList[i], 'schema.purpose.text', '');
    status[i] = get(medicationList[i], 'schema.status.option');
  }

  // allergy
  function yesNoAllergies() {
    const allergyQ1 = get(questionnaireAnswers, 'sections.allergies.schema.q1.yes[0]', false);
    const allergyQ2 = get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[0]', false);
    const allergyQ3 = get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[0]', false);

    return allergyQ1 || allergyQ2 || allergyQ3;
  }
  const allergyList = get(questionnaireAnswers, 'sections.allergies.schema.q1.yes[1].schema.allergy_list.list', []);
  const allergyList2 = get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[0]', false);
  const allergyList3 = get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[0]', false);

  const allergyName = [];
  const reaction = [];

  for (let i = 0; i < allergyList.length; i++) {
    allergyName.push(get(allergyList[i], 'schema.q1_name.text', ''));
    reaction.push(get(allergyList[i], 'schema.q1_reaction.text', ''));
  }

  if (allergyList2) {
    allergyName.push('Latex');
    reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q2.yes[1].schema.q2_reaction.text', ''));
  }

  if (allergyList3) {
    allergyName.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_name.text', ''));
    reaction.push(get(questionnaireAnswers, 'sections.allergies.schema.q3.yes[1].schema.q3_reaction.text', ''));
  }

  return (
    <div id="page3" className="page" style={styles.root}>
      <Header pageNumber={3} pageCount={10} />
      <div>MEDICATION RECONCILIATION</div>
      <PatientInfo />
      <div>Medications Prior to Admission</div>
      <p style={{ textAlign: 'left' }}>
        List all medications, vitamins, nutritional and/or herbal supplements, including medications administrated via
        pump, or patch, prior to admission.
      </p>
      <div style={medicationTable}>
        <div>Medication, dosage, route, frequency</div>
        <div>Last Taken</div>
        <div></div>
        <div>Reason</div>
        <div>
          <div style={{ fontSize: '.85rem' }}>Cont = Continue Med</div>
          <div style={{ fontSize: '.85rem' }}>Hold=Hold medication</div>
          <div style={{ fontSize: '.85rem' }}>New = New Prescription given</div>
          <div style={{ fontSize: '.85rem' }}>PCP = Check with PCP</div>
        </div>
        {[...Array(medicationList.length)].map((_, i) => (
          <>
            <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, borderLeft: 0 }}>
              <MedicationTextArea name={`medication.r${i}.c0`} value={result[i]} />
            </div>
            <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0 }}>
              <MedicationTextArea name={`medication.r${i}.c1`} value={last[i]} />
            </div>
            <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0 }}>
              <MedicationTextArea name={`medication.r${i}.c4`} />
            </div>
            <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0 }}>
              <MedicationTextArea name={`medication.r${i}.c2`} value={purposeArr[i]} />
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, paddingRight: '10px' }}>
                <input type="checkbox" name={`medication.r${i}.cont`} label="Cont" />
                <div style={{ fontSize: '.9rem' }}>Cont</div>
              </div>
              <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, paddingRight: '10px' }}>
                <input type="checkbox" name={`medication.r${i}.hold`} label="Hold" />
                <div style={{ fontSize: '.9rem' }}>Hold</div>
              </div>
              <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, paddingRight: '10px' }}>
                <input type="checkbox" name={`medication.r${i}.new`} label="New" />
                <div style={{ fontSize: '.9rem' }}>New</div>
              </div>
              <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, paddingRight: '10px' }}>
                <input type="checkbox" name={`medication.r${i}.pcp`} label="PCP" />
                <div style={{ fontSize: '.9rem' }}>PCP</div>
              </div>
            </div>
          </>
        ))}
        {[...Array(2)].map((_, i) => (
          <>
            <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, borderLeft: 0 }}>
              <MedicationTextArea name={`medication.r${i}.c00`} />
            </div>
            <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0 }}>
              <MedicationTextArea name={`medication.r${i}.c10`} />
            </div>
            <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0 }}>
              <MedicationTextArea name={`medication.r${i}.c40`} />
            </div>
            <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0 }}>
              <MedicationTextArea name={`medication.r${i}.c20`} />
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, paddingRight: '10px' }}>
                <input type="checkbox" name={`medication.r${i}.cont`} label="Cont" />
                <div style={{ fontSize: '.9rem' }}>Cont</div>
              </div>
              <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, paddingRight: '10px' }}>
                <input type="checkbox" name={`medication.r${i}.hold`} label="Hold" />
                <div style={{ fontSize: '.9rem' }}>Hold</div>
              </div>
              <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, paddingRight: '10px' }}>
                <input type="checkbox" name={`medication.r${i}.new`} label="New" />
                <div style={{ fontSize: '.9rem' }}>New</div>
              </div>
              <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, paddingRight: '10px' }}>
                <input type="checkbox" name={`medication.r${i}.pcp`} label="PCP" />
                <div style={{ fontSize: '.9rem' }}>PCP</div>
              </div>
            </div>
          </>
        ))}
      </div>
      <div style={getGridParentStyles(6, false)}>
        <div style={getGridChildStyles2(4)}>Pre-Op Nurse Signature: ________________________</div>
        <div style={getGridChildStyles2(2)}>Date: _________________</div>
      </div>
      <div style={prescriptionTable}>
        <div>Prescriptions Given at Discharge</div>
        <div>Next Dose</div>
        {[...Array(7)].map((_, i) => (
          <>
            <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0, borderLeft: 0 }}>
              <MedicationTextArea name={`prescription.r${i}.c0`} />
            </div>
            <div style={{ border: '1px solid black', borderBottom: 0, borderRight: 0 }}>
              <MedicationTextArea name={`prescription.r${i}.c1`} />
            </div>
          </>
        ))}
      </div>
      <MedicalPassportCheckbox
        name="discharge"
        label="Discharge Medications reviewed with patient or patient representative."
      />
      <div style={getGridParentStyles(6, false)}>
        <div style={getGridChildStyles2(4)}>Post-Op Nurse Signature: ________________________</div>
        <div style={getGridChildStyles2(2)}>Date/Time: _________________</div>
        <div style={getGridChildStyles2(4)}>Patient or Responsible Party Signature: ________________________</div>
        <div style={getGridChildStyles2(2)}>Date/Time: _________________</div>
      </div>
      <Footer pageNumber={3} pageCount={11} />
    </div>
  );
};

const medicationTable = { display: 'grid', border: '1px solid black', gridTemplateColumns: '40% 10% 10% 20% 20%' };
const prescriptionTable = { display: 'grid', border: '1px solid black', gridTemplateColumns: '50% 50%' };

export default Page3;
