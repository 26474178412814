import { Mutation } from '@apollo/client/react/components';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import Form from '../../../se/components/Form';
import { editOrTimes } from '../../../graph/patients';
import { compose, withProps } from 'recompose';
import TextInput from '../../../se/components/inputs/TextInput';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import { withLabel } from '../../../se/components/Label';
import { format, setHours, setMinutes } from 'date-fns';
import LinkButton from '../../../se/components/LinkButton';
import Button from '../../../se/components/Button';
import { OR_TIMES } from './TimeEditModal';

const FormWrapper = styled.div``;

const RowButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  button:nth-child(even) {
    margin-left: 1em;
  }
`;
export const formatTime = value => (value ? format(value, 'HH:mm') : undefined);

export const DateInput = withProps({
  type: 'time',
  style: { fontSize: '2rem', lineHeight: '1.8rem', minHeight: '1.5em' },
})(TextInput);

const minMax = (value, minEnteredAt) => ({
  [OR_TIMES.ENTERED_AT]: {
    min: minEnteredAt,
    max: value[OR_TIMES.TIMEOUT_START],
  },
  [OR_TIMES.TIMEOUT_START]: {
    min: value[OR_TIMES.ENTERED_AT],
    max: value[OR_TIMES.PROCEDURE_START],
  },
  [OR_TIMES.PROCEDURE_START]: {
    min: value[OR_TIMES.TIMEOUT_START],
    max: value[OR_TIMES.CLOSING_START],
  },
  [OR_TIMES.CLOSING_START]: {
    min: value[OR_TIMES.PROCEDURE_START],
  },
});

const WithMinMax = Component =>
  class extends React.Component {
    render() {
      return (
        <Component
          {...this.props}
          {...minMax(this.props.rootValue, formatTime(this.props.minEnterdAtTime))[this.props.valueKey]}
        />
      );
    }
  };

const Input = (focused, disabledFields = {}, minEnterdAtTime) =>
  withProps({
    schema: {
      enteredAt: compose(
        withLabel('Entered At Time'),
        withProps({
          autoFocus: focused === OR_TIMES.ENTERED_AT,
          disabled: disabledFields[OR_TIMES.ENTERED_AT],
          minEnterdAtTime,
        })
      )(WithMinMax(DateInput)),
      timeoutStart: compose(
        withLabel('Timeout Start'),
        withProps({
          autoFocus: focused === OR_TIMES.TIMEOUT_START,
          disabled: disabledFields[OR_TIMES.TIMEOUT_START],
        })
      )(WithMinMax(DateInput)),
      procedureStart: compose(
        withLabel('Procedure Start'),
        withProps({
          autoFocus: focused === OR_TIMES.PROCEDURE_START,
          disabled: disabledFields[OR_TIMES.PROCEDURE_START],
        })
      )(WithMinMax(DateInput)),
      calledNextPatient: compose(
        withLabel('Called Next Patient'),
        withProps({
          autoFocus: focused === OR_TIMES.CALLED_NEXT_PATIENT,
          disabled: disabledFields[OR_TIMES.CALLED_NEXT_PATIENT],
        })
      )(WithMinMax(DateInput)),
      closingStart: compose(
        withLabel('Closing Start'),
        withProps({
          autoFocus: focused === OR_TIMES.CLOSING_START,
          disabled: disabledFields[OR_TIMES.CLOSING_START],
        })
      )(WithMinMax(DateInput)),
      surgeonLeft: compose(
        withLabel('Surgeon Left'),
        withProps({
          autoFocus: focused === OR_TIMES.SURGEON_LEFT,
          disabled: disabledFields[OR_TIMES.SURGEON_LEFT],
        })
      )(WithMinMax(DateInput)),
    },
  })(ObjectInput);

const setTime = (date = new Date(), time) => {
  if (time) {
    const [hour, min] = time.split(':').map(x => parseInt(x));
    if (isFinite(hour) && isFinite(min)) {
      return setHours(setMinutes(date, min), hour);
    }
  }
};

const Footer = ({ busy, label, onClose }) => (
  <RowButtons>
    <Button primary busy={busy}>
      {label}
    </Button>
    {onClose && <LinkButton onClick={onClose}>Cancel</LinkButton>}
  </RowButtons>
);

export const CancelableForm = withProps({ Footer })(Form);

const TimeEditForm = props => {
  const {
    patientId,
    roomId,
    enteredAt,
    timeoutStart,
    procedureStart,
    closingStart,
    focused,
    onClose,
    disabledFields,
    enteredPrepAt,
  } = props;
  const onSubmit = mutate => async data => {
    const times = Object.keys(data)
      .filter(key => data[key] !== 'Invalid Date')
      .reduce((acc, cur) => ({ ...acc, [cur]: data[cur] }), {});
    const diff = Object.values(OR_TIMES).reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: formatTime(props[cur]) !== times[cur] ? setTime(props[cur], times[cur]) : undefined,
      }),
      {}
    );
    await mutate({ variables: { patientId, roomId, ...diff } });
    onClose();
  };

  const input = useMemo(() => Input(focused, disabledFields, enteredPrepAt), [focused, disabledFields, enteredPrepAt]);

  return (
    <Mutation mutation={editOrTimes}>
      {(mutate, { loading }) => (
        <FormWrapper>
          <CancelableForm
            autoFocus
            label="Update"
            onClose={onClose}
            initialValue={{
              enteredAt: formatTime(enteredAt),
              timeoutStart: formatTime(timeoutStart),
              procedureStart: formatTime(procedureStart),
              closingStart: formatTime(closingStart),
            }}
            input={input}
            onSubmit={onSubmit(mutate)}
            busy={loading}
          />
        </FormWrapper>
      )}
    </Mutation>
  );
};

export default TimeEditForm;
