import React from 'react';
import { useValue } from '../../ValueContext';
import styles from '../../styles';
import MedicalPassportField from '../../components/MedicalPassportField';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import MedicationReconciliationField from '../../components/MedicationReconciliationField';
import GT from '../../../../../../assets/images/GTlogo.png';
import get from 'lodash/get';
import Barcode from '../../../Barcode';

const Page5 = ({ formName, pageNumber, showQRCode, defaultValue, answers }) => {
  const providerId = defaultValue?.procedure?.patient?.providerId;
  const { questionnaireAnswers } = useValue();

  const getMedicationList = answers => {
    const [takesMedication, medicationAnswer] = get(answers, 'sections.medications.yes', []) || [];
    return [takesMedication, get(medicationAnswer, 'list', [])];
  };

  const medicationList = getMedicationList(questionnaireAnswers);
  const medication = medicationList[1];

  // Pre defined medication answers with limit 17
  let nameArr = [17];
  let doseArr = [17];
  let freqArr = [17];
  let purposeArr = [17];
  let last = [17];

  for (let i = 0; i < 18; i++) {
    nameArr[i] = get(medication[i], 'schema.name.text', '');
    doseArr[i] = get(medication[i], 'schema.dosage.text', '');
    freqArr[i] = get(medication[i], 'schema.frequency.option', '');
    purposeArr[i] = get(medication[i], 'schema.purpose.text', '');
    last[i] = get(medication[i], 'schema.last.text', '');
  }

  const allergies = get(questionnaireAnswers, 'sections.allergies.schema.q1.checkboxes', {});
  const latex = get(allergies, 'Allergic to Latex?[0]');

  let latexFlag = false;
  if (latex === true) {
    latexFlag = true;
  }

  return (
    <div id="page8" className="page">
      <div style={styles.center}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div />
          <div>
            {showQRCode && (
              <Barcode
                formName={formName}
                pageNumber={pageNumber}
                providerId={defaultValue?.procedure?.patient?.providerId}
                serviceTime={defaultValue?.procedure?.serviceTime}
              />
            )}
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'left', width: '75px', height: '75px' }}>
          <img src={GT} alt="Hospital Logo" />
        </div>
        <strong>
          <div style={{ display: 'flex', alignItems: 'left', fontSize: '2rem' }}>Medication Reconciliation</div>
        </strong>
        <div style={{ display: 'flex', alignItems: 'left' }}>
          <input type="checkbox" name="page8.latex" label="Latex Allergy / Sensitivity" id="id" checked={latexFlag} />
          Latex Allergy / Sensitivity
        </div>
        <strong>
          <div style={{ display: 'flex', alignItems: 'left' }}>Allergies</div>
        </strong>
        <MedicalPassportCheckbox name="page8.agreement" label="Patient has reviewed Pre-OP Guidelines/Instructions" />
        <br></br>
        <div style={{ display: 'flex', alignItems: 'left' }}>U-Unknown</div>
        <div style={{ ...styles.sectionHeader, fontWeight: 'bold', padding: '5px' }}>MEDICATION</div>
        <div
          style={{
            lineHeight: '1.6em',
            display: 'grid',
            gridTemplateColumns: '31% 6.5% 14% 13% 14% 6% 7% 8.5%',
            textAlign: 'center',
            border: '1px solid black',
            borderBottom: 0,
            borderRight: '1px solid black',
            fontWeight: 'bold',
          }}
        >
          <div style={{ borderRight: '1px solid black' }}>Name</div>
          <div style={{ borderRight: '1px solid black' }}>Dose</div>
          <div style={{ borderRight: '1px solid black' }}>Frequency</div>
          <div style={{ borderRight: '1px solid black' }}>Reason</div>
          <div style={{ borderRight: '1px solid black' }}>Last Taken Date & Time</div>
          <div style={{ borderRight: '1px solid black', fontSize: '11px' }}>Continue</div>
          <div style={{ borderRight: '1px solid black', fontSize: '9px' }}>Discontinue</div>
          <div style={{}}>Date to Resume</div>
        </div>

        <div style={styles.eightColumns}>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c1.r1" value={nameArr[0]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c1.r2" value={doseArr[0]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c1.r3" value={freqArr[0]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c1.r4" value={purposeArr[0]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c1.r5" value={last[0]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c1.r6" />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c1.r7" />
            </div>
          </div>
          <div>
            <MedicationReconciliationField name="page8.c1.r8" />
          </div>
        </div>

        <div style={styles.eightColumns}>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c2.r1" value={nameArr[1]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c2.r2" value={doseArr[1]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c2.r3" value={freqArr[1]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c2.r4" value={purposeArr[1]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c2.r5" value={last[1]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c2.r6" />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c2.r7" />
            </div>
          </div>
          <div>
            <MedicationReconciliationField name="page8.c2.r8" />
          </div>
        </div>

        <div style={styles.eightColumns}>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c3.r1" value={nameArr[2]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c3.r2" value={doseArr[2]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c3.r3" value={freqArr[2]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c3.r4" value={purposeArr[2]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c3.r5" value={last[2]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c3.r6" />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c3.r7" />
            </div>
          </div>
          <div>
            <MedicationReconciliationField name="page8.c3.r8" />
          </div>
        </div>

        <div style={styles.eightColumns}>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c4.r1" value={nameArr[3]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c4.r2" value={doseArr[3]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c4.r3" value={freqArr[3]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c4.r4" value={purposeArr[3]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c4.r5" value={last[3]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c4.r6" />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c4.r7" />
            </div>
          </div>
          <div>
            <MedicationReconciliationField name="page8.c4.r8" />
          </div>
        </div>

        <div style={styles.eightColumns}>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c5.r1" value={nameArr[4]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c5.r2" value={doseArr[4]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c5.r3" value={freqArr[4]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c5.r4" value={purposeArr[4]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c5.r5" value={last[4]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c5.r6" />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c5.r7" />
            </div>
          </div>
          <div>
            <MedicationReconciliationField name="page8.c5.r8" />
          </div>
        </div>

        <div style={styles.eightColumns}>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c6.r1" value={nameArr[5]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c6.r2" value={doseArr[5]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c6.r3" value={freqArr[5]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c6.r4" value={purposeArr[5]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c6.r5" value={last[5]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c6.r6" />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c6.r7" />
            </div>
          </div>
          <div>
            <MedicationReconciliationField name="page8.c6.r8" />
          </div>
        </div>

        <div style={styles.eightColumns}>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c7.r1" value={nameArr[6]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c7.r2" value={doseArr[6]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c7.r3" value={freqArr[6]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c7.r4" value={purposeArr[6]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c7.r5" value={last[6]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c7.r6" />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c7.r7" />
            </div>
          </div>
          <div>
            <MedicationReconciliationField name="page8.c7.r8" />
          </div>
        </div>

        <div style={styles.eightColumns}>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c8.r1" value={nameArr[7]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c8.r2" value={doseArr[7]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c8.r3" value={freqArr[7]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c8.r4" value={purposeArr[7]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c8.r5" value={last[7]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c8.r6" />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c8.r7" />
            </div>
          </div>
          <div>
            <MedicationReconciliationField name="page8.c8.r8" />
          </div>
        </div>

        <div style={styles.eightColumns}>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c9.r1" value={nameArr[8]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c9.r2" value={doseArr[8]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c9.r3" value={freqArr[8]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c9.r4" value={purposeArr[8]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c9.r5" value={last[8]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c9.r6" />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c9.r7" />
            </div>
          </div>
          <div>
            <MedicationReconciliationField name="page8.c9.r8" />
          </div>
        </div>

        <div style={styles.eightColumns}>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c10.r1" value={nameArr[9]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c10.r2" value={doseArr[9]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c10.r3" value={freqArr[9]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c10.r4" value={purposeArr[9]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c10.r5" value={last[9]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c10.r6" />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c10.r7" />
            </div>
          </div>
          <div>
            <MedicationReconciliationField name="page8.c10.r8" />
          </div>
        </div>

        <div style={styles.eightColumns}>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c11.r1" value={nameArr[10]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c11.r2" value={doseArr[10]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c11.r3" value={freqArr[10]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c11.r4" value={purposeArr[10]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c11.r5" value={last[10]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c11.r6" />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c11.r7" />
            </div>
          </div>
          <div>
            <MedicationReconciliationField name="page8.c11.r8" />
          </div>
        </div>

        <div style={styles.eightColumns}>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c12.r1" value={nameArr[11]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c12.r2" value={doseArr[11]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c12.r3" value={freqArr[11]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c12.r4" value={purposeArr[11]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c12.r5" value={last[11]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c12.r6" />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c12.r7" />
            </div>
          </div>
          <div>
            <MedicationReconciliationField name="page8.c12.r8" />
          </div>
        </div>

        <div style={styles.eightColumns}>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c13.r1" value={nameArr[12]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c13.r2" value={doseArr[12]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c13.r3" value={freqArr[12]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c13.r4" value={purposeArr[12]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c13.r5" value={last[12]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c13.r6" />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c13.r7" />
            </div>
          </div>
          <div>
            <MedicationReconciliationField name="page8.c13.r8" />
          </div>
        </div>

        <div style={styles.eightColumns}>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c14.r1" value={nameArr[13]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c14.r2" value={doseArr[13]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c14.r3" value={freqArr[13]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c14.r4" value={purposeArr[13]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c14.r5" value={last[13]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c14.r6" />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c14.r7" />
            </div>
          </div>
          <div>
            <MedicationReconciliationField name="page8.c14.r8" />
          </div>
        </div>

        <div style={styles.eightColumns}>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c15.r1" value={nameArr[14]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c15.r2" value={doseArr[14]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c15.r3" value={freqArr[14]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c15.r4" value={purposeArr[14]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c15.r5" value={last[14]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c15.r6" />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c15.r7" />
            </div>
          </div>
          <div>
            <MedicationReconciliationField name="page8.c15.r8" />
          </div>
        </div>

        <div style={styles.eightColumns}>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c16.r1" value={nameArr[15]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c16.r2" value={doseArr[15]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c16.r3" value={freqArr[15]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c16.r4" value={purposeArr[15]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c16.r5" value={last[15]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c16.r6" />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c16.r7" />
            </div>
          </div>
          <div>
            <MedicationReconciliationField name="page8.c16.r8" />
          </div>
        </div>

        <div style={styles.eightColumns}>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c17.r1" value={nameArr[16]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c17.r2" value={doseArr[16]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c17.r3" value={freqArr[16]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c17.r4" value={purposeArr[16]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicationReconciliationField name="page8.c17.r5" value={last[16]} />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c17.r6" />
            </div>
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <div style={{ position: 'relative', left: '30%' }}>
              <MedicalPassportCheckbox name="page8.c17.r7" />
            </div>
          </div>
          <div>
            <MedicationReconciliationField name="page8.c17.r1" />
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            border: '1px solid black',
            borderTop: '1px solid black',
            borderBottom: 0,
            lineHeight: '1.6em',
            padding: '0 0.1rem',
          }}
        >
          <MedicalPassportCheckbox
            name="additionalPage"
            label="Medication Reconciliation continued (see additional page)"
          />
        </div>
        <div style={{ ...styles.sectionHeader, fontWeight: 'bold' }}>PRESCRIPTIONS GIVEN AT DISCHARGE</div>
        <div
          style={{
            lineHeight: '1.6em',
            display: 'grid',
            gridTemplateColumns: '35% 7.5% 7.5% 12% 18% 20%',
            textAlign: 'center',
            border: '1px solid black',
            borderBottom: 0,
            borderRight: '1px solid black',
            fontWeight: 'bold',
          }}
        >
          <div style={{ borderRight: '1px solid black' }}>Medication</div>
          <div style={{ borderRight: '1px solid black' }}>Dosage</div>
          <div style={{ borderRight: '1px solid black' }}>Route</div>
          <div style={{ borderRight: '1px solid black' }}>Frequency</div>
          <div style={{ borderRight: '1px solid black' }}>Indication</div>
          <div style={{}}>Last Dose Given</div>
        </div>

        <div style={styles.sixColumns}>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicalPassportField name="page8.c18.r1" />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicalPassportField name="page8.c18.r2" />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicalPassportField name="page8.c18.r3" />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicalPassportField name="page8.c18.r4" />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicalPassportField name="page8.c18.r5" />
          </div>
          <div>
            <MedicalPassportField name="page8.c18.r6" />
          </div>
        </div>

        <div style={styles.sixColumns}>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicalPassportField name="page8.c19.r1" />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicalPassportField name="page8.c19.r2" />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicalPassportField name="page8.c19.r3" />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicalPassportField name="page8.c19.r4" />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicalPassportField name="page8.c19.r5" />
          </div>
          <div>
            <MedicalPassportField name="page8.c19.r6" />
          </div>
        </div>

        <div style={styles.sixColumns}>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicalPassportField name="page8.c20.r1" />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicalPassportField name="page8.c20.r2" />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicalPassportField name="page8.c20.r3" />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicalPassportField name="page8.c20.r4" />
          </div>
          <div style={{ borderRight: '1px solid black' }}>
            <MedicalPassportField name="page8.c20.r5" />
          </div>
          <div>
            <MedicalPassportField name="page8.c20.r6" />
          </div>
        </div>
        <div>
          <MedicalPassportCheckbox name="copy" label="Copy given to PT" />
        </div>
        <div style={{ float: 'right' }}>
          <div style={styles.box}>
            <div
              style={{
                width: '50%',
                height: 'auto',
                margin: '0 auto',
                padding: '10px',
                position: 'relative',
                opacity: '.5',
              }}
            >
              PATIENT LABEL
            </div>
          </div>
        </div>
        <br></br>
        <strong>
          <div style={{ textAlign: 'left' }}>Signature Review of Medications Across the Patient Care Continuum</div>
        </strong>
        <div style={styles.signatureBox1}>
          <div>
            <div style={{ paddingTop: '1.5rem' }}>______________________________</div>
            <div>Pre-Op </div>
          </div>
        </div>
        <div style={styles.signatureBox1}>
          <div>
            <div style={{ paddingTop: '1.5rem' }}>_________________________</div>
            <div>PACU Signature</div>
          </div>
          <div>
            <div style={{ marginLeft: '1rem', marginTop: '1.5rem' }}>_______________________________</div>
            <div>Surgeon Signature</div>
          </div>
        </div>
        <br></br>
        <div>Please bring this medication record with you to your physician office/pharmacist</div>
      </div>
    </div>
  );
};

export default Page5;
