import React from 'react';
import { RouteComponentProps } from 'react-router';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import { gql, useSubscription } from '@apollo/client';
import { format } from 'date-fns';
import TrackingSessionBeaconLog from './TrackingSessionBeaconLog';
import CurrentTrackingSession from '../../CurrentTrackingSession';

interface TrackingSessionBeaconProps extends RouteComponentProps<{ sessionId: string; beaconId: string }> {
  baseUrl: string;
}

const TrackingSessionBeacon = ({ match, history, baseUrl }: TrackingSessionBeaconProps) => {
  const classes = useStyles();

  const sessionId = match.params.sessionId;
  const beaconId = match.params.beaconId;

  const { data } = useSubscription<{
    trackingSession: {
      id: string;
      startedBy: number;
      startedAt: string;
      stoppedBy: number;
      stoppedAt: string;
      bytesLogged: number;
    };
  }>(
    gql`
      subscription trackingSession($trackingSessionId: String!) {
        trackingSession(trackingSessionId: $trackingSessionId) {
          id
          startedBy
          startedAt
          stoppedBy
          stoppedAt
          bytesLogged
        }
      }
    `,
    {
      variables: { trackingSessionId: sessionId },
    }
  );

  const session = data?.trackingSession;

  return (
    <div className={classes.root}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link component={RouterLink} to={baseUrl}>
          Tracking Insights
        </Link>
        <Link component={RouterLink} to={baseUrl + '/' + sessionId}>
          Session {session ? format(session.startedAt, 'MMMM D, YYYY; HH:mm') : sessionId}
        </Link>
        <Typography>Beacon {beaconId}</Typography>
      </Breadcrumbs>
      <CurrentTrackingSession history={history} baseUrl={match.url} />
      <TrackingSessionBeaconLog sessionId={sessionId} beaconId={beaconId} />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

export default TrackingSessionBeacon;
