import { useStyles as useDocumentUploaderStyles } from '../FileUploader';
import { useDropzone } from 'react-dropzone';
import { useTheme } from '@material-ui/core/styles';
import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useApolloClient, useQuery } from '@apollo/client';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import Icon from '@material-ui/core/Icon';
import { AppBar, Typography } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '../Tooltip';
import IconButton from '@material-ui/core/IconButton';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import MuiTooltip from '@material-ui/core/Tooltip';
import Image from 'material-ui-image';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { UploadSection } from '../../types/Upload';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
// @ts-ignore
import { FilePicker } from 'react-file-picker';
// @ts-ignore
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import '../lightboxStyles.css';
import Slide from '@material-ui/core/Slide';
import { AddAPhoto, ArrowDownward, Delete, Print } from '@material-ui/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

const CircularProgressWithLabel = (props: any) => (
  <Box
    position="absolute"
    display="inline-flex"
    zIndex="999"
    top="50%"
    left="50%"
    style={{ transform: 'translate(-50%, -50%)' }}
  >
    <CircularProgress variant="indeterminate" {...props} />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="caption" component="div" color="textSecondary" />
    </Box>
  </Box>
);

interface DeleteDocumentDialogProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
  open: boolean;
  title: string;
  text: string;
}

const DeleteDocumentDialog: FC<DeleteDocumentDialogProps> = ({ setOpen, onConfirm, title, text, open }) => {
  const handleClose = async (confirm: boolean) => {
    setOpen(false);
    if (confirm) {
      await onConfirm();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            No
          </Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

interface UploadedFile {
  type: string;
  data: any;
  name: string;
}

interface DocumentUploaderSectionProps {
  isAllowedToView: boolean;
  isAllowedToEdit: boolean;
  entityId: number;
  entity: any;
  section: UploadSection;
  uploadedFilesQuery: any;
  deleteUploadedFileMutation: any;
  uploadFileRequestQuery: any;
  confirmFileUploadMutation: any;
}

const FileUploaderSection: FC<DocumentUploaderSectionProps> = ({
  isAllowedToView,
  isAllowedToEdit,
  entityId,
  entity,
  section,
  uploadedFilesQuery,
  deleteUploadedFileMutation,
  uploadFileRequestQuery,
  confirmFileUploadMutation,
}) => {
  const theme = useTheme();
  const classes = useDocumentUploaderStyles();
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [preview, setPreview] = useState<number | null>(null);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmLightboxOpen, setConfirmLightboxOpen] = useState(false);
  const [itemUploadings, setItemUploadings] = useState<any[]>([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const isMd = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  const uploadedDocumentsData = useQuery(uploadedFilesQuery, { variables: { sectionId: section.id, ...entity } });
  const uploadedFiles = useMemo(
    () => uploadedDocumentsData.data?.uploadedForms ?? [],
    [uploadedDocumentsData.data?.uploadedForms]
  );

  useEffect(() => {
    const items = uploadedFiles.map((file: any) => {
      let extractedName = file.substring(file.lastIndexOf('/') + 1);
      extractedName = extractedName.substring(section.id.toString().length + 1, extractedName.indexOf('?'));
      return {
        type: file.indexOf('.pdf') > -1 ? 'file' : 'picture',
        data: file,
        name: decodeURI(extractedName),
      };
    });
    setFiles(items);
  }, [section.id, uploadedFiles]);

  const onDrop = (files: any[]) => isAllowedToEdit && files && files.length > 0 && handleFilePickerChange(files[0]);

  const { getRootProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: ['image/*', '.pdf'],
    onDrop,
  });

  const handleImageOpen = (i: number) => {
    setPreview(i);
  };

  const handleImageClose = () => {
    setPreview(null);
  };

  const client = useApolloClient();

  const handleDelete = async (i: number) => {
    setPreview(null);
    const deleteFileName = files[i]?.name;
    const formValues = files.map((item, index) => ({ id: index, fileName: item.name })).filter(item => item.id !== i);

    client
      .mutate({
        mutation: deleteUploadedFileMutation,
        variables: { sectionId: section.id, ...entity, fileName: deleteFileName, value: JSON.stringify(formValues) },
      })
      .then(result => {
        if (result.data) {
          setFiles([...files.slice(0, i), ...files.slice(i + 1)]);
        } else {
          console.warn('Error deleting file:', deleteFileName);
        }
      })
      .catch(reason => alert(reason));
  };

  const handleDeleteConfirm = () => {
    setConfirmationOpen(true);
  };

  const handleFilePickerChange = async (file: any) => {
    if (!file) return;

    const reader = new FileReader();
    reader.readAsDataURL(file);

    console.log('images', files);

    reader.onload = async e => {
      const data = e?.target?.result;
      setFiles([
        ...files,
        {
          type: file.type.indexOf('pdf') > -1 ? 'file' : 'picture',
          data: data,
          name: file.name + '_' + files.length,
        },
      ]);
      await processUpload(files, data, file.name, file.type);
    };
  };

  const handleFilePickerError = (error: any) => {
    alert(error);
  };

  const processUpload = async (existingItems: UploadedFile[], newItem: any, fileName: string, contentType: string) => {
    try {
      const newFileName = fileName + '_' + existingItems.length;
      const preSignQueryResult = await client.query({
        query: uploadFileRequestQuery,
        variables: { sectionId: section.id, ...entity, fileName: newFileName, contentType },
      });

      const preSignedUrl = preSignQueryResult?.data?.uploadRequest;
      if (!Boolean(preSignedUrl)) {
        console.error('PreSign URL invalid');
        return;
      }
      console.log('File upload url', preSignedUrl);

      const formValues = existingItems.map((item, index) => ({ id: index, fileName: item.name }));
      formValues.push({ id: existingItems.length, fileName: newFileName });

      console.log('Uploading…', formValues);
      setItemUploadings([...itemUploadings, existingItems.length]);
      try {
        await uploadFileToPreSignedUrl(preSignedUrl, newItem, contentType, fileName, false);
      } finally {
        setItemUploadings([...itemUploadings]);
      }

      const confirmUploadQueryResult = await client.mutate({
        mutation: confirmFileUploadMutation,
        variables: { sectionId: section.id, ...entity, fileName: newFileName, value: JSON.stringify(formValues) },
      });

      const url = confirmUploadQueryResult.data.confirmFormUpload;

      if (url) {
        setFiles(prev => {
          const index = prev.findIndex(image => image.name === newFileName);
          if (index >= 0) {
            const next = [...prev];
            next[index] = { ...next[index], data: url };
            return next;
          } else {
            return prev;
          }
        });
        console.log('Upload confirmed!');
      } else {
        console.error('Error while confirming upload. Try reuploading!');
      }
    } catch (e) {
      console.error('Error while processing upload', e);
    }
  };

  // if we do not have dataUri, we would set noConvert param
  const uploadFileToPreSignedUrl = async (
    url: string,
    file: any,
    contentType: string,
    name: string,
    noConvert: boolean
  ) => {
    try {
      const headers = new Headers();
      headers.append('pragma', 'no-cache');
      headers.append('Content-Type', contentType);
      if (name && name.indexOf('.pdf') < 0) {
        headers.append('Content-Disposition', 'attachment; filename=' + name);
      }
      const response = await fetch(url, {
        method: 'PUT',
        body: noConvert ? file : convertDataURItoBlob(file),
        headers,
      });

      if (response.status === 200) {
        console.log('File uploaded to S3!');
      } else {
        console.warn('Error uploading file', response);
      }
    } catch (e) {
      console.error('Uploading error', e);
    }
  };

  const convertDataURItoBlob = (dataURI: any) => {
    let byteString;
    let mimeString;
    let ia;

    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = encodeURI(dataURI.split(',')[1]);
    }
    // split the mime component
    mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes to a typed array
    ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  };

  const handleThumbnailClick = (url: any) => {
    const thumbIndex = onlyImages().findIndex(item => item.url === url);
    if (thumbIndex > -1) {
      setLightboxIndex(thumbIndex);
    } else {
      setLightboxIndex(0);
    }
    setLightboxOpen(true);
  };

  const handleDeleteLightBox = () => {
    setLightboxOpen(false);
    setConfirmLightboxOpen(true);
  };

  const handleDeleteImageLightbox = () => {
    let index = lightboxIndex;
    if (index < 0 || index > files.length - 1) index = 0;

    const urlToRemove = files[lightboxIndex].data;
    const imageIndex = files.findIndex(item => item.data === urlToRemove);
    if (imageIndex > -1) {
      handleDelete(imageIndex);
    }
  };

  const handleDownloadLightBox = () => {
    const lightboxImages = onlyImages();
    let index = lightboxIndex;
    if (index < 0 || index > lightboxImages.length - 1) index = 0;

    let imageName = lightboxImages[index].name;
    imageName = imageName.substring(0, imageName.lastIndexOf('_'));

    const urlToDownload = lightboxImages[index].url;
    const link = document.createElement('a');
    link.download = imageName;
    link.target = '_blank';
    // @ts-ignore
    link.href = urlToDownload;
    link.text = 'Image download';
    link.dispatchEvent(new MouseEvent('click'));
    URL.revokeObjectURL(link.href);
  };

  const handlePrintImage = () => {
    const lightboxImages = onlyImages();
    let index = lightboxIndex;
    if (index < 0 || index > lightboxImages.length - 1) index = 0;

    const urlToPrint = lightboxImages[index].url;

    const win: any = window.open();
    win.document.write(
      `<img style='max-width: 100%' src='${urlToPrint}' onload="window.print()" alt="document print" />`
    );
    win.focus();
  };

  const onlyImages = () =>
    files.filter(image => image.type === 'picture').map(image => ({ name: image.name, url: image.data }));

  const lightboxMainSrc = () => onlyImages()[lightboxIndex].url;

  const lightboxNextSrc = () => {
    const items = onlyImages();
    return items[(lightboxIndex + 1) % items.length].url;
  };

  const lightboxPrevSrc = () => {
    const items = onlyImages();
    return items[(lightboxIndex + items.length - 1) % items.length].url;
  };

  const lightboxMovePrev = () => {
    const items = onlyImages();
    setLightboxIndex((lightboxIndex + items.length - 1) % items.length);
  };

  const lightboxMoveNext = () => {
    setLightboxIndex((lightboxIndex + 1) % onlyImages().length);
  };

  // @ts-ignore
  return (
    <Box
      className={clsx(classes.panel, {
        [classes.panelMain]: section.mainCategory,
      })}
      style={{ flex: 1, borderColor: isDragActive ? '#00ff0e' : theme.palette.divider }}
      {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
    >
      <Box pl={2} pr={1} py={1} display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Box mr={1} display="flex">
            <Icon color="disabled">{section.content}</Icon>
          </Box>
          <Typography variant="subtitle2">{section.name}</Typography>
        </Box>

        <Hidden mdUp>
          <label htmlFor={'documentPhoto' + entityId + section.id}>
            {/* @ts-ignore */}
            <Tooltip content={!isAllowedToEdit ? 'You don’t have sufficient permissions to upload a document.' : null}>
              <Box>
                <IconButton color="primary" component="span" disabled={!isAllowedToEdit}>
                  <AddAPhoto fontSize={'small'} />
                  <input
                    type="file"
                    id={'documentPhoto' + entityId + section.id}
                    name={'documentPhoto' + entityId + section.id}
                    accept="image/*"
                    capture="environment"
                    style={{ display: 'none' }}
                    disabled={!isAllowedToEdit}
                    onChange={e =>
                      isAllowedToEdit && handleFilePickerChange(e.target.files ? e.target.files[0] : undefined)
                    }
                  />
                </IconButton>
              </Box>
            </Tooltip>
          </label>
        </Hidden>

        <Hidden smDown>
          {/* @ts-ignore */}
          <Tooltip content={!isAllowedToEdit ? 'You don’t have sufficient permissions to upload a document.' : null}>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              edge="end"
              size="small"
              disabled={!isAllowedToEdit}
            >
              <FilePicker
                extensions={['jpg', 'jpeg', 'png', 'pdf']}
                maxSize={50}
                disabled={!isAllowedToEdit}
                onChange={handleFilePickerChange}
                onError={handleFilePickerError}
              >
                <Icon color="primary" className={clsx({ [classes.opacity]: !isAllowedToView })}>
                  publish
                </Icon>
              </FilePicker>
            </IconButton>
          </Tooltip>
        </Hidden>
      </Box>

      {lightboxOpen && (
        <Lightbox
          mainSrc={lightboxMainSrc()}
          nextSrc={lightboxNextSrc()}
          prevSrc={lightboxPrevSrc()}
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={lightboxMovePrev}
          onMoveNextRequest={lightboxMoveNext}
          toolbarButtons={[
            <IconButton onClick={handleDownloadLightBox} className={classes.lightboxIcon}>
              <ArrowDownward fontSize={'small'} />
            </IconButton>,
            <IconButton onClick={handleDeleteLightBox} className={classes.lightboxIcon}>
              <Delete fontSize={'small'} />
            </IconButton>,
            <IconButton onClick={handlePrintImage} className={classes.lightboxIcon}>
              <Print fontSize={'small'} />
            </IconButton>,
          ]}
        />
      )}

      <DeleteDocumentDialog
        open={confirmLightboxOpen}
        setOpen={setConfirmLightboxOpen}
        title={'Confirm action'}
        text={'Are you sure you want to delete this image?'}
        onConfirm={handleDeleteImageLightbox}
      />

      {files.length !== 0 ? (
        <div className={classes.root}>
          <GridList cols={isMd ? 4 : 6} cellHeight="auto" style={{ flex: 1 }}>
            {files.map((image, index) => (
              <GridListTile key={index}>
                <Box
                  display="flex"
                  alignItems="stretch"
                  className={clsx(classes.tile, { [classes.blurred]: !isAllowedToView })}
                >
                  {itemUploadings.indexOf(index) > -1 ? <CircularProgressWithLabel /> : null}
                  {image.type === 'picture' ? (
                    <MuiTooltip
                      /* @ts-ignore */
                      title={
                        isAllowedToView ? null : 'You don’t have sufficient permissions to edit or view this document.'
                      }
                    >
                      <Image
                        className={[section.name, image.name].join(' ')}
                        key={index + section.name + image.name}
                        src={image.data}
                        onClick={() => isAllowedToView && handleThumbnailClick(image.data)}
                        color={theme.palette.background.default}
                        loading={<CircularProgress size={16} />}
                        animationDuration={1500}
                        aspectRatio={1}
                        style={{
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                        }}
                        imageStyle={{
                          margin: 'auto',
                          objectFit: 'cover',
                        }}
                      />
                    </MuiTooltip>
                  ) : (
                    <MuiTooltip
                      title={
                        isAllowedToView
                          ? image.name.substring(0, image.name.lastIndexOf('_'))
                          : 'You don’t have sufficient permissions to view this document.'
                      }
                      className={classes.tooltip}
                    >
                      <IconButton onClick={() => isAllowedToView && handleImageOpen(index)}>
                        <Icon fontSize="large" color="primary">
                          picture_as_pdf
                        </Icon>
                      </IconButton>
                    </MuiTooltip>
                  )}
                </Box>
              </GridListTile>
            ))}
          </GridList>
        </div>
      ) : (
        <Box
          pb={2}
          px={2}
          flex={1}
          textAlign="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {section.mainCategory ? (
            <Box mb={2}>
              <Icon style={{ fontSize: '4rem' }} color="disabled">
                publish
              </Icon>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                No documents have been uploaded yet. Drag 'n' drop some files here, or click button to select files.
              </Typography>
            </Box>
          ) : (
            <Box mb={2}>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Drag 'n' drop some files here, or click button to select files.
              </Typography>
            </Box>
          )}
        </Box>
      )}

      <Dialog
        fullScreen={preview !== null && files[preview] && files[preview].type === 'file'}
        open={preview !== null}
        onClose={handleImageClose}
        /* @ts-ignore */
        TransitionComponent={Transition}
      >
        {preview !== null && files[preview] ? (
          files[preview].type === 'picture' ? (
            <img src={files[preview].data} style={{ maxWidth: '100%' }} alt="preview" />
          ) : (
            <React.Fragment>
              <AppBar color="transparent" position="relative" elevation={0}>
                <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={handleImageClose} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    {files[preview].name.substring(0, files[preview].name.lastIndexOf('_'))}
                  </Typography>
                  <Button startIcon={<DeleteIcon />} variant="outlined" color="secondary" onClick={handleDeleteConfirm}>
                    Delete
                  </Button>
                </Toolbar>
              </AppBar>

              <iframe title={files[preview].name} src={files[preview].data} style={{ width: '100%', height: '100%' }} />
            </React.Fragment>
          )
        ) : null}

        <DeleteDocumentDialog
          open={confirmationOpen}
          setOpen={setConfirmationOpen}
          title={'Confirm action'}
          text={'Are you sure you want to delete this file?'}
          onConfirm={async () => {
            if (preview !== null) {
              await handleDelete(preview);
            }
          }}
        />
      </Dialog>
    </Box>
  );
};

export default FileUploaderSection;
