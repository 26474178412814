import React from 'react';
import styles from '../../styles';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import MedicalPassportField from '../../components/MedicalPassportField';
import { age65AndOlderNo, age65AndOlderYes, toBooleanNegative } from '../../../../../../questionnaire/fallRiskReducers';
import toSex from '../../../../../../questionnaire/toSex';
import toAge from '../../../../../../questionnaire/toAge';

const Page7 = ({ value }) => (
  <div id="page7" className="page">
    <div style={styles.center}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div />
        <h2 style={styles.underlineHeading}>Fall Risk Assessment</h2>
        <div></div>
      </div>

      <div
        style={{
          marginBottom: '0.4rem',
          width: '100%',
          display: 'grid',
          gridTemplateRows: '1fr 1fr',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <MedicalPassportField name="procedure.patient.name" label="Patient" fontWeight={'bold'} fontSize={16} />
          <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} />
          <MedicalPassportField name="procedure.patient.providerId" label="MRN" />
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <MedicalPassportField name="procedure.physician.name" label="Surgeon" />
          <MedicalPassportField name="sex" label="Sex" path={['demographicInformation', 'sex']} reducer={toSex} />
          <MedicalPassportField name="age" label="Age" path={['demographicInformation', 'dob']} reducer={toAge} />
          <MedicalPassportField name="procedure.serviceTime" label="DOS" />
        </div>
      </div>
    </div>
    <div>
      <div style={styles.oneColumn}>Fall Risk Assessment</div>
      <div style={styles.threeNotEqualColumns3}>
        <div style={{ textAlign: 'center', padding: '0.5rem', borderRight: '1px solid black' }}>Risk Factor(s)</div>
        <div style={{ textAlign: 'center', padding: '0.5rem', borderRight: '1px solid black' }}>Scale</div>
        <div style={{ textAlign: 'center', padding: '0.5rem' }}>Score</div>
      </div>
      <div style={styles.threeNotEqualColumns3}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRight: '1px solid black',
            gridRow: '1 / 3',
          }}
        >
          Age: 65 and older
        </div>
        <div
          style={{
            padding: '0.25rem',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
          }}
        >
          <MedicalPassportCheckbox
            name="fallRiskAssessment.age65AndOlderYes"
            label="Yes"
            path={['demographicInformation', 'dob']}
            reducer={age65AndOlderYes}
          />
        </div>
        <div
          style={{
            textAlign: 'center',
            borderBottom: '1px solid black',
            padding: '0.25rem',
          }}
        >
          1
        </div>
        <div style={{ borderRight: '1px solid black', padding: '0.25rem' }}>
          <MedicalPassportCheckbox
            name="fallRiskAssessment.age65AndOlderNo"
            label="No"
            path={['demographicInformation', 'dob']}
            reducer={age65AndOlderNo}
          />
        </div>
        <div style={{ textAlign: 'center', padding: '0.25rem' }}>0</div>
      </div>
      <div style={styles.threeNotEqualColumns3}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            borderRight: '1px solid black',
            gridRow: '1 / 3',
          }}
        >
          <div style={{ textAlign: 'center' }}>History of Falls* (within 6 month)</div>
        </div>
        <div
          style={{
            padding: '0.25rem',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
          }}
        >
          <MedicalPassportCheckbox
            name="fallRiskAssessment.historyOfFallsYes"
            label="Yes"
            path={['basicscreening', 'q1']}
          />
        </div>
        <div
          style={{
            textAlign: 'center',
            borderBottom: '1px solid black',
            padding: '0.25rem',
            color: value?.historyOfFallsYes ? 'red' : 'black',
          }}
        >
          7
        </div>
        <div style={{ borderRight: '1px solid black', padding: '0.25rem' }}>
          <MedicalPassportCheckbox
            name="fallRiskAssessment.historyOfFallsNo"
            label="No"
            path={['basicscreening', 'q1']}
            reducer={toBooleanNegative}
          />
        </div>
        <div style={{ textAlign: 'center', padding: '0.25rem' }}>0</div>
      </div>
      <div style={styles.threeNotEqualColumns3}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRight: '1px solid black',
            gridRow: '1 / 3',
          }}
        >
          Ambulatory Aid**
        </div>
        <div
          style={{
            padding: '0.25rem',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
          }}
        >
          <MedicalPassportCheckbox
            name="fallRiskAssessment.ambulatoryAidYes"
            label="Yes : Crutches / Cane / walker"
            path={['basicscreening', 'device']}
          />
        </div>
        <div
          style={{
            textAlign: 'center',
            borderBottom: '1px solid black',
            padding: '0.25rem',
          }}
        >
          7
        </div>
        <div style={{ borderRight: '1px solid black', padding: '0.25rem' }}>
          <MedicalPassportCheckbox
            name="fallRiskAssessment.ambulatoryAidNo"
            label="No : None"
            path={['basicscreening', 'device']}
            reducer={toBooleanNegative}
          />
        </div>
        <div style={{ textAlign: 'center', padding: '0.25rem' }}>0</div>
      </div>
      <div style={styles.threeNotEqualColumns3}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRight: '1px solid black',
            gridRow: '1 / 3',
          }}
        >
          Secondary Diagnosis
        </div>
        <div
          style={{
            padding: '0.25rem',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
          }}
        >
          <MedicalPassportCheckbox
            name="fallRiskAssessment.secondaryDiagnosisYes"
            label="Yes : IF MORE than one medical diagnosis is listed"
          />
        </div>
        <div
          style={{
            textAlign: 'center',
            borderBottom: '1px solid black',
            padding: '0.25rem',
          }}
        >
          1
        </div>
        <div style={{ borderRight: '1px solid black', padding: '0.25rem' }}>
          <MedicalPassportCheckbox
            name="secondaryDiagnosisNo"
            label="No : IF NO more than one medical diagnosis listed"
          />
        </div>
        <div
          style={{
            textAlign: 'center',
            padding: '0.25rem',
          }}
        >
          0
        </div>
      </div>
      <div style={styles.threeNotEqualColumns3}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRight: '1px solid black',
            gridRow: '1 / 3',
          }}
        >
          Gait / Transferring***
        </div>
        <div
          style={{
            padding: '0.25rem',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
          }}
        >
          <MedicalPassportCheckbox
            name="fallRiskAssessment.gaitTransferringYes"
            label="Yes: Weak or Unsteady/sensory Impairment (i.e.) Neuropathy, Visual, Auditory, etc))/Immobile, assistive device"
          />
        </div>
        <div
          style={{
            textAlign: 'center',
            borderBottom: '1px solid black',
            padding: '0.25rem',
          }}
        >
          7
        </div>
        <div style={{ borderRight: '1px solid black', padding: '0.25rem' }}>
          <MedicalPassportCheckbox name="fallRiskAssessment.gaitTransferringNo" label="No: Normal" />
        </div>
        <div style={{ textAlign: 'center', padding: '0.25rem' }}>0</div>
      </div>
      <div style={styles.threeNotEqualColumns3}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRight: '1px solid black',
            gridRow: '1 / 3',
          }}
        >
          Medications (Incl. Opiates, anticonvulsants, hypnotics, sedatives and psychotropics) Check Med Rec
        </div>
        <div
          style={{
            padding: '0.25rem',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
          }}
        >
          <MedicalPassportCheckbox name="fallRiskAssessment.fallRiskMedicstionsYes" label="Yes" />
        </div>
        <div
          style={{
            textAlign: 'center',
            borderBottom: '1px solid black',
            padding: '0.25rem',
          }}
        >
          1
        </div>
        <div style={{ borderRight: '1px solid black', padding: '0.25rem' }}>
          <MedicalPassportCheckbox name="fallRiskAssessment.fallRiskMedicstionsNo" label="No" />
        </div>
        <div
          style={{
            textAlign: 'center',
            padding: '0.25rem',
          }}
        >
          0
        </div>
      </div>
      <div style={styles.threeNotEqualColumns3}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRight: '1px solid black',
            gridRow: '1 / 3',
          }}
        >
          Mental Status
        </div>
        <div
          style={{
            padding: '0.25rem',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
          }}
        >
          <MedicalPassportCheckbox
            name="fallRiskAssessment.mentalStatusYes"
            label="Yes: Lack of understanding of physical and cognitive limitations"
          />
        </div>
        <div
          style={{
            textAlign: 'center',
            borderBottom: '1px solid black',
            padding: '0.25rem',
          }}
        >
          1
        </div>
        <div style={{ borderRight: '1px solid black', padding: '0.25rem' }}>
          <MedicalPassportCheckbox name="fallRiskAssessment.mentalStatusNo" label="No: Oriented to Own Ability" />
        </div>
        <div style={{ textAlign: 'center', padding: '0.25rem' }}>0</div>
      </div>
      <div style={styles.twoNotEqualColumns4}>
        <div style={{ textAlign: 'right', padding: '0.5rem', borderRight: '1px solid black' }}>Total Score:</div>
        <div style={{ textAlign: 'center', padding: '0.5rem' }}>
          <MedicalPassportField name="fallRiskAssessment.totalScore" />
        </div>
      </div>
    </div>
    <div style={{ textAlign: 'center', padding: '0.5rem' }}>
      To obtain the Fall Risk Score, add the score from each category:
    </div>
    <div>
      <div style={{ ...styles.oneColumn, fontWeight: 'bold' }}>Fall Risk Score Interpretation</div>
      <div style={styles.twoEqualColumns3}>
        <div style={{ padding: '0.5rem', borderRight: '1px solid black' }}>High Risk</div>
        <div style={{ padding: '0.5rem' }}>7 or more</div>
      </div>
      <div style={{ ...styles.twoEqualColumns3, borderBottom: '1px solid black' }}>
        <div style={{ padding: '0.5rem', borderRight: '1px solid black' }}>Universal Fall Risk</div>
        <div style={{ padding: '0.5rem' }}>0-6</div>
      </div>
    </div>
    <div style={styles.signatureBox1}>
      <div style={{ paddingTop: '1.5rem' }}>PreOp Nurse Signature: ______________________________</div>
      <div style={{ marginLeft: '1rem', marginTop: '1.5rem' }}>Date/Time: _______________________</div>
    </div>
    <h2 style={{ textAlign: 'center', marginTop: 160, fontSize: 18, fontWeight: 'normal' }}>Universal Fall Risk</h2>
  </div>
);

export default Page7;
