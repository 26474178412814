import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import PatientSlotContainer from './PatientSlotContainer';
import PatientInfo from './PatientInfo';
import BedInfo from './BedInfo';
import { INTERNAL_MONITOR, PACU_TABLET, POST_OP_TABLET, PREP_TABLET } from '../../entities/screens/enums';
import ChatButton from '../../chat/ChatButton';
import { CaretakerChatDialog } from '../../chat/ChatDialog';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, IconButton } from '@material-ui/core';
import CaretakerMessage from './CaretakerMessage';
import MessageIcon from '@material-ui/icons/Message';
import Popover from '@material-ui/core/Popover';

const useStyles = makeStyles(theme =>
  createStyles({
    chatButton: {
      paddingLeft: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

const PatientSlot = ({
  caretakerMessage,
  status,
  physician,
  procedure,
  patient,
  patientType,
  bed,
  showPriority,
  priority,
  timeEntered,
  poseRef,
  preventAnimation,
  isFamilyReadyPreOp,
  ready,
  readyForSurgeon,
  readyForOr,
  isHoldProcedure,
  isBlockNerve,
  frameColor,
  familyReady,
  size,
  roomName,
  screenType,
  expectedExitTime,
  hideBedInfo,
  patientData,
  preOpFocused,
  showPreOpNote,
  preOpNote,
  showPacuNote,
  pacuNote,
  hideChart,
  isDPU,
  showRoom,
  showDuration,
  hospitalOverviewMode,
  blockNerveFollow,
  called,
  isPreOp,
}) => {
  const classes = useStyles();
  const [anchor, setAnchor] = useState(null);
  const tooltipOpen = Boolean(anchor);

  const isInternalScreen = screenType === INTERNAL_MONITOR && caretakerMessage;
  const isPreOpScreen = screenType === PREP_TABLET;
  const isPacuScreen = screenType === PACU_TABLET;
  const isPostOpScreen = screenType === POST_OP_TABLET;

  const showChatIcon = useMemo(() => {
    if (!patientData?.caretakerConsent) return false;
    if (hospitalOverviewMode) return true;

    return !!patientData?.notificationNumbers && (isPacuScreen || isPreOpScreen || isPostOpScreen);
  }, [patientData, isPacuScreen, isPreOpScreen, isPostOpScreen, hospitalOverviewMode]);
  const showDisabledChatIcon = !patientData?.caretakerConsent && hospitalOverviewMode;

  const handlePopoverOpen = event => {
    setAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  return (
    <Box
      onClick={handlePopoverOpen}
      onBlur={handlePopoverClose}
      aria-owns={tooltipOpen ? 'mouse-over-popover' : undefined}
      aria-haspopup={!!tooltipOpen}
    >
      <PatientSlotContainer
        status={status}
        ref={poseRef}
        preventAnimation={preventAnimation}
        border={(ready || isFamilyReadyPreOp) && !isDPU ? '#00B63E' : frameColor}
        size={size}
      >
        <Popover
          id="mouse-over-popover"
          style={{ pointerEvents: 'none' }}
          open={tooltipOpen}
          anchorEl={anchor}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableRestoreFocus
          onClose={handlePopoverClose}
        >
          {'No caretaker consent'}
        </Popover>
        <PatientInfo
          physician={physician}
          patient={patient}
          patientType={patientType}
          procedure={procedure}
          timeEntered={timeEntered}
          ready={ready}
          readyForSurgeon={readyForSurgeon}
          readyForOr={readyForOr}
          familyReady={familyReady}
          isHoldProcedure={isHoldProcedure}
          isBlockNerve={isBlockNerve}
          bed={bed}
          size={size}
          roomName={roomName}
          expectedExitTime={expectedExitTime}
          showPreOpNote={showPreOpNote}
          showPacuNote={showPacuNote}
          preOpNote={preOpNote}
          pacuNote={pacuNote}
          hideChart={hideChart}
          isDPU={isDPU}
          showRoom={showRoom}
          showDuration={showDuration}
          blockNerveFollow={blockNerveFollow}
          called={called}
          isPreOp={isPreOp}
        />
        <Box className={classes.chatButton}>
          {showChatIcon ? (
            <ChatButton
              hasUnreadMessages={patientData?.caretakerThread?.hasUnreadMessages}
              chatComponent={CaretakerChatDialog}
              chatProps={{
                patient: patientData,
              }}
            />
          ) : null}
          {showDisabledChatIcon ? (
            <IconButton color="primary" size="small" edge="end" disabled>
              <MessageIcon fontSize="large" />
            </IconButton>
          ) : null}
        </Box>
        {isInternalScreen && (
          <CaretakerMessage phoneNumber={caretakerMessage?.phoneNumber} message={caretakerMessage?.content} />
        )}
        {!hideBedInfo && (
          <BedInfo preOpFocused={preOpFocused} bed={bed} showPriority={showPriority} priority={priority} size={size} />
        )}
      </PatientSlotContainer>
    </Box>
  );
};

PatientSlot.propTypes = {
  patient: PropTypes.string.isRequired,
};

export default PatientSlot;
