import React from 'react';

import HtmlButton, { buttonType } from './html/Button';
import { AbsoluteSpinner } from './Spinner';

/**
 * Good old `<button>`.
 */
export default ({ children, busy, disabled, ...rest }) => (
  <HtmlButton {...rest} disabled={busy || disabled} busy={busy} style={rest.style}>
    {children}
    {busy ? <AbsoluteSpinner strokeAccessor={`button.${buttonType(rest)}.color`} {...rest} /> : null}
  </HtmlButton>
);
