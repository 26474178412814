import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { compose, withProps } from 'recompose';

import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import EntityEmptyState from '../../../se/components/entity-states/EntityEmptyState';

import get from 'lodash/get';
import { inTakeFormColumns, inTakeFormListColumns } from './columns';
import schema, { intakeStatistics, procedureStatistics, setIntakeStatus } from '../../../graph/procedures';
import EntityView from '../../../se/components/entity/EntityView';
import QuestionnaireAnswers, { QuestionnaireTypeContext } from './QuestionnaireAnswers';
import Filters from '../../pages/analytics/Filters';
import ProcedureEventLog, { intakeStatusLabels, procedureStatuses, filterIntakeEvents } from './ProcedureEventLog';
import ProcedureInput from './ProcedureInput';
import { withTheme } from '../../../se/theme';
import ScheduledProcedureMobileListItem from './components/ScheduledProcedureMobileListItem';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { withSession } from '../../../state/Session';
import { unpackSessionObject } from '../../pages/unpackSessionObject';
import { a11yProps, TabPanel } from './TabPanel';
import { DashboardFilter } from './DashboardFilter';
import useCareAppAccess from './utils/useCareAppAccess';
import { NamedRange } from '../../core/DatePicker';
import intakeProceduresSource from '../../../omnisearch/sources/hospital/intakeProcedures';
import pick from 'lodash/fp/pick';
import Forms from './Forms';
import { useMutation, useQuery } from '@apollo/client';
import { procedureForms } from '../../../graph/surgeon/forms';
import { DescriptionTwoTone } from '@material-ui/icons';
import { getLastProcedureEvent } from '../../../util/procedureEvents';
import { ProcedureStatus } from '../../../types/ProcedureEvent';
import { FormType } from '../../../types/Form';
import { format } from 'date-fns';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import SelectInput from '../../../se/components/inputs/SelectInput';
import { DefaultTitle } from '../../../se/components/entity/EntityRouter';
import defaultTo from 'lodash/defaultTo';
import IntakePackageGenerator from './IntakePackageGenerator';
import FileUploaderIntake from '../../FileUploaderIntake';
import PatientVisits from './PatientVisits';
import { withScope } from '../../../contexts/ScopeContext';

const Empty = withProps({
  title: `There are no scheduled procedures at the moment.`,
  hint: '',
  illustration: withTheme(theme => get(theme, 'illustration.patients')),
})(EntityEmptyState);

function tabSwitcher(state, action) {
  if (state.preopComplete) {
    switch (action.type) {
      case 'init':
        return { ...state, switchToTab: 1 };
      case 'formsExists':
        return state.formsLoaded
          ? state
          : action.formsExists
          ? { ...state, switchToTab: 2, formsLoaded: true }
          : { ...state, formsLoaded: true };
      default:
        return state;
    }
  } else {
    return state;
  }
}

const StatusWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 1rem;
  min-width: 16rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

const StatusSelectInput = withProps({
  placeholder: 'Status',
  isSearchable: false,
  menuWidthFollowContent: true,
  isClearable: true,
  name: 'status',
})(SelectInput);

const Title = ({ data }) => {
  const [updateIntakeStatus] = useMutation(setIntakeStatus);
  const handleIntakeStatusChange = async e => {
    await updateIntakeStatus({
      variables: {
        id: data.id,
        status: e,
      },
    });
  };

  return (
    <DefaultTitle>
      {data ? `#${defaultTo(data.id, '#')} - ${data.patientName ? data.patientName : 'Unknown'}` : '-'}
      <StatusWrapper>
        <Box mr={2}>
          <StatusSelectInput
            onChange={handleIntakeStatusChange}
            value={data?.intakeStatus}
            options={intakeStatusLabels}
            isClearable={true}
          />
        </Box>
      </StatusWrapper>
    </DefaultTitle>
  );
};

const ProcedureAndInTakeForm = props => {
  const { scope, accessToken, data } = props;
  useCareAppAccess(get(scope, 'hospital.id'), accessToken);
  const procedureId = data?.id;
  const lastPreOpCompletedEvent = getLastProcedureEvent(data?.events || [], ProcedureStatus.PreOpCompleted);

  const {
    data: queryData,
    loading,
    error,
  } = useQuery(procedureForms, { variables: { procedureId, formType: FormType.InTakeForm } });
  const forms = queryData?.procedureForms || [];

  const formsExists = queryData?.procedureForms?.length > 0;

  const [tab, setTab] = useState(null);
  const tabNumber = tab || 0;

  const procedureEvents = (data?.events || []).filter(event => event.type !== 'Note');

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Fragment>
      <EntityView {...props} />
      {procedureId && <FileUploaderIntake procedureId={procedureId} />}
      {formsExists && (
        <Box mt={6} minWidth={0}>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr .5fr' }}>
            <Tabs indicatorColor={'primary'} value={tabNumber} onChange={handleChange} aria-label="Form Tabs">
              <Tab label="Registration Package Questionnaire" {...a11yProps(0)} />
              <Tab label="Forms" {...a11yProps(1)} />
              <Tab label="Patient Visits" {...a11yProps(2)} />
              <Tab label="Procedure Events" {...a11yProps(3)} />
            </Tabs>
            {procedureId && <IntakePackageGenerator procedureId={procedureId} />}
          </div>
          <TabPanel value={tabNumber} index={0}>
            <QuestionnaireTypeContext.Provider value="InTake Form">
              <QuestionnaireAnswers
                entryQuestionnaire={get(props, 'data.inTakeForm')}
                procedure={get(props, 'data', {})}
              />
            </QuestionnaireTypeContext.Provider>
          </TabPanel>
          <TabPanel value={tabNumber} index={1}>
            <Forms procedureId={procedureId} forms={forms} lastPreOpCompletedEvent={lastPreOpCompletedEvent} />
          </TabPanel>
          <TabPanel value={tabNumber} index={2}>
            <PatientVisits
              visits={props?.data?.visits}
              currentVisit={props?.data?.id}
              baseUrlProvider={props.baseUrlProvider}
            />
          </TabPanel>
          <TabPanel value={tabNumber} index={3}>
            <ProcedureEventLog filterEvents={filterIntakeEvents} events={procedureEvents} name="Procedure Events" />
          </TabPanel>
        </Box>
      )}
      {!formsExists && (
        <Box mt={6}>
          <QuestionnaireTypeContext.Provider value="InTake Form">
            <QuestionnaireAnswers
              entryQuestionnaire={get(props, 'data.inTakeForm')}
              procedure={get(props, 'data', {})}
            />
          </QuestionnaireTypeContext.Provider>
        </Box>
      )}
    </Fragment>
  );
};

const CustomFilter = withProps({
  textSearchKey: 'name',
  hideProcedureStatusSelectInput: true,
  hideIntakeStatusSelectInput: false,
  hideCategorySelectInput: true,
  hideSpecialitySelectInput: false,
  hideProcedureTypeSelectInput: true,
  futurePicker: true,
  defaultValue: {
    dateRange: NamedRange.tomorrow(),
  },
})(Filters);

const CustomDashboardFilter = withProps({
  subscription: intakeStatistics,
  CustomFilter: CustomFilter,
  items: [
    {
      title: 'Procedures Scheduled',
      getter: data => get(data, 'intakeStatistics.scheduledProcedures', '-'),
      icon: <DescriptionTwoTone />,
    },
    {
      title: 'Patient Submitted',
      getter: data => get(data, 'intakeStatistics.totalForms', '-'),
      icon: <DescriptionTwoTone />,
    },
  ],
})(DashboardFilter);

const ScheduledProceduresCRUD = GraphQLCRUD({
  entityName: 'RegistrationPackage',
  scheme: { ...schema, list: schema.inTakeFormsSubscription, item: schema.inTakeForm },
  List: {
    tableKey: 'RegistrationPackage',
    MobileItemComponent: ScheduledProcedureMobileListItem,
    useColumnSelection: true,
    columns: inTakeFormListColumns,
    Empty,
    FilterComponent: CustomDashboardFilter,
    FilterPrefix: 'sc',
    defaultFilterValues: {
      dateRange: NamedRange.tomorrow(),
    },
    pickFilter: pick([
      'name',
      'physician',
      'procedureType',
      'hospital',
      'intakeStatus',
      'dateRange',
      'category',
      'speciality',
      'view',
    ]),
  },
  Show: {
    columns: inTakeFormColumns,
    View: compose(withSession(unpackSessionObject), withScope)(ProcedureAndInTakeForm),
    Empty,
    Title,
  },
  Edit: {
    Input: ProcedureInput,
    skipRefetch: true,
    prepareUpdateData: ({ patientDateOfBirth, ...rest }) => {
      console.log('rest1234', rest);
      return {
        ...rest,
        patientDateOfBirth: format(patientDateOfBirth, 'YYYY-MM-DD'),
      };
    },
    mapEditItemProps: ({ data }) => ({
      data: {
        ...data,
        scheduledProcedure: {
          ...data?.scheduledProcedure,
          patientName: data?.scheduledProcedure?.patientNameUnformatted,
          patientDateOfBirth: data?.scheduledProcedure?.patientDateOfBirthISO,
        },
      },
    }),
  },
  searchSource: intakeProceduresSource,
});

export default ScheduledProceduresCRUD;
