import React from 'react';
import styled from 'styled-components';
import Icon from '../../../se/components/Icon';
import { withTheme } from '../../../se/theme';

const Span = styled.span`
  cursor: pointer;
  color: ${withTheme(theme => theme.textColor.string())};
  opacity: ${props => (props.active ? 1 : 0.6)};
`;

export const Button = React.forwardRef(({ className, ...props }, ref) => <Span {...props} ref={ref} />);

export const EditorIcon = React.forwardRef(({ className, ...props }, ref) => <Icon {...props} ref={ref} />);

const ToolbarWrapper = styled.div`
  position: relative;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding-bottom: 0.25rem;

  & > * {
    display: inline-block;
  }

  & > * + * {
    margin-left: 1rem;
  }
`;

export const Toolbar = React.forwardRef(({ className, ...props }, ref) => <ToolbarWrapper {...props} ref={ref} />);
