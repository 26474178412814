import React, { ReactNode } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

interface VitalsButtonProps {
  title: string;
  icon: ReactNode;
}

const VitalsButton = ({ title, icon }: VitalsButtonProps) => {
  const classes = useStyles();

  return (
    <Box display="flex" className={classes.root}>
      {icon}
      <Typography variant="caption" color="textSecondary" style={{ lineHeight: 1 }}>
        {title}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '.5rem',
    flex: 1,
  },
});

export default VitalsButton;
