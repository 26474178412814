import React, { useEffect, useMemo, useState } from 'react';
import objectHash from 'object-hash/dist/object_hash';
import { isASubscriptionOperation } from 'subscriptions-transport-ws/dist/utils/is-subscriptions';
import { graphql } from '@apollo/client/react/hoc';
import isFunction from 'lodash/isFunction';

const SubscriptionRelay = ({ onChange, ...props }) => {
  const hash = objectHash(JSON.parse(JSON.stringify(props)));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onChange(props), [hash]);
  return null;
};

export default function (document, operationOptions) {
  try {
    const isSubscription = isASubscriptionOperation(document);

    if (isSubscription && isFunction(operationOptions.options)) {
      return Component => props => {
        const [subscription, setSubscription] = useState();

        const hash = objectHash(JSON.parse(JSON.stringify({ document, options: operationOptions.options(props) })));

        // eslint-disable-next-line react-hooks/exhaustive-deps
        const Relay = useMemo(() => graphql(document, operationOptions)(SubscriptionRelay), [hash]);

        return (
          <>
            <Relay {...props} onChange={setSubscription} />
            {subscription && <Component {...props} {...subscription} />}
          </>
        );
      };
    } else {
      return graphql(document, operationOptions);
    }
  } catch (_) {
    return graphql(document, operationOptions);
  }
}
