import React from 'react';
import { roundValue } from '../Analytics';
import styled from 'styled-components';
import responsive from '../../../se/utilities/responsive';

const TextBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${responsive.md.andSmaller`
    flex-flow: column;
  `}
  > * + * {
    margin-left: 1em;
  }
`;

const TextBox = styled.div`
  display: flex;
  width: 100%;
  padding: 1em 0.5em;
  border: 2px solid #2e3762;
  align-items: center;
`;

const ColorLine = styled.div`
  flex-direction: row;
  width: 10%;
  border-top: 4px solid ${props => props.color};
  margin-right: 0.5em;
`;

export const bookedColor = '#F55323';
export const utilizedColor = '#00A7F7';

export default ({ bookings, utilization }) => (
  <TextBoxContainer>
    <TextBox>
      <ColorLine color={bookedColor} />
      <label>{`${bookings}% Slots Booked from Total Capacity`}</label>
    </TextBox>
    <TextBox>
      <ColorLine color={utilizedColor} />
      <label>{`${roundValue(utilization)}% Slots Utilized From Total Capacity`}</label>
    </TextBox>
  </TextBoxContainer>
);
