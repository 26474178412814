import React from 'react';
import { useValue } from '../ValueContext';
import { useQuestionnaireBooleanValue } from '../hooks';
import toBoolean from '../../../../../questionnaire/toBoolean';

const MedicalPassportCheckbox = ({ label, name, path, reducer, style, negative }) => {
  const { defaultValue, value, setValue } = useValue(name);
  const questionnaireValue = useQuestionnaireBooleanValue(reducer || toBoolean, path);

  const questionnaire = negative ? !(questionnaireValue || defaultValue) : questionnaireValue || defaultValue;
  return (
    <div style={{ display: 'flex', ...style }}>
      <label>
        <input
          type="checkbox"
          name={name}
          checked={value ?? (questionnaire || false)}
          onChange={e => {
            setValue(e.target.checked);
          }}
        />
        {label}
      </label>
    </div>
  );
};

export default MedicalPassportCheckbox;
