import React from 'react';
import styled from 'styled-components';
import hipaaBadge from '../assets/images/HIPAA-Compliance-Verification.png';

const Link = styled.a`
  display: flex;
  align-items: center;
`;

const HipaaBadge = () => (
  <Link href="https://compliancy-group.com/hipaa-compliance-verification" target="_blank">
    <img
      className="aligncenter wp-image-13813"
      src={hipaaBadge}
      alt="HIPAA Seal of Compliance"
      width="160"
      height="auto"
      style={{ maxWidth: '100%' }}
    />
  </Link>
);

export default HipaaBadge;
