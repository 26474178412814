import { useEffect } from 'react';
import config from '../../../../config';
import get from 'lodash/get';

const useCareAppAccess = (organizationId, accessToken) => {
  useEffect(() => {
    const handleMessage = event => {
      if (event.origin !== config.careURL) {
        return;
      }

      if (get(event.data, 'method') === 'GetAccessToken') {
        event.source.postMessage({ accessToken, organizationId }, config.careURL);
      }
    };

    window.addEventListener('message', handleMessage, false);

    return () => window.removeEventListener('message', handleMessage);
  }, [accessToken, organizationId]);
};

export default useCareAppAccess;
