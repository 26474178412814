import { Query } from '@apollo/client/react/components';
import React from 'react';
import { hospitalRatingStats } from '../../../graph/feedback';
import { get } from 'lodash';
import { StarRating } from '../../entities/feedback/columns';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import pick from 'lodash/fp/pick';

const useStyles = makeStyles(theme => ({
  inlineTooltip: {
    marginLeft: theme.spacing(1),
  },
  tooltipText: {
    borderBottom: `1px dotted ${theme.palette.divider}`,
  },
  group: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    flexShrink: 0,
  },
}));

export const roundToDecimal = val => (val ? (Math.round(val * 10) / 10).toFixed(1) : val);

const Group = ({ children }) => {
  const classes = useStyles();

  return (
    <Box component={Paper} className={classes.group}>
      {children}
    </Box>
  );
};
const noRatingsTooltip = `
  Make sure the patient leaves their cell phone number at the front desk and they will automatically receive an SMS asking them to rate their experience once the procedure is over.'
`;

export const HospitalRatingWidget = ({ stats, loading }) => {
  const classes = useStyles();

  if (loading) {
    return (
      <Group>
        <span style={{ height: 24, width: 100 }}> </span>
      </Group>
    );
  }
  if (stats) {
    return (
      <Group isFlex>
        <Link component={RouterLink} to={'feedback'}>
          {roundToDecimal(stats.averageRating)}
        </Link>
        <Box component={RouterLink} mx={1} to={'feedback'}>
          <StarRating rating={stats.averageRating} />
        </Box>
        <Tooltip title={`${stats.votes} patients left feedback, total turnout is at ${stats.turnout}%`}>
          <Typography to={'feedback'}>{`(${stats.votes} / ${stats.turnout}%)`}</Typography>
        </Tooltip>
      </Group>
    );
  }
  return (
    <Box flexShrink={0}>
      <Alert severity="info">
        No patient ratings yet.
        <Tooltip title={noRatingsTooltip} className={classes.inlineTooltip}>
          <span className={classes.tooltipText}>How to invite patients to leave feedback?</span>
        </Tooltip>
      </Alert>
    </Box>
  );
};

const pickFilter = pick([
  'name',
  'physician',
  'procedureType',
  'hospital',
  'status',
  'dateRange',
  'category',
  'speciality',
]);

export default ({ filter: { dateRange, ...rest } }) => (
  <Query
    query={hospitalRatingStats}
    variables={{ filter: { dateRange: dateRange.toJSON(), ...pickFilter(rest) } }}
    fetchPolicy="network-only"
  >
    {({ loading, data = {} }) =>
      data ? <HospitalRatingWidget stats={get(data, 'hospitalRatingStats')} loading={loading} /> : undefined
    }
  </Query>
);
