import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import Dropdown from '../../core/Dropdown';
import DatePicker, { createRanges, Range } from '../../core/DatePicker';
import TextInput from '../../../se/components/inputs/TextInput';
import { LocalDate, Period } from '@js-joda/core';
import PropTypes from 'prop-types';

const FilterContainer = styled.div`
  position: relative;
  > div {
    height: 100%;
  }
`;

const noop = () => {};

const DummyTextInput = styled(TextInput)`
  color: transparent;
  text-shadow: 0 0 0 ${props => props.theme.textColor.string()};
  width: 100%;
  height: 100%;
  &:focus {
    outline: none;
  }
`;

const PickerDropdownContent = ({
  close,
  today,
  value,
  onChange,
  ranges,
  customPicker,
  style,
  title,
  picker,
  setPicker,
}) => {
  const handleChange = useCallback(
    (next, done) => {
      setPicker(next);

      if (done) {
        close(() => onChange(next));
      }
    },
    [setPicker, close, onChange]
  );

  return (
    <div>
      {title && <label style={{ padding: '1em', display: 'block' }}>{title}</label>}
      <DatePicker today={today} value={picker} onChange={handleChange} ranges={ranges} customPicker={customPicker} />
    </div>
  );
};

export const DateFilter = ({ today, value, onChange, ranges, style, title, ...props }) => {
  const [picker, setPicker] = useState(value);

  useEffect(() => {
    setPicker(value);
  }, [value]);

  const defaultToday = useMemo(() => LocalDate.now(), []);
  const defaultValue = useMemo(() => Range.of(defaultToday, Period.ofDays(1), defaultToday), [defaultToday]);
  const defaultOnChange = useMemo(() => () => {}, []);
  const defaultRanges = useMemo(() => createRanges({ today: defaultToday }), [defaultToday]);

  const dropdownContentProps = {
    today: today || defaultToday,
    value: value || defaultValue,
    onChange: onChange || defaultOnChange,
    ranges: ranges || defaultRanges,
    style,
    title,
    picker,
    setPicker,
  };

  return (
    <FilterContainer style={style}>
      <Dropdown contentComponent={PickerDropdownContent} contentProps={dropdownContentProps} {...props}>
        <DummyTextInput
          placeholder="Filter By Date"
          value={value.toString()}
          onChange={noop}
          autoComplete="off"
          disabled={props.disabled}
        />
      </Dropdown>
    </FilterContainer>
  );
};

DateFilter.propTypes = {
  today: PropTypes.instanceOf(LocalDate),
  value: PropTypes.instanceOf(Range).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DateFilter;
