import React, { FC } from 'react';
import StaffShiftRoom from '../../../../../types/StaffShift';
import StaffShift from '../../../../../types/StaffShift';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, createStyles, Theme } from '@material-ui/core';
import formatTimeRange from '../../../calendar/util/formatTimeRange';
import StaffShifts from './StaffShifts';
import Typography from '@material-ui/core/Typography';
import { useScheduleUserContext } from '../vendor/context/ScheduleUserContext';
import Button from '@material-ui/core/Button';
import Procedures from '../../../kiosk/schedule/edit/Procedures';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';

const styles = {
  accordionRoot: {
    margin: 0,
  },
  accordionHeader: {
    padding: '0 .5em',
  },
  accordionContent: {
    padding: 0,
  },
};

const StaffShiftRoomBox: FC<{ shift: StaffShiftRoom }> = ({ shift }) => {
  const classes = useStyles();
  const coworkersShifts: StaffShift[] = shift?.coworkersShifts || [];
  const { isAssignedRoomShift, isAnsweredRoomShift, isEditing, acceptRoomShift, rejectRoomShift } =
    useScheduleUserContext();
  const isAssigned = isAssignedRoomShift(shift);
  const isAnswered = isAnsweredRoomShift(shift);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography>{shift?.room?.name || 'Room'}</Typography>
        <Typography>{formatTimeRange(shift?.from, shift?.to)}</Typography>
      </div>
      {(shift?.procedures || [])?.length > 0 && (
        <Accordion style={styles.accordionRoot}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} style={styles.accordionHeader}>
            <Typography>{`Procedures: ${(shift?.procedures || [])?.length || 0}`}</Typography>
          </AccordionSummary>
          <AccordionDetails style={styles.accordionContent}>
            <Procedures procedures={shift?.procedures || []} />
          </AccordionDetails>
        </Accordion>
      )}
      <div className={classes.box1}>
        <StaffShifts staffShifts={[shift as StaffShift, ...coworkersShifts]} type={'RoomShift'} />
        {((isAssigned && !isAnswered) || isEditing(shift?.id ?? null, 'RoomShift')) && (
          <div className={classes.actions}>
            <Button onClick={() => rejectRoomShift(shift?.id)} variant="outlined" color="primary" fullWidth>
              Reject
            </Button>
            <Button onClick={() => acceptRoomShift(shift?.id)} variant="contained" color="primary" fullWidth>
              Accept
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0.5em',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: theme.spacing(1),
      background: 'rgba(255,255,255,0.1)',
    },
    box1: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1em',
      padding: theme.spacing(1),
      background: 'rgba(255, 255, 255, 0.05)',
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      gap: '1em',
    },
  })
);

export default StaffShiftRoomBox;
