import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import scheme from '../../../graph/config';
import { compose, withProps } from 'recompose';
import { withLabel } from '../../../se/components/Label';
import TextInput from '../../../se/components/inputs/TextInput';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import { H2 } from '../../../se/components/typography';
import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { ConfigType } from '../../../types/HospitalConfig';
import ExpandableText from '../../core/ExpandableText';

const Input = withProps({
  schema: {
    name: compose(
      withLabel('Name'),
      withProps({
        placeholder: 'e.g., Patient late',
        autoComplete: 'off',
        required: true,
      })
    )(TextInput),
  },
})(ObjectInput);

export const nameColumn = {
  title: 'Name',
  key: 'name',
  lens: item => JSON.parse(item.values || '{}')?.name,
  Component: props => <ExpandableText text={props.data} />,
};

const columns = [nameColumn];

const prepareCreateData = formData => ({
  type: ConfigType.ProcedureDelayReason,
  values: JSON.stringify(formData),
});

const prepareUpdateData = formData => ({
  id: formData?.id,
  values: JSON.stringify(formData),
});

export const mapEditItemProps = ({ data }) => {
  if (data) {
    const obj = {
      ...data,
      config: {
        ...data.config,
        ...JSON.parse(data.config.values || '{}'),
      },
    };
    return { data: obj };
  }
};

const Title = () => <H2>Procedure Delay Reasons</H2>;

const ProcedureDelayReasons = GraphQLCRUD({
  entityName: 'Config',
  scheme,
  List: {
    tableKey: 'Config',
    columns,
    Title,
    itemsProvider: graphql(scheme.list, {
      options: () => ({
        variables: {
          type: ConfigType.ProcedureDelayReason,
          fetchPolicy: 'cache-and-network',
        },
      }),
    }),
  },
  Create: {
    Input,
    prepareCreateData,
    refetchQueries: [{ query: scheme.list, variables: { type: ConfigType.ProcedureDelayReason } }],
  },
  Show: {
    columns,
  },
  Edit: {
    Input,
    mapEditItemProps,
    prepareUpdateData,
    refetchQueries: [{ query: scheme.list, variables: { type: ConfigType.ProcedureDelayReason } }],
    removeRefetchQueries: [{ query: scheme.list, variables: { type: ConfigType.ProcedureDelayReason } }],
  },
});

export default ProcedureDelayReasons;
