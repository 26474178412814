import React, { Fragment } from 'react';
import styled from 'styled-components';
import LinkButton from '../../../se/components/LinkButton';
import ErrorFactory from '../../../se/components/errors/Error';
import RemoveButton from '../../RemoveButton';
import scheme from '../../../graph/transitionRules';
import RoomTypeSelectInput from './RoomTypeSelectInput';
import TimeoutInput from './TimeoutInput';
import { isDefinedAndNotNull } from '../../../se/utilities/check';
import EntityState from '../../../se/components/EntityState';
import { Paragraph } from '../../../se/components/typography';
import { withTheme } from '../../../se/theme';
import { Mutation, Query } from '@apollo/client/react/components';

const Error = ErrorFactory();

const Label = styled.p`
  margin: 0;
  color: ${withTheme(theme => theme.textColor.string())};
  font-weight: 500;
  opacity: 0.8;
  margin-bottom: 0.5rem;
`;

const RowLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.5fr;
  grid-gap: 0.5em;

  margin-bottom: 1rem;
`;

const ActionButtonsLayout = styled.div`
  display: flex;
  flex-direction: row;
  > button {
    flex: 1;
  }
`;

const FormWrapper = styled.div`
  margin: 1rem 0;
`;

const EmptyStateContainer = ({ data, children }) => {
  if (isDefinedAndNotNull(data) && data.length > 0) {
    return children;
  } else {
    return (
      <EntityState
        title={'No Rules Configured.'}
        hint={`System regard any bracelet transition as valid until there is at least one rule.`}
      />
    );
  }
};

const getHash = (values = []) => values.reduce((acc, _) => `${acc}|${_.fromRoom}${_.toRoom}${_.timeoutSeconds}|`, '');
const sanitizeVariables = items =>
  items.map(({ fromRoom, toRoom, timeoutSeconds }) => ({ fromRoom, toRoom, timeoutSeconds }));

class ListForm extends React.PureComponent {
  state = {
    values: [],
    initialHash: '',
    initialValues: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const initialValues = nextProps.initialValues;
    if (getHash(prevState.initialValues) !== getHash(initialValues)) {
      return {
        ...prevState,
        initialValues,
        initialHash: getHash(initialValues),
        values: initialValues || [],
      };
    }
    return null;
  }

  onSubmit = mutation => e => {
    e.preventDefault();
    mutation({
      variables: {
        transitionRules: sanitizeVariables(this.state.values),
      },
      refetchQueries: [{ query: scheme.list }],
    });
  };

  updateCompleted = items => {
    if (items) {
      this.setState({
        values: items.batchUpdateTransitionRule,
        initialValues: [],
      });
    }
  };

  removeItem = idx => () => {
    const values = [...this.state.values];
    values.splice(idx, 1);
    this.setState({ values: values });
  };

  addItem = () => {
    const emptyValue = {
      fromRoom: null,
      toRoom: null,
      timeoutSeconds: 0,
    };

    this.setState({ values: [...this.state.values, emptyValue] });
  };

  itemChange = (id, field) => data => {
    const values = [...this.state.values];
    values[id][field] = data;
    this.setState({ values: values });
  };

  render() {
    const { values, initialHash } = this.state;
    const isDirty = initialHash !== getHash(values);

    return (
      <Mutation mutation={scheme.batchUpdate} onCompleted={this.updateCompleted}>
        {(updateRules, { loading, error }) => (
          <FormWrapper>
            <form onSubmit={this.onSubmit(updateRules)}>
              <EmptyStateContainer data={values}>
                <Fragment>
                  <Paragraph style={{ paddingBottom: '2em' }}>
                    Each row represents one valid transition, all others are considered invalid and ignored.
                    <br />
                    Timeout in seconds represents the time that needs to pass before a transition is triggered (0 means
                    immediately).
                  </Paragraph>

                  <RowLayout>
                    <Label>Transition From</Label>
                    <Label>Transition To</Label>
                    <Label>Timeout (seconds)</Label>
                  </RowLayout>

                  {values.map(({ fromRoom, toRoom, timeoutSeconds }, index) => (
                    <RowLayout key={index}>
                      <RoomTypeSelectInput value={fromRoom} onChange={this.itemChange(index, 'fromRoom')} />
                      <RoomTypeSelectInput value={toRoom} onChange={this.itemChange(index, 'toRoom')} />
                      <TimeoutInput value={timeoutSeconds} onChange={this.itemChange(index, 'timeoutSeconds')} />
                      <RemoveButton type="button" onClick={this.removeItem(index)}>
                        Remove
                      </RemoveButton>
                    </RowLayout>
                  ))}
                </Fragment>
              </EmptyStateContainer>

              <ActionButtonsLayout>
                <LinkButton type="button" onClick={this.addItem} busy={loading}>
                  Add New Rule Set
                </LinkButton>
                {isDirty && <LinkButton busy={loading}>Save Changes</LinkButton>}
              </ActionButtonsLayout>

              <Error isVisible={!!error} id="TransitionRulesFormError">
                <p>An unexpected error has occurred. Please, try again or contact our support.</p>
              </Error>
            </form>
          </FormWrapper>
        )}
      </Mutation>
    );
  }
}

const TransitionRules = props => (
  <Query query={scheme.list}>
    {({ loading, error, data = {} }) => <ListForm initialValues={data.transitionRules} />}
  </Query>
);

export default TransitionRules;
