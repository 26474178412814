import React, { CSSProperties, useEffect, useState } from 'react';
import {
  Box,
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import { breakpoint } from '../../utilities/responsive';

export interface Option {
  label: string;
  status?: string;
  primary?: boolean;
  loading?: boolean;
  onClick?: () => Promise<void> | void;
}

interface ButtonWithOptionsProps {
  options: Array<Option>;
  primaryOptions: Array<Option>;
  secondaryOptions: Array<Option>;
  renderOption?: (option: Option) => React.ReactNode;
  loading?: boolean;
  onClick?: (option: Option, index: number) => void | Promise<void>;
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'outlined' | 'contained';
  popperStyles?: CSSProperties;
}

function ButtonWithOptions({
  options,
  primaryOptions,
  secondaryOptions,
  loading,
  popperStyles,
  variant = 'outlined',
  size = 'small',
}: ButtonWithOptionsProps) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [clicked, setClicked] = useState<string | undefined>();
  const isMobile = window.innerWidth < breakpoint.md;

  useEffect(() => {
    setClicked(c => (c ? undefined : c));
  }, [options]);

  const handleClickPrimary =
    (index: number, label?: string) => async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      setClicked(label);
      await primaryOptions[index].onClick?.();
      // setClicked(undefined);
    };

  const handleToggle = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setOpen(prevOpen => !prevOpen);
  };

  const handleClickSecondary =
    (index: number, label?: string) => async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      setClicked(label);
      await secondaryOptions[index].onClick?.();
      // setOpen(false);
      // setClicked(undefined);
    };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return primaryOptions.length > 0 || secondaryOptions.length > 0 ? (
    <Box>
      {primaryOptions.length > 0 ? (
        <ButtonGroup size={size} variant={variant} color="primary" ref={anchorRef} aria-label="split button">
          {primaryOptions &&
            primaryOptions.map((primaryOption, index) => (
              <Button
                key={index}
                onClick={handleClickPrimary(index, primaryOption?.loading ? primaryOption.label : undefined)}
                disabled={!!clicked}
              >
                {clicked === primaryOption.label && primaryOption?.loading && (
                  <CircularProgress size={20} style={{ marginRight: 4 }} color="inherit" />
                )}
                {primaryOption.label}
              </Button>
            ))}
          <Button onClick={handleToggle} disabled={!!clicked}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      ) : (
        <div ref={anchorRef}>
          <Button
            size={size}
            color="primary"
            onClick={handleToggle}
            disabled={!!clicked}
            endIcon={<ArrowDropDownIcon />}
          >
            More
          </Button>
        </div>
      )}
      <Popper
        open={open}
        placement={isMobile ? 'bottom-start' : 'bottom-end'}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={popperStyles ? { zIndex: 1, width: 300, ...popperStyles } : { zIndex: 1, width: 300 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              {/* @ts-ignore*/}
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {secondaryOptions &&
                    secondaryOptions.map((secondaryOption, index) => (
                      <MenuItem
                        key={index}
                        onClick={handleClickSecondary(
                          index,
                          secondaryOption?.loading ? secondaryOption.label : undefined
                        )}
                        disabled={!!clicked}
                      >
                        {clicked === secondaryOption.label && secondaryOption?.loading && (
                          <CircularProgress size={20} style={{ marginRight: 4 }} color="inherit" />
                        )}
                        {secondaryOption.label}
                      </MenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  ) : null;
}

export default ButtonWithOptions;
