import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';

export default ({ item, columns, index: rowIndex, onClick }) =>
  columns.map(({ lens, Component }) => (
    <Fragment key={rowIndex}>
      <Box onClick={onClick} interactive>
        <Component data={lens(item)} />
      </Box>
    </Fragment>
  ));
