import color from 'color';
import colors from './colors';
import merge from 'lodash/merge';
import theme from './se/theme';

const textSize = {
  xs: '0.75rem',
  s: '0.875rem',
  m: '1rem',
  l: '1.2rem',
  xl: '1.5rem',
};

/**
 * Values to override in default theme to make it light.
 */
export const light = {
  colors,
  panel: {
    background: color('#f4f5f7'),
  },
  backgroundColor: colors.lightest,
  textColor: color('#000C3F'),
  secondaryTextColor: color('#535f93'),
  color: {
    default: color('#E6ECEF'),
    focus: colors.primary,
  },
  popover: {
    background: color('#17214C'),
  },
  border: {
    color: {
      default: color('#E6ECEF'),
    },
  },
  button: {
    default: {
      backgroundColor: color('#00A7F7'),
      color: colors.lightest,
    },
    primary: {
      backgroundColor: color('#00A7F7'),
      color: colors.lightest,
    },
    danger: {
      backgroundColor: color('#ff9999'),
      color: color('#b13030'),
    },
  },
  default: {
    backgroundColor: color('#E9EFF0'),
    color: colors.text,
    disabled: {
      opacity: '0.4',
    },
  },
  primary: {
    backgroundColor: colors.primary,
    color: color('#fff'),
    disabled: {
      opacity: '0.4',
    },
  },
  danger: {
    backgroundColor: colors.danger,
    color: color('#fff'),
    disabled: {
      opacity: '0.4',
    },
  },
  input: {
    backgroundColor: colors.lightest,
  },
  link: {
    primaryColor: color('#00A7F7'),
    dangerColor: color.danger,
  },
  list: {
    backgroundColor: colors.lightest.darken(0.035),
    backgroundColorHover: colors.lightest.darken(0.05),
  },
  logo: 'dark',
  sidebar: {
    backgroundColor: colors.lightest,
    textColor: color('#000C3F'),
  },
  labelOpacity: 1,
  scrollbar: {
    background: colors.lightest.darken(0.05),
    thumb: colors.light.darken(0.05),
    outline: colors.light.darken(0.1),
  },
};

/**
 * Values to override in default theme to make it dark.
 */
export const dark = {
  colors,
  backgroundColor: colors.dark,
  textColor: colors.light,
  secondaryTextColor: color('#91c4ff'),
  panel: {
    background: color('#17214C'),
  },
  popover: {
    background: color('#17214C'),
  },
  border: {
    color: {
      default: color('#2F375A'),
    },
  },
  button: {
    primary: {
      backgroundColor: color('#00A7F7'),
      color: colors.lightest,
    },
    default: {
      backgroundColor: color('#1a2553'),
      color: color('#E6ECEF'),
    },
  },
  logo: 'light',
  list: {
    backgroundColor: colors.dusk,
    backgroundColorHover: colors.dusk.darken(0.1),
  },
  input: {
    backgroundColor: colors.dark.string(),
  },
  sidebar: {
    backgroundColor: color('#000C3F'),
    textColor: color('#E6ECEF'),
  },
  warningColor: color('#FFBD00'),
  successColor: color('#009624'),
  scrollbar: {
    background: color('#000C3F'),
    thumb: color('#3D568A'),
    outline: color('#3D568A'),
  },
};

/**
 * Default theme values.
 */
export default {
  colors,
  defaultSpacing: '1rem',
  backgroundColor: colors.lightest,
  warningColor: color('#FFBD00'),
  dangerColor: color('#b13030'),
  successColor: color('#009624'),
  textColor: colors.dark,
  secondaryTextColor: color('#91c4ff'),
  toaster: {
    info: {
      textColor: color('white'),
      backgroundColor: color('#328af2'),
    },
    success: {
      textColor: color('white'),
      backgroundColor: color('#009624'),
    },
    warning: {
      textColor: color('white'),
      backgroundColor: color('#FF8B00'),
    },
    error: {
      textColor: color('white'),
      backgroundColor: color('#FF8B00'),
    },
  },
  textSize,
  linkColor: colors.primary.lighten(0.2),
  border: {
    color: {
      default: colors.light,
      focus: colors.primary,
    },
  },
};

export const withTheme = f => props => f(merge({}, theme, props.theme), props);
