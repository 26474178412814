import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import PhoneIcon from '@material-ui/icons/Phone';
import React, { FC } from 'react';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';

export const PhoneNumberInput: FC<TextFieldProps> = ({ ...rest }) => (
  <TextField
    {...rest}
    variant="filled"
    margin="dense"
    fullWidth
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <PhoneIcon />
        </InputAdornment>
      ),
    }}
  />
);
