import React, { Dispatch, SetStateAction, useState } from 'react';
import ModalWithActions from '../../ModalWithActions';
import Box from '@material-ui/core/Box';
import { FieldRow } from '../../pages/kiosk/schedule/edit/ProcedureEditor';
import DateInput from '../../../se/components/inputs/DateInput';
import TimeInputControl from '../../../se/components/controls/TimeControl';

interface RescheduleConsultationDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onConfirmAction: (date: string, time: string) => () => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
  title?: string;
  confirmActionLabel?: string;
  data: {
    consultationDate?: string;
    consultationTime?: string;
  };
}

const RescheduleConsultationDialog = ({
  open = false,
  setOpen,
  onConfirmAction,
  onCancel,
  title = 'Reschedule Consultation',
  confirmActionLabel = 'Save Changes',
  data: { consultationDate, consultationTime },
}: RescheduleConsultationDialogProps) => {
  const [date, setDate] = useState<string | undefined>(consultationDate);
  const [time, setTime] = useState<string | undefined>(consultationTime);

  const handleConfirmAction = () => {
    date && time && onConfirmAction(date, time)();
    setOpen(false);
  };

  const handleCancel = () => {
    onCancel?.();
    setOpen(false);
  };

  return (
    <ModalWithActions
      open={open}
      handleCancel={handleCancel}
      handleConfirmAction={handleConfirmAction}
      confirmActionLabel={confirmActionLabel}
      title={title}
    >
      <Box display="flex" flexDirection="column">
        <FieldRow>
          <div>
            <label>Consultation Date</label>
            <DateInput value={date} disabled={false} onChange={setDate} required={true} />
          </div>
          <div>
            <label>Consultation Time</label>
            <TimeInputControl name="time" value={time} onChange={setTime} placeholder="12:15" />
          </div>
        </FieldRow>
      </Box>
    </ModalWithActions>
  );
};

export default RescheduleConsultationDialog;
