import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { withTheme } from 'styled-components';
import { withTheme as withSpicyTheme } from '../../../se/theme';
import selectStyles from './selectStyles';

const StyledCreatableSelect = ({
  name,
  value,
  onChange,
  loading,
  options,
  disabled,
  hasError,
  onCreate,
  isMulti,
  styles = {},
  ...rest
}) => {
  const theme = withSpicyTheme(theme => theme)({ ...rest });
  const customStyles = selectStyles({ theme, hasError });
  return (
    <CreatableSelect
      {...rest}
      id={name}
      name={name}
      isMulti={isMulti}
      options={options}
      onChange={onChange}
      onCreateOption={onCreate}
      menuPlacement={'auto'}
      value={value}
      styles={{ ...customStyles, ...styles }}
      isLoading={loading}
      disabled={disabled || loading}
      menuPortalTarget={document.getElementById('select-menu')}
    />
  );
};

export default withTheme(StyledCreatableSelect);
