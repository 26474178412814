import React from 'react';
import { Link } from '@material-ui/core';

export default ({ children }) => (
  <Link
    onClick={e => {
      e.stopPropagation();
    }}
    href={`tel:${children}`}
    target="_blank"
  >
    {children}
  </Link>
);
