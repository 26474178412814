import React from 'react';

import GroupedTabNavigation from '../pages/kiosk/charting/GroupedTabNavigation';
import { withRouter } from 'react-router-dom';
import useTabs from '../pages/kiosk/charting/modules/useTabs';

export default withRouter(({ history, location, match }) => {
  const tabs = useTabs();

  return tabs ? <GroupedTabNavigation tabs={tabs} history={history} location={location} match={match} /> : null;
});
