import React, { FC, useState } from 'react';
import { anesthesiologistPickedInitial, setValueAndResetError } from '../utils/values';
import set from 'lodash/fp/set';
import { transformValue } from '../utils/functions';
import { AutocompleteInputSetter } from './AutocompleteInputSetter';
import { FormParams, StaffDetails } from '../types/types';
import { useMutation } from '@apollo/client';
import { PhoneNumberInput } from './PhoneNumberInput';
import { phoneNumberFormatPipeline } from '../../../../../../se/components/inputs/PhoneInput';
import { EmailInput } from './EmailInput';
import {
  create as createAnesthesiologist,
  list as anesthesiologists,
  update as updateAnesthesiologist,
} from '../../../../../../graph/anesthesiologists';
import Anesthesiologist from '../../../../../../types/Anesthesiologist';

const AnesthesiologistAutocomplete: FC<FormParams<StaffDetails>> = ({ value, setValue }) => {
  const [anesthesiologist, setAnesthesiologist] = useState<Anesthesiologist | null | undefined>();

  const [create] = useMutation(createAnesthesiologist, {
    refetchQueries: [{ query: anesthesiologists }],
  });

  const [update] = useMutation(updateAnesthesiologist, {
    refetchQueries: [{ query: anesthesiologists }],
  });

  const createOption = async (text: string) => {
    const res = await create({
      variables: {
        name: text,
      },
    });
    const created = res?.data?.createAnesthesiologist;
    setAnesthesiologist(created);
    return created;
  };

  const updateOption = (field: string) => async (text: string) => {
    if (!!anesthesiologist) {
      const res = await update({
        variables: {
          ...anesthesiologist,
          [field]: text,
        },
      });

      const updated = res?.data?.editAnesthesiologist;
      setAnesthesiologist(updated);
      return updated;
    }
    return null;
  };

  return (
    <>
      <AutocompleteInputSetter
        list={anesthesiologists}
        label="Anesthesiologist Name"
        variant="filled"
        margin="dense"
        fullWidth
        onChange={event => {
          if (event?.constructor?.name === 'SyntheticEvent') {
            setValue(set('anesthesiologist.name', event?.target?.value)(value));
          } else {
            setValue(set('anesthesiologist', transformValue(event || {}, anesthesiologistPickedInitial))(value));
          }
        }}
        onBlurCreateOption={createOption}
        value={value?.anesthesiologist?.name?.value || ''}
      />

      <PhoneNumberInput
        label="Anesthesiologist Phone Number"
        onChange={event =>
          setValue(set('anesthesiologist.phoneNumber.value', phoneNumberFormatPipeline(event.target.value))(value))
        }
        value={value?.anesthesiologist?.phoneNumber?.value || ''}
        onBlur={() => updateOption('phoneNumber')(value?.anesthesiologist?.phoneNumber?.value || '')}
      />
      <EmailInput
        label="Anesthesiologist Email"
        error={!!value?.anesthesiologist?.email?.error}
        helperText={value?.anesthesiologist?.email?.error}
        value={value?.anesthesiologist?.email?.value || ''}
        onChange={event => setValue(setValueAndResetError('anesthesiologist.email', event.target.value, value))}
        onBlur={() => updateOption('email')(value?.anesthesiologist?.email?.value || '')}
      />
    </>
  );
};

export default AnesthesiologistAutocomplete;
