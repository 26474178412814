import React, { createContext, FC, useContext, useState } from 'react';

interface DictationsApi {
  newDictation: string | null;
  setNewDictation: (newDictation: string) => void;
  clear: () => void;
}


const DictationsContext = createContext<DictationsApi>({
  newDictation: null,
  setNewDictation: (newDictation: string) => {},
  clear: () => {},
});

export const useDictationContext = () => useContext(DictationsContext);

export const DictationsContextProvider: FC<{ children?: React.ReactNode; }> = ({ children }) => {

  const [newDictation, setNewDictation] = useState<string | null>(null);

  return (
    <DictationsContext.Provider value={{ newDictation, setNewDictation: (s: string) => setNewDictation(s), clear: () => setNewDictation(null) }}>
      {children}
    </DictationsContext.Provider>
  );
};

export default DictationsContext;
