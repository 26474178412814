import React, { createContext, useContext } from 'react';
import { ReactNode } from 'react';

const patientIdContext = createContext<number | undefined>(undefined);

export const usePatientIdContext = (): number | undefined => {
  return useContext(patientIdContext);
};

interface PatientIdContextProviderProps {
  patientId?: number;
  children: ReactNode;
}

const PatientIdContextProvider = ({ patientId, children }: PatientIdContextProviderProps) => {
  return <patientIdContext.Provider value={patientId}>{children}</patientIdContext.Provider>;
};

export default PatientIdContextProvider;
