import styled from 'styled-components';
import responsive from '../../se/utilities/responsive';

const GridFader = styled.div`
  display: grid;
  overflow: hidden;
  background-color: pink;

  > div {
    display: grid;
    overflow-y: auto;
    -webkit-overflow-scrolling: auto;
    ${responsive.md.andSmaller`
      *::-webkit-scrollbar {
        width: 0px;
      }
    `}
  }
`;

export default GridFader;
