import React from 'react';
import Box from '@material-ui/core/Box';
import { useQuery } from '@apollo/client';
import { hasConsent, hasSignature } from '../../graph/surgeon/PhysicianSO';
import get from 'lodash/get';
import CheckboxInput from './CheckboxInput';

const SignPhysicianInput = ({ procedure, physician, name, value, onChange, rootValue, disabled = false }) => {
  const phy = get(rootValue, 'physician') || get(procedure, 'physician') || physician;
  const { data: hasSignatureQuery } = useQuery(hasSignature, {
    variables: { id: phy ? phy.id : -1 },
    fetchPolicy: 'network-only',
  });
  const { data: hasConsentQuery } = useQuery(hasConsent, {
    variables: { id: phy ? phy.id : -1 },
    fetchPolicy: 'network-only',
  });
  const physicianHasSignature = get(hasSignatureQuery, 'hasSignature', false);
  const physicianHasConsent = get(hasConsentQuery, 'hasConsent', false);
  const physicianName = get(phy, 'name', '');

  return (
    <Box>
      {physicianHasSignature ? (
        physicianHasConsent ? (
          <Box display="flex" flexDirection="row" alignItems="center">
            <CheckboxInput
              onChange={onChange}
              value={value}
              name={name}
              layoutProps={{ style: { marginBottom: 0 } }}
              isDisabled={disabled}
            />
            <label>{`Sign forms with physician signature`}</label>
          </Box>
        ) : (
          <label>{`Physician ${physicianName} has not consented to signature usage`}</label>
        )
      ) : (
        <label>Physician signature is not uploaded</label>
      )}
    </Box>
  );
};

export default SignPhysicianInput;
