import { withProps } from 'recompose';
import graphqlSelectInput from '../graphqlSelectInput';
import { list } from '../../../graph/anesthesiologists';

export default withProps({
  isClearable: true,
})(
  graphqlSelectInput({
    entityName: 'Anesthesiologist',
    label: 'Anesthesiologist',
    placeholder: 'Select',
    graphqlList: list,
    getOptionValue: option => (option ? parseInt(option.value, 10) : undefined),
    sortOptions: true,
  })
);
