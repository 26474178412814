import { Theme } from '@material-ui/core';

export default function StepConnector(theme: Theme) {
  return {
    MuiStepConnector: {
      line: {
        borderColor: theme.palette.divider,
      },
    },
  };
}
