import React, { useEffect, useMemo, useState } from 'react';
import { LinearProgress } from '@material-ui/core';

interface TimerProps {
  duration: number;
  onDone: () => void;
}

const Timer = ({ duration, onDone }: TimerProps) => {
  const startTime = useMemo(() => new Date().getTime(), []);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let animationFrame: ReturnType<typeof requestAnimationFrame>;

    const loop = () => {
      const currentTime = new Date().getTime();
      const elapsed = currentTime - startTime;
      const progress = elapsed / duration;
      setProgress(progress);
      if (progress < 1) {
        animationFrame = requestAnimationFrame(loop);
      } else {
        onDone();
      }
    };

    loop();

    return () => {
      cancelAnimationFrame(animationFrame);
    };
  }, [duration, onDone, startTime]);

  return <LinearProgress variant="determinate" color="primary" value={progress * 100} />;
};

export default Timer;
