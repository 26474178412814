import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PatientHeader, { PatientHeaderProps } from './PatientHeader';
import PatientOptions, { PatientOptionsProps } from './PatientOptions';
import PatientBedPicker from './PatientBedPicker';
import { CustomModal, DischargeModal } from '../tablet/OperationRoomTabletV1';
import PatientRoomPicker from './PatientRoomPicker';
import { PACU, POST_OP } from '../../../entities/room/enums';
import PatientPriorityPicker from './PatientPriorityPicker';
import ProcedurePriorityPicker from './ProcedurePriorityPicker';
import { useMutation, useQuery } from '@apollo/client';
import { procedureStep, setAnesthesiaEnd } from '../../../../graph/procedureSteps';
import { patientProcedures } from '../../../../graph/procedures';
import { ProcedureStepType } from '../../../../types/ProcedureStep';
import { CancelableForm, formatTime } from '../../../inputs/timeEdit/TimeEditForm';
import { ZonedDateTime } from '@js-joda/core';
import { RouterlessModal } from '../../../../se/components/Modal';
import { convertToDateTime, FormInput } from '../../../entities/patient/patientJourney/PatientEventTimestamp';
import PatientVisitCancellationModal from './PatientVisitCancellationModal';
import { useScope } from '../../../../hooks/useScope';

// const Dispatch = CustomModal(
//   'This action will mark the patient as discharged and remove them from all monitoring screens. This action cannot be undone.'
// );

const PatientPickup = CustomModal(
  `Patient Family will be notified that the patient is ready for pickup. This action cannot be undone.`
);

const PatientReadyForFamily = CustomModal(
  `Patient Family will be notified that the patient is ready for visit. This action cannot be undone.`
);

export const PatientScreenContainer = styled.div`
  display: grid;
  background: ${props => props.theme.backgroundColor.string()};
  color: ${props => props.theme.textColor.string()};
  grid-template-rows: [header] min-content [content] auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  height: 100vh;
  width: 100%;
`;

const ScreenRouter = ({
  room,
  roomType,
  patient,
  patientPickupMessage,
  readyForFamilyMessage,
  screen,
  changeScreenState,
  backToOverview,
  bodyData,
  hasPatientCharting,
}) => {
  const notificationNumbers = patient?.notificationNumbers || '';
  const hasCaretakerConsent = patient?.caretakerConsent || false;
  const smsIndicator = notificationNumbers !== '' && hasCaretakerConsent;
  const isPacu = roomType === PACU;
  const isPostOp = roomType === POST_OP;
  const priorityCounter = bodyData?.priorityCounter;

  const scope = useScope();
  const { data } = useQuery(procedureStep, {
    variables: {
      patientId: patient?.id,
      procedureStepType: ProcedureStepType.AnestheticEnd,
    },
  });
  const { data: procedureData } = useQuery(patientProcedures, {
    variables: {
      patientId: patient?.id,
    },
  });
  const hasOrTabletV2Module = scope?.hospital?.modules?.orTabletV2 || false;
  const hasAnestheticEndConfigured = hasOrTabletV2Module && (!!data?.procedureStep || false);

  const [setAnesthesiaEndTime] = useMutation(setAnesthesiaEnd);

  const onTimeSubmit = async value => {
    const newDateTime = convertToDateTime(data?.procedureStep?.timestamp || new Date(), value?.time);
    patient?.id &&
      (await setAnesthesiaEndTime({
        variables: {
          patientId: patient?.id,
          timestamp: newDateTime,
        },
        refetchQueries: [
          {
            query: procedureStep,
            variables: {
              patientId: patient?.id,
              procedureStepType: ProcedureStepType.AnestheticEnd,
            },
          },
        ],
      }));
    backToOverview();
  };

  const Dispatch = DischargeModal(`Patient ${patient.initials} gets removed from TV`);
  return (
    <>
      {screen === 'overview' && (
        <PatientOptions
          {...bodyData}
          patient={patient}
          onSelect={changeScreenState}
          roomType={roomType}
          columns={(isPacu || isPostOp) && bodyData.onMarkFamilyReady && hasCaretakerConsent ? 2 : 3}
          smsIndicator={smsIndicator}
          hasPatientCharting={hasPatientCharting}
          hasAnestheticEndConfigured={hasAnestheticEndConfigured}
          anesthesiaEndTimestamp={data?.procedureStep?.timestamp}
        />
      )}
      {screen === 'dischargePatient' && (
        <Dispatch handleConfirm={() => changeScreenState('dischargePatientConfirm')} handleCancel={backToOverview} />
      )}
      {screen === 'assignBed' && (
        <PatientBedPicker
          room={room}
          beds={bodyData.beds}
          onAssignBed={bodyData.onAssignBed}
          onCancel={backToOverview}
          goToStartPage={bodyData.goBack}
        />
      )}
      {screen === 'anesthesiaEnd' && (
        <RouterlessModal title="Update Time" onClose={backToOverview}>
          <CancelableForm
            autoFocus
            label="Update"
            onClose={backToOverview}
            initialValue={{
              time: data?.procedureStep?.timestamp
                ? formatTime(data?.procedureStep?.timestamp)
                : formatTime(new Date(), 'HH:mm'),
            }}
            input={FormInput('Anesthesia End Time')}
            onSubmit={onTimeSubmit}
            busy={false}
          />
        </RouterlessModal>
      )}
      {screen === 'preOpPriority' && (
        <PatientPriorityPicker
          priorityCounter={priorityCounter}
          assignedPriority={patient?.priority}
          onAssignPriority={bodyData.onAssignPriority}
          onCancel={backToOverview}
          goToStartPage={bodyData.goBack}
        />
      )}
      {screen === 'procedurePriority' && (
        <ProcedurePriorityPicker
          procedureId={procedureData?.procedures.id}
          priorityCounter={priorityCounter}
          assignedPriority={procedureData?.procedures?.procedurePriority}
          onCancel={backToOverview}
          goToStartPage={bodyData.goBack}
        />
      )}
      {screen === 'moveToOr' && (
        <PatientRoomPicker
          onSelectRoom={bodyData.onMoveToOr}
          onCancel={backToOverview}
          goToStartPage={bodyData.goBack}
        />
      )}
      {screen === 'cancelVisit' && <PatientVisitCancellationModal patientId={patient?.id} onDone={bodyData.goBack} />}
      {screen === 'markAsFamilyReady' && (
        <PatientPickup
          sendTo={
            smsIndicator &&
            `Message will be sent to Caretaker Phone ${
              notificationNumbers.split(',').length > 1 ? 'numbers' : 'number'
            } ${notificationNumbers}`
          }
          messageToSend={
            smsIndicator &&
            patientPickupMessage
              .replaceAll('{{patientFullName}}', patient?.name || '')
              .replaceAll('{{patientInitials}}', patient?.initials || '')
          }
          handleConfirm={() => changeScreenState('markAsFamilyReadyConfirm')}
          handleCancel={backToOverview}
        />
      )}
      {screen === 'markAsReadyConfirm' && (
        <PatientReadyForFamily
          sendTo={
            smsIndicator &&
            `Message will be sent to Caretaker Phone ${
              notificationNumbers.split(',').length > 1 ? 'numbers' : 'number'
            } ${notificationNumbers}`
          }
          messageToSend={
            smsIndicator &&
            readyForFamilyMessage
              .replaceAll('{{patientFullName}}', patient?.name || '')
              .replaceAll('{{patientInitials}}', patient?.initials || '')
          }
          handleConfirm={() => changeScreenState('markAsReady')}
          handleCancel={backToOverview}
        />
      )}
      {screen === 'markAsReadyToSeeFamilyPreOp' && (
        <PatientReadyForFamily
          sendTo={
            smsIndicator &&
            `Message will be sent to Caretaker Phone ${
              notificationNumbers.split(',').length > 1 ? 'numbers' : 'number'
            } ${notificationNumbers}`
          }
          messageToSend={
            smsIndicator &&
            readyForFamilyMessage
              .replaceAll('{{patientFullName}}', patient?.name || '')
              .replaceAll('{{patientInitials}}', patient?.initials || '')
          }
          handleConfirm={() => changeScreenState('markAsReadyToSeeFamilyPreOp')}
          handleCancel={backToOverview}
        />
      )}
    </>
  );
};

class PatientScreen extends React.Component {
  state = { screen: 'overview' };

  changeScreenState = async screen => {
    const { bodyData } = this.props;
    if (screen === 'markAsReady') {
      await bodyData.onMarkAsReady();
      bodyData.goBack();
    } else if (screen === 'markAsNotReady') {
      await bodyData.onMarkAsNotReady();
      bodyData.goBack();
    } else if (screen === 'markAsReadyForSurgeon') {
      await bodyData.onMarkAsReadyForSurgeon();
      bodyData.goBack();
    } else if (screen === 'markAsNotReadyForSurgeon') {
      await bodyData.onMarkAsNotReadyForSurgeon();
      bodyData.goBack();
    } else if (screen === 'dischargePatientConfirm') {
      await bodyData.onDischargePatient();
      bodyData.goBack();
    } else if (screen === 'markAsFamilyReadyConfirm') {
      bodyData.onMarkFamilyReady();
      bodyData.goBack();
    } else if (screen === 'holdProcedure') {
      await bodyData.onHoldProcedure();
      bodyData.goBack();
    } else if (screen === 'undoHoldProcedure') {
      await bodyData.onUndoHoldProcedure();
      bodyData.goBack();
    } else if (screen === 'blockNerve') {
      await bodyData.onBlockNerve();
      bodyData.goBack();
    } else if (screen === 'undoBlockNerve') {
      await bodyData.onUndoBlockNerve();
      bodyData.goBack();
    } else if (screen === 'markAsReadyToSeeFamilyPreOp') {
      await bodyData.onMarkAsReadyToSeeFamilyPreOp();
      bodyData.goBack();
    } else if (screen === 'markAsNotReadyToSeeFamilyPreOp') {
      await bodyData.onMarkAsNotReadyToSeeFamilyPreOp();
      bodyData.goBack();
    } else {
      this.setState({ screen });
    }
  };

  backToOverview = () => this.changeScreenState('overview');

  render() {
    const { screen } = this.state;
    const { room, roomType, bodyData, headerData, patientPickupMessage, readyForFamilyMessage } = this.props;
    return (
      <PatientScreenContainer>
        <PatientHeader
          {...headerData}
          roomType={roomType}
          hasNoteModule={bodyData.hasNoteModule}
          hasPatientCharting={bodyData.hasPatientCharting}
          isSurgeonNotified={bodyData.isSurgeonNotified}
          markAsReadyForSurgeon={bodyData.markAsReadyForSurgeon}
        />
        <ScreenRouter
          patientPickupMessage={patientPickupMessage}
          readyForFamilyMessage={readyForFamilyMessage}
          patient={headerData.patient}
          roomType={roomType}
          screen={screen}
          room={room}
          bodyData={bodyData}
          changeScreenState={this.changeScreenState}
          backToOverview={this.backToOverview}
          hasPatientCharting={bodyData.hasPatientCharting}
        />
      </PatientScreenContainer>
    );
  }
}

PatientScreen.propTypes = {
  headerData: PropTypes.shape(PatientHeaderProps).isRequired,
  roomType: PropTypes.string,
  bodyData: PropTypes.shape({
    ...PatientOptionsProps,
    onAssignBed: PropTypes.func.isRequired,
    onMarkAsReady: PropTypes.func.isRequired,
    onMarkAsNotReady: PropTypes.func.isRequired,
    onMarkAsReadyForSurgeon: PropTypes.func.isRequired,
    onMarkAsNotReadyForSurgeon: PropTypes.func.isRequired,
    onDischargePatient: PropTypes.func.isRequired,
    onMoveToOr: PropTypes.func.isRequired,
    onMarkFamilyReady: PropTypes.func,
    onCancelVisit: PropTypes.func.isRequired,
    onHoldProcedure: PropTypes.func.isRequired,
    onUndoHoldProcedure: PropTypes.func.isRequired,
    onBlockNerve: PropTypes.func.isRequired,
    onUndoBlockNerve: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    beds: PropTypes.arrayOf(
      PropTypes.shape({
        bedNumber: PropTypes.number.isRequired,
        occupied: PropTypes.bool,
      }).isRequired
    ).isRequired,
  }).isRequired,
};

export default PatientScreen;
