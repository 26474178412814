import React from 'react';
import styled from 'styled-components';
import Popover from 'react-popover';
import ReactTimeout from 'react-timeout';

// TODO refactor this component

const Container = styled.div`
  background: transparent;
  outline: none;
  cursor: pointer;
  color: ${props => props.theme.textColor.lighten(0.5).string()};
  display: flex;
  }
`;
const Content = styled.div`
  background: white;
  color: #000;
  box-shadow: rgba(0, 0, 0, 0.33) 0 0.33rem 2rem;
`;

class Dropdown extends React.Component {
  state = {
    open: false,
  };

  setOpen = (open, f) => {
    if (open && !this.state.open) {
      if (this.props.contentComponent) {
        this.content = this.props.contentComponent;
        this.contentProps = { close: f => this.setOpen(false, f) };
      } else {
        this.content = this.props.content({
          close: f => this.setOpen(false, f),
        });
        this.contentProps = {};
      }
    }

    this.setState(
      {
        open,
      },
      () => {
        if (!this.state.open) {
          this.content = null;
        }
        if (f) {
          f();
        }
      }
    );
  };

  render() {
    const {
      children,
      content,
      hover,
      position,
      duration,
      showTip = true,
      contentProps = {},
      disabled,
      ...props
    } = this.props;
    const { open } = this.state;
    const PassedContent = this.content;
    return (
      <Popover
        style={{ zIndex: 5000 }}
        isOpen={open}
        body={
          open ? (
            <Content>
              <PassedContent {...{ ...this.contentProps, ...contentProps }} />
            </Content>
          ) : (
            <span />
          )
        }
        preferPlace={position || 'below'}
        enterExitTransitionDurationMs={duration || 500}
        onOuterAction={() => this.setOpen(false)}
        tipSize={showTip ? 7 : 0.01}
      >
        <Container
          {...props}
          onClick={() => (!disabled ? this.setOpen(!open) : undefined)}
          onMouseEnter={() => hover && this.setOpen(true)}
          onMouseLeave={() => hover && this.setOpen(false)}
        >
          {children}
        </Container>
      </Popover>
    );
  }
}

export default ReactTimeout(Dropdown);
