import { useQuery } from '@apollo/client';
import React, { FC } from 'react';
import FileUploader from '../../uploader/FileUploader';
import { hospitalDocumentSections } from '../../../graph/hospitals';

interface Props {
  organizationId: number;
}

const HospitalDocumentUploader: FC<Props> = ({ organizationId }) => {
  const { data } = useQuery(hospitalDocumentSections, { variables: { organizationId } });

  return (
    <FileUploader
      entityId={organizationId}
      entity={{ organizationId }}
      uploadSections={data?.hospitalDocumentSections}
    />
  );
};

export default HospitalDocumentUploader;
