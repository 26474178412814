import { graphql } from '@apollo/client/react/hoc';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { compose, withProps } from 'recompose';
import GraphQLCRUD from '../../../entities/patient/../../../se/components/GraphQLCRUD';
import { mapListProps } from '../../../entities/patient/transducers';
import {
  dateOfVisit,
  note,
  PatientNameColumn,
  phoneNumberColumn,
  physicianColumn,
  procedureTypeColumn,
  providerIdColumn,
  roomOrDateOfVisit,
  chartingSmsNotificationNumbersColumn,
} from '../../../entities/patient/columns';
import Filters from '../../../entities/patient/../../pages/analytics/Filters';
import PatientListView from '../../../entities/patient/./views/PatientListView';
import PatientShowTitle from '../../../entities/patient/../../patient/PatientShowTitle';
import PatientMobileListItem from '../../../entities/patient/./views/PatientMobileListItem';
import { NamedRange } from '../../../core/DatePicker';
import activeAndPastProceduresSource from '../../../entities/patient/../../../omnisearch/sources/hospital/activeAndPastProcedures';
import pick from 'lodash/fp/pick';
import { withScope } from '../../../../contexts/ScopeContext';
import { get, set } from 'lodash/fp';
import ChartingPatientView from './ChartingPatientView';
import { TruncatedText } from '../../../../se/components/typography';
import scheme, { listPhysicianPatients } from '../../../../graph/patientChart';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { sortString } from '../../../../util/sort';
import { Divider, LinearProgress, Typography } from '@material-ui/core';
import { QuestionnaireStatus, QuestionnaireType } from '../../../../types/Questionnaire';
import { Patient } from '../../../../types/Patient';
import responsiveStyles from '../../../../se/utilities/responsive';
import { RouteComponentProps } from 'react-router';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { getChartingData, getChartingName, getUrlPart } from './utils';
import { PatientChartButtonConfig, PatientChartButtonsConfig } from '../../../../types/HospitalConfig';
import GroupedTabRoutes from './GroupedTabRoutes';
import { makeStyles } from '@material-ui/core/styles';
import { green, pink } from '@material-ui/core/colors';
import ChartingButtons, { DictationButton } from './ChartingButtons';
import { ChartingSession, Role } from './modules/chartingStore';
import { withPin } from './modules/withPin';
import useTabs from './modules/useTabs';

const Text = TruncatedText as any;
const responsive = responsiveStyles as any;

export const Empty = { illustration: (theme: any) => theme.illustrations.patients };

const FiltersWrapper = styled.div`
  ${responsive.md.andSmaller`
    padding-bottom: 0rem;
    display: flex;
    flex-flow: column;
    .hidden {
      margin-bottom: 2em;
    }
  `};
`;

export const filterEventColumns = (props: any) => {
  const hasPostop = get('scope.hospital.modules.hasPostop')(props);
  return hasPostop
    ? props
    : set(
        'columns',
        get('columns')(props).filter((column: any) => column.title !== 'Post Op Entry')
      )(props);
};

export const CustomFilters = (props: any) => (
  <FiltersWrapper>
    <Filters {...props} />
  </FiltersWrapper>
);

export const anesthesiologistColumn = {
  title: 'Anesthesiologist',
  lens: (data: any) => data?.procedure?.anesthesiologist?.name,
  Component: (props: any) => <Text>{props.data}</Text>,
};

export const anesthesiaTypeColumn = {
  title: 'Anesthesia Type',
  lens: (data: any) => data?.procedure?.anesthesiaType,
  Component: (props: any) => <Text>{props.data}</Text>,
};

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const useStyles = makeStyles(theme => ({
  anesthesiologistColorPrimary: {
    backgroundColor: pink[100],
  },
  anesthesiologistBarColorPrimary: {
    backgroundColor: pink[500],
  },
  physicianColorPrimary: {
    backgroundColor: green[200],
  },
  physicianBarColorPrimary: {
    backgroundColor: green[600],
  },
}));

export const ChartingButton = ({
  buttonLabel,
  patient,
  questionnaireType,
  handleClick,
  colorScheme,
}: {
  buttonLabel?: string | null;
  patient: Patient;
  questionnaireType: QuestionnaireType;
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  colorScheme?: string;
}) => {
  const hasProcedure = !!patient?.procedure?.id;
  const classes = useStyles();

  const [urlMatches, setUrlMatches] = useState(false);

  useEffect(() => {
    const urlPart = getUrlPart(questionnaireType);
    setUrlMatches(location.pathname.includes(`/${urlPart}-chart`) && location.pathname.includes(`/${urlPart}`));
  }, [location.pathname, questionnaireType]);

  if (!hasProcedure) return null;

  const charts = getChartingData(questionnaireType, patient);
  const count = charts?.length || 0;

  const isAnesthesiologist =
    questionnaireType === QuestionnaireType.AnesthesiologistPreOpChart ||
    questionnaireType === QuestionnaireType.AnesthesiologistOrChart ||
    questionnaireType === QuestionnaireType.AnesthesiologistPacuChart;

  const isPhysician = questionnaireType === QuestionnaireType.PhysicianChart;

  const completed = charts?.map(chart => chart?.completed);
  const completedNumber =
    completed?.filter((e?: QuestionnaireStatus) => e === QuestionnaireStatus.Completed)?.length || 0;
  const completedAll = completedNumber === count;

  const progress = count ? (completedNumber / count) * 100 : 0;

  const questionnaireName = getChartingName(questionnaireType);

  return (
    <Row>
      <Button
        style={{
          width: '100px',
          height: '50px',
          overflow: 'hidden',
          borderRadius: 2,
          backgroundColor: urlMatches ? '#C0C0C0' : '',
        }}
        disableElevation
        variant={completedAll ? 'outlined' : 'contained'}
        onClick={handleClick}
        endIcon={completedAll && <CheckCircleIcon fontSize="inherit" />}
      >
        <Box display="flex" alignItems="center">
          {buttonLabel ? buttonLabel : `${questionnaireName} ${completedAll ? 'Complete' : 'Charting'}`}
          {!completedAll && (
            <Box ml={1}>
              <Typography variant="body2" color="textSecondary">
                {completedNumber}/{count}
              </Typography>
            </Box>
          )}
        </Box>
        {!completedAll && (
          <Box position="absolute" bottom={0} left={0} right={0} display="flex" width="100%">
            <LinearProgress
              variant="determinate"
              value={progress}
              style={{ flex: 1 }}
              classes={{
                colorPrimary:
                  classes[`${colorScheme}ColorPrimary` as 'anesthesiologistColorPrimary' | 'physicianColorPrimary'] ||
                  (isAnesthesiologist ? classes.anesthesiologistColorPrimary : false) ||
                  (isPhysician ? classes.physicianColorPrimary : false),
                barColorPrimary:
                  classes[
                    `${colorScheme}BarColorPrimary` as 'anesthesiologistBarColorPrimary' | 'physicianBarColorPrimary'
                  ] ||
                  (isAnesthesiologist ? classes.anesthesiologistBarColorPrimary : false) ||
                  (isPhysician ? classes.physicianBarColorPrimary : false),
              }}
            />
          </Box>
        )}
      </Button>
    </Row>
  );
};

export const chartingLink = (buttonLabel: string | null, questionnaireType: QuestionnaireType) => ({
  title: 'Charting',
  headerCellStyle: {
    justifyContent: 'flex-end',
  },
  useThemeBackgroundColor: true,
  style: {
    position: window.innerWidth > 768 ? 'sticky' : 'initial', // TODO use theme media queries
    right: 0,
    width: '1%',
  },
  lens: (data: any) => data,
  Component: withRouter((props: any) => {
    const patient = props?.data;

    const handleClick = (e: any) => {
      e.stopPropagation();
      const urlPart = getUrlPart(questionnaireType);
      const url = `${props?.location.pathname.replace(`/${urlPart}`, `/${urlPart}-chart/${patient?.id}`)}${
        window.location.search
      }`;
      props?.history?.push(url);
    };

    const hasProcedure = !!patient?.procedure?.id;

    if (!hasProcedure) return null;

    return (
      <Box display="flex" style={{ gap: 8 }}>
        <ChartingButton
          buttonLabel={buttonLabel}
          patient={patient}
          handleClick={handleClick}
          questionnaireType={questionnaireType}
        />
        <Box px={1}>
          <Divider orientation="vertical" />
        </Box>
        <DictationButton patientId={patient?.id} />
      </Box>
    );
  }),
});

export const nameColumn = (sortField: any) => ({
  title: 'Name',
  key: 'name',
  lens: (data: any) => ({
    id: data.id,
    icon: data.icon,
    color: data.color,
    name: data.name,
    type: data.type,
    patient: data,
  }),
  sortFunction: (l: any, r: any) => sortString(l[sortField], r[sortField]),
  Component: (props: any) => (
    <PatientNameColumn {...props} showIcon={false} showPatientType={false} showPatientChartCompleted={false} />
  ),
});

export const getPatientChartButtonsConfig = (
  config: PatientChartButtonsConfig | null,
  role: Role
): PatientChartButtonConfig[] => {
  const tabsConfig = (config?.buttons || []).find(e => e?.role?.toLowerCase() === role?.toLowerCase());
  return tabsConfig?.buttons || [];
};

const getChartingButtonLabel = (
  tabsConfig: PatientChartButtonsConfig | null,
  role: Role,
  questionnaireType: QuestionnaireType
): string | null =>
  getPatientChartButtonsConfig(tabsConfig, role)?.find(e => e.chartType === questionnaireType)?.buttonLabel || null;

const StaffCharting = (tabsConfig: PatientChartButtonsConfig | null, questionnaireType: QuestionnaireType) =>
  withScope(
    GraphQLCRUD({
      entityName: 'Patient',
      scheme: {
        item: scheme.item,
        list: scheme.list(questionnaireType),
      },
      List: {
        tableKey: 'ActiveAndPastPatients',
        MobileItemComponent: PatientMobileListItem,
        useColumnSelection: true,
        columns: [
          providerIdColumn,
          nameColumn('name'),
          physicianColumn,
          procedureTypeColumn,
          anesthesiologistColumn,
          anesthesiaTypeColumn,
          roomOrDateOfVisit,
          chartingLink(getChartingButtonLabel(tabsConfig, 'staff', questionnaireType), questionnaireType),
        ],
        View: PatientListView,
        FilterComponent: withProps({
          textSearchKey: 'name',
          hideSpecialitySelectInput: true,
          hideRoomSelectInput: false,
          hideProcedureTypeSelectInput: true,
          hideMobile: true,
          defaultValue: NamedRange.today(),
          reload: false,
        })(CustomFilters),
        defaultFilterValues: {
          dateRange: NamedRange.today(),
        },
        pickFilter: pick([
          'name',
          'physician',
          'procedureType',
          'hospital',
          'status',
          'dateRange',
          'category',
          'speciality',
          'room',
        ]),
        Empty,
        mapListProps: compose(mapListProps, filterEventColumns),
        containsSeparator: true,
      },
      Show: {
        columns: [
          providerIdColumn,
          physicianColumn,
          procedureTypeColumn,
          anesthesiologistColumn,
          anesthesiaTypeColumn,
          chartingSmsNotificationNumbersColumn,
          phoneNumberColumn,
          dateOfVisit,
          note,
        ],
        Empty,
        View: withPin()(ChartingPatientView),
        Title: PatientShowTitle,
        Actions: ChartingButtons(tabsConfig, 'staff'),
        itemProvider: graphql(scheme.item, {
          options: ({ idProvider, ...rest }: any) => ({
            variables: {
              id: parseInt(idProvider(rest), 10),
              fetchPolicy: 'cache-and-network',
            },
          }),
        }),
      },
      searchSource: activeAndPastProceduresSource,
    })
  );

const PhysicianCharting = (
  tabsConfig: PatientChartButtonsConfig | null,
  questionnaireType: QuestionnaireType,
  physicianId: number
) =>
  withScope(
    GraphQLCRUD({
      entityName: 'Patient',
      scheme: { item: scheme.item, list: listPhysicianPatients(physicianId, questionnaireType) },
      List: {
        tableKey: 'ActiveAndPastPatients',
        MobileItemComponent: PatientMobileListItem,
        useColumnSelection: true,
        columns: [
          providerIdColumn,
          nameColumn('name'),
          physicianColumn,
          procedureTypeColumn,
          anesthesiologistColumn,
          anesthesiaTypeColumn,
          roomOrDateOfVisit,
          chartingLink(getChartingButtonLabel(tabsConfig, 'physician', questionnaireType), questionnaireType),
        ],
        View: PatientListView,
        FilterComponent: withProps({
          textSearchKey: 'name',
          hideSpecialitySelectInput: true,
          hidePhysicianSelectInput: true,
          hideRoomSelectInput: false,
          hideProcedureTypeSelectInput: true,
          hideMobile: true,
          defaultValue: NamedRange.today(),
          reload: false,
        })(CustomFilters),
        defaultFilterValues: {
          dateRange: NamedRange.today(),
        },
        pickFilter: pick(['name', 'procedureType', 'dateRange', 'room']),
        Empty,
        mapListProps: compose(mapListProps, filterEventColumns),
        containsSeparator: true,
      },
      Show: {
        columns: [
          providerIdColumn,
          physicianColumn,
          procedureTypeColumn,
          anesthesiologistColumn,
          anesthesiaTypeColumn,
          chartingSmsNotificationNumbersColumn,
          phoneNumberColumn,
          dateOfVisit,
          note,
        ],
        Empty,
        View: withPin()(ChartingPatientView),
        Title: PatientShowTitle,
        Actions: ChartingButtons(tabsConfig, 'physician'),
        itemProvider: graphql(scheme.item, {
          options: ({ idProvider, ...rest }: any) => ({
            variables: {
              id: parseInt(idProvider(rest), 10),
              fetchPolicy: 'cache-and-network',
            },
          }),
        }),
      },
      searchSource: activeAndPastProceduresSource,
    })
  );

const AnesthesiologistCharting = (tabsConfig: PatientChartButtonsConfig | null, questionnaireType: QuestionnaireType) =>
  withScope(
    GraphQLCRUD({
      entityName: 'Patient',
      scheme: {
        item: scheme.item,
        list: scheme.list(questionnaireType),
      },
      List: {
        tableKey: 'ActiveAndPastPatients',
        MobileItemComponent: PatientMobileListItem,
        useColumnSelection: true,
        columns: [
          providerIdColumn,
          nameColumn('name'),
          physicianColumn,
          procedureTypeColumn,
          anesthesiologistColumn,
          anesthesiaTypeColumn,
          roomOrDateOfVisit,
          chartingLink(getChartingButtonLabel(tabsConfig, 'anesthesiologist', questionnaireType), questionnaireType),
        ],
        View: PatientListView,
        FilterComponent: withProps({
          textSearchKey: 'name',
          hideSpecialitySelectInput: true,
          hideRoomSelectInput: false,
          hideProcedureTypeSelectInput: true,
          hideMobile: true,
          defaultValue: NamedRange.today(),
          reload: false,
        })(CustomFilters),
        defaultFilterValues: {
          dateRange: NamedRange.today(),
        },
        pickFilter: pick([
          'name',
          'physician',
          'procedureType',
          'hospital',
          'status',
          'dateRange',
          'category',
          'speciality',
          'room',
        ]),
        Empty,
        mapListProps: compose(mapListProps, filterEventColumns),
        containsSeparator: true,
      },
      Show: {
        columns: [
          providerIdColumn,
          physicianColumn,
          procedureTypeColumn,
          anesthesiologistColumn,
          anesthesiaTypeColumn,
          chartingSmsNotificationNumbersColumn,
          phoneNumberColumn,
          dateOfVisit,
          note,
        ],
        Empty,
        View: withPin()(ChartingPatientView),
        Title: PatientShowTitle,
        Actions: ChartingButtons(tabsConfig, 'anesthesiologist'),
        itemProvider: graphql(scheme.item, {
          options: ({ idProvider, ...rest }: any) => ({
            variables: {
              id: parseInt(idProvider(rest), 10),
              fetchPolicy: 'cache-and-network',
            },
          }),
        }),
      },
      searchSource: activeAndPastProceduresSource,
    })
  );

export const Charting = (
  tabsConfig: PatientChartButtonsConfig | null,
  questionnaireType: QuestionnaireType,
  chartingSession?: ChartingSession | null
) =>
  chartingSession?.role === 'physician'
    ? PhysicianCharting(tabsConfig, questionnaireType, chartingSession?.id)
    : chartingSession?.role === 'anesthesiologist'
    ? AnesthesiologistCharting(tabsConfig, questionnaireType)
    : StaffCharting(tabsConfig, questionnaireType);

const ChartingPatientsNavigation = withRouter(
  ({ chartingSession, ...rest }: { chartingSession: ChartingSession | null } & RouteComponentProps) => {
    const tabs = useTabs();

    return (
      <>
        {tabs && tabs.length > 0 && (
          <GroupedTabRoutes
            {...rest}
            tabsStyles={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
            tabs={tabs}
          />
        )}
      </>
    );
  }
);

export default ChartingPatientsNavigation;
