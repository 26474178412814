import styled from 'styled-components';
import responsive from '../../../../../se/utilities/responsive';

const size = ({ lg, md }) => {
  if (lg) {
    return '1.3333em';
  } else if (md) {
    return '1em';
  } else {
    return '1.3333em';
  }
};

export const RoomName = styled.div`
  color: #abe3ff;
  > span {
    opacity: 0.5;
  }
  ${responsive.md.andSmaller`
    display: none;
  `};

  font-size: ${props => size(props)};
`;
