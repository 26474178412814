import React from 'react';
import useLocalStorage from 'use-localstorage-hook';
import logo from '../../assets/images/logo_white.svg';
import androidMenu from './assets/pwa/android_menu.svg';
import iosAdd from './assets/pwa/ios_add.svg';
import iosShare from './assets/pwa/ios_share.svg';
import safariIcon from './assets/pwa/safari.svg';
import {
  hasUserCancelledPWA,
  isInStandaloneMode,
  isIOS,
  isSafari,
  isIPad,
  isAndroidDevice,
  isApplicableMobileDevice,
  AppIcon,
  TextBlock,
  Line,
} from './PWAUtils';
import { RouterlessModal as Modal } from './Modal';

const IOSInstallInstructions = () => (
  <TextBlock style={{ marginBottom: '0.25rem' }} darker>
    <p>
      Tap on the <img src={iosShare} alt="" /> Share Button and then tap <img src={iosAdd} alt="" /> Add to Home Screen.
    </p>
  </TextBlock>
);

const AndroidInstallInstructions = () => (
  <TextBlock style={{ marginBottom: '0.25rem' }} darker>
    <p>
      Tap on the <img src={androidMenu} alt="" /> Menu button and then choose Add to Home Screen.
    </p>
  </TextBlock>
);

const NonSafariInstallInstructions = () => (
  <TextBlock style={{ marginBottom: '0.25rem' }} darker>
    <p>
      To get the app, you need to first open View by Ospitek in <img src={safariIcon} alt="" /> Safari.
    </p>
  </TextBlock>
);

const pickInstructions = () => {
  if (isIOS() && isSafari()) {
    return <IOSInstallInstructions />;
  } else if (isAndroidDevice() && !isIOS()) {
    return <AndroidInstallInstructions />;
  } else {
    return <NonSafariInstallInstructions />;
  }
};

export default ({ children }) => {
  const [PWAInfo, setPWAInfo] = useLocalStorage('PWAInfo', { canceledAt: undefined, cancelCount: 0 });
  const userCanceledPWA = hasUserCancelledPWA(PWAInfo);
  const isMobile = isApplicableMobileDevice();
  const standaloneMode = isInStandaloneMode();

  if (isMobile && !standaloneMode && !userCanceledPWA) {
    return (
      <Modal
        onClose={() => {
          setPWAInfo({
            canceledAt: new Date(),
            cancelCount: (PWAInfo.cancelCount || 0) + 1,
          });
        }}
      >
        <div style={{ marginBottom: isIPad() ? '2rem' : '1rem' }}>
          <TextBlock>
            <h3>Get the App!</h3>
          </TextBlock>
          <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
            <AppIcon src={logo} alt="" />
          </div>
          <TextBlock>
            <p>No additional download required.</p>
          </TextBlock>
        </div>
        <TextBlock style={{ marginBottom: '0.25rem' }}>
          <p>With our app you can get quick and easy access on the go!</p>
        </TextBlock>
        <Line />
        {pickInstructions()}
      </Modal>
    );
  }

  return children || null;
};
