import React from 'react';
import { TruncatedText } from '../../../se/components/typography';

import { ROOM_TYPE_LABELS } from '../room/enums';
import { sortNumber } from '../../../util/sort';
import ExpandableText from '../../core/ExpandableText';

export const nameColumn = {
  title: 'Name',
  key: 'name',
  lens: item => item.name,
  style: { maxWidth: '50em' },
  Component: props => <ExpandableText text={props.data} />,
};

export const expectedPreOpDurationColumn = {
  title: `Avg. time in ${ROOM_TYPE_LABELS.PRE_OP} (mins)`,
  key: 'expectedPreOpDuration',
  lens: item => item.expectedPreOpDuration,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
  sortFunction: (l, r) => sortNumber(l.expectedPreOpDuration, r.expectedPreOpDuration),
};

export const expectedDurationColumn = {
  title: `Avg. time in ${ROOM_TYPE_LABELS.OR} (mins)`,
  key: 'expectedDuration',
  lens: item => item.expectedDuration,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
  sortFunction: (l, r) => sortNumber(l.expectedDuration, r.expectedDuration),
};

export const expectedPacuDurationColumn = {
  title: `Avg. time in ${ROOM_TYPE_LABELS.PACU} (mins)`,
  key: 'expectedPacuDuration',
  lens: item => item.expectedPacuDuration,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
  sortFunction: (l, r) => sortNumber(l.expectedPacuDuration, r.expectedPacuDuration),
};

export const listColumns = [
  nameColumn,
  expectedPreOpDurationColumn,
  expectedDurationColumn,
  expectedPacuDurationColumn,
];

export const viewColumns = [expectedPreOpDurationColumn, expectedDurationColumn, expectedPacuDurationColumn];
