import GraphQLCRUD from '../../../../se/components/GraphQLCRUD';
import TitleAction from '../../../../se/components/TitleAction';
import {
  last30DaysRange,
  last90DaysRange,
  lastMonthRange,
  lastSevenDaysExcludingTodayRange,
  lastYearRange,
  NamedRange,
  next30DaysRange,
  next90DaysRange,
  nextMonthRange,
  nextSevenDaysRange,
  restOfMonthRange,
  thisYearRange,
  todayRange,
} from '../../../core/DatePicker';
import pick from 'lodash/fp/pick';
import { withProps } from 'recompose';
import TabRoutes from '../../../core/TabRoutes';
import React, { useEffect } from 'react';
import EntityState from '../../../../se/components/EntityState';
import users from '../../../../assets/images/illustrations/users.svg';
import { Box, Chip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Filters from '../../analytics/Filters';
import { Row } from '../../../entities/feedback/Feedback';
import {
  getClosedPositions,
  getOpenPositions,
  listClosedPositions,
  listOpenPositions,
  openPositionCount,
  openPositionSeenBy,
} from '../../../../graph/staff';
import { OpenPosition, StaffShiftLog, StaffShiftLogType, StaffShiftState } from '../../../../types/StaffShift';
import formatTimeRange from '../../calendar/util/formatTimeRange';
import { OtherSpecialtyLabel, PrimarySpecialtyColors, PrimarySpecialtyLabel } from '../../../../types/StaffMember';
import StaffShiftAction, {
  AssignedStaffMember,
  getStateContent,
  getStatusState,
  getStatusStateChip,
} from './StaffShiftAction';
import { graphql } from '@apollo/client/react/hoc';
import { DefaultTitle } from '../../../../se/components/collection/CollectionList';
import { H4 } from '../../../../se/components/typography';
import { EventLog, EventLogEntry, EventLogItem, EventLogTime1 } from '../../../entities/patient/views/components';
import { format } from 'date-fns';
import { LocalDate, ZonedDateTime } from '@js-joda/core';
import EntityView from '../../../../se/components/entity/EntityView';
import { set } from 'lodash/fp';
import { sortDate, sortString } from '../../../../util/sort';
import OpenPositionCounter, { OpenPositionCounterResetButton } from './OpenPositionCounter';
import { useMutation } from '@apollo/client';
import { useSession } from '../../../../state/Session';
import { getFirstShiftLog } from '../../../../util/staffShiftLogs';
import ExpandableView from '../../../../se/components/collection/ExpandableView';

const Tabs = TabRoutes as any;

const OpenPositionsFilter = ({ value, ...rest }: any) => (
  <Row>
    <Filters
      value={value}
      hidePartnersSelectInput={false}
      hidePhysicianSelectInput={true}
      hideProcedureTypeSelectInput={true}
      dateTimePickerTitle="Filter by Date"
      defaultValue={{
        dateRange: NamedRange.next30Days(),
      }}
      datePickerRanges={(today = LocalDate.now()) => [
        todayRange(today),
        nextSevenDaysRange(today),
        nextMonthRange(today),
        restOfMonthRange(today),
        next30DaysRange(today),
        next90DaysRange(today),
        thisYearRange(today),
      ]}
      {...rest}
    />
  </Row>
);

const ClosedPositionsFilter = ({ value, ...rest }: any) => (
  <Row>
    <Filters
      value={value}
      hidePartnersSelectInput={false}
      textSearchKey={'partner'}
      hidePhysicianSelectInput={true}
      hideProcedureTypeSelectInput={true}
      dateTimePickerTitle="Filter by Date"
      defaultValue={{
        dateRange: NamedRange.last30Days(),
      }}
      datePickerRanges={(today = LocalDate.now()) => [
        lastSevenDaysExcludingTodayRange(today),
        lastMonthRange(today),
        last30DaysRange(today),
        last90DaysRange(today),
        lastYearRange(today),
      ]}
      {...rest}
    />
  </Row>
);

export const columns = (sendSms: boolean) => [
  {
    title: 'Job Opening',
    key: 'job-opening',
    lens: (data: OpenPosition) => data,
    sortFunction: (l: OpenPosition, r: OpenPosition) => sortDate(l.date, r.date),
    Component: (props: { data: OpenPosition }) => {
      const { staffShiftHospitalGroupId, staffShiftHospitalId, staffShiftId, description } = props.data;
      return (
        <ExpandableView
          content={
            props?.data?.date && (
              <>
                <Typography variant={'body1'}>
                  {format(new Date(props?.data?.date + 'T00:00'), 'MMM D, YYYY')}

                  {props.data.unseen && (
                    <Chip
                      style={{
                        marginLeft: 5,
                      }}
                      size="small"
                      label={'New'}
                      color="primary"
                    />
                  )}
                </Typography>
                <Typography variant={'body2'}>{formatTimeRange(props?.data?.from, props?.data?.to)}</Typography>
              </>
            )
          }
          additional={
            <>
              <Typography variant={'body2'} color={'textSecondary'}>
                {staffShiftHospitalId ? `G${staffShiftHospitalGroupId}H${staffShiftHospitalId}-` : ''}
                {`${description?.isProcedure ? 'P' : 'R'}${staffShiftId}`}
              </Typography>
            </>
          }
        />
      );
    },
  },
  {
    title: 'Description',
    key: 'description',
    lens: (data: OpenPosition) => data,
    sortFunction: (l: OpenPosition, r: OpenPosition) =>
      sortString(l.description?.primarySpecialty || null, r.description?.primarySpecialty || null),
    Component: (props: { data: OpenPosition }) => {
      const { title, primarySpecialty, otherSpecialties, description } = props.data?.description ?? {};

      return (
        <ExpandableView
          content={
            <Typography variant={'body1'}>
              {primarySpecialty ? (
                <>
                  {PrimarySpecialtyLabel[primarySpecialty] && (
                    <span style={{ color: PrimarySpecialtyColors[primarySpecialty] }}>●&nbsp;</span>
                  )}{' '}
                  {PrimarySpecialtyLabel[primarySpecialty]}
                </>
              ) : (
                '-'
              )}
            </Typography>
          }
          additional={
            otherSpecialties || title || description ? (
              <>
                {otherSpecialties && (
                  <Typography variant={'body2'} color={'textSecondary'}>
                    {otherSpecialties?.map(e => OtherSpecialtyLabel[e]).join(', ')}
                  </Typography>
                )}
                {title && <Typography variant={'body1'}>{title}</Typography>}
                {description && (
                  <Typography variant={'body2'} color={'textSecondary'}>
                    {description}
                  </Typography>
                )}
              </>
            ) : undefined
          }
        />
      );
    },
  },
  {
    title: 'Hiring Manager',
    key: 'hiring-manager',
    lens: (data: OpenPosition) => data,
    sortFunction: (l: OpenPosition, r: OpenPosition) => sortString(l.staffShiftHospitalName, r.staffShiftHospitalName),
    Component: (props: { data: OpenPosition }) => {
      const event: StaffShiftLog | undefined = getFirstShiftLog(props?.data?.logs, StaffShiftState.CreatedOpenPosition);
      return (
        <ExpandableView
          content={<Typography variant={'body1'}>{props.data.staffShiftHospitalName}</Typography>}
          additional={
            <>
              <Typography variant={'body1'}>{event?.userName}</Typography>
              {!!event?.userPhoneNumber && (
                <Typography variant={'body1'} color={'textSecondary'}>
                  {event?.userPhoneNumber}
                </Typography>
              )}
            </>
          }
        />
      );
    },
  },
  {
    title: 'Candidate',
    key: 'candidate',
    lens: (data: OpenPosition) => data,
    sortFunction: (l: OpenPosition, r: OpenPosition) => sortString(l.staff?.name, r.staff?.name),
    Component: (props: { data: OpenPosition }) => {
      const { staff: staffMember } = props.data;
      return (
        <ExpandableView
          content={
            !!staffMember ? (
              <>
                <Typography variant={'body1'}>{staffMember.name}</Typography>
                {staffMember.primarySpecialty && (
                  <Typography variant={'body1'}>
                    <span style={{ color: PrimarySpecialtyColors[staffMember.primarySpecialty] }}>●&nbsp;</span>
                    {PrimarySpecialtyLabel[staffMember.primarySpecialty]}
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant={'body1'} color="textSecondary">
                No Application
              </Typography>
            )
          }
          additional={!!staffMember ? <AssignedStaffMember openPosition={props.data} sendSms={sendSms} /> : undefined}
        />
      );
    },
  },
  {
    title: 'Status',
    key: 'status',
    lens: (data: OpenPosition) => data,
    sortFunction: (l: OpenPosition, r: OpenPosition) =>
      sortString(getStatusState(l.state, l.date), getStatusState(r.state, r.date)),
    Component: (props: { data: OpenPosition }) => {
      const { date, state } = props.data;
      return (
        <ExpandableView
          content={getStatusStateChip(state, date)}
          additional={getStateContent(props.data?.logs, props.data.staffHospitalName, state, date)}
        />
      );
    },
  },
  {
    title: 'Actions',
    key: 'actions',
    lens: (data: OpenPosition) => data,
    Component: (props: { data: OpenPosition }) => <StaffShiftAction openPosition={props.data} />,
  },
];

const OpenPositionsTitle = () => (
  <Box display="flex" alignItems="center" style={{ gap: '1rem' }}>
    <Typography variant="h2">Open Positions </Typography>
    <OpenPositionCounter />
    <OpenPositionCounterResetButton />
  </Box>
);

const ClosedPositionsTitle = () => (
  <Box display="flex" alignItems="center" style={{ gap: '1rem' }}>
    <Typography variant="h2">Closed Positions</Typography>
  </Box>
);

const OpenPositionsEmptyState = (props: any) => (
  <EntityState
    title="There are currently no open positions"
    hint="Open positions will be displayed here if created"
    illustration={users}
    {...props}
  />
);

const ClosedPositionsEmptyState = (props: any) => (
  <EntityState
    title="There are currently no closed positions"
    hint="Closed positions will be displayed here when closed"
    illustration={users}
    {...props}
  />
);

const getStateChangeEvent = (log: StaffShiftLog): string => {
  switch (log.state) {
    case StaffShiftState.CreatedOpenPosition:
      return `Created By ${log.userName}`;
    case StaffShiftState.BookOpenPosition:
      return `Requested By ${log.userName}`;
    case StaffShiftState.ApprovedByHiringManager:
      return `Approved By Hiring Manager ${log.userName}`;
    case StaffShiftState.ApprovedByStaffMember:
      return `Approved By Staff Member ${log.userName}`;
    case StaffShiftState.RejectedByHiringManager:
      return `Reject By Hiring Manager ${log.userName}`;
    case StaffShiftState.RejectedByStaffMember:
      return `Reject By Staff Member ${log.userName}`;
    case StaffShiftState.Deleted:
      return `Deleted By Staff Member ${log.userName}`;
    case StaffShiftState.Opened:
      return `Opened Position After Being Rejected By Staff Member ${log.userName}`;
    default:
      return 'Unknown';
  }
};

const getEvent = (log: StaffShiftLog): string => {
  switch (log.type) {
    case StaffShiftLogType.ChangedState:
      return getStateChangeEvent(log);
    case StaffShiftLogType.MessageDeliveryOpenPositionInvite:
      return `Open Position SMS Invitation status: ${log?.messageStatus}`;
    case StaffShiftLogType.OpenPositionInvite:
      return `Open Position Invitation sent via SMS`;
    default:
      return 'Unknown';
  }
};

const StaffShiftStatusTag = ({ log }: { log: StaffShiftLog }) => (
  <span style={{ paddingLeft: '5px' }}>{getEvent(log)}</span>
);

const OpenPositionView = (props: { data: OpenPosition; columns: any[] }) => {
  const logs = props.data.logs || [];
  const entityProps = set(
    'columns',
    props.columns.filter((i: any) => i.title !== 'Action')
  )(props);

  const session: any = useSession();
  const userId = session?.session?.user?.id;
  const [markAsSeen] = useMutation(openPositionSeenBy);

  const openPosition = props?.data;

  useEffect(() => {
    (async () => {
      await markAsSeen({
        variables: {
          userId,
          id: openPosition?.staffShiftId,
          shiftHospitalId: openPosition?.staffShiftHospitalId,
          isProcedure: !!openPosition?.description?.isProcedure,
        },
        refetchQueries: [{ query: openPositionCount, variables: { userId } }],
      });
    })();
  }, [
    markAsSeen,
    userId,
    openPosition?.staffShiftId,
    openPosition?.staffShiftHospitalId,
    openPosition?.description?.isProcedure,
  ]);

  return (
    <>
      <EntityView {...entityProps} />
      <EventLog>
        <H4>Event Log</H4>
        {logs.map((log: any, index: number) => (
          <EventLogItem key={index}>
            <EventLogEntry>
              <EventLogTime1>
                {format(ZonedDateTime.parse(log.at).toLocalDateTime().toString(), 'MM/DD/YYYY HH:mm:ss')}
              </EventLogTime1>
              <StaffShiftStatusTag log={log} />
            </EventLogEntry>
          </EventLogItem>
        ))}
      </EventLog>
    </>
  );
};

const OpenPositionTitle = ({ data }: { data: OpenPosition }) => <DefaultTitle>Open Position</DefaultTitle>;

const filterPick = pick([
  'name',
  'physician',
  'procedureType',
  'hospital',
  'partner',
  'status',
  'dateRange',
  'category',
  'speciality',
]);

const OpenPositions = GraphQLCRUD({
  entityName: 'OpenPosition',
  idType: 'string',
  scheme: {
    list: listOpenPositions,
  },
  List: {
    tableKey: 'OpenPosition',
    columns: columns(true),
    TitleAndActions: () => <TitleAction Title={OpenPositionsTitle} />,
    Title: () => <div />,
    FilterComponent: OpenPositionsFilter,
    defaultFilterValues: {
      dateRange: NamedRange.next30Days(),
    },
    pickFilter: filterPick,
    Empty: OpenPositionsEmptyState,
    pollInterval: 30000,
  },
  Show: {
    columns: columns(true).filter(column => column.title !== 'Actions'),
    Title: OpenPositionTitle,
    View: OpenPositionView,
    Actions: (props: any) => <StaffShiftAction openPosition={props.data} />,
    itemProvider: graphql(getOpenPositions, {
      options: ({ idProvider, ...rest }: any) => ({
        variables: {
          id: idProvider(rest),
          fetchPolicy: 'cache-and-network',
        },
      }),
    }),
  },
});

const ClosedPositions = GraphQLCRUD({
  entityName: 'ClosedPosition',
  idType: 'string',
  scheme: {
    list: listClosedPositions,
  },
  List: {
    tableKey: 'ClosedPosition',
    columns: columns(false).filter(column => column.title !== 'Actions'),
    TitleAndActions: () => <TitleAction Title={ClosedPositionsTitle} />,
    Title: () => <div />,
    FilterComponent: ClosedPositionsFilter,
    defaultFilterValues: {
      dateRange: NamedRange.last30Days(),
    },
    pickFilter: filterPick,
    Empty: ClosedPositionsEmptyState,
  },
  Show: {
    columns: columns(false).filter(column => column.title !== 'Actions'),
    Empty: ClosedPositionsEmptyState,
    Title: ClosedPositionsTitle,
    View: OpenPositionView,
    itemProvider: graphql(getClosedPositions, {
      options: ({ idProvider, ...rest }: any) => ({
        variables: {
          id: idProvider(rest),
          fetchPolicy: 'cache-and-network',
        },
      }),
    }),
  },
});

export default withProps({
  tabs: [
    {
      title: 'Open Positions',
      path: '/open',
      component: OpenPositions,
    },
    {
      title: 'Closed Positions',
      path: '/closed',
      component: ClosedPositions,
    },
  ],
})(Tabs);
