import { getHours, getMinutes, parse } from 'date-fns';

export const translateToTz = tz => date => {
  if (!tz) {
    console.warn('Timezone not provided, using America/Los_Angeles');
    return translateToTz('America/Los_Angeles')(date);
  }
  try {
    return parse(date.toLocaleString('en-US', { timeZone: tz }));
  } catch (e) {
    console.error(e);
    return date;
  }
};

// export const translateToPST = translateToTz('America/Los_Angeles');
export const translateToUTC = translateToTz('UTC');

export const getMinutesOfDay = date => getHours(date) * 60 + getMinutes(date);

// export const currentPSTDate = () => translateToPST(new Date());
export const currentDateInZone = zoneId => translateToTz(zoneId)(new Date());
