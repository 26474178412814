import React, { ComponentType } from 'react';
import PinDialog, { PinDialogProps } from '../components/PinDialog';
import { useChartingSession } from './hooks';
import { chartingStore } from './chartingStore';

interface WithPinOptions {
  CloseButton?: PinDialogProps['CloseButton'];
}

export const withPin =
  (options?: WithPinOptions) =>
  <TProps,>(Component: ComponentType<TProps>) =>
  (props: TProps) => {
    const session = useChartingSession();

    return session ? (
      <Component {...props} />
    ) : (
      <PinDialog onEnter={session => chartingStore.setState({ session })} CloseButton={options?.CloseButton} />
    );
  };
