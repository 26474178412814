import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { Collapse, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useMutation } from '@apollo/client';
import { updateTransferStatus as updateTransferStatusMutation } from '../../../graph/scheduleRequests';
import { ScheduleTransferStatus } from './enums';
import Grid from '@material-ui/core/Grid';

const RequestDocumentsButton = ({ requestId, disabled }) => {
  const [updateTransferStatus] = useMutation(updateTransferStatusMutation);
  const [inFocus, setInFocus] = useState(false);
  const [input, setInput] = useState();

  const handleCancel = () => {
    setInFocus(false);
  };

  const handleRequestDocuments = async () => {
    await updateTransferStatus({
      variables: {
        scheduleTransferStatusMessage: input,
        id: requestId,
        scheduleTransferRequestStatus: ScheduleTransferStatus.MissingFiles,
      },
    });
    setInput('');
    handleCancel();
  };

  const handleFocus = () => {
    setInFocus(true);
  };

  return (
    <Box display="flex" alignItems="baseline" justifyContent="space-between" px={2}>
      <Grid container direction="column" spacing={2}>
        <Grid md={6} item>
          <TextField
            fullWidth
            multiline
            rowsMax={4}
            rows={1}
            onFocus={handleFocus}
            variant="outlined"
            placeholder="Something missing? Type in your request here…"
            onChange={event => {
              setInput(event.target.value);
            }}
            value={input}
          />
        </Grid>
        <Grid item>
          <Collapse in={inFocus}>
            <Box display="flex" alignItems="center" mb={2}>
              <Box mr={1}>
                <Button color="primary" variant="contained" onClick={handleRequestDocuments} disabled={disabled}>
                  Request Documents
                </Button>
              </Box>
              <Button onClick={handleCancel}>Cancel</Button>
            </Box>
          </Collapse>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RequestDocumentsButton;
