import React from 'react';
import styled from 'styled-components';
import PickerHeader from './PickerHeader';

const Content = styled.div`
  padding: 0 1em 1em;
  display: flex;
  flex-direction: column;
`;

const PrioritySlots = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  flex: 1;
`;

const PrioritySlotWrapper = styled.button`
  border: none;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  font-size: 4rem;
  border-radius: 0.25rem;

  &:active {
    background: #00b641;
  }

  &:disabled {
    opacity: 0.25;
  }
`;

const PrioritySlot = ({
  priority,
  disabled,
  onClick,
}: {
  priority: number;
  disabled: boolean;
  onClick: (priority: number) => void;
}) => (
  <PrioritySlotWrapper disabled={disabled} onClick={() => !disabled && onClick(priority)}>
    <span>{priority}</span>
  </PrioritySlotWrapper>
);

const PatientPriorityPicker = ({
  priorityCounter,
  assignedPriority,
  onAssignPriority,
  goToStartPage,
  onCancel,
}: {
  priorityCounter: number;
  assignedPriority?: string;
  onAssignPriority: (priority: number) => void;
  goToStartPage: () => void;
  onCancel: () => void;
}) => {
  const handleAssignPriority = async (priority: number) => {
    await onAssignPriority(priority);
    goToStartPage();
  };

  return (
    <Content>
      <PickerHeader onCancel={onCancel} title={'Bed Priority'} bedInput={undefined} />
      <PrioritySlots>
        {Array.from(Array(priorityCounter).keys()).map((p: number) => (
          <PrioritySlot
            key={p}
            disabled={`${p + 1}` === assignedPriority}
            priority={p + 1}
            onClick={handleAssignPriority}
          />
        ))}
      </PrioritySlots>
    </Content>
  );
};

export default PatientPriorityPicker;
