import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles({
  tableRow: {
    cursor: 'pointer',
  },
});

const Row = React.forwardRef(
  (
    {
      history,
      location,
      columns,
      item,
      context,
      showUrlProvider,
      idProvider,
      onClick,
      highlighted,
      highlightedRowStyles,
    },
    ref
  ) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
      <TableRow ref={ref} hover className={classes.tableRow} style={highlightedRowStyles}>
        {columns.map(({ lens, Component, interactive, style, useThemeBackgroundColor }, j) => (
          <TableCell
            key={j}
            className="cell"
            style={
              useThemeBackgroundColor
                ? {
                    whiteSpace: 'nowrap',
                    ...style,
                    backgroundColor: theme.palette.background.default,
                  }
                : {
                    whiteSpace: 'nowrap',
                    ...style,
                  }
            }
            onClick={
              showUrlProvider
                ? () =>
                    history.push({
                      pathname: showUrlProvider(idProvider(item)),
                      search: location.search,
                    })
                : onClick
            }
          >
            <Component data={lens(item, context)} onClick={onClick} />
          </TableCell>
        ))}
      </TableRow>
    );
  }
);

export default Row;
