import React from 'react';
import styled from 'styled-components';
import RouterLink from '../../../../se/components/RouterLink';
import { AutomaticPatientTracking } from '../../../ModuleOptional';
import ProtectedContent from '../../../../security/ProtectedContent';

const Root = styled.div`
  margin: 0 auto;
  max-width: 640px;
  text-align: center;
  margin-top: 25%;
  h3 {
    font-size: 1.6em;
    margin-bottom: 24px;
  }
  p {
  }
`;

const AutoMode = () => (
  <Root>
    <h3>To enter the patient into the system, please place the bracelet on the scanning device</h3>
    <p>
      Scanning device not working? <RouterLink to="/patients/create">Click here</RouterLink> to enter the patient
      manually.
    </p>
  </Root>
);

const ManualMode = () => (
  <Root>
    <ProtectedContent
      accessRight="patient.create"
      content={
        <h3>
          Please use <RouterLink to="/patients/create">Create Patient</RouterLink> button to enter patient information
          into the Ospitek system
        </h3>
      }
      fallback={<h3>Patients will appear here once they are admitted.</h3>}
    />
  </Root>
);

export default AutomaticPatientTracking(AutoMode, ManualMode);
