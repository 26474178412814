import React, { FC } from 'react';
import { Box, Chip, createStyles, makeStyles, MenuItem, MenuList, Popover, useTheme } from '@material-ui/core';
import { Procedure, ProcedureVendor, ShiftState } from '../../../../../types/Procedure';
import { useVendorProcedureContext } from './context/VendorProcedureContext';
import { CheckCircleOutline, HelpOutline, HighlightOff, RemoveCircleOutline } from '@material-ui/icons';
import { useStyles as useStylesSlotMenu } from '../staff/StaffSlotMenu';
import { useScheduleUserContext } from './context/ScheduleUserContext';
import { ScheduleUserType } from '../../../../../types/ScheduleUser';
import procedure from '../timeline/Procedure';

interface VendorProps {
  assignedVendor: ProcedureVendor;
  procedure?: Procedure;
  onClick: (vendorId: number) => Promise<void>;
  editableStaff?: boolean;
}

export const ShiftStateIcon = ({ shiftState }: { shiftState: ShiftState }) => {
  const theme = useTheme();
  return shiftState === ShiftState.Accepted ? (
    <CheckCircleOutline fontSize="small" style={{ color: theme.palette.success.main }} />
  ) : shiftState === ShiftState.Rejected ? (
    <HighlightOff fontSize="small" style={{ color: theme.palette.error.main }} />
  ) : (
    <HelpOutline fontSize="small" style={{ color: theme.palette.info.light }} />
  );
};

const VendorMenu = ({ assignedVendor, editableStaff, onClick }: VendorProps) => {
  const classes = useStylesSlotMenu();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const { setEditing, isEditing } = useScheduleUserContext();

  const open = Boolean(editableStaff ? anchorEl : false);
  const popoverId = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
    setEditing(null, null);
  };

  const removeSlot = async (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    await onClick(assignedVendor?.vendor?.id);
    handleClose();
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setEditing(isEditing(assignedVendor?.id, 'ProcedureShift') ? null : assignedVendor?.id ?? null, 'ProcedureShift');
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Chip
        variant="outlined"
        color={'primary'}
        label={
          <Box display="flex" flexDirection="row" alignItems="center" style={{ gap: '0.2em' }}>
            {assignedVendor?.vendor?.name}
            <ShiftStateIcon shiftState={assignedVendor?.shiftState} />
          </Box>
        }
        onClick={handleButtonClick}
        size="small"
      />
      {open && (
        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClick={e => e.stopPropagation()}
          onClose={handleClose}
          getContentAnchorEl={null}
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{
            paper: classes.popover,
          }}
        >
          <MenuList className={classes.menu} onClick={e => e.stopPropagation()}>
            {editableStaff && (
              <MenuItem onClick={removeSlot}>
                <Chip
                  icon={<RemoveCircleOutline className={classes.iconRemove} />}
                  label={'Remove Slot'}
                  size="small"
                  className={classes.chip}
                />
              </MenuItem>
            )}
          </MenuList>
        </Popover>
      )}
    </>
  );
};

interface VendorsProps {
  procedure?: Procedure;
  editableStaff?: boolean;
  children: React.ReactNode;
}

const Vendors: FC<VendorsProps> = ({ procedure, editableStaff, children }) => {
  const classes = useStyles();
  const vendorContext = useVendorProcedureContext();
  return (
    <div className={classes.vendors}>
      {children}
      {(procedure?.assignedVendors || []).map(assignedVendor => (
        <VendorMenu
          editableStaff={editableStaff}
          onClick={vendorContext.remove}
          key={assignedVendor?.id}
          procedure={procedure}
          assignedVendor={assignedVendor}
        />
      ))}
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    vendors: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      margin: -2,

      '& > *': {
        margin: 2,
      },
    },
  })
);

export default Vendors;
