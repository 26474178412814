import React, { Fragment } from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import { compose, withHandlers, withProps, withState } from 'recompose';

import { getNestedValue } from '../../utilities/data/object';
import LinkButton from '../LinkButton';
import Form from '../Form';
import { withTheme } from '../../theme';

const CenteredLinkButton = styled(LinkButton)`
  align-self: center;
  padding: 0 ${withTheme(theme => `calc(${theme.defaultSpacing} * 0.75)`)};
`;

const SubmitButton = styled(CenteredLinkButton)`
  display: none;
`;

const DangerButton = posed(CenteredLinkButton)({
  visible: {
    opacity: 1,
    transition: {
      default: { duration: 150 },
    },
  },
  hidden: {
    opacity: 0,
    transition: { duration: 150 },
  },
});

const Footer = ({
  label,
  busy,
  disabled,
  isDirty,
  isTransparent,
  dangerLabel = 'Remove',
  handleDangerLinkButtonClick,
  isDangerActionInProgress,
  hover,
}) => (
  <Fragment>
    <SubmitButton disabled={disabled || isDangerActionInProgress} busy={busy} primary>
      {label}
    </SubmitButton>
    <DangerButton
      danger
      type="button"
      onClick={handleDangerLinkButtonClick}
      disabled={busy || disabled}
      initialPose="hidden"
      pose={hover ? 'visible' : 'hidden'}
      tabIndex={-1}
    >
      {dangerLabel}
    </DangerButton>
  </Fragment>
);

const EnhancedFooter = compose(
  withState('isDangerActionInProgress', 'setIsDangerActionInProgress', false),
  withHandlers({
    handleDangerLinkButtonClick: props => async () => {
      const { setIsDangerActionInProgress, onDangerLinkButtonClick, onBusy, onError } = props;

      try {
        onBusy(true);
        onError({
          hasError: false,
          error: undefined,
          errors: undefined,
        });
        setIsDangerActionInProgress(true);
        await onDangerLinkButtonClick();
      } catch (error) {
        onError({
          hasError: true,
          error: getNestedValue('error', error),
          errors: getNestedValue('errors', error),
        });
      } finally {
        onBusy(false);
        setIsDangerActionInProgress(false);
      }
    },
  })
)(Footer);

export default compose(
  withProps({
    Footer: EnhancedFooter,
  })
)(Form);
