import React from 'react';

export default class ActionManipulation {
  private fns: (() => void)[] = [];
  private nextId = 1;
  private readonly interactionsInProgress = new Set<number>();

  fire(fn: () => void) {
    if (this.interactionsInProgress.size === 0) {
      fn();
    } else {
      this.fns.push(fn);
    }
  }

  preventOnClick() {
    return this.delayOnClick(true);
  }

  delayOnClick(preventAsWell: boolean = false) {
    const id = this.nextId++;

    return (event: React.MouseEvent<HTMLElement>) => {
      this.interactionsInProgress.add(id);

      let shouldBePrevented = false;

      if (preventAsWell) {
        const eventTarget = event.target;

        const handleElementMouseUp = (e: Event) => {
          shouldBePrevented = true;
          eventTarget.removeEventListener('mouseup', handleElementMouseUp);
        };

        eventTarget.addEventListener('mouseup', handleElementMouseUp);
      }

      const handleDocumentMouseUp = (e: MouseEvent) => {
        this.interactionsInProgress.delete(id);

        if (!shouldBePrevented) {
          this.fns.forEach(fn => {
            try {
              fn();
            } catch (e) {
              console.error(e);
            }
          });
        }

        this.fns = [];
      };

      document.addEventListener('mouseup', handleDocumentMouseUp);
    };
  }
}
