import { compose, mapProps, withProps } from 'recompose';
import { withLabel } from '../../../se/components/Label';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import TextInput from '../../../se/components/inputs/TextInput';
import { withDescription } from '../../../se/components/Description';
import PhoneInput from '../../../se/components/inputs/PhoneInput';
import EmploymentSelectInput from './EmploymentSelectInput';
import CompensationSelectInput from './CompensationSelectInput';
import PrimarySpecialtySelectInput from './PrimarySpecialtySelectInput';
import NumberInput from '../../../se/components/inputs/NumberInput';
import OtherSpecialtiesSelectInput from './OtherSpecialtiesSelectInput';
import TitleSelectInput from './TitleSelectInput';
import { formatProviderId } from '../../../util/providerId';

const StaffMemberInput = withProps({
  schema: {
    firstName: compose(
      withLabel('First Name'),
      withProps({
        placeholder: 'e.g., Martha',
        autoComplete: 'off',
        required: true,
      })
    )(TextInput),
    lastName: compose(
      withLabel('Last Name'),
      withProps({
        placeholder: 'e.g., Jane',
        autoComplete: 'off',
        required: true,
      })
    )(TextInput),
    hstId: compose(
      withLabel('Provider ID'),
      withProps({
        placeholder: 'e.g., 182',
        autoComplete: 'off',
        required: false,
      }),
      mapProps(({ value, onChange, ...rest }) => ({
        value: formatProviderId(value),
        onChange: next => onChange('l/' + next),
        ...rest,
      }))
    )(NumberInput),
    phoneNumber: compose(
      withLabel('Phone Number'),
      withDescription('This phone number will be used for schedule sharing.'),
      withProps({
        placeholder: 'Enter mobile phone number',
        autoComplete: 'off',
      })
    )(PhoneInput),
    email: compose(
      withLabel('Email'),
      withProps({
        placeholder: 'e.g., john@clinic.edu',
        autoComplete: 'off',
        required: false,
      })
    )(TextInput),
    primarySpecialty: PrimarySpecialtySelectInput,
    otherSpecialties: OtherSpecialtiesSelectInput,
    title: TitleSelectInput,
    employment: EmploymentSelectInput,
    compensation: CompensationSelectInput,
    hourlyRate: compose(
      withLabel('Hourly Rate'),
      withProps({
        autoComplete: 'off',
        required: false,
      })
    )(NumberInput),
  },
})(ObjectInput);

export default StaffMemberInput;
