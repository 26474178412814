import { useMutation } from '@apollo/client';
import { sensorReports } from '../../../graph/sensors';
import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { generateSensorDetailedAlertReport, generateSensorReportAudit } from './ReportGenerator';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';
import { makeStyles } from '@material-ui/core/styles';
import objectHash from 'object-hash/dist/object_hash';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0.5),
  },
}));

function useExpiringState(initialValue) {
  const [state, setState] = useState(initialValue);

  useEffect(() => {
    if (state !== null) {
      const timeout = setTimeout(() => {
        setState(null);
      }, 60000);

      return () => clearTimeout(timeout);
    }
  }, [state]);

  return [state, setState];
}

const PrintDialog = ({ sensorIds, filter, scope }) => {
  const classes = useStyles();
  const [getReportData] = useMutation(sensorReports);

  const [sensorReportAudit, setSensorReportAudit] = useExpiringState(null);
  const [sensorDetailedAlertReport, setSensorDetailedAlertReport] = useExpiringState(null);

  const [working, setWorking] = useState(false);

  const onPrint = (printHandler, state, setState) => async () => {
    const variables = { dateRange: filter.dateRange.toJSON(), sensors: sensorIds };
    const variablesHash = objectHash(JSON.parse(JSON.stringify(variables)));
    if (state !== null && state[0] === variablesHash) {
      printHandler({ sensors: get(state[1], 'data.sensorReports'), hospitalName: get(scope, 'hospital.name') });
    } else {
      setWorking(true);
      try {
        const result = await getReportData({ variables });
        setState([variablesHash, result]);
        printHandler({ sensors: get(result, 'data.sensorReports'), hospitalName: get(scope, 'hospital.name') });
      } catch (e) {
        console.log(e);
      } finally {
        setWorking(false);
      }
    }
  };

  const onPrintAudit = onPrint(generateSensorReportAudit, sensorReportAudit, setSensorReportAudit);
  const onPrintDetailed = onPrint(
    generateSensorDetailedAlertReport,
    sensorDetailedAlertReport,
    setSensorDetailedAlertReport
  );

  return (
    <Box>
      <Button
        startIcon={<PrintIcon />}
        onClick={onPrintAudit}
        disabled={working}
        className={classes.button}
        color="primary"
      >
        Detailed Report
      </Button>

      <Button
        startIcon={<PrintIcon />}
        onClick={onPrintDetailed}
        disabled={working}
        className={classes.button}
        color="primary"
      >
        Audit Report
      </Button>
    </Box>
  );
};

export default PrintDialog;
