import Form, { FormType } from '../../../../types/Form';
import { Patient } from '../../../../types/Patient';
import { useQuery } from '@apollo/client';
import PatientChartForm from '../../../entities/surgeonProcedures/PatientChartForm';
import React, { CSSProperties } from 'react';
import { list } from '../../../../graph/surgeon/forms';
import { findByFormType, getQuestionnaireType } from './ChartingForms';
import { alpha, Box, ButtonBase } from '@material-ui/core';
import ChartingQuestionnairesContext from '../../../../contexts/ChartingQuestionnairesContext';
import withStyles from '@material-ui/core/styles/withStyles';
import { tryParseJson } from '../../../../util/parseJson';

const OverlayButton = withStyles(theme => ({
  root: {
    position: 'absolute',
    backgroundColor: alpha(theme.palette.secondary.main, 0.15),
    zIndex: 1,
  },
}))(ButtonBase);

type Config = {
  styles: CSSProperties;
  slide: number;
  chartingId: number;
};

export const ChartingForm = ({
  hospitalId,
  patient,
  formType,
  openChartingWithSlide,
}: {
  hospitalId: number;
  patient: Patient;
  formType: FormType;
  openChartingWithSlide: (chartingId: number, slide: number) => void;
}) => {
  const { data: formsData } = useQuery(list);
  const form: Form | undefined = findByFormType(formsData?.forms, formType);
  const configs: Config[] = tryParseJson(form?.config) || [];

  if (form) {
    return (
      <ChartingQuestionnairesContext.Provider
        value={{
          preOpCharts: patient?.procedure?.preOpCharts || [],
          orCharts: patient?.procedure?.orCharts || [],
          pacuCharts: patient?.procedure?.pacuCharts || [],
          physicianCharts: patient?.procedure?.physicianCharts || [],
          anesthesiologistPreOpCharts: patient?.procedure?.anesthesiologistPreOpCharts || [],
          anesthesiologistOrCharts: patient?.procedure?.anesthesiologistOrCharts || [],
        }}
      >
        {patient?.procedure?.id && (
          <Box position="relative" flex={1}>
            <Box position="relative" display="flex" mx="auto" width={967}>
              {(configs || []).map((config: Config, i: number) => (
                <OverlayButton
                  data-name="OverlayButton"
                  style={config.styles}
                  onClick={() => {
                    openChartingWithSlide(config.chartingId, config.slide);
                  }}
                />
              ))}
            </Box>

            <PatientChartForm
              id={form?.id}
              hospitalId={hospitalId}
              patientId={patient?.id}
              procedureId={patient?.procedure?.id}
              questionnaireType={getQuestionnaireType(form?.type)}
              isolated={true}
            />
          </Box>
        )}
      </ChartingQuestionnairesContext.Provider>
    );
  }

  return null;
};

export default ChartingForm;
