import React from 'react';
import { openPdf } from '../../../../../../entities/procedures/pdf/shared';
import { format } from 'date-fns';
import { createPdf } from '../../../../../../../vendor/pdfMake';
import { Room } from '../../../../../../../types/Room';
import StaffShift from '../../../../../../../types/StaffShift';

export interface StaffShiftExtended extends StaffShift {
  isProcedure?: boolean;
}

export type RoomWithProcedureFlag = Omit<Room, 'staffShifts'> & { staffShifts: Array<StaffShiftExtended> };

const tableRowMargin = [0, 2, -10, -4];
const tableRowFontSize = 10;
const MINIMUM_NUMBER_OF_LINES = 7; // It’s actually -> MINIMUM_NUMBER_OF_LINES - 1

const getRoomInfo = (room: RoomWithProcedureFlag) => {
  const staffShifts = room.staffShifts;
  const shiftsLength = room.staffShifts.length;

  let additionalNumOfLines =
    shiftsLength <= MINIMUM_NUMBER_OF_LINES ? MINIMUM_NUMBER_OF_LINES - shiftsLength : shiftsLength;
  if (shiftsLength >= MINIMUM_NUMBER_OF_LINES - 1) additionalNumOfLines = 0;

  return {
    stack: [
      {
        table: {
          headerRows: 1,
          widths: [100, 140],
          body: [
            [
              { text: room.name, style: 'tableHeaderRoomTitle' },
              {
                text: 'TIME IN',
                style: {
                  bold: true,
                  fontSize: 10,
                  color: 'black',
                  alignment: 'left',
                },
              },
            ],
            ...staffShifts.map(shift => {
              const shiftTimeSpan = +!!shift.from
                ? `${shift?.isProcedure ? 'Procedure: ' : ''}${shift.from}${shift.to ? ' - ' + shift.to : ''}`
                : '';
              const staffName = shift.staff?.name;
              const additionalText = shift?.additionalText;
              const secondColumn = `${shiftTimeSpan} ${!!additionalText ? '(' + additionalText + ')' : ''}`;
              return [
                {
                  text: staffName,
                  fontSize: tableRowFontSize,
                  margin: tableRowMargin,
                },
                {
                  text: secondColumn,
                  fontSize: tableRowFontSize,
                  margin: tableRowMargin,
                },
              ];
            }),
            ...[...new Array(additionalNumOfLines)]
              .map((element: any) => '')
              .map(line => [
                {
                  text: '   ',
                  margin: tableRowMargin,
                  fontSize: tableRowFontSize,
                },
                { text: '   ', margin: tableRowMargin, fontSize: tableRowFontSize },
              ]), // Is there some smarter way to do this?
          ],
        },
        layout: 'lightHorizontalLines',
      },
    ],
  };
};

type PageBreak = 'after' | 'before';

const getPageContent = (
  date: Date,
  firstRoomTypeContent: RoomWithProcedureFlag[],
  secondRoomTypeContent: RoomWithProcedureFlag[] | undefined,
  title?: string,
  pageBreak?: PageBreak
) => ({
  stack: [
    {
      text: `DAILY STAFFING SCHEDULE ${!!title ? title : ''}`,
      style: 'header',
      alignment: 'center',
      margin: [0, 0, 0, 0],
    },
    {
      columns: [
        {
          text: `Date:`,
          alignment: 'right',
        },
        {
          text: `${format(date, 'MM.DD.YYYY')}`,
          style: {
            decoration: 'underline',
            alignment: 'left',
          },
        },
      ],
      style: 'subheader',
      alignment: 'center',
    },
    {
      columns: [
        {
          stack: [firstRoomTypeContent.map(room => getRoomInfo(room))],
        },
        {
          stack: [secondRoomTypeContent?.map(room => getRoomInfo(room))],
        },
      ],
    },
  ],
  pageBreak: pageBreak,
});

// function for sorting the non-OR rooms, putting PreOp and PACU before
const sortNotOrRooms = (a: RoomWithProcedureFlag, b: RoomWithProcedureFlag) => {
  const sortOrder = { PreOp: 0, PACU: 1 };

  if (a.name in sortOrder && b.name in sortOrder) {
    return sortOrder[a.name] - sortOrder[b.name];
  } else if (a.name in sortOrder) {
    return -1;
  } else if (b.name in sortOrder) {
    return 1;
  } else {
    return a.name.localeCompare(b.name);
  }
};

const geLayout = (date: Date, orRooms: RoomWithProcedureFlag[], notOrRooms: RoomWithProcedureFlag[]) => {
  // temporary fix by sorting rooms based of name
  orRooms.sort((a, b) => a.name.localeCompare(b.name));
  notOrRooms.sort(sortNotOrRooms);

  if (orRooms.length <= 4 && notOrRooms.length <= 4) {
    return getPageContent(date, notOrRooms, orRooms, undefined);
  } else if (orRooms.length + notOrRooms.length <= 8) {
    if (notOrRooms.length > 4) {
      return getPageContent(date, notOrRooms.slice(0, 4), notOrRooms.slice(4).concat(orRooms), undefined);
    } else {
      return getPageContent(date, orRooms.slice(0, 4), orRooms.slice(4).concat(notOrRooms), undefined);
    }
  } else {
    return {
      stack: [
        getPageContent(date, notOrRooms.slice(0, 4), notOrRooms.slice(4), undefined, 'after'),
        getPageContent(date, orRooms.slice(0, 4), orRooms.slice(4), '(OR)'),
      ],
    };
  }
};

const getScheduleContent = (date: Date, rooms: RoomWithProcedureFlag[]) =>
  geLayout(
    date,
    rooms.filter(room => room.type === 'OR'),
    rooms.filter(room => room.type !== 'WAITING_ROOM' && room.type !== 'OR')
  );

const getOrdinalSuffix = (day: number) => {
  const suffix = ['th', 'st', 'nd', 'rd'];
  const v = day % 100;
  return suffix[(v - 20) % 10] || suffix[v] || suffix[0];
};

const formatDateWithOrdinal = (date: Date) => {
  const day = format(date, 'DD');
  const dayWithSuffix = `${day}${getOrdinalSuffix(parseInt(day, 10))}`;
  const month = format(date, 'MMMM');
  const year = format(date, 'YYYY');
  const weekday = format(date, 'dddd');
  return `${weekday}, ${month} ${dayWithSuffix} ${year}`;
};
export const StaffSchedulePdfGenerator = (hospitalName: string, date: Date, rooms: RoomWithProcedureFlag[]) => {
  const docDefinition = {
    pageSize: 'LETTER',
    pageMargins: [40, 40, 40, 40],
    defaultStyle: {
      fontSize: 9,
      lineHeight: 1.4,
    },
    info: { title: `Staff Schedule` },
    header: {
      stack: [
        {
          text: formatDateWithOrdinal(date),
          style: 'header',
          alignment: 'center',
          margin: [0, 10, 0, 10],
        },
      ],
    },
    content: [
      getScheduleContent(
        date,
        rooms.map(room => ({ ...room, staffShifts: room.staffShifts.filter(staff => !!staff.staff?.name) }))
      ),
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: 'justify',
      },
      subheader: {
        fontSize: 14,
        bold: true,
        alignment: 'justify',
        margin: [0, 0, 0, 10],
      },
      tableHeaderRoomTitle: {
        bold: true,
        fontSize: 16,
        color: 'black',
        fillColor: '#CCCCCC',
        margin: [2, 0, 0, -10],
      },
    },
  };

  openPdf(createPdf(docDefinition), `Staff_Schedule${format(date, 'MM_DD_YYYY_HH_mm_ss')}`);
};
