import React from 'react';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useQuery } from '@apollo/client';
import { Patient } from '../../../../types/Patient';
import { QuestionnaireType } from '../../../../types/Questionnaire';
import { sortBy } from 'lodash';
import { list } from '../../../../graph/config';
import get from 'lodash/get';
import { isValueOutOfRange } from '../../../../util/patientChart';
import DataItem from './components/DataItem';
import { ChartingSections } from './Charting';
import { ConfigType } from '../../../../types/HospitalConfig';

export const ChartIndicators = ({ combined }: { combined: Object }) => {
  const classes = useStyles();
  const { data: configData } = useQuery(list, {
    variables: {
      type: ConfigType.PatientChartField,
    },
  });

  return (
    <Box className={classes.formFieldParams}>
      {sortBy(configData?.configs || [], 'id')
        .filter((e: any) => e.values && JSON.parse(e.values)?.name !== 'TEMPF')
        .map((e: any) => {
          const config = JSON.parse(e.values || '{}');
          let value = config?.path ? get(combined as any, config?.path) : undefined;
          let unit = config?.unit;

          if (
            config?.name === 'TEMP' &&
            get(combined as any, 'tempF') !== undefined &&
            get(combined as any, 'tempF') !== ''
          ) {
            value = get(combined as any, 'tempF');
            unit = '°F';
          }
          const isRemarkable = isValueOutOfRange(config, value);

          return (
            <DataItem
              key={config?.name}
              name={config?.name || '-'}
              value={value}
              unit={unit}
              isRemarkable={isRemarkable}
            />
          );
        })}
    </Box>
  );
};

const ChartInfo = ({
  patient,
  patientId,
  hospitalId,
  questionnaireType,
  combined,
}: {
  patient: Patient | undefined;
  hospitalId: number;
  patientId: number;
  questionnaireType: QuestionnaireType;
  combined: Object;
}) => (
  <>
    <ChartingSections
      patientId={patientId}
      hospitalId={hospitalId}
      patient={patient}
      questionnaireType={questionnaireType}
      indicators={combined}
    />
  </>
);

export const useStyles = makeStyles({
  formFieldParams: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export default ChartInfo;
