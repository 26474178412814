import React from 'react';
import styled from 'styled-components';
import Button from '../../../se/components/Button';

const Container = styled.div`
  display: flex;
  max-width: 600px;
  flex-direction: column;
`;

const RoomButton = styled(Button)`
  display: flex;
  width: 100%;
  font-size: 1.25em;
  margin-bottom: 0.5em;
  ${props =>
    props.selected
      ? `
    background: url('data:image/svg+xml,\\
      <svg xmlns="http://www.w3.org/2000/svg">\\
      <style>@keyframes foo {to {stroke-dashoffset: -15px}}<\\/style>\\
      <rect width="100%" height="100%" style="stroke: ${props.theme.warningColor.string()}; stroke-width: 4px; fill: none;\\
      stroke-dasharray: 10px 5px; animation: foo 0.75s infinite linear" />\\
      </svg>')  
  `
      : ''}
`;

const RoomSelection = ({ rooms = [], onSelect, isRecording, selectedRoom }) => (
  <Container>
    {rooms.map(room => (
      <RoomButton
        key={room.id}
        onClick={() => onSelect(room.id)}
        disabled={!isRecording}
        selected={selectedRoom === room.id}
      >
        {room.name}
      </RoomButton>
    ))}
  </Container>
);

export default RoomSelection;
