import { graphql } from '@apollo/client/react/hoc';
import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { compose, withProps } from 'recompose';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import PatientInputFactory from '../../inputs/patient/PatientInput';
import scheme, { listActiveAndPastPatients } from '../../../graph/patients';
import { mapEditItemProps, mapListProps, prepareCreateData } from './transducers';
import {
  braceletIdColumn,
  dateOfVisit,
  idColumn,
  nameColumn,
  note,
  operationRoom,
  patientAgeColumn,
  patientSexColumn,
  phoneNumberColumn,
  physicianColumn,
  preOpBedNumber,
  procedureDurationColumn,
  procedureStartTimeColumn,
  procedureTypeColumn,
  providerIdColumn,
  recoveryBedNumber,
  roomOrDateOfVisit,
  smsNotificationNumbersColumn,
  visitDurationColumn,
} from './columns';
import Filters from '../../pages/analytics/Filters';
import PatientListView from './views/PatientListView';
import PatientView from './views/PatientView';
import PatientShowTitle from '../../patient/PatientShowTitle';
import responsive from '../../../se/utilities/responsive';
import PatientMobileListItem from './views/PatientMobileListItem';
import { NamedRange } from '../../core/DatePicker';
import activeAndPastProceduresSource from '../../../omnisearch/sources/hospital/activeAndPastProcedures';
import pick from 'lodash/fp/pick';
import { get, set } from 'lodash/fp';
import { PatientType } from '../../../types/Patient';
import { useQuery } from '@apollo/client';
import { orTabletProcedureSteps, procedureSteps } from '../../../graph/procedureSteps';
import { baseEventColumns, getEventsColumns } from './util/patientEvents';
import { useContext } from 'react';
import { SessionContext, withSession } from '../../../state/Session';
import UserRoles from '../../../types/UserRoles';
import { withScope } from '../../../contexts/ScopeContext';
import PatientShowActions from '../../patient/PatientShowActions';
import { Switch, withRouter } from 'react-router-dom';
import useTabs from '../../pages/kiosk/charting/modules/useTabs';
import { Route } from 'react-router';
import ChartingPatientView from '../../pages/kiosk/charting/ChartingPatientView';
import WhiteThemeProviderWrapper from '../../../WhiteThemeProviderWrapper';
import ChartingProfile from '../../pages/kiosk/charting/screens/ChartingProfile';
import { QuestionnaireType } from '../../../types/Questionnaire';
import { useScope } from '../../../hooks/useScope';
import { useChartingSession } from '../../pages/kiosk/charting/modules/hooks';
import { chartingStore } from '../../pages/kiosk/charting/modules/chartingStore';
import identity from 'lodash/identity';
import { ANESTHESIOLOGIST, PHYSICIAN } from '../role/enums';
import ActiveAndPastPatientCharting from './ActiveAndPastPatientCharting';
import Dictation from '../../pages/kiosk/charting/screens/Dictation';
import ActiveAndPastPatientsDictation from './ActiveAndPastPatientsDictation';

const PatientCreationInput = PatientInputFactory(true, false);
const PatientUpdateInput = PatientInputFactory(false);
const Empty = { illustration: theme => theme.illustrations.patients };

const FiltersWrapper = styled.div`
  ${responsive.md.andSmaller`
    padding-bottom: 0rem;
    display: flex;
    flex-flow: column;
    .hidden {
      margin-bottom: 2em;
    }
  `};
`;

const filterEventColumns = props => {
  const hasPostop = get('scope.hospital.modules.hasPostop')(props);
  return hasPostop
    ? props
    : set(
        'columns',
        get('columns')(props).filter(column => column.title !== 'Post Op Entry')
      )(props);
};

export const CustomFilters = props => (
  <FiltersWrapper>
    <Filters {...props} />
  </FiltersWrapper>
);

const ActiveAndPastPatients = withRouter(props => {
  const { data } = useQuery(procedureSteps);

  const sessionContext = useContext(SessionContext);
  const roles = sessionContext.session?.user?.metadata ?? [];
  const isMonitoringEngineer = roles.indexOf(UserRoles.MonitoringEngineer) >= 0;

  const isOrTabletV2 = props?.scope?.hospital?.modules?.orTabletV2;
  const steps = data?.procedureSteps || [];

  const eventColumns = isOrTabletV2 ? getEventsColumns(steps) : baseEventColumns;

  const PatientCRUDComponent = GraphQLCRUD({
    entityName: 'Patient',
    scheme: {
      ...scheme,
      list: listActiveAndPastPatients,
    },
    List: {
      tableKey: 'ActiveAndPastPatients',
      MobileItemComponent: PatientMobileListItem,
      useColumnSelection: true,
      useCSVExport: !isMonitoringEngineer,
      columns: [
        idColumn,
        providerIdColumn,
        braceletIdColumn,
        nameColumn('id'),
        patientAgeColumn,
        patientSexColumn,
        procedureStartTimeColumn,
        procedureDurationColumn,
        physicianColumn,
        procedureTypeColumn,
        operationRoom,
        preOpBedNumber,
        recoveryBedNumber,
        visitDurationColumn,
        roomOrDateOfVisit,
        ...eventColumns,
      ],
      View: PatientListView,
      FilterComponent: withProps({
        textSearchKey: 'name',
        hideSpecialitySelectInput: false,
        hideProcedureTypeSelectInput: true,
        hideMobile: true,
      })(CustomFilters),
      defaultFilterValues: {
        dateRange: NamedRange.lastSevenDays(),
      },
      pickFilter: pick([
        'name',
        'physician',
        'procedureType',
        'hospital',
        'status',
        'dateRange',
        'category',
        'speciality',
      ]),
      Empty,
      mapListProps: compose(mapListProps, filterEventColumns),
      containsSeparator: true,
    },
    Show: {
      columns: [
        idColumn,
        providerIdColumn,
        physicianColumn,
        procedureTypeColumn,
        smsNotificationNumbersColumn,
        phoneNumberColumn,
        dateOfVisit,
        note,
      ],
      Empty,
      View: PatientView,
      Title: PatientShowTitle,
      Actions: PatientShowActions,
      itemProvider: graphql(scheme.subscription, {
        options: ({ idProvider, ...rest }) => ({
          variables: {
            id: parseInt(idProvider(rest), 10),
            fetchPolicy: 'cache-and-network',
          },
        }),
      }),
    },
    Create: {
      Input: PatientCreationInput,
      Empty,
      prepareCreateData,
      initialValue: { type: PatientType.Out },
    },
    Edit: {
      mapEditItemProps,
      Input: PatientUpdateInput,
      Empty,
      prepareUpdateData: prepareCreateData,
    },
    searchSource: activeAndPastProceduresSource,
  });

  const tabs = useTabs();

  const scope = useScope();

  const chartingSession = useChartingSession();

  const organizationId = get('hospital.id')(scope);
  const name = get('session.user.name')(props);
  const userMetadata = get('session.user.metadata', props) || [];
  const memberships = get('session.user.memberships', props) || [];
  const membership = memberships.find(m => m.organization.id === organizationId);
  const membershipMetadata = get('metadata', membership) || [];
  const metadata = [...userMetadata, ...membershipMetadata];
  const isPhysician = metadata.indexOf(PHYSICIAN) >= 0;
  const isAnesthesiologist = metadata.indexOf(ANESTHESIOLOGIST) >= 0;
  const [ready, setReady] = useState(false);

  useEffect(() => {
    chartingStore.setState({
      session: {
        id: -1,
        name,
        pin: '-1',
        role: isPhysician ? 'physician' : isAnesthesiologist ? 'anesthesiologist' : 'staff',
      },
      pinTimeout: null,
      keepAlive: 0,
    });
    setReady(true);
  }, [name, isPhysician, isAnesthesiologist]);

  return (
    <ThemeProvider theme={{ gridListColumnsTemplate: 'auto auto auto minmax(10vw,2fr) auto' }}>
      {ready &&
        (Array.isArray(metadata) ? (
          <Switch>
            {tabs.map(tab => (
              <Route
                key={tab.path}
                {...tab}
                path={`${props.match.url}/:id${tab.path}`}
                component={undefined}
                render={props => {
                  const patientId = parseInt(get('params.id')(props.match), 10);

                  return (
                    <>
                      <ActiveAndPastPatientCharting
                        {...props}
                        hospitalId={organizationId}
                        patientId={patientId}
                        questionnaireType={tab.chartType}
                        room="App"
                      />
                      <Route
                        path={`${props.match.path}/dictation`}
                        render={props => {
                          return (
                            <ActiveAndPastPatientsDictation
                              {...props}
                              hospitalId={organizationId}
                              questionnaireType={tab.chartType}
                            />
                          );
                        }}
                      />
                    </>
                  );
                }}
              />
            ))}
            <Route render={() => <PatientCRUDComponent {...props} />} />
          </Switch>
        ) : (
          <PatientCRUDComponent {...props} />
        ))}
    </ThemeProvider>
  );
});

export default compose(withSession(identity), withScope)(ActiveAndPastPatients);
