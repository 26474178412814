import React from 'react';
import styled from 'styled-components';
import TextInput from '../../../se/components/inputs/TextInput';
import { Mutation } from '@apollo/client/react/components';
import { updateThreshold } from '../../../graph/gateways';
import responsive from '../../../se/utilities/responsive';
import isFinite from 'lodash/isFinite';

const InlineTextInput = styled(TextInput)`
  outline: none;
  width: 4.5em;
  ${props => (props.disabled ? 'pointer-events: none' : undefined)};

  ${responsive.xs.andSmaller`
    width: 100%;
  `};
`;

class ThresholdInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: props.threshold };
  }

  onChange = mutation => value => {
    this.setState({ value });

    const { threshold, gateway } = this.props;
    const parsed = parseInt(value);
    if (threshold !== parsed && isFinite(parsed)) {
      mutation({ variables: { id: gateway, threshold: parsed } });
    }
  };
  render() {
    return (
      <Mutation mutation={updateThreshold}>
        {editGateway => (
          <InlineTextInput
            disabled={this.props.disabled}
            value={this.state.value}
            onChange={this.onChange(editGateway)}
          />
        )}
      </Mutation>
    );
  }
}

export default ThresholdInput;
