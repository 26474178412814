import React, { useState } from 'react';
import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createTheme';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Paper } from '@material-ui/core';
import { MatrixTableQuestion } from '../../../../types/Question';
import { ItemCountsTableAnswer, MatrixTableAnswer } from '../../../../types/Answer';

const columns = [{
  label: 'PRE-OP COUNT',
  value: 'preOpCount',
}, {
  label: 'ADDITIONS',
  value: 'additions',
}, {
  label: 'RELIEF COUNT / FINAL COUNT',
  value: 'reliefCount',
}];

const rows = [{
  label: 'RAYTEC SPONGES',
  value: 'raytecSponges',
}, {
  label: 'SUTURES',
  value: 'sutures',
}, {
  label: 'KNIFE BLADES',
  value: 'knifeBlades',
}, {
  label: 'DRILL PINS',
  value: 'drillPins',
}, {
  label: 'HYPODERMICS',
  value: 'hypodermics',
}, {
  label: 'BOVIE TIP / CLEANER',
  value: 'bovieTipCleaner',
}, {
  label: 'LAPS',
  value: 'laps',
}, {
  label: 'OTHER',
  value: 'other',
}];

type TableProps = {
  answer?: ItemCountsTableAnswer;
  onChange: (value: ItemCountsTableAnswer) => void;
}

const ItemCountsTable = ({ answer, onChange }: TableProps) => {

  const handleChange = (row: string, column: string, value: string) => {
    onChange?.(answer ? {
      ...answer,
      [row]: (answer?.[row] ? {
        ...(answer?.[row] || {}),
        [column]: value,
      } : {
        [column]: value,
      }),
    } : {
      [row]: {
        [column]: value,
      },
    });
  };


  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ITEM COUNTS</TableCell>
            {
              columns.map(e => (
                <TableCell>{e.label}</TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.value}>
              <TableCell>{row.label}</TableCell>
              {
                columns.map(col => (
                  <TableCell>
                    <TextField
                      value={answer?.[row?.value]?.[col?.value] || ''}
                      onChange={(e) => handleChange(row?.value, col?.value, e.target.value)}
                      inputProps={{
                        inputMode: 'numeric',
                      }}
                    />
                  </TableCell>
                ))
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ItemCountsTable;
