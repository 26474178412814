import React from 'react';
import { Answer, Field, Question } from './Containers';
import get from 'lodash/get';

export default ({ label, value }) => (
  <Field>
    <Question>{label}</Question>
    <Answer>{get(value, 'text')}</Answer>
  </Field>
);
