import { Query } from '@apollo/client/react/components';
import React from 'react';
import styled from 'styled-components';
import { feedbackStats } from '../../../graph/feedback';
import { get } from 'lodash';
import { HospitalRatingWidget } from '../../pages/analytics/HospitalRating';
import pick from 'lodash/fp/pick';
import { Analytics } from '../analytics/Analytics';

const AnalyticsWrapper = styled.div`
  > :first-child {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }
`;

const pickFilter = pick(['name', 'dateRange', 'physician', 'procedureStatus', 'procedureType']);

const FeedbackStats = ({ filter }) => (
  <Query
    query={feedbackStats}
    variables={{ filter: { ...pickFilter(filter), dateRange: filter.dateRange.toJSON() } }}
    fetchPolicy={'network-only'}
  >
    {({ data, loading }) => (
      <AnalyticsWrapper>
        <HospitalRatingWidget stats={get(data, 'hospitalRatingStats')} loading={loading} />
        <Analytics data={data} pathFeedbackStats={'feedbackStats'} pathRatingStats={'hospitalRatingStats'} />
      </AnalyticsWrapper>
    )}
  </Query>
);

export default FeedbackStats;
