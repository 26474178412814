export default class ImmutableSet<T> {
  static empty<T>(): ImmutableSet<T> {
    return new ImmutableSet<T>();
  }
  static of<T>(...elements: T[]): ImmutableSet<T> {
    return ImmutableSet.from(elements);
  }
  static from<T>(elements: T[]): ImmutableSet<T> {
    return new ImmutableSet<T>(new Set(elements));
  }

  private _set = new Set<T>();

  private constructor(set: Set<T> = new Set()) {
    this._set = set;
  }

  add(...values: T[]): ImmutableSet<T> {
    if (values.every(value => this._set.has(value))) {
      return this;
    }

    const setClone = new Set(this._set);

    values.forEach(value => {
      setClone.add(value);
    });

    return new ImmutableSet<T>(setClone);
  }

  delete(...values: T[]): ImmutableSet<T> {
    if (values.every(value => !this._set.has(value))) {
      return this;
    }

    const setClone = new Set(this._set);

    values.forEach(value => {
      setClone.delete(value);
    });

    return new ImmutableSet<T>(setClone);
  }

  has(value: T): boolean {
    return this._set.has(value);
  }

  size(): number {
    return this._set.size;
  }

  isEmpty(): boolean {
    return this.size() === 0;
  }

  values(): T[] {
    return Array.from(this._set);
  }
}
