import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import get from 'lodash/fp/get';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import EntityView from '../../../se/components/entity/EntityView';
import PhysicianProcedureTypesForm from './PhysicianProcedureTypesForm';
import scheme, {
  grantScheduleAccess,
  resendScheduleAccessLink,
  revokeScheduleAccess,
  setScheduleAccessAllPhysician,
} from '../../../graph/physicians';
import { listColumns, viewColumns } from './columns';
import PhysicianInput from '../../inputs/physician/PhysicianInput';
import PhysicianMobileListItem from './PhysicianMobileListItem';
import ScheduleAccessEditor from '../../pages/ScheduleAccessEditor';
import { PhysicianUploadButton } from '../../inputs/upload/UploadButton';
import ScheduleAccessToggle from '../../pages/ScheduleAccessToggle';

export const PhysicianScheduleAccessEditor = ({ physician, onClose, refetchQueries = [], ...rest }) => (
  <ScheduleAccessEditor
    grantScheduleAccess={grantScheduleAccess}
    resendScheduleAccessLink={resendScheduleAccessLink}
    revokeScheduleAccess={revokeScheduleAccess}
    {...physician}
    onClose={onClose}
    {...rest}
  >
    <ScheduleAccessToggle
      scheduleUserId={physician?.id}
      scheduleAccessAll={physician?.scheduleAccessAll}
      setScheduleAccessAll={setScheduleAccessAllPhysician}
      refetchQueries={refetchQueries || []}
    />
  </ScheduleAccessEditor>
);

const PhysicianAndProcedureTypesView = props => (
  <Fragment>
    <EntityView {...props} />
    <PhysicianProcedureTypesForm {...props} scheme={scheme} />
  </Fragment>
);

export default GraphQLCRUD({
  entityName: 'Physician',
  List: {
    tableKey: 'Physicians',
    columns: listColumns,
    Actions: PhysicianUploadButton,
    MobileItemComponent: PhysicianMobileListItem,
    simpleFilter: true,
    additionalRoutes: [
      {
        key: 'schedule-access',
        path: '/schedule-access/:id',
        Component: props => {
          const { data: item, loading } = useQuery(scheme.item, {
            variables: {
              id: parseInt(get('params.id')(props.match), 10),
            },
          });

          return !loading && item.physician ? <PhysicianScheduleAccessEditor {...item} {...props} /> : null;
        },
      },
    ],
  },
  Show: {
    columns: viewColumns,
    View: PhysicianAndProcedureTypesView,
  },
  Create: {
    Input: PhysicianInput,
  },
  Edit: {
    Input: PhysicianInput,
  },
  scheme,
});
