import React, { useState, useMemo } from 'react';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import { Box, Typography } from '@material-ui/core';
import { DateHeader } from '../WeekView/Day';
import ProcedureSlotPopover from '../WeekView/ProcedureSlotPopover';
import DayProceduresPopover from './DayProceduresPopover';
import styled from 'styled-components';
import { useScope } from '../../../../../hooks/useScope';
import { parse } from 'date-fns';

const MAX_PROCEDURES_SHOWN = 4;

const ProcedureItemStyled = styled(Box)`
  display: flex;

  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
`;

const ShowMoreStyled = styled(Box)`
  cursor: pointer;
  white-space: nowrap;
  overflow-y: visible;

  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
`;

const ShowMore = ({ children, procedures, canUseScheduleViewProcedure, setEditProcedureMonthly }) => {
  const [anchor, setAnchor] = useState(null);

  const open = Boolean(anchor);

  const handlePopoverOpen = (event, data) => {
    setAnchor({ element: event.currentTarget, data });
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  return (
    <ShowMoreStyled
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onClick={e => {
        e.stopPropagation();
        handlePopoverOpen(e, procedures);
      }}
    >
      {children}
      <DayProceduresPopover
        isOpen={open}
        onClose={handlePopoverClose}
        anchorElement={anchor?.element}
        data={anchor?.data}
        title={anchor?.data[0]?.or}
        canUseScheduleViewProcedure={canUseScheduleViewProcedure}
        setEditProcedureMonthly={setEditProcedureMonthly}
      />
    </ShowMoreStyled>
  );
};

const ProcedureItem = ({ procedure, onClick }) => {
  const [anchor, setAnchor] = useState(null);

  const open = Boolean(anchor);

  const handlePopoverOpen = (event, data) => {
    setAnchor({ element: event.currentTarget, data });
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  return (
    <ProcedureItemStyled
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={e => handlePopoverOpen(e, procedure)}
      onMouseLeave={handlePopoverClose}
      onClick={onClick}
    >
      <Typography variant="textSecondary">{get(procedure, 'startTimeText')}</Typography>
      <ProcedureSlotPopover
        isOpen={open}
        onClose={handlePopoverClose}
        anchorElement={anchor?.element}
        data={anchor?.data}
      ></ProcedureSlotPopover>
    </ProcedureItemStyled>
  );
};

const Day = ({ day, procedures, rooms, setEditProcedureMonthly, openProcedureForm, canUseScheduleViewProcedure }) => {
  const scope = useScope();
  const createProcedureScheduleModule = scope?.hospital?.modules?.createProcedureSchedule;
  const roomProcedures = useMemo(
    () =>
      groupBy(
        procedures.map(_ => ({ ..._, start: parse(_.startTime) })),
        _ => _.or
      ),
    [procedures]
  );

  return (
    <Box style={{ gap: '.25em', overflow: 'hidden' }} display="flex" flexDirection="column" flex={1} height="100%">
      <DateHeader date={day} procedureCount={procedures.length} />
      <Box display="flex" flexGrow={1}>
        {rooms.map(room => (
          <Box
            ml={0.5}
            flex={1}
            height="100%"
            key={`${room.id}`}
            onClick={
              createProcedureScheduleModule && canUseScheduleViewProcedure
                ? e => {
                    e.stopPropagation();
                    openProcedureForm(undefined, rooms, room, day);
                  }
                : undefined
            }
          >
            {roomProcedures[room.name] ? (
              <>
                {roomProcedures[room.name].slice(0, MAX_PROCEDURES_SHOWN).map(p => (
                  <ProcedureItem
                    procedure={p}
                    onClick={
                      canUseScheduleViewProcedure
                        ? e => {
                            e.stopPropagation();
                            createProcedureScheduleModule
                            ? openProcedureForm(p, rooms, room, day)
                            : setEditProcedureMonthly(p);
                          }
                        : undefined
                    }
                  />
                ))}
                {roomProcedures[room.name].length > MAX_PROCEDURES_SHOWN ? (
                  <ShowMore
                    procedures={roomProcedures[room.name]}
                    canUseScheduleViewProcedure={canUseScheduleViewProcedure}
                    setEditProcedureMonthly={setEditProcedureMonthly}
                  >{`${roomProcedures[room.name].length - MAX_PROCEDURES_SHOWN} more`}</ShowMore>
                ) : null}
              </>
            ) : null}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Day;
