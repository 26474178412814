import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { defaultsDeep } from 'lodash';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { MenuItem, TextField } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import {
  create as createMutation,
  list,
  remove as deleteMutation,
  update as updateMutation,
} from '../../../../../graph/dictationTemplate';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDictationTemplate } from '../../../../../contexts/DictationTemplateContext';
import { useDictationContext } from '../../../../../contexts/DictationsContext';
import { useForm } from '../../../../../contexts/FormContext';
import { useChartingSession } from '../../../../pages/kiosk/charting/modules/hooks';

interface PredefinedFormProps<TValue> {
  hospitalName: string;
  defaultValue: TValue;
  value: TValue;
  setValue: (value: TValue) => void;
  questionnaire: any;
  questionnaireAnswers: any;
  lastPreOpCompletedEvent: any;
  procedureId: number;
  showQRCode?: boolean;
  formName: string;
}

interface Props<TValue> extends PredefinedFormProps<TValue> {
  children: (page: ReactNode, index: number) => ReactNode;
}

function divideText(line: string, maxCharacters: number): string[] {
  if (line.length < maxCharacters) return [line];

  const words = line.split(' ');
  const lines: string[] = [];
  let currentLine = '';

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (currentLine.length + word.length <= maxCharacters) {
      if (currentLine.length > 0) {
        currentLine += ' ';
      }
      currentLine += word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }

  lines.push(currentLine);

  return lines;
}

export interface DictationTemplate {
  id?: number;
  title?: string;
  template?: string;
}

export const TemplateDialogForm = ({
  title,
  open,
  onClose,
  item,
  onSave,
}: {
  title: string;
  open: boolean;
  onClose: () => void;
  item: DictationTemplate;
  onSave: (item: DictationTemplate) => void;
}) => {
  const [formValues, setFormValues] = React.useState({
    title: item.title || '',
    template: item.template || '',
  });

  const handleChange = event => {
    const { name, value } = event.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    onSave({ ...item, ...formValues });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          id="title"
          name="title"
          label="Title"
          variant="outlined"
          margin="normal"
          value={formValues.title}
          onChange={handleChange}
        />
        <TextField
          multiline
          maxRows={4}
          fullWidth
          id="template"
          name="template"
          label="Template"
          variant="outlined"
          margin="normal"
          value={formValues.template}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const TemplatesMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [nestedAnchorEl, setNestedAnchorEl] = useState(null);
  const [state, setState] = useState<'NEW' | 'UPDATE' | 'CHOOSE' | 'DELETE' | null>(null);
  const [template, setTemplate] = useState<DictationTemplate | null>(null);

  const dt = useDictationTemplate();
  const text = dt?.value || '';
  const applyTemplate = dt?.onChange || (() => {});

  const handleOpenNestedMenu = event => {
    setNestedAnchorEl(event.currentTarget);
  };

  const handleCloseNestedMenu = () => {
    setNestedAnchorEl(null);
  };

  const handleOpenParentMenu = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseParentMenu = () => {
    setAnchorEl(null);
    setState(null);
  };

  const chartingSession = useChartingSession();

  const { data, loading } = useQuery(list, {
    variables: { pin: chartingSession?.pin },
  });

  const templates: DictationTemplate[] = data?.dictationTemplates || [];

  const [create] = useMutation(createMutation);

  const createTemplate = async (title: string, content: string) => {
    await create({
      variables: {
        pin: chartingSession?.pin,
        title,
        content,
      },
      refetchQueries: [{ query: list, variables: { pin: chartingSession?.pin } }],
    });
  };

  const [update] = useMutation(updateMutation);

  const updateTemplate = async (id: number, title: string, content: string) => {
    await update({
      variables: {
        id,
        pin: chartingSession?.pin,
        title,
        content,
      },
      refetchQueries: [{ query: list, variables: { pin: chartingSession?.pin } }],
    });
  };

  const [remove] = useMutation(deleteMutation);

  const deleteTemplate = async (id: number) => {
    await remove({
      variables: {
        id,
        pin: chartingSession?.pin,
      },
      refetchQueries: [{ query: list, variables: { pin: chartingSession?.pin } }],
    });
  };

  const saveAsNew = () => {
    setState('NEW');
    setTemplate({ template: text });
  };

  const onChoose = (e: any) => {
    setState('CHOOSE');
    setTemplate(null);
    handleOpenNestedMenu(e);
  };

  const onUpdate = (e: any) => {
    setState('UPDATE');
    setTemplate(null);
    handleOpenNestedMenu(e);
  };

  const onDelete = (e: any) => {
    setState('DELETE');
    handleOpenNestedMenu(e);
  };

  const pickTemplate = async (item: DictationTemplate) => {
    if (state === 'CHOOSE') {
      applyTemplate(item?.template || '');
    } else if (state === 'UPDATE') {
      setTemplate(item);
    } else {
      item.id && (await deleteTemplate(item.id));
    }

    handleCloseNestedMenu();
  };

  const createOrUpdate = async (item: DictationTemplate) => {
    if (state === 'NEW') {
      item?.title && item?.template && (await createTemplate(item?.title, item?.template));
    } else if (state === 'UPDATE') {
      item?.id && item?.title && item?.template && (await updateTemplate(item?.id, item?.title, item?.template));
    }
    setState(null);
  };

  return (
    <div>
      <Button onClick={handleOpenParentMenu} variant="contained">
        Templates
      </Button>
      <Menu id="parent-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseParentMenu}>
        <MenuItem onClick={onChoose}>Choose Template</MenuItem>
        <MenuItem onClick={saveAsNew}>Save as new Template</MenuItem>
        <MenuItem onClick={onUpdate}>Update Template</MenuItem>
        <MenuItem onClick={onDelete}>Delete Template</MenuItem>
      </Menu>
      <Menu id="nested-menu" anchorEl={nestedAnchorEl} open={Boolean(nestedAnchorEl)} onClose={handleCloseNestedMenu}>
        {templates.length === 0 && <MenuItem onClick={handleCloseNestedMenu}>No Templates</MenuItem>}
        {templates.length > 0 && (
          <>
            {templates.map(e => (
              <MenuItem onClick={() => pickTemplate(e)}>{e.title}</MenuItem>
            ))}
          </>
        )}
      </Menu>
      {template && (
        <TemplateDialogForm
          title={state === 'NEW' ? 'Create Template' : 'Update Template'}
          item={template}
          open={state === 'NEW' || state === 'UPDATE'}
          onClose={() => setState(null)}
          onSave={createOrUpdate}
        />
      )}
    </div>
  );
};

const DictationForm = <TValue,>({ defaultValue, value, setValue, children }: Props<any>) => {
  const [procedureflag, setProcedureFlag] = useState(true);

  const dt = useDictationTemplate();
  const applyTemplate = dt?.onChange || (() => {});

  useEffect(() => {
    if (value?.procedure?.name) {
      setProcedureFlag(false);
    }
  }, [value?.procedure?.name]);

  const procedureName = value?.procedure?.name || (procedureflag && defaultValue?.procedure?.name) || '';
  const dictations = value?.dictations || defaultValue?.dictations || '';

  const textareaRef = useRef<any>(null);

  const defaultStyle: any = {
    overflow: 'hidden',
    resize: 'none',
    width: '100%',
  };

  useEffect(() => {
    if (textareaRef?.current) {
      textareaRef.current.style.height = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 'px';
    }
  }, [dictations]);

  return (
    <>
      <style>
        {`
        html {
        overflow: hidden;
        font-family: Arial;
    }

    article {
        display: flex;
        flex-direction: column;
        padding: 1% 3%;
        font-size: 13px;
    }

    p {
        margin: 0;
        margin-bottom: 10px;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    li {
        margin-bottom: 2px;
    }

    input {
        color: #0B2161;
        border: none;
        font-size: 15px;
        outline: none;
        border-bottom: 1px solid black;
    }

    textarea {
        color: #0B2161;
        width: 98%;
        border: none;
        font-size: 15px;
        outline: none;
        resize: none;
        border-bottom: 1px solid black;
    }

    .noborder {
        border: none;
    }

    .width75 {
        width: 75%;
    }

    .width78 {
        width: 78%;
    }

    .width80 {
        width: 80%;
    }

    .width82 {
        width: 82%;
    }

    .width85 {
        width: 85%;
    }

    .width87 {
        width: 87%;
    }

    .width88 {
        width: 88%;
    }

    .width90 {
        width: 90%;
    }

    .width98 {
        width: 98%;
    }

    .width100 {
        width: 100%;
    }

    /* Customize the label (the container) */
    .container {
        display: unset;
        position: relative;
        padding-left: 20px;
        cursor: pointer;
        margin-top: 3px;
        margin-bottom: 3px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        line-height: 15px;
        min-height: 15px;
    }

    /* Hide the browser’s default checkbox */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input~.checkmark {
        border-color: #2196F3;
    }

    @media print {

        /* When the checkbox is checked, add a blue background */
        .container input:checked~.checkmark {
            border-color: black;
        }

        .container .checkmark:after {
            border: solid black;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        article {
            zoom: 100%;
        }

    }
        `}
      </style>
      {children(
        <div className="page">
          <article>
            <section style={{ marginTop: '5%' }}>
              <center style={{ fontSize: '20px' }}>Dictation Form</center>
              <ul style={{ marginTop: '5%' }}>
                <li style={{ marginBottom: '15px' }}>
                  Patient Name:
                  <input
                    type="text"
                    name="patient.name"
                    className="width88"
                    value={defaultValue?.procedure?.patient?.name}
                    onChange={e =>
                      setValue(
                        defaultsDeep(
                          {
                            patient: {
                              name: e.target.value,
                            },
                          },
                          value || defaultValue
                        )
                      )
                    }
                  />
                </li>
                <li style={{ marginBottom: '15px' }}>
                  Patient DOB:
                  <input
                    type="text"
                    name="procedure.patient.dob"
                    className="width88"
                    value={defaultValue?.procedure?.patient?.dob}
                    onChange={e =>
                      setValue(
                        defaultsDeep(
                          {
                            procedure: {
                              patient: {
                                dob: e.target.value,
                              },
                            },
                          },
                          value || defaultValue
                        )
                      )
                    }
                  />
                </li>
                <li style={{ marginBottom: '15px' }}>
                  Pre-Operative Diagnosis:
                  <input
                    type="text"
                    name="preOpDiagnosisDictationForm"
                    className="width80"
                    value={value?.preOpDiagnosisDictationForm || defaultValue?.preOpDX}
                    onChange={e =>
                      setValue(
                        defaultsDeep(
                          {
                            preOpDiagnosisDictationForm: e.target.value,
                          },
                          value || defaultValue
                        )
                      )
                    }
                  />
                </li>
                <li style={{ marginBottom: '15px' }}>
                  Post-Operative Diagnosis:
                  <input
                    type="text"
                    name="postOpDiagnosisDictationForm"
                    className="width78"
                    value={value?.postOpDiagnosisDictationForm || value?.postOpDX?.ifNo || value?.preOpDX}
                    onChange={e =>
                      setValue(
                        defaultsDeep(
                          {
                            postOpDiagnosisDictationForm: e.target.value,
                          },
                          value || defaultValue
                        )
                      )
                    }
                  />
                </li>
                <li style={{ marginBottom: '15px' }}>
                  Procedure Name:
                  <input
                    type="text"
                    name="procedure.name"
                    className="width85"
                    value={procedureName}
                    onChange={e =>
                      setValue(
                        defaultsDeep(
                          {
                            procedure: {
                              name: e.target.value,
                            },
                          },
                          value || defaultValue
                        )
                      )
                    }
                  />
                </li>
                <li style={{ marginBottom: '15px' }}>
                  Anesthesia:
                  <input
                    type="text"
                    name="anesthesiaDictationForm"
                    className="width90"
                    value={value?.anesthesiaDictationForm || defaultValue?.anesthesiologist}
                    onChange={e =>
                      setValue(
                        defaultsDeep(
                          {
                            anesthesiaDictationForm: e.target.value,
                          },
                          value || defaultValue
                        )
                      )
                    }
                  />
                </li>
                <li style={{ marginBottom: '15px' }}>
                  Assistant:
                  <input
                    type="text"
                    name="assistantDictationForm"
                    className="width90"
                    value={value?.assistantDictationForm || defaultValue?.assist}
                    onChange={e =>
                      setValue(
                        defaultsDeep(
                          {
                            assistantDictationForm: e.target.value,
                          },
                          value || defaultValue
                        )
                      )
                    }
                  />
                </li>
                <li style={{ marginBottom: '15px' }}>
                  Estimated Blood Loss:
                  <input
                    type="text"
                    name="bloodLossDictationForm"
                    className="width82"
                    value={value?.bloodLossDictationForm || defaultValue?.ebl}
                    onChange={e =>
                      setValue(
                        defaultsDeep(
                          {
                            bloodLossDictationForm: e.target.value,
                          },
                          value || defaultValue
                        )
                      )
                    }
                  />
                </li>
                <li style={{ marginBottom: '15px' }}>
                  Complications:
                  <input
                    type="text"
                    name="complicationsDictationForm"
                    className="width87"
                    value={value?.complicationsDictationForm || defaultValue?.complications?.ifYes}
                    onChange={e =>
                      setValue(
                        defaultsDeep(
                          {
                            complicationsDictationForm: e.target.value,
                          },
                          value || defaultValue
                        )
                      )
                    }
                  />
                </li>
                <li style={{ marginBottom: '15px' }}>
                  Indications:
                  <input
                    type="text"
                    name="indications"
                    className="width90"
                    value={defaultValue?.indications}
                    onChange={e =>
                      setValue(
                        defaultsDeep(
                          {
                            indications: e.target.value,
                          },
                          value || defaultValue
                        )
                      )
                    }
                  />
                </li>
                <li style={{ marginBottom: '15px' }}>
                  <div>Description of Procedure:</div>
                  <textarea
                    id="dictations"
                    ref={textareaRef}
                    style={defaultStyle}
                    value={dictations}
                    onChange={e => {
                      setValue(
                        defaultsDeep(
                          {
                            dictations: e.target.value,
                          },
                          value || defaultValue
                        )
                      );
                      applyTemplate(e.target.value);
                    }}
                  />
                  <div id="dictations-print">{dictations?.trim()}</div>
                  <style>
                    {`#dictations-print { display: none; }
                    @media print { #templates-menu { display: none; } #dictations { display: none } #dictations-print { display: block; white-space: pre-wrap; } }`}
                  </style>
                </li>
              </ul>
            </section>
          </article>
        </div>,
        0
      )}
    </>
  );
};

export default DictationForm;
