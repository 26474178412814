import get from 'lodash/get';
import identity from 'lodash/identity';
import {
  INTERNAL_MONITOR,
  INTERNAL_MONITOR_LABELS,
  OPERATION_ROOM_MONITOR,
  PACU_TABLET,
  PREP_TABLET,
  WAITING_ROOM_MONITOR,
} from '../screens/enums';
import { ROOM_TYPE_LABELS } from '../room/enums';

export const issueIcon = {
  TOO_FEW_ACTIVE_BRACELETS: 'warning',
  LOW_BATTERY: 'battery_alert',
  GATEWAY_UNRESPONSIVE: 'signal_wifi_off',
  SCREEN_OFFLINE: 'mobile_off',
  MANUAL_ENTRY: 'touch_app',
  PATIENT_COLLISION: 'group',
};

export const makePriorityColors = theme => ({
  LOW: theme.palette.text.primary,
  MEDIUM: theme.palette.warning.main,
  HIGH: theme.palette.error.main,
});

export const getIssueColor = (data, theme) => {
  if (data.isResolved) {
    return theme.palette.text.secondary;
  }

  return makePriorityColors(theme)[data.priority];
};

export const getIssueIcon = data => issueIcon[data.type];

const getPercentage = priority => {
  switch (priority) {
    case 'HIGH':
      return 'under 10%';
    case 'MEDIUM':
      return 'under 20%';
    case 'LOW':
      return 'under 30%';
    default:
      return 'running low';
  }
};

export const getIssueText = data => {
  switch (data.type) {
    case 'TOO_FEW_ACTIVE_BRACELETS':
      return `Number of active bracelets is too low`;
    case 'LOW_BATTERY':
      return `Bracelet battery ${getPercentage(data.priority)} (${data.braceletId || 'Unknown Bracelet'})`;
    case 'GATEWAY_UNRESPONSIVE':
      return `Gateway unresponsive (${data.gatewayId || 'Unknown Gateway'}${
        get(data, 'room.name') ? ` - ${get(data, 'room.name')}` : ''
      })`;
    case 'SCREEN_OFFLINE':
      const screenCode = data.screenCode;
      const screenTypeType = get(data, 'screenType.type');
      const screenType = [INTERNAL_MONITOR, WAITING_ROOM_MONITOR, PACU_TABLET, PREP_TABLET].includes(screenTypeType)
        ? INTERNAL_MONITOR_LABELS[screenTypeType]
        : screenTypeType === OPERATION_ROOM_MONITOR
        ? get(data, 'screenType.room.name') || ROOM_TYPE_LABELS.OR
        : undefined;
      return `Screen offline (${[screenCode, screenType].filter(identity).join(' - ')})`;
    case 'MANUAL_ENTRY':
      return 'A patient’s location was changed manually.';
    case 'PATIENT_COLLISION':
      return `There is more than one patient in ${data.room && data.room.name ? data.room.name : 'one of the ORs'}.`;
    default:
      return '';
  }
};
