import React, { FC, useEffect, useState, VFC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Virtuoso } from 'react-virtuoso';
import CalendarYear from './CalendarYear';
import { format } from 'date-fns';
import { END_YEAR, Lifecycle, START_YEAR, THIS_YEAR, useStyles } from './StaffMemberCalendar';
import ImmutableSet from './types/ImmutableSet';
import Dates from './types/Dates';
import HolidayEditor from './HolidayEditor';
import { DateString } from './util/groupStaffAvailability';
import { HolidayCalendar as HolidayCalendarType } from '../../../types/HolidayCalendar';
import classNames from 'clsx';
import useHolidayCalendar from './hooks/useHolidayCalendar';
import { useDeselect } from './hooks/useDeselect';
import { RouteComponentProps } from 'react-router';
import { CalendarTabs, Params } from './MultipleCalendarPage';

export const holidayColor = '#cd5c5c';
export const ptoColor = '#996819';

const HolidayCalendar: FC<RouteComponentProps<Params>> = props => {
  const classes = useStyles();

  const [years, setYears] = useState<ImmutableSet<number>>(ImmutableSet.of<number>());
  const yearValues = years.isEmpty() ? [new Date().getFullYear()] : years.values();

  const { selectedDates, setSelectedDates, highlightedDates, setHighlightedDates } = useDeselect();

  const [holidayGroup, setHolidayGroup] = useState<HolidayCalendarType[]>([]);
  const [freeGroup, setFreeGroup] = useState<Dates>(Dates.empty);

  const { holidays, holidaysPerDate, doCreateHoliday, doRemoveHoliday, doUpdateHoliday } = useHolidayCalendar(
    `${Math.min(...yearValues)}-01-01`,
    `${Math.max(...yearValues) + 1}-01-01`
  );

  useEffect(() => {
    const selected = selectedDates.values();
    let hasHoliday: HolidayCalendarType[] = [];
    holidays.forEach(holiday => {
      if (selected.includes(holiday.date)) {
        hasHoliday.push(holiday);
      }
    });
    const hasHolidayGroupDates = hasHoliday.map(holiday => holiday.date);
    setFreeGroup(Dates.from(selected.filter(singleSelected => !hasHolidayGroupDates.includes(singleSelected))));
    setHolidayGroup(hasHoliday);
  }, [holidays, selectedDates]);

  return (
    <CalendarTabs {...props}>
      <Box className={classes.container}>
        <Virtuoso
          totalCount={END_YEAR - START_YEAR}
          initialTopMostItemIndex={THIS_YEAR - START_YEAR}
          itemContent={index => (
            <Lifecycle
              key={index}
              onMount={() => setYears(prev => prev.add(START_YEAR + index))}
              onUnmount={() => setYears(prev => prev.delete(START_YEAR + index))}
            >
              <CalendarYear
                year={START_YEAR + index}
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                highlightedDates={highlightedDates}
                renderDate={date => (
                  <DateViewCalendar
                    date={format(date, 'YYYYMMDD')}
                    selected={selectedDates.has(format(date, 'YYYYMMDD'))}
                    holiday={holidays}
                  />
                )}
                renderDateTooltip={date =>
                  holidaysPerDate.get(format(date, 'YYYYMMDD')).length > 0 ? (
                    <DateTooltip date={date} holidays={holidaysPerDate.get(format(date, 'YYYYMMDD'))} />
                  ) : null
                }
              />
            </Lifecycle>
          )}
        />
        {!selectedDates.isEmpty() && (
          <HolidayEditor
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            highlightedDates={highlightedDates}
            setHighlightedDates={setHighlightedDates}
            holidayGroup={holidayGroup}
            freeGroup={freeGroup}
            createHoliday={async (dates, description) => {
              if (!dates.isEmpty()) {
                await doCreateHoliday({
                  variables: {
                    dates: dates.values().map(date => format(date, 'YYYY-MM-DD')),
                    description: description || '',
                  },
                });
              }
            }}
            deleteHoliday={async ids => {
              if (!ids.isEmpty()) {
                await doRemoveHoliday({
                  variables: {
                    eventIds: ids.values(),
                  },
                });
              }
            }}
            updateHoliday={async (ids, description) => {
              if (!ids.isEmpty()) {
                await doUpdateHoliday({
                  variables: {
                    eventIds: ids.values(),
                    description: description,
                  },
                });
              }
            }}
          />
        )}
      </Box>
    </CalendarTabs>
  );
};

export const DateViewCalendar: VFC<{
  date: DateString;
  selected: boolean;
  holiday: HolidayCalendarType<DateString>[];
}> = ({ date, selected, holiday }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.day, { [classes.selected]: selected })}>
      {holiday.map(holidayDay =>
        holidayDay.date === date ? (
          <div
            key={holidayDay.id}
            style={{
              flex: '1 1 1px',
              backgroundColor: holidayColor,
            }}
          />
        ) : null
      )}
    </div>
  );
};
const DateTooltip: VFC<{
  date: Date;
  holidays: HolidayCalendarType<DateString>[];
}> = ({ date, holidays }) => {
  const classes = useStyles();
  return (
    <Box className={classes.column}>
      <Box>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          {format(date, 'MM/DD/YYYY')}
        </Typography>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          Holiday
        </Typography>
        {holidays.map(e => (
          <Box className={classes.row}>
            <Typography>{e.description}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default HolidayCalendar;
