import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { excludeFromMap } from '../../utilities/data/object';
import DefaultSelectInput from './SelectInput';

const SwitchInput = props => {
  const { SelectInput, name, typeKey, options, value, onChange, flatten, isDisabled, ...rest } = props;

  const type = value ? value[typeKey] : undefined;
  const option = options[type];
  const Component = option ? option.Input : undefined;
  const selectOptions = Object.entries(options).reduce((acc, [key, { label }]) => ({ ...acc, [key]: label }), {});

  const handleSelectInputChange = option => {
    if ((value && value[typeKey]) !== option) {
      const rest = flatten.reduce((acc, key) => ({ ...acc, [key]: undefined }), {});
      onChange({ [typeKey]: option, ...rest });
    }
  };

  const handleInputChange = value =>
    onChange(option.flatten ? { [typeKey]: type, ...value } : { [typeKey]: type, [option.valueKey]: value });

  return (
    <Fragment>
      <SelectInput
        name={`${name}.${typeKey}`}
        value={type}
        onChange={handleSelectInputChange}
        options={selectOptions}
        isDisabled={isDisabled}
      />
      {Component ? (
        <Component
          name={`${name}.${typeKey}`}
          value={option.flatten ? excludeFromMap(value, typeKey) : value[option.valueKey]}
          onChange={handleInputChange}
          {...rest}
        />
      ) : null}
    </Fragment>
  );
};

SwitchInput.defaultProps = {
  SelectInput: DefaultSelectInput,
  typeKey: 'type',
};

SwitchInput.propTypes = {
  SelectInput: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  typeKey: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SwitchInput;
