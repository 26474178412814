import 'core-js';
import 'regenerator-runtime/runtime';
import 'requestidlecallback-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import { unregister } from './serviceWorker';
import Entrypoint from './components/Entrypoint';

if (import.meta.env.MODE === 'development') {
  import('@axe-core/react').then(axe => axe.default(React, ReactDOM, 1000));
}

if (document.getElementById('root')) {
  ReactDOM.render(<Entrypoint />, document.getElementById('root'));

  import('./util/duration').then(formatDuration => (window.formatDuration = formatDuration));

  // Unregister service worker
  unregister();

  // Make sure application is refreshed on tablet screens.
  if (window.navigator.standalone === true) {
    const reloadApp = () => {
      if (document.visibilityState === 'hidden') {
        if (
          window.location.pathname.startsWith('/kiosk/') &&
          !window.location.pathname.match(/^\/kiosk\/\d+\/charting\/\d+$/)
        ) {
          ReactDOM.unmountComponentAtNode(document.getElementById('root'));
        }
      } else {
        ReactDOM.render(<Entrypoint />, document.getElementById('root'));
      }
    };
    document.addEventListener('visibilitychange', reloadApp);
  }
}
