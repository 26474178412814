import React, { FC, useState } from 'react';
import AddStaffSlot from './AddStaffSlot';
import { Box, createStyles, makeStyles, Tooltip, Typography, withStyles } from '@material-ui/core';
import { PrimarySpecialtyLabel } from '../../../../../types/StaffMember';
import { OpenPositionDescription } from '../../../../../types/StaffShift';
import formatTimeRange from '../../../calendar/util/formatTimeRange';

interface StaffSlotProps {
  id: number;
  from?: string;
  to?: string;
  openPosition?: OpenPositionDescription;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void | Promise<void>;
}

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'grey',
    maxWidth: 200,
  },
}))(Tooltip);

const StaffSlot: FC<StaffSlotProps> = ({ id, from, to, openPosition, onClick }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <>
      {!!openPosition && (
        <StyledTooltip
          open={open}
          title={
            <Box>
              <Box className={classes.box}>
                <Typography className={classes.title}>Time Slot</Typography>
                <Typography className={classes.value}>{formatTimeRange(from, to)}</Typography>
              </Box>
              <Box className={classes.box}>
                <Typography className={classes.title}>Title</Typography>
                <Typography className={classes.value}>
                  {openPosition?.title ? JSON.parse(openPosition?.title)?.title : '-'}
                </Typography>
              </Box>
              <Box className={classes.box}>
                <Typography className={classes.title}>Primary Specialty</Typography>
                <Typography className={classes.value}>
                  {(PrimarySpecialtyLabel as any)[openPosition?.primarySpecialty || ''] || '-'}
                </Typography>
              </Box>
              <Box className={classes.box}>
                <Typography className={classes.title}>Other Specialties</Typography>
                <Typography className={classes.value}>
                  {openPosition?.otherSpecialties?.map(e => (e as any).label)?.join(', ') || '-'}
                </Typography>
              </Box>
              <Box className={classes.box}>
                <Typography className={classes.title}>Description</Typography>
                <Typography className={classes.value}>{openPosition?.description || '-'}</Typography>
              </Box>
            </Box>
          }
        >
          <Box onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
            <AddStaffSlot from={from} to={to} openPosition={openPosition} onClick={onClick} />
          </Box>
        </StyledTooltip>
      )}
    </>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    box: {
      padding: theme.spacing(1),
    },
    title: {
      fontWeight: 'bold',
      fontSize: '1.2em',
    },
    value: {
      fontSize: '1.2em',
    },
  })
);

export default StaffSlot;
