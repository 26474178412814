import React, { createContext, useContext } from 'react';
import { ReactNode } from 'react';

const procedureIdContext = createContext<number | undefined>(undefined);

export const useProcedureIdContext = (): number | undefined => {
  return useContext(procedureIdContext);
};

interface ProcedureIdContextProviderProps {
  procedureId?: number;
  children: ReactNode;
}

const ProcedureIdContextProvider = ({ procedureId, children }: ProcedureIdContextProviderProps) => {
  return <procedureIdContext.Provider value={procedureId}>{children}</procedureIdContext.Provider>;
};

export default ProcedureIdContextProvider;
