import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { renderLegend } from './ORPerformancePieChart';
import React from 'react';
import CustomTooltip from './CustomTooltip';
import { useTheme } from '@material-ui/core';

const CompareBarChart = ({ data }) => {
  const theme = useTheme();

  return (
    <ResponsiveContainer width="100%" height="100%" aspect={1}>
      <BarChart layout="vertical" data={data}>
        <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.25)" />
        <YAxis type="category" dataKey="title" stroke="#FFFFFF" fontSize="12" />
        <XAxis hide type="number" />
        <Tooltip
          content={({ active, payload, label }) => <CustomTooltip active={active} payload={payload} label={label} />}
          cursor={{ fill: 'rgba(255,255,255,0.15)' }}
          contentStyle={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: 4,
            border: 0,
          }}
        />
        <Bar dataKey="inOr" stackId="a" fill="#2B3C87" />
        <Bar dataKey="inSurgery" stackId="a" fill="#74AC48" />
        <Bar dataKey="closing" stackId="a" fill="#B7DD9A" />
        <Bar dataKey="procedureComplete" stackId="a" fill="#A6AFD9" />
        <Bar dataKey="cleaning" stackId="a" fill="#6A78B6" />
        <Bar dataKey="orReady" stackId="a" fill="#485796" />
        <Legend content={renderLegend} align="left" verticalAlign="bottom" iconType="circle" iconSize={8} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CompareBarChart;
