import React, { useState } from 'react';
import { useValue } from '../ValueContext';
import { useQuestionnaireBooleanValue } from '../hooks';
import toBoolean from '../../../../../questionnaire/toBoolean';

const styles = {
  row: {
    border: '1px solid black',
    display: 'flex',
    lineHeight: '25px',
    borderTop: 0,
  },
};

const MedicalPassportRiskFactorRow = ({ text, name, path, reducer, style }) => {
  const { defaultValue, value, setValue } = useValue(name);
  const [currentValuePriority, setCurrentValuePriority] = useState(false);
  const questionnaireValue = useQuestionnaireBooleanValue(reducer || toBoolean, path);
  return (
    <div style={{ ...styles.row, ...style }}>
      <div style={{ borderRight: '1px solid black' }}>
        <input
          type="checkbox"
          name={name}
          checked={currentValuePriority ? value : value || questionnaireValue || defaultValue || false}
          onChange={e => {
            setValue(e.target.checked);
            setCurrentValuePriority(true);
          }}
        />
      </div>
      <div style={{ padding: '0 5px' }}>{text}</div>
    </div>
  );
};

export default MedicalPassportRiskFactorRow;
