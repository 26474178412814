import Button from '../../../../../se/components/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import React, { useState } from 'react';
import { Procedure } from '../../../../../types/Procedure';
import { makeStyles } from '@material-ui/core/styles';
import UpcomingProcedure from '../../schedule/overview/Procedure';
import Slide from '@material-ui/core/Slide';

export const useStyles = makeStyles(theme => ({
  dialogContent: {
    background: theme.palette.background.default,
    fontSize: '1.5em',
    height: '100%',
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

const NextProcedures = ({ nextProceduresInOperationRoom }: { nextProceduresInOperationRoom: Procedure[] }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const hide = (nextProceduresInOperationRoom?.length || 0) <= 0;

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        style={{ visibility: hide ? 'hidden' : 'visible' }}
      >
        Next Procedures
      </Button>
      {/* @ts-ignore */}
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar color="inherit" className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Next Procedures
            </Typography>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Back to the current procedure
            </Button>
          </Toolbar>
        </AppBar>
        <Box p={4} className={classes.dialogContent}>
          {nextProceduresInOperationRoom.map((p: Procedure) => (
            <Box mb={2} key={p.id}>
              {/* @ts-ignore */}
              <UpcomingProcedure
                onClick={undefined}
                procedure={p}
                isUnassigned={false}
                date={new Date()}
                isPatientIconVisible={true}
                highContrast={true}
              />
            </Box>
          ))}
        </Box>
      </Dialog>
    </>
  );
};

export default NextProcedures;
