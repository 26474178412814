import styled from 'styled-components';
import React from 'react';
import classNames from 'classnames';
import { withTheme } from '../theme';

const size = props => {
  if (props.xl) {
    return 'xl';
  } else if (props.l) {
    return 'l';
  } else {
    return 'm';
  }
};

const Icon = styled.i`
  font-size: ${withTheme((theme, props) => theme.textSize[size(props)])};
`;

export default ({ children, className, ...rest }) => (
  <Icon className={classNames('material-icons', className)} {...rest}>
    {children}
  </Icon>
);
