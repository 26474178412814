import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const DistinguishTitle = ({ title }) => {
  const intl = useIntl();

  return (
    <Box pt={2} mb={1} borderTop={1} borderColor="divider">
      <Typography variant="h3" gutterBottom>
        {intl.formatMessage({ id: title, defaultMessage: title })}
      </Typography>
    </Box>
  );
};

export default DistinguishTitle;
