import React, { ReactNode } from 'react';
import { formatTime, parseTime } from '../../../../../form/components/AnesthesiaVitals';
import { rowsV2 } from '../../../../../form/components/anesthesiaTableRowsV2';

export interface PredefinedFormProps<TValue> {
  hospitalName: string;
  defaultValue: TValue;
  value: TValue;
  setValue: (value: TValue) => void;
  questionnaire: any;
  questionnaireAnswers: any;
  lastPreOpCompletedEvent: any;
  procedureId: number;
  showQRCode?: boolean;
  formName: string;
}

interface AnesthesiaTableProps<TValue> extends PredefinedFormProps<TValue> {
  children: (page: ReactNode, index: number) => ReactNode;
}

const rows = rowsV2;
const columns = 10;

const AnesthesiaTableV2 = <TValue,>({ procedureId, defaultValue, children }: AnesthesiaTableProps<TValue>) => {
  const { table: anesthesiaTable, startTime, endTime } = ((defaultValue as any)?.anesthesiaTableAndChart || {}) as any;

  const times = Object.entries(anesthesiaTable ?? {})
    .filter(([k, v]) => rows.find(r => r.value === k) !== undefined)
    .flatMap(([, t]) => Object.keys(t as any))
    .map(parseTime)
    .filter(t => t > 0);

  const startTimeInSeconds = times.length > 0 ? Math.min(...times) : parseTime(startTime ?? '12:00');
  const endTimeInSeconds = times.length > 0 ? Math.max(...times) : startTimeInSeconds + columns * 15 * 60;

  // const startTimeInSeconds = Math.floor(parseTime(startTime) / 60 / 15) * 15 * 60;
  // const endTimeInSeconds = Math.ceil(parseTime(endTime) / 60 / 15) * 15 * 60;

  const pages = Math.ceil((endTimeInSeconds - startTimeInSeconds) / 60 / 15 / columns) || 1;

  return (
    <>
      <style>
        {`.anesthesia-table {
          font-size: 13px;
        }

        .anesthesia-table, .anesthesia-table th, .anesthesia-table td {
          border: 1px solid black;
          border-collapse: collapse;
        }
        .anesthesia-table th {
          text-align: start;
        }`}
      </style>
      {[...Array(pages)].map((_, page) =>
        children(
          <div className="page">
            <table className="anesthesia-table" width="100%">
              <thead>
                <tr>
                  <th></th>
                  {[...Array(columns)].map((_, i) => (
                    <th>{formatTime(startTimeInSeconds + (page * columns + i) * 15 * 60)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((row, i) => (
                  <tr key={i}>
                    <th>{row.label}</th>
                    {[...Array(columns)].map((_, j) => (
                      <td key={j}>
                        {prepareValue(
                          anesthesiaTable?.[row.value]?.[
                            formatTime(startTimeInSeconds + (page * columns + j) * 15 * 60)
                          ] || ''
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>,
          page
        )
      )}
    </>
  );
};

const prepareValue = (value: string): ReactNode => {
  function loop(remaining: string, result: ReactNode): ReactNode {
    const matchPrefix = remaining.match(/[({[<]/);
    const matchPostfix = remaining.match(/[)}\]>,.;-=+]/);

    if (
      matchPrefix &&
      matchPrefix.index &&
      (!matchPostfix || !matchPostfix.index || matchPrefix.index < matchPostfix.index)
    ) {
      const first = remaining.substring(0, matchPrefix.index);
      const second = remaining.substring(matchPrefix.index);

      return loop(
        second,
        <>
          {result}
          {first}
          <wbr />
          {second}
        </>
      );
    }

    if (matchPostfix && matchPostfix.index) {
      const first = remaining.substring(0, matchPostfix.index + 1);
      const second = remaining.substring(matchPostfix.index + 1);

      return loop(
        second,
        <>
          {result}
          {first}
          <wbr />
        </>
      );
    }

    return (
      <>
        {result}
        {remaining}
      </>
    );
  }

  return loop(value, <></>);
};

export default AnesthesiaTableV2;
