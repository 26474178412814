import React, { useEffect, useState } from 'react';
import SimpleReactLightbox from 'simple-react-lightbox';
import 'react-dropzone-uploader/dist/styles.css';
import { AppBar, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Hidden from '@material-ui/core/Hidden';
import {
  confirmFormUpload,
  removeUploadedForm,
  uploadedFormsSubscription,
  uploadFormTypesIntake,
  uploadRequest,
} from '../graph/surgeon/forms';
import get from 'lodash/get';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FilePicker } from 'react-file-picker';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Image from 'material-ui-image';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DeleteIcon from '@material-ui/icons/Delete';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import './lightboxStyles.css';
import { useApolloClient, useQuery, useSubscription } from '@apollo/client';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import useHasAccessRight from '../hooks/useHasAccessRight';
import Tooltip from './Tooltip';
import MuiTooltip from '@material-ui/core/Tooltip';
import { AddAPhoto, ArrowDownward, Delete, Print } from '@material-ui/icons';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    padding: theme.spacing(0, 2, 2),
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  panel: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px dashed ${theme.palette.divider}`,
    transition: 'border-color .15s ease-in-out',

    '&:hover': {
      borderColor: theme.palette.text.secondary,
    },
  },
  panelMain: {
    minHeight: '10rem',
  },
  appBar: {},
  title: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  tile: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: '100%',
    height: 0,
    position: 'relative',
  },
  tooltip: {
    flex: 1,
    width: '100%',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
  },
  gridItem: {
    display: 'flex',
  },
  blurred: {
    filter: 'blur(1rem)',
  },
  opacity: {
    opacity: 0.4,
  },
  lightboxIcon: {
    color: 'white',
  },
  overlayButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: '#808080',
    borderRadius: '50%',
    zIndex: 1000,
    width: '30px',
    height: '30px',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#404040',
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Document = ({ title, icon, procedure, form, formType, main }) => {
  const hasAccessRight = useHasAccessRight();
  const isAllowedToView = hasAccessRight('patient.view');
  const isAllowedToEdit = hasAccessRight('patient.edit');

  const onDrop = files => isAllowedToEdit && files && files.length > 0 && handleFilePickerChange(files[0]);

  const { getRootProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: ['image/*', '.pdf'],
    onDrop,
  });

  const theme = useTheme();
  const [images, setImages] = useState([]);
  const [preview, setPreview] = useState(null);
  const classes = useStyles();

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmLightboxOpen, setConfirmLightboxOpen] = useState(false);
  const [itemUploadings, setItemUploadings] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const isMd = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  const client = useApolloClient();

  const procedureId = procedure; // fixed
  const id = form; // fixed

  const uploadedForms = useSubscription(uploadedFormsSubscription, {
    variables: { id, procedureId },
  });

  useEffect(() => {
    const items = (uploadedForms.data?.uploadedForms ?? []).map(form => {
      let extractedName = form.substring(form.lastIndexOf('/') + 1);
      extractedName = extractedName.substring(id.toString().length + 1, extractedName.indexOf('?'));
      return {
        type: form.toLowerCase().indexOf('.pdf') > -1 ? 'file' : 'picture',
        data: form,
        name: decodeURI(extractedName),
      };
    });
    setImages(items);
  }, [id, uploadedForms.data?.uploadedForms]);

  const handleImageOpen = index => {
    setPreview(index);
  };

  const handleImageClose = () => {
    setPreview(null);
  };

  const handleDelete = i => {
    setPreview(null);
    const deleteFileName = images[i].name;
    const formValues = images.map((item, index) => ({ id: index, fileName: item.name })).filter(item => item.id !== i);

    client
      .mutate({
        mutation: removeUploadedForm,
        variables: { id, procedureId, fileName: deleteFileName, value: JSON.stringify(formValues) },
      })
      .then(result => {
        if (result.data) {
          setImages([...images.slice(0, i), ...images.slice(i + 1)]);
        } else {
          console.warn('Error deleting file:', deleteFileName);
        }
      })
      .catch(reason => alert(reason));
  };

  const handleDeleteConfirm = () => {
    setConfirmationOpen(true);
  };

  const handleFilePickerChange = file => {
    if (!file) return;

    const reader = new FileReader();
    reader.readAsDataURL(file);

    console.log('images', images);

    reader.onload = async e => {
      const data = e.target.result;
      setImages([
        ...images,
        {
          type: file.type.indexOf('pdf') > -1 ? 'file' : 'picture',
          data: data,
          name: file.name + '_' + images.length,
        },
      ]);
      await processUpload(images, data, file.name, file.type);
    };
  };

  const handleFilePickerError = error => {
    alert(error);
  };

  const processUpload = async (existingItems, newItem, fileName, contentType) => {
    try {
      const newFileName = fileName + '_' + existingItems.length;
      const preSignQueryResult = await client.query({
        query: uploadRequest,
        variables: { id, procedureId, fileName: newFileName, contentType },
      });

      const preSignedUrl = preSignQueryResult.data.uploadRequest;
      if (!Boolean(preSignedUrl)) {
        console.error('PreSign URL invalid');
        return;
      }
      console.log('File upload url', preSignedUrl);

      const formValues = existingItems.map((item, index) => ({ id: index, fileName: item.name }));
      formValues.push({ id: existingItems.length, fileName: newFileName });

      console.log('Uploading…', formValues);
      setItemUploadings([...itemUploadings, existingItems.length]);
      try {
        await uploadFileToPreSignedUrl(preSignedUrl, newItem, contentType, fileName);
      } finally {
        setItemUploadings([...itemUploadings]);
      }

      const confirmUploadQueryResult = await client.mutate({
        mutation: confirmFormUpload,
        variables: { id, procedureId, fileName: newFileName, value: JSON.stringify(formValues) },
      });

      const url = confirmUploadQueryResult.data.confirmFormUpload;

      if (url) {
        setImages(prev => {
          const index = prev.findIndex(image => image.name === newFileName);
          if (index >= 0) {
            const next = [...prev];
            next[index] = { ...next[index], data: url };
            return next;
          } else {
            return prev;
          }
        });
        console.log('Upload confirmed!');
      } else {
        console.error('Error while confirming upload. Try reuploading!');
      }
    } catch (e) {
      console.error('Error while processing upload', e);
    }
  };

  // if we do not have dataUri, we would set noConvert param
  const uploadFileToPreSignedUrl = async (url, file, contentType, name, noConvert) => {
    try {
      const headers = new Headers();
      headers.append('pragma', 'no-cache');
      headers.append('Content-Type', contentType);
      if (name && name.toLowerCase().indexOf('.pdf') < 0) {
        headers.append('Content-Disposition', 'attachment; filename=' + name);
      }
      const response = await fetch(url, {
        method: 'PUT',
        body: noConvert ? file : convertDataURItoBlob(file),
        headers,
      });

      if (response.status === 200) {
        console.log('File uploaded to S3!');
      } else {
        console.warn('Error uploading file', response);
      }
    } catch (e) {
      console.error('Uploading error', e);
    }
  };

  const convertDataURItoBlob = dataURI => {
    let byteString;
    let mimeString;
    let ia;

    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = encodeURI(dataURI.split(',')[1]);
    }
    // split the mime component
    mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes to a typed array
    ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  };

  const handleThumbnailClick = url => {
    const thumbIndex = onlyImages().findIndex(item => item.url === url);
    if (thumbIndex > -1) {
      setLightboxIndex(thumbIndex);
    } else {
      setLightboxIndex(0);
    }
    setLightboxOpen(true);
  };

  const handleDeleteLightBox = () => {
    setLightboxOpen(false);
    setConfirmLightboxOpen(true);
  };

  const handleDeleteImageLightbox = () => {
    let index = lightboxIndex;
    if (index < 0 || index > images.length - 1) index = 0;

    const urlToRemove = images[lightboxIndex].data;
    const imageIndex = images.findIndex(item => item.data === urlToRemove);
    if (imageIndex > -1) {
      handleDelete(imageIndex);
    }
  };

  const handleDownloadLightBox = () => {
    const lightboxImages = onlyImages();
    let index = lightboxIndex;
    if (index < 0 || index > lightboxImages.length - 1) index = 0;

    let imageName = lightboxImages[index].name;
    imageName = imageName.substring(0, imageName.lastIndexOf('_'));

    const urlToDownload = lightboxImages[index].url;
    const link = document.createElement('a');
    link.download = imageName;
    link.target = '_blank';
    link.href = urlToDownload;
    link.text = 'Image download';
    link.dispatchEvent(new MouseEvent('click'));
    URL.revokeObjectURL(link.href);
  };

  const handlePrintImage = () => {
    const lightboxImages = onlyImages();
    let index = lightboxIndex;
    if (index < 0 || index > lightboxImages.length - 1) index = 0;

    const urlToPrint = lightboxImages[index].url;

    const win = window.open();
    win.document.write(
      `<img style='max-width: 100%' src='${urlToPrint}' onload="window.print()" alt="document print" />`
    );
    win.focus();
  };

  const onlyImages = () =>
    images.filter(image => image.type === 'picture').map(image => ({ name: image.name, url: image.data }));

  const lightboxMainSrc = () => onlyImages()[lightboxIndex].url;

  const lightboxNextSrc = () => {
    const items = onlyImages();
    return items[(lightboxIndex + 1) % items.length].url;
  };

  const lightboxPrevSrc = () => {
    const items = onlyImages();
    return items[(lightboxIndex + items.length - 1) % items.length].url;
  };

  const lightboxMovePrev = () => {
    const items = onlyImages();
    setLightboxIndex((lightboxIndex + items.length - 1) % items.length);
  };

  const lightboxMoveNext = () => {
    setLightboxIndex((lightboxIndex + 1) % onlyImages().length);
  };
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [showOverlayButton, setShowOverlayButton] = useState(false);
  const handleOverlayButtonClick = (event, index) => {
    setLightboxIndex(index);
    setConfirmLightboxOpen(true);
  };
  return (
    <Box
      className={clsx(classes.panel, {
        [classes.panelMain]: main,
      })}
      style={{ flex: 1, borderColor: isDragActive ? '#00ff0e' : theme.palette.divider }}
      {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
    >
      <Box pl={2} pr={1} py={1} display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Box mr={1} display="flex">
            <Icon color="disabled">{icon}</Icon>
          </Box>
          <Typography variant="subtitle2">{title}</Typography>
        </Box>

        <Hidden mdUp>
          <label htmlFor={'documentPhoto' + procedure + form}>
            <Tooltip content={!isAllowedToEdit ? 'You don’t have sufficient permissions to upload a document.' : null}>
              <Box>
                <IconButton color="primary" component="span" disabled={!isAllowedToEdit}>
                  <AddAPhoto fontSize={'small'} />
                  <input
                    type="file"
                    id={'documentPhoto' + procedure + form}
                    name={'documentPhoto' + procedure + form}
                    accept="image/*"
                    capture="environment"
                    style={{ display: 'none' }}
                    disabled={!isAllowedToEdit}
                    onChange={e =>
                      isAllowedToEdit && handleFilePickerChange(e.target.files ? e.target.files[0] : undefined)
                    }
                  />
                </IconButton>
              </Box>
            </Tooltip>
          </label>
        </Hidden>

        <Hidden smDown>
          <Tooltip content={!isAllowedToEdit ? 'You don’t have sufficient permissions to upload a document.' : null}>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              edge="end"
              size="small"
              disabled={!isAllowedToEdit}
            >
              <FilePicker
                extensions={['jpg', 'jpeg', 'png', 'pdf']}
                maxSize={50}
                disabled={!isAllowedToEdit}
                onChange={handleFilePickerChange}
                onError={handleFilePickerError}
              >
                <Icon color="primary" className={clsx({ [classes.opacity]: !isAllowedToView })}>
                  publish
                </Icon>
              </FilePicker>
            </IconButton>
          </Tooltip>
        </Hidden>
      </Box>

      {lightboxOpen && (
        <Lightbox
          mainSrc={lightboxMainSrc()}
          nextSrc={lightboxNextSrc()}
          prevSrc={lightboxPrevSrc()}
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={lightboxMovePrev}
          onMoveNextRequest={lightboxMoveNext}
          toolbarButtons={[
            <IconButton onClick={handleDownloadLightBox} className={classes.lightboxIcon}>
              <ArrowDownward fontSize={'small'} />
            </IconButton>,
            <IconButton onClick={handleDeleteLightBox} className={classes.lightboxIcon}>
              <Delete fontSize={'small'} />
            </IconButton>,
            <IconButton onClick={handlePrintImage} className={classes.lightboxIcon}>
              <Print fontSize={'small'} />
            </IconButton>,
          ]}
        />
      )}

      <ConfirmationDialog
        open={confirmLightboxOpen}
        setOpen={setConfirmLightboxOpen}
        title={'Confirm action'}
        text={'Are you sure you want to delete this image?'}
        onConfirm={handleDeleteImageLightbox}
      />

      {images.length !== 0 ? (
        <div className={classes.root}>
          <GridList cols={isMd ? 4 : 6} cellHeight="auto" style={{ flex: 1 }}>
            {images.map((image, index) => (
              <GridListTile key={index}>
                <Box
                  display="flex"
                  alignItems="stretch"
                  className={clsx(classes.tile, { [classes.blurred]: !isAllowedToView })}
                  onMouseEnter={() => {
                    setShowOverlayButton(true);
                    setHoveredIndex(index);
                  }}
                  onMouseLeave={() => {
                    setShowOverlayButton(false);
                    setHoveredIndex(null);
                  }}
                >
                  {showOverlayButton && hoveredIndex === index && (
                    <IconButton
                      className={classes.overlayButton}
                      onClick={event => handleOverlayButtonClick(event, index)}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  )}
                  <ConfirmationDialog
                    open={confirmLightboxOpen}
                    setOpen={setConfirmLightboxOpen}
                    title={'Confirm action'}
                    text={'Are you sure you want to delete this image?'}
                    onConfirm={handleDeleteImageLightbox}
                  />
                  {itemUploadings.indexOf(index) > -1 ? <CircularProgressWithLabel /> : null}
                  {image.type === 'picture' ? (
                    <MuiTooltip
                      title={
                        isAllowedToView ? null : 'You don’t have sufficient permissions to edit or view this document.'
                      }
                    >
                      <Image
                        className={[formType, image.name].join(' ')}
                        key={index + formType + image.name}
                        src={image.data}
                        onClick={() => isAllowedToView && handleThumbnailClick(image.data)}
                        color={theme.palette.background.default}
                        loading={<CircularProgress size={16} />}
                        animationDuration={1500}
                        aspectRatio={1}
                        style={{
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                        }}
                        imageStyle={{
                          margin: 'auto',
                          objectFit: 'cover',
                        }}
                      />
                    </MuiTooltip>
                  ) : (
                    <MuiTooltip
                      title={
                        isAllowedToView
                          ? image.name.substring(0, image.name.lastIndexOf('_'))
                          : 'You don’t have sufficient permissions to view this document.'
                      }
                      className={classes.tooltip}
                    >
                      <IconButton onClick={() => isAllowedToView && handleImageOpen(index)}>
                        <Icon fontSize="large" color="primary">
                          picture_as_pdf
                        </Icon>
                      </IconButton>
                    </MuiTooltip>
                  )}
                </Box>
              </GridListTile>
            ))}
          </GridList>
        </div>
      ) : (
        <Box
          pb={2}
          px={2}
          flex={1}
          textAlign="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {main ? (
            <Box mb={2}>
              <Icon style={{ fontSize: '4rem' }} color="disabled">
                publish
              </Icon>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                No documents have been uploaded yet. Drag 'n' drop some files here, or click button to select files.
              </Typography>
            </Box>
          ) : (
            <Box mb={2}>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Drag 'n' drop some files here, or click button to select files.
              </Typography>
            </Box>
          )}
        </Box>
      )}

      <Dialog
        fullScreen={images[preview] && images[preview].type === 'file'}
        open={preview !== null}
        onClose={handleImageClose}
        TransitionComponent={Transition}
      >
        {images[preview] ? (
          images[preview].type === 'picture' ? (
            <img src={images[preview].data} style={{ maxWidth: '100%' }} alt="preview" />
          ) : (
            <React.Fragment>
              <AppBar color="transparent" position="relative" elevation={0}>
                <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={handleImageClose} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    {images[preview].name.substring(0, images[preview].name.lastIndexOf('_'))}
                  </Typography>
                  <Button startIcon={<DeleteIcon />} variant="outlined" color="secondary" onClick={handleDeleteConfirm}>
                    Delete
                  </Button>
                </Toolbar>
              </AppBar>
              <iframe
                title={images[preview].name}
                src={images[preview].data}
                style={{ width: '100%', height: '100%' }}
              />
            </React.Fragment>
          )
        ) : null}

        <ConfirmationDialog
          open={confirmationOpen}
          setOpen={setConfirmationOpen}
          title={'Confirm action'}
          text={'Are you sure you want to delete this file?'}
          onConfirm={() => handleDelete(preview)}
        />
      </Dialog>
    </Box>
  );
};

const CircularProgressWithLabel = props => (
  <Box
    position="absolute"
    display="inline-flex"
    zIndex="999"
    top="50%"
    left="50%"
    style={{ transform: 'translate(-50%, -50%)' }}
  >
    <CircularProgress variant="indeterminate" {...props} />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="caption" component="div" color="textSecondary" />
    </Box>
  </Box>
);

const ConfirmationDialog = props => {
  const handleClose = confirm => {
    props.setOpen(false);
    if (confirm) {
      props.onConfirm();
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{props.text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            No
          </Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const FileUploaderIntake = ({ procedureId }) => {
  const classes = useStyles();
  const { data: formQuery } = useQuery(uploadFormTypesIntake, { variables: { procedureId } });
  const forms = get(formQuery, 'uploadFormTypesIntake', []);

  return (
    <Grid container spacing={1}>
      {forms.map((item, index) => (
        <SimpleReactLightbox key={index}>
          <Grid
            item
            xs={12}
            sm={item.mainCategory ? 12 : forms.length >= 2 ? 6 : false}
            md={item.mainCategory ? 12 : forms.length >= 3 ? 4 : false}
            lg={item.mainCategory ? 12 : forms.length >= 3 ? 4 : false}
            key={index}
            className={classes.gridItem}
          >
            <Document
              main={item.mainCategory}
              title={item.name}
              icon={item.content}
              procedure={procedureId}
              form={item.id}
              formType={item.name}
            />
          </Grid>
        </SimpleReactLightbox>
      ))}
    </Grid>
  );
};

export default FileUploaderIntake;
