import React, { CSSProperties, ReactNode } from 'react';
import { useTheme } from '@material-ui/core';

interface TableCellLeftProps {
  row: number;
  topOffset: number;
  leftOffset: number;
  className?: string;
  style?: CSSProperties;
  children: (row: number) => ReactNode;
}

const TableCellLeft = ({ row, topOffset, leftOffset, className, style, children }: TableCellLeftProps) => {
  const theme = useTheme();

  return (
    <div
      key={row}
      className={className}
      style={{
        position: 'sticky',
        left: 0,
        backgroundColor: 'white',
        zIndex: 10,
        whiteSpace: 'nowrap',
        gridRow: `${1 + row + topOffset} / span 1`,
        gridColumn: `${1 + leftOffset} / span 1`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderLeft: `1px solid ${theme.palette.divider}`,
        borderRight: `2px solid ${theme.palette.divider}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...(style || {}),
      }}
    >
      {children(row)}
    </div>
  );
};

export default TableCellLeft;
