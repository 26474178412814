import React, { FC, useEffect, useMemo, useState, VFC } from 'react';
import { Box, Button, IconButton, TextField, Theme, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useSubscription } from '@apollo/client';
import { list, listStaffPTOs } from '../../../graph/staff';
import { StaffAvailability } from '../../../types/StaffAvailability';
import useDebouncedEffect from '../../../hooks/useDebouncedEffect';
import { addDays, format, isDate, isWeekend, parse, subDays } from 'date-fns';
import { DateString } from './util/groupStaffAvailability';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { DateRange, generateDates, generateHours, getHour, getMin, Hour } from './util/calendar';
import StaffMember, { PrimarySpecialtyColors, PrimarySpecialtyLabel } from '../../../types/StaffMember';
import { getStaffFormattedName } from '../kiosk/schedule/staff/StaffMember';
import clsx from 'clsx';
import filterStaffMembers from './util/filterStaffMembers';
import useHolidayCalendar from './hooks/useHolidayCalendar';
import GroupedByDate from './types/GroupedByDate';
import { HolidayCalendar as HolidayCalendarType } from '../../../types/HolidayCalendar';
import { holidayColor, ptoColor } from './HolidayCalendar';
import { convertStringDateToDate } from '../../../util/dateHandlers';
import { StyledTooltip } from './CalendarDate';
import formatTimeRange from './util/formatTimeRange';
import { useStyles as useStylesHoliday } from './StaffMemberCalendar';
import { RouteComponentProps, withRouter } from 'react-router';
import { CalendarTabs, Params } from './MultipleCalendarPage';
import { ArrowBack } from '@material-ui/icons';

const DAYS = 14;
const FROM_HOUR = 6;
const TO_HOUR = 18;
const HOURS = TO_HOUR - FROM_HOUR + 1;

type GroupedData = {
  [staffId: string]: {
    [date: string]: StaffAvailability<DateString>[];
  };
};

const HourCell = ({
  date,
  staffMember,
  hour,
  groupedPTOs,
}: {
  date: Date | null;
  staffMember: StaffMember;
  hour: Hour;
  groupedPTOs: GroupedData;
}) => {
  const classes = useStyles();
  const dateStr = date && format(date, 'YYYYMMDD');
  const ptos = dateStr ? groupedPTOs?.[staffMember?.staffId]?.[dateStr] || [] : [];

  const isHourBetween = ptos.some(
    e => hour.value > (e.from ? getHour(e.from) : 0) && hour.value < (e.to ? getHour(e.to) : 24)
  );
  const quarters = [0, 15, 30, 45];

  const isFilled = isHourBetween
    ? [true, true, true, true]
    : ptos.reduce(
        (acc, e) => {
          const hourFrom = e.from ? getHour(e.from) : 0;
          const hourTo = e.to ? getHour(e.to) : 24;
          const minFrom = e.from ? getMin(e.from) : 0;
          const minTo = e.to ? getMin(e.to) : 0;

          const isHourSame = hour.value === hourFrom && hour.value === hourTo;
          const isHourFrom = hour.value === hourFrom && hour.value !== hourTo;
          const isHourTo = hour.value !== hourFrom && hour.value === hourTo;

          if (isHourSame) {
            return [
              acc?.[0] || (quarters[0] >= minFrom && quarters[0] < minTo),
              acc?.[1] || (quarters[1] >= minFrom && quarters[1] < minTo),
              acc?.[2] || (quarters[2] >= minFrom && quarters[2] < minTo),
              acc?.[3] || (quarters[3] >= minFrom && quarters[3] < minTo),
            ];
          } else if (isHourFrom) {
            return [
              acc?.[0] || quarters[0] >= minFrom,
              acc?.[1] || quarters[1] >= minFrom,
              acc?.[2] || quarters[2] >= minFrom,
              acc?.[3] || quarters[3] >= minFrom,
            ];
          } else if (isHourTo) {
            return [
              acc?.[0] || quarters[0] < minTo,
              acc?.[1] || quarters[1] < minTo,
              acc?.[2] || quarters[2] < minTo,
              acc?.[3] || quarters[3] < minTo,
            ];
          }

          return [acc?.[0], acc?.[1], acc?.[2], acc?.[3]];
        },
        [false, false, false, false]
      );

  return (
    <Box className={classes.hourCell}>
      <Box className={clsx(classes.quarterCell, { [classes.pto]: isFilled[0] })} />
      <Box className={clsx(classes.quarterCell, { [classes.pto]: isFilled[1] })} />
      <Box className={clsx(classes.quarterCell, { [classes.pto]: isFilled[2] })} />
      <Box className={clsx(classes.quarterCell, { [classes.pto]: isFilled[3] })} />
    </Box>
  );
};

const DateTooltipPTO: VFC<{
  dateStr: string;
  pto: StaffAvailability<string>[];
  holiday: HolidayCalendarType<string>[];
  staffMember?: StaffMember;
}> = ({ dateStr, pto, holiday, staffMember }) => {
  const classes = useStylesHoliday();

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {(pto || []).length > 0 && (
        <Box>
          {pto.map((e, index) => (
            <Box key={index} className={classes.row}>
              <Typography>{formatTimeRange(e.from, e.to)}</Typography>
            </Box>
          ))}
        </Box>
      )}

      {(holiday || []).length > 0 && (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '0.5em',
          }}
        >
          <Typography gutterBottom style={{ fontWeight: 'bold', marginRight: '0.5em' }}>
            Holiday
          </Typography>
          {holiday.map((e, index) => (
            <Box key={index} className={classes.row}>
              <Typography>{e.description}</Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
const miliInHour = 3600000;
const miliInMinutes = 60000;
const getTimeInMili = (time: { hours: number; minutes: number }) =>
  time.hours * miliInHour + time.minutes * miliInMinutes;

const getTimeFromAndTo = (pto: any) => {
  let timeFrom = getTimeInMili(
    !!pto?.[0]?.from
      ? {
          hours: parseInt(pto[0].from.split(':')[0]),
          minutes: parseInt(pto[0].from.split(':')[1]),
        }
      : { hours: 6, minutes: 0 }
  );

  let timeTo = getTimeInMili(
    pto?.[0]?.to
      ? {
          hours: parseInt(pto[0].to.split(':')[0]),
          minutes: parseInt(pto[0].to.split(':')[1]),
        }
      : { hours: 18, minutes: 0 }
  );

  const timeStart = getTimeInMili({
    hours: 6,
    minutes: 0,
  });

  const timeEnd = getTimeInMili({
    hours: 18,
    minutes: 0,
  });

  if (timeFrom < timeStart && timeTo > timeEnd) {
    timeFrom = timeEnd;
    timeTo = timeEnd;
  } else if (timeFrom < timeStart && timeTo < timeEnd) {
    timeFrom = timeStart;
    if (!(timeTo > timeStart && timeTo < timeEnd)) timeTo = getTimeInMili({ hours: 8, minutes: 0 });
  } else if (timeFrom > timeStart && timeTo > timeEnd) {
    if (!(timeFrom > timeStart && timeFrom < timeEnd)) timeFrom = getTimeInMili({ hours: 16, minutes: 0 });
    timeTo = timeEnd;
  }

  return { timeFrom, timeTo, timeStart };
};

const DateCell = ({
  staffMember,
  dateStr,
  groupedPTOs,
  holidaysPerDate,
  onClick,
}: {
  staffMember: StaffMember;
  dateStr: DateString;
  groupedPTOs: GroupedData;
  holidaysPerDate: GroupedByDate<HolidayCalendarType<DateString>>;
  onClick: () => void;
}) => {
  const classes = useStyles();

  const holiday = holidaysPerDate.get(dateStr);
  const pto = groupedPTOs?.[staffMember?.staffId]?.[dateStr];

  const tooltipContent = <DateTooltipPTO staffMember={staffMember} dateStr={dateStr} pto={pto} holiday={holiday} />;

  const { timeFrom, timeTo, timeStart } = getTimeFromAndTo(pto);

  const cellContent = (
    <Box
      onClick={onClick}
      className={clsx(
        classes.cell,
        { [classes.weekend]: !groupedPTOs?.[staffMember?.staffId]?.[dateStr] && isWeekend(parse(dateStr)) },
        { [classes.holiday]: holidaysPerDate.get(dateStr).length > 0 }
      )}
    >
      <Box
        className={clsx({ [classes.pto]: !!groupedPTOs?.[staffMember?.staffId]?.[dateStr] })}
        style={{
          position: 'absolute',
          color: 'red',
          height: '100%',
          top: 0,
          left: timeTo - timeFrom === 0 ? 0 : `${((timeFrom - timeStart) / (12 * miliInHour)) * 100}%`,
          width:
            timeTo - timeFrom === 0 || holidaysPerDate.get(dateStr).length > 0
              ? '100%'
              : `${((timeTo - timeFrom) / (12 * miliInHour)) * 100}%`,
        }}
      />
    </Box>
  );
  return holiday.length !== 0 || !!pto ? (
    <StyledTooltip title={tooltipContent} enterDelay={700} leaveDelay={0}>
      {cellContent}
    </StyledTooltip>
  ) : (
    <>{cellContent}</>
  );
};

const CalendarRow = withRouter(
  ({
    date,
    staffMember,
    datesString,
    hours,
    groupedPTOs,
    holidaysPerDate,
    highlighted,
    setDate,
    history,
    location,
  }: {
    date: Date | null;
    staffMember: StaffMember;
    datesString: string[];
    hours: Hour[];
    groupedPTOs: GroupedData;
    holidaysPerDate: GroupedByDate<HolidayCalendarType<DateString>>;
    highlighted?: boolean;
    setDate: (date: string) => void;
  } & RouteComponentProps) => {
    const classes = useStyles();
    return (
      <Box className={classes.calendarRow}>
        <Box
          className={clsx(classes.staff, { [classes.highlightedStaffMember]: highlighted })}
          onClick={() => history.push(location.pathname.replace(`/pto`, `/staff/${staffMember?.staffId}`))}
        >
          {staffMember?.primarySpecialty && PrimarySpecialtyLabel[staffMember?.primarySpecialty] && (
            <span style={{ color: PrimarySpecialtyColors[staffMember?.primarySpecialty] }}>●&nbsp;</span>
          )}
          <Typography variant="body2">{getStaffFormattedName(staffMember.name, staffMember.titleAbbr)}</Typography>
        </Box>
        <Box className={date ? classes.hourCells : classes.dayCells}>
          {(date ? hours : datesString).map((e: Hour | string, index: number) =>
            date ? (
              <HourCell key={index} date={date} hour={e as Hour} staffMember={staffMember} groupedPTOs={groupedPTOs} />
            ) : (
              <DateCell
                key={index}
                onClick={() => setDate(datesString[index])}
                dateStr={e as string}
                staffMember={staffMember}
                groupedPTOs={groupedPTOs}
                holidaysPerDate={holidaysPerDate}
              />
            )
          )}
        </Box>
      </Box>
    );
  }
);

interface IFilterStaffMembers {
  filtered: StaffMember[];
  others: StaffMember[];
}

const CalendarRows: VFC<{
  filteredStaffMembers: IFilterStaffMembers | null;
  staffMembers: StaffMember[];
  search: string;
  onDateClick: (date: Date | Hour) => void;
  date: Date | null;
  hours: Hour[];
  datesString: string[];
  groupedPTOs: GroupedData;
  holidaysPerDate: GroupedByDate<HolidayCalendarType<string>>;
}> = ({
  filteredStaffMembers,
  staffMembers,
  search,
  onDateClick,
  date,
  hours,
  datesString,
  groupedPTOs,
  holidaysPerDate,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.content}>
      {!!search && (
        <>
          {filteredStaffMembers?.filtered.map((staffMember: StaffMember, index: number) => (
            <CalendarRow
              key={index}
              setDate={(date: string) => onDateClick(convertStringDateToDate(date))}
              date={date}
              staffMember={staffMember}
              hours={hours}
              datesString={datesString}
              groupedPTOs={groupedPTOs}
              holidaysPerDate={holidaysPerDate}
              highlighted={true}
            />
          ))}
          {filteredStaffMembers?.others.map((staffMember: StaffMember, index: number) => (
            <CalendarRow
              key={index}
              setDate={(date: string) => onDateClick(convertStringDateToDate(date))}
              date={date}
              staffMember={staffMember}
              hours={hours}
              datesString={datesString}
              groupedPTOs={groupedPTOs}
              holidaysPerDate={holidaysPerDate}
            />
          ))}
        </>
      )}
      {!search &&
        staffMembers.map((staffMember: StaffMember, index: number) => (
          <CalendarRow
            key={index}
            setDate={(date: string) => onDateClick(convertStringDateToDate(date))}
            date={date}
            staffMember={staffMember}
            hours={hours}
            datesString={datesString}
            groupedPTOs={groupedPTOs}
            holidaysPerDate={holidaysPerDate}
          />
        ))}
    </Box>
  );
};

const PTOCalendar: FC<RouteComponentProps<Params>> = props => {
  const classes = useStyles();

  const { data: staffMembersData } = useQuery(list);

  const [staffMembers, setStaffMembers] = useState<StaffMember[]>([]);
  const [filteredStaffMembers, setFilteredStaffMembers] = useState<IFilterStaffMembers | null>(null);

  useEffect(() => {
    if (staffMembers.length === 0) {
      setStaffMembers(staffMembersData?.staffMembers || []);
    }
  }, [staffMembersData, staffMembers]);

  const TODAY = new Date();
  const [date, setDate] = useState<Date | null>(null);
  const [dateRange, setDateRange] = useState<DateRange>({
    from: TODAY,
    to: addDays(TODAY, DAYS),
  });

  const dates = useMemo(() => generateDates(dateRange), [dateRange]);
  const hours: Hour[] = useMemo(() => generateHours(FROM_HOUR, TO_HOUR), []);
  const datesString = useMemo(() => dates.map(e => format(e, 'YYYYMMDD')), [dates]);

  const goToFutureDates = () => {
    if (date) {
      setDate(addDays(date, 1));
    } else {
      setDateRange(prev => ({ from: prev.to, to: addDays(prev.to, DAYS) }));
    }
  };

  const goToPastDates = () => {
    if (date) {
      setDate(subDays(date, 1));
    } else {
      setDateRange(prev => ({ from: subDays(prev.from, DAYS), to: prev.from }));
    }
  };

  const [staffPTOs, setStaffPTOs] = useState<StaffAvailability<DateString>[]>([]);

  const { data: staffPTOsData } = useSubscription<{ listStaffPTOs: StaffAvailability[] }, { from: string; to: string }>(
    listStaffPTOs,
    {
      variables: {
        from: date ? format(date, 'YYYY-MM-DD') : format(dateRange.from, 'YYYY-MM-DD'),
        to: date ? format(addDays(date, 1), 'YYYY-MM-DD') : format(dateRange.to, 'YYYY-MM-DD'),
      },
    }
  );

  useDebouncedEffect(
    () => {
      if (staffPTOsData?.listStaffPTOs) {
        setStaffPTOs(
          staffPTOsData?.listStaffPTOs.map(({ id, staffId, date, from, to, available }) => ({
            id,
            staffId,
            date: format(date, 'YYYYMMDD'),
            from,
            to,
            available,
          }))
        );
      }
    },
    350,
    [staffPTOsData?.listStaffPTOs]
  );

  const groupedPTOs = useMemo<GroupedData>(
    () =>
      staffPTOs.reduce((acc, curr) => {
        const staffId = curr.staffId;
        const date = curr.date;
        return (acc as any)?.[staffId]
          ? {
              ...acc,
              [staffId]: {
                ...(acc as any)?.[staffId],
                [date]: (acc as any)?.[staffId]?.[date] ? [...(acc as any)?.[staffId]?.[date], curr] : [curr],
              },
            }
          : {
              ...acc,
              [staffId]: {
                [date]: [curr],
              },
            };
      }, {}),
    [staffPTOs]
  );

  const [search, setSearch] = useState<string>('');

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const search = e.target.value;
    setSearch(search);
  };

  useDebouncedEffect(
    () => {
      if (search) {
        const filtered = filterStaffMembers(staffMembers, search);
        setFilteredStaffMembers(filtered);
      } else {
        setFilteredStaffMembers(null);
      }
    },
    350,
    [search]
  );

  const [isHourView, setIsHourView] = useState(false);
  const resetHourViewFlags = () => {
    setIsHourView(false);
    setDate(null);
  };
  const onDateClick = (date: Date | Hour) => {
    if (isDate(date)) {
      setIsHourView(true);
      setDate(date as Date);
    } else {
      resetHourViewFlags();
    }
  };

  const { holidaysPerDate } = useHolidayCalendar(
    date ? format(date, 'YYYY-MM-DD') : format(dateRange.from, 'YYYY-MM-DD'),
    date ? format(addDays(date, 1), 'YYYY-MM-DD') : format(dateRange.to, 'YYYY-MM-DD')
  );

  return (
    <CalendarTabs {...props}>
      <Box className={classes.container}>
        <Box className={classes.calendarRow}>
          <Box className={classes.staffHeader}>
            <Typography variant="h4">Staff Members</Typography>
          </Box>
          <Box className={classes.navigation1}>
            <Box>
              {isHourView && (
                <Button onClick={resetHourViewFlags} startIcon={<ArrowBack fontSize={'small'} />}>
                  Back to daily view
                </Button>
              )}
            </Box>
            <Box className={classes.navigation2}>
              <Box>
                <Typography
                  variant="h4"
                  style={{ color: isHourView && holidaysPerDate.size() === 1 ? holidayColor : 'inherit' }}
                >
                  {!!date
                    ? `${format(date, 'ddd, D MMM YYYY')}`
                    : `${format(dateRange.from, 'D MMM YYYY')} - ${format(subDays(dateRange.to, 1), 'D MMM YYYY')}`}
                </Typography>
              </Box>
              <Box>
                <Tooltip title={isHourView ? 'Previous day' : 'Previous days'}>
                  <IconButton onClick={goToPastDates}>
                    <ArrowBackIosIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={isHourView ? 'Next day' : 'Next days'}>
                  <IconButton onClick={goToFutureDates}>
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.calendarRow}>
          <Box className={classes.staffHeader}>
            <TextField label="Search" variant="filled" value={search} onChange={handleSearch} fullWidth />
          </Box>
          <Box className={date ? classes.hourCells : classes.dayCells}>
            {(date ? hours : dates).map((e: Hour | Date, index: number) => (
              <Box key={index} className={clsx(classes.cell, classes.headerCell)} onClick={() => onDateClick(e)}>
                <Typography variant="body2">{date ? (e as Hour).label : format(e as Date, 'D. ddd')}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <CalendarRows
          filteredStaffMembers={filteredStaffMembers}
          staffMembers={staffMembers}
          search={search}
          date={date}
          datesString={datesString}
          groupedPTOs={groupedPTOs}
          holidaysPerDate={holidaysPerDate}
          hours={hours}
          onDateClick={onDateClick}
        />
      </Box>
    </CalendarTabs>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flex: 1,
  },
  staff: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'rgba(0,167,247,0.2)',
    padding: theme.spacing(2),
    '&:hover': {
      opacity: 0.6,
    },
    cursor: 'pointer',
  },
  calendarRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 6fr',
    gridTemplateRows: '3.5rem',
    marginBottom: theme.spacing(0.5),
    '& > *': {
      marginRight: theme.spacing(0.5),
    },
  },
  navigation1: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  navigation2: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(2),
    },
  },
  staffHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  highlightedStaffMember: {
    backgroundColor: 'rgba(0,167,247,0.4)',
  },
  dayCells: {
    display: 'grid',
    flex: 1,
    gridTemplateColumns: `repeat(${DAYS}, 1fr)`,

    '& > *': {
      marginRight: theme.spacing(0.5),
    },
  },
  hourCells: {
    display: 'grid',
    flex: 1,
    gridTemplateColumns: `repeat(${HOURS}, 1fr)`,

    '& > *': {
      marginRight: theme.spacing(0.5),
    },
  },
  content: {
    marginTop: theme.spacing(1),
  },
  cell: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6,
    },
  },
  hourCell: {
    display: 'grid',
    flex: 1,
    gridTemplateColumns: `repeat(${4}, 1fr)`,
    cursor: 'pointer',
    gridColumnGap: theme.spacing(0.2),
  },
  quarterCell: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flex: 1,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6,
    },
  },
  headerCell: {
    backgroundColor: 'rgba(0,167,247,0.2)',
    opacity: 0.6,
    '&:hover': {
      opacity: 0.2,
    },
  },
  pto: {
    backgroundColor: ptoColor,
  },
  weekend: {
    backgroundColor: 'rgba(26,37,83,0.7)',
    '&:hover': {
      opacity: 0.4,
    },
  },
  holiday: {
    backgroundColor: holidayColor,
    '&:hover': {
      opacity: 0.4,
    },
  },
}));

export default PTOCalendar;
