import { Mutation } from '@apollo/client/react/components';
import React from 'react';
import styled from 'styled-components';
import LinkButton from '../../../se/components/LinkButton';
import ErrorFactory from '../../../se/components/errors/Error';
import { addItem, removeItem } from '../common/entityListHelpers';
import RemoveButton from '../../RemoveButton';
import { getProceduresDiff } from './transducers';
import { procedureTypeSelectInputFactory } from '../../inputs/procedureType/ProcedureTypeSelectInput';

const addNewProcedure = addItem('procedures');
const removeProcedureAtIndex = removeItem('procedures');
const ProcedureTypeSelectInput = procedureTypeSelectInputFactory();
const Error = ErrorFactory();

const Label = styled.p`
  margin: 0;
  color: ${props => props.theme.textColor.string()};
  font-weight: 500;
  opacity: 0.45;
  margin-bottom: 0.5rem;
`;

const ProcedureInputLayout = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  > div {
    flex: 1;
  }
`;

const ActionButtonsLayout = styled.div`
  display: flex;
  flex-direction: row;
  > button {
    flex: 1;
  }
`;

const FormWrapper = styled.div`
  margin: 1rem 0;
`;

export default class PhysicianProcedureTypesForm extends React.Component {
  state = {};
  componentDidMount = () => {
    const procedures = [...(this.props.data.procedures || [])];
    this.setState({
      procedures,
      initialProcedures: [...procedures],
      initialPurityHash: this.getPurityHash(procedures),
      isDirty: false,
    });
  };

  getPurityHash = procedures => procedures.reduce((acc, _) => `${acc}|${_.id}`, '');

  onSubmit = mutation => e => {
    e.preventDefault();
    const { assignProcedures, unassignProcedures } = getProceduresDiff(
      this.state.initialProcedures,
      this.state.procedures.filter(_ => Number.isInteger(_.id))
    );
    mutation({
      variables: {
        physician: this.props.data.id,
        assignProcedures,
        unassignProcedures,
      },
      refetchQueries: [{ query: this.props.scheme.item, variables: { id: this.props.data.id } }],
    });
  };

  onChange = ({ value, index }) => {
    const procedures = [...this.state.procedures];
    procedures[index].id = value;
    this.setProcedures(procedures);
  };

  onCompleted = () => {
    const procedures = this.state.procedures.filter(_ => Number.isInteger(_.id));
    this.setState({
      initialProcedures: [...procedures],
      procedures: [...procedures],
      initialPurityHash: this.getPurityHash(procedures),
      isDirty: false,
    });
  };

  addProcedure = () => this.setProcedures(addNewProcedure({ id: null })(this.state).procedures);

  removeProcedure = index => () => this.setProcedures(removeProcedureAtIndex(index)(this.state).procedures);

  setProcedures = procedures => {
    const isDirty = this.getPurityHash(procedures) !== this.state.initialPurityHash;
    this.setState({ procedures, isDirty });
  };

  render() {
    return (
      <Mutation mutation={this.props.scheme.applyPhysicianProcedureTypeChanges} onCompleted={this.onCompleted}>
        {(applyPhysicianProcedureTypeChanges, { loading, error }) => (
          <FormWrapper>
            <form onSubmit={this.onSubmit(applyPhysicianProcedureTypeChanges)}>
              <Label>Procedures</Label>
              {(this.state.procedures || []).map(({ id }, index) => (
                <ProcedureInputLayout key={index}>
                  <ProcedureTypeSelectInput onChange={value => this.onChange({ index, value })} value={id} />
                  <RemoveButton type="button" onClick={this.removeProcedure(index)}>
                    Remove
                  </RemoveButton>
                </ProcedureInputLayout>
              ))}
              <ActionButtonsLayout>
                <LinkButton type="button" onClick={this.addProcedure} busy={loading}>
                  Add a new procedure
                </LinkButton>
                {this.state.isDirty && <LinkButton busy={loading}>Save Changes</LinkButton>}
              </ActionButtonsLayout>
              <Error isVisible={error !== undefined} id="PhysicianProcedureTypesFormError">
                <p>An unexpected error has occurred. Please, try again or contact our support.</p>
              </Error>
            </form>
          </FormWrapper>
        )}
      </Mutation>
    );
  }
}
