import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment EquipmentData on Equipment {
      id
      name
    }
  `,
};

export const list = gql`
  query equipmentList {
    equipment: equipmentList {
      ...EquipmentData
    }
  }
  ${fragments.all}
`;

export const item = gql`
  query equipment($id: Long!) {
    equipment(id: $id) {
      ...EquipmentData
    }
  }
  ${fragments.all}
`;

export const create = gql`
  mutation creatEquipment($name: String!) {
    creatEquipment(name: $name) {
      ...EquipmentData
    }
  }
  ${fragments.all}
`;

export const update = gql`
  mutation editEquipment($id: Long!, $name: String!) {
    editEquipment(id: $id, name: $name) {
      ...EquipmentData
    }
  }
  ${fragments.all}
`;

export const remove = gql`
  mutation deleteEquipment($id: Long!) {
    deleteEquipment(id: $id)
  }
`;

export default {
  list,
  item,
  create,
  update,
  remove,
};
