import React, { useState, VFC } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button/Button';

type ActionButtonProps = ButtonProps & {
  loadingClassName?: string;
  loadingSize?: number;
};

const ActionButton: VFC<ActionButtonProps> = ({
  loadingClassName,
  loadingSize = 30,
  disabled,
  onClick,
  children,
  ...props
}) => {
  const [busy, setBusy] = useState(false);

  return (
    <Button
      {...props}
      onClick={
        onClick
          ? async e => {
              setBusy(true);
              try {
                await onClick(e);
              } finally {
                setBusy(false);
              }
            }
          : undefined
      }
      disabled={disabled || busy}
    >
      {busy && <CircularProgress size={loadingSize} className={loadingClassName} />}
      {!busy && children}
    </Button>
  );
};

export default ActionButton;
