import React, { useEffect, useMemo, useRef } from 'react';
import { withRouter } from 'react-router';
import { Box } from '@material-ui/core';
import { useSubscription } from '@apollo/client';
import { Patient } from '../../../types/Patient';
import { QuestionnaireAndAnswers } from '../../../types/Questionnaire';
import { sortBy } from 'lodash';
import { item as patientWithCharts } from '../../../graph/patientChart';
import { ThemeProvider } from '@material-ui/core/styles';
import { tabletThemeLight } from '../../../themes/tabletTheme';
import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Scatter, XAxis, YAxis, ZAxis } from 'recharts';
import { format, parse } from 'date-fns';
import { Measurements } from '../kiosk/charting/ChartV1';
import SignaturePad from 'react-signature-canvas';
import { MeasurementsV2 } from '../kiosk/charting/ChartV2';
import { MeasurementsV3 } from '../kiosk/charting/ChartV3';

const Vitals = withRouter(({ patientId }: { patientId: string }) => {
  const { data: patientData } = useSubscription(patientWithCharts, {
    variables: { id: patientId },
    skip: !patientId,
  });
  const patient: Patient | undefined = patientData?.patient;

  const charts: QuestionnaireAndAnswers[] | undefined = sortBy(
    patient?.procedure?.pacuCharts || [],
    'questionnaire.order'
  );

  const mergedAnswers = charts?.reduce(
    (acc, e) => ({
      ...acc,
      ...JSON.parse(e?.questionnaireExchange?.answers || '{}'),
    }),
    {}
  );

  const measurments = sanitizeMeasurements(
    Array.isArray(mergedAnswers)
      ? (mergedAnswers as Measurements[]) ?? []
      : Array.isArray(mergedAnswers?.measurments)
      ? (mergedAnswers?.measurments as Measurements[])
      : []
  );

  const signature = Array.isArray(mergedAnswers) ? undefined : mergedAnswers?.signature;

  const data = useMemo(
    () =>
      measurments.map(entry => {
        if (isFinite(entry.sys ?? NaN)) {
          return { ...entry, dif: entry.sys! - entry.dia! };
        }
        return entry;
      }),
    [measurments]
  );

  const minTimestamp = measurments.length > 0 ? measurments[0].timestamp ?? -Infinity : -Infinity;
  const maxTimestamp = measurments.length > 0 ? measurments[measurments.length - 1].timestamp ?? Infinity : Infinity;

  // const pacuCharts = usePacuCharts();
  // const medicationExtra = Array.isArray(question.extraFields)
  //   ? question.extraFields.find(field => field.interpreter === 'medication')
  //   : undefined;
  // const medications = medicationExtra ? getMedication(medicationExtra, pacuCharts) : undefined;
  const canvasRef = useRef<SignaturePad>();

  useEffect(() => {
    signature && canvasRef.current?.fromDataURL(signature, { ratio: 1 });
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <ThemeProvider theme={tabletThemeLight}>
      <Box flex={1} display="grid">
        <Box gridRow="1 / span 1" gridColumn="1 / span 1">
          <ResponsiveContainer height="100%" width="100%">
            <ComposedChart data={data} margin={{ top: 25, bottom: 15, right: 35 }}>
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                type="number"
                dataKey="timestamp"
                domain={[minTimestamp, maxTimestamp]}
                tickCount={measurments.length}
                padding={{ left: 20, right: 20 }}
                ticks={measurments.map(e => Number(e.timestamp)).filter(isFinite)}
                tickFormatter={time => format(parse(time), 'HH:mm')}
              />
              <YAxis
                yAxisId="left"
                type="number"
                domain={[0, 220]}
                label={{ color: 'white' }}
                padding={{ top: 20 }}
                ticks={[20, 40, 60, 80, 100, 120, 140, 160, 180, 200, 220]}
              />
              <ZAxis type="number" range={[400]} />
              <Bar yAxisId="left" stackId="bp" dataKey="dia" barSize={5} fill="transparent" />
              <Bar
                yAxisId="left"
                stackId="bp"
                dataKey="dif"
                barSize={5}
                fill="#1976D2"
                shape={props => {
                  const { fill, width, height, x, y } = props;

                  const arrowAngle = (45 * Math.PI) / 180;
                  const arrowLength = 10;
                  const arrowX = Math.sin(arrowAngle) * arrowLength;
                  const arrowY = Math.cos(arrowAngle) * arrowLength;
                  const topArrow = [
                    [x + width / 2 - arrowX, y - arrowY],
                    [x + width / 2, y],
                    [x + width / 2 + arrowX, y - arrowY],
                  ];
                  const bottomArrow = [
                    [x + width / 2 - arrowX, y + height + arrowY],
                    [x + width / 2, y + height],
                    [x + width / 2 + arrowX, y + height + arrowY],
                  ];
                  const topArrowPoints = topArrow.map(point => point.join(',')).join(' ');
                  const bottomArrowPoints = bottomArrow.map(point => point.join(',')).join(' ');

                  return (
                    <g>
                      <polyline
                        points={topArrowPoints}
                        stroke={fill}
                        strokeWidth={5}
                        fill="none"
                        strokeLinecap="round"
                      />
                      <polyline
                        points={bottomArrowPoints}
                        stroke={fill}
                        strokeWidth={5}
                        fill="none"
                        strokeLinecap="round"
                      />
                      <rect {...props} />
                      <text
                        {...props}
                        fill="black"
                        x={props.x + props.width + 5}
                        y={props.y + props.height - 5}
                        fontWeight="bold"
                      >
                        {props.dia}
                      </text>
                      <text {...props} fill="black" x={props.x + props.width + 5} y={props.y + 15} fontWeight="bold">
                        {props.sys}
                      </text>
                    </g>
                  );
                }}
              />
              <Scatter yAxisId="left" name="Medication" dataKey="meds" fill="#DC004E" shape="triangle" />
              <Line
                connectNulls
                yAxisId="left"
                type="monotone"
                dataKey="bpm"
                stroke="#4B9731"
                strokeWidth={3}
                dot={<CustomizedDot dataKey="bpm" />}
              />
              <Line
                connectNulls
                yAxisId="left"
                type="monotone"
                dataKey="resp"
                stroke="#979797"
                strokeWidth={3}
                dot={<CustomizedDot dataKey="resp" />}
              />
              <Line
                connectNulls
                yAxisId="left"
                type="monotone"
                dataKey="o2"
                stroke="#EE4B2B"
                strokeWidth={3}
                dot={<CustomizedDot dataKey="o2" />}
              />
              <Line
                connectNulls
                yAxisId="left"
                type="monotone"
                dataKey="temp"
                stroke="#89CFF0"
                strokeWidth={3}
                dot={<CustomizedDot dataKey="temp" />}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </Box>
        {/*<Box gridRow="1 / span 1" gridColumn="1 / span 1" style={{ pointerEvents: 'none' }}>*/}
        {/*  {medications === undefined ? null : medications === null ? null : (*/}
        {/*    <ResponsiveContainer width="100%" height="100%">*/}
        {/*      <ScatterChart margin={{ left: -25 }}>*/}
        {/*        <XAxis*/}
        {/*          type="number"*/}
        {/*          dataKey="timestamp"*/}
        {/*          domain={[minTimestamp, maxTimestamp]}*/}
        {/*          tickCount={0}*/}
        {/*          padding={{ left: 20, right: 20 }}*/}
        {/*          // ticks={value.map(e => Number(e.timestamp)).filter(isFinite)}*/}
        {/*          tickFormatter={time => format(parse(time), 'HH:mm')}*/}
        {/*          stroke="transparent"*/}
        {/*        />*/}
        {/*        <YAxis type="number" domain={[0, 1]} dataKey="y" label={{ color: 'transparent' }} tickCount={0} />*/}
        {/*        <Scatter*/}
        {/*          data={medications*/}
        {/*            .filter(m => m.timestamp >= minTimestamp && m.timestamp <= maxTimestamp)*/}
        {/*            .map(m => ({ ...m, y: 0 }))}*/}
        {/*          fill="#DC004E"*/}
        {/*          shape="triangle"*/}
        {/*        />*/}
        {/*      </ScatterChart>*/}
        {/*    </ResponsiveContainer>*/}
        {/*  )}*/}
        {/*</Box>*/}
      </Box>
    </ThemeProvider>
  );
});

export default Vitals;

const CustomizedDot = (props: any) => {
  const { cx, cy, stroke, payload, value } = props;

  const circleSize = 8;

  return (
    <g>
      <circle cx={cx} cy={cy} r={circleSize} fill={stroke} />
      <circle cx={cx} cy={cy} r={circleSize / 2} fill="white" />
      <text
        {...props}
        stroke="transparent"
        fill="black"
        x={cx + circleSize / 2 + 5}
        y={cy + circleSize / 2 - 10}
        fontWeight="bold"
      >
        {props.value}
      </text>
    </g>
  );
};

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

const randomString = (length: number = 7) => {
  let result = '';

  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const sanitizeMeasurements = (measurements: Measurements[]) =>
  measurements.map(({ key, ...measurement }) => ({
    key: key ?? randomString(),
    ...measurement,
  }));

export const sanitizeMeasurementsV2 = (measurements: MeasurementsV2[]) =>
  measurements.map(({ key, ...measurement }) => ({
    key: key ?? randomString(),
    ...measurement,
  }));

export const sanitizeMeasurementsV3 = (measurements: MeasurementsV3[]) =>
  measurements.map(({ key, ...measurement }) => ({
    key: key ?? randomString(),
    ...measurement,
  }));
