import React, { FC } from 'react';
import { Theme, createStyles, makeStyles, CircularProgress } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Recipient } from './Thread';

interface DeliveryStatusProps {
  recipients: Recipient[];
}

const DeliveryStatus: FC<DeliveryStatusProps> = ({ recipients }) => {
  const classes = useStyles();

  if (recipients.length === 0) {
    return null;
  }

  if (recipients.every(recipient => recipient.deliveryStatus.type === 'Delivered')) {
    return <CheckCircleIcon className={classes.icon} />;
  }

  if (recipients.some(recipient => recipient.deliveryStatus.type === 'Sent')) {
    return <CheckCircleOutlineIcon className={classes.icon} />;
  }

  if (recipients.some(recipient => recipient.deliveryStatus.type === 'Queued')) {
    return <CircularProgress className={classes.spinner} size="0.85rem" />;
  }

  return <ErrorOutlineIcon className={classes.icon} />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      transform: 'scale(67%) translate(-50%, 10%)',
      color: theme.palette.text.primary,
    },
    spinner: {
      color: theme.palette.text.primary,
      marginLeft: '-0.05rem',
      marginBottom: '-0.15rem',
    },
  })
);

export default DeliveryStatus;
