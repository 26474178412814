import { gql } from '@apollo/client';
import { fragments as roomFragments } from './rooms';

export const fragments = {
  all: gql`
    fragment GatewayData on Gateway {
      id
      type
      threshold
      createdAt
      lastSeenAt
      serverTime
      isInactive
      location
      portSwitch
      room {
        ...RoomDataS
      }
      signal {
        braceletId
        signalStrength
        signalAverage
        mainGateway
        updatedAt
      }
    }
    ${roomFragments.s}
  `,
  hospitalGateway: gql`
    fragment HospitalGatewayData on HospitalGateway {
      id
      hospital {
        id
        name
      }
      type
      threshold
      createdAt
      lastSeenAt
      serverTime
      isInactive
      location
      portSwitch
      room {
        ...RoomDataS
      }
    }
    ${roomFragments.s}
  `,
};

export const list = gql`
  query ($braceletId: String) {
    gateways(braceletId: $braceletId) {
      ...GatewayData
    }
  }
  ${fragments.all}
`;

export const listAllGateways = gql`
  query hospitalGateways($filter: Filter!) {
    hospitalGateways(filter: $filter) {
      ...HospitalGatewayData
    }
  }
  ${fragments.hospitalGateway}
`;

export const update = gql`
  mutation editGateway($id: String!, $gatewayType: GatewayType, $roomId: Long, $threshold: Int) {
    editGateway(id: $id, gatewayType: $gatewayType, threshold: $threshold, roomId: $roomId)
  }
`;

export const updateThreshold = gql`
  mutation updateThreshold($id: String!, $threshold: Int!) {
    updateThreshold(id: $id, threshold: $threshold)
  }
`;

export const updateLocation = gql`
  mutation updateLocation($id: String!, $location: String) {
    updateLocation(id: $id, location: $location)
  }
`;

export const updatePortSwitch = gql`
  mutation updatePortSwitch($id: String!, $portSwitch: String) {
    updatePortSwitch(id: $id, portSwitch: $portSwitch)
  }
`;

export const remove = gql`
  mutation deleteGateway($id: String!) {
    deleteGateway(id: $id)
  }
`;

export const listUnassignedGateways = gql`
  subscription {
    unassociatedGateways {
      id
      lastSeenAt
      serverTime
      associatedAt
      organizationId
    }
  }
`;

export const associateGateway = gql`
  mutation associateGateway($id: String!, $organizationId: Long!) {
    associateGateway(id: $id, organizationId: $organizationId)
  }
`;

export const disassociateGateway = gql`
  mutation disassociateGateway($id: String!) {
    disassociateGateway(id: $id)
  }
`;

export default {
  list,
  update,
  remove,
};
