import { Patient, PatientStatus } from '../../../../../types/Patient';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { startAdditionalTimeout as startAdditionalTimeoutMutation } from '../../../../../graph/patients';
import Button from '@material-ui/core/Button';

const TimeOut2ActionButton = ({ status, patient }: { status: string; patient?: Patient & { duration?: string } }) => {
  const isTimeOutActive = status === PatientStatus.TimeOut2;
  const [working, setWorking] = useState(false);
  const [startAdditionalTimeout] = useMutation(startAdditionalTimeoutMutation);

  const handleButtonClick = async () => {
    try {
      setWorking(true);
      patient?.id && (await startAdditionalTimeout({ variables: { id: patient?.id } }));
    } catch (error) {
      console.error(error);
    }
    setWorking(false);
  };

  return (
    <Button variant="outlined" onClick={handleButtonClick} disabled={working || isTimeOutActive}>
      {isTimeOutActive ? 'Time Out 2 Started' : 'Start Time Out 2'}
    </Button>
  );
};

export default TimeOut2ActionButton;
