import React from 'react';

import { createStyles, makeStyles, Grid, fade } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import Box from '@material-ui/core/Box';
import Frame from './Frame';
import Slot from './entry/Slot';
import Slots from './entry/Slots';
import Procedure from './entry/Procedure';
import Procedures from './entry/Procedures';

const contentFor = (entry, onClick) => {
  switch (entry.type) {
    case 'SLOT':
      return <Slot {...entry} onClick={onClick} />;
    case 'SLOTS':
      return <Slots {...entry} onClick={onClick} />;
    case 'PROCEDURE':
      return <Procedure {...entry} onClick={onClick} />;
    case 'PROCEDURES':
      return <Procedures {...entry} onClick={onClick} />;
    default:
      return null;
  }
};

const Timeline = ({ title, entries, onClick }) => {
  const classes = useStyles();

  return (
    <Box my={0.5}>
      <Grid container spacing={1} alignItems="center">
        <Grid item md={1} sm={12} xs={12}>
          <Typography className={classes.orName} variant="caption" color="textSecondary">
            {title}
          </Typography>
        </Grid>
        <Grid item md={11} sm={12} xs={12}>
          <Box position="relative" className={classes.timeline}>
            {entries.map(entry => (
              <Frame key={`${entry.type}-${entry.from}-${entry.to}`} from={entry.from} to={entry.to}>
                {contentFor(entry, onClick)}
              </Frame>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    orName: {
      marginRight: theme.spacing(2),
    },
    timeline: {
      height: theme.spacing(5),
      background: `repeating-linear-gradient(120deg,
        ${fade(theme.palette.background.default, 0.25)} 0,
        ${fade(theme.palette.primary.dark, 0.1)} 1px,
        ${fade(theme.palette.primary.dark, 0.1)} .25em,
        ${fade(theme.palette.background.default, 0.25)} calc(.25em + 1px),
        ${fade(theme.palette.background.default, 0.25)} .75em
      )`,
    },
  })
);

export default Timeline;
