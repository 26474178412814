import React, { Fragment } from 'react';
import { ArrowIcon, ListItemLink } from '../../../../se/components/collection/mobile/ListItem';
import { Column, Label, Value, TwoItemSection } from '../../../../se/components/collection/mobile/shared';
import RouterLink from '../../../../se/components/RouterLink';

const RoomMobileListItem = ({
  history,
  location,
  columns,
  item,
  context,
  index: i,
  showUrlProvider,
  idProvider,
  onClick,
}) => {
  if (item.separator) {
    return null;
  }
  const nameColumn = columns.find(_ => _.title === 'Name');
  const typeColumn = columns.find(_ => _.title === 'Type');
  const capacityColumn = columns.find(_ => _.title === 'Capacity');
  const dailyCapacity = columns.find(_ => _.title === 'Daily capacity in hours');

  return (
    <Fragment>
      <ListItemLink
        as={showUrlProvider ? RouterLink : undefined}
        to={
          showUrlProvider
            ? {
                pathname: showUrlProvider(idProvider(item)),
                search: location.search,
              }
            : undefined
        }
        onClick={onClick}
      >
        <ArrowIcon />
        <TwoItemSection style={{ marginBottom: '0.75rem' }}>
          <Column>
            <Label>{nameColumn.title}</Label>
            <Value>{nameColumn && <Value trimText>{nameColumn.lens(item, context)}</Value>}</Value>
          </Column>
          <Column>
            <Label>{typeColumn.title}</Label>
            <Value>{typeColumn && <Value trimText>{typeColumn.lens(item, context)}</Value>}</Value>
          </Column>
        </TwoItemSection>

        <TwoItemSection>
          <Column>
            <Label>{capacityColumn.title}</Label>
            <Value>{capacityColumn && <Value trimText>{capacityColumn.lens(item, context)}</Value>}</Value>
          </Column>
          <Column>
            <Label>{dailyCapacity.title}</Label>
            <Value>{dailyCapacity && <Value trimText>{dailyCapacity.lens(item, context)}</Value>}</Value>
          </Column>
        </TwoItemSection>
      </ListItemLink>
    </Fragment>
  );
};

export default RoomMobileListItem;
