import React, { Fragment } from 'react';
import styled from 'styled-components';
import ListHeader from './ListHeader';
import { isFunction } from '../../utilities/check';
import { Scroller } from './List';

const ListItemsContainer = styled.div`
  margin-top: 1.25rem;
`;

const MobileListView = ({
  columns,
  showUrlProvider,
  idProvider,
  onViewClick,
  getList,
  data,
  history,
  location,
  highlightedProvider,
  highlightedRowStyles,
  highlightedRowStyleProvider,
  tableKey,
  useCSVExport,
  MobileItemComponent,
}) => {
  const handleViewClick = data => () => {
    if (isFunction(onViewClick)) {
      onViewClick(data);
    }
  };

  const list = getList(data);
  return (
    <Fragment>
      <ListHeader
        showColumnSelection={false}
        showCSVExport={useCSVExport}
        selectedColumns={[]}
        setSelectedColumns={() => {}}
        columns={columns}
        columnOptions={[]}
        tableKey={tableKey}
        list={list}
        data={data}
      />
      <ListItemsContainer>
        {list.map((item, i) => (
          <Scroller key={`${idProvider(item)}-${i}`} collection={location.pathname} id={idProvider(item)}>
            <MobileItemComponent
              history={history}
              location={location}
              columns={columns}
              item={item}
              context={{ data }}
              index={i}
              showUrlProvider={showUrlProvider}
              idProvider={idProvider}
              onClick={handleViewClick(item)}
              highlighted={((highlightedProvider ? highlightedProvider(item) : false) && 'true') || undefined}
              highlightedRowStyles={
                highlightedRowStyleProvider ? highlightedRowStyleProvider(item) : highlightedRowStyles
              }
            />
          </Scroller>
        ))}
      </ListItemsContainer>
    </Fragment>
  );
};

export default MobileListView;
