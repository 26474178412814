import React from 'react';
import get from 'lodash/get';
import addMinutes from 'date-fns/add_minutes';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import { ROOM_TYPES } from '../../../../entities/room/enums';

export const NormalizedStatus = {
  Admitted: 'Admitted',
  In_Waiting_Room: 'WR',
  In_PRE_OP: 'PRE-OP',
  In_DPU: 'DPU',
  In_PACU: 'PACU',
  In_POST_OP: 'PostOp',
  In_OR: 'OR',
  AnestheticStart: 'AnestStart',
  TimeOut: 'TimeOut',
  ReadyForSurgery: 'Ready',
  Ongoing: 'Ongoing',
  CallNextPatient: 'Called',
  Closing: 'Closing',
  SurgeonLeftOR: 'SurgeonLeft',
  DressingOn: 'Dressing',
  ProcedureEnd: 'End',
  AnestheticEnd: 'AnestEnd',
  Ready: 'Ready',
  In: name => `${name}`,
  Completed: 'Completed',
  Discharged: 'Discharged',
  Canceled: 'Canceled',
  Unknown: 'Unknown',
};

export const normalizeStatus = (status, room, isDpuAsPreOp) => {
  if (room && !['Discharged', 'Canceled'].includes(status)) {
    if (room.type === ROOM_TYPES.PACU) {
      return NormalizedStatus.In_PACU;
    } else if (room.type === ROOM_TYPES.WAITING_ROOM) {
      return NormalizedStatus.In_Waiting_Room;
    } else if (room.type === ROOM_TYPES.PRE_OP) {
      return isDpuAsPreOp ? NormalizedStatus.In_DPU : NormalizedStatus.In_PRE_OP;
    } else if (room.type === ROOM_TYPES.POST_OP) {
      return NormalizedStatus.In_POST_OP;
    }
  }

  switch (status) {
    case 'Admitted':
      if (room) {
        switch (room.type) {
          case ROOM_TYPES.WAITING_ROOM:
            return NormalizedStatus.In_Waiting_Room;
          case ROOM_TYPES.PRE_OP:
            return isDpuAsPreOp ? NormalizedStatus.In_DPU : NormalizedStatus.In_PRE_OP;
          case ROOM_TYPES.OR:
            // Not sure if this is ok, doesn‘t sound right at all
            // Problem arose with the way we handle Exit Procedure on tablet,
            // it clears patient from the OR, sets it status to ADMITTED, but leaves roomId unchanged in the patient table
            // I'm pretty sure there is no clear idea what 'Exit Procedure' should do
            return NormalizedStatus.Completed;
          default:
            console.warn(`Unable to normalize Admitted status with ${room.type} room type.`);
            return NormalizedStatus.Unknown;
        }
      } else {
        return NormalizedStatus.In_Waiting_Room;
      }
    case 'InOr':
      return NormalizedStatus.In_OR;
    case 'Ready':
      return NormalizedStatus.In_OR;
    case 'AnestheticStart':
      return NormalizedStatus.AnestheticStart;
    case 'TimeOut':
      return NormalizedStatus.TimeOut;
    case 'ReadyForSurgery':
      return NormalizedStatus.ReadyForSurgery;
    case 'TimeOut2':
      return NormalizedStatus.TimeOut;
    case 'Ongoing':
      return NormalizedStatus.Ongoing;
    case 'CallNextPatient':
      return NormalizedStatus.CallNextPatient;
    case 'Closing':
      return NormalizedStatus.Closing;
    case 'SurgeonLeftOR':
      return NormalizedStatus.SurgeonLeftOR;
    case 'DressingOn':
      return NormalizedStatus.DressingOn;
    case 'ProcedureEnd':
      return NormalizedStatus.ProcedureEnd;
    case 'AnestheticEnd':
      return NormalizedStatus.AnestheticEnd;
    case 'Done':
      return NormalizedStatus.Completed;
    case 'Discharged':
      return NormalizedStatus.Discharged;
    case 'Canceled':
      return NormalizedStatus.Canceled;
    default:
      return NormalizedStatus.Unknown;
  }
};

export const isCompleted = procedure =>
  get(procedure, 'patient.journey.status') === 'Completed' ||
  [
    NormalizedStatus.Completed,
    NormalizedStatus.In_PACU,
    NormalizedStatus.In_POST_OP,
    NormalizedStatus.Discharged,
  ].includes(normalizeStatus(get(procedure, 'patient.status'), get(procedure, 'patient.room')));

export const getElapsedTimeFromStart = procedure => {
  const startTime = get(procedure, 'startTime');
  const orAt = get(procedure, 'patient.events.orAt');
  return differenceInMinutes(orAt || new Date(), startTime);
};

export const getOrLateEntryInMinutes = (procedure, isDpuAsPreOp) => {
  const startTime = get(procedure, 'startTime');
  const duration = get(procedure, 'duration');
  const orAt = get(procedure, 'patient.events.orAt');
  const dischargedAt = get(procedure, 'patient.dischargedAt');

  const isDpu =
    normalizeStatus(procedure?.patient?.status, procedure?.patient?.room, isDpuAsPreOp) === NormalizedStatus.In_DPU;
  const isPacu = procedure?.patient?.room?.type === ROOM_TYPES.PACU;
  const orLateInMinutes = differenceInMinutes(orAt || new Date(), startTime);
  const noShow = !get(procedure, 'patient') && orLateInMinutes > duration;
  return orLateInMinutes > 10 && !noShow && !isPacu && !isDpu && !dischargedAt ? orLateInMinutes : null;
};

export const getDelayInMinutes = (procedure, currentTime = new Date()) => {
  const startTime = get(procedure, 'startTime');
  const duration = get(procedure, 'duration');
  const endTime = addMinutes(startTime, duration);
  const postOpAt = get(procedure, 'patient.events.postOpAt');
  const recoveryAt = get(procedure, 'patient.events.recoveryAt');
  const procedureEndAt =
    (postOpAt && recoveryAt ? (postOpAt < recoveryAt ? postOpAt : recoveryAt) : postOpAt || recoveryAt) ||
    get(procedure, 'patient.events.dischargedAt');

  return differenceInMinutes(procedureEndAt || currentTime, endTime);
};

export const isDelayed = (procedure, isDpuAsPreOp) => {
  const isDpu =
    normalizeStatus(procedure?.patient?.status, procedure?.patient?.room, isDpuAsPreOp) === NormalizedStatus.In_DPU;
  return getDelayInMinutes(procedure) > 10 && !isDpu;
};

export const isNoShow = procedure =>
  !procedure.patient && getElapsedTimeFromStart(procedure) > get(procedure, 'duration') + 6 * 60;

export const isCanceled = procedure =>
  normalizeStatus(get(procedure, 'patient.status')) === NormalizedStatus.Canceled || get(procedure, 'isCanceled');

export const isDimmed = procedure => isCompleted(procedure) || isNoShow(procedure) || isCanceled(procedure);

export const formatMinutes = totalMinutes => {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);
  return hours > 0 ? `${hours}h ${minutes}m` : minutes === 0 ? '< 1m' : `${minutes}m`;
};

export const formatDuration = (from, to, inProgress, style) => {
  const totalMinutes = Math.abs(differenceInMinutes(to, from));

  return <span style={{ fontWeight: inProgress ? 'bold' : null, ...style }}>{formatMinutes(totalMinutes)}</span>;
};

export const fullNameToDrLastName = name => {
  if (!name) {
    return name;
  }
  const clean = name
    .trim()
    .replace(/\s+m\.*d\.*/gi, '')
    .replace(/\s+d\.*o\.*\s/gi, '')
    .replace(/\s+jr\.+\s*/gi, '');

  return `Dr. ${clean.split(' ').reverse()[0]}`;
};

export const fullNameToLastNameNoDr = name => {
  if (!name) {
    return name;
  }
  const clean = name
    .trim()
    .replace(/\s+m\.*d\.*/gi, '')
    .replace(/\s+d\.*o\.*\s/gi, '')
    .replace(/\s+jr\.+\s*/gi, '');

  const lastName = clean.split(' ').reverse()[0];
  return lastName.slice(0, 5);
};

export const fullNameToDrLastNameMiddleEast = name => {
  if (!name) {
    return name;
  }
  const lastName = name.trim().match(/(((AL|Al)(\s|-)\w+)|\w+)$/)?.[0];

  return `Dr. ${lastName}`;
};

export const fullNameToLastNameRest = name => {
  if (!name) {
    return name;
  }

  const chunks = name.split(' ');

  chunks.reverse();

  return `${chunks[0]}`;
};

export const fullNameToLastNameRestTrunkated = (name, numberOfCharacters = 4) => {
  if (!name) {
    return name;
  }

  const chunks = name.split(' ');

  chunks.reverse();

  const lastName = chunks[0];
  return lastName.slice(0, numberOfCharacters);
};
