import config from '../../../config';

export const verifySession = async session => {
  if (session && session.token) {
    const response = await fetch(`${config.apiURL}/identity/session`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${session.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();
    if (response.status !== 200) {
      return null;
    } else {
      return json;
    }
  }
  return null;
};

export const requestSession = async organizationId => {
  const response = await fetch(`${config.apiURL}/identity/session`, {
    method: 'POST',
    body: JSON.stringify({ kiosk: true, organization: parseInt(organizationId, 10) }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  const json = await response.json();

  if (response.status !== 201) {
    return { error: true };
  } else {
    return {
      error: false,
      ...json,
    };
  }
};

export const persistSession = session => localStorage.setItem(location.hostname + '_kiosk', JSON.stringify(session));

export const clearSession = () => localStorage.removeItem(location.hostname + '_kiosk');

export const fetchSession = () => {
  const cookie = localStorage.getItem(location.hostname + '_kiosk');
  return JSON.parse(cookie || '{}') || {};
};
