import React, { FC, useState } from 'react';
import { editPacuNote, editPreOpNote } from '../../../graph/patients';
import { useMutation } from '@apollo/client';
import { Note, Patient } from '../../../types/Patient';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const PreOpNoteInput: FC<{
  open: boolean;
  handleClose: () => void;
  patient?: Patient;
  onClick?: (note: Note) => void;
}> = ({ open, handleClose, patient, onClick }) => {
  const [note, setNote] = useState<Note>(patient?.preOpNote);
  const [saveForPacu, setSaveForPacu] = useState<boolean>(!!patient?.pacuNote);

  const [edit] = useMutation(editPreOpNote);
  const [editPacu] = useMutation(editPacuNote);

  const onSave = async () => {
    if (onClick) {
      await onClick(note);
    } else {
      await edit({ variables: { id: patient?.id, note } });
      if (saveForPacu) {
        await editPacu({ variables: { id: patient?.id, note } });
      }
    }
    handleClose();
  };

  const handleSaveForPacu = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSaveForPacu(checked);
    if (checked) {
      await editPacu({ variables: { id: patient?.id, note } });
    } else {
      await editPacu({ variables: { id: patient?.id, note: undefined } });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
      fullWidth
      onClick={e => e.stopPropagation()}
    >
      <DialogTitle id="form-dialog-title">Write PreOp Note</DialogTitle>
      <DialogContent>
        <Box mt={4} display="flex" flexDirection="column">
          <Typography gutterBottom>PreOp Note</Typography>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Note"
              variant="filled"
              multiline
              minRows={1}
              onChange={e => setNote(e.target.value)}
              value={note}
            />
          </Box>
          <FormControlLabel
            label="Note follows Patient"
            control={<Checkbox checked={saveForPacu} onChange={handleSaveForPacu} />}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button color="primary" onClick={onSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreOpNoteInput;
