import React, { Fragment, useState } from 'react';
import { Redirect } from 'react-router';
import { compose, withState } from 'recompose';
import styled, { ThemeProvider } from 'styled-components';
import queryString from 'query-string';
import posed, { PoseGroup } from 'react-pose';
import identity from 'lodash/identity';
import get from 'lodash/get';

import Form from '../../../se/components/Form';
import CredentialsInput from '../../../se/components/inputs/CredentialsInput';
import PasswordInput from '../../../se/components/inputs/PasswordInput';
import { isFunction } from '../../../se/utilities/check';

import Copyright from '../../Copyright';
import loginIllustration from '../../../assets/images/surgery_center.svg';
import responsive from '../../../se/utilities/responsive';
import { Paragraph } from '../../../se/components/typography';
import Link from '../../../se/components/html/Link';
import Logo from '../../Logo';
import { light as lightTheme } from '../../../theme';
import { withSession } from '../../../state/Session';
import PWAInstallBanner from '../../../se/components/PWAInstallBanner';
import PasswordChangeInput from '../../../se/components/inputs/PasswordChangeInput';
import ReactivationBanner from './ReactivationBanner';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ForgotPasswordInput from '../../../se/components/inputs/ForgotPasswordInput';
import { Alert } from '@material-ui/lab';

const Wrapper = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: auto 25rem auto;
  grid-template-rows: 1fr 2fr 1fr;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.textColor.string()};
  padding-left: 1em;
  padding-right: 1em;

  ${responsive.sm.andSmaller`
    grid-template-columns: auto 100% auto;
    grid-template-rows: auto auto auto;
  `};
`;

const Container = posed(
  styled.div`
    grid-column: 2;
  `
)({
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1000,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
  exit: {
    x: -20,
    opacity: 0,
  },
});

const Heading = posed(
  styled.div`
    grid-column: 2;
  `
)({
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1000,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
  exit: {
    x: -20,
    opacity: 0,
  },
});

const Footer = posed(Copyright)({
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1000,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
  exit: {
    x: -20,
    opacity: 0,
  },
});

const Description = ({ children, ...rest }) => <Typography {...rest}>{children}</Typography>;

const Page = styled.div`
  background-color: white;
  display: grid;
  grid-template-columns: 50% auto;

  ${responsive.md.andSmaller`
    grid-template-columns: auto;
  `};
`;

const Illustration = posed(
  styled.img`
    width: 30vw;
    margin-left: 4vw;
    margin-bottom: 2vh;
    align-self: center;

    ${responsive.md.andSmaller`
      display: none;
    `};
  `
)({
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1000,
      ease: [0.0, 0.01, 0.01, 0.99],
    },
  },
  exit: {
    x: 40,
    opacity: 0,
  },
});

const QuestionAction = ({ question, onClick, className, style, children }) => {
  const handleClick = e => {
    e.preventDefault();

    if (isFunction(onClick)) {
      onClick();
    }
  };

  // eslint-disable-next-line no-script-url
  const nothing = 'javascript:void(0)';

  return (
    <Paragraph className={className} style={style}>
      <span>{question}</span>{' '}
      <Link href={nothing} onClick={handleClick}>
        {children}
      </Link>
    </Paragraph>
  );
};

const Login = ({
  session,
  createUsingEmailPassword,
  changePasswordWithCredentials,
  resendConfirmationLinkEmail,
  sendPasswordResetLinkEmail,
  destroy,
  location,
  history,
  setBusy,
}) => {
  const [forceChangePassword, setForceChangePassword] = useState(null);
  const [isFirstLogin, setIsFirstLogin] = useState(null);
  const [forceConfirmation, setForceConfirmation] = useState(null);
  const [forgotPassword, setForgotPassword] = useState(null);
  const [resetPasswordLinkSent, setResetPasswordLinkSent] = useState(null);

  const isSessionUnknown = !session || !session.account || !session.account.id || !session.account.email;

  const isSessionValid = session && session.valid;

  const nextUrl = () => queryString.parse(location.search).next || '/';

  const reactivationCode = queryString.parse(location.search).reactivation;

  if (isSessionValid) {
    return <Redirect to={nextUrl()} />;
  }

  const reactToError = (e, payload) => {
    if (get(e, 'message') === 'password.change') {
      setForceChangePassword(payload);
    } else if (get(e, 'message') === 'password.initialize') {
      setForceChangePassword(payload);
      setIsFirstLogin(true);
    } else if (get(e, 'message') === 'password.attempts') {
      setForceConfirmation(payload);
    } else {
      throw e;
    }
  };

  const logInWithCredentials = async ({ email, password } = {}) => {
    setBusy(true);
    try {
      await createUsingEmailPassword(email, password);
    } catch (e) {
      reactToError(e, { email });
    }
    setBusy(false);
  };

  const logInWithPassword = async password => {
    setBusy(true);
    const email = get(session, 'account.email.Email.address');
    try {
      await createUsingEmailPassword(email, password);
    } catch (e) {
      reactToError(e, { email });
    }
    setBusy(false);
  };

  const changePassword = async ({ oldPassword, newPassword }) => {
    setBusy(true);
    const email = get(forceChangePassword, 'email');
    await changePasswordWithCredentials(email, oldPassword, newPassword);
    setBusy(false);
    setForceChangePassword(null);
    setIsFirstLogin(null);
  };

  const resendConfirmation = async () => {
    setBusy(true);
    const email = get(forceConfirmation, 'email');
    await resendConfirmationLinkEmail(email);
    setBusy(false);
  };

  const sendResetPasswordLink = async ({ email }) => {
    setBusy(true);
    await sendPasswordResetLinkEmail(email);
    setBusy(false);
    setResetPasswordLinkSent(true);
  };

  const goToApp = () => history.replace(nextUrl());

  const clickForgotPassword = () => {
    setForgotPassword(true);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Page>
        <Wrapper>
          <PoseGroup animateOnMount>
            <Heading key={0}>
              <Logo />
            </Heading>
            <Container key={1}>
              <PWAInstallBanner />
              {reactivationCode && <ReactivationBanner code={reactivationCode} />}
              {forceConfirmation ? (
                <Fragment>
                  <Typography variant="h2" gutterBottom>
                    Too Many Attempts
                  </Typography>
                  <Description
                    style={{ marginBottom: '2em' }}
                  >{`Your account is under lockdown. We sent you a reactivation link to your email address ${get(
                    forceConfirmation,
                    'email'
                  )}`}</Description>
                  <Form input={() => null} label="Resend" onSubmit={resendConfirmation} />
                  <QuestionAction question="Not you?" onClick={() => setForceConfirmation(null)}>
                    Click here.
                  </QuestionAction>
                  <Box mt={2}>
                    <Link
                      onClick={() => {
                        clickForgotPassword(true);
                        setForceConfirmation(false);
                      }}
                    >
                      Or Forgot Password?
                    </Link>
                  </Box>
                </Fragment>
              ) : forceChangePassword ? (
                <Fragment>
                  <Typography variant="h2" gutterBottom>
                    Change Password
                  </Typography>
                  {isFirstLogin ? (
                    <Description>
                      Our security policy requires all users to set their password on first login.
                    </Description>
                  ) : (
                    <Description>
                      We noticed that you didn‘t change your password in a while, please do so now to proceed.
                    </Description>
                  )}
                  <Typography variant="h3">{get(forceChangePassword, 'email')}</Typography>
                  <Form input={PasswordChangeInput} onSubmit={changePassword} label="Change" />
                  <QuestionAction question="Not you?" onClick={() => setForceChangePassword(null)}>
                    Click here.
                  </QuestionAction>
                </Fragment>
              ) : forgotPassword ? (
                <Fragment>
                  <Box mb={2}>
                    {!!resetPasswordLinkSent && (
                      <Alert severity="info">
                        In a few moments, you will receive an email with a link to reset your password. Please check
                        your email.
                      </Alert>
                    )}
                  </Box>
                  <Box mb={4}>
                    <Typography variant="h2" gutterBottom>
                      Please enter your email
                    </Typography>
                    <Typography variant="body1">We will send email with link to password reset</Typography>
                  </Box>
                  <Form autoFocus input={ForgotPasswordInput} label="Send" onSubmit={sendResetPasswordLink} />
                </Fragment>
              ) : isSessionUnknown ? (
                <Fragment>
                  <Typography variant="h2" gutterBottom>
                    Log In
                  </Typography>
                  <Form autoFocus input={CredentialsInput} label="Log In" onSubmit={logInWithCredentials} />
                  <Box mt={2}>
                    <Link onClick={clickForgotPassword}>Forgot Password?</Link>
                  </Box>
                </Fragment>
              ) : isSessionValid ? (
                <Fragment>
                  <Typography variant="h2">{session.user.name}</Typography>
                  <Form input={() => null} label="Continue" onSubmit={goToApp} />
                  <QuestionAction question="Not you?" onClick={destroy}>
                    Click here.
                  </QuestionAction>
                </Fragment>
              ) : (
                <Fragment>
                  <Typography variant="h2">{session.user.name}</Typography>
                  <Form autoFocus input={PasswordInput} label="Log In" onSubmit={logInWithPassword} />
                  <QuestionAction question="Not you?" onClick={destroy}>
                    Click here.
                  </QuestionAction>
                </Fragment>
              )}
            </Container>
            <Footer key={2} />
          </PoseGroup>
        </Wrapper>
        <PoseGroup animateOnMount={true}>
          <Illustration key={0} src={loginIllustration} />
        </PoseGroup>
      </Page>
    </ThemeProvider>
  );
};

export default compose(withSession(identity), withState('busy', 'setBusy', false))(Login);
