import { compose, withProps } from 'recompose';
import ObjectInput from '../../../se/components/inputs/ObjectInput';
import TextInput from '../../../se/components/inputs/TextInput';
import NumberInput from '../../../se/components/inputs/NumberInput';
import { withLabel } from '../../../se/components/Label';

import { ROOM_TYPE_LABELS } from '../../entities/room/enums';

export default withProps({
  schema: {
    name: compose(
      withLabel('Name'),
      withProps({
        placeholder: 'e.g., Neuroimaging',
        autoComplete: 'off',
        required: true,
      })
    )(TextInput),
    expectedPreOpDuration: compose(
      withLabel(`Avg. time in ${ROOM_TYPE_LABELS.PRE_OP} (mins)`),
      withProps({
        placeholder: 'e.g., 45',
      })
    )(NumberInput),
    expectedDuration: compose(
      withLabel(`Avg. time in ${ROOM_TYPE_LABELS.OR} (mins)`),
      withProps({
        placeholder: 'e.g., 240',
      })
    )(NumberInput),
    expectedPacuDuration: compose(
      withLabel(`Avg. time in ${ROOM_TYPE_LABELS.PACU} (mins)`),
      withProps({
        placeholder: 'e.g., 120',
      })
    )(NumberInput),
  },
})(ObjectInput);
