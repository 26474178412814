import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { TruncatedText } from '../../../se/components/typography';
import { formatPhoneNumber, processPhoneNumbersString } from '../../../se/components/inputs/PhoneInput';
import RouterLink from '../../../se/components/RouterLink';
import Anesthesiologist from '../../../types/Anesthesiologist';
import { Block, Check } from '@material-ui/icons';
import { formatProviderId } from '../../../util/providerId';
import { gql } from '@apollo/client';
import { PinColumn } from '../common/columns';
import { fragments } from '../../../graph/anesthesiologists';

const Text = TruncatedText as any;

export const nameColumn = {
  title: 'Name',
  lens: (item: Anesthesiologist) => item.name,
  Component: (props: { data: string }) => <Text>{props.data}</Text>,
};

export const hstIdColumn = {
  title: 'Provider Id',
  lens: (item: Anesthesiologist) => item.hstId,
  Component: (props: { data: string }) => <Text>{formatProviderId(props.data)}</Text>,
};

export const phoneNumber = {
  title: 'Phone Number',
  lens: (item: Anesthesiologist) =>
    item.phoneNumber !== null && item.phoneNumber !== undefined
      ? formatPhoneNumber(processPhoneNumbersString(item.phoneNumber).nationalNumbers)
      : undefined,
  Component: (props: { data: string }) => <Text>{props.data}</Text>,
};

export const scheduleAccessColumn = {
  title: 'Schedule Access',
  lens: (item: Anesthesiologist) => item,
  Component: withRouter((props: { data: Anesthesiologist } & RouteComponentProps) => (
    <RouterLink onClick={e => e.stopPropagation()} to={`${props.match.url}/schedule-access/${props.data.id}`}>
      {props.data.hasScheduleAccess ? <Check fontSize={'small'} /> : <Block fontSize={'small'} />}
      <Text>{props.data.hasScheduleAccess ? 'Yes' : 'No'}</Text>
    </RouterLink>
  )),
};

export const emailColumn = {
  title: 'Email',
  lens: (item: Anesthesiologist) => item,
  Component: (props: { data: Anesthesiologist }) => <Text>{props.data?.email}</Text>,
};

export const sendNotificationColumn = {
  title: 'Notify',
  lens: (item: Anesthesiologist) => item,
  Component: (props: { data: Anesthesiologist }) => <Text>{props.data.shouldSendNotification ? 'Yes' : 'No'}</Text>,
};

export const pinColumn = PinColumn<Anesthesiologist & { pin?: string }>({
  getId: data => data.id,
  getName: data => data.name,
  getPin: data => data.pin,
  createPinMutation: gql`
    mutation anesthesiologistCreatePin($id: Long!, $pin: String!) {
      anesthesiologistSetPin(id: $id, pin: $pin) {
        ...AnesthesiologistData
        pin
      }
    }
    ${fragments.all}
  `,
  issuePinMutation: gql`
    mutation anesthesiologistIssuePin($id: Long!) {
      anesthesiologistIssuePin(id: $id) {
        ...AnesthesiologistData
        pin
      }
    }
    ${fragments.all}
  `,
  removePinMutation: gql`
    mutation anesthesiologistRemovePin($id: Long!) {
      anesthesiologistRemovePin(id: $id) {
        ...AnesthesiologistData
        pin
      }
    }
    ${fragments.all}
  `,
});

export const baseColumns = [
  nameColumn,
  hstIdColumn,
  phoneNumber,
  emailColumn,
  scheduleAccessColumn,
  sendNotificationColumn,
  pinColumn,
];
export const viewColumns = [hstIdColumn, phoneNumber, emailColumn, sendNotificationColumn];
